import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ClientesService } from "app/core/services/clientes/clientes.service";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-add-operational-credit",
  templateUrl: "./add-operational-credit.component.html",
  styleUrls: ["./add-operational-credit.component.css"],
})
export class AddOperationalCreditComponent implements OnInit {
  public myForm: FormGroup;
  public credit: any;
  public entidadesFinancieras: any[];
  public monedas: any[];
  faTimes=faTimes;
  @Input() clienteId = 0;
  @Input() creditId = 0;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private clientesService: ClientesService,
    public formbuild: FormBuilder,
    public activeModal: NgbActiveModal,
    public localeService: BsLocaleService
  ) {
    defineLocale("es", esLocale);
    this.localeService.use("es");
    this.initForm();
  }

  ngOnInit(): void {
    if (this.creditId) {
      this.obtenerCreditoOperaciones();
    }

    let entidadesFinancieras = this.clientesService.obtenerEntidadesFinancieras();
    let monedas = this.clientesService.obtenerListaMonedas();

    Promise.all([entidadesFinancieras, monedas]).then((res) => {
      this.entidadesFinancieras = res[0]["results"];
      this.monedas = res[1]["results"];
    });
  }

  obtenerCreditoOperaciones() {
    this.clientesService
      .obtenerCreditoOperaciones(this.creditId)
      .then((res) => {
        this.credit = res;
        this.setForm(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      entidad_financiera: [null, [Validators.required]],
      linea_asignada: [null, [Validators.required]],
      moneda: [null, [Validators.required]],
      plazo_promedio: [null, []],
      monto_promedio: [null, [Validators.required]],
      documento_mayor_uso: [null, []],
      situacion: [null, []],
    });
  }

  get f() {
    return this.myForm.controls;
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = this.myForm.value;
      data["cliente"] = this.clienteId;

      this.clientesService
        .guardarCreditoOperaciones(this.myForm.value, this.creditId)
        .then((res) => {
          this.successEmit.emit();
          this.activeModal.close(false);
        })
        .catch((error) => {
          this.activeModal.close(false);
        });
    }
  }

  compararTipos(tipo1: any, tipo2: any) {
    return tipo1 && tipo2 ? tipo1.id === tipo2 : tipo1 === tipo2;
  }
}
