import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentsService } from 'app/core/services/documents/documents.service';

@Component({
  selector: 'app-manage-document-modal',
  templateUrl: './manage-document-modal.component.html',
  styleUrls: ['./manage-document-modal.component.css']
})
export class ManageDocumentModalComponent implements OnInit {
  faTimes = faTimes
  myForm: FormGroup;
  @Input() documentId = 0;
  documento: any;
  grupos: any[] = [];
  tiposPersonas: any[] = [];
  tiposProductos: any[] = [];
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild: FormBuilder,
    public activeModal: NgbActiveModal,
    private documentsService: DocumentsService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {

    let gruposDocumentos = this.documentsService.obtenerGrupos()
    let tiposPersonas = this.documentsService.obtenerTiposPersonas()
    let tiposProductos = this.documentsService.obtenerTiposProductos()
    let visible = this.documentsService.obtenerDocuments()

    Promise.all([gruposDocumentos, tiposPersonas, visible, tiposProductos]).then(res => {
      this.grupos = res[0]['results'];
      this.tiposPersonas = res[1]['results'];
      this.tiposProductos = res[3]['results'];
      // this.tiposPersonas.push({
      //   id: 3,
      //   nombre: 'Jurídicos y Naturales'
      // })
      this.tiposProductos.unshift({
        id: 0,
        descripcion: 'Todos'
      })
    }).catch(err => {
      console.error(err);
    });

    if (this.documentId) {
      this.obtenerDocumento();
    }
  }

  initForm() {
    this.myForm = this.formbuild.group({
      grupo: [null, [Validators.required]],
      descripcion: [null, [Validators.required]],
      visible_cliente: [false, []],
      tipo_persona: [null],
      tipo_linea: [null],
      vigencia_dias: [null],
    });
  }

  obtenerDocumento() {
    this.documentsService.obtenerDocument(this.documentId)
      .then(res => {
        this.documento = res;
        this.setForm(res);
      })
      .catch(error => {
        console.error(error);
      });
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        if(key === 'tipo_linea' && item[key] === null){
          this.myForm.controls[key].setValue(0);
        }else{
          this.myForm.controls[key].setValue(item[key]);
        }
      }
    }
  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = this.myForm.value;
      let valorTipoPersona = this.myForm.get('tipo_persona')
      if (valorTipoPersona.value === 3) {
        valorTipoPersona.setValue(null)
      }
      let valorTipoProducto = this.myForm.get('tipo_linea')
      if (valorTipoProducto.value === 0) {
        valorTipoProducto.setValue(null)
      }

      this.documentsService.guardarDocument(this.myForm.value, this.documentId)
        .then(res => {
          this.successEmit.emit();
          this.activeModal.close(false);
        }).
        catch(error => {
          this.activeModal.close(false);
        });
    }
  }

}
