import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fixed-finance-modal',
  templateUrl: './fixed-finance-modal.component.html',
  styleUrls: ['./fixed-finance-modal.component.css']
})
export class FixedFinanceModalComponent implements OnInit {
  faTimes = faTimes
  @Input() detalle;

  @Input() titleRightBottom = 'Cerrar';
  @Input() showBottomLeft = true;
  @Input() showBottomRight = true;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  

}
