import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { AdminLayoutComponent } from '../../layouts/admin/admin-layout.component';
import { CommitteeComponent } from './pages/committee/committee.component';


const routes: Routes = [
  {
    path: 'committees',
    canActivate: [AuthGuard],
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Autenticacion'
    },
    children: [
      {
        path: '',
        component: CommitteeComponent,
        data: {
          breadcrumb: 'Configuracion de Comite'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommitteesRoutingModule { }
