import { Component, OnInit } from '@angular/core';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { ManageRecaudacionCuentasComponent } from '../modals/manage-recaudacion-cuentas/manage-recaudacion-cuentas.component';
import { functions } from '../../../core/helpers/functions';

@Component({
  selector: 'app-recaudacion-cuentas',
  templateUrl: './recaudacion-cuentas.component.html',
  styleUrls: ['./recaudacion-cuentas.component.css']
})
export class RecaudacionCuentasComponent implements OnInit {
  faPlus = faPlus;
  faSyncAlt = faSyncAlt;
  faChartPie = faChartPie;
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = '';
  public canCreate: boolean = false;

  constructor(
    private configurationService: ConfigurationService,
    public modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerCuentas();
  }

  obtenerCuentas(page = 1, page_size = 10) {
    let condiciones = this.configurationService.obtenerCuentas(page, page_size);

    Promise.all([condiciones]).then((res: any[]) => {
      let condiciones = res[0];
      this.initVars();
      this.loadTable(condiciones);
    });
  }

  loadTable(data) {
    this.rowData = functions.indextable( data )
    // this.rowData = data.results
    this.pageTableActual = data.page_number
    this.pageTableTotal = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }

  initVars() {
    let columns = [
      {
        headerName: 'N°',
        field: 'index',
        sortable: true,
      },
      {
        headerName: 'Nombre',
        field: 'banco_nombre',
        class: 'text-center',
        sortable: true,
        // filterable: true,
        // filterProp: 'descripcion',
        // filterInput: true,
      },
      {
        headerName: 'Número',
        field: 'numero',
        class: 'text-center',
        sortable: true,
      },
      {
        headerName: 'Moneda',
        field: 'moneda',
        class: 'text-center',
        sortable: true,
        pipe: 'function',
        function: function (row) {
          return (row.moneda == 1) ? 'Soles' : 'Dolares';
        }
      },
    ];

    this.columnDefs = columns;
  }

  crear() {
    const modalRef = this.modalService.open(ManageRecaudacionCuentasComponent, {
    });

    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
      console.log(result);
      this.obtenerCuentas();
    },
      error => {

      });
  }

  tableEditEvent(row) {
    const modalRef = this.modalService.open(ManageRecaudacionCuentasComponent, {
    });

    modalRef.componentInstance.cuentaId = row.id;

    modalRef.componentInstance

    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
      this.obtenerCuentas();
    },
      error => {

      });
  }

  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {

    });

    modalRef.componentInstance.title = 'Remover Cuenta';
    modalRef.componentInstance.message = '¿Seguro que desea remover la Cuenta?';

    modalRef.result.then((result) => {
      if (result) {
        this.configurationService.elimiarCuenta(row.id)
          .then(result => {
            this.obtenerCuentas();
          })
          .catch(error => {
            console.error(error);
          });
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  goToPage({ page, per_page, filtros }) {
    this.obtenerCuentas(page, per_page);
  }

}
