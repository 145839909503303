import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ResultadoFacturasModalComponent } from '../../modals/resultado-facturas-modal/resultado-facturas-modal.component';

@Component({
  selector: 'app-resultados',
  templateUrl: './resultados.component.html',
  styleUrls: ['./resultados.component.css']
})
export class ResultadosComponent implements OnInit {

  public reporteForm : FormGroup;
	public headerTable: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
  public searchstring: string = '';
  rowData: any;
  filtros: any = {};
 
  procesadoFilter: undefined
  operacionDetalleFilter: undefined
  
  operacion: undefined
  operacionesDetalle: undefined
  
  constructor( 
    public documentosCarteraService: DocumentosCarteraService,
    private factoringService: FactoringService,
    public formbuild: FormBuilder,
    public authService: AuthService,
    public modalService: NgbModal
  ) {}


  ngOnInit(): void {
    this.loadTableColumns()
    this.getCavaliResultados({page:1, per_page:this.pageTablePerPages, filtros:{}})
  }


  loadTableColumns() {
    this.headerTable = [
      {
        headerName: 'ID',
        class: 'text-center',
        field: 'id',
        sortable: true
      },
      // {
      //   headerName: 'COD Acción',
      //   class: 'text-center',
      //   field: 'action_code',
      //   sortable: true
      // },
      {
        headerName: 'Etiqueta de código',
        class: 'text-center',
        field: 'action_code_label',
        sortable: true
      },
      {
        headerName: 'Fecha',
        class: 'text-center',
        field: 'action_date',
        sortable: true,
        pipe: 'function',
        function: function (row) {
					return `${row.action_date.split('-').reverse().join('/')} ${row.action_time.slice(0,8)}`
				}
      },
      {
        headerName: 'Números Documento',
        class: 'text-center',
        field: 'numeros_documentos',
        sortable: true
      },
      // {
      //   headerName: 'ID Factura',
      //   class: 'text-center',
      //   field: 'invoice__id',
      //   sortable: true
      // },
      // {
      //   headerName: 'Procesada',
      //   class: 'text-center',
      //   field: 'invoice__procesado',
      //   sortable: true,
      //   pipe: "checkbox",
      //   checkDisabled: true,
      // },
      // {
      //   headerName: 'RUC Proveedor',
      //   class: 'text-center',
      //   field: 'invoice__provider_ruc',
      //   sortable: true
      // },
      // {
      //   headerName: 'N° Serie',
      //   class: 'text-center',
      //   field: 'invoice__series',
      //   sortable: true,
      //   filterable: true,
      //   filterInput: true,
      //   filterProp: 'invoices__series'
      // },
      // {
      //   headerName: 'Numeracion',
      //   class: 'text-center',
      //   field: 'invoice__numeration',
      //   sortable: true,
      //   filterable: true,
      //   filterInput: true,
      //   filterProp: 'invoices__numeration'
      // },
      // {
      //   headerName: 'RUC Aceptante',
      //   class: 'text-center',
      //   field: 'invoice__acq_ruc',
      //   sortable: true
      // },
      // {
      //   headerName: 'Aceptante',
      //   class: 'text-center',
      //   field: 'invoice__acq_full_name',
      //   sortable: true
      // },
      // // {
      // //   headerName: 'COD Resultado',
      // //   class: 'text-center',
      // //   field: 'invoice__result_code',
      // //   sortable: true
      // // },
      // {
      //   headerName: 'Resultado',
      //   //class: 'text-center',
      //   field: 'invoice__result_message',
      //   sortable: true
      // },
      // // {
      // //   headerName: 'Estado de factura',
      // //   class: 'text-center',
      // //   field: 'invoice__invoice_state',
      // //   sortable: true
      // // },
      // {
      //   headerName: 'Estado de factura',
      //   class: 'text-center',
      //   field: 'invoice__invoice_state_label',
      //   sortable: true
      // },
      {
        headerName: 'Usuario',
        class: 'text-center',
        field: 'application_user',
        sortable: true
      }
    ]
  }

  parseData(data) {
    console.log("DA", data)
    return data.map(el=>{
      return {
        id: el?.id,
        application_user: el?.application_user,
        action_code: el?.action_code,
        action_code_label: el?.action_code_label,
        action_date: el?.action_date,
        action_time: el?.action_time,
        numeros_documentos: el?.numeros_documentos, 
        invoice__id: el?.invoice?.id,
        invoice__procesado: el?.invoice?.procesado,
        invoice__provider_ruc: el?.invoice?.provider_ruc,
        invoice__series: el?.invoice?.series,
        invoice__numeration: el?.invoice?.numeration,
        invoice__acq_ruc: el?.invoice?.acq_ruc,
        invoice__acq_full_name: el?.invoice?.acq_full_name,
        invoice__result_code: el?.invoice?.result_code,
        invoice__result_message: el?.invoice?.result_message,
        invoice__invoice_state: el?.invoice?.invoice_state,
        invoice__invoice_state_label: el?.invoice?.invoice_state_label,
      }
    })
  }
 
  loadTable(data) {
		this.rowData = this.parseData(data.results)
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  getCavaliResultados({page, per_page, filtros}) {
    console.log(filtros)
    this.factoringService.getCavaliResultados(
      page, 
      per_page,
      filtros.operacion_detalle,
      filtros.invoices__operacion_detalle__isnull,
      filtros.invoices__series,
      filtros.invoices__numeration,
      filtros.invoices__procesado,
      filtros.id_process,
      filtros.proceso,
      filtros.error,
      this.operacion,
      ).then(res=>{
        this.loadTable(res)
    })
  }

  goToPage({page, per_page, filtros}){
    this.filtros = filtros
    this.filtros["invoices__procesado"] = this.procesadoFilter
    this.getCavaliResultados({page, per_page, filtros})
	}

  externalFilter(ev, type) {
    if (type == 'procesado') {
      this.filtros["invoices__procesado"] = this.procesadoFilter
    } else if (type == 'operacion_detalle') {
      this.filtros["operacion_detalle"] = this.operacionDetalleFilter
    }
    this.getCavaliResultados({page: this.pageTableActual, per_page: this.pageTablePerPages, filtros: this.filtros})
  }

  readRow(ev) {
    const cavaliModal = this.modalService.open(ResultadoFacturasModalComponent, {size: "xl",})
    cavaliModal.componentInstance.resultado = ev.id
  }

  readActionFunction() {
    return (row) => {
      return !!row.numeros_documentos
    }
  }
  operacionChange(ev) {
    this.operacionesDetalle = undefined
    this.operacionDetalleFilter = undefined
    this.filtros['operacion_detalle'] = ev
    this.factoringService.obtenerDetalles(this.operacion).then(res=>{
      this.operacionesDetalle = res['results']
    })
    this.externalFilter(undefined, 'operacion_detalle')
  }

}
