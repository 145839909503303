import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ClientesService } from "app/core/services/clientes/clientes.service";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { ConfirmModalComponent } from "app/shared/utils/confirm-modal/confirm-modal.component";
import { AddComercialCreditComponent } from "app/modules/clientes/modals/add-comercial-credit/add-comercial-credit.component";
import { AddOperationalCreditComponent } from "app/modules/clientes/modals/add-operational-credit/add-operational-credit.component";

@Component({
  selector: "app-antecedentes-credito-creditos-capital-trabajo",
  templateUrl: "./creditos-capital-trabajo.component.html",
  styleUrls: ["./creditos-capital-trabajo.component.css"],
})
export class CreditosCapitalTrabajoComponent implements OnInit {
  public rowDataTop: any[] = [];
  public rowDataBottom: any[] = [];
  public loadingTable: boolean = false;

  public columnDefsTop: columnHeader[];
  public columnDefsBottom: columnHeader[];

  public pageTableActualTop: number;
  public pageTableActualBottom: number;
  public pageTableTotalTop: number;
  public pageTableTotalBottom: number;
  public pageTablePerPagesTop: number = 10;
  public pageTablePerPagesBottom: number = 10;
  public pageTablePagesTop: number[];
  public pageTablePagesBottom: number[];

  public searchstringTop: string = "";
  public searchstringBottom: string = "";
  public moneda_deuda: number = 2;

  @Input() clienteId = 0;
  @Input() readonly = false;

  constructor(
    private clientesService: ClientesService,
    public modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.initVars();
    this.obtenerCreditosComerciales();
    this.obtenerCreditosOperaciones();
  }

  obtenerCreditosComerciales(page = 1, page_size = 10) {
    this.clientesService
      .obtenerCreditosComerciales(this.clienteId.toString(), page, page_size)
      .then((res) => {
        this.loadTable({ top: res });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  obtenerCreditosOperaciones(page = 1, page_size = 10) {
    this.clientesService
      .obtenerCreditosOperaciones(this.clienteId.toString(), page, page_size)
      .then((res) => {
        this.loadTable({ bottom: res });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadTable(data) {
    const { top, bottom } = data;

    if (top) {
      this.rowDataTop = top.results.map((el: any, i: number) => ({
        ...el,
        index: i + 1,
      }));

      this.pageTableActualTop = top.page_number;
      this.pageTableTotalTop = top.count;
      this.pageTablePerPagesTop = top.per_page;
      this.pageTablePagesTop = [];
      for (let index = 0; index < top.num_pages; index++) {
        this.pageTablePagesTop.push(index);
      }
    }

    if (bottom) {
      this.rowDataBottom = bottom.results.map((el: any, i: number) => ({
        ...el,
        index: i + 1,
      }));

      this.pageTableActualBottom = bottom.page_number;
      this.pageTableTotalBottom = bottom.count;
      this.pageTablePerPagesBottom = bottom.per_page;
      this.pageTablePagesBottom = [];
      for (let index = 0; index < bottom.num_pages; index++) {
        this.pageTablePagesBottom.push(index);
      }
    }
  }

  initVars() {
    let columnsTop = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Producto",
        field: "producto",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Entidad Financiera",
        field: "entidad_financiera_nombre",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Moneda",
        field: "moneda_descripcion",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Plazo Operación",
        field: "plazo",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Monto de Cuota",
        field: "monto",
        sortable: true,
        pipe: "currency",
        moneda: "moneda",
      },
      {
        class: "text-center",
        headerName: "Vencimiento Primera Cuota",
        field: "vencimiento_primera_cuota",
        sortable: true,
        pipe: "date",
      },
      {
        class: "text-center",
        headerName: "Vencimiento Última Cuota",
        field: "vencimiento_primera_cuota",
        sortable: true,
        pipe: "date",
      },
      {
        class: "text-center",
        headerName: "Situación",
        field: "situacion",
        sortable: true,
      },
    ];

    let columnsBottom = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Entidad Financiera",
        field: "entidad_financiera_nombre",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Límite de Linea Asignada",
        field: "linea_asignada",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Documento de Mayor Uso",
        field: "documento_mayor_uso",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Moneda",
        field: "moneda_descripcion",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Plazo Promedio de Operación",
        field: "plazo_promedio",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Monto Promedio de Operación",
        field: "monto_promedio",
        sortable: true,
        pipe: "currency",
        moneda: "moneda",
      },
      {
        class: "text-center",
        headerName: "Situación",
        field: "situacion",
        sortable: true,
      },
    ];

    this.columnDefsTop = columnsTop;
    this.columnDefsBottom = columnsBottom;
  }

  goToPage({ page, per_page, filtros }, form) {
    form === "top"
      ? this.obtenerCreditosComerciales(page, per_page)
      : this.obtenerCreditosOperaciones(page, per_page);
  }

  tableEditEvent(credit, form) {
    console.log(form);

    let modal =
      form === "top"
        ? AddComercialCreditComponent
        : AddOperationalCreditComponent;

    const modalRef = this.modalService.open(modal, {
      size: "lg",
    });

    modalRef.componentInstance.clienteId = this.clienteId;
    modalRef.componentInstance.creditId = credit.id;

    modalRef.componentInstance.successEmit.subscribe(
      (result) => {
        form === "top"
          ? this.obtenerCreditosComerciales()
          : this.obtenerCreditosOperaciones();
      },
      (error) => {}
    );
  }

  destroyContact(credit, form) {
    console.log(form);
    const modalRef = this.modalService.open(ConfirmModalComponent, {});

    modalRef.componentInstance.title = `Remover Crédito ${
      form === "top" ? "Comercial" : "Operaciones"
    } `;
    modalRef.componentInstance.message = `¿Seguro que desea remover el crédito ${
      form === "top" ? "comercial" : " de operaciones"
    }?`;

    modalRef.result.then((result) => {
      if (result) {
        this.clientesService[
          form === "top"
            ? "eliminarCreditoComercial"
            : "eliminarCreditoOperaciones"
        ](credit.id)
          .then((result) => {
            form === "top"
              ? this.obtenerCreditosComerciales()
              : this.obtenerCreditosOperaciones();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }

  openModal(form) {
    console.log(form);

    let modal =
      form === "top"
        ? AddComercialCreditComponent
        : AddOperationalCreditComponent;

    const modalRef = this.modalService.open(modal, {
      size: "lg",
    });

    modalRef.componentInstance.clienteId = this.clienteId;
    modalRef.componentInstance.successEmit.subscribe(
      (result) => {
        form === "top"
          ? this.obtenerCreditosComerciales()
          : this.obtenerCreditosOperaciones();
      },
      (error) => {}
    );
  }
}
