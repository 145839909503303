import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { AceptantesService } from 'app/core/services/factoring/aceptantes.service';

@Component({
  selector: 'app-add-proveedores',
  templateUrl: './add-proveedores.component.html',
  styleUrls: ['./add-proveedores.component.css']
})
export class AddProveedoresComponent implements OnInit {
  tipodoc_list: any = [];
  @Input() proveedor: any;
  myForm: FormGroup;
  faTimes = faTimes;
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild         : FormBuilder,
    public activeModal       : NgbActiveModal,
    public configs           : DocschecklistService,
    private aceptantesService: AceptantesService
  ) { 
    this.initForm();
  }

  ngOnInit(): void {
    this.configs.obtenerTipoDetalles().then((res: any)=>{
      this.tipodoc_list = res.results.sort(function (a, b) {
        if (a.descripcion > b.descripcion) {
            return 1;
        }
        if (a.descripcion < b.descripcion) {
            return -1;
        }
        return 0;
        });
      if (this.proveedor) {
        this.setForm(this.proveedor);
      }
    })

  }

  initForm() {
    this.myForm = this.formbuild.group({
      resenia   : [null,  []],
      tipos_detalles_  : [[], [Validators.required]],
      tipos_detalles  : [[], []],
    });
  }

  tipoDetallesChanged(value){
    if(!!value && value.length > 0){
      console.log(value);
      let filtered = value.map((id)=>{
        return this.tipodoc_list.find(item => item.id == id)
      })
      
      this.myForm.get('tipos_detalles_').setValue(filtered)
    }
  }

  setForm(form) {
    for (const key in form) {
      if (this.myForm.controls[key]) {
        if(key == 'tipos_detalles'){
          let filtered = form[key].map((item)=> item.id)
          this.myForm.controls['tipos_detalles_'].setValue(filtered)  
        }
        this.myForm.controls[key].setValue(form[key]);
      }
    }
  }

  onSubmit() {
    if (this.myForm.invalid) 
      return;

    let body = this.myForm.value

    body.tipos_detalles = body.tipos_detalles_.map((id)=>{
      return this.tipodoc_list.find(item => item.id == id)
    })

    delete body.tipos_detalles_

    this.aceptantesService.actualizarCliente(this.myForm.value, this.proveedor.aceptante)
    .then(res => {
      this.activeModal.close(false);
      this.successEmit.emit();
    }).catch(error => {
      console.error(error);
      
    });
  }

}
