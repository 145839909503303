import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ClientesService } from "app/core/services/clientes/clientes.service";

@Component({
  selector: "app-add-relation-provider",
  templateUrl: "./add-relation-provider.component.html",
  styleUrls: ["./add-relation-provider.component.css"],
})
export class AddRelationProviderComponent implements OnInit {
  public minDate: Date;
  public myForm: FormGroup;
  public relacion: any;
  faTimes=faTimes;
  @Input() clienteId = 0;
  @Input() relacionId = 0;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild: FormBuilder,
    public activeModal: NgbActiveModal,
    private clientesService: ClientesService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    if (this.relacionId) {
      this.obtenerRelacionProveedor();
    }
  }

  obtenerRelacionProveedor() {
    this.clientesService
      .obtenerRelacionProveedor(this.relacionId)
      .then((res) => {
        this.relacion = res;
        this.setForm(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre: [null, [Validators.required]],
      ruc: [null, [Validators.required, Validators.maxLength(50)]],
      telefono: [null, []],
      persona_contacto: [null, []],
      producto_servicio: [null, []],
      tiempo_relacion: [null, []],
      facturacion_anual: [null, []],
      facturacion_mensual: [null, []],
      porcentaje_soles: [null, []],
      porcentaje_dolares: [null, []],
      ventas_credito_porcentaje: [null, []],
      documento_pago: [null, []],
      plazo_promedio: [null, []],
    });
  }

  get f() {
    return this.myForm.controls;
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = this.myForm.value;
      data["cliente"] = this.clienteId;

      this.clientesService
        .guardarRelacionProveedor(this.myForm.value, this.relacionId)
        .then((res) => {
          this.successEmit.emit();
          this.activeModal.close(false);
        })
        .catch((error) => {
          this.activeModal.close(false);
        });
    }
  }
}
