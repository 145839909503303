import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './auth/auth.service';
import { ToastService } from './toast/toast.service';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { url } from 'inspector';
import { IObtenerCabecera, IObtenerCuentaPorCobrar, IObtenerDocumentosCartera, IObtenerExcedentes, IObtenerLiquidaciones } from './reportes.service.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportesService {

	private apiUrl : string = '';
	public cabecera : string = 'factoring/formato_documentos/deuda_pago/cabecera';
	public documentosEnCartera : string = 'factoring/carteras/';
	public cuentasPorCobrar : string = 'factoring/cuentas/';
	public excedentes : string = 'factoring/excedentes/';
	public aceptantes : string = "clientes/aceptantes/";
	public clientes : string = "clientes/clientes/";
	public calificaciones : string = "factoring/beneficiarios/calificacion/";
	public totalreport : string = 'factoring/total_report/';
	public roles : string = 'factoring/responsables/rol/'
	public responsables	: string = 'factoring/responsable_usuario/'
	public liquidaciones : string = 'factoring/liquidaciones/agrupar_por_operacion'

  	constructor(
    	public appService: AppService,
		public toast     : ToastService,
		public spinner   : NgxSpinnerService,
		public http      : HttpClient,
		public auth      : AuthService
	) {
			this.apiUrl = this.appService.settings.API_base_url
	}

  	/**
	* Habilita el loader para request a la API
	*/
	spinnerOn(){
		this.spinner.show()
	}

	/**
	* Desabilita el loader
	* @param mensaje Mensaje del toast
	* @param ok Tipo de mensaje, TRUE para success, FALSE para errores
	*/
	spinnerOff(mensaje: string = null, ok : boolean = true){
		this.spinner.hide()
		this.appService.notifyMe(mensaje,ok)

		if(mensaje && ok)
		this.toast.success(mensaje)
		if(mensaje && !ok)
		this.toast.warning(mensaje)
  }

  // URL = this.apiUrl+`factoring/formato_documentos/deuda_pago/cabecera/?entidad=cliente&moneda=1&tipo_cambio=1&codigo_entidad=57`
  obtenerCabecera(
		entidad        = '',
		moneda         = '',
		tipo_cambio    = '',
		codigo_entidad = '',
		aceptante__in  = '',
		cliente__in    = '',
		empresa    = '',
		tipo_linea    = '',
	  ){

		var URL = this.apiUrl+
			this.cabecera+
			`?entidad=${entidad}`+
			`&moneda=${moneda}`+
			`&tipo_cambio=${tipo_cambio}`+
			`&empresa=${empresa}`+
			`&codigo_entidad=${codigo_entidad}`+
			`&tipo_linea=${tipo_linea}`

		if(entidad == 'cliente' && aceptante__in != ''){
			URL+=`&aceptante__in=${aceptante__in}`;
		}

		if(entidad == 'aceptante' && cliente__in != ''){
			URL+=`&cliente__in=${cliente__in}`;
		}

		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}

	obtenerCabeceraAlt(data : IObtenerCabecera){
		var URL = this.apiUrl+
			this.cabecera+
			`?entidad=${data.entidad}`+
			`&moneda=${data.moneda}`+
			`&tipo_cambio=${data.tipo_cambio}`+
			`&empresa=${data.empresa}`+
			`&codigo_entidad=${data.codigo_entidad}`+
			`&tipo_linea=${data.tipo_linea}`

		if(data.entidad == 'cliente' && data.aceptante != ''){
			URL+=`&aceptante__in=${data.aceptante}`;
		}

		if(data.entidad == 'aceptante' && data.cliente != ''){
			URL+=`&cliente__in=${data.cliente}`;
		}

		return new Promise((resolve, reject) => {
			this.spinnerOn();
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				this.spinnerOff();
				resolve(data);
			}, (err) => {
				this.spinnerOff();
				reject(err);
			});

		});
	}
	
	obtenerCuentasPorCobrar(
		beneficiario = '',
		deudor       = '', 
		empresa      = '' ,
		tipo_linea   = '' ,
		estado__in   = '',
		page         = 1, 
		page_size    = 1000){
		const URL = this.apiUrl+
		this.cuentasPorCobrar+
		`?beneficiario=${beneficiario}`+
		`&deudor__in=${deudor}`+
		`&empresa=${empresa}`+
		`&tipo_linea=${tipo_linea}`+
		`&estado__in=${estado__in}`+
		`&page=${page}`+
		`&page_size=${page_size}`;

		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}

	obtenerCuentasPorCobrarReporte(
		cliente_linea = '',
		deudor       = '', 
		empresa      = '' ,
		tipo_linea   = '' ,
		estado__in   = '',
		page         = 1, 
		page_size    = 1000){
		const URL = this.apiUrl+
		this.cuentasPorCobrar+
		`?cliente_linea=${cliente_linea}`+
		`&deudor__in=${deudor}`+
		`&empresa=${empresa}`+
		`&tipo_linea=${tipo_linea}`+
		`&estado__in=${estado__in}`+
		`&page=${page}`+
		`&page_size=${page_size}`;

		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}


	obtenerCuentasPorCobrarReporteAlt( data : IObtenerCuentaPorCobrar ){
		data = {...data, 
			page: 1,
			page_size: 1000,
		}

		const URL = this.apiUrl +
			this.cuentasPorCobrar +
			(data.cliente_linea !== undefined ? `?cliente_linea=${data.cliente_linea || ""}` : "") +
			(data.beneficiario !== undefined ? `?beneficiario=${data.beneficiario || ""}` : "") +
			`&deudor__in=${data.deudor}` +
			`&empresa=${data.empresa}` +
			`&tipo_linea=${data.tipo_linea}` +
			`&estado__in=${data.estado}` +
			`&page=${data.page}` +
			`&page_size=${data.page_size}`;

		return new Promise((resolve, reject) => {
			this.spinnerOn();
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				this.spinnerOff();
				resolve(data);
			}, (err) => {
				this.spinnerOff();
				reject(err);
			});

		});
	}


	obtenerCalificacion(beneficiario = '', page = 1, page_size = 1000){
		const URL = this.apiUrl+
		this.calificaciones+
		`?beneficiario=${beneficiario}`+
		`&page=${page}`+
		`&page_size=${page_size}`;

		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}

	obtenerExcedentes(
		cliente       = '',
		aceptante__in = '',
		empresa       = '',
		tipo_linea    = '',
		page          = 1,
		page_size     = 1000){
		const URL = this.apiUrl+
		this.excedentes+
		`?cliente=${cliente}`+
		`&aceptante__in=${aceptante__in}`+
		`&empresa=${empresa}`+
		`&tipo_linea=${tipo_linea}`+
		`&estado=${1}`+
		`&page=${page}`+
		`&page_size=${page_size}`;

		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}

	obtenerExcedentesReporte(
		cliente_linea       = '',
		aceptante__in = '',
		empresa       = '',
		tipo_linea    = '',
		page          = 1,
		page_size     = 1000){
		const URL = this.apiUrl+
		this.excedentes+
		`?cliente_linea=${cliente_linea}`+
		`&aceptante__in=${aceptante__in}`+
		`&empresa=${empresa}`+
		`&tipo_linea=${tipo_linea}`+
		`&estado=${1}`+
		`&page=${page}`+
		`&page_size=${page_size}`;

		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}

	obtenerExcedentesReporteAlt(data : IObtenerExcedentes){		
		data = {...data, 
			estado: '1',
			page: 1,
			page_size: 1000,
		}
		const URL = this.apiUrl +
		this.excedentes +
		(data.cliente !== undefined ? `?cliente=${data.cliente || ""}` : "") +
		(data.cliente_linea !== undefined ? `?cliente_linea=${data.cliente_linea || ""}` : "") +
		`&aceptante__in=${data.aceptante}` +
		`&empresa=${data.empresa}` +
		`&tipo_linea=${data.tipo_linea}` +
		`&estado=${data.estado}` +
		`&page=${data.page}` +
		`&page_size=${data.page_size}`;

		return new Promise((resolve, reject) => {
			this.spinnerOn()
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				this.spinnerOff();
				resolve(data);
			}, (err) => {
				this.spinnerOff()
				reject(err);
			});

		});
	}

	obtenerLiquidacionesReporte(data : IObtenerLiquidaciones){		
		data = { ...data }
		const URL = this.apiUrl +
		this.liquidaciones +
		(data.cliente_codigo !== undefined ? `?cliente_codigo=${data.cliente_codigo || ""}` : "") +
		(data.aceptante_codigo !== undefined ? `?aceptante_codigo=${data.aceptante_codigo || ""}` : "") 

		return new Promise((resolve, reject) => {
			this.spinnerOn()
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				this.spinnerOff();
				resolve(data);
			}, (err) => {
				this.spinnerOff()
				reject(err);
			});
		});
	}


	obtenerTotalReport(
		nombre = '',
		url = '',
		page         = 1,
		page_size    = 10){
		const URL = this.apiUrl+
		this.totalreport+
		`?nombre=${nombre}`+
		`&url=${url}`+
		`&page=${page}`+
		`&page_size=${page_size}`;
		console.log(URL)
		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}

	obtenerEvaluaciones(
		page: number = 1,
		page_size: number = 10,
	  ) {
		  const url = this.apiUrl + this.totalreport +
					  `?page_size=${page_size}` + `&page=${page}`;
	
		  return new Promise((res, ref) => {
			this.spinnerOn();
	  
			this.http.get(url).subscribe(
			  (response) => {
				this.spinnerOff();
				res(response);
			  },
			  (err) => {
				this.spinnerOff("La petición falló", false);
				ref(err);
			  }
			);
		  });
	  }

	obtenerDocumentosEnCartera(
		beneficiario = '',
		deudor       = '',
		estado       = '1,3',
		empresa      = '',
		tipo_linea      = '',
		page         = 1,
		page_size    = 1000){
		const URL = this.apiUrl+
		this.documentosEnCartera+
		`?beneficiario=${beneficiario}`+
		`&deudor__in=${deudor}`+
		`&estado__in=${estado}`+
		`&empresa=${empresa}`+
		`&tipo_linea=${tipo_linea}`+
		`&page=${page}`+
		`&page_size=${page_size}`;

		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}

	obtenerDocumentosEnCarteraReporte(
		cliente_linea = '',
		deudor       = '',
		estado       = '1,3',
		empresa      = '',
		tipo_linea      = '',
		page         = 1,
		page_size    = 1000){
		const URL = this.apiUrl+
		this.documentosEnCartera+
		`?cliente_linea=${cliente_linea}`+
		`&deudor__in=${deudor}`+
		`&estado__in=${estado}`+
		`&empresa=${empresa}`+
		`&tipo_linea=${tipo_linea}`+
		`&page=${page}`+
		`&page_size=${page_size}`;

		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}

	obtenerDocumentosEnCarteraReporteAlt(data : IObtenerDocumentosCartera){
		data = {...data,
			page: 1,
			page_size: 1000
		}

		const URL = this.apiUrl +
		this.documentosEnCartera +
		(data.cliente_linea !== undefined ? `?cliente_linea=${data.cliente_linea || ""}` : "" ) + 
		(data.beneficiaro !== undefined ? `?beneficiario=${data.beneficiaro || ""}` : "") +
		`&deudor__in=${data.deudor}` +
		`&estado__in=${data.estado}` +
		`&empresa=${data.empresa}` +
		`&tipo_linea=${data.tipo_linea}` +
		`&page=${data.page}` +
		`&page_size=${data.page_size}`;

		return new Promise((resolve, reject) => {
			this.spinnerOn();
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				this.spinnerOff();
				resolve(data);
			}, (err) => {
				this.spinnerOff();
				reject(err);
			});
		});
	}

	obtenerAceptantesObservable(page: number = 1, page_size: number = 10, nombre = ""): Observable<any> {
		const url =
		  this.apiUrl +
		  this.aceptantes +
		  `?nombre__icontains=${nombre}` +
		  `&page_size=${page_size}` +
		  `&page=${page}`;

		  return this.http
		  .get<any>(url)
		  .pipe(map(resp => {
			  if (resp.Error) {
				  throwError(resp.Error);
			  } else {
				  return resp.results;
			  }
		  })
	  );
	}


	obtenerReporteValidacion(
		fecha_pago__gte = '',
		fecha_pago__lte = '',
		page = 1,
		page_size = 10){
			
		const URL = this.apiUrl+`factoring/recaudaciones/reporte-cobranza-validacion/`+
		`?fecha_pago__gte=${fecha_pago__gte}`+
		`&fecha_pago__lte=${fecha_pago__lte}`+
		`&page=${page}`+
		`&page_size=${page_size}`;


		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}

	/**
	 * Retorna los clientes
	 * @param page Pagina del request
	 * @param page_size Resultados por pagina
	 * @param oficial_negocio__icontains
	 * @param nombre__icontains
	 * @param sector
	 * @param ruc__icontains
	 */
	obtenerClientesObservable(
		page: number = 1,
		page_size: number = 10,
		ruc__icontains: string = "",
		nombre__icontains: string = "",
		sector: string = "",
		oficial_negocio__icontains: String = ""
	): Observable<any> {
		const url =
		this.apiUrl +
		this.clientes +
		`?oficial_negocio_nombre__icontains=${oficial_negocio__icontains}` +
		`&nombre__icontains=${nombre__icontains}` +
		`&sector=${sector}` +
		`&ruc__icontains=${ruc__icontains}` +
		`&page_size=${page_size}` +
		`&page=${page}`;

        return this.http
            .get<any>(url)
            .pipe(map(resp => {
                if (resp.Error) {
                    throwError(resp.Error);
                } else {
                    return resp.results;
                }
            })
		);

		// return new Promise((res, ref) => {
		// this.spinnerOn();

		// this.http.get(url).subscribe(
		// 	(response) => {
		// 	this.spinnerOff();
		// 	res(response);
		// 	},
		// 	(err) => {
		// 	this.spinnerOff("La operación falló", false);
		// 	ref(err);
		// 	}
		// );
		// });
	}

	obtenerRolesUsuario(page: number = 1, page_size: number = 1000) {
		const url = this.apiUrl + this.roles + `?page_size=${page_size}`;// + `&page=${page}`;

		return new Promise((res, ref) => {
		this.spinnerOn();
		this.http.get(url).subscribe(
			(response) => {
			this.spinnerOff();
			res(response);
			},
			(err) => {
			this.spinnerOff();
			ref(err);
			}
		);
		});
	}

	//MODIFICAR EL page_size DE ACUERDO A LA CANTIDAD DE REGISTROS QUE SE OBTIENE DE:
	//select COUNT(*) from factoring_qa.factoring.fact_responsable fr where activo = true and estado = true;
	obtenerResponsablesUsuarios(page: number = 1, page_size: number = 300) {
		const url = this.apiUrl + this.responsables + `?page_size=${page_size}&estado=true`;
		//const url = this.apiUrl + this.responsables + `?page=${page}&`+`?page_size=${page_size}&estado=true`;// + `&page=${page}`;

		return new Promise((res, ref) => {
		this.spinnerOn();
		this.http.get(url).subscribe(
			(response) => {
			this.spinnerOff();
			res(response);
			},
			(err) => {
			this.spinnerOff();
			ref(err);
			}
		);
		});
	}
  	
}
