import { Component, OnInit, ViewChild } from '@angular/core';
import { columnHeader, FactoringDatatableComponent } from "app/shared/factoring-datatable/factoring-datatable.component";
import { AuthService } from "app/core/services/auth/auth.service";
import { DocumentosCarteraService } from "app/core/services/factoring/documentos-cartera.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ActivatedRoute, Router } from '@angular/router';
import { DocumentosCarteraModalComponent } from '../../components/documentos-cartera-modal/documentos-cartera-modal.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { SharedFunctions } from 'app/core/shared/functions';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { functions } from 'app/core/helpers/functions';
import { ModalConfirCorreosComponent } from '../modal-confir-correos/modal-confir-correos.component';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { utils, writeFile } from 'xlsx';
import Swal from 'sweetalert2';
//import { PdfMakerService } from 'app/core/services/pdf-maker.service';
import pdfMake from 'pdfmake/build/pdfmake';
import { DomSanitizer } from '@angular/platform-browser';

export interface ITotalizaciones {
  soles: number, 
  dolares: number, 
}

@Component({
  selector: 'app-documentos-cartera',
  templateUrl: './documentos-cartera.component.html',
  styleUrls: ['./documentos-cartera.component.css']
})
export class DocumentosCarteraComponent implements OnInit {
  faSyncAlt=faSyncAlt;
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = "";
  public canCreate: boolean = false;
  public tmpData: any;
  public cliente: any;
  public aceptante: any;
  public tableSelectable: boolean = false;
  public selectionRows: any[];
  public dateFilter: any[];
  public responsables: any[];
  public todoSelect: boolean = false;
  public preselected: any[] = [];
  public oficial:any;
  public lider:any;
  public generando: boolean = false;
  public generando_pdf: boolean = false;
  public totalizacionData : ITotalizaciones = { soles: 0, dolares: 0 };
  public totalSaldo : ITotalizaciones = { soles: 0, dolares: 0 };

  @ViewChild(DocumentosCarteraModalComponent) documentoCarteraModal: DocumentosCarteraModalComponent;
  @ViewChild(FactoringDatatableComponent) tablaDocsRef: FactoringDatatableComponent;

  constructor(
    public modalService: NgbModal,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    public localService: LocalServiceService,
    public authService: AuthService,
    public auxFunctions: SharedFunctions,
    public documentosCarteraService: DocumentosCarteraService,
    private factoringService: FactoringService,
    private sanitizer : DomSanitizer
  ) {
    this.activatedRoute.params.subscribe(param => {

      this.cliente = param.cliente
      this.aceptante = param.aceptante

      this.oficial = param.oficial
      this.lider = param.lider

    })

  }

  ngOnInit(): void {
    this.user = this.authService.user;

    let data = this.localService.getJsonValue("RedirectFromJudicial")
    if (data) {
      let obj = {
        page: 1,
        per_page: 10,
        filtros: {
          beneficiario   : data.cliente_nombre ? data.cliente_nombre : '',
          aceptante      : data.aceptante_nombre ? data.aceptante_nombre : '',
          oficial_negocio: this.user.isOficialDeNegocio ? this.user.id : '',
        },
      }

      setTimeout(() => {
        this.goToPage(obj)
        this.localService.setJsonValue("RedirectFromJudicial", null)
      }, 3000)
    }
    this.loadRedirectionFilters()
    this.obtenerDocumentosCartera();
  }

  toggleText(){
    this.todoSelect = !this.todoSelect
    this.toggleCheckAll()
  }

  toggleCheckAll(){
    this.selectionRows = null
      if(this.todoSelect){
          this.preselected = this.rowData.map((item) => { return item.id })
          this.selectionRows = this.rowData
      } else {
          this.preselected = []
      }

    console.log(this.todoSelect,this.preselected.length)
  }

  evalCheck(){
    this.todoSelect = this.selectionRows.length == this.rowData.length
  }
  loadTotalizaciones(data){
    console.info("totales",data)
    this.totalizacionData = {
        soles: data['monto']['S/'] || 0,
        dolares: data['monto']['US$'] || 0
    }
    this.totalSaldo = {
        soles: data['desembolso']['S/'] || 0,
        dolares: data['desembolso']['US$'] || 0
    }
}


  async loadRedirectionFilters() {

    let user = this.authService.userIn

    let dataFromDashboard = this.localService.getJsonValue("DashCarterasVencidas")
    console.log( dataFromDashboard )

    if (this.oficial || this.lider) {

      let user_ = this.localService.getJsonValue('user');
        this.dateFilter = [this.auxFunctions.parseDate(dataFromDashboard.fecha_vencimiento__gte), this.auxFunctions.parseDate(dataFromDashboard.fecha_vencimiento__lte)]
        await  this.documentosCarteraService
          .obtenerDocumentosCartera(
            1,
            10,
            '',
            '',
            '',
            '',
            '1,3',
            '',
            dataFromDashboard.fecha_vencimiento__gte ? dataFromDashboard.fecha_vencimiento__gte : '',
            dataFromDashboard.fecha_vencimiento__lte ? dataFromDashboard.fecha_vencimiento__lte : '',
            '',
            this.oficial != 'o' ? this.oficial : '',
            // this.authService.userIn.isOficialDeNegocio ? user.id.toString() : '',
            '',
            '',
            this.lider != 'l' ? this.lider : '',
            // this.authService.userIn.isLiderDeEquipo ? user.id.toString() : '',
            user_.id,
            user_.perfil
          )
          .then((documents: any) => {
            this.loadTable(documents);
          })

          await this.documentosCarteraService
          .obtenerDocumentosCarteraTotales(
            1,
            10,
            '',
            '',
            '',
            '',
            '1,3',
            '',
            dataFromDashboard.fecha_vencimiento__gte ? dataFromDashboard.fecha_vencimiento__gte : '',
            dataFromDashboard.fecha_vencimiento__lte ? dataFromDashboard.fecha_vencimiento__lte : '',
            '',
            this.oficial != 'o' ? this.oficial : '',
            // this.authService.userIn.isOficialDeNegocio ? user.id.toString() : '',
            '',
            '',
            this.lider != 'l' ? this.lider : '',
            // this.authService.userIn.isLiderDeEquipo ? user.id.toString() : '',
            user_.id,
            user_.perfil
          )
          .then((totales: any) => {
            console.log({totales})
            console.log("aca1")
              this.loadTotalizaciones({ 
                monto: {
                    "S/": totales.monto[1]? parseFloat(totales.monto[1]) : .0,
                    "US$": totales.monto[2]? parseFloat(totales.monto[2]) : .0,
                },
                desembolso: {
                    "S/": totales.saldo[1] ? parseFloat(totales.saldo[1] ) : .0,
                    "US$": totales.saldo[2]  ? parseFloat(totales.saldo[2] ) : .0,
                }
              })
            //this.loadTable(documents);
          })
    }
  }

  /**
   *
   * @param page
   * @param page_size
   */
  obtenerDocumentosCartera(cliente = this.cliente, aceptante = this.aceptante) {
    let estados  = this.documentosCarteraService.obtenerEstadosCarteras();
    let empresas = this.factoringService.obtenerEmpresas();
    let lineas   = this.factoringService.obtenerTiposDeLineas();
		let oficialesNegocio = this.factoringService.obtenerResponsables(3);

    // No es necesario obtenerlos ya que al haber un defaultValue simula un click en un filtrar y los obtiene. Si lo agrego tambien aca hace dos llamadas
    Promise.all([
      estados, 
      empresas, 
      lineas,
      oficialesNegocio]).then(
      (res: any[]) => {
        let estados = res[0]['results'];
        let empresas = res[1]['results'];
        let lineas = res[2]['results']
        this.initVars(estados, empresas, lineas,res[3]['results']);
      }
    );
  }

  loadTable(data) {

    this.rowData = functions.indextable( data )
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  enableSelectable() {
    this.tableSelectable = !this.tableSelectable
  }

  resultadosSelection(seleccion) {
    this.selectionRows = seleccion
    this.evalCheck()
  }

  enviarRecordatorios() {
    this.lanzarModalConfirmacion(this.selectionRows)
  }

  lanzarModalConfirmacion(filas: any[]) {
    const modalRef = this.modalService.open(ModalConfirCorreosComponent, {size: 'xl'});
    modalRef.componentInstance.seleccionRecordatorios = filas
    let idsdocumentos = filas.map((item) => item.id);

  }

  initVars(estados, empresas, lineas,oficiales) {
    console.log(this.authService.user, 'nuevo')
    let idOficiales = this.user.equipo.map((item)=> item.id)
    let filtrados = oficiales.filter((item)=>  { return idOficiales.includes(item.id) || item.id == this.user.id })
    let modenas = [{"id":1,"nombre":"Soles"},{"id":2,"nombre":"Dolares"}]
    let columnaOficial =       {
      headerName: 'Oficial de Negocio',
      field: 'oficial_negocio_nombre',
      filterProp: 'oficial_negocio',
      sortable: true,
      class: 'text-center',
      filterable: true,
      filterSelectItems: filtrados,
      filterItemsProps: {
        value: 'id',
        label: 'nombre'
      },
    }

    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        headerName: 'Tipo de Producto',
        field: 'tipo_producto_descripcion',
        class: 'text-center',
        sortable: true,
        filterable: true,
        filterProp: 'tipo_linea',
        filterSelectItems: lineas,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "beneficiario_nombre",
        sortable: true,
        filterable: this.cliente ? false : true,
        filterProp: "beneficiario",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "beneficiario_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
        sortable: true,
        filterable: this.aceptante ? false : true,
        filterProp: "aceptante",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "aceptante_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "operacion_tipo_detalle_descripcion",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Nro Documento",
        field: "operacion_numero_documento",
        sortable: true,
        filterable: true,
        filterProp: "operacion_numero_documento",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Fch. Vcto",
        field: "fecha_vencimiento",
        pipe: "date",
        sortable: true,
        filterable: true,
        filterDate: true,
      },
      {
        headerName: 'Moneda',
        field: 'moneda',
        class: 'text-center',
        sortable: true,
        pipe: 'function',
        function: function (row) {
          return (row.moneda == 1) ? 'Soles' : 'Dolares';
        },
        filterable: true,
        filterSelectItems: modenas,
        filterItemsProps: {
          value: 'id',
          label: 'nombre'
        },
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto_neto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14,19, 18],
        filterable: true,
        filterRange: true,
        sumCol: true,
        sumColFn: () =>  {
                    const htmlString = `
                        <div class="d-flex flex-column">
                            <div class="d-flex align-items-center justify-content-between mx-2 mt-1">
                                <h6 class="m-0 p-0">S/. </h6>
                                <p class="m-0 p-0 ml-2">${Number(this.totalizacionData.soles).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mx-2 mb-1">
                                <h6 class="m-0 p-0">$ </h6>
                                <p class="m-0 p-0 ml-2">${Number(this.totalizacionData.dolares).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
                            </div>
                        </div>
                    `
                    return this.sanitizer.bypassSecurityTrustHtml(htmlString)
                },
      },
      {
        class: "text-center",
        headerName: "Saldo X Pagar",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        filterRange: true,
        perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14,19, 18],
        sumCol: true,
        sumColFn: () =>  {
                    const htmlString = `
                        <div class="d-flex flex-column">
                            <div class="d-flex align-items-center justify-content-between mx-2 mt-1">
                                <h6 class="m-0 p-0">S/. </h6>
                                <p class="m-0 p-0 ml-2">${Number(this.totalSaldo.soles).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
                            </div>
                            <div class="d-flex align-items-center justify-content-between mx-2 mb-1">
                                <h6 class="m-0 p-0">$ </h6>
                                <p class="m-0 p-0 ml-2">${Number(this.totalSaldo.dolares).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
                            </div>
                        </div>
                    `
                    return this.sanitizer.bypassSecurityTrustHtml(htmlString)
                },
      },
      {
        headerName: 'Estado',
        field: 'estado_descripcion',
        filterProp: 'estado',
        sortable: true,
        class: 'text-center',
        filterable: true,
        filterSelectItems: estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
        defaultValue: {
          id: 1,
          descripcion: 'Vigente'
        }
      },
      {
        headerName: 'Operación ',
        field: 'operacion',
        pipe: 'actionable',
        textField: 'operacion',
        class: 'text-center',
        sortable: true,
        filterable: true,
        filterProp: 'operacion',
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Prórroga",
        field: "dias_prorroga",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Asistente Cobranzas",
        field: "asistente_cobranzas_nombre",
        sortable: true,
        filterable: true,
        filterProp: "asistente_cobranzas",
        filterInput: true,
      },
    ];


    if(this.user.isLiderDeEquipo){
      columns.splice(7,0,columnaOficial)
    }
    this.columnDefs = columns;
  }

  tableEditEvent(row: any = this.tmpData) {
    let comentarios    = this.documentosCarteraService.obtenerComentariosDocumento(row.id)
    let bandejaRecaudo = this.documentosCarteraService.obtenerBandejaRecaudo(row.id)
    let prorrogas      = this.documentosCarteraService.obtenerBandejaProrroga(row.id)
    let cabecera       = this.documentosCarteraService.obtenerCabeceraCartera(row.id)

    this.tmpData = row
    Promise.all([comentarios, bandejaRecaudo, prorrogas, cabecera]).then((response: any[]) => {
      let comentarios = response[0].results.map((comentario) => {
        comentario.fecha = this.parseDate(comentario.fecha)
        return comentario
      })
      let bandejaRecaudos = response[1].results
      let prorrogas = response[2].results
      let cabecera = response[3]
      this.documentoCarteraModal.initModal(
        cabecera,
        comentarios,
        bandejaRecaudos,
        prorrogas)
    })
  }

  nuevoComentario(event: string) {
    let user = this.authService.user
    let commentBody = {
      documento_cartera: this.tmpData.id,
      comentario: event,
      responsable: user.id,
      responsable_nombre: user.nombreCompleto,
      fecha: new Date().toISOString()
    }
    this.documentosCarteraService.crearComentarioDocumento(commentBody).then((result) => {
      return this.documentosCarteraService.obtenerComentariosDocumento(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.parseDate(comentario.fecha)
        return comentario
      })
      this.documentoCarteraModal.refrescaComentarios(comentarios)
    })
  }

  editaComentario(event) {
    let hoy = new Date();

    event.fecha = hoy.toISOString()
    this.documentosCarteraService.editarComentarioDocumento(event, event.id).then((result) => {
      return this.documentosCarteraService.obtenerComentariosDocumento(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.parseDate(comentario.fecha)
        return comentario
      })
      this.documentoCarteraModal.refrescaComentarios(comentarios)
    })
  }

  eliminaComentario(event) {
    this.documentosCarteraService.eliminarcomentarioDocumento(event, event.id).then((result) => {
      return this.documentosCarteraService.obtenerComentariosDocumento(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.parseDate(comentario.fecha)
        return comentario
      })
      this.documentoCarteraModal.refrescaComentarios(comentarios)
    })
  }

  parseDate(fecha) {
    let convertedDate = fecha.split('T')
    convertedDate.push('UTC')
    convertedDate = convertedDate.join(' ')
    convertedDate = new Date(convertedDate).toLocaleString()
    return convertedDate
  }

  workflowEvent(row) {
    this.router.navigate(['/operaciones', row.operacion]);
  }

  async goToPage({ page, per_page, filtros }) {

    const range = (filtros.valueFrom && filtros.valueTo) ? `${filtros.valueFrom},${filtros.valueTo}` : '';

    if( this.oficial == undefined || this.lider == undefined  ){
      let user_ = this.localService.getJsonValue('user');
      await this.documentosCarteraService
        .obtenerDocumentosCartera(
          page,
          per_page,
          filtros.beneficiario,
          filtros.aceptante,
          filtros.operacion_numero_documento,
          range,
          filtros.estado,
          filtros.operacion,
          filtros.fecha__gte ? this.auxFunctions.cleanDate(filtros.fecha__gte) : '',
          filtros.fecha__lte ? this.auxFunctions.cleanDate(filtros.fecha__lte) : '',
          filtros.asistente_cobranzas,
          this.user.isOficialDeNegocio && !this.user.isLiderDeEquipo 
            ? this.user.id 
            : (filtros.oficial_negocio ? filtros.oficial_negocio : (this.user.isOficialDeNegocio ? this.user.id : '')),
          filtros.empresa,
          filtros.tipo_linea,
          this.user.isLiderDeEquipo ? this.user.id : '',
          user_.id,
          user_.perfil
        )
        .then((documents: any) => {
          this.loadTable(documents);
        }).catch(error => {
          const data = {
            count: 0,
            next: null,
            num_pages: 1,
            page_number: 1,
            per_page: 10,
            previous: null,
            results: []
          }

          this.loadTable(data);
        });

        await this.documentosCarteraService
          .obtenerDocumentosCarteraTotales(
            1,
            10,
            '',
            '',
            '',
            '',
            '1,3',
            '',
            filtros.fecha__gte ? this.auxFunctions.cleanDate(filtros.fecha__gte) : '',
            filtros.fecha__lte ? this.auxFunctions.cleanDate(filtros.fecha__lte) : '',
            '',
            // this.oficial != 'o' ? this.oficial : '',
            this.user.isOficialDeNegocio && !this.user.isLiderDeEquipo 
            ? this.user.id 
            : (filtros.oficial_negocio ? filtros.oficial_negocio : (this.user.isOficialDeNegocio ? this.user.id : '')),
            '',
            '',
            this.lider != 'l' ? this.lider : '',
            // this.authService.userIn.isLiderDeEquipo ? user.id.toString() : '',
            user_.id,
            user_.perfil
          )
          .then((totales: any) => {
            console.log({totales})
            console.log(totales.monto[1])
            console.log("aca2")
            //this.loadTable(documents);
              this.loadTotalizaciones({ 
                monto: {
                    "S/": totales.monto[1]? parseFloat(totales.monto[1]) : .0,
                    "US$": totales.monto[2]? parseFloat(totales.monto[2]) : .0,
                },
                desembolso: {
                    "S/": totales.saldo[1] ? parseFloat(totales.saldo[1] ) : .0,
                    "US$": totales.saldo[2]  ? parseFloat(totales.saldo[2] ) : .0,
                }
              })
            })

    }else{

      let dataFromDashboard = this.localService.getJsonValue("DashCarterasVencidas")
      let user_ = this.localService.getJsonValue('user');
      await this.documentosCarteraService.obtenerDocumentosCartera(
        page,
        per_page,
        '',
        '',
        '',
        '',
        '1,3',
        '',
        // filtros.fecha__gte,
        // filtros.fecha__lte,
        dataFromDashboard.fecha_vencimiento__gte,
        dataFromDashboard.fecha_vencimiento__lte,
        '',
        this.oficial != 'o' ? this.oficial : '',
        '',
        '',
        this.lider != 'l' ? this.lider : '',
        user_.id,
        user_.perfil
      ).then(res => {

        this.loadTable(res);
      })
        .catch(error => {
          const data = {
            count: 0,
            next: null,
            num_pages: 1,
            page_number: 1,
            per_page: 10,
            previous: null,
            results: []
          }

          this.loadTable(data);
        });

        await this.documentosCarteraService
          .obtenerDocumentosCarteraTotales(
            1,
            10,
            '',
            '',
            '',
            '',
            '1,3',
            '',
            filtros.fecha__gte ? this.auxFunctions.cleanDate(filtros.fecha__gte) : '',
            filtros.fecha__lte ? this.auxFunctions.cleanDate(filtros.fecha__lte) : '',
            '',
            this.oficial != 'o' ? this.oficial : '',
            // this.authService.userIn.isOficialDeNegocio ? user.id.toString() : '',
            '',
            '',
            this.lider != 'l' ? this.lider : '',
            // this.authService.userIn.isLiderDeEquipo ? user.id.toString() : '',
            user_.id,
            user_.perfil
          )
          .then((totales: any) => {
            console.log({totales})
            console.log("aca3")
              this.loadTotalizaciones({ 
                monto: {
                    "S/": totales.monto[1]? parseFloat(totales.monto[1]) : .0,
                    "US$": totales.monto[2]? parseFloat(totales.monto[2]) : .0,
                },
                desembolso: {
                    "S/": totales.saldo[1] ? parseFloat(totales.saldo[1] ) : .0,
                    "US$": totales.saldo[2]  ? parseFloat(totales.saldo[2] ) : .0,
                }
              })
            //this.loadTable(documents);
          })

    }

  }

  guardarCambios(body) {
    this.documentosCarteraService.modificarCabecera(body, this.tmpData.id).then((res) => {
      console.log(body)
    })
  }

  get showVencimientoMasivoButton() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilAnalistaRiesgosID,
      this.authService.perfil.perfilJefeDeAdmisionID,
      this.authService.perfil.perfilAsesorLegalID,
      this.authService.perfil.perfilLegalID,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilSubGerenciaComercialID,
      this.authService.perfil.perfilTesoreriaID,
      this.authService.perfil.perfilLiderDeTesoreriaID,
      this.authService.perfil.perfilRecepcionID,
      this.authService.perfil.perfilAsistenteCobranzasID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  async openExcelModal() {
    const result = await Swal.fire({
      title: 'Generar Excel',
      text: "Elige si deseas generar el excel de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    console.log(result)
    if (result.isDenied) {
      this.generarExcel(this.rowData)
    } else if(result.isConfirmed) {
      this.generarExcelCompleto()
    }
  }

  async generarExcelCompleto() {
    const page_size = 100 //5000
    let page = 1
    let data = []

    this.generando = true
    while (this.generando) {
      try {
        let user_ = this.localService.getJsonValue('user');
        let dataFromDashboard = this.localService.getJsonValue("DashCarterasVencidas")
        //console.log({user_})
        let res = await this.documentosCarteraService.obtenerDocumentosCartera(
          page,
          page_size,
          '',
          '',
          '',
          '',
          '1,3',
          '',
          // filtros.fecha__gte,
          // filtros.fecha__lte,
          '',
          '',
          '',
          this.oficial != 'o' ? this.oficial : '',
          '',
          '',
          this.lider != 'l' ? this.lider : '',
          user_.id,
          user_.perfil
        )

        data = data.concat (res['results'] )
        page++

        if (res['page_number'] == res['num_pages']) {
          this.generando = false
          this.generarExcel(data)
        }
      } catch (ex) {
        console.log(ex)
        this.generando = false
      }
    }
  }

  generarExcel(data) {
    console.log({data})
    /* generate a worksheet */
    var ws = utils.json_to_sheet(this.newData( data ));

    /* add to workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hoja 1");

    /* write workbook and force a download */
    writeFile(wb, `Reporte - ${new Date().toLocaleString()}.xlsx`);
  }

  newData(data){

    let newDatos = [];
    //console.log({data})
    newDatos = data.map( item=>({
      tipo_producto: item.tipo_producto_descripcion,
      beneficiario_nombre: item.beneficiario_nombre,
      beneficiario_ruc: item.beneficiario_ruc,
      aceptante_nombre: item.aceptante_nombre, 
        aceptante_ruc: item.aceptante_ruc, 
      operacion_tipo_detalle_descripcion: item.operacion_tipo_detalle_descripcion, 
      operacion_numero_documento: item.operacion_numero_documento, 
      fecha_vencimiento: item.fecha_vencimiento, 
      monto_neto: item.monto_neto, 
      saldo: item.saldo, 
      estado: (item.estado ==1?'Vigente':''), 
      operacion: item.operacion, 
      dias_prorroga: item.dias_prorroga, 
      asistente_cobranzas_nombre: item.asistente_cobranzas_nombre, 
    }))

    return newDatos;

  }

  async openPdfModal() {
    const result = await Swal.fire({
      title: 'Generar Pdf',
      text: "Elige si deseas generar el pdf de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    //console.log(result)
    if (result.isDenied) {
      this.generarPdf(this.rowData)
    } else if(result.isConfirmed) {
      this.generarPdfCompleto()
    }
  }

  async generarPdfCompleto() {
    const page_size = 100 //5000
    let page = 1
    let data = []

    this.generando_pdf = true
    while (this.generando_pdf) {
      try {
        let user_ = this.localService.getJsonValue('user');
        let dataFromDashboard = this.localService.getJsonValue("DashCarterasVencidas")
        //console.log({user_})
        let res = await this.documentosCarteraService.obtenerDocumentosCartera(
          page,
          page_size,
          '',
          '',
          '',
          '',
          '1,3',
          '',
          // filtros.fecha__gte,
          // filtros.fecha__lte,
          '',
          '',
          '',
          this.oficial != 'o' ? this.oficial : '',
          '',
          '',
          this.lider != 'l' ? this.lider : '',
          user_.id,
          user_.perfil
        )

        data = data.concat (res['results'] )
        page++

        if (res['page_number'] == res['num_pages']) {
          this.generando_pdf = false
          this.generarPdf(data)
        }
      } catch (ex) {
        console.log(ex)
        this.generando_pdf = false
      }
    }
  }

  generarPdf(data) {
    data = this.newDataPdf(data)
    //console.log({data})
    //src\assets\img\factoring\logo_2.png
    //const img_logo = '@src\\assets\\img\\factoring\\logo_2.png' //./../../assets/img/factoring/logo_2.png'
    const docDefinition = {
      pageOrientation: 'landscape',
      content: [
        { text:'TOTAL SERVICIOS FINACIEROS',style: 'title_'},
        { text: 'Documentos en cartera', style: 'header' },
        { table: { headerRows: 1,body: data }}
      ],
      styles: {
        title_: { fontSize: 20, bold: true, margin: [0, 0, 0, 10] },
        header: { fontSize: 13, bold: true, margin: [0, 0, 0, 10] },
        tableHeader: { bold: true,fontSize: 8,color: 'black',},
        tableColumn: { fontSize: 6.5,},
      }
    };
      pdfMake.createPdf(docDefinition).download('Reporte_Documento_cartera.pdf');
    //this.pdf.open(docDefinition)
  }


  newDataPdf(data){

    let newDatos = [];
    //console.log({data})
    newDatos.push([ { text: 'Tipo Producto', style: 'tableHeader' },
      { text: 'Beneficiario', style: 'tableHeader' },
      { text: 'Ruc', style: 'tableHeader' },
      { text: 'Aceptante', style: 'tableHeader' },
        { text: 'Ruc', style: 'tableHeader' }, 
      { text: 'Tipo Operacion', style: 'tableHeader' }, 
      { text: 'Nrdocumento', style: 'tableHeader' }, 
      { text: 'Fecha Venc.', style: 'tableHeader' }, 
      { text: 'Monto Neto', style: 'tableHeader' }, 
      { text: 'Saldo', style: 'tableHeader' }, 
      { text: 'Estado', style: 'tableHeader' }, 
      { text: 'Operacion', style: 'tableHeader' }, 
      { text: 'Dias Prorroga', style: 'tableHeader' }, 
      { text: 'Asistente Crobranza', style: 'tableHeader' }])
   /*  newDatos = data.map( item=>({
      tipo_producto_descripcion: item.tipo_producto_descripcion,
      beneficiario_nombre: item.beneficiario_nombre,
      aceptante: item.beneficiario_ruc,
        aceptante_ruc: item.aceptante_ruc, 
      aceptante_nombre: item.aceptante_nombre, 
      operacion_tipo_detalle_descripcion: item.operacion_tipo_detalle_descripcion, 
      operacion_numero_documento: item.operacion_numero_documento, 
      fecha_vencimiento: item.fecha_vencimiento, 
      monto_neto: item.monto_neto, 
      saldo: item.saldo, 
      estado: item.estado, 
      operacion: item.operacion, 
      dias_prorroga: item.dias_prorroga, 
      asistente_cobranzas_nombre: item.asistente_cobranzas_nombre, 
    })) */
    data.forEach(element => {
      newDatos.push([
        { text: element.tipo_producto_descripcion.toString(), style: 'tableColumn' },
        { text: element.beneficiario_nombre.toString(), style: 'tableColumn' },
        { text: element.beneficiario_ruc.toString(), style: 'tableColumn' },
        { text: element.aceptante_nombre.toString(), style: 'tableColumn' },
        { text: element.aceptante_ruc.toString(), style: 'tableColumn' },
        { text: element.operacion_tipo_detalle_descripcion.toString(), style: 'tableColumn' },
        { text: element.operacion_numero_documento.toString(), style: 'tableColumn' },
        { text: element.fecha_vencimiento.toString(), style: 'tableColumn' },
        { text: element.monto_neto.toString(), style: 'tableColumn' },
        { text: element.saldo.toString(), style: 'tableColumn' },
        { text: (element.estado ==1?'Vigente':''), style: 'tableColumn' },
        { text: element.operacion.toString(), style: 'tableColumn' },
        { text: element.dias_prorroga.toString(), style: 'tableColumn' },
        { text: element.asistente_cobranzas_nombre.toString(), style: 'tableColumn' },
      ])
        
    });
    //newDatos.concat(data)
    return newDatos;

  }
}
