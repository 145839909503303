import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { CuentasService } from '../../../../core/services/cuentas-x-cobrar/cuentas.service';
import { Router } from '@angular/router';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { filter, distinctUntilChanged, debounceTime, tap, switchMap, catchError } from "rxjs/operators";

@Component({
  selector: 'app-cuentas-x-pagar',
  templateUrl: './cuentas-x-pagar.component.html',
  styleUrls: ['./cuentas-x-pagar.component.css']
})
export class CuentasXPagarComponent implements OnInit {
  faTimes = faTimes
  public headerDocumentsCarteraTable: columnHeader[];
  public documentsCarteraList: any[] = [];
  public documentsCarteraSelectionList: any[] = [];
  @Input() beneficiarioId: any = null;
  @Input() deudorId: any = null;
  @Input() empresa: any = null;
  @Input() recaudacion: any = null;
  @Input() documentosPagar: any[] = [];

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  pagadores$: Observable<any>
  pagadoresInput$ = new Subject<string>()
  pagadoresLoading = false
  otroPropietario = undefined

  constructor(
    public activeModal: NgbActiveModal,
    private recaudacionService: RecaudacionService,
    private cuentasService : CuentasService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initTables();

    this.loadCuentas();

    this.loadPagadores() // Buscador depagadores al seleccionar cxc para aplicar
  }

  loadCuentas(queryParams:object={}) {
    let payload = {
      beneficiario: '', // !!this.beneficiarioId ? this.beneficiarioId : '',
      deudor: '', // !!this.deudorId ? this.deudorId : ''
    };

    queryParams['pagador_cliente'] = this.beneficiarioId || ''
    queryParams['pagador_aceptante'] = this.deudorId || ''

    if (this.otroPropietario) {
      if (this.otroPropietario.tipo == 1) { // ACEPTANTE
        queryParams['pagador_aceptante'] = this.otroPropietario.id
      }
      if (this.otroPropietario.tipo == 2) { // CLIENTE
        queryParams['pagador_cliente'] = this.otroPropietario.id
      }
    }

    // Agregar orden
    queryParams['ordering'] = `${this.recaudacion?.moneda == 1 ? '' : '-'}moneda,-saldo`
    this.recaudacionService.obtenerCuentasPorCobrar(payload, '1', this.empresa, 1, 1000, queryParams)
      .then(res => {
        let filteredList = res['results']
        if (this.documentosPagar.length > 0) {
          this.documentosPagar.filter(e => e.cuenta_cobrar !== null).forEach(e => {
            filteredList = filteredList.filter(x => x.id !== e.cuenta_cobrar)
          })
        }
        this.documentsCarteraList = filteredList
      })
      .catch(error => {
        console.error(error);
      });
  }

  initTables() {
    let headerDocumentsCarteraTable:any = [
      {
        headerName: "N°",
        field: "id",
        sortable: true,
        pipe: 'indexcol'
      },
      {
        class: "text-center",
        headerName: "Producto",
        field: "tipo_producto_descripcion",
        sortable: true,
        filterable: true,
        filterProp: "tipo_linea",
        filterSelectItems: [
          // Obtener desde backend, por servicio de lineas, no existe servicio
          // Crear método para obtener, pero lleva a BOILERPLATE CODE, se deja en hardcode
          {id: 1, descripcion: 'Descuento'},
          {id: 2, descripcion: 'Factoring'},
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "beneficiario_nombre",
        sortable: true,
        filterable: true,
        filterProp: "beneficiario_ruc_nombre__icontains",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Operación",
        field: "operacion_detalle_id",
        sortable: true,
        filterable: true,
        filterProp: "operacion_id_detalle__icontains",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Concepto",
        field: "concepto_descripcion",
        sortable: true,
        filterable: true,
        filterProp: "concepto",
        filterSelectItems: [
          // Obtener desde backend, por servicio de lineas, no existe servicio
          // Crear método para obtener, pero lleva a BOILERPLATE CODE, se deja en hardcode
          {id: -2, concepto_descripcion: 'Intereses Mora'},
          {id: 8, concepto_descripcion: 'Comisión de prórroga'},
          {id: -3, concepto_descripcion: 'Intereses prórroga'},
          {id: 1, concepto_descripcion: 'Financiado'},
          {id: 11, concepto_descripcion: 'Comisión Protesto'},
          {id: 6, concepto_descripcion: 'Legales'},
          {id: 3, concepto_descripcion: 'Custodia'},
        ],
        filterItemsProps: {
          value: 'concepto',
          label: 'concepto_descripcion'
        },
      },
      {
        headerName: "Nro°",
        field: "id",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "IGV",
        field: "igv",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Total",
        field: "total",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Saldo",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      // {
      //   class: "text-center",
      //   headerName: "Prorroga", 
      //   field: "fecha_prorroga", // Field será el campo del json en el servicio
      // },
      // {
      //   class: "text-center",
      //   headerName: "Fecha Liquidacion",
      //   field: "liquidacion_fecha",
      //   pipe: "date",
      //   sortable: true,
      // },
    ];

    // if( this.router.url.split('/')[1] == 'excedentes' ){
    //   headerDocumentsCarteraTable.push({
    //     class: "text-center",
    //     headerName: "Tipo cambio.",
    //     field: "tipo_cambio_monto",
    //     pipe:  "input_camb",
    //     sortable: true,
    //   })
    // }

    this.headerDocumentsCarteraTable = headerDocumentsCarteraTable;
  }

  goToPageDocumentsCartera({page, per_page, filtros}) {
    this.loadCuentas(filtros)
  }

  //TIPO DE CAMBIO MODIFICADO
  async tipoCambio( data:any ){
    console.log( 'DESDE CXC!!', data )
    await this.cuentasService.modificarTipoCambio( data.id, { tipo_cambio_monto: data.tipo_cambio_monto } )

  }

  selectedItem(row) {
    const id = row.id;

    const findRow = this.documentsCarteraSelectionList.find(item => item.id == id);

    console.log(row, findRow);

    if (findRow) {
      this.documentsCarteraSelectionList = this.documentsCarteraSelectionList.filter(
        (item) => item.id !== id
      );
    } else {
      this.documentsCarteraSelectionList.push(row);
    }

    // if (this.documentsCarteraSelectionList.indexOf(id) === -1)
    //   this.documentsCarteraSelectionList.push(row);
    // else
    //   this.documentsCarteraSelectionList = this.documentsCarteraSelectionList.filter(
    //     (item) => item !== id
    //   );
  }

  sendCuentasList() {
    this.successEmit.emit(this.documentsCarteraSelectionList);
    this.activeModal.close();
  }

  onChangeCheckBuscarOtros(checked) {
    if (!checked && this.otroPropietario) this.onChangePropietario(undefined)
  }

  onChangePropietario(propietario) {
    this.otroPropietario = propietario
    this.goToPageDocumentsCartera({ page: 1, per_page: 9999, filtros: {}})
  }

  private loadPagadores() {
    this.pagadores$ = concat(
      of([]), // default items
      this.pagadoresInput$.pipe(
        filter((res) => {
          return res !== null
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.pagadoresLoading = true),
        switchMap(term => this.recaudacionService.getPagadores({ 'ruc_nombre': term, 'tipo': this.recaudacion.tipo_pagador }, false).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.pagadoresLoading = false)
        ))
      )
    )
  }

}
