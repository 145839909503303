import { Component, OnInit } from '@angular/core';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { ManageTiposFirmasContratoModalComponent } from '../modals/manage-tipos-firmas-contrato-modal/manage-tipos-firmas-contrato-modal.component';
import { functions } from '../../../core/helpers/functions';

@Component({
  selector: 'app-tipos-firma-contratos',
  templateUrl: './tipos-firma-contratos.component.html',
  styleUrls: ['./tipos-firma-contratos.component.css']
})
export class TiposFirmaContratosComponent implements OnInit {
   faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public user             : any;
	public identidad        : any     = {};
	public searchstring     : string = '';
	public canCreate: boolean = false;

  constructor(
    private configurationService  : ConfigurationService,
    public modalService           : NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerTiposFirmas();
  }

  loadTable(data) {
    this.rowData = functions.indextable( data )
    // this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }
    
  initVars() {
    let columns = [
      {
        headerName: 'N°', 
        field     : 'index' ,
        sortable  : true,
      },
      {
        headerName: 'Descripción', 
        field     : 'descripcion',
        class     : 'text-center',
        sortable  : true,
        // filterable: true,
        // filterProp: 'descripcion',
        // filterInput: true,
      },
      {
        headerName    : 'Monto Legal', 
        field         : 'monto_legal',
        class         : 'text-center',
        sortable      : true,
        pipe          : 'currency_detail',
        moneda_detail : 1
      },
      {
        headerName    : 'Monto Natural', 
        field         : 'monto_natural',
        class         : 'text-center',
        sortable      : true,
        pipe          : 'currency_detail',
        moneda_detail : 1
      },
    ];
  
    this.columnDefs = columns;
  }

  obtenerTiposFirmas(page = 1, page_size = 10) {
    let tiposFirmantes = this.configurationService.obtenerTiposFirmantes(page, page_size);

    Promise.all([tiposFirmantes]).then((res :any[])=>{
      let tiposFirmantes  = res[0];
      this.initVars();
      this.loadTable(tiposFirmantes);
    });
  }

  crear() {
    const modalRef = this.modalService.open(ManageTiposFirmasContratoModalComponent, {
    });

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerTiposFirmas();
		},
		error => {
      console.error(error);
      
		});
  }

  tableEditEvent(row) {
    const modalRef = this.modalService.open(ManageTiposFirmasContratoModalComponent, {
    });

    modalRef.componentInstance.tiposFirmantesId = row.id;

    modalRef.componentInstance

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerTiposFirmas();
		},
		error => {

		});
  }

  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Tipo de Firma';
		modalRef.componentInstance.message = '¿Seguro que desea remover el Tipo de Firma?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.configurationService.elimiarTiposFirmas(row.id)
			.then(result => {
			this.obtenerTiposFirmas();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  goToPage({page,per_page,filtros}) {
    this.obtenerTiposFirmas(page, per_page);
  }
 
}
