import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";


import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { ArchwizardModule } from "angular-archwizard";
import { SharedModule } from "app/shared/shared.module";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { UiSwitchModule } from "ngx-ui-switch";
import { AlertasComponent } from "./pages/alertas/alertas.component";
import { LavadoActivosRoutingModule } from "./lavado-activos-routing.module";
import { VariablesRiesgoComponent } from "./pages/variables_riesgo/variables_riesgo.component";
import { ConfiguracionVariablesRiesgoComponent } from "./pages/configuracion_variables_riesgo/configuracion_variables_riesgo.component";
import { ManageConfiguracionModalComponent } from "./pages/configuracion_variables_riesgo/modals/manage/manage-configuracion-modal.component";
import { ModalVariableRiesgoComponent } from "./pages/variables_riesgo/modals/modal_variable_riesgo.component";
import { ModalAlertasComponent } from "./pages/alertas/modals/modal-alertas/modal-alertas.component";
import { ReportesComponent } from "./pages/reportes/reportes.component";
import { MatricesRiesgoComponent } from "./pages/matrices-riesgo/matrices-riesgo.component";
import { PeriodosComponent } from "./pages/periodos/periodos.component";
import { ModalsReporteComponent } from './pages/reportes/modals-reporte/modals-reporte.component';
import { ConfiguracionGeneralComponent } from './configuracion-general/configuracion-general.component';
import { ModalConfiguracionComponent } from './configuracion-general/modal-configuracion/modal-configuracion.component';
import { ModalMatricesRiesgoComponent } from './pages/matrices-riesgo/modals/modal-matrices-riesgo/modal-matrices-riesgo.component';
import { ModalElementosMatrizRiesgoComponent } from './pages/matrices-riesgo/modals/modal-elementos-matriz-riesgo/modal-elementos-matriz-riesgo.component';
import { ZonasRiesgoComponent } from './pages/zonas-riesgo/zonas-riesgo.component';
import { ModalZonaRiesgoComponent } from './pages/zonas-riesgo/modals/modal-zona-riesgo/modal-zona-riesgo.component';
import { ModalImportarComponent } from './pages/alertas/modals/modal-importar/modal-importar.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    MatricesRiesgoComponent,
    ModalAlertasComponent,
    ReportesComponent,
    AlertasComponent,
    VariablesRiesgoComponent,
    PeriodosComponent,
    ConfiguracionVariablesRiesgoComponent,
    ManageConfiguracionModalComponent,
    ModalVariableRiesgoComponent,
    ModalsReporteComponent,
    ConfiguracionGeneralComponent,
    ModalConfiguracionComponent,
    ModalMatricesRiesgoComponent,
    ModalElementosMatrizRiesgoComponent,
    ZonasRiesgoComponent,
    ModalZonaRiesgoComponent,
    ModalImportarComponent,
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    LavadoActivosRoutingModule,
    FontAwesomeModule,
    SharedModule,
    JwBootstrapSwitchNg2Module,
    NgbModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    ArchwizardModule
  ],
})
export class LavadoActivosModule {}
