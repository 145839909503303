import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { LegalService } from 'app/core/services/legal.service';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-formato-adenda-modal',
  templateUrl: './formato-adenda-modal.component.html',
  styleUrls: ['./formato-adenda-modal.component.css']
})
export class FormatoAdendaModalComponent implements OnInit {

  myForm: FormGroup;
  estaciones: any[];
  public firmantes: any[] = [];
  firmantesSelect: any[] = [];

  @Input() title;
  @Input() beneficiario: number;
  @Input() beneficiario_tipo;
  @Input() formato_codigo;
  @Input() formato_plantilla_url;
  @Input() numero_contrato;
  @Input() montoOperacion;
  @Input() monedaOperacion;
  @Input() monedaSimbolo;

  @Input() operacion;
  @Input() contrato;

  cliente: any;
  lineas: any[] = [];
  linea: any;

  constructor(
    private modalService      : NgbModal,
    public activeModal        : NgbActiveModal,
    private formBuilder       : FormBuilder,
    private formatoService    : FormatosService,
    private clientesService   : ClientesService,
    public toast              : ToastService,
    private propuestaService  : PropuestaComercialService,
    private legalService	    : LegalService,
  ) { }

  async ngOnInit() {
    this.createForm();

    await this.clientesService.obtenerLinea(this.operacion.linea_credito).then(resp => {
      console.log(resp);
      this.linea = resp;
    }, err => {
      console.error(err);
    });

    this.legalService.obtenerFirmantes(this.contrato.id).then(resp => {
      console.log(resp);
      this.firmantes = resp['results'];
    }, err => {
      console.error(err);
      
    });

    this.clientesService.obtenerCliente(this.linea.beneficiario).then((cliente: any) => {
      this.cliente = cliente;
    });

    this.obtenerHistorico();
  }

  createForm() {

    this.myForm = this.formBuilder.group({
      firmante: [null, []],
      emision: [{ value: new Date(), disabled: true }, Validators.required],
      beneficiario_tipo: [{ value: this.beneficiario_tipo == undefined ? 'J' : this.beneficiario_tipo, disabled: true }, Validators.required]
    });

  }

  obtenerHistorico() {
    this.clientesService.obtenerLineasHstorico(this.linea.beneficiario.toString())
    .then(res => {
      console.log(res);
      this.lineas = res['results'];
    }).catch(error => {
      console.error(error);
      
    });
  }

  mapperAdendas(index, linea) {
    switch (index) {
      case 1:
        return {
          "a1_fecha": linea.fecha_creacion + 'Z', 
          "a1_monto_linea": linea.linea_actual, 
          "a1_moneda_linea": (linea.moneda == 1) ? 'S/.' : '$',
          "a1_contrato_id": linea.numero_contrato
        }
        break;

      case 2:
        return {
          "a2_fecha": linea.fecha_creacion + 'Z', 
          "a2_monto_linea": linea.linea_actual, 
          "a2_moneda_linea": (linea.moneda == 1) ? 'S/.' : '$',
          "a2_contrato_id": linea.numero_contrato
        }
        break;

      case 3:
        return {
          "a3_fecha": linea.fecha_creacion + 'Z', 
          "a3_monto_linea": linea.linea_actual, 
          "a3_moneda_linea": (linea.moneda == 1) ? 'S/.' : '$',
          "a3_contrato_id": linea.numero_contrato
        }
        break;
    
      default:
        break;
    }
  }

  onSubmit() {

    if (!this.firmantes.length) {
      this.toast.warning('No posee firmantes');
      this.activeModal.close(false);
      return;
    }

    if (!this.lineas.length) {
      this.toast.warning('No posee lineas');
      this.activeModal.close(false);
      return;
    }

    const firmantes = this.firmantes.map(f => {
      return {
        id              : f.id,
        dni             : f.nro_documento,
        estado_civil    : f.estado_civil,
        nombre_completo : f.firmante_nombre,
        tipo_documento  : f.tipo_documento,
        sexo            : f.sexo,
        gentilicio      : f.gentilicio,
        lugar_firma     : f.lugar_firma
      }
    });

    let adendas = this.lineas.filter(a => a.tipo_historico == 3);
    console.log(adendas);
    if (adendas.length == 3) {
      if(adendas[0]) {
        adendas[0] = this.mapperAdendas(1, adendas[0])
      }

      if(adendas[2]) {
        adendas[2] = this.mapperAdendas(2, adendas[2])
      }

      if(adendas[1]) {
        adendas[1] = this.mapperAdendas(3, adendas[1])
      }
    } 
    else if (adendas.length == 2) {
      if(adendas[0]) {
        adendas[0] = this.mapperAdendas(1, adendas[0])
      }

      if(adendas[1]) {
        adendas[1] = this.mapperAdendas(2, adendas[1])
      }
    } else if (adendas.length == 1) {
      if(adendas[0]) {
        adendas[0] = this.mapperAdendas(1, adendas[0])
      }
    }

    
    // adendas = adendas.map((a, i) => {
    //   if(adendas[0]) {
    //     return this.mapperAdendas(i + 1, a)
    //   }

    //   if(adendas[1]) {
    //     return this.mapperAdendas(i + 1, a)
    //   }

    //   if(adendas[2]) {
    //     return this.mapperAdendas(i + 1, a)
    //   }
    // });

    console.log(adendas, 'adenas');

    const data = {
      beneficiario: this.linea.beneficiario,
      archivo_plantilla: this.formato_plantilla_url,
      fecha_actual: this.myForm.controls['emision'].value,
      nombre: this.cliente.nombre,
      ruc: this.cliente.ruc,
      domicilio: this.cliente.domicilio_legal,
      departamento: this.cliente.departamento_nombre,
      provincia: this.cliente.provincia_nombre,
      distrito: this.cliente.distrito_nombre,
      nro_partida: this.cliente.numero_partida,
      firmantes: firmantes,
      lugar_registro: this.firmantes.find(f => f.representante_legal).lugar_firma,

      monto_linea_previo  : this.lineas[1].linea_actual,
      monto_linea_nuevo   : this.lineas[0].linea_actual,
      moneda_linea        : (this.lineas[0].moneda == 1) ? 'S/.' : '$',

      ...adendas[0],
      ...adendas[1],
      ...adendas[2],
    }

    console.log(data);

    this.clean(data);
    data.firmantes.forEach(element => {
      this.clean(element);
    });

    this.formatoService.generarAdendaContrato(data).then(
      (x: any) => {
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Adenda.docx";
        link.click();
        this.activeModal.close(data);
      }, async (reason) => {
        this.activeModal.close(data);
        console.log(reason);
        const message = JSON.parse(await reason.error.text());
        let msg = '';
        let i = 0;
        for (const iterator in message) {
          msg += message[iterator] + ' ';
          i++;
        }
        console.log(message);
        this.showModalErrorContrato(msg);
      });
  }

  showModalErrorContrato(message) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {

    });

    modalRef.componentInstance.showBottomLeft = false;
    modalRef.componentInstance.title = 'Información requerida en adenda contrato';
    modalRef.componentInstance.titleRightBottom = 'Aceptar';
    modalRef.componentInstance.messageHTML = true;
    modalRef.componentInstance.message = `<span class="">${message}</span>`;
  }

  clean(obj) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

}
