import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
  selector: 'app-resultado-facturas-modal',
  templateUrl: './resultado-facturas-modal.component.html',
  styleUrls: ['./resultado-facturas-modal.component.css']
})
export class ResultadoFacturasModalComponent implements OnInit {

  public reporteForm : FormGroup;
	public headerTable: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 50;
	public pageTablePages: number[];
  public searchstring: string = '';
  rowData: any;
  filtros: any = {};
 
  errorFilter: undefined
  resultadoFilter: undefined

  public operacionesDetalle = []
  public operacionDetalleSelected = []
    
  @Input() proceso : any
  @Input() resultado : any

  constructor( 
    public documentosCarteraService: DocumentosCarteraService,
    private factoringService: FactoringService,
    public formbuild: FormBuilder,
    public authService: AuthService,
    public modalService: NgbModal
  ) {}


  ngOnInit(): void {
    // Cargo tabla
    this.loadTableColumns()
    this.getCavaliFacturas({page:1, per_page:this.pageTablePerPages, filtros:{ operacion_detalle: this.operacionDetalleSelected }})
  }


  loadTableColumns() {
    this.headerTable = [
      {
        headerName: 'ID',
        class: 'text-center',
        field: 'id',
        sortable: true
      },
      {
        headerName: 'Nro. Documento',
        class: 'text-center',
        field: 'numero_documento',
        sortable: true
      },
      {
        headerName: 'Operación',
        class: 'text-center',
        field: 'operacion',
        sortable: true
      },
      {
        headerName: 'Procesado',
        class: 'text-center',
        field: 'procesado',
        sortable: true,
        pipe: 'checkbox',
        checkDisabled: true
      },
      {
        headerName: 'RUC Proveedor',
        class: 'text-center',
        field: 'provider_ruc',
        sortable: true
      },
      {
        headerName: 'Proveedor',
        class: 'text-center',
        field: 'series',
        sortable: true
      },
      {
        headerName: 'Numeración',
        class: 'text-center',
        field: 'numeration',
        sortable: true
      },
      {
        headerName: 'RUC Aceptante',
        class: 'text-center',
        field: 'acq_ruc',
        sortable: true
      },
      {
        headerName: 'Aceptante',
        class: 'text-center',
        field: 'acq_full_name',
        sortable: true
      },
      // {
      //   headerName: 'result_code',
      //   class: 'text-center',
      //   field: 'result_code',
      //   sortable: true
      // },
      {
        headerName: 'Resultado',
        class: 'text-center',
        field: 'result_message',
        sortable: true
      },
      // {
      //   headerName: 'invoice_state',
      //   class: 'text-center',
      //   field: 'invoice_state',
      //   sortable: true
      // },
      {
        headerName: 'Estado',
        class: 'text-center',
        field: 'invoice_state_label',
        sortable: true
      },
    ]
  }

  parseData(data) {
    return data.map(el=>{
      return {
        id: el?.id,
        numero_documento: el?.numero_documento,
        operacion: el?.operacion,
        operacion_detalle: el?.operacion_detalle,
        procesado: el?.procesado,
        provider_ruc: el?.provider_ruc,
        series: el?.series,
        numeration: el?.numeration,
        acq_ruc: el?.acq_ruc,
        acq_full_name: el?.acq_full_name,
        result_code: el?.result_code,
        result_message: el?.result_message,
        invoice_state: el?.invoice_state,
        invoice_state_label: el?.invoice_state_label,
        transfer_account_date: el?.transfer_account_date,
      }
    })
  }
 
  loadTable(data) {
		this.rowData = this.parseData(data.results)
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  getCavaliFacturas({page, per_page, filtros}) {
    console.log(filtros)
    this.factoringService.getCavaliFacturas(
      page, 
      per_page,
      this.resultado,
      this.proceso
      ).then(res=>{
        this.loadTable(res)
    })
  }

  goToPage({page, per_page, filtros}){
    this.filtros = filtros
    this.getCavaliFacturas({page, per_page, filtros})
	}

}
