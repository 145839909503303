import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder, ControlContainer } from '@angular/forms';

export interface SelectInput { 
  id: number;
  default_value: any;
  label: string;
  validator_name: string;
  classes: string;
  formParent: FormGroup;
  placeholder: string;
  is_disabled: boolean;
  items:  SelectItem[];
  item_selected?: SelectItem;
}

export type SelectItem = { 
  label: string;
  id: number;
  value: any;
}

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.css']
})
export class FormSelectComponent implements OnInit {
  public _search_string: string = '';
  public result: any[] = [];

  @Input() selectInput: SelectInput;

  @Output() getSelectInput: EventEmitter<SelectInput> = new EventEmitter<SelectInput>();

  

  constructor() {}

  ngOnInit(): void {
    this.addControlContent();
    this.searchItem('');
    this.selectInput.formParent.get('search_string').valueChanges.subscribe((value)=>{
      this.searchItem(value)
    })
    this.SendSelectInput()
  }
  
  newSearch(){
    this.selectInput.formParent.get('search_string').setValue('') 
  }
  
  addControlContent(){
    this.selectInput.formParent.addControl('search_string', new FormControl('', Validators.required));
  }


  searchItem(query: string = ''){
    if (query != '') {
      this.result = [];
      this.selectInput.items.forEach(item => {
        if (item.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          this.result.push(item);
        }
      });
    }else{
      this.result = this.selectInput.items;
    }
  }

  setItem(item: SelectItem){
    this.selectInput.item_selected = item;
    this.selectInput.formParent.controls[this.selectInput.validator_name].setValue(item.label) 
    this.SendSelectInput()
  }

  SendSelectInput(){
    this.getSelectInput.emit(this.selectInput)
  }
}
