import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { BloquearModalComponent } from 'app/shared/bloquear-modal/bloquear-modal.component';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { AddClienteComponent } from '../add-cliente/add-cliente.component';
import { functions } from '../../../../core/helpers/functions';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {
	faPlus=faPlus;
	faSyncAlt=faSyncAlt;
	faChartPie=faChartPie;
  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public user             : any;
	public identidad        : any     = {};
	public searchstring     : string = '';
	public canCreate: boolean = false;

  constructor(
	private clientesService	: ClientesService,
	private router          : Router,
    private modalService: NgbModal,
	private authService : AuthService

  ) { }

  ngOnInit(): void {
    this.obtenerClientes();
  }

  /**
   * 
   * @param page  
   * @param page_size 
   * @param nombre__icontains 
   * @param sector 
   * @param ruc__icontains 
   */
  obtenerClientes(
    page              = 1,
	page_size            = 10,
	ruc__icontains       = '',
    nombre__icontains = '',
    sector            = '',
    oficial_negocio__icontains = '',
  ) {
      let clientes  = this.clientesService.obtenerClientes(page, page_size, ruc__icontains, nombre__icontains, sector, oficial_negocio__icontains);
      let sectores  = this.clientesService.obtenerSector();

      Promise.all([clientes,sectores]).then((res :any[])=>{
        let clientes  = res[0];
        let sectores  = res[1]['results'];
        this.initVars(sectores);
        this.loadTable(clientes);
      });
  }

  loadTable(data) {
	this.rowData = functions.indextable( data )
	// this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
		this.pageTablePages.push(index)
	}
  }
  
  initVars(sectores) {
    let columns = [
		{
			headerName: 'N°', 
			field     : 'index' ,
			sortable  : true,
		},
		{
			headerName: 'RUC ', 
			field     : 'ruc',
			class     : 'text-center',
			sortable  : true,
			filterable: true,
			filterProp: 'ruc',
			filterInput: true,
		},
		{
			headerName: 'Cliente ', 
			field     : 'nombre',
			class     : 'text-center',
			sortable  : true,
			filterable: true,
			filterProp: 'nombre',
			filterInput: true,
		},
		// {
		// 	headerName: 'Oficial de Negocios ', 
		// 	field     : 'oficial_negocio_nombre',
		// 	class     : 'text-center',
		// 	sortable  : true,
		// 	filterable: true,
		// 	filterProp: 'oficial_negocio_nombre',
		// 	filterInput: true,
		// },
		{
			headerName: 'Sector', 
			field     : 'sector_nombre',
			filterProp: 'sector',
			sortable  : true,
			class     : 'text-center',
			filterable: true,
			filterSelectItems: sectores,
			filterItemsProps: {
				value: 'id',
				label: 'nombre'
			},
		},
		{
			headerName: 'Inicio Operaciones', 
			field     : 'inicio_operaciones',
			pipe      : 'date',
			class     : 'text-center',
		},
		// {
		// 	headerName: 'Fecha Vcto. Contrato', 
		// 	field     : 'fecha_vencimiento_contrato',
		// 	pipe      : 'date',
		// 	class     : 'text-center',
		// },
	];

	this.columnDefs = columns;
  }

  goToPage({page,per_page,filtros}) {
	this.obtenerClientes(page, per_page, filtros.ruc, filtros.nombre, filtros.sector, filtros.oficial_negocio_nombre);
  }

  tableEditEvent(cliente) {
	this.router.navigate(['/clientes', cliente.id]);
  }

  crear(){
	this.router.navigate(['/add-clientes']);
  }

  get canAdd() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilOficialDeNegocioID,
      this.authService.perfil.perfilLegalID,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaRiesgosID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilAsistenteCobranzasID,
      this.authService.perfil.perfilAsesorLegalID,
      this.authService.perfil.perfilJefeDeAdmisionID,
      this.authService.perfil.perfilLiderDeEquipo,
	  this.authService.perfil.perfilAsistenteComercialID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }
}
