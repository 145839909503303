import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { AddDirectorieComponent } from 'app/modules/clientes/modals/add-directorie/add-directorie.component';
import { AddShareholdersComponent } from 'app/modules/clientes/modals/add-shareholders/add-shareholders.component';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-directories',
  templateUrl: './directories.component.html',
  styleUrls: ['./directories.component.css']
})
export class DirectoriesComponent implements OnInit {

  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
  public searchstring     : string = '';

  @Input() clienteId = 0;
  @Input() readonly = false;

  constructor(
    private clientesService	: ClientesService,
    public modalService     : NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerDirectores();
  }

  obtenerDirectores(page = 1, page_size = 10) {
    this.clientesService.obtenerDirectores(this.clienteId.toString(), page, page_size)
    .then(res => {
      this.initVars();
			this.loadTable(res);
    })
    .catch(error => {
      console.error(error);
    })
  }

  loadTable(data) {

		this.rowData           = data.results;
		this.pageTableActual   = data.page_number;
		this.pageTableTotal    = data.count;
		this.pageTablePerPages = data.per_page;
		this.pageTablePages = [];
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index);
		}

	}

  initVars() {
		let columns = [
			{
				headerName: 'N° Doc.', 
				field     : 'id' ,
				sortable  : true,
			},
			{
				headerName: 'Directores', 
				field     : 'nombre' ,
				sortable  : true,
			},
			{
				headerName: 'Documento de Identidad', 
				field     : 'dni' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Cargo', 
				field     : 'cargo' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Nacionalidad', 
				field     : 'nacionalidad_nombre' ,
				sortable  : true,
			},
      {
				headerName: 'Fecha de Ingreso', 
				field     : 'fecha_ingreso',
        class     : 'text-center',
        pipe      : 'date'
			},
		];

		this.columnDefs = columns;
	}

  goToPage({page,per_page,filtros}) {
    this.obtenerDirectores(page, per_page);
  }

  tableEditEvent(director) {
    const modalRef = this.modalService.open(AddDirectorieComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;
		modalRef.componentInstance.directorId = director.id;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerDirectores();
		},
		error => {

		});
  }

  destroyContact(director) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Director';
		modalRef.componentInstance.message = '¿Seguro que desea remover el Director?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.clientesService.elimiarDirector(director.id)
			.then(result => {
			this.obtenerDirectores();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  openModal() {
    const modalRef = this.modalService.open(AddDirectorieComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;

		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerDirectores();
		},
		error => {

		});
  }

}
