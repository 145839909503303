import { Component, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
import { ModalConfiguracionComponent } from "./modal-configuracion/modal-configuracion.component";
import { BaseLavadoActivosComponent } from "../base-lavado-activos-component";
@Component({
  selector: 'app-configuracion-general',
  templateUrl: './configuracion-general.component.html',
  styleUrls: ['./configuracion-general.component.css']
})
export class ConfiguracionGeneralComponent extends BaseLavadoActivosComponent implements OnInit {
  public editModal = ModalConfiguracionComponent
  public requestService = (filters) => this.lavadoActivosService.obtenerConfiguraciones(filters)
  constructor(
    public modalService: NgbModal,
    public authService: AuthService,
    private lavadoActivosService: LavadoActivosService,
  ) { 
    super(authService, modalService)
  }
  initVars(){
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Descripción",
        field: "descripcion",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Código",
        field: "codigo",
        sortable: true,
      },
    ]
    this.columnDefs = columns;
  }
}
