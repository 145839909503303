import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

import { ParametrosService } from 'app/core/services/configuration/parametros.service';

@Component({
  selector: 'app-criterios-riesgos',
  templateUrl: './criterios-riesgos.component.html',
  styleUrls: ['./criterios-riesgos.component.css']
})
export class CriteriosRiesgosComponent implements OnInit {
  criterios = []

  constructor(
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
    private parametrosService: ParametrosService,
  ) { }

  ngOnInit(): void {
    this.spinner.show()
    this.parametrosService.obtenerParametros({'parametro__contains': 'OP_SIN_RIESGO', 'page_size': 9999}).subscribe(
      (data: any) => {
        data.results
        this.criterios = [
          {descripcion: 'Límite exposición aceptante', valor: 'Superar límite de exposición', input: null, esPorcentaje: false},
          {codigo: 'OP_SIN_RIESGO_MORA_ACEPTANTE', descripcion: 'Comportamiento de pago aceptante (días)', valor: null, input: 'text', esPorcentaje: false},
          {codigo: 'OP_SIN_RIESGO_MORA_CLIENTE', descripcion: 'Comportamiento de pago cliente (días)', valor: null, input: 'text', esPorcentaje: false},
          {descripcion: 'Linea cliente', valor: 'Incremento de linea actual', input: null, esPorcentaje: false},
          {codigo: 'OP_SIN_RIESGO_TEM_MIN_SOLES', descripcion: 'TEM minima soles (%)', valor: null, input: 'text', esPorcentaje: true},
          {codigo: 'OP_SIN_RIESGO_TEM_MIN_DOLARES', descripcion: 'TEM minima dolares (%)', valor: null, input: 'text', esPorcentaje: true},
          {codigo: 'OP_SIN_RIESGO_COSTO_ESTRUCTURACION_MIN', descripcion: 'Costo de Estructuración mínima (%)', valor: null, input: 'text', esPorcentaje: true},
          {codigo: 'OP_SIN_RIESGO_MONTO_OPERACION', descripcion: 'Monto máximo de operación (S/.)', valor: null, input: 'text', esPorcentaje: false},
          {codigo: 'OP_SIN_RIESGO_CALIFICACION_CPP_MAX', descripcion: 'Calificación CPP máxima cliente (%)', valor: null, input: 'text', esPorcentaje: true},
        ]
        this.criterios.forEach(criterio => {
          const parametro = data.results.find(e => e.parametro == criterio.codigo)
          if (parametro) {
            criterio.valor = parametro.valor
            criterio.id = parametro.id
          }
        })
      },
      res => console.log(res),
    ).add(() => this.spinner.hide())
  }

  guardar() {
    let observables$ = this.criterios.filter(criterio => criterio.codigo).map(criterio => {
      let data = {id: criterio.id, valor : criterio.valor}
      return this.parametrosService.guardar(data)
    })
    this.spinner.show()
    forkJoin(observables$).subscribe((res) => {
      this.spinner.hide()
      this.activeModal.close(true)
    })
  }

}
