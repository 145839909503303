import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { faTimes, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { TablaDocsComponent } from '../tabla-docs/tabla-docs.component';

@Component({
  selector: 'app-modal-exclusiones-docs',
  templateUrl: './modal-exclusiones-docs.component.html',
  styleUrls: ['./modal-exclusiones-docs.component.css']
})
export class ModalExclusionesDocsComponent implements OnInit {
  faUserTie = faUserTie;
  faTimes   = faTimes;
  documentosExcluidos: any[];
  constructor(
    public modalService: NgbModal,
    public documentosCarteraService: DocumentosCarteraService,
    public activeModal: NgbActiveModal,
    public factoring: FactoringService
  ) { }

  ngOnInit(): void {
    // Obtener documentos excluidos
  }

  cerrar() {
    this.activeModal.dismiss();
  }

  mostrarDocumentos() {
    const modalRef = this.modalService.open(TablaDocsComponent, {size: 'xl'});
    modalRef.componentInstance.selectable = true;
    modalRef.componentInstance.tabladocs.subscribe(seleccion => {
      this.documentosExcluidos = seleccion.map((item)=>{
        let fecha = new Date()
        item.fecha_hasta = fecha
        return item
      })
    });
  }

  generarRecordatorios(){
    
  }

}
