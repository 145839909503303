import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from "../toast/toast.service";
import { forkJoin, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { toIsoString } from "app/shared/date/date";

@Injectable({
  providedIn: "root",
})
export class RecaudacionService {
  private apiUrl: string = "";
  public documents: string = "factoring/recaudaciones/";
  public formasPago: string = "factoring/pagos/formas/";
  public tiposPagador: string = "factoring/pagos/tipospagador/";
  public estadosRecaudacion: string = "factoring/recaudaciones/estados/";
  public bancosRecaudacion: string = "factoring/bancos/";
  public cuentasRecaudacion: string = "factoring/recaudaciones/cuentas/";
  public cuentasCobrar: string = "factoring/cuentas/";
  public monedas: string = "factoring/monedas/";
  public actualizarRecaudacion: string = "factoring/recaudaciones/";
  public beneficiarios: string = "factoring/beneficiarios/";
  public deudores: string = "factoring/deudores/";
  public docsCartera: string = "/factoring/carteras/";
  public detalleDocumento: string = "factoring/recaudaciones/detalles/";
  public excedentes: string = "factoring/recaudaciones/excedentes/";
  public excedentes2: string = "factoring/excedentes/";
  public recaudacionesClientes: string = "factoring/recaudaciones/clientes/";
  public recaudacionesAceptantes: string = "factoring/recaudaciones/aceptantes/";
  public cavaliRedencion: string = "factoring/cavali/facturas/redencion/";
  public notasCreditos: string = "factoring/recaudaciones/notas-credito/"
  public recaudacionGenerarLiquidacion: string = "factoring/formato_documentos/recaudacion/";
  
  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
  }
  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();
    this.appService.notifyMe(mensaje, ok)

    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);
  }

  /**
   * Mostros errores recibidos del servidor
   * @param error Error enviado del servidor
   */
  showErrors(error) {
    const err = error.error;

    for (const key in err) {
      if (Object.prototype.hasOwnProperty.call(err, key)) {
        const element = err[key];
        if (Array.isArray(element) && element.length) {
          element.forEach(item => {
            this.spinnerOff(item, false);
          });
        } else {
          this.spinnerOff(element, false);
        }

      }
    }
  }

  /**
   * Retorna los documentos
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   * @param pagador_nombre Nombre del pagador
   * @param analista_operacion ID del analista
   * @param operacion ID de la opracion
   */
  obtenerDocumentos(
    page: number = 1,
    page_size: number = 10,
    pagador_nombre: string = "",
    tipo_pagador: string = "",
    forma_pago: string = "",
    numero_pago: string = "",
    estado: string = "",
    banco: string = "",
    monto__range: string = "",
    empresa: string = '',
    id: number = undefined,
    queryParams: object = {},
  ) {
    const url =
      this.apiUrl +
      this.documents +
      `?tipo_pagador=${tipo_pagador}` +
      `&pagador__iconstains=${pagador_nombre}` +
      `&forma_pago=${forma_pago}` +
      `&numero_pago__icontains=${numero_pago}` +
      `&estado=${estado}` +
      `&banco=${banco}` +
      `&monto__range=${monto__range}` +
      `&empresa=${empresa}` +
      `&id=${id || ''}` +
      `&page_size=${page_size}` +
      `&page=${page}&` +
      Object.entries(queryParams).map(e => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&');

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La operación que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna las formas de pago
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerFormasPago(queryParams: object = {}) {
    const url = this.apiUrl + this.formasPago + '?' + Object.entries(queryParams).map(e => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&');

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna los tipos de pagadores
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerTiposPagador() {
    const url = this.apiUrl + this.tiposPagador;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna las bancos para recaudacion
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerBancosRecaudacion() {
    const url = this.apiUrl + this.bancosRecaudacion + '?page_size=1000';

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna los tipos de pagador
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerEstadosRecaudacion() {
    const url = this.apiUrl + this.estadosRecaudacion;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }
  /**
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerCuentasRecaudacion() {
    const url = this.apiUrl + this.cuentasRecaudacion + '?page_size=1000';

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerCuentasBeneficiarios(beneficiarioId) {
    const url = this.apiUrl + this.beneficiarios + `cuentasabono/` +
      `?beneficiario=${beneficiarioId}` + '&page_size=1000';

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerCuentasDeudores(deudorId) {
    const url = this.apiUrl + this.deudores + `cuentas-abono/` +
      `?deudor=${deudorId}` + '&page_size=1000';

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  elimiarCuentaAbono(cuentaAbono) {
    const url = this.apiUrl + (cuentaAbono.deudor ? 'factoring/deudores/cuentas-abono' : 'factoring/beneficiarios/cuentasabono') + `/${cuentaAbono.id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Cuenta eliminada!");
          res(response);
        },
        (err) => {
          if (err.error.detail) {
            this.spinnerOff(err.error.detail, false);
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  obtenerCuentasPorCobrar(
    { beneficiario = '', deudor = '' },
    estado__in: string = '',
    empresa: string = '',
    page: number = 1,
    page_size: number = 1000,
    queryParams:object = {}
  ) {
    let queryParamsStr = Object.entries(queryParams).map(e => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&');
    let attr = !!beneficiario ? `&beneficiario=${beneficiario}` : `&deudor=${deudor}`
    const url = this.apiUrl + this.cuentasCobrar +
      `?page=${page}` +
      `&page_size=${page_size}` +
      `&estado__in=${estado__in}` +
      `&empresa=${empresa}` +
      `${attr}`+
      `&${queryParamsStr}`;

    console.log("URL con parámetros")
    console.log(url)
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerCuentasPorCobrarConLineas(
    { beneficiario = '', deudor = '' },
    estado__in: string = '',
    empresa: string = '',
    tipo_linea: string = '',
    page: number = 1,
    page_size: number = 1000,
  ) {

    let attr = !!beneficiario ? `&beneficiario=${beneficiario}` : `&deudor=${deudor}`
    const url = this.apiUrl + this.cuentasCobrar +
      `?page=${page}` +
      `&page_size=${page_size}` +
      `&estado__in=${estado__in}` +
      `&empresa=${empresa}` +
      `&tipo_linea=${tipo_linea}` +
      `${attr}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerCuentaPorCobrar(
    id: number
  ) {

    const url = this.apiUrl + this.cuentasCobrar + id + '/'

    console.log(url)
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerMonedas() {
    const url = this.apiUrl + this.monedas;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerClientesRecaudacion(
    recaudacion: string = '',
    page: number = 1,
    page_size: number = 1000,
  ) {

    const url = this.apiUrl + this.recaudacionesClientes +
      `?page=${page}` +
      `&page_size=${page_size}` +
      `&recaudacion=${recaudacion}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });

  }

  obtenerAceptantesRecaudacion(
    recaudacion: string = '',
    page: number = 1,
    page_size: number = 1000) {
      const url = this.apiUrl + this.recaudacionesAceptantes +
        `?page=${page}` +
        `&page_size=${page_size}` +
        `&recaudacion=${recaudacion}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff();
            ref(err);
          }
        );
      });
  }

  obtenerClientesAceptante(
      aceptante: string = '',
      page: number = 1,
      page_size: number = 1000) {
    const url = this.apiUrl + 'factoring/recaudaciones/clientes-aceptante/' +
      `?page=${page}` +
      `&page_size=${page_size}` +
      `&deudor=${aceptante}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Cambia el estado de la recaudacion
   * @param id id de la recaudacion
   * @param estado nuevo estado de la recaudacion
   */
  actualizarEstadoRecaudacion(id: number, data: object, queryParams: object = {}) {
    let url = this.apiUrl + this.actualizarRecaudacion + `${id}/`;
    let queryParamsStr = Object.entries(queryParams).map(e => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&');

    if (queryParamsStr) {
      url += `?${queryParamsStr}`;
    }

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.patch(url, data).subscribe(
        (response) => {
          this.spinnerOff('Se ha actualizado la aplicación ');
          res(response);
        },
        (err) => {
          if (err.error['cuenta_cobrar']) {
            this.spinnerOff("Ocurrió un error durante el proceso", false);
            err.error['cuenta_cobrar'].forEach((item) => this.toast.warning(item))
          } else if (err.error['fecha_contable_cancelacion']) {

            this.spinnerOff(`Ocurrió un error durante el proceso: ${ err.error['fecha_contable_cancelacion'] }`, false);

          } else if (err.error['documento_cartera']){

            this.spinnerOff(err.error['documento_cartera'][0], false);

          } else if (err.error['operacion']) {

            this.spinnerOff(err.error['operacion'][0], false);

          } else if (err.error['factura']) {

            this.spinnerOff(`Ocurrió un error durante el proceso: ${err.error['factura']}`, false)

          // Comentado: porque no mostraba el mensaje de error de back correcto
          // } else if (err.error['estado']) {
          //   this.spinnerOff(
          //     'Se está intentando pagar documentos con prorroga anticipadamente, asegurarse que las facturas de las CXC relacionadas hayan sido facturadas',
          //     false,
          //   )
          } else if (err.error['aplicacion']) {
            
            this.spinnerOff('El monto de la aplicación es incorrecto. El monto a aplicar debe ser igual al pago total de los detalles de la aplicación', false)

          } else {
            let error = 'Ocurrió un error inesperado' + err
            if (err.error && typeof(err.error) == 'object') {
              error = Object.entries(err.error).map(e => `${e[0]}: ${e[1]}`).join('\n');
            }
            this.spinnerOff(error, false);
          }
          
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna la lista de beneficiarios
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerBeneficiarios(page: number = 1, page_size: number = 10000) {
    const url =
      this.apiUrl +
      this.beneficiarios +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerBeneficiariosObserver(ruc_nombre__icontains, page: number = 1, page_size: number = 10000) {
    const URL = this.apiUrl + this.beneficiarios +
      `?ruc_nombre__icontains=${ruc_nombre__icontains}` +
      `&page=${page}` +
      `&page_size=${page_size}`;

    return this.http
      .get<any>(URL)
      .pipe(map(resp => {
        if (resp.Error) {
          throwError(resp.Error);
        } else {
          return resp.results;
        }
      })
      );
  }

  obtenerBeneficiariosObserver2(ruc_nombre__icontains, page: number = 1, page_size: number = 10): Observable<any> {
    const URL = this.apiUrl + this.beneficiarios +
      `?ruc_nombre__icontains=${ruc_nombre__icontains}` +
      `&page=${page}` +
      `&page_size=${page_size}`;

    return this.http
      .get<any>(URL)
      .pipe(map(resp => {
        if (resp.Error) {
          throwError(resp.Error);
        } else {
          return resp.results;
        }
      })
      );
  }
  /**
   * Retorna la lista de deudores
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerDeudores(page: number = 1, page_size: number = 10000) {
    const url =
      this.apiUrl + this.deudores + `?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerDeudoresObserver(ruc_nombre__icontains, page: number = 1, page_size: number = 10000) {
    const URL = this.apiUrl + this.deudores +
      `?ruc_nombre__icontains=${ruc_nombre__icontains}` +
      `&page=${page}` +
      `&page_size=${page_size}`;

    return this.http
      .get<any>(URL)
      .pipe(map(resp => {
        if (resp.Error) {
          throwError(resp.Error);
        } else {
          return resp.results;
        }
      })
      );
  }

  /**
   * Retorna la lista de documentos en cartera
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerDocumentosCartera(
    beneficiario: string = "",
    deudor: string = "",
    estado__in: string = "",
    empresa: any = '',
    protestado: any = '',
    page: number = 1,
    page_size: number = 1000,
    fecha__gte:string = '',
    fecha__lte:string = '',
    queryParams:object = {}
  ) {
    let queryParamsStr = Object.entries(queryParams).map(e => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&');
    const url =
      this.apiUrl +
      this.docsCartera +
      `?beneficiario=${beneficiario}` +
      `&deudor=${deudor}` +
      `&estado__in=${estado__in}` +
      `&empresa=${empresa}` +
      `&protestado=${protestado}` +
      `&page_size=${page_size}` +
      `&page=${page}`+
      `&fecha_vencimiento__gte=${fecha__gte.split(' ')[0]}`+
      `&fecha_vencimiento__lte=${fecha__lte.split(' ')[0]}`+
      `&${queryParamsStr}`;
    
    console.log("URL DOCUMENTOS CARTERA")
    console.log(url)

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerDocumentosCarteraExpediente(
    beneficiario: string = "",
    deudor: string = "",
    estado__in: string = "",
    empresa: any = '',
    protestado: any = '',
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.docsCartera + `expediente/` +
      `?beneficiario=${beneficiario}` +
      `&deudor=${deudor}` +
      `&estado__in=${estado__in}` +
      `&empresa=${empresa}` +
      `&protestado=${protestado}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }


  /**
   * Retorna un documento en cartera
   */
  obtenerDocumentoCartera(
    id: number,
  ) {
    const url =
      this.apiUrl +
      this.docsCartera + id + '/'

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Registrar Documentos en cartera
   */
  registrarDocumentoCartera(doc) {
    const url = this.apiUrl + this.detalleDocumento;
    this.spinnerOn();
    console.log("Initial", doc.id)
    return this.http.post(url, doc).pipe(
      tap(documentEntry => this.spinnerOff()),
      catchError((err) => of(this.showErrors(err))),
    );;
  }

  /**
   * Registrar Notas de creditos
   */
  registrarNotasCredito(doc) {
    const url = this.apiUrl + this.notasCreditos;
    this.spinnerOn();
    console.log("Initial", doc.id)
    return this.http.post(url, doc).pipe(
      tap(documentEntry => this.spinnerOff()),
      catchError((err) => of(this.showErrors(err))),
    );;
  }

  /**
 * Actualizar Recaudacion detalle
 */
  actualizarRecaudacionDetalle(docId: number, data: object) {
    const url = this.apiUrl + this.detalleDocumento + docId + '/';
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.patch(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerDocumentosDetalle(
    recaudacion: string = "",
    cliente: string = "",
    aceptante: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {
    const url =
      this.apiUrl +
      this.detalleDocumento +
      `?recaudacion=${recaudacion}` +
      `&cliente=${cliente}` +
      `&aceptante=${aceptante}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerDocumentosDetalle2(id:any){
    const url =
      this.apiUrl +
      this.detalleDocumento + id;

      return new Promise((res, ref) => {
        this.spinnerOn();
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff();
            ref(err);
          }
        );
      });
  }

  /**
   * Registra la recaudacion
   */
  registrarRecaudacion(data: object) {
    const url = this.apiUrl + this.documents;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff('Se ha creado una aplicación ');
          res(response);
        },
        (err) => {
          if( err.error.fecha_contable ){
            this.spinnerOff(err.error.fecha_contable, false);
          }else{
            let error = 'Ocurrió un error inesperado'
            if (err.error && typeof(err.error) == 'object') {
              error = Object.entries(err.error).map(e => `${e[0]}: ${e[1]}`).join('\n');
            }
            this.spinnerOff(error, false);
          }
          ref(err);
        }
      );
    });
  }

  /**
   * Obtener Recaudacion
   */
  obtenerRecaudacion(recaudacionId) {
    const url = this.apiUrl + this.documents + `/${recaudacionId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Registra el detalle del documento
   */
  registrarDetalleDocumento(data: object) {
    const url = this.apiUrl + this.detalleDocumento;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna los detalles
   */
  obtenerDetalles(id: number) {
    const url = this.apiUrl + this.detalleDocumento + `?prorroga=${id}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Eliminar el detalle
   */
  eliminarDetalleDocumento(id: number) {
    const url = this.apiUrl + this.detalleDocumento + `${id}/`;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Registra los excedentes
   */
  registrarExcedentes(data: object) {
    const url = this.apiUrl + this.excedentes;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna los excedentes
   */
  obtenerExcedentes(id: number) {
    const url = this.apiUrl + this.excedentes2 + `?recaudacion=${id}&page_size=10000`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }


  /**
   * Eliminar los excedentes
   */
  eliminarExcedentes(id: number) {
    const url = this.apiUrl + this.excedentes + `${id}/`;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  redencion(data) {
    const url = this.apiUrl + this.cavaliRedencion;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff('Redención CAVALI realizada con éxito', true);
          res(response);
        },
        (err:any) => {

          if( err.error.fecha_pago ){

            this.spinnerOff( `${ err.error.fecha_pago[0] }`);

          }else if( err.error.xml ){
          
            this.spinnerOff( `${ err.error.xml[0] }`);
          
          }else{
            let error = 'Error en comunicación'
            if (err.error && typeof(err.error) == 'object') {
              error = Object.values(err.error).map(e => typeof(e) == 'object' ? JSON.stringify(e) : e).join('\n')
            }
            this.spinnerOff(error, false);
          }
          
          ref(err);
        }
      );
    });
  }

  obtenerNotasCreditos(
    recaudacion: string = "",
    nota_credito: string = "",
    page: number = 1,
    page_size: number = 1000
  ) {

    const url = this.apiUrl + this.notasCreditos + `?recaudacion=${recaudacion}` + `&nota_credito=${nota_credito}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff('Error en comunicación');
          ref(err);
        }
      );
    });
  }

  eliminarNotaCredito(notaId) {
    const url = this.apiUrl + this.notasCreditos + `${notaId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff('Error en comunicación');
          ref(err);
        }
      );
    });
  }

  generarLiquidacion(recaudacionId) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.recaudacionGenerarLiquidacion;
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      let date = toIsoString(new Date());
      let [d1, d2] = date.split('T')
      d1 = d1.split("-").reverse().join("/")
      d2 = d2.slice(0, 8)
      httpReques = this.http.post(URL, {
        recaudacion_id: recaudacionId,
        archivo_plantilla: "excel/liquidacion_recaudacion.xlsx",
        fecha_actual: `${d1} ${d2}`
      }, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, (err) => {
        this.spinnerOff()
        var reader = new FileReader();
        reader.onloadend = (e) => {
          let error = JSON.parse(<any>e.target.result)
          console.log(e, e.target.result, error)
          for (let key in error) {
            this.toast.warning(`El campo ${key} es requerido`);
          }
        }
        reader.readAsText(err.error);
        reject(err);
      });

    });
  }
  
  simulacionTipoCambio( id, monto ){

    return new Promise( (res, ref)=>{
      res([])
    })

  }

  obtenerMotivosModificacionMora() {
    const url = `${this.apiUrl}factoring/recaudaciones/motivos-modificacion-mora/`
    return this.http.get(url).pipe(
      map((res: any) => res.results)
    )
  }

  actualizarDetalle(id, data) {
    const url = `${this.apiUrl}factoring/recaudaciones/detalles/${id}/`
    return this.http.patch(url, data)
  }

  modificarRecaudacion(id, data) {
    const url = `${this.apiUrl}factoring/recaudaciones/${id}/`
    return this.http.patch(url, data)
  }

  enviarFacturar(data) {
    const url = `${this.apiUrl}factoring/facturas-electronicas/facturar/`
    return this.http.post(url, data)
  }

  obtenerFacturasCount(id: number) {
    const url = `${this.apiUrl}factoring/facturas-electronicas/` + `?recaudacion=${id}`

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  getPagadores(queryParams: object = { 'page_size': 9999 }, omitirDuplicados = true) {
    const url = `${this.apiUrl}factoring/pagos/pagadores/?${Object.entries(queryParams).map((e: any) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&')}`
    return this.http.get(url).pipe(
      map((res: any) => res.results)
    ).pipe(
      map((data: any) => {
        if (!omitirDuplicados) return data
        let pagadores = []
        let rucs = new Set()
        for (let e of data) {
          if (!rucs.has(e.ruc)) {
            rucs.add(e.ruc)
            pagadores.push(e)
          }
        }
        return pagadores
      })
    )
  }

  obtenerAplicacionesEnlazadas(aplicacionId: number) {
    const url = `${this.apiUrl}factoring/recaudaciones/${aplicacionId}/aplicaciones-enlazadas/`
    return this.http.get(url)
  }

}
