import { Component, OnInit } from '@angular/core';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { ManageTiposFirmasContratoModalComponent } from '../modals/manage-tipos-firmas-contrato-modal/manage-tipos-firmas-contrato-modal.component';
import { functions } from '../../../core/helpers/functions';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { ParametrosService } from 'app/core/services/configuration/parametros.service';
import { ToastService } from 'app/core/services/toast/toast.service';
@Component({
  selector: 'app-asignacion-curso-normal',
  templateUrl: './asignacion-curso-normal.component.html',
  styleUrls: ['./asignacion-curso-normal.component.css']
})
export class AsignacionCursoNormalComponent implements OnInit {
  faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public user             : any;
  public userDefault      : any;
	public identidad        : any     = {};
	public searchstring     : string = '';
	public canCreate: boolean = false;
  public tblAcciones       : any[]   = [];
  public parametroCursoNormal : any;
  public parametroCursoNormalLimite: number  = 0;
  constructor(
    public modalService           : NgbModal,
    private factoringService: FactoringService,
    private parametrosService: ParametrosService,
    public toast: ToastService,
  ) { }

  ngOnInit(): void {
    // this.userDefault = null;
    this.obtenerTiposFirmas();
  }
  loadTable(data) {
    this.rowData = functions.indextable( data )
    //console.log(this.rowData)
    // this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }
    
  initVars() {
    this.tblAcciones = [
      {
        tooltip: 'Asignar', icon: 'fa fa-check', class: 'btn-success',
        click: row => this.changeAnalistaDefault(row),
        //visible: row => !row['fecha_anulacion_contable'] && row['monto'] == row['saldo']
      }
    ]
    let columns = [
      {
        headerName: 'N°', 
        field     : 'index' ,
        sortable  : true,
      },
      {
        headerName: 'Analista', 
        field     : 'nombre',
        class     : 'text-center',
        sortable  : true,
        // filterable: true,
        // filterProp: 'descripcion',
        // filterInput: true,
      },
      // {
      //   headerName: 'Nro.Operaciones', 
      //   field     : 'cantidad_operaciones',
      //   class     : 'text-center',
      //   sortable  : true,
      // },
      {
        headerName: "U.Default",
        field: "defaultCursoNormal",
        sortable: false,
        pipe: 'function',
        function: function (row, header) {
					if (row.defaultCursoNormal) {
						return `ASIGNADO`
					}
					else {
						return ``
					}
				}
      },
      
    ];
    
    this.columnDefs = columns;
  }

  async obtenerParametros(){
    await this.parametrosService.obtenerParametro('RIESGOS_CURSO_NORMAL_ANALISTA').toPromise().then((res :any[])=>{
      this.parametroCursoNormal = res
      })

    await this.parametrosService.obtenerParametro('RIESGOS_CURSO_NORMAL_LIMITE').toPromise().then((res :any)=>{
        this.parametroCursoNormalLimite = res
      })
  }

  async obtenerTiposFirmas(page = 1, page_size = 10) {
    this.obtenerParametros()
    let rol = 6;
    let analistasRiesgos = this.factoringService.obtenerResponsables(rol,'true',page,page_size);
    /*let tiposFirmantes = this.configurationService.obtenerTiposFirmantes(page, page_size);
    */
    await Promise.all([analistasRiesgos]).then((res :any[])=>{
      let tiposFirmantes  = res[0];
      this.initVars();

      tiposFirmantes.results.map( (element, indice) => {
        element.defaultCursoNormal = false
        if( this.parametroCursoNormal.valor == element.email ){
          element.defaultCursoNormal = true
          this.userDefault = element
        }
      });
      this.loadTable(tiposFirmantes);
    });
   
  }

  colorRow(row) {
    return row['fecha_anulacion_contable'] ? '#f003' : ''
  }

  async changeAnalistaDefault (data){
    let dataUpdate = {id: this.parametroCursoNormal.id, valor : data.email}
    console.log({dataUpdate})
    await this.parametrosService.guardar(dataUpdate).toPromise().then((res :any[])=>{
      this.parametroCursoNormal = res
      //this.userDefault = null
      this.toast.success("Se cambio el usuario default");
      this.obtenerTiposFirmas();
      })
    
  }

  // crear() {
  //   const modalRef = this.modalService.open(ManageTiposFirmasContratoModalComponent, {
  //   });

  //   modalRef.result.then((result) => {
  //         console.log(result);
  //     }, (reason) => {
  //         console.log(reason);
  //   });

  //   modalRef.componentInstance.successEmit.subscribe(result => {
	// 		console.log(result);
	// 		this.obtenerTiposFirmas();
	// 	},
	// 	error => {
  //     console.error(error);
      
	// 	});
  // }

  // tableEditEvent(row) {
  //   const modalRef = this.modalService.open(ManageTiposFirmasContratoModalComponent, {
  //   });

  //   modalRef.componentInstance.tiposFirmantesId = row.id;

  //   modalRef.componentInstance

  //   modalRef.result.then((result) => {
  //         console.log(result);
  //     }, (reason) => {
  //         console.log(reason);
  //   });

  //   modalRef.componentInstance.successEmit.subscribe(result => {
	// 		console.log(result);
	// 		this.obtenerTiposFirmas();
	// 	},
	// 	error => {

	// 	});
  // }

  // destroy(row) {
  //   const modalRef = this.modalService.open(ConfirmModalComponent, {
		
	// 	});

	// 	modalRef.componentInstance.title = 'Remover Tipo de Firma';
	// 	modalRef.componentInstance.message = '¿Seguro que desea remover el Tipo de Firma?';
		
	// 	modalRef.result.then((result) => {
	// 	if (result) {
	// 		this.configurationService.elimiarTiposFirmas(row.id)
	// 		.then(result => {
	// 		this.obtenerTiposFirmas();
	// 		})
	// 		.catch(error => {
	// 		console.error(error);
	// 		});
	// 	}
	// 	}, (reason) => {
	// 		console.log(reason);
	// 	});
  // }

  goToPage({page,per_page,filtros}) {
    this.obtenerTiposFirmas(page, per_page);
  }
 

}
