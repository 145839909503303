import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { VerificationsService } from 'app/core/services/factoring/verifications.service';

@Component({
  selector: 'app-estaciones-modal',
  templateUrl: './estaciones-modal.component.html',
  styleUrls: ['./estaciones-modal.component.css']
})
export class EstacionesModalComponent implements OnInit {
  faTimes=faTimes;
  myForm      : FormGroup;
  estaciones  : any[];
  motivos     : any[] = [];

  @Input() restricciones;
  @Output() estacionEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private modalService: NgbModal,
    public activeModal  : NgbActiveModal,
    private formBuilder : FormBuilder,
    private docService  : DocschecklistService,
    private verificacionService: VerificationsService,
  ) { }

  ngOnInit(): void {
    this.docService.obtenerEstaciones()
    .then(result => {
      let estacionAll = result['results'];

      estacionAll = estacionAll.filter(item => {
        return this.restricciones.includes(item.id);
      });

      this.estaciones = estacionAll;
    });

    this.verificacionService.obtenerMotivosDevolucion().then((res:any)=>{
      this.motivos = res.results
    });
    
    this.createForm();
  }

  createForm() {
    this.myForm = this.formBuilder.group({
      estacion    : [null, [Validators.required]],
      motivo      : [null, [Validators.required]],
      comentario  : [null, [Validators.required]]
    });
    
  }

  onSubmit() {
    if (this.myForm.valid) {
      this.activeModal.close(false);
      this.estacionEmit.emit(this.myForm.value);
    }
  }

}
