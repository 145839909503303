import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { AnalistasService } from 'app/core/services/factoring/analistas.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { Estacion } from 'app/core/services/auth/Estacion';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Usuario } from 'app/core/services/auth/Usuario';

@Component({
  selector: 'app-reassign-analyst-modal',
  templateUrl: './reassign-analyst-modal.component.html',
  styleUrls: ['./reassign-analyst-modal.component.css']
})
export class ReassignAnalystModalComponent implements OnInit {
  faTimes = faTimes;
  
  public _operacion: any;
  public estacion: Estacion;
  public get operacion(): any {
    return this._operacion;
  }
  @Input() public set operacion(value: any) {
    if(value){
      this.estacion = new Estacion(value)
      this._operacion = value;
    }
  }
  @Input() user: Usuario;

  analistas: any;
  analistasDiversos: any = [];
  assigned_analyst: any;
  classColor = false;

  selectAnalyst = {
    cantidad_analisis: 0,
    cantidad_observadas: 0,
    cantidad_terminadas: 0,
    id: 0,
    nombre: '',
  };

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private analistaService: AnalistasService,
    public toast            : ToastService,
		private authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.obtenerAnalistas().then((res: any)=>{
      this.cargarDatos(res)
		});
  }

  obtenerAnalistas() {
    switch(this.user.perfil){

      case this.user.perfilJefeDeOperacionesID:
        return this.analistaService.obtenerAnalistasPorRol(this.user.perfilAnalistaOperacionesID);
        
      case this.user.perfilAsistenteCobranzasID:
        return this.analistaService.obtenerAnalistasPorRol(this.user.perfilLegalID);

      case this.user.perfilJefeDeAdmisionID:
        return this.analistaService.obtenerAnalistas(this.operacion.id);
        
      case this.user.perfilLiderDeTesoreriaID:
        return this.analistaService.obtenerAnalistasPorRol(this.user.perfilTesoreriaID);
        
      case this.user.perfilGerenciaGeneralID:
      case this.user.perfilGerenciaComercialID:

        if(this.estacion.actualJefeOperaciones){
          return this.analistaService.obtenerAnalistasPorRol(this.user.perfilAnalistaOperacionesID);
        } else {
          return this.analistaService.obtenerOficialesDeNegocio(this.operacion.id);
        }

      case this.user.perfilLiderDeEquipo:
        if(this.estacion.actualJefeOperaciones){
          return this.analistaService.obtenerAnalistasPorRol(this.user.perfilAnalistaOperacionesID);
        } else {
          return this.analistaService.obtenerOficialesDeNegocio(this.operacion.id);
       }

      case this.user.perfilAsesorLegalID:
        return this.analistaService.obtenerAnalistasPorRol( this.user.perfilLegalID )
    }
  }

  cargarDatos(res: any){
    let operacionResponsableID = this.operacion.responsable

    switch(this.user.perfil){
      case this.user.perfilJefeDeOperacionesID:
        this.assigned_analyst = this.operacion.analista_operacion;
        let analistas =res['results']
        this.analistasDiversos = this.parceOptionAdd(analistas)
        this.analistasDiversos.push(this.selfOptionAdd())
        this.analistasDiversos = this.analistasDiversos.filter((user: any) => user.id != this.assigned_analyst)
      break;
      case this.user.perfilAsistenteCobranzasID:
        this.assigned_analyst = operacionResponsableID;
        this.analistasDiversos = res['results'].filter((user: any) => user.id != operacionResponsableID)
        this.analistasDiversos.push(this.selfOptionAdd())
      break;
      case this.user.perfilJefeDeAdmisionID:
        this.assigned_analyst = res['analista_riesgo'];
        this.analistas = res['analistas'].filter(item => item.id != this.assigned_analyst);
      break;
      case this.user.perfilGerenciaGeneralID:
      case this.user.perfilGerenciaComercialID:
        if(this.estacion.actualJefeOperaciones){
          this.assigned_analyst = this.operacion.analista_operacion;
          let analistas =res['results']
          this.analistasDiversos = this.parceOptionAdd(analistas)
          this.analistasDiversos.push(this.selfOptionAdd())
          this.analistasDiversos = this.analistasDiversos.filter((user: any) => user.id != this.assigned_analyst)
        } else {
          this.assigned_analyst = res['oficial_negocio'];
          this.analistas = res['oficiales'].filter(item => item.id != this.assigned_analyst);
        }

      break;
      case this.user.perfilLiderDeEquipo:
        if (this.estacion.actualJefeOperaciones){
          this.assigned_analyst = this.operacion.analista_operacion;
          let analistas = res['results']
          this.analistasDiversos = this.parceOptionAdd(analistas)
          this.analistasDiversos.push(this.selfOptionAdd())
          this.analistasDiversos = this.analistasDiversos.filter((user: any) => user.id != this.assigned_analyst)
        } else {
          let miembrosDelEquipo = this.authService.miembrosDeleQuipo.map(e => e.id)
          miembrosDelEquipo.push(this.user.id)
          this.assigned_analyst = res['oficial_negocio'];
          this.analistas = res['oficiales']
            .filter(item => item.id != this.assigned_analyst)
            .filter(item => miembrosDelEquipo.indexOf(item.id) !== -1)
          
        }
        break;
      case this.user.perfilAsesorLegalID:
        this.assigned_analyst = operacionResponsableID;
        this.analistasDiversos = res['results'].filter((user: any) => user.id != operacionResponsableID)
        this.analistasDiversos.push(this.selfOptionAdd())
      break;
      case this.user.perfilLiderDeTesoreriaID:
        this.assigned_analyst = operacionResponsableID;
        this.analistasDiversos = res['results'].filter((user: any) => user.id != operacionResponsableID)
        this.analistasDiversos.push(this.selfOptionAdd())
      break;
      default:
        this.assigned_analyst = operacionResponsableID;
        this.analistasDiversos = res[0]['results'].filter((user: any) => user.id != operacionResponsableID)
        this.analistasDiversos.push(this.selfOptionAdd())
      break;
    }
  }

  selfOptionAdd(){
    let usuario = this.authService.user.user

    return {
      cantidad_operaciones: null,
      codigo_sf           : null,
      email               : usuario.email,
      estado              : usuario.estado,
      id                  : usuario.id,
      nombre              : `${usuario.nombre} ${usuario.apellido == undefined ? '' :usuario.apellido }`,
      rol                 : usuario.perfil,
      rol_descripcion     : usuario.perfil_descripcion,
      sucursal            : null  
    }
  }

  parceOptionAdd(options){
    return options.map((option)=>{
      option.cantidad_operaciones = null
      option.codigo_sf            = null
      option.estado               = option.estado
      option.nombre               = `${option.nombre} ${option.apellido == undefined ? '' :option.apellido}` 
      option.rol                  = option.rol
      option.rol_descripcion      = option.rol_descripcion
      return option
    })
  }

  asignaAnalista(){
    let analista = this.selectAnalyst;

    const data = {
      analista_riesgo: analista.id,
      analista_riesgo_nombre: analista.nombre
    };

    if (!analista.id) {
      return;
    }

    this.analistaService.asignarAnalista(this.operacion.id, data).
    then(res => {
      this.activeModal.close(true);
      this.toast.success('El Analista de Riesgos ha sido asignado!');
    })
    .catch(error => {
      console.error(error);
      this.activeModal.close(false);
      this.toast.warning('Ha ocurrido un error al enviar la data');
    });
  }

  asignarAnalista() {

    switch(this.user.perfil){

      case this.user.perfilJefeDeOperacionesID:
        this.asignaAnalistaDeOperaciones()
      break
        
      case this.user.perfilJefeDeAdmisionID:
        this.asignaAnalista()
      break

      case this.user.perfilGerenciaGeneralID:
      case this.user.perfilGerenciaComercialID:
        if(this.estacion.actualJefeOperaciones){
          this.asignaAnalistaDeOperaciones()
        } else {
          this.asignaOficialDeNegocio()
        }
      break

      case this.user.perfilLiderDeEquipo:
        if(this.estacion.actualJefeOperaciones){
          this.asignaAnalistaDeOperaciones()
        } else {
          this.asignaOficialDeNegocio()
        }
      break

      default:
        this.asignaResponsable()
    }

  }

  asignaOficialDeNegocio(){
    let oficialDeNegocio = this.selectAnalyst;

    const data = {
      oficial_negocio: oficialDeNegocio.id,
      operacion: this.operacion.id
    };

    this.analistaService.reAsignOficialDeNegocios(this.operacion.id, data)
    .then(res => {
      this.activeModal.close(true);
      this.toast.success('El nuevo oficial de negocio ha sido asignado');
    })
    .catch(error => {
      console.error(error);
      this.activeModal.close(false);
      this.toast.warning('Ha ocurrido un error al enviar la data');
    });
  }

  asignaAnalistaDeOperaciones(){
    let analista = this.selectAnalyst;
    const data = {
      analista_operacion: analista.id,
      operacion: this.operacion.id
    };

    this.analistaService.asignaAnalistaDeOperaciones(data)
    .then(res => {
      this.activeModal.close(true);
      this.toast.success('El nuevo responsable ha sido asignado');
    })
    .catch(error => {
      console.error(error);
      this.activeModal.close(false);
      this.toast.warning('Ha ocurrido un error al enviar la data');
    });
  }

  asignaResponsable(){
    let analista = this.selectAnalyst;

    const data = {
      responsable: analista.id,
      operacion: this.operacion.id
    };

    this.analistaService.reAsignResponsable(data)
    .then(res => {
      this.activeModal.close(true);
      this.toast.success('El nuevo responsable ha sido asignado');
    })
    .catch(error => {
      console.error(error);
      this.activeModal.close(false);
      this.toast.warning('Ha ocurrido un error al enviar la data');
    });
  }

  selectEvent(analista) {
    this.selectAnalyst = analista;
  }

}
