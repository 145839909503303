import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';

@Component({
  selector: 'app-editar-detalle',
  templateUrl: './editar-detalle.component.html',
  styleUrls: ['./editar-detalle.component.css']
})
export class EditarDetalleComponent implements OnInit {

  @Input() itemInstance: any = null;
  @Input() recaudacion: any = null;
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  forma: FormGroup
  motivos: any[] = []
  faTimes = faTimes

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private recaudacionService: RecaudacionService,
  ) {
    this.forma = this.formBuilder.group({
      'monto_mora': [null,],
      'motivo_monto_mora': [null, [Validators.required]],
      'tasa_mora': [null,],
      'motivo_tasa_mora': [null, [Validators.required]],
      'tasa_mora_mensual': [null,],
      'monto_mora_original': [null,],
      'tasa_mora_original': [null,],
      'tasa_mora_mensual_original': [null,],
      'tem': [null,],
      'tea': [null,],
      'todos': [false,],
      'todos_cliente': [false,],
      'saldo_documento': [null,],
      'saldo_financiado_documento': [null,],
      'monto': [null,],
      'monto_pagar': [null,],
      'prorrogar': [false,],
    })
    this.forma.get('todos').valueChanges.subscribe(value => {
      if (value) this.forma.get('todos_cliente').setValue(false)
    })
    this.forma.get('todos_cliente').valueChanges.subscribe(value => {
      if (value) this.forma.get('todos').setValue(false)
    })
  }

  ngOnInit(): void {
    this.recaudacionService.obtenerMotivosModificacionMora().subscribe(
      data => this.motivos = data,
      res => console.log(res)
    )
    this.forma.patchValue(Object.assign({}, this.itemInstance, {
        'tasa_mora_mensual': Math.round(this.tea2tem(this.itemInstance['tasa_mora']) * 100) / 100,
        'tasa_mora_mensual_original': Math.round(this.tea2tem(this.itemInstance['tasa_mora_original']) * 100) / 100,
      }))
    this.actualizarEstadoCampos()
  }

  async onSubmit() {
    let data = this.forma.value
    if (!data['monto_mora']) data['monto_mora'] = null
    if (!data['tasa_mora']) data['tasa_mora'] = null
    if (!data['motivo_monto_mora']) data['motivo_monto_mora'] = null
    if (!data['motivo_tasa_mora']) data['motivo_tasa_mora'] = null
    if (!data['monto_pagar']) data['monto_pagar'] = null
    if (this.canProrrogar && !data['prorrogar']) {
      const result = await Swal.fire({
        title: 'Prorrogar',
        html: `
          Está realizando un abono parcial. <br />
          ¿Desea marcar para prorrogar o continuar sin prorrogar? <br />
          <small>Si se marca para prorrogar, no crea cuenta por cobrar de mora</small>
          `,
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Si, prorrogar',
        cancelButtonText: 'No, continuar',
      })
      if (result.isConfirmed) {
        data['prorrogar'] = true
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Mantener sin cambios y continuar para guardar
      } else {
        return
      }
    }
    this.successEmit.emit(data)
    this.activeModal.close(true)
  }

  onChangeTasaMora() {
    const tasaMora = this.forma.get('tasa_mora').value
    this.forma.get('tasa_mora_mensual').setValue(Math.round(this.tea2tem(tasaMora) * 100) / 100)
    this.actualizarEstadoCampos()
  }

  tea2tem(value) {
    value = value / 100
    value = Math.pow(value + 1, 1 / 12) - 1
    return isNaN(value) ? 0 : value * 100
  }

  actualizarEstadoCampos() {
    const montoMora = this.forma.get('monto_mora').value
    const tasaMora = this.forma.get('tasa_mora').value

    if (!montoMora && montoMora !== 0) {
      this.forma.get('motivo_monto_mora').setValue(null)
      this.forma.get('motivo_monto_mora').disable()
      this.forma.get('tasa_mora').enable()
    } else {
      this.forma.get('motivo_monto_mora').enable()
      this.forma.get('motivo_monto_mora').setValue(1)
      this.forma.get('tasa_mora').disable()
    }
    if (!tasaMora && tasaMora !== 0) {
      this.forma.get('motivo_tasa_mora').setValue(null)
      this.forma.get('motivo_tasa_mora').disable()
      this.forma.get('monto_mora').enable()
      this.forma.get('todos').setValue(false)
      this.forma.get('todos_cliente').setValue(false)
    } else {
      this.forma.get('motivo_tasa_mora').enable()
      this.forma.get('motivo_tasa_mora').setValue(1)
      this.forma.get('monto_mora').disable()
    }
  }

  /*
   * Solo se puede prorrogar si es un abono parcial
  */
  get canProrrogar() {
    let montoPagar = Number(this.forma.get('monto_pagar').value)
    let canProrrogar = false
    if (this.itemInstance['documento_cartera']) {
      if (Number(this.itemInstance['monto_pagar']) == montoPagar) {
        canProrrogar = Number(this.itemInstance['pago_financiado']) < Number(this.itemInstance['saldo_financiado_documento'])
      } else {
        if (montoPagar != 0) {
          canProrrogar = montoPagar < Number(this.itemInstance['saldo_financiado_documento'])
        }
      }
    }
    if (!canProrrogar) {
      this.forma.get('prorrogar').setValue(false)
    }
    return canProrrogar
  }

}
