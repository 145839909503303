import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx'

import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { AbonosService } from 'app/core/services/abonos/abonos.service';

@Component({
  selector: 'app-importar',
  templateUrl: './importar.component.html',
  styleUrls: ['./importar.component.css']
})
export class ImportarComponent implements OnInit {
  cuentas = []
  monedas = []
  public tblRows: any[] = []
  public tblHeaders: columnHeader[]

  constructor(
    public activeModal: NgbActiveModal,
    private abonosService: AbonosService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.spinner.show()
    forkJoin({
      cuentas: this.abonosService.getCuentasBancarias(),
      monedas: this.abonosService.getMonedas(),
    }).subscribe(({ cuentas, monedas }) => {
      this.cuentas = cuentas
      this.monedas = monedas
      this.initTabla()
    }).add(() => this.spinner.hide())
  }

  guardar() {
    if (this.tblRows.filter(x => x.error).length > 0) {
      this.abonosService.toast.warning('Solucione los errores antes de guardar')
      return
    }
    this.spinner.show()
    this.tblRows.forEach(x => x.saldo = x.monto)
    this.abonosService.importar({ abonos: this.tblRows }).subscribe(
      (data: any) => {
        this.activeModal.close(data['abonos'])
      },
      res => {
        console.log(res)
        let errores = res.error.abonos
        if (errores && Array.isArray(errores)) {
          for (let i = 0; i < errores.length; i++) {
            let msg = Object.values(errores[i]).map(e => typeof(e) == 'object' ? JSON.stringify(e) : e).join('\n')
            this.tblRows[i].error = msg
          }
        }
      }
    ).add(() => this.spinner.hide())
  }

  async onSelectFileImportar(ev) {
    if (ev.target.files.length > 0) {
      this.spinner.show()
      const file = ev.target.files[0]
      ev.target.value = null
      this.tblRows = []
      try {
        this.tblRows = await this.importarAbonos(file)
      } finally {
        this.spinner.hide()
      }
    }
  }

  async importarAbonos(file) {
    const buffer = await file.arrayBuffer()
    const wb = XLSX.read(buffer, { type: 'buffer', cellDates: true })
    const ws = wb.Sheets[wb.SheetNames[0]]
    const data: any = XLSX.utils.sheet_to_json(ws)
    const headersFormato = this.getHeadersFormato()
    let abonos = []
    let monedas = {}
    let cuentas = {}
    let pagadores = {}
    let rucPagadores = []

    for (let moneda of this.monedas) {
      monedas[moneda['descripcion'].toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")] = moneda
    }
    for (let cuenta of this.cuentas) {
      let banco = cuenta['banco_nombre'].trim().toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")
      if (!cuentas[cuenta['moneda']]) cuentas[cuenta['moneda']] = {}
      if (!cuentas[cuenta['moneda']][banco])
        cuentas[cuenta['moneda']][banco] = {}
      cuentas[cuenta['moneda']][banco][cuenta['codigo_texto'].trim().toLowerCase()] = cuenta
    }
    rucPagadores = Array.from(new Set(data.map(x => x['ruc pagador']).filter(x => x)))
    if (rucPagadores.length > 0) {
      let dataPagadores = await this.abonosService.getPagadores({ 'page_size': rucPagadores.length * 2 }, { 'ruc__in': rucPagadores.join(',') }).toPromise()
      for (let pagador of dataPagadores) {
        pagadores[pagador['ruc']] = pagador
      }
    }

    for (let i = 0; i < data.length; i++) {
      let abono = Object.assign(data[i])
      let headers = Object.keys(abono)
      let pagador = pagadores[(abono['ruc pagador'] || '').toString().trim()]
      let moneda = monedas[(abono.moneda || '').toString().trim().toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")]
      let bancoPartes = (abono.banco || '').split(' ')
      let empresa = bancoPartes[0].trim().toLowerCase()
      let banco = bancoPartes.slice(1).join(' ').trim().toLowerCase().normalize("NFD").replace(/\p{Diacritic}/gu, "")
      let cuenta = null
      let fecha = null
      let fechaContable = null
      let headersFaltantes = []
      let errors = []

      if (moneda && cuentas[moneda.id] && cuentas[moneda.id][banco] && cuentas[moneda.id][banco][empresa]) {
        cuenta = cuentas[moneda.id][banco][empresa]
      }

      for (let header of headersFormato) {
        if (!headers.includes(header)) {
          headersFaltantes.push(header)
        }
      }
      if (headersFaltantes.length) errors.push(`Falta ${headersFaltantes.join(', ')}.`)

      if (cuenta) {
        abono.cuenta = cuenta.id
        abono.banco = cuenta.banco
        abono.banco_nombre = `${cuenta['codigo_texto']} ${cuenta['banco_nombre']}`
      } else {
        errors.push(`La cuenta "${abono.moneda || ''} ${abono.banco || ''}" no existe.`)
        abono.banco_nombre = abono.banco
        delete abono.banco
      }
      if (moneda) {
        abono.moneda = moneda.id
        abono.moneda_descripcion = moneda.descripcion
      } else {
        errors.push(`La moneda "${abono.moneda || ''}" no existe.`)
        abono.moneda_descripcion = abono.moneda
        delete abono.moneda
      }
      if (pagador) {
        abono.pagador_ruc = pagador.ruc
        abono.pagador_nombre = pagador.nombre
      } else {
        errors.push(`El pagador "${abono['ruc pagador'] || ''}" no existe.`)
        abono.pagador_ruc = abono['ruc pagador']
      }
      try {
        fecha = new Date(abono['fecha'].toISOString().split('T')[0] + 'T00:00')
        abono['fecha'] = fecha.toISOString().split('T')[0]
      } catch (ex) {
        errors.push(`La fecha "${abono['fecha'] || ''}" no es válida.`)
      }
      try {
        fechaContable = new Date(abono['fecha contable'].toISOString().split('T')[0] + 'T00:00')
        abono['fecha_contable'] = fechaContable.toISOString().split('T')[0]
      } catch (ex) {
        errors.push(`La fecha contable "${abono['fecha contable'] || ''}" no es válida.`)
      }
      if (fecha && fechaContable && fechaContable.getTime() != fecha.getTime()) {
        errors.push(`La fecha contable debe ser igual a la fecha banco`)
      }
      if (fecha && fecha.getTime() > new Date().getTime()) errors.push(`La fecha NO debe ser posterior a hoy`)
      if (fechaContable && fechaContable.getTime() > new Date().getTime()) errors.push(`La fecha contable NO debe ser posterior a hoy`)
      if (abono.monto <= 0) errors.push(`El monto tiene que ser mayor a cero`)
      if (fecha && fecha.getTime() < new Date(2023, 1, 1).getTime()) errors.push(`La fecha NO puede ser anterior a 01/02/2023`)

      abono.error = errors.join(' | ')
      abonos.push(abono)
    }
    return abonos
  }

  async descargarFormato() {
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet([this.getHeadersFormato()])
    XLSX.utils.book_append_sheet(wb, ws, "Recaudaciones")
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(
      this.cuentas.map(cuenta => ({
        'banco': `${cuenta['codigo_texto']} ${cuenta['banco_nombre']}`,
        'cuenta': cuenta['numero'],
        'moneda': cuenta['moneda'],
      }))
    ), "Cuentas")
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(this.monedas), "Monedas")
    XLSX.writeFile(wb, "formato_recaudaciones.xlsx")
  }

  private getHeadersFormato() {
    return ['fecha', 'fecha contable', 'ruc pagador', 'banco', 'moneda', 'monto']
  }

  private initTabla() {
    this.tblHeaders = [
      {
        headerName: 'Nro',
        pipe: 'indexcol',
        class: "text-right",
      },
      {
        headerName: 'Fecha',
        field: 'fecha',
        pipe: 'date',
        class: "text-center",
      },
      {
        headerName: 'Fecha contable',
        field: 'fecha_contable',
        pipe: 'date',
        class: "text-center",
      },
      {
        headerName: 'Ruc pagador',
        field: 'pagador_ruc',
      },
      {
        headerName: 'Pagador',
        field: 'pagador_nombre',
      },
      {
        headerName: 'Banco',
        field: 'banco_nombre',
      },
      {
        headerName: 'Moneda',
        field: 'moneda_descripcion',
        class: "text-center",
      },
      {
        headerName: 'Monto',
        field: 'monto',
        class: "text-right",
        pipe: "currency",
      },
      {
        headerName: 'Error',
        field: 'error',
        class: "text-danger",
      },
    ]
  }
}
