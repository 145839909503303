import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/core/services/auth/auth.service';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { ReportesService } from 'app/core/services/reportes.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { utils, writeFile } from 'xlsx';
import Swal from 'sweetalert2';
import { functions } from '../../../../core/helpers/functions';

@Component({
  selector: 'app-reporte-excedentes',
  templateUrl: './reporte-excedentes.component.html',
  styleUrls: ['./reporte-excedentes.component.css']
})
export class ReporteExcedentesComponent implements OnInit {

  public reporteForm : FormGroup;
	public headerTable: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
  public searchstring: string = '';
  public filtrosTemporales: any;

  public rowData = [];

  public estados = []
  public oficiales_negocio = []
  public empresas = []

  public tipo_pagadores = [
    {
      'id': 1,
      'descripcion': 'Aceptante'
    },
    {
      'id': 2,
      'descripcion': 'Cliente'
    }
  ]

  public lineas = []

  public generando: boolean = false;
  
  public filtros: any = {
    nro_operacion: undefined,
    nro_recaudacion:undefined,
    cliente_nombre: undefined,
    cliente_ruc: undefined,
    deudor_nombre: undefined,
    deudor_ruc: undefined,
    tipo_pagador: undefined,
    linea: undefined,
  };

  constructor( 
    public cxcService       : CuentasService,
    private factoringService: FactoringService,
    public formbuild        : FormBuilder,
    public authService      : AuthService
  ) {}

  ngOnInit(): void {
    this.getReporteCXC({page:1, per_page:this.pageTablePerPages, filtros:{}})
    const estados        = this.cxcService.obtenerEstados()
    const tipo_pagadores = this.cxcService.obtenerTiposPagador()
    const lineas         = this.factoringService.obtenerTiposDeLineas()

    Promise.all([estados, tipo_pagadores, lineas]).then(res=>{
      this.estados = res[0]['results']
      this.tipo_pagadores = res[1]['results']
      this.lineas = res[2]['results']
      let estados = [
        {
          "id": 3,
          "descripcion": "Aprobado"
        },

        {
          "id": 1,
          "descripcion": "Solicitado"
        }
      ]
      this.loadTableColumns(estados, this.tipo_pagadores, this.lineas)
    })
  }

  loadTableColumns(estados, tipo_pagadores, lineas) {
		this.headerTable = [
			{
				headerName: 'N°',
        field: 'index',
				// pipe: 'indexcol',
				sortable: true,
			},
      {
        headerName: 'Ejecutivo',
        class: 'text-center',
        field: 'ejecutivo',
        sortable: true,
      },
      {
        headerName: 'Fecha',
        class: 'text-center',
        field: 'fecha',
        sortable: true,
        pipe: "date",
        filterable: true,
        filterDate: true,
        filterProp: 'fecha'
      },
      {
        headerName: 'Nro. aplicación',
        class: 'text-center',
        field: 'nro_recaudacion',
        filterProp: 'nro_recaudacion',
        sortable: true,
        filterable: true,
        filterInput: true,
      },
      {
        headerName: 'Nro Operación',
        field     : 'nro_operacion',
        filterProp: 'nro_operacion',
        sortable  : true,
        class: 'text-center',
        filterable: true,
        filterInput: true,
      },
      {
        headerName: 'RUC',
        class: 'text-center',
        field: 'cliente_ruc',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'cliente_ruc'
      },
      {
        headerName: 'Cliente Nombre',
        class: 'text-center',
        field: 'cliente_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'cliente_nombre'
      },
      {
        headerName: 'RUC',
        class: 'text-center',
        field: 'deudor_ruc',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'deudor_ruc'
      },
      {
        headerName: 'Deudor Nombre',
        class: 'text-center',
        field: 'deudor_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'deudor_nombre'
      },
      {
        headerName: 'Monto Soles',
        class: 'text-center',
        field: 'monto_soles',
        // sortable: true,
        // filterable: true,
        // filterInput: true,
        // filterProp: 'id'
                pipe      : 'function',
				function : function(row){
					let retorno;
          if(!row.monto_soles) return `S/ 0`
          let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.monto_soles))
          retorno = `S/ ${number}`
					return retorno;
				},
      },
      {
        headerName: 'Monto Dólares',
        class: 'text-center',
        field: 'monto_dolares',
        // sortable: true,
        // filterable: true,
        // filterInput: true,
        // filterProp: 'id',
        pipe      : 'function',
				function : function(row){
					let retorno;
          if(!row.monto_dolares) return `$ 0`
          retorno = `$ ${Number(row.monto_dolares).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno;
				},
      },
      {
        headerName: 'Estado',
        class: 'text-center',
        field: 'estado',
        sortable: true,
        filterable: false,
      },
      {
        headerName: 'Saldo',
        class: 'text-center',
        field: 'saldo',
        // sortable: true,
        // filterable: true,
        // filterInput: true,
        // filterProp: 'id'
                pipe      : 'function',
				function : function(row, header){
          if (!row.saldo) {
            return 0
          }

          if (row.moneda == 1) {
            let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(row.saldo))
            return `S/ ${number}`
          }
          else {
            return `$ ${Number(row.saldo).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
          }
				},
      },
      {
        headerName: 'IGV',
        class: 'text-center',
        field: 'igv',
        sortable: true,
        pipe: 'function',
        function: function (row, header) {
          if (!row.igv) {
            return 0
          }

          if (row.moneda == 1) {
            let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(row.igv))
            return `S/ ${number}`
          }
          else {
            return `$ ${Number(row.igv).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
          }
        },
      },
      {
        headerName: 'Intereses Mora',
        class: 'text-center',
        field: 'interes_mora',
        sortable: true,
        pipe: 'function',
        function: function (row, header) {
          if (!row.interes_mora) {
            return 0
          }

          if (row.moneda == 1) {
            let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(row.interes_mora))
            return `S/ ${number}`
          }
          else {
            return `$ ${Number(row.interes_mora).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
          }
        },
      },
      {
        headerName: 'Documento de Cartera',
        class: 'text-center',
        field: 'monto_documento_cartera',
        // sortable: true,
        // filterable: true,
        // filterInput: true,
        // filterProp: 'id'
                pipe      : 'function',
				function : function(row, header){
          if (!row.monto_documento_cartera) {
            return 0
          }

          if (row.moneda == 1) {
            let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(row.monto_documento_cartera))
            return `S/ ${number}`
          }
          else {
            return `$ ${Number(row.monto_documento_cartera).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
          }
				},
      },
      {
        headerName: 'Otros Conceptos',
        class: 'text-center',
        field: 'otros_conceptos',
        sortable: true,
      }
    ];
  }

  loadTable(data) {

    this.rowData = functions.indextable( data )

		this.pageTableActual   = data.page_number
		this.pageTableTotal    = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages    = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  getReporteCXC({page, per_page, filtros}) {
    this.factoringService.getReporteExcedentesDevolver(
      filtros.nro_operacion,
      filtros.nro_recaudacion,
      filtros.fecha__gte,
      filtros.fecha__lte,
      filtros.cliente_nombre,
      filtros.cliente_ruc,
      filtros.deudor_nombre,
      filtros.deudor_ruc,
      filtros.tipo_pagador,
      filtros.estado,
      filtros.id,
      page, 
      per_page,
      ).then(res=>{
        this.loadTable(res)
    })
  }


  goToPage({page, per_page, filtros}){
    this.filtros = filtros
    this.getReporteCXC({page, per_page, filtros})
	}


  // Descargar el exel 
  async openExcelModal() {
    const result = await Swal.fire({
      title: 'Generar Excel',
      text: "Elige si deseas generar el excel de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    console.log(result)
    if (result.isDenied) {
      this.generarExcel(this.rowData)
    } else if(result.isConfirmed) {
      this.generarExcelCompleto()
    }
  }

  async generarExcelCompleto() {
    const page_size = 5000
    let page = 1
    let data = []
  
    
    this.generando = true
    while (this.generando) {
      try {
        let res = await this.factoringService.getReporteExcedentesDevolver(
          this.filtros.nro_operacion ? this.filtros.nro_operacion : undefined,
          this.filtros.nro_recaudacion ? this.filtros.nro_recaudacion : undefined,
          this.filtros.fecha__gte ? this.filtros.fecha__gte : undefined,
          this.filtros.fecha__lte ? this.filtros.fecha__lte : undefined,
          this.filtros.cliente_nombre ? this.filtros.cliente_nombre : undefined,
          this.filtros.cliente_ruc ? this.filtros.cliente_ruc : undefined,
          this.filtros.deudor_nombre ? this.filtros.deudor_nombre : undefined,
          this.filtros.deudor_ruc ? this.filtros.deudor_ruc : undefined,
          this.filtros.tipo_pagador ? this.filtros.tipo_pagador : undefined,
          this.filtros.estado ? this.filtros.estado : undefined,
          this.filtros.id ? this.filtros.id : undefined,
          page, 
          page_size,
          )

        data = data.concat(res['results'])
        page++
        if (res['page_number'] == res['num_pages']) {
          this.generando = false
          this.generarExcel(data)
        }
      } catch (ex) {
        console.log(ex)
        this.generando = false
      }
    }
  }

  generarExcel(data) {
    /* generate a worksheet */
    var ws = utils.json_to_sheet(this.newData(data));

    /* add to workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hoja 1");

    /* write workbook and force a download */
    writeFile(wb, `Reporte - ${new Date().toLocaleString()}.xlsx`);
  }

  newData(data){

    let newDatos = [];

    newDatos = data.map( item=>({

      ejecutivo	 : item.ejecutivo,
      fecha	 : item.fecha	,
      nro_recaudacion	 : item.nro_recaudacion	,
      nro_operacion	 : item.nro_operacion	,
      cliente_nombre	 : item.cliente_nombre	,
      cliente_ruc	 : item.cliente_ruc	,
      deudor_nombre	 : item.deudor_nombre	,
      deudor_ruc : item.deudor_ruc,
      monto_soles	 : item.monto_soles	,
      monto_dolares	 : item.monto_dolares	,
      saldo	 : item.saldo	,
      estado	 : item.estado	,
      igv	 : item.igv	,
      interes_mora	 : item.interes_mora	,
      monto_documento_cartera	 : item.monto_documento_cartera	,
      moneda	 : item.moneda	,
      otros_conceptos : item.otros_conceptos

      
    }))

    return newDatos;

  }

  // async generarExcelCompleto() {
  //   let { filtros } = this
  //   this.factoringService.getReporteExcedentesDevolver(
  //     filtros?.nro_operacion,
  //     filtros?.nro_recaudacion,
  //     filtros?.cliente_nombre,
  //     filtros?.cliente_ruc,
  //     filtros?.deudor_nombre,
  //     filtros?.deudor_ruc,
  //     filtros?.tipo_pagador,
  //     filtros?.estado,
  //     filtros?.id,
  //     1, 
  //     1000,
  //     ).then((res: any)=>{
  //      this.generarExcel(res.results)
  //   })
  // }

  // descargarExcelCompleto(){
  //   return new Promise<boolean>((resolve, rejects) => setTimeout(() => {
  //       this.factoringService.downloadExcelReporteCXC().then(
  //         (x: any) => {
  //           console.log(x);
  //           var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //           var downloadURL = window.URL.createObjectURL(blob);
  //           var link = document.createElement('a');
  //           link.href = downloadURL;
  //           link.download = `Reporte Excedentes Por Devolver.xlsx`;
  //           link.click();
  //           resolve(true)
  //         }).catch(err => {
  //           rejects(false)
  //         })
  //   }, 3000))
  // }

  // generarExcel(data) {
  //   /* generate a worksheet */
	// let reporte = this.cleanData(data)

  //   var ws = utils.json_to_sheet(reporte);

  //   /* add to workbook */
  //   var wb = utils.book_new();
  //   utils.book_append_sheet(wb, ws, "Hoja 1");

  //   /* write workbook and force a download */
  //   writeFile(wb, `Reporte - ${new Date().toLocaleString()}.xlsx`);
  // }


  // cleanData(data){
  //   let columnas = [
  //     'ejecutivo',
  //     'fecha',
  //     'nro_recaudacion',
  //     'nro_operacion',
  //     'cliente_ruc',
  //     'cliente_nombre',
  //     'deudor_ruc',
  //     'deudor_nombre',
  //     'monto_soles',
  //     'monto_dolares',
  //     'estado',
  //     'saldo',
  //     'igv',
  //     'interes_mora',
  //     'monto_documento_cartera',
  //     'otros_conceptos',
  //   ]
  //   let reporte = data.map((item)=>{
  //     let retorno = {}
  //     columnas.forEach((col)=>{
  //       retorno[col] = item[col]
  //     })
  //     return retorno
  //   })
  
  //   return reporte.map((item)=>{
  //     for(let i in item){
  //       if(!item[i]){
  //         item[i] = 0
  //       }
  
  //     }
  
  //     return item
  //   })
  //   }

}
