import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { AdminLayoutComponent } from 'app/layouts/admin/admin-layout.component';
import { ReporteAplicacionesExcedentesComponent } from '../pages/reporte-aplicaciones-excedentes/reporte-aplicaciones-excedentes.component';
import { ReporteCambioLineaXclienteComponent } from '../pages/reporte-cambio-linea-xcliente/reporte-cambio-linea-xcliente.component';
import { ReporteCarteraComponent } from '../pages/reporte-cartera/reporte-cartera.component';
import { ReporteCuadroConsolidadoComponent } from '../pages/reporte-cuadro-consolidado/reporte-cuadro-consolidado.component';
import { ReporteCxcComponent } from '../pages/reporte-cxc/reporte-cxc.component';
import { ReporteExcedentesPageComponent } from '../pages/reporte-excedentes-page/reporte-excedentes-page.component';
import { ReporteExcedentesComponent } from '../pages/reporte-excedentes/reporte-excedentes.component';
import { ReporteExedentesDevueltosComponent } from '../pages/reporte-exedentes-devueltos/reporte-exedentes-devueltos.component';
import { ReporteSabanaComponent } from '../pages/reporte-sabana/reporte-sabana.component';
import { ReportelineacreditoComponent } from '../pages/reportelineacredito/reportelineacredito.component';
import { ReporteliquidacionComponent } from '../pages/reporteliquidacion/reporteliquidacion.component';
import { ReportesPagosComponent } from '../pages/reportes-pagos/reportes-pagos.component';
import { ReportesPagos2Component } from '../pages/reportes-pagos2/reportes-pagos2.component';
import { ReportesComponent } from '../pages/reportes/reportes.component';
import { ReporteverificacionComponent } from '../pages/reporteverificacion/reporteverificacion.component';
import { ReporteverificacionpaginaComponent } from '../pages/reporteverificacionpagina/reporteverificacionpagina.component';
import { TotalReportComponent } from '../pages/reporte-total-report/total-report.component';
import { ReporteCreditosFactoringComponent } from '../pages/reporte-creditos-factoring/reporte-creditos-factoring.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Reportes'
    },
    children: [
      {
        path: 'reportes/total-report',
        component: TotalReportComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Total Report'
        }
      },
      {
        path: 'reportes/cartera',
        component: ReporteCarteraComponent,
        data: {
          breadcrumb: 'Reporte Cartera'
        }
      },
      {
        path: 'reportes/cliente',
        component: ReportesComponent,
        data: {
          breadcrumb: 'Reportes'
        }
      },
      {
        path: 'reportes/cxc',
        component: ReporteCxcComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Reporte de Cuentas por cobrar'
        }
      },
      {
        path: 'reportes/sabana',
        component: ReporteSabanaComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Reporte'
        }
      },
      {
        path: 'reportes/apl-excedentes',
        component: ReporteAplicacionesExcedentesComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Reporte Aplicaciones Excedentes'
        }
      },
      {
        path: 'reportes/verificacion',
        component: ReporteverificacionComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Reporte de Verificación'
        }
      },
      {
        path: 'reporte/excedentes',
        component: ReporteExcedentesComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Reporte Excedente por Devolver'
        }
      },  
      {
      path: 'reporte/cambioLineaXCliente',
      component: ReporteCambioLineaXclienteComponent,
      canActivate: [AuthGuard],
      data: {
        breadcrumb: 'Reporte de Cambios de línea'
        }
      },   
      {
        path: 'reporte/excedentes-devueltos',
        component: ReporteExedentesDevueltosComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Reporte Excedente por Devolver'
        }
      },
      {
        path: 'reporte/cuadroConsolidado',
        component: ReporteCuadroConsolidadoComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Reporte Consolidado de Excedentes'
        }
      },
      {
        path: 'reporte/creditosFactoring',
        component: ReporteCreditosFactoringComponent,
        canActivate: [AuthGuard],
        data: {
          breadcrumb: 'Reporte Créditos Factoring'
        }
      },
    ]
  },
  {
    path: 'reporte/deuda/:moneda/:ruc/:tipo/:tipocambio',
    component: ReportesPagosComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Reporte de Deudas'
    }
  },
  {
    path: 'reporte/pagos/:moneda/:ruc/:tipo/:tipocambio',
    component: ReportesPagos2Component,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Reporte de Pagos'
    }
  },
  {
    path: 'reporte/liquidacion',
    component: ReporteliquidacionComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Reporte de Deuda y Pagos'
    }
  },
  {
    path: 'reporte/linea_credito',
    component: ReportelineacreditoComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Reporte de Línea de Crédito'
    }
  },
  {
    path: 'reporte/verificacion/tabla',
    component: ReporteverificacionpaginaComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Reporte de Deuda y Pagos'
    }
  },
  {
    path: 'reporte/excedentes/page',
    component: ReporteExcedentesPageComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Reporte de Excedentes'
    }
  }, 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportesRoutingModule { }
