import { Component, OnInit } from '@angular/core';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { ManageBancosComponent } from '../modals/manage-bancos/manage-bancos.component';
import { functions } from '../../../core/helpers/functions';

@Component({
  selector: 'app-gestion-bancos',
  templateUrl: './gestion-bancos.component.html',
  styleUrls: ['./gestion-bancos.component.css']
})
export class GestionBancosComponent implements OnInit {
  faSyncAlt  = faSyncAlt;
  faChartPie = faChartPie;
  faPlus     = faPlus;
  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public user             : any;
	public identidad        : any     = {};
	public searchstring     : string = '';
	public canCreate: boolean = false;

  constructor(
    private configurationService  : ConfigurationService,
    public modalService           : NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerBancos();
  }

  loadTable(data) {
    this.rowData = functions.indextable( data )
    // this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }
    
  initVars() {
    let columns = [
      {
        headerName: 'N°', 
        field     : 'index' ,
        sortable  : true,
      },
      {
        headerName: 'Nombre', 
        field     : 'nombre',
        class     : 'text-center',
        sortable  : true,
        filterable: true,
        filterProp: 'nombre',
        filterInput: true,
      },
      {
        headerName: 'Código', 
        field     : 'codigo',
        class     : 'text-center',
        sortable  : true,
      },
    ];
  
    this.columnDefs = columns;
  }

  obtenerBancos(nombre = '', page = 1, page_size = 10) {
    let bancos = this.configurationService.obtenerBancos(nombre, page, page_size);

    Promise.all([bancos]).then((res :any[])=>{
      let bancos  = res[0];
      this.initVars();
      this.loadTable(bancos);
    });
  }

  crear() {
    const modalRef = this.modalService.open(ManageBancosComponent, {
    });

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerBancos();
		},
		error => {

		});
  }

  tableEditEvent(row) {
    const modalRef = this.modalService.open(ManageBancosComponent, {
    });

    modalRef.componentInstance.bancoId = row.id;

    modalRef.componentInstance

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			this.obtenerBancos();
		},
		error => {

		});
  }

  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Banco';
		modalRef.componentInstance.message = '¿Seguro que desea remover el Banco?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.configurationService.elimiarBanco(row.id)
			.then(result => {
			this.obtenerBancos();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  goToPage({page,per_page,filtros}) {
    this.obtenerBancos(filtros.nombre, page, per_page);
  }
  
}
