import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { AddFormulaComponent } from './partials/add-formula/add-formula.component';

@Component({
  selector: 'app-formulas',
  templateUrl: './formulas.component.html',
  styleUrls: ['./formulas.component.css']
})
export class FormulasComponent implements OnInit {
	public columnDefs: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
  	public rowData: any[] = [];
	public searchstring: string = '';
  
  constructor(
    public formbuild        : FormBuilder,
    private clientesService	: ClientesService,
    private router          : Router,
    public toast            : ToastService, 
	public modalService     : NgbModal,
	private configurationService  : ConfigurationService,

  ) { }

  ngOnInit(): void {
	this.initTable()
    this.getData()
  }

  getData(){
	let formulas = this.configurationService.obtenerFormulasMaestras()

	Promise.all([formulas]).then((res: any[])=>{
		console.log(res[0])
		this.loadTable(res[0])
	})
  } 
  
  initTable(){
    this.columnDefs = [
			{
				headerName: 'Descripción',
				field: 'descripcion',
				sortable: true,
				filterable: false,
      },
      {
				headerName: 'Fórmula',
				field: 'formula',
				sortable: true,
				filterable: false,
      },
      {
				headerName: 'Afecto IGV',
				field: 'afecto_igv',
				sortable: true,
				pipe: "checkbox",
				checkDisabled: true,
				filterable: false,
      },
      {
				headerName: 'Estado',
				field: 'estado',
				sortable: true,
				pipe: "checkbox",
				checkDisabled: true,
				filterable: false,
      },
      {
				headerName: 'Grupo',
				field: 'grupo_descripcion',
				sortable: true,
				filterable: false,
      },
      {
				headerName: 'Moneda',
				field: 'moneda_descripcion',
				sortable: true,
				filterable: false,
      },
    ]
  }

	goToPage({page,per_page,filtros}){
		let request;

		let dataResult = {
			count      : 0,
			next       : null,
			num_pages  : 1,
			page_number: 1,
			per_page   : 10,
			previous   : null,
			results    : []
		};

		const range = (filtros.valueFrom && filtros.valueTo) ? `${filtros.valueFrom},${filtros.valueTo}` : '';

		// if (filtros.operacion > 0) {
		// 	this.factoringService.obtenerOperacionBandeja(filtros.operacion)
		// 		.then(res => {
		// 			dataResult.results.push(res);
		// 			this.loadTable(dataResult);
		// 			return;
		// 		}).catch(error => {
		// 			console.error(error);
		// 			this.loadTable(dataResult);
		// 			return;
		// 		});
		// 	return;
		// }

		request = this.configurationService.obtenerFormulasMaestras(page,per_page)

		request.then((res) => {
			this.loadTable(res)
		})
	}  

  loadTable(data) {
		this.rowData           = data.results
		this.pageTableActual   = data.page_number
		this.pageTableTotal    = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages    = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}

  }

  openModal() {

	const modalRef = this.modalService.open(AddFormulaComponent, {
		size: 'lg'
	});
	
	modalRef.componentInstance.editable = false;

	modalRef.result.then((result) => {
	console.log(result);
	}, (reason) => {
		console.log(reason);
	});

	modalRef.componentInstance.successEmit.subscribe(result => {
		if(result){
			this.modalService.dismissAll()
			this.ngOnInit()
		}
	},
	error => {

	});

}
  
  tableEditEvent(row){
		const modalRef = this.modalService.open(AddFormulaComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.formulaItem = row;
		modalRef.componentInstance.editable = true;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			if(result){
				this.modalService.dismissAll()
				this.ngOnInit()
			}
		},
		error => {

		});
	}

	destroy(row) {
		const modalRef = this.modalService.open(ConfirmModalComponent, {
			
		});

		modalRef.componentInstance.title = 'Remover Fórmula';
		modalRef.componentInstance.message = '¿Seguro que desea remover la fórmula?';

		modalRef.result.then((result) => {
		if (result) {
			this.configurationService.eliminarFormulaMaestra(row.id).then((res:any)=>{
				this.ngOnInit()
			})
			// Hace algo si true
		}
		}, (reason) => {
			console.log(reason);
		});
	}

}
