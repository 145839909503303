import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx'

import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { AceptantesService } from 'app/core/services/factoring/aceptantes.service';
import { CriteriosRiesgosComponent } from '../../modals/criterios-riesgos/criterios-riesgos.component';
import { concat } from 'rxjs';
import { toArray } from 'rxjs/operators';

@Component({
  selector: 'app-aceptantes-sin-riesgos',
  templateUrl: './aceptantes-sin-riesgos.component.html',
  styleUrls: ['./aceptantes-sin-riesgos.component.css']
})
export class AceptantesSinRiesgosComponent implements OnInit {

  exportando = false
  eliminando = false
  tblRows: any[] = []
  tblHeaders: columnHeader[]
  tblTotalRows: number
  tblPages: number[]
  tblActualPage: number = 1
  tblPerPage: number = 10
  tblFiltros = {}
  tblAcciones = []

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private modalService: NgbModal,
    private aceptantesService: AceptantesService,
  ) { }

  ngOnInit(): void {
    this.initTablaAceptantes()
  }

  loadDataTablaAceptantes({ page = this.tblActualPage, per_page = this.tblPerPage, filtros = this.tblFiltros }) {
    this.spinner.show()
    Object.keys(filtros).filter(field => field.includes('fecha')).forEach(field => filtros[field] = filtros[field].split(' ')[0])
    filtros['sin_riesgo'] = true // Mostrar solo aceptantes sin riesgo
    // Orden de listado
    filtros['ordering'] = '-fecha_actualizacion'
    this.tblFiltros = filtros
    return this.aceptantesService.getDeudores(Object.assign({ page, page_size: per_page }, filtros)).subscribe(
      (data: any) => {
        this.tblRows = data.results
        this.tblTotalRows = data.count
        this.tblPages = Array.from(Array(data.num_pages).keys())
        this.tblPerPage = data.per_page
        this.tblActualPage = data.page_number
      },
      (res: any) => console.log(res)
    ).add(() => this.spinner.hide())
  }

  openModalCriterios() {
    const modal = this.modalService.open(CriteriosRiesgosComponent, {
      size: "md",
    })

    modal.result.then(
      (result) => {
        if (result) {
        }
      },
      (reason) => { },
    )
  }

  async onSelectFileImportar(ev) {
    if (ev.target.files.length > 0) {
      this.spinner.show()
      const file = ev.target.files[0]
      ev.target.value = null
      try {
        await this.importar(file)
      } finally {
        this.spinner.hide()
      }
    }
  }

  async importar(file) {
    const buffer = await file.arrayBuffer()
    const wb = XLSX.read(buffer, { type: 'buffer', cellDates: true })
    const ws = wb.Sheets[wb.SheetNames[0]]
    const data: any = XLSX.utils.sheet_to_json(ws)

    this.aceptantesService.importarDeudoresSinRiesgo(data).subscribe(
      (data: any) => {
        let error = data.filter(e => e.error).map(e => e.error)
        if (error.length > 0) {
          this.aceptantesService.toast.warning(error)
        }
        this.loadDataTablaAceptantes({})
      },
      res => console.log(res),
    )
  }

  async exportarDataTablaAceptantes() {
    const lote = 200

    this.exportando = true
    this.aceptantesService.getDeudores(Object.assign({ 'page_size': 1 }, this.tblFiltros)).subscribe(
      data => {
        if (data['count'] > 1000) {
          this.aceptantesService.toast.warning('No se puede exportar más de 1000 registros. Use los filtros para reducir registros')
          this.exportando = false
          return
        }
        let pages = Math.ceil(data['count'] / lote)
        let pages$ = Array.from({ length: pages }).map((_, i) => i + 1).map(
          page => this.aceptantesService.getDeudores(Object.assign({ page, 'page_size': lote }, this.tblFiltros))
        )
        concat(...pages$).pipe(toArray()).subscribe((data: any) => {
          data = data.map((x: any) => x.results).flat(1)
          data = data.map(x => {
            let row = {
              'ruc': x['ruc'],
              'nombre': x['nombre'],
              'exposicion': x['maxima_exposicion'],
            }
            return row
          })
          if (data.length == 0) {
            data.push({'ruc': null, 'nombre': null, 'exposicion': null})
          }
          let ws = XLSX.utils.json_to_sheet(data)
          let wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, 'Aceptantes')
          XLSX.writeFile(wb, 'Aceptantes curso normal.xlsx')
        }).add(() => this.exportando = false)
      },
      res => {
        console.log(res)
        this.exportando = false
      }
    )
  }

  async eliminarSeleccionados() {
    this.spinner.show()
    const seleccionados = this.tblRows.filter(e => e['_selected_'])
    if (seleccionados.length > 0) {
      this.aceptantesService.eliminarDeudoresSinRiesgo(seleccionados.map(e => e.id)).subscribe(
        data => {
          this.spinner.hide()
          this.loadDataTablaAceptantes({}).add(() => this.eliminando = false)
        },
        res => {
          this.spinner.hide()
          this.eliminando = false
          console.log(res)
        }
      )
    } else {
      this.eliminando = false
      this.spinner.hide()
    }
  }

  selectedItemTabla(row) {
    if (row['_selected_']) {
      row['_selected_'] = false
    } else {
      row['_selected_'] = true
    }
  }

  private initTablaAceptantes() {
    this.tblHeaders = [
      {
        headerName: 'ID',
        field: 'id',
        class: 'text-center',
        filterable: false,
        filterProp: 'id',
        filterInput: true,
      },
      {
        headerName: 'RUC',
        field: 'ruc',
        filterable: true,
        filterProp: 'ruc__icontains',
        filterInput: true,
      },
      {
        headerName: 'Nombre',
        field: 'nombre',
        filterable: true,
        filterProp: 'ruc_nombre__icontains',
        filterInput: true,
      },
      {
        headerName: 'Exposición',
        field: 'maxima_exposicion',
        class: 'text-right',
        pipe: 'currency',
        moneda: 'moneda_maxima_exposicion',
        filterable: false,
        filterProp: 'monto',
        filterRange: true
      },
    ]
    this.loadDataTablaAceptantes({})
  }

}
