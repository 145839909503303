import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { FormBuilder, FormGroup, Validators, FormControl} from "@angular/forms";
import { concat, Observable, of, Subject } from 'rxjs';
import { RecaudacionService } from "app/core/services/factoring/recaudacion.service";
import {SelectInput, SelectItem} from "app/shared/form-select/form-select.component";
import {
  filter,
  distinctUntilChanged,
  debounceTime,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { element } from 'protractor';
@Component({
  selector: 'app-modal-alertas',
  templateUrl: './modal-alertas.component.html',
  styleUrls: ['./modal-alertas.component.css']
})
export class ModalAlertasComponent implements OnInit {
  // Utils - Iconos
  faTimes                     = faTimes

  // Utils - General
  public is_open_periodo:boolean;
  readOnlyForm = false;
  is_historial: boolean = false;

  // Utils - Filtro Cliente
  searchText$              = new Subject<string>();
  beneficiarioSelect       : any;
  searchTextBene           = "";
  beneResults$             : Observable<any[]>;
  caracteresMinimos        : number  = 4;
  loadingSearch            : boolean = false;

  // Formularios
  public alertaForm: FormGroup;
  public puntuacionesForm: FormGroup;
  
  // Listas
  public puntuaciones = []
  public historial: any[] = [];
  public items_selected: any[] = []; 
  public selectedPuntuaciones: any[]=[];
  private plantillaRiesgoData: any = {}; 

  // Inputs
  @Input() plantillaRiesgo: any;
  @Input() plantillaRiesgoId: number = null;

  //variables
  public selectInputs: any[] = [];

 
  constructor(
    private formBuilder       : FormBuilder,
    public activeModal        : NgbActiveModal,
    private recaudacionService: RecaudacionService,
    private lavadoActivosService: LavadoActivosService,
  ) { }
  ngOnInit(): void {
    this.createAlertaForm();
    if(this.plantillaRiesgoId){
      if(this.is_open_periodo){
        this.obtenerPlantillaRiesgo()
      }
      else{
        this.obtenerHistorialPlantillaRiesgo();
      }
    }
   
    this.loadSeachBeneficiarios();

  }
  createAlertaForm(){
    // Inicializamos el formulario
    this.alertaForm = this.formBuilder.group({
      cliente    : [null, [Validators.required]],
      cliente_nombre    : ['', [Validators.required]],
      cliente_ruc: ['', [Validators.required]],
      tipo: [null, [Validators.required]]
    });
    this.puntuacionesForm = this.formBuilder.group({})
  }
  changeCliente(resultado) {
    if(resultado){
      this.alertaForm.controls["cliente_nombre"].setValue(resultado['nombre']);
      this.alertaForm.controls['tipo'].setValue(resultado['tipo_persona']==1 ? 'Natural': 'Juridico');
      //
      this.plantillaRiesgoData['cliente'] = resultado['id']
      this.plantillaRiesgoData['tipo'] = resultado['tipo_persona']
    }
  }
  getPuntuacionData() {
    this.puntuacionesForm.enable();
    let data = [];
    this.items_selected.forEach(item => {
      data.push({
        'id': item.id,
        'valor': item.value, // guardar "valor" con el value del input
      })

    });
    return data;
  }
  addSelectInput(item){
    let selectInput: SelectInput = {
      id: item.id,
      default_value: item.valor,
      label: item.variable.descripcion,
      validator_name: item.variable.descripcion,
      item_selected: {
        id: item.id, 
        label: item.valor_descripcion + ' - ' + item.score, 
        value: item.valor
      },
      classes: '',
      placeholder: 'Selecciona un elemento',
      is_disabled: false,
      formParent: this.puntuacionesForm,
      items:  [],
    }
    item.variable.valores.forEach(valor => {
      selectInput.items.push(
        {
          id: item.id,
          label: valor.descripcion + ' - ' + valor.puntuacion,
          value: valor.id,
        }
      )
    });
    this.selectInputs.push(selectInput);
  }
  buildPuntuacionData(puntuaciones: any[]){
    puntuaciones.forEach(item => {
      this.puntuacionesForm.addControl(item.variable.descripcion, new FormControl(
        item.valor, 
        Validators.required
        )
      );
      this.addSelectInput(item)
    });    
  }
  buildPuntuacionHistorialData(puntuaciones: any[]){
    puntuaciones.forEach(item => {
      this.selectedPuntuaciones[item.variable]=item.valor
      this.puntuacionesForm.addControl(item.variable, new FormControl(
        item.valor, 
        Validators.required
        )
      );
    });
  }
  // Seteamos los valores del formulario
  setForm(data) {
    const mapperDataPuntuaciones = {}
    data.puntuaciones.forEach(item => {
      mapperDataPuntuaciones[item.variable.descripcion]=item.valor_descripcion + ' - ' + item.score
    });
    this.alertaForm.controls["cliente_nombre"].setValue(data['cliente_nombre']);
    this.alertaForm.controls['tipo'].setValue(data['tipo_descripcion']);
    this.alertaForm.controls['cliente_ruc'].setValue(data['cliente_ruc']);
    
    for (const key in mapperDataPuntuaciones) {
      if (this.puntuacionesForm.controls[key]) {
        this.puntuacionesForm.controls[key].setValue(mapperDataPuntuaciones[key]);
      }
    }
  }
  validarPlantillaRiesgo(){
    return this.plantillaRiesgoId == null? false : true
  }
  async obtenerPlantillaRiesgo() {
    await this.lavadoActivosService
      .obtenerPlantillaRiesgo(this.plantillaRiesgoId)
      .then((res) => {
        this.selectInputs = [];
        this.plantillaRiesgo = res;
        this.puntuaciones = this.plantillaRiesgo.puntuaciones
        this.buildPuntuacionData(this.plantillaRiesgo.puntuaciones)
        this.setForm(this.plantillaRiesgo)
        
      })
      .catch((error) => {
        console.error(error);
      });
    
  }
  obtenerHistorialPlantillaRiesgo() {
    this.is_historial = true;
    let historial = this.lavadoActivosService.
    obtenerHistorialPlantillaRiesgo(this.plantillaRiesgoId)
    Promise.all([historial]).then((res: any[]) => {
      this.plantillaRiesgo = res[0];
      this.puntuaciones = this.plantillaRiesgo.puntuaciones
      this.buildPuntuacionHistorialData(this.plantillaRiesgo.puntuaciones)
      this.setForm(this.plantillaRiesgo);
    });  
  }
  refreshPlantillaRiesgo(plantillaRiesgo: any){
    this.plantillaRiesgo = plantillaRiesgo;
    this.plantillaRiesgoId = plantillaRiesgo.id;
    this.obtenerPlantillaRiesgo()
  }
  enviarPlantilla(){
    let data = [];
    if(this.plantillaRiesgo && this.plantillaRiesgoId){
      var puntuaciones = this.getPuntuacionData();
      data = this.plantillaRiesgo;
      data['puntuaciones'] = puntuaciones;
      this.lavadoActivosService
      .pathPlantillasRiesgo(data)
      .then(async (plantillaRiesgo: any) => {
        this.refreshPlantillaRiesgo(plantillaRiesgo)
      });
      
    } else {
      data = this.plantillaRiesgoData;
      if (!data) {
        return
      }
      data['puntuaciones'] = []
      this.lavadoActivosService
      .postPlantillasRiesgo(data)
      .then(async (plantillaRiesgo: any) => {
        this.refreshPlantillaRiesgo(plantillaRiesgo)
      });
    }

  }
  loadSeachBeneficiarios() {
    this.beneResults$ = concat(
      of([]), // Items predeterminados
      this.searchText$.pipe(
        filter((res) => {
          return res !== null && res.length >= this.caracteresMinimos;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.loadingSearch = true)),
        switchMap((term) => {
          return this.recaudacionService
            .obtenerBeneficiariosObserver(term)
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => (this.loadingSearch = false))
            );
        })
      )
    );
  }
  async sincronizarPlantillaRiesgo(){
    await this.lavadoActivosService.sincronizarPlantillaRiesgo(this.plantillaRiesgoId)
    .catch((error) => {
      console.error(error);
    });
  }
  async captureHistorialPlantillaRiesgo(){
    await this.lavadoActivosService.captureHistorialPlantillaRiesgo(this.plantillaRiesgoId)
    .then((res: any[])=>{
      this.historial = res
    })
    .catch((error) => {
      console.error(error);
    });
  }
  getSelectInput(selectInput: SelectInput){
    /*Verificamos si hay un item seleccionado*/
    if(selectInput.item_selected){
      /* Verificamos si el item seleccionado existe en nuestra lista de items seleccionados*/
      var index = this.items_selected.findIndex(item => item.id == selectInput.id)
      /*Verifica si se encontró un elemento:*/
      if (index >= 0) {
        /*Reemplazamos el item*/
        this.items_selected[index] = selectInput.item_selected;
      }else{
        /*Agregamos el item seleccionado*/
        this.items_selected.push(selectInput.item_selected);
      }
    }
  }
}
