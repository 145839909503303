import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentosCarteraRoutingModule } from './documentos-cartera-routing.module';
import { DocumentosCarteraComponent } from './pages/documentos-cartera/documentos-cartera.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { ArchwizardModule } from "angular-archwizard";
import { SharedModule } from "app/shared/shared.module";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { UiSwitchModule } from "ngx-ui-switch";
import { DocumentosCarteraModalComponent } from './components/documentos-cartera-modal/documentos-cartera-modal.component';
import { ModalConfirCorreosComponent } from './pages/modal-confir-correos/modal-confir-correos.component';
import { GestionCobranzasComponent } from './pages/gestion-cobranzas/gestion-cobranzas.component';
import { QuillModule } from 'ngx-quill';
import { ModalExclusionesDocsComponent } from './pages/modal-exclusiones-docs/modal-exclusiones-docs.component';
import { TablaDocsComponent } from './pages/tabla-docs/tabla-docs.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [DocumentosCarteraComponent,DocumentosCarteraModalComponent, ModalConfirCorreosComponent, GestionCobranzasComponent, ModalExclusionesDocsComponent, TablaDocsComponent],
  imports: [
    QuillModule.forRoot(),
    CommonModule,
    DocumentosCarteraRoutingModule,
    FontAwesomeModule,
    SharedModule,
    JwBootstrapSwitchNg2Module,
    NgbModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    ArchwizardModule,
  ],
})
export class DocumentosCarteraModule { }
