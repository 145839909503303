import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-mini-alert',
	templateUrl: './mini-alert.component.html',
	styleUrls: ['./mini-alert.component.css']
})
export class MiniAlertComponent implements OnInit {
	@ViewChild('miniAlert', { static: false }) public miniAlert;
	@Input() title  		 = '';
	@Input() body  		  = '';
	@Input() truefalse = true;
	@Input() accept  	 = false;
	faTimes = faTimes;
	
	constructor(private modalService         : NgbModal) { }
	
	ngOnInit(): void {
	}
	
	
	displayAlert({
		title     = '',
		body      = '',
		truefalse = true,
		accept    = false
	}){
			this.title     = title;
			this.body      = body;
			this.truefalse = truefalse;
			this.accept    = accept;
			return this.modalService.open(this.miniAlert, {
				size: 'md'
			}).result
	}
	
}
