import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faCloudUploadAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { ExcedentesService } from 'app/core/services/excedentes/excedentes.service';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { AddRecaudacionModalComponent } from 'app/modules/recaudacion/modals/add-recaudacion-modal/add-recaudacion-modal.component';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { concat, Observable, of, Subject, BehaviorSubject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map, filter } from 'rxjs/operators';
import { ExcedentesModalComponent } from '../excedentes-modal/excedentes-modal.component';
import { ShowExcedentesModalComponent } from '../show-excedentes-modal/show-excedentes-modal.component';
import Swal from 'sweetalert2';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { AddCuentasAbonoComponent } from 'app/modules/clientes/modals/add-cuentas-abono/add-cuentas-abono.component';
import { ExcepcionDeudaLeasingComponent } from 'app/shared/modals/operaciones/excepcion-deuda-leasing/excepcion-deuda-leasing.component';
import { DevolucionEditarDetalleComponent } from '../devolucion-editar-detalle/devolucion-editar-detalle.component';

@Component({
  selector: 'app-devolucion-modal',
  templateUrl: './devolucion-modal.component.html',
  styleUrls: ['./devolucion-modal.component.css']
})
export class DevolucionModalComponent implements OnInit {
  faTimes = faTimes;
  active = 1;
  faCloudUploadAlt = faCloudUploadAlt;
  user: any;
  currentPagadorType : number = 0;

  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];

  @Input() devolucionId = 0;
	@Input() mostrarDeuda = false;
  devolucion: any;

  pagadores$: Observable<any>
  pagadoresInput$ = new Subject<string>()
  pagadoresLoading = false

  monedas: any[] = [];
  estados: any[] = [];
  myForm: FormGroup;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  detalles: any[] = [];

  // Cuentas de abono
  tblCuentasRows: any[] = []
  tblCuentasRowsReady : boolean = false
  tblCuentasHeaders: columnHeader[]
  tblCuentasTotalRows: number
  tblCuentasPages: number[]
  tblCuentasActualPage: number = 1
  tblCuentasPerPage: number = 10
  tblCuentasFiltros = {}
  tblCuentasAcciones = []

  /**
   * Comentarios
   */
  comments: any[] = [];

  // Orden de pago
  orderForm: FormGroup;
  public minDate: Date;
  bancos: any[] = [];
  formasPago: any[] = [];
  orden: any;
  file: File;
  peso: number;
  cuentasRecaudadoras: any[] = [];
  cuentasBeneficiario: any[] = [];

  aprobaciones: any[] = [];
  public AprobacionesrowData: any[] = [];
  public AprobacionesloadingTable: boolean = false;
  public AprobacionescolumnDefs: columnHeader[];

  public deudasRowData: any[] = [];
  public deudasLoadingTable: boolean = false;
  public deudasColumnDefs: columnHeader[];
  public tiposCambio: any = [];
  empresas: any[] = [];
  tieneDeudaVencida = false
  loadedDeudaVencida = false
  loadedDevolucion = false

  constructor(
    public modalService       : NgbModal,
    public formBuilder        : FormBuilder,
    public activeModal        : NgbActiveModal,
    public devolucionesService: ExcedentesService,
    public recaudacionService : RecaudacionService,
    public localService       : LocalServiceService,
    public authService        : AuthService,
    public factoring          : FactoringService,
    public toast              : ToastService,
    public router             : Router,
    public configs            : DocschecklistService,
  ) {
    this.initForm();
    this.initFormOrden();
    this.user = this.authService.user;
  }

  ngOnInit(): void {
    this.minDate = new Date();
    this.initTables();
    this.loadPagadores();
    this.obtenerData();
    this.factoring.obtenerEmpresas().then((res) => {
      this.empresas = res['results'];
    });
    if (this.devolucionId) {
      this.obtenerDevolucion();
    }

  }

  async obtenerDevolucion() {
    this.loadedDevolucion = false;
    this.devolucionesService.obtenerDevolucion(this.devolucionId)
      .then(res => {
        console.log("obtenerDevolucion: ", res);
        this.devolucion = res;
        this.AprobacionesrowData = res['aprobadores'];

        this.setForm(res);
        this.obtenerComentarios();
        this.obtenerDetalles();
        this.obtenerDataOrden();
        this.obtenerDeuda(this.devolucion.cliente, this.devolucion.aceptante);
        this.loadDataTablaCuentas({})

        if (res['estado'] == 3 || res['estado'] == 4) {
          this.ordenDePago();
        }

        this.currentPagadorType = !!res['aceptante'] ? 1 : !!res['cliente'] ? 2 : 0
        this.initTables();

      }).catch(error => {
        console.error(error);
      }).finally(() => {
        this.loadedDevolucion = true;
      })
  }
  async obtenerDevolucionUpdate() {
    this.loadedDevolucion = false;
    this.devolucion = await this.devolucionesService.obtenerDevolucion(this.devolucionId).finally(() => {this.loadedDevolucion = true})

    this.AprobacionesrowData = this.devolucion['aprobadores'];

    this.setForm(this.devolucion);
    this.obtenerComentarios();
    this.obtenerDetalles();
    this.obtenerDataOrden();
    this.obtenerDeuda(this.devolucion.cliente, this.devolucion.aceptante);
    this.loadDataTablaCuentas({})

    if (this.devolucion['estado'] == 3 || this.devolucion['estado'] == 4) {
      this.ordenDePago();
    }

    this.initTables();
  }

  obtenerData() {
    let monedas = this.recaudacionService.obtenerMonedas();
    let estados = this.devolucionesService.obtenerEstados();
    let tiposDeCambio = this.configs.obtenerTiposDeCambio(this.formatDate(new Date()));

    Promise.all([monedas, estados, tiposDeCambio]).then((res: any) => {
      this.monedas = res[0].results;
      this.estados = res[1].results;
      this.cargarTiposCambio(res[2].results[0])
    }).catch(error => {

    });

  }

  cargarTiposCambio(cambios) {
    if (cambios) {
      this.tiposCambio.push({
        descripcion: 'Contable',
        valor: cambios.contable
      })

      this.tiposCambio.push({
        descripcion: 'Compra',
        valor: cambios.compra
      })

      this.tiposCambio.push({
        descripcion: 'Venta',
        valor: cambios.venta
      })
    } else {
      console.log('No hay tipos de cambio')
      this.tiposCambio.push({
        descripcion: 'Contable',
        valor: 1
      })

      this.tiposCambio.push({
        descripcion: 'Compra',
        valor: 1.1
      })

      this.tiposCambio.push({
        descripcion: 'Venta',
        valor: 0.9
      })
    }

  }


  obtenerDataOrden() {
    let bancos = this.recaudacionService.obtenerBancosRecaudacion();
    let formasPago = this.recaudacionService.obtenerFormasPago();

    this.obtenerCuentas();

    Promise.all([bancos, formasPago]).then((res: any) => {

      this.bancos = res[0]['results'];
      this.formasPago = this.filterUserTeroreria(res[1]['results']);

    }).catch(error => {

    });
  }

  obtenerCuentas() {
    let cuentasCargo = this.recaudacionService.obtenerCuentasRecaudacion();
    let cuentasAbono = null

    if (this.devolucion.cliente) {
      cuentasAbono = this.recaudacionService.obtenerCuentasBeneficiarios(this.devolucion.cliente);
    }
    if (this.devolucion.aceptante) {
      cuentasAbono = this.recaudacionService.obtenerCuentasDeudores(this.devolucion.aceptante);
    }

    Promise.all([cuentasCargo, cuentasAbono]).then((res: any) => {
      this.cuentasRecaudadoras = res[0]['results'];
      this.cuentasBeneficiario = res[1]['results'];
    }).catch(error => {

    });
  }

  initForm() {
    this.myForm = this.formBuilder.group({
      cliente        : [null, []],
      propietario_ruc    : [null, []],
      propietario_nombre : [null, []],
      moneda         : [null, [Validators.required]],
      empresa        : [{ value:2 , disabled:true }, [Validators.required]],
      monto          : [0, []],
      estado         : [1, []],
      fecha_solicitud: [this.formarDate(this.parseDate(new Date())), []],
      fecha_contable  : [null, [Validators.required]],
      aceptante: [null, []],
      compensacion: [false, []]
    }, {
      validators: (group: AbstractControl):  ValidationErrors | null => { 
        let cliente = group.get('cliente').value
        let aceptante = group.get('aceptante').value
        return cliente || aceptante ? null : { propietario: true }
      }
    });
  }

  setForm(form) {
    for (const key in form) {

      if (this.myForm.controls[key]) {

        if (key === 'fecha_solicitud') {
          this.myForm.controls[key].setValue(this.formarDate(form[key]));
          continue;
        } else if (key === 'fecha_contable') {
          this.myForm.controls[key].setValue(this.formarDate(form[key]))
          continue;
        }

        this.myForm.controls[key].setValue(form[key]);
      }

    }
  }

  initFormOrden() {


    this.orderForm = this.formBuilder.group({
      forma_pago              : [null, [Validators.required]],
      cuenta                  : [null, [Validators.required]],
      cuenta_cargo            : [null, [Validators.required]],
      moneda_cargo            : [null, [Validators.required]],
      fecha_pago              : [null, [Validators.required]],
      nro_pago                : [null, [Validators.required]],
      moneda_abono            : [{ value: null, disabled: true }, [Validators.required]],
      cuenta_abono            : [{ value: null, disabled: true }, [Validators.required]],
      responsable_orden_nombre: [null, []],
      empresa                 : [1, []],
      banco_cargo             : [null, []],
      banco_abono             : [{ value: null, disabled: true }, [Validators.required]],
      adjunto                 : [null, []],
      cuentasAbono            : [null, []]
    });
    
  }

  changeCargos(cuenta) {
    console.log("cuenta cargo", cuenta)
    this.orderForm.get('moneda_cargo').setValue(cuenta.moneda);
    this.orderForm.get('cuenta_cargo').setValue(cuenta.numero);
    this.orderForm.get('banco_cargo').setValue(cuenta.banco);
  }

  changeAbono(cuenta) {
    console.log("cuenta abono", cuenta);
    this.orderForm.get('moneda_abono').setValue(cuenta.moneda);
    this.orderForm.get('cuenta_abono').setValue(cuenta.numero_cuenta);
    this.orderForm.get('banco_abono').setValue(cuenta.entidad_financiera);
  }

  setOrdenForm(form) {
    for (const key in form) {
     
      if (this.orderForm.controls[key]) {
        if (key == 'fecha_pago') {
          this.orderForm.controls[key].setValue(this.formarDate(form[key]));
          continue;
        }
        this.orderForm.controls[key].setValue(form[key]);
      }

    }
  }

  initTables() {
    let headerDocumentsCarteraTable = [
      {
        headerName: "N°",
        field: "id",
        sortable: true,
        pipe: 'indexcol'
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "tipo_documento_descripcion",
      },
      {
        class: "text-center",
        headerName: "Nro de Documento",
        field: "numero_documento",
      },
      {
        class: "text-center",
        headerName: "Excedente",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fch. aplicación",
        field: "fecha",
        pipe: "date",
        sortable: true,
      },
      {
        headerName: 'Aplicación',
        field: 'recaudacion',
        class: 'text-center',
        sortable: true,
        textField: 'recaudacion',
        pipe: 'actionable',
        actionableType: 'recaudacion',
      },
    ];

    let headerAprobacionesTable = [
      {
        headerName: "N°",
        field: "id",
        sortable: true,
        pipe: 'indexcol'
      },
      {
        class: "text-center",
        headerName: "Aprobador",
        field: "responsable_nombre",
      },
      {
        class: "text-center",
        headerName: "Fecha",
        field: "fecha",
        pipe: (this.devolucion && this.devolucion.estado != 5) ? "switch" : '',
        text: 'Aprobar',
        actionableType: 'Aprobar',
        sortable: true,
        idResponsable: this.user.id,
        responsableField: 'responsable',
        stateField: 'estado'
      },
    ];

    let headerDeudaTable = [
      {
        headerName: '',
        field: '',
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Vigente",
        field: "vigente",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Mora",
        field: "mora",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cuentas x Cobrar",
        field: "cxc",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
    ];

    this.tblCuentasHeaders = [
      {
				headerName: 'N°.', 
				field     : 'id' ,
				sortable  : true,
        columnaWidth : 10
			},
			{
				headerName: 'Banco', 
				field     : 'entidad_financiera_nombre' ,
				sortable  : true,
			},
			{
				headerName: 'Moneda', 
				field     : 'moneda_descripcion' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Número de Cuenta', 
				field     : 'numero_cuenta' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'CCI', 
				field     : 'numero_cuenta_cci' ,
				sortable  : true,
			},
    ]

    this.columnDefs = headerDocumentsCarteraTable;
    this.AprobacionescolumnDefs = headerAprobacionesTable;
    this.deudasColumnDefs = headerDeudaTable;
  }

  onChangePropietario(propietario) {
    if (!propietario) return

    this.currentPagadorType = propietario.tipo; // 1 Aceptante, 2 Cliente
    this.myForm.controls[propietario.tipo == 1 ? 'aceptante' : 'cliente'].setValue(propietario.id);
    this.myForm.controls[propietario.tipo == 1 ? 'cliente' : 'aceptante'].setValue(null);
    this.myForm.controls['propietario_ruc'].setValue(propietario.ruc);
    this.obtenerDeuda(this.myForm.value.cliente, this.myForm.value.aceptante);
    this.active = 2;
  }

  onClearPropietario() {
    this.myForm.controls['cliente'].setValue('');
    this.myForm.controls['aceptante'].setValue('');
    this.myForm.controls['propietario_ruc'].setValue('');
  }

  get sumaMonto(): any {

    const valueInit = '0';

    if (!this.rowData.length)
      return parseFloat(valueInit).toFixed(2);

    if (this.devolucion) {
      return this.devolucion.monto;
    }

    let amount = 0;

    if (this.myForm.controls['moneda'].value == 1) {
      amount = this.rowData.reduce((acc, act) => {
        return parseFloat(acc) + parseFloat(act.saldo_soles);
      }, 0);
    } else {
      amount = this.rowData.reduce((acc, act) => {
        return parseFloat(acc) + parseFloat(act.saldo_dolares);
      }, 0);
    }

    return amount.toFixed(2)
  }

  get sumMontoDolar() {
    let amount = 0;

    amount = this.rowData.reduce((acc, act) => {
      return parseFloat(acc) + parseFloat(act.saldo_dolares);
    }, 0);
    return amount
  }

  async onSubmit() {

    console.log( this.myForm.value );

    if (this.myForm.invalid && this.rowData.length) {
      return;
    }

    // const montoValid = 20;

    // if (this.sumMontoDolar < montoValid) {
    //   this.toast.warning(`El monto debe ser mayor a ${montoValid}`);
    //   return;
    // }

    let user = this.authService.user;

    let data = {
      ...this.myForm.value,
      monto: this.sumaMonto,
      responsable: user.id
    };

    let detalles = this.rowData.map(item => {
      return {
        excedente: item.id,
        monto: item.saldo
      };
    });

    data['detalles'] = detalles;
    data['fecha_solicitud'] = this.parseDate(new Date());
    data['fecha_contable'] = this.parseDate( data['fecha_contable'] )

    if (this.tieneDeudaVencida) {
      const res = await this.openModalExcepcionDeudaLeasing()
      if (!res) return
    }

    this.devolucionesService.crearDevolucion(data)
      .then(res => {
        console.log("Oli!", res);
        this.successEmit.emit(res);
        this.devolucionId = res['id'];
        this.devolucion = res;
        this.obtenerDevolucion();
        // this.activeModal.close(true);
      }).catch(error => {
        console.error(error);
      });

  }

  rechazar() {

    this.devolucionesService.crearDevolucion({ estado: 5 }, this.devolucionId)
      .then(res => {
        this.obtenerDevolucion();
      }).catch(error => {

      });
  }

  showExcedentes() {
    const modalRef = this.modalService.open(ShowExcedentesModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });

    let cliente = this.myForm.controls['cliente'].value
    let aceptante = this.myForm.controls['aceptante'].value
    modalRef.componentInstance.propietario = aceptante || cliente;
    modalRef.componentInstance.tipoPropietario = aceptante ? 1 : 2
    modalRef.componentInstance.list           = this.rowData;
    modalRef.componentInstance.empresa        = this.myForm.controls['empresa'].value;

    modalRef.componentInstance.successEmit.subscribe(result => {
      // this.rowData.push(...result);
      this.rowData = result;
      this.myForm.controls['monto'].setValue(this.sumaMonto);
    },
      error => {

      });

    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  setValueMonto(e) {
    this.myForm.controls['monto'].setValue(this.sumaMonto);
  }

  destroy(row) {
    this.rowData = this.rowData.filter(item => item.id != row.id);
  }

  parseDate(date) {
    if (!(date instanceof Date)) {
      // Se realizo este ajuste porque no dejaba actualizar con fecha_pago
      const formatedDate = date.split("/")
      if (formatedDate.length != 3) return
      return (formatedDate[2] + "-" + (formatedDate[1]) + "-" + formatedDate[0]);
    };
    return (date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate());
  }

  formarDate(date) {
    if (!date)
      return;

    let arrDate = date.split('-');
    return `${arrDate[2]}/${arrDate[1]}/${arrDate[0]}`;
  }

  async obtenerDetalles() {

    await this.devolucionesService.obtenerDetalles(this.devolucionId.toString())
      .then(res => {
        this.rowData = this.mapperDetalles(res['results']);
      }).catch(error => {
        console.error(error);
      });

  }

  async obtenerDeuda(clienteId: number, aceptanteId: number) {
    this.mostrarDeuda = true // para renderizar la tabla deudas
    let promise = null
    if (aceptanteId) {
      promise = this.devolucionesService.obtenerDeudaDeudor(aceptanteId)
    } else {
      promise = this.devolucionesService.obtenerDeudaBeneficiario(clienteId)
    }
    await promise
      .then(res => {
        this.deudasRowData = this.mapperDeuda(res);
      }).catch(error => {
        console.error(error);
      });
  }

  mapperDetalles(array) {

    const arrayDetalles = array.map(element => {
      return {
        aceptante_nombre: element['aceptante_nombre'],
        tipo_documento_descripcion: element['tipo_documento_descripcion'],
        numero_documento: element['numero_documento'],
        saldo: element['monto'],
        moneda: element['moneda'],
        fecha: element['fecha_recaudacion'],
        recaudacion: element['recaudacion'],
      }
    });

    return arrayDetalles;

  }


  mapperDeuda(data) {
    let arr = [];
    arr.push({
      vigente: data['total_documentos_cartera_vigente_dolares'],
      mora: data['total_documentos_cartera_mora_dolares'],
      cxc: data['total_cuentas_cobrar_dolares'],
      moneda: 2
    });

    arr.push({
      vigente: data['total_documentos_cartera_vigente_soles'],
      mora: data['total_documentos_cartera_mora_soles'],
      cxc: data['total_cuentas_cobrar_soles'],
      moneda: 1
    });

    return arr;
  }

  /**
   * Comentarios
   */

  async obtenerComentarios() {

    let user = this.authService.user;

    await this.devolucionesService.obtenerComentarios(this.devolucionId.toString())
      .then(res => {
        this.comments = res['results'];
      }).catch(error => {
        console.error(error);
      });

  }

  crearComentario(comment) {

    let user = this.authService.user;

    const data = {
      solicitud_devolucion: this.devolucionId,
      comentario: comment,
      responsable: user.id
    };

    this.devolucionesService.crearComentario(data)
      .then(res => {
        this.obtenerComentarios();
      }).catch(error => {

      });
  }

  editaComentario(comment) {
    console.log(comment);
    this.devolucionesService.crearComentario(comment, comment.id)
      .then(res => {
        this.obtenerComentarios();
      }).catch(error => {

      });
  }

  deleteComment(comment) {

    this.devolucionesService.eliminarComentario(comment.id)
      .then(res => {
        this.obtenerComentarios();
      }).catch(error => {

      });

  }

  openRecaudacion(event) {

    const modalRef = this.modalService.open(AddRecaudacionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });

    modalRef.componentInstance.recaudacionId = event.actionableType;

    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  obtenerAprobaciones() {
    this.devolucionesService.obtenerAprobaciones(this.devolucionId.toString())
      .then(res => {
        console.log(res);
        this.aprobaciones = res['results'];
      }).catch(error => {
        console.error(error);

      });
  }

  lanzarModalConfirmacion(row){
    if(this.devolucion.monto < this.devolucion.monto_minimo){
      let { moneda } = this.devolucion
      const modalRef = this.modalService.open(ConfirmModalComponent, {});
      let mensaje = ``
      mensaje+=`<br><span class='message'>El monto de la devolución es menor a <strong>${this.devolucion.monto_minimo_original} Dólares </strong> <br> ¿Está seguro de que desea aprobar la operación?</strong></span>`
      modalRef.componentInstance.title = "El monto es menor al mínimo";
      modalRef.componentInstance.message = mensaje;
      modalRef.componentInstance.messageHTML = true;
      modalRef.result.then((result) => {
        if (result) {
          this.aprobar(row)
        }
      });
    } else {
      this.aprobar(row)
    }
  }

  async aprobar(row) {
    
    if(this.devolucion.estado == 1){
      var estado_solicitud = true
      var compensacion = false
      var data_solicitud =  await this.devolucionesService.obtenerDevolucionesEspecifica(this.devolucion.id)
      
      if(this.devolucion.compensacion){
        estado_solicitud = false
        compensacion = true
        await this.observacionSolicitud(this.devolucion.id, 5)
      }

      // ! Cuenta abono existente
      if(data_solicitud['cuentas_abono'].length < 1 && !compensacion){
        estado_solicitud = false
        await this.observacionSolicitud(this.devolucion.id, 1)
      }else{
        // ! Cuenta abono igual a solicitud
        var cuentas_ok = false
        let tCuentaPreferencial = this.tblCuentasRows.filter(x => x.selected);

        if(tCuentaPreferencial.length > 0){
          cuentas_ok = tCuentaPreferencial[0].moneda == this.devolucion.moneda;
        }else{
          for (let i = 0; i < data_solicitud['cuentas_abono'].length; i++) {
            if(data_solicitud['cuentas_abono'][i].moneda_id == this.devolucion.moneda){
              cuentas_ok = true
            }
          }
        }
        // Validacion
        if (cuentas_ok == false && !compensacion){
          estado_solicitud = false
          await this.observacionSolicitud(this.devolucion.id, 2)
        }
      }

      // ! CXC && Documento Cartera DEUDA de cliente o deudor (Deuda Factoring)
      var deudas = await this.obtenerDeudaFactoring(this.devolucion.cliente, this.devolucion.aceptante)
      if (deudas == false){
        estado_solicitud = false
        await this.observacionSolicitud(this.devolucion.id, 4)
      }

      // ! Deuda Leasing
      if(this.devolucion.aceptante){
        var deudaLeasing = await this.obtenerDeudaLeasing(this.devolucion.aceptante_ruc)
      }else{
        var deudaLeasing =  await this.obtenerDeudaLeasing(this.devolucion.cliente_ruc)
      }

      if (deudaLeasing == false){
        estado_solicitud = false
        await this.observacionSolicitud(this.devolucion.id, 3)
      }

      //!update final 
      if(estado_solicitud == false){
        await this.devolucionesService.crearDevolucion({ estado: 6}, this.devolucion.id)
        await this.obtenerDevolucionUpdate();
      }else{
        let data = {};
        let cuentaPreferencial = this.tblCuentasRows.filter(x => x.selected);
        if(cuentaPreferencial.length > 0){
          data = {
            solicitud_devolucion: this.devolucionId,
            responsable: this.user.id,
            cuenta_preferencial: cuentaPreferencial[0].id,
          };
        }else{
          data = {
            solicitud_devolucion: this.devolucionId,
            responsable: this.user.id
          };
        }        
        await this.devolucionesService.aprobarSolicitudDevolucion(data)
        await this.obtenerDevolucionUpdate();
      }
    }else{
      let cabecera        = this.devolucion.moneda_descripcion;
      let detalle         = this.rowData;
      let monedaDetalle   : string;
      const distintaMoneda: string[] = [""];

      // ! Validación Previa: Moneda Excedentes vs Solicitud
      detalle.forEach((item) => {
        monedaDetalle = (item.moneda) === 1 ? "Soles" : "Dólares";
        distintaMoneda.push(monedaDetalle);
      })

      distintaMoneda.push(cabecera);
      let limpiaMoneda = distintaMoneda.filter(element => element != "")
      if (limpiaMoneda.includes('Dólares') && limpiaMoneda.includes('Soles')) {
        const diferenteMonedaModalResult = await Swal.fire({
          title             : '¿Está seguro?',
          text              : "Existen excedentes de diferente moneda a la devolución ¿Desea continuar con la aprobación?",
          icon              : 'warning',
          showCancelButton  : true,
          confirmButtonColor: '#3085D6',
          cancelButtonColor : '#d33',
          confirmButtonText : 'Si, Aprobar'
        })

        if (!diferenteMonedaModalResult.isConfirmed) return
      }

      let data = {};
      let cuentaPreferencial = this.tblCuentasRows.filter(x => x.selected);
      if(cuentaPreferencial.length > 0){
        data = {
          solicitud_devolucion: this.devolucionId,
          responsable: this.user.id,
          cuenta_preferencial: cuentaPreferencial[0].id,
        };
      }else{data = {
          solicitud_devolucion: this.devolucionId,
          responsable: this.user.id
        };
      }   

      if (this.tieneDeudaVencida) {
        let res = await this.openModalExcepcionDeudaLeasing(true)
        if (res) {
          res = await this.devolucionesService.saveExcepcionDeudaLeasing(this.devolucionId, res).toPromise()
        }
        if (!res) return
      }

      this.devolucionesService.aprobarSolicitudDevolucion(data)
        .then(res => {
          this.obtenerDevolucion();
          this.successEmit.emit(res);
        }).catch(error => {
          console.error(error);
        });
      }

  }

  async obtenerDeudaLeasing(ruc) {
    let deudaLeasing = null
    let validacion_deudas = true

    deudaLeasing = await this.factoring.beneficiarioDeudaLeasing(ruc)
    
    if (deudaLeasing != null){
      if(deudaLeasing['length'] > 0){
        validacion_deudas = false
      }
    }

    return validacion_deudas
  }

async obtenerDeudaFactoring(clienteId: number, aceptanteId: number) {
    let promise = null
    let validacion_deudas = true
    if (aceptanteId) {
      promise = await this.devolucionesService.obtenerDeudaDeudor(aceptanteId)
    } else {
      promise = await this.devolucionesService.obtenerDeudaBeneficiario(clienteId)
    }

    var dc_soles = promise['total_documentos_cartera_mora_soles']
    var dc_dolares = promise['total_documentos_cartera_mora_dolares']
    var cxc_soles = promise['total_cuentas_cobrar_soles']
    var cxc_dolares = promise['total_cuentas_cobrar_dolares']

    if(dc_soles > 0 || dc_dolares > 0 || cxc_soles > 0 || cxc_dolares > 0){
      validacion_deudas = false
    }
    return validacion_deudas
  }

async aprobacionMasiva(data){
      var data_solicitud = {
        solicitud_devolucion: data.id,
        responsable: this.authService.user.id
      };
      await this.devolucionesService.aprobarSolicitudDevolucion(data_solicitud)
  }

async observacionSolicitud(solicitud_id, observacion_id){
    var data_solicitud_observacion = {
      solicitud_devolucion: solicitud_id,
      observacion_tipo: observacion_id
    };
    await this.devolucionesService.crearSolicitudObservaciones(data_solicitud_observacion)
}


  ordenDePago() {
    this.devolucionesService.obtenerOrdenDePago(this.devolucionId)
      .then(res => {
        if (res['results'].length) {
          this.orden = res['results'][0];
          console.log(this.orden);
          
          this.setOrdenForm(this.orden);
          if ([this.authService.perfil.perfilTesoreriaID, this.authService.perfil.perfilLiderDeTesoreriaID].indexOf(this.user.perfil) == -1) {
            this.orderForm.disable();
          }
        }
      }).catch(error => {
        console.error(error);

      });
  }

  openInputFile() {
    document.getElementsByName("input_adjunto")[0].click();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.peso = file.size;
      this.file = file;

    }
  }
  
  enviarOrdenDePago() {

    if (this.orderForm.invalid) {
      return;
    }

    const values = {...this.orderForm.getRawValue(), ...this.orderForm.value};
    const formData = new FormData();

    for (const key in values) {
      console.log(key, values[key])
      formData.append(key, values[key]);
    }

    formData.set('fecha_pago', this.parseDate(values['fecha_pago']));
    formData.append('responsable_orden', this.user.id);
    if (this.file) {
      formData.append('adjunto', this.file);
      formData.append('peso', this.file.size.toString());
    } else {
      formData.delete('adjunto')
    }

    this.devolucionesService.crearOrdenPago(formData, this.orden.id)
      .then(res => {
        console.log(res);
        this.obtenerDevolucion();
        this.successEmit.emit(res);
      }).catch(error => {
        console.error(error);

      });

  }

  goToReporte() {
    this.modalService.dismissAll();
    // this.router.navigate(['/reporte', 'deuda-pagos', this.devolucion.moneda, this.devolucion.cliente, 1, '', this.tiposCambio[0].valor]);
    let cliente = {
      cliente_id: this.myForm.value.cliente,
      cliente_ruc: this.myForm.value.propietario_ruc,
      cliente_nombre: this.myForm.value.propietario_nombre
    }
    if (!this.myForm.value.cliente) {
      cliente = null
    }
    this.localService.setJsonValue('cliente_reporte', cliente)
    this.router.navigate(['/reportes', 'cliente']);
  }

  formatDate(date) {
    if (!(date instanceof Date))
      return date;

    return date.getFullYear() +
      "-" + (date.getMonth() + 1) +
      "-" + date.getDate();
  }

  download(adjunto) {
    console.log(adjunto);
    // return;
    let urlDoc = adjunto.adjunto;
    const ultimoSlash = new URL(urlDoc).pathname;
    const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1];
    this.downloadFile(urlDoc, adjuntoName);
  }

  downloadFile(url, name) {
    this.configs.downloadFile(url).then(
      (x: any) => {
        console.log(x);
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = name;
        link.click();
      }, async (reason) => {
        console.log(reason);
      }), err => {
        console.error(err);
      };
  }

  get canEdit() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilAnalistaRiesgosID,
      this.authService.perfil.perfilJefeDeAdmisionID,
      this.authService.perfil.perfilAsesorLegalID,
      this.authService.perfil.perfilLegalID,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilSubGerenciaComercialID,
      this.authService.perfil.perfilTesoreriaID,
      this.authService.perfil.perfilLiderDeTesoreriaID,
      this.authService.perfil.perfilRecepcionID,
      this.authService.perfil.perfilAsistenteCobranzasID
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  filterUserTeroreria(data){

		if( this.authService.user.perfilLiderDeTesoreriaID === this.authService.user.perfil ||  this.authService.user.perfilTesoreriaID === this.authService.user.perfil ){

			return data.filter( item => item.id > 0 && item.id != 8 )

		}else{

			return data;
		}

	}

  loadDataTablaCuentas({ page = this.tblCuentasActualPage, per_page = this.tblCuentasPerPage, filtros = this.tblCuentasFiltros }) {
    this.tblCuentasFiltros = filtros
    let cuentasPromise = null
    if (this.devolucion.cliente) {
      cuentasPromise = this.recaudacionService.obtenerCuentasBeneficiarios(this.devolucion.cliente)
    }
    if (this.devolucion.aceptante) {
      cuentasPromise = this.recaudacionService.obtenerCuentasDeudores(this.devolucion.aceptante)
    }
    this.tblCuentasRowsReady = false;
    cuentasPromise.then((data: any) => {
        let tCuentasRows = data?.results || [];  
        let tCuentaRowPreferred = tCuentasRows.find(x => (x.numero_cuenta == this.devolucion.cuenta_abono && x.moneda == this.devolucion.cuenta_abono_moneda_id))?.id || -1;
        tCuentasRows.forEach(x => x.selected = x.id == tCuentaRowPreferred);
        this.tblCuentasRows = tCuentasRows;
        this.tblCuentasTotalRows = data.count
        this.tblCuentasPages = Array.from(Array(data.num_pages).keys())
        this.tblCuentasPerPage = data.per_page
        this.tblCuentasActualPage = data.page_number
      })
      .catch((res: any) => console.log(res))
      .finally(() => {
        this.tblCuentasRowsReady = true;
      })
      
  }

  openModalCuentaAbono(cuentaAbono = null) {
    const modalRef = this.modalService.open(AddCuentasAbonoComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.devolucion.cliente;
    modalRef.componentInstance.aceptanteId = this.devolucion.aceptante;
    modalRef.componentInstance.cuentaAbonoId = cuentaAbono?.id || 0;

		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			this.loadDataTablaCuentas({});
		},
		error => {

		});
  }

  deleteCuentaAbono(cuentaAbono) {
    const modalRef = this.modalService.open(ConfirmModalComponent, { });

		modalRef.componentInstance.title = 'Remover cuenta de abono';
		modalRef.componentInstance.message = '¿Seguro que desea remover esta cuenta?';
		
		modalRef.result.then((result) => {
      if (result) {
        this.recaudacionService.elimiarCuentaAbono(cuentaAbono)
        .then(result => {
          this.loadDataTablaCuentas({});
        })
        .catch(error => {
          console.error(error);
        });
      }
		}, (reason) => {
			console.log(reason);
		});
  }

  selectCuentaAbono(xcuentaAbono){ 
    console.log("CuentaAbono: ", xcuentaAbono);
    // let tCuentaAbono = xcuentaAbono[0]?.id || -1
    // console.log("SelectedId: ", tCuentaAbono );
    // console.log("Lista: ", this.tblCuentasRows);
    // this.tblCuentasRows?.forEach(x => {
    //   x.selected = tCuentaAbono == x.id 
    // })
  }


  openModalExcepcionDeudaLeasing(aprobando = false) {
		const modal = this.modalService.open(ExcepcionDeudaLeasingComponent, {
		  size: "md",
		})
		modal.componentInstance.value = {}
    modal.componentInstance.soloAdvertencia = !aprobando
		return modal.result.then(
		  (result) => result,
		  (reason) => null,
		)
	}

  receiveDeudaVencida(deuda) {
		this.tieneDeudaVencida = deuda['deuda_vencida']
    this.loadedDeudaVencida = true
	}

  showEditarDetalle(item) {
    const modalRef = this.modalService.open(DevolucionEditarDetalleComponent, {
      size: "md",
    });
    modalRef.componentInstance.excedente = item

    modalRef.result.then(
      (result) => {
        if (result) {
          this.myForm.controls['monto'].setValue(this.sumaMonto)
        }
      },
      (reason) => {console.log('rea', reason)},
    );
  }

  private loadPagadores() {
    this.pagadores$ = concat(
      of([]), // default items
      this.pagadoresInput$.pipe(
        filter((res) => {
          return res !== null
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.pagadoresLoading = true),
        switchMap(term => this.recaudacionService.getPagadores({ 'ruc_nombre': term }, false).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.pagadoresLoading = false)
        ))
      )
    )
  }

  get canAddCuentasAbono(){
    const perfilesPermitidos = [
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilTesoreriaID,
      this.authService.perfil.perfilLiderDeTesoreriaID,
    ]
    return this.currentPagadorType == 1 || perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  get canEditCuentasAbono(){
    const perfilesPermitidos = [
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilTesoreriaID,
      this.authService.perfil.perfilLiderDeTesoreriaID,
    ]
    return this.currentPagadorType == 1 || perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  get canDeleteCuentasAbono(){
    const perfilesPermitidos = [
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilTesoreriaID,
      this.authService.perfil.perfilLiderDeTesoreriaID,
    ]
    return this.currentPagadorType == 1 || perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  get canBeSelectedCuentasAbono(){
    return true
    // if(this.devolucion && (this.devolucion.estado == 3 || this.devolucion.estado == 5)){ // Aprobado || Rechazado
    //   return false;
    // }else{
    //   return true;
    // }
  }

  get prefferredCuentasAbono(){
    let tAccount = this.tblCuentasRows.find(x => x.selected)
    if (tAccount){
      return `${tAccount.entidad_financiera_nombre} (${tAccount.moneda_descripcion}): ${tAccount.numero_cuenta}`
    }else{
      return ""
    }
  }

}
