import { Component, OnInit, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { Router } from "@angular/router";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { AuthService } from "app/core/services/auth/auth.service";
import { AceptantesService } from "app/core/services/factoring/aceptantes.service";
import { ClientesService } from "app/core/services/clientes/clientes.service";
import { SharedFunctions } from 'app/core/shared/functions';
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { FactoringService } from '../../../../core/services/factoring/factoring.service';

@Component({
  selector: "app-add-aceptante-data",
  templateUrl: "./data.component.html",
  styleUrls: ["./data.component.css"],
})
export class DataComponent implements OnInit {
  public active        = 1;
  public aceptante     : any;
  public user          : any;
  public form          : FormGroup;
  public minDate       : Date;
  public tiposPersonas : any;
  public estadosCiviles: any;
  public distritos     : any[];
  public departamentos : any[];
  public provincias    : any[];
  public generos       = [
    { key: "F", nombre: "Femenino" },
    { key: "M", nombre: "Masculino" },
  ];

  @Input() aceptanteId = 0;
  @Input() readonly = false;
  gruposEconomicos: any[] = [];
  emailValido = true;

  public headerTable      : columnHeader[];
  public rowData          : any[] = [];
  public pageTableActual  : number;
  public pageTableTotal   : number;
  public pageTablePerPages: number = 10;
  public pageTablePages   : number[];
  public searchstring     : string = "";
  public loadingTable     = false;
  public tiposCredito: any[];

  correos:any[] = [];

  public customSearchFn :string = '';
  phonePattern = { 'P': { pattern: new RegExp(/[\+|\d]/) }, '0': { pattern: new RegExp(/\d/) } }

  constructor(
    private formBuilder      : FormBuilder,
    private router           : Router,
    private aceptantesService: AceptantesService,
    private authService      : AuthService,
    public localeService     : BsLocaleService,
    private clientesService  : ClientesService,
    public sharedFunctions   : SharedFunctions,
    public factoringService  : FactoringService
  ) {
    defineLocale("es", esLocale);
    this.localeService.use("es");
    this.user = this.authService.user;
  }

  ngOnInit(): void {
    this.initForm();
    this.obtenerData();

    if (this.aceptanteId) {
      this.obtenerAceptante();
      this.obtenerEmpresas();
    }

    if (this.readonly) {
      this.form.disable()
    }

  }
  
  addTagFn(name:string) {
    
    var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(mailformat.test(name) ){
      return name;
    }

    return null;
    
  }

  initForm() {
    this.minDate = new Date();

    this.form = this.formBuilder.group({
      ruc              : [null, [Validators.required, Validators.maxLength(20)]],
      tipo_persona     : [null, []],
      codigo_ciiu      : [null, [Validators.maxLength(10)]],
      codigo_sbs       : [null, [Validators.maxLength(10), Validators.minLength(10)]],
      nombre           : [null, [Validators.required]],
      domicilio_legal  : [null, [Validators.required]],

      distrito         : [null, [Validators.required]],
      departamento     : [null, [Validators.required]],
      provincia        : [null, [Validators.required]],
      telefono_contacto: [null, []],
      telefono_contacto2: [null, []],
      fax              : [null, []],
      email            : [null, []],

      dni              : [null, []],
      apellido_paterno : [null, []],
      apellido_materno : [null, []],
      primer_nombre    : [null, []],
      segundo_nombre   : [null, []],
      sexo             : [null, []],
      fecha_nacimiento : [null, []],
      estado_civil     : [null, []],

      nombre_conyuge   : [null, []],
      dni_conyuge      : [null, [Validators.maxLength(8)]],
      grupo_economico  : [null, []],
      tipo_credito   : [null, []],
    });
  }

  obtenerEmpresas() {
    this.aceptantesService.obtenerEmpresas(this.aceptanteId).then(resp => {
      this.rowData = resp[0]['empresas'];
      // this.loadTable(resp['empresas']);
      this.initTables();
    }, err => {
      console.error(err);

    })
  }

  initTables() {
    let headerTable = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        headerName: "Nombre",
        field: "nombre",
        sortable: true,
      },
    ];

    this.headerTable = headerTable;
  }

  obtenerData() {
    let tiposPersonas = this.aceptantesService.tiposPersonas();
    let estadosCiviles = this.aceptantesService.estadosCiviles();
    let departamentos = this.aceptantesService.obtenerDepartamentos();
    let gruposEconomicos = this.clientesService.obtenerGriposEconomicos();
    let tiposCredito = this.clientesService.tiposCreditos();

    Promise.all([
      tiposPersonas, 
      estadosCiviles, 
      departamentos, 
      gruposEconomicos,
      tiposCredito])
      .then((res) => {
        this.tiposPersonas    = res[0]["results"];
        this.estadosCiviles   = res[1]["results"];
        this.departamentos    = res[2]["results"];
        this.gruposEconomicos = res[3]["results"];
        this.tiposCredito     = res[4]["results"];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async obtenerAceptante() {
    let departamento;
    let provincia;

    await this.aceptantesService
      .obtenerAceptante(this.aceptanteId)
      .then((res: any) => {
        this.aceptante = res;
        this.setForm(res);

        departamento = res["departamento"];
        provincia = res["provincia"];
      })
      .catch((error) => {
        console.error(error);
      });

    let provincias = !!departamento
      ? this.aceptantesService.obtenerProvincias(departamento)
      : null;
    let distritos = !!provincia
      ? this.aceptantesService.obtenerDistritos(provincia)
      : null;

    await Promise.all([provincias, distritos])
      .then((res: any) => {
        this.provincias = res[0]?.results;
        this.distritos = res[1]?.results;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  setForm(aceptante) {
    for (const key in aceptante) {
      if (this.form.controls[key]) {

        if (!!key) this.form.controls[key].setValue(aceptante[key]);

        if (key === "sexo" && !!!aceptante["sexo"]) {
          this.form.controls["sexo"].setValue(null);
          continue;
        }

        if (key === "fecha_nacimiento") {
          this.form.controls[key].setValue(this.parseDate(aceptante[key]));
          continue;
        }

        if(key === 'email'){
          this.form.controls[key].setValue( aceptante[key] ? aceptante[key].split(',') : null );
          continue;
        }

        if(key === 'codigo_sbs'){
          let codigo = !!aceptante['codigo_sbs'] && aceptante['codigo_sbs'].length > 0 ? aceptante['codigo_sbs'] : '';
          if (codigo.length < 10) {
            let tamanio = codigo.length;
            let autocompletar = '';
            for (let i = 0; i < 10 - tamanio; i++) {
              autocompletar += '0';
            }
            codigo = autocompletar.concat(codigo);
            this.form.controls[key].setValue(codigo);
          }

        }
  
        
      }
    }
  }

  setDepartamento() {
    const controls = this.form.controls;
    const departamento = controls["departamento"]?.value;

    if (departamento) {
      this.aceptantesService
        .obtenerProvincias(departamento)
        .then((res) => {
          this.provincias = res["results"];
          controls["provincia"].setValue(null);
          controls["distrito"].setValue(null);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  setProvincia() {
    const controls = this.form.controls;
    const provincia = controls["provincia"].value;

    if (provincia) {
      this.aceptantesService
        .obtenerDistritos(provincia)
        .then((res) => {
          this.distritos = res["results"];
          controls["distrito"].setValue(null);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const values = this.form.value;
      values["fecha_nacimiento"] = this.formatDate(values["fecha_nacimiento"]);
      values['email'] = values['email'] == null || values['email'] == [] ? null : values['email'].toString()
      this.aceptantesService
        .guardarAceptante(this.form.value, this.aceptanteId)
        .then((res: any) => {
          this.router.navigate(["/aceptante", res.id]);
        });
    }
  }

  formatDate(date) {
    if (!(date instanceof Date)) return;
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  parseDate(date) {
    if (!date) return null;

    let dateNow = new Date();

    let yy = date.split("-")[0];
    let mm = date.split("-")[1] - 1;
    let dd = date.split("-")[2];

    let d = new Date(yy, mm, dd, dateNow.getHours());
    return d;
  }

  compararSelected(a: any, b: any) {
    return a && b ? a.id === b : a === b;
  }

  compararSexos(a: any, b: any) {
    return a && b ? a.key === b : a === b;
  }
  validarEmail(e) {
    let email = e.target.value;
    this.emailValido = email ? this.sharedFunctions.validateEmail(email) : true;
  }
}
