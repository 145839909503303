import { Injectable } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from "app/app.service";
import { ToastService } from "../toast/toast.service";

@Injectable({
  providedIn: 'root'
})
export class MaskService {

  constructor(
    public appService: AppService,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
  ) { }

  /**
   * Habilita el loader para request a la API
   */
   show() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  hide(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();
    this.appService.notifyMe(mensaje, ok);

    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);
  }
}
