import { Component, OnInit } from '@angular/core';
import { utils, writeFile } from 'xlsx';
import Swal from 'sweetalert2';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'app/core/services/auth/auth.service';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddRecaudacionModalComponent } from 'app/modules/recaudacion/modals/add-recaudacion-modal/add-recaudacion-modal.component';
import { functions } from 'app/core/helpers/functions';

@Component({
  selector: 'app-reporte-aplicaciones-excedentes',
  templateUrl: './reporte-aplicaciones-excedentes.component.html',
  styleUrls: ['./reporte-aplicaciones-excedentes.component.css']
})
export class ReporteAplicacionesExcedentesComponent implements OnInit {

  public reporteForm : FormGroup;
	public headerTable: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 50;
	public pageTablePages: number[];
  public searchstring: string = '';

  public rowData = [];

 
  public filtros: any = {
    cliente_nombre: undefined,
    cliente_ruc: undefined,
  };

  constructor( 
    public cxcService : CuentasService,
    private factoringService: FactoringService,
    public formbuild: FormBuilder,
    public authService: AuthService,
    public modalService: NgbModal,
  ) {}

  ngOnInit(): void {

    this.getReporteAplicacionesExcedentes({page:1, per_page:this.pageTablePerPages, filtros:{}})
    this.loadTableColumns()
    
  }

  loadTableColumns() {
		this.headerTable = [
      {
        headerName: 'N°',
        // headerName: 'ID Exc.',
        class: 'text-center',
        // field: 'id_excedente',
        field: 'index',
        sortable: true,
      },
			{
        headerName: 'Nombre Cliente',
        class: 'text-center',
        field: 'cliente_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'cliente_nombre'
      },
      {
        headerName: 'RUC Cliente',
        class: 'text-center',
        field: 'cliente_ruc',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'cliente_ruc'
      },
      {
        headerName: 'Nombre Aceptante',
        class: 'text-center',
        field: 'aceptante_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'aceptante_nombre'
      },
      {
        headerName: 'RUC Aceptante',
        class: 'text-center',
        field: 'aceptante_ruc',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'aceptante_ruc'
      },
      {
        headerName: 'ID Rec.',
        class: 'text-center',
        field: 'id_recaudacion',
        sortable: true,
        pipe: 'actionable',
        textField: 'id_recaudacion',
        actionableType: 'id_recaudacion'
      },
      {
        headerName: 'Monto Exc.',
        class: 'text-center',
        field: 'excedente_monto',
        sortable: true,
      },
      {
        headerName: 'Saldo Exc.',
        class: 'text-center',
        field: 'excedente_saldo',
        sortable: true,
      },
      {
        headerName: 'Fecha de Pago',
        class: 'text-center',
        field: 'fecha_pago',
        sortable: true,
        pipe: 'date'
      },
      {
        headerName: 'Moneda Rec.',
        class: 'text-center',
        field: 'recaudacion_moneda',
        sortable: true,
      },
      {
        headerName: 'Monto Rec.',
        class: 'text-center',
        field: 'monto_recaudacion',
        sortable: true,
      },
      {
        headerName: 'Tipo documento',
        class: 'text-center',
        field: 'tipo_documento',
        sortable: true,
      },
      {
        headerName: 'Nro. documento',
        class: 'text-center',
        field: 'numero_documento',
        sortable: true,
      },
      {
        headerName: 'Pago documento',
        class: 'text-center',
        field: 'pago_documento',
        sortable: true,
      },
		];
  }

  loadTable(data) {

		this.rowData = functions.indextable(data)

		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  getReporteAplicacionesExcedentes({page, per_page, filtros}) {
    console.log(filtros)
    this.factoringService.getReporteAplicacionesExcedentes(
      filtros.cliente_nombre,
      filtros.cliente_ruc,
      filtros.aceptante_nombre,
      filtros.aceptante_ruc,
      page, 
      per_page,
      ).then(res=>{
        
        this.loadTable(res)
    }).catch( (err)=>{

      console.log( '****ERROR****', err )
    })
  }


  goToPage({page, per_page, filtros}){
    this.filtros = filtros
    this.getReporteAplicacionesExcedentes({page, per_page, filtros})
	}

  async openExcelModal() {
    const result = await Swal.fire({
      title: 'Generar Excel',
      text: "Elige si deseas generar el excel de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    console.log(result)
    if (result.isDenied) {
      this.generarExcel(this.rowData)
    } else if(result.isConfirmed) {
      this.generarExcelCompleto()
    }
  }

  parseData(data) {
    return data.map(el=>{
      return {
        'ID Exc.': el?.id_excedente,
        'Nombre Cliente': el?.cliente_nombre,
        'RUC Cliente': el?.cliente_ruc,
        'Nombre Aceptante': el?.aceptante_nombre,
        'RUC Aceptante': el?.aceptante_ruc,
        'ID Rec.': el?.id_recaudacion,
        'Monto Exc.': el?.excedente_monto,
        'Saldo Exc.': el?.excedente_saldo,
        'Fecha de Pago': el.fecha_pago ? el.fecha_pago = el.fecha_pago.split("T")[0].split("-").reverse().join("/") : null,
        'Moneda Rec.': el?.recaudacion_moneda,
        'Monto Rec.': el?.monto_recaudacion,
        'Tipo documento': el?.tipo_documento,
        'Nro. documento': el?.numero_documento,
        'Pago documento': el?.pago_documento,
      }      
    })
  }

  generarExcelCompleto() {
    this.factoringService.getReporteAplicacionesExcedentes(
      this.filtros.cliente_nombre,
      this.filtros.cliente_ruc,
      this.filtros.aceptante_nombre,
      this.filtros.aceptante_ruc,
      1, 
      100000,
      ).then(res=>{
        this.generarExcel(res['results'])
    })
  }

  generarExcel(data) {
    var parsedData = this.parseData(data)

    /* generate a worksheet */
    var ws = utils.json_to_sheet(parsedData);

    /* add to workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hoja 1");

    /* write workbook and force a download */
    writeFile(wb, `Reporte - ${new Date().toLocaleString()}.xlsx`);
  }

  openActionable(event) {
    if (event.actionableName == 'id_recaudacion') {
      const modalRef = this.modalService.open(AddRecaudacionModalComponent, {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
      });
  
      modalRef.componentInstance.recaudacionId = event.actionableType;
  
      modalRef.result.then((result) => {
        console.log(result);
      }, (reason) => {
        console.log(reason);
      });
    }
  }
}
