import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faPercent,
  faColumns,
  faTimesCircle,
  faFilter,
  faTimes,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FactoringService } from "app/core/services/factoring/factoring.service";
import { concat, Observable, of, Subject, throwError } from "rxjs";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap,
  map,
  filter,
} from "rxjs/operators";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { utils, writeFile } from 'xlsx';
import { listLocales } from 'ngx-bootstrap/chronos';
import { BsLocaleService } from "ngx-bootstrap/datepicker";

declare var $: any;
export interface columnHeader {
  headerName: string;
  field?: string;
  pipe?: string;
  sortable?: boolean;
  filterable?: boolean;
  class?: string;
  moneda?: string;
  text?: string;
  textField?: string;
  filterSelectItems?: any[];
  filterItemsProps?: any;
  visible?: boolean;
  filterDate?: boolean;
  filterProp?: string;
  filterValue?: any;
  filterInput?: boolean;
  filterRange?: boolean;
  filterValueFrom?: any;
  filterValueTo?: any;
  defaultInfo?: string;
  function?: any;
  postfix?: string;
  filterType?: string;
  filterSearchItems?: any[];
  filterSearchText?: any[];
  actionableType?: string;
  idResponsable?: string;
  responsableField?: string;
  stateField?: string;
  checkDisabled?: boolean;
  is_tooltip?: boolean;
  tooltip_text?: string;
  downloadFile?: boolean;
  defaultValue?: any;
  columnaWidth?: any;
  multipleSelectedItems?: boolean;
  sumCol?: boolean;
  sumColFn?: any;
  tipoCambio?: string;
  inputExcedente?:boolean;
  filterDateUnic?:boolean;
  tagElementKey?: any;
}

// El datatable recibe los valores de renderizado, pueden ser columnas personalizadas o elementos particulares
// Para elementos particulares adicionales se desarrollan en la seccion de PIPES en el bodu de la tabla
// La tabla retorna los EVENTOS de los botones clickeados

@Component({
  selector: "app-factoring-datatable",
  templateUrl: "./factoring-datatable.component.html",
  styleUrls: ["./factoring-datatable.component.css"],
  animations: [
    trigger("animationOption2", [
      state(
        "close",
        style({
          opacity: 0,
          height: "0px",
        })
      ),
      state(
        "open",
        style({
          height: "60px",
          opacity: 1,
        })
      ),
      transition("close <=> open", animate(250)),
    ]),
  ],
})
export class FactoringDatatableComponent implements OnInit {
  faFilter            = faFilter;
  faColumns           = faColumns;
  faTimes             = faTimesCircle;
  faPercent           = faPercent;
  faLongArrowAltRight = faLongArrowAltRight;
  faLongArrowAltLeft  = faLongArrowAltLeft;
  columnsToggler      : columnHeader[] = [];
  _headers            : columnHeader[] = [];
  filtersData         : any[];
  faSearch = faSearch
  private _rows: any[] = [];
  _preselected: any;
  get rows(): any[] {
    return this._rows;
  }
  _selectable: boolean = false;

  @Input() set preselected(value : any[]){
    if(value.length > 0){
        this._rows = this._rows.map(( item ) => {
          item.selected = value.some((val) => val == item.id)
          return item
        })
        this._preselected = value
    } 
    
    if(value.length == 0){
      this._rows = this._rows.map(( item ) => {
        item.selected = false
        return item
      })
      this._preselected = value
      this.evalPreselected()

    } else if(value.length == 0){
      this._rows = this._rows.map(( item ) => {
        item.selected = false
        return item
      })
      this._preselected = value
      this.evalPreselected()
    }
  }

  @Input() set selectable(value) {
    if (!value) {
      this._rows = this._rows.map((item) => {
        item.selected = false
        return item
      })
    }
    this._selectable = value;
  }

  get selectable(): boolean {
    return this._selectable
  }

  @Input() set rows(value: any[]) {
    this.evalScroll()
    if (this.checkAction) {
      value.forEach(row => {
       this.checkedRow[row.id] = row.selected
      })
    }

    if (this.checkActionRecaudacion) {
      value.forEach(row => {

        if( row.tipo_documento_descripcion == 'Factura' && row.tipo_documento_descripcion == 'Letra' ){
          this.checkedRow[row.id] = row.selected
        }

      })
    }
  
    if (this.selectable) {
      this._rows = value.map((item) => {
        item.selected = false;
        return item
      })
      this.evalPreselected()
    } else
      this._rows = value;

  }

  @Input() set headers(headers) {
    this.setHeadersFunction(headers);
    this._headers = headers;
    if (headers) {
      headers.map(head => {
        if (head.defaultValue) {
          this.filterClicked(head, head.defaultValue)
        }
      })
    }
  }

  get headers() {
    return this._headers;
  }

  /**
   * Variables de paginacion
   */
  @Input() perPage: number = 10;
  @Input() actualPage: number = 1;
  @Input() countPages: any[] = [];
  @Input() totalRows: number = 0;
  @Input() pagination: boolean = false;
  @Input() maxSize: number = 12;
  @Input() clickeable: boolean = false;

  // Estas variables determinan si se muestran estos botones del crud en la tabla, si todas estan en false, la columna de ACCIONES no se renderiza
  @Input() loading: boolean = false;
  @Input() checkAction: boolean = false;
  @Input() checkActionAll: boolean = false;
  @Input() checkActionRecaudacion: boolean = false;
  checkedRow = {}
  @Input() toggleColumn: boolean = false;
  // @Input() highlighted: Function = () => false;
  @Input() highlighted: Function = () => '';
  @Input() functionColor: Function = () => '';
  @Input() isColor: Function = () => '';

  @Input() editAction: boolean = false;
  @Input() deleteAction: boolean = false;
  @Input() linkAction: boolean = false;                     //////////////////////////////////////////////////
  @Input() readAction: boolean = false;
  @Input() reassignAction: boolean = false;
  @Input() desembolsoAction: boolean = false;
  @Input() reassignActionFunction: Function = (row) => true;
  @Input() editActionFunction: Function = (row) => true;
  @Input() readActionFunction: Function = (row) => true;
  @Input() deleteActionFunction: Function = (row) => true;
  @Input() linkActionFunction: Function = (row) => true;      ////////////////////////////////////////////////
  @Input() beautifulbeautiful: Function = (row) => true;
  @Input() desembolsoActionFunction: Function = (row) => true;
  reassignRow = {}
  editRow = {}
  readRow = {}
  deleteRow = {}
  linkRow = {}
  desembolsoRow = {}
  selectionArr = []
  @Input() readActionFunctionPriorize: boolean = false;
  @Input() editActionFunctionPriorize: boolean = false;
  @Input() reassignActionFunctionPriorize: boolean = false;
  @Input() deleteActionFunctionPriorize: boolean = false;
  @Input() linkActionFunctionPriorize: boolean = false;               ////////////////////////////////////////////////
  @Input() desembolsoActionFunctionPriorize: boolean = false;
  @Input() minimal: boolean = false;
  @Input() checkTooltip: string = "Seleccionar";
  @Input() facturacion: boolean = true;
  @Input() showInputExedente: boolean = false;
  @Input() moneda : any;
  @Input() selectableOnlyOnceAtTime?: boolean;
  

  @Input() pdfxml: boolean = false;

  @Input() acciones: [] = [];
 
  // Estos eventos se emiten cuando uno de los botones del crud es clickeado
  @Output() editEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() readEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() deleteEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() linkEvent: EventEmitter<number> = new EventEmitter<number>();           ////////////////////////////////////////////////
  @Output() reassignEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() desembolsoEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() checkEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() checkChanged: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() workflow: EventEmitter<any> = new EventEmitter<any>();
  @Output() download: EventEmitter<any> = new EventEmitter<any>();
  @Output() rejectEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() buttonEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() selectionEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() rowClickEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() donwloadPdf: EventEmitter<any> = new EventEmitter<any>();
  @Output() downloadXml: EventEmitter<any> = new EventEmitter<any>();
  @Output() modifPago: EventEmitter<any> = new EventEmitter<any>(); 
  @Output() tipoCambio: EventEmitter<any> = new EventEmitter<any>();

  // Define si la primera columna muestra el indice de la fila
  @Input() indexedCol: boolean = false;
  _checkloader: any = {};
  @Input() set checkloader(value) {
    this.updateAsyncLoader(value);
  }

  public checkedActionAll = false;

  public typeDateFilter = '';

  headerclasses(head) {
    let classes = '';

    if (this.minimal) {
      classes += 'minimal '
    }

    if (head.class) {
      classes += `${head.class} `
    }

    if (this.customCellClasses) {
      classes += `${this.customCellClasses} `
    }

    return classes
  }

  headerStyles(head) {
    return { ...this.customCellStyles }
  }

  get checkloader() {
    return this._checkloader;
  }

  @Input() checksEditables: boolean = true;
  @Input() checksBloqued: boolean = false;
  @Input() readonly: boolean = false;
  staticFilters: string = '{"beneficiario":""}';
  checkLoading: boolean = false;
  tmp: any = null;
  tmpObj: any = {};
  public rowsBackup: any[] = [];
  @Input() minheight: number = 500;
  @Input() height: any = 'auto';
  observer$: Observable<any[]>;
  observerOficiales$: Observable<any[]>;
  subjectString$ = new Subject<string>();
  subjectStringOficiales$ = new Subject<string>();
  ruc: any;
  loadingSearch: boolean = false;
  loadingSearchOficiales: boolean = false;
  formularioTesting: FormGroup;
  beneficiarioID: any;
  beneficiarioColumnaIndex: any;
  oficialColumnaIndex: any;
  openSelectSearch: boolean = false;
  scrollButtons: boolean = false;
  idScroll: string = '';
  @Input() convertToExcel: boolean = false;
  @Input() customRowClasses: any[] = [];
  @Input() customRowStyles: any = {};
  @Input() customCellClasses: string = '';
  @Input() customCellStyles: any = {};
  /**
   * Esta propiedad filtra las filas de la tabla dependiente del string
   * que sea enviado desde el padre
   */
  public _searchstring: string = "true";
  public searchResults = false;
  @Input() set searchstring(text) {
    this.searchStringFunction(text);
    this._searchstring = text;
  }
  get searchstring() {
    return this._searchstring;
  }

  paginateId = new Date().getTime();
  locale = 'es';
  locales = listLocales();
  constructor(
    private _formBuilder: FormBuilder,
    private factoringService: FactoringService,
    private localeService: BsLocaleService
  ) {
    this.loadSeach();
    let date = new Date();
    this.idScroll = date.valueOf().toString()
  }

  actCambio( row, value, idx ){
    if( Number( value.replace(/,/g,'') ) < 0 ){
      $(`#${idx}`).val(0);
      $(`#alert-${idx}`).html('Monto debe ser mayor a 0');
      return;
    }else if( !Number( value.replace(/,/g,'')) ){
      $(`#alert-${idx}`).html('Por favor agregar un monto');
      return;
    }

    $(`#alert-${idx}`).html('');


    row.tipo_cambio_monto = value.replace(/,/g,'');

    this.tipoCambio.emit( row )

  }

  evalScroll() {
    let object = document.getElementById(this.idScroll)
    if (!object || this.minimal) {
      return
    }

    if (object.scrollWidth != object.offsetWidth) {
      this.scrollButtons = true
    }
  }

  selectedRow(row, selectableOnlyOnceAtTime = false ) {
    if (this.clickeable) {
      this.rowClickEvent.emit(row)
    } else {
      if(selectableOnlyOnceAtTime){
        this._rows.forEach(x => {
          x.selected = row.id == x.id ? !x.selected : false
        })
      }else{
        row.selected = !row.selected
      }
      this.selectionChange()
    }
  }

  selectionChange() {
    if (this.selectable) {
      this.selectionArr = this._rows.filter(row => row.selected)
      this.selectionEvent.emit(this.selectionArr)
    }
  }
   
  get selecciones(){
    return this._rows.filter(row => row.selected)
  }

  buttonEventHandler(row, field) {
    row.field = field
    this.buttonEvent.emit(row)
  }

  ngOnInit() {
    this.localeService.use(this.locale);
    setTimeout(() => {
      this.evalScroll()
    }, 500)

    if (this.reassignActionFunction === null) {
      this.reassignActionFunction = () => true
    }
    if (this.editActionFunction === null) {
      this.editActionFunction = () => true
    }
    if (this.readActionFunction === null) {
      this.readActionFunction = () => true
    }
    if (this.deleteActionFunction === null) {
      this.deleteActionFunction = () => true
    }
    if (this.linkActionFunction === null) {
      this.linkActionFunction = () => true        ///////////////////////////////////////////////////////
    }
    if (this.desembolsoActionFunction === null) {
      this.desembolsoActionFunction = () => true
    }
    this.formularioTesting = this._formBuilder.group({
      teststring: [""],
      oficialString: [""],
    });

    this.formularioTesting.controls.teststring.valueChanges.subscribe(
      (value) => {
        this.headers[this.beneficiarioColumnaIndex].filterValue = value;
        this.obtenerFiltrosActivos();
      }
    );

    this.formularioTesting.controls.oficialString.valueChanges.subscribe(
      (value) => {
        this.headers[this.oficialColumnaIndex].filterValue = value;
        this.obtenerFiltrosActivos();
      }
    );
    this.loadSeach();

  }

  montoEspecifico(valor, row, index){
    if( !row.montoAnt ){
      row.montoAnt = row.pago;
      row.montoAntDolar = row.pago_neto;
      row.pago_financiado_ant = row.pago_financiado ? row.pago_financiado : '0';
    }

    if( this.moneda === 2 ){
      if( Number( valor ) > Number( row.monto.replace(/,/g,'') ) ){
        row.saldo = Math.abs(Number(row.monto.replace(/,/g,'')) - Number(row.montoAnt.replace(/,/g,''))).toFixed(2)
        row.pago = row.montoAnt;
        row.pago_neto = row.moneda_documento === 1 ? row.montoAntDolar.toString() : row.montoAnt.toString();
        row.pago_financiado = row.pago_financiado_ant;
      
        $(`#pago-${index}`).val( row.monto )
      }else if( Number( valor  ) < 0 ){
        row.pago = '0';
        row.pago_neto = '0';
        row.saldo = row.monto;
        row.pago_financiado = '0'

        $(`#pago-${index}`).val( '0')
      }else{
        if( Number( valor ) > Number(row.montoAnt.replace(/,/g,'')) ){
          row.saldo = Math.abs(Number(row.monto.replace(/,/g,'')) - Number(row.montoAnt.replace(/,/g,''))).toFixed(2)
          row.pago = row.montoAnt;
          row.pago_neto = row.moneda_documento === 1 ? row.montoAntDolar.toString() : row.montoAnt.toString();
          row.pago_financiado = row.pago_financiado_ant;

          $(`#pago-${index}`).val( row.montoAnt) 
        }else{
          let diferencia = row.moneda_documento === 1 ? Number(row.montoAntDolar.replace(/,/g,'')) - Number(row.pago_financiado_ant.replace(/,/g,'')) : Number(row.montoAnt.replace(/,/g,'')) - Number(row.pago_financiado_ant.replace(/,/g,''));

          row.saldo = Math.abs(Number(row.monto.replace(/,/g,'')) - Number(valor)).toFixed(2)
          row.pago = valor;
          row.pago_neto = row.moneda_documento === 1 ? (Number(valor)*Number(row.tipo_cambio_monto)).toString() : valor.toString();

          row.pago_financiado = row.moneda_documento === 1 ?  this.calculoPagoFinanciado( valor, row.tipo_cambio_monto, diferencia, row.pago_financiado_ant, row.moneda_documento )  : this.calculoPagoFinanciado( valor, row.tipo_cambio_mont,diferencia, row.pago_financiado_ant, row.moneda_documento);

          $(`#pago-${index}`).val( valor )
        }
      }
    }else{
      if( Number( valor ) > Number( row.monto.replace(/,/g,'') ) ){
        row.saldo = Math.abs(Number(row.monto.replace(/,/g,'')) - Number(row.montoAnt.replace(/,/g,''))).toFixed(2)
        row.pago = row.montoAnt;
        row.pago_neto = row.moneda_documento === 2 ? row.montoAntDolar.toString() : row.montoAnt.toString();
        row.pago_financiado = row.pago_financiado_ant;
      
        $(`#pago-${index}`).val( row.monto )
      }else if( Number( valor  ) < 0 ){
        row.pago = '0';
        row.pago_neto = '0';
        row.saldo = row.monto;
        row.pago_financiado = '0'

        $(`#pago-${index}`).val( '0')
      }else{
        if( Number( valor ) > Number(row.montoAnt.replace(/,/g,'')) ){
          row.saldo = Math.abs(Number(row.monto.replace(/,/g,'')) - Number(row.montoAnt.replace(/,/g,''))).toFixed(2)
          row.pago = row.montoAnt;
          row.pago_neto = row.moneda_documento === 2 ? row.montoAntDolar.toString() : row.montoAnt.toString();
          row.pago_financiado = row.pago_financiado_ant;

          $(`#pago-${index}`).val( row.montoAnt)
        }else{
          let diferencia = row.moneda_documento === 2 ? Number(row.montoAntDolar.replace(/,/g,'')) - Number(row.pago_financiado_ant.replace(/,/g,'')) : Number(row.montoAnt.replace(/,/g,'')) - Number(row.pago_financiado_ant.replace(/,/g,''));

          row.saldo = Math.abs(Number(row.monto.replace(/,/g,'')) - Number(valor)).toFixed(2)
          row.pago = valor;
          row.pago_neto = row.moneda_documento === 2 ? (Number(valor)/Number(row.tipo_cambio_monto)).toString() : valor.toString();
          row.pago_financiado = row.moneda_documento === 2 ?  this.calculoPagoFinanciado( valor, row.tipo_cambio_monto, diferencia, row.pago_financiado_ant, row.moneda_documento )  : this.calculoPagoFinanciado( valor, row.tipo_cambio_mont,diferencia, row.pago_financiado_ant, row.moneda_documento);

          $(`#pago-${index}`).val( valor )
        }
      }
    }
    this.modifPago.emit(row)
  }

  pagoValue( valor, diferencia ){
    return Number(valor) == 0 ? 0 : diferencia
  }

  calculoPagoFinanciado( valor, tipo_cambio_monto = null, diferencia = null, pago_financiado_ant = null, moneda_documento ){
    if(this.moneda === 1){

      if( moneda_documento === 2 ){
        if( Number(pago_financiado_ant) === 0 )
          return '0';
          else
        return ((Number(valor)/Number(tipo_cambio_monto))-this.pagoValue(valor, diferencia) ).toFixed(2)
    
      }else{

        if( Number(pago_financiado_ant) === 0 )
          return '0';
            else
          return ( Number(valor) - this.pagoValue(valor, diferencia) ).toFixed(2)
      }
    }else{
      if( moneda_documento === 1 ){
        if( Number(pago_financiado_ant) === 0 )
          return '0';
          else
        return ((Number(valor)*Number(tipo_cambio_monto))-this.pagoValue(valor, diferencia) ).toFixed(2)
      }else{
        if( Number(pago_financiado_ant) === 0 )
          return '0';
            else
          return ( Number(valor) - this.pagoValue(valor, diferencia) ).toFixed(2)
      }
    }
  }

  closeBeneficiariosSearch() {
    this.formularioTesting.reset();
    this.openSelectSearch = false;
  }

  updateAsyncLoader(value) {
    if (value && value.value && this.rows[value.index]) {
      this.rows[value.index][value.value] = false;
    }
    this._checkloader = value;
  }

  /**
   * Se ejecuta cada vez que una variable dentro del componente cambia o muta
   * @param changes
   */
  ngOnChanges(changes) {
    if (changes.rows && this.rows && this.rows.length > 0) {
      this.rowsBackup = this.rows;
    }

    if (changes) {
      this.rows.forEach(row => {
        this.reassignRow[row.id] = this.reassignActionFunction(row)
        this.editRow[row.id] = this.editActionFunction(row)
        this.readRow[row.id] = this.readActionFunction(row)
        this.deleteRow[row.id] = this.deleteActionFunction(row)
        //console.log(this.linkAction)
        //console.log(this.editAction)
        //console.log(this.desembolsoAction)
        //console.log((this.editAction || this.deleteAction || this.reassignAction || this.desembolsoAction))
        //console.log((!this.readonly || this.readAction || this.checkAction))
        this.linkRow[row.id] = this.linkActionFunction(row)
        this.desembolsoRow[row.id] = this.desembolsoActionFunction(row)
      })
    }

    if (this.checksEditables) this.readCheckboxValues();
  }

  checkboxChange(event, row, field) {
    let index = this.rows.findIndex((item) => item.id == row.id);
    this.rows[index][`checkLoad${field}`] = true;
    this.checkChanged.emit({
      row: row,
      newvalue: event,
      field: field,
      checkEvent: {
        value: `checkLoad${field}`,
        index: index,
      },
    });
  }

  readCheckboxValues() {
    if (
      this.headers &&
      this.headers.length > 0 &&
      this.headers.some((item) => item.pipe == "checkbox")
    ) {
      let checks = this.headers.reduce((acc, item) => {
        if (item.pipe == "checkbox") {
          acc.push(item.field);
        }
        return acc;
      }, []);
      this.mapCheckboxValues(checks);
    }
  }

  mapCheckboxValues(fields) {
    let rows = this.rows.map((item) => {
      fields.forEach((element) => {
        item[`checkLoad${element}`] = false;
      });
      return item;
    });
    this.rows = rows;
  }

  goToPage(page) {
    /**
     * Calcula si existe la pagina que se esta
     * pidiendo a la API, tomando en cuenta la configuracion
     * del numero de resultados por pagina seleccionada
     */
    let paginationInfo = Math.ceil(this.totalRows / this.perPage);
    if (paginationInfo < page) {
      this.goToPage(page - 1);
      return;
    }

    /**
     * Emite evento de actualizacion de pagina
     */
    this.obtenerFiltrosActivos(page);
  }

  perPageUpdate(quantity) {
    this.perPage = quantity;
    this.goToPage(this.actualPage);
  }

  toggleHeader(header) {
    if (!header) {
      this.headers.forEach((h) => (h.visible = true));
    } else {
      let index = this.headers.findIndex((head) => head.field === header.field);
      this.headers[index].visible = !this.headers[index].visible;
    }
  }

  /**
   * El evento se dispara cuando se clickea un filtro de una columna
   * @param head Recibe la cabecera del evento donde fue clickeado
   * @param filtered Recibe el item que fue seleccionado del dropdown de opciones
   */
  filterClicked(head, filtered) {
    /* Busca el indice de la columna en la que el filtro fue activado */
    let indexColumn = this.headers.findIndex((h) => h.field === head.field);
    if (!filtered) {
      if (head.filterInput) {
        head.filterValue = "";
        this.tmp = null;
        this.tmpObj = {};
      } else if (head.filterRange) {
        head.filterValueFrom = null;
        head.filterValueTo = null;
      } else {
        if (!this.headers[indexColumn].multipleSelectedItems) {
          this.headers[indexColumn].filterSelectItems.forEach((f) => (f.selected = false))
        }
      }
      this.obtenerFiltrosActivos();
      return;
    }
    /**
     * Busca el indice del filtro en la columna activada
     */
    let filterIndex = this.headers[indexColumn].filterSelectItems.findIndex(
      (f) => f.id === filtered.id
    );

    /**
     * Limpia todas las otras selecciones, porque se trata de una seleccion simple
     */
    if (!this.headers[indexColumn].multipleSelectedItems) {
      this.headers[indexColumn].filterSelectItems.forEach(
        (f) => (f.selected = false)
      );
    }

    /**
     * Cambia el estado de seleccion del filtro
     */
    this.headers[indexColumn].filterSelectItems[filterIndex].selected = !filtered.selected;
    this.obtenerFiltrosActivos();
  }

  obtenerFiltrosActivos(page: any = this.actualPage, type = '') {
    let filtros = this.headers.reduce((acc, header) => {
      if (
        header.filterable &&
        header.filterType &&
        (header.filterType == "buscarBeneficiario" || header.filterType == "buscarOficial")
      ) {
        // this.openSelectSearch = true
        acc[header.filterProp] = header.filterValue;
      }

      if (header.filterable && header.filterSelectItems) {
        if (!header.multipleSelectedItems) {
          let selected = header.filterSelectItems.find((item) => item.selected);
          if (selected) acc[header.filterProp] = selected.id;
        } else {
          let selected = header.filterSelectItems.filter((item) => item.selected);
          if (selected.length > 0) acc[header.filterProp] = selected.map(el => el.id).join(',');
        }
      }

      if (header.filterable && header.filterDate) {
        header.filterProp = header.filterProp ? header.filterProp : 'fecha'
        if (header.filterValue[`${header.filterProp}__gte`] != "") acc[`${header.filterProp}__gte`] = header.filterValue[`${header.filterProp}__gte`];
        if (header.filterValue[`${header.filterProp}__lte`] != "") acc[`${header.filterProp}__lte`] = header.filterValue[`${header.filterProp}__lte`];
      }

      if (header.filterable && header.filterDateUnic) {
        header.filterProp = header.filterProp ? header.filterProp : 'fecha'
        if( header.filterValue ){
          acc[header.filterProp] = header.filterValue;
        }
      }

      if (header.filterable && header.filterInput) {
        if (header.filterValue != "") {
          acc[header.filterProp] = header.filterValue;
        }
      }

      if (header.filterable && header.filterRange) {
        if (header.filterValueFrom)
          acc['valueFrom'] = header.filterValueFrom;

        if (header.filterValueTo)
          acc['valueTo'] = header.filterValueTo;

        if (header.filterProp) {
          if (header.filterValueFrom && header.filterValueTo) {
            acc[`${header.filterProp}__range`] = `${header.filterValueFrom},${header.filterValueTo}`
          }
        }
      }
      return acc;
    }, {}); 

    /**
     * Esta sentencia valida si tiene filtros seleccionados nuevos,
     * SI tiene un filtro nuevo seleccionado, pide la pagina 1
     * si NO  tiene un filtro nuevo seleccionado, continua la paginacion natural
     */
    let filtroVacio = JSON.stringify(filtros) == JSON.stringify({})
    if (!filtroVacio) {
      if (this.staticFilters != JSON.stringify(filtros)) {
        page = 1;
      }
    }

    this.staticFilters = JSON.stringify(filtros);
    let updatePage = {
      page: page,
      per_page: this.perPage,
      filtros: filtros,
      type: this.typeDateFilter
    }

    this.pageUpdate.emit(updatePage);
  }

  calendarOption(values, head, type) {
    if (!values || values.length == 0) return;
    let indexColumn = this.headers.findIndex((h) => h.field === head.field);
    const headerProp = this.headers[indexColumn].filterProp || 'fecha'
    if(!type){
      this.headers[indexColumn].filterValue[`${headerProp}__gte`] = this.formatDate(
        values[0]
      );
      this.headers[indexColumn].filterValue[`${headerProp}__lte`] = this.formatDate(
        values[1]
      );
    }else{
      this.headers[indexColumn].filterValue = this.formatDate(
        values
      );
    }

    this.typeDateFilter = head.field;
    this.obtenerFiltrosActivos(this.actualPage);
  }

  dateCloseToggle(head) {
    const headerProp = head.filterProp || 'fecha'
    if (head.filterValue[`${headerProp}__gte`]) return true
    return false
  }

  formatDate(date) {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      " 00:00"
    );
  }

  searchStringFunction(text) {
    if (text.toLowerCase().trim() === "") {
      this.rows = this.rowsBackup;
      this.searchResults = false;
    } else {
      this.rows = this.rowsBackup.reduce((acc, value) => {
        for (var prop in value) {
          if (
            value[prop] &&
            value[prop].toString().toLowerCase().includes(text.toLowerCase())
          ) {
            acc.push(value);
            return acc;
          }
        }
        return acc;
      }, []);
      this.searchResults = true;
    }
  }

  initBuscarBeneficiarios(head, index) {
    this.beneficiarioColumnaIndex = index;
  }

  initBuscarOficial(head, index) {
    this.oficialColumnaIndex = index;
  }

  evalPreselected(value: any[] = this._preselected) {
      console.log(this._preselected)
      if(value.length > 0){
          this._rows = this._rows.map(( item ) => {
            item.selected = value.some((val) => val == item.id)
            return item
          })
          this._preselected = value
      } 
      
      if(value.length == 0){
        this._rows = this._rows.map(( item ) => {
          item.selected = false
          return item
        })
      } else if(value.length == 0){
        this._rows = this._rows.map(( item ) => {
          item.selected = false
          return item
        })
        this._preselected = value
      }
  }

  setHeadersFunction(headers) {
    if (headers && headers.length > 0) {
      this.columnsToggler = headers.map((head: any, index: any) => {
        if (head.filterable && head.filterType) {
          switch (head.filterType) {
            case "buscarBeneficiario":
              head.beneficiarioValue = null;
              head.filterValue = "";
              this.initBuscarBeneficiarios(head, index);
              break;
            case "buscarOficial":
              head.beneficiarioValue = null;
              head.filterValue = "";
              this.initBuscarOficial(head, index);
              break;
          }
        }
        if (head.filterable && head.filterSelectItems) {
          head.filterSelectItems.forEach((filt) => (filt.selected = false));
        }

        if (head.filterable && head.filterDate) {
          head.filterProp = head.filterProp ? head.filterProp : 'fecha'
          head.filterValue = {}
          head.filterValue[`${head.filterProp}__gte`] = ''
          head.filterValue[`${head.filterProp}__lte`] = ''
        }

        if (head.filterable && head.filterInput) {
          // head.filterValue = this.tmp ? this.tmp : '';
          head.filterValue = this.tmpObj[head.field]
            ? this.tmpObj[head.field]
            : "";
      
        }

        head.visible = true;
        return head;
      });
    }
  }

  ejecutaFiltro(header) {
    this.openSelectSearch = true;
  }

  clearDate(head, type) {
    let indexColumn = this.headers.findIndex((h) => h.field === head.field);
    const headerProp = this.headers[indexColumn].filterProp || 'fecha'
    if( !type ){
      this.headers[indexColumn].filterValue[`${headerProp}__lte`] = "";
      this.headers[indexColumn].filterValue[`${headerProp}__gte`] = "";
    }else{
      this.headers[indexColumn].filterValue = "";
    }

    this.typeDateFilter = '';
    this.obtenerFiltrosActivos();
    
  }

  computedPercent(val) {
    if (val) return parseFloat(val).toFixed(2);
    else return 0;
  }

  workFlowEvent(row, header) {
    const roww = {
      ...row,
      actionableType: row[header.actionableType],
      actionableName: header.actionableType,
    };

    this.workflow.emit(roww);
  }

  downloadEvent(row, header) {
    if (!header.downloadFile) {
      return;
    }
    this.download.emit(row);
  }

  searchString(head) {
    console.log( 'FILTRO CONCEPTO', head )
    let indexColumn = this.headers.findIndex((h) => h.field === head.field);
    this.tmp = this.headers[indexColumn].filterValue;
    this.tmpObj[this.headers[indexColumn].field] = this.headers[
      indexColumn
    ].filterValue;
    this.obtenerFiltrosActivos();
  }

  /**
   * Este evento se suscribe a los cambios en el objeto que recibe la data desde el servidor
   */
  loadSeach() {
    /**
     * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
     * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
     * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value.
     * 		So that API will not hit if the current and previous value is the same
     * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
     * 		string typed by user
     */
    this.observer$ = concat(
      of([]), // Items predeterminados
      this.subjectString$.pipe(
        filter((res) => {
          return true;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.loadingSearch = true)),
        switchMap((term) => {
          return this.factoringService.buscarBeneficiariosObserver(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.loadingSearch = false))
          );
        })
      )
    );
  }

  loadSeachBusquedaOficiales() {
    /**
     * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
     * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
     * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value.
     * 		So that API will not hit if the current and previous value is the same
     * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
     * 		string typed by user
     */
    this.observerOficiales$ = concat(
      of([]), // Items predeterminados
      this.subjectStringOficiales$.pipe(
        filter((res) => {
          return true;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.loadingSearchOficiales = true)),
        switchMap((term) => {
          return this.factoringService.buscarBeneficiariosObserver(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.loadingSearchOficiales = false))
          );
        })
      )
    );
  }

  trackByFn(item: any) {
    return item.id;
  }

  rejectEmit(row) {
    this.rejectEvent.emit(row);
  }

  scrollHorizontal(direction) {
    let object = document.getElementById(this.idScroll)
    if (!object) {
      return
    }
    let sectionsSize = 5
    let scrollSize = object.scrollWidth
    let actualPosition = object.scrollLeft
    let scrollTo = 0

    if (direction) {
      scrollTo = actualPosition + (scrollSize / sectionsSize)
    } else {
      scrollTo = actualPosition - (scrollSize / sectionsSize)
    }

    object.scroll({
      // top: 100,
      left: scrollTo,
      behavior: 'smooth'
    });
  }

  allCheckedChange(bool) {
    if (bool == null) return
    for (const row of this.rows) {
      this.checkedRow[row['id']] = bool
    }
  }

  toggleCheckActionAll(value: boolean) {
    for (const row of this.rows) {
      if (this.checkedRow[row.id] === undefined && !value) continue
      if (this.checkedRow[row.id] === value) continue
      this.checkEvent.emit(row)
      this.changeCheck(row.id)
    }
  }

  changeCheck(rowId) {
    this.checkedRow[rowId] = !this.checkedRow[rowId];
  }

  generarExcel() {
    /* generate a worksheet */
    var ws = utils.json_to_sheet(this.rows);

    /* add to workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hoja 1");

    /* write workbook and force a download */
    writeFile(wb, `Reporte sabana - ${new Date().toLocaleString()}.xlsx`);
  }

  rowStyles(row) {
    return {
      'background-color': this.highlighted(row),
      'cursor': this.selectable ? 'pointer' : 'auto',
      ...this.customRowStyles
    }
  }

  rowClasses(row) {
    return [
      row.selected && this.selectable ? 'selectedHightlight' : '',
      (!row.selected) && this.selectable ? 'unSelectedHightlight' : '',
      ...this.customRowClasses
    ]
  }

  get sumCols() {
    return this.headers ? !!this.headers.find(el=>el.sumCol) : false
  }

  sumCol(rows, header) {
    if (header?.sumColIfUnaMoneda) {
      // Si las rows tienen monedas diferentes no continuar
      // sumColIfUnaMoneda tiene el campo moneda del detalle
      const monedas = new Set(rows.map(e => e[header?.sumColIfUnaMoneda]))
      if (monedas.size > 1) return undefined
    }
    return rows.reduce((acc, el)=>{
      let amount = Number(el[header.field])
      let tipoCambio = 0
      
      if (header.tipoCambioField && el[header.tipoCambioField]) {
        tipoCambio = Number(el[header.tipoCambioField])
      } else {
        tipoCambio = Number(header.tipoCambio)
      }

      if (header.pipe === 'currency_detail' || header?.sumColIfUnaMoneda) { // Todos tienen la misma moneda
        return acc += amount
      }

      if (header.pipe === 'currency') {
        let a_moneda = header.moneda_detail
        let moneda = el[header.moneda]

        if (moneda == 1) {
          if (a_moneda == 1) return acc += this.round2(amount)
          else return acc += this.round2(amount / tipoCambio)
        } else {
          if (a_moneda == 1) return acc += this.round2(amount * tipoCambio)
          else return acc += this.round2(amount)
        }
      }
    }, 0)
  }

  round2(number) {
    return Math.round(number * 100) / 100
  }
}
