import { Component, EventEmitter, Host, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ExcedentesService } from 'app/core/services/excedentes/excedentes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { AddRecaudacionModalComponent } from 'app/modules/recaudacion/modals/add-recaudacion-modal/add-recaudacion-modal.component';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { data } from 'jquery';
import { ExcedentesComponent } from '../../page/excedentes/excedentes.component';

@Component({
  selector: 'app-excedentes-modal',
  templateUrl: './excedentes-modal.component.html',
  styleUrls: ['./excedentes-modal.component.css']
})
export class ExcedentesModalComponent implements OnInit {
  @ViewChild('modalExcedentes', { static: false }) public modalExcedentes;
  @ViewChild(AddRecaudacionModalComponent) recModal: AddRecaudacionModalComponent;
  faTimes = faTimes
  public formularioExcedentes: FormGroup;
  public columnasAplicaciones: any = [];
  public filasAplicaciones: any = [];
  public filasComentarios: any = [];
  public active = 1;
  public headerExcedentesTable: columnHeader[];
  public headerHistorial: columnHeader[];
  public documentsCarteraList: any[] = [];
  public documentsCarteraSelectionList: any[] = [];
  public historial: any[] = [];
  public monedaExcedente: any;
  public excedente: any;
  @Input() beneficiarioId: any = [];
  @Input() list: any[] = [];
  @Input() readOnly: boolean = false;
  @Output() nuevoComent = new EventEmitter<any>();
  @Output() editaComent = new EventEmitter<any>();
  @Output() eliminaComent = new EventEmitter<any>();
  @Output() reloadList = new EventEmitter<any>();
  @Output() reloadTable = new EventEmitter<any>();
  public modalRef: any;
  public bottonAgregar: boolean = false;
  public _reasignar = false;
  public reasignarAceptante: boolean = false
  public reasignarCliente: boolean = false
  get reasignar() {
    return this._reasignar;
  }

  set reasignar(value) {
    let existeAceptante: any = this.formularioExcedentes.get('aceptante_nombre')
    let existeCliente: any = this.formularioExcedentes.get('cliente_nombre')

    if (value && !existeAceptante.value) {
      this.reasignarAceptante = true
    }

    if (value && !existeCliente.value) {
      this.reasignarCliente = true
    }
    this._reasignar = value
  }
  empresas: any[] = [];

  constructor(
    private modalService: NgbModal,
    public excedentesServicio: ExcedentesService,
    public formbuild: FormBuilder,
    public authService: AuthService,
    public functions: SharedFunctions,
    public factoring: FactoringService,
  ) { }

  ngOnInit(): void {
    this.initTables();
    this.factoring.obtenerEmpresas().then((res) => {
      this.empresas = res['results'];
    });
  }

  initTables() {
    let headerExcedentesTable = [
      {
        headerName: "N°",
        field: "id",
        sortable: true,
        pipe: 'indexcol'
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "tipo_documento_descripcion",
      },
      {
        class: "text-center",
        headerName: "Nro de Documento",
        field: "numero_documento",
      },
      {
        class: "text-center",
        headerName: "Excedente",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fch. aplicación",
        field: "fecha",
        pipe: "date",
        sortable: true,
      },
      {
        headerName: 'Aplicación',
        field: 'recaudacion',
        class: 'text-center',
        sortable: true,
        textField: 'recaudacion',
        pipe: 'actionable',
        actionableType: 'recaudacion',
      },
    ];


    let headerHistorialTable = [
      {
        headerName: "Usuario",
        field: "usuario_actualizacion",
        sortable: true,
      },
      {
        headerName: "Cliente Reasignado",
        field: "cliente_nombre",
        sortable: true,
      },
      {
        headerName: "Aceptante Reasignado",
        field: "aceptante_nombre",
        sortable: true,
      },
      {
        headerName: "Fecha",
        field: "fecha_actualizacion",
        sortable: true
      },
    ]

    this.headerExcedentesTable = headerExcedentesTable;
    this.headerHistorial = headerHistorialTable;

  }

  initModal(excedente, comentarios, aplicaciones, historial) {
    this.filasComentarios = comentarios
    this.excedente = excedente
    let montoExcedente = Number.parseFloat(this.excedente.monto)
    let totalAplicaciones = aplicaciones.reduce((acc, item) => {
      acc += Number.parseFloat(item.pago)
      return acc
    }, 0)
    this.historial = historial.map((item) => {
      item.fecha_actualizacion = this.functions.formaStringDate(item.fecha_actualizacion)
      return item
    })

    totalAplicaciones < montoExcedente ? this.bottonAgregar = false : this.bottonAgregar = true
    this.initForm(excedente)
    this.initTable(aplicaciones)
    this.displayModal()
  }

  displayModal() {
    this.modalService.open(this.modalExcedentes, {
      size: 'xl'
    }).result.then((response: any) => {
    })
  }

  initForm({
    empresa = '',
    cliente_nombre = '',
    cliente_ruc = '',
    estado_descripcion = '',
    aceptante_nombre = '',
    aceptante_ruc = '',
    operacion = '',
    tipo_documento_descripcion = '',
    numero_documento = '',
    fecha = '',
    recaudacion = '',
    monto = '',
    saldo = '',
    tipo_cambio = '',
  }) {
    this.formularioExcedentes = this.formbuild.group({
      empresa: [empresa],
      cliente_nombre: [cliente_nombre],
      cliente_ruc: [cliente_ruc],
      estado_descripcion: [estado_descripcion],
      aceptante_nombre: [aceptante_nombre],
      aceptante_ruc: [aceptante_ruc],
      operacion: [operacion],
      tipo_documento_descripcion: [tipo_documento_descripcion],
      numero_documento: [numero_documento],
      fecha: [fecha],
      recaudacion: [recaudacion],
      monto: [monto],
      saldo: [saldo],
    });

    this.formularioExcedentes.disable()
  }

  nuevoComentario(coment) {
    this.nuevoComent.emit(coment)
  }

  editarComentario(coment) {
    this.editaComent.emit(coment)
  }

  eliminarComentario(coment) {
    this.eliminaComent.emit(coment)
  }

  initTable(filas) {
    this.filasAplicaciones = filas
    this.columnasAplicaciones = [
      {
        headerName: "N°",
        pipe: "indexcol",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aplicación",
        field: "recaudacion",
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "tipo_documento_descripcion",
      },
      {
        class: "text-center",
        headerName: "Nro Documento",
        field: "numero_documento",
      },
      {
        class: "text-center",
        headerName: "Fecha de Pago",
        field: "fecha_pago",
        pipe: 'function',
        function: function (row) {
          let split = row.fecha_pago.split('-')
          return `${split[2]}/${split[1]}/${split[0]}`
        },
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: 'function',
        function: function (row) {
          if (row.moneda_recaudacion == 1) {
            let number = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(row.monto))
            return `S/ ${number}`
          }
          else {
            return `$ ${Number(row.monto).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
          }
        },
      },
      {
        class: "text-center",
        headerName: "Pago",
        field: "pago",
        pipe: 'function',
        function: function (row) {
          if (row.moneda_recaudacion == 1) {
            let number = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(row.pago))
            return `S/ ${number}`
          }
          else {
            return `$ ${Number(row.pago).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
          }
        },
      },
      {
        class: "text-center",
        headerName: "Nuevo Saldo",
        field: "saldo",
        pipe: 'function',
        function: function (row) {
          let retorno;
          if (row.moneda_recaudacion === 1) {
            let number = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(row.saldo))
            retorno = `S/ ${number}`
          }
          else
            retorno = `$ ${Number(row.saldo).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
          return retorno;
        },
      }
    ]
  }

  refrescaComentarios(comentarios) {
    this.filasComentarios = []
    this.filasComentarios = comentarios
  }

  addRecaudacion() {
    // this.recModal.initModal(this.excedente)
    this.modalRef = this.modalService.open(AddRecaudacionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });

    this.modalRef.componentInstance.openFromExcedentes = true;

    setTimeout(() => {
      this.modalRef.componentInstance.initModal(this.excedente)
    }, 1000)

    this.modalRef.componentInstance.reloadList.subscribe((res) => {
      this.reloadList.emit(res)
    })
  }


  monedapipe(moneda, valor) {
    if (moneda == 1) {
      let number = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(valor))
      return `S/ ${number}`
    }
    else {
      return `$ ${Number(valor).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
    }
  }

  reasignarExcedente() {
    this.reasignar = true
  }

  obtenerEntidadCliente(cliente) {
    let bodyRequest = {
      cliente: cliente.id,
      aceptante: null
    }
    this.excedentesServicio.modificarPropiedadesDeExcedente(bodyRequest, this.excedente.id).then((res: any) => {
      this.reasignarCliente = false
      this.initForm(res)
      this.recargaTable()
    }).catch((err) => {
      console.log(err)
    })
  }

  obtenerEntidadAceptante(aceptante) {
    let bodyRequest = {
      cliente: null,
      aceptante: aceptante.id
    }
    this.excedentesServicio.modificarPropiedadesDeExcedente(bodyRequest, this.excedente.id).then((res: any) => {
      this.reasignarAceptante = false
      this.initForm(res)
      this.recargaTable()
    }).catch((err) => {
      console.log(err)
    })
  }

  recargaTable() {
    // setTimeout(()=>{
    this.reloadTable.emit(this.excedente.id)
    // },3000)
  }

  mostrarBotonReasignar() {
    let valorAceptanteNombre = this.formularioExcedentes.get('aceptante_nombre').value
    let valorClienteNombre = this.formularioExcedentes.get('cliente_nombre').value
    let estadoVigente = this.excedente ? this.excedente.estado == 1 : false
    let user = this.authService.user
    // console.log(user.perfil)
    if (!(user.isAsistenteCobranzas || user.isJefeDeOperaciones)) {
      return false
    }

    if (estadoVigente) {
      if (valorAceptanteNombre && valorClienteNombre) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }

  }

  recargarHistorial(nuevoHistorial) {
    this.historial = nuevoHistorial.map((item) => {
      item.fecha_actualizacion = this.functions.formaStringDate(item.fecha_actualizacion)
      return item
    })
  }

  get showAgregarAplicacionButton() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilAnalistaRiesgosID,
      this.authService.perfil.perfilJefeDeAdmisionID,
      this.authService.perfil.perfilAsesorLegalID,
      this.authService.perfil.perfilLegalID,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilSubGerenciaComercialID,
      this.authService.perfil.perfilTesoreriaID,
      this.authService.perfil.perfilLiderDeTesoreriaID,
      this.authService.perfil.perfilRecepcionID,
      this.authService.perfil.perfilAsistenteCobranzasID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }
}
