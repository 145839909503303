import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { ExpedientesService } from 'app/core/services/expedientes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { DevolucionModalComponent } from 'app/modules/excedentes/componentes/devolucion-modal/devolucion-modal.component';
import { event } from 'jquery';

@Component({
	selector: 'app-bandeja-pagos',
	templateUrl: './bandeja-pagos.component.html',
	styleUrls: ['./bandeja-pagos.component.css']
})
export class BandejaPagosComponent implements OnInit {
	public ordenes: any[];
	public columnDefs: any[]
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public rowData: any[] = [];
	public pageTablePages: number[];
	public searchstring: string = '';
	public order_delete: any;
	@ViewChild('desabilitarOrden', { static: false }) public desabilitarOrden;
	faPlus = faPlus;
	faSyncAlt = faSyncAlt;
	faTimes = faTimes;
	ocultarBtn = false;
	constructor(
		private authService: AuthService,
		private router: Router,
		private factoringService: FactoringService,
		private docService: DocschecklistService,
		private modalService: NgbModal,
		public expedientes: ExpedientesService
	) { }

	ngOnInit(): void {
		let ordenesTipo = this.expedientes.obtenerTiposDeOrdenDePago()
		let oficialesNegocio = this.factoringService.obtenerResponsables(3);
		let estadosOrden = this.expedientes.obtenerEstadoOrden();
		let empresas = this.factoringService.obtenerEmpresas()

		Promise.all([ordenesTipo, oficialesNegocio, estadosOrden, empresas]).then((response: any[]) => {
			let tipos = response[0].results
			let	oficialesNegocio = response[1].results
			let	estadosOrden = response[2].results
			let empresas = response[3].results;

			this.cargarTable(tipos, oficialesNegocio, estadosOrden, empresas)
		})

	}

	cargarTable(tipos: any[], oficialesNegocio: any[], estadosOrden: any[], empresas: any[]) {
		console.log(oficialesNegocio)
		this.columnDefs = [
			{
				headerName: 'Nro Orden',
				field: 'id',
				class: 'text-center',
				sortable: true,
			},
			{
				headerName: 'Tipo',
				field: 'tipo_descripcion',
				filterProp: 'tipo',
				sortable: true,
				filterable: true,
				filterSelectItems: tipos,
				filterItemsProps: {
					value: 'id',
					label: 'descripcion'
				},
			},
			{
				headerName: 'RUC',
				field: 'beneficiario_ruc',
				sortable: true,
				class: 'text-center',
			},
			{
				headerName: 'Cliente',
				field: 'beneficiario_nombre',
				filterable: true,
				filterProp: 'beneficiario_nombre',
				filterInput: true,
				sortable: true,
				class: 'text-center',
			},
			{
				headerName: 'RUC',
				field: 'aceptante_ruc',
				sortable: true,
				class: 'text-center',
			},
			{
				headerName: 'Aceptante',
				field: 'aceptante_nombre',
				filterable: true,
				filterProp: 'aceptante_nombre',
				filterInput: true,
				sortable: true,
				class: 'text-center',
			},
			{
				headerName: 'Responsable',
				field: 'responsable_desembolso_nombre',
				sortable: true,
				class: 'text-center',
				filterProp: 'operacion__oficial_negocio',
				filterable: true,
				filterSelectItems: oficialesNegocio,
				filterItemsProps: {
					value: 'id',
					label: 'nombre'
				},
			},
			{
				headerName: 'Monto',
				field: 'monto',
				sortable: true,
				class: 'text-center',
				pipe: 'function',
				filterable: true,
				filterRange: true,
				function: function (row, header) {

					if (row.tipo == 3) {
						return '';
					}

					if (row.moneda == 1) {
						let number = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(row.operacion_monto))
						return `S/ ${number}`
					}
					else {
						return `$ ${Number(row.operacion_monto).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
					}
				},
			},
			// {
			// 	headerName: 'Anticipo', 
			// 	field     : 'operacion_anticipo_comite' ,
			// 	pipe      : 'function',
			// 	sortable  : true,
			// 	class     : 'text-center',
			// 	function  : function(row, header) {
			// 		if (row.tipo == 3) {
			// 			return '';
			// 		}

			// 		return `${row.operacion_anticipo_comite} %`
			// 	}
			// },
			// {
			// 	headerName: 'Adelanto', 
			// 	field     : 'operacion_adelanto_comite' ,
			// 	sortable  : true,
			// 	class     : 'text-center',
			// 	pipe      : 'function',
			// 	function : function(row,header){
			// 		if (row.tipo == 3) {
			// 			return '';
			// 		}

			// 		if(row.moneda == 1){
			// 			let number = new Intl.NumberFormat("en-IN",{minimumFractionDigits: 2}).format(Number(row.operacion_adelanto_comite))
			// 			return `S/ ${number}`
			// 		}
			// 		else{
			// 			return `$ ${Number(row.operacion_adelanto_comite).toLocaleString('en-US', {minimumFractionDigits: 2})}`
			// 		}
			// 	},					
			// },
			// {
			// 	headerName: 'Desembolso', 
			// 	field     : 'operacion_monto_desembolso' ,
			// 	sortable  : true,
			// 	class     : 'text-center',
			// 	pipe      : 'function',
			// 	function : function(row,header){

			// 		if(row.tipo == 2){
			// 			return 0
			// 		}

			// 		if(row.tipo == 3){
			// 			if(row.moneda == 1){
			// 			let number = new Intl.NumberFormat("en-IN",{minimumFractionDigits: 2}).format(Number(row.monto))
			// 				return `S/ ${number}`
			// 			}
			// 			else{
			// 				return `$ ${Number(row.monto).toLocaleString('en-US', {minimumFractionDigits: 2})}`
			// 			}
			// 		}

			// 		if(row.moneda == 1){
			// 			let number = new Intl.NumberFormat("en-IN",{minimumFractionDigits: 2}).format(Number(row.monto))
			// 			return `S/ ${number}`
			// 		}
			// 		else{
			// 			return `$ ${Number(row.desembolso_liquidacion).toLocaleString('en-US', {minimumFractionDigits: 2})}`
			// 		}
			// 	},
			// },
			{
				headerName: 'Fecha orden',
				field: 'fecha',
				pipe: 'date',
				sortable: true,
				filterable: true,
				filterDate: true,
				class: 'text-center',
			},
			{
				headerName: 'Estado',
				field: 'estado_descripcion',
				sortable: true,
				class: 'text-center',
				filterProp: 'estado',
				filterable: true,
				filterSelectItems: estadosOrden,
				filterItemsProps: {
					value: 'id',
					label: 'descripcion'
				},
				defaultValue: {
					id: 1,
					descripcion: 'Vigente'
				}
			},
			{
				headerName: 'Operación',
				field: 'operacion',
				class: 'text-center',
				sortable: true,
				filterable: true,
				filterProp: "operacion",
				filterInput: true,
			},
		];
	}

	recargarTabla() {
		this.expedientes.obtenerOrdenesDePago().then((ordenes: any) => {
			this.ordenes = ordenes.results
		})

	}

	tableEditEvent(event) {

		switch (event.tipo) {
			case 3:
				this.openDevolucion(event);
				break;

			default:
				this.router.navigate(['/ordenes-pagos', event.operacion]);
				break;
		}
	}

	openDevolucion(row) {
		const modalRef = this.modalService.open(DevolucionModalComponent, {
			ariaLabelledBy: "modal-basic-title",
			size: "xl",
		});

		modalRef.componentInstance.devolucionId = row.solicitud_devolucion;

		modalRef.result.then((result) => {
			console.log(result);
			if (result) {

			}
		}, (reason) => {
			console.log(reason);
		});
	}

	/**
	 * Inicializa la tabla con datos y paginacion
	 */
	loadTable(data) {
		this.rowData = data.results.map( (item)=>{

			if( item.estado === 2 ){
				this.ocultarBtn = true;
			}else{
				this.ocultarBtn = false;
			}
			return item;

		})
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

	goToPage({ page, per_page, filtros }) {
		console.info(filtros)
		const range = (filtros.valueFrom && filtros.valueTo) ? `${filtros.valueFrom},${filtros.valueTo}` : '';

		this.expedientes.obtenerOrdenesDePago(
			page,
			per_page,
			filtros.operacion__beneficiario ? filtros.operacion__beneficiario : '',
			filtros.tipo ? filtros.tipo : '',
			filtros.fecha__gte ? filtros.fecha__gte.split(' ')[0] : '',
			filtros.fecha__lte ? filtros.fecha__lte.split(' ')[0] : '',
			filtros.estado ? filtros.estado : '',
			filtros.operacion__oficial_negocio ? filtros.operacion__oficial_negocio : '',
			range,
			filtros.operacion,
			filtros.empresa ? filtros.empresa : '',
			filtros.beneficiario_nombre,
			filtros.aceptante_nombre,
		).then((res) => {
			this.loadTable(res)
		})
	}

	reloadTable() {
		this.expedientes.obtenerOrdenesDePago(
			this.pageTableActual,
			this.pageTablePerPages,
		).then((res) => {
			this.loadTable(res)
		})
	}

	tableDeleteEvent(toDelete) {

		this.order_delete = toDelete
		if (toDelete.estado == 3 || toDelete.estado == 2) {
			return
		}
		

		this.modalService.open(this.desabilitarOrden, {
			size: 'md'
		}).result.then((response: any) => {
			console.log(response)
			if (response) {
				toDelete.estado = 3
				this.expedientes.modificarOrdenDePago(toDelete.id, toDelete).then((response: any) => {
					this.reloadTable()
				})
			}
		})
	}

	
	get canAddOrDelete() {
		const perfilesPermitidos = [
            this.authService.perfil.perfilTesoreriaID, 
            this.authService.perfil.perfilLiderDeTesoreriaID, 
            this.authService.perfil.perfilJefeDeOperacionesID, 
		]
	
		return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
	}

}

