import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import Swal from 'sweetalert2';

import { FactoringService } from 'app/core/services/factoring/factoring.service';

@Component({
  selector: 'app-feriado-picker',
  templateUrl: './feriado-picker.component.html',
  styleUrls: ['./feriado-picker.component.css']
})
export class FeriadoPickerComponent implements OnInit {

  @Output() public onChangePicker: EventEmitter<any> = new EventEmitter<any>();

  @Input() public anio = null
  @Input() public mes = null
  @Input() public feriados = null

  public minDate = null
  public maxDate = null
  public dateCustomClasses = []
  public dateTooltipTexts = []

  constructor(private factoringService: FactoringService) { }

  ngOnInit(): void {
    this.minDate = new Date(this.anio, this.mes - 1, 1)
    this.maxDate = new Date(this.anio, this.mes, 0)
    this.dateCustomClasses = [...this.feriados.map(feriado => ({ date: new Date(`${feriado.fecha}T00:00:00`), classes: ['bg-danger', 'text-white'] }))]
    this.dateTooltipTexts = [...this.feriados.map(feriado => ({ date: new Date(`${feriado.fecha}T00:00:00`), tooltipText: feriado.descripcion }))]
  }

  onValueChange(value) {
    this.openModalGuardar(value)
  }

  openModalGuardar(value) {
    const fecha = new Date(value.setHours(0, 0, 0, 0)).toISOString().substring(0, 10)
    const indice = this.feriados.findIndex(feriado => feriado.fecha == fecha)
    const data = indice >= 0 ? { ...this.feriados[indice] } : {}

    Swal.fire({
      title: 'Guardar feriado',
      text: fecha.split('-').reverse().join('/'),
      input: 'text',
      inputValue: data.descripcion ?? '',
      inputPlaceholder: 'Descripción',
      inputAttributes: {
        autocapitalize: 'off',
        label: 'Descripción',
      },
      showCancelButton: true,
      showDenyButton: !!data.id,
      confirmButtonText: 'Guardar',
      denyButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      showLoaderOnDeny: true,
      preConfirm: async (descripcion) => {
        try {
          data.fecha = fecha
          return await this.factoringService.saveFeriado({ ...data, descripcion }).toPromise()
        } catch (error) {
          Swal.showValidationMessage('No se guardó, intente nuevamente');
          return false
        }
      },
      preDeny: async () => {
        try {
          await this.factoringService.deleteFeriado(data.id).toPromise()
          return data
        } catch (error) {
          Swal.showValidationMessage('No se eliminó, intente nuevamente');
          return false
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        this.onChangePicker.emit({ value: result.value, saved: true })
      } else if (result.isDenied) {
        this.onChangePicker.emit({ value: result.value, deleted: true })
      }
    })

  }

}
