import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-editar-detalle',
  templateUrl: './editar-detalle.component.html',
  styleUrls: ['./editar-detalle.component.css']
})
export class EditarDetalleComponent implements OnInit {

  @Input() itemInstance: any = null;
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  forma: FormGroup
  faTimes = faTimes

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) {
    this.forma = this.formBuilder.group({
      'dias_prorroga': [null, [Validators.required]],
      'saldo': [null, [Validators.required]],
      'saldo_financiado': [null, [Validators.required]],
      'saldo_documento': [null],
      'saldo_financiado_documento': [null],
      'abono': [null],
    })
  }

  ngOnInit(): void {
    this.forma.patchValue(Object.assign({}, this.itemInstance))
  }

  onSubmit() {
    let data = this.forma.value
    this.successEmit.emit(data)
    this.activeModal.close(true)
  }

  simularAbono() {
    const saldo = this.forma.get('saldo_documento').value
    const saldoFinanciado = this.forma.get('saldo_financiado_documento').value
    const abono = this.forma.get('abono').value
    this.forma.get('saldo').setValue(Math.round((saldo - abono) * 100) / 100)
    this.forma.get('saldo_financiado').setValue(Math.round((saldoFinanciado - abono) * 100) / 100)
  }

}
