import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigRoutingModule } from './config-routing.module';
import { DocumentosChecklistComponent } from './pages/documentos-checklist/documentos-checklist.component';
// import { UsersCrudComponent } from './pages/users-crud/users-crud.component';
import { SharedModule } from '../../shared/shared.module';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CompletarSolicitudComponent } from './pages/completar-solicitud/completar-solicitud.component';
import { RegistrarSolicitudFactoringComponent } from './pages/registrar-solicitud-factoring/registrar-solicitud-factoring.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ArchwizardModule } from 'angular-archwizard';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { EstacionesModalComponent } from './partials/modals/estaciones-modal/estaciones-modal.component';
import { RiesgoTabsComponent } from '../riesgos/componentes/riesgo-tabs/riesgo-tabs.component';
import { RiesgosModalComponent } from '../riesgos/componentes/riesgos-modal/riesgos-modal.component';
const maskConfig: Partial<IConfig> = {
  validation: false,
};


@NgModule({
  declarations: [
    DocumentosChecklistComponent,
    RegistrarSolicitudFactoringComponent,
    CompletarSolicitudComponent,
    EstacionesModalComponent,
    RiesgoTabsComponent,
    RiesgosModalComponent
  ],
  entryComponents: [
    EstacionesModalComponent
  ],
  imports: [
    FontAwesomeModule,
    CommonModule,
    ConfigRoutingModule,
    SharedModule,
    JwBootstrapSwitchNg2Module,
    NgbModule,
    NgSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule.forRoot({
        size              : 'medium',
        color             : '#3e50b4',
        checkedTextColor  : "#ffffff",
        uncheckedTextColor: "#ffffff",
        defaultBgColor    : '#6c757d',
        defaultBoColor    : '#3e50b4',
        checkedLabel      : 'Si ',
        uncheckedLabel    : 'No'
    }),
    ArchwizardModule
  ]
})
export class ConfigModule { }
