import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { LegalService } from 'app/core/services/legal.service';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-formato-pagare',
  templateUrl: './formato-pagare.component.html',
  styleUrls: ['./formato-pagare.component.css']
})
export class FormatoPagareComponent implements OnInit {

  myForm: FormGroup;
  
  @Input() beneficiario: number;
  @Input() beneficiario_tipo;
  @Input() formato_codigo;
  @Input() formato_plantilla_url;
  @Input() numero_contrato;
  @Input() fecha_vencimiento;
  @Input() operacion;
  @Input() contrato;

  cliente: any;
  representantes: any[] = [];
  avales: any[] = [];

  avalesSelect: any[] = [];
  liquidacion: any;
  linea: any;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private formatoService: FormatosService,
    private clientesService: ClientesService,
    public toast: ToastService,
    private propuestaService  : PropuestaComercialService,
    private legalService	: LegalService,
  ) { }

  async ngOnInit() {

    console.log( '****AQUI*****' ,this.formato_plantilla_url )

    console.log(this.operacion);

    this.createForm();

    await this.clientesService.obtenerLinea(this.operacion.linea_credito).then(resp => {
      console.log(resp);
      this.linea = resp;
    }, err => {
      console.error(err);
    });

    this.legalService.obtenerFirmantes(this.contrato.id).then(resp => {
      console.log(resp);
      this.representantes = resp['results'];
    }, err => {
      console.error(err);
      
    });

    this.clientesService.obtenerCliente(this.linea.beneficiario).then((cliente: any) => {
      this.cliente = cliente;
    });

    this.clientesService.obtenerAvales(this.linea.beneficiario).then(response => {
      this.avales = response['results'];
    }, err => {
      console.error(err);
    });

    this.propuestaService.ObtenerSimulacionDeLiquidacion(this.operacion.id).then(res => {
      this.liquidacion = res;
    }, err => {
      console.error(err);
      
    });

  }

  createForm() {
    this.myForm = this.formBuilder.group({
      avales: [null, Validators.required],
    });
  }

  changeFirmantes(e) {
    console.log(e);
    this.avalesSelect = e.map(i => {
      return {
        nombre: i.primer_nombre,
        domicilio: i.domicilio,
        distrito: i?.distrito_nombre,
        provincia: i?.provincia_nombre,
        departamento: i?.departamento_nombre,
        dni: i.dni,
        tipo: false,
        mostrar_domicilio: false
      };
    });
  }

  onSubmit() {

    if (!this.operacion) {
      this.toast.warning('Asegurese la operación esta en una estación posterior a comité.');
      this.activeModal.close(false);
      return;
    }    

    if (this.operacion.estacion < this.operacion.estacionOficialNegocioPostComite) {
      this.toast.warning('La operación debe estar en una estación posterior a comité.');
      this.activeModal.close(false);
      return;
    }

    if (!this.avalesSelect.length) {
      this.toast.warning('Debe seleccionar al menos un aval');
      this.activeModal.close(false);
      return;
    }

    const repre = this.representantes.map(r => {
      return {
        nombre            : r.firmante_nombre,
        dni               : r.nro_documento,
      }
    });

    const data = {
      monto               : this.operacion.linea_solicitada,
      numero_contrato     : this.numero_contrato,
      fecha_vencimiento   : this.fecha_vencimiento,
      cliente_nombre      : this.cliente.nombre,
      cliente_ruc         : this.cliente.ruc,
      cliente_inscripcion : this.cliente.numero_partida,
      cliente_domicilio   : this.cliente.domicilio_legal,
      tea_soles           : this.liquidacion.tea_soles_comite,
      tea_dolares         : this.liquidacion.tea_dolares_comite,
      tem_soles           : this.liquidacion.tem_soles_comite,
      tem_dolares         : this.liquidacion.tem_dolares_comite,
      moneda              : this.linea.moneda.toString(),
      representantes      : repre,
      avales              : this.avalesSelect.map(r => {
        let d = { ...r };
        d['tipo'] = (r.tipo) ? 'Cónyuge': 'Fiador';
        return d;
      }),
      distrito            : this.cliente.distrito_nombre,
      provincia           : this.cliente.provincia_nombre,
      departamento        : this.cliente.departamento_nombre,
      oficina_registral   : this.cliente.oficina_registral_nombre ? this.cliente.oficina_registral_nombre : '',
      zona_registral      : this.cliente.zona_registral_nombre ? this.cliente.zona_registral_nombre : ''

    };

    console.log(data);

    this.formatoService.generarFormatoPagare(data).then(
      (x: any) => {
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Pagare y Fianzas.docx";
        link.click();
        this.activeModal.close(data);
      }, async (reason) => {
        this.activeModal.close(data);
        console.log(reason);
        const message = JSON.parse(await reason.error.text());
        console.log(message);
        this.showModalErrorContrato(message['detail']);
      });

  }

  showModalErrorContrato(message) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {

    });

    modalRef.componentInstance.showBottomLeft = false;
    modalRef.componentInstance.title = 'Información requerida en el contrato marco';
    modalRef.componentInstance.titleRightBottom = 'Aceptar';
    modalRef.componentInstance.messageHTML = true;
    modalRef.componentInstance.message = `<span class="">${message}</span>`;
  }

}
