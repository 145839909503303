
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
import { FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DinamicMultiSelectInputs, isActionsDinamicMultiSelect, FormDinamicMultiSelectComponent} from 'app/shared/form-multiselect/form-multiselect.component';
import {CasillaMatrizRiesgo} from '../modal-matrices-riesgo/modal-matrices-riesgo.component';
import { faTimes } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-modal-elementos-matriz-riesgo',
  templateUrl: './modal-elementos-matriz-riesgo.component.html',
  styleUrls: ['./modal-elementos-matriz-riesgo.component.css']
})
export class ModalElementosMatrizRiesgoComponent implements OnInit {
faTimes                     = faTimes
public casilla              : CasillaMatrizRiesgo;
public data                 : any[] = [];
public elementos            : any[] = [];
public matriz_riesgo_id     : number;
public elementoForm        : FormGroup;
public propertiesShow       = ["descripcion", "nombre"];
public buttonsShow          : isActionsDinamicMultiSelect;
public inputs               : DinamicMultiSelectInputs;

@ViewChild('multiSelect', {static: false}) multiSelect: FormDinamicMultiSelectComponent
readOnlyButtonActualizar    = false;
  constructor(
    private formBuilder         : FormBuilder,
    public activeModal          : NgbActiveModal,
    private lavadoActivosService: LavadoActivosService,
  ) { }

  async ngOnInit() {
    await this.initForm()
    this.multiSelect.content = this.elementos
  }
  

  
  async eliminarElementos(data){
    this.casilla.elementos = data['filtred']
    //await this.lavadoActivosService.eliminarValores(row);
  }
    
  async initForm() {
    this.elementos = this.casilla.elementos
    this.elementoForm = this.formBuilder.group({
      descripcion : [ '', [Validators.required]],
      nombre: ['', [Validators.required]]
    });
    this.inputs = {
      form: this.elementoForm,
      inputs: [
        {
          label: 'Descripción',
          is_disabled: false,
          validator_name: 'descripcion',
          is_unnique: false,
          type: 'text',
          class: 'col-6'
        },
        {
          label: 'Nombre',
          is_disabled:false,
          validator_name: 'nombre',
          is_unnique: true,
          type: 'text',
          class: 'col-6'
        }
      ]
    }
    this.buttonsShow = {
      isDelete: true,
      isEdit: true,
      isAdd:true,
    }
  }

  async enviarCabecera() {
    var i = 0
    this.casilla.elementos = this.elementos
      
  }
}
