import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from "../toast/toast.service";
@Injectable({
  providedIn: 'root'
})
export class CuentasCobrarService {

  private apiUrl      : string = "";
  public cuentas      : string = "factoring/liquidaciones/cuentas/";
  public conceptos    : string = "factoring/liquidaciones/configuraciones/conceptos/";

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
  }

    /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show();
  }
  
  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();
    this.appService.notifyMe(mensaje,ok)

    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);
  }

  /**
   * Retorna los documentos
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */

  obtenerCuentasCobrar(
    page: number = 1,
    page_size: number = 10,
    liquidacion = "",
    beneficiario = "",
    deudor = "",
    estado__in = "",
    recaudacion = ""
  ) {
    const url =
      this.apiUrl +
      this.cuentas +
      `?liquidacion=${liquidacion}` +
      `&beneficiario=${beneficiario}` +
      `&deudor=${deudor}` +
      `&estado__in=${estado__in}` +
      `&recaudacion=${recaudacion}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La operación que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  obtenerConceptos(
    page: number = 1,
    page_size: number = 1000,
  ) {
    const url = this.apiUrl + this.conceptos + 
                `?page=${page}&page_size=${page_size}`;
    
    return new Promise((res,ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response)=>{
            this.spinnerOff();
            res(response);
        }, (err)=>{
          this.spinnerOff('La operación falló',false);
          ref(err);
        });
    });

  }

}
