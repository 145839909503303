import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faFistRaised, faLongArrowAltLeft, faLongArrowAltRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-lideres-asignar',
  templateUrl: './lideres-asignar.component.html',
  styleUrls: ['./lideres-asignar.component.css']
})
export class LideresAsignarComponent implements OnInit {
  faTimes = faTimes;
  faFistRaised = faFistRaised;
  @Input() usuariosParaLider;
  public supervisados;
	form     : FormGroup;
  public oficialesAll = [];
  public oficiales = [];
  public rowOficialesAll;
  public supervisadosAll;
  public rowSupervisadosAll;
  // @Input() user;
  faLongArrowAltRight = faLongArrowAltRight;
  faLongArrowAltLeft  = faLongArrowAltLeft;
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public auth         : AuthService,
    private modalService: NgbModal,
		private _formBuilder  : FormBuilder,
    public activeModal  : NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
			searchOficial             : ['',],
		});

    this.form.get('searchOficial').valueChanges.subscribe((value)=>{
      this.oficiales = this.searchStringFunction(value)
    })

    this.cargarData()
  }

  cargarData(){
    let oficiales = this.auth.obtenerUsuariosResponsables(1,150,'3',true)

    Promise.all([oficiales]).then((res: any[])=>{
      this.oficialesAll = res[0]['results']
      this.oficiales = this.oficialesAll
    })
  }

  searchStringFunction(text) {
    if (text.toLowerCase().trim() === "") {
      return this.oficialesAll
    } else {
      let arregloIterador = this.oficialesAll
      return arregloIterador.reduce((acc, value) => {
        for (var prop in value) {
          if (
            value[prop] &&
            value[prop].toString().toLowerCase().includes(text.toLowerCase())
          ) {
            acc.push(value);
            return acc;
          }
        }
        return acc;
      }, []);
    }
  }

  updateOficial(evento,oficial){
    oficial.es_lider = evento
    if(!evento){
      this.confirmarDesactivacionDeLider(oficial)
    } else {
      this.auth.updateLider(oficial.id,oficial).then((res)=>{
        this.cargarData
        this.successEmit.emit(true)
      })
    }
  }


  confirmarDesactivacionDeLider(lider) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Líder';
		modalRef.componentInstance.message = 'Una vez removido el rol de Líder, todos los miembros de sus equipos quedarán sin Líder. ¿Desea Continuar?';
		
		modalRef.result.then((result) => {
		if (result) {
      this.auth.updateLider(lider.id,lider).then((res)=>{
        this.cargarData
        this.successEmit.emit(true)
      })
		} else {
      let indice = this.oficiales.findIndex(({id}) => id == lider.id)
      this.oficiales[indice].es_lider = true
    }
		}, (reason) => {
			console.log(reason);
		});
  }

}
