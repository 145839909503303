import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { VerificationsService } from 'app/core/services/factoring/verifications.service';

@Component({
  selector: 'app-add-comments-modal',
  templateUrl: './add-comments-modal.component.html',
  styleUrls: ['./add-comments-modal.component.css']
})
export class AddCommentsModalComponent implements OnInit {

  myForm: FormGroup;
  @Input() row : any;
  @Input() user : any;
  @Input() comment : any;
  faTimes = faTimes;
  constructor(
    private verificacionService: VerificationsService,
    public formbuild    : FormBuilder,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.myForm = this.formbuild.group({
      comentario       : [(this.comment) ? this.comment.comentario : null , [Validators.required]],
    });
  }

  onSubmit() {

    const values = this.myForm.value;
    values['operacion_detalle'] = this.row.id;
    values['responsable'] = this.user.id;

    if (!this.comment) {
      this.verificacionService.crearComentario(values)
      .then(result => {
        console.log(result);
        this.activeModal.close(true);
      })
      .catch(error => {
        console.error(error);
        this.activeModal.close(false);
      });
    } else {
      this.verificacionService.editarComentario(this.comment.id ,values)
      .then(result => {
        console.log(result);
        this.activeModal.close(true);
      })
      .catch(error => {
        console.error(error);
        this.activeModal.close(false);
      });
    }

  }

}
