import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faExclamationTriangle, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { CommonServicesService } from 'app/core/services/common-services.service';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import { RelationsClientComponent } from 'app/modules/clientes/partials/bussins/relations-client/relations-client.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { resolve } from 'path';

@Component({
  selector: 'app-desgloses',
  templateUrl: './desgloses.component.html',
  styleUrls: ['./desgloses.component.css'],
  animations: [
    trigger("animationOption2", [
      state(
        "close",
        style({
          opacity: 0,
          height: "0px",
        })
      ),
      state(
        "open",
        style({
          height: "60px",
          opacity: 1,
        })
      ),
      transition("close <=> open", animate(250)),
    ]),
  ],
})
export class DesglosesComponent implements OnInit {
  faSave            = faSave;
  faPlus            = faPlus;
  faTimes            = faTimes;
  faExclamationTriangle = faExclamationTriangle;
  flagCall : boolean = false;
  flagLoader : boolean = false;
  errorDisplayer : any;
  _operacion
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() set operacion(idOperacion){
    if(!idOperacion)
      return
    this._operacion = idOperacion
  };
  get operacion(){
    return this._operacion;
  }
  @Input() operacionBody; 
  @Input() propuestaId; 
  @Input() detalles;
  @Input() readOnly: boolean = false; 
  @Input() readOnly_: boolean = false;
  _desgloses: any[] 
  @Input() set desgloses(value){
    if(value){
      this._desgloses = value;
    } else {
      this._desgloses = []
    }
  }
  get desgloses(){
    return this._desgloses
  }
  @Input() propuesta: string = 'comercial'; 
  public desglosesdescripcion: any[] = [
    {
      id: 1,
      descripcion: 'comercial'
    },
    {
      id: 2,
      descripcion: 'riesgos'
    },
    {
      id: 3,
      descripcion: 'comite'
    },
  ]; 
  // public desgloses: any[]; 
  public aceptantesDesgloses: any[]; 
  public seleccionForm     : FormGroup;
  public _aceptantes; 
  public _aceptantes_seleccion : any[]; 

  constructor(
    public modalService    : NgbModal,
    public formbuild       : FormBuilder,
    public propuestaService: PropuestaComercialService,
    private clientesService: ClientesService,
    public serviciosComunes: CommonServicesService,
    public activeModal     : NgbActiveModal,
  ) {
    this.serviciosComunes.invocarDesgloses.subscribe( (value) => {
      console.log(value);
    })
   }

  ngOnInit(): void {
    this.seleccionForm = this.formbuild.group({
      aceptanteRuc: new FormControl(null, Validators.required),
    });

  }

  agregarAceptante(){
    this.clientesService
    .obtenerRelacionesClientes(this.operacionBody.beneficiario, 1, 100)
    .then((res: any) => {
      this._aceptantes_seleccion = res['results']
      if(this._aceptantes_seleccion.length == 0){
        this.displayMessage(`El beneficiario no tiene aceptantes registrados en su cartera de clientes`)
      }
    })
    .catch((error) => {
      console.error(error);
    });
  }

  refrescarDesgloses(){
    this.deleteEvent.emit(true)
  }

  buscaYAgregaAcetante(ruc = this.seleccionForm.get('aceptanteRuc').value){

    if(this.flagCall || (!this.validaDuplicidadDeAceptantes(ruc)))
      return

    this.flagCall = true
    this.propuestaService.buscaAceptantes(ruc).then((res: any)=>{
      this.desgloses.push({
        "operacion"  : null,
        "aceptante"  : res,
        "propuesta"  : this.desglosesdescripcion.find((desgl) => this.propuesta == desgl.descripcion)?.id,
        "monto"      : null,
        "tem_soles"  : null,
        "tem_dolares": null,
        "anticipo"   : null,
        "mora"       : null,
        "comision_estructuracion": null
      })
      this.seleccionForm.get('aceptanteRuc').setValue(null)
      this.flagCall = false
    })
  }

  displayMessage(msg){
    this.errorDisplayer = msg
    // setTimeout(()=>{
      // this.errorDisplayer = null
    // },3000)
  }

  aceptantesMapper(aceptantes){
    aceptantes = aceptantes.reduce((acc,item,index)=>{
      let flag = acc.findIndex(ind => ind.ruc == item.ruc)
      if(flag < 0){
        acc.push(item)
      }
      
      return acc
    },[])
    let llamadas = 
    aceptantes
      .filter(acp => this.validaDuplicidadDeAceptantes(acp.ruc,false))
      .map((item)=>this.propuestaService.buscaAceptantes(item.ruc))

    Promise.all(llamadas).then((aceptantes)=>{
      aceptantes.forEach(acpt => {
        this.desgloses.push({
          "operacion"  : null,
          "aceptante"  : acpt,
          "propuesta"  : this.desglosesdescripcion.find((desgl) => this.propuesta == desgl.descripcion)?.id,
          "monto"      : null,
          "tem_soles"  : null,
          "tem_dolares": null,
          "anticipo"   : null,
          "mora"       : null,
          "comision_estructuracion": null
        })
      });
    })
  }

  validaDuplicidadDeAceptantes(ruc, display = true){
    let flag = this.desgloses.findIndex((item)=> item.aceptante.ruc == ruc)
    if(flag < 0){
      return true
    } else {
      display ? this.displayMessage('El aceptante ya se encuentra en el desglose') : null
      return false
    }
  }

  obtenerDesgloses(idOperacion,desglosesdescripcion,readOnly, displayMessage){
    var desglosesResponse;
    let desgloses: any[];
    let propuestaId: any;

    return new Promise((resolve,reject)=>{
      this.propuestaService.buscaAceptantesDesgloseLineas('',idOperacion)
      .then((res: any)=>{
        desglosesResponse = res
        let idsPromesa = [];
        let promesas = res.reduce((acc,item)=>{
          if(!idsPromesa.includes(item.aceptante)){
            idsPromesa.push(item.aceptante)
            acc.push(this.propuestaService.buscaAceptantesById(item.aceptante))
          }
  
          return acc
        },[])
  
        return Promise.all(promesas)
      })
      .then((aceptantes: any[])=>{
        let tipoPropuesta = desglosesdescripcion.find((desglose)=> desglose.descripcion === this.propuesta)
        if(tipoPropuesta){
          propuestaId = tipoPropuesta.id
          desgloses = 
          // Se asigna aceptantes a los desgloses
          desglosesResponse.map((desglose: any)=>{
            desglose.aceptante = aceptantes.find((acp)=> acp.id == desglose.aceptante)
            return desglose
          })
          //Se filtran los desgloses por la propuesta en la variable global propuesta
          .filter((desglose)=> desglose.propuesta == tipoPropuesta.id)
          // this.flagLoader = false
          if(displayMessage){            
            if(readOnly)
              this.aceptantesMapper(this.detalles)
            else {
              if(desgloses.length == 0){
                this.displayMessage('Pulse AGREGAR para seleccionar un aceptante')
                // setTimeout(() => {this.activeModal.close(false)},3000)
              }
            }
          }
        }

        resolve({
          desgloses,
          propuestaId
        })
      })    
    });


  }

}
