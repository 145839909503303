import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FactoringService } from 'app/core/services/factoring/factoring.service';

@Component({
  selector: 'app-numero-cuenta-banco',
  templateUrl: './numero-cuenta-banco.component.html',
  styleUrls: ['./numero-cuenta-banco.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumeroCuentaBancoComponent),
      multi: true
    }
  ]
})
export class NumeroCuentaBancoComponent implements OnInit, ControlValueAccessor {
  @Input() readonly: boolean = false
  value: string
  isDisabled: boolean = false

  @Input() cuentaAbono = null
  @Input() cci: boolean = false
  valido: boolean = null
  consultado: boolean = null
  loading: boolean = false

  onChange: any = () => { }
  onTouch: any = () => { }

  private currentRequest$ = new Subject<void>()

  constructor(
    private factoringService: FactoringService,
  ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges(changes) {
    if (changes.cuentaAbono) {
      if (changes.cuentaAbono.currentValue && changes.cuentaAbono.currentValue != changes.cuentaAbono.previousValue) {
        this.loadCuentaAbono()
      }
    }
  }

  writeValue(value: any): void {
    this.value = value
  }

  registerOnChange(fn: any): void {
    this.onChange = fn
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled
  }

  loadCuentaAbono() {
    this.currentRequest$.next()
    this.loading = true
    this.factoringService.getCuentaAbono(this.cuentaAbono).pipe(
      takeUntil(this.currentRequest$)
    ).subscribe(
      (data: any) => {
        if (this.cci) {
          this.valido = data.cci_valido
          this.consultado = !!data.fecha_cci_validado
        } else {
          this.valido = data.numero_valido
          this.consultado = !!data.fecha_numero_valido
        }
      }
    ).add(() => this.loading = false)
  }

}
