import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faFistRaised, faLongArrowAltLeft, faLongArrowAltRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';

@Component({
  selector: 'app-lideres-modal',
  templateUrl: './lideres-modal.component.html',
  styleUrls: ['./lideres-modal.component.css']
})
export class LideresModalComponent implements OnInit {
  faTimes = faTimes;
  faFistRaised = faFistRaised;
  @Input() userLider;
  public supervisados;
	form     : FormGroup;
  public oficialesAll;
  public rowOficialesAll;
  public supervisadosAll;
  public rowSupervisadosAll;
  // @Input() user;
  faLongArrowAltRight = faLongArrowAltRight;
  faLongArrowAltLeft  = faLongArrowAltLeft;
  constructor(
    public auth: AuthService,
    private modalService: NgbModal,
		private _formBuilder    : FormBuilder,
    public activeModal: NgbActiveModal,
  ) {
    this.form = this._formBuilder.group({
			searchEquipo             : ['',],
			searchOficial            : ['',],
		});

    this.form.get('searchEquipo').valueChanges.subscribe((item)=>{
      this.supervisadosAll = this.searchStringFunction(item,false)
    })

    this.form.get('searchOficial').valueChanges.subscribe((item)=>{
      this.oficialesAll = this.searchStringFunction(item,true)
    })
   }

   searchStringFunction(text, direccionador) {
    if (text.toLowerCase().trim() === "") {
      return direccionador ? this.rowOficialesAll : this.rowSupervisadosAll
    } else {
      let arregloIterador = direccionador ? this.rowOficialesAll : this.rowSupervisadosAll
      return arregloIterador.reduce((acc, value) => {
        for (var prop in value) {
          if (
            value[prop] &&
            value[prop].toString().toLowerCase().includes(text.toLowerCase())
          ) {
            acc.push(value);
            return acc;
          }
        }
        return acc;
      }, []);
    }
  }


  ngOnInit(): void {
    this.loadData()
  }
  // fist-raised
  loadData(){
    let { perfilOficialDeNegocioID } = this.auth.userIn
    let oficiales                   = this.auth.obtenerUsuariosResponsables(1,50,'3',true)
    let supervisados                = this.auth.obtenerSupervisados(1,50,this.userLider.id)
    Promise.all([
      oficiales,
      supervisados
    ])
    .then((res: any[])=>{
      this.supervisadosAll = res[1].results
      let idSupervisados = this.supervisadosAll.map((item)=> item.id)
      this.oficialesAll    = res[0].results.filter((item)=> { return (!idSupervisados.includes(item.id)) && (!item.es_lider)  && (item.id != this.userLider.id)})
      
      this.rowOficialesAll    = this.oficialesAll
      this.rowSupervisadosAll =  this.supervisadosAll

      let stringSearchEquipo = this.form.get('searchEquipo').value
      let stringSearchOficiales =this.form.get('searchOficial').value

      if(stringSearchEquipo != null || stringSearchEquipo != ''){
        this.supervisadosAll = this.searchStringFunction(stringSearchEquipo,false)
      }

      if(stringSearchOficiales != null || stringSearchOficiales != ''){
        this.oficialesAll = this.searchStringFunction(stringSearchOficiales,true)
      }
    })
  }

  pasar(user){
    user.lider = this.userLider.id
    let usuario = {
      lider: this.userLider.id,
      id: user.id,
    }

    this.auth.asignarLider(usuario).then((res)=>{
      this.loadData()
    })
  }

  pasarDeNuevo(user){
    user.lider = '';
    this.auth.asignarLider(user).then((res)=>{
      this.loadData()
    })
  }

}
