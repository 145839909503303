import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ToastService } from "../toast/toast.service";
import { AppService } from "app/app.service";

@Injectable({
  providedIn: 'root'
})
export class CavaliService {

  private apiUrl: string = "";

  constructor(
    private appService: AppService,
    private http: HttpClient,
    public toast: ToastService,
  ) {
    this.apiUrl = `${this.appService.settings.API_base_url}factoring/`
  }

  reenviar(proceso) {
    const url = `${this.apiUrl}cavali/procesos/${proceso}/reenviar/`
    return this.http.post(url, {}).pipe(
      catchError(res => this.handleError(res))
    )
  }

  marcarFallidos(procesos) {
    const url = `${this.apiUrl}cavali/procesos/marcar-fallidos/`
    return this.http.post(url, { procesos }).pipe(
      catchError(res => this.handleError(res))
    )
  }

  getProcesos(queryParams: object = {}) {
    const url = `${this.apiUrl}cavali/procesos/?${this.encodeQueryParams(queryParams)}`
    return this.http.get(url).pipe(
      catchError(res => this.handleError(res))
    )
  }

  getMonedas(queryParams: object = { 'page_size': 9999 }) {
    const url = `${this.apiUrl}monedas/`
    return this.getDataApi(url, queryParams)
  }

  private getDataApi(url, queryParams) {
    url = `${url}?${this.encodeQueryParams(queryParams)}`
    return this.http.get(url).pipe(
      map((res: any) => res.results)
    ).pipe(
      catchError(res => this.handleError(res))
    )
  }

  private handleError(response) {
    let error = 'Ocurrió un error inesperado'
    if (response.error && typeof (response.error) == 'object') {
      error = Object.entries(response.error).map(([k, e]) => k + ': ' +  (typeof (e) == 'object' ? JSON.stringify(e) : e) ).join('\n')
    }
    this.toast.warning(error)
    return throwError(response)
  }

  private encodeQueryParams(queryParams) {
    return Object.entries(queryParams).map((e: any) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&')
  }

}
