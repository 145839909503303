export class LineaCredito{
    public id                         : number
    public beneficiario               : number
    public beneficiario_nombre               : string
    public tipo                       : number
    public tipo_descripcion           : string
    public moneda                     : number
    public moneda_descripcion         : string
    public linea_actual               : string
    public fecha_alta                 : string
    public fecha_vencimiento          : string
    public tem_soles                  : string
    public tem_dolares                : string
    public anticipo                   : string
    public mora                       : string
    public observaciones              : string
    public linea_disponible           : string
    public linea_consumida            : string
    public monto_documentos_cartera   : string
    public monto_operaciones_aprobadas: string
    public monto_operaciones_desembolsar: string
    public nro_contrato_default: string
    public operacion: any


    constructor(linea){
        this.id                          = linea.id
        this.beneficiario                = linea.beneficiario
        this.tipo                        = linea.tipo
        this.tipo_descripcion            = linea.tipo_descripcion
        this.moneda                      = linea.moneda
        this.moneda_descripcion          = linea.moneda_descripcion
        this.linea_actual                = linea.linea_actual
        this.fecha_alta                  = linea.fecha_alta
        this.fecha_vencimiento           = linea.fecha_vencimiento
        this.tem_soles                   = linea.tem_soles
        this.tem_dolares                 = linea.tem_dolares
        this.anticipo                    = linea.anticipo
        this.mora                        = linea.mora
        this.observaciones               = linea.observaciones
        this.linea_disponible            = linea.linea_disponible
        this.linea_consumida             = linea.linea_consumida
        this.monto_documentos_cartera    = linea.monto_documentos_cartera
        this.monto_operaciones_aprobadas = linea.monto_operaciones_aprobadas
        this.monto_operaciones_desembolsar = linea.monto_operaciones_desembolsar        
        this.beneficiario_nombre = linea.beneficiario_nombre     
        this.nro_contrato_default = linea.nro_contrato_default       
    }

    get fecha_altaDate() : Date{
        if (this.fecha_alta) {
            let dateNow = new Date();

            let yy = this.fecha_alta.split("-")[0];
            let mm = parseInt(this.fecha_alta.split("-")[1]) - 1;
            let dd = this.fecha_alta.split("-")[2];

            let d = new Date(parseInt(yy), mm, parseInt(dd), dateNow.getHours());
            return d;
        } else {
            return null;
        }
        // return new Date(this.fecha_alta);
    }

    get fecha_vencimientoDate() : Date{
        return new Date(this.fecha_vencimiento)
    }

    get prefix(){
        if(this.moneda === 1){
			return 'S/ ' 
		} else {
			return '$ ' 
		}
    }
}