import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class VerificationsService {

  private apiUrl: string = '';
  public documents: string = 'factoring/operaciones/detalle/';
  public estados: string = 'factoring/operaciones/detalle/estados/';
  public interactions: string = 'factoring/operaciones/documentos/interaccion/';
  public interactions2: string = 'factoring/operaciones/detalle/interaccion/';
  public deudores: string = 'factoring/deudores/';
  public deudoresContactos: string = 'factoring/deudores/contactos/';
  public operacionDetalle: string = 'factoring/operaciones/detalle/';
  public motivosCancelacion: string = 'factoring/operaciones/cancelar/motivo/';
  public motivosDevolucion: string = 'factoring/operaciones/devolver/motivo/';
  public cancelarOperacion: string = 'factoring/operaciones/cancelar/';
  public extornaOperacion: string = 'factoring/operaciones/extornar/cancelado/';

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient
  ) {
    this.apiUrl = this.appService.settings.API_base_url
  }

  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show()
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide()
    this.appService.notifyMe(mensaje, ok)

    if (mensaje && ok)
      this.toast.success(mensaje)
    if (mensaje && !ok)
      this.toast.warning(mensaje)
  }

  /**
   * Retorna los documentos
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   * @param estado ID del estado
   * @param analista_operacion ID del analista
   * @param operacion ID de la opracion
   */
  obtenerDocumentos(
    page: number = 1,
    page_size: number = 10,
    estado: String = '',
    analista_operacion: string = '',
    operacion: string = '',
    empresa: string = '',
    vencimiento__gte: string = '',
    vencimiento__lte: string = '',
    beneficiario_nombre: string = '',
    deudor_nombre__icontains: string = '',
    numero_documento__icontains: string = ''
  ) {

    const url = this.apiUrl + this.documents +
      `?estado=${estado}` +
      `&analista_operacion=${analista_operacion}` +
      `&operacion=${operacion}` +
      `&page_size=${page_size}` +
      `&page=${page}` +
      `&empresa=${empresa}`+
      `&vencimiento__gte=${vencimiento__gte.split(' ')[0]}` +
      `&vencimiento__lte=${vencimiento__lte.split(' ')[0]}` +
      `&beneficiario_nombre__icontains=${beneficiario_nombre}`+
      `&deudor_nombre__icontains=${deudor_nombre__icontains}`+
      `&numero_documento__icontains=${numero_documento__icontains}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff('La operación que intenta consultar no existe en los registros', false)
          } else {
            this.spinnerOff('La operación falló', false)
          }
          ref(err);
        });
    });

  }

  /**
   * Retorna los estados de los documentos
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerEstados(page: number = 1, page_size: number = 1000) {
    const url = this.apiUrl +
      this.estados +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  /**
   * 
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   * @param operacionDocumento 
   */
  obtenerComments(
    page: number = 1,
    page_size: number = 10,
    operacionDocumento: string = '',
  ) {
    const url = this.apiUrl +
      this.interactions2 +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&operacion_detalle=${operacionDocumento}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  /**
   * 
   * @param data Datos del comentario
   */
  crearComentario(data) {
    const url = this.apiUrl +
      this.interactions2;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  /**
   * 
   * @param id ID del comentario
   * @param data Datos del comentario
   */
  editarComentario(id, data) {
    const url = this.apiUrl +
      this.interactions2 + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.patch(url, data)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  eliminarComentario(id) {
    const url = this.apiUrl +
      this.interactions2 + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.delete(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  buscarDeudores(ruc__icontains: any = '', page: number = 1, page_size: number = 10) {
    const URL = this.apiUrl + this.deudores + `?ruc_nombre__icontains=${ruc__icontains}` +
      `&page=${page}` +
      `&page_size=${page_size}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(URL)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  buscarDeudoresObserver(ruc__icontains: any = '', page: number = 1, page_size: number = 10): Observable<any> {
    const URL = this.apiUrl +
      this.deudores +
      `?ruc_nombre__icontains=${ruc__icontains}` +
      `&page=${page}` +
      `&page_size=${page_size}`;

    return this.http
      .get<any>(URL)
      .pipe(map(resp => {
        if (resp.Error) {
          throwError(resp.Error);
        } else {
          return resp.results;
        }
      })
      );

  }

  buscarContactosDeudorObserve(nombre__icontains: any = '', deudor: string = '', page: number = 1, page_size: number = 1000): Observable<any> {
    const URL = this.apiUrl + this.deudoresContactos +
      `?nombre__icontains=${nombre__icontains}` +
      `&deudor=${deudor}` +
      `&page=${page}` +
      `&page_size=${page_size}`;

    return this.http
      .get<any>(URL)
      .pipe(map(resp => {
        if (resp.Error) {
          throwError(resp.Error);
        } else {
          return resp.results;
        }
      })
      );
  }

  obtenerDeudor(deudorId) {
    const url = this.apiUrl +
      this.deudores + `${deudorId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  obtenerContacto(contactoId) {
    const url = this.apiUrl + this.deudoresContactos + `${contactoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  verificarDetalles(detalles, comments = [], mensaje = "") {

    console.log(detalles, comments)

    const detallesUrl = `${this.apiUrl}${this.operacionDetalle}`;
    const commentsUrl = `${this.apiUrl}${this.interactions2}`;

    let detallesArray = [];
    let commentsArray = [];

    return new Promise((res, ref) => {
      this.spinnerOn();

      detalles.forEach(element => {
        detallesArray.push(this.http.put(`${detallesUrl}${element.id}/`, element));
      });

      comments.forEach(element => {
        commentsArray.push(this.http.post(commentsUrl, element));
      });

      let arrayDate = [
        ...detallesArray,
        ...commentsArray
      ];

      forkJoin(arrayDate).subscribe(response => {
        console.log(response);
        this.spinnerOff(mensaje ? mensaje : 'El archivo fue adjuntado de manera exitosa');
        res(response);
      }, err => {
        console.log("ERR", err)
        if (err.error.vencimiento) {
          this.toast.warning("Vencimiento con formato erróneo. Use el siguiente formato en su lugar: DD/MM/YYYY");
        }
        if (err.error.vencimiento_cavali) {
          this.toast.warning("Vencimiento CAVALI con formato erróneo. Use el siguiente formato en su lugar: DD/MM/YYYY");
        }
        this.spinnerOff("Errores en la subida del Excel", false);
        ref(err);
      });

    });

  }


  obtenerMotivos() {
    const url = this.apiUrl +
      this.motivosCancelacion + `?page=1&page_size=1000`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }
  obtenerMotivosDevolucion() {
    const url = this.apiUrl +
      this.motivosDevolucion + `?page=1&page_size=1000`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }
  cancelaOperacion(id, body) {
    const url = this.apiUrl +
      this.cancelarOperacion + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.put(url, body)
        .subscribe((response) => {
          this.spinnerOff('La operación fue cancelada con éxito');
          res(response);
        }, (err) => {
          if (err.error && err.error.detail) {
            this.spinnerOff(err.error.detail, false);
          } else {
            this.spinnerOff();
          }
          console.log(err)
          ref(err);
        });
    });
  }

  extornarOperacion(id) {
    const url = this.apiUrl +
      this.extornaOperacion + `${id}/`;
    this.spinnerOn()
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.put(url, {})
        .subscribe((response) => {
          this.spinnerOff('La operación fue extornada con éxito');
          res(response);
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  consultaCavali(id) {
    const url = `${this.apiUrl}factoring/cavali/facturas/consulta/`
    return this.http.post(url, {'facturas': [id]})
  }
}
