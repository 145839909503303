export class functions {

    /*=============================================
    CORELACION DE INDICES DE TABLAS
    =============================================*/
    static indextable(data):any{

        let newData = data;

        newData.results.map( (element, indice) => {
    
          let a = (indice+1) + (data.page_number * data.per_page) - data.per_page;
          let b = data.per_page*data.page_number;
    
          if( a <= b ){
            
            element.index = a;
    
          }
    
        });

        return newData.results;

    }


}