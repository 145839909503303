import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ExcedentesService } from 'app/core/services/excedentes/excedentes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { DevolucionModalComponent } from '../../../../componentes/devolucion-modal/devolucion-modal.component';
import { functions } from '../../../../../../core/helpers/functions';
import { toArray } from 'rxjs/operators';
import * as XLSX from 'xlsx'
import { concat, Observable, of, Subject, throwError } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-devoluciones-general',
  templateUrl: './devoluciones-general.component.html',
  styleUrls: ['./devoluciones-general.component.css']
})
export class DevolucionesGeneralComponent implements OnInit {
  faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number = 1;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = "";
  public canCreate: boolean = false;
  public tmpData: any;

  monedas = []
  bancos = []
  exportando = false;
	tblFiltros: any = {}

  // totalDesembolsos = {}
  public totalDesembolsos: any = {soles: 0, dolares: 0}

  constructor(
    public modalService: NgbModal,
    public factoring: FactoringService,
    public router: Router,
    public authService: AuthService,
    public devolucionesService: ExcedentesService,
    private sanitizer: DomSanitizer
  ) { }

  @Input() tableSelectable: boolean = false;
  @Input() set refresh(value) {
    this.tableSelectable = false;
    this.goToPage({})
  }

  @Output() ocultarBtn = new EventEmitter();
  ngOnInit(): void {
    this.obtenerDatosIniciales()
    this.ocultarBtn.emit(false)
  }

	async exportarDataTablaSolicitudes() {
		console.log('Filtros Tabla', this.tblFiltros)
		const lote = 100

		this.exportando = true
		this.devolucionesService.getDevoluciones(Object.assign({ 'page_size': 1 }, this.tblFiltros)).subscribe(
			data => {
			let pages = Math.ceil(data['count'] / lote)
			let pages$ = Array.from({ length: pages }).map((_, i) => i + 1).map(
				page => this.devolucionesService.getDevoluciones(Object.assign({ page, 'page_size': lote }, this.tblFiltros))
			)
			concat(...pages$).pipe(toArray()).subscribe((data: any) => {
				data = data.map((x: any) => x.results).flat(1)
				data = data.map(x => {
				let row = {}
				for (let header of this.columnDefs) {
          if(header['field'] == 'compensacion'){
            if(x[header['field']] == true){
              row[header['headerName']] = 'SI'
            }else{
              row[header['headerName']] = 'NO'
            }
          }else if(header['field'] == 'monto'){
            if(x['moneda'] == 1){
              row['Moneda'] = 'SOLES'
              row[header['headerName']] =  parseFloat(x[header['field']])
            }else{
              row['Moneda'] = 'DOLARES'
              row[header['headerName']] =  parseFloat(x[header['field']])
            }
          }else{
            row[header['headerName']] = x[header['field']]
          }
				}
				return row
				})
				let ws = XLSX.utils.json_to_sheet(data)
				let wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, ws, 'Solicitudes')
				XLSX.writeFile(wb, 'Reporte Solicitudes Excedente Historico.xlsx')
			}).add(() => this.exportando = false)
			},
			res => {
			console.log(res)
			this.exportando = false
			}
		)
	}

  obtenerDatosIniciales() {
    let estados = this.devolucionesService.obtenerEstados();
    let empresas = this.factoring.obtenerEmpresas();
    let monedas = this.factoring.obtenerMonedas();
    let bancos = this.factoring.obtenerBancos();

    Promise.all([estados, empresas, monedas, bancos]).then(
      (res: any[]) => {
        // let estados = res[0]['results'];
        var estados = [
          {
              "id": 5,
              "descripcion": "Rechazado",
              "selected": false
          },
          {
              "id": 4,
              "descripcion": "Devuelto",
              "selected": false
          },
          {
              "id": 3,
              "descripcion": "Aprobado",
              "selected": false
          },
          {
              "id": 2,
              "descripcion": "En curso",
              "selected": false
          }
      ]
      console.log(estados)
        let empresas = res[1]['results'];
        this.monedas = res[2]['results'];
        this.bancos = res[3]['results'];
        this.initVars(estados, empresas);
        this.goToPage({})
      }
    );
  }

  loadTable(data) {
    this.rowData = functions.indextable( data )
    // this.rowData = data.results.map((element, index) => ({
    //   ...element, index: index + 1
    // }));
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars(estados, empresas) {
    let columns = [
      {
        headerName: "ID",
        field: 'id',
        sortable: true,
        sumCol: true,
        sumColFn: () => 'TOTAL'
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "cliente_nombre",
        sortable: true,
        filterable: true,
        filterProp: "cliente_nombre",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC Cliente",
        field: "cliente_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
        sortable: true,
        filterable: true,
        filterProp: "aceptante_nombre",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC Aceptante",
        field: "aceptante_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cant. Docs",
        field: "cantidad_docs",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Moneda",
        field: "moneda_descripcion",
        sortable: true,
        filterable: true,
        filterProp: 'moneda',
        filterSelectItems: this.monedas,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        filterable: true,
        filterRange: true,
        sumCol: true,
        sumColFn: () =>  {
          if (this.totalDesembolsos?.loading) return '<i class="spinner-border spinner-border-sm"></i>'
          if (this.totalDesembolsos?.error) return 'Error consultando'
					const htmlString = `
						<div class="d-flex flex-column px-5">
							<div class="d-flex align-items-center justify-content-between mx-2 mt-1">
								<h6 class="m-0 p-0">S/. </h6>
								<p class="m-0 p-0 ml-2">${Number(this.totalDesembolsos.soles).toLocaleString('en-US', {minimumFractionDigits: 2})}</p>
							</div>
							<div class="d-flex align-items-center justify-content-between mx-2 mb-1">
								<h6 class="m-0 p-0">$ </h6>
								<p class="m-0 p-0 ml-2">${Number(this.totalDesembolsos.dolares).toLocaleString('en-US', {minimumFractionDigits: 2})}</p>
							</div>
						</div>
					`
					return this.sanitizer.bypassSecurityTrustHtml(htmlString)
				},
      },
      {
 				headerName: 'Banco',
 				field: 'banco_abono_nombre',
 				class: 'text-center',
 				filterable: true,
 				filterProp: "banco_abono",
				filterSelectItems: this.bancos,
				filterItemsProps: {
					value: 'id',
					label: 'nombre'
				},
 			},
 			{
 				headerName: 'Cuenta',
 				field: 'cuenta_abono',
 				class: 'text-center',
 				filterable: true,
 				filterProp: "cuenta_abono",
 				filterInput: true,
 			},
      {
        class: "text-center",
        headerName: "Fch. Solicitud",
        field: "fecha_solicitud",
        pipe: "date",
        sortable: true,
        filterable: true,
        filterDate: true,
      },
      {
        class: "text-center",
        headerName: "Fch. Aprobación",
        field: "fecha_aprobacion",
        pipe: "date",
        sortable: true,
      },
      {
        headerName: 'Compensación',
        field: 'compensacion',
        class: 'text-center',
        filterable: true,
        filterProp: 'compensacion',
        filterSelectItems: [
          {id: true, descripcion: 'Si'},
          {id: false, descripcion: 'No'}
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
        pipe: 'function',
        function: row => row['compensacion'] ? 'Si' : 'No',
      },
      {
        headerName: 'Estado',
        field: 'estado_descripcion',
        filterProp: 'estado',
        sortable: true,
        class: 'text-center',
        filterable: true,
        filterSelectItems: estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
    ];

    this.columnDefs = columns;
  }

  tableEditEvent(row) {

    const modalRef = this.modalService.open(DevolucionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });

    modalRef.componentInstance.devolucionId = row.id;
    modalRef.componentInstance.mostrarDeuda = true;

    modalRef.result.then((result) => {
      console.log(result);
      if (result) {
        this.goToPage({})
      }
    }, (reason) => {
      console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
      console.log(result);
      this.goToPage({})
      // this.tableEditEvent(result);
    },
      error => {

      });

  }

  goToPage({ page = this.pageTableActual, per_page = this.pageTablePerPages, filtros = this.tblFiltros }) {

    let data_gte = (filtros.fecha__gte) ? filtros.fecha__gte : '';
    let data_lte = (filtros.fecha__lte) ? filtros.fecha__lte : '';
    data_gte = data_gte.replace(' 00:00', '');
    data_lte = data_lte.replace(' 00:00', '');

    // Export
    var cliente__iconstains = filtros.cliente_nombre ? filtros.cliente_nombre : ''
    var monto__gte = (filtros.valueFrom) ? filtros.valueFrom : ''
    var monto__lte =  (filtros.valueTo) ? filtros.valueTo : ''
    var fecha_solicitud__gte = data_gte
    var fecha_solicitud__lte = data_lte
    var aprobacion_gte = ''
    var aprobacion_lte = ''
    var estado = filtros.estado ? filtros.estado : ''
    var empresa = filtros.empresa ? filtros.empresa: ''
    var aceptante__iconstains = filtros.aceptante_nombre ? filtros.aceptante_nombre: ''
    var compensacion = filtros.compensacion ? filtros.compensacion: ''
    let moneda = filtros.moneda ? filtros.moneda: ''
    let banco_abono = filtros.banco_abono ? filtros.banco_abono: ''
    let cuenta_abono = filtros.cuenta_abono ? filtros.cuenta_abono: ''
    var filtros_export = {
      page,
      per_page,
      cliente__iconstains,
      monto__gte,
      monto__lte,
      fecha_solicitud__gte,
      fecha_solicitud__lte,
      aprobacion_gte,
      aprobacion_lte,
      estado,
      empresa,
      aceptante__iconstains,
      compensacion,
      moneda,
      banco_abono,
      cuenta_abono
    }
    this.tblFiltros = filtros_export

    // Filter
    let devoluciones = this.devolucionesService.obtenerDevolucionesHistorico(
      page,
      per_page,
      filtros.cliente_nombre,
      (filtros.valueFrom) ? filtros.valueFrom : '',
      (filtros.valueTo) ? filtros.valueTo : '',
      data_gte,
      data_lte,
      '',
      '',
      filtros.estado,
      filtros.empresa,
      filtros.aceptante_nombre,
      filtros.compensacion,
      moneda,
      banco_abono,
      cuenta_abono
    );

    devoluciones.then(res => {
      this.loadTable(res);
    }).catch(error => {

    });

    this.totalDesembolsos = {loading: true}
    this.devolucionesService.getTotalDesembolsos(this.tblFiltros).subscribe(
      data => {
        this.totalDesembolsos = {
          soles: data['S/'] || 0,
          dolares: data['US$'] || 0
        }
      },
      res => {
        this.totalDesembolsos = {error: true}
        console.log(res)
      }
    ).add(() => this.totalDesembolsos.loading = false)

  }

  workflowEvent(row) {

  }

}
