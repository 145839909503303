import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FactoringDatatableComponent } from './factoring-datatable/factoring-datatable.component';
import { PaginationComponent } from './pagination/pagination.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ToastComponent } from './toast/toast.component';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormDocSolicitudFactoringComponent } from './form-doc-solicitud-factoring/form-doc-solicitud-factoring.component';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";
import { FormResumenFactoringComponent } from './form-resumen-factoring/form-resumen-factoring.component';
import { PropuestaComercialComponent } from './propuesta-comercial/propuesta-comercial.component';
import { FormDocChecklistComponent } from './form-doc-checklist/form-doc-checklist.component';
import { MonedaPipe } from '../core/pipes/moneda.pipe';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AnalysisFormComponent } from './partials/propuestas/forms/analysis-form/analysis-form.component';
import { CommitteeComponent } from './partials/propuestas/forms/committee/committee.component';
import { ConfirmModalComponent } from './utils/confirm-modal/confirm-modal.component';
import { SolicitarOpinionComponent } from './partials/propuestas/solicitar-opinion/solicitar-opinion.component';
import { LiquidacionComponentComponent } from './liquidacion-component/liquidacion-component.component';
import { CajaComentariosComponent } from './caja-comentarios/caja-comentarios.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { FormatosComponent } from './formatos/formatos.component';
import { FormatoModalComponent } from './formatos/modals/formato-modal/formato-modal.component';
import { FormatoCartaAutorizacionComponent } from './formatos/modals/formato-modal/formato-carta-autorizacion/formato-carta-autorizacion.component';
import { FormatoOrigenFondosComponent } from './formatos/modals/formato-modal/formato-origen-fondos/formato-origen-fondos.component';
import { FormatoMineriaIlegalComponent } from './formatos/modals/formato-modal/formato-mineria-ilegal/formato-mineria-ilegal.component';
import { OrdenPagoComponent } from './orden-pago/orden-pago.component';
import { FormatoContratoMarcoComponent } from './formatos/modals/formato-modal/formato-contrato-marco/formato-contrato-marco.component';
import { MiniAlertComponent } from './mini-alert/mini-alert.component';
import { CuentasXCobrarModalComponent } from './modals/recaudacion/cuentas-x-cobrar-modal/cuentas-x-cobrar-modal.component';
import { ContratoComponent } from './contrato/contrato.component';
import { SessionNotificationComponent } from './session-notification/session-notification.component';
import { FormatoEvaluacionRiesgoComponent } from './formatos/modals/formato-modal/formato-evaluacion-riesgo/formato-evaluacion-riesgo.component';
import { TemToTeaPipe } from './pipes/tem-to-tea.pipe';
import { MultiVerificactionModalComponent } from './modals/operaciones/multi-verificaction-modal/multi-verificaction-modal.component';
import { OpenTextModalComponent } from './modals/open-text-modal/open-text-modal.component';
import { StringDateHourPipe } from 'app/core/pipes/string-date-hour.pipe';
import { BloquearModalComponent } from './bloquear-modal/bloquear-modal.component';
import { BuscadorEntidadesComponent } from './buscador-entidades/buscador-entidades.component';
import { FormatoHojaResumenComponent } from './formatos/modals/formato-modal/formato-hoja-resumen/formato-hoja-resumen.component';
import { FormatoMetodizadoComponent } from './formatos/modals/formato-modal/formato-metodizado/formato-metodizado.component';
import { AdjuntarFileComponent } from './adjuntar-file/adjuntar-file.component';
import { DesgloseLineasComponent } from './desglose-lineas/desglose-lineas.component';
import { DesglosesComponent } from './desgloses/desgloses.component';
import { FormatoPagareComponent } from './formatos/modals/formato-pagare/formato-pagare.component';
import { FormatoAcuerdoLlenadoComponent } from './formatos/modals/formato-modal/formato-acuerdo-llenado/formato-acuerdo-llenado.component';
import { FormatoDJPEPComponent } from './formatos/modals/formato-modal/formato-declaracion-jurada-pep/formato-declaracion-jurada-pep.component';
import { FormatoAdendaModalComponent } from './formatos/modals/formato-modal/formato-adenda-modal/formato-adenda-modal.component';
import { FormatoLAFTComponent } from './formatos/modals/formato-modal/formato-laft/formato-laft.component';
import { FormatoCartaModificacionComponent } from './formatos/modals/formato-modal/formato-carta-modificacion/formato-carta-modificacion.component';
import { CardStatComponent } from './dashboard/card-stat/card-stat.component';
import { IndicadorComponent } from './indicador/indicador.component';
import { FormatoPlanillaDescuentoComponent } from './formatos/modals/formato-modal/formato-planilla-descuento/formato-planilla-descuento.component';
import { TableStatsComponent } from './dashboard/table-stats/table-stats.component';
import { ConcentracionComponent } from './dashboard/concentracion/concentracion.component';
import { FormatoSolicitudFinalComponent } from './formatos/modals/formato-modal/formato-solicitud-final/formato-solicitud-final.component';
import { VencimientoLineasComponent } from './dashboard/vencimiento-lineas/vencimiento-lineas.component';
import { ConfigTiposDocsComponent } from './config-tipos-docs/config-tipos-docs.component';
import { FixedFinanceModalComponent } from './modals/fixed-finance-modal/fixed-finance-modal.component';
import { FormatoCartaNotarialCobranzasComponent } from './formatos/modals/formato-modal/formato-carta-notarial-cobranzas/formato-carta-notarial-cobranzas.component';
import { ProductividadComponent } from './dashboard/productividad/productividad.component';
import { AdelantosComponent } from './dashboard/adelantos/adelantos.component';
import { ResultadoCavaliComponent } from './resultado-cavali/resultado-cavali.component';
import { RouterModule } from '@angular/router';
import { FormatoUnicoComponent } from './formatos/modals/formato-modal/formato-unico/formato-unico.component';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { AlertValidationComponent } from './alert-validation/alert-validation.component';
import { FormDinamicMultiSelectComponent } from './form-multiselect/form-multiselect.component';
import { FormSelectComponent } from './form-select/form-select.component';
import { FormDateComponent } from './form-date/form-date.component';
import { ExcepcionDeudaLeasingComponent } from './modals/operaciones/excepcion-deuda-leasing/excepcion-deuda-leasing.component';
import { AdvertenciaDeudaLeasingComponent } from './advertencia-deuda-leasing/advertencia-deuda-leasing.component';
import { FormatoCartaCesionComponent } from './formatos/modals/formato-modal/formato-carta-cesion/formato-carta-cesion.component';
import { AdvertenciaDeudaComponent } from './advertencia-deuda/advertencia-deuda.component';
import { SentinelComponent } from './sentinel/sentinel.component';
import { NumeroCuentaBancoComponent } from './numero-cuenta-banco/numero-cuenta-banco.component';

defineLocale('es', esLocale);
const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
    declarations: [
        FactoringDatatableComponent, 
        PropuestaComercialComponent,
        PaginationComponent, 
        ToastComponent, 
        FormDocSolicitudFactoringComponent,
        FormResumenFactoringComponent,
        PropuestaComercialComponent,
        FormDocChecklistComponent,
        MonedaPipe,
        StringDateHourPipe,
        AnalysisFormComponent,
        CommitteeComponent,
        ConfirmModalComponent,
        SolicitarOpinionComponent,
        LiquidacionComponentComponent,
        CajaComentariosComponent,
        UploadFilesComponent,
        FormatosComponent,
        FormatoModalComponent,
        FormatoCartaAutorizacionComponent,
        FormatoOrigenFondosComponent,
        FormatoMineriaIlegalComponent,
        FormatoContratoMarcoComponent,
        FormatoDJPEPComponent,
        FormatoCartaNotarialCobranzasComponent,
        FormatoLAFTComponent,
        FormatoCartaModificacionComponent,
        FormatoPlanillaDescuentoComponent,
        OrdenPagoComponent,
        MiniAlertComponent,
        CuentasXCobrarModalComponent,
        FormatoEvaluacionRiesgoComponent,
        FormatosComponent,
        ContratoComponent,
        SessionNotificationComponent,
        TemToTeaPipe,
        MultiVerificactionModalComponent,
        OpenTextModalComponent,
        BloquearModalComponent,
        BuscadorEntidadesComponent,
        FormatoHojaResumenComponent,
        FormatoMetodizadoComponent,
        AdjuntarFileComponent,
        DesgloseLineasComponent,
        DesglosesComponent,
        FormatoPagareComponent,
        FormatoAcuerdoLlenadoComponent,
        FormatoAdendaModalComponent,
        CardStatComponent,
        IndicadorComponent,
        TableStatsComponent,
        ConcentracionComponent,
        FormatoSolicitudFinalComponent,
        VencimientoLineasComponent,
        ConfigTiposDocsComponent,
        FixedFinanceModalComponent,
        ProductividadComponent,
        AdelantosComponent,
        ResultadoCavaliComponent,
        FormatoUnicoComponent,
        AlertValidationComponent,
        FormDinamicMultiSelectComponent,
        FormSelectComponent,
        FormDateComponent,
        ExcepcionDeudaLeasingComponent,
        AdvertenciaDeudaLeasingComponent,
        FormatoCartaCesionComponent,
        AdvertenciaDeudaComponent,
        SentinelComponent,
        NumeroCuentaBancoComponent
        ],
        imports: [
            RouterModule,
            CommonModule,
            NgxSkeletonLoaderModule,
            JwBootstrapSwitchNg2Module,
            UiSwitchModule.forRoot({
                size              : 'medium',
                color             : '#3e50b4',
                checkedTextColor  : "#ffffff",
                uncheckedTextColor: "#ffffff",
                defaultBgColor    : '#6c757d',
                defaultBoColor    : '#3e50b4',
                checkedLabel      : 'Si ',
                uncheckedLabel    : 'No'
            }),
            NgbModule,
            FormsModule,
            ReactiveFormsModule,
            NgSelectModule,
            BrowserAnimationsModule,
            BsDatepickerModule.forRoot(),
            NgxMaskModule.forRoot(maskConfig),
            NgxCurrencyModule,
            TooltipModule,
            BsDropdownModule.forRoot(),
            FontAwesomeModule
        ],
        entryComponents: [
            ConfirmModalComponent,
            DesgloseLineasComponent,
            DesglosesComponent
        ],
        exports: [
            FactoringDatatableComponent, 
            PropuestaComercialComponent,
            PaginationComponent,
            ToastComponent,
            JwBootstrapSwitchNg2Module,
            FormatosComponent,
            NgbModule,
            NgSelectModule,
            FormDocSolicitudFactoringComponent,
            StringDateHourPipe,
            FormResumenFactoringComponent,
            MonedaPipe,
            CommitteeComponent,
            FormDocChecklistComponent,
            SolicitarOpinionComponent,
            LiquidacionComponentComponent,
            CajaComentariosComponent,
            UploadFilesComponent,
            FormDocChecklistComponent,
            OrdenPagoComponent,
            ContratoComponent,
            SessionNotificationComponent,
            FormatosComponent,
            TemToTeaPipe,
            BuscadorEntidadesComponent,
            DesgloseLineasComponent,
            DesglosesComponent,
            CardStatComponent,
            IndicadorComponent,
            TableStatsComponent,
            ConcentracionComponent,
            VencimientoLineasComponent,
            ConfigTiposDocsComponent,
            ProductividadComponent,
            AdelantosComponent,
            FormSelectComponent,
            FormDinamicMultiSelectComponent,
            FormDateComponent,
            AdvertenciaDeudaLeasingComponent,
            AdvertenciaDeudaComponent,
            SentinelComponent,
            NumeroCuentaBancoComponent
        ],
        schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
    })
    export class SharedModule { }
    
