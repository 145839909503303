import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "app/core/guards/auth.guard";
import { AdminLayoutComponent } from "../../layouts/admin/admin-layout.component";
import * as LavadoActivosReportesComponent from "./pages/reportes/reportes.component";
import { AlertasComponent } from "./pages/alertas/alertas.component";
import { ConfiguracionVariablesRiesgoComponent } from "./pages/configuracion_variables_riesgo/configuracion_variables_riesgo.component";
import { VariablesRiesgoComponent } from "./pages/variables_riesgo/variables_riesgo.component";
import { ConfiguracionGeneralComponent } from "./configuracion-general/configuracion-general.component";
import { MatricesRiesgoComponent } from "./pages/matrices-riesgo/matrices-riesgo.component";
import { ZonasRiesgoComponent } from "./pages/zonas-riesgo/zonas-riesgo.component";
import { PeriodosComponent } from "./pages/periodos/periodos.component";
const routes: Routes = [
    {
      path: 'lavado_activos',
      component: AdminLayoutComponent,
      data: {
        breadcrumb: "Lavado de Activos",
      },
      children: [
        {
          path: "calificaciones",
          canActivate: [],
          component: AlertasComponent,
          data: {
            breadcrumb: "Calificación",
          },
        },
        {
          path: "periodos",
          canActivate: [],
          component: PeriodosComponent,
          data: {
            breadcrumb: "Periodos",
          },
        },
        {
          path: "variables_riesgo",
          canActivate: [],
          component: VariablesRiesgoComponent,
          data: {
            breadcrumb: "Variables de Riesgo",
          },
        },
        {
          path: "plantillas",
          canActivate: [],
          component: ConfiguracionVariablesRiesgoComponent,
          data: {
            breadcrumb: "Configuración de plantillas"
          },
        },
        {
          path: "configuracion",
          canActivate: [],
          component: ConfiguracionGeneralComponent,
          data: {
            breadcrumb: "Configuración General"
          },
        },
        {
          path: "reportes",
          canActivate: [],
          component: LavadoActivosReportesComponent.ReportesComponent,
          data: {
            breadcrumb: "Reportes"
          },
        },
        {
          path: "matrices_riesgo",
          canActivate: [],
          component: MatricesRiesgoComponent,
          data: {
            breadcrumb: "Matrices de riesgo"
          },
        },
        {
          path: "zonas_riesgo",
          canActivate: [],
          component: ZonasRiesgoComponent,
          data: {
            breadcrumb: "Zonas de riesgo"
          },
        }
      ],
    },
  ];
  
  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
  })
  export class LavadoActivosRoutingModule {}
  