import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { ToastService } from "../toast/toast.service";
import { AppService } from "app/app.service";

@Injectable({
  providedIn: 'root'
})
export class AbonosService {
  private apiUrl: string = "";

  constructor(
    private appService: AppService,
    private http: HttpClient,
    public toast: ToastService,
  ) {
    this.apiUrl = `${this.appService.settings.API_base_url}factoring/`
  }

  importar(data) {
    const url = `${this.apiUrl}cobranzas/abonos/importar/`
    return this.http.post(url, data).pipe(
      catchError(res => {
        if (res.error.abonos) {
          return throwError(res)
        }
        return this.handleError(res)
      })
    )
  }

  aplicarMatch(data) {
    const url = `${this.apiUrl}cobranzas/abonos/aplicar-match/`
    return this.http.post(url, data).pipe(
      catchError(res => {
        return this.handleError(res)
      })
    )
  }

  guardarAbono(abono) {
    const url = `${this.apiUrl}cobranzas/abonos/`
    let method$ = null
    Object.keys(abono).filter(field => field.includes('fecha')).forEach(field => abono[field] = new Date(abono[field].setHours(0, 0, 0)).toISOString().split('T')[0])
    if (abono.id) {
      method$ = this.http.patch(`${url}${abono.id}/`, abono)
    } else {
      method$ = this.http.post(url, abono)
    }
    return method$.pipe(
      catchError(res => this.handleError(res))
    )
  }

  getAbonos(queryParams: object = {}) {
    const url = `${this.apiUrl}cobranzas/abonos/?${this.encodeQueryParams(queryParams)}`
    return this.http.get(url).pipe(
      catchError(res => this.handleError(res))
    )
  }

  getAbono(id) {
    const url = `${this.apiUrl}cobranzas/abonos/${id}/`
    return this.http.get(url).pipe(
      map((data: any) => {
        Object.keys(data).filter(x => x.includes('fecha')).forEach(key => {
          if (data[key]) {
            let fecha = data[key]
            if (!fecha.includes(':')) fecha += 'T00:00'
            data[key] = new Date(fecha)
          }
        })
        return data
      })
    )
  }
  getAplicacionRegister(id) {
    const url = `${this.apiUrl}recaudaciones/?abono=${id}&estado=1`
    return this.http.get(url).pipe(
      map((data: any) => {
        Object.keys(data).filter(x => x.includes('fecha')).forEach(key => {
          if (data[key]) {
            let fecha = data[key]
            if (!fecha.includes(':')) fecha += 'T00:00'
            data[key] = new Date(fecha)
          }
        })
        return data
      })
    )
  }

  getEstados(queryParams: object = { 'page_size': 9999 }) {
    const url = `${this.apiUrl}cobranzas/abonos/estados/`
    return this.getDataApi(url, queryParams)
  }

  getCuentasBancarias(queryParams: object = { 'page_size': 9999 }) {
    const url = `${this.apiUrl}recaudaciones/cuentas/`
    return this.getDataApi(url, queryParams)
  }

  getMonedas(queryParams: object = { 'page_size': 9999 }) {
    const url = `${this.apiUrl}monedas/`
    return this.getDataApi(url, queryParams)
  }

  getPagadores(queryParams: object = { 'page_size': 9999 }, params: object = {}, omitirDuplicados = true) {
    const url = `${this.apiUrl}pagos/pagadores/`
    return this.getDataApi(url, queryParams, params).pipe(
      map((data: any) => {
        if (!omitirDuplicados) return data
        let pagadores = []
        let rucs = new Set()
        for (let e of data) {
          if (!rucs.has(e.ruc)) {
            rucs.add(e.ruc)
            pagadores.push(e)
          }
        }
        return pagadores
      })
    )
  }

  getTrazabilidad(queryParams: object = { 'page_size': 9999 }) {
    const url = `${this.apiUrl}cobranzas/abonos/trazabilidad/`
    return this.getDataApi(url, queryParams)
  }

  getTiposTrazabilidad(queryParams: object = { 'page_size': 9999 }) {
    const url = `${this.apiUrl}cobranzas/abonos/trazabilidad/tipos/`
    return this.getDataApi(url, queryParams)
  }

  getMotivosAnulacion(queryParams: object = { 'page_size': 9999 }) {
    const url = `${this.apiUrl}cobranzas/abonos/motivos-anulacion/`
    return this.getDataApi(url, queryParams)
  }

  getAplicaciones(queryParams: object = { 'page_size': 9999 }) {
    const url = `${this.apiUrl}recaudaciones/`
    return this.getDataApi(url, queryParams)
  }

  private getDataApi(url, queryParams, params={}) {
    url = `${url}?${this.encodeQueryParams(queryParams)}`
    const request$ = Object.keys(params).length > 0 ? this.http.post(url, params) : this.http.get(url)
    return request$.pipe(
      map((res: any) => res.results)
    ).pipe(
      catchError(res => this.handleError(res))
    )
  }

  private handleError(response) {
    let error = 'Ocurrió un error inesperado'
    if (response.error && typeof (response.error) == 'object') {
      error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
    }
    this.toast.warning(error)
    return throwError(response)
  }

  private encodeQueryParams(queryParams) {
    return Object.entries(queryParams).map((e: any) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&')
  }
}
