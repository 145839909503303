import { Component, OnInit, ViewChild } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { LideresAsignarComponent } from '../lideres-asignar/lideres-asignar.component';
import { LideresModalComponent } from '../lideres-modal/lideres-modal.component';
import { functions } from '../../../core/helpers/functions';

@Component({
  selector: 'app-lideres-equipo',
  templateUrl: './lideres-equipo.component.html',
  styleUrls: ['./lideres-equipo.component.css']
})
export class LideresEquipoComponent implements OnInit {
  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : any[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
  @ViewChild(LideresModalComponent) lideresModalComponent: LideresModalComponent;
  @ViewChild(LideresAsignarComponent) LideresAsignarComponent: LideresAsignarComponent;
  faPlus=faPlus;
  constructor(
    public auth: AuthService,
    private modalService: NgbModal,

    ) { }

  ngOnInit(): void {
    let user = this.auth.userIn
    let oficiales                   = this.auth.obtenerLideres(1,10)

    oficiales.then((res: any)=>{
      this.loadTable(res)
    })
  }

  loadTable(data) {
    this.initVars()
    this.rowData = functions.indextable( data )
    // this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }

  crear(){
    const modalRef = this.modalService.open(LideresAsignarComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
    });
    // console.log(user)
    modalRef.componentInstance.successEmit.subscribe(result => {
      this.ngOnInit()
    })
  }

  initVars() {
    let columns = [
      {
        headerName: 'N°', 
        field     : 'index' ,
        sortable  : true,
      },
      {
        headerName: 'Nombre', 
        field     : 'nombre',
        class     : 'text-center',
        sortable  : true,
        // filterable: true,
        // filterProp: 'descripcion',
        // filterInput: true,
      }
    ];

    this.columnDefs = columns;
  }

  goToPage({page,per_page,filtros}) {
    this.auth.obtenerLideres(page,per_page)
    .then((res: any)=>{
      this.loadTable(res)
    })
  }

  tableEditEvent(user){
    const modalRef = this.modalService.open(LideresModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "lg",
    });
    modalRef.componentInstance.userLider = user
  }
}
