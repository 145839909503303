import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';

@Component({
  selector: 'app-descartar-aplicacion',
  templateUrl: './descartar-aplicacion.component.html',
  styleUrls: ['./descartar-aplicacion.component.css']
})
export class DescartarAplicacionComponent implements OnInit {
  @Input() recaudacion: any = null;

  fechaContable = new Date()
  maxFechaContable = new Date()

  constructor(
    public activeModal: NgbActiveModal,
    private recaudacionService: RecaudacionService,
  ) { }

  ngOnInit(): void {
    console.log(this.recaudacion)
    if (this.recaudacion.estado != 1) {
      this.activeModal.close(false)
    }
  }

  descartarAplicacion() {
    if (!this.fechaContable) return
    let fechaStr = new Date(this.fechaContable.setHours(0, 0, 0)).toISOString().split('T')[0]
    this.recaudacionService.actualizarEstadoRecaudacion(this.recaudacion.id, {'estado': 3, 'fecha_contable_cancelacion': fechaStr})
      .then(data => {
        console.log(data)
      })
      .catch(res => {
        console.log(res)
      }).then(() => {
        this.activeModal.close(true)
      })
  }

}
