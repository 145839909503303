import { Component, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  faFileDownload,
  faCloudUploadAlt,
  faTh
} from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { Contrato } from 'app/core/services/auth/Contrato';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { LegalService } from 'app/core/services/legal.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { AdjuntarFileComponent } from '../adjuntar-file/adjuntar-file.component';
import { FormatoModalComponent } from './modals/formato-modal/formato-modal.component';

@Component({
  selector: 'app-formatos',
  templateUrl: './formatos.component.html',
  styleUrls: ['./formatos.component.css']
})
export class FormatosComponent implements OnInit {

  public title = "Formatos";
  public _readonly: boolean = false;
  public formatos: FormatoModel[] = [];
  formatoContext;
  faCloudUploadAlt = faCloudUploadAlt;
  faTh = faTh
  uploadForm: FormGroup;
  upload: any;
  contrato_obj;
  esOficialNegocio;
  esCliente;
  public verificacion_disabled = true;
  public adjunto_disabled = true;
  public obteniendoMetodizado = false;

  @Output() formatoEmit;
  @Input() operacion: number;
  @Input() operacionModel: any;
  @Input() montoOperacion: number;
  @Input() monedaSimbolo: string;
  @Input() monedaOperacion: string;
  @Input() contrato;
  @Input() beneficiario: number;
  @Input() beneficiario_tipo: string;
  @Input() set readonly(value) {
    this._readonly = value
  };

  constructor(private fb: FormBuilder, public modalService: NgbModal,
    private formatosService: FormatosService, private legalService: LegalService,
    public adjuntaComponent : AdjuntarFileComponent,
    private authService: AuthService, public toast: ToastService,) { }

  ngOnInit(): void {

    this.esOficialNegocio = this.authService.perfil.isOficialDeNegocio;
    this.esCliente = this.authService.perfil.isCliente;

    if (this.authService.perfil.isOficialDeNegocio) {
      this.verificacion_disabled = false;
    }

    if (this.authService.perfil.isCliente || this.authService.perfil.isOficialDeNegocio) {
      this.adjunto_disabled = false;
    }

    this.uploadForm = this.fb.group({
      formato_url: ['']
    });

    this.legalService.obtenerOperacionContrato(this.operacion)
      .then((contrato: any) => {
        this.contrato_obj = new Contrato(contrato.contrato);
      });

    this.cargarFormatosOperaciones();


  }

  cargarFormatosOperaciones() {
    this.formatos = [];

    this.formatosService.obtenerOperacionFormato('',this.operacion.toString())
    .then((operacionFormato: any) => {
      var operacionesFormatos: any[] = operacionFormato.results;
      this.formatosService.obtenerFormatos().then((formatos: any) => {
        var result: any[] = formatos.results;
        result.map(formato => {
          console.log( 'FORMATO******', formato )
          var operacion_formato: any[] = operacionesFormatos.filter(x => x.formato == formato.id);
          if (operacion_formato.length == 0) {
            this.formatos.push(
              new FormatoModel(formato.id, formato.descripcion, false, '', formato.codigo, formato.archivo_plantilla, formato.ruta));
          } else {
            this.formatos.push(
              new FormatoModel(formato.id, formato.descripcion, operacion_formato[0].verificado,
                operacion_formato[0].adjunto, formato.codigo, formato.archivo_plantilla, formato.ruta));
          }
        });
      });
    });
  }

  verifyChange(event, doc) {
    this.formatosService.obtenerOperacionFormato(doc.id, this.operacion.toString()).then((x: any) => {
      if (x.results.length === 0) {
        doc.verificado = false;
        this.toast.warning("Este formato aún no ha sido adjuntado.");
        return;
      }
      var formato_documento_id = x.results[0].id;
      var data = {
        "operacion": this.operacion,
        "formato": doc.id,
        "verificado": event
      }
      this.formatosService.actualizarFormatoOperacion(formato_documento_id, data, event).then(
        x => {
          this.cargarFormatosOperaciones();
        }
      );
    });

  }

  openFormulario(formato: FormatoModel) {
    this.openProxyModalFormulario(formato);
  }

  iniciarCargaDeFormato(){
    var fileSelect = document.createElement("input"); 
    fileSelect.setAttribute("id", "campoAdjuntar"); 
    fileSelect.setAttribute("type", "file"); 
    fileSelect.setAttribute("style", "display: none;"); 
    let formularioDetalles = document.getElementsByTagName("div")[0]
    formularioDetalles.appendChild(fileSelect)
    document.getElementById("campoAdjuntar").click()
    document.getElementById("campoAdjuntar").addEventListener('change', (event) => {
        this.onFileSelectEL(event)
        formularioDetalles.removeChild(fileSelect)
    });
  }

  onFileSelectEL(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];         
      const formData = new FormData();
      formData.append('adjunto', file);
      this.obtenerArchivo(formData)
    }
  }


  obtenerArchivo(body){
    if(this.obteniendoMetodizado){
      return
    }
    this.obteniendoMetodizado = true
    this.legalService.obtenerMetodizado(body).then((res)=>{
      this.adjuntaComponent.obtenerBlob(res,'FormatoMetodizado','xlsx')
    }).catch(res => console.log(res)).then(() => this.obteniendoMetodizado = false)
  }

  openProxyModalFormulario(formato) {
    console.log(formato)
    if(formato.codigo == '09'){
      this.iniciarCargaDeFormato()
      return
    }
    const modalRef = this.modalService.open(FormatoModalComponent, {
      size: "",
    });
    console.log( 'PASA POR ACA', formato )

    modalRef.componentInstance.tipo_formato          = formato.codigo;
    modalRef.componentInstance.descripcion           = formato.descripcion;
    modalRef.componentInstance.beneficiario          = this.beneficiario;
    modalRef.componentInstance.beneficiario_tipo     = this.beneficiario_tipo;
    modalRef.componentInstance.fecha_contrato        = this.contrato_obj?.fecha_contrato;
    modalRef.componentInstance.fecha_vencimiento     = this.contrato_obj?.fecha_vencimiento;
    modalRef.componentInstance.formato_codigo        = formato.codigo;
    modalRef.componentInstance.formato_plantilla_url = formato.archivo_plantilla;
    modalRef.componentInstance.numero_contrato       = this.contrato_obj?.numero_contrato;
    modalRef.componentInstance.montoOperacion        = this.montoOperacion;
    modalRef.componentInstance.monedaOperacion       = this.monedaOperacion;
    modalRef.componentInstance.monedaSimbolo         = this.monedaSimbolo;
    modalRef.componentInstance.ruta                  = formato.ruta;
    modalRef.componentInstance.operacion             = this.operacion;
    modalRef.componentInstance.operacionModel        = this.operacionModel;
    modalRef.componentInstance.contrato              = this.contrato_obj;
    modalRef.result
    .then((response: any)=>{
      console.log(response)
      if(response){
        // this.response.emit({response:true, type: this.alertType})
      } else {
        // this.response.emit({response:false, type: this.alertType})
      }
		}).catch((err)=>{
      console.log('Error en modal',err)
      // this.response.emit({response:false, type: this.alertType})
    });
 
  }

  // openModalFormulario(title) {
  //   // const modalRef = this.modalService.open(FormatoModalComponent, {

  //   // });

  //   // modalRef.componentInstance.title = title;
  //   // modalRef.componentInstance.beneficiario = this.beneficiario;
  //   // modalRef.componentInstance.beneficiario_tipo = this.beneficiario_tipo;
  //   // modalRef.componentInstance.fecha_contrato = this.contrato_obj.fecha_contrato;
  //   // modalRef.componentInstance.fecha_vencimiento = this.contrato_obj.fecha_vencimiento;

  //   // modalRef.result.then((result) => {

  //   //   if (result === undefined) {
  //   //     return;
  //   //   }

  //   //   this.formatosService.descargarFormato(result).then((x: any) => {

  //   //     var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

  //   //     var downloadURL = window.URL.createObjectURL(blob);
  //   //     var link = document.createElement('a');
  //   //     link.href = downloadURL;
  //   //     link.download = "formato1.docx";
  //   //     link.click();
  //   //   });
  //   // }, (reason) => {
  //   // });
 
  // }

  evalDocumento(item) {
    this.formatoContext = item;
    var fileSelect = document.createElement("input");
    fileSelect.setAttribute("id", "fielSelect");
    fileSelect.setAttribute("type", "file");
    fileSelect.setAttribute("name", "formato_url");
    fileSelect.setAttribute("style", "display: none;");

    let formularioDetalles = document.getElementById("formatos")
    formularioDetalles.appendChild(fileSelect)
    document.getElementById("fielSelect").click()
    document.getElementById("fielSelect").addEventListener('change', (event) => {
      this.onFileSelect(event)
      formularioDetalles.removeChild(fileSelect)
    });

  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      setTimeout(() => {
        const file = event.target.files[0];
        this.uploadForm.get('formato_url').setValue(file);
        const formData = new FormData();
        formData.append('adjunto', this.uploadForm.get('formato_url').value);
        formData.append('operacion', this.operacion.toString());
        formData.append('formato', this.formatoContext.id);
        formData.append('peso', file.size);
        console.log(this.formatoContext.id);
        console.log(formData);

        this.formatosService.guardarDocumentoOperacion(formData)
          .then(res => {
            this.cargarFormatosOperaciones();
          })
          .catch(res => {
          })
      }, 1000);



    }
  }

  openDocument(adjunto) {
    let urlDoc = adjunto.formato_url
    var win = window.open(urlDoc, '_blank');
    win.focus();
  }

  download(adjunto) {
    console.log(adjunto);
    // return;
    let urlDoc = adjunto.formato_url;
    const ultimoSlash = new URL(urlDoc).pathname;
    const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1];
    this.downloadFile(urlDoc, adjuntoName);
  }

  downloadFile(url, name) {
    this.formatosService.downloadFile(url).then(
        (x: any) => {
        console.log(x);
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = name;
        link.click();
        }, async (reason) => {
        console.log(reason);
        }), err => {
        console.error(err);
    };
  }

}

export class FormatoModel {
  public id: number;
  public descripcion: string;
  public verificado?: boolean;
  public formato_url: string;
  public codigo: string;
  public archivo_plantilla: string;
  public ruta?: string;

  constructor(id: number, descripcion: string, verificado: boolean, formato_url: string, codigo: string,
    archivo_plantilla: string, ruta?: string) {
    this.id = id;
    this.descripcion = descripcion;
    this.verificado = verificado;
    this.formato_url = formato_url;
    this.codigo = codigo;
    this.archivo_plantilla = archivo_plantilla;
    this.ruta = ruta;
  }
}
