import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastService } from '../toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppService } from 'app/app.service';



@Injectable({
    providedIn: 'root'
})
export class DocschecklistService {
    private apiUrl      : string;
    private docEstacion : string = 'factoring/documentos/estacion/';
    private estaciones  : string = 'factoring/estaciones/';
    private documentos  : string = 'factoring/documentos/';
    private monedas     : string = 'factoring/monedas/';
    private cabecera    : string = 'factoring/operaciones/cabecera/';
    private tipoDetalles: string = 'factoring/operaciones/tipodetalle/';
    private clientes    : string = 'clientes/clientes/lista/';
    private bancos      : string = 'factoring/bancos/';
    private lineas      : string = 'factoring/lineas/';
    private estados     : string = 'factoring/operaciones/estados/';
    private tiposDeCambio     : string = 'factoring/monedas/tiposcambio/';
    private tiposDeEvaluacion : string = 'factoring/operaciones/tipoevaluacion';
    private registroCavali : string = 'factoring/cavali/facturas/registro/';
    private retiroCavali : string = 'factoring/cavali/facturas/retiro/';

    public estados_list = [
        {
            id: 1,
            descripcion: 'Registrado'
        }                                
    ]    
    constructor(
        public appService: AppService,
        public toast     : ToastService,
        public spinner   : NgxSpinnerService,
        private http     : HttpClient) {
        this.apiUrl = this.appService.settings.API_base_url
     }

    spinnerOn(){
        this.spinner.show()
    }

    spinnerOff(mensaje: string = null, ok : boolean = true){
        this.spinner.hide()
        this.appService.notifyMe(mensaje,ok)

        if(mensaje && ok)
            this.toast.success(mensaje)
        if(mensaje && !ok)
            this.toast.warning(mensaje)
    }

     /**
   * Mostros errores recibidos del servidor
   * @param error Error enviado del servidor
   */
      showErrors(error) {
        const err = error.error;

        for (const key in err) {
            if (Object.prototype.hasOwnProperty.call(err, key)) {
                const element = err[key];
                console.log(element);

                if (Array.isArray(element) && element.length) {
                    element.forEach(item => {
                        this.spinnerOff(item, false);
                    });
                } else {
                    this.spinnerOff(element, false);
                }

            }
        }
    }

    /**
     * Devuelve los datos de cabecera de una operacion de factoring
     * @param IdOperacion Id de operacion de factoring
     */
    obtenerCabecera(IdOperacion:any){
        this.spinnerOn()
        return new Promise((res,ref)=>{
            this.http.get(`${this.apiUrl}${this.cabecera}`+IdOperacion+'/')
                .subscribe((response)=>{
                    this.spinnerOff()
                    res(response)
                }, (err)=>{
                    ref(err)
                })
        })        
    }

    /**
     * 
     * Retorna las distintas estaciones de un proceso
     * de factoring
     * Ej: [{id: 1, nombre: "Cliente"},...]
     * @param page Pagina a obtener
     * @param page_size Cantidad de resultados por pagina
     */
    obtenerEstaciones(page: number = 1, page_size: number = 1000){
        
        const url = this.apiUrl+
                this.estaciones+
                `?page_size=${page_size}`+
                `&page=${page}`;     

        return new Promise((res,ref)=>{
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response)=>{
                    this.spinnerOff()
                    res(response)
                }, (err)=>{
                    ref(err)
                })
        })        
    }

    // /**
    //  * Devuelve la lista de clientes registrados
    //  * @param page Indica la pagina que se desea obtener
    //  * @param page_size Indica la cantidad de resultados por query
    //  */
    // obtenerClientes(
    //     page     : number = 1,
    //     page_size: number = 10
    // ){
    //     const url = this.apiUrl+
    //         this.clientes+
    //         `?page_size=${page_size}`+
    //         `&page=${page}`;  
            
    //     return new Promise((res,ref)=>{
    //         this.spinnerOn()
    //         this.http.get(url)
    //             .subscribe((response)=>{
    //                 this.spinnerOff()
    //                 res(response)
    //             }, (err)=>{
    //                 ref(err)
    //             })
    //     })
    // }

    

    /**
     * Retorna todos los documentos posibles que podria requerir un proceso
     * @param page Pagina solicitada
     * @param page_size Cantidad de resutlados por query
     * @param grupo Tipo/Grupo de documento
     * @param string Parametro de busqueda, retorna coincidencias con el nombre del documento
     */
    obtenerDocumentos(
        page     : number = 1,
        page_size: number = 1000,
        grupo    : string = '',
        string   : string = ''
    ){

        const url = this.apiUrl+
            this.documentos+
            `?page_size=${page_size}`+
            `&page=${page}`+  
            `&grupo=${grupo}`+  
            `&descripcion__icontains=${string}`;  

        this.spinnerOn()
        return new Promise((res,ref)=>{
            this.http.get(url)
                .subscribe((response)=>{
                    this.spinnerOff()
                    res(response)
                }, (err)=>{
                    ref(err)
                })
        })        
    }


    /**
     * Retorna todas las posibles monedas que se utilizan en un proceso de factoring
     */
    obtenerMonedas(){
        this.spinnerOn()
        return new Promise((res,ref)=>{
            this.http.get(`${this.apiUrl}${this.monedas}`)
                .subscribe((response)=>{
                    this.spinnerOff()
                    res(response)
                }, (err)=>{
                    ref(err)
                })
        })        
    }        

    /**
     * Retorna los documentos que estan asignados a cada estacion
     * @param page Indica la pagina que se pide
     * @param page_size Cantidad de registros por query
     */
    obtenerDocumentosPorEstacion(
        page     : number = 1,
        page_size: number = 1000,
    ){
        const url = this.apiUrl+
            this.docEstacion+
            `?page_size=${page_size}`+
            `&page=${page}`;  
            
        return new Promise((res,ref)=>{
            this.spinnerOn()
            this.http.get(url)
            .subscribe((response)=>{
                    this.spinnerOff()
                    res(response)
                }, (err)=>{
                    ref(err)
                })
        })        
    }


    /**
     * Se agrega un documento a una estacion
     * @param doc Informacion y propiedades del documento a agregar
     */
    agregarDocumento(doc){
        return new Promise((res,rej)=>{
            this.spinnerOn()
            this.http.post(`${this.apiUrl}${this.docEstacion}`, doc).subscribe((response)=>{
                this.spinnerOff('Se agregó el documento correctamente')
                res(response)
            }, (err)=>{
                this.spinnerOff('Ocurrio un error durante la operación',false)
                rej(err)
            })        
        })        
    } 


    registrarCavali(idDocumento){
        return new Promise((res,rej)=>{
            this.spinnerOn()
            this.http.post(`${this.apiUrl}${this.registroCavali}`, {'facturas' : [idDocumento]}).subscribe((response)=>{
                this.spinnerOff('Se agregó el documento correctamente')
                res(response)
            }, (err)=>{
                // this.spinnerOff('Ocurrio un error durante la operación',false)
                this.showErrors(err);
                rej(err)
            })        
        })        
    } 

    retirarCavali(idDocumento){
        return new Promise((res,rej)=>{
            this.spinnerOn()
            this.http.post(`${this.apiUrl}${this.retiroCavali}`, {'facturas' : [idDocumento]}).subscribe((response)=>{
                this.spinnerOff('Se retiró el documento correctamente')
                res(response)
            }, (err)=>{
                this.spinnerOff('Ocurrio un error durante la operación',false)
                rej(err)
            })        
        })        
    } 

    /**
     * Actualiza las propiedades de un documento en una estacion
     * @param id ID de la relacion del documento en la estacion
     * @param doc Documento en cuestion
     */
    actualizarValordocumento(id,doc){
        this.spinnerOn()
        return new Promise((res,rej)=>{
            this.http.put(`${this.apiUrl}${this.docEstacion}${id}/`, doc).subscribe((response)=>{
                this.spinnerOff('Se actualizó el valor correctamente')
                res(response)
            }, (err)=>{
                this.spinnerOff('Ocurrio un error durante la operación',false)
                rej(err)
            })        
        })        
    } 

    /**
     * Elimina una relacion entre estacion y documento
     * @param id Id del documento en la estacion, al ser una relacion,
     * se envia el ID de dicha relacion
     */
    desacoplarDocumento(id){
        this.spinnerOn()
        return new Promise((res,rej)=>{
            this.http.delete(`${this.apiUrl}${this.docEstacion}${id}/`).subscribe((response)=>{
                this.spinner.hide();
                this.spinnerOff('La operación fue completada con éxito!')
                res(response)
            }, (err)=>{
                this.spinnerOff('Ocurrio un error durante la operación',false)
                rej(err)
            })        
        })  
    } 
    
    obtenerTipoDetalles(page= 1, page_size = 1000){
        this.spinnerOn()
        return new Promise((res,ref)=>{
            this.http.get(`${this.apiUrl}${this.tipoDetalles}?page=${page}&page_size=${page_size}`)
                .subscribe((response)=>{
                    this.spinnerOff()
                    res(response)
                }, (err)=>{
                    this.spinnerOff()
                    ref(err)
                })
        })        
    }

    /**
     * Retorna los distintos estados de documentos que pueda
     * existir en un proceso de factoring
     * Ej: [{id: 1, descripcion: 'Registrado'}]
     */
    obtenerEstados(page: number = 1, page_size: number = 1000){
        
        const URL = this.apiUrl + this.estados +
        `?page=${page}`+
        `&page_size=${page_size}`;
        
        this.spinnerOn()
        return new Promise((resolve,reject)=>{
            this.http.get(URL).subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                reject(err);
            });
        })
    }

    
    /**
     * Retorna todos los bancos registrados para un proceso de factoring
     * @param page Indica la pagina que se quiere obtener
     * @param page_size Indica la cantidad de registros por query que se desean obtener
     */
    obtenerBancos(page: number = 1, page_size: number = 1000) {

        const URL = this.apiUrl + this.bancos +
            `?page=${page}`+
            `&page_size=${page_size}`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            this.http.get(URL).subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });

    }


    /**
     * Retorna las lineas comerciales que pueden ser utilizadas en determinado proceso de factoring
     * @param page Indica la pagina que se quiere obtener
     * @param page_size Indica la cantidad de registros por pagina
     */
    obtenerLineas(page: number = 1, page_size: number = 30) {
        const URL = this.apiUrl + this.lineas +
            `?page=${page}`+
            `&page_size=${page_size}`;

        return new Promise((resolve, reject) => {
            this.http.get(URL).subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });
    }
    
    /**
     * Retorna los tipos de evaluacion
     * @param page Indica la pagina que se quiere obtener
     * @param page_size Indica la cantidad de registros por pagina
     */
    obtenerTiposDeEvaluacion(page: number = 1, page_size: number = 30) {
        const URL = this.apiUrl + this.tiposDeEvaluacion +
            `?page=${page}`+
            `&page_size=${page_size}`;

        return new Promise((resolve, reject) => {
            this.http.get(URL).subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });
    }

    /**
     * Retorna los tipos de cambio segun SUNAT
     * @param page Indica la pagina que se quiere obtener
     * @param page_size Indica la cantidad de registros por pagina
     */
    obtenerTiposDeCambio(fecha = '2020-10-30', moneda_origen = 2,moneda_destino = 1,page: number = 1, page_size: number = 30) {
        const URL = this.apiUrl + this.tiposDeCambio +
            `?page=${page}`+
            `&page_size=${page_size}`+
            `&fecha__lte=${fecha}`+
            `&moneda_origen=${moneda_origen}`+
            `&moneda_destino=${moneda_destino}`;

        return new Promise((resolve, reject) => {
            this.http.get(URL).subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });
    }

    downloadFile(url) {
        return new Promise((resolve, reject) => {
          let httpReques;
          // let URL = this.apiUrl + this.formato_documentos + "solicitud_final/";
          const httpOptions = {
            responseType: 'blob' as 'json'
          };
          httpReques = this.http.get(url, httpOptions);
          this.spinnerOn();
          httpReques.subscribe(data => {
            this.spinnerOff();
            resolve(data);
          }, async (err) => {
            this.spinnerOff();
            console.log('Error al  generar contrato');
            reject(err);
          });
    
        });
      }
}
