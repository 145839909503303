import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { AceptantesService } from 'app/core/services/factoring/aceptantes.service';
import { ToastService } from 'app/core/services/toast/toast.service';

@Component({
  selector: 'app-formato-carta-cesion',
  templateUrl: './formato-carta-cesion.component.html',
  styleUrls: ['./formato-carta-cesion.component.css']
})
export class FormatoCartaCesionComponent implements OnInit {
  
  myForm: FormGroup;
  deudores = [];
  @Input() beneficiario: number;
  @Input() operacion;
  @Input() formato_plantilla_url;
  


  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private formatoService: FormatosService,
    private clientesService: ClientesService,
    private aceptantesService: AceptantesService,
    public toast: ToastService,
  ) { }

  async ngOnInit() {
    console.log("FORMATO")
    this.createForm();

    this.deudores = this.operacion.detalle.map(el=>{
      return {
        nombre: el.aceptante,
        id: el.deudor
      }
    }).filter((el, i, a)=>a.map(el2=>el2.id).indexOf(el.id) === i)
  }

  async createForm() {
    const cliente = await this.clientesService.obtenerCliente(this.operacion.beneficiario)
    
    this.myForm = this.formBuilder.group({
      fecha_actual: [new Date(), Validators.required],
      deudor: [null, [Validators.required]],
      deudor_nombre: [null, [Validators.required]],
      deudor_domicilio: [null, [Validators.required]],
      deudor_distrito: [null, [Validators.required]],
      deudor_provincia: [null, [Validators.required]],
      deudor_departamento: [null, [Validators.required]],
      beneficiario_nombre: [cliente['nombre'], [Validators.required]], 
      documentos: [null, [Validators.required]]
    });

    console.log("TEST")

    this.myForm.controls.deudor.valueChanges.subscribe(async value=>{
      console.log(value)
      this.myForm.controls.documentos.setValue(this.detallesMap(this.operacion.detalle.filter(el=>el.deudor==value.id)))
      
      const aceptante = await this.aceptantesService.obtenerAceptante(value['id'])
      
      this.myForm.controls.deudor_nombre.setValue(aceptante['nombre'])
      this.myForm.controls.deudor_domicilio.setValue(aceptante['domicilio_legal'])
      this.myForm.controls.deudor_distrito.setValue(aceptante['distrito_nombre'])
      this.myForm.controls.deudor_provincia.setValue(aceptante['provincia_nombre'])
      this.myForm.controls.deudor_departamento.setValue(aceptante['departamento_nombre'])
    })

  }

  detallesMap(detalles) {
    return detalles.map(el=>{
      return {
        numero_documento: el.numero_documento,
        vencimiento: el.vencimiento,
        neto_pagar: el.neto_pagar,
        moneda: el.moneda
      }
  })
  }

  onSubmit() {

    if (this.myForm.valid) {
      var model = {
        "archivo_plantilla": this.formato_plantilla_url,
        "fecha_actual": this.myForm.get("fecha_actual").value,
        "beneficiario_nombre": this.myForm.get("deudor_nombre").value, // El nombre del campo esta mal, en realidad es del deudor pero para hacer mas rapido se corrigio solo el front
        "beneficiario_domicilio": this.myForm.get("deudor_domicilio").value, // El nombre del campo esta mal, en realidad es del deudor pero para hacer mas rapido se corrigio solo el front
        "beneficiario_distrito": this.myForm.get("deudor_distrito").value, // El nombre del campo esta mal, en realidad es del deudor pero para hacer mas rapido se corrigio solo el front
        "beneficiario_provincia": this.myForm.get("deudor_provincia").value, // El nombre del campo esta mal, en realidad es del deudor pero para hacer mas rapido se corrigio solo el front
        "beneficiario_departamento": this.myForm.get("deudor_departamento").value, // El nombre del campo esta mal, en realidad es del deudor pero para hacer mas rapido se corrigio solo el front
        "deudor_nombre": this.myForm.get("beneficiario_nombre").value, // El nombre del campo esta mal, en realidad es del cliente pero para hacer mas rapido se corrigio solo el front
        "documentos": this.myForm.get("documentos").value
      }

      this.formatoService.generarFormatoCartaNotarialCobranzas(model).then(
        (x: any) => {
          var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "Carta Cesion.docx";
          link.click();
          this.activeModal.close(model);
        }, (reason) => {
          this.activeModal.close(model);
        });
    }
    else {
      this.toast.warning("El formulario no está completo.");
    }
  }

}
