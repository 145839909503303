import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
import { ModalElementosMatrizRiesgoComponent } from "../modal-elementos-matriz-riesgo/modal-elementos-matriz-riesgo.component";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

export type CasillaMatrizRiesgo = {
  elementos?  : any[],
  valor_riesgo? : number,
  color? : string,
  eje_x: number,
  eje_y: number
}

@Component({
  selector: 'app-modal-matrices-riesgo',
  templateUrl: './modal-matrices-riesgo.component.html',
  styleUrls: ['./modal-matrices-riesgo.component.css']
})
export class ModalMatricesRiesgoComponent implements OnInit {
  faTimes                     = faTimes
  public instance_row: any;
  private modalRef: any;
  public casillas: any[] = [];
  public data: any[] = [];

  constructor(
    public activeModal        : NgbActiveModal,
    private lavadoActivosService: LavadoActivosService,
    public modalService: NgbModal,
  ) {}

  ngOnInit(): void {
    this.obtenerMatriz();
  }

  obtenerMatriz() {
    let matriz = this.lavadoActivosService.obtenerMatrizRiesgo(this.instance_row.id);
    Promise.all([matriz]).then((res: any[]) => {
      let matriz = res[0];
      this.generarCasillas(matriz);
      this.setData(matriz);
      this.addIndiceItem()
    });
  }
  addIndiceItem(){
    var indice:number = 0
    while (this.data['elementos'].length > indice){
      this.data['elementos'][indice]['indice'] = indice
      indice++
    }
  }
  setData(matriz){
    this.data = matriz;
  }

  generarCasillas(data){
    var altitud = data['leyenda_vertical'].length;
    var longitud = data['leyenda_horizontal'].length;
    var ejeY = 1; 
        while (ejeY <= altitud) {
          var fila = {
            'casillas' : [],
          };
          var ejeX = 1; 
            while (ejeX <= longitud) {
              var valor_riesgo = ejeX * ejeY;
              var casilla: CasillaMatrizRiesgo = {
                eje_x: ejeX,
                eje_y: ejeY,
                valor_riesgo: valor_riesgo
              }

              if (valor_riesgo <= 3) {
                casilla.color = 'bg-color-green';
              }else if (valor_riesgo <= 6){
                casilla.color = 'bg-color-yellow';
              }else if (valor_riesgo <= 12){
                casilla.color = 'bg-color-red';
              }else if (valor_riesgo >= 12){
                casilla.color = 'bg-color-red-wine';
              }
              
              var encontrados = data['elementos'].filter((element) => {
                return element.eje_x === ejeX && element.eje_y === ejeY
              });
              if (encontrados) {
                casilla.elementos = encontrados;
              }
              fila.casillas.push(casilla);
            ejeX++;
            }
          ejeY++;
          this.casillas.push(fila);
        }
  }


  editActionElement(casilla){
    // guardamos en la variable publica modalRef el servicio para crear modales
    this.modalRef = this.modalService.open(ModalElementosMatrizRiesgoComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
      windowClass: "modalSizeXXL"
    });

    this.modalRef.componentInstance.casilla = casilla;
    this.modalRef.componentInstance.data = this.data;
    this.modalRef.componentInstance.matriz_riesgo_id = this.instance_row.id;
  }

  

  async enviarElemento(){
    let elementos = []

    this.casillas.forEach(fila => {
      
      fila.casillas.forEach(casilla => {
        casilla.elementos.forEach(element => {
          elementos.push({...element, eje_x: casilla.eje_x, eje_y: casilla.eje_y})
        });

      });
    });

    let data = {
      id: this.data['id'],
      elementos: elementos  
    };

    await this.lavadoActivosService
      .pathMatrizRiesgo(data)
    ;

  }
}
