import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ToastService } from "../toast/toast.service";
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: "root",
})
export class ProrrogasService {
  private apiUrl: string = "";
  public documents: string = "factoring/prorrogas/";
  public estados: string = "factoring/prorrogas/estados/";
  public tiposPagador: string = "factoring/pagos/tipospagador/";
  public beneficiarios: string = "factoring/beneficiarios/";
  public deudores: string = "factoring/deudores/";
  public adjuntos: string = "/factoring/prorrogas/adjuntos/";
  public docsCartera: string = "/factoring/carteras/prorrogas/";
  public detalleDocumento: string = "/factoring/prorrogas/detalle/";
  public comisiones: string = "/factoring/prorrogas/comisiones/";
  public multiplesComisiones: string = "factoring/prorrogas/comisiones/create_multiple/"
  public eliminarMultiplesComisiones: string = "factoring/prorrogas/comisiones/delete_by_prorroga/"
  public comite: string = "/factoring/prorrogas/comite/";
  public comiteAprobacion: string = "/factoring/prorrogas/comite_aprobacion/";
  public comites: string = "/factoring/comites/";
  public monedas: string = "/factoring/monedas/";
  public periodoAbierto: string = "factoring/cierres/periodo_operativo_abierto/";

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
  }
  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();
    this.appService.notifyMe(mensaje, ok)

    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);
  }

  /**
   * Retorna los documentos
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerProrrogas(
    page: number = 1,
    page_size: number = 10,
    solicitante = "",
    tipo = "",
    fecha_solicitud__gte = '',
    fecha_solicitud__lte = '',
    fecha_vencimiento__gte = "",
    fecha_vencimiento__lte = "",
    estado = "",
    comite = "",
    empresa = "",
  ) {
    const url =
      this.apiUrl +
      this.documents +
      `?solicitante_nombre__icontains=${solicitante}` +
      `&tipo_solicitante=${tipo}` +
      `&fecha_solicitud__gte=${fecha_solicitud__gte}` +
      `&fecha_solicitud__lte=${fecha_solicitud__lte}` +
      `&fecha_vencimiento__gte=${fecha_vencimiento__gte}` +
      `&fecha_vencimiento__lte=${fecha_vencimiento__lte}` +
      `&estado=${estado}` +
      `&comite=${comite}` +
      `&empresa=${empresa}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La operación que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  obtenerPeriodoAbierto() {
    const url = this.apiUrl + this.periodoAbierto;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    })
  }
  

  obtenerProrroga(prorrogaId) {
    const url = this.apiUrl + this.documents + `${prorrogaId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna los estados de prorroga
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerEstados(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl + this.estados + `?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna los tipos de pagador
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerTiposPagador(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.tiposPagador +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna la lista de beneficiarios
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerBeneficiarios(page: number = 1, page_size: number = 1000, ruc__icontains: any = '',) {
    const url =
      this.apiUrl +
      this.beneficiarios +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&ruc_nombre__icontains=${ruc__icontains}`

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }
  /**
   * Retorna la lista de deudores
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerDeudores(page: number = 1, page_size: number = 1000, ruc__icontains: any = '',) {
    const url =
      this.apiUrl + this.deudores + `?page_size=${page_size}` + `&page=${page}` + `&ruc_nombre__icontains=${ruc__icontains}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  buscarBeneficiariosObserver(ruc__icontains: any = '', page: number = 1, page_size: number = 10): Observable<any> {
    const URL = this.apiUrl +
      this.beneficiarios +
      `?ruc_nombre__icontains=${ruc__icontains}` +
      `&page=${page}` +
      `&page_size=${page_size}`;

    return this.http
      .get<any>(URL)
      .pipe(map(resp => {
        if (resp.Error) {
          throwError(resp.Error);
        } else {
          return resp.results;
        }
      })
      );
  }

  buscarDeudorObserver(ruc__icontains: any = '', page: number = 1, page_size: number = 10): Observable<any> {
    const URL = this.apiUrl +
      this.deudores +
      `?ruc_nombre__icontains=${ruc__icontains}` +
      `&page=${page}` +
      `&page_size=${page_size}`;

    return this.http
      .get<any>(URL)
      .pipe(map(resp => {
        if (resp.Error) {
          throwError(resp.Error);
        } else {
          return resp.results;
        }
      })
      );
  }

  /**
 * Retorna la lista de beneficiarios
 * @param page Pagina del request
 * @param page_size Resultados por pagina
 */
  obtenerBeneficiario(id) {
    const url = this.apiUrl + this.beneficiarios + id

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }
  /**
   * Retorna la lista de deudores
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerDeudor(id) {
    const url =
      this.apiUrl + this.deudores + id

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna la lista de documentos en cartera
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerDocumentosCartera(
    beneficiario: string = "",
    deudor: string = "",
    fecha__gte: string = "",
    fecha__lte: string = "",
    page: number = 1,
    page_size: number = 1000,
    saldo_mayor_a_0 = false,
    empresa = "",
    operacion = "",
    recaudacion = ""
  ) {
    const url =
      this.apiUrl +
      this.docsCartera +
      `?beneficiario=${beneficiario}` +
      `&deudor=${deudor}` +
      `&empresa=${empresa}` +
      `&fecha_vencimiento__gte=${fecha__gte}` +
      `&fecha_vencimiento__lte=${fecha__lte}` +
      `${saldo_mayor_a_0 ? '&saldo__gte=0.001' : ''}` +
      `&operacion=${operacion}` +
      `&recaudacion=${recaudacion || ''}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

    /**
   * Retorna la lista de documentos en cartera
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerDocumentosCarteraVacantes(
    beneficiario: string = "",
    deudor: string = "",
    page: number = 1,
    page_size: number = 1000,
    saldo_mayor_a_0 = false,
    empresa = ''
  ) {
    const url =
      this.apiUrl +
      this.docsCartera + 'rechazados/' +
      `?beneficiario=${beneficiario}` +
      `&deudor=${deudor}` +
      `&empresa=${empresa}` +
      `&page_size=${page_size}` +
      `${saldo_mayor_a_0 ? '&saldo__gte=0.001' : ''}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Registra la solicitud de prorroga
   */
  registrarSolicitudProrroga(data: object) {
    const url = this.apiUrl + this.documents;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error['fecha_contable']) {
            this.spinnerOff(`Ocurrió un error en el proceso: La fecha de Solicitud debe estar en un periodo abierto`, false)
          } else {
            this.spinnerOff(err.error);
          }
          ref(err);
        }
      );
    });
  }

  /**
   * Actualiza la solicitud de prorroga
   */
  actualizarSolicitudProrroga(id: number, data: object) {
    const url = this.apiUrl + this.documents + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.patch(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Cambia el estado de la solicitud de prorroga a Solicitado
   */
  enviarSolicitudProrroga(data: object) {
    const url = this.apiUrl + this.comite;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Aprueba la solicitud de la prorroga
   */
  aprobarSolicitud(id: number) {
    const url = this.apiUrl + this.comiteAprobacion + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.put(url, {}).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          let error = 'Ocurrió un error inesperado'
          if (err.error && typeof (err.error) == 'object') {
            error = Object.values(err.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
          }
          this.spinnerOff(error, false);
          ref(err);
        }
      );
    });
  }

  /**
   * Aprueba la solicitud de la prorroga
   */
  rechazarSolicitud(id: number) {
    const url = this.apiUrl + this.documents + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.patch(url, { estado: 4 }).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  devolverProrrogaSolicitadoARegistrado(id: number) {
    const url = this.apiUrl + this.documents + `${id}/`;
    const estadoRegistrado = 1;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.patch(url, {estado: estadoRegistrado}).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      )
    })
  }

  /**
   * Aprueba la solicitud de la prorroga
   */
  aplicarSolicitud(id: number) {
    const url = this.apiUrl + this.documents + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.patch(url, { estado: 5 }).subscribe(
        (response) => {
          this.spinnerOff('Se aplicó la solicitud de prórroga correctamente');
          res(response);
        },
        (err) => {
          let error = 'Ocurrió un error inesperado'
          if (err.error && typeof(err.error) == 'object') {
            error = Object.values(err.error).join('\n');
          }
          this.spinnerOff(error, false);
          ref(err);
        }
      );
    });
  }

  /**
   * Carga el archivo adjunto
   */
  cargarAdjunto(data: object) {
    const url = this.apiUrl + this.adjuntos;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          let msg = 'Ocurrió un error durante la carga del archivo'
          if (err.error && typeof (err.error) == 'object') {
              msg = Object.values(err.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
          }
          this.spinnerOff(msg, false);
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna el archivo adjunto de la prorroga
   */
  obtenerAdjunto(id: number) {
    const url = this.apiUrl + this.adjuntos + `?prorroga=${id}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Eliminar el archivo adjunto
   */
  eliminarAdjunto(id: number) {
    const url = this.apiUrl + this.adjuntos + `${id}/`;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Registra el detalle del documento a prorrogar
   */
  registrarDetalleDocumento(data: object) {
    const url = this.apiUrl + this.detalleDocumento;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err?.error?.documento) {
             this.spinnerOff(err?.error?.documento, false)
          } else {
            this.spinnerOff();
          }
          ref(err);
        }
      );
    });
  }

  actualizarDetalleDocumento(id: number, data: object) {
    const url = `${this.apiUrl}${this.detalleDocumento}${id}/`;
    return this.http.patch(url, data)
  }

  /**
   * Retorna los detalles de la prorroga
   */
  obtenerDetalles(id: number) {
    const url = this.apiUrl + this.detalleDocumento + `?prorroga=${id}&page_size=10000`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Eliminar el detalle del documento a prorrogar
   */
  eliminarDetalleDocumento(id: number) {
    const url = this.apiUrl + this.detalleDocumento + `${id}/`;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Registra la comision de la prorroga
   */
  registrarComision(data: object) {
    const url = this.apiUrl + this.comisiones;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Registra las comisiones prorroga de un documento
   */
  registrarMultiplesComisiones(data: object) {
    const url = this.apiUrl + this.multiplesComisiones;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err)
        }
      )
    })
  }


  /**
   * Retorna la comision de la prorroga
   */
  obtenerComision(id: number) {
    const url = this.apiUrl + this.comisiones + `?prorroga=${id}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Eliminar la comision
   */
  eliminarComision(id: number) {
    const url = this.apiUrl + this.comisiones + `${id}/`;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
    * Eliminar las comisiones por prorroga
    */
  eliminarComisionPorProrroga(prorroga_id: number) {
    const url = this.apiUrl + this.eliminarMultiplesComisiones;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.delete(url, {
        body: { "prorroga": prorroga_id }
      }).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna los integrantes del comite de aprobacion de la prorroga
   */
  obtenerIntegrantesComite(id: number) {
    const url = this.apiUrl + this.comite + `?prorroga=${id}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna los comites exitentes
   */
  obtenerListaComites() {
    const url = this.apiUrl + this.comites;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna las monedas exitentes
   */
  obtenerListaMonedas() {
    const url = this.apiUrl + this.monedas;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  downloadFile(url) {
    return new Promise((resolve, reject) => {
      let httpReques;
      // let URL = this.apiUrl + this.formato_documentos + "solicitud_final/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.get(url, httpOptions);
      this.spinnerOn();
      httpReques.subscribe(data => {
        this.spinnerOff();
        resolve(data);
      }, async (err) => {
        this.spinnerOff();
        console.log('Error al  generar contrato');
        reject(err);
      });

    });
  }

  exportarXls(prorrogaId) : Promise<any> {
    const url = `${this.apiUrl}${this.documents}`
    const prorroga = this.http.get(`${url}${prorrogaId}/`).toPromise()
    const prorrogaDetalles = this.http.get(`${url}detalle/?prorroga=${prorrogaId}&page_size=9999`).toPromise()
    const prorrogaComisiones = this.http.get(`${url}comisiones/?prorroga=${prorrogaId}&page_size=9999`).toPromise()

    return Promise.all([prorroga, prorrogaDetalles, prorrogaComisiones]).then((data: any) => {
      let prorroga = data[0]
      let prorrogaDetalles = data[1].results
      let prorrogaComisiones = data[2].results
      let ws = null, wb = null, origin = 0

      prorroga = [prorroga].map(e => ({
        'Tipo': e['tipo_solicitante_nombre'],
        'RUC': e['solicitante_ruc'],
        'Nombre': e['solicitante_nombre'],
        'Fecha solicitud': e['fecha_solicitud'],
        'Fecha vencimiento': e['fecha_vencimiento'],
        'Moneda': e['moneda_descripcion'],
        'Tasa': e['tasa'],
        'Tasa comisión': e['tasa_comision'],
        'Estado': e['estado_descripcion'],
      }))
      prorrogaDetalles = prorrogaDetalles.map(e => ({
        'Tipo': e['tipo_documento'],
        'Documento': e['numero_documento'],
        'Moneda documento': e['moneda_monto'] == 1 ? 'Soles' : 'Dolares',
        'Monto': e['monto_documento'],
        'Saldo': e['saldo'],
        'Saldo financiado': e['saldo_financiado'],
        'Fecha vencimiento': e['fecha_vencimiento'],
        'TEM': e['tem'],
        'Días prorroga': e['dias_prorroga'],
        'Moneda intereses': e['moneda'] == 1 ? 'Soles' : 'Dolares',
        'Intereses': e['interes'],
        'IGV': e['igv'],
        'Total': e['total'],
      }))
      prorrogaComisiones = prorrogaComisiones.map(e => ({
        'Concepto': e['concepto_nombre'],
        'Moneda': e['moneda'] == 1 ? 'Soles' : 'Dolares',
        'Monto': e['monto'],
        'IGV': e['igv'],
        'Total': e['total'],
      }))

      ws = XLSX.utils.json_to_sheet(prorroga)
      wb = XLSX.utils.book_new()
      origin += 4
      XLSX.utils.sheet_add_aoa(ws, [['Documentos']], {origin: `A${origin++}`},)
      XLSX.utils.sheet_add_json(ws, prorrogaDetalles, {origin: `A${origin}`})
      origin += prorrogaDetalles.length + 2
      XLSX.utils.sheet_add_aoa(ws, [['Comisiones']], {origin: `A${origin++}`},)
      XLSX.utils.sheet_add_json(ws, prorrogaComisiones, {origin: `A${origin}`})
      XLSX.utils.book_append_sheet(wb, ws, "Prorroga");
      XLSX.writeFile(wb, `Prorroga ${prorrogaId} ${new Date().toLocaleString()}.xlsx`);
    })
  }

  importarAplicacion(data) {
    const url = `${this.apiUrl}factoring/prorrogas/importar-aplicacion/`
    return this.http.post(url, data).pipe(
      catchError(response => {
        let error = 'Ocurrió un error inesperado'
        if (response.error && typeof (response.error) == 'object') {
          error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
        }
        this.toast.warning(error)
        return throwError(response)
      })
    )
  }

  obtenerAplicaciones(queryParams: object = {}) {
    const url = `${this.apiUrl}factoring/recaudaciones/?${Object.entries(queryParams).map((e: any) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&')}`
    return this.http.get(url).pipe(
      map((res: any) => res.results),
      catchError(response => {
        let error = 'Ocurrió un error inesperado'
        if (response.error && typeof (response.error) == 'object') {
          error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
        }
        this.toast.warning(error)
        return throwError(response)
      })
    )
  }
}
