import { Component, OnInit } from '@angular/core';
import { DocschecklistService } from '../../../../core/services/config/docschecklist.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'app/core/services/auth/auth.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';




@Component({
	selector: 'app-documentos-checklist',
	templateUrl: './documentos-checklist.component.html',
	styleUrls: ['./documentos-checklist.component.css']
})
export class DocumentosChecklistComponent implements OnInit {
    faTimes=faTimes;
    public formdoc            : FormGroup;
    public columnDefs         : any[];
	public loadingTable       : boolean = false;
    public tabsList           : any[]   = [];
    public activeNav          : number  = 1;
    public estaciones         : any[] = [];
    public estaciones_         : any[] = [];
    public documentos         : any[] = [];
    public checkchecked       : any = {};
    public documentoDesacoplar: any = {};
    public documentoSelect    : any[] = [];
    public documentoSelectList: any[] = [];
	
	constructor(
        public configs     : DocschecklistService,
        public formbuild   : FormBuilder,
		public authService      : AuthService,
        public modalService: NgbModal,
    ) { }
	
	ngOnInit(): void {
        this.loadData()
		this.initVariables()
	}

	initVariables(){
		this.columnDefs = [
			{
				headerName: 'N°', 
				pipe:'indexcol',
				sortable: true
			},  
			{
				headerName: 'Documento', 
				field: 'documento_descripcion' ,
				sortable: true,
                class: 'text-left'
			},
			{
				headerName: 'Grupo', 
				field: 'grupo_descripcion' ,
				sortable: true,
                class: 'text-left'
			},            
			{
				headerName: 'Obligatorio', 
				field: 'obligatorio' ,
				sortable: true,
				classes: 'text-center',
				pipe:'checkbox',
			},									                                                                    
		];  	 	
	}

    loadData(){
        let estaciones     = this.configs.obtenerEstaciones();
        let documentos     = this.configs.obtenerDocumentosPorEstacion();    
        let onlyDocumentos = this.configs.obtenerDocumentos();  
        this.tabsList = []
        Promise.all([estaciones,documentos,onlyDocumentos]).then((res)=>{
            this.estaciones      = res[0]['results'];
            this.estaciones_      = this.estaciones.filter((estacion)=>this.filterEditable(estacion));
            this.documentos      = res[1]['results'];
            this.documentoSelect = res[2]['results'];
            this.tabsList        = this.estaciones.map((item)=>{
                item.documents = this.documentos.filter((doc) => doc.estacion == item.id);
                item.canEdit = this.filterEditable(item)
                console.log(item.canEdit)
                // switch(){

                // }
                return item;
            })
        })        
    }

    filterEditable(pestana){
        let usuario = this.authService.user
        switch(pestana.id){
            case 1: //Estacion Cliente
                return usuario.isGerenteComercial 
                    || usuario.isAdmin 

            case 2: //Estacion Oficial de Negocio
                return usuario.isGerenteComercial 
                    || usuario.isAdmin 
                    || usuario.isAnalistaRiesgo 
                    || usuario.isJefeDeAdmision 


            case 3: //Estacion Analisis de Riesgos
                return usuario.isGerenteComercial 
                    || usuario.isAdmin 

            case 4: //Estacion Comite de Creditos
                return usuario.isGerenteComercial 
                    || usuario.isAdmin 
                
            case 5: //Estacion ON Post Comite
                return usuario.isGerenteComercial 
                    || usuario.isAdmin 
                    || usuario.isAsesorLegal 

            case 6: //Estacion Legal
                return usuario.isGerenteComercial 
                    || usuario.isAdmin 
            case 8: //Estacion ON Liquidacion
                return usuario.isGerenteComercial 
                    || usuario.isAdmin 
                    || usuario.isJefeDeOperaciones

            case 9: //Estacion Jefe de Operaciones
                return usuario.isGerenteComercial 
                    || usuario.isAdmin 
                    || usuario.isTesoreria 
                    || usuario.isLiderDeTesoreria 

            case 11: //Estacion Tesoreria
                return usuario.isGerenteComercial 
                    || usuario.isAdmin 
                    || usuario.isAsistenteCobranzas 

            case 13: //Estacion Asistente de Cobranzas
                return usuario.isGerenteComercial 
                    || usuario.isAdmin 
                
            case 14: //Archivo
                return usuario.isGerenteComercial 
                    || usuario.isAdmin 
        }
    }
	
    checkboxChange(event){
        let documento = {
            documento            : event.row.documento,
            estacion             : event.row.estacion,
        }
        if(event.field == 'visible'){
            documento[event.field] = event.newvalue
            documento['obligatorio'] = event.row.documento.obligatorio
        } else {
            documento[event.field] = event.newvalue
            documento['visible'] = event.row.documento.visible
        }
        this.configs.actualizarValordocumento(event.row.id,documento).then((res)=>{
            this.checkchecked = event.checkEvent
        })
    }

    modalAgregarDocumento(content){
        this.formdoc = this.formbuild.group({
            estacion : ['', [Validators.required]],
            documento: ['', [Validators.required]],
        })    

        this.formdoc.valueChanges.subscribe(val => {
            if(val.estacion){
                let index = this.tabsList.findIndex((estacion) => estacion.id == val.estacion)
                this.documentoSelectList = this.documentoSelect.filter((doc) => {
                    let value = this.tabsList[index].documents.some((d) => d.documento === doc.id)
                    if(!value)
                        return doc
                })
            }
        });         
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'md'
        }).result.then((result) => {
            if(result){
                this.submitAddDocumentos()
            }
            this.formdoc.reset()
        }, (reason) => {
            this.formdoc.reset()
        });
            
    }

    submitAddDocumentos(){
        this.configs.agregarDocumento(this.formdoc.value).then((res)=>{
            this.loadData()
        })
    }

    desacoplarDocumento(val,modal){
        this.documentoDesacoplar = val
        this.modalService.open(modal, {
            size: 'md'
        }).result.then((result) => {
            if(result){
                this.configs.desacoplarDocumento(val.id).then((res)=>{
                    this.loadData()
                    this.documentoDesacoplar = {}
                })
            }
        }, (reason) => {
        });        
    }
}
