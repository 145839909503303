import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FactoringService } from 'app/core/services/factoring/factoring.service';

import * as XLSX from 'xlsx'

@Component({
  selector: 'app-advertencia-deuda',
  templateUrl: './advertencia-deuda.component.html',
  styleUrls: ['./advertencia-deuda.component.css']
})
export class AdvertenciaDeudaComponent implements OnInit, OnChanges {

  @ViewChild('modalAdvertencia', { static: false }) private modalAdvertencia

  @Input() ruc = ''
  @Input() rucs = []
  @Input() soloBoton = false
  @Output() deudaEvent = new EventEmitter<any>()

  dataDeuda = {}

  constructor(
    private factoringService: FactoringService,
    private modalService: NgbModal,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes) {
    if (changes.ruc) {
      this.loadDeuda()
    }
  }

  showModal() {
    this.modalService.open(
      this.modalAdvertencia,
      {
        size: 'xl',
        beforeDismiss: () => false,
      }
    ).result
      .then(result => console.log(result))
      .catch(reason => console.log(reason))
  }

  exportarDetalle() {
    const wb = XLSX.utils.book_new()
    if (this.rucs.length > 0) {
      this.addSheet(wb, this.dataDeuda['cliente_aceptante']?.documentos, 'Cliente-Aceptante Documentos', this.formatDocumentos, { monto: 'saldo_financiado' })
      this.addSheet(wb, this.dataDeuda['cliente_aceptante']?.cuentas, 'Cliente-Aceptante Cuentas', this.formatCuentas, { monto: 'saldo' })
      this.addSheet(wb, this.dataDeuda['cliente']?.documentos, 'Cliente-Otros Documentos', this.formatDocumentos, { monto: 'saldo_financiado' })
      this.addSheet(wb, this.dataDeuda['cliente']?.cuentas, 'Cliente-Otros Cuentas', this.formatCuentas, { monto: 'saldo' })
      this.addSheet(wb, this.dataDeuda['cliente']?.pendientes_aplicacion, 'Cliente Pendientes Aplicación', this.formatPendientesAplicacion, { monto: 'saldo' })
      this.addSheet(wb, this.dataDeuda['cliente']?.relacion_vencido_financiado, 'Cliente Vencido-Financiado', this.formatVencidoFinanciado)
      this.addSheet(wb, this.dataDeuda['leasing']?.deuda, 'Deuda Leasing', this.formatDeudaLeasing, {
        monto: 'saldo',
        filterPen: e => e.moneda_codigo == 'PEN',
        filterUsd: e => e.moneda_codigo == 'USD',
      })
      this.addSheet(wb, this.dataDeuda['aceptante']?.documentos, 'Aceptante-Otros Documentos', this.formatDocumentos, { monto: 'saldo_financiado' })
      this.addSheet(wb, this.dataDeuda['aceptante']?.cuentas, 'Aceptante-Otros Cuentas', this.formatCuentas, { monto: 'saldo' })
      this.addSheet(wb, this.dataDeuda['aceptante']?.pendientes_aplicacion, 'Aceptante Pendientes Aplicación', this.formatPendientesAplicacion, { monto: 'saldo' })
      this.addSheet(wb, this.dataDeuda['aceptante']?.relacion_vencido_financiado, 'Aceptante Vencido-Financiado', this.formatVencidoFinanciado)
    } else {
      this.addSheet(wb, this.dataDeuda['cliente']?.documentos, 'Documentos', this.formatDocumentos, { monto: 'saldo_financiado' })
      this.addSheet(wb, this.dataDeuda['cliente']?.cuentas, 'Cuentas', this.formatCuentas, { monto: 'saldo' })
      this.addSheet(wb, this.dataDeuda['cliente']?.pendientes_aplicacion, 'Pendientes Aplicación', this.formatPendientesAplicacion, { monto: 'saldo' })
      this.addSheet(wb, this.dataDeuda['cliente']?.relacion_vencido_financiado, 'Vencido-Financiado', this.formatVencidoFinanciado)
      this.addSheet(wb, this.dataDeuda['leasing']?.deuda, 'Deuda Leasing', this.formatDeudaLeasing, {
        monto: 'saldo',
        filterPen: e => e.moneda_codigo == 'PEN',
        filterUsd: e => e.moneda_codigo == 'USD',
      })
    }
    XLSX.writeFile(wb, "DeudaVencida.xlsx")
  }

  async loadDeuda() {
    this.dataDeuda = {}
    if (this.ruc) {
      let leasing = {}
      try {
        leasing = await this.loadDeudaLeasing()
      } catch {}
      try {
        this.dataDeuda = await this.loadDeudaFactoring()
      } catch {}
      this.dataDeuda['leasing'] = leasing
      this.dataDeuda['deuda_vencida'] = this.dataDeuda['deuda_vencida'] || leasing['deuda_vencida']
      if (this.dataDeuda['deuda_vencida'] && !this.soloBoton) {
        this.showModal()
      }
    }
    this.deudaEvent.emit(this.dataDeuda)
  }

  async loadDeudaFactoring() {
    if (this.ruc) {
      try {
        let data: any = await this.factoringService.getDeudaVencida(this.ruc, this.rucs).toPromise()
        for (let seccion of ['cliente_aceptante', 'cliente', 'aceptante']) {
          data[seccion]['documentos_usd1'] = data[seccion]['documentos'].filter(e => e['dias_mora'] <= 8).reduce((t, e) => t + e['saldo_usd'], 0)
          data[seccion]['documentos_usd2'] = data[seccion]['documentos'].filter(e => e['dias_mora'] > 8).reduce((t, e) => t + e['saldo_usd'], 0)
          data[seccion]['cuentas_usd'] = data[seccion]['cuentas'].reduce((t, e) => t + e['saldo_usd'], 0)
          if (data[seccion]['pendientes_aplicacion']) {
            data[seccion]['pendientes_aplicacion_usd'] = data[seccion]['pendientes_aplicacion'].reduce((t, e) => t + e['saldo_usd'], 0)
          }
          if (data[seccion]['relacion_vencido_financiado']) {
            data[seccion]['relacion_vencido_financiado_max'] = Math.max(...data[seccion]['relacion_vencido_financiado'].map(e => e.relacion))
          }
          data[seccion]['deuda_vencida'] = data[seccion]['documentos'].length > 0 || data[seccion]['cuentas'].length > 0
          data['deuda_vencida'] = data['deuda_vencida'] || data[seccion]['deuda_vencida']
        }
        return data
      } catch (ex) {
        console.log(ex)
      }
    }
    return {}
  }

  async loadDeudaLeasing() {
    const categoriasConcepto = {
      'cuota de arrendamiento': 'Cuotas',
      'comisión de cobranzas': 'Cuotas',
      'opción de compra': 'Opciones de compra',
      '*': 'Cuentas x cobrar',
    }
    if (this.ruc) {
      try {
        let data: any = await this.factoringService.getDeudaLeasing(this.ruc).toPromise()
        let resumen = {}
        data.forEach(e => {
          e['categoria'] = categoriasConcepto[e['concepto'].toLowerCase()] ?? categoriasConcepto['*']
          if (!resumen[e['categoria']]) resumen[e['categoria']] = {}
          if (!resumen[e['categoria']][e['moneda_codigo']]) resumen[e['categoria']][e['moneda_codigo']] = 0
          resumen[e['categoria']][e['moneda_codigo']] += Number(e['saldo'])
        })

        return {
          'deuda': data,
          'deuda_usd': data.reduce((t, e) => t + e['saldo_usd'], 0),
          'cuotas': resumen['Cuotas'],
          'cuentas_cobrar': resumen['Cuentas x cobrar'],
          'opciones_compra': resumen['Opciones de compra'],
          'deuda_vencida': data.length > 0
        }
      } catch (ex) {
        console.log(ex)
      }
    }
    return {}
  }

  private addSheet(wb, data, name, format = undefined, totales = undefined) {
    if (data?.length) {
      let sheet = XLSX.utils.json_to_sheet(format ? format(data) : data)
      if (totales?.monto) {
        let filterPen = (e) => e.moneda == 1
        let filterUsd = (e) => e.moneda == 2
        if (totales.filterPen) filterPen = totales.filterPen
        if (totales.filterUsd) filterUsd = totales.filterUsd
        const totalPen = data.filter(filterPen).reduce((total, e) => total + Number(e[totales.monto]), 0)
        const totalUsd = data.filter(filterUsd).reduce((total, e) => total + Number(e[totales.monto]), 0)
        XLSX.utils.sheet_add_aoa(sheet, [['Total S/', totalPen], ['Total $', totalUsd]], { origin: `B${data.length + 3}` })
      }
      XLSX.utils.book_append_sheet(wb, sheet, name)
    }
  }

  private formatDocumentos(data) {
    return data.map(e => ({
      'Operacion': e['operacion'],
      'Número': e['numero_documento'],
      'Cliente': e['beneficiario_nombre'],
      'Aceptante': e['deudor_nombre'],
      'Moneda': e['moneda_descripcion'],
      'Saldo': e['saldo_financiado'],
      'Fecha vencimiento': e['fecha_vencimiento'],
      'Días mora': e['dias_mora'],
    }))
  }

  private formatCuentas(data) {
    return data.map(e => ({
      'Operación': e['operacion'],
      'Número': e['id'],
      'Cliente': e['beneficiario_nombre'],
      'Aceptante': e['deudor_nombre'],
      'Moneda': e['moneda_descripcion'],
      'Saldo': e['saldo'],
      'Concepto': e['concepto_descripcion'],
      'Fecha': e['fecha_creacion'],
    }))
  }

  private formatVencidoFinanciado(data) {
    return data.map(e => ({
      'Cliente/Aceptante': e['obligado_nombre'],
      'Financiado': e['financiado'],
      'Vencido': e['vencido'],
      '%': Math.round(e['relacion'] * 10000) / 100,
    }))
  }

  private formatPendientesAplicacion(data) {
    return data.map(e => ({
      'Abono': e['id'],
      'Pagador': e['pagador_nombre'],
      'Moneda': e['moneda_descripcion'],
      'Saldo': e['saldo'],
      'Banco': e['banco_nombre'],
      'Fecha': e['fecha'],
    }))
  }

  private formatDeudaLeasing(data) {
    return data.map(e => ({
      'Concepto': e['concepto'],
      'Contrato': e['contrato'],
      'Estado SBS': e['estado_sbs'],
      'Moneda': e['moneda'],
      'Saldo': e['saldo'],
      'Categoría': e['categoria'],
    }))
  }

}
