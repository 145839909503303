import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { JudicialService } from 'app/core/services/judicial/judicial.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ExpedientejudicialComponent } from '../components/expedientejudicial/expedientejudicial.component';

@Component({
  selector: 'app-bandejajudicialexpedientes',
  templateUrl: './bandejajudicialexpedientes.component.html',
  styleUrls: ['./bandejajudicialexpedientes.component.css']
})
export class BandejajudicialexpedientesComponent implements OnInit {

  @ViewChild(ExpedientejudicialComponent) expedienteModal: ExpedientejudicialComponent;
  faPlus=faPlus;
  faSyncAlt=faSyncAlt;
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = "";
  public canCreate: boolean = false;
  public tmpData: any;
  public modalPack: any = {};

  constructor(
    public modalService: NgbModal,
    public router: Router,
    public factoring: FactoringService,
    public authService: AuthService,
    public judialService: JudicialService,
    public documentosCarteraService: DocumentosCarteraService
  ) { }

  ngOnInit(): void {
    this.obtenerJudial();
  }

  obtenerJudial() {

    let bandejaJudicial = this.judialService.bandejaJudicial();
    let estados = this.documentosCarteraService.obtenerEstadosCarteras();
    let expedientesEstados = this.judialService.expedientesEstados();
    let expedientesTipos = this.judialService.expedientesTipos();
    let expedientesTiposSentenciasPrincipal = this.judialService.expedientesTiposSentenciasPrincipal();
    let expedientesTiposSentenciasCautelar = this.judialService.expedientesTiposSentenciasCautelar();
    let empresas = this.factoring.obtenerEmpresas();
    Promise.all([
      bandejaJudicial, //0
      estados, //1
      expedientesEstados, //2
      expedientesTipos, //3
      expedientesTiposSentenciasPrincipal, //4
      expedientesTiposSentenciasCautelar,   //5
      empresas
    ])
      .then((res: any[]) => {
        let documents = res[0];
        let estados = res[1]['results'];
        let empresas = res[6]['results'];
        this.modalPack = {
          expedientesEstados: res[2],
          expedientesTipos: res[3],
          expedientesTiposSentenciasPrincipal: res[4],
          expedientesTiposSentenciasCautelar: res[5],
          empresas: this.factoring.obtenerEmpresas()
        }
        this.initVars(estados, empresas);
        this.loadTable(documents);
      }
      );

  }

  crearExpediete() {
    let obj = {
      aceptante                         : null,
      aceptante_nombre                  : null,
      aceptante_ruc                     : null,
      cliente                           : null,
      cliente_nombre                    : null,
      detalles                          : [],
      cliente_ruc                       : null,
      estado                            : null,
      estado_descripcion                : null,
      fecha_admision_demanda            : null,
      fecha_admision_medida_cautelar    : null,
      empresa    : 1,
      fecha_presentacion_demanda        : null,
      fecha_presentacion_medida_cautelar: null,
      index                             : null,
      moneda                            : null,
      moneda_descripcion                : null,
      monto                             : null,
      monto_ejecutado                   : null,
      nro_documento                     : null,
      operacion                         : null,
      prorroga                          : null,
      resultado_demanda                 : null,
      resultado_medida_cautelar         : null,
      saldo                             : null,
      selected                          : null,
      sentencia_proceso_principal       : null,
      tipo                              : null,
      tipo_documento                    : null,
      tipo_resultado_medida_cautelar    : null,
      tipo_sentencia_proceso_principal: null,
      juzgado       : null,
      comentarios       : null
    }
    let { modalPack } = this
    this.expedienteModal.initModal(obj, modalPack, true)
  }

  loadTable(data) {
    this.rowData = data.results.map((element, index) => ({
      ...element, index: index + 1
    }));
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars(estados, empresas) {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "cliente_nombre",
        sortable: true,
        filterable: true,
        filterProp: "cliente__nombre",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "cliente_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
        sortable: true,
        filterable: true,
        filterProp: "aceptante__nombre",
        filterInput: true

      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "aceptante_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Documentos",
        field: "tipo_documento",
        sortable: true,
        pipe: 'function',
        function: function (row) {
          return row.detalles.length
        },
      },
      {
        class: "text-center",
        headerName: "Fecha Presentación Demanda",
        field: "fecha_presentacion_demanda",
        sortable: true,
        pipe: 'date',
        function: function (row) {
          let fecha = row.fecha_presentacion_demanda

          let splitted = fecha.replace(/\B(?=(\d{3})+(?!\d))/g, '/')
          // splitted.reverse()
          console.log(splitted)
          return row.fecha_presentacion_demanda
        },
      },
      {
        class     : "text-center",
        headerName: "Fecha Admisión Demanda",
        field     : "fecha_admision_demanda",
        sortable  : true,
        pipe      : 'date',
        function  : function (row) {
          return row.fecha_admision_demanda
        },
      },
      {
        class     : "text-center",
        headerName: "Fecha Presentación Medida Cautelar",
        field     : "fecha_presentacion_medida_cautelar",
        sortable  : true,
        pipe      : 'date',
        function  : function (row) {
          return row.fecha_admision_demanda
        },
      },
      {
        class     : "text-center",
        headerName: "Fecha Admisión Medida Cautelar",
        field     : "fecha_admision_medida_cautelar",
        sortable  : true,
        pipe      : 'date',
        function  : function (row) {
          return row.fecha_admision_medida_cautelar
        },
      },
      {
        class: "text-center",
        headerName: "Monto Ejecutado",
        field: "monto_ejecutado",
        sortable: true,
        pipe: 'function',
        function: function (row, header) {
					if (row.moneda == 1) {
						let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(row.monto_ejecutado))
						return `S/ ${number}`
					}
					else {
						return `$ ${Number(row.monto_ejecutado).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
					}
				}
      },
      {
        class: "text-center",
        headerName: "Estado",
        filterProp: 'estado',
        field: "estado",
        sortable: true,
        pipe: 'function',
        function: function (row) {
          return row.estado_descripcion
        },
        filterable: true,
        filterSelectItems: this.modalPack.expedientesEstados.results,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {
    console.log(filtros)
    this.judialService.bandejaJudicial(
      page,
      per_page,
      filtros.aceptante__nombre ? filtros.aceptante__nombre : '',
      filtros.cliente__nombre,
      filtros.estado,
      filtros.empresa
    )
      .then(res => {
        this.loadTable(res);
      })
      .catch(error => {
        const data = {
          count: 0,
          next: null,
          num_pages: 1,
          page_number: 1,
          per_page: 10,
          previous: null,
          results: []
        }

        this.loadTable(data);
      });

  }

  tableEditEvent(row) {
    this.cargarModalExpediente(row)

  }

  cargarModalExpediente(row) {
    let { modalPack } = this
    this.expedienteModal.initModal(row, modalPack)
  }

  workflowEvent(row) {
    this.router.navigate(['/operaciones', row.operacion]);
  }

  parseDate(fecha) {

    let yy = fecha.split('-')[0];
    let mm = fecha.split('-')[1];
    let dd = fecha.split('-')[2];
    dd = dd.split('T')[0]

    let hora = Number(fecha.split('T')[1].split(':')[0])
    let momento = (hora >= 12) ? "PM" : "AM";
    hora = (hora >= 12) ? hora - 12 : hora
    let minuto = fecha.split('T')[1].split(':')[1]
    let hour = `  ${hora}:${minuto} ${momento}`

    let date = `${dd}/${mm}/${yy} ${hour}`;
    return date
  }

  guardarCambios(body) {
    console.log(body)
    this.documentosCarteraService.modificarCabecera(body, this.tmpData.id).then((res) => {
      console.log(body)
    })
  }

  get showCreateButton() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilAnalistaRiesgosID,
      this.authService.perfil.perfilJefeDeAdmisionID,
      this.authService.perfil.perfilAsesorLegalID,
      this.authService.perfil.perfilLegalID,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilSubGerenciaComercialID,
      this.authService.perfil.perfilTesoreriaID,
      this.authService.perfil.perfilLiderDeTesoreriaID,
      this.authService.perfil.perfilRecepcionID,
      this.authService.perfil.perfilAsistenteCobranzasID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

}
