import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MaskService } from '../../core/services/mask/mask.service'
import { DispositivosService } from '../../core/services/dispositivos/dispositivos.service'

@Component({
  selector: 'app-alert-validation',
  templateUrl: './alert-validation.component.html',
  styleUrls: ['./alert-validation.component.css']
})
export class AlertValidationComponent implements OnInit {

  @ViewChild( 'modal_ingresardigito_correo' ) showModal : ElementRef;

  faTimes = faTimes;
  formCodigo : FormGroup;

  constructor(
    public modalService: NgbModal,
    private form: FormBuilder,
    private mask: MaskService,
    private dispositivosService: DispositivosService
  ) { }

  ngOnInit(): void {

    this.actForm();

  }

  close(){
    this.modalService.dismissAll()
  }

  actForm(){

    this.formCodigo = this.form.group({
      codigo: [ null, [ Validators.required ] ]
    })

  }

  showModalDigito(){
    this.enviarCorreo(() => {
      this.modalService.open(this.showModal, {
        size: 'md'
      })
    })
  }

  verificarCodigo(){
    if (this.formCodigo.invalid) return
    this.mask.show()
    const codigo = this.formCodigo.get('codigo').value
    return this.dispositivosService.verificarCodigo(codigo).subscribe(
      data => {
        this.dispositivosService.localService.setJsonValue('session', data)
        this.close()
        this.mask.hide('Se registro en sus dispositivos de confianza')
      },
      res => {
        let error = 'Ocurrió un error inesperado'
        if (res.error && typeof(res.error) == 'object') {
          error = Object.values(res.error).join('\n');
        }
        this.mask.hide(error, false)
      },
    )
  }

  enviarCorreo(callback) {
    this.mask.show()
    return this.dispositivosService.enviarCodigoCorreo().subscribe(
      data => {
        this.mask.hide('Se envió un código de verificación a su correo')
        if (callback) callback()
      },
      res => {
        console.log(res)
        this.mask.hide('Ocurrió un error al enviar el código de verificación a su correo', false)
      },
    )
  }

}
