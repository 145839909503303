import { Component, OnInit } from '@angular/core';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'app/core/services/auth/auth.service';

@Component({
  selector: 'app-facturacion',
  templateUrl: './facturacion.component.html',
  styleUrls: ['./facturacion.component.css']
})
export class FacturacionComponent implements OnInit {

  tableSelectable: boolean = false;
   faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  active = 1;

  refreshPending = false;
  refreshBill = false;
  refreshComprobante = false;

  constructor(private authService: AuthService) { }
  mostrarBtn = true;

  ngOnInit(): void {
  }

  enableSelectable() {
    this.tableSelectable = !this.tableSelectable;
  }

  changeRefresh() {
    switch (this.active) {
      case 1:
        this.refreshPending = !this.refreshPending;
        break;
      case 2:
        this.refreshBill = !this.refreshBill;
        break;
      case 3:
        this.refreshComprobante = !this.refreshComprobante
    }
  }

  get canAdd() {
		const perfilesPermitidos = [
		  this.authService.perfil.perfilAdmin,
		  this.authService.perfil.perfilJefeDeOperacionesID,
		//   this.authService.perfil.perfilAnalistaOperacionesID,
		  this.authService.perfil.perfilAsistenteCobranzasID,
		//   this.authService.perfil.perfilAnalistaOperacionesID,
		  this.authService.perfil.perfilGerenciaComercialID,
		  this.authService.perfil.perfilGerenciaGeneralID,
		]
	
		return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
	}
  cambiobtn( value ){

    this.mostrarBtn = value;

  }

}
