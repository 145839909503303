import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class AnalistasService {

  private apiUrl     : string = '';
  public analistas   : string = 'factoring/operaciones/asignar/analistariesgos/';
  public reAsignar   : string = 'factoring/operaciones/asignar/responsable/';
  public reAsignarAnalistaOperaciones   : string = 'factoring/operaciones/bandeja/';
  public oficialDeNegocio : string = 'factoring/operaciones/asignar/oficialnegocio/'
  public responsables: string = 'factoring/responsables/';
   

  constructor(
    public appService: AppService,
    public toast     : ToastService,
    public spinner   : NgxSpinnerService,
    public http      : HttpClient
  ) { 
    this.apiUrl = this.appService.settings.API_base_url
  }

  /**
   * Habilita el loader para request a la API
   */
  spinnerOn(){
      this.spinner.show()
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok : boolean = true){
      this.spinner.hide()
      this.appService.notifyMe(mensaje,ok)

      if(mensaje && ok)
          this.toast.success(mensaje)
      if(mensaje && !ok)
          this.toast.warning(mensaje)
  }

  /**
   * Obtener analistas de riesgos
   * @param id de la operacion
   */
  obtenerAnalistas(id) {
    const url = this.apiUrl + this.analistas + `${id}/`;

    return new Promise((resolve, reject) => {
        this.spinnerOn()
        const httpRequest = this.http.get(url);
        httpRequest.subscribe(data => {
            this.spinnerOff()
            resolve(data);
        }, (err) => {
            reject(err);
        });
        
    });
  } 

  obtenerAnalistasPorRol(idRol) {
    const url = this.apiUrl + this.responsables + `?rol=${idRol}&estado=true`;

    return new Promise((resolve, reject) => {
        this.spinnerOn()
        const httpRequest = this.http.get(url);
        httpRequest.subscribe(data => {
            this.spinnerOff()
            resolve(data);
        }, (err) => {
            reject(err);
        });
        
    });
  }

  reAsignResponsable(data) {
    const url = this.apiUrl + this.reAsignar;

    return new Promise((resolve, reject) => {
        this.spinnerOn()
        const httpRequest = this.http.post(url, data);
        httpRequest.subscribe(data => {
            this.spinnerOff()
            resolve(data);
        }, (err) => {
            this.spinnerOff(err.error.detail,false);
            reject(err);
        });
        
    });
  }

  asignaAnalistaDeOperaciones(data) {
    const url = this.apiUrl + this.reAsignarAnalistaOperaciones+ `${data.operacion}/`;

    return new Promise((resolve, reject) => {
        this.spinnerOn()
        const httpRequest = this.http.patch(url, {analista_operacion : data.analista_operacion});
        httpRequest.subscribe(data => {
            this.spinnerOff()
            resolve(data);
        }, (err) => {
            this.spinnerOff(err.error.detail,false);
            reject(err);
        });
        
    });
  }

  /**
   * Asigna un anaista a una oprecion
   * @param id ID de la operacion
   * @param data Datos del analista
   */
  asignarAnalista(id, data) {
    const url = this.apiUrl + this.analistas + `${id}/`;

    return new Promise((resolve, reject) => {
        this.spinnerOn()
        const httpRequest = this.http.patch(url, data);
        httpRequest.subscribe(data => {
            this.spinnerOff()
            resolve(data);
        }, (err) => {
            this.spinnerOff(err.error.detail,false);
            reject(err);
        });
        
    });
  }

  reAsignOficialDeNegocios(id, data) {
    const url = this.apiUrl + this.oficialDeNegocio + `${id}/`;

    return new Promise((resolve, reject) => {
        this.spinnerOn()
        const httpRequest = this.http.patch(url, data);
        httpRequest.subscribe(data => {
            this.spinnerOff()
            resolve(data);
        }, (err) => {
            this.spinnerOff(err.error.detail,false);
            reject(err);
        });
    });
  }

  /**
   * Obtener analistas de riesgos
   * @param id de la operacion
   */
     obtenerOficialesDeNegocio(id) {
      const url = this.apiUrl + this.oficialDeNegocio + `${id}/`;
  
      return new Promise((resolve, reject) => {
          this.spinnerOn()
          const httpRequest = this.http.get(url);
          httpRequest.subscribe(data => {
              this.spinnerOff()
              resolve(data);
          }, (err) => {
              reject(err);
          });
          
      });
    } 

      /**
   * Obtener analistas de riesgos
   * @param id de la operacion
   */
    // obtenerAnalistasDeOperaciones() {

    //     const url = this.apiUrl + `seguridad/usuarios/?cliente=&page=1&page_size=10&perfil=14&estado=true`;
    
    //     return new Promise((resolve, reject) => {
    //         this.spinnerOn()
    //         const httpRequest = this.http.get(url);
    //         httpRequest.subscribe(data => {
    //             this.spinnerOff()
    //             resolve(data);
    //         }, (err) => {
    //             reject(err);
    //         });
            
    //     });
    //   } 
  
}

