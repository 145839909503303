import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './auth/auth.service';
import { ToastService } from './toast/toast.service';

@Injectable({
    providedIn: 'root'
})
export class OrdenesPagoService {
    private apiUrl: string = '';
    public ordenes: string = `factoring/operaciones/ordenes/`;
    public ordenesAdelanto: string = `factoring/operaciones/ordenes/adelanto/`;
    public ordenesLiquidacion: string = `factoring/operaciones/ordenes/liquidacion/`;
    public formasDePago: string = `factoring/pagos/formas/`;
    public operaciones: string = `factoring/operaciones/`;
    public notificarCuenta: string = 'factoring/operacion/';
    public verificacionCuentaAbono: string = 'factoring/beneficiarios/cuentasabono/';

    constructor(public appService: AppService,
        public toast: ToastService,
        public spinner: NgxSpinnerService,
        public http: HttpClient,
        public auth: AuthService) {
        this.apiUrl = this.appService.settings.API_base_url
    }

    /**
    * Habilita el loader para request a la API
    */
    spinnerOn() {
        this.spinner.show()
    }

    /**
    * Desabilita el loader
    * @param mensaje Mensaje del toast
    * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
    */
    spinnerOff(mensaje: string = null, ok: boolean = true) {
        this.spinner.hide()
        this.appService.notifyMe(mensaje, ok)

        if (mensaje && ok)
            this.toast.success(mensaje)
        if (mensaje && !ok)
            this.toast.warning(mensaje)
    }


    obtenerAdelanto(operacion) {

        const URL = `${this.apiUrl}${this.ordenesAdelanto}?operacion=${operacion}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

    obtenerLiquidacion(operacion) {

        const URL = `${this.apiUrl}${this.ordenesAdelanto}?operacion=${operacion}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

    obtenerOrden(operacion) {

        const URL = `${this.apiUrl}${this.ordenesLiquidacion}?operacion=${operacion}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }


    obtenerFormasDePago() {

        const URL = `${this.apiUrl}${this.formasDePago}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

    actualizaAdelanto(adelanto, body) {

        const URL = `${this.apiUrl}${this.ordenesAdelanto}${adelanto}/`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.put(URL, body);
            httpRequest.subscribe(data => {
                this.spinnerOff('La orden de pago se actualizó con éxito')
                resolve(data);
            }, (err) => {
                let errMsg
                if (err.error.cliente) {
                    errMsg = err.error.cliente
                } else {
                    errMsg = "Ocurrio un error al procesar el Adelanto"
                    if (err.error && typeof (err.error) == 'object') {
                        errMsg = Object.values(err.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
                    }
                }
                this.spinnerOff(errMsg, false)
                reject(err);
            });

        });
    }

    actualizaDesembolso(desembolso, body) {

        const URL = `${this.apiUrl}${this.ordenesLiquidacion}${desembolso}/`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.put(URL, body);
            httpRequest.subscribe(data => {
                this.spinnerOff('La orden de pago se actualizó con éxito')
                resolve(data);
            }, (err) => {
                let errMsg
                if (err.error.cliente) {
                    errMsg = err.error.cliente
                } else {
                    errMsg = "Ocurrio un error al procesar el Desembolso"
                    if (err.error && typeof (err.error) == 'object') {
                        errMsg = Object.values(err.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
                    }
                }
                this.spinnerOff(errMsg, false)
                reject(err);
            });

        });
    }

    solicitudCambiarCuenta(operacionId) {
        const URL = `${this.apiUrl}${this.operaciones}${operacionId}/solicitar-editar-cuenta-abono/`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.post(URL, {});
            httpRequest.subscribe(data => {
                this.spinnerOff('Se solicitó cambio de cuenta abono correctamente');
                resolve(data);
            }, (err) => {
                this.spinnerOff('Ocurrio un error al procesar la solicitud', false);
                reject(err);
            });
        });
    }

    notificarCambiodeCuenta(operacionId) {
        const URL = `${this.apiUrl}${this.operaciones}${operacionId}/notificar-editar-cuenta-abono/`;
        this.spinnerOn();
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.post(URL, {});
            httpRequest.subscribe(data => {
                this.spinnerOff('Se notifico el cambio de cuenta abono correctamente');
                resolve(data);
            }, (err) => {
                this.spinnerOff('Ocurrio un error al procesar la solicitud', false);
                reject(err);
            });
        });
    }

    actualizaEstadoVerificado(cuenta_abono_id, body) {
        const URL = `${this.apiUrl}${this.verificacionCuentaAbono}${cuenta_abono_id}/`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.put(URL, body);
            httpRequest.subscribe(data => {
                this.spinnerOff('La verificación se actualizó con éxito')
            }, (err) => {
                let errMsg
                if (err.error.cliente) {
                    errMsg = err.error.cliente
                } else {
                    errMsg = "Ocurrio un error al actualizar la verificación"
                }
                this.spinnerOff(errMsg, false)
                reject(err);
            });
        });
    }
}
