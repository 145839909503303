import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DocumentsRoutingModule } from './documents-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'app/shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DocumentsComponent } from './documents/documents.component';
import { ManageDocumentModalComponent } from './modals/manage-document-modal/manage-document-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [DocumentsComponent, ManageDocumentModalComponent],
  imports: [
    FontAwesomeModule,
    CommonModule,
    DocumentsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    NgxDatatableModule,
    NgSelectModule,
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    NgxMaskModule.forRoot(maskConfig),
    BsDatepickerModule.forRoot(),
  ]
})
export class DocumentsModule { }
