import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../toast/toast.service';
import { AppService } from 'app/app.service';
import { forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';
import { ShowOperacionesComponent } from 'app/modules/clientes/modals/show-operaciones/show-operaciones.component';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { SharedFunctions } from 'app/core/shared/functions';
import { ParametrosService } from '../configuration/parametros.service';


export interface IFiltros {
        page : number,
        per_page : number,
        beneficiario : string, 
        oficial_negocio : string,
        responsable : string,
        estado : string, 
        estacion : string, 
        fecha__gte : string,
		fecha__lte : string,
		comite : string,
		analista_riesgo : string,
		oficial_negocio__nombre : string,
		monto__range : string,
		analista_operacion : string,
		tipo_linea_solicitada : string,
		empresas : string,
		lider : string,
		moneda : string,
		ordering : string,
		responsable_nombre : string,
		fecha_estacion__gte : string,
		fecha_estacion__lte : string,
        banco_abono: string,
        cuenta_abono: string,
}

@Injectable({
    providedIn: 'root'
})
export class FactoringService {
    private apiUrl: string = '';
    public operacionAll: string = 'factoring/operaciones/';
    public operacionDocumentos: string = 'factoring/operaciones/documentos/';
    public operacionDetalle: string = 'factoring/operaciones/detalle/';
    public clientes: string = 'factoring/operaciones/clientes/';
    public bandeja: string = 'factoring/operaciones/bandeja/';
    public bandejaTotalizada : string = 'factoring/operaciones/bandeja/total-monto/';
    public operacionesComite: string = 'factoring/operaciones/bandeja/comite';
    public derivar: string = 'factoring/operaciones/derivar/';
    public beneficiarios: string = 'factoring/beneficiarios/';
    public deudaLeasing: string = 'factoring/beneficiarios/deuda-leasing/';
    public documentosPorEstacion: string = 'factoring/documentos/estacion/';
    public comites: string = 'factoring/comites/';
    public denegarOperacionURL: string = 'factoring/operaciones/denegar/';
    public habilitarOperacionURL: string = 'factoring/operaciones/habilitar/';
    public aprobarOperacionURL: string = 'factoring/operaciones/aprobar/comite/';
    public operacionSugerenciasLista: string = 'factoring/sugerencias/responsable/';
    public operacionSugerencias: string = 'factoring/sugerencias/';
    public operacionSugerenciasBandeja: string = 'factoring/sugerencias/bandeja';
    public devolver: string = 'factoring/operaciones/devolver/';
    public reenviar: string = 'factoring/operaciones/reenviar/';
    public importarXml: string = 'factoring/operaciones/detalle/xml/';
    public detalles: string = 'factoring/operaciones/detalle/';
    public responsables: string = 'factoring/responsables/';
    public liquidacionesModificar: string = 'factoring/liquidaciones/modificarmontos/';
    public lineas: string = 'factoring/lineas/';
    public deudor: string = 'factoring/deudores/';
    public operacionResumenEndpoint: string = 'factoring/operaciones/resumen/';
    public sugerenciasPendientes: any[] = [];
    public reporte_cuentas: string = 'factoring/cuentas/reporte/'
    public reporte_cxc: string = 'factoring/cuentas/reporte_cxc/'
    public reporte_apl_excedentes: string = 'factoring/excedentes/reporte-aplicaciones/'

    private changeOperacion = new Subject<void>();
    public changeOperacionEnd$ = this.changeOperacion.asObservable();

    public monedas: string = "factoring/monedas/";
    public movimientos: string = "factoring/operaciones/movimientos/estaciones/";
    public validacionUbigeo: string = "factoring/";
    public trasCavali: string = "factoring/cavali/facturas/transferencia/";

    public reporteSabana: string = "factoring/operaciones/reporte_sabana/";
    public cavaliFacturas: string = "factoring/cavali/facturas/";
    public cavaliResultado: string = "factoring/cavali/facturas/resultado/";
    public cavaliProcesos: string = "factoring/cavali/procesos/";
    public empresas: string = "factoring/empresas/";

    public exedentesDevueltos: string = 'factoring/excedentes/reportes/devueltos/'
    public totalreporte: string = "factoring/total_report/";

    private tiposDeCambio: string = 'factoring/monedas/tiposcambio/';

    public cambioLineaXCliente: string = 'factoring/beneficiarios/lineas/historico/reporte-cambios/'
    private cuadroConsolidado: string = 'factoring/excedentes/reportes/cuadro_consolidado/'
    private creditosFactoring : string = 'factoring/excedentes/reportes/reporte_creditos_factoring'

    constructor(
        public appService: AppService,
        public modalService: NgbModal,
        public toast: ToastService,
        public spinner: NgxSpinnerService,
        public http: HttpClient,
        public auth: AuthService,
        public sharedFuncstions: SharedFunctions,
        public parametros: ParametrosService

    ) {
        this.apiUrl = this.appService.settings.API_base_url
    }


    /**
     * Habilita el loader para request a la API
     */
    spinnerOn() {
        this.spinner.show()
    }

    /**
     * Desabilita el loader
     * @param mensaje Mensaje del toast
     * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
     */
    spinnerOff(mensaje: string = null, ok: boolean = true, delay: number = 10000) {
        this.spinner.hide()
        this.appService.notifyMe(mensaje, ok)

        if (mensaje && ok)
            this.toast.success(mensaje)
        if (mensaje && !ok)
            this.toast.warning(mensaje, delay)
    }

    /**
     * Mostros errores recibidos del servidor
     * @param error Error enviado del servidor
     */
    showErrors(error) {
        const err = error.error;
        console.log(error);
        for (const key in err) {
            if (Object.prototype.hasOwnProperty.call(err, key)) {
                const element = err[key];
                console.log(element);

                if (Array.isArray(element) && element.length) {
                    element.forEach(item => {
                        this.spinnerOff(item, false);
                    });
                } else {
                    this.spinnerOff(element, false);
                }

            }
        }
    }

    eventChangeOperation() {
        this.changeOperacion.next();
    }

    /**
     * 
     * Obtiene los clientes de un geneficiario registrado
     * @param beneficiario ID del cliente registrado
     * @param page Pagina del request
     * @param page_size Resultados por pagina
     * @param oficial_negocio ID del oficial de negocio asignado 
     * @param responsable ID del responsable del proceso
     * @param estacion ID de la estacion
     * @param estado ID del filtro de estado
     * @param fecha__desde Filtro de fecha DESDE
     * @param fecha__hasta Filtro de fecha HASTA
    */
    // obtenerBandeja(
    //     page           = 1,
    //     page_size      = 10,
    //     beneficiario   : any = '',
    //     oficial_negocio: any = '',
    //     responsable    : any = '',
    //     estado         : any = '',
    //     estacion       : any = '',
    //     fecha__desde   : any = '',
    //     fecha__hasta   : any = '',
    //     comite         : any = '',
    //     analista_riesgo         : any = '',
    // ){
    //     const url = this.apiUrl+
    //         this.bandeja+
    //         `?beneficiario=${beneficiario}`+
    //         `&page=${page}`+
    //         `&page_size=${page_size}`+
    //         `&oficial_negocio=${oficial_negocio}`+
    //         `&responsable=${responsable}`+
    //         `&estado=${estado}`+
    //         `&estacion=${estacion}`+
    //         `&fecha__gte=${fecha__desde}`+
    //         `&fecha__lte=${fecha__hasta}`+
    //         `&analista_riesgo=${analista_riesgo}`+
    //         `&comite=${comite}`;

    //     return new Promise((res,ref)=>{
    //         this.spinnerOn()
    //         this.http.get(url)
    //             .subscribe((response)=>{
    //                 this.spinnerOff()
    //                 res(response)
    //             }, (err)=>{
    //                 ref(err)
    //             })
    //     })        
    // }  

     /**
     * Obtener url del servidor dependiendo del tamaño del archivo
     * @param file_size Tamaño del archivo
     * @param api_url url del servidor con api_gateway
     */
    async validarPesoAdjunto(file_size: any, api_url: any) {
        if (Number(file_size) > 10000000) {
            console.log("El archivo pesa mas de 10MB")
            const api_url_parametro = await this.parametros.obtenerParametro('api_url').toPromise();
            return api_url_parametro.valor;
        }
        return api_url;
    }

    /**
     * Obtener lista de contactos de un beneficiario
     * @param beneficiario ID del beneficiario
     */
    obtenerContactos(beneficiario: number = null) {

        const idUsuario = (beneficiario) ? '?beneficiario=' + beneficiario : '';

        const URL = this.apiUrl + 'factoring/contactos/' + idUsuario;

        return new Promise((resolve, reject) => {
            this.spinnerOn()
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, (err) => {
                this.spinnerOff()
                reject(err);
            });

        });

    }

    getOperaciones(queryParams: object = {}) {
        const url = `${this.apiUrl}factoring/operaciones/bandeja/?${this.encodeQueryParams(queryParams)}`
        return this.http.get(url).pipe(
        catchError(res => this.handleError(res))
        )
    }

    async getBeneficiarios(id: number = null) {

        const idUsuario = (id) ? id : '';

        const URL = this.apiUrl + 'factoring/beneficiarios/' + idUsuario;
        // const URL = this.apiUrl + this.clientes + `clientes/${idUsuario}/`;

        this.spinnerOn()
        return new Promise((resolve, reject) => {
            this.spinnerOff()
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                this.spinnerOff()
                reject(err);
            });

        });

    }

    async getDeudores(id: number = null) {

        const idUsuario = (id) ? id : '';

        const URL = this.apiUrl + 'factoring/deudores/' + idUsuario;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            this.spinnerOff();
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                this.spinnerOff();
                reject(err);
            });

        });

    }

    obtenerBeneficiario(beneficiarioId) {
        const URL = this.apiUrl + this.beneficiarios + `${beneficiarioId}/`;
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

    buscarBeneficiarios(ruc__icontains: any = '', page: number = 1, page_size: number = 10) {
        const URL = this.apiUrl +
            this.beneficiarios +
            `?ruc_nombre__icontains=${ruc__icontains}` +
            `&page=${page}` +
            `&page_size=${page_size}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

    beneficiarioDeudaLeasing(ruc) {
        const URL = this.apiUrl + this.deudaLeasing +`?ruc=${ruc}` ;
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

    buscarBeneficiariosObserver(ruc__icontains: any = '', page: number = 1, page_size: number = 10): Observable<any> {
        const URL = this.apiUrl +
            this.beneficiarios +
            `?ruc_nombre__icontains=${ruc__icontains}` +
            `&page=${page}` +
            `&page_size=${page_size}`;

        return this.http
            .get<any>(URL)
            .pipe(map(resp => {
                if (resp.Error) {
                    throwError(resp.Error);
                } else {
                    return resp.results;
                }
            })
            );
    }

    buscarDeudorObserver(ruc__icontains: any = '', page: number = 1, page_size: number = 10): Observable<any> {
        const URL = this.apiUrl +
            this.deudor +
            `?ruc_nombre__icontains=${ruc__icontains}` +
            `&page=${page}` +
            `&page_size=${page_size}`;

        return this.http
            .get<any>(URL)
            .pipe(map(resp => {
                if (resp.Error) {
                    throwError(resp.Error);
                } else {
                    return resp.results;
                }
            })
            );
    }



    /**
     * Guarda o actualiza una operacion, si recibe un ID envia una peticion PUT
     * @param data Objeto JSON del objeto a actualizar
     * @param id ID de la operacion a actualizar
     */
    guardarOperacion(data: any, id: any = null) {

        return new Promise((resolve, reject) => {
            let httpReques;
            let URL = this.apiUrl + 'factoring/operaciones/';
            if (!id) {
                httpReques = this.http.post(URL, data);
            } else {
                URL += `${id}/`
                httpReques = this.http.put(URL, data);
            }

            this.spinnerOn()
            httpReques.subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, (err) => {
                console.log('Ejecuto error')
                console.log(err)
                var error: any;
                if (err.error['deudor'] && err.error['deudor'].length > 0) {
                    err.error['deudor'].forEach((item) => {
                        this.spinnerOff(item, false);
                    })
                }
                else if (err.error['cliente'] && err.error['cliente'].length > 0) {
                    err.error['cliente'].forEach((item) => {
                        this.spinnerOff(item, false);
                    })
                } else if (err.error['linea disponible']) {
                    error = err.error['linea disponible']
                } else {
                    error = 'Ocurrió un error al intentar cargar el documento';
                }
                this.spinnerOff(error, false);
                reject(err);
            });

        });

    }


    /**
     * Actualiza los detalles de una operacion
     * @param id ID de la operacion que se desea actualizar
     * @param data Objeto JSON de la operacion
     */
    actualizarOperacion(data: any, id: number) {
        const URL = this.apiUrl + 'factoring/operaciones/' + id + '/';
        return new Promise((resolve, reject) => {

            const httpReques = this.http.patch(URL, data);
            this.spinnerOn()
            httpReques.subscribe(data => {
                this.spinnerOff('La operación fue grabada con exito!')
                resolve(data);
            }, (err) => {
                this.spinnerOff('Ocurrió un error durante el proceso :' + err)
                reject(err);
            });

        });

    }

    async operacion(id: number) {

        const URL = this.apiUrl + 'factoring/operaciones/' + id;

        return new Promise((resolve, reject) => {

            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });

    }

    async operacionResumen(id: number) {

        const URL = this.apiUrl + 'factoring/operaciones/resumen/' + id + '/';
        this.spinnerOn()
        return new Promise((resolve, reject) => {

            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                this.spinnerOff()
                reject(err);
            });

        });

    }

    async clienteRelaciones(id: number, page_size = 1000, page = 1) {

        const URL = this.apiUrl + 'clientes/relaciones/clientes/' + `?page_size=${page_size}&page=${page}&cliente=${id}`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {

            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                this.spinnerOff()
                reject(err);
            });

        });

    }

    /**
     * Retorna los documentos que estan asignados a casa estacion
     * @param estacion ID de la estacion cuyos documentos se quieren obtener
     * @param page Pagina que se desea visalizar
     * @param page_size Cantidad de registros por query
     */
    obtenerDocumentosEstacion(estacion: any = '', page: number = 1, page_size: number = 1000) {

        const URL = this.apiUrl +
            this.documentosPorEstacion +
            `?estacion=${estacion}` +
            `&page=${page}` +
            `&page_size=${page_size}`;

        this.spinnerOn();
        return new Promise((res, ref) => {
            this.http.get(URL)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    /**
     * Retorna los documentos adjuntos a una operacion en particular
     * @param operacion ID de la operacion cuyos documentos se quieren obtener
     */
    obtenerOperacionDocumentos(operacion: number) {
        this.spinnerOn();
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.operacionDocumentos}/?operacion=${operacion}&page=1&page_size=1000`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }


    obtenerDocumentosBeneficiarios(benefeciario: number) {
        this.spinnerOn();
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.beneficiarios}/documentos?beneficiario=${benefeciario}&page=1&page_size=1000`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    ref(err);
                    this.spinnerOff();
                })
        });
    }

    /**
     * Guarda un documento adjunto para determinado proceso de factoring
     * @param data Objeto JSON con la informacion del documento a guardar
     */
    async guardarDocumentoOperacion(data: any) {
        this.spinnerOn();
        const apiBase = await this.validarPesoAdjunto(data.get('peso'), this.apiUrl);
        data.delete('peso');
        return new Promise((res, ref) => {
            this.http.post(`${apiBase}${this.operacionDocumentos}`, data)
                .subscribe((response) => {
                    this.spinnerOff('El archivo fue adjuntado de manera exitosa');
                    res(response)
                }, (err) => {
                    let msg = 'Ocurrió un error durante la carga del archivo'
                    if (err.error && typeof (err.error) == 'object') {
                        msg = Object.values(err.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
                    }
                    this.spinnerOff(msg, false)
                    ref(err)
                })
        })
    }

    /**
     * Actualiza el objeto de un documento en particular
     * @param id ID del documento a actualizar 
     * @param data Objeto del documento a actualizar
     */
    async actualizarDocumentoOperacion(id: number, data: any, state: boolean) {
        this.spinnerOn();

        delete data.adjunto
        data.verificado = state
        return new Promise((res, ref) => {
            this.http.put(`${this.apiUrl}${this.operacionDocumentos}${id}/`, data)
                .subscribe((response) => {
                    this.spinnerOff('El archivo fue verificado de manera exitosa');
                    res(response)
                }, (err) => {
                    this.spinnerOff('Ocurrió un error durante la verificación del archivo', false);
                    ref(err)
                })
        })
    }


    /**
     * Retorna detalles y documentos adjuntos de un determinado proceso
     * @param operacion Id de operacion a obtener 
     */
    obtenerDetalles(operacion: number, page_size = 1000) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.operacionDetalle}?operacion=${operacion}&page_size=${page_size}`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    /**
     * Retorna el detalle de una operacion
     * @param detalle Id del detalle a obtener
     */
    obtenerDetalle(detalle: number) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.detalles}${detalle}/`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    /**
     * El metodo guarda un documento de proceso de factoring,
     * si recibe un ID la solicitud se ejecuta por el metodo PUT,
     * de no recibir un ID la solicitud se ejecuta por el metodo POST
     * @param detalle Objeto JSON del documento o detalle de proceso a guardar o crear
     * @param id ID opcional en caso de ser un UPDATE y no un cREATE
     */
    guardarDetalle(detalle: any, id: number = null) {
        this.spinnerOn();

        return new Promise((res, ref) => {
            let request;

            if (id) {
                // Cuando el metodo recibe un ID crea un request tipo PUt para actualizar el objeto del ID
                request = this.http.put(`${this.apiUrl}${this.operacionDetalle}${id}/`, detalle)
            } else {
                // Cuando NO recibe un ID crea un request tipo POST para crear un nuevo registro
                request = this.http.post(`${this.apiUrl}${this.operacionDetalle}`, detalle)
            }

            request
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {

                    //  if(err.error['deudor'] && err.error['deudor'].length > 0){
                    var error: any;
                    if (err.error['deudor'] && err.error['deudor'].length > 0) {
                        err.error['deudor'].forEach((item) => {
                            this.spinnerOff(item, false);
                        })
                    }
                    else if (err.error['cliente'] && err.error['cliente'].length > 0) {
                        err.error['cliente'].forEach((item) => {
                            this.spinnerOff(item, false);
                        })
                    }
                    else if (err.error['linea_disponible']) {
                        this.spinnerOff(err.error['linea_disponible'], false);
                    }
                    else if (typeof (err.error['detalle']) == 'object') {
                        this.spinnerOff(err.error['detalle']['error'], false, 3000);

                        const modalRef = this.modalService.open(ShowOperacionesComponent, {
                            size: "lg",
                        });
                        modalRef.componentInstance.operaciones = err.error['detalle']['operaciones']
                        modalRef.componentInstance.errorMessage = err.error['detalle']['error']
                        modalRef.componentInstance.infoMessage = "La factura/letra que intenta adjuntar ya se encuentra registrada en estado Pagado, Verificado o Rechazado  en las siguientes operaciones:"

                    }
                    else if (typeof (err.error['detalle']) == 'string') {
                        this.spinnerOff(err.error['detalle'], false);
                    }
                    else if (err.error['monto_total']) {
                        this.spinnerOff(err.error['monto_total'], false);
                    }
                    else if (err.error['incremento_linea']) {
                        this.spinnerOff(err.error['incremento_linea'], false);
                    }
                    else {
                        // error = 'Ocurrió un error al intentar guardar el detalle';
                        // this.spinnerOff(error, false);
                        this.spinnerOff();
                        this.showErrors(err);
                    }
                    err = null // Ya muestra errores, para no mostrar alerta duplicada en otros componenrtes se pone en null
                    ref(err);
                });
        })
    }

    /**
     * Elimina un elemento de detalle de un proceso en particular
     * @param id ID del elemento de detalle a eliminar
     */
    eliminarDetalle(id: number) {
        return new Promise((res, ref) => {
            this.http.delete(`${this.apiUrl}${this.operacionDetalle}${id}/`)
                .subscribe((response) => {
                    this.spinnerOff('Eliminó el detalle correctamente');
                    res(response)
                }, (err) => {
                    var error: any;
                    if (err.error['deudor'] && err.error['deudor'].length > 0) {
                        err.error['deudor'].forEach((item) => {
                            this.spinnerOff(item, false);
                        })
                    }
                    else if (err.error['cliente'] && err.error['cliente'].length > 0) {
                        err.error['cliente'].forEach((item) => {
                            this.spinnerOff(item, false);
                        })
                    }
                    else if (err.error['linea_disponible']) {
                        this.spinnerOff(err.error['linea_disponible'], false);
                    }
                    else if (typeof (err.error['detalle']) == 'object') {
                        this.spinnerOff(err.error['detalle']['error'], false, 3000);

                        const modalRef = this.modalService.open(ShowOperacionesComponent, {
                            size: "lg",
                        });
                        modalRef.componentInstance.operaciones = err.error['detalle']['operaciones']
                        modalRef.componentInstance.errorMessage = err.error['detalle']['error']
                        modalRef.componentInstance.infoMessage = "La factura/letra que intenta adjuntar ya se encuentra registrada en estado Pagado, Verificado o Rechazado  en las siguientes operaciones:"

                    }
                    else if (typeof (err.error['detalle']) == 'string') {
                        this.spinnerOff(err.error['detalle'], false);
                    }
                    else if (err.error['monto_total']) {
                        this.spinnerOff(err.error['monto_total'], false);
                    }
                    else {
                        error = 'Ocurrió un error al intentar eliminar el detalle';
                        this.spinnerOff(error, false);
                    }
                    ref(err)
                })
        })
    }


    /**
     * Invalida un elemento de detalle de un proceso en particular
     * @param id ID del elemento de detalle a invalidar
     */
    invalidarDetalle(id: number, estado) {
        return new Promise((res, ref) => {
            this.http.patch(`${this.apiUrl}${this.operacionDetalle}${id}/`, { invalido: estado })
                .subscribe((response) => {
                    this.spinnerOff(`Se ${estado ? 'invalidó' : 'volvió a validar'} el detalle correctamente`);
                    res(response)
                }, (err) => {
                    var error: any;
                    if (err.error['deudor'] && err.error['deudor'].length > 0) {
                        err.error['deudor'].forEach((item) => {
                            this.spinnerOff(item, false);
                        })
                    }
                    else if (err.error['cliente'] && err.error['cliente'].length > 0) {
                        err.error['cliente'].forEach((item) => {
                            this.spinnerOff(item, false);
                        })
                    }
                    else if (err.error['linea_disponible']) {
                        this.spinnerOff(err.error['linea_disponible'], false);
                    }
                    else if (typeof (err.error['detalle']) == 'object') {
                        this.spinnerOff(err.error['detalle']['error'], false, 3000);

                        const modalRef = this.modalService.open(ShowOperacionesComponent, {
                            size: "lg",
                        });
                        modalRef.componentInstance.operaciones = err.error['detalle']['operaciones']
                        modalRef.componentInstance.errorMessage = err.error['detalle']['error']
                        modalRef.componentInstance.infoMessage = "La factura/letra que intenta adjuntar ya se encuentra registrada en estado Pagado, Verificado o Rechazado  en las siguientes operaciones:"

                    }
                    else if (typeof (err.error['detalle']) == 'string') {
                        this.spinnerOff(err.error['detalle'], false);
                    }
                    else if (err.error['monto_total']) {
                        this.spinnerOff(err.error['monto_total'], false);
                    }
                    else {
                        error = 'Ocurrió un error al intentar invalidar el detalle';
                        this.spinnerOff(error, false);
                    }
                    ref(err)


                })
        })
    }

    /**
     * 
     * @param data 
     */
    async importarXmlDetalles(data) {
        console.log(data)
        this.spinnerOn();
        const apiBase = this.apiUrl
        const apiBaseMayor10 = await this.validarPesoAdjunto(20000000, this.apiUrl)
        // data.delete('peso');
        const url = `${apiBase}${this.importarXml}`;

        return new Promise((res, ref) => {

            let arrayOfData = [];
            data.forEach(element => {
                let adjunto = element.get('adjunto')
                console.log(adjunto.size)
                arrayOfData.push(this.http.post(url, element));
            });

            forkJoin(arrayOfData).subscribe(response => {
                this.spinnerOff('El archivo fue adjuntado de manera exitosa');
                res(response);
            }, err => {
                var error: any;
                if (err.error['deudor'] && err.error['deudor'].length > 0) {
                    err.error['deudor'].forEach((item) => {
                        this.spinnerOff(item, false, 3000);
                    })
                }
                else if (err.error['cliente'] && err.error['cliente'].length > 0) {
                    err.error['cliente'].forEach((item) => {
                        this.spinnerOff(item, false, 3000);
                    })
                } else if (err.error['linea disponible']) {
                    this.spinnerOff(err.error['linea disponible'], false, 3000);
                }
                else if (typeof (err.error['detalle']) == 'string') {
                    this.spinnerOff(err.error['detalle'], false);
                }
                else if (typeof (err.error['detalle']) == 'object') {
                    this.spinnerOff(err.error['detalle']['error'], false, 3000);

                    const modalRef = this.modalService.open(ShowOperacionesComponent, {
                        size: "lg",
                    });
                    modalRef.componentInstance.operaciones = err.error['detalle']['operaciones']
                    modalRef.componentInstance.errorMessage = err.error['detalle']['error']
                    modalRef.componentInstance.infoMessage = "La factura/letra que intenta adjuntar ya se encuentra registrada en estado Pagado, Verificado o Rechazado  en las siguientes operaciones:"

                }
                else {
                    error = `Ocurrió un error al intentar cargar el archivo XML.`;
                    if (err.error && typeof (err.error) == 'object') {
                        error = Object.values(err.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
                    }
                    this.spinnerOff(error, false);
                }
                ref(err);
            });

        });
    }

    /**
     * 
     * @param detalleId 
     * @param data 
     */
    async importarPDF(detalleId, data) {
        this.spinnerOn();
        const apiBase = await this.validarPesoAdjunto(data.get('peso'), this.apiUrl)
        data.delete('peso');
        return new Promise((res, ref) => {
            this.http.patch(`${apiBase}${this.detalles}${detalleId}/`, data)
                .subscribe((response) => {
                    this.spinnerOff('El archivo fue adjuntado de manera exitosa');
                    res(response);
                }, (err) => {
                    var error: any;
                    if (err.error['deudor'] && err.error['deudor'].length > 0) {
                        err.error['deudor'].forEach((item) => {
                            this.spinnerOff(item, false, 3000);
                        })
                    }
                    else if (err.error['cliente'] && err.error['cliente'].length > 0) {
                        err.error['cliente'].forEach((item) => {
                            this.spinnerOff(item, false, 3000);
                        })
                    } else if (err.error['linea disponible']) {
                        this.spinnerOff(err.error['linea disponible'], false, 3000);
                    }
                    else if (typeof (err.error['detalle']) == 'string') {
                        this.spinnerOff(err.error['detalle'], false);
                    }
                    else if (typeof (err.error['detalle']) == 'object') {
                        this.spinnerOff(err.error['detalle']['error'], false, 3000);

                        const modalRef = this.modalService.open(ShowOperacionesComponent, {
                            size: "lg",
                        });
                        modalRef.componentInstance.operaciones = err.error['detalle']['operaciones']
                        modalRef.componentInstance.errorMessage = err.error['detalle']['error']
                        modalRef.componentInstance.infoMessage = "La factura/letra que intenta adjuntar ya se encuentra registrada en estado Pagado, Verificado o Rechazado  en las siguientes operaciones:"

                    }
                    else {
                        error = 'Ocurrió un error, el formato del documento no es el adecuado';
                        if (err.error && typeof (err.error) == 'object') {
                            error = Object.values(err.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
                        }
                        this.spinnerOff(error, false);
                    }
                    ref(err);
                })
        });
    }

    /**
     * Deriva una operacion a la próxima estacion
     * @param id ID de la operacion a derivar
     */
    derivarOperacion(id: number, queryParams: object = {}) {
        let url = `${this.apiUrl}${this.derivar}${id}/`;
        let queryParamsStr = Object.entries(queryParams).map(e => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&');

        if (queryParamsStr) {
            url += `?${queryParamsStr}`;
        }

        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.put(url, null)
                .subscribe((response) => {
                    this.spinnerOff('La operación fue derivada correctamente');
                    res(response)
                }, (err) => {
                    this.spinnerOff(err.error.detail, false);
                    ref(err)
                })
        })
    }

    /**
     * Deriva una operacion a la próxima estacion
     * @param id ID de la operacion a derivar
     */
    validardocumentosVencidos(id: number) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.post(`${this.apiUrl}factoring/operaciones/documentos/vencidos/`, {
                "operacion": id
            })
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff(err.error.detail, false);
                    ref(err)
                })
        })
    }

    /**
     * Devuelve la operacion a la estacion especificada
     * @param id ID de la operacion a devolver
     * @param data estacion a la que se desea devolver.
     */
    devolverOperacion(id: number, data) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.patch(`${this.apiUrl}${this.devolver}${id}/`, data)
                .subscribe((response) => {
                    this.spinnerOff('La operación fue devuelta correctamente');
                    res(response)
                }, (err) => {
                    let error = 'Ocurrió un error inesperado ' + err
                    if (err.error && typeof(err.error) == 'object') {
                        error = Object.entries(err.error).map(e => `${e[0]}: ${e[1]}`).join('\n');
                    }
                    this.spinnerOff(error, false);
                    ref(err)
                })
        });
    }

    /**
     * Reenvia la operacion a la estacion a la que fue devuelta
     * @param id ID de la operacion a devolver
     * @param data estacion a la que se desea reenviar la operacion
     */
    reenviarOperacion(id: number, data) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.patch(`${this.apiUrl}${this.reenviar}${id}/`, data)
                .subscribe((response) => {
                    this.spinnerOff('Se la operación fue reenviada correctamente');
                    res(response)
                }, (err) => {
                    this.spinnerOff(err.error.detail, false);
                    ref(err)
                })
        });
    }

    /**
     * Obtiene los comites activos
     * @param page Pagina a obtener
     * @param page_size Cantidad de resutlados por query
     */
    obtenerComites(page = 1, page_size = 100) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.comites}`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }


    /**
     * Metodo para denegar una operacion
     */
    denegarOperacion(operacion) {
        return new Promise((res, ref) => {
            this.http.put(`${this.apiUrl}${this.denegarOperacionURL}${operacion.id}/`, null)
                .subscribe((response) => {
                    this.spinnerOff('Se la operación fue denegada correctamente');
                    res(response)
                }, (err) => {
                    this.spinnerOff(err.error.detail, false);
                    ref(err)
                })
        })
    }


    habilitarOperacion(operacion) {
        return new Promise((res, ref) => {
            this.http.put(`${this.apiUrl}${this.habilitarOperacionURL}${operacion.id}/`, null)
                .subscribe((response) => {
                    this.spinnerOff('Se la operación fue habilitada correctamente');
                    res(response)
                }, (err) => {
                    this.spinnerOff(err.error.detail, false);
                    ref(err)
                })
        })
    }


    /**
     * Metodo para denegar una operacion
     */
    aprobarOperacion(operacion) {
        this.spinnerOn();
        return new Promise((res, ref) => {
            this.http.post(`${this.apiUrl}${this.aprobarOperacionURL}`, {
                operacion: operacion.id,
                responsable: this.auth.user.id
            })
                .subscribe((response) => {
                    this.spinnerOff('Se la operación fue aprobada correctamente');
                    res(response)
                }, (err) => {
                    this.spinnerOff(err.error.detail, false);
                    ref(err);
                })
        })
    }

    /**
     * Retorna los usuarios disponibles para solicitar sugerencias
     */
    listadoUsuariosSugerencias(operacion) {

        const URL = this.apiUrl +
            this.operacionSugerenciasLista +
            `?operacion=${operacion}&page_size=10000`;

        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(URL)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }


    /**
 * Retorna los usuarios disponibles para solicitar sugerencias
 */
    crearSolicitudsugerencia(operacion, idUsario) {

        const URL = this.apiUrl +
            this.operacionSugerencias;

        return new Promise((res, ref) => {
            this.http.post(URL, {
                operacion: operacion,
                responsable: idUsario,
            })
                .subscribe((response) => {
                    res(response)
                }, (err) => {
                    ref(err)
                })
        })
    }


    bandejaDeSugerencias(idUser, page_size = 1000) {
        const URL = this.apiUrl +

            this.operacionSugerenciasBandeja +
            `?responsable=${idUser}` +
            `&page_size=${page_size}`;

        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(URL)
                .subscribe((response: any) => {
                    this.spinnerOff();
                    console.log(response['results']);
                    this.sugerenciasPendientes = response['results'];
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    obtenerResponsables(rol, estado = 'true', page = 1, page_size = 1000) {
        const URL = this.apiUrl +

            this.responsables +
            `?rol=${rol}` +
            `&page=${page}` +
            `&estado=${estado}` +
            `&page_size=${page_size}`;

        this.spinnerOn()
        return new Promise((res, ref) => {
            return this.http.get(URL)
                .subscribe((response: any) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    /**
     * Retorna detalles y documentos adjuntos de un determinado proceso
     * @param operacion Id de operacion a obtener 
     */
    obtenerOperacion(operacion: number) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.operacionAll}${operacion}/`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    /**
     * Retorna detalles y documentos adjuntos de un determinado proceso
     * @param operacion Id de operacion a obtener 
     */
    obtenerOperacionBandejaUnico(operacion: number) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.get(`${this.apiUrl}${this.bandeja}${operacion}/`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    /**
     * Retorna detalles y documentos adjuntos de un determinado proceso
     * @param operacion Id de operacion a obtener 
     */
    eliminarOperacion(operacion: number) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.delete(`${this.apiUrl}${this.operacionAll}${operacion}/`)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    modificarDatosLiquidacion(liquidacion: number, body) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.put(`${this.apiUrl}${this.liquidacionesModificar}${liquidacion}/`, body)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }


    /**
     * 
     * Obtiene los clientes de un geneficiario registrado
     * @param beneficiario ID del cliente registrado
     * @param page Pagina del request
     * @param page_size Resultados por pagina
     * @param oficial_negocio ID del oficial de negocio asignado 
     * @param responsable ID del responsable del proceso
     * @param estacion ID de la estacion
     * @param estado ID del filtro de estado
     * @param fecha__desde Filtro de fecha DESDE
     * @param fecha__hasta Filtro de fecha HASTA
    */
    obtenerBandeja(
        page = 1,
        page_size = 10,
        beneficiario: any = '',
        oficial_negocio: any = '',
        responsable: any = '',
        estado: any = '',
        estacion: any = '',
        fecha__desde: any = '',
        fecha__hasta: any = '',
        comite: any = '',
        analista_riesgo: any = '',
        oficial_negocio__nombre: any = '',
        monto__range: any = '',
        analista_operacion: any = '',
        tipo_linea_solicitada: any = '',
        empresa: string = '',
        idlider:string = '',
        moneda:string = '',
        ordering:string = '',
        responsable_nombre:string = '',
        fecha_estacion__desde: any = '',
        fecha_estacion__hasta: any = '',
        banco_abono:string = '',
        cuenta_abono:string = ''
    ) {
        const url = this.apiUrl +
            this.bandeja +
            `?beneficiario=${beneficiario}` +
            `&page=${page}` +
            `&page_size=${page_size}` +
            `&oficial_negocio=${oficial_negocio}` +
            `&responsable=${responsable}` +
            `&estado=${estado}` +
            `&estacion=${estacion}` +
            `&fecha__gte=${fecha__desde}` +
            `&fecha__lte=${fecha__hasta}` +
            `&analista_riesgo=${analista_riesgo}` +
            `&comite=${comite}` +
            `&oficial_negocio__nombre=${oficial_negocio__nombre}` +
            `&analista_operacion=${analista_operacion}` +
            `&monto__range=${monto__range}` +
            `&tipo_linea_solicitada=${tipo_linea_solicitada}` +
            `&responsable__icontains=${responsable_nombre}` +
            `&empresa=${empresa}` +
            `&lider=${idlider}`+
            `&moneda=${moneda}`+
            `&banco_abono__nombre__icontains=${banco_abono}`+
            `&nro_cuenta__icontains=${cuenta_abono}`+
            `&ordering=${ordering}`+
            `&fecha_estacion__gte=${fecha_estacion__desde}` +
            `&fecha_estacion__lte=${fecha_estacion__hasta}`;

        console.log("URL Dispatched: " +url + " ");
        
        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }
    

    obtenerBandejaAlt(filtros : IFiltros, totalized : boolean = false, ) {
        const url = this.apiUrl + (!totalized ? this.bandeja : this.bandejaTotalizada) +
            `?beneficiario=${filtros.beneficiario}` +
            `&page=${filtros.page}` +
            `&page_size=${filtros.per_page}` +
            `&oficial_negocio=${filtros.oficial_negocio}` +
            `&responsable=${filtros.responsable}` +
            `&estado=${filtros.estado}` +
            `&estacion=${filtros.estacion}` +
            `&fecha__gte=${filtros.fecha__gte}` +
            `&fecha__lte=${filtros.fecha__lte}` +
            `&analista_riesgo=${filtros.analista_riesgo}` +
            `&comite=${filtros.comite}` +
            `&oficial_negocio__nombre=${filtros.oficial_negocio__nombre}` +
            `&analista_operacion=${filtros.analista_operacion}` +
            `&monto__range=${filtros.monto__range}` +
            `&tipo_linea_solicitada=${filtros.tipo_linea_solicitada}` +
            `&responsable__icontains=${filtros.responsable_nombre}` +
            `&empresa=${filtros.empresas}` +
            `&lider=${filtros.lider}`+
            `&moneda=${filtros.moneda}`+
            `&ordering=${filtros.ordering}`+
            `&banco_abono=${filtros.banco_abono}`+
            `&nro_cuenta__icontains=${filtros.cuenta_abono}`+
            `&fecha_estacion__gte=${filtros.fecha_estacion__gte}` +
            `&fecha_estacion__lte=${filtros.fecha_estacion__lte}`;
     
        console.log("URL Dispatched: " +url + " ");
        
        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                    .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    obtenerOperacionBandeja(operacion: number) {
        const url = this.apiUrl + this.bandeja + `${operacion}`;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    this.showErrors(err);
                    ref(err);
                })
        });
    }

    /**
 * 
 * Obtiene los clientes de un geneficiario registrado
 * @param beneficiario ID del cliente registrado
 * @param page Pagina del request
 * @param page_size Resultados por pagina
 * @param oficial_negocio ID del oficial de negocio asignado 
 * @param responsable ID del responsable del proceso
 * @param estacion ID de la estacion
 * @param estado ID del filtro de estado
 * @param fecha__desde Filtro de fecha DESDE
 * @param fecha__hasta Filtro de fecha HASTA
*/
    bandejaComite(
        page = 1,
        page_size = 10,
        beneficiario: any = '',
        oficial_negocio: any = '',
        responsable: any = '',
        estado: any = '',
        estacion: any = '',
        fecha__desde: any = '',
        fecha__hasta: any = '',
        comite: any = '',
        analista_riesgo: any = '',
        oficial_negocio__nombre: any = '',
        monto__range: any = '',
        responsable_comite: any = '',
        responsable_comite__nombre: any = '',
        empresa: string = ''
    ) {
        const url = this.apiUrl +
            this.operacionesComite +
            `?beneficiario=${beneficiario}` +
            `&page=${page}` +
            `&page_size=${page_size}` +
            `&oficial_negocio=${oficial_negocio}` +
            `&responsable=${responsable}` +
            `&estado=${estado}` +
            `&estacion=${estacion}` +
            `&fecha__gte=${fecha__desde}` +
            `&fecha__lte=${fecha__hasta}` +
            `&analista_riesgo=${analista_riesgo}` +
            `&monto__range=${monto__range}` +
            `&oficial_negocio__nombre=${oficial_negocio__nombre}` +
            `&responsable_comite__nombre=${responsable_comite__nombre}` +
            `&responsable_comite_=${responsable_comite}` +
            `&comite=${comite}` +
            `&empresa=${empresa}`;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })
    }

    obtenerOperacionCmite(operacion: number) {
        const url = this.apiUrl + this.operacionesComite + `/${operacion}`;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response)
                }, (err) => {
                    this.spinnerOff();
                    this.showErrors(err);
                })
        });
    }

    obtenerTiposDeLineas() {

        const url = this.apiUrl + this.lineas;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })

    }

    /**
     * Obtener las lineas de credito del beneficiario
     * @param beneficiarioId 
     */
    obtenerLineas(
        beneficiarioId,
        tipo = ''
    ) {

        const url = this.apiUrl + this.beneficiarios + 'lineas/' +
            `?beneficiario=${beneficiarioId}` +
            `&tipo=${tipo}`;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        })

    }

    actualizarLinea(data, lineaId) {
        const url = this.apiUrl + this.beneficiarios + `lineas/${lineaId}/`

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.put(url, data)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        });
    }

    obtenerCuentas(beneficiarioId) {
        const url = this.apiUrl + this.beneficiarios + 'cuentasabono/' +
            `?beneficiario=${beneficiarioId}`;

        return new Promise((res, ref) => {
            this.spinnerOn()
            this.http.get(url)
                .subscribe((response) => {
                    this.spinnerOff();
                    res(response);
                }, (err) => {
                    this.spinnerOff();
                    ref(err);
                })
        });
    }

    /**
    * @param page Pagina del request
    * @param page_size Resultados por pagina
    */
    obtenerMonedas() {
        const url = this.apiUrl + this.monedas;

        return new Promise((res, ref) => {
            this.spinnerOn();
            this.http.get(url).subscribe(
                (response) => {
                    this.spinnerOff();
                    res(response);
                },
                (err) => {
                    this.spinnerOff();
                    ref(err);
                }
            );
        });
    }

    actualizarPropiedadOperacion(operacion: number, body) {
        this.spinnerOn()
        return new Promise((res, ref) => {
            this.http.patch(`${this.apiUrl}${this.operacionResumenEndpoint}${operacion}/`, body)
                .subscribe((response) => {
                    this.spinnerOff('Operación realizada con éxito');
                    res(response);
                }, (err) => {
                    this.spinnerOff('La Operación falló');
                    ref(err);
                })
        })
    }

    async updateResumenProps(id: number, body) {

        const URL = this.apiUrl + 'factoring/operaciones/resumen/' + id + '/';
        this.spinnerOn()
        return new Promise((resolve, reject) => {

            const httpRequest = this.http.patch(URL, body);
            httpRequest.subscribe(data => {
                this.spinnerOff('Operación actualizada')
                resolve(data);
            }, (err) => {
                this.spinnerOff()
                reject(err);
            });

        });

    }

    movimientosOperacion(operacionId: number, page: number = 1, page_size: number = 10) {
        const URL = this.apiUrl + this.movimientos + `${operacionId}/` +
            `?page=${page}` +
            `&page_size=${page_size}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

    validaUbigeoDetalle(operacionId: number) {
        const URL = this.apiUrl + `factoring/operaciones/${operacionId}/validar_ubigeos_aceptantes/`;

        return new Promise((resolve, reject) => {
            this.spinnerOn()
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                
                reject(err);
            });

        });
    }

    cambiaEstadoRevisado(data) {
        const url = this.apiUrl + this.bandeja + `${data.id}/`;

        return new Promise((resolve, reject) => {
            this.spinnerOn()
            const httpRequest = this.http.patch(url, { revisado: data.revisado });
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                this.spinnerOff(err.error.detail, false);
                reject(err);
            });

        });
    }


    buscarDeudorPorRuc(ruc: any = '', page: number = 1, page_size: number = 1000) {
        const URL = this.apiUrl +
            this.deudor +
            `?ruc_nombre__icontains=${ruc}` +
            `&page=${page}` +
            `&page_size=${page_size}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });

        });
    }

    trasnferenciaCavali(data) {
        const url = this.apiUrl + this.trasCavali;
        return new Promise((res, ref) => {
            this.spinnerOn();
            this.http.post(url, data).subscribe(
                (response) => {
                    this.spinnerOff('Transferencia a CAVALI realizada exitosamente!');
                    res(response);
                },
                (err) => {
                    this.spinnerOff();
                    ref(err);
                }
            );
        });
    }

    reprogramacionCavali(data) {
        const url = `${this.apiUrl}factoring/cavali/facturas/reprogramacion/`
        return this.http.post(url, data)
    }

    downloadFile(url) {
        return new Promise((resolve, reject) => {
            let httpReques;
            // let URL = this.apiUrl + this.formato_documentos + "solicitud_final/";
            const httpOptions = {
                responseType: 'blob' as 'json'
            };
            httpReques = this.http.get(url, httpOptions);
            this.spinnerOn();
            httpReques.subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, async (err) => {
                this.spinnerOff();
                console.log('Error al  generar contrato');
                reject(err);
            });

        });
    }

    obtenerEmpresas(page: number = 1, page_size: number = 1000) {

        const URL = this.apiUrl + this.empresas +
            `?page=${page}` +
            `&page_size=${page_size}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });

        // return [{ // A futuro se podria crear una ruta si es necesario
        //     id: 1,
        //     nombre: "Factoring Total"
        // }, {
        //     id: 2,
        //     nombre: "Total Servicios"
        // }];
    }

    obtenerEmpresasCodigo(page: number = 1, page_size: number = 1000) {

        const URL = this.apiUrl + 'factoring/recaudaciones/cuentas/empresas/' +
            `?page=${page}` +
            `&page_size=${page_size}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });

        // return [{ // A futuro se podria crear una ruta si es necesario
        //     id: 1,
        //     nombre: "Factoring Total"
        // }, {
        //     id: 2,
        //     nombre: "Total Servicios"
        // }];
    }    

    getReporteCXC(
        nro_operacion = undefined,
        cliente_nombre = undefined,
        cliente_ruc = undefined,
        deudor_nombre = undefined,
        deudor_ruc = undefined,
        tipo_pagador = undefined,
        estado = undefined,
        id = undefined,
        linea = undefined,
        page = 1,
        page_size = 10,
        showMask = true
        ) {


        const URL = this.apiUrl +
            this.reporte_cuentas +
            `?page=${page}` +
            `&page_size=${page_size}` +
            this.filtroNoObligatorio('nro_operacion', nro_operacion) +
            this.filtroNoObligatorio('cliente_nombre', cliente_nombre) +
            this.filtroNoObligatorio('cliente_ruc', cliente_ruc) +
            this.filtroNoObligatorio('deudor_nombre', deudor_nombre) +
            this.filtroNoObligatorio('deudor_ruc', deudor_ruc) +
            this.filtroNoObligatorio('tipo_pagador', tipo_pagador) +
            this.filtroNoObligatorio('estado', estado) +
            this.filtroNoObligatorio('id', id) +
            this.filtroNoObligatorio('tipo_producto', linea)
            ;

        if (showMask) {
            this.spinnerOn();
        }
        return new Promise((resolve, reject) => {
            this.http.get(URL).subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, async (err) => {
                this.spinnerOff('Error al generar reporte', false);
                reject(err);
            });
        })
    }

    getReporteExcedentesDevolver(
        nro_operacion  = undefined,
        nro_recaudacion = undefined,
        fecha__gte    = undefined,
        fecha__lte    = undefined,
        cliente_nombre__icontains = undefined,
        cliente_ruc__icontains    = undefined,
        deudor_nombre__icontains  = undefined,
        deudor_ruc__icontains     = undefined,
        tipo_pagador   = undefined,
        estado         = undefined,
        id             = undefined,
        page           = 1,
        page_size      = 10,
        ) {


        const URL = this.apiUrl +
            'factoring/excedentes/por_devolver/' +
            `?page=${page}` +
            `&page_size=${page_size}` +
            this.filtroNoObligatorio('nro_operacion', nro_operacion) +
            this.filtroNoObligatorio('nro_recaudacion',nro_recaudacion) +
            this.filtroNoObligatorio('fecha__gte', fecha__gte) +
            this.filtroNoObligatorio('fecha__lte', fecha__lte) +
            this.filtroNoObligatorio('cliente_nombre__icontains', cliente_nombre__icontains) +
            this.filtroNoObligatorio('cliente_ruc__icontains', cliente_ruc__icontains) +
            this.filtroNoObligatorio('deudor_nombre__icontains', deudor_nombre__icontains) +
            this.filtroNoObligatorio('deudor_ruc__icontains', deudor_ruc__icontains) +
            this.filtroNoObligatorio('tipo_pagador', tipo_pagador) +
            this.filtroNoObligatorio('estado', estado) +
            this.filtroNoObligatorio('id', id);

        this.spinnerOn();
        return new Promise((resolve, reject) => {
            this.http.get(URL).subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, async (err) => {
                this.spinnerOff('Error al generar reporte', false);
                reject(err);
            });
        })
    }

    obtenerReporte(id,page: number = 1,page_size: number = 10) {
        const url = this.apiUrl + `factoring/total_report/` + `${id}/?page=${page}&page_size=${page_size}`;
    
        return new Promise((res, ref) => {
          this.spinnerOn();
    
          this.http.get(url).subscribe(
            (response) => {
              this.spinnerOff();
              res(response);
            },
            (err) => {
              this.spinnerOff("La operación falló", false);
              ref(err);
            }
          );
        });
      }

      guardarTotalReporte(data, detalleId=0) {
        const url = detalleId
          ? this.apiUrl + `factoring/total_report/` + `${detalleId}/`
          : this.apiUrl + `factoring/total_report/`;
        return new Promise((res, ref) => {
          this.spinnerOn();
            console.log(data.rol);
          if (detalleId) {
            //patch: ES ACTUALIZAR
            console.log("infoUpdate",url,data)
            this.http.patch(url, data).subscribe(
              (response) => {
                this.spinnerOff("La información fue actualizada exitosamente.");
                res(response);
              },
              (err) => {
                this.spinnerOff("La operación falló", false);
                ref(err);
              }
            );
          } else {
            //post: ES CREAR
            this.http.post(url, data).subscribe(
              (response) => {
                this.spinnerOff("La información fue registrada exitosamente.");
                res(response);
              },
              (err) => {
                if(err && err.error && err.error.descripcion[0]){
                  this.spinnerOff(err.error.descripcion[0], false);
                } else {
                  this.spinnerOff("La operación falló", false);
                }
                ref(err);
              }
            );
          }
        });
      }

    /**
   * 
   * @param page 
   * @param page_size
   */
    obtenerReporteTotal(
        page: number = 1,
        page_size: number = 10,
        filtro: any
      ) {
          const url = this.apiUrl + `factoring/total_report/?page=${page}&page_size=${page_size}`+
          this.filtroNoObligatorio('nombre__icontains',filtro.nombre);
    
          return new Promise((res, ref) => {
            this.spinnerOn();
      
            this.http.get(url).subscribe(
              (response) => {
                this.spinnerOff();
                res(response);
              },
              (err) => {
                this.spinnerOff("La petición falló", false);
                ref(err);
              }
            );
          });
      }

    eliminaDetalle(detalleId) {
        const url = this.apiUrl + `factoring/total_report/` + `${detalleId}/`;
    
        return new Promise((res, ref) => {
          this.spinnerOn();
    
          this.http.delete(url).subscribe(
            (response) => {
              this.spinnerOff("Reporte Eliminado");
              res(response);
            },
            (err) => {
              this.spinnerOff("La petición falló", false);
              ref(err);
            }
          );
        });
      }

    getReporteCartera(
        oficial_negocio_id          = undefined, 
        asistente_cobranza          = undefined, 
        cliente_nombre              = undefined, 
        deudor_nombre               = undefined, 
        operacion_id                = undefined, 
        producto_id                 = undefined, 
        tipo_documento_id           = undefined, 
        fecha_desembolso_desde      = undefined, 
        fecha_desembolso_hasta      = undefined, 
        estado_id                   = undefined, 
        fecha_vencimiento_desde     = undefined, 
        fecha_vencimiento_hasta     = undefined, 
        fecha_pago_desde            = undefined, 
        fecha_pago_hasta            = undefined, 
        forma_pago_id               = undefined, 
        tipo_pagador_id             = undefined, 
        fecha_compromiso_pago_desde = undefined, 
        fecha_compromiso_pago_hasta = undefined, 
        empresa                     = undefined,
        numero_documento            = undefined,
        cliente_ruc                 = undefined,
        deudor_ruc                  = undefined,

        fecha_protestado_desde      = undefined,
        fecha_protestado_hasta      = undefined,
        fecha_prorrogado_desde      = undefined,
        fecha_prorrogado_hasta      = undefined,
        fecha_cobrado_desde         = undefined,
        fecha_cobrado_hasta         = undefined,
        fecha_mora_desde            = undefined,
        fecha_mora_hasta            = undefined,

        page                        = 1,
        page_size                   = 10,
        ) {
        const URL = this.apiUrl +
            'factoring/carteras/reporte_cartera/' +
            `?page=${page}` +
            `&page_size=${page_size}` +
            this.filtroNoObligatorio('oficial_negocio_id', oficial_negocio_id) +
            this.filtroNoObligatorio('asistente_cobranza', asistente_cobranza) +
            this.filtroNoObligatorio('cliente_nombre', cliente_nombre) +
            this.filtroNoObligatorio('deudor_nombre', deudor_nombre) +
            this.filtroNoObligatorio('operacion_id', operacion_id) +
            this.filtroNoObligatorio('producto_id', producto_id) +
            this.filtroNoObligatorio('fecha_desembolso_desde', fecha_desembolso_desde) +
            this.filtroNoObligatorio('fecha_desembolso_hasta', fecha_desembolso_hasta) +
            this.filtroNoObligatorio('estado_id', estado_id) +
            this.filtroNoObligatorio('fecha_vencimiento_desde', fecha_vencimiento_desde) +
            this.filtroNoObligatorio('fecha_vencimiento_hasta', fecha_vencimiento_hasta) +
            this.filtroNoObligatorio('fecha_pago_desde', fecha_pago_desde) +
            this.filtroNoObligatorio('fecha_pago_hasta', fecha_pago_hasta) +
            this.filtroNoObligatorio('forma_pago_id', forma_pago_id) +
            this.filtroNoObligatorio('tipo_pagador_id', tipo_pagador_id) +
            this.filtroNoObligatorio('fecha_compromiso_pago_desde', fecha_compromiso_pago_desde) +
            this.filtroNoObligatorio('fecha_compromiso_pago_hasta', fecha_compromiso_pago_hasta) +
            this.filtroNoObligatorio('numero_documento', numero_documento) +
            this.filtroNoObligatorio('cliente_ruc', cliente_ruc) +
            this.filtroNoObligatorio('deudor_ruc', deudor_ruc) +
            this.filtroNoObligatorio('fecha_protestado_desde', fecha_protestado_desde) +
            this.filtroNoObligatorio('fecha_protestado_hasta', fecha_protestado_hasta) +
            this.filtroNoObligatorio('fecha_prorrogado_desde', fecha_prorrogado_desde) +
            this.filtroNoObligatorio('fecha_prorrogado_hasta', fecha_prorrogado_hasta) +
            this.filtroNoObligatorio('fecha_cobrado_desde', fecha_cobrado_desde) +
            this.filtroNoObligatorio('fecha_cobrado_hasta', fecha_cobrado_hasta) +
            this.filtroNoObligatorio('fecha_mora_desde', fecha_mora_desde) +
            this.filtroNoObligatorio('fecha_mora_hasta', fecha_mora_hasta) +
            this.filtroNoObligatorio('empresa', empresa);
        return new Promise((resolve, reject) => {
            this.spinnerOn();
            this.http.get(URL).subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, async (err) => {
                this.spinnerOff();
                reject(err);
            });
        })
    }

    generarExcelReporteCXC() {

        const URL = this.apiUrl +
            this.reporte_cuentas +
            'generar_archivo/' +
            `?page=1` +
            `&page_size=1000000`;

        this.spinnerOn();
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
                this.spinnerOff("Generando reporte...");
            }, (err) => {
                reject(err);
                this.spinnerOff("Error al generar el reporte.", false);
            });
        });
    }

    downloadExcelReporteCXC(archivo = undefined): any {

        const URL = this.apiUrl + this.reporte_cuentas + 'descargar_archivo/';

        return new Promise((resolve, reject) => {
            let httpReques;
            const httpOptions = {
                responseType: 'blob' as 'json'
            };
            httpReques = this.http.get(URL, httpOptions);
            httpReques.subscribe(data => {
                resolve(data);
            }, (err) => {
                console.log('Error al generar el reporte');
                reject(err);
            });

        });
    }


    getReporteAplicacionesExcedentes(
        cliente_nombre = undefined,
        cliente_ruc = undefined,
        aceptante_nombre = undefined,
        aceptante_ruc = undefined,
        page = 1,
        page_size = 10) {


        const URL = this.apiUrl +
            this.reporte_apl_excedentes +
            `?page=${page}` +
            `&page_size=${page_size}` +
            this.filtroNoObligatorio('cliente_nombre', cliente_nombre) +
            this.filtroNoObligatorio('cliente_ruc', cliente_ruc) +
            this.filtroNoObligatorio('aceptante_nombre', aceptante_nombre) +
            this.filtroNoObligatorio('aceptante_ruc', aceptante_ruc);

        this.spinnerOn();
        return new Promise((resolve, reject) => {
            this.http.get(URL).subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, async (err) => {
                this.spinnerOff('Error al generar reporte', false);
                reject(err);
            });
        })
    }

    getReporteSabana(
        page: number = 1,
        page_size: number = 10,
        cliente_razon_social = undefined,
        cliente_ruc = undefined,
        aceptante_razon_social = undefined,
        aceptante_ruc = undefined,
        nro_operacion = undefined,
        fecha_desembolso_desde = undefined,
        fecha_desembolso_hasta = undefined,
        fecha_vencimiento_nominal_desde = undefined,
        fecha_vencimiento_nominal_hasta = undefined,
        tipo_pagador = undefined,
        oficial_negocio = undefined,
        estados = undefined,
        empresa = undefined,
        tipo_operacion = undefined,
        showMask = true
    ) {
        const url = this.apiUrl +
            this.reporteSabana +
            `?page=${page}` +
            `&page_size=${page_size}` +
            this.filtroNoObligatorio('cliente_razon_social', cliente_razon_social) +
            this.filtroNoObligatorio('cliente_ruc', cliente_ruc) +
            this.filtroNoObligatorio('aceptante_razon_social', aceptante_razon_social) +
            this.filtroNoObligatorio('aceptante_ruc', aceptante_ruc) +
            this.filtroNoObligatorio('operacion', nro_operacion) +
            this.filtroNoObligatorio('fecha_desembolso_desde', fecha_desembolso_desde) +
            this.filtroNoObligatorio('fecha_desembolso_hasta', fecha_desembolso_hasta) +
            this.filtroNoObligatorio('fecha_vencimiento_nominal_desde', fecha_vencimiento_nominal_desde) +
            this.filtroNoObligatorio('fecha_vencimiento_nominal_hasta', fecha_vencimiento_nominal_hasta) +
            this.filtroNoObligatorio('tipo_pagador', tipo_pagador) +
            this.filtroNoObligatorio('oficial_negocio', oficial_negocio) +
            this.filtroNoObligatorio('estados', estados) +
            this.filtroNoObligatorio('empresa', empresa) +
            this.filtroNoObligatorio('tipo_operacion', tipo_operacion);
        return new Promise((res, ref) => {
            if (showMask) {
                this.spinnerOn();
            }
            this.http.get(url).subscribe(
                (response) => {
                    this.spinnerOff();
                    res(response);
                },
                (err) => {
                    this.spinnerOff('Ocurrio un error', false);
                    ref(err);
                }
            );
        });
    }


    getCavaliResultados(
        page: number = 1,
        page_size: number = 1000,
        operacion_detalle: number = undefined,
        invoices__operacion_detalle__isnull: boolean = undefined,
        invoices__series: string = undefined,
        invoices__numeration: number = undefined,
        invoices__procesado: string = undefined,
        id_process: number = undefined,
        proceso: string = undefined,
        error: string = undefined,
        operacion=null,
    ) {
        const URL = this.apiUrl +
            this.cavaliResultado +
            `?page=${page}` +
            `&page_size=${page_size}` +
            `&operacion_detalle=${operacion_detalle != undefined ? operacion_detalle : ''}` +
            `&invoices__operacion_detalle__isnull=${invoices__operacion_detalle__isnull != undefined ? invoices__operacion_detalle__isnull : ''}` +
            `&invoices__series=${invoices__series != undefined ? invoices__series : ''}` +
            `&invoices__numeration=${invoices__numeration != undefined ? invoices__numeration : ''}` +
            `&invoices__procesado=${invoices__procesado != undefined ? invoices__procesado : ''}` +
            `&id_process=${id_process != undefined ? id_process : ''}` +
            `&proceso=${proceso != undefined ? proceso : ''}` +
            `&error=${error != undefined ? error : ''}` +
            `&operacion=${operacion || ''}`
            ;


        return new Promise((resolve, reject) => {
            this.spinnerOn()
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
                this.spinnerOff()
            }, (err) => {
                reject(err);
                if (err.error['operacion_detalle']) {
                    this.spinnerOff('No existe un detalle con ese ID', false)
                } else {
                    this.spinnerOff('Ocurrio un error', false)
                }

            });

        });
    }


    filtroNoObligatorio(nombre, valor): string {
        return (valor != undefined ? `&${nombre}=${valor}` : '')
    }


    getCavaliProcesos(page: number = 1, page_size: number = 1000, operacion_detalle: number = undefined, resultado__isnull: false = undefined, error: false = undefined, operacion=null) {
        const URL = this.apiUrl +
            this.cavaliProcesos +
            `?page=${page}` +
            `&page_size=${page_size}` +
            `&operacion_detalle=${operacion_detalle != undefined ? operacion_detalle : ''}` +
            `&resultado__isnull=${resultado__isnull != undefined ? resultado__isnull : ''}` +
            `&error=${error != undefined ? error : ''}` +
            `&operacion=${operacion || ''}`
            ;


        return new Promise((resolve, reject) => {
            this.spinnerOn()
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
                this.spinnerOff()
            }, (err) => {
                reject(err);
                this.spinnerOff('Ocurrio un error', false)
            });

        });
    }

    getCavaliFacturas(page: number = 1, page_size: number = 1000, resultado: number = undefined, resultado__proceso: number = undefined) {
        const URL = this.apiUrl +
            this.cavaliFacturas +
            `?page=${page}` +
            `&page_size=${page_size}` +
            `&resultado=${resultado != undefined ? resultado : ''}` +
            `&resultado__proceso=${resultado__proceso != undefined ? resultado__proceso : ''}`
            ;


        return new Promise((resolve, reject) => {
            this.spinnerOn()
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
                this.spinnerOff()
            }, (err) => {
                reject(err);
                if (err.error['resultado__proceso']) {
                    return this.spinnerOff('El resultado no posee facturas', false)
                } else {
                    return this.spinnerOff('Ocurrio un error', false)
                }
            });

        });
    }

    obtenerTipoDeCambio(fecha=null): any {
        const URL = this.apiUrl + this.tiposDeCambio + `?page=1&page_size=1&fecha__lte=${fecha || ''}`;
            this.spinnerOn()
        return new Promise((resolve, reject) => {
            this.http.get(URL).subscribe(data => {
                resolve(data['results'][0]);
                this.spinnerOff()
            }, (err) => {
                reject(err);
                this.spinnerOff()
            });
        });
    }
    getReporteCambioLineaxCliente(
             beneficiario_nombre__icontains  = undefined,
             tipo    = undefined,
             oficial_nombre__icontains    = undefined,
             fecha_alta__lte = undefined,
             fecha_alta__gte    = undefined,
             page           = 1,
             page_size      = 10,
             ) {
        
             const URL = this.apiUrl +
             this.cambioLineaXCliente +
                 `?page=${page}` +
                 `&page_size=${page_size}` +
                 this.filtroNoObligatorio('beneficiario_nombre__icontains', beneficiario_nombre__icontains) +
                 this.filtroNoObligatorio('tipo', tipo) +
                 this.filtroNoObligatorio('oficial_nombre__icontains', oficial_nombre__icontains) +
                 this.filtroNoObligatorio('fecha_alta__lte', fecha_alta__lte) +
                 this.filtroNoObligatorio('fecha_alta__gte', fecha_alta__gte)
        
             this.spinnerOn();
             return new Promise((resolve, reject) => {
                 this.http.get(URL).subscribe(data => {
                     this.spinnerOff();
                     resolve(data);
                 }, async (err) => {
                     this.spinnerOff('Error al generar reporte', false);
                     reject(err);
                 });
             })
         }

    getReporteExcedentesDevueltos(
        operacion  = undefined,
        fecha__gte    = undefined,
        fecha__lte    = undefined,
        cliente_nombre__icontains = undefined,
        cliente_ruc__icontains    = undefined,
        aceptante_nombre__icontains  = undefined,
        aceptante_ruc__icontains     = undefined,
        page           = 1,
        page_size      = 10,
        ) {

        const URL = this.apiUrl +
        this.exedentesDevueltos +
            `?page=${page}` +
            `&page_size=${page_size}` +
            this.filtroNoObligatorio('operacion', operacion) +
            this.filtroNoObligatorio('fecha__gte', fecha__gte) +
            this.filtroNoObligatorio('fecha__lte', fecha__lte) +
            this.filtroNoObligatorio('cliente_nombre__icontains', cliente_nombre__icontains) +
            this.filtroNoObligatorio('cliente_ruc__icontains', cliente_ruc__icontains) +
            this.filtroNoObligatorio('aceptante_nombre__icontains', aceptante_nombre__icontains) +
            this.filtroNoObligatorio('aceptante_ruc__icontains', aceptante_ruc__icontains)

        this.spinnerOn();
        return new Promise((resolve, reject) => {
            this.http.get(URL).subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, async (err) => {
                this.spinnerOff('Error al generar reporte', false);
                reject(err);
            });
        })
    }

    getReporteCuadroConsolidado(
        nro_operacion  = undefined,
        fecha_desde    = undefined,
        fecha_hasta    = undefined,
        cliente_nombre = undefined,
        cliente_ruc    = undefined,
        aceptante_nombre  = undefined,
        aceptante_ruc     = undefined,
        tipo_pagador   = undefined,
        excedente_moneda_cod = undefined,
        fecha_devolucion__gte = undefined,
        fecha_devolucion__lte = undefined,
        recaudacion = undefined,
        estado         = undefined,
        id             = undefined,
        page           = 1,
        page_size      = 10,
        showMask = true
        ) {

        const URL = this.apiUrl +
       this.cuadroConsolidado +
            `?page=${page}` +
            `&page_size=${page_size}` +
            this.filtroNoObligatorio('nro_operacion', nro_operacion) +
            this.filtroNoObligatorio('fecha__gte', fecha_desde) +
            this.filtroNoObligatorio('fecha__lte', fecha_hasta) +
            this.filtroNoObligatorio('cliente_nombre__icontains', cliente_nombre) +
            this.filtroNoObligatorio('ruc_cliente__icontains', cliente_ruc) +
            this.filtroNoObligatorio('aceptante_nombre__icontains', aceptante_nombre) +
            this.filtroNoObligatorio('ruc_aceptante__icontains', aceptante_ruc) +
            this.filtroNoObligatorio('tipo_pagador', tipo_pagador) +
            this.filtroNoObligatorio('moneda_excedente_cod', excedente_moneda_cod) +
            this.filtroNoObligatorio('fecha_devolucion__gte', fecha_devolucion__gte) +
            this.filtroNoObligatorio('fecha_devolucion__lte', fecha_devolucion__lte) +
            this.filtroNoObligatorio('nro_recaudacion', recaudacion) 
            // this.filtroNoObligatorio('estado', estado) +
            // this.filtroNoObligatorio('id', id);
        if (showMask) {
            this.spinnerOn();
        }
        return new Promise((resolve, reject) => {
            this.http.get(URL).subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, async (err) => {
                this.spinnerOff('Error al generar reporte', false);
                reject(err);
            });
        })
    }

    getReporteCreditoFactoring(
        nro_operacion  = undefined,
        nro_documento = undefined,
        fecha_ultima_transaccion_desde = undefined,
        fecha_ultima_transaccion_hasta = undefined,
        cliente_ruc    = undefined, 
        cliente_razon_social = undefined,
        deudor_ruc     = undefined,
        deudor_razon_social  = undefined,
        page           = 1,
        page_size      = 10,
        showMask = true
    ) {
        const URL = this.apiUrl +
       this.creditosFactoring +
            `?page=${page}` +
            `&page_size=${page_size}` +
            this.filtroNoObligatorio('nro_operacion__in', nro_operacion) +
            this.filtroNoObligatorio('nro_documento__in', nro_documento) +
            this.filtroNoObligatorio('fecha_ultima_transaccion__gte', fecha_ultima_transaccion_desde) +
            this.filtroNoObligatorio('fecha_ultima_transaccion__lte', fecha_ultima_transaccion_hasta) +
            this.filtroNoObligatorio('cliente_ruc__icontains', cliente_ruc) +
            this.filtroNoObligatorio('cliente_razon_social__icontains', cliente_razon_social) +
            this.filtroNoObligatorio('deudor_ruc__icontains', deudor_ruc) +
            this.filtroNoObligatorio('deudor_razon_social__icontains', deudor_razon_social)
        if (showMask) {
            this.spinnerOn();
        }
        console.dir(JSON.stringify(URL));
        return new Promise((resolve, reject) => {
            this.http.get(URL).subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, async (err) => {
                this.spinnerOff('Error al generar reporte', false);
                reject(err);
            });
        })
    }

    obtenerBancos() {
        const url = `${this.apiUrl}factoring/bancos/?page_size=100&ordering=nombre`;

        return new Promise((res, ref) => {
            this.spinnerOn();
            this.http.get(url).subscribe(
                (response) => {
                    this.spinnerOff();
                    res(response);
                },
                (err) => {
                    this.spinnerOff();
                    ref(err);
                }
            );
        });
    }

    getCobranzaCliente( ruc:any ){

        const url = `${ this.apiUrl }factoring/cobranzas/clientes/?ruc=${ruc}`
        this.spinnerOn();
        return new Promise( ( resolve, reject )=>{

            this.http.get( url )
                .subscribe( resp=>{
                    this.spinnerOff();
                    resolve( resp )

                }, err =>{
                    if( err.error.detail ){
                        // TODO: Compensaciones leasing: Comentado hasta que haya comunicación con COBRANZAS
                        // this.spinnerOff(err.error.detail, false);
                        this.spinnerOff();
                    }else{
                        this.spinnerOff();
                    }
                    reject( err )
                })

        })

    }

    getCobranzaContrato(beneficiario){

        const url = `${ this.apiUrl }factoring/cobranzas/contratos/?cliente=${beneficiario}`
        this.spinnerOn();
        return new Promise( ( resolve, reject )=>{

            this.http.get( url )
                .subscribe( resp=>{
                    this.spinnerOff();
                    resolve( resp )

                }, err =>{
                    this.spinnerOff();
                    reject( err )
                })

        })
            
    }

    getTiposExcepcion(queryParams: object = { 'page_size': 100 }) {
        const url = `${this.apiUrl}factoring/excepciones-tipos/?${Object.entries(queryParams).map((e: any) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&')}`
        return this.http.get(url).pipe(
            map((res: any) => res.results)
          ).pipe(
            catchError(response => {
                let error = 'Ocurrió un error inesperado'
                if (response.error && typeof (response.error) == 'object') {
                    error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
                }
                this.toast.warning(error)
                return throwError(response)
            })
          )
    }

    getDeudaLeasing(ruc) {
        const url = `${this.apiUrl}factoring/beneficiarios/deuda-leasing/?ruc=${ruc}`
        return this.http.get(url)
    }

    getDeudaVencida(ruc, rucs) {
        const url = `${this.apiUrl}factoring/beneficiarios/deuda-vencida/?ruc=${ruc}&rucs=${rucs}`
        return this.http.get(url)
    }

    saveExcepcionDeudaLeasing(operacion_id, data) {
        const url = `${this.apiUrl}factoring/operaciones/${operacion_id}/`
        return this.http.patch(url, data).pipe(
            catchError(response => {
                let error = 'Ocurrió un error inesperado'
                if (response.error && typeof (response.error) == 'object') {
                    error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
                }
                this.toast.warning(error)
                return of(false)
            })
          )
    }

    getFeriados(queryParams: object = {}) {
        const url = `${this.apiUrl}factoring/configuraciones/feriados/?${this.encodeQueryParams(queryParams)}`
        return this.http.get(url)
    }

    saveFeriado(data) {
        const url = `${this.apiUrl}factoring/configuraciones/feriados/`
        let method$ = null
        if (data.id) {
            method$ = this.http.patch(`${url}${data.id}/`, data)
        } else {
            method$ = this.http.post(url, data)
        }
        return method$.pipe(
            catchError(res => this.handleError(res))
        )
    }

    deleteFeriado(id) {
        const url = `${this.apiUrl}factoring/configuraciones/feriados/${id}/`
        
        return this.http.delete(url).pipe(
            catchError(res => this.handleError(res))
        )
    }

    getCuentaAbono(id) {
        const url = `${this.apiUrl}factoring/beneficiarios/cuentasabono/${id}/`
        return this.http.get(url)
    }

    validarCuentaAbono(id, cci: boolean = false) {
        const url = `${this.apiUrl}factoring/beneficiarios/cuentasabono/${id}/validar/?cci=${cci ? 1 : 0}`
        return this.http.post(url, {}).pipe(
            catchError(res => this.handleError(res))
        )
    }

    private encodeQueryParams(queryParams) {
        return Object.entries(queryParams).map((e: any) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&')
    }

    private handleError(response) {
        let error = 'Ocurrió un error inesperado'
        if (response.error && typeof (response.error) == 'object') {
            error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
        }
        this.toast.warning(error)
        return throwError(response)
    }

}

