import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { AdminLayoutComponent } from '../../layouts/admin/admin-layout.component';
import { CuentasCobrarComponent } from './pages/cuentas-cobrar/cuentas-cobrar.component';


const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Cuentas X Cobrar'
    },
    children: [
      {
        path: 'cuentas_cobrar',
        canActivate: [AuthGuard],
        component: CuentasCobrarComponent,
        data: {
          breadcrumb: 'Cuentas X Cobrar'
        }
      }
    ]
  }
];;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CuentasCobrarRoutingModule { }
