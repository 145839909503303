import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ExcedentesService } from 'app/core/services/excedentes/excedentes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { DevolucionModalComponent } from '../../../../componentes/devolucion-modal/devolucion-modal.component';
import { functions } from '../../../../../../core/helpers/functions';
import { toArray } from 'rxjs/operators';
import * as XLSX from 'xlsx'
import { concat, Observable, of, Subject, throwError } from 'rxjs';

@Component({
  selector: 'app-devoluciones-vigentes',
  templateUrl: './devoluciones-vigentes.component.html',
  styleUrls: ['./devoluciones-vigentes.component.css']
})
export class DevolucionesVigentesComponent implements OnInit {
  faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = "";
  public canCreate: boolean = false;
  public tmpData: any;
  listSelect: any[] = [];
  public todoSelect: boolean = false;
  public preselected: any[] = [];

  exportando = false;
	tblFiltros = {}

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public modalService: NgbModal,
    public factoring: FactoringService,
    public router: Router,
    public authService: AuthService,
    public devolucionesService: ExcedentesService
  ) { }

  @Input() tableSelectable: boolean = false;
  @Input() set refresh(value) {
    this.tableSelectable = false;
    this.obtenerDevoluciones();
  }

  @Output() ocultarBtn = new EventEmitter();


  ngOnInit(): void {
    this.obtenerDevoluciones();

    this.ocultarBtn.emit(true)
  }

  obtenerDevoluciones() {
    let devoluciones = this.devolucionesService.obtenerDevolucionesVigentes();
    let estados = this.devolucionesService.obtenerEstados();
    let empresas = this.factoring.obtenerEmpresas();

    var estado = '1'
    var filtros ={
      estado
    }

    this.tblFiltros = filtros

    Promise.all([devoluciones, estados, empresas]).then(
      (res: any[]) => {
        let devoluciones = res[0];
        let estados = res[1]['results'];
        let empresas = res[2]['results'];
        this.initVars(estados, empresas);
        this.loadTable(devoluciones);
      }
    );
  }

  async obtenerDevolucionesUpdate() {
    let devoluciones = await this.devolucionesService.obtenerDevolucionesVigentes();
    let data_estados = await this.devolucionesService.obtenerEstados();
    let data_empresas = await this.factoring.obtenerEmpresas();

    let estados = data_estados['results'];
    let empresas = data_empresas['results'];
    this.initVars(estados, empresas);
    this.loadTable(devoluciones);
      
  }

  loadTable(data) {
    this.rowData = functions.indextable( data )
    // this.rowData = data.results.map((element, index) => ({
    //   ...element, index: index + 1
    // }));
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars(estados, empresas) {
    let columns = [
      {
        headerName: "ID",
        field: 'id',
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "cliente_nombre",
        sortable: true,
        filterable: true,
        filterProp: "cliente_nombre",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC Cliente",
        field: "cliente_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
        sortable: true,
        filterable: true,
        filterProp: "aceptante_nombre",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC Aceptante",
        field: "aceptante_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cant. Docs",
        field: "cantidad_docs",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        filterable: true,
        filterRange: true
      },
      {
        class: "text-center",
        headerName: "Fch. Solicitud",
        field: "fecha_solicitud",
        pipe: "date",
        sortable: true,
        filterable: true,
        filterDate: true,
      },
      {
        class: "text-center",
        headerName: "Fch. Aprobación",
        field: "fecha_aprobacion",
        pipe: "date",
        sortable: true,
      },
      {
        headerName: 'Compensación',
        field: 'compensacion',
        class: 'text-center',
        filterable: true,
        filterProp: 'compensacion',
        filterSelectItems: [
          {id: true, descripcion: 'Si'},
          {id: false, descripcion: 'No'}
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
        pipe: 'function',
        function: row => row['compensacion'] ? 'Si' : 'No',
      },
      {
        headerName: 'Estado',
        field: 'estado_descripcion',
        filterProp: 'estado',
        sortable: true,
        class: 'text-center',
        filterSelectItems: estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        }
      }
    ];

    this.columnDefs = columns;
  }

  tableEditEvent(row) {

    const modalRef = this.modalService.open(DevolucionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });

    modalRef.componentInstance.devolucionId = row.id;
    modalRef.componentInstance.mostrarDeuda = true;

    modalRef.result.then((result) => {
      this.tableSelectable = false;
      this.obtenerDevolucionesUpdate()
      
    }, (reason) => {
      console.log(reason);
      this.tableSelectable = false;
      this.obtenerDevolucionesUpdate()
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
      console.log(result);
      this.tableSelectable = false;
      this.obtenerDevolucionesUpdate()
    },
      error => {

      });

  }
  async enviarSolicitudes() {
    for (const element of this.listSelect) {
      var confirmacion = await this.lanzarModalConfirmacion(element)
      if(confirmacion['estado']){
        await this.aprobacionMasiva(element)
      }else{
        await this.devolucionesService.crearDevolucion({ estado: 6}, element.id)
      }
      console.log('Terminó con las aprobaciones Masivas')
    }
    console.log('Se viene actualizar front')
    this.tableSelectable = false;
    this.obtenerDevoluciones()
  }

  async exportarDataTablaSolicitudes() {
		console.log('Filtros Tabla', this.tblFiltros)
		const lote = 100

		this.exportando = true
    //obtenerDevolucionesVigentes
		this.devolucionesService.getDevolucionesVigentes(Object.assign({ 'page_size': 1 }, this.tblFiltros)).subscribe(
			data => {
			let pages = Math.ceil(data['count'] / lote)
			let pages$ = Array.from({ length: pages }).map((_, i) => i + 1).map(
				page => this.devolucionesService.getDevolucionesVigentes(Object.assign({ page, 'page_size': lote }, this.tblFiltros))
			)
			concat(...pages$).pipe(toArray()).subscribe((data: any) => {
				data = data.map((x: any) => x.results).flat(1)
				data = data.map(x => {
				let row = {}
				for (let header of this.columnDefs) {
          if(header['field'] == 'compensacion'){
            if(x[header['field']] == true){
              row[header['headerName']] = 'SI'
            }else{
              row[header['headerName']] = 'NO'
            }
          }else if(header['field'] == 'monto'){
            if(x['moneda'] == 1){
              row['Moneda'] = 'SOLES'
              row[header['headerName']] =  parseFloat(x[header['field']])
            }else{
              row['Moneda'] = 'DOLARES'
              row[header['headerName']] =  parseFloat(x[header['field']])
            }
          }else{
            row[header['headerName']] = x[header['field']]
          }
				}
				return row
				})
				let ws = XLSX.utils.json_to_sheet(data)
				let wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, ws, 'Solicitudes')
				XLSX.writeFile(wb, 'Reporte Solicitudes Excedente Vigente.xlsx')
			}).add(() => this.exportando = false)
			},
			res => {
			console.log(res)
			this.exportando = false
			}
		)
	}

  async lanzarModalConfirmacion(data){
      // ! Monto minimo
      // if(data.monto < data.monto_minimo){
      //   return {'estado': false, observacion: 'Monto es menor a monto minimo de solicitud'};
      // }

      // ? Tipos de Observación:
      // 1 - Cuenta Abono inexistente
      // 2 - Cuenta Abono diferente Moneda
      // 3 - Deuda Leasing
      // 4 - Deuda Factoring

      var estado_solicitud = true
      var compensacion = false
      var data_solicitud =  await this.devolucionesService.obtenerDevolucionesEspecifica(data.id)

      if(data.compensacion){
        estado_solicitud = false
        compensacion = true
        await this.observacionSolicitud(data.id, 5)
      }

      // ! Cuenta abono existente
      if(data_solicitud['cuentas_abono'].length < 1 && !compensacion){
        estado_solicitud = false
        await this.observacionSolicitud(data.id, 1)
      }else{
        // ! Cuenta abono igual a solicitud
        var cuentas_ok = false
        for (let i = 0; i < data_solicitud['cuentas_abono'].length; i++) {
          if(data_solicitud['cuentas_abono'][i].moneda_id == data.moneda){
            cuentas_ok = true
          }
        }
        // Validacion
        if (cuentas_ok == false && !compensacion){
          estado_solicitud = false
          await this.observacionSolicitud(data.id, 2)
        }
      }

      // ! CXC && Documento Cartera DEUDA de cliente o deudor (Deuda Factoring)
      var deudas = await this.obtenerDeuda(data.cliente, data.aceptante)
      if (deudas == false){
        estado_solicitud = false
        await this.observacionSolicitud(data.id, 4)
      }

      // ! Deuda Leasing
      if(data.aceptante)
        var deudaLeasing = await this.obtenerDeudaLeasing(data.aceptante_ruc)
      else
        var deudaLeasing =  await this.obtenerDeudaLeasing(data.cliente_ruc)

      if (deudaLeasing == false){
        estado_solicitud = false
        await this.observacionSolicitud(data.id, 3)
      }

      return {'estado': estado_solicitud}
  }

  async obtenerDeudaLeasing(ruc) {
    let deudaLeasing = null
    let validacion_deudas = true

    deudaLeasing = await this.factoring.beneficiarioDeudaLeasing(ruc)
    
    if (deudaLeasing != null){
      if(deudaLeasing['length'] > 0){
        validacion_deudas = false
      }
    }

    return validacion_deudas
  }

async obtenerDeuda(clienteId: number, aceptanteId: number) {
    let promise = null
    let validacion_deudas = true
    if (aceptanteId) {
      promise = await this.devolucionesService.obtenerDeudaDeudor(aceptanteId)
    } else {
      promise = await this.devolucionesService.obtenerDeudaBeneficiario(clienteId)
    }

    var dc_soles = promise['total_documentos_cartera_mora_soles']
    var dc_dolares = promise['total_documentos_cartera_mora_dolares']
    var cxc_soles = promise['total_cuentas_cobrar_soles']
    var cxc_dolares = promise['total_cuentas_cobrar_dolares']

    if(dc_soles > 0 || dc_dolares > 0 || cxc_soles > 0 || cxc_dolares > 0){
      validacion_deudas = false
    }
    return validacion_deudas
  }

async aprobacionMasiva(data){
      var data_solicitud = {
        solicitud_devolucion: data.id,
        responsable: this.authService.user.id
      };
      await this.devolucionesService.aprobarSolicitudDevolucion(data_solicitud)
  }

async observacionSolicitud(solicitud_id, observacion_id){
    var data_solicitud_observacion = {
      solicitud_devolucion: solicitud_id,
      observacion_tipo: observacion_id
    };
    await this.devolucionesService.crearSolicitudObservaciones(data_solicitud_observacion)
}


  goToPage({ page, per_page, filtros }) {

    let data_gte = (filtros.fecha__gte) ? filtros.fecha__gte : '';
    let data_lte = (filtros.fecha__lte) ? filtros.fecha__lte : '';
    data_gte = data_gte.replace(' 00:00', '');
    data_lte = data_lte.replace(' 00:00', '');

    // Export
    var cliente__iconstains = filtros.cliente_nombre ? filtros.cliente_nombre : ''
    var monto__gte = (filtros.valueFrom) ? filtros.valueFrom : ''
    var monto__lte =  (filtros.valueTo) ? filtros.valueTo : ''
    var fecha_solicitud__gte = data_gte
    var fecha_solicitud__lte = data_lte
    var aprobacion_gte = ''
    var aprobacion_lte = ''
    var estado = '1'
    var empresa = filtros.empresa ? filtros.empresa: ''
    var aceptante__iconstains = filtros.aceptante_nombre ? filtros.aceptante_nombre: ''
    var compensacion = filtros.compensacion ? filtros.compensacion: ''
    var filtros_export = {
      page,
      per_page,
      cliente__iconstains,
      monto__gte,
      monto__lte,
      fecha_solicitud__gte,
      fecha_solicitud__lte,
      aprobacion_gte,
      aprobacion_lte,
      estado,
      empresa,
      aceptante__iconstains,
      compensacion
    }
    this.tblFiltros = filtros_export

    let devoluciones = this.devolucionesService.obtenerDevolucionesVigentes(
      page,
      per_page,
      filtros.cliente_nombre,
      (filtros.valueFrom) ? filtros.valueFrom : '',
      (filtros.valueTo) ? filtros.valueTo : '',
      data_gte,
      data_lte,
      '',
      '',
      filtros.estado,
      filtros.empresa,
      filtros.aceptante_nombre,
      filtros.compensacion
    );

    devoluciones.then(res => {
      this.loadTable(res);
    }).catch(error => {

    });
    this.todoSelect = false;
    this.listSelect = [];
    this.preselected = [];

  }
  checkEvent(row) {
    this.listSelect = row;
    this.evalCheck();
  }
  workflowEvent(row) {

  }
  toggleText(){
    this.todoSelect = !this.todoSelect
    this.toggleCheckAll()
  }
  toggleCheckAll(){
    this.listSelect = []
      if(this.todoSelect){
          this.preselected = this.rowData.map((item) => { return item.id })
          this.listSelect = this.rowData
      } else {
          this.preselected = []
      }
  }
  evalCheck(){
    this.todoSelect = this.listSelect.length == this.rowData.length
  }

}
