import { Component, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { pairwise, startWith } from 'rxjs/operators';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';

declare var $: any;
declare var require: any;

@Component({
    selector: 'app-users-crud',
    templateUrl: './users-crud.component.html',
    styleUrls: ['./users-crud.component.css']
})
export class UsersCrudComponent implements OnInit {
    public formulario: FormGroup;
    public columnDefs: any[];
    public rowData: any[] = [];
    public closeResult: string = '';
    public modalCreate: boolean = true;
    public searchText: string = '';
    public tableData: any[];
    public paginationData: any;
    public editableUser: any;
    public perfiles;
    public estados: any[];
    public clientes;
    public loadingTable: boolean = false;
    public formReady: boolean = false;
    public paginationPage: number = 0;
    public userToDelete: any;
    public searchResults: boolean = false;
    public pageTableActual: number;
    public pageTableTotal: number;
    public pageTablePerPages: number = 10;
    public pageTablePages: number[];

    cliente: any;

    constructor(
        public formbuild: FormBuilder,
        public modalService: NgbModal,
        public http: HttpClient,
        public spinner: NgxSpinnerService,
        public config: DocschecklistService,
        public auth: AuthService,
        private clienteService: ClientesService,
        private configurationService: ConfigurationService,
    ) {

        // let usuarios = this.auth.obtenerUsuarios()
        // let perfiles = this.auth.obtenerPerfiles()

        // Promise.all([
        //     usuarios,
        //     perfiles]).then((res: any[]) => {

        //         let usuarios = res[0]
        //         let filter = res[1]['results'];
        //         this.perfiles = filter.sort(function (a, b) {
        //             if (a.descripcion > b.descripcion) {
        //                 return 1;
        //             }
        //             if (a.descripcion < b.descripcion) {
        //                 return -1;
        //             }
        //             return 0;
        //         });
        //         this.estados = [
        //             { id: true, name: 'Activo' },
        //             { id: false, name: 'Inactivo' }
        //         ]
        //         this.formReady = true
        //         this.initVars();
        //         this.loadTable(usuarios);
        //         console.log(this.perfiles);
        //     })
    }

    ngOnInit() {
        this.obtenerClientes();
    }

    // obtenerUsers() {
    //     let usuarios = this.auth.obtenerUsuarios()
    //     let perfiles = this.auth.obtenerPerfiles()

    //     Promise.all([
    //         usuarios,
    //         perfiles]).then((res: any[]) => {

    //             let usuarios = res[0]
    //             this.perfiles = res[1]['results']
    //             this.estados = [
    //                 { id: true, name: 'Activo' },
    //                 { id: false, name: 'Inactivo' }
    //             ]
    //             this.formReady = true
    //             this.initVars();
    //             this.loadTable(usuarios);
    //         });
    // }

    obtenerClientes() {
        this.clienteService.obtenerClientes(1, 1000)
            .then(res => {
                this.clientes = res['results'];
            })
            .catch(error => {
                console.error(error);
            });
    }

    initVars() {
        this.columnDefs = [
            {
                headerName: 'Id',
                field: 'id',
                sortable: true
            },
            {
                headerName: 'Nombre',
                field: 'nombre',
                sortable: true
            },
            {
                headerName: 'Apellido',
                field: 'apellido',
                sortable: true
            },
            {
                headerName: 'Email',
                field: 'email',
                sortable: true
            },
            {
                headerName: 'Estado',
                field: 'estado',
                pipe: 'statusBool',
                sortable: true
            },
            {
                headerName: 'Puesto',
                field: 'puesto',
                sortable: true
            },
            // {
            //     headerName: 'Perfil', 
            //     field: 'perfil_descripcion',
            //     sortable: true
            // },
            {
                headerName: 'Perfil',
                field: 'perfil_descripcion',
                filterProp: 'perfil',
                sortable: true,
                class: 'text-center',
                filterable: true,
                filterSelectItems: this.perfiles,
                filterItemsProps: {
                    value: 'id',
                    label: 'descripcion'
                },
            },
            {
                headerName: 'Fecha de creación',
                pipe: 'date',
                field: 'fecha_creacion',
                sortable: true,
                class: 'text-center'
            },
        ];
    }

    /**
     * Inicializa la tabla con datos y paginacion
     */
    loadTable(data) {
        this.rowData = data.results
        this.pageTableActual = data.page_number
        this.pageTableTotal = data.count
        this.pageTablePerPages = data.per_page
        this.pageTablePages = []
        for (let index = 0; index < data.num_pages; index++) {
            this.pageTablePages.push(index)
        }
    }

    // goToPage({ page, per_page, filtros } = { page: this.pageTableActual, per_page: this.pageTablePerPages, filtros: {} }) {
    //     console.log(page, per_page, filtros);
    //     let request;

    //     request = this.auth.obtenerUsuarios(page, per_page, filtros['perfil'])

    //     request.then((res) => {
    //         this.loadTable(res)
    //     })
    // }

    //  async generatePassword() {
    //     const mayusculas = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
    //     const minusculas = 'abcdefghijklmnopqrstuvwxyz'
    //     const simbolos = '\.\,\;\:\-\_\¡\!\¿\?\#\$\%\+'
    //     const numeros = '123456789'
    //     const todos = mayusculas + minusculas + simbolos + numeros

    //     let parametros = await this.configurationService.obtenerParametrosContrasena(1, 100)
    //     parametros = parametros["results"][0]
    //     let password = ''


    //     while ((password.match(/[A-Z]/g) || []).length < parametros['min_uppercase']) {
    //         password += mayusculas.charAt(Math.floor(Math.random() * mayusculas.length))
    //     }

    //     while ((password.match(/[a-z]/g) || []).length < parametros['min_lowercase']) {
    //         password += minusculas.charAt(Math.floor(Math.random() * minusculas.length))
    //     }

    //     while ((password.match(/[\.\,\;\:\-\_\¡\!\¿\?\#\$\%\+]/g) || []).length < parametros['min_symbol']) {
    //         password += simbolos.charAt(Math.floor(Math.random() * simbolos.length))
    //     }

    //     while ((password.match(/[1-9]/g) || []).length < parametros['min_number']) {
    //         password += numeros.charAt(Math.floor(Math.random() * numeros.length))
    //     }

    //     while (password.length < parametros['min_length']) {
    //         password += todos.charAt(Math.floor(Math.random() * todos.length))
    //     }
    //     password = password.split('').sort((a, b) => 0.5 - Math.random()).join('');
    //     this.formulario.controls['password'].setValue(password);
    //     return password;
    // }

    copyToClipboard() {
        var textArea = document.createElement("textarea");
        textArea.value = this.formulario.get('password').value;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            console.log('Fallback: Copying text command was ' + msg);
        } catch (err) {
            console.error('Fallback: Oops, unable to copy', err);
        }

        document.body.removeChild(textArea);
    }

    initForm() {
        if (this.modalCreate) {
            this.formulario = this.formbuild.group({
                nombre: ['', [Validators.required, Validators.minLength(2)]],
                apellido: ['', [Validators.required, Validators.minLength(2)]],
                email: ['', [Validators.required, Validators.minLength(5), Validators.email]],
                password: ['', [Validators.required]],
                perfil: ['', [Validators.required]],
                estado: ['', [Validators.required]],
                cliente: [''],
                codigo_sf: [''],
                sucursal: [''],
                dni: [''],
                puesto: [''],
                restablecer_password: [false, []]
            })
        } else {
            this.formulario = this.formbuild.group({
                nombre: ['', [Validators.required, Validators.minLength(2)]],
                apellido: ['', [Validators.required, Validators.minLength(2)]],
                email: ['', [Validators.required, Validators.minLength(5), Validators.email]],
                password: ['', []],
                perfil: ['', [Validators.required]],
                estado: ['', [Validators.required]],
                cliente: [''],
                codigo_sf: [''],
                sucursal: [''],
                puesto: [''],
                dni: [''],
                restablecer_password: [false, []]
            })
        }

        this.formulario.get('perfil').valueChanges.subscribe(val => {
            if (val === 2) {
                setTimeout(() => {
                    this.formulario.get('cliente').setValidators([Validators.required])
                    this.formulario.get('cliente').updateValueAndValidity()
                }, 100)
            } else {
                setTimeout(() => {
                    this.formulario.get('cliente').setValidators([])
                    this.formulario.get('cliente').updateValueAndValidity()
                }, 100)
            }
            if (val === 3) {
                setTimeout(() => {
                    this.formulario.get('codigo_sf').setValidators([Validators.required])
                    this.formulario.get('sucursal').setValidators([Validators.required])
                    this.formulario.get('dni').setValidators([Validators.required, Validators.minLength(8)])
                    this.formulario.get('codigo_sf').updateValueAndValidity()
                    this.formulario.get('sucursal').updateValueAndValidity()
                    this.formulario.get('dni').updateValueAndValidity()
                }, 100)
            } else {
                setTimeout(() => {
                    this.formulario.get('codigo_sf').setValidators([])
                    this.formulario.get('sucursal').setValidators([])
                    this.formulario.get('dni').setValidators([])
                    this.formulario.get('codigo_sf').updateValueAndValidity()
                    this.formulario.get('sucursal').updateValueAndValidity()
                    this.formulario.get('dni').updateValueAndValidity()
                }, 100)
            }
        });
    }

    setUser(e) {
        this.cliente = e;
    }

    saveForm() {
        this.formulario.reset()
    }

    openModal(content) {
        this.initForm()
        this.modalService.open(content, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'lg'
        }).result.then((result) => {
            if (this.formulario.valid) {
                let value = this.formulario.value
                if (!this.modalCreate) {
                    value.id = this.editableUser.id
                    this.modalCreate = true
                    this.editableUser = null
                } else {
                    this.modalCreate = false
                }
                if (this.cliente) {
                    value['cliente_nombre'] = this.cliente.nombre;
                }

                if (value.password == '') {
                    delete value.password
                }
                if (result) {
                    // this.uploadUser(value)
                }
            }
            this.formulario.reset()
        }, (reason) => {
            this.formulario.reset()
        });
    }

    // tableEditEvent(row: any, modalContent: any) {
    //     this.modalCreate = false;

    //     this.auth.obtenerUsuario(row.id)
    //         .then((res: any) => {
    //             this.openModal(modalContent);
    //             this.formulario.setValue({
    //                 nombre: res.nombre,
    //                 apellido: res.apellido,
    //                 email: res.email,
    //                 password: '',
    //                 perfil: res.perfil,
    //                 estado: res.estado,
    //                 restablecer_password: res.restablecer_password,
    //                 cliente: res.cliente ? res.cliente : '',
    //                 codigo_sf: res.codigo_sf ? res.codigo_sf : '',
    //                 sucursal: res.sucursal ? res.sucursal : '',
    //                 dni: res.dni ? res.dni : '',
    //                 puesto: res.puesto ? res.puesto : '',
    //             });
    //             this.editableUser = res;
    //         })
    //         .catch(error => {
    //             console.error(error);
    //         });
    // }

    tableReadEvent(row: any, modalContent: any) {
        this.modalCreate = false
        this.openModal(modalContent);
    }

    // tableDeleteEvent(row: any, modalContent: any) {
    //     this.userToDelete = row;
    //     this.modalService.open(modalContent, {
    //         ariaLabelledBy: 'modal-basic-title',
    //         size: 'md'
    //     }).result.then((result) => {
    //         if (result) {
    //             this.deleteUser(row)
    //         }
    //         console.log('Resultado', result)
    //     }, (reason) => {
    //         console.log(reason)
    //     });
    // }

    createUserButtonClicked(contentModal: any) {
        this.modalCreate = true;
        this.openModal(contentModal);
    }

    // uploadUser(user) {
    //     this.auth.uploadUsers(user).then((res) => {
    //         this.goToPage()
    //     }).catch((err) => {
    //         console.log('Ocurrio un error durante el registro!')
    //     })
    // }

    // deleteUser(user) {
    //     this.auth.deleteUsers(user.id).then((res) => {
    //         this.goToPage()
    //     })
    // }
}