import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faFile, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal,NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { data } from 'jquery';

@Component({
  selector: 'app-desglose-lineas',
  templateUrl: './desglose-lineas.component.html',
  styleUrls: ['./desglose-lineas.component.css']
})
export class DesgloseLineasComponent implements OnInit {
  faSave            = faSave;
  faTrash            = faTrash;
  public _desglose;
  public loading : boolean = false;
  @Input() aceptante;
  @Input() desglose;
  @Input() desglosePropuesta;
  @Input() propuesta;
  @Input() propuestaId;
  @Input() operacion;
  @Input() readonly;
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();

  public desgloseForm     : FormGroup;
  

  constructor(
    public modalService    : NgbModal,
    public toast           : ToastService,
    public formbuild       : FormBuilder,
    public propuestaService: PropuestaComercialService,
    ) { }

  ngOnInit(): void {
    this.initForm()
    // console.log(this.desglose)
    if(!!this.desglose.id || !!this.desglose.aceptante){
      this.loadForm(this.desglose)
    }
  }

  initForm(): void {
    this.desgloseForm = this.formbuild.group({
      monto                  : new FormControl(0, Validators.required),
      tem_soles              : new FormControl(0, Validators.required),
      tem_dolares            : new FormControl(0, Validators.required),
      anticipo               : new FormControl(0, Validators.required),
      mora                   : new FormControl(0, Validators.required),
      comision_estructuracion: new FormControl(0, Validators.required)
    });
  }

  loadForm(data = null){

    if(!data){
      return
    }

    this.desglosePropuesta = data
    let controles = this.desgloseForm.controls

    for (let prop in controles){
      this.desgloseForm.get(prop).setValue(data[prop])
    }

    if(this.readonly)
      this.desgloseForm.disable()
    else 
      this.desgloseForm.enable()
    
  }

  guardarDesglose(){
    this.loading = true

    let dataBody = {
      propuesta : this.propuesta,
      operacion : this.operacion,
      aceptante : this.aceptante.id,
      ...this.desgloseForm.value
    }
    this.propuestaService.crearDesglose(dataBody).then((res: any)=>{
      this.desglosePropuesta = res
      this.desglose = res
      this.loading = false
      this.deleteEvent.emit(true)
    }).catch( err => {
      if(err && err.error && err.error.linea_propuesta){
        this.toast.warning(err.error.linea_propuesta)
      }
  
      if(err && err.error && err.error.monto){
        console.log('Error monto')
        this.toast.warning(err.error.monto)
      }
      this.loading = false
    })

  }

  actualizarDesglose(){
    this.loading = true
    this.propuestaService.actualizarDesglose(this.desglosePropuesta.id,this.desgloseForm.value).then((res)=>{
      this.desglosePropuesta = res
      this.loading = false
      this.deleteEvent.emit(true)
    }).catch( err => {
      if(err && err.error && err.error.linea_propuesta){
        this.toast.warning(err.error.linea_propuesta)
      }
  
      if(err && err.error && err.error.monto){
        console.log('Error monto')
        this.toast.warning(err.error.monto)
      }
      this.loading = false
    })
  }

  err(err){
    let { error } = err
    console.log(this)


    if(err && err.error && err.error.linea_propuesta){
      this.toast.warning(err.error.linea_propuesta)
    }

    if(err && err.error && err.error.monto){
      console.log('Error monto')
      this.toast.warning(err.error.monto)
    }
    this.loading = false
  }

  eliminarDesglose(){
    this.propuestaService.eliminarDesglose(this.desglosePropuesta.id).then((res)=>{
      this.desglosePropuesta = res
      this.deleteEvent.emit(true)
    })
  }

}
