import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { RecaudacionService } from "app/core/services/factoring/recaudacion.service";
import { ToastService } from "app/core/services/toast/toast.service";

interface ExcedenteDevolver {
  id_devolver: number;
  monto_exceso: string;
  tipo_pagar: number;
}

@Component({
  selector: "app-devolver-excedentes-modal",
  templateUrl: "./devolver-excedentes-modal.component.html",
  styleUrls: ["./devolver-excedentes-modal.component.css"],
})
export class DevolverExcedentesModalComponent implements OnInit {
  @Input()
  tiposPagador: any[] = [];
  @Input()
  recaudacionId: number;
  @Input()
  monto_excedentes: number;
  @Input()
  excedentes: any[];
  @Input()
  pagadorRecaudacionTipo: number;
  @Input()
  pagadorRecaudacionId: number;
  @Input() esAplicacionDevolver: boolean = false;
  @Input() recaudacion: any;

  beneficiarios: any[] = [];
  deudores: any[] = [];
  faTimes = faTimes;
  pagadoresList: any[] = [];
  myForm: FormGroup;
  listaDevolver: any[] = [];
  validado: boolean = false;
  dataExcedentesDevolver: ExcedenteDevolver[] = null;

  @Output()
  successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private recaudacionService: RecaudacionService,
    public toast: ToastService,
  ) {}

  ngOnInit() {
    this.initForm();
    this.obtenerClientes();
    this.obtenerAceptantes();
  }

  initForm() {
    this.myForm = this.formBuilder.group({
      pagador: [null, [Validators.required]],
      monto_excedentes: [this.monto_excedentes, [Validators.required]],
    });
  }

  onDevolverKeyUp(event: Event) {
    let inptMonto = event.target as HTMLInputElement;
    // se usa replace debido al "thousandSeparator"
    const montoDevolver = parseFloat((event.target as HTMLInputElement).value.replace(/,/g, ""));

    const tablaExcedentesDevolver = inptMonto.parentNode.parentNode.parentNode as HTMLTableElement;
    const montoExcedentesDevolverTabla = Array.from(tablaExcedentesDevolver.rows)
      .map(row => {
        const valorInpt = (row.childNodes[2].childNodes[0] as HTMLInputElement).value;
        if (valorInpt) {
          return parseFloat(valorInpt.replace(/,/g, ""));
        }
        return 0.0;
      })
      .reduce((acc, inptCell) => acc + inptCell, 0);

    const montoTablaCentavos = this.conversionDineroToCentavos(montoExcedentesDevolverTabla);
    const montoTotalCentavos = this.conversionDineroToCentavos(this.monto_excedentes);
    if (montoTablaCentavos > montoTotalCentavos) {
      // cambiar el monto del input que mandó el evento
      const diferencia = montoTablaCentavos - montoTotalCentavos;
      const nuevoMontoDevolver = this.conversionDineroToCentavos(montoDevolver) - diferencia;
      inptMonto.value = this.conversionCentavoToDinero(nuevoMontoDevolver);
      this.toast.warning(
        "El monto a devolver es mayor al monto calculado en la aplicación, se hizo el ajuste automático",
      );
    }

    if (montoTablaCentavos < montoTotalCentavos) {
      this.validado = false;
      return;
    }

    this.dataExcedentesDevolver = this.formatearDataExcedentesDevolver(tablaExcedentesDevolver);
    this.validado = true;
  }

  formatearDataExcedentesDevolver(tabla: HTMLTableElement): ExcedenteDevolver[] {
    // tipo_pagar = 1 devolver excedentes a aceptante
    // tipo_pagar = 2 devolver excedentes a cliente
    const dataExcedentesDevolver = Array.from(tabla.rows)
      .map(row => {
        const valorInpt = (row.childNodes[2].childNodes[0] as HTMLInputElement).value;
        let tipo_pagar = parseInt(row.children[0].id.replace("tipo_pagar_", ""));
        let monto_exceso = "0";
        if (valorInpt) {
          monto_exceso = valorInpt.replace(/,/g, "");
        }
        const id_devolver = parseInt(row.id.split("_")[1]);
        return { id_devolver, monto_exceso, tipo_pagar };
      });

    return dataExcedentesDevolver;
  }

  conversionDineroToCentavos(dinero: number): number {
    return Math.round(dinero * 100);
  }

  conversionCentavoToDinero(centavos: number): string {
    // el regex es usado para poner el separador "," en los miles
    return (centavos / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  obtenerClientes() {
    if (this.esAplicacionDevolver) {
      if (this.recaudacion.tipo_pagador == 2) {
        this.listaDevolver = this.listaDevolver.concat([{
          'id': this.recaudacion.cliente,
          'nombre': this.recaudacion.pagador_nombre,
          'tipo_pagar': this.recaudacion.tipo_pagador,
          'tipo_pagar_descripcion': this.recaudacion.tipo_pagador_nombre,
        }])
      }
      if (this.recaudacion.tipo_pagador == 1) {
        this.recaudacionService.obtenerClientesAceptante(`${this.recaudacion.aceptante}`)
          .then(res => {
            this.listaDevolver = this.listaDevolver.concat(res['results'].map(e => Object.assign(e, {
              'tipo_pagar': 2,
              'tipo_pagar_descripcion': 'Cliente'
            })));
          }).catch(error => {
            console.error(error);
          });
      }
      return
    }
    this.recaudacionService.obtenerClientesRecaudacion(`${this.recaudacionId}`)
      .then(res => {
        this.beneficiarios = res["results"];
        // devolver excedentes a cliente
        const clientes = this.beneficiarios.map(beneficiario => {
          beneficiario.tipo_pagar = 2;
          beneficiario.tipo_pagar_descripcion = "Cliente";
          return beneficiario;
        });

        this.listaDevolver = this.listaDevolver.concat(clientes);
      }).catch(error => {
        console.error(error);
      });
  }

  obtenerAceptantes() {
    if (this.esAplicacionDevolver) {
      if (this.recaudacion.tipo_pagador == 1) {
        this.listaDevolver = this.listaDevolver.concat([{
          'id': this.recaudacion.aceptante,
          'nombre': this.recaudacion.pagador_nombre,
          'tipo_pagar': this.recaudacion.tipo_pagador,
          'tipo_pagar_descripcion': this.recaudacion.tipo_pagador_nombre,
        }])
      }
      return
    }
    this.recaudacionService.obtenerAceptantesRecaudacion(`${this.recaudacionId}`)
      .then(res => {
        this.deudores = res["results"];
        // devolver excedentes a aceptante
        const aceptantes = this.deudores.map(deudor => {
          deudor.tipo_pagar = 1;
          deudor.tipo_pagar_descripcion = "Aceptante";
          return deudor;
        });
        this.listaDevolver = this.listaDevolver.concat(aceptantes);
      })
      .catch(error => {
        console.error(error);
      });
  }

  selectedTipoEvent(tipo) {
    this.myForm.controls["pagador"].setValue(null);
    const ID = (tipo.id) ? tipo.id : tipo;
    switch (ID) {
      case 1:
        this.pagadoresList = this.deudores;
        break;
      case 2:
        this.pagadoresList = this.beneficiarios;
        break;
      default:
        this.pagadoresList = [];
        break;
    }
  }

  onSubmit() {
    // this.successEmit.emit(this.myForm.value);
    this.successEmit.emit(this.dataExcedentesDevolver);
    this.activeModal.close();
  }
}
