import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'app/shared/shared.module';
import { ProcesosPendientesComponent } from './partials/procesos-pendientes/procesos-pendientes.component';
import { ProcesosCorrectosComponent } from './partials/procesos-correctos/procesos-correctos.component';
import { ProcesosFallidosComponent } from './partials/procesos-fallidos/procesos-fallidos.component';
import { ProcesosSinRespuestaComponent } from './partials/procesos-sin-respuesta/procesos-sin-respuesta.component';
import { ProcesosComponent } from './pages/procesos/procesos.component';
import { CavaliRoutingModule } from './cavali-routing.module';
import { ResultadoFacturasModalComponent } from './modals/resultado-facturas-modal/resultado-facturas-modal.component';
import { ResultadosComponent } from './pages/resultados/resultados.component';


@NgModule({
  declarations: [
    ProcesosPendientesComponent,
    ProcesosCorrectosComponent,
    ProcesosFallidosComponent,
    ProcesosSinRespuestaComponent,
    ProcesosComponent,
    ResultadoFacturasModalComponent,
    ResultadosComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgSelectModule,
    CavaliRoutingModule,
  ]
})
export class CavaliModule { }
