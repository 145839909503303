import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { ReportesService } from 'app/core/services/reportes.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { utils, writeFile } from 'xlsx';
import Swal from 'sweetalert2';
import { functions } from '../../../../core/helpers/functions';
import { AuthService } from 'app/core/services/auth/auth.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { OrdenesPagoService } from 'app/core/services/ordenes-pago.service';
import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';
@Component({
  selector: 'app-reporte-cartera',
  templateUrl: './reporte-cartera.component.html',
  styleUrls: ['./reporte-cartera.component.css']
})

export class ReporteCarteraComponent implements OnInit {

  public reporteForm : FormGroup;
	public headerTable: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
  public searchstring: string = '';
  public filtrosTemporales: any;

  public rowData = [];

  public estados = []
  public oficiales_negocio = []
  public empresas = []

  public tipo_pagadores = [
    {
      'id': 1,
      'descripcion': 'Aceptante'
    },
    {
      'id': 2,
      'descripcion': 'Cliente'
    }
  ]
  public columnas = [
    'ejecutivo',
    'gestor',
    'cliente_ruc',
    'cliente',
    'deudor_ruc',
    'deudor',
    'nro_ope',
    'producto',
    'tipo_doc',
    'numero_documento',
    'fecha_cesion',
    'monto_doc_deudor',
    'monto_no_financiado',
    'monto_ant_cliente_monto_financiado',
    'estado',
    'fecha_vencimiento',
    'fecha_cobrado',
    'fecha_mora',
    'fecha_prorrogado',
    'fecha_protestado',
    'monto_soles',
    'monto_dolares',
    'fecha_pago',
    'forma_pago',
    'tipo_pagador',
    // 'fecha_estado',
    'dias_mora',
    'monto_mora',
    'fecha_compromiso_pago',
    'gestion',
    'empresa'
  ]
  public lineas = []

  public generando: boolean = false;
  
  public filtros: any = {
    nro_operacion: undefined,
    nro_recaudacion:undefined,
    cliente_nombre: undefined,
    cliente_ruc: undefined,
    deudor_nombre: undefined,
    deudor_ruc: undefined,
    tipo_pagador: undefined,
    linea: undefined,
  };

  constructor( 
    public cxcService       : CuentasService,
    private factoringService: FactoringService,
    public formbuild        : FormBuilder,
    public documentosCarteraService: DocumentosCarteraService,
    public authService      : AuthService,
    public config : ConfigurationService,
    public ordenes : OrdenesPagoService,
    public recaudacion: RecaudacionService
  ) {}

  ngOnInit(): void {
    this.getReporteCartera({page:1, per_page:this.pageTablePerPages, filtros:{}})
    this.obtenerVariablesFiltros()
  }

  loadTableColumns(
    estados, 
    empresas, 
    lineas,
    oficiales,
    tiposDocs, 
    tiposPagos,
    tiposPagadores
    ) {
    let idOficiales = this.authService.user.equipo.map((item)=> item.id)
    let filtrados = oficiales.filter((item)=>  { return idOficiales.includes(item.id) || item.id == this.authService.user.id })
    let cols = this.columnas.map((item)=> {
      return {
        headerName: item,
        class: 'text-center',
        field: item
      }
    })
    this.headerTable = [{
        headerName: 'N°',
        field: 'index',
        pipe: 'indexcol',
      },...cols
    ]


    let setHeader = (prop,obj) => {
      let Index = this.headerTable.findIndex((el)=> el.field == prop)
      if(Index >= 0){
        let oldHeader = this.headerTable[Index]
        this.headerTable[Index] = {
          ...oldHeader , 
          ...obj
        }
      }
    }

    let configs = {}

    configs = {
      filterProp : 'oficial_negocio',
      headerName : 'Ejecutivo',
      filterable : true,
      class: 'text-center ejecutivo-class',
      filterInput: true,
      filterDate: false
    }
    setHeader('ejecutivo',configs)


    configs = {
      filterProp : 'asistente_cobranza',
      headerName : 'Gestor',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('gestor',configs)

    configs = {
      filterProp : 'cliente_ruc',
      headerName : 'Ruc',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('cliente_ruc',configs)

    configs = {
      filterProp : 'cliente_nombre',
      headerName : 'Cliente',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('cliente',configs)

    configs = {
      filterProp : 'deudor_ruc',
      headerName : 'Ruc',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('deudor_ruc',configs)

    configs = {
      filterProp : 'deudor_nombre',
      headerName : 'Deudor',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('deudor',configs)

    configs = {
      filterProp : 'operacion',
      headerName : 'Nro Operación',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('nro_ope',configs)

    configs = {
      filterProp : 'producto',
      headerName : 'Producto',
      filterable : true,
      filterInput: false,
      filterSelectItems: lineas,
      filterItemsProps: {
        value: 'id',
        label: 'descripcion'
      },
    }
    setHeader('producto',configs)

    configs = {
      filterProp : 'tipo_documento',
      headerName : 'Tipo Doc',
      filterable : true,
      filterInput: false,
      filterDate: false,
      filterSelectItems: tiposDocs,
      filterItemsProps: {
        value: 'id',
        label: 'descripcion'
      },
    }
    setHeader('tipo_doc',configs)

    configs = {
      filterProp : 'numero_documento',
      headerName : 'Nro Doc',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('numero_documento',configs)

    configs = {
      filterProp : 'fecha_desembolso',
      headerName : 'Fecha Cesión',
      pipe: 'date',
      filterable : true,
      filterInput: false,
      filterDate: true,
    }
    setHeader('fecha_cesion',configs)

    configs = {
      filterProp : 'monto_doc_deudor',
      headerName : 'Monto Deudor',
      filterable : false,
      filterInput: true,
      filterDate: false,
    }
    setHeader('monto_doc_deudor',configs)

    configs = {
      filterProp : 'monto_no_financiado',
      headerName : 'Monto No Financiado',
      filterable : false,
      filterInput: true,
      filterDate: false,
    }
    setHeader('monto_no_financiado',configs)

    configs = {
      filterProp : 'monto_ant_cliente_monto_financiado',
      headerName : 'Monto Ant Cliente',
      filterable : false,
      filterInput: true,
      filterDate: false,
    }
    setHeader('monto_ant_cliente_monto_financiado',configs)

    configs = {
      filterProp : 'estado',
      headerName : 'Estado',
      filterable : true,
      filterInput: false,
      filterDate: false,
      filterSelectItems: estados,
      filterItemsProps: {
        value: 'id',
        label: 'descripcion'
      },
    }
    setHeader('estado',configs)

    configs = {
      filterProp : 'fecha_vencimiento',
      headerName : 'Fecha Vencimiento',
      pipe: 'date',
      filterable : true,
      filterInput: false,
      filterDate: true,
    }
    setHeader('fecha_vencimiento',configs)

    configs = {
      filterProp : 'fecha_protestado',
      headerName : 'Fecha Protestado',
      pipe: 'date',
      filterable : true,
      filterInput: false,
      filterDate: true,
    }
    setHeader('fecha_protestado',configs)

    configs = {
      filterProp : 'fecha_prorrogado',
      headerName : 'Fecha Prorrogado',
      pipe: 'date',
      filterable : true,
      filterInput: false,
      filterDate: true,
    }
    setHeader('fecha_prorrogado',configs)

    configs = {
      filterProp : 'fecha_cobrado',
      headerName : 'Fecha Cobrado',
      pipe: 'date',
      filterable : true,
      filterInput: false,
      filterDate: true,
    }
    setHeader('fecha_cobrado',configs)

    configs = {
      filterProp : 'fecha_mora',
      headerName : 'Fecha Mora',
      pipe: 'date',
      filterable : true,
      filterInput: false,
      filterDate: true,
    }
    setHeader('fecha_mora',configs)

    configs = {
      filterProp : 'monto_soles',
      headerName : 'Monto Soles',
      filterable : false,
      filterInput: true,
      filterDate: false,
    }
    setHeader('monto_soles',configs)

    configs = {
      filterProp : 'monto_dolares',
      headerName : 'Monto Dólares',
      filterable : false,
      filterInput: true,
      filterDate: false,
    }
    setHeader('monto_dolares',configs)

    configs = {
      filterProp : 'fecha_pago',
      headerName : 'Fecha Pago',
      filterable : true,
      filterInput: false,
      pipe: 'date',
      filterDate: true,
    }
    setHeader('fecha_pago',configs)

    configs = {
      filterProp : 'forma_pago',
      headerName : 'Forma Pago',
      filterable : true,
      filterInput: false,
      filterDate: false,
      filterSelectItems: tiposPagos,
      filterItemsProps: {
        value: 'id',
        label: 'descripcion'
      },
    }
    setHeader('forma_pago',configs)

    configs = {
      filterProp : 'tipo_pagador',
      headerName : 'Tipo Pagador',
      filterable : true,
      filterInput: false,
      filterDate: false,
      filterSelectItems: tiposPagadores,
      filterItemsProps: {
        value: 'id',
        label: 'descripcion'
      },
    }
    setHeader('tipo_pagador',configs)

    configs = {
      filterProp : 'dias_mora',
      headerName : 'Días Mora',
      filterable : false,
      filterInput: false,
      filterDate: false,
    }
    setHeader('dias_mora',configs)

    configs = {
      filterProp : 'monto_mora',
      headerName : 'Monto Mora',
      filterable : false,
      filterInput: false,
      filterDate: false,
    }
    setHeader('monto_mora',configs)

    configs = {
      filterProp : 'fecha_compromiso_pago',
      headerName : 'Fecha Compromiso Pago',
      filterable : false,
      pipe: 'date',
      filterInput: true,
      filterDate: false,
    }
    setHeader('fecha_compromiso_pago',configs)

    configs = {
      filterProp : 'asistente_cobranza',
      headerName : 'Gestión',
      filterable : false,
      filterInput: false,
      filterDate: false,
    }
    setHeader('gestion',configs)

    configs = {
      filterProp       : 'empresa',
      headerName       : 'Empresa',
      filterable       : true,
      filterInput: false,
      filterSelectItems: [
        {
          id: 1,
          nombre: 'Total Servicios'
        },
        {
          id: 2,
          nombre: 'Factoring'
        },
      ],
      filterItemsProps: {
        value: 'id',
        label: 'nombre'
      }
    }
    setHeader('empresa',configs)
  }

  loadTable(data) {
    this.rowData = data.results
		this.pageTableActual   = data.page_number
		this.pageTableTotal    = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages    = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  getReporteCartera({page, per_page, filtros}) {
    console.log(filtros)
    this.factoringService.getReporteCartera(
      filtros.oficial_negocio, 
      filtros.asistente_cobranza, 
      filtros.cliente_nombre, 
      filtros.deudor_nombre, 
      filtros.operacion, 
      filtros.producto, 
      filtros.tipo_documento, 
      filtros.fecha_desembolso__gte,  //##########
      filtros.fecha_desembolso__lte,  //##########
      filtros.estado, 
      filtros.fecha_vencimiento__gte, //##########
      filtros.fecha_vencimiento__lte, //##########
      filtros.fecha_pago__gte, //##########
      filtros.fecha_pago__lte, //##########
      filtros.forma_pago, 
      filtros.tipo_pagador, 
      filtros.fecha_compromiso_pago__gte,  //##########
      filtros.fecha_compromiso_pago__lte,  //##########
      filtros.empresa,
      filtros.numero_documento, 
      filtros.cliente_ruc, 
      filtros.deudor_ruc, 
      filtros.fecha_protestado__gte, 
      filtros.fecha_protestado__lte, 
      filtros.fecha_prorrogado__gte, 
      filtros.fecha_prorrogado__lte, 
      filtros.fecha_cobrado__gte, 
      filtros.fecha_cobrado__lte, 
      filtros.fecha_mora__gte, 
      filtros.fecha_mora__lte, 
      page, 
      per_page,
      ).then(res=>{
        this.loadTable(res)
    })
  }

  obtenerVariablesFiltros() {
    let estados  = this.documentosCarteraService.obtenerEstadosCarteras();
    let empresas = this.factoringService.obtenerEmpresasCodigo();
    let lineas   = this.factoringService.obtenerTiposDeLineas();
		let oficialesNegocio = this.factoringService.obtenerResponsables(3);
    let tipoDocs = this.config.obtenerTiposDetalles()
    let tiposPagos = this.ordenes.obtenerFormasDePago()
    let tiposPagadores = this.recaudacion.obtenerTiposPagador()
    // No es necesario obtenerlos ya que al haber un defaultValue simula un click en un filtrar y los obtiene. Si lo agrego tambien aca hace dos llamadas
    Promise.all([
      estados, 
      empresas, 
      lineas,
      oficialesNegocio,
      tipoDocs,
      tiposPagos,
      tiposPagadores
    ]).then(
      (res: any[]) => {
        let estados = res[0]['results'];
        let empresas = res[1]['results'];
        let lineas = res[2]['results']
        this.loadTableColumns(
          estados, 
          empresas, 
          lineas,
          res[3]['results'],
          res[4]['results'],
          res[5]['results'],
          res[6]['results'],
          );
      }
    );
  }


  goToPage({page, per_page, filtros}){
    console.log(filtros)
    this.filtros = filtros
    this.getReporteCartera({page, per_page, filtros})
	}


  // Descargar el exel 
  async openExcelModal() {
    const result = await Swal.fire({
      title: 'Generar Excel',
      text: "Elige si deseas generar el excel de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    console.log(result)
    if (result.isDenied) {
      this.generarExcel(this.rowData)
    } else if(result.isConfirmed) {
      this.generarExcelCompleto()
    }
  }


  async generarExcelCompleto() {
    const page_size = 2000
    let page = 1
    let data = []

    this.generando = true
    while (this.generando) {
      try {
        let res = await  this.factoringService.getReporteCartera(
          this.filtros.oficial_negocio, 
          this.filtros.asistente_cobranza, 
          this.filtros.cliente_nombre, 
          this.filtros.deudor_nombre, 
          this.filtros.operacion, 
          this.filtros.producto, 
          this.filtros.tipo_documento, 
          this.filtros.fecha_desembolso__gte,  //##########
          this.filtros.fecha_desembolso__lte,  //##########
          this.filtros.estado, 
          this.filtros.fecha_vencimiento__gte, //##########
          this.filtros.fecha_vencimiento__lte, //##########
          this.filtros.fecha_pago__gte, //##########
          this.filtros.fecha_pago__lte, //##########
          this.filtros.forma_pago, 
          this.filtros.tipo_pagador, 
          this.filtros.fecha_compromiso_pago__gte,  //##########
          this.filtros.fecha_compromiso_pago__lte,  //##########
          this.filtros.empresa,
          this.filtros.numero_documento, 
          this.filtros.cliente_ruc, 
          this.filtros.deudor_ruc, 

          this.filtros.fecha_protestado__gte, 
          this.filtros.fecha_protestado__lte, 
          this.filtros.fecha_prorrogado__gte, 
          this.filtros.fecha_prorrogado__lte, 
          this.filtros.fecha_cobrado__gte, 
          this.filtros.fecha_cobrado__lte, 
          this.filtros.fecha_mora__gte, 
          this.filtros.fecha_mora__lte, 
          page, 
          page_size,
        )

        data = data.concat (res['results'] )
        page++

        if (res['page_number'] == res['num_pages']) {
          this.generando = false
          this.generarExcel(data)
        }
      } catch (ex) {
        console.log(ex)
        this.generando = false
      }
    }
  }

  generarExcel(data) {
      // Se deben definir las columnas que se van a mostrar
      data = data.map((item)=>{
        let obj = {}
        this.columnas.forEach((col)=>{
          obj[col] = item[col]
        })
        return obj
      })
      var header = this.columnas;
      var columns = header.length - 1
    
      const wb = utils.book_new();
      
      var Heading = [
        [
          "EJECUTIVO", 
          "GESTOR", 
          "RUC",
          "CLIENTE",
          "RUC",
          "DEUDOR",
          "NRO OPERACION",
          "PRODUCTO",
          "TIPO DOC",
          "NRO DOC",
          "FECHA CESION",
          "MONTO DEUDOR",
          "MONTO NO FINANCIADO",
          "MONTO ANT CLIENTE",
          "ESTADO",
          "FECHA DE VENCIMIENTO",
          "FECHA COBRADO",
          "FECHA MORA",
          "FECHA PRORROGADO",
          "FECHA PROTESTADO",
          "MONTO SOLES",
          "MONTO DOLARES",
          "FECHA PAGO",
          "FORMA PAGO",
          "TIPO PAGADOR",
          "DIAS MORA",
          "MONTO MORA",
          "FECHA COMPROMISO PAGO",
          "GESTION",
          "EMPRESA"
        ],
      ];
      
      // const ws = utils.json_to_sheet(data, { header,skipHeader: true });
      
      const ws = utils.json_to_sheet(data, { header,skipHeader: false });
      ws['!ref'] = utils.encode_range({s: {c:0,r:0},e: {c:columns, r:data.length}})
      utils.sheet_add_aoa(ws, Heading);
      utils.book_append_sheet(wb, ws, 'Hoja 1');
  
      writeFile(wb,`Reporte - ${new Date().toLocaleString()}.xlsx`);
  }
}