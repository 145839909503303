import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { FacturacionService } from 'app/core/services/facturacion/facturacion.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { FacturaDetalleModalComponent } from '../../partials/modals/factura-detalle-modal/factura-detalle-modal.component';
import { NotaCreditoModalComponent } from '../nota-credito-modal/nota-credito-modal.component';
import { AuthService } from '../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-facturado-modal',
  templateUrl: './facturado-modal.component.html',
  styleUrls: ['./facturado-modal.component.css']
})
export class FacturadoModalComponent implements OnInit {
  faTimes = faTimes
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = '';
  public canCreate: boolean = false;

  myForm: FormGroup;
  estados: any[] = [];
  tiposPagador: any[] = [];
  monedas: any[] = [];
  @Input() factura: any;

  public hiddenReferencia:boolean = false;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public formbuild: FormBuilder,
    private cuentaService: CuentasService,
    public configs: DocschecklistService,
    private facturacionService: FacturacionService,
    private authService: AuthService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    let estados = this.cuentaService.obtenerEstados();
    let tiposPagador = this.cuentaService.obtenerTiposPagador();
    let monedas = this.configs.obtenerMonedas()


    Promise.all([estados, tiposPagador, monedas]).then((res) => {
      this.tiposPagador = res[0]['results'];
      this.estados = res[1]['results'];
      this.monedas = res[2]['results'];
      this.setForm(this.factura);
      // console.log( 'FACTURA', this.factura )
      this.myForm.disable();
      if( this.factura.tipo === 2 || this.factura.tipo === 3 ) this.hiddenReferencia = true;
      this.obtenerDetalles();
    }, err => {
      console.error(err);
    });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      tipo_pagador: [null, [Validators.required]],
      tipo_pagador_descripcion: [null, [Validators.required]],
      pagador_nombre: [null, [Validators.required]],
      pagador_ruc: [null, [Validators.required]],
      concepto: [null, [Validators.required]],
      fecha_facturado: [null, [Validators.required]],
      fecha_pago: [null, [Validators.required]],
      moneda: [null, [Validators.required]],
      monto: [null, [Validators.required]],
      igv: [null, [Validators.required]],
      total: [null, [Validators.required]],
      glosa: [null, []],
      numeracion: [null , []],
      referencia_numeracion: [null, []]
    });
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        switch (key) {
          case 'fecha_facturado':
            this.myForm.controls[key].setValue(this.parseDate(item[key]));
            continue;
            break;
          case 'fecha_pago':
            this.myForm.controls[key].setValue(this.parseDate(item[key]));
            continue;
            break;
        }
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  obtenerDetalles() {
    this.facturacionService.obtenerFacturasDetalles(this.factura.id, '', 1, 1000).then(resp => {
      console.log(resp);
      this.initVars();
      this.loadTable(resp);
    }, err => {
      console.error(err);

    });
  }

  loadTable(data) {

    this.rowData = data.results
    this.pageTableActual = data.page_number
    this.pageTableTotal = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }

  initVars() {
    let columns = [
      // {
      //   headerName: 'N°', 
      //   field     : 'id' ,
      //   sortable  : true,
      // },
      {
        headerName: 'Nro CxC',
        field: 'cuenta_cobrar',
        class: 'text-center',
        sortable: true,
      },
      {
        headerName: 'Monto',
        field: 'monto',
        class: 'text-center',
        pipe: 'currency_detail',
        moneda: 'moneda',
        sortable: true,
        moneda_detail: this.myForm.controls.moneda.value,
      },
      {
        headerName: 'IGV',
        field: 'igv',
        class: 'text-center',
        pipe: 'currency_detail',
        moneda: 'moneda',
        sortable: true,
        moneda_detail: this.myForm.controls.moneda.value,
      },
      {
        headerName: 'Total',
        field: 'total',
        class: 'text-center',
        pipe: 'currency_detail',
        moneda: 'moneda',
        sortable: true,
        moneda_detail: this.myForm.controls.moneda.value,
      },
      {
        headerName: 'Glosa',
        field: 'glosa',
        class: 'text-center',
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }

  parseDate(date) {
    if (!date)
      return null;

    let dateNow = new Date();
    let yy = date.split('-')[0];
    let mm = (date.split('-')[1] - 1);
    // let dd = date.split('-')[2];
    let dd = (date.split('-')[2]).substring(0, 2);

    let d = new Date(yy, mm, dd, dateNow.getHours());

    return d;
  }

  editDetalle(row) {
    const modalRef = this.modalService.open(FacturaDetalleModalComponent, {

    });

    modalRef.componentInstance.glosa = row;

    modalRef.componentInstance.successEmit.subscribe(result => {
      console.log(result);
      this.obtenerDetalles();
    },
      error => {

      });

    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  createNC() {
    const data = {
      tipo: this.factura.tipo,
      fecha_pago: this.factura.fecha_pago,
      moneda: this.factura.moneda,
      total: this.factura.total,
      estado: this.factura.estado,
      tipo_pagador: this.factura.tipo_pagador,
    };

    this.facturacionService.crearNotaCredito(this.factura.id, data).then(resp => {
      this.activeModal.close(false);
      setTimeout(() => {
        this.openNotaCredito(resp);
      }, 500);

    }, err => {
      console.error(err);

    })
  }

  openNotaCredito(factura) {
    const modalRef = this.modalService.open(NotaCreditoModalComponent, {
      size: 'xl'
    });

    modalRef.componentInstance.facturaIn = factura;

    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  get canAdd() {
		const perfilesPermitidos = [
		  this.authService.perfil.perfilAdmin,
		  this.authService.perfil.perfilJefeDeOperacionesID,
		  this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilAsistenteCobranzasID,
		]

		return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
	}

}
