import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { ToastService } from 'app/core/services/toast/toast.service';

@Component({
  selector: 'app-formato-carta-modificacion',
  templateUrl: './formato-carta-modificacion.component.html',
  styleUrls: ['./formato-carta-modificacion.component.css']
})
export class FormatoCartaModificacionComponent implements OnInit {
  
  myForm: FormGroup;
  estaciones: any[];


  @Input() beneficiario: number;
  @Input() operacion;
  @Input() formato_plantilla_url;

  @Input() numero_contrato;
  @Input() montoOperacion;
  @Input() monedaOperacion;
  @Input() monedaSimbolo;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private formatoService: FormatosService,
    private clientesService : ClientesService,
    private factoringService: FactoringService,
    public toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  async createForm() {
    const clienteId = (this.operacion.operacion.tipo_linea_solicitada == 1) ? this.operacion.beneficiario : this.operacion.operacion.beneficiario_factoring;

    this.myForm = this.formBuilder.group({
      fecha_actual: [{ value: new Date(), disabled: true }, Validators.required],
      nombre: [{ value: '', disabled: true }, Validators.required],
      domicilio: [{ value: '', disabled: true }, Validators.required],
      departamento: [{ value: '', disabled: true }, Validators.required],
      provincia: [{ value: '', disabled: true }, Validators.required],
      distrito: [{ value: '', disabled: true }, Validators.required],
      tipo_linea: [{ value: '', disabled: true }, Validators.required],
      id_linea: [{ value: '', disabled: true }, Validators.required],
      monto_linea: [{ value: '', disabled: true }, Validators.required],
      moneda_linea: [{ value: '', disabled: true }, Validators.required],
    });
    
    const cliente = await this.clientesService.obtenerCliente(clienteId)
    const lineas = await this.factoringService.obtenerLineas(clienteId)
    
    const linea = lineas['results'].find(el=>el.tipo==this.operacion.operacion.tipo_linea_solicitada)
    
    console.log(this.operacion.linea_propuesta_comite)
    this.myForm.controls.nombre.setValue(cliente['nombre'])
    this.myForm.controls.domicilio.setValue(cliente['domicilio_legal'])
    this.myForm.controls.departamento.setValue(cliente['departamento_nombre'])
    this.myForm.controls.provincia.setValue(cliente['provincia_nombre'])
    this.myForm.controls.distrito.setValue(cliente['distrito_nombre'])
    this.myForm.controls.tipo_linea.setValue(this.operacion.operacion.tipo_linea_solicitada == 1 ? 'Descuento' : 'Factoring')
    this.myForm.controls.id_linea.setValue(this.numero_contrato)
    this.myForm.controls.monto_linea.setValue(linea['linea_actual'])
    this.myForm.controls.moneda_linea.setValue(linea['moneda'] == 1 ? "S/." : "US$")
    

    
  }

  async onSubmit(tipo) {
    if (!this.myForm.invalid) {
      var model = {
        // "archivo_plantilla": this.formato_plantilla_url,
        // "fecha_actual": this.myForm.get("fecha_actual").value,
        "nombre": this.myForm.get("nombre").value,
        "domicilio": this.myForm.get("domicilio").value,
        "departamento": this.myForm.get("departamento").value,
        "provincia": this.myForm.get("provincia").value,
        "distrito": this.myForm.get("distrito").value,
        "tipo_linea": this.myForm.get("tipo_linea").value, // Descuento o Factoring
        "id_linea": this.myForm.get("id_linea").value, // Ver bien que ID poner
        "monto_linea": this.myForm.get("monto_linea").value,
        "moneda_linea": this.myForm.get("moneda_linea").value, // "S/" o "US$"
      }

      if (tipo == 'pdf') {
        let correlativo = await this.formatoService.generarCorrelativo()
        model["correlativo"] = correlativo['codigo']
        model["id_linea"] = model.id_linea === undefined ? 'NO EXISTE CONTRATO' : model.id_linea
        const query = Object.keys(model).reduce((acc,key)=>`${acc}&${key}=${encodeURIComponent(model[key])}`, "")
        window.open(`#/formatos/imprimir/carta-modificacion?${query}`);

      } else if (tipo == 'word') {

        model["archivo_plantilla"] = this.formato_plantilla_url
        model["fecha_actual"] = this.myForm.get("fecha_actual").value
        this.formatoService.generarFormatoCartaModificacion(model).then(
         (x: any) => {
           var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
           var downloadURL = window.URL.createObjectURL(blob);
           var link = document.createElement('a');
           link.href = downloadURL;
           link.download = "CartaModificacion.docx";
           link.click();
           this.activeModal.close(model);
         }, (reason) => {
           this.activeModal.close(model);
         });
      }
    }
    else {
      this.toast.warning("El formulario no está completo.");
    }
  }

}
