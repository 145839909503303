import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';

@Component({
  selector: 'app-add-directorie',
  templateUrl: './add-directorie.component.html',
  styleUrls: ['./add-directorie.component.css']
})
export class AddDirectorieComponent implements OnInit {
  faTimes=faTimes;
  myForm: FormGroup;
  @Input() clienteId = 0;
  paises : any[];
  @Input() directorId = 0;
  director : any;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  personas: any[];

  constructor(
    public formbuild        : FormBuilder,
    public activeModal      : NgbActiveModal,
    private clientesService	: ClientesService,
  ) {
    this.initForm();
   }

  ngOnInit(): void {
    this.obtenerPaises();

    if (this.directorId) {
      this.obtenerDirector();
    }
  }

  obtenerPaises() {
    this.clientesService.obtenerPaises()
    .then(res => {
      this.paises = res['results'];
    })
    .catch(error => {
      console.error(error);
    });
  }

  obtenerDirector() {
    this.clientesService.obtenerDirector(this.directorId)
    .then(res => {
      this.director = res;
      this.setForm(res);
    })
    .catch(error => {
      console.error(error);
    });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre              : [null, [Validators.required]],
      dni                 : [null, [Validators.required, Validators.maxLength(50)]],
      cargo               : [null, []],
      nacionalidad        : [null, []],
      fecha_ingreso       : [null, []],
    });
  }

  get f() {
    return this.myForm.controls;
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        if (key == 'fecha_ingreso') {
          this.myForm.controls[key].setValue(this.parseDate(item[key]));
          continue;
        }

        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  onSubmit() {
    if(this.myForm.valid) {
      const data = this.myForm.value;
      data['cliente'] = this.clienteId;
      data['fecha_ingreso'] = this.formatDate(data['fecha_ingreso']);

      this.clientesService.guardarDirector(this.myForm.value, this.directorId)
      .then(res => {
        this.successEmit.emit();
        this.activeModal.close(false);
      }).
      catch(error => {
        this.activeModal.close(false);
      });
    }
  }

  formatDate(date){
    if (!(date instanceof Date))
        return;

    return date.getFullYear() + 
                "-" + (date.getMonth() + 1) + 
                "-" + date.getDate();
  }

  parseDate(date) {
    if (!date)
      return null;
    
    let dateNow = new Date();
    
    let yy = date.split('-')[0];
    let mm = (date.split('-')[1] - 1);
    let dd = date.split('-')[2];

    let d = new Date(yy, mm, dd, dateNow.getHours());
    console.log(d);
    return d;
  }

}
