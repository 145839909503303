import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { ClientesService } from "app/core/services/clientes/clientes.service";
import { AceptantesService } from "app/core/services/factoring/aceptantes.service";
import { BloquearModalComponent } from "app/shared/bloquear-modal/bloquear-modal.component";

@Component({
  selector: "app-add-aceptante",
  templateUrl: "./add-aceptante.component.html",
  styleUrls: ["./add-aceptante.component.css"],
})
export class AddAceptanteComponent implements OnInit {
  faExclamationTriangle = faExclamationTriangle;
  public active         = 1;
  public aceptanteID    = 0;
  bloqueado             : boolean = false;
  entidadData           : any;

  constructor(
    public aceptantesService: AceptantesService,
    public clientesService	 : ClientesService,
    public modalService     : NgbModal,
    public _activatedRoute  : ActivatedRoute,
    public authService      : AuthService
    ) {
    this._activatedRoute.params.subscribe(({id}) => {
      if (id) {
        this.aceptanteID = id;  
        this.loadData(id)
      }
    });
  }

  ngOnInit(): void {}

  loadData(id){
    this.aceptantesService.obtenerAceptanteById(id).then(( res:any )=>{
      this.entidadData = res
      this.bloqueado   = res.bloqueado
    })
  }

  bloquearCliente(){

    if(this.bloqueado){
      let bodyUnblock = {
        bloqueado      : false,
        motivo_bloqueo : ' ',
        fecha_bloqueo  : null,
        usuario_bloqueo: null,        
      }
      this.updateBlockState(bodyUnblock)
      return
    }

    const modalRef = this.modalService.open(BloquearModalComponent, {
      size: "lg",
    });

    modalRef.componentInstance.title       = 'Bloqueo de Aceptante'
    modalRef.componentInstance.entidad     = 'cliente'
    modalRef.componentInstance.entidadData = this.entidadData
  
      modalRef.result.then((bloqueoData) => {
        if (bloqueoData) {
          bloqueoData.bloqueado = true
          this.updateBlockState(bloqueoData)
        } else {
          this.bloqueado = false
        }
      });

  }

  updateBlockState(bloqueoData){
    this.aceptantesService.updateBloqueoUsuarioAceptante(bloqueoData, this.aceptanteID).then((res: any)=>{
      this.loadData(this.aceptanteID)
    })
  }

  get canEdit() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilOficialDeNegocioID,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilJefeDeAdmisionID,
      this.authService.perfil.perfilAnalistaRiesgosID,
      this.authService.perfil.perfilLegalID,
      this.authService.perfil.perfilAsesorLegalID,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilSubGerenciaComercialID,
      this.authService.perfil.perfilTesoreriaID,
      this.authService.perfil.perfilLiderDeTesoreriaID,
      this.authService.perfil.perfilRecepcionID,
      this.authService.perfil.perfilAsistenteCobranzasID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilLiderDeEquipo,
      this.authService.perfil.perfilAsistenteComercialID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  get canBlock() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilJefeDeAdmisionID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }
}
