import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';

@Component({
  selector: 'app-manage-recaudacion-cuentas',
  templateUrl: './manage-recaudacion-cuentas.component.html',
  styleUrls: ['./manage-recaudacion-cuentas.component.css']
})
export class ManageRecaudacionCuentasComponent implements OnInit {
  faTimes = faTimes;
  myForm: FormGroup;
  @Input() cuentaId = 0;
  cuenta: any;
  bancos: any[] = [];
  monedas: any[] = [];

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();
  empresas: any[] = [];

  constructor(
    public formbuild: FormBuilder,
    public activeModal: NgbActiveModal,
    private configurationService: ConfigurationService,
    public configs: DocschecklistService,
    private factoringService: FactoringService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {

    let bancos = this.configurationService.obtenerBancos('', 1, 1000);
    let monedas = this.configs.obtenerMonedas();
    this.factoringService.obtenerEmpresas().then((res) => {
      res['results'][1].disabled = true;
      this.empresas = res['results'];
    });

    Promise.all([bancos, monedas]).then(res => {
      this.bancos = res[0]['results'];
      this.monedas = res[1]['results'];
    }).catch(err => {
      console.error(err);

    });

    if (this.cuentaId) {
      this.obtenerCuenta();
    }
  }

  initForm() {
    this.myForm = this.formbuild.group({
      numero: [null, [Validators.required]],
      moneda: [null, [Validators.required]],
      banco: [null, [Validators.required]],
      empresa: [null , [Validators.required]],
    });

  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  obtenerCuenta() {
    this.configurationService.obtenerCuenta(this.cuentaId)
      .then(res => {
        this.cuenta = res;
        this.setForm(res);
      })
      .catch(error => {
        console.error(error);
      });
  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = this.myForm.value;

      this.configurationService.guardarCuenta(this.myForm.value, this.cuentaId)
        .then(res => {
          this.successEmit.emit();
          this.activeModal.close(true);
        }).
        catch(error => {
          this.activeModal.close(false);
        });
    }
  }

}
