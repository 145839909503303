import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './auth/auth.service';
import { ToastService } from './toast/toast.service';

@Injectable({
	providedIn: 'root'
})
export class RiesgosService {
	private apiUrl              : string = '';
	public gruposEconomicos     : string = 'clientes/gruposeconomicos/';
	public clientes             : string = 'clientes/clientes/';
	public operacionesRiesgos   : string = 'factoring/operaciones/riesgos/';
	public evaluacion           : string = 'factoring/operaciones/evaluaciones/';
	public comportamientoEmpresa: string = 'factoring/beneficiarios/comportamientoslaft/';
	public exposicion           : string = 'factoring/beneficiarios/lineas/';
	public empresasRelacionadas           : string = 'factoring/beneficiarios/';
	public beneficiarioDeudasDocumentos           : string = 'factoring/beneficiarios/deuda/documentoscartera/';
	public deudores           : string = 'factoring/deudores/riesgos/';
	public tiposCredito           : string = 'factoring/deudores/tiposcredito/';
	
	constructor(
		public appService: AppService,
		public toast     : ToastService,
		public spinner   : NgxSpinnerService,
		public http      : HttpClient,
		public auth      : AuthService
	) {
		this.apiUrl = this.appService.settings.API_base_url
	}
	
	/**
	* Habilita el loader para request a la API
	*/
	spinnerOn(){
		this.spinner.show()
	}
	
	/**
	* Desabilita el loader
	* @param mensaje Mensaje del toast
	* @param ok Tipo de mensaje, TRUE para success, FALSE para errores
	*/
	spinnerOff(mensaje: string = null, ok : boolean = true){
		this.spinner.hide()
		this.appService.notifyMe(mensaje,ok)
		
		if(mensaje && ok)
		this.toast.success(mensaje)
		if(mensaje && !ok)
		this.toast.warning(mensaje)
	}


	obtenerGruposEconomicos(body = null, id = null){
        const URL = this.apiUrl+
                this.gruposEconomicos;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}
	
	obtenerClientes(id = null, body = null){
		var URL = this.apiUrl;
		
		if(id){
			URL = URL+
				this.clientes+
				`${id}/`;
		} else {
			URL = URL+
				this.clientes;
		}

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}	

	obtenerOperacionesRiesgos(id = ''){
        var URL = this.apiUrl;

        if(id){
            URL = URL+
                this.operacionesRiesgos+
                `${id}/`;
        } else {
            URL = URL+
                this.operacionesRiesgos;
        }                

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });
    }	
    
	actualizarOperacionesRiesgos(id,body){
        var URL = this.apiUrl;

        URL = URL+
            this.operacionesRiesgos+
            `${id}/`;
       

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.put(URL,body);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });
    }
    
	actualizarOperacionesRiesgosPatch(id,body){
        var URL = this.apiUrl;

        URL = URL+
            this.operacionesRiesgos+
            `${id}/`;
       

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.patch(URL,body);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });
	}    

	obtenerEvaluaciones(body = null, id = null){
        const URL = this.apiUrl+
                this.evaluacion;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }	
    
    obtenerTiposCredito(body = null, id = null){
        const URL = this.apiUrl+
                this.tiposCredito;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}	
	
	obtenerComportamientoEmpresas(body = null, id = null){
        const URL = this.apiUrl+
                this.comportamientoEmpresa;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }
    
	obtenerEmpresasRelacionadas(grupo_economico){
        const URL = this.apiUrl+
                this.empresasRelacionadas+`?grupo_economico=${grupo_economico}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }  
    
	obtenerDeudoresRiesgo(cliente = '',grupo_economico = ''){
        const URL = this.apiUrl+
                this.deudores+`?grupo_economico=${grupo_economico}&cliente=${cliente}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }      
    
	obtenerBeneficiarioDeudadocumentos(id__in,moneda_deuda){
        const URL = this.apiUrl+
                this.beneficiarioDeudasDocumentos+`?id__in=${id__in}&moneda_deuda=${moneda_deuda}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}

    obtenerDeudasBeneficiario(beneficiario){
        const URL = this.apiUrl+
                `factoring/carteras/?beneficiario=${beneficiario}&estado__in=3`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}


	obtenerDocumentosAprobados(beneficiario = '', tipo = '', moneda = ''){
        const URL = this.apiUrl+
                this.exposicion+
                `?beneficiario=${beneficiario}`+
                `&tipo=${tipo}`+
                `&moneda=${moneda}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }	

    actualizarDeudor(deudor,body){
        const URL = this.apiUrl+
                this.deudores+`${deudor}/`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.patch(URL,body);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }    
    
	
}
	