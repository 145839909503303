import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

import { NotificacionesService } from 'app/core/services/notificaciones/notificaciones.service';

@Component({
  selector: 'app-reenviar',
  templateUrl: './reenviar.component.html',
  styleUrls: ['./reenviar.component.css']
})
export class ReenviarComponent implements OnInit {
  @Input() notificacion: any = null

  forma: FormGroup

  constructor(
    private notificacionesService: NotificacionesService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
  ) {
    this.forma = this.formBuilder.group({
      'destinatarios': ['', [Validators.required]],
      'destinatarios_raw': ['', [Validators.required]],
    })
    this.forma.get('destinatarios').valueChanges.subscribe(value => {
      value = value.toString()
      if (this.notificacion.tipo == 1) {
        this.forma.get('destinatarios_raw').setValue(JSON.stringify([(value || '').split(','), []]))
      } else {
        this.forma.get('destinatarios_raw').setValue(value)
      }
    })
  }

  ngOnInit(): void {
    this.forma.patchValue(this.notificacion)
  }

  onSubmit() {
    if (!this.forma.valid) return
    this.spinner.show()
    let notificacion = Object.assign({}, this.forma.value, { id: this.notificacion.id })
    this.notificacionesService.guardarNotificacion(notificacion).subscribe(
      (data: any) => {
        Object.assign(this.notificacion, data)
        this.activeModal.close(true)
      },
      res => console.log(res)
    ).add(() => this.spinner.hide())
  }

}
