import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { LegalService } from 'app/core/services/legal.service';
import { AddRecaudacionModalComponent } from 'app/modules/recaudacion/modals/add-recaudacion-modal/add-recaudacion-modal.component';

@Component({
  selector: 'app-docs-carteras-modal',
  templateUrl: './docs-carteras-modal.component.html',
  styleUrls: ['./docs-carteras-modal.component.css']
})
export class DocsCarterasModalComponent implements OnInit {
	faTimes = faTimes
  @ViewChild('documentoModal', { static: false }) public documentoModal;
	active           = 1;
	documento           :any;
	filasComentarios :any;
	public columnasRecaudacion: any[];
	public columnasProrrogas: any[];
	public formularioDocumento   : FormGroup;
	public bandejaRecaudos : any[];
	public bandejaProrrogas : any[];
	public minDate: Date = new Date();
	@Input() readOnly  		= false;
	@Output() nuevoComent = new EventEmitter<any>();
	@Output() editaComent = new EventEmitter<any>();
	@Output() eliminaComent = new EventEmitter<any>();
	@Output() cambios = new EventEmitter<any>();
	
	constructor(
		public formbuild    : FormBuilder,
		public factoring    : FactoringService,
		private authService : AuthService,
		private modalService: NgbModal,
		private legalService: LegalService,
	) {
		this.initForm({})
	}
	
	ngOnInit(): void {
	}
	
	initForm({
		cliente_nombre         = null,
		cliente_ruc            = null,
		estado_descripcion     = null,
		aceptante_nombre       = null,
		aceptante_ruc          = null,
		operacion              = null,
		tipo_documento_detalle = null,
		fecha_vencimiento      = null,
		monto_neto             = null,
		saldo                  = null,
		tipo_cambio_monto      = null,
		protestado             = null,
		compromiso_pago        = null,
		mora                   = 0,
		numero_documento_detalle = null,
		estado = null,
	}){
		this.formularioDocumento = this.formbuild.group({
			cliente_nombre          : [cliente_nombre ? cliente_nombre                        : ''],
			cliente_ruc             : [cliente_ruc ? cliente_ruc                              : ''],
			estado_descripcion      : [estado_descripcion ? estado_descripcion                : ''],
			aceptante_nombre        : [aceptante_nombre ? aceptante_nombre                    : ''],
			aceptante_ruc           : [aceptante_ruc ? aceptante_ruc                          : ''],
			operacion               : [operacion ? operacion                                  : ''],
			tipo_documento_detalle  : [tipo_documento_detalle ? tipo_documento_detalle        : ''],
			fecha_vencimiento       : [fecha_vencimiento ? this._formatDate(fecha_vencimiento): ''],
			mora                    : [mora ? mora                                            : 0],
			monto_neto              : [monto_neto ? monto_neto                                : ''],
			saldo                   : [saldo ? saldo                                          : ''],
			tipo_cambio_monto       : [tipo_cambio_monto ? tipo_cambio_monto                  : ''],
			protestado              : [protestado ? protestado                                : ''],
			compromiso_pago         : [compromiso_pago ? compromiso_pago                      : ''],
			numero_documento_detalle: [numero_documento_detalle ? numero_documento_detalle    : ''],
		});

		this.formularioDocumento.get('cliente_nombre').disable()
		this.formularioDocumento.get('cliente_ruc').disable()
		this.formularioDocumento.get('estado_descripcion').disable()
		this.formularioDocumento.get('aceptante_nombre').disable()
		this.formularioDocumento.get('aceptante_ruc').disable()
		this.formularioDocumento.get('operacion').disable()
		this.formularioDocumento.get('tipo_documento_detalle').disable()
		this.formularioDocumento.get('fecha_vencimiento').disable()
		this.formularioDocumento.get('mora').disable()
		this.formularioDocumento.get('monto_neto').disable()
		this.formularioDocumento.get('saldo').disable()
		this.formularioDocumento.get('tipo_cambio_monto').disable()
		this.formularioDocumento.get('numero_documento_detalle').disable()

		if(this.readOnly || (estado === 2)){
			this.formularioDocumento.get('protestado').disable()
			this.formularioDocumento.get('compromiso_pago').disable()		
		}

		this.columnasRecaudacion = [
			{
				headerName: 'N° aplicación', 
				field     : 'recaudacion' ,
				class     : 'text-center',
				sortable  : true,
				textField: 'recaudacion',
				pipe: 'actionable',
				actionableType: 'recaudacion',
			}, 
			{
				headerName: 'Fecha Pago', 
				field     : 'fecha_pago' ,
				sortable  : true,
				class     : 'text-center',
				pipe      : 'function',
				function : function(row,header){
					
					let split = row.fecha_pago.split('-')
					return `${split[2]}/${split[1]}/${split[0]}`
				}	
			},  	
			{
				headerName: 'Monto Pago', 
				field     : 'monto',
				sortable  : true,
				class     : 'text-center',
				pipe      : 'function',
				function : function(row,header){
					if(row.moneda_documento == 1){
						let number = new Intl.NumberFormat("en-IN",{minimumFractionDigits: 2}).format(Number(row.monto))
						return `S/ ${number}`
					}
					else{
						return `$ ${Number(row.monto).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
				}				
			}, 				
			{
				headerName: 'Pago', 
				field     : 'pago' ,
				sortable  : true,
				class     : 'text-center',
				pipe      : 'function',
				function : function(row,header){
					if(row.moneda_documento == 1){
						let number = new Intl.NumberFormat("en-IN",{minimumFractionDigits: 2}).format(Number(row.pago))
						return `S/ ${number}`
					}
					else{
						return `$ ${Number(row.pago).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
				}					
			},
			{
				headerName: 'Tipo Pagador', 
				field     : 'tipo_pagador_descripcion' ,
				sortable  : true,
				class     : 'text-center',
			}, 		
			{
				headerName: 'Medio Pago', 
				field     : 'medio_pago_descripcion' ,
				sortable  : true,
				class     : 'text-center',
			}, 		
			{
				headerName: 'Nro Pago', 
				field     : 'numero_pago' ,
				sortable  : true,
				class     : 'text-center',
			}, 									 				
		]

		this.columnasProrrogas = [
			{
				headerName: 'N° Prórroga', 
				field     : 'prorroga' ,
				class     : 'text-center',
				sortable  : true,
			}, 
			{
				headerName: 'Fecha Solicitud', 
				field     : 'fecha_solicitud' ,
				sortable  : true,
				class     : 'text-center',
				pipe      : 'function',
				function : function(row,header){
					
					let split = row.fecha_solicitud.split('-')
					return `${split[2].split('T')[0]}/${split[1]}/${split[0]}`
				}						
			},  	
			{
				headerName: 'Fecha Vencimiento', 
				field     : 'fecha_vencimiento',
				sortable  : true,
				class     : 'text-center',
				pipe      : 'function',
				function : function(row,header){
					
					let split = row.fecha_vencimiento.split('-')
					return `${split[2]}/${split[1]}/${split[0]}`
				}		
			}, 				
			{
				headerName: 'Días Prórroga', 
				field     : 'dias_prorroga' ,
				sortable  : true,
				class     : 'text-center',
				pipe      : 'function',
				function : function(row,header){
					return row.dias_prorroga + ' Días'
				}
			},	
			{
				headerName: 'Comentarios', 
				field     : 'comentario',
				sortable  : true,
				class     : 'text-center',			
			}, 													 				
		]		
	}
	
	initModal(row, comentarios,recaudos,prorrogas){

		this.documento = row
		this.refrescaComentarios(comentarios)
		this.bandejaRecaudos = recaudos
		this.bandejaProrrogas = prorrogas
		let hoy = new Date();

		if(row.compromiso_pago){
			let split = row.compromiso_pago.split('-').map((i)=>{
				return Number(i)
			});
			let date = new Date(split[0],(split[1]-1),split[2],hoy.getHours())
			row.compromiso_pago = date
		}
		this.initForm(row)
		this.displayModal()
	}

	nuevoComentario(coment){
		this.nuevoComent.emit(coment)
	}

	editarComentario(coment){
		this.editaComent.emit(coment)
	}
	
	eliminarComentario(coment){
		this.eliminaComent.emit(coment)
	}	
	
	displayModal(){
		this.modalService.open(this.documentoModal, {
			size: 'xl'
		}).result.then((response: any)=>{
			// console.log(response)
		}).catch((err)=>{
			// console.log(err)
			// console.log('Error modal')
		})
	}

	guardarCambios(){
		let obj : any = { protestado: this.formularioDocumento.get('protestado').value ? true : false };

		if(this.formularioDocumento.get('compromiso_pago').value){
			obj.compromiso_pago = this.formatDate(this.formularioDocumento.get('compromiso_pago').value)
		}

		this.cambios.emit(obj)
		this.modalService.dismissAll({})

	}

	refrescaComentarios(comentarios){
		this.filasComentarios = []
		this.filasComentarios = comentarios
	}

	formatDate(value: any){
		console.log(value);
		if(typeof value == 'object'){
			let year = value.getFullYear()
			let month = value.getMonth()
			let day = value.getDate()
			console.log(`${year}-${month}-${day}`);
			return `${year}-${month+1}-${day}`
		}
		let split = value.split('-')
		let Year  = Number(split[0])
		let month = Number(split[1])
		let day   = Number(split[2].split('T')[0])
		return `${Year}/${month}/${day}`
	}

	_formatDate(value){
		let split = value.split('-')
		let Year  = split[0]
		let month = split[1]
		let day   = split[2]
		return `${day}/${month}/${Year}`
	}	

	openRecaudacion(event) {

		const modalRef = this.modalService.open(AddRecaudacionModalComponent, {
		  ariaLabelledBy: "modal-basic-title",
		  size: "xl",
		});
	
		modalRef.componentInstance.recaudacionId = event.actionableType;
	
		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
		  console.log(reason);
		});
	}
}
