import { getLocaleDateFormat } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { AddAvalComponent } from '../../modals/add-aval/add-aval.component';

@Component({
  selector: 'app-avalesfiadores',
  templateUrl: './avalesfiadores.component.html',
  styleUrls: ['./avalesfiadores.component.css']
})
export class AvalesfiadoresComponent implements OnInit {
  	@Input() clienteId = 0;
	@Input() readonly = false;

	public columnDefs: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
  	public rowData: any[] = [];
	public searchstring: string = '';
  
	constructor(
	public formbuild        : FormBuilder,
	private clientesService	: ClientesService,
	private router          : Router,
	public toast            : ToastService, 
	public modalService     : NgbModal,
	) { }

	ngOnInit(): void {
		this.getData()
	}

	getData(){
		let tiposAvales = this.clientesService.obtenerTiposAvales()
		let avales = this.clientesService.obtenerAvales(this.clienteId)
		
		Promise.all([tiposAvales,avales])
		.then((res: any[])=>{
			console.log(res[0])
			console.log(res[1])
			this.initTable()
					this.loadTable(res[1])
			})
	}   

	initTable(){
	this.columnDefs = [
			{
				headerName: 'N°',
				field: 'id',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Primer Nombre',
				field: 'primer_nombre',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Segundo Nombre',
				field: 'segundo_nombre',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Apellido Paterno',
				field: 'apellido_paterno',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Apellido Materno',
				field: 'apellido_materno',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'DNI',
				field: 'dni',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Fecha Nacimiento',
				field: 'fecha_nacimiento',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Sexo',
				field: 'sexo',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Estado Civil',
				field: 'estado_civil_nombre',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Domicilio',
				field: 'domicilio',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'País',
				field: 'pais_nombre',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Departamento',
				field: 'departamento_nombre',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Provincia',
				field: 'provincia_nombre',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Distrito',
				field: 'distrito_nombre',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Monto Patrimonio',
				field: 'monto_patrimonio',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Tipo Aval',
				field: 'tipo_descripcion',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'Régimen Matrimonial',
				field: 'regimen_matrimonial_descripcion',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'País Nacimiento',
				field: 'pais_nacimiento_nombre',
				sortable: true,
				filterable: false,
		},
		{
				headerName: 'País Residencia',
				field: 'pais_residencia_nombre',
				sortable: true,
				filterable: false,
		},
	]
	}

  	/**
	 * Inicializa la tabla con datos y paginacion
	 */
	loadTable(data) {
		this.rowData = data.results
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}
  
	goToPage({page,per_page,filtros}){
		let request;

		let dataResult = {
			count      : 0,
			next       : null,
			num_pages  : 1,
			page_number: 1,
			per_page   : 10,
			previous   : null,
			results    : []
		};

		const range = (filtros.valueFrom && filtros.valueTo) ? `${filtros.valueFrom},${filtros.valueTo}` : '';

		// if (filtros.operacion > 0) {
		// 	this.factoringService.obtenerOperacionBandeja(filtros.operacion)
		// 		.then(res => {
		// 			dataResult.results.push(res);
		// 			this.loadTable(dataResult);
		// 			return;
		// 		}).catch(error => {
		// 			console.error(error);
		// 			this.loadTable(dataResult);
		// 			return;
		// 		});
		// 	return;
	// }

		// request = this.obtenerOperacionesFiltradas(
		// 	page,
		// 	per_page,
		// 	filtros.beneficiario ? filtros.beneficiario : '',  //Beneficiario
		// 	filtros.oficial_negocio ? filtros.oficial_negocio : '',  //Oficial de negocio
		// 	filtros.responsable ? filtros.responsable : '',  //Responsable
		// 	filtros.estado ? filtros.estado : '',  //Estado
		// 	filtros.estacion ? filtros.estacion : '',  //Estacion
		// 	filtros.fecha__gte ? filtros.fecha__gte : '',  //Fecha desde
		// 	filtros.fecha__lte ? filtros.fecha__lte : '',  //Fecha hasta
		// 	filtros.analista_riesgo ? filtros.analista_riesgo : '',  //Fecha hasta
		// 	'',
		// 	range,
		// 	filtros.oficial_negocio__nombre ? filtros.oficial_negocio__nombre : '',  //Filtro de oficial
		// )

		// request.then((res) => {
		// 	this.loadTable(res)
		// })
	}
  
	openModal() {

		const modalRef = this.modalService.open(AddAvalComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;
		modalRef.componentInstance.editable = false;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			if(result){
				this.modalService.dismissAll()
				this.ngOnInit()
			}
		},
		error => {

		});

	}

	tableEditEvent(row){
		const modalRef = this.modalService.open(AddAvalComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;
		modalRef.componentInstance.avalRow = row;
		modalRef.componentInstance.editable = true;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			if(result){
				this.modalService.dismissAll()
				this.ngOnInit()
			}
		},
		error => {

		});
	}

	destroy(row) {
		const modalRef = this.modalService.open(ConfirmModalComponent, {
			
		});

		modalRef.componentInstance.title = 'Remover Aval';
		modalRef.componentInstance.message = '¿Seguro que desea remover el Aval?';

		modalRef.result.then((result) => {
		if (result) {
			this.clientesService.eliminarAval(row.id)
			.then(result => {
				this.ngOnInit()
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
	}

}
