import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';

@Component({
  selector: 'app-desembolso-modal',
  templateUrl: './desembolso-modal.component.html',
  styleUrls: ['./desembolso-modal.component.css']
})
export class DesembolsoModalComponent implements OnInit {

  myForm: FormGroup;
  @Input() row: any;
  faTimes = faTimes;
  monedas: any[] = [];
  bancos: any[] = [];

  constructor(
    public formbuild: FormBuilder,
    public activeModal: NgbActiveModal,
    public configs: DocschecklistService,
    private configurationService: ConfigurationService,
  ) {
    this.initForm();
  }

  ngOnInit(): void {

    let monedas = this.configs.obtenerMonedas();
    let bancos = this.configurationService.obtenerBancos('', 1, 1000);

    Promise.all([monedas, bancos]).then(res => {
      this.monedas = res[0]['results'];
      this.bancos = res[1]['results'];
    }, err => {
      console.error(err);

    })

  }

  onSubmit() {

  }

  initForm() {
    this.myForm = this.formbuild.group({
      moneda: [null, [Validators.required]],
      banco: [null, [Validators.required]],
    });
  }

}
