import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { NgForm, FormGroup, Validators } from "@angular/forms";
import { FormBuilder } from "@angular/forms";
import { AuthService } from "app/core/services/auth/auth.service";
import { RestriccionesService } from "app/core/services/auth/restricciones.service";
import { DocschecklistService } from "app/core/services/config/docschecklist.service";
import { PropuestaComercialService } from "app/core/services/propuesta-comercial/propuesta-comercial.service";
import { ToastService } from "app/core/services/toast/toast.service";
import { CommitteeComponent } from "../partials/propuestas/forms/committee/committee.component";
import { AnalysisFormComponent } from "../partials/propuestas/forms/analysis-form/analysis-form.component";
import { SolicitarOpinionComponent } from "../partials/propuestas/solicitar-opinion/solicitar-opinion.component";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { OpenTextModalComponent } from "../modals/open-text-modal/open-text-modal.component";
import { DesgloseLineasComponent } from "../desglose-lineas/desglose-lineas.component";
import { DesglosesComponent } from "../desgloses/desgloses.component";
import { CommonServicesService } from "app/core/services/common-services.service";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-propuesta-comercial",
  templateUrl: "./propuesta-comercial.component.html",
  styleUrls: ["./propuesta-comercial.component.css"],
  providers : [DesglosesComponent, NgbActiveModal]
})
export class PropuestaComercialComponent implements OnInit {
  faCopy = faCopy;
  public user             : any;
  public frmPropuesta     : FormGroup;
  public tiposLinea       : any[] = [];
  public tiposMoneda      : any[] = [];
  public tipoevaluacion   : any[] = [];
  public tiposFirma   : any[] = [];
  public tiposFianza   : any[] = [];
  public condiciones   : any[] = [];
  public tiempos          : any[];
  public propuestaContext = {};
  contrato                : any;
  _operacionId            : any = 0;
  @Input() set operacionId(value) {
    this._operacionId = value;
    // this.initform();
    this.loadComponent();
  }
  get operacionId() {
    return this._operacionId;
  }
  public propuestaComercialColorSet: any = {};
  public propuestaRiesgoColorSet: any = {};
  public propuestaComiteColorSet: any = {};
  // operacion                        : any;
  checkCopy = false;
  auxPropuestaRiesgos: any;
  auxPropuestaComercial: any;
  auxPropuestComite: any;
  @Output() propuestaComite = new EventEmitter<boolean>();
  @ViewChild(CommitteeComponent) commiteeComponent: CommitteeComponent;
  @ViewChild(AnalysisFormComponent) analysisForm: AnalysisFormComponent;
  @ViewChild('desg') desglosesComponent: DesglosesComponent;
  @ViewChild(SolicitarOpinionComponent) solicitarOpinionComponent: SolicitarOpinionComponent;
  @Input() readOnlyPropuestaRiesgo = false;
  @Input() readOnlyPropuestaComite = false;
  @Input() operacion;
  public desglosesLineaPropuestaComercial;
  @Output() canAprobe = new EventEmitter<any>();
  _readOnlyPropuestaComercial;
  columnasSugerencias: any[] = [];
  public sugerenciasSolicitadas: any[] = [];
  existeSugerencia: boolean = false;
  tieneContrato: boolean = false;
  condiciones_comercial_model : any [] = []
  @Input() set readOnlyPropuestaComercial(val) {
    this._readOnlyPropuestaComercial = val;
  }
  get readOnlyPropuestaComercial() {
    return this._readOnlyPropuestaComercial;
  }
  desglosesDisabled: boolean = false

  estructuracionMinimo = 0.5
  temMinimoSoles = 0
  temMinimoDolares = 0

  @Input() tieneDeudaVencida = false

  constructor(
    public toast            : ToastService,
    private propuestaService: PropuestaComercialService,
    private configs         : DocschecklistService,
    private formbuild       : FormBuilder,
    private authService     : AuthService,
    private restricciones   : RestriccionesService,
    public modalService     : NgbModal,
    public serviciosComunes: CommonServicesService
  ) {
    
    this.user = this.authService.user;
  }

  ngOnInit(): void {
    this.initform();

    if(this.operacion.contrato){
      this.tieneContrato = true
    } else {
      this.tieneContrato = false
      this.frmPropuesta.get('contrato_nuevo_comercial').setValue(true)
      this.frmPropuesta.get('tipo_fianza_comercial').setValidators([Validators.required])
      this.frmPropuesta.get('contrato_nuevo_comercial').setValidators([Validators.required])
    }

    if (this.readOnlyPropuestaComercial) {
      this.frmPropuesta.disable();
    } else {
      this.frmPropuesta.enable();
    }

  }

  loadComponent() {
    this.tiempos = [
      { id: 1, descripcion: "Días" },
      { id: 2, descripcion: "Meses" },
      { id: 3, descripcion: "Años" },
    ];
    let tiposFirma  = this.propuestaService.obtenerTiposFirma();
    let tiposFianza = this.propuestaService.obtenerTiposFianza();
    let lineas      = this.configs.obtenerLineas();
    let monedas     = this.configs.obtenerMonedas();
    let propuesta   = this.propuestaService.obtenerPropuestaComercialPorOperacion(this.operacionId);
    let condiciones = this.propuestaService.obtenerCondiciones();
    let sugerencias = this.propuestaService.obtenerSugerenciasDeOperacion(
      this.operacionId
    );
    let tipoevaluacion = this.configs.obtenerTiposDeEvaluacion();
    let desgloses = this.serviciosComunes.getDesglose(this.operacionId,1)

    Promise.all([
      propuesta, 
      lineas, 
      monedas, 
      sugerencias, 
      tipoevaluacion,
      tiposFirma,
      tiposFianza,
      condiciones,
      desgloses
    ]).then(
      (res: any[]) => {
        let dataPropuesta           = res[0];
        this.propuestaContext       = res[0];
        this.tiposLinea             = res[1]["results"];
        this.tiposMoneda            = res[2]["results"];
        this.sugerenciasSolicitadas = res[3]["results"];
        this.tipoevaluacion         = res[4]["results"];
        this.tiposFirma             = res[5]["results"];
        this.tiposFianza            = res[6]["results"];
        this.condiciones            = res[7]["results"];
        this.authService.user;
        this.desglosesLineaPropuestaComercial = res[8]['desgloses']
        
        this.existeSugerencia = this.sugerenciasSolicitadas.some(
          (comentario) =>
            comentario.comentario != "" && comentario.comentario != null
        );
        this.frmPropuesta.reset({
          tipo_linea_comercial             : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
          moneda_linea                     : dataPropuesta["moneda_comercial"],
          moneda_comercial                 : dataPropuesta["moneda"],
          monto_linea                      : dataPropuesta["linea_propuesta_comercial"],
          vigencia                         : dataPropuesta["vigencia_comercial"],
          vigencia_tipo                    : dataPropuesta["tipo_vigencia_comercial"],
          tea_soles                        : dataPropuesta["tea_soles_comercial"],
          tem_soles                        : dataPropuesta["tem_soles_comercial"],
          tea_dolares                      : dataPropuesta["tea_dolares_comercial"],
          tem_dolares                      : dataPropuesta["tem_dolares_comercial"],
          tasa_mora                        : dataPropuesta["tasa_mora_comercial"],
          anticipo                         : dataPropuesta["anticipo_comercial"],
          plazo                            : dataPropuesta["plazo_comercial"],
          plazo_tipos                      : dataPropuesta["tipo_plazo_comercial"],
          comentarios                      : dataPropuesta["comentarios_comercial"],
          tipo_evaluacion                  : dataPropuesta["tipo_evaluacion"],
          adelanto_                        : dataPropuesta["adelanto_comercial"],
          moneda_abono_leasing             : dataPropuesta["moneda"],
          abono_leasing                    : dataPropuesta["abono_leasing_comercial"],
          contrato_nuevo_comercial         : dataPropuesta["contrato_nuevo_comercial"],
          ofical_negocio                   : dataPropuesta["oficial_negocio_nombre"],
          tipo_fianza_comercial            : dataPropuesta["tipo_fianza_comercial"],
          tipo_firma_comercial             : dataPropuesta["tipo_firma_comercial"],
          comision_estructuracion_comercial: dataPropuesta["comision_estructuracion_comercial"],
          condiciones_comercial            : dataPropuesta["condiciones_comercial"]
        });

        if(this.operacion.puntual){
          this.frmPropuesta.get('tipo_evaluacion').setValue(10)
          this.frmPropuesta.get('contrato_nuevo_comercial').setValue(true)
          this.frmPropuesta.get('tipo_evaluacion').disable()
          this.frmPropuesta.get('contrato_nuevo_comercial').disable()
          this.desglosesDisabled = true
        }
        this.obtenerContrato(this.propuestaContext["id"]);
      }
    );

    this.getOperacion();
  }

  obtenerDesgloses(){
    this.modalService.dismissAll()
    let desgloses = this.serviciosComunes.getDesglose(this.operacionId,1)
    desgloses.then((res: any)=>{
      this.desglosesLineaPropuestaComercial = res['desgloses']
      this.mostrarDesglose()
    })
  }

  getPropuesta() {
    let lineas    = this.configs.obtenerLineas();
    let monedas   = this.configs.obtenerMonedas();
    let propuesta = this.propuestaService.obtenerPropuestaComercialPorOperacion(this.operacionId);
    Promise.all([lineas, monedas, propuesta]).then((res) => {
      this.tiposLinea       = res[0]["results"];
      this.tiposMoneda      = res[1]["results"];
      let dataPropuesta     = res[2];
      this.propuestaContext = res[2];
      this.frmPropuesta.reset({
        tipo_linea_comercial             : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
        moneda_linea                     : dataPropuesta["moneda_comercial"],
        moneda_comercial                 : dataPropuesta["moneda"],
        monto_linea                      : dataPropuesta["linea_propuesta_comercial"],
        vigencia                         : dataPropuesta["vigencia_comercial"],
        vigencia_tipo                    : dataPropuesta["tipo_vigencia_comercial"],
        tea_soles                        : dataPropuesta["tea_soles_comercial"],
        tem_soles                        : dataPropuesta["tem_soles_comercial"],
        tea_dolares                      : dataPropuesta["tea_dolares_comercial"],
        tem_dolares                      : dataPropuesta["tem_dolares_comercial"],
        tasa_mora                        : dataPropuesta["tasa_mora_comercial"],
        anticipo                         : dataPropuesta["anticipo_comercial"],
        plazo                            : dataPropuesta["plazo_comercial"],
        plazo_tipos                      : dataPropuesta["tipo_plazo_comercial"],
        comentarios                      : dataPropuesta["comentarios_comercial"],
        tipo_evaluacion                  : dataPropuesta["tipo_evaluacion"],
        adelanto_                        : dataPropuesta["adelanto_comercial"],
        moneda_abono_leasing             : dataPropuesta["moneda"],
        abono_leasing                    : dataPropuesta["abono_leasing_comercial"],
        // contrato_nuevo_comercial      : dataPropuesta["contrato_nuevo_comercial"],
        contrato_nuevo_comercial         : dataPropuesta["contrato_nuevo_comercial"],
        ofical_negocio                   : dataPropuesta["oficial_negocio_nombre"],
        tipo_fianza_comercial            : dataPropuesta["tipo_fianza_comercial"],
        tipo_firma_comercial             : dataPropuesta["tipo_firma_comercial"],
        comision_estructuracion_comercial: dataPropuesta["comision_estructuracion_comercial"],
        condiciones_comercial            : dataPropuesta["condiciones_comercial"]
      });

      this.obtenerContrato(this.propuestaContext["id"]);
    });
  }

  getOperacion() {
    // let promise = this.propuestaService.obtenerOperacion(this.operacionId);
    // Promise.all([promise]).then(res => {
    // 	this.operacion = res[0];
    // })
    // .catch(error => {
    // 	console.error(error);
    // });
  }

  initform() {
    let producto = this.operacion?.tipo_linea_solicitada || this.operacion?.operacion?.tipo_linea_solicitada
    let fecha = new Date(this.operacion?.fecha || this.operacion?.operacion?.fecha || new Date())
    let fechaInicioValidacion = new Date(2022, 8, 14) // Validar operaciones creadas desde esta fecha
    this.temMinimoSoles = (producto == 2 || fecha.getTime() < fechaInicioValidacion.getTime()) ? 0 : (this.operacion?.linea_credito ? 1.5 : 1.6)
    this.temMinimoDolares = (producto == 2 || fecha.getTime() < fechaInicioValidacion.getTime()) ? 0 : (this.operacion?.linea_credito ? 1.47 : 1.57)
    
    this.frmPropuesta                  = this.formbuild.group({
      tipo_linea_comercial             : ["", [Validators.required]],
      moneda_linea                     : ["", [Validators.required]],
      moneda_comercial                 : ["", [Validators.required]],
      monto_linea                      : ["", [Validators.required]],
      vigencia                         : ["", [Validators.required]],
      vigencia_tipo                    : ["", [Validators.required]],
      tea_soles                        : ["", [Validators.required]],
      tem_soles                        : ["", [Validators.required, Validators.min(this.temMinimoSoles)]],
      tea_dolares                      : ["", [Validators.required]],
      tem_dolares                      : ["", [Validators.required, Validators.min(this.temMinimoDolares)]],
      tasa_mora                        : ["", [Validators.required]],
      anticipo                         : ["", [Validators.required]],
      plazo                            : ["", [Validators.required]],
      plazo_tipos                      : ["", [Validators.required]],
      comentarios                      : ["", [Validators.required]],
      tipo_evaluacion                  : ["", [Validators.required]],
      adelanto_                        : [0, [Validators.required, Validators.max(this.operacion.monto - 1)]],
      moneda_abono_leasing             : ["", [Validators.required]],
      abono_leasing                    : [0 ],
      contrato_nuevo_comercial         : [null],
      ofical_negocio                   : [""],
      tipo_fianza_comercial            : [""],
      tipo_firma_comercial             : [""],
      comision_estructuracion_comercial: [""],
      condiciones_comercial            : [[],[]],
    });

    this.frmPropuesta.controls.tipo_evaluacion.valueChanges.subscribe(v=>{
      if (v==1) {
        this.estructuracionMinimo = 1
      } else {
        this.estructuracionMinimo = 0.5
      }
      this.frmPropuesta.controls.comision_estructuracion_comercial.setValidators(Validators.min(this.estructuracionMinimo))
      this.frmPropuesta.controls.comision_estructuracion_comercial.updateValueAndValidity()
      this.frmPropuesta.markAllAsTouched()
    })

    this.frmPropuesta.valueChanges.subscribe((val: any) => {
      this.auxPropuestaComercial = val;
    });

    for (var prop in this.frmPropuesta.controls) {
      this.propuestaComercialColorSet[prop] = "";
      this.propuestaRiesgoColorSet[prop] = "";
    }

    this.columnasSugerencias = [
      {
        headerName: "N°",
        pipe: "indexcol",
        sortable: true,
      },
      {
        headerName: "Responsable",
        field: "responsable_nombre",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Perfil",
        field: "responsable_rol",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Comentario",
        field: "comentario",
        sortable: true,
        class: "text-center",
      },
    ];

  }

  get formValues() {
    return this.frmPropuesta.value;
  }

  /**
   * Guarda los valores el formulario riesgo
   * @param values recibe el formulario riesgo
   */
  setAnalysisForm(values) {
    this.auxPropuestaRiesgos = values;
    this.evalFormulario()
  }

  /**
   * Guarda los valores del formulario comite
   * @param values recibe los valores del formulario comite
   */
  setCommiteeForm(values) {
    this.auxPropuestComite = values;
    this.evalFormulario()
    this.propuestaComite.emit(values);
  }

  sendValueCommite(formControl, value = null) {
    // if (this.operacion.estacion != 4) {
    // 	return;
    // }
    // let values = (value) ? value : this.frmPropuesta.controls[formControl].value;
    // this.commiteeComponent.setValueForm(formControl, values);
  }

  receiveValuesAnalysisForm(data) {
    this.sendValueCommite(data.formControl, data.value);
  }

  saveForm() {

    if (!this.frmPropuesta.valid) return;
    this.frmPropuesta.get('contrato_nuevo_comercial').enable()
    let req = {
      tipo_linea_comercial             : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
      linea_propuesta_comercial        : this.frmPropuesta.controls["monto_linea"].value,
      vigencia_comercial               : this.frmPropuesta.controls["vigencia"].value,
      tipo_vigencia_comercial          : this.frmPropuesta.controls["vigencia_tipo"].value,
      moneda_comercial                 : this.frmPropuesta.controls["moneda_linea"].value,
      tea_soles_comercial              : this.frmPropuesta.controls["tea_soles"].value,
      tem_soles_comercial              : this.frmPropuesta.controls["tem_soles"].value,
      tea_dolares_comercial            : this.frmPropuesta.controls["tea_dolares"].value,
      tem_dolares_comercial            : this.frmPropuesta.controls["tem_dolares"].value,
      tasa_mora_comercial              : this.frmPropuesta.controls["tasa_mora"].value,
      anticipo_comercial               : this.frmPropuesta.controls["anticipo"].value,
      plazo_comercial                  : this.frmPropuesta.controls["plazo"].value,
      tipo_plazo_comercial             : this.frmPropuesta.controls["plazo_tipos"].value,
      comentarios_comercial            : this.frmPropuesta.controls["comentarios"].value,
      tipo_evaluacion                  : this.frmPropuesta.controls["tipo_evaluacion"].value,
      adelanto_comercial               : this.frmPropuesta.controls["adelanto_"].value,
      contrato_nuevo_comercial         : this.frmPropuesta.controls["contrato_nuevo_comercial"].value,
      oficial_negocio                  : this.propuestaContext["oficial_negocio"],
      comision_estructuracion_comercial: this.frmPropuesta.get("comision_estructuracion_comercial").value,
      tipo_fianza_comercial            : this.frmPropuesta.controls["tipo_fianza_comercial"].value,
      tipo_firma_comercial             : this.frmPropuesta.controls["tipo_firma_comercial"].value,
      condiciones_comercial            : this.frmPropuesta.controls["condiciones_comercial"].value,
      abono_leasing_comercial          : this.frmPropuesta.controls["abono_leasing"].value
      //falta moneda_leasing - monto_leasing


    };
    this.propuestaService
      .actulizarPropuestaComercial(this.operacionId, req)
      .then((r) => {
        this.getPropuesta();
      })
      .catch((error) => {});
  }

  setCommitteeForm(values = null) {
    let desglose: any;
    if (values) {
      this.commiteeComponent.setForm(values);
      //Riesgos
      desglose = JSON.stringify(values._desgloses)
    } else {
      this.commiteeComponent.setForm(this.propuestaContext);
      desglose = JSON.stringify(this.desglosesLineaPropuestaComercial)
    }

    this.commiteeComponent.setDesglose(desglose);
  }

  setPropuestaRiesgoForm() {
    let  valores = this.frmPropuesta.value;
    this.analysisForm.setForm(valores);
    let desglose = JSON.stringify(this.desglosesLineaPropuestaComercial)
    this.analysisForm.setDesglose(desglose)
  }

  sendAnalysis(e) {
    if (e) {
      // this.getPropuesta();
      this.frmPropuesta.controls['tipo_evaluacion'].setValue(this.analysisForm.analysisForm.controls['tipo_evaluacion'].value)
    }
  }

  evalFormulario() {
    if (!this.restricciones.esMiembroDelComite) {
      return;
    }
    let blue = "input-blue select-blue";
    let red = "input-red select-red";
    if (this.auxPropuestaComercial && this.auxPropuestaRiesgos && this.auxPropuestComite) {
      for (let prop of Object.keys(this.auxPropuestaRiesgos)) {
        let valueA = parseFloat(this.auxPropuestaRiesgos[prop]); //Propuesta de Riesgos
        let valueB = parseFloat(this.auxPropuestaComercial[prop]); //Propuesta Comercial
        let valueC = parseFloat(this.auxPropuestComite[prop]); //Propuesta Comercial
        switch(prop){
          case 'vigencia':
            if (this.auxPropuestaRiesgos["vigencia_tipo"] == 2) {
              valueA = valueA * 30;
            }
    
            if (this.auxPropuestaComercial["vigencia_tipo"] == 2) {
              valueB = valueB * 30;
            }
    
            if (this.auxPropuestaRiesgos["vigencia_tipo"] == 3) {
              valueA = valueA * 360;
            }
    
            if (this.auxPropuestaComercial["vigencia_tipo"] == 3) {
              valueB = valueB * 360;
            }
          break;
          case 'plazo':
            if (this.auxPropuestaRiesgos["plazo_tipos"] == 2) {
              valueA = valueA * 30;
            }
    
            if (this.auxPropuestaComercial["plazo_tipos"] == 2) {
              valueB = valueB * 30;
            }
    
            if (this.auxPropuestaRiesgos["plazo_tipos"] == 3) {
              valueA = valueA * 360;
            }
    
            if (this.auxPropuestaComercial["plazo_tipos"] == 3) {
              valueB = valueB * 360;
            }
          break;
          case 'tipo_linea_comercial':
            if (valueC || valueA == valueB) {
              this.propuestaRiesgoColorSet[prop] = "";
              this.propuestaComercialColorSet[prop] = "";
            } else if (valueA === 2) {
              //Propuesta de Riesgo FACTORING
              this.propuestaRiesgoColorSet[prop] = red;
              this.propuestaComercialColorSet[prop] = blue;
            } else {
              this.propuestaComercialColorSet[prop] = blue;
              this.propuestaRiesgoColorSet[prop] = red;
            }
          break;
          case 'comision_estructuracion_riesgos':
            valueA = parseFloat(this.auxPropuestaRiesgos[prop])
            valueB = parseFloat(this.auxPropuestaComercial['comision_estructuracion_comercial'])
            
          default:
            valueA = parseFloat(valueA.toFixed(2));
            valueB = parseFloat(valueB.toFixed(2));
            if (valueC || valueA == valueB) {
              this.propuestaRiesgoColorSet[prop] = "";
              this.propuestaComercialColorSet[prop] = "";
            } else if ((valueA && valueB) && valueA < valueB) {
              this.propuestaRiesgoColorSet[prop] = blue;
              this.propuestaComercialColorSet[prop] = red;
            } else {
              this.propuestaComercialColorSet[prop] = blue;
              this.propuestaRiesgoColorSet[prop] = red;
            }
          break;
        }
      }
    }
  }

  setInitialValuesComite() {
    if (!this.restricciones.esMiembroDelComite) {
      return;
    }
    for (let prop of Object.keys(this.auxPropuestaRiesgos)) {
      let noEsNulo: boolean;
      noEsNulo = this.auxPropuestaRiesgos[prop] != null ? true : false;
      noEsNulo = this.auxPropuestaComercial[prop] != null ? true : false;

      let sonIguales =
        this.auxPropuestaRiesgos[prop] == this.auxPropuestaComercial[prop];
      if (noEsNulo && sonIguales) {
        this.commiteeComponent.setForm(this.auxPropuestaRiesgos[prop], prop);
      }
    }
  }

  updateFormValue(prop, value = null) {
    if (!this.restricciones.esMiembroDelComite) return;
    
    if (value) {
      this.commiteeComponent.setForm(this.auxPropuestaRiesgos[prop], prop);
    } else {
      this.commiteeComponent.setForm(this.auxPropuestaComercial[prop], prop);
    }
  }

  updateComite() {
    this.commiteeComponent.updatePropuesta();
  }

  editaComentario(comentario) {
    this.propuestaService.actualizarComentarioDeSugerencia(
      comentario.id,
      comentario
    );
  }

  agregaComentario(comentario) {
    console.log(comentario);
  }

  muestraCajaDeComentarios() {
    let permiteOpinar = this.restricciones.permiteOpinar(this.operacionId);
    let esMiembroDelComite = this.restricciones.esMiembroDelComite;
    if (
      permiteOpinar ||
      (esMiembroDelComite && this.existeSugerencia) ||
      this.existeSugerencia
    ) {
      return true;
    } else {
      return false;
    }
  }

  obtenerContrato(operacionId) {
    this.propuestaService
      .obtenerContrato(operacionId)
      .then((res) => {
        this.contrato = res["contrato"] ? res["contrato"] : null;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  parseDate(date) {
    if (!date) return;

    const arrayDate = date.split("-");
    return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
  }

  changeMonedaAdelanto(moneda) {
		this.frmPropuesta.controls['moneda_linea'].setValue(moneda.id);
	}

  get showOpenText() {
    return (this.frmPropuesta.controls['comentarios'].value && this.frmPropuesta.controls['comentarios'].value.length > 100)
  }

  openTextModal() {

    if(!this.frmPropuesta.disabled || this.frmPropuesta.get('comentarios').value.length < 100) return;
    
    const modalRef = this.modalService.open(OpenTextModalComponent, {
    });

    modalRef.componentInstance.title = 'Comentarios Propuesta Comercial';
    modalRef.componentInstance.message = this.frmPropuesta.get('comentarios').value;

    modalRef.result.then((result) => {
      }, (reason) => {
          console.log(reason);
    });
  }

  mostrarDesglose(){
    let { detalle } = this.operacion
    const modalRef = this.modalService.open(DesglosesComponent, {
      size: "lg",
    });
    modalRef.componentInstance.desgloses = this.desglosesLineaPropuestaComercial
    modalRef.componentInstance.operacion     = this.operacion.id;
    modalRef.componentInstance.operacionBody = this.operacion;
    modalRef.componentInstance.propuesta     = 1;
    modalRef.componentInstance.readonly     = this.readOnlyPropuestaComercial;
    // modalRef.componentInstance.readonly     = false;
    modalRef.componentInstance.deleteEvent.subscribe((val)=>{
      this.obtenerDesgloses()
      this.modalService.dismissAll()
    })

    modalRef.result.then((result) => {
      if (result) {

      }
    });

  }
}
