import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { ProrrogasService } from "app/core/services/factoring/prorrogas.service";
import { AuthService } from "app/core/services/auth/auth.service";
import { ToastService } from "app/core/services/toast/toast.service";
import { concat, Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from "rxjs/operators";
import { SharedFunctions } from "app/core/shared/functions";
import { FactoringService } from "app/core/services/factoring/factoring.service";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Usuario } from "app/core/services/auth/Usuario";
import { EditarDetalleComponent } from "../editar-detalle/editar-detalle.component";

@Component({
  selector: "app-add-prorroga-modal",
  templateUrl: "./add-prorroga-modal.component.html",
  styleUrls: ["./add-prorroga-modal.component.css"],
})
export class AddProrrogaModalComponent implements OnInit {
  faTimes = faTimes
  private modalDocumentsCarteraReference: any;
  public isUserComiteOfComiteProrroga: boolean = false;
  public tipos: any[] = [];
  public deudorebeneficiariosList: any[] = [];
  public deudoresList: any[] = [];
  public solicitantesList: any[] = [];
  public documentsCarteraList: any[] = [];
  public documentsCarteraSelectedList: any[] = [];
  public documentsCarteraSelectionList: any[] = [];
  public comisionsList: any[] = [];
  public comiteList: any[] = [];
  public monedasList: any[] = [];
  public headerDocumentsTable: columnHeader[];
  public headerComisionTable: columnHeader[];
  public headerDocumentsCarteraTable: columnHeader[];
  public headerComiteTable: columnHeader[];
  public pageDocumentsCarteraTableActual: number;
  public pageDocumentsCarteraTableTotal: number;
  public pageDocumentsCarteraTablePerPages: number = 10;
  public pageDocumentsCarteraTablePages: number[];
  public adjunto: any = null;
  public minDate: Date;
  public cabeceraSolicitudForm: FormGroup;
  public bodySolicitudForm: FormGroup;
  public user: Usuario;
  public canAprobe: any = null;
  public canEdit: boolean = true;

  aplicacionFilter = null;
  aplicaciones: any[] = [];

  beneficiariosResults$: Observable<any[]>;
  searchBeneficiarios$ = new Subject<string>();
  loadingBeneficiarioSearch: boolean = false;

  deudoresResults$: Observable<any[]>;
  searchDeudores$ = new Subject<string>();
  loadingDeudorSearch: boolean = false;
  empresas: any[] = [];

  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    public localeService: BsLocaleService,
    private authService: AuthService,
    private prorrogasService: ProrrogasService,
    public activeModal: NgbActiveModal,
    public toast: ToastService,
    public sharedFunctions: SharedFunctions,
    public factoring: FactoringService,
  ) {
    defineLocale("es", esLocale);
    this.localeService.use("es");
    this.user = this.authService.user;
  }

  @ViewChild("e_documentscartera", { static: false }) public e_documentscartera;
  @Input() prorroga: any;
  @Input() comentario: string = "";
  @Input() comite: string = "";
 
  @Input() estado: string = "";

  ngOnInit(): void {

    this.factoring.obtenerEmpresas().then((res) => {

      this.empresas = [res['results'][0]]; 
      
    });
    this.initTables();
    this.crearCabecera();
    this.obtenerData();
    this.loadSearch()
  }

  initTables() {
    let headerDocumentsTable = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "cliente_nombre",
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
      },
      {
        class: "text-center",
        headerName: "Producto",
        field: "tipo_producto_descripcion",
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "tipo_documento",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Nro Documento",
        field: "numero_documento",
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto_documento",
        pipe: "currency",
        moneda: "moneda_monto",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Saldo",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda_monto",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Saldo Financiado",
        field: "saldo_financiado",
        pipe: "currency",
        moneda: "moneda_monto",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fecha Vencimiento",
        field: "fecha_vencimiento",
        pipe: "date",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "TEM",
        field: "tem",
        pipe: "percent-icon",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Días Prórroga",
        field: "dias_prorroga",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fecha Prórroga",
        field: "fecha_vencimiento_prorroga",
        pipe: "date",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Intereses",
        field: "interes",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "IGV",
        field: "igv",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Total",
        field: "total",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
    ];

    let headerComisionTable = [
      {
        headerName: "N°",
        field: "index",
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "beneficiario_nombre",
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "deudor_nombre",
      },
      {
        class: "text-center",
        headerName: "Concepto",
        field: "concepto_nombre",
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
      },
      {
        class: "text-center",
        headerName: "IGV",
        field: "igv",
        pipe: "currency",
        moneda: "moneda",
      },
      {
        class: "text-center",
        headerName: "Total x Cobrar",
        field: "total",
        pipe: "currency",
        moneda: "moneda",
      },
    ];

    let headerDocumentsCarteraTable = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Producto",
        field: "tipo_producto_descripcion",
        sortable: true,
        filterable: true,
        filterProp: "tipo_linea",
        filterSelectItems: [
          // Obtener desde backend, por servicio de lineas, no existe servicio
          // Crear método para obtener, pero lleva a BOILERPLATE CODE, se deja en hardcode
          {id: 1, descripcion: 'Descuento'},
          {id: 2, descripcion: 'Factoring'},
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Operación",
        field: "operacion",
        sortable: true,
        filterable: true,
        filterProp: "operacion",
        filterInput: true,
      },
      {
        class: "text-center chortRow",
        headerName: "Cliente",
        field: "beneficiario_nombre",
        sortable: true,
        widthRow: '30%',
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
        widthRow: '10',
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "operacion_tipo_detalle",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Nro Documento",
        field: "operacion_numero_documento",
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto_neto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Saldo",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fecha Vencimiento",
        field: "fecha_vencimiento",
        pipe: "date",
        sortable: true,
        filterable: true,
        filterDate: true,
      },
      {
        class: "text-center",
        headerName: "TEM",
        field: "tem",
        pipe: "percent-icon",
        sortable: true,
      },
    ];

    let headerComiteTable = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aprobador",
        field: "responsable_nombre",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fecha",
        field: "fecha",
        pipe: "date",
      },
    ];

    this.headerDocumentsTable = headerDocumentsTable;
    this.headerComisionTable = headerComisionTable;
    this.headerDocumentsCarteraTable = headerDocumentsCarteraTable;
    this.headerComiteTable = headerComiteTable;
  }

  crearCabecera() {
    let desabilitar = this.prorroga && (this.prorroga.estado == '3' || this.prorroga.estado == '5' || this.prorroga.estado == '4')

    // this.prorrogasService.obtenerPeriodoAbierto()
    // .then((fecha: Date) => {
    //   const inicio_periodo = new Date(fecha);
    //   inicio_periodo.setMinutes(inicio_periodo.getMinutes() + inicio_periodo.getTimezoneOffset())
    //   this.minDate = inicio_periodo;
    const date = new Date();
    this.minDate = new Date(date.getFullYear(), date.getMonth(), 1);
    // })
    // let hoy = new Date(today.getTime)

    this.cabeceraSolicitudForm = this.formBuilder.group({
      tipo: [
        { value: null, disabled: this.prorroga?.estado },
        [Validators.required],
      ],
      solicitante: [
        { value: null, disabled: this.prorroga?.estado },
        [Validators.required],
      ],
      solicitante_search: [
        { value: null, disabled: this.prorroga?.estado },
      ],
      moneda: [null , [Validators.required]],
      fecha_solicitud: [
        {
          value: null,
          disabled: desabilitar,
        },
        [Validators.required],
      ],
      empresa: [  2, [Validators.required]],
      tasa: [null , [Validators.required]],
      tasa_comision: ['5.00', [Validators.required]],
      fecha_vencimiento: [null , [Validators.required]],
    });

    if (!!this.prorroga && !!this.prorroga.fecha_solicitud) {
      this.cabeceraSolicitudForm.get('fecha_solicitud')?.setValue(this.sharedFunctions.getDateFromString(this.prorroga.fecha_solicitud))
      this.cabeceraSolicitudForm.get('empresa')?.setValue(this.prorroga.empresa);
    } else {
      let fecha_solicitud_ = new Date(Date.now());
      fecha_solicitud_.setHours(0, 0, 0, 0)
      this.cabeceraSolicitudForm.get('fecha_solicitud')?.setValue(fecha_solicitud_)
    }


    this.cabeceraSolicitudForm.controls.solicitante_search.valueChanges.subscribe((value) => {
      if (this.cabeceraSolicitudForm.value['tipo'] == 1) { // Aceptante
        this.cabeceraSolicitudForm.controls.solicitante.setValue(value['id'])
      } else { // Cliente
        this.cabeceraSolicitudForm.controls.solicitante.setValue(value['id'])
      }
    })
  }

  obtenerData() {
    let tiposPagador = this.prorrogasService.obtenerTiposPagador();
    let monedas = this.prorrogasService.obtenerListaMonedas();

    Promise.all([tiposPagador, monedas]).then(
      (res: any[]) => {
        this.tipos = res[0].results;

        this.monedasList = res[1].results;

        if (!!this.prorroga) {
          const { tipo_solicitante } = this.prorroga;

          const {
            tipo,
            solicitante,
            solicitante_search,
            moneda,
            fecha_solicitud,
            tasa,
            tasa_comision,
            fecha_vencimiento,
          } = this.cabeceraSolicitudForm.controls;

          tipo.patchValue(tipo_solicitante, 500);

          if (tipo_solicitante == 1) {
            this.prorrogasService.obtenerDeudor(this.prorroga.deudor).then(deudor => {
              solicitante_search.patchValue(deudor)
            })
            solicitante.patchValue(this.prorroga.deudor, 500);
          } else if (tipo_solicitante == 2) {
            this.prorrogasService.obtenerBeneficiario(this.prorroga.beneficiario).then(beneficiario => {
              solicitante_search.patchValue(beneficiario)
            })
            solicitante.patchValue(this.prorroga.beneficiario, 500);
          }

          moneda.patchValue(this.prorroga?.moneda, 500);

          fecha_solicitud.setValue(
            this.sharedFunctions.getDateFromString(this.prorroga.fecha_solicitud)
          );

          tasa.setValue(this.prorroga.tasa);

          tasa_comision.setValue(this.prorroga.tasa_comision)

          fecha_vencimiento.setValue(
            this.formatDateInput(this.prorroga.fecha_vencimiento, "string")
          );

          this.comentario = this.prorroga.comentario;
          this.comite = this.prorroga.comite_nombre;
          this.estado = this.prorroga.estado_descripcion;

          let detalles = this.prorrogasService.obtenerDetalles(
            this.prorroga.id
          );
          let adjunto = this.prorrogasService.obtenerAdjunto(this.prorroga.id);
          let comision = this.prorrogasService.obtenerComision(
            this.prorroga.id
          );

          Promise.all([detalles, adjunto, comision]).then(async (res: any) => {
            this.documentsCarteraSelectedList = res[0].results.map((el, i) => {
               let igv = el.cuenta_cobrar_interes ? el.cuenta_cobrar_interes.igv : 0;
               let total = el.cuenta_cobrar_interes ? el.cuenta_cobrar_interes.total : 0;

              if (`${el.igv}` !== igv ) {
                igv = el.igv
                total = el.total
              }
              return {
                ...el,
                igv,
                total,
                saldo_financiado: el.saldo_financiado ?? 0,
                index: i + 1,
              }
            });

            this.adjunto = res[1].results[0];

            this.comisionsList =
              res[2].results.length > 0
                ? res[2].results.map((el, i) => ({
                  ...el,
                  index: i + 1,
                }))
                : [
                  {
                    concepto_nombre: "Costo de prórroga",
                    monto: 0,
                    igv: 0,
                    total: 0,
                    moneda: 1,
                  },
                ];

            if (!!this.prorroga.comite) {
              await this.prorrogasService
                .obtenerIntegrantesComite(this.prorroga.id)
                .then((comite: any) => {
                  this.comiteList = comite.results?.map((el, i) => ({
                    ...el,
                    index: i + 1,
                  }));

                  if (this.user.comite?.length > 0) {
                    this.canAprobe = comite?.results?.some(
                      (el) =>
                        this.prorroga?.estado === 2 &&
                        el.responsable === this.user?.id &&
                        el.fecha === null
                    );
                  }
                });
            }

            this.isUserComiteOfComiteProrroga = this.user?.comite?.some(
              (el: any) => el?.comite === this.prorroga?.comite
            );
            
            if(
              ((this.user.isAsistenteCobranzas || this.user.isAsistenteComercial) && this.prorroga?.estado !== 1) ||
              !(this.userCanEdit()) ||
              (this.prorroga?.estado === 3 && !this.isUserComiteOfComiteProrroga) ||
              this.prorroga?.estado === 5 ||
              this.prorroga?.estado === 4 ||
              this.prorroga?.estado === 3 ||
              (this.prorroga?.estado === 2 && !this.comiteList?.find((el) => el.responsable === this.user?.id)) ||
              (this.prorroga?.estado === 2 && (this.user?.isAsistenteCobranzas || this.user?.isAsistenteComercial)) ||
              this.comiteList.some((el: any) => el?.fecha !== null)
            ) {
              this.canEdit = false;
              moneda.disable();
              tasa.disable();
              fecha_vencimiento.disable();
            }
          });
        }
      }
    );
  }

  compararTipos(tipo1: any, tipo2: any) {
    return tipo1 && tipo2 ? tipo1.id === tipo2 : tipo1 === tipo2;
  }

  compararSolicitantes(solicitante1: any, solicitante2: any) {
    return solicitante1 && solicitante2
      ? solicitante1.id === solicitante2
      : solicitante1 === solicitante2;
  }


  getCabeceraData() {
    const {
      tipo,
      solicitante,
      moneda,
      tasa,
      tasa_comision,
      fecha_vencimiento,
      fecha_solicitud,
      empresa
    } = this.cabeceraSolicitudForm.value;

    let data = {
      tipo_solicitante: tipo,
      tasa,
      tasa_comision,
      moneda,
      empresa,
      fecha_vencimiento: this.formatDate(fecha_vencimiento),
      fecha_solicitud: this.formatDate(fecha_solicitud),
    };

    if (tipo === 2) data["beneficiario"] = solicitante;
    else data["deudor"] = solicitante;

    return data;
  }

  enviarCabecera() {
    if (this.prorroga?.id) return this.actualizarCabeceraSolicitud();
    else return this.registrarSolicitud();
  }

  async registrarSolicitud() {

    let data = this.getCabeceraData();

    let prorroga_ = await this.prorrogasService.registrarSolicitudProrroga(data);
    this.prorroga = prorroga_;

    const { tipo_solicitante } = this.prorroga
    const {
      solicitante_search
    } = this.cabeceraSolicitudForm.controls;

    this.cabeceraSolicitudForm.controls.tipo.patchValue(tipo_solicitante, 500);
    if (tipo_solicitante == 1) {
      this.prorrogasService.obtenerDeudor(this.prorroga.deudor).then(deudor => {
        solicitante_search.patchValue(deudor)
      })
      this.cabeceraSolicitudForm.controls.solicitante.patchValue(this.prorroga.deudor, 500);
    } else if (tipo_solicitante == 2) {
      this.prorrogasService.obtenerBeneficiario(this.prorroga.beneficiario).then(beneficiario => {
        solicitante_search.patchValue(beneficiario)
      })
      this.cabeceraSolicitudForm.controls.solicitante.patchValue(this.prorroga.beneficiario, 500);
    }


    this.estado = "Registrado";

    const { tipo, solicitante } = this.cabeceraSolicitudForm.controls;

    tipo.disable();
    solicitante.disable();

    this.toast.success("Se registró la solicitud de prórroga correctamente");
  }

  async actualizarCabeceraSolicitud() {
    let cliente = this.prorroga.deudor
    if (this.prorroga.tipo_solicitante ==2) {
        cliente = this.prorroga.beneficiario 
    }
    let loading_comisiones = true;
    // this.cabeceraSolicitudForm.controls['solicitante'].value.toString()
    if (this.cabeceraSolicitudForm.controls['solicitante_search'].value.id.toString() !==  cliente.toString()){
      //let deleteAll     =  await this.changeCliente()
      loading_comisiones = false;
      await Promise.all([this.changeCliente()]).then(
        (res: any[]) => {
          console.info("respuesta changeCliente",res)
          this.cabeceraSolicitudForm.controls.solicitante.setValue(this.cabeceraSolicitudForm.controls['solicitante_search'].value.id)
          this.documentsCarteraSelectedList = []
        }
      );
      //let deleteComision = await this.deleteComisionesChangeCliente()
      await Promise.all([this.deleteComisionesChangeCliente()]).then(
        (res: any[]) => {
          console.info("respuesta comisionsList",res)
          this.comisionsList = []
        }
      );
      
      // await this.loadComisiones()
    }
    let id = this.prorroga.id;
    let data = this.getCabeceraData();
    data["estado"] = this.prorroga.estado;

    if (cliente != this.cabeceraSolicitudForm.controls['solicitante_search'].value.id.toString()) {
      if (this.prorroga.tipo_solicitante ==2) {
        data["beneficiario"]=this.cabeceraSolicitudForm.controls['solicitante_search'].value.id
      }else{
        data["deudor"] = this.cabeceraSolicitudForm.controls['solicitante_search'].value.id
      }
    }
    //console.log("antes prorroga",this.prorroga)
    let new_prorroga=await this.prorrogasService.actualizarSolicitudProrroga(id, data);
    //console.log("update prorroga",new_prorroga)
    this.prorroga =  new_prorroga
    await this.prorrogasService.obtenerDetalles(this.prorroga.id).then((res: any) => {
      this.documentsCarteraSelectedList = res.results.map((e, i) => ({
        ...e,
        saldo_financiado: e.saldo_financiado ?? 0, 
        index: i + 1,
      }));
    })
    if (loading_comisiones) {
      await this.loadComisiones()
    }
    //await this.showDocumentsCartera()
    this.toast.success("Se actualizó la solicitud de prórroga correctamente");

  }
  async changeCliente(){
    // console.info("documentsCarteraSelectedList",this.documentsCarteraSelectedList)
      await this.documentsCarteraSelectedList.forEach(async element =>  {
        //this.eliminarDetalle(element)
        const { id } = element;
        await this.prorrogasService.eliminarDetalleDocumento(id).then(() => {
          this.documentsCarteraSelectedList = this.documentsCarteraSelectedList.filter(
            (doc: any) => doc.id !== id
          );
        });
      });
  }

  
  onChangeBeneficiarioDeudor($event){
    if (this.prorroga !=  null && this.prorroga != undefined) {
      let cliente_ = this.prorroga.deudor
      if (this.prorroga.tipo_solicitante ==2) {
          cliente_ = this.prorroga.beneficiario 
      }
      //console.log(this.cabeceraSolicitudForm.controls['solicitante_search'].value)
      if (this.cabeceraSolicitudForm.controls['solicitante_search'].value.id != cliente_) {
          this.toast.warning("Tiene que actualizar el beneficiario");
          //this.enviarCabecera()
      }

      /*console.log(this.prorroga)
      console.info("cambio_beneficiario",$event.id)
      console.info("solicitante",this.cabeceraSolicitudForm.controls.solicitante)
      console.info("solicitante_Seach",this.cabeceraSolicitudForm.controls['solicitante_search'].value)*/

      //this.cabeceraSolicitudForm.controls.solicitante.setValue($event.id)
      // this.cabeceraSolicitudForm.controls['solicitante_search'].value.id = $event.id
    } 
  }

  async deleteComisionesChangeCliente(){
    console.info("nroComisiones",this.comisionsList.length);
    //if (this.comisionsList.length > 0) {
      console.info("this.prorroga",this.prorroga)
      const prorroga_id = this.prorroga.id;
      await this.prorrogasService.eliminarComisionPorProrroga(prorroga_id).then(()=>{
        this.comisionsList = []
      }).catch(()=>{
        this.comisionsList = []
      })
   // }
  }


  async enviarSolicitud() {
    let { id, estado } = this.prorroga;
    if (this.comentario) {
      const data = { comentario: this.comentario, estado };
      await this.prorrogasService.actualizarSolicitudProrroga(id, data);
    }

    await this.prorrogasService
      .enviarSolicitudProrroga({ prorroga: id })
      .then(() => { });

    this.activeModal.close();

    this.toast.success("Se envió la solicitud de prórroga correctamente");
  }

  async aprobarSolicitud() {
    const idProrroga = this.prorroga?.id;
    const estadoProrroga = this.prorroga?.estado;
    const idComite = this.comiteList?.find(
      (el) => el.responsable === this.user.id && el.fecha === null
    )?.id;

    if (this.comentario) {
      const data = { comentario: this.comentario, estado: estadoProrroga };
      await this.prorrogasService.actualizarSolicitudProrroga(idProrroga, data);
    }

    await this.prorrogasService.aprobarSolicitud(idComite);

    this.activeModal.close();
    this.toast.success("Se aprobó la solicitud de prórroga correctamente");
  }

  async rechazarSolicitud() {
    const id = this.prorroga.id;

    if (this.comentario) {
      const data = { comentario: this.comentario, estado: 2 };
      await this.prorrogasService.actualizarSolicitudProrroga(id, data);
    }

    await this.prorrogasService.rechazarSolicitud(id);

    this.activeModal.close();
    this.toast.success("Se rechazó la solicitud de prórroga correctamente");
  }

  async devolverProrrogaSolicitadoARegistrado() {
    const id = this.prorroga.id;
    await this.prorrogasService.devolverProrrogaSolicitadoARegistrado(id)
    this.activeModal.close();
    this.toast.success("Se devolvió la solicitud de prórroga al estado Registrado correctamente");
  }

  aplicarSolicitud() {
    const id = this.prorroga.id;
    this.prorrogasService.aplicarSolicitud(id)
      .then(res => {
        this.activeModal.close();
      })
      .catch(console.log)
  }

  selectFile() {
    document.getElementById("adjunto_input").click();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("adjunto", file);
      formData.append("prorroga", String(this.prorroga.id));

      this.prorrogasService.cargarAdjunto(formData).then((adjunto) => {
        this.adjunto = adjunto;
      });
    }
  }

  async deleteAdjunto() {
    await this.prorrogasService
      .eliminarAdjunto(this.adjunto.id)
      .then(() => (this.adjunto = null));
  }

  async obtenerDocumentosCartera(
    page = 1,
    page_size: 1000,
    beneficiario = "",
    deudor = "",
    fecha_gte = "",
    fecha__lte = "",
    empresa = "",
    operacion = "",
  ) {
    return await this.prorrogasService
      .obtenerDocumentosCartera(beneficiario, deudor, fecha_gte, fecha__lte, page, page_size, true, empresa, operacion, this.aplicacionFilter)
      .then((documents: any) => {
        const { results, count } = documents;
        let tablePages = [];
        let filteredList = results;
        this.pageDocumentsCarteraTableTotal = count;

        if (this.documentsCarteraSelectedList.length > 0) {
          this.documentsCarteraSelectedList.forEach((doc) => {
            const { documento } = doc;

            filteredList = filteredList
              .filter((doc: any) => doc.id !== documento)

            this.documentsCarteraList = filteredList;
          });
        } else {
          this.documentsCarteraList = results.filter(
            (doc: any) => doc.saldo > 0
          );
        }

        for (
          let index = 0;
          index < Math.floor(count / page_size) + 1;
          index++
        ) {
          tablePages.push(index);
        }

        this.documentsCarteraList = this.documentsCarteraList.map(
          (doc: any, index: number) => ({ ...doc, index: index + 1 })
        );

        this.pageDocumentsCarteraTableActual = page;
        this.pageDocumentsCarteraTablePerPages = page_size;
        this.pageDocumentsCarteraTablePages = tablePages;
      });
  }

  async obtenerDocumentosCarteraVacantes(
    page = 1,
    page_size: 10,
    beneficiario = "",
    deudor = "",
    empresa = ""
  ) {
    return await this.prorrogasService
      .obtenerDocumentosCartera(beneficiario, deudor, "", "", page, page_size, true, empresa)
      .then((documents: any) => {
        const { results, count } = documents;
        let tablePages = [];
        let filteredList = results;
        this.pageDocumentsCarteraTableTotal = count;

        if (this.documentsCarteraSelectedList.length > 0) {
          this.documentsCarteraSelectedList.forEach((doc) => {
            const { documento } = doc;

            filteredList = filteredList
              .filter((doc: any) => doc.id !== documento)

            this.documentsCarteraList = filteredList;
          });
        } else {
          this.documentsCarteraList = results.filter(
            (doc: any) => doc.saldo > 0
          );
        }

        for (
          let index = 0;
          index < Math.floor(count / page_size) + 1;
          index++
        ) {
          tablePages.push(index);
        }

        this.documentsCarteraList = this.documentsCarteraList.map(
          (doc: any, index: number) => ({ ...doc, index: index + 1 })
        );

        this.pageDocumentsCarteraTableActual = page;
        this.pageDocumentsCarteraTablePerPages = page_size;
        this.pageDocumentsCarteraTablePages = tablePages;
      });
  }

  async showDocumentsCartera() {
    let cliente_ = this.prorroga.deudor
    if (this.prorroga.tipo_solicitante ==2) {
        cliente_ = this.prorroga.beneficiario 
    }
    /*console.info(this.cabeceraSolicitudForm.controls['solicitante_search'].value.id.toString(),cliente_)
    console.info(this.cabeceraSolicitudForm.controls['solicitante_search'].value)
    console.log((this.cabeceraSolicitudForm.controls['solicitante_search'].value.id.toString() !== cliente_.toString()))*/
    if (this.cabeceraSolicitudForm.controls['solicitante_search'].value.id.toString() !== cliente_.toString()) {
      this.toast.warning("Tiene que actualizar el beneficiario");
      return false
    }
    
    const { tipo, solicitante } = this.cabeceraSolicitudForm.controls;

    let beneficiario = tipo?.value === 2 ? solicitante?.value: "";
    let deudor       = tipo?.value === 1 ? solicitante?.value: "";
    let empresa      = this.cabeceraSolicitudForm.controls['empresa'].value;

    this.aplicacionFilter = null
    this.loadAplicaciones()
    await this.obtenerDocumentosCarteraVacantes(1, 10, beneficiario, deudor, empresa);

    this.modalDocumentsCarteraReference = this.modalService.open(
      this.e_documentscartera,
      {
        size: "xl",
      }
    );
  }

  goToPageDocumentsCartera({ page, per_page, filtros }) {
    const { tipo, solicitante } = this.cabeceraSolicitudForm.controls;
    let beneficiario = tipo?.value === 2 ? solicitante?.value : "";
    let deudor = tipo?.value === 1 ? solicitante?.value : "";
    let { operacion, fecha__lte, fecha__gte } = filtros;
    fecha__lte  = fecha__lte ? fecha__lte.split(' ')[0] : ""
    fecha__gte  = fecha__gte ? fecha__gte.split(' ')[0] : ""
    this.obtenerDocumentosCartera(page, per_page, beneficiario, deudor, fecha__gte, fecha__lte, "", operacion);
  }

  selectedItem(row: any) {
    const id = row.id;

    if (this.documentsCarteraSelectionList.indexOf(id) === -1)
      this.documentsCarteraSelectionList.push(id);
    else
      this.documentsCarteraSelectionList = this.documentsCarteraSelectionList.filter(
        (item) => item !== id
      );
  }

  async registrarDetalles() {
    for (let i = 0; i < this.documentsCarteraSelectionList.length; i++) {
      const data = { prorroga: this.prorroga.id, documento: this.documentsCarteraSelectionList[i] };
      let detalle: any = await this.prorrogasService.registrarDetalleDocumento(data)
      this.documentsCarteraSelectedList.push(
        { ...detalle, 
          saldo_financiado: detalle.saldo_financiado ?? 0 ,
          index: i + 1 }
        );
    }

    this.documentsCarteraSelectionList = [];

    this.loadComisiones()

    this.modalDocumentsCarteraReference.close();
  }

  async eliminarDetalle(row: any) {
    const { id } = row;
    await this.prorrogasService.eliminarDetalleDocumento(id).then(() => {
      this.documentsCarteraSelectedList = this.documentsCarteraSelectedList.filter(
        (doc: any) => doc.id !== id
      );
    });

    this.loadComisiones()
  }

  async loadComisiones() {
    await this.prorrogasService.obtenerComision(this.prorroga.id).then((res: any) => {
      this.comisionsList = res.results.map((e, i) => ({
        ...e,
        index: i + 1,
      }))
    })
  }

  // TODO: Deprecated las comisiones se crean y actualizan en backend
  async registrarComision() {
    if (this.comisionsList.length > 0) {
      const { prorroga } = this.comisionsList[0];

      if (prorroga)
        await this.prorrogasService
          .eliminarComisionPorProrroga(prorroga)
          .then(() => (this.comisionsList = []));
    }

    await this.prorrogasService
      .registrarMultiplesComisiones({ prorroga: this.prorroga.id })
      .then((comisiones: object[]) => {
        comisiones.map((comision, idx) => {
          this.comisionsList.push({...comision, index: idx + 1})
        })
      });
  }

  formatDate(date) {
    if (!(date instanceof Date)) return;

    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  formatDateInput(date, type) {
    if (!date) return null;

    if (type === "string") {
      let dateArray = date.split("-");

      let year = dateArray[0];
      let month = parseInt(dateArray[1], 10) - 1;
      let stringDate = dateArray[2];
      let _entryDate = new Date(year, month, stringDate);
      return _entryDate.toLocaleString("es-ES").split(",")[0].split(" ")[0];
    } else {
      return date.toLocaleString("es-ES").split(",")[0].split(" ")[0];
    }
  }

  parseDate(date) {
    if (!date) return null;

    let dateNow = new Date();

    let yy = date.split("-")[0];
    let mm = date.split("-")[1] - 1;
    let dd = date.split("-")[2];

    let d = new Date(yy, mm, dd, dateNow.getHours());
    return d;
  }



  loadSearch() {
    this.beneficiariosResults$ = concat(
      of([]), // Items predeterminados
      this.searchBeneficiarios$.pipe(
        filter(res => {
          return res !== null && res.length >= 4
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.loadingBeneficiarioSearch = true),
        switchMap(term => {
          return this.prorrogasService.buscarBeneficiariosObserver(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.loadingBeneficiarioSearch = false)
          )
        })
      )
    );

    this.deudoresResults$ = concat(
      of([]), // Items predeterminados
      this.searchDeudores$.pipe(
        filter(res => {
          return res !== null && res.length >= 4
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.loadingDeudorSearch = true),
        switchMap(term => {
          return this.prorrogasService.buscarDeudorObserver(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.loadingDeudorSearch = false)
          )
        })
      )
    );
  }

  download(adjunto) {
    let urlDoc = adjunto.adjunto;
    const ultimoSlash = new URL(urlDoc).pathname;
    const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1];
    this.downloadFile(urlDoc, adjuntoName);
  }

  downloadFile(url, name) {
    this.prorrogasService.downloadFile(url).then(
      (x: any) => {
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = name;
        link.click();
      }, async (reason) => {
        console.log(reason);
      }), err => {
        console.error(err);
      };
  }


  userCanEdit(){
    return this.user.isAdmin || this.user.isGerenteComercial || this.user.isGerenteGeneral || this.user.isJefeDeOperaciones || this.user.isAsistenteCobranzas || this.user.isAsistenteComercial
  }

  showEditarDetalle(item) {
    const modalRef = this.modalService.open(EditarDetalleComponent, {
      size: "lg",
    });
    modalRef.componentInstance.itemInstance = item;
    modalRef.result.then(
      (result) => {console.log('res', result)},
      (reason) => {console.log('rea', reason)},
    );
    modalRef.componentInstance.successEmit.subscribe(
      async (result) => {
        this.prorrogasService.spinnerOn()
        this.prorrogasService.actualizarDetalleDocumento(item.id, result).subscribe(
          (data: any) => {
            this.prorrogasService.spinnerOff()
            Object.assign(item, data)
          },
          (res) => {
            let error = 'Ocurrió un error inesperado'
            if (res.error && typeof(res.error) == 'object') {
              error = Object.values(res.error).join('\n');
            }
            this.prorrogasService.spinnerOff(error, false);
          }
        )
      },
      (error) => { console.log(error) }
    );
  }

  exportar() {
    this.prorrogasService.spinnerOn()
    this.prorrogasService.exportarXls(this.prorroga.id)
      .then(() => {
        this.prorrogasService.spinnerOff()
      })
      .catch(res => {
        let error = 'Ocurrió un error inesperado'
        if (res.error && typeof(res.error) == 'object') {
          error = Object.values(res.error).join('\n');
        }
        this.prorrogasService.spinnerOff(error, false)
      })
  }

  openImportarAplicacion() {
    let prorroga = this.prorroga.id
    let recaudacion = prompt('Aplicación') // TODO: Cambiar por modal
    this.prorrogasService.spinnerOn()
    this.prorrogasService.importarAplicacion({prorroga, recaudacion}).subscribe(
      data => {
        this.prorrogasService.obtenerDetalles(this.prorroga.id).then((res: any) => {
          this.documentsCarteraSelectedList = res.results.map((e, i) => ({
            ...e,
            saldo_financiado: e.saldo_financiado ?? 0, 
            index: i + 1,
          }));
        })
    
        this.loadComisiones()
      },
      res => console.log(res)
    ).add(() => this.prorrogasService.spinnerOff())
  }

  loadAplicaciones() {
    this.prorrogasService.obtenerAplicaciones({
      'prorrogar': true,
      'docs_prorrogar': true,
      'cliente': this.prorroga['tipo_solicitante'] == 2 ? this.prorroga['beneficiario'] : '',
      'aceptante': this.prorroga['tipo_solicitante'] == 1 ? this.prorroga['deudor'] : '',
    }).subscribe(
      (data: any) => this.aplicaciones = data,
      res => {
        this.aplicaciones = []
        console.log(res)
      }
    )
  }

}
