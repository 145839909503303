import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { AdminLayoutComponent } from 'app/layouts/admin/admin-layout.component';
import { FacturacionComponent } from './facturacion/facturacion.component';


const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Facturación'
    },
    children: [
      {
        path: 'facturacion',
        canActivate: [AuthGuard],
        component: FacturacionComponent,
        data: {
          breadcrumb: 'Facturación'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FacturacionRoutingModule { }
