import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
export type DinamicMultiSelectInput = {
  label: string;
  is_disabled: boolean;
  validator_name: string;
  is_unnique: boolean;
  type: string;
  class: string;
}
export interface DinamicMultiSelectInputs {
  form: FormGroup,
  inputs: DinamicMultiSelectInput[]
}
export interface isActionsDinamicMultiSelect {
  isDelete?: boolean;
  isEdit?: boolean;
  isAdd?: boolean;
}

@Component({
  selector: 'app-form-multiselect',
  templateUrl: './form-multiselect.component.html',
  styleUrls: ['./form-multiselect.component.css']
})
export class FormDinamicMultiSelectComponent implements OnInit {
  // Listas
  public inputs: any[] = [];

  // Utils
  public selectContent: any;
  public result: any[] = [];
  public indice: number = 0
  public renderContent: any[] = [];
  public isNotApply: boolean = true
  public inputContent: any
  public inputTextContent: any
  // Inputs
  @Input() content : any[] = [];
  @Input() dinamicSelectLabel : string = '';
  @Input() dinamicMultiSelectInputs: DinamicMultiSelectInputs;
  @Input() dinamicSelectFormGroup: FormGroup ;
  @Input() dinamicSelectFormGroupName: string = '';
  @Input() dinamicSelectShowContent: any[];
  @Input() isActions: isActionsDinamicMultiSelect;

  // Outputs
  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() setContentEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    
    this.dinamicSelectFormGroup = this.dinamicMultiSelectInputs['form']

    this.dinamicSelectFormGroup.addControl('content', new FormControl([], Validators.required));
    this.dinamicSelectFormGroup.addControl('search_string', new FormControl('', Validators.required));
    this.dinamicSelectFormGroup.addControl('descripcion', new FormControl('', Validators.required));

    // Detecta al seleccionar un item
    this.dinamicSelectFormGroup.get('content').valueChanges.subscribe((item)=>{
      this.autoCompleteInputs(item)
    })
    // Detecta cambios en el input con el FormControlName SearchString
    this.dinamicSelectFormGroup.get('search_string').valueChanges.subscribe((value)=>{
      this.searchItem(value)
    })
    // Detecta cambios en el input con el FormControlName Descripcion
    this.dinamicSelectFormGroup.get('descripcion').valueChanges.subscribe((value)=>{
      this.inputContent = value
      this.isInputContent()
    })
  }
  isContent(){
    return this.content.length > 0
  }
  
  validateExistsValueUnniqueInput(){
      const compare_list = this.getInputsDinamicSelect()['content']
      const inputs = this.dinamicMultiSelectInputs['inputs'];
      var is_exists = false
      inputs.forEach(input => {
      // Si el input es unico: Validar
      if(input['is_unnique']==true){
        // Obtenemos el valor del input escrito por el usuario
        const value_input = this.dinamicSelectFormGroup.controls[input['validator_name']].value
        // Filtramos existentes donde el valor del usuario sea igual al valor del item
        let exists = this.content.filter(
          (item, index)=>item[input['validator_name']] === value_input && !(compare_list.includes(item.indice.toString()))
        )
        // Si existe almenos un valor, devolver True, si no, false
        if(exists.length > 0){
          is_exists = true
        }
        
      }
      
    })
    return is_exists
  }
  
  concatItemValuesToString(item: {}){
    if(!this.dinamicSelectShowContent){
      return Object.values(item).join(" | ")
    }
    let itemToString = []
    this.dinamicSelectShowContent.map(key=>{
      if(item[key]){
        itemToString[key] = item[key]
      }
    })
    return Object.values(itemToString).join(" | ")
    
  }
  getInputsDinamicSelect(){
    let data = {}
    for(const key in this.dinamicSelectFormGroup.controls){
      data[key] = this.dinamicSelectFormGroup.controls[key].value  
    }
    return data
  }
  getInputItem(){
    let item = {}
    let inputValues = this.getInputsDinamicSelect()
    for(const key in inputValues){
      if(key != 'content' && key != 'search_string'){
        item[key]= inputValues[key]
      }
    }
    
    return item
  }
  addButton(){
    /* Recorrer y asignar cada KEY:VALUE de {dinamicSelectFormGroup} y enviarlo
     * como un objeto al metodo {addItem}
    */
    let item = this.getInputItem();
   
    if(!this.validateExistsValueUnniqueInput()){
      this.addItem(item)
    } else {
      alert("Ya existe un registro con ese valor")
    }
    
    
    
  }
  addItem(item: {}){
    /**
     * Tomar el item con su KEY:VALUE y añadir como objeto a {content}
     */
    
    item['indice'] = this.indice
    this.indice++
    this.content.push({
      ...item,
    })
    
  }
  deleteItem(){
    let contentRemove = this.getInputsDinamicSelect()['content']
    let filtred = []
    let removes = []
    let ids = []
    /**
     * Guardamos los filtrados en {filtred}
     */
    filtred = this.content.filter((value)=>
      !contentRemove.includes(value.indice.toString()),
    )
    removes = this.content.filter((value)=>
      contentRemove.includes(value.indice.toString()),
    )
    /**
     * Obtenemos las ids a traves del index que recogemos en {contentRemove}
     */
    removes.forEach((value)=>{
      if ('id' in value){
        ids.push(value.id)
      }
      
    })
    /**
     * Establecemos la nueva lista para content y luego ejecutamos la funcion recibida enviando las ids
     */
    this.content = filtred
    this.result = filtred 
    
    this.deleteEvent.emit({ids, filtred})

  }
  editItem(){
    let inputValues = this.getInputsDinamicSelect();

    if (inputValues['content'].length > 1){
      return alert('Por favor escoja solo un valor');
    }
    const item_indice = inputValues['content'][0]
    
    /**
     * Recorrer cada KEY:VALUE de {content}, obtener los valores de {getInputDinamicSelect}
     * y remplazarlo usando {index_item}
     */
    let data = this.getInputItem();
    
    this.content = this.setList(this.content, item_indice, data)
    this.result = this.setList(this.result, item_indice, data)

  }
  isEdit(){
    let content = this.getInputsDinamicSelect()['content']
    if (content){
      return true ? content.length == 1 : false;
    }
  }
  isDelete(){
    let content = this.getInputsDinamicSelect()['content']
    if (content){
      return true ? content.length > 0 : false;
    }
  }

  isInputContent(){
    let list = []

    this.content.forEach(element => {
      list.push(element.descripcion)
    });

    const result = list.filter(list => list == this.inputContent);
    
    if (result.length > 0) {
      this.isNotApply = true
    }else{
      this.isNotApply = false
    }

    if (this.inputContent == '') {
      this.isNotApply = true
    }
  }

  autoCompleteInputs(content:any[]){
    if (content.length == 1){
      const item_indice = content[0] 
      
      const selected_content = this.content[parseInt(item_indice)];
      for(const key in this.dinamicSelectFormGroup.controls){
        if(key != 'content' && key != 'search_string'){
          this.inputTextContent = selected_content.descripcion
          this.dinamicSelectFormGroup.controls[key].setValue(selected_content[key]) 
        }
        
      }
    }
  }
  searchItem(query: string = ''){
    // Inicializamos result en una lista vacia
    this.result = [];
    if (query != '') {
      // recorremos el content
      this.content.forEach(item => {
        // Hacemos LowerCase y filtramos 
        if (item.descripcion.toLowerCase().indexOf(query.toLowerCase()) == 0) {
          this.result.push(item);
        }
      });
    }else{
      this.result = this.content;
    }
  }
  
  // Validamos si el input de busqueda esta vacio para inicializar el content
  validateSearchString(){
    if(this.dinamicSelectFormGroup.controls['search_string'].value == ''){
      return this.content
    }
    return this.result
  }
  
  getItemIndex(list: any[], indice:number){
    return list.findIndex(
      item => item.indice == indice
    )
  }
  setList(list: any[], indice:number, data: any){
    let actual_item = list[this.getItemIndex(list, indice)]

    list[indice] = {
      id: actual_item.id ? actual_item.id : null,
      indice: actual_item.indice,
      ...data
    }
    return list
  }
  sendContent(){
    this.setContentEvent.emit(this.content)
  }
}