import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { RestriccionesService } from 'app/core/services/auth/restricciones.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OpenTextModalComponent } from 'app/shared/modals/open-text-modal/open-text-modal.component';
import { DesglosesComponent } from 'app/shared/desgloses/desgloses.component';
import { AuthService } from 'app/core/services/auth/auth.service';
import { CommonServicesService } from 'app/core/services/common-services.service';
import { TasasHistoricasComponent } from '../../modals/tasas-historicas/tasas-historicas.component';
import { faExclamation, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ExcepcionDeudaLeasingComponent } from 'app/shared/modals/operaciones/excepcion-deuda-leasing/excepcion-deuda-leasing.component';

@Component({
  selector: 'app-committee',
  templateUrl: './committee.component.html',
  styleUrls: ['./committee.component.css']
})
export class CommitteeComponent implements OnInit {
  @ViewChild('confirmarGuardar', { static: false }) public confirmarGuardar;
  faTimes = faTimes
  faSyncAlt=faSyncAlt;
  faExclamation=faExclamation;
  fakerMembers = [
    {
      id: 1,
      name: 'Jorge',
      date: '09/10/2020'
    },
    {
      id: 1,
      name: 'Isabel',
      date: '09/10/2020'
    },
    {
      id: 1,
      name: 'Eduardo',
      date: '09/10/2020'
    },
    {
      id: 1,
      name: 'Eleazar',
      date: '09/10/2020'
    }
  ];

  public columnDefs: any[];
  public loadingTable: boolean = false;
  public estaciones: any[] = [];
  public members: any[] = [];
  public checkchecked: any = {};
  public documentoDesacoplar: any = {};
  public documentoSelect: any[] = [];
  public documentoSelectList: any[] = [];
  public committeeForm: FormGroup;
  public tiposLinea: any[] = [];
  @Input() condiciones: any[] = []
  public tiposMoneda: any[] = [];
  public tiempos: any[];
  public propuestaContext = {};
  contrato: any;
  @Input() tiposFirma: any[] = []
  @Input() tiposFianza: any[] = []
  @Input!() hasContrato: boolean = false;
  @Input() operacionId: any = 0;
  @Input() operacion: any = 0;
  @Output() formSend = new EventEmitter<boolean>();
  @Output() guardarIguales = new EventEmitter<boolean>();

  @Output() changeForm = new EventEmitter<any>();
  @Output() canAprobe = new EventEmitter<any>();

  @Input() readOnlyCommittee = false;
  desglosesLineaPropuesta: any;
  desglosesDisabled: boolean = false;

  temMinimoSoles = 0
  temMinimoDolares = 0
  @Input() tieneDeudaVencida: boolean = false

  constructor(
    public toast: ToastService,
    private propuestaService: PropuestaComercialService,
    private configs: DocschecklistService,
    private formbuild: FormBuilder,
    public serviciosComunes: CommonServicesService,
    public restricciones: RestriccionesService,
    public modalService: NgbModal,
    public authService     : AuthService
    ) {

  }

  ngOnInit(): void {
    this.initForm();

    if (!this.operacion.contrato) {
      this.committeeForm.get('contrato_nuevo_comite').disable()
      this.committeeForm.get('contrato_nuevo_comite').setValue(true)
      this.committeeForm.get('tipo_fianza_comite').setValidators([Validators.required])
      this.committeeForm.get('tipo_firma_comite').setValidators([Validators.required])
    } else {
      this.committeeForm.get('contrato_nuevo_comite').enable()
    }

    this.tiempos = [
      { id: 1, descripcion: 'Días' },
      { id: 2, descripcion: 'Meses' },
      { id: 3, descripcion: 'Años' }
    ];

    let lineas    = this.configs.obtenerLineas();
    let monedas   = this.configs.obtenerMonedas();
    let propuesta = this.propuestaService.obtenerPropuestaComite(this.operacionId);
    let desgloses = this.serviciosComunes.getDesglose(this.operacionId,3)

    Promise.all([propuesta, lineas, monedas,desgloses]).then((res: any[]) => {
      let dataPropuesta = res[0];
      this.propuestaContext = res[0];
      this.restricciones.listaAprobaciones = dataPropuesta['comite_aprobaciones']
      this.restricciones.propuestaComite = dataPropuesta
      this.evalFormulario()
     
      this.committeeForm.reset({
        tipo_linea_comercial          : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
        moneda_linea                  : dataPropuesta['moneda_comite'],
        moneda_comite                 : dataPropuesta['moneda'],
        monto_linea                   : dataPropuesta['linea_propuesta_comite'],
        vigencia                      : dataPropuesta['vigencia_comite'],
        vigencia_tipo                 : dataPropuesta['tipo_vigencia_comite'],
        tea_soles                     : dataPropuesta['tea_soles_comite'],
        tem_soles                     : dataPropuesta['tem_soles_comite'],
        tea_dolares                   : dataPropuesta['tea_dolares_comite'],
        tem_dolares                   : dataPropuesta['tem_dolares_comite'],
        tasa_mora                     : dataPropuesta['tasa_mora_comite'],
        anticipo                      : dataPropuesta['anticipo_comite'],
        plazo                         : dataPropuesta['plazo_comite'],
        plazo_tipos                   : dataPropuesta['tipo_plazo_comite'],
        adelanto_                     : dataPropuesta['adelanto_comite'],
        moneda_abono_leasing          : dataPropuesta['moneda'],
        abono_leasing                 : dataPropuesta['abono_leasing_comite'],
        contrato_nuevo_comite         : !this.operacion.contrato ? true: dataPropuesta["contrato_nuevo_comite"],
        comentarios                   : dataPropuesta['comentarios_comite'],
        ofical_negocio                : dataPropuesta['analista_riesgo_nombre'],
        tipo_firma_comite             : dataPropuesta['tipo_firma_comite'],
        tipo_fianza_comite            : dataPropuesta['tipo_fianza_comite'],
        comision_estructuracion_comite: dataPropuesta['comision_estructuracion_comite'],
        condiciones_comite            : dataPropuesta['condiciones_comite'],
      });

      dataPropuesta['vigencia_comite'] ? null     : this.committeeForm.controls['vigencia_tipo'].setValue(2)
      dataPropuesta['tipo_vigencia_comite'] ? null: this.committeeForm.controls['vigencia'].setValue(12)

      this.tiposLinea              = res[1]['results'];
      this.tiposMoneda             = res[2]['results'];
      this.desglosesLineaPropuesta = res[3]['desgloses']
      this.members                 = dataPropuesta.comite_aprobaciones
      if(this.operacion.puntual){
        this.committeeForm.get('contrato_nuevo_comite').setValue(true)
        this.committeeForm.get('contrato_nuevo_comite').disable()
        this.desglosesDisabled = true
      }
      this.obtenerContrato(this.propuestaContext['id']);

      // Si esta denegada "Borra info no importante"
      if (this.operacion.estado == 9) this.setInviable()

    }).catch(error => {
      console.error(error);
    });

    this.loadTable();

    if (this.readOnlyCommittee) {
      this.committeeForm.disable();
    } else {
      // this.committeeForm.enable();
    }

  }

  getPropuestaComite() {
    let lineas = this.configs.obtenerLineas();
    let monedas = this.configs.obtenerMonedas();
    let propuesta = this.propuestaService.obtenerPropuestaComite(this.operacionId);

    Promise.all([propuesta, lineas, monedas]).then((res: any[]) => {
      let dataPropuesta = res[0];
      this.propuestaContext = res[0];
      this.restricciones.propuestaComite = dataPropuesta
      this.restricciones.listaAprobaciones = dataPropuesta['comite_aprobaciones']
      this.committeeForm.reset({
        tipo_linea_comercial          : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
        moneda_linea                  : dataPropuesta['moneda_comite'],
        moneda_comite                 : dataPropuesta['moneda'],
        monto_linea                   : dataPropuesta['linea_propuesta_comite'],
        vigencia                      : dataPropuesta['vigencia_comite'],
        vigencia_tipo                 : dataPropuesta['tipo_vigencia_comite'],
        tea_soles                     : dataPropuesta['tea_soles_comite'],
        tem_soles                     : dataPropuesta['tem_soles_comite'],
        tea_dolares                   : dataPropuesta['tea_dolares_comite'],
        tem_dolares                   : dataPropuesta['tem_dolares_comite'],
        tasa_mora                     : dataPropuesta['tasa_mora_comite'],
        anticipo                      : dataPropuesta['anticipo_comite'],
        plazo                         : dataPropuesta['plazo_comite'],
        plazo_tipos                   : dataPropuesta['tipo_plazo_comite'],
        adelanto_                     : dataPropuesta['adelanto_comite'],
        moneda_abono_leasing          : dataPropuesta['moneda'],
        abono_leasing                 : dataPropuesta['abono_leasing_comite'],
        contrato_nuevo_comite         : !this.operacion.contrato ? true: dataPropuesta["contrato_nuevo_comite"],
        comentarios                   : dataPropuesta['comentarios_comite'],
        ofical_negocio                : dataPropuesta['analista_riesgo_nombre'],
        tipo_firma_comite             : dataPropuesta['tipo_firma_comite'],
        tipo_fianza_comite            : dataPropuesta['tipo_fianza_comite'],
        comision_estructuracion_comite: dataPropuesta['comision_estructuracion_comite'],
        condiciones_comite            : dataPropuesta['condiciones_comite'],
      });
      this.evalFormulario()
      this.tiposLinea = res[1]['results'];
      this.tiposMoneda = res[2]['results'];
      this.members = dataPropuesta.comite_aprobaciones

      this.obtenerContrato(this.propuestaContext['id']);

    }).catch(error => {
      console.error(error);
    });
  }

  loadTable() {
    this.columnDefs = [
      {
        headerName: 'N°',
        pipe: 'indexcol',
        sortable: true
      },
      {
        headerName: 'Aprobador',
        field: 'integrante_nombre',
        sortable: true,
        class: 'text-center'
      },
      {
        headerName: 'Fecha',
        field: 'fecha',
        sortable: true,
        pipe: 'date',
        defaultInfo: 'Aún no aprobado',
        class: 'text-center'
      },
    ];
  }

  initForm() {
    let producto = this.operacion?.tipo_linea_solicitada || this.operacion?.operacion?.tipo_linea_solicitada
    let fecha = new Date(this.operacion?.fecha || this.operacion?.operacion?.fecha || new Date())
    let fechaInicioValidacion = new Date(2022, 8, 14) // Validar operaciones creadas desde esta fecha
    this.temMinimoSoles = (producto == 2 || fecha.getTime() < fechaInicioValidacion.getTime()) ? 0 : 1//(this.operacion?.linea_credito ? 1.5 : 1.6)
    this.temMinimoDolares = (producto == 2 || fecha.getTime() < fechaInicioValidacion.getTime()) ? 0 : 1//(this.operacion?.linea_credito ? 1.47 : 1.57)

    this.committeeForm = this.formbuild.group({
      tipo_linea_comercial          : ['', [Validators.required]],
      moneda_linea                  : ['', [Validators.required]],
      moneda_comite                 : ['', [Validators.required]],
      monto_linea                   : ['', [Validators.required,validarMontoLinea]],
      vigencia                      : ['', [Validators.required]],
      vigencia_tipo                 : ['', [Validators.required]],
      tea_soles                     : ['', []],
      tem_soles                     : ['', [Validators.required, Validators.min(this.temMinimoSoles)]],
      tea_dolares                   : ['', []],
      tem_dolares                   : ['', [Validators.required, Validators.min(this.temMinimoDolares)]],
      tasa_mora                     : ['', [Validators.required]],
      anticipo                      : ['', [Validators.required]],
      plazo                         : ['', [Validators.required]],
      plazo_tipos                   : ['', [Validators.required]],
      comentarios                   : ['', [Validators.required]],
      adelanto_                     : ['', [Validators.required, Validators.max(this.operacion.monto - 1)]],
      moneda_abono_leasing          : ['', [Validators.required]],
      abono_leasing                 : [0],
      contrato_nuevo_comite         : [false],
      ofical_negocio                : ['', []],
      tipo_firma_comite             : ['', []],
      tipo_fianza_comite            : ['', []],
      comision_estructuracion_comite: ['', []],
      condiciones_comite            : [[]]
    });

    this.committeeForm.valueChanges.subscribe(value => {
      if (this.diffEmpty(value)) {
        this.canAprobe.emit(this.committeeForm.valid)
        this.changeForm.emit(this.committeeForm.value);
      }
    });

  }

  setForm(values: any, prop = null) {

    if (prop) {
      switch(prop){
        case 'tipo_firma_comercial':
          this.committeeForm.get('tipo_firma_comite').setValue(values)
        break;
        case 'tipo_firma_riesgos':
          this.committeeForm.get('tipo_firma_comite').setValue(values)
        break;
        case 'tipo_fianza_comercial':
          this.committeeForm.get('tipo_fianza_comite').setValue(values)
        break;
        case 'tipo_fianza_riesgos':
          this.committeeForm.get('tipo_fianza_comite').setValue(values)
        break;
        case 'comision_estructuracion_comercial':
          this.committeeForm.get('comision_estructuracion_comite').setValue(values)
        break;
        case 'comision_estructuracion_riesgos':
          this.committeeForm.get('comision_estructuracion_comite').setValue(values)
        break;
        default:
          this.committeeForm.get(prop).setValue(values)
        break;
      }
      
      return
    }
    
    if (values['analista_riesgo']) {
      this.committeeForm.reset({
        tipo_linea_comercial          : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
        moneda_linea                  : values['moneda_riesgos'],
        monto_linea                   : values['linea_propuesta_riesgos'],
        moneda_comite                 : values['moneda'],
        vigencia                      : values['vigencia_riesgos'],
        vigencia_tipo                 : values['tipo_vigencia_riesgos'],
        tea_soles                     : values['tea_soles_riesgos'],
        tem_soles                     : values['tem_soles_riesgos'],
        tea_dolares                   : values['tea_dolares_riesgos'],
        tem_dolares                   : values['tem_dolares_riesgos'],
        tasa_mora                     : values['tasa_mora_riesgos'],
        anticipo                      : values['anticipo_riesgos'],
        plazo                         : values['plazo_riesgos'],
        plazo_tipos                   : values['tipo_plazo_riesgos'],
        comentarios                   : values['comentarios_riesgos'],
        ofical_negocio                : this.propuestaContext['analista_riesgo_nombre'],
        contrato_nuevo_comite         : !this.operacion.contrato ? true: values['contrato_nuevo_riesgos'], // SI NO HAY CONTRADO ACTIVAR SWITCH COMO NUEVO CONTRATO
        adelanto_                     : values['adelanto_riesgos'],
        tipo_firma_comite             : values['tipo_firma_riesgos'],
        tipo_fianza_comite            : values['tipo_fianza_riesgos'],
        comision_estructuracion_comite: values['comision_estructuracion_riesgos'],
        condiciones_comite            : values['condiciones_riesgos'],
        moneda_abono_leasing          : values["moneda_riesgos"],
        abono_leasing                 : values["abono_leasing_riesgos"],
      });
    } else {
      this.committeeForm.reset({
        tipo_linea_comercial          : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
        moneda_linea                  : values['moneda_comercial'],
        monto_linea                   : values['linea_propuesta_comercial'],
        moneda_comite                 : values['moneda'],
        vigencia                      : values['vigencia_comercial'],
        vigencia_tipo                 : values['tipo_vigencia_comercial'],
        tea_soles                     : values['tea_soles_comercial'],
        tem_soles                     : values['tem_soles_comercial'],
        tea_dolares                   : values['tea_dolares_comercial'],
        tem_dolares                   : values['tem_dolares_comercial'],
        tasa_mora                     : values['tasa_mora_comercial'],
        anticipo                      : values['anticipo_comercial'],
        plazo                         : values['plazo_comercial'],
        plazo_tipos                   : values['tipo_plazo_comercial'],
        comentarios                   : values['comentarios_comercial'],
        contrato_nuevo_comite         : !this.operacion.contrato ? true: values['contrato_nuevo_comercial'], // SI NO HAY CONTRADO ACTIVAR SWITCH COMO NUEVO CONTRATO
        ofical_negocio                : this.propuestaContext['analista_riesgo_nombre'],
        adelanto_                     : values['adelanto_comercial'],
        tipo_firma_comite             : values['tipo_firma_comercial'],
        tipo_fianza_comite            : values['tipo_fianza_comercial'],
        comision_estructuracion_comite: values['comision_estructuracion_comercial'],
        condiciones_comite            : values['condiciones_comercial'],
        moneda_abono_leasing          : values["moneda_comercial"],
        abono_leasing                 : values["abono_leasing_comercial"] ?? values['abono_leasing'],
      });
    }

  }
  
  
  setInviable(){
    this.committeeForm.reset({
      moneda_linea                   : 1,
      monto_linea                    : 0,
      vigencia                       : 0,
      vigencia_tipo                  : 1,
      tea_soles                      : 0,
      tem_soles                      : 0,
      tea_dolares                    : 0,
      tem_dolares                    : 0,
      tasa_mora                      : 0,
      anticipo                       : 0,
      plazo                          : 0,
      plazo_tipos                    : 1,
      oficial_negocio                : null,
      adelanto_                      : 0,
      tipo_fianza_riesgos            : 1,
      tipo_firma_riesgos             : 1,
      comision_estructuracion_riesgos: 0,
      contrato_nuevo_comite          : false,
      condiciones_comite             : [],
      comentarios: this.propuestaContext['comentarios_comite'],
      abono_leasing                  :0
    });
  }

  public setValueForm(formControl: string, value) {
    this.committeeForm.controls[formControl].setValue(value);
  }

  saveButton() {
    let existeAlgunoAprobado = this.members.some(e => e.fecha)

    if (existeAlgunoAprobado) {
      this.modalService.open(this.confirmarGuardar, {
        size: 'md'
      }).result.then((result) => {
        if (result)
          this.saveForm()
      })
    } else {
      this.saveForm()
    }
  }

  async saveForm() {

    if (!this.committeeForm.valid)
      return
    this.committeeForm.get('contrato_nuevo_comite').enable()
    const req = {
      tipo_linea_comite             : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
      linea_propuesta_comite        : this.committeeForm.controls['monto_linea'].value,
      moneda_comite        : this.committeeForm.controls['moneda_linea'].value,
      vigencia_comite               : this.committeeForm.controls['vigencia'].value,
      tipo_vigencia_comite          : this.committeeForm.controls['vigencia_tipo'].value,
      tea_soles_comite              : this.committeeForm.controls['tea_soles'].value,
      tem_soles_comite              : this.committeeForm.controls['tem_soles'].value,
      tea_dolares_comite            : this.committeeForm.controls['tea_dolares'].value,
      tem_dolares_comite            : this.committeeForm.controls['tem_dolares'].value,
      tasa_mora_comite              : this.committeeForm.controls['tasa_mora'].value,
      anticipo_comite               : this.committeeForm.controls['anticipo'].value,
      anticipo_comite_respaldo      : this.committeeForm.controls['anticipo'].value,
      plazo_comite                  : this.committeeForm.controls['plazo'].value,
      tipo_plazo_comite             : this.committeeForm.controls['plazo_tipos'].value,
      comentarios_comite            : this.committeeForm.controls['comentarios'].value,
      adelanto_comite               : this.committeeForm.controls['adelanto_'].value,

      contrato_nuevo_comite         : !this.operacion.contrato ? true: this.committeeForm.controls['contrato_nuevo_comite'].value, // SI NO HAY CONTRADO ACTIVAR SWITCH COMO NUEVO CONTRATO
      condiciones_comite            : this.committeeForm.controls['condiciones_comite'].value,
      analista_riesgo_nombre        : this.propuestaContext['analista_riesgo_nombre'],
      tipo_fianza_comite            : this.committeeForm.controls["tipo_fianza_comite"].value,
      tipo_firma_comite             : this.committeeForm.controls["tipo_firma_comite"].value,
      comision_estructuracion_comite: this.committeeForm.controls['comision_estructuracion_comite'].value,
      abono_leasing_comite          : this.committeeForm.controls['abono_leasing'].value
          //falta moneda_leasing - monto_leasing
    };
    // this.committeeForm.get('contrato_nuevo_comite').disable()

    if (this.tieneDeudaVencida) {
      const res = await this.openModalExcepcionDeudaLeasing()
      if (!res) return
      res['excepcion_comite'] = true
      Object.assign(req, res)
    }

    this.propuestaService.actualizarPropuestaComite(this.operacionId, req)
      .then(r => {
        this.toast.success('La operación fue completada con éxito!');
        this.getPropuestaComite();
        this.formSend.emit(true);
        this.updatePropuesta()
      })
      .catch(error => {
        this.formSend.emit(false);
        if(error.error.linea_propuesta_comite){
          if(error.error.linea_propuesta_comite.length > 0){
            this.toast.warning(error.error.linea_propuesta_comite[0]);
          }
        }
      });

  }

  removeMember(member) {
    console.log(member);
  }

  diffEmpty(obj) {
    for (const item in obj) {
      if (obj[item] != '') {
        return true
      }
    }
    return false
  }

  updatePropuesta() {
    let propuesta = this.propuestaService.obtenerPropuestaComite(this.operacionId);
    propuesta.then((res) => {
      let dataPropuesta = res;
      this.propuestaContext = res;
      this.restricciones.propuestaComite = dataPropuesta
      this.restricciones.listaAprobaciones = dataPropuesta['comite_aprobaciones']

      this.members = dataPropuesta['comite_aprobaciones']

      this.committeeForm.reset({
        tipo_linea_comercial          : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
        moneda_linea                  : dataPropuesta['moneda_comite'],
        monto_linea                   : dataPropuesta['linea_propuesta_comite'],
        moneda_comite                 : dataPropuesta['moneda'],
        vigencia                      : dataPropuesta['vigencia_comite'],
        vigencia_tipo                 : dataPropuesta['tipo_vigencia_comite'],
        tea_soles                     : dataPropuesta['tea_soles_comite'],
        tem_soles                     : dataPropuesta['tem_soles_comite'],
        tea_dolares                   : dataPropuesta['tea_dolares_comite'],
        tem_dolares                   : dataPropuesta['tem_dolares_comite'],
        tasa_mora                     : dataPropuesta['tasa_mora_comite'],
        anticipo                      : dataPropuesta['anticipo_comite'],
        plazo                         : dataPropuesta['plazo_comite'],
        plazo_tipos                   : dataPropuesta['tipo_plazo_comite'],
        comentarios                   : dataPropuesta['comentarios_comite'],
        adelanto_                     : dataPropuesta['adelanto_comite'],
        moneda_abono_leasing          : dataPropuesta['moneda_comite'],
        abono_leasing                 : dataPropuesta['abono_leasing_comite'],
        contrato_nuevo_comite         : dataPropuesta['contrato_nuevo_comite'],
        ofical_negocio                : dataPropuesta['analista_riesgo_nombre'],
        tipo_fianza_comite            : dataPropuesta['tipo_fianza_comite'],
        tipo_firma_comite             : dataPropuesta['tipo_firma_comite'],
        comision_estructuracion_comite: dataPropuesta['comision_estructuracion_comite'],
        condiciones_comite            : dataPropuesta['condiciones_comite']
      });
      this.changeForm.emit(this.committeeForm.value);
    })
  }

  evalFormulario() {
    let elFormularioEsNulo: boolean = true;

    this.committeeForm.controls['vigencia_tipo'].setValue(2)
    this.committeeForm.controls['vigencia'].setValue(12)

    for (let prop of Object.keys(this.committeeForm.controls)) {
      elFormularioEsNulo = this.committeeForm.get(prop).value ? false : true
    }

    if (elFormularioEsNulo) {
      this.guardarIguales.emit(elFormularioEsNulo)
    }
  }

  obtenerContrato(operacionId) {
    this.propuestaService.obtenerContrato(operacionId)
      .then(res => {
        this.contrato = (res['contrato']) ? res['contrato'] : null;
      })
      .catch(error => {
        console.error(error);
      });
  }

  parseDate(date) {
    if (!date)
      return;

    const arrayDate = date.split('-');
    return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;

  }

  changeMonedaAdelanto(moneda) {
    this.committeeForm.controls['moneda_linea'].setValue(moneda.id);
  }

  get showOpenText() {
    return (this.committeeForm.controls['comentarios'].value && this.committeeForm.controls['comentarios'].value.length > 100)
  }

  fechaAprobacion() {
    let fecha = this.propuestaContext['fecha_aprobacion_comite'].split("T")[0]

    return `${fecha.split("-")[2]}/${fecha.split("-")[1]}/${fecha.split("-")[0]}`
  }

  openTextModal() {

    if(!this.readOnlyCommittee) return;
    
    const modalRef = this.modalService.open(OpenTextModalComponent, {
    });

    modalRef.componentInstance.title = 'Comentarios Resolución Comité';
    modalRef.componentInstance.message = this.committeeForm.get('comentarios').value;

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });
  }

  mostrarDesglose(){
    let { detalle } = this.operacion
    const modalRef = this.modalService.open(DesglosesComponent, {
      size: "lg",
    });

    modalRef.componentInstance.desgloses = this.desglosesLineaPropuesta
    modalRef.componentInstance.operacion     = this.operacion.id;
    modalRef.componentInstance.operacionBody = this.operacion;
    modalRef.componentInstance.propuesta     = 3;
    modalRef.componentInstance.readonly     = this.readOnlyCommittee;
    modalRef.componentInstance.readonly_     = true;
    // modalRef.componentInstance.readonly     = false;
    modalRef.componentInstance.deleteEvent.subscribe((val)=>{
      this.obtenerDesgloses()
    })

    modalRef.result.then((result) => {
      if (result) {

      }
    });

  }

  obtenerDesgloses(){
    this.modalService.dismissAll()
    let desgloses = this.serviciosComunes.getDesglose(this.operacionId,3)
    desgloses.then((res: any)=>{
      this.desglosesLineaPropuesta = res['desgloses']
      this.mostrarDesglose()
    })
  }

  setDesglose(_desgloses){
    let desgloses = JSON.parse(_desgloses)
    let desgloseTemp = this.desglosesLineaPropuesta

    let addDesg: any[] = []
    let filtrados = desgloseTemp.map((desg,index,arr) => {
      let find = desgloses.find((item) => item.aceptante.id == desg.aceptante.id)
      if(find){
        for (let prop in find){
          if(!(prop == 'id' || prop =='propuesta')){
            desg[prop] = find[prop]
          }
        }
      }
      
      return desg
    })

    let noIncluidos = desgloses.filter((desg)=>{
      let find = desgloseTemp.find((item) => item.aceptante.id == desg.aceptante.id)
      if(!find){
        return true
      } else {
        return false
      }
    })

    let concatenar = noIncluidos.map((item)=>{
      item.propuesta = 2
      delete item.id
      return item
    })
    this.desglosesLineaPropuesta = desgloseTemp.concat(concatenar)
  }

  openModalTasasCobrar() {
    console.log("OP", this.operacion)
    const modalRef = this.modalService.open(TasasHistoricasComponent, {
      size: 'xl'
    })
    modalRef.componentInstance.clienteID = this.operacion.operacion.beneficiario_factoring || this.operacion.beneficiario
    modalRef.componentInstance.tipoLinea = this.operacion.operacion.tipo_linea_solicitada
  }

  openModalExcepcionDeudaLeasing() {
		const modal = this.modalService.open(ExcepcionDeudaLeasingComponent, {
		  size: "md",
		})
		modal.componentInstance.value = {
      'excepcion_sustento': this.propuestaContext['excepcion_sustento'],
			'excepcion_usuario': this.propuestaContext['excepcion_usuario'],
			'excepcion_fecha': this.propuestaContext['excepcion_fecha'],
      'excepcion_tipo': this.propuestaContext['excepcion_tipo'],
    }
		return modal.result.then(
		  (result) => result,
		  (reason) => null,
		)
	}

}

function validarMontoLinea(control: AbstractControl): {[key: string]: any} | null  {
  if (control.value && control.value <= 0) {
    return { 'montoLineaInvalido': true };
  }
  return null;
}