import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { VerificationsService } from 'app/core/services/factoring/verifications.service';

@Component({
  selector: 'app-eliminar-operacion-modal',
  templateUrl: './eliminar-operacion-modal.component.html',
  styleUrls: ['./eliminar-operacion-modal.component.css']
})
export class EliminarOperacionModalComponent implements OnInit {
  faTimes = faTimes;
  myForm                  : FormGroup;
  @Output() successEmit   : EventEmitter<any> = new EventEmitter<any>();
  motivos                 : any[] = [];
  idOperacion: any;
  constructor(
    public activeModal : NgbActiveModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private verificacionService: VerificationsService,
  ) { 
    this.initForm();
  }

  ngOnInit() {
  }

  initForm() {

    this.verificacionService.obtenerMotivos().then((res:any)=>{
      this.motivos = res.results
    })


    this.myForm = this.formBuilder.group({
      motivo      : [null, [Validators.required]],
      comentario  : [null, [Validators.required]]
    });
  }

  onSubmit() {
    this.verificacionService.cancelaOperacion(this.idOperacion , this.myForm.value).then((res:any)=>{
      this.successEmit.emit(true)
    })
  }

}
