import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { AppService } from 'app/app.service';
import { ToastService } from '../toast/toast.service';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
  private apiUrl: string = ""

  constructor(
    private appService: AppService,
    private http: HttpClient,
    public toast: ToastService,
  ) {
    this.apiUrl = `${this.appService.settings.API_base_url}factoring/`
  }

  guardarNotificacion(notificacion) {
    const url = `${this.apiUrl}notificaciones/`
    let method$ = null
    Object.keys(notificacion).filter(field => field.includes('fecha')).forEach(field => notificacion[field] = new Date(notificacion[field].setHours(0, 0, 0, 0)).toISOString().split('T')[0])
    if (notificacion.id) {
      method$ = this.http.patch(`${url}${notificacion.id}/`, notificacion)
    } else {
      method$ = this.http.post(url, notificacion)
    }
    return method$.pipe(
      catchError(res => this.handleError(res))
    )
  }

  reenviar(notificacion) {
    const url = `${this.apiUrl}notificaciones/${notificacion.id}/reenviar/`
    return this.http.post(url, {}).pipe(
      catchError(res => this.handleError(res))
    )
  }

  getNotificaciones(queryParams: object = {}) {
    const url = `${this.apiUrl}notificaciones/?${this.encodeQueryParams(queryParams)}`
    return this.http.get(url).pipe(
      catchError(res => this.handleError(res))
    )
  }

  getNotificacion(id) {
    const url = `${this.apiUrl}notificaciones/${id}/`
    return this.http.get(url).pipe(
      map((data: any) => {
        Object.keys(data).filter(x => x.includes('fecha')).forEach(key => {
          if (data[key]) {
            let fecha = data[key]
            if (!fecha.includes(':')) fecha += 'T00:00'
            data[key] = new Date(fecha)
          }
        })
        return data
      })
    )
  }

  getTiposNotificacion(queryParams: object = { 'page_size': 100 }) {
    const url = `${this.apiUrl}notificaciones/tipos/`
    return this.getDataApi(url, queryParams)
  }

  getEstadosNotificacion(queryParams: object = { 'page_size': 100 }) {
    const url = `${this.apiUrl}notificaciones/estados/`
    return this.getDataApi(url, queryParams)
  }

  getTiposEvento(queryParams: object = { 'page_size': 100 }) {
    const url = `${this.apiUrl}notificaciones/eventos/`
    return this.getDataApi(url, queryParams)
  }

  private getDataApi(url, queryParams) {
    url = `${url}?${this.encodeQueryParams(queryParams)}`
    return this.http.get(url).pipe(
      map((res: any) => res.results)
    ).pipe(
      catchError(res => this.handleError(res))
    )
  }

  private handleError(response) {
    let error = 'Ocurrió un error inesperado'
    if (response.error && typeof (response.error) == 'object') {
      error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
    }
    this.toast.warning(error)
    return throwError(response)
  }

  private encodeQueryParams(queryParams) {
    return Object.entries(queryParams).map((e: any) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&')
  }
}
