import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CuentasCobrarRoutingModule } from './cuentas-cobrar-routing.module';
import { CuentasCobrarComponent } from './pages/cuentas-cobrar/cuentas-cobrar.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { ArchwizardModule } from "angular-archwizard";
import { SharedModule } from "app/shared/shared.module";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { UiSwitchModule } from "ngx-ui-switch";

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [CuentasCobrarComponent],
  imports: [
    CommonModule,
    CuentasCobrarRoutingModule,
    FontAwesomeModule,
    SharedModule,
    JwBootstrapSwitchNg2Module,
    NgbModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    ArchwizardModule,
  ],
})
export class CuentasCobrarModule { }
