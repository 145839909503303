import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { HttpModule } from "@angular/http";
import { APP_BASE_HREF } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { HttpClientModule } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { AppComponent } from "./app.component";

import { SidebarModule } from "./sidebar/sidebar.module";
import { FixedPluginModule } from "./shared/fixedplugin/fixedplugin.module";
import { FooterModule } from "./shared/footer/footer.module";
import { NavbarModule } from "./shared/navbar/navbar.module";

import { AuthModule } from "./modules/auth/auth.module";
import { AplicacionEvaluacionRiesgosModule } from "./modules/aplicacion-evaluacion-riesgos/aplicacion-evaluacion-riesgos.module";
import { DashboardModule } from "./modules/dashboard/dashboard.module";

import { AdminLayoutComponent } from "./layouts/admin/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth/auth-layout.component";
import { AppRoutes } from "./app.routing";

import { AuthInterceptor } from "./core/interceptors/auth.interceptor";
import { NgxSpinnerModule } from "ngx-spinner";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { SharedModule } from "./shared/shared.module";
import { ConfigModule } from "./modules/config/config.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { AppService } from "./app.service";
import { CommitteesModule } from "./modules/committees/committees.module";
import { ClientesModule } from "./modules/clientes/clientes.module";
import { AceptantesModule } from "./modules/aceptantes/aceptantes.module";
import { RecaudacionModule } from "./modules/recaudacion/recaudacion.module";
import { ProrrogasModule } from "./modules/prorrogas/prorrogas.module";
import { DocumentosCarteraModule } from "./modules/documentos-cartera/documentos-cartera.module";
import { CuentasCobrarModule } from "./modules/cuentas-cobrar/cuentas-cobrar.module";
import { CuentasXCobrarModule } from "./modules/cuentas-x-cobrar/cuentas-x-cobrar.module";
import { JudicialesModule } from "./modules/judiciales/judiciales.module";
import { ExcedentesModule } from "./modules/excedentes/excedentes.module";
import { RiesgosModule } from "./modules/riesgos/riesgos.module";
import { ReportesComponent } from './modules/reportes/pages/reportes/reportes.component';
import { ReportesModule } from "./modules/reportes/reportes/reportes.module";
import { ReportesPagosComponent } from './modules/reportes/pages/reportes-pagos/reportes-pagos.component';
import { ReportesPagos2Component } from './modules/reportes/pages/reportes-pagos2/reportes-pagos2.component';
import { ReporteliquidacionComponent } from './modules/reportes/pages/reporteliquidacion/reporteliquidacion.component';
import { IConfig, NgxMaskModule } from "ngx-mask";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { DocumentsModule } from "./modules/documents/documents.module";
import { ConfigurationModule } from "./modules/configuration/configuration.module";
import { StringDateHourPipe } from './core/pipes/string-date-hour.pipe';
import { AdjuntarFileComponent } from "./shared/adjuntar-file/adjuntar-file.component";
import { FacturacionModule } from "./modules/facturacion/facturacion.module";
import { ReportelineacreditoComponent } from './modules/reportes/pages/reportelineacredito/reportelineacredito.component';
import { FormatosModule } from "./modules/formatos/formatos.module";
import { ReporteCxcComponent } from './modules/reportes/pages/reporte-cxc/reporte-cxc.component';
import { ReporteAplicacionesExcedentesComponent } from './modules/reportes/pages/reporte-aplicaciones-excedentes/reporte-aplicaciones-excedentes.component';
import { ReporteverificacionComponent } from './modules/reportes/pages/reporteverificacion/reporteverificacion.component';
import { ReporteverificacionpaginaComponent } from './modules/reportes/pages/reporteverificacionpagina/reporteverificacionpagina.component';
import { ReporteExcedentesComponent } from './modules/reportes/pages/reporte-excedentes/reporte-excedentes.component';
import { ReporteExcedentesPageComponent } from './modules/reportes/pages/reporte-excedentes-page/reporte-excedentes-page.component';
import { ReporteCambioLineaXclienteComponent } from './modules/reportes/pages/reporte-cambio-linea-xcliente/reporte-cambio-linea-xcliente.component';
import { ReporteExedentesDevueltosComponent } from "./modules/reportes/pages/reporte-exedentes-devueltos/reporte-exedentes-devueltos.component";
import { QuillModule } from 'ngx-quill';
import { ReporteCarteraComponent } from './modules/reportes/pages/reporte-cartera/reporte-cartera.component';
import { ReporteCuadroConsolidadoComponent } from './modules/reportes/pages/reporte-cuadro-consolidado/reporte-cuadro-consolidado.component';
import { AbonosModule } from './modules/abonos/abonos.module'
import { LavadoActivosModule } from "./modules/lavado-activos/lavado-activos-module";
import { ModalsReporteComponent } from './modules/lavado-activos/pages/reportes/modals-reporte/modals-reporte.component';
import { ConfiguracionGeneralComponent } from './modules/lavado-activos/configuracion-general/configuracion-general.component';
import { ModalConfiguracionComponent } from './modules/lavado-activos/configuracion-general/modal-configuracion/modal-configuracion.component';
import { MatricesRiesgoComponent } from './modules/lavado-activos/pages/matrices-riesgo/matrices-riesgo.component';
import { ModalMatricesRiesgoComponent } from './modules/lavado-activos/pages/matrices-riesgo/modals/modal-matrices-riesgo/modal-matrices-riesgo.component';
import { ModalElementosMatrizRiesgoComponent } from './modules/lavado-activos/pages/matrices-riesgo/modals/modal-elementos-matriz-riesgo/modal-elementos-matriz-riesgo.component';
import { ZonasRiesgoComponent } from './modules/lavado-activos/pages/zonas-riesgo/zonas-riesgo.component';
import { ModalZonaRiesgoComponent } from './modules/lavado-activos/pages/zonas-riesgo/modals/modal-zona-riesgo/modal-zona-riesgo.component';
import { ModalImportarComponent } from './modules/lavado-activos/pages/alertas/modals/modal-importar/modal-importar.component';
import { NotificacionesModule } from "./modules/notificaciones/notificaciones.module";

import { TotalReportComponent } from "./modules/reportes/pages/reporte-total-report/total-report.component";
import { CavaliModule } from "./modules/cavali/cavali.module";
import { ReporteCreditosFactoringComponent } from "./modules/reportes/pages/reporte-creditos-factoring/reporte-creditos-factoring.component";

// import { CommonModule } from '@angular/common';

export function init_app(appService: AppService) {
  return () => appService.load();
}
const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  imports: [
    QuillModule.forRoot(),
    FontAwesomeModule,
    SharedModule,
    NgxSpinnerModule,
    AuthModule,
    AplicacionEvaluacionRiesgosModule,
    DashboardModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskModule.forRoot(maskConfig),
    BsDatepickerModule.forRoot(),
    NgbModule,
    HttpModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedPluginModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true,
    }),
    NgSelectModule,
    NgxSkeletonLoaderModule,
    ConfigModule,
    CommitteesModule,
    ClientesModule,
    AceptantesModule,
    RecaudacionModule,
    ProrrogasModule,
    DocumentosCarteraModule,
    CuentasCobrarModule,
    CuentasXCobrarModule,
    JudicialesModule,
    ExcedentesModule,
    ReportesModule,
    DocumentsModule,
    ConfigurationModule,
    FacturacionModule,
    FormatosModule,
    LavadoActivosModule,
    AbonosModule,
    NotificacionesModule,
    CavaliModule,
    //TotalReporteModule,
    // RiesgosModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    TotalReportComponent,
    ReportesPagosComponent,
    ReportesPagos2Component,
    ReporteliquidacionComponent,
    ReportelineacreditoComponent,
    ReporteCxcComponent,
    ReporteAplicacionesExcedentesComponent,
    ReporteverificacionComponent,
    ReporteverificacionpaginaComponent,
    ReporteExcedentesComponent,
    ReporteExcedentesPageComponent,
    ReporteCambioLineaXclienteComponent,
    ReporteExedentesDevueltosComponent,
    ReporteCarteraComponent,
    ReporteCuadroConsolidadoComponent,
    ReporteCreditosFactoringComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppService],
      multi: true,
    },
    AdjuntarFileComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
