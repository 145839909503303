export class Contrato{
    public fecha_contrato   : string;
    public fecha_vencimiento: string;
    public vencimiento_indefinido : boolean;
    public id               : number;
    public linea_credito    : number;
    public numero_contrato  : string;
    public observaciones    : string;
    public vigente          : boolean;
    public verificado          : boolean;    

    constructor(contrato){

        this.fecha_contrato         = contrato.fecha_contrato;
        this.fecha_vencimiento      = contrato.fecha_vencimiento;
        this.id                     = contrato.id;
        this.linea_credito          = contrato.linea_credito;
        this.numero_contrato        = contrato.numero_contrato;
        this.observaciones          = contrato.observaciones;
        this.vigente                = contrato.vigente;
        this.verificado             = contrato.verificado;
        this.vencimiento_indefinido = contrato.vencimiento_indefinido;

    }

    get fecha_contratoDate() : Date{

        return this.parseDate(this.fecha_contrato)

    }

    get fecha_vencimientoDate() : Date{
        return this.parseDate(this.fecha_vencimiento)
    }

    public parseDate(fecha){
        let now = new Date();
        let yy = fecha.split('-')[0];
        let mm = Number(fecha.split('-')[1]) - 1;
        let dd = fecha.split('-')[2];

        let date = new Date(yy,mm,dd)
        return date
    }
}