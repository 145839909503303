import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
  selector: 'app-cuentas-x-cobrar-modal',
  templateUrl: './cuentas-x-cobrar-modal.component.html',
  styleUrls: ['./cuentas-x-cobrar-modal.component.css']
})
export class CuentasXCobrarModalComponent implements OnInit {
  faTimes = faTimes
  public headerDocumentsCarteraTable: columnHeader[];
  public documentsCarteraList: any[] = [];
  public documentsCarteraSelectionList: any[] = [];
  @Input() beneficiarioId: any;
  @Input() docsCuentasCobrar : any[];

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public activeModal          : NgbActiveModal,
    private recaudacionService  : RecaudacionService,
  ) { }

  ngOnInit(): void {

    this.initTables();

    this.loadCuentas();

  }

  loadCuentas() {
    let payload = {beneficiario : this.beneficiarioId}; 

    this.recaudacionService.obtenerCuentasPorCobrar(payload, '1')
    .then((res : any) => {
      console.log(res);
      const { results, count, num_pages } = res;
      let filteredList = results;
      
      if (this.docsCuentasCobrar.length > 0) {
        this.docsCuentasCobrar.forEach((doc) => {
			  const documento = doc;
			
        filteredList = filteredList.filter((doc: any) => {
          return doc.id !== documento.id;
        });

        this.documentsCarteraList = filteredList;
        });
      } else {
        this.documentsCarteraList = results;
      }

      // this.documentsCarteraList = res['results'];
    })
    .catch(error => {
      console.error(error);
    });
  }

  initTables() {
    let headerDocumentsCarteraTable = [
      {
        headerName: "N°",
        field: "id",
        sortable: true,
        pipe: 'indexcol'
      },
      {
        class: "text-center",
        headerName: "Producto",
        field: "tipo_producto_descripcion",
        sortable: true,
        filterable: true,
        filterProp: "tipo_linea",
        filterSelectItems: [
          // Obtener desde backend, por servicio de lineas, no existe servicio
          // Crear método para obtener, pero lleva a BOILERPLATE CODE, se deja en hardcode
          {id: 1, descripcion: 'Descuento'},
          {id: 2, descripcion: 'Factoring'},
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "beneficiario_nombre",
        sortable: true,
        filterable: true,
        filterProp: "beneficiario_ruc_nombre__icontains",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Concepto",
        field: "concepto_descripcion",
      },
      {
        headerName: "Número",
        field: "id",
        sortable: true,
      },
      {
        headerName: "Operación",
        field: "operacion_detalle_operacion",
        class: "text-center",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "IGV",
        field: "igv",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Total",
        field: "total",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Saldo",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      // {
      //   class: "text-center",
      //   headerName: "Fecha Liquidacion",
      //   field: "liquidacion_fecha",
      //   pipe: "date",
      //   sortable: true,
      // },
    ];

    this.headerDocumentsCarteraTable = headerDocumentsCarteraTable;
  }

  goToPageDocumentsCartera(e) {

  }

  selectedItem(row) {
    const id = row.id;

    const findRow = this.documentsCarteraSelectionList.find(item => item.id == id);

    console.log(row, findRow);

    if (findRow) {
      this.documentsCarteraSelectionList = this.documentsCarteraSelectionList.filter(
        (item) => item.id !== id
      );
    } else {
      this.documentsCarteraSelectionList.push(row);
    }

    // if (this.documentsCarteraSelectionList.indexOf(id) === -1)
    //   this.documentsCarteraSelectionList.push(row);
    // else
    //   this.documentsCarteraSelectionList = this.documentsCarteraSelectionList.filter(
    //     (item) => item !== id
    //   );
  }

  sendCuentasList() {
    this.successEmit.emit(this.documentsCarteraSelectionList);
    this.activeModal.close();
  }

}
