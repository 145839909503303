import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faMoneyCheckAlt, faWallet } from '@fortawesome/free-solid-svg-icons';
import { NgbCalendar, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { MonedaPipe } from 'app/core/pipes/moneda.pipe';
import Chart from 'chart.js';

@Component({
  selector: 'app-productividad',
  templateUrl: './productividad.component.html',
  styleUrls: ['./productividad.component.css'],
  providers: [MonedaPipe]
})
export class ProductividadComponent implements OnInit {
  faMoneyCheckAlt = faMoneyCheckAlt;
  faWallet = faWallet;
  public chartColor;
  public canvas: any;
  public ctx;
  public gradientStroke;
  public gradientFill;
  moneda: boolean = true;
  _indicador;
  _indicadorNombre;
  itemsProductividad: any[];
  @Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();
  @Input() title;
  @Input() indicadorTitle;
  @Input() indicadorId;
  @Input() set indicador(value) {
    if (value) {
      this._indicador = value
      this.itemsProductividad = value.results
      setTimeout(() => {
        this.cargarIndicadorVolumen(this.moneda);
      }, 500);

    }
  }
  @Input() show = true;
  chart: any = null;

  model: NgbDateStruct;
  date: { year: number, month: number };
  bsValue = new Date();

  showProductividad = true;

  constructor(
    private calendar: NgbCalendar,
    public monedapipe: MonedaPipe,
  ) { }

  ngOnInit(): void {

  }

  capitalize(str) {
    let StrLower = str.toLowerCase()
    return StrLower.charAt(0).toUpperCase() + StrLower.slice(1)

  }

  nameMapper(item) {
    let nameSplitted = item.oficial.split(' ').map((item) => {
      return this.capitalize(item)
    })
    let splitLength = nameSplitted.length
    let numPair = this.esNumeroPar(splitLength);

    let name: any = '';
    let secondName: any = '';
    if (numPair) {
      name = `${nameSplitted[0]} ${nameSplitted[1]}`
      secondName = `${nameSplitted[2]} ${nameSplitted[3]}`
    }

    else if (splitLength == 3) {
      name = `${nameSplitted[0]}`
      secondName = `${nameSplitted[1]} ${nameSplitted[2]}`
    }

    else if (splitLength == 2) {
      name = `${nameSplitted[0]}`
      secondName = `${nameSplitted[1]}`
    }

    else if (splitLength == 5) {
      name = `${nameSplitted[0]} ${nameSplitted[1]}`
      secondName = `${nameSplitted[2]} ${nameSplitted[3]} ${nameSplitted[4]}`
    }

    else {
      name = `${nameSplitted[0]} ${nameSplitted[1]}`
      secondName = `${nameSplitted[2]} ${nameSplitted[3]}`
    }
    return [name, secondName]
  }

  esNumeroPar(num) {
    return num % 2 === 0
  }

  retornaTick(value) {

    if (this.moneda) {
      return '$' + value;
    } else {
      return 'S/ ' + value;
    }
  }


  cargarIndicadorVolumen(moneda = this.moneda) {

    this.chartColor = "#FFFFFF";
    this.canvas = document.getElementById('graficoProductividad');
    this.ctx = this.canvas?.getContext("2d");

    const footer = (tooltipItems) => {
      console.log(tooltipItems)
      return 'exito'
    },
      { monedapipe } = this

    const data = {
      labels: this.itemsProductividad.map(item => this.nameMapper(item)),
      datasets: [
        {
          label: "Ingreso",
          borderColor: "#0F52BA",
          backgroundColor: "#0F52BA",
          pointRadius: 0,
          pointHoverRadius: 0,
          fill: true,
          borderWidth: 1,
          data: this.itemsProductividad.map(item => moneda ? item.ingresados.monto_soles_ingresos : item.ingresados.monto_dolares_ingresos)
        },
        {
          label: "Aprobado",
          borderColor: "#DF711B",
          backgroundColor: "#DF711B",
          pointRadius: 0,
          pointHoverRadius: 0,
          fill: true,
          borderWidth: 1,
          data: this.itemsProductividad.map(item => moneda ? item.aprobados.monto_soles_aprobados : item.aprobados.monto_dolares_aprobados)
        },
        {
          label: "Desembolso",
          borderColor: "#787A91",
          backgroundColor: "#787A91",
          pointRadius: 0,
          pointHoverRadius: 0,
          fill: true,
          borderWidth: 1,
          data: this.itemsProductividad.map(item => moneda ? item.desembolsados.monto_soles_desembolsado : item.desembolsados.monto_dolares_desembolsado)
        },
      ]
    };

    const config = {
      type: 'horizontalBar',
      data: data,
      options: {
        maintainAspectRatio: false,
        responsive: true,
        indexAxis: 'y',
        // Elements options apply to all of the options unless overridden in a dataset
        // In this case, we are setting the border of each `horizontal` bar to be 2px wide
        elements: {
          bar: {
            borderWidth: 2,
          }
        },
        tooltips: {
          enabled: true,
          mode: 'label',
          callbacks: {
            label: function (tooltipItems, data) {
              let valor = data.datasets[0].data[tooltipItems.index]
              return monedapipe.transform(valor, moneda ? 1 : 2);
            },
          }
        },
        scales: {
          yAxes: [{
            ticks: {
              fontSize: 15,
              // callback: function(value, index, values) {
              //   console.log(value);
              //     return value;
              // },
              color: 'red',
            }
          }],
          xAxes: [{
            ticks: {
              fontSize: 15,
              // callback: function (value, index, values) {
              //   if (moneda) {
              //     return 'S/ ' + value;
              //   } else {
              //     return '$ ' + value;
              //   }
              // },
              callback(value, index, values) {
                return monedapipe.transform(value, moneda ? 1 : 2);
              }
            }
          }],
        },
        onClick: event => {
          let dat = this.chart.getElementAtEvent(event)[0]
          if (dat) {
            const datasetIndex = dat._datasetIndex;
            const model = this.chart.getElementsAtEvent(event)[datasetIndex]._model;
            // this.onBarClicked(model.label);
          }
        }
      },
    };
    // var myChart = new Chart(this.ctx, config);
    if (this.chart != null) {
      let i = 0;
      this.chart.options.scales.xAxes = [{
        ticks: {
          fontSize: 15,
          callback(value, index, values) {
            return monedapipe.transform(value, moneda ? 1 : 2);
          }
        }
      }];
      this.chart.options.tooltips = {
        enabled: true,
        mode: 'label',
        callbacks: {
          label: function (tooltipItems, data) {
            // console.log(tooltipItems, data);
            let label = data.datasets[tooltipItems.datasetIndex].label;
            let value = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index];

            return `${label}: ${monedapipe.transform(value, moneda ? 1 : 2)}`;
            // return data.datasets[0].label + ' : ' + monedapipe.transform(valor, moneda ? 1 : 2);
          },
        }
      };
      this.chart.data = data;
      this.chart.update();
      i = 0;
    } else {
      this.chart = new Chart(this.ctx, config);
    }

  }

  actualizarDom(event) {
    var element = document.getElementById('graficoProductividad');

    // if (element) {
    //   let parent = element.parentNode
    //   parent.removeChild(element);
    //   var newCanva = document.createElement("canvas");
    //   newCanva.setAttribute("id", 'graficoProductividad');
    //   parent.appendChild(newCanva);
    // }

    // this.cargarIndicadorVolumen(event)
    this.cargarIndicadorVolumen(event)
  }

  changeDate(e: Date) {
    this.showProductividad = false;
    const date = `${e.getFullYear()}-${e.getMonth() + 1}-01`;
    this.updateEvent.emit(date);
    setTimeout(() => {
      this.showProductividad = true;
    }, 500);

  }

  get styles() {

    if (!this.itemsProductividad?.length) return { 'height': '100px' };

    const b = 100;
    return {
      'height': `${(this.itemsProductividad.length + 1) * b}px`
    }
  }
}
