import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from '../../layouts/admin/admin-layout.component';
import { ProrrogasComponent } from './pages/prorrogas/prorrogas.component';


const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Prorrogas'
    },
    children: [
      {
        path: 'prorrogas',
        component: ProrrogasComponent,
        data: {
          breadcrumb: 'Prorrogas'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProrrogasRoutingModule { }
