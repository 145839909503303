import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { of, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { AbonosService } from 'app/core/services/abonos/abonos.service';
import { IdentificarComponent } from '../../modals/identificar/identificar.component';
import { ReasignarComponent } from '../../modals/reasignar/reasignar.component';
import { AddRecaudacionModalComponent } from 'app/modules/recaudacion/modals/add-recaudacion-modal/add-recaudacion-modal.component';

@Component({
  selector: 'app-abono',
  templateUrl: './abono.component.html',
  styleUrls: ['./abono.component.css']
})
export class AbonoComponent implements OnInit {
  forma: FormGroup
  abono: any = {}
  aplicacion_register: any = {}
  cuentas = []
  monedas = []
  estados = []
  motivosAnulacion = []
  tiposTrazabilidad = []
  public tblRows: any[] = []
  public tblHeaders: columnHeader[]

  constructor(
    private abonosService: AbonosService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
  ) {
    this.forma = this.formBuilder.group({
      'cuenta': [{ value: null, disabled: true }, [Validators.required]],
      'banco': [{ value: null, disabled: true }, [Validators.required]],
      'moneda': [{ value: null, disabled: true }, [Validators.required]],
      'fecha': [{ value: new Date(), disabled: true }, [Validators.required]],
      'fecha_contable': [{ value: new Date(), disabled: true }, [Validators.required]],
      'fecha_carga': [{ value: new Date(), disabled: true }, [Validators.required]],
      'monto': [{ value: null, disabled: true }, [Validators.required]],
      'saldo': [{ value: null, disabled: true }, [Validators.required]],
      'estado': [{ value: null, disabled: true }, [Validators.required]],
      'pagador_ruc': [null, [Validators.required]],
      'pagador_nombre': [null, [Validators.required]],
      'fecha_anulacion': [{ value: null, disabled: true }, []],
      'fecha_anulacion_contable': [{ value: null, disabled: true }, []],
      'motivo_anulacion': [{ value: null, disabled: true }, []],
      'sustento_anulacion': [{ value: null, disabled: true }, []],
    })
    this.forma.get('cuenta').valueChanges.subscribe(value => {
      if (value) {
        let cuenta: any = this.cuentas.find((x: any) => x.id == value)
        if (cuenta) {
          this.forma.get('banco').setValue(cuenta.banco)
          this.forma.get('moneda').setValue(cuenta.moneda)
        }
      }
    })
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(({ id }) => this.abono = { id })

    this.spinner.show()
    forkJoin({
      cuentas: this.abonosService.getCuentasBancarias(),
      monedas: this.abonosService.getMonedas(),
      estados: this.abonosService.getEstados(),
      abono: this.loadAbono(this.abono.id),
      aplicacion_register: this.aplicacion_registrada(this.abono.id),
      tiposTrazabilidad: this.abonosService.getTiposTrazabilidad(),
      motivosAnulacion: this.abonosService.getMotivosAnulacion(),
    }).subscribe(({ cuentas, monedas, estados, abono, tiposTrazabilidad, motivosAnulacion }) => {
      this.cuentas = cuentas
      this.monedas = monedas
      this.estados = estados
      this.tiposTrazabilidad = tiposTrazabilidad
      this.motivosAnulacion = motivosAnulacion
      console.log("abono:", abono)
      console.log("aplicacion: ", this.aplicacion_register)
      this.initTablaTrazabilidad()
    }).add(() => this.spinner.hide())
  }

  onSubmit() {
    if (!this.forma.valid) return
    this.spinner.show()
    let abono = Object.assign({}, this.forma.value, { id: this.abono.id })
    this.abonosService.guardarAbono(abono).subscribe(
      (data: any) => {
        this.abono = data
        this.loadDataTablaTrazabilidad({})
      },
      res => console.log(res)
    ).add(() => this.spinner.hide())
  }

  loadDataTablaTrazabilidad({ filtros = {} }) {
    this.tblRows = []
    Object.keys(filtros).filter(field => field.includes('fecha')).forEach(field => filtros[field] = filtros[field].split(' ')[0])
    this.abonosService.getTrazabilidad(Object.assign({ page_size: 9999, abono: this.abono.id }, filtros)).subscribe(
      (data: any) => this.tblRows = data,
      (res: any) => console.log(res)
    )
    console.log('trazabilidad: ', this.abono)
  }

  openModalIdentificar() {
    const modal = this.modalService.open(IdentificarComponent, {
      size: "md",
    })
    modal.componentInstance.abono = this.abono
    modal.result.then(
      (result) => {
        if (result) {
          this.spinner.show()
          this.loadAbono(this.abono.id).subscribe().add(() => this.spinner.hide())
        }
      },
      (reason) => { },
    )
  }
  openModalReasignar() {
    const modal = this.modalService.open(ReasignarComponent, {
      size: "md",
    })
    modal.componentInstance.abono = this.abono
    modal.result.then(
      (result) => {
        if (result) {
          this.spinner.show()
          this.loadAbono(this.abono.id).subscribe().add(() => this.spinner.hide())
        }
      },
      (reason) => { },
    )
  }

  openModalAplicacion(abono) {
    if (!abono.aplicacion) return

    const modal = this.modalService.open(AddRecaudacionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    })

    modal.componentInstance.abono = Object.assign({}, abono)
    modal.componentInstance.recaudacionId = abono.aplicacion

    modal.result.then(result => { }).catch(reason => { }).then(() => {
      let recaudacion = modal.componentInstance.recaudacion
      if (recaudacion && recaudacion.id) {
        // pass
      }
    })
  }

  onActionableTblTrazabilidad(ev) {
    this.openModalAplicacion(ev)
  }

  private loadAbono(id) {
    if (!id) return of(false)
    return this.abonosService.getAbono(id).pipe(
      tap((data: any) => {
        this.abono = data
        this.forma.patchValue(this.abono)
        this.loadDataTablaTrazabilidad({})
      })
    )
  }
  private aplicacion_registrada(id) {
    if (!id) return of(false)
    return this.abonosService.getAplicacionRegister(id).pipe(
      tap((data: any) => {
        this.aplicacion_register = data
      })
    )
  }

  private initTablaTrazabilidad() {
    this.tblHeaders = [
      {
        headerName: 'ID',
        field: 'id',
        class: 'text-center',
        filterable: true,
        filterProp: 'id',
        filterInput: true,
      },
      {
        headerName: 'Tipo trazabilidad',
        field: 'tipo_descripcion',
        class: 'text-center',
        filterable: true,
        filterProp: 'tipo',
        filterSelectItems: this.tiposTrazabilidad,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Fecha',
        field: 'fecha',
        pipe: 'date',
        class: 'text-center',
        filterable: true,
        filterDate: true,
      },
      {
        headerName: 'Fecha contable',
        field: 'fecha_contable',
        pipe: 'date',
        class: 'text-center',
        filterable: true,
        filterDate: true,
      },
      {
        headerName: 'Pagador',
        field: 'pagador_txt',
        filterable: true,
        filterProp: 'pagador_txt',
        filterInput: true,
      },
      {
        headerName: 'Saldo',
        field: 'abono_saldo',
        class: 'text-right',
        filterable: true,
        filterProp: 'abono_saldo',
        filterRange: true
      },
      {
        headerName: 'T.C.',
        field: 'tipo_cambio_monto',
        class: 'text-right',
      },
      {
        headerName: 'Estado',
        field: 'abono_estado_descripcion',
        class: 'text-center',
        filterable: true,
        filterProp: 'abono_estado',
        filterSelectItems: this.estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Aplicación',
        field: 'aplicacion',
        class: 'text-center',
        textField: 'aplicacion',
        pipe: 'actionable',
        actionableType: 'aplicacion',
        filterable: true,
        filterProp: 'aplicacion',
        filterInput: true,
      },
      {
        headerName: 'Usuario',
        field: 'usuario_creacion',
      },
    ]

    this.loadDataTablaTrazabilidad({})
  }
}
