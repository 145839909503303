import { Component, OnInit, Input, SimpleChanges, OnChanges, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FactoringService } from '../../../../core/services/factoring/factoring.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { columnHeader } from '../../../../shared/factoring-datatable/factoring-datatable.component';
import { Router } from '@angular/router';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { ReassignAnalystModalComponent } from '../../modals/reassign-analyst-modal/reassign-analyst-modal.component';
// import { concat, Observable, of, Subject, throwError } from 'rxjs';
// import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map, filter } from 'rxjs/operators';
import { EstacionesOperacionModalComponent } from '../../modals/estaciones-operacion-modal/estaciones-operacion-modal.component';
import { faChartPie, faPlus, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-bandeja-denegados',
	templateUrl: './bandeja-denegados.component.html',
	styleUrls: ['./bandeja-denegados.component.css']
})
export class BandejaDenegadosComponent implements OnInit {
	public rowData: any[] = [];
	public loadingTable: boolean = false;
	public columnDefs: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
	public user: any;
	public identidad: any = {};
	public searchstring: string = '';
	public haveTipoCambio: boolean = true;
	reassing = false;
	faPlus= faPlus;
	faSyncAlt = faSyncAlt;
	faChartPie = faChartPie;
	faTimes = faTimes;
	@ViewChild('e_tipocambio', { static: false }) public e_tipocambio;

	// observable$      : Observable<any[]>;
	// subjectText$      = new Subject<string>();
	// fieldData              : any;
	// loadingSearch              : boolean = false;

	estaciones: any[] = [];

	constructor(
		private authService: AuthService,
		private router: Router,
		private factoringService: FactoringService,
		private docService: DocschecklistService,
		private modalService: NgbModal,

	) {
		this.user = this.authService.user;
	}

	ngOnInit(): void {
		let page = 1, per_page = 10;

		let operaciones;

		switch (this.user.perfil) {
			case this.authService.perfil.perfilAsistenteCobranzasID:
				operaciones = this.obtenerOperacionesFiltradas(page, per_page, '', '', '', 9,
					this.authService.perfil.perfilAsistenteCobranzasID.toString());
				break;

			case this.authService.perfil.perfilJefeDeOperacionesID:
				operaciones = this.obtenerOperacionesFiltradas(page, per_page, '', '', '', 9,
					this.authService.perfil.perfilJefeDeOperacionesID.toString());
				break;

			default:
				operaciones = this.obtenerOperacionesFiltradas(page, per_page);
				break;
		}

		let estados          = this.docService.obtenerEstados();
		let estaciones       = this.docService.obtenerEstaciones();
		let analistasRiesgos = this.factoringService.obtenerResponsables(6);
		let oficialesNegocio = this.factoringService.obtenerResponsables(3);
		let tiposDeCambio    = this.docService.obtenerTiposDeCambio(this.formatDate(new Date()));
		let tipoProductos    = this.factoringService.obtenerTiposDeLineas();
		let comites          = this.factoringService.obtenerComites();
		let empresas = this.factoringService.obtenerEmpresas()

		Promise.all([
			operaciones,
			estados,
			estaciones,
			analistasRiesgos,
			oficialesNegocio,
			tiposDeCambio,
			tipoProductos,
			comites,
			empresas
		]).then((res: any[]) => {

			let operaciones       = res[0];
			let estados           = res[1]['results'];
			let estaciones        = res[2]['results'];
			let analistasRiesgos  = res[3]['results'];
			let oficialesNegocios = res[4]['results'];
			let tiposDeCambio     = res[5]['results'][0]
			let tipoProductos     = res[6]['results']
			let comites           = res[7]["results"].sort((a, b) => a.nombre > b.nombre ? 1 : -1);
			let empresas          = res[8]['results'];
			if (!tiposDeCambio) {
				this.haveTipoCambio = false
			}

			let idOficiales = this.user.equipo.map((item)=> item.id)
			let filtrados = oficialesNegocios.filter((item)=>  { return idOficiales.includes(item.id) || item.id == this.user.id })
			this.initVars(tipoProductos, estados, estaciones, analistasRiesgos, filtrados, comites, empresas);

			this.loadTable(operaciones);
			this.estaciones = estaciones;
		});

		if (this.user.perfil == 5) {
			this.reassing = true;
		}
	}

	/*
		  const estadosFiltrados = this.rowData.filter( x=> x.estado ==9 );
		console.log(estadosFiltrados, "rowdataaaa!")
	*/

	/**
	 * Inicializa la tabla con datos y paginacion
	 */
	loadTable(data) {
		this.rowData = data.results
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}

	}

	initVars(
		tipoProductos = [],
		estados = [],
		estaciones = [],
		analistaRiesgos = [],
		oficialesNegocio = [],
		comites = [],
		empresas = []
	) {
		let columnas = [
			{
				headerName: 'N° Ope.',
				field: 'id',
				sortable: true,
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 18],
				filterable: true,
				filterProp: "operacion",
				filterInput: true,
			},
			{
				headerName: 'Tipo de Producto',
				field: 'tipo_linea_solicitada_descripcion',
				filterProp: 'tipo_linea_solicitada',
				sortable: true,
				class: 'text-center',
				filterable: true,
				filterSelectItems: tipoProductos,
				filterItemsProps: {
					value: 'id',
					label: 'descripcion'
				},
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 18],
			},
			{
				headerName: 'RUC',
				field: 'beneficiario_ruc',
				sortable: true,
				class: 'text-center',
				perfiles: [1, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 18]
			},
			{
				headerName: 'Cliente',
				field: 'beneficiario_nombre',
				sortable: true,
				filterable: true,
				filterProp: 'beneficiario',
				filterType: 'buscarBeneficiario',
				perfiles: [1, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 18]
			},
			{
				headerName: 'Oficial de Negocios',
				field: 'oficial_negocio_nombre',
				filterProp: 'oficial_negocio',
				sortable: true,
				filterable: true,
				filterSelectItems: oficialesNegocio,
				filterItemsProps: {
					value: 'id',
					label: 'nombre'
				},
				perfiles: [1, 2, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 18]
			},
			{
				headerName: 'Analista de Riesgos',
				field: 'analista_riesgo_nombre',
				filterProp: 'analista_riesgo',
				filterable: true,
				filterSelectItems: analistaRiesgos,
				filterItemsProps: {
					value: 'id',
					label: 'nombre'
				},
				sortable: true,
				perfiles: [5, 9, 8, 7, 18]
			},
			{
				headerName: 'Fecha asignado',
				field: 'fecha_analista_asignado',
				pipe: 'date',
				class: 'text-center',
				perfiles: []
			},
			{
				headerName: 'Monto',
				field: 'monto',
				pipe: 'currency',
				moneda: 'moneda',
				sortable: true,
				class: 'text-center',
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 18],
				filterable: true,
				filterRange: true,
			},
			{
				headerName: 'Anticipo',
				field: 'anticipo_comite',
				pipe: 'percent-icon',
				sortable: true,
				class: 'text-center',
				perfiles: [9, 4, 16, 10, 11, 17, 12, 18]
			},
			{
				headerName: 'Desembolso',
				field: 'monto_desembolso',
				pipe: 'currency',
				moneda: 'moneda',
				class: 'text-center',
				perfiles: [9, 4, 16, 10, 11, 17, 12, 18]
			},
			{
				headerName: 'Fecha',
				field: 'fecha',
				pipe: 'date',
				sortable: true,
				filterable: true,
				filterDate: true,
				class: 'text-center',
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 18]
			},
			{
				headerName: 'Estado',
				field: 'estado_descripcion',
				filterProp: 'estado',
				sortable: true,
				class: 'text-center',
				// filterable: true,
				// filterSelectItems: estados,
				// filterItemsProps: {
				// 	value: 'descripcion',
				// 	label: 'descripcion'
				// },
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 18]
			},
			{
				headerName: 'Workflow',
				field: 'perfil_descripcion',
				pipe: 'actionable',
				text: 'Ver',
				sortable: true,
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 18]
			},
			{
				headerName: 'Estación',
				field: 'estacion_nombre',
				sortable: true,
				filterProp: 'estacion',
				class: 'text-center',
				filterable: true,
				filterSelectItems: estaciones,
				filterItemsProps: {
					value: 'id',
					label: 'nombre'
				},
				perfiles: [1, 3, 4, 16, 9, 13, 14, 18]
			},
			{
				headerName: 'Estación',
				field: 'estacion_nombre',
				sortable: true,
				filterProp: 'estacion',
				class: 'text-center',
				perfiles: [6, 5, 7, 8, 10, 11, 17, 12]
			},
			{
				headerName: 'Estación',
				field: 'estacion_descripcion_cliente',
				sortable: true,
				filterProp: 'estacion',
				class: 'text-center',
				perfiles: [2]
			},
			{
				headerName: 'Responsable',
				field: 'responsable_nombre',
				sortable: true,
				perfiles: [3, 4, 16, 5, 6, 8, 7, 9, 18]
			},
			{
				headerName: 'Fecha estación',
				field: 'fecha_estacion',
				pipe: 'date',
				sortable: true,
				class: 'text-center',
				perfiles: [1, 3, 4, 16, 6, 8, 7, 9, 10, 11, 17, 12, 13, 18]
			},
			{
				headerName: "Comité",
				field: "comite_nombre",
				sortable: true,
				filterable: true,
				filterSelectItems: comites,
				filterProp: "comite",
				filterItemsProps: {
					value: "id",
					label: "nombre",
				},
				perfiles: [1, 3, 4, 16, 6, 8, 7, 9, 10, 11, 17, 12, 13, 18]
			},
		];


		this.columnDefs = columnas.reduce((acc, item) => {
			if (item.perfiles.includes(this.user.perfil)) {
				acc.push(item)
			}
			return acc
		}, [])
	}

	crear() {
		if (this.haveTipoCambio) {
			this.router.navigate(['/registrar-solicitud-factoring'])
		} else {
			this.errorNoHayTipoCambio()
		}
	}

	tableEditEvent(row: any) {
		if (row.estacion == 1) {
			this.router.navigate(['/registrar-solicitud-factoring', row.id])
		} else {
			this.router.navigate(['/denegados', row.id])
		}

	}

	goToPage({ page, per_page, filtros }) {
		let request;
		let dataResult = {
			count: 0,
			next: null,
			num_pages: 1,
			page_number: 1,
			per_page: 10,
			previous: null,
			results: []
		};
		const range = (filtros.valueFrom && filtros.valueTo) ? `${filtros.valueFrom},${filtros.valueTo}` : '';

		if (filtros.operacion > 0) {
			this.factoringService.obtenerOperacionBandeja(filtros.operacion)
				.then(res => {
					dataResult.results.push(res);
					this.loadTable(dataResult);
					return;
				}).catch(error => {
					console.error(error);
					this.loadTable(dataResult);
					return;
				});
			return;
		}

		request = this.obtenerOperacionesFiltradas(
			page,
			per_page,
			filtros.beneficiario ? filtros.beneficiario : '',  //Beneficiario
			filtros.oficial_negocio ? filtros.oficial_negocio : '',  //Oficial de negocio
			filtros.responsable ? filtros.responsable : '',  //Responsable
			9,  													//Estado
			filtros.estacion ? filtros.estacion : '',  //Estacion
			filtros.fecha__gte ? filtros.fecha__gte : '',  //Fecha desde
			filtros.fecha__lte ? filtros.fecha__lte : '',  //Fecha hasta
			filtros.analista_riesgo ? filtros.analista_riesgo : '',  //Fecha hasta
			filtros.comite ? filtros.comite : "", //Analista de riesgos
			range,
			filtros.tipo_linea_solicitada ? filtros.tipo_linea_solicitada : '',
			filtros.empresa
		)

		request.then((res) => {
			this.loadTable(res)
		})
	}

	obtenerOperacionesFiltradas(
		page                  = 1,
		per_page              = 10,
		beneficiario          = '',
		oficial_negocio       = '',
		responsable           = '',
		estado                = 9, // Solo operaciones denegadas
		estacion              = '',
		fecha__gte            = '',
		fecha__lte            = '',
		analista_riesgo       = '',
		comite                = '',
		monto__range          = '',
		tipo_linea_solicitada = '',
		empresa               =  '',
	) {
		switch (this.user.perfil) {
			/**
			 * Perfil cliente
			 */
			case this.authService.perfil.perfilClienteID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					this.user.cliente,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					responsable,	//Responsable
					estado,			//Estado
					estacion,       //Estacion Analisis de riesgo
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
			/**
			 * Perfil Oficial de Negocio
			 */
			case this.authService.perfil.perfilOficialDeNegocioID:
			case this.authService.perfil.perfilAsistenteComercialID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					this.user.id,	//Oficial de Negocio
					responsable,	//Responsable
					estado,			//Estado
					estacion,       //Estacion Analisis de riesgo
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;

			/**
			 * Perfil Lider de Equipo
			 */
			 case this.authService.perfil.perfilLiderDeEquipo:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,	//Oficial de Negocio
					responsable,	//Responsable
					estado,			//Estado
					estacion,       //Estacion Analisis de riesgo
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa,
					this.authService.user.id.toString()
				)
			break;
			/**
			 * Perfil Gerente Comercial
			 */
			case this.authService.perfil.perfilGerenciaComercialID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					responsable,	//Responsable
					estado,			//Estado
					estacion,       //Estacion Analisis de riesgo
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta,
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
			/**
			* Perfil Jefe de Admision
			*/
			case this.authService.perfil.perfilJefeDeAdmisionID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					responsable,	//Responsable
					estado,			//Estado
					3,       //Estacion Analisis de riesgo
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta,
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
			/**
			 * Perfil Analista de Riesgo
			 */
			case this.authService.perfil.perfilAnalistaRiesgosID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					this.user.id,	//Responsable
					estado,			//Estado
					3,       //Estacion Analisis de riesgo
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta,
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
			/**
			* Asesor Legal
			*/
			case this.authService.perfil.perfilAsesorLegalID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					this.user.id,	//Responsable
					estado,			//Estado
					7,       //Estacion Legal
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
			/**
			 * Perfil de Legal
			 */
			case this.authService.perfil.perfilLegalID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					this.user.id,	//Responsable
					estado,			//Estado
					6,       //Sub gerencia comercial
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
			/**
			 * Jefe de operaciones
			 */
			case this.authService.perfil.perfilJefeDeOperacionesID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					responsable,	//Responsable
					estado,			//Estado
					estacion,      	//Sub gerencia comercial
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
			/**
			* Asistente de cobranza
			*/
			case this.authService.perfil.perfilAsistenteCobranzasID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					responsable,	//Responsable
					estado,			//Estado
					estacion,      	//Sub gerencia comercial
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
			/**
			* Recepcionista
			*/
			case this.authService.perfil.perfilRecepcionID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					this.user.id,	//Responsable
					estado,			//Estado
					12,       		//Sub gerencia comercial
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
			/**
			* Tesoreria
			*/
			case this.authService.perfil.perfilTesoreriaID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					this.user.id,	//Responsable
					estado,			//Estado
					11,       		//Sub gerencia comercial
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
			/**
			* Lider de Tesoreria
			*/
			case this.authService.perfil.perfilLiderDeTesoreriaID:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					responsable,	//Responsable
					estado,			//Estado
					11,       		//Sub gerencia comercial
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
			/**
			 * Retorna todas las operaciones sin filtros
			 */
			default:
				return this.factoringService.obtenerBandeja(
					page,			//Pagina
					per_page,		//Por pagina
					beneficiario,	//Beneficiario
					oficial_negocio,//Oficial de Negocio
					responsable,	//Responsable
					estado,			//Estado
					estacion,       //Estacion Analisis de riesgo
					fecha__gte,		//Fecha desde
					fecha__lte,		//Fecha hasta
					comite,
					analista_riesgo,
					"",
					monto__range,
					"",
					tipo_linea_solicitada,
					empresa
				)
				break;
		}
	}

	reassignAnalyst(e) {
		console.log(e);
		const modalRef = this.modalService.open(ReassignAnalystModalComponent, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'lg'
		});
		modalRef.componentInstance.operacion = e;
		modalRef.result.then(res => {
			console.log(res);
			if (res) {
				let operaciones = this.obtenerOperacionesFiltradas(1, 10);
				Promise.all([operaciones]).then((res: any[]) => {
					let operaciones = res[0];
					this.loadTable(operaciones);
				});
			}
		});
	}


	errorNoHayTipoCambio() {
		this.modalService.open(this.e_tipocambio, {
			size: 'md'
		}).result.then((response: any) => {
			return this.docService.obtenerTiposDeCambio(this.formatDate(new Date()))
		}).then((res) => {
			let tiposDeCambio = res['results'][0]
			if (!tiposDeCambio) {
				this.haveTipoCambio = false
			} else {
				this.haveTipoCambio = true
			}
		})
	}

	formatDate(date) {
		return date.getFullYear() +
			"-" + (date.getMonth() + 1) +
			"-" + date.getDate();
	}

	refrescar() {
		this.obtenerOperacionesFiltradas().then((response: any) => {
			this.loadTable(response)
		})
	}

	openOpercion(row) {
		console.log(row);
		const modalRef = this.modalService.open(EstacionesOperacionModalComponent, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'xl'
		});
		modalRef.componentInstance.operacion = row;
		modalRef.componentInstance.estaciones = this.estaciones;
		modalRef.result.then(res => {
			console.log(res);

		});
	}

	get canDelete() {
		const perfilesPermitidos = [
			this.authService.perfil.perfilAdmin,
			this.authService.perfil.perfilOficialDeNegocioID,
			this.authService.perfil.perfilGerenciaComercialID,
			this.authService.perfil.perfilGerenciaGeneralID,
			this.authService.perfil.perfilAnalistaRiesgosID,
			this.authService.perfil.perfilJefeDeAdmisionID,
			this.authService.perfil.perfilAsesorLegalID,
			this.authService.perfil.perfilLegalID,
			this.authService.perfil.perfilJefeDeOperacionesID,
			this.authService.perfil.perfilAnalistaOperacionesID,
			this.authService.perfil.perfilSubGerenciaComercialID,
			this.authService.perfil.perfilTesoreriaID,
			this.authService.perfil.perfilLiderDeTesoreriaID,
			this.authService.perfil.perfilRecepcionID,
			this.authService.perfil.perfilAsistenteCobranzasID,
			this.authService.perfil.perfilAnalistaOperacionesID,
			this.authService.perfil.perfilAsistenteComercialID,
		]
	
		return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
	  }
}
