import { Injectable, Input } from '@angular/core';
import { AuthService } from './auth.service'
import { Estacion } from './Estacion';

@Injectable({
	providedIn: 'root'
})
export class RestriccionesService {
	public puedeVerificarDocumentosLegales: boolean = false;
	public puedeVerificarDocumentosFinancieros: boolean = false;
	public comiteAprobaciones: any[];
	public propuestaComite;


	constructor(public auth: AuthService) {
	}

	set listaAprobaciones(val) {
		this.comiteAprobaciones = val
	}

	get miembroPuedeAprobar() {
		let miembroPuedeAprobarOperaciones = false
		let miembroNOPuedeAprobarOperaciones = true
		let retorno = miembroNOPuedeAprobarOperaciones
		if (this.comiteAprobaciones) {
			let result = this.comiteAprobaciones.find(comite => (comite.integrante_id === this.auth.user.id) && comite.fecha)
			if (!result) {
				retorno = miembroPuedeAprobarOperaciones
			}
		}
		return retorno
	}

	get miembroNoPuedeDenegar() {
		if (this.comiteAprobaciones) {

			let formaParteDelcomiteYNoAprobado = this.comiteAprobaciones.find(comite => (comite.integrante_id === this.auth.user.id) && comite.fecha)
			let yaExisteUnaAprobacion = this.comiteAprobaciones.find(comite => comite.fecha)
			//let propuestaComiteTieneComentario = this.propuestaComite && this.propuestaComite.comentarios_comite ? this.propuestaComite.comentarios_comite.length > 0 : false

			if ((!formaParteDelcomiteYNoAprobado) && (!yaExisteUnaAprobacion)) {
				return false
			}
		}
		return true
	}

	get esMiembroDelComite() {
		let esMiembroDelComite = true
		let noEsMiembroDelComite = false
		let retorno = noEsMiembroDelComite

		if (this.comiteAprobaciones) {
			let result = this.comiteAprobaciones.find(comite => comite.integrante_id === this.auth.user.id)
			if (result) {
				retorno = esMiembroDelComite
			}
		}
		return retorno
	}

	/**
	 * Funcion devuelve verdadero si puede modificar
	 * o false si no puede modificar cierto documento segun perfil
	 * @param doc Objeto de documento a editar, 
	 * debe contener el grupo al que pertenece este documento
	 */
	verificaDocumentos(doc) {
		/**
		 * ID perfil Legal = 8
		 * ID perfil Analista de Riesgo = 6
		 */
		let perfilLegal = this.auth.user.isLegal
		let perfilAsesorLegal = this.auth.user.isAsesorLegal
		let perfilRiesgo = this.auth.user.isAnalistaRiesgo
		let perfilJefeAdmision = this.auth.user.isJefeDeAdmision
		let perfilAnalistaOperaciones = this.auth.user.isAnalistaDeOperaciones
		let perfilJefeOperaciones = this.auth.user.isJefeDeOperaciones

		/**
		 * ID grupo documentos legales = 1
		 * ID grupo documentos financieros = 2
		 * ID grupo documentos otros = 3
		 */
		let documentoLegal = doc.grupo === 1 ? true : false
		let documentoFinanciero = doc.grupo === 2 ? true : false
		let documentoOtro = doc.grupo === 3 ? true : false
		let excepcionesOperaciones = doc.id === -1 || doc.id === -2 ? true : false

		if ((perfilLegal || perfilAsesorLegal) && documentoLegal) {
			return true
		}

		if ((perfilRiesgo || perfilJefeAdmision || perfilAnalistaOperaciones) && (documentoFinanciero || documentoOtro)) {
			return true
		}

		if (perfilJefeOperaciones) {
			return true
		}

		if (perfilJefeOperaciones && excepcionesOperaciones) {
			return true
		}

		return false
	}

	subirDocumentos(doc, op) {
		let perfilOficialDeNegocio = this.auth.user.isOficialDeNegocio
		let estacion = new Estacion(op)
		if ((doc.adjunto.verificado
			&& perfilOficialDeNegocio
			&& [estacion.estacionAnalisisRiesgo, estacion.estacionLegal, estacion.estacionJefeOperaciones].indexOf(op.estacion_devolucion) != -1)) {
			return false
		}

		return true
	}

	comiteAprobarDenegar() {
		return true
	}


	/**
	 * Si la operacion se encuentra en el arreglo de operaciones que solicito su opinion, se muestra el componente
	 * @param idOperacion 
	 */
	permiteOpinar(idOperacion) {
		let operacionDeOpinion = this.auth.solicitudesDeOpinon.find(op => op.id === idOperacion)
		if (operacionDeOpinion) {
			return true
		} else {
			return false
		}
	}
}
