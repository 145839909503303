import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-nota-credito-table',
  templateUrl: './nota-credito-table.component.html',
  styleUrls: ['./nota-credito-table.component.css']
})
export class NotaCreditoTableComponent implements OnInit {

  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = '';
  public canCreate: boolean = false;

  @Input() set refresh(v: string) {
    this.obtenerNotasCreditos();
  }

  @Input() recaudacion: any;

  @Output() deleteEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private recaudacionService: RecaudacionService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    console.log(this.recaudacion.id);

    this.initVars();
    this.obtenerNotasCreditos();
  }

  obtenerNotasCreditos() {
    if (!(this.recaudacion.id > 0)) return;
    this.recaudacionService.obtenerNotasCreditos(this.recaudacion.id, '', 1, 1000).then(resp => {
      this.loadTable(resp);
    }, err => {
      console.error(err);

    });
  }

  loadTable(data) {

    this.rowData = data.results
    this.pageTableActual = data.page_number
    this.pageTableTotal = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }

  initVars(estados = []) {
    let columns = [
      {
        headerName: '',
        field: '',
        sortable: true,
      },
      {
        headerName: 'Cliente',
        field: 'cliente_nombre',
        class: 'text-center',
        sortable: true,
      },
      {
        headerName: 'Tipo Doc.',
        field: 'tipo_descripcion',
        class: 'text-center',
        sortable: true,
      },
      {
        headerName: 'Número',
        field: 'numero',
        class: 'text-center',
      },
      {
        headerName: 'Saldo',
        field: 'moneda_documento',
        class: 'text-center',
        pipe: 'currency',
        moneda: 'moneda_documento',
        sortable: true,
      },
      // {
      //   headerName: 'Tipo cambio Liq.',
      //   field: 'tipo_cambio_monto',
      //   class: 'text-center',
      //   pipe: 'currency',
      //   moneda: 'moneda_documento',
      //   sortable: true,
      // },
      {
        headerName: 'Monto',
        field: 'monto',
        class: 'text-center',
        pipe: 'currency',
        moneda: 'moneda',
        sortable: true,
      },
      {
        headerName: 'Pago',
        field: 'pago',
        class: 'text-center',
        pipe: 'currency',
        moneda: 'moneda_documento',
        sortable: true,
      },
      {
        headerName: 'Fecha',
        field: 'fecha_facturado',
        class: 'text-center',
        pipe: 'date',
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }

  tableEditEvent(row) {

  }

  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {});

    modalRef.componentInstance.title = "Remover Nota de Crédito";
    modalRef.componentInstance.message =
      "¿Seguro que desea remover esta Nota de Crédito?";

    modalRef.result.then(
      (result) => {
        if (result) {
          this.recaudacionService
            .eliminarNotaCredito(row.id)
            .then((result) => {
              this.obtenerNotasCreditos();
              this.deleteEvent.emit();
            })
            .catch((error) => {
              console.error(error);
            });
        }
      },
      (reason) => { }
    );
  }

  goToPage({ page, per_page, filtros }) {
    // let listEstados = (filtros.estado) ? `${filtros.estado}` : '2,3,5';
    // this.obtenerFacturas(page, per_page, '', '', '', listEstados);
  }

  get canDelete() {
    return this.recaudacion && this.recaudacion.estado == 1 ? true : false;
  }

}
