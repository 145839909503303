import { Component, OnInit, AfterViewInit, AfterViewChecked, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from '../core/services/auth/auth.service';
import { style } from '@angular/animations';
import { ActivatedRoute, Router } from '@angular/router';

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    collapse?: string;
    icontype: string;
    isComite?: boolean;
    fontAwesomeIcon?: any,
    accessAllowed?: any[];
    children?: any[];
}

// export interface ChildrenItems {
//     ab   : string;
//     path: string;
//     title: string;
//     type: string;
//     collapse?: string;
//     icontype: string;
//     isComite?: boolean;
//     fontAwesomeIcon?: any,
//     accessAllowed?: any[];
//     children?: ChildrenItems[];
// }

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {

    public barExposed: boolean = false;
    // faMoneyBillWave  = faMoneyBillWave;
    faPowerOff = faPowerOff;
    @Output() maskUser   : EventEmitter<any> = new EventEmitter<any>();

    faUserEdit = faUserEdit;
    public menuItems: any[] = [];
    userToMask:string;
    subscription: Subscription;

    btnIndicador:boolean = false;
    btnIndicador1:boolean = false;
    
    constructor(public auth: AuthService,
                private router: Router,
                private activatedRoute: ActivatedRoute ){
    }
    
    isNotMobileMenu(){
        if( window.outerWidth > 991){
            return false;
        }
        return true;
    }
    
    ngOnInit() {

        this.activatedRoute.paramMap.subscribe( (params:any)=>{
            this.auth.sidebar.subscribe( val =>{
                if( val ){
                    this.activarSubMenu()
                }
            })
        })

        this.subscription = this.auth.actualUserMask.subscribe(user => this.userToMask = user)

    }

    activarSubMenu(){

        this.auth.routes.map(( item:any )=>{

            item.showsub = false;

            if( item.type === 'sub' && this.router.url.split('/')[1] === item.collapse ){
                item.showsub = true;
            }

            if( item.type === 'sub' ){
                item.children.map( ( sub:any )=>{

                    if( this.router.url.split('/')[1] === sub.path.split('/')[1] ){

                        item.showsub = true;
                    }

                })
            }

            return item;

        })


    }

    ngOnChanges(change){
    }


    maskAction1(tipo, equipo){

        equipo.map( (a)=>{

            if( a.act === undefined ){

                a.act = false;

            }else{

                a.act = false;
            }

        })

        if( tipo == 'Ver los indicadores de mi equipo' ){

            this.auth.changeUserMask( '' )
            this.btnIndicador = true;
            this.btnIndicador1 = false;

        }else{

            console.log('222')
            this.auth.changeUserMask( { id :this.auth.user.id } )
            this.btnIndicador = false;
            this.btnIndicador1 = true;

        }

        this.backDashboard();

    }

    maskAction(userEquipo, equipo){

        equipo.map( (a)=>{

            if( a.act === undefined ){

                a.act = false;

            }else{

                a.act = false;
            }

        })

        // this.maskUser.emit(userEquipo)
        this.auth.changeUserMask(userEquipo)
        // this.messageSource.next(userEquipo)
        userEquipo.act = true;
        this.btnIndicador = false;
        this.btnIndicador1 = false;

        this.backDashboard();
    }

    barEquipoToggle(){

        this.barExposed = !this.barExposed
    }

    backDashboard(){

        if( this.router.url.split('/')[1] !== 'dashboard' ){

            this.router.navigate(['/'])

        }

    }

}
