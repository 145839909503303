import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';

@Component({
  selector: 'app-add-entidad-vinculo',
  templateUrl: './add-entidad-vinculo.component.html',
  styleUrls: ['./add-entidad-vinculo.component.css']
})
export class AddEntidadVinculoComponent implements OnInit {
  faTimes = faTimes;
  myForm: FormGroup;
  @Input() clienteId = 0;
  paises : any[];
  @Input() vinculoId = 0;
  vinculo : any;
  @Input() directo = false;

  personas: any[];

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild        : FormBuilder,
    public activeModal      : NgbActiveModal,
    private clientesService	: ClientesService,
  ) { 
    this.initForm();
  }

  ngOnInit(): void {
    this.getTiposPersonas();

    if (this.vinculoId) {
      this.obtenerVinculo();
    }
  }

  getTiposPersonas() {
    this.clientesService.tiposPersonas()
    .then(res => {
      this.personas = res['results'];
    })
    .catch(error => {
      console.error(error);
    })
  }

  obtenerVinculo() {
    this.clientesService.obtenerVinculo(this.vinculoId)
    .then(res => {
      this.vinculo = res;
      this.setForm(res);
    })
    .catch(error => {
      console.error(error);
    });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre          : [null, [Validators.required]],
      tipo_persona    : [null, [Validators.required, Validators.maxLength(50)]],
      dni_ruc         : [null, []],
      aporte          : [null, []],
      participacion   : [null, []],
      gestion         : [false, []],
      codigo_sbs      : [null, []],
      cargo           : [null, []],
    });
  }

  get f() {
    return this.myForm.controls;
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  onSubmit() {
    if(this.myForm.valid) {
      const data = this.myForm.value;
      data['cliente'] = this.clienteId;
      data['directo'] = (this.directo) ? 1 : 0;

      this.clientesService.guardarVinculo(data, this.vinculoId)
      .then(res => {
        this.successEmit.emit(res);
        this.activeModal.close(false);
      }).
      catch(error => {
        this.activeModal.close(false);
      });
    }
  }

}
