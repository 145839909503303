import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
	faFileDownload, 
	faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import {
	trigger,
	state,
	style,
	animate,
	transition
	} from '@angular/animations';	
import { FormBuilder, FormGroup } from '@angular/forms';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
	
@Component({
	selector: 'app-upload-files',
	templateUrl: './upload-files.component.html',
	styleUrls: ['./upload-files.component.css'],
	animations: [
		trigger('loadingAnimation', [
			state('close', style({
			  opacity: 0,
			  display: 'none'
			})),
			state('open', style({
			  opacity: 1,
			  display: 'flex'
			})),
			transition('close <=> open', animate(300)),
		])
	  ]
})
export class UploadFilesComponent implements OnInit {
	faFileDownload     = faFileDownload;
	faCloudUploadAlt   = faCloudUploadAlt;
    documentoContext: any = {};
	docs               : any[];
	uploadForm         : FormGroup;
	@Input() set documentos(docs){
		if(docs){
			this.docs = docs.map((doc)=>{
				doc.loading = false
				return doc
			})
		}
	}
	@Input() readonly    : boolean = false;
	@Input() enableVerify: boolean = false;
	modelo               : boolean = false;
	@Output() fileSelected: EventEmitter<any> = new EventEmitter<any>();
	@Output() docVerify: EventEmitter<any> = new EventEmitter<any>();

	
	constructor(
		private formBuilder: FormBuilder,
		private configs : DocschecklistService,
	) { }
	
	ngOnInit(): void {
	}
	
	verifyChange(event,doc,index){
		doc.loading = true
		this.docVerify.emit({doc: doc, index:index,value:event})
	}

	asyncEnd({doc,index}){
		doc.loading = false
	}


    uploadDoc(item){
        this.documentoContext = item;
        var fileSelect = document.createElement("input"); 
        fileSelect.setAttribute("id", "fielSelect"); 
        fileSelect.setAttribute("type", "file"); 
        fileSelect.setAttribute("name", "profile"); 
        fileSelect.setAttribute("style", "display: none;");
        let formularioDetalles = document.getElementById("form_upload")
        formularioDetalles.appendChild(fileSelect)
        document.getElementById("fielSelect").click()
        document.getElementById("fielSelect").addEventListener('change', (event) => {
            this.onFileSelect(event)
            formularioDetalles.removeChild(fileSelect)
        });
    }	
	

	onFileSelect(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			const formData = new FormData();
			formData.append('adjunto', file);
			formData.append('documento', this.documentoContext.id);
			this.fileSelected.emit(formData)
		}
	}	

	downloadDoc({adjunto}){
        let urlDoc = adjunto
        var win = window.open(urlDoc, '_blank');
        win.focus();
	}

	openDocument({adjunto}){
        let urlDoc = adjunto;
		const ultimoSlash = new URL(urlDoc).pathname;
    	const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1];
        console.log(adjunto);
		this.downloadFile(adjunto, adjuntoName);
        // var win = window.open(urlDoc, '_blank');
        // win.focus();
    }

	downloadFile(url, name) {
		this.configs.downloadFile(url).then(
			(x: any) => {
			console.log(x);
			var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
			var downloadURL = window.URL.createObjectURL(blob);
			var link = document.createElement('a');
			link.href = downloadURL;
			link.download = name;
			link.click();
			}, async (reason) => {
			    console.log(reason);
			}), err => {
			    console.error(err);
		    };
	}

	validarPesoAdjunto(file_size: any, api_url: any) {
        	var apiBase = api_url
	        if (file_size > 10000000) {
	            apiBase = 'http://3.139.77.41/';
	        }
	        return apiBase;
    	}
    }



