import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { SharedFunctions } from 'app/core/shared/functions';

@Component({
  selector: 'app-add-aval',
  templateUrl: './add-aval.component.html',
  styleUrls: ['./add-aval.component.css']
})
export class AddAvalComponent implements OnInit {
  myForm             : FormGroup;
  @Input() clienteId = 0;
  @Input() avalRow: any = null;
  @Input() editable: any = null;
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();
  provincias : any [] = []
  distritos : any [] = []
  paises : any [] = []
  departamentos : any [] = []
  tiposAvales : any [] = []
  regimenesMatrimoniales : any [] = []
  magnitudes: any [] = [];
  faTimes = faTimes;
  selectSexo : any = [
    {
      id : 1,
      descripcion: 'Masculino',
      symbol: 'M'
    },
    {
      id : 2,
      descripcion: 'Femenino',
      symbol: 'F'
    },
  ]

  selectEstadoCivil : any = []
  
  constructor(
    public formbuild       : FormBuilder,
    public activeModal     : NgbActiveModal,
    private authService    : AuthService,
    private clientesService: ClientesService,
    public sharedFunctions  : SharedFunctions
  ) {
   }

  ngOnInit(): void {
    this.initForm()
    this.getData()
  }

  getData(){
    let paises             = this.clientesService.obtenerPaises()
    let departamentos      = this.clientesService.obtenerDepartamentos()
    let tiposAvales        = this.clientesService.obtenerTiposAvales()
    let regimenMatrimonial = this.clientesService.obtenerRegimenesPatrimoniales()
    let estadosCiviles     = this.clientesService.estadosCiviles()
    let magnitudes         = this.clientesService.magnitudes();

    Promise.all([paises,departamentos,tiposAvales,regimenMatrimonial,estadosCiviles,magnitudes]).then((res: any[])=>{
      this.paises                 = res[0]['results']
      this.departamentos          = res[1]['results']
      this.tiposAvales            = res[2]['results']
      this.regimenesMatrimoniales = res[3]['results']
      this.selectEstadoCivil      = res[4]['results']
      this.magnitudes             = res[5]['results'];

      if(this.editable){
        this.setForm(this.avalRow)
      }
    })

  }

  getProvincias(departamento){
    this.clientesService.obtenerProvincias(departamento).then((res:any)=>{
      this.provincias = res['results']
      this.myForm.get('provincia').enable()
    })
  }

  getDistrito(provincia){
    this.clientesService.obtenerDistritos(provincia).then((res:any)=>{
      this.distritos = res['results']
      this.myForm.get('distrito').enable()
    })
  }

  initForm() {
    this.myForm = this.formbuild.group({
      cliente            : [this.clienteId, []],
      dni                : [null, [Validators.required, Validators.maxLength(8), Validators.minLength(8), Validators.pattern('[0-9]*') ]],
      primer_nombre      : [null, [Validators.required]],
      segundo_nombre     : [null, []],
      apellido_paterno   : [null, [Validators.required]],
      apellido_materno   : [null, [Validators.required]],
      apellido_casado    : [null, []],
      fecha_nacimiento   : [null, []],
      sexo               : [null, []],
      estado_civil       : [null, []],
      domicilio          : [null, [Validators.required]],
      pais               : [null, []],
      departamento       : [null, [Validators.required]],
      provincia          : [{value : null, disabled: true}, [Validators.required]],
      distrito           : [{value : null, disabled: true}, []],
      fecha_situacional  : [null, []],
      monto_patrimonio   : [null, []],
      tipo               : [null, [Validators.required]],
      regimen_matrimonial: [null, []],
      pais_nacimiento    : [null, []],
      pais_residencia    : [null, []],
      codigo_sbs         : [null, [ Validators.maxLength(10), Validators.minLength(10) ]],
      magnitud           : [null, []],
    });

    this.myForm.get('departamento').valueChanges.subscribe((value)=>{
      if(value){
        this.getProvincias(value)
      }
    });

    this.myForm.get('provincia').valueChanges.subscribe((value)=>{
      if(value){
        this.getDistrito(value)
      }
    });
  }

  setForm(valores) {
    for (const key in valores) {
      if (this.myForm.controls[key]) {

        switch(key){
          case 'fecha_situacional':
            this.myForm.controls[key].setValue(this.sharedFunctions.parseDate(valores[key]));
            break
            case 'fecha_nacimiento':
            this.myForm.controls[key].setValue(this.sharedFunctions.parseDate(valores[key]));
          break
          case 'provincia':
            if(valores[key]){                                                                                                
              this.myForm.get('provincia').enable()
              this.getProvincias(valores.departamento)
              this.myForm.controls[key].setValue(valores[key]);
            }
          break;
          case 'distrito':
            if(valores[key]){
              this.myForm.get('distrito').enable()
              this.getDistrito(valores.provincia)
              this.myForm.controls[key].setValue(valores[key]);
            }
          break;   
          default:
            this.myForm.controls[key].setValue(valores[key]);
          break
        }

      }
        
    }
  }


  onSubmit(){

    let valores = this.mapDataToSave()

    if(this.editable){
      this.clientesService.actualizarAval(this.avalRow.id, valores).then((res : any)=>{
        this.successEmit.emit(true)
      })
    } else {
      this.clientesService.guardarAval(valores).then((res: any)=>{
        this.successEmit.emit(true)
      })
    }

  } 

  mapDataToSave(){
    let valores = {}
    let controles = this.myForm.controls

    for (const key in controles) {
      if(this.myForm.get(key) && this.myForm.get(key).value){
        switch(key){
          case 'fecha_situacional':
            valores[key] = this.sharedFunctions.formatDate(this.myForm.get(key).value)
          break
          case 'fecha_nacimiento':
            valores[key] = this.sharedFunctions.formatDate(this.myForm.get(key).value)
          break
          default:
            valores[key] = this.myForm.get(key).value
          break
        }
      }
    }

    return valores
  }


  // this.successEmit.emit();

}
