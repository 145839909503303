import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from "../toast/toast.service";
import { FactoringService } from "app/core/services/factoring/factoring.service";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AceptantesService {
  private apiUrl: string = "";
  public personas: string = "clientes/personas/";
  public aceptantes: string = "clientes/aceptantes/";
  public contactos: string = "clientes/aceptantes/contactos/";
  public archivos: string = "clientes/aceptantes/archivos/";
  public Aceptantesclientes: string = "clientes/aceptantes/clientes/";
  public clientes: string = "clientes/clientes/";
  public deudas: string = "factoring/beneficiarios/deuda/documentoscartera/";

  public departamentos: string = "clientes/ubigeos/departamentos/";
  public provincias: string = "clientes/ubigeos/provincias/";
  public distritos: string = "clientes/ubigeos/distritos/";
  public paises: string = "clientes/ubigeos/paises/";
  public empresas: string = "factoring/deudores/";

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient,
    public factoring: FactoringService,
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
  }
  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();

    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);
  }

  getDeudores(queryParams: object = {}) {
    const url = `${this.apiUrl}factoring/deudores/?${Object.entries(queryParams).map((e: any) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&')}`
    return this.http.get(url).pipe(
      catchError(res => {
        let error = 'Ocurrió un error inesperado'
        if (res.error && typeof (res.error) == 'object') {
          error = Object.values(res.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
        }
        this.toast.warning(error)
        return throwError(res)
      })
    )
  }

  importarDeudoresSinRiesgo(data) {
    const url = `${this.apiUrl}factoring/deudores/importar-sin-riesgo/`
    return this.http.post(url, data).pipe(
      catchError(res => {
        let error = 'Ocurrió un error inesperado'
        if (res.error && typeof (res.error) == 'object') {
          error = Object.values(res.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
        }
        this.toast.warning(error)
        return throwError(res)
      })
    )
  }

  eliminarDeudoresSinRiesgo(data) {
    const url = `${this.apiUrl}factoring/deudores/eliminar-sin-riesgo/`
    return this.http.post(url, data).pipe(
      catchError(res => {
        let error = 'Ocurrió un error inesperado'
        if (res.error && typeof (res.error) == 'object') {
          error = Object.values(res.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
        }
        this.toast.warning(error)
        return throwError(res)
      })
    )
  }

  /**
   * Retorna los aceptantes
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerAceptantes(page: number = 1, page_size: number = 10, nombre = "") {
    const url =
      this.apiUrl +
      this.aceptantes +
      `?nombre__icontains=${nombre}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La información que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna el aceptante por un id consultado
   * @param id ID del aceptante a consultar
   */
  obtenerAceptante(id) {
    const url = this.apiUrl + this.aceptantes + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Guarda o edita un aceptante
   * @param data Datos a registrar
   * @param aceptanteId ID del aceptante si se trata de edicion.
   */
  guardarAceptante(data, aceptanteId = 0) {
    const url = aceptanteId
      ? this.apiUrl + this.aceptantes + `${aceptanteId}/`
      : this.apiUrl + this.aceptantes;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (aceptanteId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("El Aceptante fue registrado exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  /**
   * Eliminar Aceptante
   */
  eliminarAceptante(id: number) {
    const url = this.apiUrl + this.aceptantes + `${id}/`;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  tiposPersonas(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.personas +
      "tipos/" +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  estadosCiviles(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.personas +
      "estadosciviles/" +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  /**
   * Obtiene los departamentos
   * @param page
   * @param page_size
   */
  obtenerDepartamentos(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.departamentos +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  /**
   * Obtiene las provincias para un departamente
   * @param departamento ID del departamento
   * @param page
   * @param page_size
   */
  obtenerProvincias(departamento, page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.provincias +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&departamento=${departamento}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  /**
   * Obtiene los distritos para un provincia
   * @param provincia ID de la provincia
   * @param page
   * @param page_size
   */
  obtenerDistritos(provincia, page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl +
      this.distritos +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&provincia=${provincia}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna los contactos del aceptante
   * @param aceptanteId ID del aceptante
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerContactos(
    aceptanteId: number = 0,
    page: number = 1,
    page_size: number = 10
  ) {
    const url =
      this.apiUrl +
      this.contactos +
      `?aceptante=${aceptanteId}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La información que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  guardarContacto(data, contactoId = 0) {
    const url = contactoId
      ? this.apiUrl + this.contactos + `${contactoId}/`
      : this.apiUrl + this.contactos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (contactoId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  guardarGrupoContacto(data) {
    const url = this.apiUrl + this.contactos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      data.forEach((element) => {
        this.http.post(url, element).subscribe(
          (response) => {
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      });

      this.spinnerOff();
    });
  }

  /**
   *
   * @param contactId
   */
  obtenerContacto(contactId) {
    const url = this.apiUrl + this.contactos + `${contactId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  eliminarContacto(contactId) {
    const url = this.apiUrl + this.contactos + `${contactId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Contacto Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Obtiene los paises
   * @param page
   * @param page_size
   */
  obtenerPaises(page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl + this.paises + `?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  /**
   * Retorna los archivos del aceptante
   * @param aceptanteId ID del aceptante
   * @param page Pagina del request
   * @param page_size Resultados por pagina
   */
  obtenerArchivos(
    aceptanteId: number = 0,
    page: number = 1,
    page_size: number = 10
  ) {
    const url =
      this.apiUrl +
      this.archivos +
      `?aceptante=${aceptanteId}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La información que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  async guardarArchivo(data, archivoId = 0) {
    const apiBase = await this.factoring.validarPesoAdjunto(data.get('peso'), this.apiUrl);
    data.delete('peso');
    const url = archivoId
      ? apiBase + this.archivos + `${archivoId}/`
      : apiBase + this.archivos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (archivoId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            let msg = 'La operación falló'
            if (err.error && typeof (err.error) == 'object') {
                msg = Object.values(err.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
            }
            this.spinnerOff(msg, false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            let msg = 'La operación falló'
            if (err.error && typeof (err.error) == 'object') {
                msg = Object.values(err.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
            }
            this.spinnerOff(msg, false);
            ref(err);
          }
        );
      }
    });
  }

  /**
   *
   * @param archivoId
   */
  obtenerArchivo(archivoId) {
    const url = this.apiUrl + this.archivos + `${archivoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  eliminarArchivo(archivoId) {
    const url = this.apiUrl + this.archivos + `${archivoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Archivo Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerClientes(
    ruc: number = 0,
    page: number = 1,
    page_size: number = 1000
  ) {
    const url = this.apiUrl + this.Aceptantesclientes +
      `?ruc=${ruc}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La información que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  actualizarCliente(data, clienteId) {
    const url = this.apiUrl + this.Aceptantesclientes + `${clienteId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.put(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La información que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  obtenerDeudas(
    clientes: string = '',
    deudor: string = '',
    page: number = 1,
    page_size: number = 1000
  ) {
    const url = this.apiUrl + this.deudas +
      `?id__in=${clientes}` +
      `&page_size=${page_size}` +
      `&deudor=${deudor}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La información que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  bloquearAceptante(data, IdAceptante) {
    const url = this.apiUrl + this.aceptantes + `${IdAceptante}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.patch(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La información que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  obtenerClienteBiId(clienteId) {
    const url = this.apiUrl + this.clientes + `${clienteId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La información que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  obtenerAceptanteById(aceptanteId) {
    const url = this.apiUrl + this.aceptantes + `${aceptanteId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La información que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  updateBloqueoUsuarioAceptante(
    body: any,
    idEntidad: number
  ) {
    const url =
      this.apiUrl + this.aceptantes + `${idEntidad}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.patch(url, body).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  downloadFile(url) {
    return new Promise((resolve, reject) => {
      let httpReques;
      // let URL = this.apiUrl + this.formato_documentos + "solicitud_final/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.get(url, httpOptions);
      this.spinnerOn();
      httpReques.subscribe(data => {
        this.spinnerOff();
        resolve(data);
      }, async (err) => {
        this.spinnerOff();
        console.log('Error al  generar contrato');
        reject(err);
      });

    });
  }

  obtenerEmpresas(aceptanteId) {
    const url = this.apiUrl + this.empresas + `${aceptanteId}/empresas`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

}
