import { Injectable, ViewChild } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { SessionNotificationComponent } from 'app/shared/session-notification/session-notification.component';
import { AppComponent } from 'app/app.component';
import { LocalServiceService } from '../services/local-service.service';
import { AppService } from 'app/app.service';
import { ToastService } from '../services/toast/toast.service';
// import { APP_ID } from '../../configs/app-settings.config';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertValidationComponent } from '../../shared/alert-validation/alert-validation.component'
import { NgxSpinnerService } from 'ngx-spinner';
import { DispositivosService } from '../services/dispositivos/dispositivos.service'

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
    @ViewChild(AppComponent) alertModal: AppComponent;

    obs$: any = new Observable();

    private dispositivosService: DispositivosService = null

    constructor(
        public appService: AppService,
        public toast: ToastService,
        public localService: LocalServiceService,
        public router : Router,
        public modalService: NgbModal,
        public spinner: NgxSpinnerService,
        private http: HttpClient) {

    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let token = this.localService.getJsonValue('token')
        let user = this.localService.getJsonValue('user');

        if (!token) {
            return next.handle(req)
        }

        const safe_method = req.headers.get('SAFE_METHOD') === '1'

        const modified = req.clone({
            headers: req.headers.set('Authorization', `Bearer ${token}`)
                        .delete('SAFE_METHOD'),
        });
 
        if( user?.id && (req.method === 'POST' || req.method === 'PUT' || req.method === 'PATCH' || req.method === 'DELETE') ){
            if (!safe_method && this.getDispositivosService() && !this.getDispositivosService().esDispositivoConfiable()) {
                this.spinner.hide();
                this.modalService.open(AlertValidationComponent, {size:'md', backdrop: 'static'})
                //Se envia un Observable vacío para cancelar la peticion
                return next.handle(this.obs$)
            }
        }

        return next.handle(modified).pipe(
            // tap(evt => {
            // console.log(evt)
            // this.spinnerOff()
            // this.spinner.hide()
            // }),
            catchError((err: HttpErrorResponse) => {
                if (err.status === 503) {
                    console.log('Entro en error 503', err)
                    return this.handleErrors(err, next, modified)
                } else {
                    return this.handleErrors(err, next)
                }

            })
        );
        
    }

    handleErrors(err, next, req = null) {

        if (!!err && !!err.error && !!err.error.detail) {
            /**
             * Caso de generacion de reportes cunado el Aceptante no tiene linea
             */
            console.warn(err.error.detail)
            if (err.error.detail === 'Credenciales de autenticación incorrectas.') {
                this.localService.clearToken();
                this.router.navigate(['/login']);
            }

        } else if (!!err && !!err.error && !!err.error.code) {
            /**
             * Caso cuando el token de autenticacion no es valido,
             */
            if (err.error.code == 'token_not_valid') {
                console.log(`Error request: ${err.error.code}`)
                if (this.alertModal) {
                    this.alertModal.receive('RECEPCION');
                }
            }

        } else if (err.status === 503) {
            /**
             * Caso de error 503
             */
            this.intercept(req, next)
            next.handle(req)
        }

        return throwError(err);
    }

    private getDispositivosService() {
        if (!this.dispositivosService && this.appService.settings) {
            this.dispositivosService = new DispositivosService(this.appService, this.localService, this.http)
        }
        return this.dispositivosService
    }

}