import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CuentasXCobrarRoutingModule } from './cuentas-x-cobrar-routing.module';
import { CuentasXCobrarComponent } from './pages/cuentas-x-cobrar/cuentas-x-cobrar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'app/shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ModalCuentasCobrarComponent } from './components/modal-cuentas-cobrar/modal-cuentas-cobrar.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [CuentasXCobrarComponent, ModalCuentasCobrarComponent],
  imports: [
    FontAwesomeModule,
    CommonModule,
    CuentasXCobrarRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    NgxDatatableModule,
    NgSelectModule,
    UiSwitchModule.forRoot({
                size              : 'medium',
                color             : '#3e50b4',
                checkedTextColor  : "#ffffff",
                uncheckedTextColor: "#ffffff",
                defaultBgColor    : '#6c757d',
                defaultBoColor    : '#3e50b4',
                checkedLabel      : 'Si ',
                uncheckedLabel    : 'No'
            }),
    NgxMaskModule.forRoot(maskConfig),
    BsDatepickerModule.forRoot(),
  ]
})
export class CuentasXCobrarModule { }
