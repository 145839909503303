import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { JudicialesRoutingModule } from './judiciales-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ArchwizardModule } from 'angular-archwizard';
import { SharedModule } from 'app/shared/shared.module';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { UiSwitchModule } from 'ngx-ui-switch';
import { JudicialComponent } from './judicial/judicial.component';
import { DocsCarterasModalComponent } from './components/docs-carteras-modal/docs-carteras-modal.component';
import { ExpedientejudicialComponent } from './components/expedientejudicial/expedientejudicial.component';
import { BandejajudicialexpedientesComponent } from './bandejajudicialexpedientes/bandejajudicialexpedientes.component';
import { ClientesModule } from '../clientes/clientes.module';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [JudicialComponent, DocsCarterasModalComponent, ExpedientejudicialComponent, BandejajudicialexpedientesComponent],
  imports: [
    CommonModule,
    JudicialesRoutingModule,
    FontAwesomeModule,
    SharedModule,
    JwBootstrapSwitchNg2Module,
    NgbModule,
    NgSelectModule,
    ClientesModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    ArchwizardModule,
  ]
})
export class JudicialesModule { }
