import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ReportesRoutingModule } from './reportes-routing.module';
import { ReportesComponent } from '../pages/reportes/reportes.component';
import { NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { FormControl, FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SharedModule } from 'app/shared/shared.module';
import { MonedaPipe } from 'app/core/pipes/moneda.pipe';
import { ReporteSabanaComponent } from '../pages/reporte-sabana/reporte-sabana.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [ReportesComponent, ReporteSabanaComponent],
  imports: [
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ReportesRoutingModule,
    NgbNavModule,
    SharedModule,
    NgxMaskModule.forRoot(maskConfig),
    NgSelectModule,
    BsDatepickerModule.forRoot(),
  ]
})
export class ReportesModule { }
