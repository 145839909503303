import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { FacturacionService } from 'app/core/services/facturacion/facturacion.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
  selector: 'app-nota-credito-modal',
  templateUrl: './nota-credito-modal.component.html',
  styleUrls: ['./nota-credito-modal.component.css']
})
export class NotaCreditoModalComponent implements OnInit {
  faTimes = faTimes
  myForm: FormGroup;
  monedas: any[] = [];
  estados: any[] = [];
  tiposPagador: any[] = [];
  @Input() facturaIn: any;
  factura: any;
  user: any;
  tipos: any[] = [];
  facturas: any[] = [];
  readonly = true;
  tiposNc: any[] = [];
  readonlyPendiente = true;
  tipo_nc_previa: number;
  total_previo: number = 0;

  referencia_factura:string = '';

  public aprobacionesrowData: any[] = [];
  public aprobacionesloadingTable: boolean = false;
  public aprobacionescolumnDefs: columnHeader[];

  constructor(
    private toast: ToastService,
    public activeModal: NgbActiveModal,
    public formbuild: FormBuilder,
    private cuentaService: CuentasService,
    public configs: DocschecklistService,
    private facturacionService: FacturacionService,
    public authService: AuthService,
  ) {
    this.initForm();
    this.user = this.authService.user;
  }

  ngOnInit(): void {

    this.initNC();

  }

  initNC() {
    let factura = this.facturacionService.obtenerFactura(this.facturaIn.id);
    let estados = this.cuentaService.obtenerEstados();
    let tiposPagador = this.cuentaService.obtenerTiposPagador();
    let monedas = this.configs.obtenerMonedas();
    let tipos = this.facturacionService.obtenerTipos();
    let facturas = this.facturacionService.obtenerFacturasCompacto('', '', '1', '2', '', 1, 1000,
      this.facturaIn.tipo_pagador == 2 ? this.facturaIn.pagador : '',
      this.facturaIn.tipo_pagador == 1 ? this.facturaIn.pagador : ''
      );
    let tipoNc = this.facturacionService.getTipoNc(1, 10, true);

    Promise.all([estados, tiposPagador, monedas, factura, tipos, facturas, tipoNc]).then((res) => {
      this.tiposPagador = res[0]['results'];
      this.estados = res[1]['results'];
      this.monedas = res[2]['results'];
      this.factura = res[3];
      this.tipos = res[4]['results'];
      this.facturas = res[5]['results'];
      this.tiposNc = res[6]['results'];

      this.setForm(this.factura);
      if (this.factura.estado != 1) this.myForm.disable();
      if (this.facturaIn.referencia_tipo_descripcion === 'Nota de débito'){
        this.referencia_factura = this.facturaIn.referencia_referencia_numeracion;
        this.myForm.controls['referencia_factura'].setValue( this.facturaIn.referencia_referencia_numeracion)
        // this.myForm.disable(); 
      }
      this.aprobacionesrowData = this.factura.aprobadores;
      this.initTables();
    }, err => {
      console.error(err);
    });
  }

  initTables() {
    let headerAprobacionesTable = [
      {
        headerName: "N°",
        field: "id",
        sortable: true,
        pipe: 'indexcol'
      },
      {
        class: "text-center",
        headerName: "Aprobador",
        field: "responsable_nombre",
      },
      {
        class: "text-center",
        headerName: "Fecha",
        field: "fecha",
        pipe: this.factura.estado == 1 ? 'switch' : 'date',
        text: 'Aprobar',
        actionableType: 'Aprobar',
        sortable: true,
        idResponsable: this.user.id,
        responsableField: 'responsable',
        stateField: 'estado'
      },
    ];

    this.aprobacionescolumnDefs = headerAprobacionesTable;

  }

  initForm() {
    this.myForm = this.formbuild.group({
      tipo_pagador: [null, [Validators.required]],
      tipo_pagador_descripcion: [null, [Validators.required]],
      pagador_nombre: [null, [Validators.required]],
      pagador_ruc: [null, [Validators.required]],
      concepto: [null, [Validators.required]],
      fecha_facturado: [null, [Validators.required]],
      fecha_pago: [null, [Validators.required]],
      moneda: [null, [Validators.required]],
      monto: [null, [Validators.required]],
      igv: [null, [Validators.required]],
      total: [null, [Validators.required]],
      tipo: [null, [Validators.required]],
      tipo_descripcion: [null, [Validators.required]],
      numeracion: [null, [Validators.required]],
      observacion: [null, [Validators.required]],
      glosa: [null, []],
      referencia: [null, []],
      referencia_factura: [null, []],
      tipo_nc: [null, []]
    });
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        switch (key) {
          case 'fecha_facturado':
            this.myForm.controls[key].setValue(this.parseDate(item[key]));
            continue;
          case 'fecha_pago':
            this.myForm.controls[key].setValue(this.parseDate(item[key]));
            continue;
          case 'tipo_nc':
            this.tipo_nc_previa = item[key]
            this.myForm.controls[key].setValue(item[key]);
            continue;
          case 'total':
            this.total_previo = item[key]
            this.myForm.controls[key].setValue(item[key]);
        }

        if(item.estado == 1 ){
          this.readonlyPendiente = false;
        }else{
          this.readonlyPendiente = true;
        }

        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  parseDate(date) {
    if (!date)
      return null;

    let dateNow = new Date();
    let yy = date.split('-')[0];
    let mm = (date.split('-')[1] - 1);
    // let dd = date.split('-')[2];
    let dd = (date.split('-')[2]).substring(0, 2);

    let d = new Date(yy, mm, dd, dateNow.getHours());

    return d;
  }

  parseDate1(date) {
    return `${ date.getFullYear() }-${ date.getMonth()+1 }-${ date.getDate() }`
  } 

  async aprobar(row) {
    const data = {
      factura: this.factura.id,
      responsable: row.responsable
    }
    const error = await this.onSubmit(true, false)
    if (error) return
    this.facturacionService.aprobarNC(data).then(resp => {
      this.activeModal.close(true)
    }, err => {
      console.error(err);

    });
  }

  rechazar(row) {
    const data = {
      factura: this.factura.id,
      responsable: row.responsable
    }
    this.facturacionService.rechazarNC(this.factura.id).then(resp => {
      this.activeModal.close(true);
      // this.initNC();
    }, err => {
      console.error(err);

    })
  }

  get statusNC() {
    let status = true;
    this.aprobacionesrowData.forEach((row) => {
      if (!row.id) status = false;
    });
    return status;
  }

  get showReferencia() {
    return this.myForm.controls['tipo'].value == 2 || this.myForm.controls['tipo'].value == 3;
  }

  async onSubmit(type, interno=true) {
    const ERROR = true
    if (this.showReferencia && !this.myForm.controls['referencia'].value) {
      return ERROR;
    }
    if (this.myForm.controls['total'].value == 0) {
      this.toast.warning("La nota de crédito no puede tener total 0");
      return ERROR;
    }
    if (this.showReferencia && !this.myForm.controls['tipo_nc'].value) {
      this.toast.warning("Se tiene que asignar un 'tipo' a la nota de crédito");
      return ERROR;
    }
    if (interno && this.tipo_nc_previa === this.myForm.controls['tipo_nc'].value && this.total_previo == this.myForm.controls['total'].value) {
      this.toast.success("No se detectaron cambios");
      return ERROR;
    }

    this.total_previo = this.myForm.controls['total'].value;

    let data = {
      tipo: this.myForm.controls['tipo'].value,
      observacion: this.myForm.controls['observacion'].value,
      referencia: this.myForm.controls['referencia'].value,
      tipo_nc: this.myForm.controls['tipo_nc'].value
    }

    if( this.facturaIn.estado === 1 ){
      data['fecha_pago'] = this.parseDate1( this.myForm.controls['fecha_pago'].value );
      data['total'] = this.myForm.controls['total'].value
    }

    await this.facturacionService.mofificarFactura(this.facturaIn.id, data).then(res => {
      if( !type ){
        this.initNC();
      }else{
        // this.activeModal.close(true);
      }
    }, err => {
      console.error(err);
    });
    return null
  }

}
