import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/core/services/auth/auth.service';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';

import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

import Swal from 'sweetalert2';
import { utils, writeFile } from 'xlsx';
import { functions } from '../../../../core/helpers/functions';

@Component({
  selector: 'app-reporte-cuadro-consolidado',
  templateUrl: './reporte-cuadro-consolidado.component.html',
  styleUrls: ['./reporte-cuadro-consolidado.component.css']
})
export class ReporteCuadroConsolidadoComponent implements OnInit {

	public headerTable: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
  public searchstring: string = '';
  public rowData = [];
  public generando: boolean = false;

  public filtros: any = {
    operacion: undefined,
    cliente_nombre: undefined,
    cliente_ruc: undefined,
    aceptante_nombre: undefined,
    aceptante_ruc: undefined,
    tipo_pagador: undefined,
    linea: undefined,
  };

  public fechas = {

    fecha_devolucion__gte: '',
    fecha_devolucion__lte: '',
    fecha__lte: '',
    fecha__gte: ''

  } 

  constructor(
    private factoringService: FactoringService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {

    this.getReporteCuadroConsolidados({page:1, per_page:this.pageTablePerPages, filtros:{}, type: ''})

    const monedaExc = [
      {
        id: 1,
        descripcion:'Soles'
      },
      {
        id:2,
        descripcion:'Dólares'
      }
    ]

    this.loadTableColumns(monedaExc)

  }

  loadTableColumns (monedaExc = []) {
    this.headerTable = [
      { 
				headerName: 'N°',
        field: 'index',
				// pipe: 'indexcol',
				sortable: true,
			},
      {
        headerName: 'Ejecutivo',
        class: 'text-center',
        field: 'ejecutivo',
        sortable: true,
      },
      {
        headerName: 'Fecha',
        class: 'text-center',
        field: 'fecha',
        sortable: true,
        pipe: "date",
        filterable: true,
        filterDate: true,
        filterProp: 'fecha'
      },
      {
        headerName: 'Nro. aplicación',
        class: 'text-center',
        field: 'nro_recaudacion',
        filterProp: 'nro_recaudacion',
        sortable: true,
        filterable: true,
        filterInput: true,
      },
      {
        headerName: 'Nro Operación',
        field     : 'nro_operacion',
        filterProp: 'nro_operacion',
        sortable  : true,
        class: 'text-center',
        filterable: true,
        filterInput: true,
      },
      {
        headerName: 'RUC Cliente',
        class: 'text-center',
        field: 'ruc_cliente',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'ruc_cliente'
      },
      {
        headerName: 'Cliente Nombre',
        class: 'text-center',
        field: 'cliente_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'cliente_nombre'
      },
      {
        headerName: 'RUC Aceptante',
        class: 'text-center',
        field: 'ruc_aceptante',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'ruc_aceptante'
      },
      {
        headerName: 'Aceptante Nombre',
        class: 'text-center',
        field: 'aceptante_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'aceptante_nombre'
      },
      {
        headerName: 'Moneda Exc',
        class: 'text-center',
        field: 'moneda_excedente',
        sortable: true,
        filterable: true,
        filterProp: "moneda_excedente_cod",
        filterSelectItems: monedaExc,
        filterItemsProps: {
          value: "id",
          label: "descripcion",
        },
      },
      {
        headerName: 'Monto Excedente',
        class: 'text-center',
        field: 'monto_excedente',
        sortable: true,
      },
      {
        headerName: 'Concepto CXC',
        class: 'text-center',
        field: 'concepto_cxc',
        sortable: true,
      },
      {
        headerName: 'Moneda CXC',
        class: 'text-center',
        field: 'moneda_cxc',
        sortable: true,
      },
      {
        headerName: 'Monto CXC Aplicada',
        class: 'text-center',
        field: 'monto_cxc_aplicada',
        sortable: true,
      },
      {
        headerName: 'Doc en Cartera',
        class: 'text-center',
        field: 'documento_cartera',
        sortable: true,
      },
      {
        headerName: 'Moneda Doc',
        class: 'text-center',
        field: 'moneda_documento',
        sortable: true,
      },
      {
        headerName: 'Monto Doc Aplicado',
        class: 'text-center',
        field: 'monto_documento_cartera_aplicado',
        sortable: true,
      },
      {
        headerName: 'Monto a Devolver',
        class: 'text-center',
        field: 'monto_devolver',
        sortable: true,
      },
      {
        headerName: 'Fecha de Devolución',
        class: 'text-center',
        field: 'fecha_devolucion',
        sortable: true,
        pipe: "date",
        filterable: true,
        filterDate: true,
        filterProp: 'fecha'
      },
      {
        headerName: 'Tipo de Cambio',
        class: 'text-center',
        field: 'tipo_cambio',
        sortable: true,
      },
    ]
  }

  loadTable(data) {
		this.rowData           = functions.indextable( data )
		this.pageTableActual   = data.page_number
		this.pageTableTotal    = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages    = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  getReporteCuadroConsolidados({page, per_page, filtros, type}) {

    if(type === 'fecha_devolucion'){

      this.fechas.fecha_devolucion__gte = filtros.fecha__gte ? filtros.fecha__gte.split(' ')[0] : undefined
      this.fechas.fecha_devolucion__lte = filtros.fecha__lte ? filtros.fecha__lte.split(' ')[0] : undefined

    }else if( type === 'fecha' ){

      this.fechas.fecha__lte = filtros.fecha__lte ? filtros.fecha__lte.replace('00:00', '23:59:59') : undefined
      this.fechas.fecha__gte = filtros.fecha__gte ? filtros.fecha__gte : undefined

    }else{

      this.fechas = {
        fecha_devolucion__gte: '',
        fecha_devolucion__lte: '',
        fecha__lte: '',
        fecha__gte: ''
      }
    }

    this.factoringService.getReporteCuadroConsolidado(
      filtros.nro_operacion,
      this.fechas.fecha__gte,
      this.fechas.fecha__lte,
      filtros.cliente_nombre,
      filtros.ruc_cliente,
      filtros.aceptante_nombre,
      filtros.ruc_aceptante,
      filtros.tipo_pagador,
      filtros.moneda_excedente_cod,
      this.fechas.fecha_devolucion__gte,
      this.fechas.fecha_devolucion__lte,
      filtros.nro_recaudacion,
      filtros.estado,
      filtros.id,
      page, 
      per_page,
      true
      ).then(res=>{
        this.loadTable(res)
    })
  }

  goToPage({page, per_page, filtros, type}){
    this.filtros = filtros
    this.getReporteCuadroConsolidados({page, per_page, filtros, type})
	}

// Descargar el exel
  async openExcelModal() {
    const result = await Swal.fire({
      title: 'Generar Excel',
      text: "Elige si deseas generar el excel de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    console.log(result)
    if (result.isDenied) {
      this.generarExcel(this.rowData)
    } else if(result.isConfirmed) {
      this.generarExcelCompleto()
    }
  }

  async generarExcelCompleto() {
    // const page_size = 5000
    let page = 1
    let data = []
  
    
    this.generando = true
    while (this.generando) {
      try {
        let res = await this.factoringService.getReporteCuadroConsolidado(
          this.filtros.nro_operacion ? this.filtros.nro_operacion : undefined,
          this.fechas.fecha__gte ? this.fechas.fecha__gte : undefined,
          this.fechas.fecha__lte ? this.fechas.fecha__lte : undefined,
          this.filtros.cliente_nombre ? this.filtros.cliente_nombre : undefined,
          this.filtros.ruc_cliente ? this.filtros.ruc_cliente : undefined,
          this.filtros.aceptante_nombre ? this.filtros.aceptante_nombre : undefined,
          this.filtros.ruc_aceptante ? this.filtros.ruc_aceptante : undefined,
          this.filtros.tipo_pagador ? this.filtros.tipo_pagador : undefined,
          undefined,
          this.fechas.fecha_devolucion__gte ? this.fechas.fecha_devolucion__gte : undefined,
          this.fechas.fecha_devolucion__lte ? this.fechas.fecha_devolucion__lte : undefined,
          this.filtros.nro_recaudacion ? this.filtros.nro_recaudacion : undefined,
          this.filtros.estado ? this.filtros.estado : undefined,
          this.filtros.id ? this.filtros.id : undefined,
          page, 
          500,
          false // showMask
          // this.authService.user.isOficialDeNegocio ? this.authService.user.id : (this.filtros.oficial_negocio ? this.filtros.oficial_negocio : undefined), 
          )

        data = data.concat(res['results'])
        page++
          console.log(page,'ee')
        if (res['page_number'] == res['num_pages']) {
          this.generando = false
          this.generarExcel(data)
        }
      } catch (ex) {
        console.log(ex)
        this.generando = false
      }
    }
  }

  generarExcel(data) {

    let reporte = this.cleanData(data)
    /* generate a worksheet */
    var ws = utils.json_to_sheet(reporte);

    /* add to workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hoja 1");

    /* write workbook and force a download */
    writeFile(wb, `Reporte - ${new Date().toLocaleString()}.xlsx`);
  }

  cleanData(data){
    let columnas = [
      'ejecutivo',
      'fecha',
      'nro_recaudacion',
      'nro_operacion',
      'ruc_cliente',
      'cliente_nombre',
      'ruc_aceptante',
      'aceptante_nombre',
      'moneda_excedente',
      'monto_excedente',
      'concepto_cxc',
      'moneda_cxc',
      'monto_cxc_aplicada',
      'documento_cartera',
      'moneda_documento',
      'monto_documento_cartera_aplicado',
      'monto_devolver',
      'fecha_devolucion',
      'tipo_cambio'
    ]
    let reporte = data.map((item)=>{
      let retorno = {}
      columnas.forEach((col)=>{
        retorno[col] = item[col]
      })
      return retorno
    })
  
    return reporte.map((item)=>{
      for(let i in item){
        if(!item[i]){
          item[i] = ''
        }
  
      }
  
      return item
    })
  }
}