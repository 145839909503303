import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './pages/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UsersCrudComponent } from './pages/users-crud/users-crud.component';
import { NgxSpinnerModule } from "ngx-spinner";

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgSelectModule } from '@ng-select/ng-select';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';

import { SharedModule } from '../../shared/shared.module';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { RecoverPasswordComponent } from './pages/recover-password/recover-password.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterModule } from '@angular/router';

import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { NgxCaptchaModule } from 'ngx-captcha';

@NgModule({
    declarations: [
        LoginComponent,
        UsersCrudComponent,
        ChangePasswordComponent,
        ForgetPasswordComponent,
        RecoverPasswordComponent,
    ],
    imports: [
        FontAwesomeModule,
        JwBootstrapSwitchNg2Module,
        NgxSpinnerModule,
        FormsModule,
        CommonModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        NgxSkeletonLoaderModule,
        NgSelectModule,
        SharedModule,
        RouterModule,
        TooltipModule,
        NgxCaptchaModule,
        UiSwitchModule.forRoot({
                size              : 'medium',
                color             : '#3e50b4',
                checkedTextColor  : "#ffffff",
                uncheckedTextColor: "#ffffff",
                defaultBgColor    : '#6c757d',
                defaultBoColor    : '#3e50b4',
                checkedLabel      : 'Si ',
                uncheckedLabel    : 'No'
            }),
    ]
})
export class AuthModule { }
