import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { ReportesService } from 'app/core/services/reportes.service';

@Component({
  selector: 'app-manage-reporte-modal',
  templateUrl: './manage-reporte-modal.component.html',
  styleUrls: ['./manage-reporte-modal.component.css']
})
export class ManageReporte implements OnInit {

  faTimes = faTimes
  myForm: FormGroup;
  @Input() evaluacionId = 0;
  evaluacion: any;
  usuarios: any[] = [];
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild          : FormBuilder,
    public activeModal        : NgbActiveModal,
    private facService        : FactoringService,
    private reporteService    : ReportesService,
  ) {
    this.initForm();
   }

  ngOnInit(): void {

    let usuariosResponsables = this.reporteService.obtenerResponsablesUsuarios();

    Promise.all([usuariosResponsables]).then(res => {
      this.usuarios = res[0]['results'];
      this.usuarios = this.usuarios.filter(usuario => ![34,108,202].includes(usuario.id));
      //console.log(this.usuarios);
      if (this.evaluacionId) {
        this.obtenerReporte();
      }
    }).catch(err => {
      console.error(err);
    });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre: [null, [Validators.required]],
      url: [null, [Validators.required]],
      permisos: [null, [Validators.required]]
    },
    );
  }

  obtenerReporte() {
    this.facService.obtenerReporte(this.evaluacionId)
    .then(res => {
      this.evaluacion = res;
      this.setForm(res);
    })
    .catch(error => {
      console.error(error);
    });
  }  
  
  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
    if (item.usuarios) {      
      const usuariosExcluidos = item.usuarios.filter(usuario => usuario !== 202);
      this.myForm.get('permisos').setValue(usuariosExcluidos);
    }
  }

  onSubmit() {
    if(this.myForm.valid) {
      const data = this.myForm.value;
      console.log("ESTOS SON MIS DATOS",data);
      console.log("USUARIOS SELECCIONADOS: ",this.myForm.get('permisos').value);
      data.permisos = this.myForm.get('permisos').value;
      //SI SE EDITA EL REPORTE SE AGREGA EL ID 202 QUE SE EXCLUYE EN SETFORM
      if(this.evaluacionId) data.permisos.push(202);
      this.facService.guardarTotalReporte(data,this.evaluacionId)      
      .then(res => {
        //LLAMAR A OTRO SERVICIOS DE TOTAL REPORT USUARIOS PARA AGREGAR EL ID DE USUARIO
        this.successEmit.emit();
        this.activeModal.close(true);
      }).
      catch(error => {
        console.log(error);
        //this.activeModal.close(false);
      });
    }
  }
}
