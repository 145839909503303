import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from '../factoring-datatable/factoring-datatable.component';

@Component({
  selector: 'app-resultado-cavali',
  templateUrl: './resultado-cavali.component.html',
  styleUrls: ['./resultado-cavali.component.css']
})
export class ResultadoCavaliComponent implements OnInit {

  public reporteForm : FormGroup;
	public headerTable: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 50;
	public pageTablePages: number[];
  public searchstring: string = '';
  rowData: any;
  filtros: any;
 
  @Input() operacionDetalleId

  
  constructor( 
    public documentosCarteraService: DocumentosCarteraService,
    private factoringService: FactoringService,
    public formbuild: FormBuilder,
    public authService: AuthService
  ) {}


  ngOnInit(): void {
    this.loadTableColumns()
    this.getCavaliResultado({page:1, per_page:this.pageTablePerPages, filtros:{
      operacion_detalle: this.operacionDetalleId
    }})
  }


  loadTableColumns() {
    this.headerTable = [
      {
        headerName: 'N°',
				pipe:'indexcol',
				sortable: true
      },
      {
        headerName: 'Usuario',
        class: 'text-center',
        field: 'application_user',
        sortable: true
      },
      // {
      //   headerName: 'COD Acción',
      //   class: 'text-center',
      //   field: 'action_code',
      //   sortable: true
      // },
      {
        headerName: 'Etiqueta de código',
        class: 'text-center',
        field: 'action_code_label',
        sortable: true
      },
      {
        headerName: 'Fecha',
        class: 'text-center',
        field: 'action_date',
        sortable: true,
        pipe: 'date'
      },
      {
        headerName: 'Tiempo',
        class: 'text-center',
        field: 'action_time',
        sortable: true
      },
      // {
      //   headerName: 'ID Factura',
      //   class: 'text-center',
      //   field: 'invoice__id',
      //   sortable: true
      // },
      {
        headerName: 'Procesada',
        class: 'text-center',
        field: 'invoice__procesado',
        sortable: true,
        pipe: "checkbox",
        checkDisabled: true,
      },
      {
        headerName: 'RUC Proveedor',
        class: 'text-center',
        field: 'invoice__provider_ruc',
        sortable: true
      },
      {
        headerName: 'N° Serie',
        class: 'text-center',
        field: 'invoice__series',
        sortable: true
      },
      {
        headerName: 'Numeración',
        class: 'text-center',
        field: 'invoice__numeration',
        sortable: true
      },
      {
        headerName: 'RUC Aceptante',
        class: 'text-center',
        field: 'invoice__acq_ruc',
        sortable: true
      },
      {
        headerName: 'Nombre Aceptante',
        class: 'text-center',
        field: 'invoice__acq_full_name',
        sortable: true
      },
      // {
      //   headerName: 'COD Resultado',
      //   class: 'text-center',
      //   field: 'invoice__result_code',
      //   sortable: true
      // },
      {
        headerName: 'Resultado',
        //class: 'text-center',
        field: 'invoice__result_message',
        sortable: true
      },
      // {
      //   headerName: 'Estado de factura',
      //   class: 'text-center',
      //   field: 'invoice__invoice_state',
      //   sortable: true
      // },
      {
        headerName: 'Estado de factura',
        class: 'text-center',
        field: 'invoice__invoice_state_label',
        sortable: true
      }
    ]
  }

  parseData(data) {
    console.log("DA", data)
    return data.map(el=>{
      return {
        id: el?.id,
        operacion_detalle_id: this.operacionDetalleId,
        application_user: el?.application_user,
        action_code: el?.action_code,
        action_code_label: el?.action_code_label,
        action_date: el?.action_date,
        action_time: el?.action_time,
        invoice_id: el?.invoice?.id,
        invoice__procesado: el?.invoice?.procesado,
        invoice__provider_ruc: el?.invoice?.provider_ruc,
        invoice__series: el?.invoice?.series,
        invoice__numeration: el?.invoice?.numeration,
        invoice__acq_ruc: el?.invoice?.acq_ruc,
        invoice__acq_full_name: el?.invoice?.acq_full_name,
        invoice__result_code: el?.invoice?.result_code,
        invoice__result_message: el?.invoice?.result_message,
        invoice__invoice_state: el?.invoice?.invoice_state,
        invoice__invoice_state_label: el?.invoice?.invoice_state_label,
      }
    })
  }
 
  loadTable(data) {
		this.rowData = this.parseData(data.results)
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  getCavaliResultado({page, per_page, filtros}) {
    console.log(filtros)
    this.factoringService.getCavaliResultados(
      page, 
      per_page,
      this.operacionDetalleId
      ).then(res=>{
        this.loadTable(res)
    })
  }


  goToPage({page, per_page, filtros}){
    this.filtros = filtros
    this.getCavaliResultado({page, per_page, filtros})
	}
}