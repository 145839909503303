import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, of } from 'rxjs';
import { ToastService } from '../toast/toast.service';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FacturacionService {

  private apiUrl: string = "";
  private series: string = "factoring/facturas-electronicas/secuencias/";
  private tipos: string = "factoring/facturas-electronicas/tipos/";
  private facturas: string = "factoring/facturas-electronicas/";
  private facturasCompacto: string = "factoring/facturas-electronicas/compacto/";
  private detalles: string = "factoring/facturas-electronicas/detalles/";
  private aprobaciones: string = "factoring/facturas-electronicas/aprobaciones/";
  private conceptos: string = "factoring/liquidaciones/configuraciones/conceptos/";
  private origen: string = "factoring/facturas-electronicas/origenes-pago/"
  private tiposNc: string = "factoring/facturas-electronicas/tipos-nc/";

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
  }

  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();

    this.appService.notifyMe(mensaje, ok)
    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);

  }

  showErrors(error) {
    const err = error.error;
    console.log(error);
    for (const key in err) {
      if (Object.prototype.hasOwnProperty.call(err, key)) {
        const element = err[key];
        console.log(element);

        if (Array.isArray(element) && element.length) {
          element.forEach(item => {
            this.spinnerOff(item, false);
          });
        } else {
          this.spinnerOff(element, false);
        }

      }
    }
  }

  obtenerTipos() {
    const url = this.apiUrl + this.tipos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerSeries(
    page: number = 1,
    page_size: number = 10,
  ) {
    const url = this.apiUrl + this.series +
      `?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  guardarSerie(data, serieId = 0) {
    const url = serieId
      ? this.apiUrl + this.series + `${serieId}/`
      : this.apiUrl + this.series;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (serieId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  /**
   *
   * @param serieId
   */
  obtenerSerie(serieId) {
    const url = this.apiUrl + this.series + `${serieId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerOrigen() {
    const url = this.apiUrl + this.origen ;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerFacturas(
    referencia: string = '',
    tipo_pagador: string = '',
    tipo: string = '',
    estado: string = '',
    asistente_cobranzas: string = '',
    page: number = 1,
    page_size: number = 10,
    cliente: string = '',
    aceptante: string = '',
    pagador__icontains: string = '',
    concepto: string = '',
    fecha_pago__gte: string = '',
    fecha_pago__lte: string = '',
    fecha_facturado__gte: string = '',
    fecha_facturado__lte: string = '',
    operacion: string = '',
    usuario_creador: string = '',
    origen_pago__gte: string = '',
    origen_pago__lte: string = '',
    operacion__oficial_negocio:string = '',
    cuenta_cobrar: string = '',
    numeracion: string = '',
  ) {

    const url = this.apiUrl + this.facturas +
      `?page_size=${page_size}` +
      `&page=${page}` + 
      `&referencia=${referencia}` + 
      `&tipo_pagador=${tipo_pagador}` +
      `&asistente_cobranzas=${asistente_cobranzas}` +
      `&cliente=${cliente}` +
      `&aceptante=${aceptante}` +
      `&tipo__in=${tipo}` + 
      `&estado__in=${estado}` + 
      `&pagador__icontains=${pagador__icontains}` + 
      `&concepto=${concepto}` + 
      `&fecha_pago__gte=${fecha_pago__gte.split(' ')[0]}` + 
      `&fecha_pago__lte=${fecha_pago__lte.split(' ')[0]}` +
      `&fecha_facturado__gte=${fecha_facturado__gte}` +
      `&fecha_facturado__lte=${fecha_facturado__lte.replace('00:00', '23:59:59')}`+
      `&operacion=${operacion}`+
      `&usuario_creador__icontains=${usuario_creador}`+
      `&origen_pago__gte=${origen_pago__gte}`+
      `&origen_pago__lte=${origen_pago__lte}`+
      `&cuenta_cobrar=${cuenta_cobrar}`+
      `&numeracion=${numeracion}`+
      `&operacion__oficial_negocio__in=${operacion__oficial_negocio}`
      ;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).pipe(
        map( (data:any) =>{
          data.results.sort( (a,b)=>( new Date(b.fecha_pago).getTime() - new Date(a.fecha_pago).getTime() ) )
          return data;
        })
      ).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerFacturasCompacto(
    referencia: string = '',
    tipo_pagador: string = '',
    tipo: string = '',
    estado: string = '',
    asistente_cobranzas: string = '',
    page: number = 1,
    page_size: number = 10,
    cliente: string = '',
    aceptante: string = '',
    pagador__icontains: string = '',
    concepto: string = '',
    fecha_pago__gte: string = '',
    fecha_pago__lte: string = '',
    fecha_facturado__gte: string = '',
    fecha_facturado__lte: string = '',
    operacion: string = '',
    usuario_creador: string = '',
    origen_pago__gte: string = '',
    origen_pago__lte: string = ''
  ){
    const url = this.apiUrl + this.facturasCompacto +
    `?page_size=${page_size}` +
    `&page=${page}` + 
    `&referencia=${referencia}` + 
    `&tipo_pagador=${tipo_pagador}` +
    `&asistente_cobranzas=${asistente_cobranzas}` +
    `&cliente=${cliente}` + `&aceptante=${aceptante}` +
    `&tipo_in=${tipo}` + 
    `&estado__in=${estado}` + 
    `&pagador__icontains=${pagador__icontains}` + 
    `&concepto=${concepto}` + 
    `&fecha_pago__gte=${fecha_pago__gte.split(' ')[0]}` + 
    `&fecha_pago__lte=${fecha_pago__lte.split(' ')[0]}` +
    `&fecha_facturado__gte=${fecha_facturado__gte}` +
    `&fecha_facturado__lte=${fecha_facturado__lte.replace('00:00', '23:59:59')}`+
    `&operacion=${operacion}`+
    `&usuario_creador__icontains=${usuario_creador}`+
    `&origen_pago__gte=${origen_pago__gte}`+
    `&origen_pago__lte=${origen_pago__lte}`
    ;

  return new Promise((res, ref) => {
    this.spinnerOn();

    this.http.get(url).pipe(
      map( (data:any) =>{
        data.results.sort( (a,b)=>( new Date(b.fecha_pago).getTime() - new Date(a.fecha_pago).getTime() ) )
        return data;
      })
    ).subscribe(
      (response) => {
        this.spinnerOff();
        res(response);
      },
      (err) => {
        this.spinnerOff("La operación falló", false);
        ref(err);
      }
    );
  });

  }

  obtenerFacturasPorRecaudacion(
    recaudacion__in: string = '',
    tipo: string = '',
    estado__in: string = '',
    page_size: number = 100,
    page: number = 1,
  ){
    const url = this.apiUrl + this.facturasCompacto +
    `?page_size=${page_size}` +
    `&page=${page}` +
    `&tipo=${tipo}` +
    `&recaudacion__in=${recaudacion__in}` +
    `&estado__in=${estado__in}`;

  return new Promise((res, ref) => {
    this.spinnerOn();

    this.http.get(url).pipe(
      map( (data:any) =>{
        data.results.sort( (a,b)=>( new Date(b.fecha_pago).getTime() - new Date(a.fecha_pago).getTime() ) )
        return data;
      })
    ).subscribe(
      (response) => {
        this.spinnerOff();
        res(response);
      },
      (err) => {
        this.spinnerOff("La operación falló", false);
        ref(err);
      }
    );
  });

  }

  obtenerFactura(facturaId) {
    const url = this.apiUrl + this.facturas + `${facturaId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  mofificarFactura(facturaId, data) {
    const url = this.apiUrl + this.facturas + `${facturaId}/`;
    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.put(url, data).subscribe(
        (response) => {
          this.spinnerOff("El comprobante fue modificado con éxito.");
          res(response);
        },
        (err) => {
          this.spinnerOff();
          this.showErrors(err);
          ref(err);
        }
      );
    });
  }

  obtenerFacturasDetalles(
    factura: string = '',
    cuenta_cobrar: string = '',
    page: number = 1,
    page_size: number = 10
  ) {

    const url = this.apiUrl + this.detalles +
      `?page_size=${page_size}` + `&page=${page}` + `&factura=${factura}` + `&cuenta_cobrar=${cuenta_cobrar}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });

  }

  editarFacturaDetalles(data, id) {
    const url = this.apiUrl + this.detalles + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.put(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  facturar(data) {
    const url = this.apiUrl + this.facturas;

    // return new Promise((res, ref) => {
    //   this.spinnerOn();

    //   let arrayOfData = [];
    //   data.forEach(element => {
    //     arrayOfData.push(this.http.post(url + `${element.id}/facturar/`, element));
    //   });

    //   forkJoin(arrayOfData).subscribe(response => {
    //     this.spinnerOff('El proceso de facturación se ejecutó correctamente');
    //     res(response);
    //   }, err => {
    //     const errorFormat = 'Ocurrió un error, el formato del documento no es el adecuado';
    //     const error = (err.error['linea disponible']) ? err.error['linea disponible'] : errorFormat;
    //     this.spinnerOff();
    //     this.showErrors(err);
    //     ref(err);
    //   });

    // });

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.post(url + `${data.id}/facturar/`, data).subscribe(
        (response) => {
          this.spinnerOff('El proceso de facturación se ejecutó correctamente');
          res(response);
        },
        (err) => {
          this.spinnerOff();
          this.showErrors(err);
          ref(err);
        }
      );
    });
  }

  obtenerAprobadores(facturaId) {
    const url = this.apiUrl + this.aprobaciones;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  crearNotaCredito(facturaId, data) {
    const url = this.apiUrl + this.facturas + `${facturaId}/nota-credito/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          let error = err.error.estado;
          if (error) {this.spinnerOff(error, false)} 
          else {this.spinnerOff("La operación falló", false)};
          ref(err);
        }
      );
    });
  }

  aprobarNC(data) {
    const url = this.apiUrl + this.aprobaciones;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff("La Nota de crédito fue aprobada correctamente");
          res(response);
        },
        (err) => {
          if (err.error['factura']) {
            this.spinnerOff("Se tiene que asignar un 'tipo' a la nota de crédito", false);
          }
          ref(err);
        }
      );
    });
  }

  rechazarNC(facturaId) {
    const url = this.apiUrl + this.facturas + `${facturaId}/rechazar/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.post(url, {}).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obenerEstados(
    page: number = 1,
    page_size: number = 1000
  ) {

    const url = this.apiUrl + this.facturas + 'estados/' +
      `?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerConceptos(){

    const url = this.apiUrl + this.conceptos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });

  }

  //DESCARGAR PDF O XML FACTURAS
  downloadPdfXml( id, type ){

    const url = this.apiUrl + this.facturas + id + '/obtener_pdf_or_xml/' +  `?tipo_archivo=${type}`;

    return new Promise( (res, ref) =>{
      const httpOptions = {
        responseType: 'blob' as 'json'
      };

      this.spinnerOn();
      this.http.get( url, httpOptions ).subscribe(
        (resp)=>{
          this.spinnerOff();
          res( resp )
        },
        (err)=> {

          this.http.get( url).subscribe({
            next:()=> console.log(),
            error: ( err )=>{ 
              if( err.error.detail ){
                this.spinnerOff( err.error.detail , false);
              }else{
                this.spinnerOff( 'Ha ocurrido un error inesperado' , false);
              }
            }
        
          }
            
          )
          
          ref(err);
        }
      )

    })


  }

  //TODO obtener tipo de NC
  getTipoNc(page:number = 1, page_size:number = 10, habilitado:any = ''){
    
    const url = `${this.apiUrl}${this.tiposNc}?page=${page}&page_size=${page_size}&habilitado=${habilitado}`;

    return new Promise( (resolve, reject )=>{
      this.spinnerOn();
      this.http.get( url ).
        subscribe( (resp)=>{
          this.spinnerOff();
          resolve(resp)

        }, err=>{

          this.spinnerOff( 'Ha ocurrido un error inesperado' , false);
          reject(err)
        })

    })

  }
  //TODO EDITAR TIPO NC
  putTiposNc(id:any, body:any){

    const url = `${this.apiUrl}${this.tiposNc}${id}/`;
    this.spinnerOn();
    return this.http.put( url, body )
      .pipe( 
        map( resp=>{
          this.spinnerOff();
          return resp;
        }),
        catchError( err=>{
          this.spinnerOff( 'Ha ocurrido un error inesperado' , false);
          return of(err)
        })
       )

  }

}
