import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin, concat, of } from 'rxjs';
import { catchError, toArray } from 'rxjs/operators';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import * as XLSX from 'xlsx'

import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { AbonosService } from 'app/core/services/abonos/abonos.service'
import { ImportarComponent } from '../../modals/importar/importar.component';
import { AnularComponent } from '../../modals/anular/anular.component';
import { AddRecaudacionModalComponent } from 'app/modules/recaudacion/modals/add-recaudacion-modal/add-recaudacion-modal.component';
import { DescartarAplicacionComponent } from 'app/modules/recaudacion/modals/descartar-aplicacion/descartar-aplicacion.component';
import { ObservacionesComponent } from '../../modals/observaciones/observaciones.component';


@Component({
  selector: 'app-abonos',
  templateUrl: './abonos.component.html',
  styleUrls: ['./abonos.component.css']
})
export class AbonosComponent implements OnInit {
  @ViewChild('modalPagadorAplicacion', { static: false }) private modalPagadorAplicacion

  estados = []
  monedas = []
  exportando = false
  tblRows: any[] = []
  tblHeaders: columnHeader[]
  tblTotalRows: number
  tblPages: number[]
  tblActualPage: number = 1
  tblPerPage: number = 10
  tblFiltros = {}
  tblAcciones = []
  pagadoresAplicacion = []

  constructor(
    public router: Router,
    private abonosService: AbonosService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    forkJoin({
      estados: this.abonosService.getEstados(),
      monedas: this.abonosService.getMonedas(),
    }).subscribe(({ estados, monedas }) => {
      this.estados = estados
      this.monedas = monedas
      this.initTablaAbonos()
    })
  }

  loadDataTablaAbonos({ page = this.tblActualPage, per_page = this.tblPerPage, filtros = this.tblFiltros }) {
    this.spinner.show()
    Object.keys(filtros).filter(field => field.includes('fecha')).forEach(field => filtros[field] = filtros[field].split(' ')[0])
    this.tblFiltros = filtros
    this.abonosService.getAbonos(Object.assign({ page, page_size: per_page }, filtros)).subscribe(
      (data: any) => {
        this.tblRows = data.results.map(e => Object.assign(e, {'origen_identificado': e['origen_identificado'] ? 'Si' : 'No'}))
        this.tblTotalRows = data.count
        this.tblPages = Array.from(Array(data.num_pages).keys())
        this.tblPerPage = data.per_page
        this.tblActualPage = data.page_number
      },
      (res: any) => console.log(res)
    ).add(() => this.spinner.hide())
  }

  async exportarDataTablaAbonos() {
    const lote = 500

    this.exportando = true
    this.abonosService.getAbonos(Object.assign({ 'page_size': 1 }, this.tblFiltros)).subscribe(
      data => {
        let pages = Math.ceil(data['count'] / lote)
        let pages$ = Array.from({ length: pages }).map((_, i) => i + 1).map(
          page => this.abonosService.getAbonos(Object.assign({ page, 'page_size': lote }, this.tblFiltros))
        )
        concat(...pages$).pipe(toArray()).subscribe((data: any) => {
          data = data.map((x: any) => x.results).flat(1)
          data = data.map(x => {
            let row = {}
            for (let header of this.tblHeaders) {
              row[header['headerName']] = x[header['field']]
            }
            return row
          })
          let ws = XLSX.utils.json_to_sheet(data)
          let wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, 'Recaudaciones')
          XLSX.writeFile(wb, 'Reporte recaudaciones.xlsx')
        }).add(() => this.exportando = false)
      },
      res => {
        console.log(res)
        this.exportando = false
      }
    )
  }

  openModalImportar() {
    const modal = this.modalService.open(ImportarComponent, {
      size: "xl",
    })

    modal.result.then(
      (result) => {
        if (result) {
          this.aplicarMatchPerfecto(result).add(() => {
            this.loadDataTablaAbonos({})
          })
        }
      },
      (reason) => { },
    )
  }

  openModalAnular(abono) {
    const modal = this.modalService.open(AnularComponent, {
      size: "md",
    })
    modal.componentInstance.abono = abono
    modal.result.then(
      (result) => {
        if (result) this.loadDataTablaAbonos({})
      },
      (reason) => { },
    )
  }

  async openModalAplicar(abono) {
    let pagador = null
    let aplicacion = null

    try {
      this.spinner.show()
      let aplicaciones = await this.abonosService.getAplicaciones({abono: abono.id, estado: 1}).toPromise()
      if (aplicaciones.length > 0) {
        aplicacion = aplicaciones[0]
      }
    } finally {
      this.spinner.hide()
    }
    
    if (!aplicacion) {
      pagador = await this.getPagadorAplicacion(abono)
      if (!pagador) return
    }

    const modal = this.modalService.open(AddRecaudacionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
      beforeDismiss() {
        return false
      },
    });

    modal.componentInstance.abono = Object.assign({'pagador': pagador}, abono)
    modal.componentInstance.recaudacionId = aplicacion?.id

    modal.result.then(result => { }).catch(reason => { }).then(async () => {
      let recaudacion = modal.componentInstance.recaudacion
      if (recaudacion && recaudacion.id) {
        if (recaudacion.estado == 1) { // REGISTRADO
          let modalDescartarAplicacion = this.modalService.open(DescartarAplicacionComponent, {
            size: 'md',
            beforeDismiss() {
              return false
            },
          })
          modalDescartarAplicacion.componentInstance.recaudacion = recaudacion
          await modalDescartarAplicacion.result
        }
        this.loadDataTablaAbonos({})
      }
    })
  }

  aplicarMatchPerfecto(abonos) {
    const observables = abonos.map((e) => {
      return this.abonosService.aplicarMatch({abonos: [e.id]}).pipe(
        catchError((res) => of(false))
      )
    })

    this.spinner.show()

    let procesados = 0, pendientes = observables.length
    return concat(...observables).subscribe(
      (data) => {
        pendientes--
        procesados++
        this.abonosService.toast.success(`Aplicando match perfecto ${observables.length - pendientes}/${observables.length}`)
        if (data === false) procesados--
        if (pendientes <= 0) this.spinner.hide()
        if (pendientes <= 0 && procesados > 0) {
          return true
        }
      },
      (res) => {
        this.spinner.hide()
        this.abonosService.toast.warning('Ocurrió un error inesperado')
        if (procesados > 0) {
          return true
        }
      }
    )
  }

  colorRow(row) {
    return row['fecha_anulacion_contable'] ? '#f003' : ''
  }

  private async getPagadorAplicacion(abono) {
    let pagador = null
    this.pagadoresAplicacion = []
    this.spinner.show()
    try {
      this.pagadoresAplicacion = await this.abonosService.getPagadores({ 'ruc__in': abono['pagador_ruc'] }, undefined, false).toPromise()
    } finally {
      this.spinner.hide()
    }
    if (this.pagadoresAplicacion.length == 0) {
      this.abonosService.toast.warning(`No se encontró un pagador con el ruc "${abono['pagador_ruc']}"`)
    } else if (this.pagadoresAplicacion.length == 1) {
      pagador = this.pagadoresAplicacion[0]
    } else {
      let seleccionado: any = await new Promise((resolve, _) => {
        this.modalService.open(
          this.modalPagadorAplicacion,
          { size: 'md', }
        ).result
          .then(result => resolve(result == false ? undefined : result))
          .catch(reason => resolve(undefined))
      })
      if (seleccionado == undefined) {
        this.abonosService.toast.warning(`No se seleccionó un pagador para el ruc "${abono['pagador_ruc']}"`)
      } else {
        pagador = seleccionado
      }
    }
    return pagador
  }

  public openModalObservaciones(abono) {
    const modal = this.modalService.open(ObservacionesComponent, {
      size: "md",
    })
    modal.componentInstance.abono = abono
    modal.result.then(
      (result) => {
        if (result) this.loadDataTablaAbonos({})
      },
      (reason) => { },
    )
  }

  private initTablaAbonos() {
    this.tblAcciones = [
      {
        tooltip: 'Anular', icon: 'fa fa-close', class: 'btn-danger',
        click: row => this.openModalAnular(row),
        visible: row => !row['fecha_anulacion_contable'] && row['monto'] == row['saldo']
      },
      {
        tooltip: 'Aplicar', icon: 'fa fa-usd', class: 'btn-primary',
        click: row => this.openModalAplicar(row),
        visible: row => !row['fecha_anulacion_contable'] && row['fecha_identificacion_contable'] && Number(row['saldo']) > 0
      },
      {
        tooltip: 'Ver Observaciones Match Perfecto', icon: 'fa fa-eye', class: 'btn-warning',
        click: row => this.openModalObservaciones(row),
        visible: row => !!row['observaciones'] && row['monto'] == row['saldo']
      },
    ]
    this.tblHeaders = [
      {
        headerName: 'ID',
        field: 'id',
        class: 'text-center',
        filterable: true,
        filterProp: 'id',
        filterInput: true,
      },
      {
        headerName: 'Fecha carga',
        field: 'fecha_carga',
        pipe: 'date',
        class: 'text-center',
        filterable: true,
        filterProp: 'fecha_carga',
        filterDate: true,
      },
      {
        headerName: 'Fecha banco',
        field: 'fecha',
        pipe: 'date',
        class: 'text-center',
        filterable: true,
        filterDate: true,
      },
      {
        headerName: 'Fecha contable',
        field: 'fecha_contable',
        pipe: 'date',
        class: 'text-center',
        filterable: true,
        filterProp: 'fecha_contable',
        filterDate: true,
      },
      {
        headerName: 'Pagador',
        field: 'pagador_txt',
        filterable: true,
        filterProp: 'pagador_txt',
        filterInput: true,
      },
      {
        headerName: 'Moneda',
        field: 'moneda_descripcion',
        class: 'text-center',
        filterable: true,
        filterProp: 'moneda',
        filterSelectItems: this.monedas,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Monto',
        field: 'monto',
        class: 'text-right',
        filterable: true,
        filterProp: 'monto',
        filterRange: true
      },
      {
        headerName: 'Saldo',
        field: 'saldo',
        class: 'text-right',
        filterable: true,
        filterProp: 'saldo',
        filterRange: true
      },
      {
        headerName: 'Banco',
        field: 'banco_txt',
        filterable: true,
        filterProp: 'banco_txt',
        filterInput: true,
      },
      {
        headerName: 'Estado',
        field: 'estado_descripcion',
        class: 'text-center',
        filterable: true,
        filterProp: 'estado',
        filterSelectItems: this.estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'O.I',
        field: 'origen_identificado',
        class: 'text-center',
        filterable: true,
        filterProp: 'origen_identificado',
        filterSelectItems: [
          {id: 1, descripcion: 'Origen identificado'},
          {id: 0, descripcion: 'Origen NO identificado'},
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Es auto Pago',
        field: 'auto_pago',
        class: 'text-center',
        filterable: true,
        filterProp: 'auto_pago',
        filterSelectItems: [
          {id: 1, descripcion: 'Si'},
          {id: 2, descripcion: 'No'},
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
        
      },
    ]
    this.loadDataTablaAbonos({})
  }
}
