import { Component, OnInit } from '@angular/core';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-cierre-periodo',
  templateUrl: './cierre-periodo.component.html',
  styleUrls: ['./cierre-periodo.component.css']
})
export class CierrePeriodoComponent implements OnInit {

  active = 1;
  public faSyncAlt:any = faSyncAlt;
  public refreshOperativo = false;
  public refreshContable = false;

  constructor() { }

  ngOnInit(): void {
  }

  changeRefresh(){
    switch (this.active) {
      case 1:
        this.refreshOperativo = !this.refreshOperativo;
        break;
      case 2:
        this.refreshContable = !this.refreshContable;
        break;
    }

  }

}
