import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FacturacionService } from '../../../../core/services/facturacion/facturacion.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { columnHeader } from '../../../../shared/factoring-datatable/factoring-datatable.component';
import { NotaCreditoModalComponent } from '../../modals/nota-credito-modal/nota-credito-modal.component';
import { FacturaModalComponent } from '../../modals/factura-modal/factura-modal.component';

@Component({
  selector: 'app-facturas-comprobantes',
  templateUrl: './facturas-comprobantes.component.html',
  styleUrls: ['./facturas-comprobantes.component.css']
})
export class FacturasComprobantesComponent implements OnInit {

  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public searchstring: string = '';
  listSelect: any[] = [];

  public todoSelect: boolean = false;
  public preselected: any[] = [];

  @Input() tableSelectable:boolean = false;

	@Input() set refresh(value) {
    this.tableSelectable = false;
		this.obtenerFacturas();
	}

  @Output() ocultarBtn = new EventEmitter();


  constructor( private facturacionService: FacturacionService,
    private modalService: NgbModal,
    private authService: AuthService) {

      this.user = this.authService.user;

    }

  ngOnInit(): void {

    this.ocultarBtn.emit(true)

    // Se trae los datos de los filtros ( ESTADOS, TIPOS, CONCEPTO )
    forkJoin({
      estados: this.facturacionService.obenerEstados(),
      tipos: this.facturacionService.obtenerTipos(),
      concepto: this.facturacionService.obtenerConceptos()
    }).pipe( catchError( err=> of(err) ) ).subscribe( ({ estados, tipos, concepto })=>{

      this.initVars(estados['results'],tipos['results'], concepto['results']);

    })

    this.obtenerFacturas();

  }

  obtenerFacturas(
    page = 1,
    page_size = 10,
    referencia = '',
    tipo_pagador = '',
    tipo = '',
    estado = '1,4,6',
    asistente_cobranzas = '',
    pagador_nombre = '',
    concepto = '',
    fecha_pago__gte = '' ,
    fecha_pago__lte = '',
    fecha_facturado__gte = '' ,
    fecha_facturado__lte = '',
    operacion = '',
    usuario_creador = '',
    cuenta_cobrar = ''
  ) {
    // this.user.isAsistenteCobranzas ? this.user.id : ''
    this.facturacionService.obtenerFacturas(
      referencia, tipo_pagador, tipo, estado,
      '',
      page, page_size, '', '', pagador_nombre, concepto , fecha_pago__gte, fecha_pago__lte, '', '', operacion, usuario_creador, '', '-1',  this.user.isOficialDeNegocio || this.user.isLiderDeEquipo ? this.user.id : '', cuenta_cobrar
    ).then((resp:any) => {
      this.loadTable(resp);
    }, err => {
      console.error(err);

    });
  }

  loadTable(data) {
    this.rowData = data.results
    this.pageTableActual = data.page_number
    this.pageTableTotal = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }

  initVars(estados = [], tipos = [], concepto = []) {
    let columns = [
      {
				headerName: '',
				field: '',
				sortable: true,
			},
      {
				headerName: 'Creado Por',
				field: 'usuario_creador',
				class: 'text-center',
				sortable: true,
        filterable: true,
        filterProp: 'usuario_creador',
        filterInput: true,
			},
      {
        headerName: 'Nro CxC',
        field: 'cuenta_cobrar',
        class: 'text-center',
        sortable: true,
        filterable: true,
				filterProp: 'cuenta_cobrar',
				filterInput: true,
      },
      {
        headerName: 'Tipo Doc.',
        field: 'tipo_descripcion',
        class: 'text-center',
        sortable: true,
        filterable: true,
        filterProp: 'tipo',
        filterSelectItems: tipos,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Cobrar a',
        field: 'pagador_nombre',
        class: 'text-center',
        sortable: true,
        filterable: true,
        filterProp: 'pagador_nombre',
        filterInput: true,
      },
      {
        headerName: 'Fecha de Pago',
        field: 'fecha_pago',
        class: 'text-center',
        pipe: 'date',
        sortable: true,
        filterable: true,
        filterDate: true,
      },
      {
        headerName: 'Concepto',
        field: 'concepto',
        class: 'text-center',
        sortable: true,
				filterable: true,
				filterProp: 'concepto',
				filterSelectItems: concepto,
				filterItemsProps: {
					value: 'id',
					label: 'descripcion'
				},
      },
      {
        headerName: 'Monto',
        field: 'monto',
        class: 'text-center',
        pipe: 'currency',
        moneda: 'moneda',
        sortable: true,
      },
      {
        headerName: 'IGV',
        field: 'igv',
        class: 'text-center',
        pipe: 'currency',
        moneda: 'moneda',
        sortable: true,
      },
      {
        headerName: 'Total',
        field: 'total',
        class: 'text-center',
        pipe: 'currency',
        moneda: 'moneda',
        sortable: true,
      },
      {
        headerName: 'Estado',
        field: 'estado_descripcion',
        filterProp: 'estado',
        sortable: true,
        class: 'text-center',
        filterable: true,
        filterSelectItems: estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
				headerName: 'Operación',
				field: 'operacion',
				class: 'text-center',
        sortable: true,
        filterable: true,
				filterProp: 'operacion',
				filterInput: true,
			},
      {
        headerName: 'Glosa',
        field: 'glosa',
        class: 'text-center',
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {
    let listEstados = (filtros.estado) ? `${filtros.estado}` : '1,4,6';
    this.obtenerFacturas(page, per_page, '', '', filtros.tipo, listEstados, '', filtros.pagador_nombre, filtros.concepto,
    filtros.fecha__gte,
    filtros.fecha__lte, '' , '', filtros.operacion, filtros.usuario_creador, filtros.cuenta_cobrar);

    this.todoSelect = false;
    this.preselected = [];
    this.listSelect = [];
  }

  tableEditEvent(row) {

    let modalRef;

    if (row.tipo == 2) {
      modalRef = this.modalService.open(NotaCreditoModalComponent, {
        size: 'xl',
        backdrop: 'static'
      });
    } else {
      modalRef = this.modalService.open(FacturaModalComponent, {
        size: 'xl'
      });
    }

    // const modalRef = this.modalService.open(FacturaModalComponent, {
    //   size: 'xl'
    // });

    modalRef.componentInstance.facturaIn = row;

    modalRef.result.then((result) => {
      console.log(result);
      if (result) {
        this.obtenerFacturas();
      }
    }, (reason) => {
      console.log(reason);
    });
  }

  destroy(row) {

  }

  checkEvent(row) {
    this.listSelect = row;
    this.evalCheck();
  }

  evalCheck(){
    this.todoSelect = this.listSelect.length == this.rowData.length
  }
  
  async enviarDacturas() {

    for (const element of this.listSelect) {
      const resp = await this.facturacionService.facturar(element)
      console.log(resp);
    }

    this.obtenerFacturas();
  }

  toggleText(){
    this.todoSelect = !this.todoSelect
    this.toggleCheckAll()
  }

  toggleCheckAll(){
    this.listSelect = []
      if(this.todoSelect){
          this.preselected = this.rowData.map((item) => { return item.id })
          this.listSelect = this.rowData
      } else {
          this.preselected = []
      }
  }

  get canAdd() {
		const perfilesPermitidos = [
		  this.authService.perfil.perfilAdmin,
		  this.authService.perfil.perfilJefeDeOperacionesID,
		//   this.authService.perfil.perfilAnalistaOperacionesID,
		  this.authService.perfil.perfilAsistenteCobranzasID,
		//   this.authService.perfil.perfilAnalistaOperacionesID,
		  this.authService.perfil.perfilGerenciaComercialID,
		  this.authService.perfil.perfilGerenciaGeneralID,
		]
	
		return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
	}

}
