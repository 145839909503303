import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { GrupoEconomicoService } from 'app/core/services/clientes/grupo-economico.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { AddGrupoEconomicoModalComponent } from '../../modals/add-grupo-economico-modal/add-grupo-economico-modal.component';
import { functions } from '../../../../core/helpers/functions';

@Component({
  selector: 'app-grupos-economicos',
  templateUrl: './grupos-economicos.component.html',
  styleUrls: ['./grupos-economicos.component.css']
})
export class GruposEconomicosComponent implements OnInit {
  faChartPie = faChartPie;
  faSyncAlt  = faSyncAlt;
  faPlus     = faPlus;
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = '';
  public canCreate: boolean = false;
  canEdit = true;

  constructor(
    private grupoService: GrupoEconomicoService,
    private router: Router,
    public modalService: NgbModal,
    public authService: AuthService,
  ) {
    this.user = authService.user;
  }

  ngOnInit() {
    this.initVars();
    this.obtenerGrupos();
  }

  obtenerGrupos(page = 1, page_size = 10) {
    this.grupoService.obtenerGruposEconomicos(page, page_size)
      .then(res => {
        this.loadTable(res);
      }).catch(error => {
        console.error(error);
      });
  }

  loadTable(data) {
    this.rowData = functions.indextable( data )
    // this.rowData = data.results
    this.pageTableActual = data.page_number
    this.pageTableTotal = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }

  initVars() {
    let columns = [
      {
        headerName: 'N°',
        field: 'index',
        sortable: true,
      },
      {
        headerName: 'Nombre ',
        field: 'nombre',
        class: 'text-center',
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Monto de Línea",
        field: "monto_linea",
        pipe: "currency",
        moneda: "moneda_linea",
      },
    ];

    this.columnDefs = columns;
  }

  crear() {
    const modalRef = this.modalService.open(AddGrupoEconomicoModalComponent, {
      size: 'lg'
    });

    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
      console.log(result);
      this.obtenerGrupos();
    },
      error => {

      });

  }

  tableEditEvent(row) {

    const modalRef = this.modalService.open(AddGrupoEconomicoModalComponent, {
      size: 'lg'
    });

    modalRef.componentInstance.grupoId = row.id;

    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
      console.log(result);
      this.obtenerGrupos();
    },
      error => {

      });
  }

  goToPage({ page, per_page, filtros }) {
    this.obtenerGrupos(page, per_page);
  }

  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {

    });

    modalRef.componentInstance.title = 'Remover Grupo Económico';
    modalRef.componentInstance.message = '¿Seguro que desea remover el grupo económico?';

    modalRef.result.then((result) => {
      if (result) {
        this.grupoService.eliminarGrupoEconomico(row.id)
          .then(result => {
            this.obtenerGrupos();
          })
          .catch(error => {
            console.error(error);
          });
      }
    }, (reason) => {
      console.log(reason);
    });
  }

}
