import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FactoringComponent } from './pages/factoring/factoring.component';
import { FactoringComiteComponent } from './pages/factoring-comite/factoring-comite.component';
import { AdminLayoutComponent } from '../../layouts/admin/admin-layout.component';
import { CompletarSolicitudComponent } from '../config/pages/completar-solicitud/completar-solicitud.component';
import { VerificationComponent } from './pages/verification/verification.component';
import { BandejaPagosComponent } from './pages/bandeja-pagos/bandeja-pagos.component';
import { AuthGuard } from 'app/core/guards/auth.guard';


const routes: Routes = [
    {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        // canActivateChild: [AuthGuard],
        data: {
            breadcrumb: 'operaciones'
        },
        children: [
            {
                path: 'operaciones-comite',
                component: FactoringComiteComponent,
                data: {
                    breadcrumb: 'Operaciones de Comité'
                }
            },
            {
                path: 'operaciones',
                component: FactoringComponent,
                data: {
                    breadcrumb: 'Operaciones de Factoring'
                }
            },
            {
                path: 'operaciones/estacion/:estacionId/:oficial/:lider/:moneda',
                component: FactoringComponent,
                data: {
                    breadcrumb: 'Operaciones de Factoring'
                }
            },
            {
                path: 'operaciones/:id',
                component: CompletarSolicitudComponent,
                data: {
                    breadcrumb: 'Completar solicitud de factoring'
                }
            },
            {
                path: 'verifications',
                component: VerificationComponent,
                data: {
                    breadcrumb: 'Verificación de documentos'
                }
            },      
            {
                path: 'operaciones-comite/:id',
                component: CompletarSolicitudComponent,
                data: {
                    breadcrumb: 'Completar solicitud de factoring'
                }
            }, 
            {
                path: 'ordenes-pagos',
                component: BandejaPagosComponent,
                data: {
                    breadcrumb: 'Órdenes de Pago'
                }
            },   
            {
                path: 'ordenes-pagos/:id',
                component: CompletarSolicitudComponent,
                data: {
                    breadcrumb: 'Orden de Pago'
                }
            },
                                 
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AplicacionEvaluacionRiesgosRoutingModule { }
