import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { ReportesService } from 'app/core/services/reportes.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { utils, writeFile } from 'xlsx';
import Swal from 'sweetalert2';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { functions } from '../../../../core/helpers/functions';
import { AuthService } from 'app/core/services/auth/auth.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManageReporte } from 'app/modules/configuration/modals/manage-reporte-modal/manage-reporte-modal.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-reporte-total-report',    //POSIBLE ERROR
  templateUrl: './total-report.component.html',
  styleUrls: ['./total-report.component.css']
})

export class TotalReportComponent implements OnInit {

  faPlus=faPlus;
	faChartPie=faChartPie;
	faSyncAlt=faSyncAlt;

  public reporteForm       : FormGroup;
  public rowData           : any[] = [];
  public loadingTable      : boolean = false;
  public columnDefs        : columnHeader[];
	public pageTableActual   : number;
	public pageTableTotal    : number;
	public pageTablePerPages : number = 10;
	public pageTablePages    : number[];
  //public searchstring: string = '';
  public user: any;

  public filtros: any = {
    nombre: undefined
  };

  constructor( 
    private factoringService: FactoringService,
    public formbuild        : FormBuilder,
    public authService      : AuthService,

    public modalService     : NgbModal,
    public repservice:  ReportesService,

  ) {
    this.user = this.authService.user;
  }

  ngOnInit(): void {
    
    let page=1,per_page=10;
    //console.log(this.user.perfil,'perfil',this.authService.perfil);
    
    this.obtenerTotalReport(page,per_page,this.filtros);
    //this.obtenerVariablesFiltros()
  }

  obtenerTotalReport(page = 1, page_size = 10,filtros) {
    let registros = this.factoringService.obtenerReporteTotal(page,page_size,filtros);
    //let usuarios = this.repservice.obtenerResponsablesUsuarios();
    
    //console.log(usuarios);

    Promise.all([registros]).then((res :any[])=>{
      let registros  = res[0];
      //let roles = res[1]['results'];
      this.initVars();
      this.loadTable(registros);
    });
  }

  loadTable(data) {

    this.rowData = functions.indextable( data )
    //this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }

  initVars() {
    let columns = [
      {
        headerName: 'N°',
        field     : 'index' ,
        sortable  : true,
      },
      {
        headerName: 'Nombre', 
        field     : 'nombre',
        class     : 'text-center',
        sortable  : true,
        filterable: true,
        filterProp: 'nombre',
        filterInput: true,
      },
     /* {
        headerName: 'URL', 
        field     : 'url',
        class     : 'text-center hidden-column',
        //sortable  : true,
        hide: true,
        //visible: false,
        // filterable: true,
        // filterProp: 'descripcion',
        // filterInput: true,
      },*/
    ];
  
    this.columnDefs = columns;
  }

  crear(){
    const modalRef = this.modalService.open(ManageReporte, {
    });

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerTotalReport(1,10,this.filtros);
		},
		error => {

		});
  }

  tableEditEvent(row) {
    const modalRef = this.modalService.open(ManageReporte, {
    });

    modalRef.componentInstance.evaluacionId = row.id;

    modalRef.componentInstance

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			this.obtenerTotalReport(1,10,this.filtros);
		},
		error => {

		});
  }

  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Reporte';
		modalRef.componentInstance.message = '¿Seguro que desea remover el Reporte?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.factoringService.eliminaDetalle(row.id)
			.then(result => {
			this.obtenerTotalReport(1,10,this.filtros);
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  linkEvent(row){
    //ABRE LA PÁGINA EN LA MISMA VENTANA
    //window.location.href = row.url;
    //ABRE LA PÁGINA EN UNA NUEVA PESTAÑA
    window.open(row.url,'_blank');
  }

  goToPage({page, per_page, filtros}){
    this.obtenerTotalReport(page, per_page,filtros);
	}

  // Descargar el exel 
    // async openExcelModal() {
    //   this.generarExcelCompleto();
    // }
  
  
    // async generarExcelCompleto() {
    //   const page_size = 2000
    //   let page = 1
    //   let data = []
  
    //   this.generando = true
    //   while (this.generando) {
    //     try {
    //       let res = await  this.factoringService.obtenerReporteTotal(
    //         page, 
    //         page_size,
    //         this.filtros
    //         )
  
    //       data = data.concat (res['results'] )
    //       page++
  
    //       if (res['page_number'] == res['num_pages']) {
    //         this.generando = false
    //         this.generarExcel(data,this.columnasAMostrar)
    //       }
    //     } catch (ex) {
    //       console.log(ex)
    //       this.generando = false
    //     }
    //   }
    // }
  
    // generarExcel(data,columnasAMostrar) {
    //     // Se deben definir las columnas que se van a mostrar
    //     data = data.map((item)=>{
    //       let obj = {}
    //       this.columnasAMostrar.forEach((col)=>{
    //         obj[col] = item[col]
    //       })
    //       return obj
    //     })
    //     var header = this.columnasAMostrar;
    //     var columns = header.length - 1
      
    //     const wb = utils.book_new();
        
    //     var Heading = [
    //       [
    //         "NOMBRE"
    //       ],
    //     ];
        
    //     // const ws = utils.json_to_sheet(data, { header,skipHeader: true });
        
    //     const ws = utils.json_to_sheet(data, { header,skipHeader: false });
    //     ws['!ref'] = utils.encode_range({s: {c:0,r:0},e: {c:columns, r:data.length}})
    //     utils.sheet_add_aoa(ws, Heading);
    //     utils.book_append_sheet(wb, ws, 'Hoja 1');
    
    //     writeFile(wb,`Total Report.xlsx`);
    // }
}