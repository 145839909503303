import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { forkJoin, from, of } from 'rxjs';

@Component({
  selector: 'app-formato-solicitud-final',
  templateUrl: './formato-solicitud-final.component.html',
  styleUrls: ['./formato-solicitud-final.component.css']
})
export class FormatoSolicitudFinalComponent implements OnInit {

  myForm: FormGroup;
  
  @Input() beneficiario: number;
  @Input() beneficiario_tipo;
  @Input() formato_codigo;
  @Input() formato_plantilla_url;
  @Input() numero_contrato;
  @Input() fecha_vencimiento;
  @Input() operacion;
  @Input() contrato;

  cliente: any;
  linea: any;

  principalesAccionistas : any;
  directorio : any;
  vinculacion_con_otras_empresas: any;
  de_los_accionistas_directos_gerentes: any;
  administracion: any;
  directos: any;
  locales: any;
  ingresos: any;
  descripcion_ingresos: any;
  cartera_clientes: any;
  cartera_provedores: any;
  principales_competidores: any;
  relacion_activos: any;
  creditos_comerciales_capital_trabajo: any[] = [];
  operaciones_factoring_descuento: any[] = [];
  otros_creditos: any[] = [];
  relaciones_financieras: any[] = [];
  experiencia_nuestros_productos: any;
  documents: any[] = [];
  garantia_descuento_solicitada: any[] = [];
  contactaOne: any;
  contactoTwo: any;

  deshabilitarBoton:boolean = true;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private formatoService: FormatosService,
    private clientesService: ClientesService,
    public toast: ToastService,
    private factoringService: FactoringService
  ) { }

  async ngOnInit() {

    this.createForm();

    console.log(this.operacion);

    if (!this.numero_contrato) {
      this.toast.warning('Aun no dispone de contrato!');
      setTimeout(() => {
        this.activeModal.close(null);
      }, 500);
      return;
    }

    await this.clientesService.obtenerLinea(this.operacion.linea_credito).then(resp => {
      this.linea = resp;
    }, err => {
      console.error(err);
    });

    await this.clientesService.obtenerCliente(this.linea.beneficiario).then((cliente: any) => {
      this.cliente = cliente;
    }); 

    this.obtenerAccionistas();
    this.obtenerDirectores();
    this.obtenerVinculos();
    this.obtenerLocales();
    this.obtenerCarteraClientes();
    this.obtenerProveedores();
    this.obtenerCompetidores();
    this.obtenerActivos();
    this.obtenerCreditosComerciales();
    this.obtenerOperacionesDescuentos();
    this.obtenerOtrosCreditos();
    this.obtenerRelacionesFinancieras();
    this.obtenerEjecutivos();
    this.obtenerDocuments();
    this.obtenerContactos();
    this.obtenerAvalaes();
    
    await this.clientesService.obtenerIngresos(this.cliente.id).then(resp => {
      console.log(resp);
      this.ingresos = resp;
      try {
        this.mapperIngresos(resp);
      } catch (error) {
        console.log('Error mapenado ingresos', error);
      }
      
    }, err => {
      console.error(err);
      this.descripcion_ingresos = {
        nivel_ventas_soles: {},
        nivel_ventas: []
      }
      // this.toast.warning('Aun no ha registrado su descripción de ingresos.');
      // this.activeModal.close(null);
    });

    setTimeout( ()=>{
      this.deshabilitarBoton = false;
    }, 1100)

  }

  mapperIngresos(resp) {
    this.descripcion_ingresos = {
      nivel_ventas_soles: {
        fecha_1: (resp?.anio_1).toString().substring(2,4),
        fecha_2: (resp?.anio_2).toString().substring(2,4),
        fecha_3_1: resp?.dia_mes_3,
        fecha_3_2: (resp?.anio_3).toString().substring(2,4),
      },
      nivel_ventas: [
        {
          ventas: {
            1: resp.mercado_local_ventas_1,
            2: resp.mercado_local_ventas_2,
            3: resp.mercado_local_ventas_3,
          },
          porcentage: resp.mercado_local_porcentaje,
          promedio_ventas_mensuales: resp.mercado_local_ventas_promedio,
          condiciones_grales_venta: {
            cont_porcentage: resp.mercado_local_ventas_contado_porcentaje,
            cred_porcentage: resp.mercado_local_ventas_credito_porcentaje,
          },
          politica_credito: {
            facturas_porcentage: resp.mercado_local_facturas_porcentaje,
            plazo_promedio_facturas: resp.mercado_local_facturas_plazo_promedio,
            letras_porcentage: resp.mercado_local_letras_porcentaje,
            plazo_promedio_letras: resp.mercado_local_letras_plazo_promedio,
          }
        },
        {
          ventas: {
            1: resp.mercado_externo_ventas_1,
            2: resp.mercado_externo_ventas_2,
            3: resp.mercado_externo_ventas_3,
          },
          porcentage: resp.mercado_externo_porcentaje,
          promedio_ventas_mensuales: resp.mercado_externo_ventas_promedio,
          condiciones_grales_venta: {
            cont_porcentage: resp.mercado_externo_ventas_contado_porcentaje,
            cred_porcentage: resp.mercado_externo_ventas_credito_porcentaje,
          },
          politica_credito: {
            facturas_porcentage: resp.mercado_externo_facturas_porcentaje,
            plazo_promedio_facturas: resp.mercado_externo_facturas_plazo_promedio,
            letras_porcentage: resp.mercado_externo_letras_porcentaje,
            plazo_promedio_letras: resp.mercado_externo_letras_plazo_promedio,
          }
        },
        {
          ventas: {
            1: (parseFloat(resp.mercado_externo_ventas_1) + parseFloat(resp.mercado_local_ventas_1)).toString(),
            2: (parseFloat(resp.mercado_externo_ventas_2) + parseFloat(resp.mercado_local_ventas_2)).toString(),
            3: (parseFloat(resp.mercado_externo_ventas_3) + parseFloat(resp.mercado_local_ventas_3)).toString(),
          },
          porcentage: (parseFloat(resp.mercado_externo_porcentaje) + parseFloat(resp.mercado_local_porcentaje)).toString(),
          promedio_visitas_mensuales: "",
          condiciones_grales_venta: {
            cont_porcentage: (parseFloat(resp.mercado_externo_ventas_contado_porcentaje) + parseFloat(resp.mercado_local_ventas_contado_porcentaje)).toString(),
            cred_porcentage: (parseFloat(resp.mercado_externo_ventas_credito_porcentaje) + parseFloat(resp.mercado_local_ventas_credito_porcentaje)).toString(),
          },
          politica_credito: {
            facturas_porcentage: (parseFloat(resp.mercado_externo_facturas_porcentaje) + parseFloat(resp.mercado_local_facturas_porcentaje)).toString(),
            plazo_promedio_facturas: (parseFloat(resp.mercado_externo_facturas_plazo_promedio) + parseFloat(resp.mercado_local_facturas_plazo_promedio)).toString(),
            letras_porcentage: (parseFloat(resp.mercado_externo_letras_porcentaje) + parseFloat(resp.mercado_local_letras_porcentaje)).toString(),
            plazo_promedio_letras: (parseFloat(resp.mercado_externo_letras_plazo_promedio) + parseFloat(resp.mercado_local_letras_plazo_promedio)).toString(),
          }
        }
      ]
    }
  }

  obtenerAccionistas() {
       this.clientesService.obtenerAccionistas(this.cliente.id).then(resp => {
        this.principalesAccionistas = resp['results'].map(a => {
          return {
            nombre        : a.nombre,
            tipo_persona  : a.tipo_persona_nombre,
            documento     : a.dni_ruc,
            edad          : this.getEdad(a.fecha_nacimiento),
            nacionalidad  : a.nacionalidad_nombre,
            aporte_soles  : a.aporte,
            participacion : a.participacion
          }
        });
      }, err => {
        console.error(err);
      })
  }
  
  
  getEdad(dateString) {
    let hoy = new Date()
    let fechaNacimiento = new Date(dateString)
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear()
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth()
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--
    }
    return edad
  }

  obtenerDirectores() {
    this.clientesService.obtenerDirectores(this.cliente.id).then(resp => {
      this.directorio = resp['results'].map(a => {
        return {
          nombre        : a.nombre,
          documento     : a.dni,
          cargo         : a.cargo,
          nacionalidad  : a.nacionalidad_nombre,
          fecha_ingreso : this.formatDate(a.fecha_ingreso)
        }
      });
    }, err => {
      console.error(err);
      
    });
  }

  obtenerVinculos() {
    this.clientesService.obtenerVinculos(this.cliente.id).then(resp => {
      // this.vinculos = resp['results'];
      this.vinculacion_con_otras_empresas = resp['results'].filter( item => item.directo).map(a => {
        return {
          razon_social    : a.nombre,
          tipo_persona  : a.tipo_persona_nombre,
          documento     : a.dni_ruc,
          aportes       : {
              soles: a.aporte,
              parte_porcentage: a.participacion
          },
          gestion       : a.gestion,
          codigo_sbs    : a.codigo_sbs
        }
      });

      this.de_los_accionistas_directos_gerentes = resp['results'].filter( item => !item.directo).map(a => {
        return {
          razon_social    : a.nombre,
          tipo_persona    : a.tipo_persona_nombre,
          documento       : a.dni_ruc,
          aportes: {
              soles: a.aporte,
              parte_porcentage: a.participacion,
          },
          gestion         : a.gestion,
          codigo_sbs      : a.codigo_sbs,
          cargo_y_funcion : a.cargo,
        }
      });
    }, err => {
      console.error(err);
      
    });
  }

  obtenerEjecutivos() {
    this.clientesService.obtenerEjecutivos(this.cliente.id).then(resp => {
      this.administracion =  resp['results'].map(a => {
        return {
          nombre       : a.nombre,
          documento    : a.dni,
          cargo        : a.cargo,
          nacionalidad : a.nacionalidad_nombre,
          correo       : a.email,
        }
      });
    }, err => {
      console.error(err);
      
    });
  }

  obtenerLocales() {
    this.clientesService.obtenerLocales(this.cliente.id).then(resp => {
      this.locales = resp['results'].map(a => {
        return {
          tipo: a.tipo_local_nombre,
          direccion: a.direccion,
          area_terreno: a.area_terreno,
          area_construido: a.area_construido,
          propio_o_alquilado: a.tipo_propiedad_nombre,
          gravado_a_favor_de: a.gravado_beneficiario,
          valor_comercial_dolares: a.valor_comercial,
        }
      });
    }, err => {
      console.error(err);
      
    });
  }

  obtenerCarteraClientes() {
    this.clientesService.obtenerRelacionesClientes(this.cliente.id).then(resp => {
      const clientes = resp['results'].map(a => {
        return {
          razon_social: a.nombre,
          ruc: a.ruc,
          producto_servicio: a.producto_servicio,
          tiempo_relacion_comercial: a.tiempo_relacion,
          facturacion: {
              anual_soles: a.facturacion_anual,
              mensual_soles: a.facturacion_mensual,
          },
          moneda: {
              soles_porcentage: a.porcentaje_soles,
              dolares_porcentage: a.porcentaje_dolares,
          },
          politicas_credito: {
              ventas_credito_porcentage: a.ventas_credito_porcentaje,
              documento_pago: a.documento_pago,
          },
          plazo_promedio: a.plazo_promedio,
          telefono: a.telefono,
          persona_contacto: a.persona_contacto
        }
      });

      this.cartera_clientes = {
        clientes: clientes,
        total_facturacion_mensual: clientes.reduce((acc, act) => acc + parseFloat(act.facturacion.mensual_soles), 0).toString(),
      }
    }, err => {
      console.error(err);
    })
  }

  obtenerProveedores() {
    this.clientesService.obtenerRelacionesProveedores(this.cliente.id).then(resp => {
      this.cartera_provedores = resp['results'].map( a => {
        return {
          razon_social: a.nombre,
          ruc: a.ruc,
          producto_servicio: a.producto_servicio,
          tiempo_relacion_comercial: a.tiempo_relacion,
          facturacion: {
              anual_soles: a.facturacion_anual,
              mensual_soles: a.facturacion_mensual,
          },
          moneda: {
              soles_porcentage: a.porcentaje_soles,
              dolares_porcentage: a.porcentaje_dolares,
          },
          politicas_credito: {
              ventas_credito_porcentage: a.ventas_credito_porcentaje,
              documento_pago: a.documento_pago,
          },
          plazo_promedio: a.plazo_promedio,
          telefono: a.telefono,
          persona_contacto: a.persona_contacto,
        }
      });
    }, err => {
      console.error(err);
      
    });
  }

  obtenerCompetidores() {
    this.clientesService.obtenerRelacionesCompetidores(this.cliente.id).then(resp => {
      this.principales_competidores = resp['results'].map(a => {
        return {
          "razon_social": a.nombre,
          "participacion_mercado_porcentage": a.participacion_mercado,
      }
      });
    }, err => {

    });
  }

  obtenerActivos() {
    this.clientesService.obtenerActivos(this.cliente.id).then(resp => {
      const activos = resp['results'].map(a => {
        return {
          tipo_bien: a.tipo_bien_nombre,
          ubicacion: a.ubicacion,
          inmuebles: {
              numero_ficha_rrpp: a.ficha_rrpp,
          },
          vehiculos_maquinaria_y_equipos: {
              placa_series: a.placa,
              marca: a.marca,
              modelo: a.modelo,
              anio: a.anio,
          },
          gravado_favor_de: a.gravado_beneficiario,
          fecha_compra: this.formatDate(a.fecha_compra),
          valor_de_compra: a.valor_compra,
        }
      });
      this.relacion_activos = {
        activos: activos,
        total_dolares: activos.reduce((acc, act) => acc + parseFloat(act.valor_de_compra), 0).toString(),
      }
    }, err => {
      console.error(err);
      
    });
  }

  obtenerCreditosComerciales() {
    this.clientesService.obtenerCreditosComerciales(this.cliente.id).then(resp => {
      this.creditos_comerciales_capital_trabajo = resp['results'].map(a => {
        return {
          productos: a.producto,
          entidad_financiera: a.entidad_financiera_nombre,
          moneda: a.moneda_descripcion,
          plazo_operacion: a.plazo,
          monto_cuota_o_linea: a.monto,
          vencimiento: {
              fecha_primera_cuota: this.formatDate(a.vencimiento_primera_cuota),
              fecha_ultima_cuota: this.formatDate(a.vencimiento_ultima_cuota),
          },
          situacion: a.situacion,
        }
      });
    }, err => {
      console.error(err);
      
    });
  }

  obtenerOperacionesDescuentos() {
    this.clientesService.obtenerCreditosOperaciones(this.cliente.id).then(resp => {
      this.operaciones_factoring_descuento = resp['results'].map(a => {
        return {
          entidad_financiera: a.entidad_financiera_nombre,
          limite_linea_asignada: a.linea_asignada,
          descuento_mayor_uso: a.documento_mayor_uso,
          moneda: a.moneda_descripcion,
          plazo_promedio: a.plazo_promedio,
          monto_promedio: a.monto_promedio,
          situacion: a.situacion,
        }
      });
    }, err => {
      console.error(err);
      
    });
  }

  obtenerOtrosCreditos() {
    this.clientesService.obtenerOtrosCreditos(this.cliente.id).then(resp => {
      this.otros_creditos = resp['results'].map(a => {
        return {
          tipo_credito: a.tipo_credito_nombre,
          entidad_financiera: a.entidad_financiera_nombre,
          moneda: a.moneda_descripcion,
          plazo_numero_cuotas: a.plazo,
          monto_cuota: a.monto,
          vencimiento: {
              fecha_primera_cuota: this.formatDate(a.vencimiento_primera_cuota),
              fecha_ultima_cuota: this.formatDate(a.vencimiento_ultima_cuota),
          },
          cuotas_pagadas: a.cuotas_pagadas,
        }
      });
    }, err => {
      console.error(err);
      
    });
  }

  obtenerRelacionesFinancieras() {
    this.clientesService.obtenerRelacionesFinancieras(this.cliente.id).then(resp => {
      this.relaciones_financieras = resp['results'].map(a => {

        return {
          entidad_financiera: a.entidad_financiera_nombre,
          sucursal: a.sucursal,
          moneda: a.moneda_descripcion,
          numero_cuenta: a.numero_cuenta,
          sectorista: a.sectorista,
          telefono: a.telefono,
          deuda_vigente_dolares: a.deuda_vigente,
        }
      });
    }, err => {
      console.error(err);
      
    });
  }

  obtenerContactos() {
    this.clientesService.obtenerContactos(this.cliente.id).then(resp => {
      console.log(resp);
      for (let index = 0; index < resp['results'].length; index++) {
        if (index == 0) {
          this.contactaOne = resp['results'][index];
        }
        if (index == 1) {
          this.contactoTwo = resp['results'][index];
        }
      }
      console.log(this.contactaOne, this.contactoTwo);
    }, err => {
      console.error(err);
      
    });
  }

  createForm() {
    
    this.myForm = this.formBuilder.group({
      // firmante: [null, []],
      // emision: [{ value: new Date(), disabled: true }, Validators.required],
      // beneficiario_tipo: [{ value: this.beneficiario_tipo == undefined ? 'J' : this.beneficiario_tipo, disabled: true }, Validators.required]
    });

  }

  get solicitante () {
    return {
        razon_social:         this.cliente.nombre,
        domicilio:            this.cliente.domicilio_legal,
        distrito:             this.cliente.distrito_nombre,
        provincia:            this.cliente.provincia_nombre,
        departamento:         this.cliente.departamento_nombre,
        telefono:             this.cliente.telefono_contacto,
        fax:                  this.cliente.fax,
        correo:               this.cliente.email,
        ruc:                  this.cliente.ruc,
        codigo_cciu:          this.cliente.codigo_ciiu,
        codigo_sbs:           this.cliente.codigo_sbs,
        capital_social:       this.cliente.capital_social,
        patrimonio:           this.cliente.patrimonio,
        nombre_conyuge:       this.cliente.nombre_conyuge,
        dni_conyuge:          this.cliente.dni_conyuge,
        nombre_contacto_1:    this.operacion.contacto_nombre,
        telefono_contacto_1:  this.operacion.contacto_telefono,
        correo_contacto_1:    this.operacion.contacto_email,
        nombre_contacto_2:    '',
        telefono_contacto_2:  '',
        correo_contacto_2:    '',
        nombre_contador:             '',
        telefono_contador:             '',
        correo_contador:             ''
    }
  }

  get informacionNegocio() {
    return {
      fecha_inicio_operaciones          : this.formatDate(this.cliente.inicio_operaciones),
      sector_economico                  : this.cliente.sector_nombre,
      actividad_principal_nombre        : this.cliente.actividad_principal_texto,
      actividad_principal_porcentage    : this.cliente.actividad_principal_porcentaje,
      participacion_mercado_porcentage  : this.cliente.participacion_mercado,
      numero_empleados                  : this.cliente.cantidad_empleados,
      numero_obreros                    : this.cliente.cantidad_obreros,
      sectores_atiende                  : {
        industrial_porcentage   : this.cliente.sector_industrial_porcentaje,
        comercial_porcentage    : this.cliente.sector_comercial_porcentaje,
        servicios_porcentage    : this.cliente.sector_servicios_porcentaje,
        otros_porcentage        : this.cliente.sector_otros_porcentaje
      },
      actividades_complementarias       : [
        {
          nombre: this.cliente.actividad_complementaria_1_texto,
          porcentage: this.cliente.actividad_complementaria_1_porcentaje,
        },
        {
          nombre: this.cliente.actividad_complementaria_2_texto,
          porcentage: this.cliente.actividad_complementaria_2_porcentaje
        }
      ],
      locales                           : this.locales
    }
  }

  get getConyuge() {
    return (this.cliente.tipo_persona == 2) ? {
      conyugue_1: "",
      dni_1: "",
      estado_civil_1: "",
      conyugue_2: "",
      dni_2: "",
      domicilio_parte_1: "",
      domicilio_parte_2: "",
    } : {
      conyugue_1: this.cliente.nombre,
      dni_1: this.cliente.dni,
      estado_civil_1: this.cliente.estado_civil,
      conyugue_2: this.cliente.nombre_conyuge,
      dni_2: this.cliente.dni_conyuge,
      domicilio_parte_1: this.cliente.domicilio_legal,
      domicilio_parte_2: this.cliente.domicilio_comercial,
    }
  }

  obtenerDocuments() {
    this.factoringService.obtenerOperacionDocumentos(this.operacion.id).then(resp => {
      this.documents = resp['results'];
    }, err => {
      console.error(err);
      
    });
  }

  obtenerAvalaes() {
    this.clientesService.obtenerPagares(this.cliente.id).then(resp => {
      this.garantia_descuento_solicitada = resp['results'].map(a => {
        return {
          descripcion: a.tipo_documento_descripcion,
          monto_valorizado: a.monto,
        }
      });
    }, err => {
      console.error(err);
      
    });
  }

  checkExistDoc(document) {
    return !!this.documents.find(doc => doc.documento === document);
  }

  onSubmit() {

    const dateNow = new Date;

    const data = {
      fecha                                 : this.formatDate(`${dateNow.getFullYear()}-${(dateNow.getMonth() + 1)}-${dateNow.getDate()}`),
      archivo_plantilla                     : this.formato_plantilla_url,
      oficial_de_negocio                    : this.operacion.oficial_negocio_nombre,
      numero_registro                       : this.numero_contrato,
      datos_solicitante                     : this.solicitante,
      principales_accionistas               : this.principalesAccionistas,
      directorio                            : this.directorio,
      administracion                        : this.administracion,
      vinculacion_con_otras_empresas        : this.vinculacion_con_otras_empresas,
      de_los_accionistas_directos_gerentes  : this.de_los_accionistas_directos_gerentes,
      informacion_negocio                   : this.informacionNegocio,
      descripcion_ingresos                  : this.descripcion_ingresos,
      cartera_clientes                      : this.cartera_clientes,
      cartera_provedores                    : this.cartera_provedores,
      principales_competidores              : this.principales_competidores,
      relacion_activos                      : this.relacion_activos,
      creditos_comerciales_capital_trabajo  : this.creditos_comerciales_capital_trabajo,
      operaciones_factoring_descuento       : this.operaciones_factoring_descuento,
      otros_creditos                        : this.otros_creditos,
      relaciones_financieras                : this.relaciones_financieras,
      experiencia_nuestros_productos        : {},
      observaciones                         : this.cliente.observaciones,
      declaracion_confidencial_patrimonio   : this.getConyuge,
      garantia_descuento_solicitada         : this.garantia_descuento_solicitada,
      checkbox_s: {
        descripcion_ingresos: {
          check_1 : this.ingresos?.agente_retencion,
          check_2 : !this.ingresos?.agente_retencion,
        },
        datos_operacion_solicita: {
          check_3: !!this.linea,
          check_4: false,
          check_5: !(!!this.linea),
          check_6: false,
        },
        tipo_documento: {
          check_7  : (this.operacion.detalle[0].tipo_detalle == 2),
          check_8  : (this.operacion.moneda == 1),
          check_9  : (this.operacion.banco_abono == 1),
          check_10 : (this.operacion.detalle[0].tipo_detalle == 1),
          check_11 : !(this.operacion.moneda == 1),
          check_12 : (this.operacion.banco_abono == 2),
          check_13 : (this.operacion.banco_abono != 1 && this.operacion.banco_abono == 2),
        },
        garantias_ofrecidas: {
          check_14 : false,
          check_15 : false,
          check_16 : (this.garantia_descuento_solicitada.length > 0),
          check_17 : false,
        },
        documentos_presentados: {
          legales : {
            check_18 : this.checkExistDoc(1),
            check_19 : this.checkExistDoc(2),
            check_20 : this.checkExistDoc(3),
            check_21 : this.checkExistDoc(4),
            check_22 : this.checkExistDoc(5),
            check_23 : this.checkExistDoc(6),
            check_24 : this.checkExistDoc(7),
          },
          financieros : {
            check_25 : this.checkExistDoc(8),
            check_26 : this.checkExistDoc(9),
            check_27 : this.checkExistDoc(10),
            check_28 : this.checkExistDoc(11),
            check_29 : this.checkExistDoc(12),
            check_30 : this.checkExistDoc(13),
            check_31 : this.checkExistDoc(14),
            check_32 : this.checkExistDoc(16),
            check_33 : this.checkExistDoc(17),
          }
        }
      }
    };

    console.log(data);
    // return;
    this.formatoService.generarFormatoSolicitudFinal(data).then(
      (x: any) => {
        console.log(x);
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Solicitud Final.pdf";
        link.click();
        this.activeModal.close(data);
      }, async (reason) => {
        this.activeModal.close(data);
        console.log(reason);
        const message = JSON.parse(await reason.error.text());
        console.log(message);
        this.showModalErrorContrato(message['detail']);
      }), err => {
        console.error(err);
        
      };

  }

  showModalErrorContrato(message) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {

    });

    modalRef.componentInstance.showBottomLeft = false;
    modalRef.componentInstance.title = 'Información requerida en el contrato marco';
    modalRef.componentInstance.titleRightBottom = 'Aceptar';
    modalRef.componentInstance.messageHTML = true;
    modalRef.componentInstance.message = `<span class="">${message}</span>`;
  }

  formatDate(date) {
    const arrayDate = date.split('-');
    return `${arrayDate[2].substring(0,2)}/${arrayDate[1]}/${arrayDate[0]}`;
  }

}
