import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class JudicialService {

  private apiUrl: string = '';
  public judicial: string = 'factoring/carteras/';

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient
  ) {
    this.apiUrl = this.appService.settings.API_base_url
  }

  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show()
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();

    if (mensaje && ok)
      this.toast.success(mensaje);
    if (mensaje && !ok)
      this.toast.warning(mensaje);
  }

  /**
   * Mostros errores recibidos del servidor
   * @param error Error enviado del servidor
   */
  showErrors(error) {
    const err = error.error;

    for (const key in err) {
      if (Object.prototype.hasOwnProperty.call(err, key)) {
        const element = err[key];

        if (key == 'operacion') {
          this.spinnerOff('Seleccione una opción válida', false);
          continue;
        }

        if (Array.isArray(element) && element.length) {
          element.forEach(item => {
            this.spinnerOff(item, false);
          });
        } else {
          this.spinnerOff(element, false);
        }

      }
    }
  }

  /**
   * 
   * @param page 
   * @param page_size 
   * @param beneficiario_ruc_nombre__icontains 
   * @param deudor_ruc_nombre__icontains 
   * @param operacion_detalle__numero_documento__icontains 
   * @param monto_neto__range 
   * @param estado__in 
   * @param operacion_detalle 
   */
  obtenerJudial(
    page: number = 1,
    page_size: number = 10,
    beneficiario_ruc_nombre__icontains: string = '',
    deudor_ruc_nombre__icontains: string = '',
    operacion_detalle__numero_documento__icontains: string = '',
    monto_neto__range: string = '',
    estado__in: string = '',
    operacion_detalle: string = '',
    empresa: string = ''
  ) {

    const url = this.apiUrl + this.judicial + '?protestado=true' +
      `&beneficiario_ruc_nombre__icontains=${beneficiario_ruc_nombre__icontains}` +
      `&aceptante_ruc_nombre__icontains=${deudor_ruc_nombre__icontains}` +
      `&operacion_detalle__numero_documento__icontains=${operacion_detalle__numero_documento__icontains}` +
      `&monto_neto__range=${monto_neto__range}` +
      `&estado__in=${estado__in}` +
      `&operacion=${operacion_detalle}` +
      `&page_size=${page_size}` +
      `&empresa=${empresa}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }


  bandejaJudicial(
    page: number = 1,
    page_size: number = 10,
    aceptante__nombre = '',
    cliente__nombre = '',
    estado = '',
    empresa = '',
  ) {

    const url = this.apiUrl + 'factoring/expedientes_judiciales' +
      `?page_size=${page_size}` +
      `&aceptante__nombre=${aceptante__nombre}` +
      `&cliente__nombre=${cliente__nombre}` +
      `&estado=${estado}` +
      `&empresa=${empresa}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  bandejaJudicialPatch(row) {

    const url = this.apiUrl + 'factoring/expedientes_judiciales' + `/${row.id}/`

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.patch(url, row)
        .subscribe((response) => {
          this.spinnerOff('El expediente fue actualizado con Éxito');
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  bandejaJudicialDetalleDelete(id) {

    const url = this.apiUrl + 'factoring/expedientes_judiciales/detalles' + `/${id}/`

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url)
        .subscribe((response) => {
          this.spinnerOff('El detalle fue eliminado con Éxito');
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  bandejaJudicialPost(row) {

    const url = this.apiUrl + 'factoring/expedientes_judiciales/';

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.post(url, row)
        .subscribe((response) => {
          this.spinnerOff('El expediente fue creado con Éxito');
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  expedientesEstados(
    page: number = 1,
    page_size: number = 10,
  ) {

    const url = this.apiUrl + 'factoring/expedientes_judiciales/estado' +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  expedientesTipos(
    page: number = 1,
    page_size: number = 10,
  ) {

    const url = this.apiUrl + 'factoring/expedientes_judiciales/tipo' +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  expedientesTiposSentenciasPrincipal(
    page: number = 1,
    page_size: number = 10,
  ) {

    const url = this.apiUrl + 'factoring/expedientes_judiciales/tipo_sentencia_proceso_principal' +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  expedientesTiposSentenciasCautelar(
    page: number = 1,
    page_size: number = 10,
  ) {

    const url = this.apiUrl + 'factoring/expedientes_judiciales/tipo_resultado_medida_cautelar/' +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  expedientesDetalles(
    page: number = 1,
    page_size: number = 10,
  ) {

    const url = this.apiUrl + 'factoring/expedientes_judiciales/detalles' +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }
}
