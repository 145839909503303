import { Component, ViewChild, Input} from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { dateInput } from "app/shared/form-date/form-date.component";
import { ModalAlertasComponent } from "./modals/modal-alertas/modal-alertas.component";
import { ModalImportarComponent } from "./modals/modal-importar/modal-importar.component";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";

import { BaseLavadoActivosComponent } from "app/modules/lavado-activos/base-lavado-activos-component"

@Component({
    selector: 'app-alertas',
    templateUrl: './alertas.component.html',
    styleUrls: ['./alertas.component.css']
})
export class AlertasComponent extends BaseLavadoActivosComponent{
  public requestService = (filters: Record<string, string>) => this.lavadoActivosService.obtenerPlantillasRiesgo(filters);
  // Utils - Iconos
 
  bsValue       = new Date();

  // Utils - General
  public generando: boolean = false;
  public faltantes: any;
  public validando_str: string = '';
  public dateInput     : dateInput;
  public isPeriodoButton: boolean = false;
  public isPeriodoButtonColor: any = 'btn-info'

  // Periodo
  public periodos: any[] = [];
  @Input() selectedPeriodo: any;

  
  
  // Plantilla Riesgo
  public plantillaRiesgo: any;

  // Templates
  @ViewChild("e_confirmdelete", { static: false }) public e_confirmdelete;
  
  // Inicializamos Servicios y Modales
  constructor(
    public modalService: NgbModal,
    public authService: AuthService,
    private lavadoActivosService: LavadoActivosService
  ) {
    super(authService, modalService)
  }

  // Lo primero que se ejecuta al entrar
  ngOnInit(): void {
    // Fecha de periodo por defecto
    this.setPeriodoFecha(this.getDate())
    this.buildInputDate();
  }

  getDate(){
    const year = this.bsValue.getFullYear();
    const month = this.bsValue.getMonth() + 1;
    const currentDate = `${year}-${month}-01`;
    return currentDate;
  }
  async setPeriodoFecha(date: any): Promise<void> {
    // Seteamos periodo_fecha por la fecha obtenida
    this.filters['periodo__fecha'] = date
    try {
      const periodos = await this.lavadoActivosService.obtenerPeriodosSimple(
        {page_size: '1', page: '1', fecha: date }
      );
      this.isPeriodo(periodos['results']);
      
      const resultado = await Promise.all([periodos]);
      const periodo = resultado[0]['results'][0];
      this.setPeriodo(periodo);
      this.filters['periodo'] = periodo['id']
    } catch (error) {
      // Manejar el error aquí
      console.error('Periodo no encontrado', );
    }
  }
  
  isPeriodo(periodo: any): void{
    this.isPeriodoButton = periodo.length === 0;
    this.isPeriodoButtonColor = this.isPeriodoButton ? 'btn-danger' : 'btn-info';
  }

  setPeriodo(periodo) {
    // Asignamos la respuesta
    this.selectedPeriodo = periodo;
  }
  
  async generarEvaluaciones() {
    this.faltantes = 'Calculando...'
    this.validando_str = 'Clientes'
    this.generando = true

    let res = await this.lavadoActivosService.generarEvaluaciones(
      this.selectedPeriodo.id
    )
        
    this.faltantes = res['clientes_faltantes']
      if(!res['generando']){
        this.generando = false
      }

    this.executeService()
  }

  async cerrarPeriodo() {
    var is_error = false
    let services = [
      await this.lavadoActivosService.validar_evaluaciones(this.selectedPeriodo.id),
      await this.lavadoActivosService.cerrarPeriodo(this.selectedPeriodo.id),
      await this.lavadoActivosService.generar_historial_evaluaciones(this.selectedPeriodo.id)
    ]
    services.forEach(service => {
      new Promise((resolve, rejects) => {
        if (is_error){
          return false
        }
        if (!is_error){
          service.then(
            (message: any) =>{
              console.log(message)
              resolve(true)
            }
          ).catch(err => {
            is_error = true
            rejects(false)
          })
        }
      });
    });
    this.setPeriodoFecha(this.filters['periodo__fecha'])

  }
  

  exportar(){
    return new Promise<boolean>((resolve, rejects) => {
      this.lavadoActivosService.exportarCalificaciones(this.selectedPeriodo.id).then(
          (x: any) => {
            var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = `Reporte de Evaluaciones.xlsx`;
            link.click();
            resolve(true)
          }).catch(err => {
            console.log(err)
            rejects(false)
          })
        })
  }
  importar(){
    // guardamos en la variable publica modalRef el servicio para crear modales
    this.modalRef = this.modalService.open(ModalImportarComponent, { // Llamamos el componenente a Renderizar
      ariaLabelledBy: "modal-basic-title", // Aria Label
      size: "xl", // Size
      windowClass: "modalSizeXXL" // Clase Css
    });

    this.modalRef.componentInstance.selectedPeriodo = this.selectedPeriodo;
  }

  nuevoPeriodo(){
    this.lavadoActivosService.nuevoPeriodo({'fecha': this.filters['periodo__fecha']})
    .then((res: any[]) =>{
      this.isPeriodoButton = false
      let periodos = res
      this.periodos = periodos
      this.setPeriodoFecha(this.filters['periodo__fecha'])
    })
    .catch(error=>{
      console.log(error)
    })
  }

  initVars() {
    // Inicializar columnas
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: false,
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "cliente_nombre",
        sortable: true,
        filterable: true,
        filterProp: "cliente__cliente", // Nombre de la propiedad a filtrar
        filterInput: true, // Se puede filtrar?
      },
      {
        class: "text-center",
        headerName: "Ruc",
        field: "cliente_ruc",
        sortable: true,
        filterable: true,
        filterProp: "cliente__ruc", // Nombre de la propiedad a filtrar
        filterInput: true, // Se puede filtrar?
      },
      {
        class: "text-center",
        headerName: "Plantilla",
        field: "tipo_descripcion",
        sortable: true,
        filterable: true,
        filterProp: "tipo__descripcion", // Nombre de la propiedad a filtrar
        filterInput: true, // Se puede filtrar?
      },
      {
        class: "text-center fw-bold",
        headerName: "Nivel de Riesgo",
        field: "nivel_riesgo",
        sortable: false,
      },
      {
        class: "text-center fw-bold",
        headerName: "Puntación de Riesgo",
        field: "puntuacion_riesgo",
        sortable: true,
      },
      {
        class: "text-center", // Clase CSS de las columnas
        headerName: "Ultima Evaluación", // Nombre que se rendirzara en la columna
        field: "ultima_evaluacion", // Campo del propiedad del objeto que se guarda en LoadTable()
        sortable: false,
      },
    ];

    this.columnDefs = columns; // guardamos las columnas en la variable publica
  }
  addAlerta(){
    this.openModal(ModalAlertasComponent,
      {
        'alerta': null
      }
    )
  }

  editActionTable(row: any){
    this.openModal(ModalAlertasComponent,
      {
        'plantillaRiesgoId': row.id,
        'is_open_periodo': this.selectedPeriodo.is_open
      }
    )
  }
  // Obtenemos el row
  deleteActionTable(row:any){
    this.plantillaRiesgo = row;
    // Creamos el modal llamando el template e_confirmdelete
    this.modalRef = this.modalService.open(
      this.e_confirmdelete,
      {
        size: "md",
      }
    );
    
  }

  // Si el usuario dice que si, llama al endpoint y elimina el objeto
  confirmDelete(){
    
    this.lavadoActivosService.deletePlantillaRiesgo(this.plantillaRiesgo.id)
    .catch((error)=>{
      console.log(error)
    }).then(() => {
      this.modalRef.close();
      this.plantillaRiesgo = null;
      // Obtenmos las alertas
      this.executeService()
    });
    
   
    
  }

  rowColorAlerta(row: any): string {
    switch (row.nivel_riesgo) {
      case 'Bajo':
        return '#D5F5E3';
      case 'Medio':
        return '#FAD7A0';
      case 'Alto':
        return '#F5B7B1';
      case 'NO/ID':
        return '#F1948A';
      default:
        return '#FFFFFF';
    }
  }
  // Cerrar el modal
  closeModal() {
    
    this.modalRef.close();
    this.plantillaRiesgo = null;
  }

  // Paginador
  async goToPage({ page, per_page, filtros }): Promise<void> {
    this.filters = {
      page: page,
      page_size: per_page,
    }
    let campos = ['tipo__descripcion', 'cliente__cliente', 'cliente__ruc']
    campos.forEach(campo => {
      if (campo in filtros) {
        this.filters[campo] = filtros[campo];
      }
    });
    try {
      this.executeService()
    } catch (error) {
      // Manejar el error aquí
      console.error('Error al obtener las plantillas de riesgo:', error);
    }
  }
  

  buildInputDate(){
    this.dateInput = {
      bsConfig : {
        defaultDay: '01',
        minMode: 'month',
        adaptivePosition: true,
        dateInputFormat: 'MM-YYYY'
      }, 
      bsplaceholder : '00/0000',
      classes: ''
    }
  }
}