
import { HttpClient, HttpParams  } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from "../toast/toast.service";
import { FactoringService } from "app/core/services/factoring/factoring.service";

@Injectable({
  providedIn: "root",
})
export class LavadoActivosService {
    private apiUrl: string = "";
    private base: string = "factoring/lavado_activos/"
    public plantillas: string = `${this.base}plantillas/`;
    public tipo_plantillas: string = `${this.base}tipo_plantillas/`;
    public variables_riesgo: string = `${this.base}variables_riesgo/`;
    public valores_riesgo: string = `${this.base}valores_riesgo/`;
    public periodos: string = `${this.base}periodos/`;
    public configuracion: string = `${this.base}configuracion/`;
    public reportes: string = `${this.base}reportes/`;
    public tipo_reportes: string = `${this.base}tipo_reportes/`;
    public matrices: string = `${this.base}matrices/`;
    public zonas_riesgo: string =  `${this.base}zonas_riesgo/`;
    public ObjectsMessaje: {
      variable: 'La variable',
      valor: 'El valor',
      plantilla: 'La evaluación',
      tipo: 'El tipo'
    };
    constructor(
      public appService: AppService,
      public toast: ToastService,
      public spinner: NgxSpinnerService,
      public http: HttpClient,
      public factoring: FactoringService,
    ) {
      this.apiUrl = this.appService.settings.API_base_url;
    }
     /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();

    this.appService.notifyMe(mensaje, ok)
    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);

  }


  /**
   * Mostros errores recibidos del servidor
   * @param error Error enviado del servidor
   */
  showErrors(error) {
    const err = error.error;
    console.log(error);
  
    const error_server = typeof err === 'string' || error instanceof String;
    if (!error_server) {
      for (const key in err) {
        console.log(key)
        if (err.hasOwnProperty(key)) {
          const element = err[key];
          console.log(element);
          if (key === 'error') {
            const errors = err['error']['errors'];
            this.showErrorsArray(errors);
          } else if (Array.isArray(element) && element.length) {
            element.forEach(item => this.spinnerOff(item, false));
          } else {
            this.spinnerOff(element, false);
          }
        }
      }
    } else {
      this.spinnerOff('Error interno del servidor', false);
    }
  }
  
  showErrorsArray(errors) {
    for (const key_error in errors) {
      if (errors.hasOwnProperty(key_error)) {
        this.spinnerOff(errors[key_error], false);
      }
    }
  }
  
  
  
  /**
   * 
   * @param metodo 
   * @param objeto 
   */
  successMessage(metodo: string, objeto: string){
    const mensaje = `${objeto} ` + 'fue ' + `${metodo} ` + 'con exito.'
    this.spinnerOff(mensaje)
  }

  
  request(
    method: string,
    url: string,
    data: any[] = [],
    object: string = 'El Objeto',
    showMask: boolean = true,
    filters: Record<string, string> = {},
    httpOptions: any = {}
    ): Promise<any> {
    if (showMask) {
      this.spinnerOn();
    }
    
    let requestPromise: Promise<any>;
    let params = new HttpParams();
    for (let key in filters) {
      params = params.append(key, filters[key]);
    }
    switch (method) {
      case 'GET':
        requestPromise = this.http.get(url, { params }).toPromise();
        break;
      case 'POST':
        httpOptions['params'] = params
        requestPromise = this.http.post(url, data, httpOptions).toPromise();
        break;
      case 'PATCH':
        requestPromise = this.http.patch(url, data, {params}).toPromise();
        break;
      case 'DELETE':
        requestPromise = this.http.delete(url).toPromise();
        break;
      default:
        throw new Error('Invalid HTTP method.');
    }
    
    return requestPromise
      .then((response) => {
        if (showMask) {
          switch (method) {
            case 'POST':
              this.successMessage('creada', object);
              break;
            case 'PATCH':
              this.successMessage('actualizada', object);
              break;
            case 'DELETE':
              this.successMessage('eliminada', object);
              break;
          }
        }
        
        return response;
      })
      .catch((err) => {
        this.showErrors(err);
        throw err;
      })
      .finally(() => {
        this.spinnerOff();
      });
  }

  // Plantillas Riesgo
  /**
   * GET
   * @param id 
   */
  sincronizarPlantillaRiesgo(id: any){
    const url = this.apiUrl + this.plantillas + `${id}/sincronizar`;
    return this.request('GET', url)
  }
  /**
   * GET
   * @param page
   * @param page_size
   * @param filters
   * @returns 
   */
  obtenerPlantillasRiesgo(filters: Record<string, string>) {
    const url =
      this.apiUrl + this.plantillas
      ;
    return this.request('GET', url, [], 'Plantillas', true, filters)
    
  }
  /**
   * GET
   * @param id 
   * @returns 
   */
   obtenerPlantillaRiesgo(id: any) {
    const url =
      this.apiUrl + this.plantillas + `${id}/`;
    return this.request('GET', url)
  }
  /**
   * GET
   * @param id 
   * @returns 
   */
   obtenerHistorialPlantillaRiesgo(id:number, page: number = 1, page_size: number = 10) {
    const url =
      this.apiUrl + this.plantillas + `${id}/historial`+ `?page_size=${page_size}` + `&page=${page}`;
    return this.request('GET', url)
  }
  /**
   * POST
   * @param row data a registrar
   * @returns 
   */
  postPlantillasRiesgo(row: any) {
    const url = this.apiUrl + this.plantillas;

    return this.request('POST', url, row, 'Evaluación')
  }
  /**
   * 
   * @param id 
   */
  captureHistorialPlantillaRiesgo(id){
    const url = this.apiUrl + this.plantillas + `${id}/historial_capture/`
    return this.request('POST', url, [], 'Historial')
  }
  /**
   * PATCH
   * @param row 
   * @returns 
   */
   pathPlantillasRiesgo(row: any){
    const url = this.apiUrl + this.plantillas + `${row.id}/`;
    return this.request('PATCH', url, row, 'La evaluación')
  }
  /**
   * DELETE
   * @param id
   */
  deletePlantillaRiesgo(id) {
    const url = this.apiUrl + this.plantillas + `${id}/`;

    return this.request('DELETE', url, [], 'La evaluación')
  }

  // Tipo Plantillas Riesgo
  /**
   * GET
   * @param page 
   * @param page_size 
   * @returns 
   */

  obtenerTipoPlantillasRiesgo(filters) {
    const url =
      this.apiUrl + this.tipo_plantillas;

    return this.request('GET', url, [], 'Tipo Plantilla', true, filters)
  }
  /**
   * PATCH
   * @param row 
   * @returns 
   */
   pathTipoPlantillasRiesgo(row:any){
    const url = this.apiUrl + this.tipo_plantillas + `${row.id}/`;
    
    return this.request('PATCH', url, row, 'Tipo')
  }
  // Variables Riesgo
  /**
   * GET
   * @param page 
   * @param page_size 
   * @param tipo_id tipo de plantilla
   * @returns 
   */
  obtenerVariablesRiesgo(filters) {
    const url =
      this.apiUrl + this.variables_riesgo
    

    return this.request('GET', url, [], 'Variable', true, filters)
  }
  // Variables Riesgo
  /**
   * GET
   * @param page 
   * @param page_size 
   * @param tipo_id tipo de plantilla
   * @returns 
   */
  obtenerVariablesRiesgoOnlyDescripcion(page: number = 1, page_size: number = 10, tipos: any[] = null) {
    const url =
      this.apiUrl + this.variables_riesgo + `only_descripcion/?page_size=${page_size}`
      + `&page=${page}` 
      + (tipos!=null ? `&tipos=${tipos}` : '');
    

    return this.request('GET', url)
  }
  /**
   * GET
   * @param id 
   * @returns 
   */
   obtenerVariableRiesgo(id) {
    const url =
      this.apiUrl + this.variables_riesgo + `${id}/`;

    return this.request('GET', url)
  }
   /**
   * GET
   * @param id 
   * @returns 
   */
  cargarValoresDinamicosVariableRiesgo(id){
    const url =
      this.apiUrl + this.variables_riesgo + `${id}/generar_valores`;

    return this.request('GET', url, [], 'Valor', true)
  }
  /**
   * POST
   * @param row data a registrar
   * @returns 
   */
  postVariableRiesgo(row:any) {

    const url = this.apiUrl + this.variables_riesgo;

    return this.request('POST', url, row, 'Variable')
  }
  /**
   * PATCH
   * @param row 
   * @returns 
   */
  pathVariableRiesgo(row){
    const url = this.apiUrl + this.variables_riesgo + `${row.id}/`;
    
    return this.request('PATCH', url, row, 'Variable', true, {})
  }
  /**
   * DELETE
   * @param id 
   * @returns 
   */
  deleteVariableRiesgo(id) {
    const url = this.apiUrl + this.variables_riesgo + `${id}/`;

    return this.request('DELETE', url, [], 'Variable')
  }

  // Valores Riesgo
  /**
   * GET
   * @param page 
   * @param page_size 
   * @param variable_riesgo 
   * @returns 
   */
  obtenerValoresRiesgo(variable_riesgo:number, page: number = 1, page_size: number = 10) {
    const url =
      this.apiUrl + this.valores_riesgo + `?page_size=${page_size}` + `&page=${page}` 
      + `&variable=${variable_riesgo}`;

    return this.request('GET', url)
  }
  // Periodos
  /**
   * POST
   */
  nuevoPeriodo(row:any){
    const url =
      this.apiUrl + this.periodos
      return this.request('POST', url, row , 'Periodo')
  }

  validar_evaluaciones(id:any){
    const url =
      this.apiUrl + this.periodos + `${id}/validar_evaluaciones/`
      return this.request('POST', url, [], 'Evaluaciones', false)
  }
  generar_historial_evaluaciones(id:any){
    const url =
    this.apiUrl + this.periodos + `${id}/generar_historial_evaluaciones/`
    return this.request('POST', url, [], 'Historial', false)
  }
  cerrarPeriodo(id: any){
    const url =
    this.apiUrl + this.periodos + `${id}/cerrar/`
    return this.request('POST', url, [], 'Periodo')
  }
  generarEvaluaciones(id: any){
    const url =
      this.apiUrl + this.periodos + `${id}/generar_evaluaciones/`
      return this.request('POST', url, [], 'Evaluaciones', false)
  }
  cantidad_plantillas(id: any){
    const url =
    this.apiUrl + this.periodos + `${id}/cantidad_plantillas/`
    return this.request('GET', url, [], 'Las Plantillas', false)
    
  }


  obtenerPeriodos(filters: Record<string, string>){
    const url = this.apiUrl + this.periodos
      return this.request('GET', url, [], 'Periodos', true, filters)
  }
  obtenerPeriodosSimple(filters: Record<string, string>){
    const url = this.apiUrl + this.periodos + 'simple/'
      return this.request('GET', url, [], 'Periodos', true, filters)
  }

  
  obtenerPeriodo(id: any){
    const url =
      this.apiUrl + this.periodos + `/${id}`
    return this.request('GET', url)
  }

  eliminarValores(row: any){
    const url =
      this.apiUrl + this.valores_riesgo + `eliminar_valores/`
    return this.request('POST', url, row, 'Valores', false)
  }


  obtenerConfiguracion(id: any){
    const url =
    this.apiUrl + this.configuracion + `${id}/`
  return this.request('GET', url)
  }

  obtenerConfiguraciones(filters){
    var filtros_string: string = '';
    if (filters) {
     let keys = Object.keys(filters);
     keys.forEach(key => {
      filtros_string += `&${key}=${filters[key]}`
     }); 
    }
    const url =
    this.apiUrl + this.configuracion + `?page_size=${filters['page_size']}` + `&page=${filters['page']}` + filtros_string;

  return this.request('GET', url)
  }

  editarConfiguracion(row: any){
    const url =
    this.apiUrl + this.configuracion + `${row.id}/`
  return this.request('PATCH', url, row, 'Configuracion')
  }

  // Servicios Reportes

  /**
   * GET
   * @param page
   * @param page_size
   * @param filters
   * @returns 
   */
   obtenerReportes(filters) {

    const url =
      this.apiUrl + this.reportes
      ;
    return this.request('GET', url, [], 'Reporte', true, filters)
    
  }
  /**
   * GET
   * @param id 
   * @returns 
   */
   obtenerReporte(id: any) {
    const url =
      this.apiUrl + this.reportes + `${id}/`;
    return this.request('GET', url)
  }
  
  /**
   * POST
   * @param row data a registrar
   * @returns 
   */
  postReporte(row: any) {
    const url = this.apiUrl + this.reportes;

    return this.request('POST', url, row, 'Evaluación')
  }
  
  /**
   * PATCH
   * @param row 
   * @returns 
   */
   pathReporte(row: any){
    const url = this.apiUrl + this.reportes + `${row.id}/`;
    return this.request('PATCH', url, row, 'La evaluación')
  }
  /**
   * DELETE
   * @param id
   */
  deleteReporte(id) {
    const url = this.apiUrl + this.reportes + `${id}/`;

    return this.request('DELETE', url, [], 'La evaluación')
  }

  obtenerMatrices() {
    const url = this.apiUrl + this.matrices;
    return this.request('GET', url)
  }

  obtenerMatrizRiesgo(id) {
    const url = this.apiUrl + this.matrices + `${id}/`;
    return this.request('GET', url)
  }
  
  pathMatrizRiesgo(row: any){
    const url = this.apiUrl + this.matrices + `${row.id}/`;
    return this.request('PATCH', url, row, 'Matriz de riesgo')
  }

  obtenerTiposReporte() {
    const url = this.apiUrl + this.tipo_reportes;
    return this.request('GET', url)
  }

  exportarCalificaciones(id) {
    const url = this.apiUrl + this.periodos + `${id}/exportar/`;
    const httpOptions = {
      responseType: 'blob'
    };
    return this.request('POST', url, [], 'Excel', true, {}, httpOptions)
  
  }
  
  /**
   * POST
   * @param row data a registrar
   * @returns 
   */
  postExcelPreviewImportacion(id:number, row: any) {
    const url = this.apiUrl + `${this.base}periodos/${id}/preview_importar_excel/`;

    return this.request('POST', url, row, 'Previsualizar excel', true, {})
  }
  
  /**
   * POST
   * @param row data a registrar
   * @returns 
   */
  postExcelImportacion(id:number, row: any) {
  const url = this.apiUrl + `${this.base}periodos/${id}/importar_excel/`;

  return this.request('POST', url, row, 'Importar excel')
  }

  /**
   * PATCH
   * @param row 
   * @returns 
   */
  pathZonasRiesgo(row: any){
    const url = this.apiUrl + this.zonas_riesgo + `${row.id}/`;
    return this.request('PATCH', url, row, 'Zonas de riesgo')
  }

  obtenerZonaRiesgo(filters: Record<string, string>) {
    const url = this.apiUrl + this.zonas_riesgo;
    return this.request('GET', url, [], 'Zonas de Riesgo', true, filters)
  }

  getZonaRiesgo(id) {
    const url = this.apiUrl + this.zonas_riesgo + `${id}/`;
    return this.request('GET', url)
  }
}
  
