import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppService } from "app/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastService } from "../toast/toast.service";
@Injectable({
  providedIn: 'root'
})
export class DocumentosCarteraService {
  private apiUrl: string = "";
  public documents: string = "factoring/carteras/";
  public carterasComentarios: string = "factoring/carteras/comentarios/";
  public carterasCabecera: string = "factoring/carteras/cabecera/";
  public bandejaRecaudaciones: string = "factoring/recaudaciones/bandeja/";
  public bandejaProrrogas: string = "factoring/prorrogas/bandeja/";
  public carteraSimulacion: string = "factoring/carteras/cabecera/tipo_cambio/simulacion/"

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
  }
  /**
 * Habilita el loader para request a la API
 */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();
    this.appService.notifyMe(mensaje, ok)

    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);
  }

  /**
   * 
   * @param page 
   * @param page_size 
   * @param beneficiario_ruc_nombre__icontains 
   * @param deudor_ruc_nombre__icontains 
   * @param operacion_detalle__numero_documento__icontains 
   * @param monto_neto__range 
   * @param estado__in 
   * @param operacion_detalle 
   */
  obtenerDocumentosCartera(
    page                                             : number = 1,
    page_size                                        : number = 10,
    beneficiario_ruc_nombre__icontains               : string = '',
    deudor_ruc_nombre__icontains                     : string = '',
    operacion_detalle__numero_documento__icontains   : string = '',
    monto_neto__range                                : string = '',
    estado__in                                       : string = '',
    operacion_detalle                                : string = '',
    fecha_vencimiento__gte                           : string = '',
    fecha_vencimiento__lte                           : string = '',
    operacion__asistente_cobranzas__nombre__icontains: string = '',
    oficial_negocio                                  : string = '',
    empresa                                          : string = '',
    tipo_linea                                       : string = '',
    lider                                            : string = '',
    usuario_id                                       : number = 0,
    perfil_id                                        : number = 0,
  ) {
    const url =
      // 'http://127.0.0.1:8000/carteras' + '?protestado=false' +
      this.apiUrl + this.documents + '?protestado=false' +
      `&beneficiario_ruc_nombre__icontains=${beneficiario_ruc_nombre__icontains}` +
      `&aceptante_ruc_nombre__icontains=${deudor_ruc_nombre__icontains}` +
      `&operacion_detalle__numero_documento__icontains=${operacion_detalle__numero_documento__icontains}` +
      `&monto_neto__range=${monto_neto__range}` +
      `&estado__in=${estado__in}` +
      `&operacion=${operacion_detalle}` +
      `&fecha_vencimiento__gte=${fecha_vencimiento__gte}` +
      `&fecha_vencimiento__lte=${fecha_vencimiento__lte}` +
      `&operacion__asistente_cobranzas__nombre__icontains=${operacion__asistente_cobranzas__nombre__icontains}` +
      `&page_size=${page_size}` +
      `&page=${page}` +
      `&empresa=${empresa}` +
      `&tipo_linea=${tipo_linea}` +
      `&oficial_negocio=${oficial_negocio}`+
      `&usuario_id=${usuario_id}`+
      `&perfil_id=${perfil_id}`+
      `&lider=${lider}`;
    console.log(`&estado__in=${estado__in}`)

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La operación que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  obtenerDocumentosCarteraTotales(
    page                                             : number = 1,
    page_size                                        : number = 10,
    beneficiario_ruc_nombre__icontains               : string = '',
    deudor_ruc_nombre__icontains                     : string = '',
    operacion_detalle__numero_documento__icontains   : string = '',
    monto_neto__range                                : string = '',
    estado__in                                       : string = '',
    operacion_detalle                                : string = '',
    fecha_vencimiento__gte                           : string = '',
    fecha_vencimiento__lte                           : string = '',
    operacion__asistente_cobranzas__nombre__icontains: string = '',
    oficial_negocio                                  : string = '',
    empresa                                          : string = '',
    tipo_linea                                       : string = '',
    lider                                            : string = '',
    usuario_id                                       : number = 0,
    perfil_id                                        : number = 0,
  ) {
    const url =
      // 'http://127.0.0.1:8000/carteras' + '?protestado=false' +
      this.apiUrl + this.documents +'total-moneda' +'?protestado=false' +
      `&beneficiario_ruc_nombre__icontains=${beneficiario_ruc_nombre__icontains}` +
      `&aceptante_ruc_nombre__icontains=${deudor_ruc_nombre__icontains}` +
      `&operacion_detalle__numero_documento__icontains=${operacion_detalle__numero_documento__icontains}` +
      `&monto_neto__range=${monto_neto__range}` +
      `&estado__in=${estado__in}` +
      `&operacion=${operacion_detalle}` +
      `&fecha_vencimiento__gte=${fecha_vencimiento__gte}` +
      `&fecha_vencimiento__lte=${fecha_vencimiento__lte}` +
      `&operacion__asistente_cobranzas__nombre__icontains=${operacion__asistente_cobranzas__nombre__icontains}` +
      `&page_size=${page_size}` +
      `&page=${page}` +
      `&empresa=${empresa}` +
      `&tipo_linea=${tipo_linea}` +
      `&oficial_negocio=${oficial_negocio}`+
      `&usuario_id=${usuario_id}`+
      `&perfil_id=${perfil_id}`+
      `&lider=${lider}`;
    //console.log(`&estado__in=${estado__in}`)

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          if (err.error && err.error.operacion) {
            this.spinnerOff(
              "La operación que intenta consultar no existe en los registros",
              false
            );
          } else {
            this.spinnerOff("La operación falló", false);
          }
          ref(err);
        }
      );
    });
  }

  obtenerEstadosCarteras(page: number = 1, page_size: number = 1000) {

    const url = this.apiUrl + this.documents + 'estado/' +
      `?page=${page}` +
      `&page_size=${page_size}`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });

  }

  obtenerComentariosDocumento(documento_cartera, page: number = 1, page_size: number = 1000) {
    const url = this.apiUrl +
      this.carterasComentarios +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&documento_cartera=${documento_cartera}`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  crearComentarioDocumento(body) {
    const url = this.apiUrl +
      this.carterasComentarios;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.post(url, body)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  editarComentarioDocumento(body, id) {
    const url = this.apiUrl +
      this.carterasComentarios + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.put(url, body)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  enviarRecordatorios(body) {
    const url = this.apiUrl +`factoring/beneficiarios/deuda/documentoscartera/recordatorio/`;
    // const url = `http://127.0.0.1:8000/beneficiarios/deuda/documentoscartera/recordatorio/`;
      http://127.0.0.1:8000/carteras
    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.post(url, body)
        .subscribe((response) => {
          this.spinnerOff('Recordatorios enviados con éxito');
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  eliminarcomentarioDocumento(body, id) {
    const url = this.apiUrl +
      this.carterasComentarios + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.delete(url, body)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  obtenerBandejaRecaudoPorDocumentos(documentos_cartera: string, recaudacion__estado = '2,4') {
    const url = this.apiUrl +
      this.bandejaRecaudaciones +
      `?documento_cartera__in=${documentos_cartera}` +
      `&recaudacion_estado__in=${recaudacion__estado}`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  obtenerBandejaRecaudo(documento_cartera, recaudacion__estado = '2') {
    console.log('Ejecuto bandeja recaudo')
    const url = this.apiUrl +
      this.bandejaRecaudaciones +
      `?documento_cartera=${documento_cartera}` +
      `&recaudacion__estado__in=${recaudacion__estado}`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  obtenerBandejaProrroga(documento, prorroga__estado_pagado = 5, prorroga__estado_migrado = 6) {
    const url = this.apiUrl +
      this.bandejaProrrogas +
      `?documento=${documento}` +
      `&prorroga__estado__in=${prorroga__estado_pagado}%2C${prorroga__estado_migrado}`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }


  obtenerCabeceraCartera(documento) {
    const url = this.apiUrl +
      this.carterasCabecera +
      `${documento}/`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }



  modificarCabecera(body, id) {
    const url = this.apiUrl +
      this.carterasCabecera + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.put(url, body)
        .subscribe((response) => {
          this.spinnerOff('Información almacenada con exito');
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  modificarTipoCambio(id, body){

    const url = this.apiUrl + this.carterasCabecera + `${id}/`;

    return new Promise( (res, ref)=>{
      this.spinnerOn()
      this.http.patch( url, body )
        .subscribe((resp)=>{
          this.spinnerOff();
          res(resp)
        }, (err)=>{
          this.spinnerOff();
          ref(err);
        })
    })

  }

  simulacionTipoCambio( id, monto ){

    const url = this.apiUrl + this.carteraSimulacion + `${id}/${monto}`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });

  }
}
