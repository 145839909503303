import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-advertencia-deuda-leasing',
  templateUrl: './advertencia-deuda-leasing.component.html',
  styleUrls: ['./advertencia-deuda-leasing.component.css']
})
export class AdvertenciaDeudaLeasingComponent implements OnInit, OnChanges {

  @ViewChild('modalDetalle', { static: false }) private modalDetalle
  
  @Input() ruc = ''
  @Input() soloBoton = false
  @Output() deudaEvent = new EventEmitter<any>()

  dataDeuda = []

  constructor(
    private factoringService: FactoringService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes) {
    if (changes.ruc) {
      this.loadDeuda()
    }
  }

  get deudaTotal() {
    return this.dataDeuda.reduce((acc, e) => {
      acc[e.moneda] = (acc[e.moneda] || 0) + Number(e.saldo)
      return acc
    }, {})
  }

  get deudaTotalStr() {
    return Object.entries(this.deudaTotal).map(([moneda, total]) => `${new Intl.NumberFormat('en-US').format(Number(total))} ${moneda}`).join(', ')
  }

  get deudaTotalStr2() {
    return Object.entries(this.deudaTotal).map(([moneda, _]) => moneda).join(' y ')
  }

  showDetalle() {
    this.modalService.open(
      this.modalDetalle,
      { size: 'lg', }
    ).result
      .then(result => console.log(result))
      .catch(reason => console.log(reason))
  }

  async loadDeuda() {
    this.dataDeuda = []
    if (this.ruc) {
      try {
        let data: any = await this.factoringService.getDeudaLeasing(this.ruc).toPromise()
        this.dataDeuda = data
      } catch (ex) {
        console.log(ex)
      }
    }

    this.deudaEvent.emit(this.deudaTotal)
  }

}
