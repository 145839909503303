import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class CuentasService {

  private apiUrl: string = '';
  public cuentas: string = 'factoring/cuentas/bandeja/';
  public estados: string = 'factoring/cuentas/estado/';
  public cuentasComentarios: string = 'factoring/cuentas/comentarios/'
  public bandejaRecaudaciones: string = "factoring/recaudaciones/bandeja/";
  public tipoPagador: string = 'factoring/pagos/tipospagador/';
  public tiposProcesos: string = 'factoring/cuentas/procesos/';

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient
  ) {
    this.apiUrl = this.appService.settings.API_base_url
  }

  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show()
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();
    this.appService.notifyMe(mensaje, ok)

    if (mensaje && ok)
      this.toast.success(mensaje);
    if (mensaje && !ok)
      this.toast.warning(mensaje);
  }

  /**
   * Mostros errores recibidos del servidor
   * @param error Error enviado del servidor
   */
  showErrors(error) {
    const err = error.error;

    for (const key in err) {
      if (Object.prototype.hasOwnProperty.call(err, key)) {
        const element = err[key];
        console.log(element);

        if (Array.isArray(element) && element.length) {
          element.forEach(item => {
            this.spinnerOff(item, false);
          });
        } else {
          this.spinnerOff(element, false);
        }

      }
    }
  }

  /**
   * Obtener listado cuentas por cobrar
   * @param page 
   * @param page_size 
   * @param beneficiario 
   * @param deudor 
   * @param estado 
   * @param concepto 
   */
  obtenerCuentas(
    page: number = 1,
    page_size: number = 10,
    beneficiario_deudor_ruc_nombre__icontains: string = '',
    deudor: string = '',
    estado: string = '',
    concepto: string = '',
    tipo_linea: string = '',
    fecha_creacion__range: string = '',
    liquidacion__operacion: string = '',
    tipo_proceso: string = '',
    pagador__iconstains: string = '',
    numero_proceso: string = '',
    fecha_creacion__gte: string = '',
    fecha_creacion__lte: string = '',
    monto__range: string = '',
    empresa: string = ''
  ) {

    const url = this.apiUrl + this.cuentas +
      `?beneficiario_deudor_ruc_nombre__icontains=${beneficiario_deudor_ruc_nombre__icontains}` +
      `&deudor_ruc_nombre__icontains=${deudor}` +
      `&pagador__iconstains=${pagador__iconstains}` +
      `&estado=${estado}` +
      `&concepto=${concepto}` +
      `&tipo_linea=${tipo_linea}` +
      `&fecha_creacion__range=${fecha_creacion__range}` +
      `&fecha_creacion__gte=${fecha_creacion__gte}` +
      `&fecha_creacion__lte=${fecha_creacion__lte}` +
      `&liquidacion__operacion=${liquidacion__operacion}` +
      `&numero_proceso=${numero_proceso}` +
      `&tipo_proceso=${tipo_proceso}` +
      `&monto__range=${monto__range}` +
      `&empresa=${empresa}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }

  /**
   * Obtener cuentar por cobrar por un ID especifico
   * @param id 
   */
  obtenerCuenta(id) {
    const url = this.apiUrl + this.cuentas + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff('La operación falló', false);
          ref(err);
        });
    });
  }

  /**
   * 
   * @param data 
   * @param clienteId 
   */
  guardarCliente(data, clienteId = 0) {

    const url = (clienteId) ? this.apiUrl + this.cuentas + `${clienteId}/` : this.apiUrl + this.cuentas;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (clienteId) {
        this.http.patch(url, data)
          .subscribe((response) => {
            this.spinnerOff('La cuenta ha sido actualizada exitosamente');
            res(response);
          }, (err) => {
            this.spinnerOff('La operación falló', false);
            ref(err);
          });
      } else {
        this.http.post(url, data)
          .subscribe((response) => {
            this.spinnerOff('La cuenta ha sido registrada exitosamente');
            res(response);
          }, (err) => {
            this.spinnerOff('La operación falló', false);
            ref(err);
          });
      }
    });
  }

  /**
   * 
   * @param page 
   * @param page_size 
   */
  obtenerEstados(
    page: number = 1,
    page_size: number = 1000,
  ) {
    const url = this.apiUrl + this.estados +
      `?page=${page}&page_size=${page_size}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff('La operación falló', false);
          ref(err);
        });
    });

  }

  /**
   * 
   * @param cuenta_cobrar 
   * @param page 
   * @param page_size 
   */
  obtenerComentariosCuentaCobrar(cuenta_cobrar, page: number = 1, page_size: number = 1000) {
    const url = this.apiUrl +
      this.cuentasComentarios +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&cuenta_cobrar=${cuenta_cobrar}`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  /**
   * 
   * @param body 
   */
  crearComentarioCuentaCobrar(body) {
    const url = this.apiUrl +
      this.cuentasComentarios;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.post(url, body)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  /**
   * 
   * @param body 
   * @param id 
   */
  editarComentarioCuentaCobrar(body, id) {
    const url = this.apiUrl +
      this.cuentasComentarios + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.put(url, body)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  /**
   * 
   * @param body 
   * @param id 
   */
  eliminarcomentarioCuentaCobrar(body, id) {
    const url = this.apiUrl +
      this.cuentasComentarios + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.delete(url, body)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  /**
   * 
   * @param cuenta_cobrar 
   */
  obtenerBandejaRecaudo(cuenta_cobrar) {
    const url = this.apiUrl +
      this.bandejaRecaudaciones +
      `?cuenta_cobrar=${cuenta_cobrar}&recaudacion__estado__in=2`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }


  obtenerBandejaRecaudoRecaudos(recaudacion) {
    const url = this.apiUrl +
      this.bandejaRecaudaciones +
      `?recaudacion=${recaudacion}&recaudacion__estado=2`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  /**
   * 
   */
  obtenerTiposPagador() {
    const url = this.apiUrl +
      this.tipoPagador;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  obtenerTiposProcesos() {
    const url = this.apiUrl +
      this.tiposProcesos;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  /**
   * 
   * @param data 
   * @param clienteId 
   */
  guardarCuenta(data) {

    const url = this.apiUrl + this.cuentas

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data)
        .subscribe((response) => {
          this.spinnerOff('La cuenta ha sido registrada exitosamente');
          res(response);
        }, (err) => {
          this.spinnerOff(`La operación falló: ${err.error.operacion}`, false);
          ref(err);
        });
    });
  }

  /**
   * 
   * @param data 
   * @param id 
   */
  modificarCuenta(data, id) {

    const url = this.apiUrl + this.cuentas + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.put(url, data)
        .subscribe((response) => {
          this.spinnerOff('La cuenta ha sido modificada exitosamente');
          res(response);
        }, (err) => {
          this.spinnerOff('La operación falló', false);
          ref(err);
        });
    });
  }

  /**
 * 
 * @param id 
 */
  anularCuentaCobrar(id) {
    const url = this.apiUrl + this.cuentas + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.patch(url, { estado: 3 })
        .subscribe((response) => {
          this.spinnerOff('CXC anulada correctamente');
          res(response)
        }, (err) => {
          this.spinnerOff('Error al intentar anular la CXC', false);
          ref(err);
        });
    });
  }

  modificarTipoCambio(id, body){

    const url = this.apiUrl + this.cuentas + `${id}/`;

    return new Promise( (res, ref)=>{
      this.spinnerOn()
      this.http.patch( url, body )
        .subscribe((resp)=>{
          this.spinnerOff();
          res(resp)
        }, (err)=>{
          if( err.error.detail )this.spinnerOff(err.error.detail, false);

            this.spinnerOff();
          
          ref(err);
        })
    })

  }
}
