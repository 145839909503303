import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface linea {
  text ?     : string
  bold ?     : boolean
  fontSize ? : number
  alignment ?: string
  margin ?   : number[]
  style ?    : string
}

@Injectable({
  providedIn: 'root'
})
export class PdfMakerService {

  constructor() { }

  get plantilla(){
    return {
      content :[
      ]
    }
  }

  open(documentDefinition){
    pdfMake.createPdf(documentDefinition).open();
  }

  download(documentDefinition){
    pdfMake.createPdf(documentDefinition).download();
  }

  openHere(documentDefinition){
    pdfMake.createPdf(documentDefinition).open({},window);
  }

  print(documentDefinition){
    pdfMake.createPdf(documentDefinition).print();
  }
  
}
