import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './auth/auth.service';
import { ToastService } from './toast/toast.service';
import { FactoringService } from "app/core/services/factoring/factoring.service";

@Injectable({
	providedIn: 'root'
})
export class LegalService {
	private apiUrl                 : string = '';
	public contratosNuevo          : string = 'factoring/beneficiarios/lineas/contratos/nuevo/';
	public contratosDocumentosTipo : string = 'factoring/beneficiarios/lineas/contratos/documentos/tipos/';
	public contratos               : string = 'factoring/beneficiarios/lineas/contratos/';
	public lineasDeCredito         : string = 'factoring/beneficiarios/lineas/';
    public contratoDocumentos   : string = 'factoring/beneficiarios/lineas/contratos/documentos/';
    public operacionesContrato  : string = 'factoring/operaciones/contrato/'
    public contratoFirmantes    : string = 'factoring/contratos/firmantes/'
    public firmantesTipos       : string = 'factoring/contratos/firmantes/tipos/'
    public beneficiarioFirmantes: string = '/factoring/contactos/'
	
	constructor(
		public appService: AppService,
		public toast     : ToastService,
		public spinner   : NgxSpinnerService,
		public http      : HttpClient,
		public auth      : AuthService,
		public factoring: FactoringService,
	) {
        this.apiUrl = this.appService.settings.API_base_url
	 }
	
	
	/**
	* Habilita el loader para request a la API
	*/
	spinnerOn(){
		this.spinner.show()
	}
	
	/**
	* Desabilita el loader
	* @param mensaje Mensaje del toast
	* @param ok Tipo de mensaje, TRUE para success, FALSE para errores
	*/
	spinnerOff(mensaje: string = null, ok : boolean = true){
		this.spinner.hide()
        this.appService.notifyMe(mensaje,ok)
		
		if(mensaje && ok)
		this.toast.success(mensaje)
		if(mensaje && !ok)
		this.toast.warning(mensaje)
	}



	/**
	 * Listar los documentos adjuntados al contrato
	 * @param idContrato Id contrato
	 * @param documento Tipo documento
	 */
    obtenerDocumentosContrato(idContrato,documento = ''){
        const URL = this.apiUrl+
                this.contratoDocumentos+
                `?contrato=${idContrato}`+
                `&documento=${documento}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}	
	

	/**
	 * Validar si la operación necesita un nuevo contrato
	 * @param idOperacion Id de la operacion
	 */	
	obtenerContrato(idLinea){
        const URL = `${this.apiUrl}${this.contratos}${idLinea}/`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}

	/**
	 * Listar tipos de documentos de contratos
	 */	
	obtenerTiposDocumentosContrato(){
        const URL = `${this.apiUrl}${this.contratosDocumentosTipo}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}	



	listarContratos(linea_credito, vigencia = true){
		const URL = `${this.apiUrl}${this.contratos}`+
					`?linea_credito=${linea_credito}`+
					`&vigente=${vigencia}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}	
	
	crearContrato(body){
		const URL = `${this.apiUrl}${this.contratos}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.post(URL,body);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}	
	
	actualizarcontrato(body,idContrato){
		const URL = `${this.apiUrl}${this.operacionesContrato}${idContrato}/`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.put(URL,{contrato:body});
            httpRequest.subscribe(data => {
                this.spinnerOff('La operación fue grabada con éxito')
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}	
	
	
	listarLineaCredito(beneficiario = ''){
		const URL = `${this.apiUrl}${this.lineasDeCredito}`+
			`?beneficiario=${beneficiario }`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}
    
    ObtenerDataResumen(idOperacion = ''){
		const URL = `${this.apiUrl}factoring/operaciones/${idOperacion}/hoja_resumen`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}


    buscarBeneficiario(beneficiario = ''){
		const URL = `${this.apiUrl}factoring/beneficiarios/${beneficiario}/`
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}	

    buscarOperacion(operacion = ''){
		const URL = `${this.apiUrl}factoring/operaciones/${operacion}/`
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}	

    hojaResumenFormato(body){
		const URL = `${this.apiUrl}factoring/formato_documentos/hoja_resumen/`
        const httpOptions = {
            responseType: 'blob' as 'json'
        }
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.post(URL,body,httpOptions);
            httpRequest.subscribe(data => {
                console.log(data)
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}	
	
	crearLineaCredito(body){
		const URL = `${this.apiUrl}${this.lineasDeCredito}`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.post(URL,body);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
	}	
	
	actualizarLineaCredito(body){
		const URL = `${this.apiUrl}${this.lineasDeCredito}${body.id}/`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.put(URL,body);
            httpRequest.subscribe(data => {
                this.spinnerOff('La operación fue procesa con éxito')
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }		
    
    buscarLineaCredito(idLinea){
		const URL = `${this.apiUrl}${this.lineasDeCredito}${idLinea}/`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }	   
    
	async agregarDocumentoContrato(body){
	    this.spinnerOn()
		const apiBase = await this.factoring.validarPesoAdjunto(body.get('peso'), this.apiUrl);
		const URL = `${apiBase}${this.contratoDocumentos}`;
        body.delete('peso');
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.post(URL,body);
            httpRequest.subscribe(data => {
                this.spinnerOff('El documento fue  cargado con éxito')
                resolve(data);
            }, (err) => {
                let msg = 'Ocurrió un error durante la carga del archivo'
                if (err.error && typeof (err.error) == 'object') {
                    msg = Object.values(err.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
                }
                this.spinnerOff(msg, false)
                reject(err);
            });
            
        });
    }	
    
	actualizarDocumentoContrato(body,idDocumento){
		const URL = `${this.apiUrl}${this.contratoDocumentos}${idDocumento}/`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.put(URL,body);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }	   
    
	obtenerOperacionContrato(idDocumento){
		const URL = `${this.apiUrl}${this.operacionesContrato}${idDocumento}/`;

        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }	  
    
    
	actualizarBodyContrato(body,idContrato){
		const URL = `${this.apiUrl}${this.contratos}${idContrato}/`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.put(URL,body);
            httpRequest.subscribe(data => {
                this.spinnerOff('La operación fue grabada con éxito')
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }	    
    
	obtenerFirmantes(contrato = '',firmante = ''){
		const URL = `${this.apiUrl}${this.contratoFirmantes}?contrato=${contrato}`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }  

	agregarFirmante(body){
		const URL = `${this.apiUrl}${this.contratoFirmantes}`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.post(URL,body);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }   
    
    eliminarFirmante(id){
		const URL = `${this.apiUrl}${this.contratoFirmantes}${id}/`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.delete(URL);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }   
    
    obtenerTiposFirmantes(){
		const URL = `${this.apiUrl}${this.firmantesTipos}`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });        
    }

    obtenerFirmantesBeneficiario(beneficiario = '',nombre__icontains='',representante_legal = true,poder = true,es_firmante = true){
        
        const URL = `${this.apiUrl}${this.beneficiarioFirmantes}?beneficiario=${beneficiario}&es_firmante=${es_firmante}`;
        
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });        
    }    

	editarFirmante(id,body){
		const URL = `${this.apiUrl}${this.contratoFirmantes}${id}/`;
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.patch(URL,body);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, (err) => {
                reject(err);
            });
            
        });
    }      
    
    
    obtenerMetodizado(body){
		const URL = `${this.apiUrl}factoring/reporte_tributario/xml/`;
        const httpOptions = {
            responseType: 'blob' as 'json'
        }
        this.spinnerOn()
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.post(URL,body,httpOptions);
            httpRequest.subscribe(data => {
                this.spinnerOff()
                resolve(data);
            }, async (err) => {
                let msg = 'Ocurrió un error durante la carga del archivo'
                let error = undefined
                try {
                    error = await new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = () => {
                          resolve(JSON.parse(reader.result.toString()))
                        }
                        reader.onerror = reject
                        reader.readAsText(err.error)
                    })
                } catch {}
                if (error && typeof (error) == 'object') {
                    msg = Object.values(error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
                }
                this.spinnerOff(msg, false)
                reject(err);
            });
            
        });
    }      
}
	