import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { AddCuentasAbonoComponent } from 'app/modules/clientes/modals/add-cuentas-abono/add-cuentas-abono.component';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-cuentas-abono',
  templateUrl: './cuentas-abono.component.html',
  styleUrls: ['./cuentas-abono.component.css']
})
export class CuentasAbonoComponent implements OnInit {

  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
  public searchstring     : string = '';

  @Input() clienteId = 0;
  @Input() readonly = false;

  constructor(
    private clientesService	: ClientesService,
    public modalService     : NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerCuentasAbono();
  }

  obtenerCuentasAbono() {
    this.clientesService.obtenerCuentasAbonos(this.clienteId.toString())
    .then(res => {
      this.initVars();
			this.loadTable(res);
    })
    .catch(error => {
      console.error(error);
    })
  }

  loadTable(data) {

		this.rowData           = data.results;
		this.pageTableActual   = data.page_number;
		this.pageTableTotal    = data.count;
		this.pageTablePerPages = data.per_page;
		this.pageTablePages = [];
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index);
		}

	}

  initVars() {
		let columns = [
			{
				headerName: 'N°.', 
				field     : 'id' ,
				sortable  : true,
			},
			{
				headerName: 'Banco', 
				field     : 'entidad_financiera_nombre' ,
				sortable  : true,
			},
			{
				headerName: 'Moneda', 
				field     : 'moneda_descripcion' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Número de Cuenta', 
				field     : 'numero_cuenta' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Válido', 
				field     : 'numero_valido' ,
				sortable  : true,
				class     : 'text-center',
				pipe: 'functionInnertHTML',
				function: row => this.getLabelValidacion(row['numero_valido'], row['fecha_numero_validado'])
			},
			{
				headerName: 'Fecha validación', 
				field     : 'fecha_numero_validado' ,
				sortable  : true,
				class     : 'text-center',
				pipe: 'date-time'
			},
			{
				headerName: 'CCI', 
				field     : 'numero_cuenta_cci' ,
				sortable  : true,
			},
			{
				headerName: 'Válido', 
				field     : 'cci_valido' ,
				sortable  : true,
				class     : 'text-center',
				pipe: 'functionInnertHTML',
				function: row => this.getLabelValidacion(row['cci_valido'], row['fecha_cci_validado'])
			},
			{
				headerName: 'Fecha validación', 
				field     : 'fecha_cci_validado' ,
				sortable  : true,
				class     : 'text-center',
				pipe: 'date-time'
			},
		];

		this.columnDefs = columns;
	}

	getLabelValidacion(valido, fecha) {
		let icon = ''
		let texto = ''
		if (valido === true) {
			icon = 'fa-check text-success'
			texto = 'Válido'
		} else if (valido === false) {
			icon = 'fa-close text-danger'
			texto = 'Inválido'
		} else if (!!fecha) {
			icon = 'fa-warning text-warning'
			texto = 'No se puede validar'
		}
		return `
			<i class="fa ${icon}"></i>
			<span>${texto}</span>
		`
	}

  goToPage({page,per_page,filtros}) {
    this.obtenerCuentasAbono();
  }

  tableEditEvent(cuentaAbono) {
    const modalRef = this.modalService.open(AddCuentasAbonoComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;
		modalRef.componentInstance.cuentaAbonoId = cuentaAbono.id;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerCuentasAbono();
		},
		error => {

		});
  }

  destroyContact(cuentaAbono) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover cuenta de abono';
		modalRef.componentInstance.message = '¿Seguro que desea remover esta cuenta?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.clientesService.elimiarCuentaAbono(cuentaAbono.id)
			.then(result => {
			this.obtenerCuentasAbono();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  openModal() {
    const modalRef = this.modalService.open(AddCuentasAbonoComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;

		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerCuentasAbono();
		},
		error => {

		});
  }

}
