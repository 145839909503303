import { Component, OnInit } from "@angular/core";
import { faChartPie, faPlus, faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { FactoringService } from "app/core/services/factoring/factoring.service";
import { ProrrogasService } from "app/core/services/factoring/prorrogas.service";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { AddProrrogaModalComponent } from "../../modals/add-prorroga-modal/add-prorroga-modal.component";

@Component({
  selector: "app-prorrogas",
  templateUrl: "./prorrogas.component.html",
  styleUrls: ["./prorrogas.component.css"],
})
export class ProrrogasComponent implements OnInit {
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = "";
  public canCreate: boolean = false;
   faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  public amoneda = 1;
  public bmoneda = 2;

  constructor(
    public modalService: NgbModal,
    private authService: AuthService,
    private prorrogasService: ProrrogasService,
    private factoringService: FactoringService,
  ) {
    this.user = this.authService.user;
  }

  ngOnInit(): void {
    this.obtenerProrrogas(1, 10, "", "", "", "", "");
  }

  /**
   *
   * @param page
   * @param page_size
   */
  async obtenerProrrogas(
    page = 1,
    page_size = 10,
    solicitante = "",
    tipo = "",
    fecha_solicitud__gte = '',
    fecha_solicitud__lte = '',
    fecha_vencimiento__gte = "",
    fecha_vencimiento__lte = "",
    estado = "",
    comite = ""
  ) {
    //this.loadingTable = true
     let documents = await this.prorrogasService.obtenerProrrogas(
      page,
      page_size,
      solicitante,
      tipo,
      fecha_solicitud__gte,
      fecha_solicitud__lte,
      fecha_vencimiento__gte,
      fecha_vencimiento__lte,
      estado,
      comite
    );
    let estados      = this.prorrogasService.obtenerEstados();
    let tiposPagador = this.prorrogasService.obtenerTiposPagador();
    let listaComites = this.prorrogasService.obtenerListaComites();
    let empresas     = this.factoringService.obtenerEmpresas()

    await Promise.all([documents, estados, tiposPagador, listaComites, empresas]).then(
      (res: any[]) => {
        let documents = res[0];
        let estados = res[1]["results"];
        let tiposPagador = res[2]["results"];
        let comites = res[3]["results"];
        let empresas = res[4]['results'];
        this.initVars(estados, tiposPagador, comites, empresas);
        this.loadTable(documents);
      }
    );
    //this.loadingTable = false
  }

  loadTable(data) {

    // this.rowData = data.results?.map((el: any, i: number) => ({
    //   ...el,
    //   moneda_comisiones: 1,
    // }));

    data.results.map( (element, indice) => {
    
      let a = (indice+1) + (data.page_number * data.per_page) - data.per_page;
      let b = data.per_page*data.page_number;

      // element.moneda_comisiones = 1;

      if( a <= b ){
        
        element.index = a;

      }

    });

    this.rowData = data.results;

    this.pageTableActual   = data.page_number;
    this.pageTableTotal    = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages    = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars(estados = [], tipos = [], comites = [], empresas = []) {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Solicitante",
        field: "solicitante_nombre",
        sortable: true,
        filterable: true,
        filterProp: "solicitante",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "solicitante_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "tipo_solicitante_nombre",
        sortable: true,
        filterable: true,
        filterProp: "tipo",
        filterSelectItems: tipos,
        filterItemsProps: {
          value: "id",
          label: "descripcion",
        },
      },
      {
        class: "text-center",
        headerName: "Fecha Solicitud",
        field: "fecha_solicitud",
        pipe: "date",
        sortable: true,
        filterable: true,
        filterDate: true,
      },
      {
        class: "text-center",
        headerName: "Fecha Vencimiento",
        field: "fecha_vencimiento",
        pipe: "date",
        sortable: true,
        filterable: true,
        filterDate: true,
      },
      {
        class: "text-center",
        headerName: "Cant Docs",
        field: "cantidad_docs",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Saldo Docs",
        field: "total_saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Días Prórroga",
        field: "dias_prorroga",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Tasa",
        field: "tasa",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Intereses",
        field: "total_interes",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Comisiones",
        field: "total_comisiones",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Comité",
        field: "comite_nombre",
        sortable: true,
        filterable: true,
        filterProp: "comite",
        filterSelectItems: comites,
        filterItemsProps: {
          value: "id",
          label: "nombre",
        },
      },
      {
        headerName: "Estado",
        field: "estado_descripcion",
        sortable: true,
        class: "text-center",
        filterable: true,
        filterProp: "estado",
        filterSelectItems: estados,
        filterItemsProps: {
          value: "id",
          label: "descripcion",
        },
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros, type }) {

    let fechas = {

      fecha_vencimiento__gte: '',
      fecha_vencimiento__lte: '',
      fecha_solicitud__gte: '',
      fecha_solicitud__lte: ''
    }

    if( type == 'fecha_vencimiento' ){

      fechas.fecha_vencimiento__gte = filtros.fecha__gte ? filtros.fecha__gte.split(' ')[0] : ''
      fechas.fecha_vencimiento__lte = filtros.fecha__lte ? filtros.fecha__lte.split(' ')[0] : ''

    }else{

      fechas.fecha_solicitud__gte = filtros.fecha__gte ? filtros.fecha__gte.split(' ')[0] : ''
      fechas.fecha_solicitud__lte = filtros.fecha__lte ? filtros.fecha__lte.split(' ')[0] : ''

    }

    this.prorrogasService
      .obtenerProrrogas(
        page,
        per_page,
        filtros.solicitante ? filtros.solicitante : "",
        filtros.tipo ? filtros.tipo : "",
        fechas.fecha_solicitud__gte,
        fechas.fecha_solicitud__lte,
        fechas.fecha_vencimiento__gte,
        fechas.fecha_vencimiento__lte,
        filtros.estado ? filtros.estado : "",
        filtros.comite ? filtros.comite : "",
        filtros.empresa ? filtros.empresa : "",
      )
      .then((documents: any) => {
        this.loadTable(documents);
      });
  }

  addProrroga() {
    let modalAddReference = this.modalService.open(AddProrrogaModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
      windowClass: "modalSizeXXL",
    });

    modalAddReference.componentInstance.prorroga = null;
    modalAddReference.result.then(() => {
      this.obtenerProrrogas();
    });
  }

  editarProrroga(row: any) {
    let modalEditReference = this.modalService.open(AddProrrogaModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
      windowClass: "modalSizeXXL",
    });

    modalEditReference.componentInstance.prorroga = row;
    modalEditReference.result.finally(() => this.obtenerProrrogas()); // cuando se cierre el modal, volver a llamar a las prorrogas
  }

  tableEditEvent(row) { }

  formatDate(date) {
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  get canAdd() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilAsistenteCobranzasID,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilAsistenteComercialID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }
}
