import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { RecaudacionService } from "app/core/services/factoring/recaudacion.service";

@Component({
	selector: "app-imprimir-recaudacion",
	templateUrl: "./imprimir-recaudacion.component.html",
	styleUrls: ["./imprimir-recaudacion.component.css"],
})
export class ImprimirRecaudacionComponent implements OnInit {
	public id: number;
	public cabecera: any;
	public excedentes: any
	public pagados: any
	public columnasExcedentes: any[] = [];
	public columnasPagados: any[] = [];
	public saldo: string;

	constructor(
		private activatedRoute: ActivatedRoute,
		public recaudacionService: RecaudacionService
	) {
		this.activatedRoute.params.subscribe((param) => {
			this.id = param.id;
			this.getData();
		});
	}

	ngOnInit(): void {
	}


	getData() {
		let cabecera;
		cabecera = this.recaudacionService.obtenerRecaudacion(this.id);
		let excedente;
		excedente = this.recaudacionService.obtenerExcedentes(this.id);
		let pagados;
		pagados = this.recaudacionService.obtenerDocumentosDetalle(
			this.id.toString()
		);

		Promise.all([cabecera, excedente, pagados])
			.then((res: any[]) => {
				this.cabecera = res[0];
				this.cabecera.monto = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(this.cabecera.monto))

				this.cabecera.fecha_pago = `${this.cabecera.fecha_pago?.split("-")[2]}/${this.cabecera.fecha_pago?.split("-")[1]}/${this.cabecera.fecha_pago?.split("-")[0]
					}`;
				this.excedentes = res[1].results;
				this.pagados = res[2].results;
				console.log(this.excedentes)

				let splitDate = this.cabecera.fecha_registro.split("T");
				let date = splitDate[0].split('-').reverse().join('/');
				this.cabecera.fecha_registro = date

				if (this.cabecera.tipo_pagador_nombre !== "Cliente") {
					res[1].results.map(item => {
						item.aceptante_nombre = item.cliente_nombre;
					})

					res[2].results.map(item => {
						item.aceptante_nombre = item.cliente_nombre;
					})
				}
			})
			.catch((err) => {
				console.warn(err);
			})
			.finally(() => {
				setTimeout(() => {
					window.print()
				}, 3000)
			});
	}
}
