import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { LegalService } from 'app/core/services/legal.service';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { FactoringService } from '../../../../../core/services/factoring/factoring.service';

@Component({
  selector: 'app-formato-hoja-resumen',
  templateUrl: './formato-hoja-resumen.component.html',
  styleUrls: ['./formato-hoja-resumen.component.css']
})
export class FormatoHojaResumenComponent implements OnInit {
  estaciones: any[];
  myForm: FormGroup;
  public firmantes: any[] = [];
  firmanteValue;
  razon_social;
  contrato: any;
  liquidacion: any;
  beneficiarioData: any;
  resumen: any;

  tipo:any;
  
  @Input() beneficiario;
  @Input() operacion;
  @Input() monedaSimbolo;
  @Input() montoOperacion;
  @Input() fecha_vencimiento;

  
  constructor(
    public modalService   : NgbModal,
    public activeModal     : NgbActiveModal,
    public formBuilder    : FormBuilder,
    public formatoService : FormatosService,
    public toast           : ToastService,
    public clientesService: ClientesService,
    public propuestaComercial: PropuestaComercialService,
    public legal: LegalService
  ) { }

  ngOnInit(): void {
    this.createForm();
    let liquidacion = this.propuestaComercial.ObtenerSimulacionDeLiquidacion(this.operacion)
    let lineaCredito = this.legal.listarLineaCredito(this.beneficiario)
    let beneficiario = this.legal.buscarBeneficiario(this.beneficiario)
    let operacion = this.legal.buscarOperacion(this.operacion)
    let dataResumen = this.legal.ObtenerDataResumen(this.operacion)
    Promise.all([
      liquidacion,
      lineaCredito,
      beneficiario,
      operacion,
      dataResumen]).then((res: any[])=>{
      this.liquidacion  = res[0]
      let operacionData = res[3]
      this.tipo = res[3].tipo_linea_solicitada;
      this.resumen = res[4]
      if(res[1]['results'].length > 0){
        let contratoFactoring = res[1]['results'].find((cont)=> cont.tipo == operacionData.tipo_linea_solicitada)
        this.contrato         = contratoFactoring
        
      }
      this.beneficiarioData = res[2]
      this.initForm()
    })
  }

  createForm() {
    this.myForm = this.formBuilder.group({
      nro_contrato     : [null,[]],
      cliente          : [null,[]],
      tea_nacional     : [null,[]],
      tea_internacional: [null,[]],
      monto            : [null,[]],   
      archivo_plantilla: [null,[]],      
    });
  }

  initForm(){
    if(this.resumen.nro_contrato){
      this.myForm.get('nro_contrato').setValue(this.resumen.nro_contrato)
      this.myForm.get('nro_contrato').disable()
    }
    this.myForm.get('tea_nacional').setValue((this.resumen.tea_soles).toFixed(2))
    this.myForm.get('tea_internacional').setValue((this.resumen.tea_dolares).toFixed(2))
    this.myForm.get('cliente').setValue(this.resumen.beneficiario_nombre)
    this.myForm.get('monto').setValue((this.resumen.monto_linea).toFixed(2))
    this.myForm.get('archivo_plantilla').setValue( this.tipo === 1 ? "words/hoja_resumen.docx" : "words/hoja_resumen_factoring.docx")

    this.myForm.get('tea_nacional').disable()
    this.myForm.get('tea_internacional').disable()
    this.myForm.get('cliente').disable()
    this.myForm.get('monto').disable()
    this.myForm.get('archivo_plantilla').disable()

  }

  onSubmit() {

    let valores = {
      archivo_plantilla: this.myForm.get('archivo_plantilla').value,
      cliente          : this.myForm.get('cliente').value,
      nro_contrato     : this.myForm.get('nro_contrato').value,
      tea_internacional: this.myForm.get('tea_internacional').value,
      tea_nacional     : this.myForm.get('tea_nacional').value,
      fecha_vencimiento: this.fecha_vencimiento,
      monto            : this.myForm.get('monto').value,
      simbolo          : this.monedaSimbolo,
   
    }
    
    this.legal.hojaResumenFormato(valores).then((res: any)=>{
      console.log(res)
      var blob = new Blob([res]);
      // var blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      var downloadURL = window.URL.createObjectURL(blob);
      var link = document.createElement('a');
      link.href = downloadURL;
      link.download = "HojaResumen.docx";
      link.click();
      this.activeModal.close(this.myForm.value);
    }).catch((err)=>{
      console.log(err);
    })

    // this.toast.warning("El formulario no está completo.");
  }

}
