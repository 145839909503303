import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from '../../layouts/admin/admin-layout.component';
import { DocumentosCarteraComponent } from './pages/documentos-cartera/documentos-cartera.component';
import { GestionCobranzasComponent } from './pages/gestion-cobranzas/gestion-cobranzas.component';


const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Documentos Cartera'
    },
    children: [
      {
        path: 'documentos_cartera',
        component: DocumentosCarteraComponent,
        data: {
          breadcrumb: 'Documentos Cartera'
        }
      },
      {
        path: 'documentos_cartera/:cliente/:aceptante',
        component: DocumentosCarteraComponent,
        data: {
          breadcrumb: 'Documentos Cartera'
        }
      },
      {
        path: 'documentos_cartera/ol/:oficial/:lider',
        component: DocumentosCarteraComponent,
        data: {
          breadcrumb: 'Documentos Cartera'
        }
      },
      {
        path: 'gestion_cobranzas',
        component: GestionCobranzasComponent,
        data: {
          breadcrumb: 'Gestión de Cobranzas'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentosCarteraRoutingModule { }
