import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormatosRoutingModule } from './formatos-routing.module';
import { ImprimirCartaModificacionComponent } from './pages/imprimir-carta-modificacion/imprimir-carta-modificacion.component';


@NgModule({
  declarations: [ImprimirCartaModificacionComponent],
  imports: [
    CommonModule,
    FormatosRoutingModule
  ]
})
export class FormatosModule { }
