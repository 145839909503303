import { Injectable, ViewChild } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { UsersModel } from "../../../shared/models/users.model";
import { ToastService } from "../toast/toast.service";
import { AppService } from "app/app.service";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from "@angular/router";
// import { RouteInfo } from "app/sidebar/sidebar.component";
import { map } from 'rxjs/operators';
import { Perfil } from "./Perfil";
import { Usuario } from "./Usuario";
import { SessionNotificationComponent } from "app/shared/session-notification/session-notification.component";
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import { LocalServiceService } from "../local-service.service";
import { DispositivosService } from '../dispositivos/dispositivos.service'

import { AlertValidationComponent } from '../../../shared/alert-validation/alert-validation.component'

import { _ROUTES } from './Routes.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
export const ROUTES: RouteInfo[] = _ROUTES
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  collapse?: string;
  icontype: string;
  isComite?: boolean;
  fontAwesomeIcon?: any,
  accessAllowed?: any[];
  children?: any[];
}

interface campanaNotificaciones {
  id: number;
  mensaje: string;
  bagde: number;
  route?: any;
  elementos?: any;
}

// TODO: Delete on future
let temporalAsistenteCobranzas = [
  'dyagua@totalsf.com.pe',
  'rayala@totalsf.com.pe',
  'mlopezj@totalsf.com.pe',
  'mvillavicencio@totalsf.com.pe'
]

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private userToken: string;
  private apiUrl: string = "";
  private signIn: string = "seguridad/token/";
  private verify: string = "seguridad/token/verify/";
  private refresh: string = "seguridad/token/refresh/";
  private apiSession: string = "seguridad/session/";
  // private users: string = "seguridad/usuarios/";
  // private perfiles: string = "seguridad/perfiles/";
  private comiteIntegrante: string = "factoring/comites/integrantes/";
  public userIn: Usuario;
  public operacionSugerenciasBandeja: string = "factoring/sugerencias/bandeja";
  public campanaDeNotificaciones: campanaNotificaciones[] = [];
  public campanaDeOpiniones: any[] = [];
  public perfil: Perfil;
  @ViewChild(SessionNotificationComponent) sessionNotificationComponent: SessionNotificationComponent;
  private displayAlertModal = new Subject<any>();
  private routesAlert = new Subject<any>();
  alertSuscriber$ = this.displayAlertModal.asObservable();
  routesAlert$ = this.routesAlert.asObservable();
  public miembrosDeleQuipo : any[] = [];
  _routes: RouteInfo[];
  public sidebar = new BehaviorSubject( false );

  private userMask = new BehaviorSubject('default message');
  actualUserMask = this.userMask.asObservable();

  public get routes() {
    return this._routes;
  }

  constructor(
    public appService: AppService,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public router: Router,
    private http: HttpClient,
    public localService: LocalServiceService,
    public modalService: NgbModal,
    private dispositivosService: DispositivosService
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
    this.readToken();
  }

  changeUserMask(message: any) {
    this.userMask.next(message)
  }

  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();
    this.appService.notifyMe(mensaje, ok);

    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);
  }

  // deleteUsers(user: any) {
  //   return new Promise((res, rej) => {
  //     this.http.delete(`${this.apiUrl}${this.users}${user}/`).subscribe(
  //       (response) => {
  //         this.toast.success("Se eliminó el usuario correctamente");
  //         res(response);
  //       },
  //       (error) => {
  //         rej(error);
  //       }
  //     );
  //   });
  // }

  // uploadUsers(user: any) {
  //   if (!user.id) {
  //     user.cliente === "" ? delete user.cliente : null;
  //     return new Promise((res, rej) => {
  //       this.http.post(`${this.apiUrl}${this.users}`, user).subscribe(
  //         (response) => {
  //           this.toast.success("Se creó el usuario correctamente");
  //           res(response);
  //         },
  //         (error) => {
  //           if (error.error.password) {
  //             error.error.password.forEach(err => {
  //               this.toast.warning(
  //                 err
  //               );
  //             })
  //           }
  //           else if (error.error.email) {
  //             if (
  //               error.error.email.find((item) =>
  //                 item.includes("email already exists")
  //               )
  //             )
  //               this.toast.warning(
  //                 "El email ingresado ya se encuentra registrado"
  //               );
  //           } else {
  //             this.toast.warning("Ocurrio un error en la creación del usuario");
  //           }
  //           rej(error);
  //         }
  //       );
  //     });
  //   } else {
  //     user.cliente === "" ? delete user.cliente : null;
  //     user.password === "" ? delete user.password : null;
  //     // delete user.password
  //     return new Promise((res, rej) => {
  //       this.http.put(`${this.apiUrl}${this.users}${user.id}/`, user).subscribe(
  //         (response) => {
  //           this.toast.success("Se actualizó el usuario correctamente");
  //           res(response);
  //         },
  //         (err) => {
  //           if (err.error.password) {
  //             err.error.password.forEach(errVal => {
  //               this.toast.warning(
  //                 errVal,
  //                 20000
  //               );
  //             })
  //           } else {
  //             this.toast.warning(
  //               "Ocurrio un error en la actualización del usuario"
  //             );
  //           }
  //           rej(err);
  //         }
  //       );
  //     });
  //   }
  // }

  // updatePassword(user: any) {
  //   return new Promise((res, rej) => {
  //     this.http.put(`${this.apiUrl}${this.users}${user.id}/`, user).subscribe(
  //       (response) => {
  //         this.toast.success("Se actualizó el usuario correctamente");
  //         res(response);
  //       },
  //       (err) => {
  //         if (err.error.password) {
  //           err.error.password.forEach(errVal => {
  //             this.toast.warning(
  //               errVal,
  //               20000
  //             );
  //           })
  //         } else {
  //           this.toast.warning(
  //             "Ocurrio un error en la actualización del usuario"
  //           );
  //         }
  //         rej(err);
  //       }
  //     );
  //   });
  // }

  login(usuario: UsersModel) {
    return (
      new Promise((resolve, rejects) => {
        this.http.post(`${this.apiUrl}${this.signIn}`, usuario).subscribe(
          (response) => {
            this.writeToken(response["access"]);
            this.localService.setJsonValue("refresh", response["refresh"]);
            resolve(response);
          },
          (err: any) => {
            if (err.status == 401 || err.status === 403) {
              if (err.error['detail']) {
                this.toast.warning(
                  err.error['detail']
                );
              }
            }
            if (err.error['contraseña']) {
              this.toast.warning(
                err.error['contraseña'][0]
              );
            }
            rejects(err);
          }
        );
      })
        /**
         * Luego de ejecutar el login
         * returna un token de acceso
         */
        .then((response) => {
          return this.getSession(response["access"]);
        })
        /**
         * Con el token de acceso retornado por el login
         * se obtiene el usuario logueado
         */
        .then((sessionUser: any) => {
          // this.router.navigate(['/dashboard']);
          if (this.checkRestablecerPassword()) {
              this.router.navigate(['/change-password']);
              return null
          } else {
            // setTimeout(()=>{
              this.router.navigate(['/dashboard']);
            // },300)
              return this.comiteIntegrantes(sessionUser.id);
          }
        })
        .then((response: any) => {
          if (!this.checkRestablecerPassword()) return this.getNotifications();
        })
        .catch((err: any) => {
          throw new Error(JSON.stringify(err));
        })
    );
  }

  comiteIntegrantes(integrante, comite = "", page = 1, page_size = 1000) {
    return new Promise((resolve, reject) => {
      const url =
        this.apiUrl +
        this.comiteIntegrante +
        `?integrante=${integrante}` +
        `&comite=${comite}` +
        `&page=${page}` +
        `&page_size=${page_size}`;

      this.http.get(url).subscribe(
        (response: any) => {
          this.updateUser("comite", response.results);
          resolve(response);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /**
   * Devuelve los usuarios registrados
   * @param page //Pagina del query
   * @param page_size //Cantidad de registros por pagina
   * @param perfil //ID del perfil del usuario
   * @param estado //ID del estado del usuario
   * @param cliente //ID del cliente/beneficiario del usuario
   * @param fecha_creacion__range //Rango de fechas en las que fue creado
   */
  // obtenerUsuarios(
  //   page: any = 1,
  //   page_size: any = 10,
  //   perfil: any = "",
  //   estado: any = "",
  //   cliente: any = "",
  //   fecha_creacion__range: string = ""
  // ) {
  //   const url =
  //     this.apiUrl +
  //     this.users +
  //     `?cliente=${cliente}` +
  //     `&page=${page}` +
  //     `&page_size=${page_size}` +
  //     `&perfil=${perfil}` +
  //     `&estado=${estado}` +
  //     `&fecha_creacion__range=${fecha_creacion__range}`;

  //   return new Promise((res, ref) => {
  //     this.spinnerOn();
  //     this.http.get(url).subscribe(
  //       (response) => {
  //         this.spinnerOff();
  //         res(response);
  //       },
  //       (err) => {
  //         ref(err);
  //       }
  //     );
  //   });
  // }

  obtenerUsuariosResponsables(
    page: any = 1,
    page_size: any = 10,
    perfil: any = "",
    estado: any = "",
  ) {
    const url =
      this.apiUrl +
      `factoring/responsables` +
      `?rol=${perfil}` +
      `&page=${page}` +
      `&page_size=${page_size}` +
      `&perfil=${perfil}` +
      `&estado=${estado}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  obtenerLideres(
    page: any = 1,
    page_size: any = 1000,
  ) {
    const url =
      this.apiUrl +
      `factoring/responsables/lideres` +
      `?` +
      `page=${page}` +
      `&page_size=${page_size}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  updateLider(
    id,
    body,
  ) {
    const url =
      this.apiUrl +
      `factoring/responsables/${id}/`

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.patch(url,body).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  obtenerSupervisados(
    page     : any = 1,
    page_size: any = 10,
    lider    = '',
    estado   = '',
    email    = ''
  ) {
    const url =
      this.apiUrl +
      'factoring/supervisados' +
      `?lider=${lider}` +
      `&page=${page}` +
      `&page_size=${page_size}` +
      `&email=${email}` +
      `&estado=${estado}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  asignarLider(
    user
  ) {
    const url =
      this.apiUrl +
      `factoring/responsables/${user.id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.patch(url,user).subscribe(
        (response) => {
          this.spinnerOff('El equipo ha sido modificado');
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  /**
   * Obtiene un usuario especifico
   * @param id ID del usuario
   */
  // obtenerUsuario(id) {
  //   const url = this.apiUrl + this.users + `/${id}`;

  //   return new Promise((res, ref) => {
  //     this.spinnerOn();
  //     this.http.get(url).subscribe(
  //       (response) => {
  //         this.spinnerOff();
  //         res(response);
  //       },
  //       (err) => {
  //         ref(err);
  //       }
  //     );
  //   });
  // }

  /**
   * Devuelve la lista de clientes registrados
   * @param page Indica la pagina que se desea obtener
   * @param page_size Indica la cantidad de resultados por query
   */
  // obtenerPerfiles(page: number = 1, page_size: number = 30) {
  //   const url =
  //     this.apiUrl + this.perfiles + `?page_size=${page_size}` + `&page=${page}`;

  //   return new Promise((res, ref) => {
  //     this.spinnerOn();
  //     this.http.get(url).subscribe(
  //       (response) => {
  //         this.spinnerOff();
  //         res(response);
  //       },
  //       (err) => {
  //         ref(err);
  //       }
  //     );
  //   });
  // }

  obtenerSupervisadosLider(lider, page: number = 1, page_size: number = 1000) {
    const url =
      this.apiUrl + `factoring/supervisados/` + `?page_size=${page_size}&page=${page}&lider=${lider}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  validacionLider(lider) {
    const url =
      this.apiUrl + `factoring/responsables/${lider}/es_lider`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  sessionRequest(token){
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.apiUrl}${this.apiSession}`, {
          headers: new HttpHeaders().set("Authorization", `Bearer ${token ? this.localService.getJsonValue('token') : token}`),
        })
        .subscribe(
          (res) => {
            this.user = res["usuario"];

            this.localService.setJsonValue('session', res)
            if (!this.dispositivosService.esDispositivoConfiable()) {
              this.modalService.open(AlertValidationComponent, {size:'md', backdrop: 'static'})
            }

            if( this.checkRestablecerPassword() ){
              resolve(this.user);
              return;
            }
              Promise.all([
                this.validacionLider(this.user.id),
                this.obtenerSupervisadosLider(this.user.id),
              ]).then((response: any[])=>{
                let esMiembro = response[0].resultado
                this.miembrosDeleQuipo = response[1].results
                if(esMiembro){
                  let perfil     = new Perfil()
                  this.updateUser('perfil_descripcion','Líder de Equipo')
                  this.updateUser('perfil',perfil.perfilLiderDeEquipo)
                  this.updateUser('equipo',this.miembrosDeleQuipo)
                }  
              resolve(this.user);
              this.spinnerOff()
              }).catch(e => {
                console.error(e)
              })

          },
          (err) => {
            console.log( err )
            if (err.error.code == "token_not_valid") {
              this.displayAlert();
            }

            reject(err);
          }
        );
    });

  }

  getSession(token) {
    return this.sessionRequest(token)
  }

  private writeToken(idToken: string) {
    this.userToken = idToken;
    this.localService.setJsonValue("token", idToken);
  }

  private readToken() {
    if (this.localService.getJsonValue("token")) {
      this.userToken = this.localService.getJsonValue("token");
      this.user = this.localService.getJsonValue("user");
      this.getSession(this.userToken)
      .then((res: any[])=>{
        let sessionUser: any = res
        return this.comiteIntegrantes(sessionUser.id);
      }).then((response: any)=>{
        return this.getNotifications();
      })
    } else {
      this.userToken = "";
    }
    return this.userToken;
  }

  validarLiderDeEquipo(){
    Promise.all([
      this.validacionLider(this.user.id),
      this.obtenerSupervisadosLider(this.user.id),
    ]).then((res:any[])=>{
      let esMiembro = res[0].resultado
      this.miembrosDeleQuipo = res[1].results
      if(esMiembro){
        let perfil = new Perfil()
        this.updateUser('perfil',perfil.perfilLiderDeEquipo)
        this.updateUser('perfil_descripcion','Líder de Equipo')
      }
    })
  }

  public set user(user) {
    this.localService.setJsonValue("user", user);
    this.userIn = new Usuario(user);
    this.perfil = new Perfil(this.userIn);
  }

  public get user() {
    return this.userIn;
  }

  updateUser(prop: string, value: any) {
    return new Promise((resolve:any, reject) => {
      if (this.localService.getJsonValue("user")) {
        let user = this.localService.getJsonValue("user");
        user[prop] = value;
        this.userIn[prop] = value;
        this.localService.setJsonValue("user", user);
        this._routes = this.filterPerfil();
        this.sidebar.next(true)
        //@ts-ignore
        resolve();
      } else {
        reject();
      }
    });
  }

  logout() {
    this.localService.clearToken();
    this.router.navigate(["/login"]);
  }

  getNotifications() {
    let sugerencias = this.bandejaDeSugerencias(this.user.id);

    Promise.all([sugerencias]).then((res: any[]) => {
      let sugerencias = res[0];
      let campana: campanaNotificaciones = {
        id: 1,
        mensaje: `Usted tiene ${sugerencias.count} operaciones que requieren su atención`,
        bagde: sugerencias.count,
        elementos: sugerencias.results,
      };

      this.campanaDeOpiniones = sugerencias.results;
      this.actualizarNotificacion(campana);
    });
  }

  bandejaDeSugerencias(idUser, page_size = 1000) {
    const URL =
      this.apiUrl +
      this.operacionSugerenciasBandeja +
      `?responsable=${idUser}` +
      `&page_size=${page_size}`;

    this.spinnerOn();
    return new Promise((res, ref) => {
      this.http.get(URL).subscribe(
        (response: any) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          ref(err);
        }
      );
    });
  }

  get notifications() {
    return this.campanaDeNotificaciones;
  }

  get solicitudesDeOpinon() {
    return this.campanaDeOpiniones;
  }

  actualizarNotificacion(item) {
    let index = this.campanaDeNotificaciones.findIndex((i) => i.id === item.id);
    if (index >= 0) {
      this.campanaDeNotificaciones.splice(index, 1, item);
    } else {
      this.campanaDeNotificaciones.push(item);
    }
  }

  filterPerfil(rutas: any[] = ROUTES, perfil = this.user.perfil) {
    if (this.user.comite) {
      rutas.forEach((ruta) => {
        if ((this.user.comite.length > 0 || this.user.isGerenteGeneral) && ruta.isComite) {
          ruta.accessAllowed.push(this.user.perfil);
        }
      });
    }

    if(temporalAsistenteCobranzas.includes(this.user.email)){
      console.log("Asignando perfil asistente comercial");
      this.user.perfil = this.user.perfilAsistenteComercialID;
      this.user.perfil_descripcion = "Asistente Comercial";
      this.user.user.perfil = this.user.perfilAsistenteComercialID;
      this.user.user.perfil_descripcion = "Asistente Comercial";
    }

    let rutasFiltradas = rutas.reduce((acc, item) => {
      if (item.accessAllowed.includes(perfil) || this.user.isAdmin) {
        if (item.children) {
          let childs = this.filterPerfil(item.children);
          item.children = childs;
        }
        acc.push(item);
      }      
      return acc;
    }, []);

    // this._routes = rutasFiltradas;
    return rutasFiltradas
  }

  refreshToken() {
    return new Promise((res, rej) => {
      let tokenRefresh = this.localService.getJsonValue("refresh");

      if (!tokenRefresh) return;
      this.http
        .post(`${this.apiUrl}${this.refresh}/`, { refresh: tokenRefresh })
        .subscribe(
          (response: any) => {
            let { access } = response;
            this.writeToken(access);
            location.reload();
            res(response);
          },
          (error) => {
            rej(error);
          }
        );
    });
  }

  displayAlert() {
    this.displayAlertModal.next();
  }

  emitRouteAlert() {
    this.routesAlert.next();
  }

  obtenerEstacionesNumerosAlt(oficialId = "", lider = "", fecha_estacion_desde : Date = null, fecha_estacion_hasta : Date = null){
    let url = this.apiUrl + "factoring/operaciones/contar/?";
    if(oficialId){
      url += `&oficial_negocio=${oficialId}`
    }
    if(lider){
      url += `&lider={${lider}}`
    }
    if(fecha_estacion_desde){
      let tFechaEstacionDesde = `${fecha_estacion_desde.getFullYear()}-${fecha_estacion_desde.getMonth() + 1}-${fecha_estacion_desde.getDate()}`
      url += `&fecha_estacion_desde=${tFechaEstacionDesde}`
    }
    if(fecha_estacion_hasta){
      let tFechaEstacionHasta = `${fecha_estacion_hasta.getFullYear()}-${fecha_estacion_hasta.getMonth() + 1}-${fecha_estacion_hasta.getDate()}`
      url += `&fecha_estacion_hasta=${tFechaEstacionHasta}`
    }

    return new Promise((res, ref) => {
      this.http.get(url).subscribe(
        (response) => { res(response); },
        (err) => {
          this.spinnerOff("La operación ha fallado", false);
          ref(err);
        }
      );
    });
  }

  obtenerCarterasVencidas(oficial_negocio = '', lider = '') {
    const url = this.apiUrl + `factoring/carteras/vigencia/?oficial_negocio=${oficial_negocio}&lider=${lider}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerLineasVencidas(page, per_page, tipo = '1', oficial_negocio = '', lider = '') {
    const url = this.apiUrl + `factoring/beneficiarios/lineas/por_vencer/?page=${page}&per_page=${per_page}&tipo_vencer=${tipo}&oficial_negocio=${oficial_negocio}&lider=${lider}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  concentracionClientes(page = 1, page_size = 10, oficial_negocio = '', lider = '') {
    const url = this.apiUrl + `factoring/carteras/concentracion_clientes/?oficial_negocio=${oficial_negocio}&lider=${lider}&page=${page}&page_size=${page_size}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  concentracionAceptantes(page = 1, page_size = 10, oficial_negocio = '', lider = '') {
    const url = this.apiUrl + `factoring/carteras/concentracion_aceptantes/?oficial_negocio=${oficial_negocio}&lider=${lider}&page=${page}&page_size=${page_size}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerEstaciones() {
    const url = this.apiUrl + `factoring/estaciones/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }


  obtenerProductividad(
    page = 1,
    page_size = 10,
    fecha = '',
    oficial_negocio = '',
    lider = ''
  ) {

    const url = this.apiUrl + `factoring/operaciones/productividad/?page=${page}&page_size=${page_size}&fecha=${fecha}&oficial_negocio=${oficial_negocio}&lider=${lider}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerAdelantosDashboard(
    page = 1,
    per_page = 10,
    oficial_negocio = '',
    estado = 1,
    lider = ''
  ) {

    const url = this.apiUrl +
      `factoring/operaciones/contar_adelantos/?page=${page}&per_page=${per_page}&oficial_negocio=${oficial_negocio}&estado=${estado}&lider=${lider}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerAdelantosPendientesDashboard(
    page = 1,
    per_page = 10,
    oficial_negocio = '',
    estado= 1,
    lider = '',
    fechaAdelantoIni = '',
    fechaAdelantoFin = '',
  ) {

    const url = this.apiUrl +
      `factoring/operaciones/contar_pendiente_adelantos/?page=${page}&per_page=${per_page}&oficial_negocio=${oficial_negocio}&estado=${estado}&lider=${lider}&fecha_adelanto_ini=${fechaAdelantoIni}&fecha_adelanto_fin=${fechaAdelantoFin}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerAdelantosCanceladosDashboard(
    page = 1,
    per_page = 10,
    oficial_negocio = '',
    estado = 1,
    lider = ''
  ) {

    const url = this.apiUrl +
      `factoring/operaciones/contar_adelantos_cancelados/?page=${page}&per_page=${per_page}&oficial_negocio=${oficial_negocio}&estado=${estado}&lider=${lider}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerAdelantosAbonadosDashboard(
    page = 1,
    per_page = 10,
    oficial_negocio = '',
    estado = 1,
    lider = ''
  ) {

    const url = this.apiUrl +
      `factoring/operaciones/contar_adelantos_abonados/?page=${page}&per_page=${per_page}&oficial_negocio=${oficial_negocio}&estado=${estado}&lider=${lider}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  checkRestablecerPassword() {
    let user = this.localService.getJsonValue('user');
    return (user.restablecer_password) ? true : false;
  }

  getitbackPassword( email: any ){
    const url = this.apiUrl + `seguridad/proxy/usuarios/recuperar-password/`

    return new Promise( ( resolve, reject )=>{
      this.spinnerOn();

      this.http.post( url, email )
        .subscribe( (resp)=>{

          this.spinnerOff();
          resolve( resp )

        }, err=>{
          if( err.error.detail ){
            this.spinnerOff(err.error.detail, false);
          }
          else if( err.error.email ){
            this.spinnerOff(err.error.email, false);
          }else{
            this.spinnerOff("La operación falló", false);
          }

          reject( err )

        })

      }) 
    
  }

  changePassword( id:any, password:any ){
    const url = this.apiUrl + `seguridad/usuarios/${id}/`;

    return new Promise( ( resolve, reject )=>{
      this.spinnerOn();
      this.http.put( url, password )
        .subscribe( (resp)=>{
          this.spinnerOff()
          console.log( 'RESPUESTA', resp )
          resolve( resp )
        }, err=>{
          this.spinnerOff();
          if (err.error['detail']) {
            this.toast.warning(
              err.error['detail']
            );
          }else if(err.error.password ){
            this.toast.warning(err.error.password, 2500);
          }else{
            this.spinnerOff("La operación falló", false);
          }
          reject( err )

        } )

    })
     /**
      * Luego de ejecutar el login
      * returna un token de acceso
      */
     .then((response) => {
      return this.getSession(response["access"]);
    })
    /**
     * Con el token de acceso retornado por el login
     * se obtiene el usuario logueado
     */
    .then((sessionUser: any) => {

      // this.router.navigate(['/dashboard']);
      if (this.checkRestablecerPassword()) {
          this.router.navigate(['/change-password']);
          return null
      } else {
        // setTimeout(()=>{
          this.router.navigate(['/dashboard']);
        // },300)
          return this.comiteIntegrantes(sessionUser.id);
      }
    })
    .then((response: any) => {
      if (!this.checkRestablecerPassword()) return this.getNotifications();
    })
    .catch((err: any) => {
      throw new Error(JSON.stringify(err));
    })


  }

  obtenerUsuarioConfiguraciones(usuario) {
    const url = `${this.apiUrl}usuarios-configuraciones/?usuario=${usuario}`
    return this.http.get(url).pipe(
      map((res: any) => res.results)
    )
  }

  guardarUsuarioConfiguracion(data) {
    const url = `${this.apiUrl}usuarios-configuraciones/`
    return this.http.post(url, data)
  }

}
