import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { concat, Observable, of, Subject, forkJoin } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

import { AbonosService } from 'app/core/services/abonos/abonos.service';

@Component({
  selector: 'app-identificar',
  templateUrl: './identificar.component.html',
  styleUrls: ['./identificar.component.css']
})
export class IdentificarComponent implements OnInit {
  @Input() abono: any = null

  forma: FormGroup
  maxFechaContable = new Date()
  pagadores$: Observable<any>
  pagadoresInput$ = new Subject<string>()
  pagadoresLoading = false

  constructor(
    private abonosService: AbonosService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
  ) {
    this.forma = this.formBuilder.group({
      'pagador_ruc': [null, [Validators.required]],
      'pagador_nombre': [null, [Validators.required]],
      'fecha_identificacion_contable': [new Date(), [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.loadPagadores()
  }

  onSubmit() {
    if (!this.forma.valid) return
    this.spinner.show()
    let abono = Object.assign({}, this.forma.value, { id: this.abono.id })
    this.abonosService.guardarAbono(abono).subscribe(
      (data: any) => {
        this.activeModal.close(true)
      },
      res => console.log(res)
    ).add(() => this.spinner.hide())
  }

  private loadPagadores() {
    this.pagadores$ = concat(
      of([]), // default items
      this.pagadoresInput$.pipe(
        filter((res) => {
          return res !== null
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.pagadoresLoading = true),
        switchMap(term => this.abonosService.getPagadores({ 'ruc_nombre': term }).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.pagadoresLoading = false)
        ))
      )
    )
  }
}
