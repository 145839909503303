import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FactoringService } from 'app/core/services/factoring/factoring.service';

@Component({
  selector: 'app-estaciones-operacion-modal',
  templateUrl: './estaciones-operacion-modal.component.html',
  styleUrls: ['./estaciones-operacion-modal.component.css']
})
export class EstacionesOperacionModalComponent implements OnInit {
  faTimes = faTimes;
  @Input() operacion: any;
  @Input() estaciones: any[] = [];
  resumen: any;
  estacionesDate : any = [];

  constructor(
    public activeModal: NgbActiveModal,
    private factoringService: FactoringService,
  ) { }

  ngOnInit() {
    this.factoringService.operacionResumen(this.operacion.id)
    .then(res => {
      console.log(res);
      this.resumen = res;
    }).catch(error => {
      console.error(error);
      
    });

    this.obtenerMovimientos();

  }

  className(estacion) {

    if (this.operacion.estado == 10) {
      return 'complete';
    }

    if (this.operacion.estacion == 14) {
      
      if (estacion.id < 5) {
        return 'complete';
      } else {
        return 'archive';
      }

    } else {
      
      if (this.operacion.estacion == estacion.id) {
        return 'actual';
      } else if (this.operacion.estacion > estacion.id) {
        return 'complete';
      } else {
        return '';
      }
      
    }

  }

  classDocs() {

    if (this.operacion.estado == 10) {
      return 'complet';
    }

    const documentos  = this.resumen.cantidad_documentos;
    const verificados = this.resumen.cantidad_documentos_verificados;
    const rechazados  = this.resumen.cantidad_documentos_rechazados;

    if (this.operacion.estacion < 3 || documentos == 0) {
      return '';
    } else {

      if (documentos > (verificados + rechazados)) {
        return 'curso';
      } else if (documentos == (verificados + rechazados)) {
        return 'complet';
      } else {
        return '';
      }

    }

  }

  obtenerMovimientos() {
    this.factoringService.movimientosOperacion(this.operacion.id)
    .then(res => {
      console.log(res);
      this.estacionesDate = res;
    }).catch(error => {
      console.error(error);
      
    });
  }

  showDate(estacion) {
    return (this.operacion.estacion > estacion.id);
  }

  findInicio(estacion) {
    if(!this.estacionesDate || this.operacion.estacion < estacion.id) return '';
    const inicio = this.estacionesDate.find(e => e.estacion == estacion.id);
    return (inicio) ? 'Inicio: ' + inicio.inicio :  '';
  }

  findDuration(estacion) {
    if(!this.estacionesDate || this.operacion.estacion < estacion.id) return '';
    const duracion = this.estacionesDate.find(e => e.estacion == estacion.id);
    return (duracion) ? 'Duración: ' + duracion.duracion : '';
  }

}
