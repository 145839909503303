import { Component, ElementRef, Input, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, filter } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-general-data',
  templateUrl: './general-data.component.html',
  styleUrls: ['./general-data.component.css']
})
export class GeneralDataComponent implements OnInit {

  @ViewChild('claveSol') claveSol: ElementRef | undefined;

  faSearch=faSearch;
  active = 1;
  ruc = '';
  consultaSunat = true;
  emailValido = true;

  codigo_ciiu: string;

  dataForm: FormGroup;
  departamentos: any[];
  provincias: any[];
  distritos: any[];
  cliente: any;
  contactos: any;
  personas: any;
  estadosCiviles: any;
  oficinas: any;
  zonas: any;
  tiposCreditos: any;
  magnitudes: any;
  riesgosCambiarios: any;
  clasificaciones: any;
  listaAsistentesDeCobranzas: any;
  listaOficialesDeNegocio: any;

  ciiuResults$: Observable<any[]>;
  searchCiiu$ = new Subject<string>();
  loadingCiiu: boolean = false;
  ciiuCharacters: number = 2;

  customSearchFn:string = '';

  iconEye:string = 'fa fa-eye';
  iconText:string = 'Mostrar';

  @Input() clienteId = 0;
  @Input() readonly = false;

  generos = [
    { key: 'F', nombre: 'Femenino' },
    { key: 'M', nombre: 'Masculino' }
  ];
  tiposDocumentos: any[] = [];
  gruposEconomicos: any[] = [];
  empresas: any[] = [];

  correos:any[] = [];

  phonePattern = { 'P': { pattern: new RegExp(/[\+|\d]/) }, '0': { pattern: new RegExp(/\d/) } }

  constructor(
    public formbuild: FormBuilder,
    private clientesService: ClientesService,
    private factoringService: FactoringService,
    private authService: AuthService,
    private router: Router,
    public toast: ToastService,
    public sharedFunctions: SharedFunctions,
    private renderer2: Renderer2
  ) {
    this.getDepartamentos();
    this.obtenerTiposPersonas();
    this.initForm();
  }

  ngOnInit(): void {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    });

    this.loadSearchCiiu();

    if (this.clienteId) {

      this.obtenerCliente();
      this.consultaSunat = false;

      this.clientesService.obtenerEmpresas(this.clienteId).then(resp => {
        this.empresas = resp[0]['empresas'];
      }, err => {

      });

      if( this.canClaveSol || this.canClaveSolReadonly ){

        //Obtener contraseña
        this.clientesService.obtenerClave( this.clienteId )
        .then( ({ clave_sol })=>{

          this.dataForm.controls['clave_sol'].setValue( clave_sol );

          if(this.canClaveSolReadonly)this.dataForm.controls['clave_sol'].disable();

        }).catch(error => {
          console.log(error);
        })

      }
      
      
    }

    if (this.readonly) {
      this.dataForm.disable()
    }

  }

  addTagFn(name:string) {
    
    var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 
    if(mailformat.test(name) ){
      return name;
    }

    return null;
    
  }

  obtenerTiposPersonas() {

    let tiposPersonas = this.clientesService.tiposPersonas();
    let estadosCiviles = this.clientesService.estadosCiviles();
    // let oficinas = this.clientesService.oficinas();
    let zonas = this.clientesService.zonas();
    let tiposCreditos = this.clientesService.tiposCreditos();
    let magnitudes = this.clientesService.magnitudes();
    let riesgosCambiarios = this.clientesService.riesgosCambiarios();
    let clasificaciones = this.clientesService.obtenerClasificaciones();
    let tiposDocumentos = this.clientesService.obtenerTiposDocumentos();
    let gruposEconomicos = this.clientesService.obtenerGriposEconomicos();
    let listaAsistentesDeCobranzas = this.factoringService.obtenerResponsables(this.authService.perfil.perfilAsistenteCobranzasID, "true")
    let listaOficialesDeNegocio = this.factoringService.obtenerResponsables(this.authService.perfil.perfilOficialDeNegocioID, "true")
    let listaGerencia = this.factoringService.obtenerResponsables(this.authService.perfil.perfilGerenciaComercialID, "true")
    let listaAltaGerencia = this.factoringService.obtenerResponsables(this.authService.perfil.perfilGerenciaGeneralID, "true")
   
    Promise.all([
      tiposPersonas, estadosCiviles,
      zonas, tiposCreditos, magnitudes,
      riesgosCambiarios, clasificaciones, tiposDocumentos,
      gruposEconomicos, listaAsistentesDeCobranzas, 
      listaOficialesDeNegocio, listaGerencia, listaAltaGerencia
    ])
      .then(res => {
        this.personas = res[0]['results'];
        this.estadosCiviles = res[1]['results'];
        this.zonas = res[2]['results'];
        this.tiposCreditos = res[3]['results'];
        this.magnitudes = res[4]['results'];
        this.riesgosCambiarios = res[5]['results'];
        this.clasificaciones = res[6]['results'];
        this.tiposDocumentos = res[7]['results'];
        this.gruposEconomicos = res[8]['results'];
        this.listaAsistentesDeCobranzas = res[9]['results'];
        this.listaOficialesDeNegocio = res[10]['results'];
        this.listaOficialesDeNegocio = this.listaOficialesDeNegocio.concat(res[11]['results'])
        this.listaOficialesDeNegocio = this.listaOficialesDeNegocio.concat(res[12]['results'])
        console.log("ASD")
      })
      .catch(error => {
        console.error(error);
      })
  }

  obtenerCliente() {
    this.clientesService.obtenerCliente(this.clienteId)
      .then((res: any) => {
        console.log(res )
        this.cliente = res;
        this.setForm(res);
        this.getProvincias();
        this.getDistritos();
      })
      .catch(error => {
        console.error(error);
      })
  }

  getDistritos() {
    const provincia = this.dataForm.controls['provincia'].value;

    if (provincia) {
      this.clientesService.obtenerDistritos(provincia)
        .then(res => {
          this.distritos = res['results'];
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  initDistritos() {
    const provincia = this.cliente.provincia;

    if (provincia) {
      this.clientesService.obtenerDistritos(provincia)
        .then(res => {
          this.distritos = res['results'];
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  getDepartamentos() {
    this.clientesService.obtenerDepartamentos()
      .then(res => {
        this.departamentos = res['results'];
      })
      .catch(error => {
        console.error(error);
      });
  }

  getProvincias() {
    const departamento = this.dataForm.controls['departamento'].value;
    if (departamento) {
      this.clientesService.obtenerProvincias(departamento)
        .then(res => {
          this.provincias = res['results'];
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  initProvincias() {
    const departamento = this.cliente.departamento;
    if (departamento) {
      this.clientesService.obtenerProvincias(departamento)
        .then(res => {
          this.provincias = res['results'];
        })
        .catch(error => {
          console.error(error);
        });
    }
  }

  initForm() {
    this.dataForm = this.formbuild.group({
      ruc: [null, [Validators.required, Validators.maxLength(20)]],
      codigo_ciiu: [null, [Validators.required, Validators.maxLength(10)]],
      ciiu_id: [null, []],
      codigo_sbs: [null, [Validators.maxLength(10), Validators.minLength(10)]],
      nombre: [null, [Validators.required]],
      domicilio_legal: [null, [Validators.required]],
      distrito: [null, [Validators.required]],
      departamento: [null, [Validators.required]],
      provincia: [null, [Validators.required]],
      telefono_contacto: [null, [Validators.required]],
      telefono_contacto2: [null, []],
      numero_partida: [null, []],
      fax: [null, []],
      email: [ null, [Validators.required]],
      capital_social: [null, []],
      patrimonio: [null, []],
      nombre_conyuge: [null, []],
      dni_conyuge: [null, [Validators.maxLength(8)]],
      tipo_persona: [null, []],
      domicilio_comercial: [null, []],
      domicilio_correspondencia: [null, []],
      dni: [null, []],
      apellido_paterno: [null, []],
      apellido_materno: [null, []],
      primer_nombre: [null, []],
      segundo_nombre: [null, []],
      sexo: [null, []],
      fecha_nacimiento: [null, []],
      estado_civil: [null, []],
      oficina_registral: [null, []],
      zona_registral: [null, []],
      tipo_credito: [null, []],
      magnitud: [null, []],
      riesgo_cambiario: [null, []],
      calificacion: [null, []],
      calificacion_final: [null, []],
      observaciones: [null, []],
      grupo_economico: [null, []],
      codigo_registros_publicos: [null, []],
      asistente_cobranzas: [null, [Validators.required]],
      oficial_negocio: [null, []],
      clave_sol: [ null, [ Validators.maxLength(15), Validators.pattern('[0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]*') ] ],
      usuario_clave_sol: [ null, [ Validators.maxLength(15), Validators.pattern('[0-9a-zA-ZáéíóúñÁÉÍÓÚÑ ]*') ] ]
    });

    console.log( 'cargo el formulario', this.dataForm )

    this.dataForm.controls['zona_registral'].valueChanges.subscribe(value => {
      this.buscarOficinas();
    });

    this.dataForm.controls.codigo_ciiu.valueChanges.subscribe((value) => {
      if (!!value && typeof (value) == "object") {
        console.log("id, codigo", value.id, value.codigo)
        this.dataForm.controls["ciiu_id"].setValue(value.id);
        this.dataForm.controls["codigo_ciiu"].setValue(value.codigo);
      }
    })
  }

  setForm(cliente) {
    for (const key in cliente) {
      if (this.dataForm.controls[key]) {
        if (key == 'fecha_nacimiento') {
          this.dataForm.controls[key].setValue(this.parseDate(cliente[key]));
          continue;
        }
        if(key === 'email'){
          console.log( cliente[key] )
          this.dataForm.controls[key].setValue( cliente[key] ? cliente[key].split(',') : null );
          continue;
        }
        if( key === 'usuario_clave_sol' && this.canClaveSolReadonly ){

          this.dataForm.controls[key].setValue( cliente[key] )
          this.dataForm.controls[key].disable();
        }

        this.dataForm.controls[key].setValue(cliente[key]);
      }

    }
  }

  get tipoPersona() {
    const typePerson = this.dataForm.controls['tipo_persona'].value;
    return (typePerson == 1) ? true : false;
  }

  validarRuc() {
    const ruc = this.dataForm.controls['ruc'].value;

    this.clientesService.validaExistenia(ruc)
      .then((res: any) => {
        if (res.count > 0) {
          this.toast.warning('El RUC que intenta buscar ya se encentra registrado');
          this.router.navigate(['/clientes', res.results[0].id]);
        } else {
          this.searchRuc()
        }
      }).catch((err) => {
        console.log(err)
      })
  }

  searchRuc() {
    const ruc = this.dataForm.controls['ruc'].value;
    if (ruc.length) {
      this.clientesService.buscarRuc(ruc)
        .then(async res => {

          if (!res) {
            this.toast.warning('No hay datos en SUNAT');
            this.consultaSunat = false;
            return;
          }

          this.contactos = (res['representantes_legales']) ? res['representantes_legales'] : [];
          // this.setForm(this.mapperData(res));

          // await this.verificarClienteExistente(ruc);

          const contribuyente_tipo = (res['tipo_contribuyente']) ? res['tipo_contribuyente'] : '';

          this.llenarFormPorTipoPersona(contribuyente_tipo.includes('PERSONA NATURAL'), res);

          this.consultaSunat = false;

        })
        .catch(error => {
          // this.toast.warning('No hay datos en SUNAT');
          this.consultaSunat = false;
          console.error(error);
        });
    }
  }

  /**
   * Este evento se suscribe a los cambios en el objeto que recibe la data desde el servidor
   */
  loadSearchCiiu() {
    /**
     * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
     * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
     * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value. 
     * 		So that API will not hit if the current and previous value is the same
     * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
     * 		string typed by user
     */
    this.ciiuResults$ = concat(
      of([]), // Items predeterminados
      this.searchCiiu$.pipe(
        filter(res => {
          return res !== null && res.length >= this.ciiuCharacters
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.loadingCiiu = true),
        switchMap(term => this.clientesService.buscarCiiuObserver(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.loadingCiiu = false)
        )
        ))
    );
  }

  llenarFormPorTipoPersona(tipo_persona: boolean, rucData) {
    if (tipo_persona) {

      let dni = rucData.numero_documento.substring(2, (rucData.numero_documento.length - 1));
      let direccion = (rucData.direcciones.length) ? rucData.direcciones[0] : null;

      let data = {
        nombre: rucData.razon_social,
        codigo_ciiu: rucData.codigo_ciiu,
        domicilio_legal: direccion,
        dni: dni,
        tipo_persona: 1
      }

      this.setForm(data);

    } else {
      let direccion = (rucData.direcciones.length) ? rucData.direcciones[0] : null;

      let data = {
        nombre: rucData.razon_social,
        codigo_ciiu: rucData.codigo_ciiu,
        domicilio_legal: direccion,
        tipo_persona: 2
      }

      this.setForm(data);
    }
  }

  mapperData(rucData) {
    const data = {
      nombre: rucData.razon_social,
      codigo_ciiu: rucData.codigo_ciiu,
      domicilio_legal: rucData.direccion
    }

    return data;
  }
  // 3443
  onSubmit() {
    if (this.dataForm.valid) {
      const values = this.dataForm.value;
      values['fecha_nacimiento'] = this.formatDate(values['fecha_nacimiento']);
      values['documento_tributario'] = values['ruc'];
      values['sexo'] = (this.tipoPersona) ? values['sexo'] : null;
      values['email'] = values['email'] == null || values['email'] == [] ? null : values['email'].toString()
      console.log( values);
      this.clientesService.guardarCliente(values, this.clienteId)
        .then((res: any) => {
          console.log(res);
          if (this.contactos && this.contactos.length) {
            console.log('guardando contactos', this.contactos);
            this.guardarContactos(res);
          } else {
            console.log(' no guardar contactos');
            this.router.navigate(['/clientes', res.id]);
          }

        })
    }
  }

  async guardarContactos(res: any) {
    console.log('guardando contactos');

    let arrayData = [];

    // for (const key in this.contactos) {
    //   if (Object.prototype.hasOwnProperty.call(this.contactos, key)) {
    //     const element = this.contactos[key];

    //     let tipoDoc = this.tiposDocumentos.find(item => item.codigo == key['tipo_documento']);
    //     console.log(tipoDoc);
    //     element['cliente']        = res.id;
    //     // element['dni'] = this.searchDni(key);
    //     element['dni']            = key['dni'];
    //     element['nombre']         = key['nombre'];
    //     element['tipo_documento'] = (tipoDoc) ? tipoDoc.id : '';
    //     arrayData.push(element);
    //   }
    // }

    this.contactos.forEach(element => {
      let tipoDoc = this.tiposDocumentos.find(item => item.codigo == element['tipo_documento']);
      let data = {
        cliente: res.id,
        cargo: element.cargo,
        nombre: element.nombre,
        dni: element.numero_documento,
        tipo_documento: (tipoDoc) ? tipoDoc.id : null,
      }
      arrayData.push(data);
    });

    await this.clientesService.guardarGrupoContacto(arrayData).then(res => {
      console.log(res);
    }).catch(error => {
      console.error(error);

    });

    this.router.navigate(['/clientes', res.id]);

  }

  formatDate(date) {
    if (!(date instanceof Date))
      return;
    return date.getFullYear() +
      "-" + (date.getMonth() + 1) +
      "-" + date.getDate();
  }

  parseDate(date) {
    if (!date)
      return null;

    let dateNow = new Date();

    let yy = date.split('-')[0];
    let mm = (date.split('-')[1] - 1);
    let dd = date.split('-')[2];

    let d = new Date(yy, mm, dd, dateNow.getHours());
    console.log(d);
    return d;
  }

  verificarClienteExistente(ruc) {

    this.clientesService.obtenerClientes(1, 10, ruc)
      .then(res => {
        if (res['results'].length) {
          let cliente = res['results'][0];
          this.router.navigate(['/clientes', cliente.id]);
        } else {
          return false;
        }
      })
      .catch(error => {
        return false;
      });

  }

  corroborarSunat() {
    const ruc = this.dataForm.controls['ruc'].value;

  }

  searchDni(text) {
    console.log(text);
    return text.split(':')[1];
    // if (text.includes('EXT')) {
    //   return text.split(':')[1];
    // } else if(text.includes('DNI')) {
    //   return text.split(':')[1];
    // }

  }
  validarEmail(e) {
    let email = e.target.value;
    this.emailValido = email ? this.sharedFunctions.validateEmail(email) : true;
  }

  buscarOficinas() {
    const zona = this.dataForm.controls['zona_registral'].value;

    if (!zona) return;

    let oficinas = this.clientesService.oficinas(zona);
    oficinas.then(res => {
      this.oficinas = res['results'];
    }).catch(err => {
      console.error(err);

    });
  }

  get canClaveSol() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilOficialDeNegocioID,
      this.authService.perfil.perfilAsistenteComercialID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  get canClaveSolReadonly(){

    const perfilesPermitidos = [
      this.authService.perfil.perfilAnalistaRiesgosID,
      this.authService.perfil.perfilJefeDeAdmisionID
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  //Mostrar contraseña
  showPw(){

    if( this.claveSol.nativeElement.type === 'password' ){

      this.iconEye = 'fa fa-eye-slash'
      this.iconText = 'Ocultar';
      this.renderer2.setAttribute(this.claveSol.nativeElement, 'type', 'text')

    }else{

      this.iconEye = 'fa fa-eye'
      this.iconText = 'Mostrar';

      this.renderer2.setAttribute(this.claveSol.nativeElement, 'type', 'password')

    }

  }

}
