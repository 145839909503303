import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    ViewChild
  } from "@angular/core";
  import { FormBuilder, FormGroup, Validators } from "@angular/forms";
  import {  NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
  import { BsLocaleService } from "ngx-bootstrap/datepicker";
  import { esLocale } from "ngx-bootstrap/locale";
  import { defineLocale } from "ngx-bootstrap/chronos";
  import { AuthService } from "app/core/services/auth/auth.service";
  import { Perfil } from "app/core/services/auth/Perfil";
  import { ToastService } from "app/core/services/toast/toast.service";
  import { Subject } from 'rxjs';
  import { faTimes } from "@fortawesome/free-solid-svg-icons";
  import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
  import {DinamicMultiSelectInputs, isActionsDinamicMultiSelect, FormDinamicMultiSelectComponent} from 'app/shared/form-multiselect/form-multiselect.component';

  @Component({
    selector: "app-modal-variable_riesgo",
    templateUrl: "./modal_variable_riesgo.component.html",
    styleUrls: ["./modal_variable_riesgo.component.css"],
  })
  export class ModalVariableRiesgoComponent implements OnInit {
    faTimes                     = faTimes
    public valoresRiesgo        : any[] = [];
    public indexValoresRiesgo   : number            = 0;
    public minDate              : Date;
    public cabeceraSolicitudForm: FormGroup;
    public valoresForm          : FormGroup;
    public bodySolicitudForm    : FormGroup;
    public user                 : any;
    public canAprobe            : any               = null;
    public instance_row: any;
    @Input() variableRiesgo     : any               = [];
    @Input() variableRiesgoId   = null;
    @Output() reloadList        : EventEmitter<any> = new EventEmitter<any>();
    @Output() successEmit       : EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('multiSelect', {static: false}) multiSelect: FormDinamicMultiSelectComponent

    readOnlyForm                = false;
    readOnlyButtonActualizar    = false;
    perfil                      = new Perfil();
    searchTextBene              = "";
    searchText$                 = new Subject<string>();
    loadingSearch               : boolean           = false;
    caracteresMinimos           : number            = 4;
    actInputExc                 : boolean;
    valoresPropertiesShow = ["descripcion", "puntuacion"];
    valoresButtonsShow: isActionsDinamicMultiSelect;
    valoresInputs: DinamicMultiSelectInputs;
  
    constructor(

      private formBuilder       : FormBuilder,
      public localeService      : BsLocaleService,
      private authService       : AuthService,
      public activeModal        : NgbActiveModal,
      public toast              : ToastService,
      private lavadoActivosService : LavadoActivosService,
    ) {
      defineLocale("es", esLocale);
      this.localeService.use("es");
      this.user = this.authService.user;
    }
  
    async ngOnInit() {
      if(this.instance_row){
        this.variableRiesgoId = this.instance_row.id
      }
      
      this.crearCabecera();
      if (this.variableRiesgoId) {
        await this.obtenerVariableRiesgo();
        
      }
      this.esDinamicoChange();
    }
    async eliminarValores(data){
      this.valoresRiesgo = data['filtred']
      let row = {
        "valores": data['ids']
      }
      await this.lavadoActivosService.eliminarValores(row);
    }
    
    getCabeceraData() {
      this.cabeceraSolicitudForm.enable();
  
      // Desconstruimos los datos de la cabecera de la variable
      const {
        variable_descripcion,
      } = this.cabeceraSolicitudForm.value;
  
      // Mapeamos todos los datos para enviarlo al endpoint
      let data = {
        descripcion: variable_descripcion,
        valores: [],
      };
  
      return data;
    }

    async enviarCabecera() {
      let data = this.getCabeceraData();
      if (!data) {
        return
      }
      if (this.multiSelect?.content){
        this.multiSelect.content.forEach(valor=>{
          data.valores.push({
            id: valor.id ? valor.id : null,
            descripcion: valor.descripcion,
            puntuacion: valor.puntuacion,
            variable: this.variableRiesgoId
          })
          
        })
      }
      if(this.variableRiesgo && this.variableRiesgoId){
        data['id'] = this.variableRiesgoId;
        await this.lavadoActivosService
        .pathVariableRiesgo(data)
        ;
      } else {
        await this.lavadoActivosService
        .postVariableRiesgo(data)
        ;
      }
      this.activeModal.close(false)
    }
    addIndiceItemMultiSelect(){
      if(this.valoresRiesgo){
        this.multiSelect.content = this.valoresRiesgo
        while (this.multiSelect.content.length > this.multiSelect.indice){
          this.multiSelect.content[this.multiSelect.indice]['indice'] = this.multiSelect.indice
          this.multiSelect.indice++
        }
      }
      
    }
    async refreshVariableRiesgo(variableRiesgo: any){
      this.variableRiesgo = variableRiesgo;
      this.variableRiesgoId = variableRiesgo.id;
      this.valoresRiesgo = variableRiesgo['valores'];
      this.addIndiceItemMultiSelect()
      this.setForm(variableRiesgo);
    }
    async obtenerVariableRiesgo() {
      try {
        const res = await this.lavadoActivosService
          .obtenerVariableRiesgo(this.variableRiesgoId);
        await this.refreshVariableRiesgo(res)
        
      } catch (error) {
        console.error(error);
      }
      
    }
    
    crearCabecera() {
      this.cabeceraSolicitudForm = this.formBuilder.group({
        variable_descripcion    : ['', [Validators.required]],
      });
      this.valoresForm = this.formBuilder.group({
        descripcion : [
          '', 
          [Validators.required],
        ],
        puntuacion: [
          null,
          [Validators.required]
        ]
      });
      this.valoresInputs = {
        form: this.valoresForm,
        inputs: [
          {
            label: 'Descripción',
            is_disabled: false,
            validator_name: 'descripcion',
            is_unnique: true,
            type: 'text',
            class: 'col-8'
          },
          {
            label: 'Puntuación',
            is_disabled:false,
            validator_name: 'puntuacion',
            is_unnique: false,
            type: 'number',
            class: 'col-4'
          }
        ]
      }
      this.valoresButtonsShow = {
        isDelete: true,
        isEdit: true,
        isAdd:true,
      }
    }
    cargarValoresDinamicos(){
      this.lavadoActivosService
        .cargarValoresDinamicosVariableRiesgo(this.variableRiesgoId)
        .then(async () => {
          await this.obtenerVariableRiesgo()
          
        });
    }
    esDinamicoChange(){
      const es_dinamico = this.variableRiesgo?.es_dinamico
      if(es_dinamico){
        this.valoresButtonsShow.isAdd = false
        this.valoresButtonsShow.isDelete = false
        // Deshabilitamos el input de descripcion del valor
        this.valoresForm.controls['descripcion'].disable();
        this.cabeceraSolicitudForm.controls['variable_descripcion'].disable();
      }
    }
    setForm(data) {
      // Desconstruimos
      const mapperData = {
        variable_descripcion: data.descripcion,

      };
      // Recorremos mapperData y assiganmos en nuestro formulario
      for (const key in mapperData) {
        if (this.cabeceraSolicitudForm.controls[key]) {
          this.cabeceraSolicitudForm.controls[key].setValue(mapperData[key]);
        }
  
      }
    }
  
  
    get validForm() {
      let constrls = this.cabeceraSolicitudForm.controls;
  
      let result = true;
  
      for (const key in constrls) {
        if (Object.prototype.hasOwnProperty.call(constrls, key)) {
          if (constrls[key].errors) {
            result = true;
          } else {
            result = false;
          }
        }
      }
      return result;
    }

  
    get canAdd() {
      const perfilesPermitidos = [
        this.authService.perfil.perfilAdmin,
        this.authService.perfil.perfilJefeDeOperacionesID,
        this.authService.perfil.perfilAnalistaOperacionesID,
        this.authService.perfil.perfilAsistenteCobranzasID,
        this.authService.perfil.perfilAnalistaOperacionesID,
        this.authService.perfil.perfilGerenciaComercialID,
        this.authService.perfil.perfilGerenciaGeneralID,
      ]
  
      return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
    }
  
  }
  