import { Injectable } from '@angular/core';

declare var $:any;
declare var require: any;

@Injectable({
	providedIn: 'root'
})
export class ToastService {

    toastSuccess: any[] = [];
    toastWarning: any[] = [];
    historyToast: any[] =[];
	
	constructor() {

	}

    toastInit(toastId:number,delay: number = 3000){
        this.clearToastsArray(toastId,delay)
		$('.toast').toast({
			animation: true,
			autohide: true,
			delay: 3000
		})
        
        setTimeout(()=>{
		    $(`#${toastId}`).toast('show')   
        },100)
    }

    success(msg : string = '', delay: number = 10000){
        let idToast = new Date().valueOf()
        this.toastSuccess.push({
            toastId: idToast,
            msg   : msg,
            delay : delay
        })
        this.toastInit(idToast,delay)
    }

    warning(msg : string = '', delay: number = 10000){
        let idToast = new Date().valueOf()
        this.toastWarning.push({
            toastId: idToast,
            msg   : msg,
            delay : delay
        })
        this.toastInit(idToast,delay)
    }    

    clearToastsArray(idToast:number, delay:number){
        setTimeout(()=>{
            let index;
            index = this.toastSuccess.findIndex(({toastId}) => toastId===idToast)
            if(index != -1){
                this.toastSuccess.splice(index,1)
            } else {
                index = this.toastWarning.findIndex(({toastId}) => toastId===idToast)
                if (index != -1) {
                    this.toastWarning.splice(index,1)
                }
            }
        },delay+100)
    }
	
    getWarningToasts(){
        return this.toastWarning
    }

    getSuccessToasts(){
        return this.toastSuccess
    }    
	
}
