import {
  Component,
  OnInit,
  ViewChildren,
  Input,
  EventEmitter,
  Output
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { AuthService } from "app/core/services/auth/auth.service";
import { Perfil } from "app/core/services/auth/Perfil";
import { ToastService } from "app/core/services/toast/toast.service";
import { faTimes, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Router } from '@angular/router'; 
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
import {SelectInput, SelectItem} from "app/shared/form-select/form-select.component";
import { ClientesService } from 'app/core/services/clientes/clientes.service';

@Component({
  selector: 'app-modals-reporte',
  templateUrl: './modals-reporte.component.html',
  styleUrls: ['./modals-reporte.component.css']
})
export class ModalsReporteComponent implements OnInit {
  faTimes                     = faTimes
  faSearch                    = faSearch
  public consultaSunat = true;
  public itemsInput           : SelectItem[] = [];  
  public item_selected       : SelectItem; 
  public selectTipoCalificacion: SelectInput;
  public Reporte              : any[] = [];
  public indexReporte         : number = 0;
  public minDate              : Date;
  public ReporteForm          : FormGroup;
  public user                 : any;
  public canAprobe            : any               = null;
  public instance_row
  @Input() reporte            : any               = [];
  @Input() reporteId          = null;
  @Output() reloadList        : EventEmitter<any> = new EventEmitter<any>();
  @Output() successEmit       : EventEmitter<any> = new EventEmitter<any>();
  @ViewChildren('ReporteElement') public inputReporte;
  readOnlyForm                = false;
  readOnlyButtonActualizar    = false;
  perfil                      = new Perfil();
  actInputExc                 : boolean;

   // Utils - Filtro Cliente
   beneficiarioSelect       : any;
   searchTextBene           = "";

  constructor(
    private clientesService: ClientesService,
    private formBuilder       : FormBuilder,
    public localeService      : BsLocaleService,
    private authService       : AuthService,
    public activeModal        : NgbActiveModal,
    public toast              : ToastService,
    private router: Router,
    private lavadoActivosService : LavadoActivosService,
  ) {
    defineLocale("es", esLocale);
    this.localeService.use("es");
    this.user = this.authService.user;
  }

  async ngOnInit() {
    if (this.instance_row){
      this.reporteId = this.instance_row.id
    }
    
    this.actInputExc = this.router.url.split('/')[1] == 'reporte' ? true : false;
    this.crearCabecera();
    this.obtenerTiposReporte();
    this.buildSelect();
    this.ReporteForm.controls['cliente_nombre'].disable()
    if (this.reporteId) {
      await this.obtenerReporte();
      
    } else{
      this.ReporteForm.controls['descripcion'].disable()
      this.ReporteForm.controls['tipo'].disable()
    }
  }
  
  getCabeceraData() {
    this.ReporteForm.enable();

    // Desconstruimos los datos de la cabecera de la recaudacion
    const {
      cliente_tipo,
      cliente_nombre,
      cliente_ruc,
      descripcion,
      tipo,
    } = this.ReporteForm.value;

    // Mapeamos todos los datos para enviarlo al endpoint
    let data = {
      cliente_tipo: cliente_tipo,
      cliente_nombre: cliente_nombre,
      cliente_ruc: cliente_ruc,
      descripcion: descripcion,
      tipo: this.item_selected.id
    };

    return data;
  }
  searchRuc() {
    const ruc = this.ReporteForm.controls['cliente_ruc'].value;
    if (ruc.length) {
      this.clientesService.buscarRuc(ruc)
        .then(async res => {
          if (!res) {
            this.toast.warning('No hay datos en SUNAT');
            this.consultaSunat = false;
            return;
          }
          this.consultaSunat = false;
          this.ReporteForm.controls['cliente_nombre'].setValue(res['razon_social'])
          this.ReporteForm.controls['descripcion'].enable()
          this.ReporteForm.controls['tipo'].enable()
        })
        .catch(error => {
          // this.toast.warning('No hay datos en SUNAT');
          this.consultaSunat = false;
          console.error(error);
        });
    }
  }

  async enviarCabecera() {
    let data = this.getCabeceraData();
    if (!data) {
      return
    }
    if(this.reporte && this.reporteId){
      data['id'] = this.reporteId;
      await this.lavadoActivosService
      .pathReporte(data);
      this.obtenerReporte();
    } else {
      await this.lavadoActivosService
      .postReporte(data);
    }
    this.ReporteForm.controls['cliente_nombre'].disable()
    this.ReporteForm.controls['cliente_ruc'].disable()
  }
  
crearCabecera() {
  this.ReporteForm = this.formBuilder.group({
    usuario_creacion    : ['', [Validators.required]],
    fecha_creacion    : ['', [Validators.required]],
    usuario_actualizacion    : ['', [Validators.required]],
    fecha_actualizacion    : ['', [Validators.required]],
    tipo    : ['', [Validators.required]],
    cliente_nombre    : ['', [Validators.required]],
    cliente_ruc    : ['', [Validators.required]],
    cliente_tipo    : ['', [Validators.required]],
    descripcion    : ['', [Validators.required]],
  });
}

generateItems(items: any[]){
  items.forEach(element => {
    this.itemsInput.push({
      label: element.descripcion,
      id : element.id,
      value: element.id
    })
  });
}

async obtenerTiposReporte() {
  await this.lavadoActivosService
    .obtenerTiposReporte()
    .then((res) => {
      this.generateItems(res['results'])
    })
    .catch((error) => {
      console.error(error);
    });
}

  refreshReporte(reporte: any){
    this.reporte = reporte;
    this.reporteId = reporte.id;
    this.setForm(reporte);
  }

  async obtenerReporte() {
    await this.lavadoActivosService
      .obtenerReporte(this.reporteId)
      .then((res) => {
        this.refreshReporte(res)
        
      })
      .catch((error) => {
        console.error(error);
      });
  }
  
  buildSelect(){
    this.selectTipoCalificacion = {
      id: this.reporte.tipo,
      default_value: this.reporte.tipo,
      label: 'Tipo de calificación',
      validator_name: 'tipo',
      item_selected: {id: this.reporte.tipo, label: this.reporte.descripcion, value: this.reporte.tipo},
      classes: '',
      placeholder: 'Selecciona un elemento',
      is_disabled: false,
      formParent: this.ReporteForm,
      items: this.itemsInput,
    }
  }

  setForm(data) {
    const mapperData = {
      usuario_creacion: data.usuario_creacion,
      fecha_creacion: data.fecha_creacion,
      usuario_actualizacion: data.usuario_actualizacion,
      fecha_actualizacion: data.fecha_actualizacion,
      tipo: data.tipo_descripcion,
      cliente_tipo: data.cliente_tipo,
      cliente_nombre: data.cliente_nombre,
      cliente_ruc: data.cliente_ruc,
      descripcion: data.descripcion,
    };

    for (const key in mapperData) {
      if (this.ReporteForm.controls[key]) {
        this.ReporteForm.controls[key].setValue(mapperData[key]);
      }

    }
  }


  get validForm() {
    let constrls = this.ReporteForm.controls;

    let result = true;

    for (const key in constrls) {
      if (Object.prototype.hasOwnProperty.call(constrls, key)) {
        if (constrls[key].errors) {
          result = true;
        } else {
          result = false;
        }
      }
    }
    return result;
  }
  
  getSelectInput(selectInput: SelectInput){
    this.item_selected = selectInput.item_selected
  }


  get canAdd() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilAsistenteCobranzasID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }


}
