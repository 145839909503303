import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faEye, faTimes, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'app/app.service';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { NgxSpinnerService } from 'ngx-spinner';
import { ModalExclusionesDocsComponent } from '../modal-exclusiones-docs/modal-exclusiones-docs.component';
import { TablaDocsComponent } from '../tabla-docs/tabla-docs.component';

@Component({
  selector: 'app-gestion-cobranzas',
  templateUrl: './gestion-cobranzas.component.html',
  styleUrls: ['./gestion-cobranzas.component.css']
})
export class GestionCobranzasComponent implements OnInit {
  faEye     = faEye;
  faUserTie = faUserTie;
  faTimes   = faTimes;
  documentosExcluidos: any[] = [];
  preselectedDocsExcluidos: any[] = [];
  private apiUrl: string = "";
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: any[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public htmlContent: any;
  public modulesQuill = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ font: [] }],
      [{ color: [] }, { background: [] }],
      [{ size: ['small', false, 'large', 'huge'] }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ align: [] }],
      ['blockquote', 'code-block'],
      [{ list: 'ordered'}, { list: 'bullet' }],
      ['link', 'image', 'video'],
      ['clean'],
    ]
  };

  @Input() seleccionRecordatorios;
  correos: any[] = [];
  scrollHeight = 450
  @Input() seleccionAgrupada: any[];
  selections: any[] = [];
  textoasunto: any = 'DOCUMENTOS CON VENCIMIENTO “Fecha de vencimiento”: “Cliente” – “Aceptante”';
  modelosCorreos: any = [];

  constructor(
    public modalService            : NgbModal,
    public appService              : AppService,
    public router                  : Router,
    private activatedRoute         : ActivatedRoute,
    public localService            : LocalServiceService,
    public authService             : AuthService,
    public auxFunctions            : SharedFunctions,
    public documentosCarteraService: DocumentosCarteraService,
    public spinner                 : NgxSpinnerService,
    public toast                   : ToastService,
    public http                    : HttpClient,
    private factoringService       : FactoringService,
    public factoring               : FactoringService
  ) { 
    this.apiUrl = this.appService.settings.API_base_url;
    // this.apiUrl = 'http://127.0.0.1:8000/';
  }

  ngOnInit(): void {
    let responsablesRequest = this.factoringService.obtenerResponsables('','true',1,1000)
    let plantillasRequest = this.obtenerPlantillas()
    let excluidos = this.obtenerExlcluidos()
    Promise.all([responsablesRequest,plantillasRequest,excluidos]).then((res:any[])=>{
      this.correos = res[0].results
      this.loadTable(res[1])
      this.documentosExcluidos = res[2].results.map((item)=>{
        item.fecha_exclusion = this.parseDate(item.fecha_exclusion)
        return item
      })
    })
  }

  parseDate(date) {
    if (!date)
      return null;
    
    let dateNow = new Date();
    let yy = date.split('-')[0];
    let mm = (date.split('-')[1] - 1);
    let dd = (date.split('-')[2]).substring(0,2);

    let d = new Date(yy, mm, dd, dateNow.getHours());
    
    return d;
  }

  /**
 * Habilita el loader para request a la API
 */
   spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();
    this.appService.notifyMe(mensaje, ok)

    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);
  }


  onChangedEditor(event: any): void {
    if (event.html) {
        this.htmlContent = event.html;
      }
  }

  obtenerPlantillas(page: number = 1, page_size: number = 10) {

    // const url = this.apiUrl + "cobranzas_plantillas/"+
    const url = this.apiUrl + "factoring/cobranzas_plantillas/"+
      `?page=${page}` +
      `&page_size=${page_size}`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });

  }

  customSearchFn(term: string, item) {
    term = term.toLowerCase();
    let searchEmail = item.email.toString().toLowerCase().includes(term)
    let searchNombre = item.nombre.toString().toLowerCase().includes(term);
    return searchEmail || searchNombre
  }

  addTagFn(name) {
    return { email: name, nombre: name, tag: true };
  }

  loadTable(data: any) {
    this.modelosCorreos = data.results.map((itemModelo ,index, arr)=>{
      if(itemModelo.dest_para != ""){
        itemModelo.dest_para = itemModelo.dest_para.split(',')
        itemModelo.dest_para.forEach(para => {
          let index = this.correos.findIndex((correos)=> correos.email == para)
    
          if(index < 0){
            this.correos.push(this.addTagFn(para))
          }
        });
      }
  
      if(itemModelo.dest_cc  != ""){
        itemModelo.dest_cc = itemModelo.dest_cc.split(',')
        itemModelo.dest_cc.forEach(cc => {
          let index = this.correos.findIndex((correos)=> correos.email == cc)
    
          if(index < 0){
            this.correos.push(this.addTagFn(cc))
          }
        });
      }


  

      return itemModelo
    })

    
    let modeloVencidoMasSeisDias                = this.modelosCorreos.find((modelo) => modelo.id == 4)
    modeloVencidoMasSeisDias.glosa_inicial      = modeloVencidoMasSeisDias.glosas.find(gls => gls.id == 11)
    modeloVencidoMasSeisDias.glosa_recordar     = modeloVencidoMasSeisDias.glosas.find(gls => gls.id == 12)
    modeloVencidoMasSeisDias.glosa_adicional    = modeloVencidoMasSeisDias.glosas.find(gls => gls.id == 13)
    modeloVencidoMasSeisDias.glosa_despedida    = modeloVencidoMasSeisDias.glosas.find(gls => gls.id == 21)


    let modeloVencidoCuatroSeisDias             = this.modelosCorreos.find((modelo) => modelo.id == 3)
    modeloVencidoCuatroSeisDias.glosa_inicial   = modeloVencidoCuatroSeisDias.glosas.find(gls => gls.id == 10)
    modeloVencidoCuatroSeisDias.glosa_recordar  = modeloVencidoCuatroSeisDias.glosas.find(gls => gls.id == 9)
    modeloVencidoCuatroSeisDias.glosa_adicional = modeloVencidoCuatroSeisDias.glosas.find(gls => gls.id == 8)
    modeloVencidoCuatroSeisDias.glosa_despedida = modeloVencidoCuatroSeisDias.glosas.find(gls => gls.id == 20)


    let modeloVencidoMenoTresDias               = this.modelosCorreos.find((modelo) => modelo.id == 1)
    modeloVencidoMenoTresDias.glosa_inicial     = modeloVencidoMenoTresDias.glosas.find(gls => gls.id == 3)
    modeloVencidoMenoTresDias.glosa_recordar    = modeloVencidoMenoTresDias.glosas.find(gls => gls.id == 2)
    modeloVencidoMenoTresDias.glosa_despedida   = modeloVencidoMenoTresDias.glosas.find(gls => gls.id == 17)
    modeloVencidoMenoTresDias.glosa_adicional   = modeloVencidoMenoTresDias.glosas.find(gls => gls.id == 22)

    let modeloPorVencerSieteDias                = this.modelosCorreos.find((modelo) => modelo.id == 2)
    modeloPorVencerSieteDias.glosa_inicial      = modeloPorVencerSieteDias.glosas.find(gls => gls.id == 7)
    modeloPorVencerSieteDias.glosa_recordar     = modeloPorVencerSieteDias.glosas.find(gls => gls.id == 6)
    modeloPorVencerSieteDias.glosa_despedida    = modeloPorVencerSieteDias.glosas.find(gls => gls.id == 19)
    modeloPorVencerSieteDias.glosa_adicional    = modeloPorVencerSieteDias.glosas.find(gls => gls.id == 23)

    let modeloPorVencerHoy                      = this.modelosCorreos.find((modelo) => modelo.id == 5)
    modeloPorVencerHoy.glosa_inicial            = modeloPorVencerHoy.glosas.find(gls => gls.id == 14)
    modeloPorVencerHoy.glosa_recordar           = modeloPorVencerHoy.glosas.find(gls => gls.id == 15)
    modeloPorVencerHoy.glosa_adicional          = modeloPorVencerHoy.glosas.find(gls => gls.id == 16)
    modeloPorVencerHoy.glosa_despedida          = modeloPorVencerHoy.glosas.find(gls => gls.id == 18)

    this.modelosCorreos= [
      modeloPorVencerSieteDias,
      modeloPorVencerHoy,
      modeloVencidoMenoTresDias,
      modeloVencidoCuatroSeisDias,
      modeloVencidoMasSeisDias,
    ]
  }


  actualizarModelos(itemModelo: any){
    let para;
    let cc
    if( Array.isArray(itemModelo.dest_para)){
      para = itemModelo.dest_para.reduce((accText,itemPara,index,arr)=> {
        if((index < arr.length) && (index != 0)){
          accText += ','
        }
        accText += itemPara.email ? itemPara.email : itemPara
        return accText
      },'')
    }

    if(Array.isArray(itemModelo.dest_cc)){
      cc = itemModelo.dest_cc.reduce((accText,itemPara,index,arr)=> {
        if((index < arr.length) && (index != 0)){
          accText += ','
        }
        accText += itemPara.email ? itemPara.email : itemPara
        return accText
      },'')
    }

    let modeloPack                                                               = itemModelo
    modeloPack.dest_para                                                         = para
    modeloPack.dest_cc                                                           = cc
    modeloPack.glosas                                                            = []
    modeloPack.glosa_inicial ? modeloPack.glosas.push(modeloPack.glosa_inicial)    : null;
    modeloPack.glosa_recordar ? modeloPack.glosas.push(modeloPack.glosa_recordar)  : null;
    modeloPack.glosa_adicional ? modeloPack.glosas.push(modeloPack.glosa_adicional): null;
    modeloPack.glosa_despedida ? modeloPack.glosas.push(modeloPack.glosa_despedida): null;
    console.log(itemModelo)
    console.log(modeloPack)
    this.actualizarModelosCorreos(itemModelo, itemModelo.id).then((Res)=>{
      if(itemModelo.dest_para != ""){
        if(!itemModelo.dest_para.split(',')){
          itemModelo.dest_para = [itemModelo.dest_para]
        } else {
          itemModelo.dest_para = itemModelo.dest_para.split(',')
        }
      }
  
      if(itemModelo.dest_cc != ""){
        if(!itemModelo.dest_cc.split(',')){
          itemModelo.dest_cc = [itemModelo.dest_cc]
        } else{
          itemModelo.dest_cc = itemModelo.dest_cc.split(',')
        }
      }
    })
  }

  /**
   * Actualiza los detalles de una operacion
   * @param id ID de la operacion que se desea actualizar
   * @param data Objeto JSON de la operacion
   */
    actualizarModelosCorreos(data: any, id) {
      const URL = this.apiUrl + 'factoring/cobranzas_plantillas/' + id + '/';
      return new Promise((resolve, reject) => {
        const httpReques = this.http.patch(URL, data);
        this.spinnerOn()
        httpReques.subscribe(data => {
            this.spinnerOff('La operación fue grabada con exito!')
            resolve(data);
        }, (err) => {
            this.spinnerOff('Ocurrió un error durante el proceso :' + err)
            reject(err);
        });
      });
    }

    obtenerExlcluidos() {
      const URL = this.apiUrl + 'factoring/carteras/excluir_notificacion_cobro/';
      return new Promise((resolve, reject) => {
        const httpReques = this.http.get(URL);
        this.spinnerOn()
        httpReques.subscribe(data => {
            this.spinnerOff()
            resolve(data);
        }, (err) => {
            this.spinnerOff('Ocurrió un error durante el proceso :' + err)
            reject(err);
        });
      });
    }

    guardar(doc){
      let objGuardar = {
        documento_cartera: doc.id,
        fecha_exclusion: this.formaStringDate(doc.fecha_exclusion)
      }
      this.guardarExclusion(objGuardar).then((res: any)=>{
        let index = this.documentosExcluidos.findIndex((item)=> item.id == doc.id)
        res.fecha_exclusion = this.parseDate(res.fecha_exclusion)
        this.documentosExcluidos.splice(index,1,res)
      })
    }

    actualizar(doc){
      let fecha = this.formaStringDate(doc.fecha_exclusion)
      let objGuardar = {
        documento_cartera: doc.documento_cartera,
        fecha_exclusion: fecha
      }
      this.actualizarExclusion(doc.id,objGuardar).then((res:any)=>{
        res.fecha_exclusion = this.parseDate(res.fecha_exclusion)
        let index = this.documentosExcluidos.findIndex((item)=> item.id == doc.id)
        this.documentosExcluidos.splice(index,1,res)
      })
    }
    
    dateChange(evnt,doc){
      let index = this.documentosExcluidos.findIndex((item)=> item.id == doc.id)
      this.documentosExcluidos[index].fecha_exclusion = evnt
    }

    eliminar(doc){
      if(doc.documento_cartera){
        this.eliminarExclusion(doc.id).then((res)=>{
          console.log(res)
          let index = this.documentosExcluidos.findIndex((item)=> item.id == doc.id)
          this.documentosExcluidos.splice(index,1)
        })
      } else {
        let index = this.documentosExcluidos.findIndex((item)=> item.id == doc.id)
        this.documentosExcluidos.splice(index,1)
      }
    }

    guardarExclusion(body: any) {
      const URL = this.apiUrl + 'factoring/carteras/excluir_notificacion_cobro/';
      return new Promise((resolve, reject) => {
        const httpReques = this.http.post(URL,body);
        // this.spinnerOn()
        httpReques.subscribe(data => {
            this.spinnerOff('La exclusión fue grabada con exito!')
            resolve(data);
        }, (err) => {
            this.spinnerOff('Ocurrió un error durante el proceso :' + err)
            reject(err);
        });
      });
    }

    actualizarExclusion(id,body: any) {
      const URL = this.apiUrl + 'factoring/carteras/excluir_notificacion_cobro/'+`${id}/`;
      return new Promise((resolve, reject) => {
        const httpReques = this.http.patch(URL,body);
        // this.spinnerOn()
        httpReques.subscribe(data => {
            this.spinnerOff('La exclusión fue actualizada con exito!')
            resolve(data);
        }, (err) => {
            this.spinnerOff('Ocurrió un error durante el proceso :' + err)
            reject(err);
        });
      });
    }

    eliminarExclusion(id: any) {
      const URL = this.apiUrl + 'factoring/carteras/excluir_notificacion_cobro/'+`${id}/`;
      return new Promise((resolve, reject) => {
        const httpReques = this.http.delete(URL);
        // this.spinnerOn()
        httpReques.subscribe(data => {
            this.spinnerOff('La exclusión fue retirada con exito!')
            resolve(data);
        }, (err) => {
            this.spinnerOff('Ocurrió un error durante el proceso :' + err)
            reject(err);
        });
      });
    }

    mostrarExclusiones(filas: any[]) {
      const modalRef = this.modalService.open(ModalExclusionesDocsComponent, {size: 'xl'});
      // modalRef.componentInstance.seleccionRecordatorios = filas
    }

    formaStringDate(date) {
      if (!(date instanceof Date)) return;
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    }

    mostrarDocumentos(modelo) {
      let now = new Date()
      now.setHours(0,0,0)
      let filtros = {
        fecha_vencimiento__gte: null,
        fecha_vencimiento__lte: null,
        estado: null,
      }
      switch(modelo.id){
        case 2:
          now.setDate(now.getDate() + 7)
          filtros.fecha_vencimiento__gte = this.formatDate(now)
          filtros.fecha_vencimiento__lte = this.formatDate(now)
        break;
        case 5:
          //Vencen HOY
          filtros.fecha_vencimiento__lte = this.formatDate(now)
          filtros.fecha_vencimiento__gte = this.formatDate(now)
        break;
        case 1:
          //Vencidos menor a 2 dias
          filtros.fecha_vencimiento__lte = this.formatDate(now)
          now.setDate(now.getDate() - 2)
          filtros.fecha_vencimiento__gte = this.formatDate(now)
        break;
        case 3:
          //Vencidos de 5 a 6 dias
          now.setDate(now.getDate() - 5)
          filtros.fecha_vencimiento__lte = this.formatDate(now)
          now.setDate(now.getDate() - 1)
          filtros.fecha_vencimiento__gte = this.formatDate(now)
        break;
        case 4:
          //Vencidos mas de 7 dias
          now.setDate(now.getDate() - 7)
          filtros.fecha_vencimiento__lte = this.formatDate(now)
          const INICIO_DE_LOS_TIEMPOS = '1970-1-1'
          filtros.fecha_vencimiento__gte = INICIO_DE_LOS_TIEMPOS
          filtros.estado = 3
        break;
      }
      const modalRef                               = this.modalService.open(TablaDocsComponent, {size: 'xl'});
      modalRef.componentInstance.pageTablePerPages = 25
      modalRef.componentInstance.selectable        = false
      modalRef.componentInstance.filtros           = filtros
    }

    formatDate(date) {
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate()
      );
    }

    buscarDocumentos(filas: any[] = []) {
      const modalRef                             = this.modalService.open(TablaDocsComponent, {size: 'xl'});
      modalRef.componentInstance.selectable      = true;
      let preselect                              = this.documentosExcluidos.map(elmnt => { 
        if(elmnt.documento_cartera){
          return elmnt.documento_cartera
        } else {
          return elmnt.id
        }
      });
      modalRef.componentInstance.seleccionPrevia = preselect
      modalRef.componentInstance.tabladocs.subscribe((seleccion: any[]) => {
        this.documentosExcluidos.forEach((doc)=>{
          let index = seleccion.findIndex((elm) => {
            if(doc.documento_cartera){
              return elm.id == doc.documento_cartera
            } else {
              return elm.id == doc.id
            }
          })
          index >= 0 ? seleccion.splice(index,1) : null
        })

        seleccion =  seleccion.map((item)=>{
          let fecha = new Date()
          item.fecha_exclusion = fecha
          item.cliente = item.beneficiario_nombre
          item.nro_ope = item.operacion
          item.numero_documento = item.operacion_numero_documento
          item.tipo_doc = item.operacion_tipo_detalle_descripcion
          item.deudor = item.aceptante_nombre
          item.cliente = item.beneficiario_nombre
          return item
        })
        let temp = this.documentosExcluidos
        this.documentosExcluidos = [] 
        this.documentosExcluidos = [...temp,...seleccion]
      });
    }
}
