import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ClientesService } from "app/core/services/clientes/clientes.service";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { ConfirmModalComponent } from "app/shared/utils/confirm-modal/confirm-modal.component";
import { AddAssetComponent } from "app/modules/clientes/modals/add-asset/add-asset.component";

@Component({
  selector: " app-business-information-relations-assets",
  templateUrl: "./relations-assets.component.html",
  styleUrls: ["./relations-assets.component.css"],
})
export class RelationsAssetsComponent implements OnInit {
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public searchstring: string = "";
  public monedaBien: number = 2;

  @Input() clienteId = 0;
  @Input() readonly = false;

  constructor(
    private clientesService: ClientesService,
    public modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.obtenerActivos();
  }

  obtenerActivos(page = 1, page_size = 10) {
    this.clientesService
      .obtenerActivos(this.clienteId.toString(), page, page_size)
      .then((res) => {
        this.initVars();
        this.loadTable(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadTable(data) {
    this.rowData = data.results.map((el: any, i: number) => ({
      ...el,
      index: i + 1,
    }));
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars() {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Tipo de Bien",
        field: "tipo_bien_nombre",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Ubicación",
        field: "ubicacion",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Inmuebles Nro Ficha",
        field: "ficha_rrpp",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Marca",
        field: "marca",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Modelo",
        field: "modelo",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Serie",
        field: "serie",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Placa",
        field: "placa",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Año",
        field: "anio",
        sortable: true,
      },
      {
        headerName: "Gravado a Favor de ",
        field: "gravado_beneficiario",
        class: "text-center",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fecha de Compra",
        field: "fecha_compra",
        pipe: "date",
      },
      {
        class: "text-center",
        headerName: "Valor de Compra $US",
        field: "valor_compra",
        pipe: "currency",
        moneda: "monedaBien",
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {
    this.obtenerActivos(page, per_page);
  }

  tableEditEvent(activo) {
    const modalRef = this.modalService.open(AddAssetComponent, {
      size: "lg",
    });

    modalRef.componentInstance.clienteId = this.clienteId;
    modalRef.componentInstance.activoId = activo.id;

    modalRef.componentInstance.successEmit.subscribe(
      (result) => {
        this.obtenerActivos();
      },
      (error) => {}
    );
  }

  destroyContact(activo) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {});

    modalRef.componentInstance.title = "Remover Activo";
    modalRef.componentInstance.message = "¿Seguro que desea remover el activo?";

    modalRef.result.then((result) => {
      if (result) {
        this.clientesService
          .eliminarActivo(activo.id)
          .then((result) => {
            this.obtenerActivos();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }

  openModal() {
    const modalRef = this.modalService.open(AddAssetComponent, {
      size: "lg",
    });

    modalRef.componentInstance.clienteId = this.clienteId;

    modalRef.componentInstance.successEmit.subscribe(
      (result) => {
        this.obtenerActivos();
      },
      (error) => {}
    );
  }
}
