import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { ParametrosService } from 'app/core/services/configuration/parametros.service';
import { VerificationsService } from 'app/core/services/factoring/verifications.service';
import { columnHeader, FactoringDatatableComponent } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { MonedaPipe } from 'app/core/pipes/moneda.pipe';

@Component({
  selector: 'app-multi-verificaction-modal',
  templateUrl: './multi-verificaction-modal.component.html',
  styleUrls: ['./multi-verificaction-modal.component.css'],
  providers: [MonedaPipe]
})
export class MultiVerificactionModalComponent implements OnInit {

  public minDate: Date;
  faTimes = faTimes;
  myForm: FormGroup;
  @Input() estados_list: any[] = [];
  @Input() tableData: any[] = [];
  @Input() detalles: any[] = [];
  @Input() operacionId: any;
  @Input() clienteFactoring: any;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();
  listadoAceptantes: any[] = [];
  readOnlyEstado = false;
  public rowData          : any[]   = [];
  public columnDefs       : columnHeader[];
  mostrarTablaDocumentos = false;
  mostrarMensaje = false;
  documentosSeleccionados: any[] = [];

  aceptanteSelected: any;

  deudorSelect: any;
  deudorSelectD: any;
  searchTextRucD  = "";
  loadingSearch2: boolean = false;
  rucResultsD$    : Observable<any[]>;
  searchTextD$    = new Subject<string>();

  loadingSearch: boolean = false;
  caracteresMinimos: number = 4;

  contactosFactoring = [];

  contactListSize = 0;
  contactSize = 10;
  loading = false;

  showDataCavali = false
  tblAcciones = []
  clausulasEspeciales = ''
  valoresEsperadosCavali = {}
  cavaliFields = [
    'register_date', 'sunat_response', 'invoice_sunat_state', 'deliver_date_acq', 'acq_response',
    'invoice_state', 'special_clauses', 'reschedule_date',
  ]

  @ViewChild(FactoringDatatableComponent) tablaDocsRef: FactoringDatatableComponent ;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private verificacionService: VerificationsService,
    private clientesService: ClientesService,
    private parametrosService : ParametrosService,
    private monedaPipe: MonedaPipe
  ) { }

  ngOnInit(): void {
    this.procesarAceptantes();
    this.initForm();
    this.initTables();

    console.log("CF", this.clienteFactoring)
    if (this.clienteFactoring) { 
      this.clientesService.obtenerContactos(this.clienteFactoring.id).then(res=>{
        this.contactosFactoring = res['results']
      }) 
    }

    this.parametrosService.obtenerParametro('CAVALI_CLAUSULAS_ESPECIALES').subscribe(
      data => this.clausulasEspeciales = data.valor,
      res => console.log(res)
    )

    this.parametrosService.obtenerParametro('CAVALI_VALORES_VALIDOS_DESEMBOLSO').subscribe(
      data => this.valoresEsperadosCavali = JSON.parse(data.valor),
      res => console.log(res)
    )
  }
  initTables() {
    let headerDocumentsCarteraTable = [
      {
        headerName: "N°",
        pipe: "indexcol",
        sortable: true,
      },
      {
        headerName: "RUC Aceptante",
        field: "deudor_ruc",
        sortable: true,
        class: "text-center",
        pipe: 'functionInnertHTML',
        function: row => `
          ${this.indicadorValidacion(row, '__diff_ruc')}
          <span>${row['deudor_ruc']}</span>
        `
      },
      {
        headerName: "Número de Documento",
        field: "numero_documento",
        sortable: true,
        class: "text-center",
        pipe: 'functionInnertHTML',
        function: row => `
          ${this.indicadorValidacion(row, '__diff_numero_doc')}
          <span>${row['numero_documento']}</span>
        `
      },
      {
        headerName: "Monto Neto",
        field: "neto_pagar",
        sortable: true,
        class: "text-center",
        pipe: 'functionInnertHTML',
        function: row => `
          ${this.indicadorValidacion(row, '__diff_neto')}
          <span>${this.monedaPipe.transform(row['neto_pagar'], row['moneda'])}</span>
        `
      },
      {
        headerName: "Vencimiento CAVALI",
        field: "vencimiento_cavali",
        sortable: true,
        class: "text-center",
        pipe: 'functionInnertHTML',
        function: row => `
          ${this.indicadorValidacion(row, '__diff_vencimiento')}
          <span>${this.parseFormatDate(row['vencimiento_cavali']) || ''}</span>
        `
      },
      {
        headerName: "Estado",
        field: "estado_descripcion",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Contacto (Deudor)",
        field: "contacto_deudor",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Télefono (Deudor)",
        field: "telefono_deudor",
        sortable: true,
        class: "text-center",
      },
    ];

    this.tblAcciones = []
    if (this.showDataCavali) {
      const cavaliColumns = [
        {
          headerName: "Indicador de venta",
          field: 'indicador_venta',
          class: "text-center",
          sortable: false,
        },
        {
          headerName: "Claúsulas especiales",
          field: '',
          class: "text-center",
          sortable: false,
          pipe: 'functionInnertHTML',
          function: row => this.indicadorValidacion(row, '__diff_clausulas')
        },
        {
          headerName: "Fecha de registro",
          field: '',
          class: "text-center",
          sortable: false,
          pipe: 'functionInnertHTML',
          function: row => this.indicadorValidacion(row, '__diff_fecha_registro')
        },
        {
          headerName: "Respuesta Sunat",
          field: '',
          class: "text-center",
          sortable: false,
          pipe: 'functionInnertHTML',
          function: row => this.indicadorValidacion(row, '__diff_respuesta_sunat')
        },
        {
          headerName: "Estado Sunat",
          field: '',
          class: "text-center",
          sortable: false,
          pipe: 'functionInnertHTML',
          function: row => this.indicadorValidacion(row, '__diff_estado_sunat')
        },
        {
          headerName: "Fecha entrega factura",
          field: '',
          class: "text-center",
          sortable: false,
          pipe: 'functionInnertHTML',
          function: row => this.indicadorValidacion(row, '__diff_fecha_entrega')
        },
        {
          headerName: "Respuesta adquiriente",
          field: '',
          class: "text-center",
          sortable: false,
          pipe: 'functionInnertHTML',
          function: row => this.indicadorValidacion(row, '__diff_respuesta_adquiriente')
        },
        {
          headerName: "Fecha reprogramación",
          field: 'reschedule_date',
          class: "text-center",
          sortable: false,
        },
        {
          headerName: "Estado",
          field: '',
          class: "text-center",
          sortable: false,
          pipe: 'functionInnertHTML',
          function: row => this.indicadorValidacion(row, '__diff_estado')
        },
        {
          headerName: "Error",
          field: '',
          sortable: false,
          class: "text-center",
          pipe: "function",
          function: row => !row['_cavali_error'] ? '' : row['_cavali_error']
        }
      ]
      
      headerDocumentsCarteraTable = headerDocumentsCarteraTable.concat(cavaliColumns)

      this.tblAcciones = [
        {
          tooltip: 'Diferencia Cavali', icon: 'fa fa-eye', class: 'btn-warning',
          click: row => {
            Swal.fire({
              title: `Documento ${row['numero_documento']}`,
              html: `
              <div style="color: white">
                <table class="table" style="font-size: 14px">
                  <thead>
                    <tr>
                      <th>Dato</th>
                      <th style="min-width: 150px">Digital | Valor esperado</th>
                      <th style="min-width: 150px">Cavali</th>
                    </tr>
                  </thead>
                  <tbody>
                  ${row['_cavali_diff'].map(e => `
                    <tr>
                      <td>${e[1]}</td>
                      <td>${e.length > 4 ? e[4] : ''}${e[3] ?? ''}</td>
                      <td>${e[2] ?? ''}</td>
                    </tr>
                  `)}
                  </tbody>
                </table>
              </div>
              `,
              customClass: {
                content: 'text-left'
              },
              confirmButtonText: 'Cerrar',
              allowOutsideClick: false,
              width: 800
            })
          },
          visible: row => !!row['_cavali_diff'] && row['_cavali_diff'].length > 0
        },
        {
          tooltip: 'Consultar Cavali', icon: 'fa fa-refresh', class: 'btn-info mr-2',
          iconClass: row => row['_cavali_loading'] ? 'fa-spin' : '',
          click: row => this.consultaCavali(row),
        }
      ]
    }

    this.columnDefs = headerDocumentsCarteraTable;
  }
  procesarAceptantes(){
    if(this.tableData.length > 0){
      var aceptantes = [];
      aceptantes.push({
        deudor : 0,
        deudor_nombre : '--- SELECCIONAR TODOS ---'
      });
      var result = this.tableData.reduce((unique, o) => {
        if(!unique.some(obj => obj.deudor === o.deudor)) {
          unique.push({
            deudor : o.deudor,
            deudor_nombre : o.deudor_nombre
          });
        }
        return unique;
      },[]);
      if(result.length > 1){
        aceptantes = [...aceptantes,...result]
      }
      this.listadoAceptantes = aceptantes;
    }
  }
  filtrarDocumentos(e){
    if(e.deudor != 0){
      let dataFiltrada = this.detalles.filter( x => x.deudor === e.deudor);
      this.rowData = dataFiltrada;
      this.mostrarTablaDocumentos = true;
      this.documentosSeleccionados = [];
    }else{
      this.rowData = this.detalles;
      this.mostrarTablaDocumentos = true;
      this.documentosSeleccionados = [];
    }
    this.selectAllItems(false)
    this.mostrarMensaje = this.rowData.length == 0 ? true : false;
  }
  selectedItem(row){
    const id = row.id;

    const findRow = this.documentosSeleccionados.find(item => item.id == id);

    if (findRow) {
      this.documentosSeleccionados = this.documentosSeleccionados.filter(
        (item) => item.id !== id
      );
    } else {
      this.documentosSeleccionados.push(row);
    }
  }
  initForm() {
    this.myForm = this.formBuilder.group({
      vencimiento_cavali: [null, []],
      vencimiento: [null, []],
      comentario: [null, []],
      estado: [null, []],
      aceptante : [null, []],
      contacto_deudor : [null, []],
      telefono_deudor : [null, []],
      contacto_nombre_search : [null, []],
    });

    this.minDate = new Date();

    if (this.authService.user.isOficialDeNegocio) {
      this.readOnlyEstado = true;
    }

    this.myForm.controls.contacto_nombre_search.valueChanges.subscribe((value) => {
      console.log("CONTACTO", value)
      if (value && this.clienteFactoring && typeof(value) == 'object') { 
        // value es un objeto
        this.myForm.controls.contacto_deudor.setValue(value.nombre)
        this.myForm.controls.telefono_deudor.setValue(value.telefono)
        console.log("CON2", value, this.myForm.controls.contacto_deudor.value, this.myForm.controls.telefono_deudor.value)
      } else { 
        // value es un numero
        this.obtenerContatos(value);
      }
    });

    this.myForm.controls.aceptante.valueChanges.subscribe((value) => {
      if (value !== null) {
        // Reinicio form relacionado a aceptante
        this.myForm.controls.contacto_nombre_search.setValue(null)
        this.myForm.controls.contacto_deudor.setValue(null)
        this.myForm.controls.telefono_deudor.setValue(null)
      }
      
      if (value == 0 && this.listadoAceptantes.length != 1) {
        this.aceptanteSelected = null
      } 
      else if (value == 0 && this.listadoAceptantes.length == 1) {
        // Cuando tengo solo un aceptante, miro la prier factura y saco el deudor
        this.aceptanteSelected = this.tableData[0]["deudor"]
        this.recargarContactos();
      }
      else if (value > 0) {
        this.aceptanteSelected = value
        this.recargarContactos();
      }
    });
  }

  formatDate(date) {
    if (!(date instanceof Date)) return;
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  onSubmit() {
    let dataDetalles = [];
    let comments = [];

    let values = this.myForm.value;
    let detalles = this.documentosSeleccionados;


    if (values.contacto_deudor || values.telefono_deudor || values.estado || values.vencimiento_cavali || values.vencimiento) {
      dataDetalles = detalles.map(item => {
        let data = item;
        data['operacion'] = this.operacionId;
        delete data['operacion_xml'];
        delete data['adjunto_pdf'];
        (values.estado) ? data['estado'] = values.estado : '';
        (values.vencimiento_cavali) ? data['vencimiento_cavali'] = this.formatDate(values.vencimiento_cavali) : '';
        (values.vencimiento_cavali) ? data['vencimiento_cavali_doc'] = this.formatDate(values.vencimiento_cavali) : '';
        (values.vencimiento) ? data['vencimiento'] = this.formatDate(values.vencimiento) : '';
        (values.telefono_deudor) ? data['telefono_deudor'] = values.telefono_deudor : '';
        (values.contacto_deudor) ? data['contacto_deudor'] = values.contacto_deudor : '';

        return data;
      });
    }

    if (values.comentario) {
      detalles.forEach(element => {
        let data = {
          operacion_detalle: element.id,
          responsable: this.authService.user.id,
          comentario: this.myForm.controls['comentario'].value,
        }
        comments.push(data);
      });
    }

    if (dataDetalles.length || comments.length) {

      this.verificacionService.verificarDetalles(dataDetalles, comments,'Se ha realizado el registro exitosamente')
        .then(res => {
          console.log(res);
          this.activeModal.close(true);
        }).catch(error => {
          console.error(error);
          this.activeModal.close(false);
        });

    } else {
      console.log('No hay data');
    }

  }

  
  addTagPromiseD(name) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ deudor: 0, nombre: name, telefono: "" });
      }, 500);
    });
  }

  resetContact() {
    this.myForm.controls['contacto_nombre_search'].setValue('');
    this.myForm.controls['telefono_deudor'].setValue('');
    if(this.tableData && (this.contactSize != this.contactListSize)){
      this.recargarContactos();
    }
  }

  changeRucSearchD(e) {
    console.log(e)
    this.searchTextRucD = e.term;
  }

  onScrollToEnd() {
    if(this.contactListSize < this.contactSize){
      return;
    }
    this.contactSize += this.contactSize;
    this.recargarContactos();
  }

  recargarContactos(){
    this.searchTextD$ = new BehaviorSubject<string>('');
    this.loadContactos();
  }

  loadContactos() {
    this.rucResultsD$ = concat(
      of([]), // Items predeterminados
      this.searchTextD$.pipe(
        filter((res) => {
          return this.aceptanteSelected; 
         }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.loadingSearch2 = true)),
        switchMap((term) => {
          console.log("term:", term)
          return this.verificacionService.buscarContactosDeudorObserve(term, this.aceptanteSelected, 1, this.contactSize).pipe(
            catchError(() => of([])), // empty list on error
            tap((response) => {
              this.loadingSearch2 = false;
              let newResponse: any[] = response.slice(this.contactListSize, this.contactSize + this.contactListSize);
              this.contactListSize = this.contactListSize + newResponse.length;
            })
          );
        })
      )
    );
  }

  obtenerContatos(contactoId) {
    console.log("contacto", contactoId)
    if (!contactoId) {
      this.deudorSelectD = null;
      this.myForm.controls["contacto_deudor"].setValue(this.searchTextRucD);
    } else {
      this.verificacionService
        .obtenerContacto(contactoId)
        .then((res) => {
          this.deudorSelectD = res;
          this.myForm.controls["contacto_deudor"].setValue(res["nombre"]);
          this.myForm.controls["telefono_deudor"].setValue(res["telefono"]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  selectAllItems(bool) {
    if (this.tablaDocsRef) this.tablaDocsRef.allCheckedChange(bool)

    if (bool) {
      this.documentosSeleccionados = this.rowData
    } else {
      this.documentosSeleccionados = []
    }
  }

  toggleDataCavali() {
    this.showDataCavali = !this.showDataCavali
    this.initTables()
    if (!this.showDataCavali) return
    this.ordenarObservados()
    this.rowData.forEach(row => {
      if (!row['_cavali_success']) {
        this.consultaCavali(row)
      }
    })
  }

  consultaCavali(row) {
    if (row['_cavali_loading']) return
    row['_cavali_loading'] = true
    row['_cavali_error'] = null
    row['_cavali_diff'] = []
    row['_cavali_diff_fields'] = {}
    this.cavaliFields.forEach(field => {
      const fieldLabel = `${field}_label`
      delete row[field]
      if (fieldLabel in row) {
        delete row[fieldLabel]
      }
    })
    this.verificacionService.consultaCavali(row['id']).subscribe(
      (data: any) => {
        if (!!data && data.length == 0) {
          row['_cavali_success'] = false
          row['_cavali_error'] = 'No encontrado'
          return
        }
        data.sort((a, b) => a['process_number'] - b['process_number'])
        data = data[data.length - 1]
        this.cavaliFields.forEach(field => {
          const fieldLabel = `${field}_label`
          row[field] = data[field]
          if (fieldLabel in data) {
            row[fieldLabel] = data[fieldLabel]
          }
        })
        // Diferencias
        row['_cavali_diff'] = [
          ['__diff_ruc', 'RUC', data['acq_ruc'], row['deudor_ruc']],
          // ['__diff_razon_social', 'Razón social', data['acq_full_name'], row['deudor_nombre']],
          ['__diff_numero_doc', 'Nro documento', `${data['series']}-${data['numeration']}`, row['numero_documento'].replace(/-(0+)/, '-')],
          ['__diff_neto', 'Monto neto', this.monedaPipe.transform(data['net_amount_pending'], data['currency']), this.monedaPipe.transform(row['neto_pagar'], row['moneda'])],
          ['__diff_vencimiento', 'Vencimiento cavali', this.parseFormatDate(data['payment_date']), this.parseFormatDate(row['vencimiento_cavali'])],
          ['__diff_clausulas', 'Claúsulas especiales', data['special_clauses'], this.clausulasEspeciales],
        ].filter(([__, _, cavali, digital]) => `${cavali}`.normalize('NFD').replace(/[\u0301-\u034F]/g, '').replace(/\u00A0/g, ' ').toUpperCase() != `${digital}`.normalize('NFD').replace(/[\u0301-\u034F]/g, '').replace(/\u00A0/g, ' ').toUpperCase())
        row['_cavali_diff'] = row['_cavali_diff'].concat(
          [
            // ['__diff_indicador_venta', 'Indicador de venta', data['sale_indicator_label'], this.valoresEsperadosCavali['sale_indicator']],
            ['__diff_respuesta_sunat', 'Respuesta Sunat', data['sunat_response_label'], this.valoresEsperadosCavali['sunat_response']],
            ['__diff_estado_sunat', 'Estado Sunat', data['invoice_sunat_state_label'], this.valoresEsperadosCavali['invoice_sunat_state']],
            ['__diff_respuesta_adquiriente', 'Respuesta adquiriente', data['acq_response_label'], this.valoresEsperadosCavali['acq_response']],
            ['__diff_estado', 'Estado', data['invoice_state_label'], this.valoresEsperadosCavali['invoice_state']],
          ].filter(([__, _, cavali, valido]) => !`${valido}`.split(',').map(e => e.trim().toUpperCase()).includes(`${cavali}`.toUpperCase()))
        )
        row['_cavali_diff'] = row['_cavali_diff'].concat(
          [
            ['__diff_fecha_registro', 'Fecha de registro', data['register_date'], this.parseFormatDate(row['fecha_emision']), 'Mayor o igual a: '],
            ['__diff_fecha_entrega', 'Fecha de entrega', data['deliver_date_acq'], this.parseFormatDate(row['fecha_emision']), 'Mayor o igual a: '],
          ].filter(([__, _, cavali, digital]) => !cavali || !digital || this.esFechaMenor(cavali, digital))
        )
        // if (row['vencimiento_cavali_rep'] || row['neto_pagar_rep']) {
        //   if (!data['reschedule_date'] || !row['fecha_emision'] || this.esFechaMenor(data['reschedule_date'], row['fecha_emision'])) {
        //     row['_cavali_diff'].push(['__diff_fecha_reprogramacion', 'Fecha de reprogramación', data['reschedule_date'], this.parseFormatDate(row['fecha_emision']), 'Mayor o igual a: '])
        //   }
        // } else {
        //   if (data['reschedule_date']) {
        //     row['_cavali_diff'].push(['__diff_fecha_reprogramacion', 'Fecha de reprogramación', data['reschedule_date'], 'Sin reprogramación'])
        //   }
        // }
        row['_cavali_diff'].forEach(e => row['_cavali_diff_fields'][e[0]] = true)
        row['_cavali_success'] = true
      },
      (res: any) => {
        let error = res.error
        if (res.error && typeof (res.error) == 'object') {
          error = Object.values(res.error).map(e => typeof e == 'object' ? JSON.stringify(e) : e).join('\n')
        }
        row['_cavali_success'] = false
        row['_cavali_error'] = error instanceof Object ? JSON.stringify(error) : error
      }
    ).add(() => {
      row['_cavali_loading'] = false
      this.ordenarObservados()
    })
  }

  colorRow() {
    return row => {
      if (!this.showDataCavali) return ''
      return !!row['_cavali_error'] ? '#f003' : ''
    }
  }

  private ordenarObservados() {
    this.rowData.sort((a, b) => {
      if (a['_cavali_success'] != b['_cavali_success']) {
        return b['_cavali_success'] ? -1 : 1
      }
      if ((a['_cavali_diff'] ?? []).length != (b['_cavali_diff'] ?? []).length) {
        return (b['_cavali_diff'] ?? []).length - (a['_cavali_diff'] ?? []).length
      }
      return b['id'] - a['id']
    })
  }

  private indicadorValidacion(row, field) {
    if (!this.showDataCavali) return ''
    if (!row['_cavali_success'] || row['_cavali_loading']) return ''
    return row['_cavali_diff_fields'][field]
      ? `<i class="fa fa-warning text-warning"></i>`
      : `<i class="fa fa-check text-success"></i>`
  }

  private esFechaMenor(fechaMenor, fechaMayor) {
    if (!fechaMenor) return false
    fechaMenor = `${fechaMenor}`.substring(0, 10).split('/').reverse().join('-')
    fechaMayor = `${fechaMayor}`.substring(0, 10).split('/').reverse().join('-')
    fechaMenor = new Date(`${fechaMenor}T00:00:00`)
    fechaMayor = new Date(`${fechaMayor}T00:00:00`)
    return fechaMenor.getTime() < fechaMayor.getTime()
  }

  private parseFormatDate(dateStr) {
    if (!dateStr) return null
    dateStr = `${dateStr}`.substring(0, 10).split('-').reverse().join('/')
    return dateStr
  }
  
}
