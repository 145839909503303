import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { ToastService } from '../toast/toast.service';

import { AppService } from 'app/app.service';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {
  private apiUrl: string = "";

  constructor(
    private http: HttpClient,
    private appService: AppService,
    public toast: ToastService,
    ) {
    this.apiUrl = `${this.appService.settings.API_base_url}/factoring/beneficiarios/parametros/`
  }

  obtenerParametro(parametro: string) {
    const url = `${this.apiUrl}?parametro=${parametro}`
    return this.http.get(url).pipe(
      map((res: any) => {
        if (res.results.length > 0) {
          return res.results[0]
        }
        return {}
      })
    )
  }

  obtenerParametros(queryParams: object = {}) {
    const url = `${this.apiUrl}?${Object.entries(queryParams).map((e: any) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&')}`
    return this.http.get(url).pipe()
  }

  guardar(data) {
    const url = `${this.apiUrl}`
    let method$ = null
    if (data.id) {
      method$ = this.http.patch(`${url}${data.id}/`, data)
    } else {
      method$ = this.http.post(url, data)
    }
    return method$.pipe(
      catchError(res => this.handleError(res))
    )
  }

  private handleError(response) {
    let error = 'Ocurrió un error inesperado'
    if (response.error && typeof (response.error) == 'object') {
      error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
    }
    this.toast.warning(error)
    return throwError(response)
  }
}
