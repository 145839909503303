import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ClientesService } from "app/core/services/clientes/clientes.service";

@Component({
  selector: "app-business-information-ingresos",
  templateUrl: "./ingresos.component.html",
  styleUrls: ["./ingresos.component.css"],
})
export class IngresosComponent implements OnInit {
  @Input() clienteId = 0;
  @Input() readonly = false;

  public myForm: FormGroup;
  public exist: boolean = false;

  /* 
    TOTAL
  */

  public total_ventas_1 = null;
  public total_ventas_2 = null;
  public total_ventas_3 = null;
  public total_porcentaje = null;
  public total_ventas_promedio = null;
  public total_ventas_contado_porcentaje = null;
  public total_ventas_credito_porcentaje = null;
  public total_facturas_porcentaje = null;
  public total_facturas_plazo_promedio = null;
  public total_letras_porcentaje = null;
  public total_letras_plazo_promedio = null;

  constructor(
    private clientesService: ClientesService,
    public formbuild: FormBuilder
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.obtenerIngresos();

    if (this.readonly) {
      this.myForm.disable()
    }
  }

  initForm() {
    this.myForm = this.formbuild.group({
      agente_retencion: [false, []],

      anio_1: [null, [Validators.required]],
      anio_2: [null, [Validators.required]],
      mes_anio: [null, [Validators.required]],

      mercado_local_ventas_1: [{ value: null, disabled: false }, []],
      mercado_local_ventas_2: [{ value: null, disabled: false }, []],
      mercado_local_ventas_3: [{ value: null, disabled: false }, []],
      mercado_local_porcentaje: [{ value: null, disabled: false }, []],
      mercado_local_ventas_promedio: [{ value: null, disabled: false }, []],
      mercado_local_ventas_contado_porcentaje: [
        { value: null, disabled: false },
        [],
      ],
      mercado_local_ventas_credito_porcentaje: [
        { value: null, disabled: false },
        [],
      ],
      mercado_local_facturas_porcentaje: [{ value: null, disabled: false }, []],
      mercado_local_facturas_plazo_promedio: [
        { value: null, disabled: false },
        [],
      ],
      mercado_local_letras_porcentaje: [{ value: null, disabled: false }, []],
      mercado_local_letras_plazo_promedio: [
        { value: null, disabled: false },
        [],
      ],

      /* 
        MERCADO-EXTERNO
      */

      mercado_externo_ventas_1: [{ value: null, disabled: false }, []],
      mercado_externo_ventas_2: [{ value: null, disabled: false }, []],
      mercado_externo_ventas_3: [{ value: null, disabled: false }, []],
      mercado_externo_porcentaje: [{ value: null, disabled: false }, []],
      mercado_externo_ventas_promedio: [{ value: null, disabled: false }, []],
      mercado_externo_ventas_contado_porcentaje: [
        { value: null, disabled: false },
        [],
      ],
      mercado_externo_ventas_credito_porcentaje: [
        { value: null, disabled: false },
        [],
      ],
      mercado_externo_facturas_porcentaje: [
        { value: null, disabled: false },
        [],
      ],
      mercado_externo_facturas_plazo_promedio: [
        { value: null, disabled: false },
        [],
      ],
      mercado_externo_letras_porcentaje: [{ value: null, disabled: false }, []],
      mercado_externo_letras_plazo_promedio: [
        { value: null, disabled: false },
        [],
      ],
    });

    this.myForm.valueChanges.subscribe((form) => {
      this.total_ventas_1 = (
        Number(form["mercado_local_ventas_1"]) +
        Number(form["mercado_externo_ventas_1"])
      ).toFixed(2);
      this.total_ventas_2 = (
        Number(form["mercado_local_ventas_2"]) +
        Number(form["mercado_externo_ventas_2"])
      ).toFixed(2);
      this.total_ventas_3 = (
        Number(form["mercado_local_ventas_3"]) +
        Number(form["mercado_externo_ventas_3"])
      ).toFixed(2);
      this.total_porcentaje = (
        Number(form["mercado_local_porcentaje"]) +
        Number(form["mercado_externo_porcentaje"])
      ).toFixed(2);

      this.total_ventas_promedio = (
        Number(form["mercado_local_ventas_promedio"]) +
        Number(form["mercado_externo_ventas_promedio"])
      ).toFixed(2);
      this.total_ventas_contado_porcentaje = (
        Number(form["mercado_local_ventas_contado_porcentaje"]) +
        Number(form["mercado_externo_ventas_contado_porcentaje"])
      ).toFixed(2);
      this.total_ventas_credito_porcentaje = (
        Number(form["mercado_local_ventas_credito_porcentaje"]) +
        Number(form["mercado_externo_ventas_credito_porcentaje"])
      ).toFixed(2);

      this.total_facturas_porcentaje = (
        Number(form["mercado_local_facturas_porcentaje"]) +
        Number(form["mercado_externo_facturas_porcentaje"])
      ).toFixed(2);
      this.total_facturas_plazo_promedio =
        Number(form["mercado_local_facturas_plazo_promedio"]) +
        Number(form["mercado_externo_facturas_plazo_promedio"]);
      this.total_letras_porcentaje = (
        Number(form["mercado_local_letras_porcentaje"]) +
        Number(form["mercado_externo_letras_porcentaje"])
      ).toFixed(2);
      this.total_letras_plazo_promedio =
        Number(form["mercado_local_letras_plazo_promedio"]) +
        Number(form["mercado_externo_letras_plazo_promedio"]);
    });
  }

  obtenerIngresos() {
    this.clientesService
      .obtenerIngresos(this.clienteId)
      .then((ingresos: any) => {
        let values = ingresos;

        let mes_anio =
          String(ingresos?.dia_mes_3) + "-" + String(ingresos?.anio_3);

        delete values["anio_3"];
        delete values["dia_mes_3"];
        delete values["cliente"];
        delete values["cliente_nombre"];

        values["mes_anio"] = mes_anio;

        for (const property in values) {
          this.myForm.controls[property].setValue(values[property]);
        }

        this.exist = true;
      })
      .catch((error) => console.log(error));
  }

  onSubmit() {
    let formValues = this.myForm.value;

    let mes_anio = formValues["mes_anio"];

    delete formValues["mes_anio"];

    let data = formValues;

    data["cliente"] = this.clienteId;
    data["dia_mes_3"] = mes_anio.slice(0, 2);
    data["anio_3"] = mes_anio.slice(2, 6);

    this.clientesService
      .guardarIngresos(data, this.clienteId, this.exist)
      .then(() => {
        this.exist = true;
      });
  }
}
