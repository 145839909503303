import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.css']
})
export class ConfirmModalComponent implements OnInit {
  faTimes=faTimes;
  @Input() title = '';
  @Input() message = '';
  @Input() messageHTML : boolean = false;

  @Input() titleRightBottom = 'Cancelar';
  @Input() showBottomLeft = true;
  @Input() showBottomRight = true;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

}
