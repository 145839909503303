import { Component, OnInit, ViewChild } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { FactoringDatatableComponent } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
	selector: 'app-solicitar-opinion',
	templateUrl: './solicitar-opinion.component.html',
	styleUrls: ['./solicitar-opinion.component.css']
})
export class SolicitarOpinionComponent implements OnInit {
	@ViewChild('solicitarOpinionModal', { static: false }) public solicitarOpinionModal;
	// @ViewChild('datatable', { static: false }) public datatable;
	faTimes = faTimes;
	// @ViewChild(FactoringDatatableComponent) datatable: FactoringDatatableComponent;

    public columnDefs  : any[];
    public data        : any[];
    public checkchecked: any = {};
    public idOp : any;


	constructor(
        public factoring     : FactoringService,
		public modalService : NgbModal) { }
	
	ngOnInit(): void {
	}
	
	initVariables(){
		this.columnDefs = [
			{
				headerName: 'N°', 
				pipe      : 'indexcol',
				sortable  : true
			},  
			{
				headerName       : 'Nombre', 
				field            : 'nombre' ,
				sortable         : true,
                class: 'text-center'
			},
			{
				headerName       : 'Perfil', 
				field            : 'rol_descripcion' ,
				sortable         : true,
                class: 'text-center'
			},            
			{
				headerName: 'Sugerencia', 
				field     : 'sugerencia' ,
				sortable  : true,
				classes   : 'text-center',
				pipe      : 'checkbox-element-disable',
			},									                                                                    
		];  
	}

	initModal(idProceso){
		this.idOp = idProceso
		this.initVariables()
		this.factoring.listadoUsuariosSugerencias(idProceso)
		.then((response : any)=>{
			this.data = response.results
			console.log(this.data)
			return this.modalService.open(this.solicitarOpinionModal, {
				size: 'md'
			}).result
		}).then((result)=>{
			console.log(result)
		}, (reason)=>{
			console.log(reason)
		})
	}

	checkboxChange(event){
		// console.log(event)
		this.factoring.crearSolicitudsugerencia(this.idOp, event.row.id)
		.then((res)=>{
			this.checkchecked = event.checkEvent
		})
	}
	
}
	