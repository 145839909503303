import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { FactoringService } from 'app/core/services/factoring/factoring.service';

@Component({
  selector: 'app-feriados',
  templateUrl: './feriados.component.html',
  styleUrls: ['./feriados.component.css']
})
export class FeriadosComponent implements OnInit {

  public anio = new Date().getFullYear()
  public anios = Array.from(Array(5), (_, i) => this.anio + 2 - i)
  public meses = []
  public feriados: any = []

  constructor(
    private factoringService: FactoringService,
    private spinner: NgxSpinnerService,
    private localeService: BsLocaleService
  ) { }

  ngOnInit(): void {
    this.localeService.use('es')
    this.onChangeAnio()
  }

  onChangeAnio() {
    this.feriados = []
    this.reconfigureMeses()
    this.loadFeriados()
  }

  onChangeFeriado({ value, saved = false, deleted = false }) {
    const indice = this.feriados.findIndex(feriado => feriado.id == value.id)
    if (saved) {
      if (indice >= 0) {
        this.feriados[indice] = value
      } else {
        this.feriados.push(value)
      }
    } else if (deleted && indice >= 0) {
      this.feriados.splice(indice, 1)
    }
    this.reconfigureMeses()
  }

  private loadFeriados() {
    const fechaIni = new Date(this.anio, 0, 1).toISOString().substring(0, 10)
    const fechaFin = new Date(this.anio + 1, 0, 0).toISOString().substring(0, 10)

    this.feriados = []
    this.spinner.show()
    return this.factoringService.getFeriados({ 'fecha__gte': fechaIni, 'fecha__lte': fechaFin, 'page_size': 100 }).subscribe(
      (data: any) => this.feriados = data.results,
      res => console.log(res)
    ).add(() => {
      this.reconfigureMeses()
      this.spinner.hide()
    })
  }

  private reconfigureMeses() {
    this.meses = Array.from(Array(12), (_, i) => (i + 1)).map(i => ({ mes: i, feriados: [] }))
    this.meses = this.meses.map(mes => ({
      ...mes, feriados: this.getFeriadosMes(mes.mes)
    }))
  }

  private getFeriadosMes(mes) {
    const periodo = `${this.anio}-${mes.toString().padStart(2, '0')}`
    return this.feriados.filter(
      feriado => feriado.fecha.substring(0, 7) == periodo
    )
  }

}
