import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ClientesService } from "app/core/services/clientes/clientes.service";

@Component({
  selector: "app-add-financial-relationship",
  templateUrl: "./add-financial-relationship.component.html",
  styleUrls: ["./add-financial-relationship.component.css"],
})
export class AddFinancialRelationshipComponent implements OnInit {
  public myForm: FormGroup;
  public relation: any;
  public monedas: any[];
  public entidadesFinancieras: any[];
  faTimes=faTimes;
  @Input() clienteId = 0;
  @Input() relationId = 0;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild: FormBuilder,
    public activeModal: NgbActiveModal,
    private clientesService: ClientesService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    if (this.relationId) {
      this.obtenerRelacionFinanciera();
    }

    let entidadesFinancieras = this.clientesService.obtenerEntidadesFinancieras();
    let monedas = this.clientesService.obtenerListaMonedas();

    Promise.all([entidadesFinancieras, monedas]).then((res) => {
      this.entidadesFinancieras = res[0]["results"];
      this.monedas = res[1]["results"];
    });
  }

  obtenerRelacionFinanciera() {
    this.clientesService
      .obtenerRelacionFinanciera(this.relationId)
      .then((res) => {
        this.relation = res;
        this.setForm(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      entidad_financiera: [null, [Validators.required]],
      sucursal: [null, []],
      numero_cuenta: [null, [Validators.required]],
      moneda: [null, [Validators.required]],
      deuda_vigente: [null, []],
      sectorista: [null, []],
      telefono: [null, []],
    });
  }

  get f() {
    return this.myForm.controls;
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = this.myForm.value;
      data["cliente"] = this.clienteId;

      this.clientesService
        .guardarRelacionFinanciera(this.myForm.value, this.relationId)
        .then((res) => {
          this.successEmit.emit();
          this.activeModal.close(false);
        })
        .catch((error) => {
          this.activeModal.close(false);
        });
    }
  }

  compararTipos(tipo1: any, tipo2: any) {
    return tipo1 && tipo2 ? tipo1.id === tipo2 : tipo1 === tipo2;
  }
}
