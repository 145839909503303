import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';

@Component({
  selector: 'app-manage-tipos-firmas-contrato-modal',
  templateUrl: './manage-tipos-firmas-contrato-modal.component.html',
  styleUrls: ['./manage-tipos-firmas-contrato-modal.component.css']
})
export class ManageTiposFirmasContratoModalComponent implements OnInit {
  faTimes = faTimes;
  myForm: FormGroup;
  @Input() tiposFirmantesId = 0;
  tiposFirmantes: any;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild          : FormBuilder,
    public activeModal        : NgbActiveModal,
    private configurationService	: ConfigurationService,
  ) { 
    this.initForm();
  }

  ngOnInit(): void {
    if (this.tiposFirmantesId) {
      this.obtenerTipoFirma();
    }
  }

  initForm() {
    this.myForm = this.formbuild.group({
      descripcion: [null, [Validators.required]],
      monto_legal: [null, [Validators.required]],
      monto_natural: [null, [Validators.required]],
    });
  }
  
  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  obtenerTipoFirma() {
    this.configurationService.obtenerTipoFirma(this.tiposFirmantesId)
    .then(res => {
      this.tiposFirmantes = res;
      this.setForm(res);
    })
    .catch(error => {
      console.error(error);
    });
  }

  onSubmit() {
    if(this.myForm.valid) {
      const data = this.myForm.value;

      this.configurationService.guardarTipoFirma(this.myForm.value, this.tiposFirmantesId)
      .then(res => {
        this.successEmit.emit();
        this.activeModal.close(true);
      }).
      catch(error => {
        this.activeModal.close(false);
      });
    }
  }

}
