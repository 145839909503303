import { Component, OnInit, Renderer2, ViewChild, ElementRef, Inject  } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsuarioModel } from '../../usuarios.model';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css']
})
export class ForgetPasswordComponent implements OnInit {

  @ViewChild('imgBackgroud', {static: true}) imgBackgroud : ElementRef | undefined;

  correo: UsuarioModel;
  faUser = faUser;
  focus;

  resetPassword = this.formbuild.group({
    email: [ null,[ Validators.required,Validators.pattern(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/), Validators.minLength(5)] ]
  })

  /*=============================================
	Controlador personalizado
	=============================================*/
  get email(){return this.resetPassword.controls.email}

  constructor(
    private formbuild: FormBuilder,
    private render2: Renderer2,
    private element  : ElementRef,
    private auth: AuthService,
    @Inject(DOCUMENT) document: any
  ) { }


  ngOnInit(): void {

    this.checkFullPageBackgroundImage();

  }

  checkFullPageBackgroundImage(){

    const img = this.imgBackgroud.nativeElement.getAttribute( 'data-image' );
    const div = this.render2.createElement('div');
    
    this.render2.appendChild(this.imgBackgroud.nativeElement, div);
    this.render2.addClass( div, 'full-page-background' );
    this.render2.setStyle( div, 'background-image', `url( '${img}')` );

  }

  submitResetPassword(){

    //Envio del Email
    if( this.resetPassword.valid ){

      const data = {

        email: this.resetPassword.controls.email.value,
        url_login: `${document.location?.origin}/#/login`

      }

      this.auth.getitbackPassword( data )
        .then( (resp)=>{
          this.auth.spinnerOff( 'Email enviado con exito', true )

        }).catch( err=> console.log)

    }
    
  }
 
}
