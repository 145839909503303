import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx'

import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { concat } from 'rxjs';
import { toArray } from 'rxjs/operators';

@Component({
  selector: 'app-asignacion-cartera',
  templateUrl: './asignacion-cartera.component.html',
  styleUrls: ['./asignacion-cartera.component.css']
})
export class AsignacionCarteraComponent implements OnInit {

  exportando = false
  tblRows: any[] = []
  tblHeaders: columnHeader[]
  tblTotalRows: number
  tblPages: number[]
  tblActualPage: number = 1
  tblPerPage: number = 10
  tblFiltros = {}
  tblAcciones = []

  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private clientesService	: ClientesService,
  ) { }

  ngOnInit(): void {
    this.initTablaClientes()
  }

  loadDataTablaClientes({ page = this.tblActualPage, per_page = this.tblPerPage, filtros = this.tblFiltros }) {
    this.spinner.show()
    Object.keys(filtros).filter(field => field.includes('fecha')).forEach(field => filtros[field] = filtros[field].split(' ')[0])
    this.tblFiltros = filtros
    this.clientesService.getClientesAsignacionCartera(Object.assign({ page, page_size: per_page }, filtros)).subscribe(
      (data: any) => {
        this.tblRows = data.results
        this.tblTotalRows = data.count
        this.tblPages = Array.from(Array(data.num_pages).keys())
        this.tblPerPage = data.per_page
        this.tblActualPage = data.page_number
      },
      (res: any) => console.log(res)
    ).add(() => this.spinner.hide())
  }

  async onSelectFileImportar(ev) {
    if (ev.target.files.length > 0) {
      this.spinner.show()
      const file = ev.target.files[0]
      ev.target.value = null
      try {
        let res = await this.importar(file)
        this.spinner.hide()
        if (res) this.loadDataTablaClientes({})
      } catch (ex) {
        console.log(ex)
        this.spinner.hide()
      }
    }
  }

  async importar(file) {
    const buffer = await file.arrayBuffer()
    const wb = XLSX.read(buffer, { type: 'buffer', cellDates: true })
    const ws = wb.Sheets[wb.SheetNames[0]]
    const dataXls: any = XLSX.utils.sheet_to_json(ws)

    let emails = new Set(dataXls.map(e => e.email).filter(e => e))
    let oficiales = {}
    let data = []

    if (emails.size > 0) {
      const resOficiales: any = await this.clientesService.getResponsables({ 'email__in': [...emails], 'page_size': 9999 }).toPromise()
      resOficiales.results.forEach(e => oficiales[e.email] = e)
    }

    for (let row of dataXls) {
      let oficial = oficiales[row.email]
      if (!row.email) {
        oficial = null
      } else {
        if (!oficial) {
          this.clientesService.toast.warning(`El ejecutivo "${row.email}" no se encontró`)
          return
        }
        oficial = oficial.id
      }
      data.push({ 'ruc': row.ruc, 'oficial_negocio': oficial})
    }

    try {
      await this.clientesService.importarAsignacionCartera(data).toPromise()
      return true
    } catch (ex) {
      console.log(ex)
      return false
    }
  }

  async exportarDataTablaClientes() {
    const lote = 200

    this.exportando = true
    this.clientesService.getClientesAsignacionCartera(Object.assign({ 'page_size': 1 }, this.tblFiltros)).subscribe(
      data => {
        if (data['count'] > 1000) {
          this.clientesService.toast.warning('No se puede exportar más de 1000 registros. Use los filtros para reducir registros')
          this.exportando = false
          return
        }
        let pages = Math.ceil(data['count'] / lote)
        let pages$ = Array.from({ length: pages }).map((_, i) => i + 1).map(
          page => this.clientesService.getClientesAsignacionCartera(Object.assign({ page, 'page_size': lote }, this.tblFiltros))
        )
        concat(...pages$).pipe(toArray()).subscribe((data: any) => {
          data = data.map((x: any) => x.results).flat(1)
          data = data.map(x => {
            let row = {
              'ruc': x['ruc'],
              'nombre': x['nombre'],
              'email': x['oficial_negocio_email'],
            }
            return row
          })
          if (data.length == 0) {
            data.push({'ruc': null, 'nombre': null, 'email': null})
          }
          let ws = XLSX.utils.json_to_sheet(data)
          let wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, 'Cartera')
          XLSX.writeFile(wb, 'Asignacion cartera.xlsx')
        }).add(() => this.exportando = false)
      },
      res => {
        console.log(res)
        this.exportando = false
      }
    )
  }

  private initTablaClientes() {
    this.tblHeaders = [
      {
        headerName: 'ID',
        field: 'id',
        class: 'text-center',
      },
      {
        headerName: 'RUC',
        field: 'ruc',
        filterable: true,
        filterProp: 'ruc__icontains',
        filterInput: true,
      },
      {
        headerName: 'Nombre',
        field: 'nombre',
        filterable: true,
        filterProp: 'nombre__icontains',
        filterInput: true,
      },
      {
        headerName: 'Oficial de negocio',
        field: 'oficial_negocio_nombre',
        filterable: true,
        filterProp: 'oficial_negocio__nombre__icontains',
        filterInput: true,
      },
      {
        headerName: 'Oficial de negocio (Correo)',
        field: 'oficial_negocio_email',
        filterable: true,
        filterProp: 'oficial_negocio__email__icontains',
        filterInput: true,
      },
    ]
    this.loadDataTablaClientes({})
  }

}
