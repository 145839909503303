import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
  selector: 'app-tasas-historicas',
  templateUrl: './tasas-historicas.component.html',
  styleUrls: ['./tasas-historicas.component.css']
})
export class TasasHistoricasComponent implements OnInit {

  @Input() tipoLinea;
  @Input() clienteID;
  linea: any;
  myForm: FormGroup;
  monedas: any[] = [];

  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
  public searchstring     : string = '';

  constructor(
    public formbuild          : FormBuilder,
    private clientesService	  : ClientesService,
    private factoringService  : FactoringService
  ) { }

  ngOnInit() {
    this.initVars();
    this.initForm();

	  let monedas = this.factoringService.obtenerMonedas();

    Promise.all([monedas]).then(res => {
      this.monedas = res[0]['results'];
    }).catch(error => {
      console.error(error);
      
    })

    this.obtenerLineaCredito();
    this.obtenerHistorico();

    this.myForm.disable();
	
  }

  initVars() {
		let columns = [
			{
				headerName: 'N°.', 
				field     : 'id' ,
				sortable  : true,
				pipe      : 'indexcol'
			},
      {
				headerName: 'Fecha Alta', 
				field     : 'fecha_alta' ,
				sortable  : true,
        pipe      : 'date',
        class     : 'text-center',
			},
			{
				headerName: 'Tipo', 
				field     : 'tipo_historico_descripcion',
				sortable  : true,
        class     : 'text-center',
			},
			{
				headerName: 'Moneda', 
				field     : 'moneda_descripcion' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Monto', 
				field     : 'linea_actual' ,
				sortable  : true,
				class     : 'text-center',
				pipe      : "currency",
				moneda    : "moneda",
			},
			{
				headerName: 'TEM Soles', 
				field     : 'tem_soles' ,
				sortable  : true,
        pipe      : 'percent-icon',
        class     : 'text-center',
			},
      {
				headerName: 'TEM Dólares', 
				field     : 'tem_dolares' ,
				sortable  : true,
        pipe      : 'percent-icon',
        class     : 'text-center',
			},
      {
				headerName: 'Anticipo', 
				field     : 'anticipo' ,
				sortable  : true,
        pipe      : 'percent-icon',
        class     : 'text-center',
			},
      {
				headerName: 'Tasa Mora', 
				field     : 'mora' ,
				sortable  : true,
        pipe      : 'percent-icon',
        class     : 'text-center',
			},
      {
				headerName: 'Observaciones', 
				field     : 'observaciones_contrato',
				sortable  : true,
			},
		];

		this.columnDefs = columns;
	}

  loadTable(data) {

		this.rowData           = data.results.filter(item => item.tipo == this.tipoLinea);
		this.pageTableActual   = data.page_number;
		this.pageTableTotal    = data.count;
		this.pageTablePerPages = data.per_page;
		this.pageTablePages = [];
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index);
		}

	}

  initForm() {
    this.myForm = this.formbuild.group({
      linea_actual      : [null, []],
      linea_consumida   : [null, []],
      linea_disponible  : [null, []],
      moneda            : [null, []],

      fecha_solicitud   : [null, []],
      fecha_resolucion  : [null, []],
      fecha_contrato    : [null, []],
      fecha_vencimiento_contrato : [null, []],

      tem_soles         : [null, []],
      tem_dolares       : [null, []],
      anticipo          : [null, []],

      tea_soles         : [null, []],
      tea_dolares       : [null, []],
      mora              : [null, []],

      observaciones     : [null, []],
    })
  }

  setForm(values) {
    for (const key in values) {
      if (this.myForm.controls[key]) {

        if (key == 'tem_soles') {
          this.myForm.controls[key].setValue(values[key]);
          this.myForm.controls['tea_soles'].setValue(this.teas(values[key]));
          continue;
        }

        if (key == 'tem_dolares') {
          this.myForm.controls[key].setValue(values[key]);
          this.myForm.controls['tea_dolares'].setValue(this.teas(values[key]));
          continue;
        }

        if (key ==  'fecha_alta') {
          this.myForm.controls[key].setValue(this.formatDate(values[key]));
          continue;
        }

        if (key ==  'fecha_vencimiento_contrato') {
          this.myForm.controls[key].setValue(this.formatDate(values[key]));
          continue;
        }

        if (key ==  'fecha_contrato') {
          this.myForm.controls[key].setValue(this.formatDate(values[key]));
          continue;
        }

        if (key ==  'fecha_solicitud') {
          this.myForm.controls[key].setValue(this.formatDate(values[key]));
          continue;
        }

        if (key ==  'fecha_resolucion') {
          this.myForm.controls[key].setValue(this.formatDate(values[key]));
          continue;
        }

        this.myForm.controls[key].setValue(values[key]);
      }
      
    }
  }

  formatDate(date) {
    if (!date) return ''
    const arrayDate = date.split('-');

    return `${arrayDate[2].substring(0,2)}/${arrayDate[1]}/${arrayDate[0]}`;
  }

  teas(value) {
		console.log(value);
		let amount = 0;
		let val = 1 + (value / 100);
		amount = Math.pow(val, 12) - 1;
		amount *= 100;
		console.log(amount);
		return amount.toFixed(2)
  }

  obtenerLineaCredito() {
    this.factoringService.obtenerLineas(this.clienteID, this.tipoLinea)
    .then(res => {
      this.linea = res['results'][0];
      this.setForm(this.linea);
    }).catch(error => {
      console.error(error);
      
    });
  }

  obtenerHistorico() {
		this.clientesService.obtenerLineasHstorico(this.clienteID)
		.then(res => {
      console.log(res);
      this.loadTable(res);
		}).catch(error => {
		  console.error(error);
		
		});
  }

}