import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';

@Component({
  selector: 'app-bloquear-modal',
  templateUrl: './bloquear-modal.component.html',
  styleUrls: ['./bloquear-modal.component.css']
})
export class BloquearModalComponent implements OnInit {
  dataForm: FormGroup;
  faTimes = faTimes;
  @Input() title = '';
  @Input() entidad : any;
  @Input() entidadData : any;
  user: any;

  constructor(
    public formbuild    : FormBuilder,
    private modalService: NgbModal,
    public activeModal  : NgbActiveModal,
		public authService    : AuthService,

  ) {
    this.user = this.authService.user
   }

  ngOnInit(): void {
    this.initForm()
  }

  initForm(){
    this.dataForm = this.formbuild.group({

      motivo_bloqueo : [
        { 
          value   : this.entidadData.motivo_bloqueo, 
          disabled: false
        }, [ Validators.required ]
      ],

      fecha_bloqueo  : [
        {
          value   : this.entidadData.fecha_bloqueo, 
          disabled: true
        }, [ Validators.required ]
      ],

      usuario_bloqueo: [
        {
          value   : null, 
          disabled: true
        }, []
      ],

    })    
  }

  get username(){
    if(this.user){
      return `${this.user.nombre} ${this.user.apellido}`
    }
    return ''
  }

  get fechaBloqueo(){
    let fecha = new Date()
    return `${fecha.getDate()}/${fecha.getMonth()+1}/${fecha.getFullYear()}`
  }

  get bloqueoData(){

    if(this.dataForm.valid){
      let fecha = new Date()
      return {
        motivo_bloqueo : this.dataForm.get('motivo_bloqueo').value,
        fecha_bloqueo  : `${fecha.getFullYear()}-${fecha.getMonth()+1}-${fecha.getDate()}`,
        usuario_bloqueo: `${this.authService.user.nombre} ${this.authService.user.apellido}`
      }
    }

    return
  }

}
