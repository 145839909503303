import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { LegalService } from 'app/core/services/legal.service';
import { ToastService } from 'app/core/services/toast/toast.service';

@Component({
  selector: 'app-formato-declaracion-jurada-pep',
  templateUrl: './formato-declaracion-jurada-pep.component.html',
  styleUrls: ['./formato-declaracion-jurada-pep.component.css']
})
export class FormatoDJPEPComponent implements OnInit {
  
  myForm: FormGroup;
  estaciones: any[];
  public firmantes: any[] = [];
  firmanteValue;

  @Input() beneficiario: number;
  @Input() operacion;
  @Input() beneficiario_tipo;
  @Input() formato_plantilla_url;
  @Input() contrato;


  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private formatoService: FormatosService,
    private clientesService : ClientesService,
    private legalService	: LegalService,
    public toast: ToastService,
  ) { }

  async ngOnInit() {
    const clienteId = (this.operacion.operacion.tipo_linea_solicitada == 1) ? this.operacion.beneficiario : this.operacion.operacion.beneficiario_factoring;
    if (!this.contrato) {
      return this.toast.warning("La operación no posee contrato")
    }
    this.createForm();
    const firmantes = await this.legalService.obtenerFirmantes(this.contrato.id)
    this.firmantes = firmantes['results']
  }

  async createForm() {
    const clienteId = (this.operacion.operacion.tipo_linea_solicitada == 1) ? this.operacion.beneficiario : this.operacion.operacion.beneficiario_factoring;

    this.myForm = this.formBuilder.group({
      firmante: [null, []],
      emision: [new Date(), Validators.required],
      nombre: [null, Validators.required],
      dni: [null, Validators.required],
      domicilio: [null, Validators.required],
      telefono: [null, Validators.required],
      email: [null, Validators.required],
      cargo: [null, Validators.required],
      tipo_persona: [this.beneficiario_tipo == '' ? 'J' : this.beneficiario_tipo, Validators.required]
    });

    const cliente = await this.clientesService.obtenerCliente(clienteId)

    if (this.operacion.operacion.tipo_linea_solicitada == 2) {
      this.beneficiario_tipo = cliente['tipo_persona'] == 1 ? 'N' : 'J'
    }
    this.myForm.controls.tipo_persona.setValue(this.beneficiario_tipo)


    this.myForm.get("firmante").valueChanges.subscribe(value => {
      this.clientesService.obtenerContacto(value.firmante).then((contacto: any)=>{
        this.myForm.controls.nombre.setValue(contacto['nombre'])
        this.myForm.controls.dni.setValue(value['nro_documento'])
        this.myForm.controls.domicilio.setValue(contacto['direccion'])
        this.myForm.controls.telefono.setValue(value['telefono'])
        this.myForm.controls.email.setValue(value['email'])
        this.myForm.controls.cargo.setValue(contacto['cargo'])
      })
    });

  }

  onSubmit() {

    if (this.myForm.valid) {
      var model = {
        "archivo_plantilla": this.formato_plantilla_url,
        "fecha_actual": this.myForm.get("emision").value,
        "nombre": this.myForm.get("nombre").value,
        "dni": this.myForm.get("dni").value,
        "domicilio": this.myForm.get("domicilio").value,
        "telefono": this.myForm.get("telefono").value,
        "email": this.myForm.get("email").value,
        "cargo": this.myForm.get("cargo").value,
        "tipo_persona": this.myForm.get("tipo_persona").value, // 'N' o 'J' }
      }

      this.formatoService.generarFormatoDJPEP(model).then(
        (x: any) => {
          var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "DJPEP.docx";
          link.click();
          this.activeModal.close(model);
        }, (reason) => {
          this.activeModal.close(model);
        });
    }
    else {
      this.toast.warning("El formulario no está completo.");
    }
  }

}
