import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactoPoderesService } from 'app/core/services/poderes/contacto-poderes.service';
import { PoderesService } from 'app/core/services/poderes/poderes.service';

export interface act {
  id: number;
  name: string;
}

@Component({
  selector: 'app-poderes',
  templateUrl: './poderes.component.html',
  styleUrls: ['./poderes.component.css']
})
export class PoderesComponent implements OnInit {

  @Output() poderesEmit: EventEmitter<any> = new EventEmitter<any>();
  // Indicar que se deben crear los poderes cuando el usuario a editar no los tenía antes.
  @Output() newPoderesEmit: EventEmitter<any> = new EventEmitter<any>();
  @Input() contactId = 0;
  @Input() readonly = false;

  myForm: FormGroup;
  resultados: any[] = [];

  constructor(private poderesService: PoderesService,
    private contactosPoderesService: ContactoPoderesService,
    public formbuild: FormBuilder,) { }

  typeLoadName: string;
  lFormGroup: FormGroup;

  stateControl: AbstractControl;
  stateFormControls: FormGroup;

  get lFormArray(): FormArray {
    if (this.lFormGroup) {
      return this.lFormGroup.get("lFormArray") as FormArray;
    }
  }

  ngOnInit(): void {

    if (this.contactId != 0) {

      let poderes = this.contactosPoderesService.obtenerPoderesContactos(this.contactId);
      let resultados = this.poderesService.obtenerResultados();

      Promise.all([poderes, resultados]).then(
        (res: any[]) => {

          let poderesResults = res[0];
          let resultadosResults = res[1].results;

          if (poderesResults.length == 0) {
            this.newPoderesEmit.emit(1);
            this.poderesService.obtenerPoderes().then((x: any) => {
              this.initControls(x.results, resultadosResults);
            });

          } else {
            this.initControlsUpdate(poderesResults, resultadosResults);
          }
        }
      );
    }
    else {

      this.newPoderesEmit.emit(1);
      let poderes = this.poderesService.obtenerPoderes();
      let resultados = this.poderesService.obtenerResultados();

      Promise.all([poderes, resultados]).then(
        (res: any[]) => {

          let poderesResults = res[0].results;
          let resultadosResults = res[1].results;

          this.initControls(poderesResults, resultadosResults);
        }
      );
    }

  }

  initControls(poderesData, resultadosData) {

    this.lFormGroup = this.formbuild.group({
      lFormArray: this.formbuild.array([])
    });

    var resultados = [];
    var poderes = [];

    poderes = poderesData.sort(function (a, b) { return a.id - b.id });

    resultados = resultadosData.sort(function (a, b) { return a.id - b.id });

    poderes.forEach((poder, index) => {
      var poderLista = { 'poderId': 0, 'poderDescripcion': '', 'resultados': [] };
      poderLista.poderId = poder.id;
      poderLista.poderDescripcion = poder.descripcion;
      poderLista.resultados = resultados;

      this.stateFormControls = this.createControls();
      const myFormArray = <FormArray>this.lFormGroup.get("lFormArray");
      this.stateFormControls.get("groupIndex").patchValue(myFormArray.length);
      this.lFormArray.push(this.stateFormControls);

      // this.stateFormControls.get("resultado").patchValue(resultados[0].id);
      this.lFormArray.get([index + '', 'resultados']).patchValue(resultados);
      this.lFormArray.get([index + '', 'poder']).patchValue(poder.id);
      this.lFormArray.get([index + '', 'poderSeleccionado']).patchValue({ id: poder.id, descripcion: poder.descripcion });
      this.lFormArray.get([index + '', 'firmante_conjunto']).patchValue(poder.firmante_conjunto);
      this.poderesEmit.emit(this.lFormArray.value);
    });

    this.poderesEmit.emit(this.lFormArray.value);
  }

  initControlsUpdate(poderesData, resultadosData) {

    this.lFormGroup = this.formbuild.group({
      lFormArray: this.formbuild.array([])
    });

    var resultados = [];
    var poderes = [];

    poderes = poderesData.sort(function (a, b) { return a.id - b.id });

    resultados = resultadosData.sort(function (a, b) { return a.id - b.id });

    poderes.forEach((poder, index) => {
      var poderLista = { 'poderId': 0, 'poderDescripcion': '', 'resultados': [] };
      poderLista.poderId = poder.poder;
      poderLista.poderDescripcion = poder.poder_descripcion;
      poderLista.resultados = resultados;

      this.stateFormControls = this.createControls();
      const myFormArray = <FormArray>this.lFormGroup.get("lFormArray");
      this.stateFormControls.get("groupIndex").patchValue(myFormArray.length);
      this.stateFormControls.get("id").patchValue(poder.id);
      this.lFormArray.push(this.stateFormControls);

      this.stateFormControls.get("resultado").patchValue(poder.resultado);
      this.lFormArray.get([index + '', 'resultados']).patchValue(resultados);
      this.lFormArray.get([index + '', 'poder']).patchValue(poder.poder);
      this.lFormArray.get([index + '', 'poderSeleccionado']).patchValue({ id: poder.poder, descripcion: poder.poder_descripcion });
      this.lFormArray.get([index + '', 'firmante_conjunto']).patchValue(poder.firmante_conjunto);
      this.poderesEmit.emit(this.lFormArray.value);
    });

    this.poderesEmit.emit(this.lFormArray.value);

  }

  onChangeState(event, index) {
    console.log(this.lFormArray.value);
    this.poderesEmit.emit(this.lFormArray.value);
  }

  createControls() {
    return this.formbuild.group({
      id: 0,
      groupIndex: "",
      contacto: 0,
      resultado: "",
      poder: "",
      poderSeleccionado: "",
      resultados: [],
      firmante_conjunto: "",
    });
  }
}

