import { Component, OnInit } from '@angular/core';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { ManageCondicionesModalComponent } from '../modals/manage-condiciones-modal/manage-condiciones-modal.component';
import { functions } from '../../../core/helpers/functions';

@Component({
  selector: 'app-condiciones',
  templateUrl: './condiciones.component.html',
  styleUrls: ['./condiciones.component.css']
})
export class CondicionesComponent implements OnInit {
  faChartPie = faChartPie;
  faSyncAlt  = faSyncAlt;
  faPlus     = faPlus;
  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public user             : any;
	public identidad        : any     = {};
	public searchstring     : string = '';
	public canCreate: boolean = false;

  constructor(
    private configurationService  : ConfigurationService,
    public modalService           : NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerCondiciones();
  }

  obtenerCondiciones(page = 1, page_size = 10) {
    let condiciones = this.configurationService.obtenerCondiciones(page, page_size);

    Promise.all([condiciones]).then((res :any[])=>{
      let condiciones  = res[0];
      this.initVars();
      this.loadTable(condiciones);
    });
  }

  loadTable(data) {
    this.rowData = functions.indextable( data )
    // this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }
    
  initVars() {
    let columns = [
      {
        headerName: 'N°', 
        field     : 'index' ,
        sortable  : true,
      },
      {
        headerName: 'Descripción', 
        field     : 'descripcion',
        class     : 'text-center',
        sortable  : true,
        // filterable: true,
        // filterProp: 'descripcion',
        // filterInput: true,
      },
    ];
  
    this.columnDefs = columns;
  }

  crear() {
    const modalRef = this.modalService.open(ManageCondicionesModalComponent, {
    });

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerCondiciones();
		},
		error => {

		});
  }

  tableEditEvent(row) {
    const modalRef = this.modalService.open(ManageCondicionesModalComponent, {
    });

    modalRef.componentInstance.condicionId = row.id;

    modalRef.componentInstance

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			this.obtenerCondiciones();
		},
		error => {

		});
  }

  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Condición';
		modalRef.componentInstance.message = '¿Seguro que desea remover la Condición?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.configurationService.elimiarCondicion(row.id)
			.then(result => {
			this.obtenerCondiciones();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  goToPage({page,per_page,filtros}) {
    this.obtenerCondiciones(page, per_page);
  }

}
