import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { GrupoEconomicoService } from 'app/core/services/clientes/grupo-economico.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
  selector: 'app-add-grupo-economico-modal',
  templateUrl: './add-grupo-economico-modal.component.html',
  styleUrls: ['./add-grupo-economico-modal.component.css']
})
export class AddGrupoEconomicoModalComponent implements OnInit {
  faTimes=faTimes;
  myForm: FormGroup;
  @Input() grupoId = 0;
  grupo: any;
  monedas: any[] = [];

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  public columnDefs: columnHeader[];
  public loadingTable: boolean = false;
  public clientes: any[] = [];

  constructor(
    public formbuild: FormBuilder,
    public activeModal: NgbActiveModal,
    private grupoService: GrupoEconomicoService,
    private clienteService: ClientesService
  ) { }

  ngOnInit() {

    this.initForm();
    this.obtenerData();
    this.initVars();

    if (this.grupoId) {
      this.obtenerGrupo();
      this.obtenerClientes();
    }

  }

  obtenerData() {

    let monedas = this.clienteService.obtenerListaMonedas();

    Promise.all([monedas])
      .then(res => {
        this.monedas = res[0]['results'];
      }).catch(error => {
        console.error(error);
      });

  }

  obtenerGrupo() {
    let contactos = this.grupoService.obtenerGrupoEconomico(this.grupoId);
    contactos.then(res => {

      this.grupo = res;
      this.setForm(this.grupo);

    }).catch(error => {

    });
  }

  obtenerClientes() {
    let clientes = this.grupoService.grupoEcomonicoClientes(this.grupoId);
    clientes.then(res => {
      this.clientes = res['results'];
    }).catch(error => {

    });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre: [null, [Validators.required]],
      monto_linea: [null, [Validators.required]],
      moneda_linea: [null, [Validators.required]],
    });
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  initVars() {
    this.columnDefs = [
      {
        headerName: 'N°',
        pipe: 'indexcol',
        sortable: true
      },
      {
        headerName: 'Razón Social',
        field: 'nombre',
        sortable: true,
        class: 'text-center'
      },
      {
        headerName: 'RUC',
        field: 'ruc',
        sortable: true,
        defaultInfo: 'Aún no aprobado',
        class: 'text-center'
      },
      {
        headerName: 'Comportamiento',
        field: 'comportamiento_la_ft_descripcion',
        sortable: true,
        class: 'text-center'
      },
      {
        headerName: 'Exposición',
        field: 'exposicion',
        sortable: true,
        class: 'text-center',
        pipe: 'currency',
        moneda: 'moneda_exposicion'
      },
    ];
  }

  onSubmit() {

    if (this.myForm.valid) {
      const data = this.myForm.value;

      this.grupoService.guardarGrupoEconomico(this.myForm.value, this.grupoId)
        .then((res: any) => {

          this.successEmit.emit();
          this.activeModal.close(false);
        }).
        catch(error => {
          this.activeModal.close(false);
        });
    }

  }

}
