import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { AdminLayoutComponent } from 'app/layouts/admin/admin-layout.component';
import { ConfigTiposDocsComponent } from 'app/shared/config-tipos-docs/config-tipos-docs.component';
import { CodigoCIIUComponent } from './codigo-ciiu/codigo-ciiu.component';
import { CondicionesComponent } from './condiciones/condiciones.component';
import { ConfigComiteComponent } from './config-comite/config-comite.component';
import { FacturacionSeriesComponent } from './facturacion-series/facturacion-series.component';
import { FormulasComponent } from './formulas/formulas.component';
import { GestionBancosComponent } from './gestion-bancos/gestion-bancos.component';
import { LideresEquipoComponent } from './lideres-equipo/lideres-equipo.component';
import { ParametrosContrasenaComponent } from './parametros-contraseña/parametros-contraseña.component';
import { ParametrosComponent } from './parametros/parametros.component';
import { RecaudacionCuentasComponent } from './recaudacion-cuentas/recaudacion-cuentas.component';
import { TipoEvaluacionComponent } from './tipo-evaluacion/tipo-evaluacion.component';
import { TiposFirmaContratosComponent } from './tipos-firma-contratos/tipos-firma-contratos.component';
import { CierrePeriodoComponent } from '../facturacion/cierre-periodo/cierre-periodo.component';
import { TipoNcComponent } from './tipo-nc/tipo-nc.component';
import { AsignacionCursoNormalComponent } from './asignacion-curso-normal/asignacion-curso-normal.component';
import { FeriadosComponent } from './feriados/feriados.component';

const routes: Routes = [
  {
    path: 'configuracion',
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Evaluaciones'
    },
    children: [
      {
        path: 'tipo-evaluacion',
        canActivate: [AuthGuard],
        component: TipoEvaluacionComponent,
        data: {
          breadcrumb: 'Evaluaciones'
        }
      },
      {
        path: 'tipos-documentos',
        canActivate: [AuthGuard],
        component: ConfigTiposDocsComponent,
        data: {
          breadcrumb: 'Tipos de Documentos'
        }
      },
      {
        path: 'tipos-firmas',
        canActivate: [AuthGuard],
        component: TiposFirmaContratosComponent,
        data: {
          breadcrumb: 'Tipos de Firma contratos'
        }
      },
      {
        path: 'condiciones',
        canActivate: [AuthGuard],
        component: CondicionesComponent,
        data: {
          breadcrumb: 'Condiciones'
        }
      },
      {
        path: 'bancos',
        canActivate: [AuthGuard],
        component: GestionBancosComponent,
        data: {
          breadcrumb: 'Bancos'
        }
      },
      {
        path: 'cuentas',
        canActivate: [AuthGuard],
        component: RecaudacionCuentasComponent,
        data: {
          breadcrumb: 'Cuentas'
        }
      },
      {
        path: 'config-comite',
        canActivate: [AuthGuard],
        component: ConfigComiteComponent,
        data: {
          breadcrumb: 'Comités'
        }
      },
      {
        path: 'formulas',
        canActivate: [AuthGuard],
        component: FormulasComponent,
        data: {
          breadcrumb: 'Fórmulas'
        }
      },
      {
        path: 'ciiu',
        canActivate: [AuthGuard],
        component: CodigoCIIUComponent,
        data: {
          breadcrumb: 'Código CIIU'
        }
      },
      {
        path: 'parametros',
        canActivate: [AuthGuard],
        component: ParametrosComponent,
        data: {
          breadcrumb: 'Parámetros'
        }
      },
      // {
      //   path: 'parametros-contrasena',
      //   canActivate: [AuthGuard],
      //   component: ParametrosContrasenaComponent,
      //   data: {
      //     breadcrumb: 'Parámetros'
      //   }
      // },
      {
        path: 'facturacion-series',
        canActivate: [AuthGuard],
        component: FacturacionSeriesComponent,
        data: {
          breadcrumb: 'Facturación Series'
        }
      },
      {
        path: 'lideres',
        canActivate: [AuthGuard],
        component: LideresEquipoComponent,
        data: {
          breadcrumb: 'Líderes de Equipo'
        }
      },
      {
        path: 'cierre-periodo',
        canActivate: [AuthGuard],
        component: CierrePeriodoComponent,
        data: {
          breadcrumb: 'Cierre de Periodo'
        }
      },
      {
        path: 'tipos-nc',
        canActivate: [AuthGuard],
        component: TipoNcComponent,
        data: {
          breadcrumb: 'Tipos De NC'
        }
      },
      {
        path: 'asignacion_curso_normal',
        canActivate: [AuthGuard],
        component: AsignacionCursoNormalComponent,
        data: {
          breadcrumb: 'Asignacion Curso Normal'
        }
      },
      {
        path: 'feriados',
        canActivate: [AuthGuard],
        component: FeriadosComponent,
        data: {
          breadcrumb: 'Feriados'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ConfigurationRoutingModule { }
