export class Estacion {
    public estacionCliente: number = 1;
    public estacionOficialDeNegocio: number = 2;
    public estacionAnalisisRiesgo: number = 3;
    public estacionComiteCreditos: number = 4;
    public estacionOficialNegocioPostComite: number = 5;
    public estacionLegal: number = 6;
    public estacionAsesorLegal: number = 7;
    public estacionOficialNegocioLiquidacion: number = 8;
    public estacionJefeOperaciones: number = 9;
    public estacionTesoreria: number = 11;
    public estacionRecepcion: number = 12;
    public estacionAsistenteCobranzas: number = 13;
    public estacionArchivo: number = 14;
    public operacion;

    constructor(operacion) {
        this.operacion = operacion
    }

    get actualtCliente() {
        if (this.operacion.actualtacion === this.estacionCliente) {
            return true
        } else {
            return false
        }
    }

    get actualOficialNegocio() {
        if (this.operacion.estacion === this.estacionOficialDeNegocio) {
            return true
        } else {
            return false
        }
    }

    get actualAnalisisRiesgo() {
        if (this.operacion.estacion === this.estacionAnalisisRiesgo) {
            return true
        } else {
            return false
        }
    }

    get actualComiteCreditos() {
        if (this.operacion.estacion === this.estacionComiteCreditos) {
            return true
        } else {
            return false
        }
    }

    get actualOficialNegociosPostComite() {
        if (this.operacion.estacion === this.estacionOficialNegocioPostComite) {
            return true
        } else {
            return false
        }
    }


    get actualLegal() {
        if (this.operacion.estacion === this.estacionLegal) {
            return true
        } else {
            return false
        }
    }

    get actualAsesorLegal() {
        if (this.operacion.estacion === this.estacionAsesorLegal) {
            return true
        } else {
            return false
        }
    }

    get actualOficialNegocioLiquidacion() {
        if (this.operacion.estacion === this.estacionOficialNegocioLiquidacion) {
            return true
        } else {
            return false
        }
    }

    get actualJefeOperaciones() {
        if (this.operacion.estacion === this.estacionJefeOperaciones) {
            return true
        } else {
            return false
        }
    }

    get actualTesoreria() {
        if (this.operacion.estacion === this.estacionTesoreria) {
            return true
        } else {
            return false
        }
    }

    get actualRecepcion() {
        if (this.operacion.estacion === this.estacionRecepcion) {
            return true
        } else {
            return false
        }
    }

    get actualAsistenteCobranzas() {
        if (this.operacion.estacion === this.estacionAsistenteCobranzas) {
            return true
        } else {
            return false
        }
    }

    get actualArchivo() {
        if (this.operacion.estacion === this.estacionArchivo) {
            return true
        } else {
            return false
        }
    }

    get habilitarEdicionDetalles() {
        let estaciones = [
            this.estacionOficialDeNegocio,
            this.estacionAnalisisRiesgo,
            this.estacionComiteCreditos,
            this.estacionOficialNegocioPostComite,
            this.estacionLegal,
            this.estacionAsesorLegal,
            this.estacionOficialNegocioLiquidacion
        ];

        return estaciones.includes(this.operacion.estacion);
    }

}

export interface IEstacion {
    id?: number;
    nombre?: number;
    descripcion_cliente?: number;
}