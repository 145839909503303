import { Component, ComponentFactoryResolver, Input, OnInit, ViewChild } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, FormArray } from '@angular/forms';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import {
	faPencilAlt, faSearch, faTimes, faTrash, faEdit
} from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'app/core/services/toast/toast.service';
import { Router } from '@angular/router';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { MonedaPipe } from 'app/core/pipes/moneda.pipe';
import { RestriccionesService } from 'app/core/services/auth/restricciones.service';
import { AuthService } from 'app/core/services/auth/auth.service';
import { Operacion } from 'app/core/services/auth/Operacion';
import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';
import { CuentasXCobrarModalComponent } from '../modals/recaudacion/cuentas-x-cobrar-modal/cuentas-x-cobrar-modal.component';
import { columnHeader } from '../factoring-datatable/factoring-datatable.component';
import { StringDateHourPipe } from 'app/core/pipes/string-date-hour.pipe';
import { DesglosesComponent } from '../desgloses/desgloses.component';
import { CommonServicesService } from 'app/core/services/common-services.service';
import { FixedFinanceModalComponent } from '../modals/fixed-finance-modal/fixed-finance-modal.component';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { AddRecaudacionModalComponent } from 'app/modules/recaudacion/modals/add-recaudacion-modal/add-recaudacion-modal.component';
import { ExcepcionDeudaLeasingComponent } from '../modals/operaciones/excepcion-deuda-leasing/excepcion-deuda-leasing.component';
import { state } from '@angular/animations';

declare var $: any;

@Component({
	selector: 'app-liquidacion-component',
	templateUrl: './liquidacion-component.component.html',
	styleUrls: ['./liquidacion-component.component.css'],
	providers: [MonedaPipe, StringDateHourPipe, NgbActiveModal]
})
export class LiquidacionComponentComponent implements OnInit {
	faTimes = faTimes;
	@ViewChild('modificarLiquidacion', { static: false }) public modificarLiquidacion;
	@ViewChild('modificarDatoLiquidacion', { static: false }) public modificarDatoLiquidacion;
	@ViewChild('e_tipocambio', { static: false }) public e_tipocambio;
	@ViewChild('e_abonoLeasing', { static: false }) e_abonoLeasing;

	faPencilAlt = faPencilAlt;
	faTrash = faTrash;
	faSearch = faSearch;
	faSpinner = faEdit;
	public formularioLiquidacion: FormGroup;
	public formularioLeasing: FormGroup;
	public tiposCambio: any[] = [];
	public monedas: any[];
	public comisiones: any[];
	public gastos: any[];
	public minDate: Date;
	public liquidacionDate: Date;
	public liquidacionDates: Date[] = [];
	public documentos: any[];
	public columns: any[];
	public columnasConcepto: any[];
	public documentosCartera: any[];
	public desgloses: any[];
	public desglosesLiq: any;
	public _liquidaciones: any;
	desglosesLineaPropuesta: any;
	public set liquidaciones(value) {

	}

	public get liquidaciones() {
		return this._liquidaciones
	}

	public _liquidacion: any;
	public set liquidacion(value) {
		this.liquidacionSetter(value)
	}

	public get liquidacion() {
		return this._liquidacion
	}

	public carteraSelct:number = 0;
	public liquidacionSaved: boolean = false;
	public liquidacionEditable: any;
	public rowEditable: any;
	public monedaOperacion: any;
	public updatePrefix: boolean = true;
	public motivoliquidacion: string;
	public value = 0;
	public montoDiferencia = 0;
	public diferenciaToggle: boolean = true;
	public _temporalEditable: number = 0;
	public totalIGV: number = 0;
	public total: number = 0;
	set temporalEditable(value: number) {
		let igv: number = value * 0.18
		this.totalIGV = Number(igv.toFixed(10)) // Fix a 10 decimales para prevenir ..9999 periódico
		this.totalIGV = Math.round(this.totalIGV * 100) / 100 // Redondeo a 2 decimales
		this.total = Number(value) + this.totalIGV
		this.total = Math.round(this.total * 100) / 100 // Redondeo a 2 decimales
		this._temporalEditable = value
	}

	get temporalEditable() {
		return this._temporalEditable
	}
	_idOperacion: number;
	prefix: string = 'S/ '
	totalColor: boolean = false;
	public existeTipoCambio: boolean = true;
	@Input() set idOperacion(value) {
		this._idOperacion = value
	}


	@Input() readOnlyLiquidacion;
	@Input() operacion: any;
	@Input() tieneDeudaVencida = false

	private modalDocumentsCarteraReference: any;
	public documentsCarteraList: any[] = [];
	public documentsCarteraSelectedList: any[] = [];
	public documentsCarteraSelectionList: any[] = [];
	public headerDocumentsCarteraTable: columnHeader[];
	public pageDocumentsCarteraTableActual: number;
	public pageDocumentsCarteraTableTotal: number;
	public pageDocumentsCarteraTablePerPages: number = 10;
	public pageDocumentsCarteraTablePages: number[];

	public headerCuentasCobrarTable: columnHeader[];
	public headerLeasing: any[];

	public documentsLeasing: any[] = [];

	//MONTO LEASING
	public montoLeasing:number = 0;
	public abonoComite:number = 0;
	public montoDelanto:any = '0.00';
	showAdelanto: boolean = false;
	public abonos_leasing: any = null;
	liqAdelanto:any = "0.00";

	cxcList: any = [];
	tiposFirmas: any[] = [];
	propuestaComite: any;

	liquidacionAux: any;

	loading = true;
	guardando = false

	montoAdelanto = 0;

	cerrar = false;

	showMotivo: boolean = true;

	constructor(
		private configs: DocschecklistService,
		private propuestaService: PropuestaComercialService,
		public modalService: NgbModal,
		public activeModal: NgbActiveModal,
		private router: Router,
		private formbuild: FormBuilder,
		public serviciosComunes: CommonServicesService,
		public toast: ToastService,
		private factoring: FactoringService,
		public authService: AuthService,
		public monedapipe: MonedaPipe,
		public localService: LocalServiceService,
		public restricciones: RestriccionesService,
		private recaudacionService: RecaudacionService,
	) {
		// this.initform();
		this.initData();
		this.initform();
		this.initFormLeasing();
	}

	initData(){

		let monedas = this.configs.obtenerMonedas();
		let tiposDeCambio = this.configs.obtenerTiposDeCambio(this.formatDate(new Date()));
		let tiposFirmas = this.propuestaService.obtenerTiposFirmas();

		Promise.all([monedas, tiposDeCambio, tiposFirmas]).then((res: any[]) => {
			this.monedas = res[0]['results']
			this.tiposFirmas = res[2]['results']
			this.crearTiposCambios(res[1]['results'][0])

			let simulacion = this.propuestaService.ObtenerSimulacionDeLiquidacion(this._idOperacion)
			let desgloses = this.serviciosComunes.getDesglose(this._idOperacion, 3)

			return Promise.all([simulacion, desgloses])
		}).then((res: any[]) => {
			let liquidacion = res[0]
			let { desgloses } = res[1]
			this.desgloses = desgloses
			this.desglosesLineaPropuesta = desgloses
			var { es_liquidacion_adelanto, liquidaciones } = liquidacion

			if (es_liquidacion_adelanto && !liquidaciones.length) {
				this.liquidacionAux = liquidacion;
				this.initWatcher();
				this.crearSimulacion();

			} else if ((!es_liquidacion_adelanto && !liquidaciones.length) ||
				(!es_liquidacion_adelanto && liquidaciones.length && liquidaciones[0].provisional)) {
				this.liquidacionAux = liquidacion;
				this.initWatcher();
				this.crearSimulacion();
			} else {
				this.liquidacion = liquidacion

				this.liquidacionDate = this.parseDate(liquidaciones[0].fecha_desembolso_programado);
				liquidaciones.forEach((element, i) => {
					this.liquidacionDates.push(this.parseDate(element['fecha_desembolso_programado']));
				});
				this.liquidacionSaved = true
				this.cargarLiquidacion();
			}

		}).catch((error) => {
			console.error(error);

		});

	}

	detallesLiquidacion(det): any[] {
		if (this.desgloses.length > 0) {

		}
		return [det]
	}

	mergeDesgloses(liquidacion) {
		let desgloseDetalles = liquidacion.liquidaciones.map((liq, liqIndex, liqArr) => {
			let detall = liq.detalles.map((det, detIndex) => {
				let find = this.desgloses.find((desg) => desg.aceptante.ruc == det.ruc)
				if (!!find) {
					det.mora = find.mora
					det.comision_estructuracion = find.comision_estructuracion
					det.anticipo = find.anticipo
				} else {
					det.mora = liquidacion.tasa_mora_comite
				}
				return det
			})
			liq.detalles = detall
			return liq
		})

		liquidacion.liquidaciones = desgloseDetalles
		return liquidacion
	}

	crearTiposCambios(cambios) {
		if (cambios) {
			this.tiposCambio.push({
				descripcion: 'Contable',
				valor: cambios.contable
			})

			this.tiposCambio.push({
				descripcion: 'Compra',
				valor: cambios.compra
			})

			this.tiposCambio.push({
				descripcion: 'Venta',
				valor: cambios.venta
			})

		} else {
			this.existeTipoCambio = false
		}
	}

	ngOnInit(): void {
		console.log( 'Operacion', this.operacion)
		
		$(function () {
			$('[data-toggle="tooltip"]').tooltip()
		});

		this.initTables();

		if (this.operacion) {
			this.obtenerDocumentosCartera(1, 100, this.operacion.beneficiario, '', '1,3');

			let payload = { beneficiario: this.operacion.beneficiario };

			this.recaudacionService.obtenerCuentasPorCobrar(payload, '1')
				.then((res: any) => {
					const { results, count, num_pages } = res;
					this.cxcList = results;
				}).catch(error => {

				});

			this.propuestaService.obtenerPropuestaComite(this.operacion.id)
				.then((res:any) => {
					//Monto leasing
					this.montoLeasing = res.abono_leasing_comite;
					this.abonoComite = res.abono_leasing_comite;
					this.propuestaComite = res;
				}).catch(error => {
					console.error(error);

				})

		}

	}

	//TODO: si el monto es mayor al abono del comte mostrar el motivo
	cambio( monto ){
		// + this.abonoComite
		let valor = Number( monto.value.split(' ')[1].replace(/[,]/g, '') );
		let suma = valor +  Number( this.montoDelanto.replace(/[,]/g, '') );

		if( !this.showAdelanto){

			if(  valor > this.abonoComite ){
				this.showMotivo = false;
			}else{
				this.showMotivo = true;
			}
			
		} else {

			if(valor > 0 && suma > this.abonoComite ){
				this.showMotivo = false;
			}else{
				this.showMotivo = true;
			}

		}

		if (this.showMotivo) {
			this.formularioLeasing.controls.motivo.disable()
		} else {
			this.formularioLeasing.controls.motivo.enable()
		}

	}

	initFormLeasing(){

		this.formularioLeasing = this.formbuild.group({
			monto:[0, [Validators.required] ],
			referencia:['',[ /*Validators.required*/ ] ],
			motivo: [ {value: '', disabled: true}, [ Validators.required ] ]
		})
	}

	liquidacionSetter(value) {
		if (!value) {
			return
		}

		value.liquidaciones.forEach((liq, i) => {
			if (liq.comite_aprobaciones) {
				liq.comite_aprobaciones.forEach((miembro: any) => {
					miembro.fecha = this.commentDate(miembro.fecha)
				})
			}
		})


		if (value.liquidaciones.length > 0) {
			this._liquidacion = this.mergeDesgloses(value)
		} else {
			this._liquidacion = value
		}

	}

	initform() {
		this.minDate = new Date();

		this.formularioLiquidacion = this.formbuild.group({
			tipo_cambio: ['', [Validators.required]],
			fecha_desembolso: [new Date(), [Validators.required]],
			moneda: ['', [Validators.required]],
			tem_soles: [{ value: '', disabled: true }, []],
			tea_soles: [{ value: '', disabled: true }, []],
			anticipo: [{ value: '', disabled: true }, []],
			tem_dolares: [{ value: '', disabled: true }, []],
			tea_dolares: [{ value: '', disabled: true }, []],
			tipo_firma_contrato: [1, []],
			revisado: [true, []],
			liquidaciones: this.formbuild.array([]),
		});
	}

	addFormLiquidacion(): FormGroup {
		return this.formbuild.group({
			tipo_cambio: ['', [Validators.required]],
			fecha_desembolso: [new Date(), [Validators.required]],
			moneda: ['', [Validators.required]],
			tem_soles: [{ value: '', disabled: true }, []],
			tea_soles: [{ value: '', disabled: true }, []],
			anticipo: [{ value: '', disabled: true }, []],
			tem_dolares: [{ value: '', disabled: true }, []],
			tea_dolares: [{ value: '', disabled: true }, []],
			tipo_firma_contrato: [1, []],
			revisado: [true, []],
		});
	}

	get liquidacionesForm(): FormArray {
		return this.formularioLiquidacion.get("liquidaciones") as FormArray
	}

	loadTableColumns() {
		this.columns = [
			{
				headerName: 'N°',
				pipe: 'indexcol',
				sortable: true
			},
			{
				headerName: 'Aceptante',
				class: 'text-center',
				field: 'aceptante',
				sortable: true
			},
			{
				headerName: 'Monto',
				field: 'monto_total',
				sortable: true,
				class: 'text-center',
				pipe: 'currency',
				moneda: 'moneda',
			},
			{
				headerName: 'Neto pagar',
				pipe: 'currency',
				class: 'text-center',
				field: 'neto_pagar',
				sortable: true,
				moneda: 'moneda',
			},
			{
				headerName: 'No Financiado',
				class: 'text-center',
				field: 'monto_no_financiado',
				pipe: 'currency_detail',
				sortable: true,
				moneda_detail: this.liquidacion.liquidaciones[0].moneda,
			},
			// {
			// 	headerName: 'Financiado',
			// 	field: 'monto_financiado',
			// 	class: 'text-center',
			// 	pipe: 'currency_detail',
			// 	sortable: true,
			// 	moneda_detail: this.liquidacion.liquidaciones[0].moneda,
			// },
			{
				headerName: 'Financiado',
				class: 'text-center',
				field: 'monto_financiado',
				sortable: true,
				pipe: 'functionActionabled',
				moneda_detail: this.liquidacion.liquidaciones[0].moneda,
				is_tooltip: true,
				tooltip_text: 'Ver ajustes',
				function: function (row, header) {
					let amount;

					function numberWithCommas(x) {
						var parts = x.toString().split(".");
						parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
						return parts.join(".");
					}

					if (header.moneda_detail == 1) {
						amount = `S/ ${numberWithCommas(row.monto_financiado)}`
					}
					else {
						amount = `$ ${Number(row.monto_financiado).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
					}

					return amount;
				},
			},
			{
				headerName: 'Nro Documento',
				field: 'numero_documento',
				class: 'text-center',
				sortable: true
			},
			{
				headerName: 'Vcto CAVALI',
				field: 'vencimiento_cavali',
				sortable: true,
				class: 'text-center',
				pipe: 'date'
			},
			{
				headerName: 'Dias',
				field: 'dias_financiados',
				class: 'text-center',
				sortable: true,
			},
			{
				headerName: 'TEM',
				field: 'tem',
				sortable: true,
				class: 'text-center',
				pipe: 'function',
				function: function (row) {
					return row.tem
				},
				postfix: 'percent'
			},
			{
				headerName: 'Mora',
				field: 'mora',
				class: 'text-center',
				sortable: true,
				postfix: 'percent'
			},
			{
				headerName: 'Estructuración',
				field: 'comision_estructuracion',
				class: 'text-center',
				sortable: true,
				postfix: 'percent'
			},
			{
				headerName: 'Anticipo',
				field: 'anticipo',
				class: 'text-center',
				sortable: true,
				postfix: 'percent'
			},
		];

		// this.agregarColumnas()

	}

	colorItem(row: any) {
		return (row.financiado_ajuste != 0) ? 'red' : 'none';
	}

	isColor(row) {
		return (row.dias_ajuste != 0) ? 'red' : 'none';
	}

	workflow(row) {
		console.log(row);
		if (row.dias_ajuste != 0 || row.financiado_ajuste != 0) {
			const modalRef = this.modalService.open(FixedFinanceModalComponent, {
				size: 'lg'
			});
			modalRef.componentInstance.detalle = row;

			modalRef.result.then((result) => {
				console.log(result);
			}, (reason) => {
				console.log(reason);
			});
		};
	}

	async onClickGuardar() {
		if (this.guardando) return
		this.guardando = true
		await this.guardarLiquidacion()
		this.guardando = false
	}

	async guardarLiquidacion(action = 1) {

		if (this.liquidacion.monto_desembolso < 0) {
			this.toast.warning('El desembolso no puede ser negativo');
			return;
		}

		// solo validar si el desembolso es menor a 1 no se esta cerrando la liquidacion y el monto leasing es 0
		if (this.liquidacion.monto_desembolso < 1 && !this.cerrar && this.montoLeasing === 0) {
			this.toast.warning('El desembolso no puede ser cero sin cerrarse la liquidacion o tener un abono leasing');
			return;
		}

		let tipo_cambio = (this.liquidacionesForm.at(0)) ? this.liquidacionesForm.at(0).get('tipo_cambio').value : this.formularioLiquidacion.get('tipo_cambio').value;
		let moneda = (this.liquidacionesForm.at(0)) ? this.liquidacionesForm.at(0).get('moneda').value : this.formularioLiquidacion.get('moneda').value;
		let fecha_desembolso = (this.liquidacionesForm.at(0)) ? this.liquidacionesForm.at(0).get('fecha_desembolso').value : this.formularioLiquidacion.get('fecha_desembolso').value;
		let tipo_firma_contrato = (this.liquidacionesForm.at(0)) ? this.liquidacionesForm.at(0).get('tipo_firma_contrato').value : this.formularioLiquidacion.get('tipo_firma_contrato').value;

		// const data = this.liquidacion;
		let data = {};
		data['fecha_desembolso'] = this.formatDate(fecha_desembolso);
		data['moneda'] = moneda;
		data['operacion'] = this._idOperacion;
		data['tipo_cambio'] = tipo_cambio;
		data['tipo_firma_contrato'] = tipo_firma_contrato;

		const validarAbono = ()=>{
			const liq = this.liquidacionAux;

			if (liq.liquidaciones[0]) {
				if (liq.liquidaciones[0].abono_leasing == 0) {
					return this.abonoComite;
				} else {
					return 0;
				}
			}

			return this.abonoComite;
		}

		const ref = this.liquidacion?.liquidaciones[0]?.abonos_leasing[0]?.referencia ?? '';

		data['abonos_leasing'] = [{
			moneda,
			monto: this.abonos_leasing != null ? this.formularioLeasing.controls.monto.value : validarAbono(),
			referencia: this.abonos_leasing != null ? this.formularioLeasing.controls.referencia.value : ref,
			cliente: 0, //Todo this.operacion.beneficiario, Se envia en 0 para enviar errores de validacion
			cliente_ruc: this.operacion.beneficiario_.ruc,
			cliente_nombre: this.operacion.beneficiario_.nombre
		}]

		if( this.liquidacion?.liquidaciones[0].motivo_modificacion ){

			data['motivo_modificacion'] = this.formularioLeasing.controls.motivo.value != '' ? this.formularioLeasing.controls.motivo.value : this.liquidacion?.liquidaciones[0].motivo_modificacion;
		}

		if (this.cerrar) {
			await this.cerrarliquidacion(this.liquidacion.id, data);
			return;
		}

		await this.propuestaService.guardarSimulacionDeLiquidacion(this.liquidacion.id, data).then((res: any) => {
			this.obtenerSimulacion();
			this.factoring.eventChangeOperation();
			this.operacion.liquidado = true;
		}, err => {
			console.error(err);
		});
	}

	async cerrarliquidacion(liq, data) {
		await this.propuestaService.cerrarLiquidacion(liq, data).then(resp => {
			this.obtenerSimulacion();
			this.factoring.eventChangeOperation();
			this.operacion.liquidado = true;
		}, err => {
			console.error(err);

		});
	}

	formatDate(date) {
		if (!(date instanceof Date))
			return date;

		return date.getFullYear() +
			"-" + (date.getMonth() + 1) +
			"-" + date.getDate();
	}

	crearSimulacion(action = 1) {
		console.log(this.liquidacionAux);
		if (!this.existeTipoCambio) {
			this.errorNoHayTipoCambio();
			return;
		}
		if (this.formularioLiquidacion.invalid) return;
		if (this.liquidacion && this.liquidacion?.liquidado) {
			return;
		}
		if (this.liquidacionAux?.liquidaciones.length && this.liquidacionAux.liquidaciones[0].detalles.length) {
			this.formularioLiquidacion.get('moneda').setValue(this.liquidacionAux.liquidaciones[0].detalles[0]['moneda']);
		}

		let tipo_cambio = (this.liquidacionesForm.at(0)) ? this.liquidacionesForm.at(0).get('tipo_cambio').value : this.formularioLiquidacion.get('tipo_cambio').value;
		let moneda = this.liquidacionAux?.moneda ? this.liquidacionAux?.moneda : this.liquidacion.moneda;
		let fecha_desembolso = (this.liquidacionesForm.at(0)) ? this.liquidacionesForm.at(0).get('fecha_desembolso').value : this.formularioLiquidacion.get('fecha_desembolso').value;
		let tipo_firma_contrato = (this.liquidacionesForm.at(0)) ? this.liquidacionesForm.at(0).get('tipo_firma_contrato').value : this.formularioLiquidacion.get('tipo_firma_contrato').value;

		const abonos_leasing = this.abonos_leasing?.value

		const validarAbono = ()=>{

			const liq = this.liquidacionAux;

			if(liq.liquidaciones[0]){
				if( liq.liquidaciones[0].abono_leasing == 0 ){
					return this.abonoComite;
				} else {
					return 0;
				}
			}

			return liq?.liquidaciones[0] ? liq?.liquidaciones[0].abono_leasing : this.abonoComite;

		}

		let ref = this.liquidacion?.liquidaciones[0]?.abonos_leasing[0]?.referencia ? this.liquidacion?.liquidaciones[0]?.abonos_leasing[0]?.referencia : '';

		const data = {
			operacion: this._idOperacion,
			tipo_cambio: tipo_cambio,
			fecha_desembolso: this.formatDate(fecha_desembolso),
			moneda: moneda,
			tipo_firma_contrato: tipo_firma_contrato,
			abonos_leasing: [
				{
					moneda,
					monto: abonos_leasing ? abonos_leasing?.monto : validarAbono(), // TODO : si no tomar el q viene de propuesta comite
					referencia: abonos_leasing?.referencia ? abonos_leasing?.referencia : ref,
					cliente: 0, //Todo this.operacion.beneficiario, Se envia en 0 para enviar errores de validacion
					cliente_ruc: this.operacion.beneficiario_.ruc,
					cliente_nombre: this.operacion.beneficiario_.nombre
	
				}
			],
		};

		if (!this.showMotivo && (abonos_leasing?.motivo || this.liquidacion?.liquidaciones[0].motivo_modificacion)) {
			data['motivo_modificacion'] = abonos_leasing?.motivo ? abonos_leasing?.motivo : this.liquidacion?.liquidaciones[0].motivo_modificacion;
		}

		if (this.liquidacion)
			data['grupos_no_cobrar'] = this.liquidacion.grupos_no_cobrar;

		// console.log(this.cerrar);
		// if (this.cerrar) {
		// 	this.crearSimularCierre(data);
		// 	return;
		// }

		this.propuestaService.crearSimulacionDeLiquidacionMethod2(
			data
		).then((res: any) => {

			this.liqAdelanto = res.liquidaciones[0]?.abono_leasing;

			this.montoLeasing = res.liquidaciones[0]?.abono_leasing;

			this.liquidacion = res;

			this.monedaOperacion = this.liquidacionAux?.moneda ? this.liquidacionAux?.moneda : this.liquidacion.moneda;

			this.setPrefix(this.liquidacion.liquidaciones[0].moneda);

			this.liquidacion.liquidaciones.forEach((element, i) => {
				this.liquidacionDates.push(this.parseDate(element['fecha_desembolso_programado']));
			});

			this.formularioLiquidacion.get('anticipo').setValue(res.anticipo_comite);
			this.formularioLiquidacion.get('tem_soles').setValue(res.tem_soles_comite);
			this.formularioLiquidacion.get('tea_soles').setValue(res.tea_soles_comite);
			this.formularioLiquidacion.get('tem_dolares').setValue(res.tem_dolares_comite);
			this.formularioLiquidacion.get('tea_dolares').setValue(res.tea_dolares_comite);

			this.liquidacion.liquidaciones.forEach((element, i) => {

				if (!this.liquidacionesForm.at(i)) {
					this.liquidacionesForm.push(this.addFormLiquidacion());
				}

				let tipoDeFirma = (this.liquidacion.liquidaciones.length > 1) ? this.liquidacion.liquidaciones[1]['tipo_firma_contrato']
					: this.liquidacion.liquidaciones[i]['tipo_firma_contrato'];
				let tipoCambio = (this.liquidacion.liquidaciones.length > 1) ? this.liquidacion.liquidaciones[1]['tipo_cambio_monto']
					: this.liquidacion.liquidaciones[i]['tipo_cambio_monto'];

				this.liquidacionesForm.at(i).get('tipo_cambio').setValue(tipoCambio);
				this.liquidacionesForm.at(i).get('moneda').setValue(this.monedaOperacion);
				this.liquidacionesForm.at(i).get('anticipo').setValue(element['anticipo']);
				this.liquidacionesForm.at(i).get('tem_soles').setValue(res.tem_soles_comite);
				this.liquidacionesForm.at(i).get('tea_soles').setValue(res.tea_soles_comite);
				this.liquidacionesForm.at(i).get('tem_dolares').setValue(res.tem_dolares_comite);
				this.liquidacionesForm.at(i).get('tea_dolares').setValue(res.tea_dolares_comite);
				this.liquidacionesForm.at(i).get('tipo_firma_contrato').setValue(tipoDeFirma);

			});

			if (this.liquidacion.liquidaciones.length > 1) {
				this.montoAdelanto = this.liquidacion.liquidaciones[1].monto_desembolso;
			} else if (this.liquidacion.liquidaciones.length === 1) {
				this.montoAdelanto = this.liquidacion.adelanto;
			}

			this.loadTableColumns();
		}).catch(error => {
			console.log(error, 'erro en simulacion cargada');
			if (error.error?.fecha_desembolso)
				this.toast.warning(error?.fecha_desembolso);
			// this.formularioLiquidacion.get('fecha_desembolso').setValue(new Date());
		});

	}

	crearSimularCierre(e) {
		if (!e) {
			this.crearSimulacion();
			return;
		}
		
		this.propuestaService.crearSimulacionDeCierre(this.operacion.id, {}).then((res: any) => {
			this.liquidacion = res;

			this.monedaOperacion = this.liquidacionAux.moneda;

			this.setPrefix(this.liquidacion.liquidaciones[0].moneda);

			this.liquidacion.liquidaciones.forEach((element, i) => {
				this.liquidacionDates.push(this.parseDate(element['fecha_desembolso_programado']));
			});

			this.formularioLiquidacion.get('anticipo').setValue(res.anticipo_comite);
			this.formularioLiquidacion.get('tem_soles').setValue(res.tem_soles_comite);
			this.formularioLiquidacion.get('tea_soles').setValue(res.tea_soles_comite);
			this.formularioLiquidacion.get('tem_dolares').setValue(res.tem_dolares_comite);
			this.formularioLiquidacion.get('tea_dolares').setValue(res.tea_dolares_comite);

			this.liquidacion.liquidaciones.forEach((element, i) => {

				if (!this.liquidacionesForm.at(i)) {
					this.liquidacionesForm.push(this.addFormLiquidacion());
				}

				let tipoDeFirma = (this.liquidacion.liquidaciones.length > 1) ? this.liquidacion.liquidaciones[1]['tipo_firma_contrato']
					: this.liquidacion.liquidaciones[i]['tipo_firma_contrato'];
				let tipoCambio = (this.liquidacion.liquidaciones.length > 1) ? this.liquidacion.liquidaciones[1]['tipo_cambio_monto']
					: this.liquidacion.liquidaciones[i]['tipo_cambio_monto'];

				this.liquidacionesForm.at(i).get('tipo_cambio').setValue(tipoCambio);
				this.liquidacionesForm.at(i).get('moneda').setValue(this.monedaOperacion);
				this.liquidacionesForm.at(i).get('anticipo').setValue(element['anticipo']);
				this.liquidacionesForm.at(i).get('tem_soles').setValue(res.tem_soles_comite);
				this.liquidacionesForm.at(i).get('tea_soles').setValue(res.tea_soles_comite);
				this.liquidacionesForm.at(i).get('tem_dolares').setValue(res.tem_dolares_comite);
				this.liquidacionesForm.at(i).get('tea_dolares').setValue(res.tea_dolares_comite);
				this.liquidacionesForm.at(i).get('tipo_firma_contrato').setValue(tipoDeFirma);

			});

			if (this.liquidacion.liquidaciones.length > 1) {
				this.montoAdelanto = this.liquidacion.liquidaciones[1].monto_desembolso;
			} else if (this.liquidacion.liquidaciones.length === 1) {
				this.montoAdelanto = this.liquidacion.adelanto;
			}

			this.loadTableColumns();
		}, err => {
			console.error(err);

		});
	}

	cargarLiquidacion() {
		if (this.liquidacion.liquidaciones.length) {
			if (this.liquidacion.liquidaciones[0].detalles.length > 0) {
				this.formularioLiquidacion.get('moneda').setValue(this.liquidacion.liquidaciones[0].detalles[0]['moneda']);
				this.monedaOperacion = this.liquidacion.liquidaciones[0].detalles[0]['moneda'];
			}
		}

		// this.monedaOperacion = this.liquidacion.liquidacion.moneda;
		this.formularioLiquidacion.get('tipo_cambio').setValue(this.liquidacion.liquidaciones[0].tipo_cambio_monto);
		// this.formularioLiquidacion.get('moneda').setValue(this.liquidacion.liquidacion.moneda);
		this.formularioLiquidacion.get('moneda').disable();
		this.formularioLiquidacion.get('fecha_desembolso').setValue(this.liquidacion.liquidaciones[0].fecha_desembolso_programado)
		this.formularioLiquidacion.get('anticipo').setValue(this.liquidacion.anticipo_comite);
		this.formularioLiquidacion.get('tipo_firma_contrato').setValue(this.liquidacion?.liquidaciones[0].tipo_firma_contrato);
		this.formularioLiquidacion.get('tem_soles').setValue(this.liquidacion.tem_soles_comite);
		this.formularioLiquidacion.get('tea_soles').setValue(this.liquidacion.tea_soles_comite);
		this.formularioLiquidacion.get('tem_dolares').setValue(this.liquidacion.tem_dolares_comite);
		this.formularioLiquidacion.get('tea_dolares').setValue(this.liquidacion.tea_dolares_comite);
		// this.setPrefix(this.liquidacion.liquidacion.moneda);
		this.setPrefix(this.monedaOperacion);

		this.liquidacion.liquidaciones.forEach((element, i) => {
			this.liquidacionesForm.push(this.addFormLiquidacion());

			if (element.detalles.length > 0) {
				this.liquidacionesForm.at(i).get('moneda').setValue(element.detalles[0]['moneda']);
				this.monedaOperacion = element.detalles[0]['moneda'];
			}
			this.liquidacionesForm.at(i).get('moneda').disable();

			this.liquidacionesForm.at(i).get('anticipo').setValue(element['anticipo']);
			this.liquidacionesForm.at(i).get('tem_soles').setValue(this.liquidacion.tem_soles_comite);
			this.liquidacionesForm.at(i).get('tea_soles').setValue(this.liquidacion.tea_soles_comite);
			this.liquidacionesForm.at(i).get('tem_dolares').setValue(this.liquidacion.tem_dolares_comite);
			this.liquidacionesForm.at(i).get('tea_dolares').setValue(this.liquidacion.tea_dolares_comite);
			this.liquidacionesForm.at(i).get('tipo_cambio').setValue(element['tipo_cambio_monto']);
			this.liquidacionesForm.at(i).get('fecha_desembolso').setValue(this.parseDate(element['fecha_desembolso_programado']));
			this.liquidacionesForm.at(i).get('tipo_firma_contrato').setValue(element['tipo_firma_contrato']);
			this.liquidacionesForm.at(i).get('revisado').setValue(element['revisado']);

		});

		if (this.liquidacion.liquidaciones.length > 1) {
			this.montoAdelanto = this.liquidacion.liquidaciones[1].monto_desembolso;
		} else if (this.liquidacion.liquidaciones.length === 1) {
			this.montoAdelanto = this.liquidacion.adelanto;
		}

		this.loadTableColumns();
	}

	setPrefix(moneda) {
		this.updatePrefix = false
		setTimeout(() => {
			if (moneda === 1) {
				this.prefix = 'S/ '
			} else {
				this.prefix = '$ '
			}
			this.updatePrefix = true
		}, 500);
	}

	initWatcher() {
		if (!this.tiposCambio[0]) {
			// this.errorNoHayTipoCambio()
			return
		}

		this.formularioLiquidacion.get('tipo_cambio').setValue(this.tiposCambio[0].valor);
		this.formularioLiquidacion.get('fecha_desembolso').setValue(new Date());
		this.formularioLiquidacion.get('moneda').setValue(1);

		this.formularioLiquidacion.get('tipo_cambio').valueChanges.subscribe((change) => {
			this.crearSimulacion()
		});

		this.formularioLiquidacion.get('fecha_desembolso').valueChanges.subscribe((change) => {
			this.crearSimulacion();
		});

		// this.formularioLiquidacion.get('moneda').valueChanges.subscribe((change) => {
		// 	this.crearSimulacion();
		// })

		this.formularioLiquidacion.get('tipo_firma_contrato').valueChanges.subscribe((change) => {
			this.crearSimulacion();
		});

	}

	changeForm(e) {

		console.log(e);

		this.crearSimulacion();
	}

	modificarDataLiquidacion(liq = null) {
		this.propuestaService.modificarSimulacionDeLiquidacion(liq.id).then((res) => {
			this.liquidacionEditable = res;
			this.columnasConcepto = [
				{
					headerName: 'N°',
					pipe: 'indexcol',
					sortable: true
				},
				{
					headerName: 'Concepto',
					field: 'concepto_descripcion',
					class: 'text-center',
					sortable: true
				},
				{
					headerName: 'Grupo',
					field: 'grupo_codigo',
					class: 'text-center',
					sortable: true
				},
				{
					headerName: 'Fórmula',
					field: 'formula',
					sortable: true
				},
				{
					headerName: 'Moneda',
					field: 'moneda_descripcion',
					class: 'text-center',
					sortable: true
				},
				{
					headerName: 'Monto',
					class: 'text-center',
					field: 'monto_original',
					sortable: true,
					pipe: 'function',
					function: function (row, header) {
						if (!row.monto_original) {
							return null
						}

						if (header.moneda_detail == 1) {
							let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(row.monto_original))
							return `S/ ${number}`
						}
						else {
							return `$ ${Number(row.monto_original).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
						}
					},
				},
				{
					headerName: 'Valor modificado',
					field: 'monto_modificado',
					class: 'text-center',
					sortable: true,
					moneda_detail: this.liquidacionEditable.moneda,
					pipe: 'function',
					function: function (row, header) {
						if (!row.monto_modificado) {
							return null
						}

						if (header.moneda_detail == 1) {
							let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(row.monto_modificado))
							return `S/ ${number}`
						}
						else {
							return `$ ${Number(row.monto_modificado).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
						}
					},
				},
				{
					headerName: 'Diferencia',
					field: 'monto_modificado',
					class: 'text-center',
					sortable: true,
					moneda_detail: this.liquidacionEditable.moneda,
					pipe: 'function',
					function: function (row, header) {
						let valor = Number(row.monto_modificado) - Number(row.monto_original);

						if (!row.monto_modificado) {
							return null
						}

						if (header.moneda_detail == 1) {
							let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(valor))
							return `S/ ${number}`
						}
						else {
							return `$ ${Number(valor).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
						}
					},
				},
			];
			return this.modalService.open(this.modificarLiquidacion, {
				size: 'xl'
			}).result
		}).then((result) => {
			if (result) {
				console.log(this.liquidacionEditable, 'Liquidacion');
				this.actualizarConcepto(liq);
				this.factoring.eventChangeOperation();
			}
		})
	}

	eliminarLiquidacion() {

		if (this.operacion.revisado) {
			// this.toggleRevisado()
		}
		this.cerrar = false;

		this.propuestaService.eliminarLiquidacion(this.liquidacion.id)
			.then(res => {
				this.liquidacion.liquidado = false;
				this.liquidacionSaved = false;
				this.factoring.eventChangeOperation();
				this.ngOnInit();
				this.liquidacionSetter(this._liquidacion);
				this.obtenerSimulacion();
				this.operacion.liquidado = false;
			})
			.catch(error => {

			});
	}

	editarDatoLiquidacion(row) {
		console.log(row, 'Editar liq');
		this.rowEditable = row

		if (row.grupo == 1 || row.grupo == 2) {
			return
		}

		if (this.rowEditable.monto_modificado) {
			let valor = (this.rowEditable.monto_modificado / 1.18).toFixed(2)
			this.temporalEditable = Number(valor)
		}

		this.modalService.open(this.modificarDatoLiquidacion, {
			size: 'lg'
		}).result.then((response: any) => {
			if (response) {
				// this.rowEditable.monto_modificado = this.temporalEditable
				this.rowEditable.monto_modificado = this.total
				let index = this.liquidacionEditable.conceptos.findIndex((liq) => liq.id === row.id)
				this.liquidacionEditable.conceptos[index] = this.rowEditable
			}
			this.temporalEditable = null;
		}).catch(res => console.log(res))
	}

	actualizarConcepto(body) {

		if (!this.liquidacionEditable) {
			return;
		}

		body['conceptos'] = this.liquidacionEditable['conceptos'];

		for (const key in this.liquidacionEditable) {
			if (Object.prototype.hasOwnProperty.call(body, key)) {
				if (body[key] != this.liquidacionEditable[key]) {
					body[key] = this.liquidacionEditable[key];
				}
			}
		}

		this.propuestaService.actualizarSimulacionDeLiquidacion(body['id'], body).then((res: any) => {
			return this.propuestaService.ObtenerSimulacionDeLiquidacion(this.liquidacion.id)
		}).then((liquidacion: any) => {
			this.liquidacion = liquidacion
			this.ngOnInit();
		})
	}

	parseDate(date) {
		if (!date)
			return null;

		let dateNow = new Date();

		let yy = date.split('-')[0];
		let mm = (date.split('-')[1] - 1);
		let dd = date.split('-')[2];

		let d = new Date(yy, mm, dd, dateNow.getHours());
		return d;
	}

	errorNoHayTipoCambio() {
		this.modalService.open(this.e_tipocambio, {
			size: 'md'
		}).result.then((response: any) => {
			this.router.navigate(['/operaciones'])
		})

	}

	financial(x) {
		return Number.parseFloat(x).toFixed(2);
	}

	get diferencia() {
		let valor = this.liquidacionEditable.conceptos.reduce((acc, item) => {

			if (item.monto_modificado) {
				let mat = Number(item.monto_modificado) - Number(item.monto_original)
				acc = acc + mat;
			}

			return acc
		}, 0)

		if (this.liquidacionEditable.moneda == 1) {
			let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(valor))
			return `S/ ${number}`
		}
		else {
			return `$ ${Number(valor).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
		}
	}

	aprobarLiquidacion(idresponsable, index_liq) {
		console.log("LQ", this.liquidacion.liquidaciones)
		let length = this.liquidacion.liquidaciones[index_liq].comite_aprobaciones.length

		let aprobaciones = this.liquidacion.liquidaciones[index_liq].comite_aprobaciones.reduce((acc, item) => {

			if (item.fecha) {
				acc++
			}

			return acc
		}, 0)

		let aprobacionesListas = aprobaciones === (length - 1)

		this.propuestaService.aprobarModificacionDeSimulacion({
			liquidacion: this.liquidacion.liquidaciones[index_liq].id,
			responsable: idresponsable
		}).then((res: any) => {
			return this.propuestaService.ObtenerSimulacionDeLiquidacion(this.liquidacion.id)
		}).then((liquidacion: any) => {
			this.liquidacion = liquidacion

			if (aprobacionesListas) {
				this.router.navigate(['/operaciones-comite']);
			}
			// this.cargarLiquidacion()
			this.router.navigate(['/operaciones-comite']);
			// this.ngOnInit();
		})
	}

	obtenerSimulacion() {
		this.loading = false;
		this.initform();
		this.liquidacionDates = [];
		this.propuestaService.ObtenerSimulacionDeLiquidacion(this._idOperacion)
			.then((liquidacion: any) => {
				console.log( liquidacion , 'Eliminada liquidacion')
				const liq = liquidacion;
				this.liquidacionAux = liq;
				this.liquidacionSaved = liq && liq.liquidaciones.length > 0 && (liq.es_liquidacion_adelanto || !liq.liquidaciones[0].provisional)
				if (!this.liquidacionSaved) {
					this.loading = true;
					this.initWatcher();
					this.crearSimulacion();
				} else {
					this.liquidacion = liq;
					this.liquidacionAux = liq;
					liquidacion.liquidaciones.forEach((element, i) => {
						this.liquidacionDates.push(this.parseDate(element['fecha_desembolso_programado']));
					});
					this.liquidacionDate = this.parseDate(liquidacion.liquidaciones[0].fecha_desembolso_programado);
					this.cargarLiquidacion();
					this.loading = true;
				}
			}).catch(error => {
				this.loading = true;
			});
	}

	
	initTables() {
		let headerDocumentsCarteraTable = [
			{
				headerName: "N°",
				field: "id",
				sortable: true,
				pipe: "indexcol",
			},
			{
				class: "text-center",
				headerName: "Producto",
				field: "tipo_producto_descripcion",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Operación",
				field: "operacion",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Tipo",
				field: "operacion_tipo_detalle_descripcion",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Nro Documento",
				field: "operacion_numero_documento",
			},
			{
				class: "text-center",
				headerName: "Monto",
				field: "monto_neto",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Saldo",
				field: "saldo",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
			},
			{
				class: 'text-center',
				headerName: 'Saldo Financiado',
				field: 'saldo_financiado',
				pipe: 'currency',
				moneda: 'moneda',
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "Fecha Vencimiento",
				field: "fecha_vencimiento",
				pipe: "date",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Mora",
				field: "intereses_mora",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "IGV int.",
				field: "igv_intereses",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "Int. dev.",
				field: "intereses_devolver",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "T.C.",
				field: "tipo_cambio_monto",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Deuda",
				field: "deuda",
				pipe: "currency",
				moneda: "moneda_pago",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "Abono",
				field: "pago",
				pipe: "currency",
				moneda: "moneda_pago",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
		];

		let headerCuentasCobrarTable = [
			{
				headerName: "N°",
				field: "id",
				sortable: true,
				pipe: 'indexcol'
			},
			{
				class: "text-center",
				headerName: "Producto",
				field: "tipo_producto_descripcion",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Operación",
				field: "operacion",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Concepto",
				field: "concepto_descripcion",
			},
			{
				headerName: "Nro°",
				field: "id",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Monto",
				field: "monto",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "IGV",
				field: "igv",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Total",
				field: "total",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Saldo",
				field: "saldo",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "T.C.",
				field: "tipo_cambio_monto",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Deuda",
				field: "deuda",
				pipe: "currency",
				moneda: "moneda_pago",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "Abono",
				field: "pago",
				pipe: "currency",
				moneda: "moneda_pago",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
		];

		let headerLeasing = [
			{
				headerName: "N°",
				field: "id",
				sortable: true,
				pipe: 'indexcol'
			},
			{
				class: "text-center",
				headerName: "Contrato",
				field: "codigo",
			},
			{
				class: "text-center",
				headerName: "Monto",
				field: "valor_bruto_total",
				pipe: "currency_detail",
				sortable: true,
				moneda_detail: ''
			}
		]

		this.headerCuentasCobrarTable = headerCuentasCobrarTable;

		this.headerDocumentsCarteraTable = headerDocumentsCarteraTable;

		this.headerLeasing = headerLeasing;
	}

	actualizarAbonoLeasing(){

		//AQUI ACTUALIZO EL VALOR DE LEASING
		if( this.formularioLeasing.invalid ){
			return;
		}
	
		this.abonos_leasing = this.formularioLeasing;
		this.crearSimulacion()

		this.liqAdelanto = new Intl.NumberFormat( 'en-US',{ 
			style: "currency",
			currency: 'USD'
		} ).format( this.formularioLeasing.controls.monto.value ).substr(1);

		//Todo: ocultar modal
		this.modalService.dismissAll();
	}

	async obtenerDocumentosCartera(
		page = 1,
		page_size = 100,
		beneficiario = "",
		deudor = "",
		estado__in = ""
	) {
		return await this.recaudacionService
			.obtenerDocumentosCartera(beneficiario, deudor, estado__in, '', '' ,page, page_size)
			.then((documents: any) => {
				const { results, count, num_pages } = documents;

				let tablePages = [];
				let filteredList = results;

				this.documentsCarteraList = results;
				this.pageDocumentsCarteraTableTotal = count;

				for (let index = 0; index < num_pages; index++) {
					tablePages.push(index);
				}
				this.pageDocumentsCarteraTableActual = page;
				this.pageDocumentsCarteraTablePerPages = page_size;
				this.pageDocumentsCarteraTablePages = tablePages;
			});
	}

	//TODO: modal Leasing
	async showAbonoLeasing(indexLiquidacion, liquidacion){

		if( liquidacion[0] && liquidacion[0].abono_leasing == '0.00' && ( liquidacion[1] && liquidacion[1]?.abono_leasing === '0.00' ) ){

			this.formularioLeasing.controls.monto.setValue(this.montoLeasing);

		} else if( liquidacion[0] && liquidacion[0].abono_leasing == '0.00' && (!liquidacion[1] ) ){

			this.formularioLeasing.controls.monto.setValue(this.montoLeasing);

		} else if( liquidacion[0] && liquidacion[0].abono_leasing != '0.00' && ( liquidacion[1] && liquidacion[1]?.abono_leasing === '0.00' ) ){

			this.formularioLeasing.controls.monto.setValue(liquidacion[indexLiquidacion].abono_leasing);

		} else if( liquidacion[0] && liquidacion[0].abono_leasing != '0.00' && ( !liquidacion[1] )){

			this.formularioLeasing.controls.monto.setValue(liquidacion[indexLiquidacion].abono_leasing);

		} else if( liquidacion[0] && liquidacion[0].abono_leasing != '0.00' && ( liquidacion[1] && liquidacion[1]?.abono_leasing !== '0.00' )){
			this.formularioLeasing.controls.monto.setValue(this.liqAdelanto);
			this.showAdelanto = true;
			this.montoDelanto = liquidacion[1] && liquidacion[1]?.abono_leasing;

		} else if( liquidacion[0] && liquidacion[0].abono_leasing === '0.00' && ( liquidacion[1] && liquidacion[1]?.abono_leasing !== '0.00' )){
			this.formularioLeasing.controls.monto.setValue(this.liqAdelanto);
			this.showAdelanto = true;
			this.montoDelanto = liquidacion[1] && liquidacion[1]?.abono_leasing;
		}

		this.formularioLeasing.controls.referencia.setValue( liquidacion[indexLiquidacion].abonos_leasing[0].referencia );
		this.formularioLeasing.controls.motivo.setValue(liquidacion[indexLiquidacion].motivo_modificacion )

		const ruc = this.operacion.beneficiario_.ruc;

		const modalLeasing = this.modalService.open(
			this.e_abonoLeasing, {
				size: "lg"
			}
		)

		await this.factoring.getCobranzaCliente(ruc)
				.then( (resp:any)=>{

				if( resp['results'].length > 0 ){

					this.factoring.getCobranzaContrato( resp['results'][0].id )
						.then( ( resp:any )=>{
	
							this.documentsLeasing = resp.results
							this.headerLeasing[2].moneda_detail = resp.results[0]['tipo_moneda'] ? resp.results[0]['tipo_moneda'] : '';
	
					})
	
				}else{

					this.documentsLeasing = [];

				}

			})

	}

	selectedItem(row: any) {
		const id = row.id;

		if (this.documentsCarteraSelectionList.indexOf(id) === -1)
			this.documentsCarteraSelectionList.push(row);
		else
			this.documentsCarteraSelectionList = this.documentsCarteraSelectionList.filter(
				(item) => item !== id
			);
	}

	get activeButton() {
		if (this.liquidacion && this.liquidacion.liquidado) {
			return true;
		} else {
			return false;
		}
	}

	get desembolso() {
		if (!this.liquidacion)
			return 0;
		else
			return this.liquidacion.monto_desembolso
	}

	get noFinanciado() {
		if (!this.liquidacion)
			return 0;

		let financiado = this.liquidacion.liquidaciones.reduce((acc, act) => {
			return parseFloat(acc) + parseFloat(act.monto_financiado);
		}, 0);

		let amount = this.liquidacion.neto_pagar - financiado;
		return amount.toFixed(2);
	}

	findInArray(arr, search) {
		return arr.find(item => item.id == search.id);
	}

	commentDate(fecha) {

		if (!fecha) {
			return
		}

		let yy = fecha.split('-')[0];
		let mm = fecha.split('-')[1];
		let dd = fecha.split('-')[2];
		dd = dd.split('T')[0]

		let hora = Number(fecha.split('T')[1].split(':')[0])
		let momento = (hora >= 12) ? "PM" : "AM";
		hora = (hora >= 12) ? hora - 12 : hora
		let minuto = fecha.split('T')[1].split(':')[1]
		let hour = `  ${hora}:${minuto} ${momento}`

		let date = `${dd}/${mm}/${yy} ${hour}`;
		return date
	}

	imprimeComprobante() {
		let data = {
			operacion: this.operacion,
			idOperacion: this.idOperacion,
		}
		this.localService.setJsonValue("RLiquidacion", data);
		//  this.router.navigate(["/reporte/liquidacion", { 'data': JSON.stringify(data) }]);
		window.open("#/reporte/liquidacion/", "_blank");

	}

	async toggleRevisado(e, liquidacion) {
		let data = {
			revisado: e,
			id: liquidacion.id,
		}

		// if(this.operacion.revisado){
		// 	this.operacion.revisado = false
		// 	data.revisado           = false
		// } else {
		// 	this.operacion.revisado = true
		// 	data.revisado           = true
		// }

		if (data.revisado && this.tieneDeudaVencida) {
			let res = await this.openModalExcepcionDeudaLeasing()
			if (res) {
				if (!this.liquidacion['excepcion_comite']) {
					res = await this.factoring.saveExcepcionDeudaLeasing(this.liquidacion.id, res).toPromise()
					if (res) {
						this.liquidacion['excepcion_sustento'] = res['excepcion_sustento']
						this.liquidacion['excepcion_usuario'] = res['excepcion_usuario']
						this.liquidacion['excepcion_fecha'] = res['excepcion_fecha']
						this.liquidacion['excepcion_tipo'] = res['excepcion_tipo']
					}
				}
			}
			if (!res) {
				setTimeout(() => { liquidacion.revisado = !data.revisado }, 100) // setTimeout para ir por otro proceso y revertir el cambio
				return
			}
		}

		this.propuestaService.cambiaEstadoRevisado(data.id, data).then((res: any) => {
			this.ngOnInit();
		})
	}

	permisosRevisado() {
		let existeOperacion = !!this.operacion
		let perfilAdmitido = (
			this.operacion.actualJefeOperaciones &&
			this.operacion.isAnalistaOperacion(this.authService.user)
		) || (
				this.operacion.actualJefeOperaciones &&
				this.authService.user.isJefeDeOperaciones
			) || (
				this.operacion.actualJefeOperaciones &&
				(this.authService.user.isGerenteComercial || this.authService.user.isGerenteGeneral)
			)

		return !(existeOperacion && perfilAdmitido)
	}

	cobrar(conecptoId: number): boolean {
		if (!this.liquidacion && this.liquidacion.liquidacion) return false;

		return this.liquidacion.grupos_no_cobrar.includes(conecptoId);
	}

	grupoNoCobrar(e, conecptoId) {
		if (!this.liquidacion && this.liquidacion.liquidacion) return;

		console.log('Grupo no cobrar', e);
		if (e) {
			this.liquidacion.grupos_no_cobrar.push(conecptoId);
		} else {
			this.liquidacion.grupos_no_cobrar = this.liquidacion.grupos_no_cobrar.filter(i => i != conecptoId);
		}
		this.crearSimulacion();
	}

	mostrarDesglose() {
		let { detalle } = this.operacion
		const modalRef = this.modalService.open(DesglosesComponent, {
			size: "lg",
		});

		modalRef.componentInstance.desgloses = this.desglosesLineaPropuesta
		modalRef.componentInstance.operacion = this.operacion.id;
		modalRef.componentInstance.operacionBody = this.operacion;
		modalRef.componentInstance.propuesta = 3;
		modalRef.componentInstance.readonly = true;
		modalRef.componentInstance.readonly_ = true;
		modalRef.componentInstance.deleteEvent.subscribe((val) => {
			this.obtenerDesgloses()
		})

		modalRef.result.then((result) => {
			if (result) {

			}
		});

	}

	obtenerDesgloses() {
		this.modalService.dismissAll()
		let desgloses = this.serviciosComunes.getDesglose(this.operacion.id, 3)
		desgloses.then((res: any) => {
			this.desglosesLineaPropuesta = res['desgloses']
			this.mostrarDesglose()
		});
	}

	showAdelantos(index): boolean {
		// console.log(this.liquidacion.liquidaciones.length, index);
		// return true;
		if (this.liquidacion.liquidaciones.length === 1) {
			return true;
		}

		if (this.liquidacion.liquidaciones.length > 1 && index === 2) {
			return true;
		} else {
			return false;
		}
	}

	liquidacionTitle(index: number) {
		if (this.liquidacion.liquidaciones.length === 1) {
			return 'Liquidación Final';
		}

		switch (index) {
			case 0:
				return 'Liquidación Saldo';
				break;
			case 1:
				return 'Liquidación Adelanto';
				break;
			case 2:
				return 'Liquidación Final';
				break;
		}
		
	}

	addRecaudacion(i) {
		const modalRef = this.modalService.open(AddRecaudacionModalComponent, {
		  ariaLabelledBy: "modal-basic-title",
		  size: "xl",
		  beforeDismiss() {
			return false
		  },
		});
		let operacion = {}
		Object.entries(this.liquidacion).filter(e => ['id', 'cliente', 'cliente_ruc', 'cliente_nombre'].includes(e[0])).forEach(([k, v]) => operacion[k] = v)
		operacion['beneficiario'] = this.operacion?.beneficiario
		operacion['beneficiario_ruc'] = this.operacion?.beneficiario_?.ruc
		operacion['beneficiario_nombre'] = this.operacion?.beneficiario_?.nombre
		modalRef.componentInstance.liquidacion = Object.assign({}, this.liquidacion.liquidaciones[i], {operacion})
		modalRef.componentInstance.recaudacionId = this.liquidacion.liquidaciones[i].recaudacion

		modalRef.result.then(result => {}).catch(reason => {}).then(() => {
			let recaudacion = modalRef.componentInstance.recaudacion
			if (recaudacion && recaudacion.id) {
				this.crearSimulacion()
			}
		})
	}

	openModalExcepcionDeudaLeasing() {
		const modal = this.modalService.open(ExcepcionDeudaLeasingComponent, {
		  size: "md",
		})
		modal.componentInstance.value = {
			'excepcion_sustento': this.liquidacion['excepcion_sustento'],
			'excepcion_usuario': this.liquidacion['excepcion_usuario'],
			'excepcion_fecha': this.liquidacion['excepcion_fecha'],
			'excepcion_tipo': this.liquidacion['excepcion_tipo'],
		}
		modal.componentInstance.soloLectura = this.liquidacion['excepcion_comite']
		return modal.result.then(
		  (result) => result,
		  (reason) => null,
		)
	}
}
