import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCloudUploadAlt, faFileDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { AdjuntarFileComponent } from 'app/shared/adjuntar-file/adjuntar-file.component';

@Component({
  selector: 'app-add-archivo',
  templateUrl: './add-archivo.component.html',
  styleUrls: ['./add-archivo.component.css']
})
export class AddArchivoComponent implements OnInit {
  user: string;
  @Input() entidad = 'cliente';
  @Input() entidadId = 0;
  myForm                  : FormGroup;
  faFileDownload = faFileDownload;
  faCloudUploadAlt = faCloudUploadAlt;
  faTimes=faTimes;

  constructor(
    public modalService: NgbModal,
    public activeModal : NgbActiveModal,
    public formbuild   : FormBuilder,
    public adjuntar    : AdjuntarFileComponent,
		public authService   : AuthService,
  ) {
   }

  ngOnInit(): void {
    this.initForm()
  }

  initForm(){
    this.myForm = this.formbuild.group({
      nombre          : [null, [Validators.required]],
      [this.entidad]  : [this.entidadId, [Validators.required]],
      archivo         : [null, [Validators.required]],
      usuario_creacion: [this.authService.user.nombreCompleto, [Validators.required]],
    });

    this.adjuntar.fileAdjunto.subscribe((res)=>{
      this.myForm.get('archivo').setValue(res)
      let valor = this.myForm.get('nombre').value

      if(!valor || valor == '' || valor == ' '){
        this.myForm.get('nombre').setValue(new Date().getTime())
      }
      this.activeModal.close(this.myForm.value)
    })
  }

  adjuntarArchivo(){
    this.adjuntar.crearAdjuntar()
  }

}
