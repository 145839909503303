import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { CompensacionesModalConfirmarComponent } from '../compensaciones-modal-confirmar/compensaciones-modal-confirmar.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'app/core/services/toast/toast.service';
import { ExcedentesService } from 'app/core/services/excedentes/excedentes.service';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { MonedaPipe } from 'app/core/pipes/moneda.pipe';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-compensaciones-modal',
  templateUrl: './compensaciones-modal.component.html',
  styleUrls: ['./compensaciones-modal.component.css'],
  providers : [MonedaPipe]
})
export class CompensacionesModalComponent implements OnInit {
  faTimes = faTimes
  @ViewChild('modalCompensaciones', { static: false }) public modalCompensaciones;
  @ViewChild(CompensacionesModalConfirmarComponent) _compensacionesModalConfirmar: CompensacionesModalConfirmarComponent;

  set compensacionesModalConfirmar(value) {
    this._compensacionesModalConfirmar = value;
  }

  get compensacionesModalConfirmar() {
    return this._compensacionesModalConfirmar
  }


  constructor(
    public modalService: NgbModal,
    public formbuild: FormBuilder,
    private factoringService: FactoringService,
    public excedentesServicio: ExcedentesService,
    public authService: AuthService,
    public spinner: NgxSpinnerService,
    public toast     : ToastService,
    public functions: SharedFunctions,
  ) { }

  public pageTableTotal: number;
  public pageTableActual: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public active: number = 1
  public CompensacionesList: any[] = []
  public headerCompensaciones: any[] = []
  public headersCXC: any[] = []
  public headersDocs: any[] = []
  public deudores: any = []
  public loader: boolean = false;
  public todoSelect: boolean = false;
  public page: number = 0;
  public empresaSelect: number = 1
  public clientesDevolver: any[] = [];
  public checkOn: boolean = true;
  public checkAll: boolean = true
  public filtroRucNombre: string = '';
  public tipoCambio = null;

  ngOnInit(): void {
    this.active = 1
    this.initVars()
  }

  registrarCompensaciones() {
    let alertas = []

    let selecciones = this.deudores.reduce((acc,item)=>{
      if(item.preselectedcxc.length >0 || item.preselecteddocs.length >0){
        item.empresa = this.empresaSelect
        acc.push(item)
      }
      if (item.preselected.length > 0 && item.preselectedcxc.length+item.preselecteddocs.length == 0) {
        alertas.push(`${item.nombre}: Debe seleccionar almenos una CxC o Doc de Cartera.`)
      }
      if (item.preselected.length == 0 && item.preselectedcxc.length+item.preselecteddocs.length > 0) {
        alertas.push(`${item.nombre}: Debe seleccionar almenos un Excedente para aplicar.`)
      }
      return acc
    },[])

    if (alertas.length > 0) {
      this.toast.warning(alertas.join('\n'))
      return false
    }
    if (selecciones.length == 0) {
      this.toast.warning('Debe seleccionar almenos un Excedente para aplicar')
      return false
    }
    
    this.lanzarModalConfirmacion(false, selecciones)
  }

  lanzarModalConfirmacion(validador, resultadoGrupos:any) {
    this.compensacionesModalConfirmar.tipoCambio = this.tipoCambio;
    if (validador) {
      const modalRef = this.modalService.open(ConfirmModalComponent, {});
      let mensaje = ``
      mensaje += `<br><span class='message'>El monto de alguno de los excedentes es menor a <strong>${20} Dólares </strong> <br> ¿Está seguro de que desea aprobar la operación?</strong></span>`
      modalRef.componentInstance.title = "El monto es menor al mínimo";
      modalRef.componentInstance.message = mensaje;
      modalRef.componentInstance.messageHTML = true;
      modalRef.result.then((result) => {
        if (result) {
          this.compensacionesModalConfirmar.displayModal(resultadoGrupos)
        }
      });
    } else {
      this.compensacionesModalConfirmar.displayModal(resultadoGrupos)
    }
  }

  toggleCheckAll(row) {
    
    if (row.target.checked) {
      this.deudores.forEach((item) => {
        let data = { target: { checked: true } }
        item.checked = true
        this.toggleCheck(data, item)
      })
    } else {
      this.deudores.forEach((item) => {
        let data = { target: { checked: false } }
        item.checked = true
        this.toggleCheck(data, item)

      })
    }
  }

  toggleCheckAllRows(evento){
    let { checked  } = evento.target
    this.deudores.forEach(deudor => {
      this.toggleCheckAllExc( evento, deudor)

      this.toggleCheckAllCxc( evento, deudor)
      setTimeout(()=>{
        this.toggleCheckAllDocs( evento, deudor)
      },100)
      

    });
  }

  selectTest() {
    let tipo = this.active === 1 ? 'cliente' : 'deudor'
    this.goToPage(tipo, 1)
    this.initVars()
    this.deudores = []
  }

  toggleCheck(event, deudor) {
    deudor.checked = event.target.checked
    let newCliente = this.clientesDevolver.filter(x => x.id === deudor.id)[0]

    if (event.target.checked) {
      deudor.preselected = deudor.excedentes.map(({id}) =>  id)
    } else {
      deudor.preselected = []
    }
  }

  formatCurrency(row, moneda) {
    if (!row) {
      return 0
    }

    if (moneda == 1) {
      let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(row))
      return `S/ ${number}`
    }
    else {
      return `$ ${Number(row).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
    }

  }

  formatDate(date) {
    let newDate = date.split('-').reverse()
    return `${newDate[0]} / ${newDate[1]} / ${newDate[2]}`
  }

  pageChange(tipo, nav) {
    this.deudores = []
    this.initVars()
    setTimeout(() => this.goToPage(tipo, nav === 'nav' ? 1 : this.page), 100)
  }

  goToPage(tipo, page) {
    this.loader = true
    let per_page = 10
    this.excedentesServicio.obtenerCompensaciones(
      page,
      per_page,
      this.empresaSelect,
      tipo === 'cliente' ? 2 : 1,
      this.filtroRucNombre
    ).then(res => {
      this.loadTable(res);

    })
      .catch(error => {

      });

  }

  loadTable(data) {
    this.deudores = data.results.map((element, index) => ({
      index          : index + 1, 
      preselected    : [],
      preselectedcxc : [], 
      preselecteddocs: [],
      totalExc       : {soles:0,dolares:0},
      totalCXC       : {soles:0,dolares:0},
      totalDocs      : {soles:0,dolares:0},
      checkexc       : false,
      checkcxc       : false,
      checkdocs      : false,
      cliente        : this.active == 1 ?  element.id : null,
      aceptante      : this.active == 2 ?  element.id : null,
      sumable        : true,
      alertaCxc      : false,
      alertaDocs     : false,
      ...element, 
    }));
    this.page              = data.page_number;
    this.pageTableTotal    = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages    = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }

    this.clientesDevolver = data.results.map((element, index) => ({
      id                : element.id,
      nombre            : element.nombre,
      ruc               : element.ruc,
      cliente           : this.active === 1 ? element.id : null,
      aceptante         : this.active !== 1 ? element.id : null,
      empresa           : this.empresaSelect,
      excedente         : element.excedentes,
      cuentas_cobrar    : [],
      documentos_cartera: [],
      checked           : false,
    }));
    this.loader = false
  }

  openModal() {
    this.checkOn = true
    this.checkAll = false
    this.goToPage(this.active === 1 ? 'cliente' : 'deudor', 1)

    // TODO: El tipo de cambio debe ser editable por el usuario, se verá en mejora de compensaciones
    this.factoringService.obtenerTipoDeCambio().then(data => {
      this.tipoCambio = Number(data.contable)
    }).catch(res => console.log(res))

    this.modalService.open(this.modalCompensaciones, {
      size: 'xl',
      windowClass: "modalSizeXXL",
    }).result.then((response: any) => {
      // console.log(response)
    }).catch(() => {})
  }

  initVars() {
    let columns = [
      {
        class: "text-center",
        headerName: "N°",
        field: "numero_documento",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "cliente_nombre",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "cliente_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "aceptante_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fch Recaudo",
        field: "fecha",
        pipe: 'date',
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "excedente",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Saldo",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Operación",
        field: "operacion",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aplicación",
        field: "recaudacion",
        sortable: true,
      },
    ];

    let columnsCXC = [
      {
        headerName: "Nro°",
        field: "id",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "beneficiario_nombre",

      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "beneficiario_ruc",

      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "deudor_nombre",

      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "deudor_ruc",
      },
      {
        class: "text-center",
        headerName: "Concepto",
        field: "concepto_descripcion",
      },
      {
        class: "text-center",
        headerName: "Producto",
        field: "tipo_producto_descripcion",
      },
      {
        headerName: "Operación",
        field: "operacion",
        class: "text-center",
        sortable: true,
      },
      {
        headerName: "Fecha",
        field: "fecha",
        class: "text-center",
        pipe: 'date',
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "IGV",
        field: "igv",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Total",
        field: "total",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Saldo",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      // {
      //   class: "text-center",
      //   headerName: "Fecha Liquidacion",
      //   field: "liquidacion_fecha",
      //   pipe: "date",
      //   sortable: true,
      // },
    ];

    let columnsDocs = [
      {
        class: "text-center",
        headerName: "Cliente",
        field: "beneficiario_nombre",

      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "beneficiario_ruc",

      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "deudor_nombre",

      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "deudor_ruc",
      },
      {
        class: "text-center",
        headerName: "Producto",
        field: "tipo_producto_descripcion",
      },
      {
        class: "text-center",
        headerName: "Operacion",
        field: "operacion",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Nro Documento",
        field: "numero_documento",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Fch. Vcto",
        field: "fecha_vencimiento",
        pipe: "date",
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto_neto",
        pipe: "currency",
        moneda: "moneda",
      },
      {
        class: "text-center",
        headerName: "Saldo Financiado",
        field: "saldo_financiado",
        pipe: "currency",
        moneda: "moneda",
      },
      {
        class: "text-center",
        headerName: "Saldo",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
      },
    ];
    
    if(this.active == 1){
      columns.splice(2,1)
      columns.splice(2,1)
      columnsCXC.splice(1,1)
      columnsCXC.splice(1,1)
      columnsDocs.splice(0,1)
      columnsDocs.splice(0,1)
    }

    if(this.active == 2){
      columns.splice(4,1)
      columns.splice(4,1)
      columnsCXC.splice(3,1)
      columnsCXC.splice(3,1)
      columnsDocs.splice(2,1)
      columnsDocs.splice(2,1)
    }

    this.headerCompensaciones = columns;
    this.headersCXC           = columnsCXC
    this.headersDocs          = columnsDocs
  }

  toggleCheckAllExc(evento, deudor) {
    if(evento.target.checked){
      deudor.preselected = deudor.excedentes.map(({id}) => id)
      let sumarTotales = deudor.excedentes.reduce((acc,item)=>{
        if (item.moneda == 2) {
          acc.dolares += parseFloat(item.saldo)
          acc.soles += Math.round(item.saldo * this.tipoCambio * 100) / 100
        } else {
          acc.soles += parseFloat(item.saldo)
          acc.dolares += Math.round(item.saldo / this.tipoCambio * 100) / 100
        }
        return acc
      },{soles: 0, dolares: 0})
      deudor.totalExc.soles = Number(sumarTotales.soles).toFixed(2)
      deudor.totalExc.dolares = Number(sumarTotales.dolares).toFixed(2)
    } else {
      deudor.preselected = []
      deudor.totalExc.soles = Number(0).toFixed(2)
      deudor.totalExc.dolares = Number(0).toFixed(2)
    }

    this.evalExcedenteCheck(deudor)
    this.evalAlertaSuperior(deudor)
  }

  toggleCheckAllCxc(row, deudor) {
    if(row.target.checked){
      let acumulador = {soles: 0, dolares: 0, preseleccion: [], index: 0}
      deudor.preselectedcxc = []
      let valorTotalExc = Number(deudor.totalExc.soles)

      while(
        (valorTotalExc > (Number(acumulador.soles) + Number(deudor.totalDocs.soles))) && 
        (acumulador.index < deudor.cuentas_cobrar.length)){
        let item         =  deudor.cuentas_cobrar[acumulador.index]
        acumulador.preseleccion.push(item)
        if (item.moneda == 2) {
          acumulador.dolares += parseFloat(item.saldo)
          acumulador.soles += Math.round(item.saldo * this.tipoCambio * 100) / 100
        } else {
          acumulador.soles += parseFloat(item.saldo)
          acumulador.dolares += Math.round(item.saldo / this.tipoCambio * 100) / 100
        }
        acumulador.index++
      }
      deudor.totalCXC.soles = Number(acumulador.soles).toFixed(2)
      deudor.totalCXC.dolares = Number(acumulador.dolares).toFixed(2)
      deudor.preselectedcxc = acumulador.preseleccion.map(({id}) => id)
    } else {
      deudor.preselectedcxc = []
      deudor.totalCXC.soles = Number(0).toFixed(2)
      deudor.totalCXC.dolares = Number(0).toFixed(2)
    }
    
    this.evalCXCCheck(deudor)
    this.evalAlertaSuperior(deudor)
  }

  toggleCheckAllDocs(row, deudor) {
    if(row.target.checked){
      let acumulador = {soles: 0, dolares: 0, preseleccion: [], index: 0}
      deudor.preselecteddocs = []
      let valorTotalExc = Number(deudor.totalExc.soles)
      while(( 
        (valorTotalExc > (Number(acumulador.soles) + Number(deudor.totalCXC.soles))) && 
        (acumulador.index < deudor.documentos_cartera.length))){
        let item         =  deudor.documentos_cartera[acumulador.index]
        acumulador.preseleccion.push(item)
        if (item.moneda == 2) {
          acumulador.dolares += parseFloat(item.saldo)
          acumulador.soles += Math.round(item.saldo * this.tipoCambio * 100) / 100
        } else {
          acumulador.soles += parseFloat(item.saldo)
          acumulador.dolares += Math.round(item.saldo / this.tipoCambio * 100) / 100
        }
        acumulador.index++
      }

      deudor.totalDocs.soles = Number(acumulador.soles).toFixed(2)
      deudor.totalDocs.dolares = Number(acumulador.dolares).toFixed(2)
      deudor.preselecteddocs = acumulador.preseleccion.map(({id}) => id)
    } else {
      deudor.preselecteddocs = []
      deudor.totalDocs.soles = Number(0).toFixed(2)
      deudor.totalDocs.dolares = Number(0).toFixed(2)
    }

    this.evalDocsCheck(deudor)
    this.evalAlertaSuperior(deudor)
  }

  checkEventTable(rows, excedenteOwner) {
    let ids = rows.map( item => item.id )
    excedenteOwner.preselected = ids

    if(excedenteOwner.excedentes.length == excedenteOwner.preselected.length){
      excedenteOwner.checked = true
    } else {
      excedenteOwner.checked = false
    }

    let sumarTotales = rows.reduce((acc,item)=>{
      if (item.moneda == 2) {
        acc.dolares += parseFloat(item.saldo)
        acc.soles += Math.round(item.saldo * this.tipoCambio * 100) / 100
      } else {
        acc.soles += parseFloat(item.saldo)
        acc.dolares += Math.round(item.saldo / this.tipoCambio * 100) / 100
      }
      return acc
    },{soles: 0, dolares: 0})
    excedenteOwner.totalExc.soles = Number(sumarTotales.soles).toFixed(2)
    excedenteOwner.totalExc.dolares = Number(sumarTotales.dolares).toFixed(2)

    this.evalExcedenteCheck(excedenteOwner)
    this.evalAlertaSuperior(excedenteOwner)
  }

  evalExcedenteCheck(owner){
    if(owner.excedentes.length == owner.preselected.length){
      owner.checkexc = true
    } else {
      owner.checkexc = false
    }
  }

  evalCXCCheck(owner){
    if(owner.cuentas_cobrar.length == owner.preselectedcxc.length){
      owner.checkcxc = true
    } else {
      owner.checkcxc = false
    }
  }

  evalDocsCheck(owner){
    if(owner.documentos_cartera.length == owner.preselecteddocs.length){
      owner.checkdocs = true
    } else {
      owner.checkdocs = false
    }
  }

  evalAlertaSuperior(owner) {
    let saldoExcedentesSoles   = parseFloat(owner.totalExc.soles)
    let saldoExcedentesDolares = parseFloat(owner.totalExc.dolares)
    let totalCXCSoles          = parseFloat(owner.totalCXC.soles)
    let totalCXCDolares        = parseFloat(owner.totalCXC.dolares)
    let totalDocSoles          = parseFloat(owner.totalDocs.soles)
    let totalDocDolares        = parseFloat(owner.totalDocs.dolares)

    owner.alertaCxc = owner.preselectedcxc.length > 0 && (saldoExcedentesDolares < totalCXCDolares || saldoExcedentesSoles < totalCXCSoles)
    owner.alertaDocs = owner.preselecteddocs.length > 0 && (saldoExcedentesDolares < (totalCXCDolares+totalDocDolares) || saldoExcedentesSoles < (totalCXCSoles+totalDocSoles))
  }

  checkEventTableCXC(rows, excedenteOwner) {
    let ids = rows.map( item => item.id )
    excedenteOwner.preselectedcxc = ids

    excedenteOwner.checked = excedenteOwner.cuentas_cobrar.length == excedenteOwner.preselectedcxc.length
    let sumarTotales = rows.reduce((acc,item)=>{
      if (item.moneda == 2) {
        acc.dolares += parseFloat(item.saldo)
        acc.soles += Math.round(item.saldo * this.tipoCambio * 100) / 100
      } else {
        acc.soles += parseFloat(item.saldo)
        acc.dolares += Math.round(item.saldo / this.tipoCambio * 100) / 100
      }
      return acc
    },{soles: 0, dolares: 0})
    excedenteOwner.totalCXC.soles = Number(sumarTotales.soles).toFixed(2)
    excedenteOwner.totalCXC.dolares = Number(sumarTotales.dolares).toFixed(2)

    this.evalCXCCheck(excedenteOwner)
    this.evalAlertaSuperior(excedenteOwner)
  }

  checkEventTableDocs(rows, excedenteOwner) {
    let ids = rows.map( item => item.id )
    excedenteOwner.preselecteddocs = ids

    if(excedenteOwner.cuentas_cobrar.length == excedenteOwner.preselecteddocs.length){
      excedenteOwner.checked = true
    } else {
      excedenteOwner.checked = false
    }

    let sumarTotales = rows.reduce((acc,item)=>{
      if (item.moneda == 2) {
        acc.dolares += parseFloat(item.saldo)
        acc.soles += Math.round(item.saldo * this.tipoCambio * 100) / 100
      } else {
        acc.soles += parseFloat(item.saldo)
        acc.dolares += Math.round(item.saldo / this.tipoCambio * 100) / 100
      }
      return acc
    },{soles: 0, dolares: 0})
    excedenteOwner.totalDocs.soles = Number(sumarTotales.soles).toFixed(2)
    excedenteOwner.totalDocs.dolares = Number(sumarTotales.dolares).toFixed(2)

    this.evalDocsCheck(excedenteOwner)
    this.evalAlertaSuperior(excedenteOwner)
  }

  evalAlert(deudor, preselect){
    return false
  }
}
