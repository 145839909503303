import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ClientesService } from "app/core/services/clientes/clientes.service";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { ConfirmModalComponent } from "app/shared/utils/confirm-modal/confirm-modal.component";
import { AddRelationClientComponent } from "app/modules/clientes/modals/add-relation-client/add-relation-client.component";

@Component({
  selector: " app-business-information-relations-client",
  templateUrl: "./relations-client.component.html",
  styleUrls: ["./relations-client.component.css"],
})
export class RelationsClientComponent implements OnInit {
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public searchstring: string = "";

  @Input() clienteId = 0;
  @Input() readonly = false;

  constructor(
    private clientesService: ClientesService,
    public modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.obtenerRelacionesClientes();
  }

  obtenerRelacionesClientes(clienteId = this.clienteId.toString(),page = 1, page_size = 10) {
    this.clientesService
      .obtenerRelacionesClientes(clienteId, page, page_size)
      .then((res) => {
        this.initVars();
        this.loadTable(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadTable(data) {
    this.rowData = data.results.map((el: any, i: number) => ({
      ...el,
      index: i + 1,
    }));
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars() {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Razón Social",
        field: "nombre",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "ruc",
        sortable: true,
      },
      {
        headerName: "Producto o Servicio",
        field: "producto_servicio",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Tiempo de Relación Comercial",
        field: "tiempo_relacion",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Facturación Anual",
        field: "facturacion_anual",
        sortable: true,
        pipe: "currency_detail",
        moneda_detail: 1,
      },
      {
        headerName: "Facturación Mensual",
        field: "facturacion_mensual",
        class: "text-center",
        pipe: "currency_detail",
        moneda_detail: 1,
      },
      {
        headerName: "% Soles",
        field: "porcentaje_soles",
        class: "text-center",
      },
      {
        headerName: "% Dolares",
        field: "porcentaje_dolares",
        class: "text-center",
      },
      {
        headerName: "% Ventas a Crédito",
        field: "ventas_credito_porcentaje",
        class: "text-center",
      },
      {
        headerName: "Documento de Pago",
        field: "documento_pago",
        class: "text-center",
      },
      {
        headerName: "Plazo Promedio",
        field: "plazo_promedio",
        class: "text-center",
      },
      {
        headerName: "Teléfono",
        field: "telefono",
        class: "text-center",
      },
      {
        headerName: "Persona de Contacto",
        field: "persona_contacto",
        class: "text-center",
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {
    this.obtenerRelacionesClientes(page, per_page);
  }

  tableEditEvent(client) {
    const modalRef = this.modalService.open(AddRelationClientComponent, {
      size: "lg",
    });

    modalRef.componentInstance.clienteId = this.clienteId;
    modalRef.componentInstance.relacionId = client.id;

    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {
        console.log(reason);
      }
    );

    modalRef.componentInstance.successEmit.subscribe(
      (result) => {
        console.log(result);
        this.obtenerRelacionesClientes();
      },
      (error) => {}
    );
  }

  destroyContact(client) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {});

    modalRef.componentInstance.title = "Remover Accionista";
    modalRef.componentInstance.message =
      "¿Seguro que desea remover el accionista?";

    modalRef.result.then(
      (result) => {
        if (result) {
          this.clientesService
            .elimiarRelacionCliente(client.id)
            .then((result) => {
              this.obtenerRelacionesClientes();
            })
            .catch((error) => {
              console.error(error);
            });
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  openModal() {
    const modalRef = this.modalService.open(AddRelationClientComponent, {
      size: "lg",
    });

    modalRef.componentInstance.clienteId = this.clienteId;

    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {
        console.log(reason);
      }
    );

    modalRef.componentInstance.successEmit.subscribe(
      (result) => {
        console.log(result);
        this.obtenerRelacionesClientes();
      },
      (error) => {}
    );
  }
}
