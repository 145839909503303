import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExcedentesRoutingModule } from './excedentes-routing.module';
import { DevolucionesComponent } from './pages/devoluciones/devoluciones.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ArchwizardModule } from 'angular-archwizard';
import { SharedModule } from 'app/shared/shared.module';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { UiSwitchModule } from 'ngx-ui-switch';
import { DevolucionModalComponent } from './componentes/devolucion-modal/devolucion-modal.component';
import { ExcedentesModalComponent } from './componentes/excedentes-modal/excedentes-modal.component';
import { ExcedentesComponent } from './page/excedentes/excedentes.component';
import { ShowExcedentesModalComponent } from './componentes/show-excedentes-modal/show-excedentes-modal.component';
import { CompensacionesModalComponent } from './componentes/compensaciones-modal/compensaciones-modal.component';
import { CompensacionesModalConfirmarComponent } from './componentes/compensaciones-modal-confirmar/compensaciones-modal-confirmar.component';
import { DevolucionesModalComponent } from './componentes/devoluciones-modal/devoluciones-modal.component';
import { DevolucionModalConfirmarComponent } from './componentes/devolucion-modal-confirmar/devolucion-modal-confirmar.component';
import { DevolucionEditarDetalleComponent } from './componentes/devolucion-editar-detalle/devolucion-editar-detalle.component';

import { DevolucionesGeneralComponent } from './pages/devoluciones/partials/solicitudes-general/devoluciones-general.component';
import { DevolucionesVigentesComponent } from './pages/devoluciones/partials/solicitudes-vigentes/devoluciones-vigentes.component';
import { DevolucionesObservadasComponent } from './pages/devoluciones/partials/solicitudes-observadas/devoluciones-observadas.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    DevolucionesComponent, 
    DevolucionModalComponent, 
    DevolucionesVigentesComponent,
    DevolucionesObservadasComponent,
    ExcedentesModalComponent, 
    ExcedentesComponent, 
    ShowExcedentesModalComponent, 
    CompensacionesModalComponent, 
    CompensacionesModalConfirmarComponent,
    DevolucionesModalComponent, 
    DevolucionModalConfirmarComponent,
    DevolucionEditarDetalleComponent,
    DevolucionesGeneralComponent
  ],
  imports: [
    CommonModule,
    ExcedentesRoutingModule,
    FontAwesomeModule,
    SharedModule,
    JwBootstrapSwitchNg2Module,
    NgbModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    ArchwizardModule
  ]
})
export class ExcedentesModule { }
