import { Component, OnInit } from '@angular/core';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'app/core/services/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DevolucionModalComponent } from '../../componentes/devolucion-modal/devolucion-modal.component';
import { ExcedentesService } from 'app/modules/excedentes/excedentes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';

@Component({
  selector: 'app-devoluciones',
  templateUrl: './devoluciones.component.html',
  styleUrls: ['./devoluciones.component.css']
})
export class DevolucionesComponent implements OnInit {

  tableSelectable: boolean = false;
  faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  active = 1;

  refreshPending = false;
  condicion = false;
  refreshBill = false;
  refreshGeneral = false;

  constructor(
    private authService: AuthService,
    private solicitudService: ExcedentesService,
    public modalService: NgbModal,
    public factoring: FactoringService,
    ) { }
  mostrarBtn = true;

  ngOnInit(): void {
    this.aprobador()
  }

  enableSelectable() {
    this.tableSelectable = !this.tableSelectable;
  }

async changeRefresh() {
    switch (this.active) {
      case 1:
        this.refreshPending = !this.refreshPending;
        break;
      case 2:
        await this.observaciones().then(() => {
          this.refreshBill = !this.refreshBill;
        })
        break;
      case 3:
        this.refreshGeneral = !this.refreshGeneral;
    }
  }

  aprobador(){
    let aprobadores = this.solicitudService.obtenerAprobadores()
    aprobadores.then(res => {
      for (let i = 0; i < res['results'].length; i++) {
        if (res['results'][i]['responsable'] == this.authService.user.id){
          this.condicion = true;
          
        }
      }
    }).catch(error => {

    }); 
  }
// ! Validaciones
async observaciones(){
  await this.levantarObservaciones();
  await this.actualizarEstadoSolicitud();
}

async levantarObservaciones(){
    let devoluciones =  await this.solicitudService.obtenerDevolucionesObservadas();
    for (var i = 0; i < devoluciones['results'].length; i++) {
      var observaciones = devoluciones['results'][i]['solicitud_observaciones']
      
      var abono_inexistente = false
      var abono_moneda = false
      var deuda_leasing = false
      var deuda_factoring = false
      var compensacion = false

      for (var j = 0; j < observaciones.length; j++) {
        switch (observaciones[j]['id']) {
          case 1:
            abono_inexistente = true
            break;
          case 2:
            abono_moneda = true
            break;
          case 3:
            deuda_leasing = true
            break;
          case 4: 
            deuda_factoring = true
          case 5:
            compensacion = true
            break;
        }
      }
      
        await this.cuentaAbonoInexistente(devoluciones['results'][i], abono_inexistente, compensacion)
        await this.cuentaAbonoMoneda(devoluciones['results'][i], abono_moneda, compensacion)
        await this.deudaLeasing(devoluciones['results'][i], deuda_leasing)
        await this.deudaFactoring(devoluciones['results'][i], deuda_factoring)
        

      }
    }

  // ! Validaciones Unitarias

  async cuentaAbonoInexistente(data, condicion, compensacion){

    if(data['cuentas_abono'].length >= 1 && condicion && !compensacion){
      var data_observacion = {
        id_solicitud_devolucion: data.id,
        id_observacion_tipo: 1,
        resuelto: true
      };
      await this.solicitudService.resolverSolicitudObservaciones(data_observacion)
    }else if (data['cuentas_abono'].length == 0 && !condicion && !compensacion){
      await this.observacionSolicitud(data.id, 1)
    }
  }

  async cuentaAbonoMoneda(data, condicion, compensacion){
    var cuentas_ok = false
    if(data['cuentas_abono'].length > 0){
      for (let i = 0; i < data['cuentas_abono'].length; i++) {
        if(data['cuentas_abono'][i]['moneda_id'] == data['moneda']){
          cuentas_ok = true
        }
      }
      // Validacion
      if (cuentas_ok == true && condicion && !compensacion){
        var data_observacion = {
          id_solicitud_devolucion: data.id,
          id_observacion_tipo: 2,
          resuelto: true
        };
        await this.solicitudService.resolverSolicitudObservaciones(data_observacion)
      }else if (cuentas_ok == false && !condicion && !compensacion){
        await this.observacionSolicitud(data.id, 2)
      }
    }
  }

  async deudaLeasing(data, condicion){

    if(data['aceptante'])
      var deudaLeasing = await this.obtenerDeudaLeasing(data['aceptante_ruc'])
    else
      var deudaLeasing =  await this.obtenerDeudaLeasing(data['cliente_ruc'])

    if (deudaLeasing != false && condicion){
      var data_observacion = {
        id_solicitud_devolucion: data.id,
        id_observacion_tipo: 3,
        resuelto: true
      };
      await this.solicitudService.resolverSolicitudObservaciones(data_observacion)
    }else if (deudaLeasing == false && !condicion){
      await this.observacionSolicitud(data.id, 3)
    }

  }
  async obtenerDeudaLeasing(ruc) {
    let deudaLeasing = null
    let validacion_deudas = true

    deudaLeasing = await this.factoring.beneficiarioDeudaLeasing(ruc)
    
    if (deudaLeasing != null){
      if(deudaLeasing['length'] > 0){
        validacion_deudas = false
      }
    }

    return validacion_deudas
  }

  async deudaFactoring(data, condicion){

    var deudas = await this.obtenerDeuda(data['cliente'], data['aceptante'])
    if (deudas != false && condicion){
      var data_observacion = {
        id_solicitud_devolucion: data.id,
        id_observacion_tipo: 4,
        resuelto: true
      };
      await this.solicitudService.resolverSolicitudObservaciones(data_observacion)
    }else if (deudas == false && !condicion){
      await this.observacionSolicitud(data.id, 4)
    }

  }
  async obtenerDeuda(clienteId: number, aceptanteId: number) {
    let promise = null
    let validacion_deudas = true
    if (aceptanteId) {
      promise = await this.solicitudService.obtenerDeudaDeudor(aceptanteId)
    } else {
      promise = await this.solicitudService.obtenerDeudaBeneficiario(clienteId)
    }

    var dc_soles = promise['total_documentos_cartera_mora_soles']
    var dc_dolares = promise['total_documentos_cartera_mora_dolares']
    var cxc_soles = promise['total_cuentas_cobrar_soles']
    var cxc_dolares = promise['total_cuentas_cobrar_dolares']

    if(dc_soles > 0 || dc_dolares > 0 || cxc_soles > 0 || cxc_dolares > 0){
      validacion_deudas = false
    }
    return validacion_deudas
  }

  // ! Actualización Final

  async actualizarEstadoSolicitud(){
    let devoluciones = await this.solicitudService.obtenerDevolucionesObservadas();
    for (var i = 0; i < devoluciones['results'].length; i++) {
      var observaciones = devoluciones['results'][i]['solicitud_observaciones'].length
      if(observaciones == 0){
        await this.actualizacionPendiente(devoluciones['results'][i])
      }
    }
      
  }
  async actualizacionPendiente(data){
    await this.solicitudService.crearDevolucion({ estado: 1}, data.id)
  }

  async observacionSolicitud(solicitud_id, observacion_id){
    var data_solicitud_observacion = {
      solicitud_devolucion: solicitud_id,
      observacion_tipo: observacion_id
    };
    await this.solicitudService.crearSolicitudObservaciones(data_solicitud_observacion)
}

  // ! Fin

  get canAdd() {
		const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilJefeDeOperacionesID,
    //   this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilAsistenteCobranzasID,
    //   this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
		]
	
		return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
	}

  open() {
    const modalRef = this.modalService.open(DevolucionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });

    modalRef.result.then((result) => {
      console.log(result);
      this.changeRefresh()
      if (result) {
      }
    }, (reason) => {
      console.log(reason);
      // this.changeRefresh()
    });
  }

  get showCreateButton() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilAnalistaRiesgosID,
      this.authService.perfil.perfilJefeDeAdmisionID,
      this.authService.perfil.perfilAsesorLegalID,
      this.authService.perfil.perfilLegalID,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilSubGerenciaComercialID,
      this.authService.perfil.perfilTesoreriaID,
      this.authService.perfil.perfilLiderDeTesoreriaID,
      this.authService.perfil.perfilRecepcionID,
      this.authService.perfil.perfilAsistenteCobranzasID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  cambiobtn( value ){
    this.mostrarBtn = value;
  }
}
