import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ExcedentesService } from 'app/core/services/excedentes/excedentes.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
  selector: 'app-show-excedentes-modal',
  templateUrl: './show-excedentes-modal.component.html',
  styleUrls: ['./show-excedentes-modal.component.css']
})
export class ShowExcedentesModalComponent implements OnInit {

  public headerDocumentsCarteraTable: columnHeader[];
  public documentsCarteraList: any[] = [];
  public documentsCarteraSelectionList: any[] = [];
  @Input() propietario: any;
  @Input() tipoPropietario = 2; // CLIENTE por defecto
  @Input() list: any[] = [];
  @Input() empresa: any;
  @Input() preselected: any[] = [];
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();
  todoSelect: boolean = false;
  selectionRows: any[] = [];
  // preselected: any[] = [];
  faTimes = faTimes
  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public devolucionesService: ExcedentesService,
  ) { }

  ngOnInit(): void {
    this.initTables();

    this.loadCuentas();
  }

  toggleText(){
    this.todoSelect = !this.todoSelect
    this.toggleCheckAll()
  }

  toggleCheckAll(){
    this.selectionRows = null
      if(this.todoSelect){
          this.preselected = this.documentsCarteraList.map((item) => { return item.id })
          this.selectionRows = this.documentsCarteraList
      } else {
          this.preselected = []
      }
  }

  resultadosSelection(seleccion) {
    this.selectionRows = seleccion
    this.evalCheck()
  }

  evalCheck(){
    this.todoSelect = this.selectionRows.length == this.documentsCarteraList.length
  }

  loadCuentas() {
    let cliente = null
    let aceptante = null
    if (this.tipoPropietario == 2) {
      cliente = this.propietario
    } else {
      aceptante = this.propietario
    }
    this.devolucionesService.obtenerExcedentes(cliente, '1', 1, 1000, true, 0, this.empresa, aceptante, true)
      .then((res: any) => {
        console.log(res);

        const { results, count, num_pages } = res;

        let filteredList = results;

        if (this.list.length > 0) {
          this.list.forEach((doc) => {
            const documento = doc;

            filteredList = filteredList.filter((doc: any) => {
              return doc.id !== documento.id;
            });

            this.documentsCarteraList = filteredList;
          });
        } else {
          this.documentsCarteraList = results;
        }

        // this.documentsCarteraList = res['results'];
      })
      .catch(error => {
        console.error(error);
      });
  }

  initTables() {
    let headerDocumentsCarteraTable = [
      {
        headerName: "N°",
        field: "id",
        sortable: true,
        pipe: 'indexcol'
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "tipo_documento_descripcion",
      },
      {
        class: "text-center",
        headerName: "Nro de Documento",
        field: "numero_documento",
      },
      {
        class: "text-center",
        headerName: "Excedente",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fch. aplicación",
        field: "fecha",
        pipe: "date",
        sortable: true,
      },
      {
        headerName: 'Aplicación',
        field: 'recaudacion',
        class: 'text-center',
        sortable: true,
        textField: 'recaudacion',
        pipe: 'actionable',
        actionableType: 'recaudacion',
      },
    ];

    this.headerDocumentsCarteraTable = headerDocumentsCarteraTable;
  }

  goToPageDocumentsCartera(e) {

  }

  selectedItem(row) {
    const id = row.id;

    const findRow = this.documentsCarteraSelectionList.find(item => item.id == id);

    if (findRow) {
      this.documentsCarteraSelectionList = this.documentsCarteraSelectionList.filter(
        (item) => item.id !== id
      );
    } else {
      this.documentsCarteraSelectionList.push(row);
    }
  }

  sendCuentasList() {
    this.successEmit.emit(this.selectionRows);
    this.activeModal.close();
  }


}
