import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-stat',
  templateUrl: './card-stat.component.html',
  styleUrls: ['./card-stat.component.css']
})
export class CardStatComponent implements OnInit {
  @Input() item: any
  constructor() { }

  ngOnInit(): void {
    console.log(this.item)
  }

}
