import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { MonedaPipe } from 'app/core/pipes/moneda.pipe';
import { ExpedientesService } from 'app/core/services/expedientes.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { DashboardComponent } from 'app/modules/dashboard/dashboard.component';

@Component({
	selector: 'app-adelantos',
	templateUrl: './adelantos.component.html',
	styleUrls: ['./adelantos.component.css'],
	providers: [MonedaPipe]

})
export class AdelantosComponent implements OnInit {
	faCreditCard = faCreditCard;
	@Output() pageUpdate: EventEmitter<any> = new EventEmitter<any>();

	_adelantos: any[];
	tipo: number = 1;
	_tipoSwitch = true;
	itemsData: any[];
	columnDefs: any[];
	rowData: any[] = [];
	pageTableActual: any;
	pageTableTotal: any;
	pageTablePerPages: any = 10;
	pageTablePages: any[];
	@Output() emiter: EventEmitter<any> = new EventEmitter<any>();
	@Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();

	@Input() set tipoSwitch(value) {
		this._tipoSwitch = value
		if (this._tipoSwitch) {
			this.tipo = 1
		} else {
			this.tipo = 2
		}
	}

	get tipoSwitch() {
		return this._tipoSwitch
	}

	@Input() set adelantos(value: any) {
		if (value) {
			// console.log(value)
			// let { results } = value

			// value.results = results.map((item)=>{
			// 	item.fecha_vencimiento = this.sharedFunctions.formaStringDate(item.fecha_vencimiento)
			// 	return item
			// })
			// console.log(value)
			this.loadTable(value)
		}
	}

	get adelantos() {
		return this._adelantos
	}

	@Input() title: string;

	estado: any;
	estados: any[] = [];

	constructor(
		public sharedFunctions: SharedFunctions,
		public monedapipe: MonedaPipe,
		private expedientesService: ExpedientesService,
		private dashboardExp: DashboardComponent,
	) { }

	ngOnInit(): void {

		this.initColumnsTable();

		this.obtenerEstados();
	}

	initColumnsTable() {
		this.columnDefs = [
			{
				headerName: 'Operación',
				field: 'operacion',
				sortable: true,
			},
			{
				headerName: 'Cliente',
				field: 'cliente',
				class: 'text-center',
				filterProp: 'fecha_vencimiento',
				filterInput: true,
			},
			{
				headerName: 'Fecha Adelanto ',
				class: 'text-center',
				filterable: this.estado == 4,
				filterDate: true,
				filterProp: 'fecha_pago_adelanto',
				pipe: 'function',
				function: function ({ moneda, fecha_pago_adelanto }, header) {
					if (!fecha_pago_adelanto) return '';
					let yy = fecha_pago_adelanto.split("-")[0];
					let mm = fecha_pago_adelanto.split("-")[1];
					let dd = fecha_pago_adelanto.split("-")[2];
					return `${dd}/${mm}/${yy}`;
				},
			},
			{
				headerName: 'Adelanto',
				field: 'monto_adelanto',
				pipe: 'currency',
				moneda: 'moneda',
				sortable: true,
				class: 'text-center',
			},
			// {
			// 	headerName: 'Monto',
			// 	class: 'text-center',
			// 	filterProp: 'tipo_linea',
			// 	filterInput: true,
			// 	pipe: 'function',
			// 	function: function ({ moneda, monto_saldo }, header) {
			// 		if (monto_saldo) {
			// 			return `${moneda} ${monto_saldo.toLocaleString('en-US', { minimumFractionDigits: 2 })}`
			// 		}

			// 		return null
			// 	},
			// }
			{
				headerName: 'Monto',
				field: 'monto_saldo',
				pipe: 'currency',
				moneda: 'moneda',
				sortable: true,
				class: 'text-center',
			},
		];

		if (this.estado == 4) {
			this.columnDefs.push({
				headerName: 'Fecha Vencimiento',
				field: 'fecha_vencimiento',
				class: 'text-center',
			})
			this.columnDefs.push({
				headerName: 'Ejecutivo',
				field: 'responsable_nombre',
				class: 'text-center',
			})
		}
	}

	loadTable(data) {
		this.rowData = data.results.map((item) => {
			item.linea_actual = this.monedapipe.transform(item.linea_actual, item.moneda)
			return item
		});
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

	goToPage({ page, per_page, filtros }) {
		filtros.tipo_vencimiento = this.tipo;
		filtros.estado = this.estado;
		this.pageUpdate.emit({ page, per_page, filtros })
	}

	switchChange(value: number) {
		let tipo;
		if (value) {
			tipo = 1
		} else {
			tipo = 2
		}
		this.pageTableActual = 1
		this.pageUpdate.emit({
			page: this.pageTableActual,
			per_page: this.pageTablePerPages,
			filtros: { tipo_vencimiento: tipo }
		})
		// this.emiter.emit(value ? 1 : 2)
	}

	update() {
		this.pageUpdate.emit({
			page: 1,
			per_page: 10,
			filtros: { tipo_vencimiento: this.tipoSwitch, estado: this.estado }
		});
		this.updateEvent.emit(1);
	}

	parseDate(date) {
		if (!date) return null;

		let yy = date.split("-")[0];
		let mm = date.split("-")[1] - 1;
		let dd = date.split("-")[2];
		return `${yy}/${mm}/${dd}`;
	}

	obtenerEstados() {
		this.expedientesService.obtenerEstadoOrden().then(resp => {
			this.estado = 4;
			this.estados = resp['results'];
			this.changeEstado(this.estado)
		}, err => {
			console.error(err);

		});
	}

	changeEstado(e) {

		let state = 0;

		if(e.id == null){
			state = e
		}else{
			state = e.id
		}

		const filtros = {estado: state};
		this.estado = state
		this.initColumnsTable()
		this.dashboardExp.updateAdelantos({ page: 1, page_size: 10, filtros })
		
		// this.pageUpdate.emit({
		// 	page: 1,
		// 	per_page: 10,
		// 	filtros: { tipo_vencimiento: this.tipoSwitch, estado: e.id }
		// });
		// this.updateEvent.emit(1);
		// this.updateEvent.emit(e.id);
	}

}
