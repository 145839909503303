import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ResultadoFacturasModalComponent } from '../../modals/resultado-facturas-modal/resultado-facturas-modal.component';
import { CavaliService } from 'app/core/services/cavali/cavali.service';
import { ToastService } from 'app/core/services/toast/toast.service';

@Component({
  selector: 'app-procesos-sin-respuesta',
  templateUrl: './procesos-sin-respuesta.component.html',
  styleUrls: ['./procesos-sin-respuesta.component.css']
})
export class ProcesosSinRespuestaComponent implements OnInit {

  @ViewChild('datatable') datatable: any

  @Input() procesosCavali = []
  
  tblRows: any[] = []
  tblHeaders: columnHeader[]
  tblTotalRows: number
  tblPages: number[]
  tblActualPage: number = 1
  tblPerPage: number = 10
  tblFiltros = {}
  tblAcciones = []

  seleccionados = []
  
  constructor( 
    public modalService: NgbModal,
    private spinner: NgxSpinnerService,
    public toast: ToastService,
    public cavaliService: CavaliService,
  ) {}


  ngOnInit(): void {
    this.loadTableColumns()
    this.loadDataTable({})
  }

  moverAFallidos() {
    if (this.seleccionados.length == 0){
      this.toast.warning('Seleccione los procesos para mover a fallidos')
      return
    }

    this.spinner.show()
    this.cavaliService.marcarFallidos(this.seleccionados.map(e => e.id)).subscribe(
      () => this.loadDataTable({}),
      res => {
        console.log(res)
        this.spinner.hide()
      }
    )
  }

  loadTableColumns() {
    this.tblHeaders = [
      {
        headerName: 'ID',
        class: 'text-center',
        field: 'id',
        sortable: true
      },
      {
        headerName: 'Código',
        class: 'text-center',
        field: 'codigo',
        sortable: true,
        filterable: true,
        filterProp: 'codigo__icontains',
        filterInput: true,
      },
      {
        headerName: 'Proceso',
        class: 'text-center',
        field: 'codigo_descripcion',
        sortable: true,
        filterable: true,
        filterProp: 'codigo',
        filterSelectItems: this.procesosCavali,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Fecha',
        class: 'text-center',
        field: 'fecha',
        sortable: true,
        pipe: 'function',
        function: function (row) {
					return `${row.fecha.split('-').reverse().join('/')} ${row.hora.slice(0,8)}`
				},
        filterable: true,
        filterProp: 'fecha',
        filterDate: true,
      },
      {
        headerName: 'Identificador',
        class: 'text-center',
        field: 'identificador',
        sortable: true,
        filterable: true,
        filterProp: 'identificador__icontains',
        filterInput: true,
      },
      {
        headerName: 'Respuesta',
        class: 'text-center',
        field: 'mensaje_respuesta',
        sortable: true
      },
      {
        headerName: 'Operación',
        class: 'text-center',
        field: 'operacion',
        sortable: true,
        filterable: true,
        filterProp: 'operacion',
        filterInput: true,
      },
      {
        headerName: 'Documentos',
        class: 'text-center',
        field: 'numeros_documentos',
        sortable: true,
        filterable: true,
        filterProp: 'numero_documento',
        filterInput: true,
      },
    ]
  }

  parseData(data) {
    return data.map(e => {
      return {...e, ...{
        resultado__application_code: e?.resultado?.application_code,
        resultado__application_user: e?.resultado?.application_user,
        resultado__action_code: e?.resultado?.action_code,
        resultado__action_code_label: e?.resultado?.action_code_label,
        resultado__action_date: e?.resultado?.action_date,
        resultado__action_time: e?.resultado?.action_time,
      }}
    })
  }

  loadDataTable({ page = this.tblActualPage, per_page = this.tblPerPage, filtros = this.tblFiltros }) {
    this.spinner.show()
    this.seleccionados = []
    if (this.datatable) this.datatable.checkedActionAll = false
    Object.keys(filtros).filter(field => field.includes('fecha')).forEach(field => filtros[field] = filtros[field].split(' ')[0])
    filtros['error'] = false
    filtros['tiene_resultado'] = false
    filtros['fallido'] = false
    this.tblFiltros = filtros
    this.cavaliService.getProcesos(Object.assign({ page, page_size: per_page }, filtros)).subscribe(
      (data: any) => {
        this.tblRows = this.parseData(data.results)
        this.tblTotalRows = data.count
        this.tblPages = Array.from(Array(data.num_pages).keys())
        this.tblPerPage = data.per_page
        this.tblActualPage = data.page_number
      },
      (res: any) => console.log(res)
    ).add(() => this.spinner.hide())
  }

  openModalDetalle(proceso) {
    const cavaliModal = this.modalService.open(ResultadoFacturasModalComponent, {size: "xl",})
    cavaliModal.componentInstance.proceso = proceso.id
  }

  onCheckedAction(row: any) {
    if (this.seleccionados.includes(row)) {
      this.seleccionados = this.seleccionados.filter((item) => item.id !== row.id)
    } else {
      this.seleccionados.push(row)
    }
    this.datatable.checkedActionAll = this.tblRows.length == this.seleccionados.length
  }
}
