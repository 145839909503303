import { Component, Input, OnInit } from '@angular/core';
import { AceptantesService } from 'app/core/services/factoring/aceptantes.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
  selector: 'app-empresas-aceptantes',
  templateUrl: './empresas-aceptantes.component.html',
  styleUrls: ['./empresas-aceptantes.component.css']
})
export class EmpresasAceptantesComponent implements OnInit {

  public user: any;
  public headerTable: columnHeader[];
  public rowData: any[] = [];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public searchstring: string = "";
  public loadingTable = false;

  @Input() aceptanteId = 0;

  constructor(
    private aceptantesService: AceptantesService,
  ) { }

  ngOnInit(): void {
    this.aceptantesService.obtenerEmpresas(this.aceptanteId).then(resp => {
      this.rowData = resp[0]['empresas'];
      // this.loadTable(resp['empresas']);
      this.initTables();
    }, err => {
      console.error(err);

    })
  }

  loadTable(data) {
    this.rowData = data.results?.map((el: any, i: number) => ({
      ...el,
      index: i + 1,
    }));

    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initTables() {
    let headerTable = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        headerName: "Nombre",
        field: "nombre",
        sortable: true,
      },
    ];

    this.headerTable = headerTable;
  }

  goToPage({ page, per_page }) {

  }

}
