import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-add-pagare',
  templateUrl: './add-pagare.component.html',
  styleUrls: ['./add-pagare.component.css']
})
export class AddPagareComponent implements OnInit {
  faTimes=faTimes;
  myForm: FormGroup;
  @Input() clienteId = 0;
  paises : any[];
  @Input() pagareId = 0;
  pagare : any;

  monedas            : any[] = [];
  tiposDocumentos    : any[] = [];
  tiposPagares       : any[] = [];
  custodias          : any[] = [];
  tiposEmision       : any[] = [];
  tiposEmisor        : any[] = [];


  marcaResults$ : Observable<any[]>;
	searchMarca$ = new Subject<string>();
	loadingMarca : boolean = false;
  minMarcaLength : Number = 2;

  modelos : any[] = [];

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild        : FormBuilder,
    public activeModal      : NgbActiveModal,
    private clientesService	: ClientesService,
  ) { 
    this.initForm();
  }

  ngOnInit(): void {
    this.obtenerData();

    if (this.pagareId) {
      this.obtenerPagare();
    }

    this.loadSearchMarca()
  }

  obtenerPagare() {
    let pagare = this.clientesService.obtenerPagare(this.pagareId);
      pagare.then(res => {
  
        this.pagare = res;
        this.setForm(this.pagare);
  
      }).catch(error => {
  
      });
  }

  obtenerModelos(marcaId) {
    this.clientesService.buscarModelos(marcaId).then(res => {
      this.modelos = res['results'];
    }).catch(error => {

    });
  }

  obtenerData() {

    let tiposDocumentos = this.clientesService.pagaresData('tiposdocumento');
    let tiposPagares = this.clientesService.pagaresData('tipospagare');
    let custodias = this.clientesService.pagaresData('custodias');
    let tiposEmision = this.clientesService.pagaresData('tiposemision');
    let tiposEmisor = this.clientesService.pagaresData('tiposemisor');
    let monedas = this.clientesService.obtenerListaMonedas();

    Promise.all([
      tiposDocumentos,
      tiposPagares,
      custodias,
      tiposEmision,
      tiposEmisor,
      monedas
    ]).then(res => {
      this.tiposDocumentos  = res[0]['results'];
      this.tiposPagares     = res[1]['results'];
      this.custodias        = res[2]['results'];
      this.tiposEmision     = res[3]['results'];
      this.tiposEmisor      = res[4]['results'];
      this.monedas          = res[5]['results'];
    }).catch(err => {
      console.log(err);
    });

  }

  initForm() {
    this.myForm = this.formbuild.group({
      monto               : [null, [ Validators.required ]],
      fecha_protesto      : [null, [ Validators.required ]],
      fecha_operativa     : [null, [ Validators.required ]],
      fecha_vencimiento   : [null, [ Validators.required ]],
      fecha_toma          : [null, [ Validators.required ]],
      numero_documento    : [null, [ Validators.required ]],
      tipo_documento      : [null, [ Validators.required ]],
      tipo_pagare         : [null, [ Validators.required ]],
      custodia            : [null, [ Validators.required ]],
      moneda              : [null, [ Validators.required ]],
      tipo_emision        : [null, [ Validators.required ]],
      tipo_emisor         : [null, [ Validators.required ]],
      marca               : [null, [  ]],
      modelo              : [null, [  ]],
      fk_marca            : [null, [  ]],
      fk_modelo           : [null, [  ]],
      categoria           : [null, [  ]],
      color               : [null, [  ]],
      anio                : [null, [ Validators.maxLength(5) ]],
      placa               : [null, [  ]],
    });

    this.myForm.controls.fecha_operativa.valueChanges.subscribe((value)=>{
      if(value != null){
        this.myForm.controls["fecha_toma"].setValue(value);
			}
		})

    this.myForm.controls.marca.valueChanges.subscribe((value)=>{
      console.log(value)
      if(!!value && typeof(value) == "object"){
        console.log("cambio")
        this.myForm.controls["fk_marca"].setValue(value.id);
        this.myForm.controls["marca"].setValue(value.nombre);

        this.myForm.controls["fk_modelo"].setValue(null);
        this.myForm.controls["modelo"].setValue(null);
        this.obtenerModelos(value.id)
			}
		})

    this.myForm.controls.modelo.valueChanges.subscribe((value)=>{
      if(!!value && typeof(value) == "object"){
        this.myForm.controls["fk_modelo"].setValue(value.id);
        this.myForm.controls["modelo"].setValue(value.nombre);
			}
		})
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {

        switch (key) {
          case 'fecha_protesto':
            this.myForm.controls[key].setValue(this.parseDate(item[key]));
            continue;
            break;

          case 'fecha_operativa':
            this.myForm.controls[key].setValue(this.parseDate(item[key]));
            continue;
            break;

          case 'fecha_vencimiento':
            this.myForm.controls[key].setValue(this.parseDate(item[key]));
            continue;
            break;

          case 'fecha_toma':
            this.myForm.controls[key].setValue(this.parseDate(item[key]));
            continue;
            break;
        
          default:
            break;
        }

        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  parseDate(date) {
    if (!date)
      return null;
    
    let dateNow = new Date();
    let yy = date.split('-')[0];
    let mm = (date.split('-')[1] - 1);
    // let dd = date.split('-')[2];
    let dd = (date.split('-')[2]).substring(0,2);

    let d = new Date(yy, mm, dd, dateNow.getHours());
    
    return d;
  }

  formatDate(date){
        
    if(!date){
        date = new Date()
    }

    if (!(date instanceof Date))
        return date;
    return date.getFullYear() + 
                "-" + (date.getMonth() + 1) + 
                "-" + date.getDate();
  }  

  onSubmit() {
    if (this.myForm.valid) {
      
      let data = {
        cliente: this.clienteId,
        ...this.myForm.value
      };

      data['fecha_protesto'] = this.formatDate(data['fecha_protesto']);
      data['fecha_operativa'] = this.formatDate(data['fecha_operativa']);
      data['fecha_vencimiento'] = this.formatDate(data['fecha_vencimiento']);
      data['fecha_toma'] = this.formatDate(data['fecha_toma']);

      this.clientesService.guardarPagare(data, this.pagareId)
        .then(res => {
          this.successEmit.emit();
          this.activeModal.close(true);
        }).catch(err => {
          this.activeModal.close(false);
        });

    }
  }


	loadSearchMarca() {
		this.marcaResults$ = concat(
			of([]), // Items predeterminados
			this.searchMarca$.pipe(
			filter(res => {
				return res !== null && res.length >= this.minMarcaLength
			}),
			distinctUntilChanged(),
			debounceTime(800),
			tap(() => this.loadingMarca = true),
			switchMap(term => this.clientesService.buscarMarcaObserver(term).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.loadingMarca = false)
        )
			))
		);
	}

}
