import { Component, OnInit, ViewChild } from '@angular/core';
import { ConfigurationService } from '../../../core/services/configuration/configuration.service';
import { FacturacionService } from '../../../core/services/facturacion/facturacion.service';
import { functions } from '../../../core/helpers/functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tipo-nc',
  templateUrl: './tipo-nc.component.html',
  styleUrls: ['./tipo-nc.component.css']
})
export class TipoNcComponent implements OnInit {

  @ViewChild( 'modal_tipo_nc' ) modal_tipo_nc;

  faTimes = faTimes;
  faSyncAlt = faSyncAlt;

  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnTipoNc       : any[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
  public searchstring     : string = '';

  myForm: FormGroup;

  row:any;

  constructor(private facturacionService: FacturacionService,
              private form : FormBuilder,
              public modalService : NgbModal,
              ) { }

  ngOnInit(): void {

    this.obtenerTiposNC();

    this.myForm = this.form.group({
      descripcion: [ '', [ Validators.required ] ]
    })

  }

  obtenerTiposNC(page = 1, page_size = 10 ){

    this.facturacionService.getTipoNc(page, page_size, '').then( (resp)=>{
      this.loadTable( resp );
      this.initVars();
    })

  }

  loadTable(data) {

    this.rowData = functions.indextable( data )
    // this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }

  initVars() {
    let columns = [
      {
        headerName: 'N°', 
        field     : 'index' ,
        sortable  : true,
      },
      {
        headerName: 'Descripción', 
        field     : 'descripcion',
        class     : 'text-center',
        sortable  : true,
      },
    ];
  
    this.columnTipoNc = columns;
  }

  goToPage({page,per_page,filtros}) {
    this.obtenerTiposNC(page, per_page);
  }

  tableEditEvent(e){

    this.myForm.controls.descripcion.setValue( e.descripcion )

    this.modalService.open( this.modal_tipo_nc, { size: 'md' } )

    this.row = {
      id: e.id,
      codigo: e.codigo
    }

  }

  onSubmit(){

    if( this.myForm.invalid && this.row){
      return;
    }

    let data = {
      codigo: this.row?.codigo,
      descripcion: this.myForm.controls.descripcion.value
    }

    this.facturacionService.putTiposNc( this.row?.id, data)
      .subscribe( (resp)=>{

        this.modalService.dismissAll();
        this.obtenerTiposNC();

      })

  }

}
