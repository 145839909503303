import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FactoringService } from "../../../../core/services/factoring/factoring.service";
import { AuthService } from "../../../../core/services/auth/auth.service";
import { columnHeader } from "../../../../shared/factoring-datatable/factoring-datatable.component";
import { Router } from "@angular/router";
import { DocschecklistService } from "app/core/services/config/docschecklist.service";
import { ReassignAnalystModalComponent } from "../../modals/reassign-analyst-modal/reassign-analyst-modal.component";
import { EstacionesOperacionModalComponent } from "../../modals/estaciones-operacion-modal/estaciones-operacion-modal.component";
import { faChartPie, faPlus, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: "app-factoring-comite",
  templateUrl: "./factoring-comite.component.html",
  styleUrls: ["./factoring-comite.component.css"],
})
export class FactoringComiteComponent implements OnInit {
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = "";
  public canCreate: boolean = false;
	faPlus= faPlus;
	faSyncAlt = faSyncAlt;
	faChartPie = faChartPie;
  reassing = false;

  estaciones: any[] = [];

  constructor(
    private authService: AuthService,
    private router: Router,
    private factoringService: FactoringService,
    private docService: DocschecklistService,
    private modalService: NgbModal
  ) {
    this.user = this.authService.user;
    if (this.user.isCliente || this.user.isOficialDeNegocio) {
      this.canCreate = true;
    }
  }

  ngOnInit(): void {
    let operaciones = this.obtenerOperacionesFiltradas(1, 10);
    let estados = this.docService.obtenerEstados();
    let estaciones = this.docService.obtenerEstaciones();
    let comites = this.factoringService.obtenerComites();
    let analistasRiesgos = this.factoringService.obtenerResponsables(6);
    let oficialesNegocio = this.factoringService.obtenerResponsables(3);
    let empresas = this.factoringService.obtenerEmpresas()

    Promise.all([
      operaciones,
      estados,
      estaciones,
      comites,
      analistasRiesgos,
      oficialesNegocio,
      empresas
    ]).then((res: any[]) => {
      let operaciones = res[0];
      let estados = res[1]["results"];
      let estaciones = res[2]["results"];
      let comites = res[3]["results"];
      let analistasRiesgos = res[4]["results"];
      let oficialesNegocios = res[5]["results"];
      let empresas = res[6]['results']

      this.estaciones = estaciones;

      this.initVars(
        estados,
        estaciones,
        comites,
        analistasRiesgos,
        oficialesNegocios,
        empresas
      );
      this.loadTable(operaciones);
    });
  }

  /**
   * Inicializa la tabla con datos y paginacion
   */
  loadTable(data) {
    this.rowData = data.results;
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars(
    estados = [],
    estaciones = [],
    comites = [],
    analistaRiesgos = [],
    oficialesNegocio = [],
    empresas = [],
  ) {
    let columnas = [
      {
        headerName: "N° Ope.",
        field: "id",
        sortable: true,
        filterable: true,
        filterProp: "operacion",
        filterInput: true,
      },
      {
        headerName: "RUC",
        field: "beneficiario_ruc",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Cliente",
        field: "beneficiario_nombre",
        sortable: true,
        filterable: true,
        filterProp: "beneficiario",
        filterType: "buscarBeneficiario",
      },
      {
        headerName: "Responsable Comité",
        field: "responsable_comite_nombre",
        filterProp: "responsable_comite__nombre",
        sortable: true,
        filterInput: true,
        filterable: true
      },
      {
        headerName: "Oficial de Negocios",
        field: "oficial_negocio_nombre",
        filterProp: "oficial_negocio__nombre",
        sortable: true,
        filterInput: true,
        filterable: true,
      },
      {
        headerName: "Analista de Riesgos",
        field: "analista_riesgo_nombre",
        filterProp: "analista_riesgo",
        sortable: true,
        filterable: true,
        filterSelectItems: analistaRiesgos,
        filterItemsProps: {
          value: "id",
          label: "nombre",
        },
      },
      {
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        class: "text-center",
        filterable: true,
        filterRange: true,
      },
      // {
      // 	headerName: 'Anticipo',
      // 	field     : 'anticipo_comite',
      // 	pipe      : 'percent-icon',
      // 	sortable  : true,
      // 	class     : 'text-center',
      // },
      // {
      // 	headerName: 'Desembolso',
      // 	field     : 'monto_desembolso',
      // 	pipe      : 'currency',
      // 	moneda    : 'moneda',
      // 	class     : 'text-center',
      // },
      {
        headerName: "Fecha",
        field: "fecha",
        pipe: "date",
        sortable: true,
        filterable: true,
        filterDate: true,
        class: "text-center",
      },
      {
        headerName: "Estado",
        field: "estado_descripcion",
        filterProp: "estado",
        sortable: true,
        class: "text-center",
        filterable: true,
        filterSelectItems: estados,
        filterItemsProps: {
          value: "id",
          label: "descripcion",
        },
      },
      {
        headerName: "Estación",
        field: "estacion_nombre",
        sortable: true,
        filterProp: "estacion",
        class: "text-center",
        // filterable: true,
        // filterSelectItems: estaciones,
        // filterItemsProps: {
        // 	value: 'id',
        // 	label: 'nombre'
        // },
      },
      {
        headerName: "Workflow",
        field: "perfil_descripcion",
        pipe: "actionable",
        text: "Ver",
        sortable: true,
      },
      // {
      // 	headerName: 'Estación',
      // 	field     : 'estacion_nombre',
      // 	sortable  : true,
      // 	filterProp: 'estacion',
      // 	class     : 'text-center',
      // },
      {
        headerName: "Comité",
        field: "comite_nombre",
        sortable: true,
        filterable: true,
        filterSelectItems: comites,
        filterProp: "comite",
        filterItemsProps: {
          value: "id",
          label: "nombre",
        },
      },
      // {
      // 	headerName: 'Responsable',
      // 	field     : 'responsable_nombre',
      // 	sortable  : true,
      // },
      // {
      // 	headerName: 'Fecha estación',
      // 	field     : 'fecha_legal_asignado',
      // 	pipe      : 'date',
      // 	sortable  : true,
      // 	class     : 'text-center',
      // }
    ];

    // this.columnDefs = columnas.reduce((acc,item)=>{
    //     if(item.perfiles.includes(this.user.perfil)){
    //         acc.push(item)
    //     }
    //     return acc
    // },[])

    this.columnDefs = columnas;
  }

  tableEditEvent(row: any) {
    if (
      this.user.perfil === 2 ||
      (this.user.perfil != 2 && row.estacion === 1)
    ) {
      this.router.navigate(["/registrar-solicitud-factoring", row.id]);
    } else {
      this.router.navigate(["/operaciones-comite", row.id]);
    }
  }

  goToPage({ page, per_page, filtros }) {
    let request;
    let dataResult = {
      count: 0,
      next: null,
      num_pages: 1,
      page_number: 1,
      per_page: 10,
      previous: null,
      results: []
    };
    console.log(filtros);

    const range = (filtros.valueFrom && filtros.valueTo) ? `${filtros.valueFrom},${filtros.valueTo}` : '';

    if (filtros.operacion > 0) {
      this.factoringService.obtenerOperacionBandeja(filtros.operacion)
        .then(res => {
          dataResult.results.push(res);
          this.loadTable(dataResult);
          return;
        }).catch(error => {
          console.error(error);
          this.loadTable(dataResult);
          return;
        });
      return;
    }

    request = this.obtenerOperacionesFiltradas(
      page,
      per_page,
      filtros.beneficiario ? filtros.beneficiario : "", //Beneficiario
      filtros.oficial_negocio ? filtros.oficial_negocio : "", //Oficial de negocio
      filtros.responsable ? filtros.responsable : "", //Responsable
      filtros.estado ? filtros.estado : "", //Estado
      filtros.estacion ? filtros.estacion : "", //Estacion
      filtros.fecha__gte ? filtros.fecha__gte : "", //Fecha desde
      filtros.fecha__lte ? filtros.fecha__lte : "", //Fecha hasta
      filtros.comite ? filtros.comite : "", //Analista de riesgos
      filtros.analista_riesgo ? filtros.analista_riesgo : "", //Fecha hasta
      range,
      filtros.oficial_negocio__nombre ? filtros.oficial_negocio__nombre : "",
      filtros.responsable_comite ? filtros.responsable_comite : "",
      filtros.responsable_comite__nombre ? filtros.responsable_comite__nombre : "",
      filtros.empresa
    );

    request.then((res) => {
      this.loadTable(res);
    });
  }

  obtenerOperacionesFiltradas(
    page = 1,
    per_page = 10,
    beneficiario = "",
    oficial_negocio = "",
    responsable = "",
    estado = "",
    estacion = "",
    fecha__gte = "",
    fecha__lte = "",
    comite = "",
    analista_riesgo = "",
    monto__range = "",
    oficial_negocio__nombre = "",
    responsable_comite = "",
    responsable_comite__nombre = "",
    empresa = "",
  ) {
    console.log(oficial_negocio__nombre, this.user)
    return this.factoringService.bandejaComite(
      page, //Pagina
      per_page, //Por pagina
      beneficiario, //Beneficiario
      oficial_negocio, //Oficial de Negocio
      responsable, //Responsable
      estado, //Estado
      estacion, //Estacion Analisis de riesgo
      fecha__gte, //Fecha desde
      fecha__lte, //Fecha hasta
      comite,
      analista_riesgo,
      oficial_negocio__nombre,
      monto__range,
      responsable_comite,
      responsable_comite__nombre,
      empresa
    );
  }

  isPriority(row: any) {
    return (row?.estado === 2) ? '#fccf71' : '';
  }

  openOpercion(row) {
    console.log(row);
    const modalRef = this.modalService.open(EstacionesOperacionModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'xl'
    });
    modalRef.componentInstance.operacion = row;
    modalRef.componentInstance.estaciones = this.estaciones;
    modalRef.result.then(res => {
      console.log(res);

    });
  }

}
