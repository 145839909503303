import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';
import { JudicialService } from 'app/core/services/judicial/judicial.service';
import { LegalService } from 'app/core/services/legal.service';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { CuentasXPagarComponent } from 'app/modules/recaudacion/modals/cuentas-x-pagar/cuentas-x-pagar.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
	selector: 'app-expedientejudicial',
	templateUrl: './expedientejudicial.component.html',
	styleUrls: ['./expedientejudicial.component.css']
})
export class ExpedientejudicialComponent implements OnInit {
	faTimes = faTimes;
	@ViewChild('documentoModal', { static: false }) public documentoModal;
	public documentsCarteraList         : any[] = [];
	public CxcList                      : any[] = [];
	public documentsCarteraSelectedList : any[] = [];
	public documentsCarteraSelectionList: any[] = [];
	public rowCuentasCobrar             : any[] = [];
	public rowDocsCarteras              : any[] = [];
	active                              = 1;
	documento                           : any;
	filasComentarios                    : any;
	public headersCXC                   : any[] = []
	public empresas                     : any[] = []
	public monedas                      : any[] = [
		{
			id: 1,
			descripcion: "Soles"
		},
		{
			id: 2,
			descripcion: "Dólares"
		}
	]
	public tiposEntidades : any[] = [
		{
			id: 1,
			descripcion: "Cliente"
		},
		{
			id: 2,
			descripcion: "Aceptante"
		}
	]
	public columnasRecaudacion   : any[];
	public columnasProrrogas     : any[];
	public formularioDocumento   : FormGroup;
	public bandejaRecaudos       : any[];
	public bandejaProrrogas      : any[];
	public minDate               : Date = new Date();
	public modalPack             : any;
	public row                   : any;
	public headers               : any[];
	public _modoEdicionVar       : boolean = false;
	DocumentosCarteraPreseleccion: any;
	CXCPreseleccion              : any;
	public set modoEdicionVar(value){
		if(this.formularioDocumento){
			console.log( '*********Valor*****',value ) 
			if(value){
				this.formularioDocumento.enable()

			} else {
				this.formularioDocumento.disable()
				console.log( this.formularioDocumento )
			}
		}
		this._modoEdicionVar = value
	}
	public get modoEdicionVar(){
		return this._modoEdicionVar
	}
	@Input() readOnly                        = false;
	@Output() nuevoComent                    = new EventEmitter<any>();
	@Output() editaComent                    = new EventEmitter<any>();
	@Output() eliminaComent                  = new EventEmitter<any>();
	@Output() cambios                        = new EventEmitter<any>();
	modalDocumentsCarteraReference           : any;
	@ViewChild("e_documentscartera", { static: false }) public e_documentscartera;
	@ViewChild("e_cuentasPorCobrar", { static: false }) public e_cuentasPorCobrar;
	public pageDocumentsCarteraTableActual   : number;
	public pageDocumentsCarteraTableTotal    : number;
	public pageDocumentsCarteraTablePerPages : number = 10;
	public pageDocumentsCarteraTablePages    : number[];
	public pageCuentaCobrarActual            : number;
	public pageCuentaCobrarTotal             : number;
	public pageCuentaCobrarPerPages          : number = 10;
	public pageCuentaCobrarTablePages        : number[];
	public seleccionCtasXCobrar              : any[] = [];
	public seleccionDocumentos               : any[] = [];
	public mergeCuentasDocumentos            : any[] = [];
	public identidadCliente: any;
	public identidadAceptante: any;
	headerDocumentsCarteraTable              : any[] = [];
	headersSeleccionCXC                      : any[] = [];
	seleccionPrecargadaDeClientes                      : any[] = [];
	seleccionPrecargadaDeAceptantes                      : any[] = [];
	muestraDocumentos                      : boolean = false;

	constructor(
		public formbuild         : FormBuilder,
		public factoring         : FactoringService,
		public authService       : AuthService,
		public judialService     : JudicialService,
		public modalService      : NgbModal,
		public localService      : LocalServiceService,
		public legalService      : LegalService,
		public recaudacionService: RecaudacionService,
		public toast             : ToastService,
		public sharedFunctions   : SharedFunctions,
	) {
	}

	ngOnInit(): void {
		this.factoring.obtenerEmpresas().then((res) => {
			this.empresas = res['results'];
		});
	}

	mostrarDocumentos(){
		console.log('Turn on button docs')
		this.muestraDocumentos = !this.muestraDocumentos
	}

	modoEdicion() {
		this.modoEdicionVar = true
	}

	obtenerEntidadCliente(entidad) {
		if(!entidad){
			return
		}
		let toggle = this.formularioDocumento.get('id')
		if (!toggle || this.modoEdicionVar) {
			this.formularioDocumento.get('cliente').setValue(entidad.id)
			this.identidadCliente = entidad.id

			if (!this.formularioDocumento.get('cliente_nombre')) {
				this.formularioDocumento.setControl('cliente_nombre', new FormControl(entidad.nombre))
				this.formularioDocumento.setControl('cliente_ruc', new FormControl(entidad.ruc))
			} else {
				this.formularioDocumento.get('cliente_nombre').setValue(entidad.nombre)
				this.formularioDocumento.get('cliente_ruc').setValue(entidad.ruc)
			}
		}

		// this.seleccionPrecargadaDeClientes = []
	}

	obtenerEntidadAceptante(entidad) {
		if(!entidad){
			return
		}
		let toggle = this.formularioDocumento.get('id')
		if (!toggle || this.modoEdicionVar) {
			if (!this.formularioDocumento.get('aceptante_nombre')) {
				this.formularioDocumento.setControl('aceptante_nombre', new FormControl(entidad.nombre))
				this.formularioDocumento.setControl('aceptante_ruc', new FormControl(entidad.ruc))
			} else {
				this.formularioDocumento.get('aceptante').setValue(entidad.id)
				this.identidadAceptante = entidad.id
				this.formularioDocumento.get('aceptante_nombre').setValue(entidad.nombre)
				this.formularioDocumento.get('aceptante_ruc').setValue(entidad.ruc)
			}
		}

		// this.seleccionPrecargadaDeAceptantes = []
	}

	limpiarFechas(direccion = true){
		let { formularioDocumento } = this
		let fechaTemporal = [
			formularioDocumento.get('fecha_presentacion_demanda'),
			formularioDocumento.get('fecha_admision_demanda'),
			formularioDocumento.get('fecha_presentacion_medida_cautelar'),
			formularioDocumento.get('fecha_admision_medida_cautelar')
		]

		fechaTemporal.forEach( fechaControl => {
			if(fechaControl.value){
				let value = fechaControl.value
				if(direccion){
					fechaControl.setValue(this.sharedFunctions.parseDate(value))
				} else {
					fechaControl.setValue(this.sharedFunctions.formatDate(value))
				}
			}
		})
	}

	initForm(row, nuevo){
		let { detalles }            = row
		this.mergeCuentasDocumentos = detalles
		this.formularioDocumento = this.formbuild.group(row)
		this.limpiarFechas(true)

		console.log( row )

		this.formularioDocumento.get('fecha_admision_demanda').valueChanges.subscribe((newValue)=>{
			if(!newValue){
				return
			}

			let fechaPresentacion = new Date(this.formularioDocumento.get('fecha_presentacion_demanda').value)
			if(!this.formularioDocumento.get('fecha_presentacion_demanda').value) {
				this.toast.warning('Primero debe seleccionar una fecha de presentación')
				this.formularioDocumento.get('fecha_admision_demanda').setValue(null)
				return
			}

			let fecha = new Date(newValue)
			let tiempoFecha = fecha.getTime() 
			let tiempoFechaPresentacion = fechaPresentacion.getTime() 

			if(tiempoFecha < tiempoFechaPresentacion){
				this.toast.warning('La fecha de admisión no puede ser menor que la fecha de presentación')
				this.formularioDocumento.get('fecha_admision_demanda').setValue(null)
			}
		})

		this.formularioDocumento.get('fecha_admision_medida_cautelar').valueChanges.subscribe((newValue)=>{
			if(!newValue){
				return
			}

			let fechaPresentacion = new Date(this.formularioDocumento.get('fecha_presentacion_medida_cautelar').value)
			if(!this.formularioDocumento.get('fecha_presentacion_medida_cautelar').value) {
				this.toast.warning('Primero debe seleccionar una fecha de presentación')
				this.formularioDocumento.get('fecha_admision_medida_cautelar').setValue(null)
				return
			}


			let fecha = new Date(newValue)
			let tiempoFecha = fecha.getTime() 
			let tiempoFechaPresentacion = fechaPresentacion.getTime() 

			if(tiempoFecha < tiempoFechaPresentacion){
				this.toast.warning('La fecha de admisión no puede ser menor que la fecha de presentación')
				this.formularioDocumento.get('fecha_admision_medida_cautelar').setValue(null)
			}
		})
		
		this.formularioDocumento.get('cliente').valueChanges.subscribe(( val )=>{
			this.identidadCliente = val
			this.setPreloadedValuesSelect(row,true)
		})

		this.formularioDocumento.get('aceptante').valueChanges.subscribe(( val )=>{
			this.identidadAceptante = val
			this.setPreloadedValuesSelect(row,false)
		})

		this.formularioDocumento.setControl('tipoEntidad',new FormControl(1))
		for( let prop in row){
			this.addValidators(prop)
		}

		if (!this.formularioDocumento.get('cliente_ruc')) {
			this.formularioDocumento.setControl('cliente_ruc', new FormControl(null))
			this.formularioDocumento.get('cliente_ruc')?.disable()
		}
		if (!this.formularioDocumento.get('aceptante_ruc')) {
			this.formularioDocumento.setControl('aceptante_ruc', new FormControl(null))
			this.formularioDocumento.get('aceptante_ruc')?.disable()
		}
		if (this.authService.user.isAsesorLegal || this.authService.user.isJefeDeOperaciones || this.authService.user.isAdmin) {
			this.readOnly = false
		} else {
			this.readOnly = true
			this.formularioDocumento.disable()
		}

		if(!!row.aceptante){
			this.setPreloadedValuesSelect(row,false)
			this.identidadAceptante = row.aceptante
			this.formularioDocumento.get('tipoEntidad').setValue(2)
		} else if(!!row.cliente) {
			this.identidadCliente = row.cliente
			this.setPreloadedValuesSelect(row,true)
			this.formularioDocumento.get('tipoEntidad').setValue(1)
		}

		this.headers = [
			{
				headerName: "N°",
				field: "id",
				sortable: true,
				pipe: 'indexcol'
			},
			{
				class: "text-center",
				headerName: "Tipo",
				field: "tipo_documento_descripcion",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Nro Documento",
				field: "nro_documento",
			},
			{
				class: "text-center",
				headerName: "Monto",
				field: "monto",
				pipe: "currency",
				moneda: "monto",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Aceptante",
				field: "aceptante_nombre",
			},
			{
				class: "text-center",
				headerName: "RUC",
				field: "aceptante_ruc",
			}
		];

		this.headersCXC = [
			{
				headerName: "N°",
				field: "id",
				sortable: true,
				pipe: 'indexcol'
			},
			{
				class: "text-center",
				headerName: "Concepto",
				field: "concepto_descripcion",
			},
			{
				headerName: "Nro",
				field: "id",
				sortable: true,
			},
			{
				headerName: "Operación",
				field: "operacion_detalle_operacion",
				class: "text-center",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Monto",
				field: "monto",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Saldo",
				field: "saldo",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
			}
		];

		this.headersSeleccionCXC = [
		{
			class: "text-center",
			headerName: "Nro Documento",
			field: "nro_documento",
			sortable: true,
		},
		{
			class: "text-center",
			headerName: "Tipo Documento",
			field: "tipo_documento_descripcion",
			sortable: true,
		},
		{
			headerName: "Nro Contrato",
			field: "numero_contrato",
			class: "text-center",
			sortable: true,
		},
		{
			headerName: "Operación",
			field: "operacion",
			class: "text-center",
			sortable: true,
		},
		{
			class: "text-center",
			headerName: "Cliente",
			field: "cliente_nombre",
			sortable: true,
		},
		{
			class: "text-center",
			headerName: "RUC",
			field: "cliente_ruc",
			sortable: true,
		},
		{
			class: "text-center",
			headerName: "Aceptante",
			field: "aceptante_nombre",
			sortable: true,
		},
		{
			class: "text-center",
			headerName: "RUC",
			field: "aceptante_ruc",
			sortable: true,
		},
		{
			class: "text-center",
			headerName: "Tipo Cambio",
			field: "tipo_cambio_monto",
			sortable: true,
		},
		{
			class: "text-center",
			headerName: "Monto",
			field: "monto",
			pipe: 'function',
			function: function (row) {
			  if (row.moneda == 1) {
				let number = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(row.monto))
				return `S/ ${number}`
			  }
			  else {
				return `$ ${Number(row.monto).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
			  }
			},
		  },
		{
			class: "text-center",
			headerName: "Saldo",
			field: "saldo",
			pipe: 'function',
			function: function (row) {
			  if (row.moneda == 1) {
				let number = new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(row.saldo))
				return `S/ ${number}`
			  }
			  else {
				return `$ ${Number(row.saldo).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
			  }
			},
		}
		];

		this.headerDocumentsCarteraTable = [
		{
			headerName: "N°",
			field: "id",
			sortable: true,
			pipe: "indexcol",
		},
		{
			class: "text-center",
			headerName: "Tipo",
			field: "operacion_tipo_detalle_descripcion",
			sortable: true,
		},
		{
			class: "text-center",
			headerName: "Operación",
			field: "operacion",
			sortable: true,
		},
		{
			class: "text-center",
			headerName: "Nro Documento",
			field: "operacion_numero_documento",
		},
		{
			class: "text-center",
			headerName: "Monto",
			field: "monto_neto",
			pipe: "currency",
			moneda: "moneda",
			sortable: true,
		},
		{
			class: "text-center",
			headerName: "Saldo",
			field: "saldo",
			pipe: "currency",
			moneda: "moneda",
			sortable: true,
		},
		{
			class: "text-center",
			headerName: "Fecha Vencimiento",
			field: "fecha_vencimiento",
			pipe: "date",
			sortable: true,
		},
		];

		if(nuevo){

			this.formularioDocumento.get('empresa').setValue(2)
			this.formularioDocumento.get('empresa').disable()

		}

		
	}

	setPreloadedValuesSelect(row, isCliente ){
		if(isCliente){
			this.seleccionPrecargadaDeClientes = [{
				id                                  : row.cliente,
				ruc                                 : row.cliente_ruc,
				nombre                              : row.cliente_nombre,
			}];
		} else {
			this.seleccionPrecargadaDeAceptantes = [{
				id                                  : row.aceptante,
				ruc                                 : row.aceptante_ruc,
				nombre                              : row.aceptante_nombre,
			}];
		}
	}

	initModal(row, pack, nuevo = false){

		// this.modoEdicionVar = true
		// setTimeout(()=>this.modoEdicionVar = nuevo , 500)	
		this.row            = row
		this.modalPack      = pack
		this.initForm(row, nuevo)
		this.displayModal()
	}

	destroy(row){
		const modalRef                     = this.modalService.open(ConfirmModalComponent, {});
		modalRef.componentInstance.title   = "Eliminar Detalle";
		modalRef.componentInstance.message =
		"¿Seguro que desea eliminar el detalle del expediente?";
		modalRef.result.then((result) => {
			if (result) {
				if(row.id){
					this.judialService.bandejaJudicialDetalleDelete(row.id).then((res)=>{
						let index=this.mergeCuentasDocumentos.findIndex( item => item.id == row.id)
						this.mergeCuentasDocumentos.splice(index,1)
					})
				} else {
				let index=this.mergeCuentasDocumentos.findIndex( item => item.id == row.id)
				this.mergeCuentasDocumentos.splice(index,1)
				}
			}
		});
	}

	resultadosSelection(seleccion){
		console.log(seleccion)
		this.seleccionDocumentos = seleccion.map((item)=>{
			return {
				expediente_judicial       : this.row ? this.row.id: null,
				documento_cartera         : item.id,
				cuenta_cobrar             : null,
				aceptante                 : item.deudor,
				aceptante_nombre          : item.aceptante_nombre,
				aceptante_ruc             : item.aceptante_ruc,
				cliente                   : item.beneficiario,
				cliente_nombre            : item.beneficiario_nombre ,
				cliente_ruc               : item.beneficiario_ruc,
				tipo_documento            : item.operacion_tipo_detalle,
				tipo_documento_descripcion: item.operacion_tipo_detalle_descripcion,
				nro_documento             : item.operacion_numero_documento,
				tipo_cambio_monto         : item.tipo_cambio_monto,
				numero_contrato           : item.numero_contrato,
				monto                     : item.monto_neto,
				saldo                     : item.saldo,
				operacion                 : item.operacion,
				moneda                    : item.moneda,
			}
		})
		this.mergeCuentasDocumentos = this.seleccionDocumentos.concat(this.seleccionCtasXCobrar)
		this.row.detalles = seleccion
		this.DocumentosCarteraPreseleccion = []
	}

	resultadosSelectionCXC(seleccion){
		this.seleccionCtasXCobrar = seleccion.map((item)=>{
			return {
				// id                        : item,
				expediente_judicial       : this.row ? this.row.id: null,
				documento_cartera         : null,
				cuenta_cobrar             : item.id,
				aceptante                 : item.deudor,
				aceptante_nombre          : item.deudor_nombre,
				aceptante_ruc             : item.deudor_ruc,
				cliente                   : item.beneficiario,
				cliente_nombre            : item.beneficiario_nombre ,
				cliente_ruc               : item.beneficiario_ruc,
				tipo_documento            : null,
				tipo_documento_descripcion: null,
				nro_documento             : item.operacion_detalle,
				tipo_cambio_monto         : item.tipo_cambio_monto,
				monto                     : item.monto,
				saldo                     : item.saldo,
				operacion                     : item.operacion_detalle_operacion,
			}
		})

		this.mergeCuentasDocumentos = this.seleccionCtasXCobrar.concat(this.seleccionDocumentos)
		this.resultadosSelectionCuentasCobrar(this.seleccionCtasXCobrar)
		this.CXCPreseleccion = []
	}

	resultadosSelectionCuentasCobrar(seleccion){
		this.rowCuentasCobrar = seleccion
	}

	displayModal(){
		this.modalService.open(this.documentoModal, {
			size: 'xl'
		}).result.then((response: any) => {
			// console.log(response)
		}).catch((err) => {
			// console.log(err)
			// console.log('Error modal')
		})
	}

	goToCuentasPorCobrar() {
		this.localService.setJsonValue("RedirectFromJudicial", this.row);
		window.open("#/cuentas-x-cobrar", "_blank");
	}

	goToDocumentosCartera() {
		this.localService.setJsonValue("RedirectFromJudicial", this.row);
		window.open("#/documentos_cartera");
	}

	addValidators(prop) {
		let required = [
			'empresa',
			'monto_ejecutado',
			'nro_documento',
			'tipo']

		if (required.includes(prop)) {
			this.formularioDocumento.get(prop).setValidators([Validators.required])
			return true
		} else {
			return false
		}
	}


	guardarCambios() {
		let val: any = {};
		this.limpiarFechas(false)
		for (let prop in this.formularioDocumento.controls) {
			val[prop] = this.formularioDocumento.get(prop).value
		}

		val.detalles = this.mergeCuentasDocumentos

		if(val.id){
			// if(this.modoEdicionVar){
				this.judialService.bandejaJudicialPatch(val).then((res)=>{
					this.initForm(res, null)
					this.row = res
					this.modoEdicionVar = false
					this.cambios.emit(true)
					this.limpiarFechas(true)
				})
			// }
		} else {
			this.judialService.bandejaJudicialPost(val).then((res) => {
				this.initForm(res, null)
				this.row = res
				this.modoEdicionVar = false
				this.cambios.emit(true)
				this.limpiarFechas(true)
			})
		}

		this.modoEdicionVar = false
	}

	async showDocumentsCartera() {
		await this.obtenerDocumentosCartera(
			1,
			1000,
			this.formularioDocumento.get('cliente')?.value ? this.formularioDocumento.get('cliente')?.value : '',
			this.formularioDocumento.get('aceptante')?.value ? this.formularioDocumento.get('aceptante')?.value : '',
			"");

		this.DocumentosCarteraPreseleccion = this.mergeCuentasDocumentos.filter( item => item.documento_cartera ).map( item => item.documento_cartera )
		
		this.modalDocumentsCarteraReference = this.modalService.open(
			this.e_documentscartera,
			{
				size: "lg",
			}
		);
	}

	async obtenerDocumentosCartera(
		page         = 1,
		page_size    : 1000,
		beneficiario = '',
		deudor       = '',
		estado__in   = ''
	) {
		return await this.recaudacionService
			.obtenerDocumentosCarteraExpediente(
				beneficiario,
				deudor,
				'3,1',
				this.formularioDocumento.get('empresa').value,
				true,
				page,
				page_size
			)
			.then((documents: any) => {
			const { results, count, num_pages } = documents;
			let tablePages = [];
			let filteredList = results;
			if (this.documentsCarteraSelectedList.length > 0) {
			  this.documentsCarteraSelectedList.forEach((doc) => {
				const { documento } = doc;
				filteredList = filteredList.filter((doc: any) => {
					return doc.id !== documento;
				});
				this.documentsCarteraList = filteredList;
				this.pageDocumentsCarteraTableTotal = filteredList.length;
			  });
			} else {
			  this.documentsCarteraList = results;
			  this.pageDocumentsCarteraTableTotal = count;
			}

			for (let index = 0; index < num_pages; index++) {
			  tablePages.push(index);
			}
			this.pageDocumentsCarteraTableActual = page;
			this.pageDocumentsCarteraTablePerPages = page_size;
			this.pageDocumentsCarteraTablePages = tablePages;
		  });
	}

	showCuentasxPagar() {

		let { tipoEntidad, cliente, aceptante, empresa } = this.formularioDocumento.controls

		let payload = {
			beneficiario: tipoEntidad.value ==  1 ? cliente.value : null,
			deudor      : tipoEntidad.value == 2 ? aceptante.value : null
		  };
		this.recaudacionService.obtenerCuentasPorCobrar(payload, '1', empresa.value ,1,1000)
		.then((res: any) => {
			this.CxcList = res['results'].map(( CXC ) => {
				CXC.monto = CXC.total
				CXC.saldo = CXC.saldo
				CXC.operacion_detalle = ''
				return CXC
			});
			this.CXCPreseleccion               = this.mergeCuentasDocumentos.filter( item => item.cuenta_cobrar ).map( item => item.cuenta_cobrar )
			this.modalDocumentsCarteraReference = this.modalService.open(
				this.e_cuentasPorCobrar,
				{
					size: "lg",
				}
			);
		})
		.catch(error => {
			console.error(error);
		});
	}

	tabChange(e){
		let { identidadCliente } = this
		console.log(identidadCliente)
		console.log(this.seleccionPrecargadaDeClientes)
		
	}

}
