import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { Perfil } from 'app/core/services/auth/Perfil';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import Chart from 'chart.js';

@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
	public obs: any;
	public user: any;
	public operacionesPorEstacion: any[];
	public indicadores: any[];
	public indicadores2: any[];
	public carterasVencidas: any[];
	public lineasVencidas: any[];
	public productividad: any[];
	public adelantos: any[];
	public concentracionClientes: any[];
	public concentracionAceptantes: any[];
	public filtro_lider: any[];
	public filtro_oficial: any[];
	public rangoFechasVolumes : { filterValue: Date | null } [] = [{ filterValue: null}, {filterValue: null}];
	public rangoFechasMontos : { filterValue: Date | null } [] = [{ filterValue: null}, {filterValue: null}];


	userMasked;
	subscription: Subscription;
	liderOficial:any;

	constructor(
		public modalService: NgbModal,
		public http: HttpClient,
		public spinner: NgxSpinnerService,
		public auth: AuthService,
		public clienteService: ClientesService,
	) {
	}

	public ngOnInit() {
		const filtros = { estado: 1 };
		const  today = new Date();
		const daysAgo = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000)); // Retrocediendo 30 días

		this.rangoFechasVolumes[0].filterValue = daysAgo;
		this.rangoFechasVolumes[1].filterValue = today;
		
		this.rangoFechasMontos[0].filterValue = daysAgo;
		this.rangoFechasMontos[1].filterValue = today;


		this.subscription = this.auth.actualUserMask.subscribe((userMask : any) => {
			this.user = this.auth.user;
			this.userMasked = userMask;

			if( userMask != 'default message' ){
				this.updateVolumen();
				this.updateMonto();
                this.updateLineasVendias();
                this.updateCarterasVencidas();
                this.updateConcentracion({ page: 1, page_size: 10 });
                this.updateConcentracionAceptanes({ page: 1, page_size: 10 });
                this.updateProductividad('');
                this.updateAdelantos({ page: 1, page_size: 10, filtros });
			}
		})

		this.user = this.auth.user
		// if(this.user.perfil == 18){
		// 	this.user.perfil = 3
		// }
		this.updateVolumen();
		this.updateMonto();

		this.updateLineasVendias();

		this.updateCarterasVencidas();

		this.updateConcentracion({ page: 1, page_size: 10 });
		this.updateConcentracionAceptanes({ page: 1, page_size: 10 });

		this.updateProductividad();
		this.updateAdelantos({ page: 1, page_size: 10, filtros });
	}

	onRangoFechasChange(event: { startDate: Date, endDate: Date}, type : number = 1) : void {
		// Don't use mutations, ngOnChanges won't detect them -> this.rangoFechasVolumes[0].filterValue = event.startDate;
		if(type == 1){ // 1 -> Volumes
			this.rangoFechasVolumes = [ {filterValue: event.startDate}, {filterValue: event.endDate} ]
			this.updateVolumen();
		}else { // -> Montos
			this.rangoFechasMontos = [ {filterValue: event.startDate}, {filterValue: event.endDate} ]
			this.updateMonto();
		}
	}	

	onRangoFechasSincro(type : number = 1) {
		if(type == 1){ // 1 -> Volumes
			this.rangoFechasMontos = [ ...this.rangoFechasVolumes ];
			this.updateMonto();
		}else { // -> Montos
			this.rangoFechasVolumes = [ ...this.rangoFechasMontos ];
			this.updateVolumen();
		}
	} 

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

    validationOficialAndLider(){
        var oficial;
        var lider;

		if (this.user.isOficialDeNegocio && !this.user.isLiderDeEquipo){
			oficial = this.user.id
		} else {
			oficial = this.validacionUserMask();
		}
		if (this.user.isLiderDeEquipo){
			lider = this.user.id;
		} else {
			lider = ''
		}
		if (lider == oficial){
			lider = ''
		}

        return {oficial, lider}
    }

	updateConcentracion({ page, page_size }) {
		let filtros = this.validationOficialAndLider();

		this.auth.concentracionClientes(page ? page : 1, page ? page_size : 10, filtros.oficial, filtros.lider)
		    .then((res: any) => this.concentracionClientes = res)
			// this.auth.concentracionAceptantes(page ? page : 1,page ? page_size : 10,this.user.isOficialDeNegocio ? this.user.id : '')
			// .then((res: any)=> this.concentracionAceptantes = res)
	}

	updateConcentracionAceptanes({ page, page_size }) {
		let filtros = this.validationOficialAndLider();

		this.auth.concentracionAceptantes(page ? page : 1, page ? page_size : 10, filtros.oficial, filtros.lider)
		    .then((res: any) => this.concentracionAceptantes = res)
	}

	updateCarterasVencidas() {
		let filtros = this.validationOficialAndLider();
		this.liderOficial = filtros;

		this.auth.obtenerCarterasVencidas(filtros.oficial, filtros.lider)
		    .then((res: any) => this.carterasVencidas = res)
	}

	updateTablestats() {
		this.carterasVencidas = null
		this.auth.obtenerCarterasVencidas()
			.then((res: any) => this.carterasVencidas = res)
	}

	updateLineasVendias() {
		let filtros = this.validationOficialAndLider();
		let request;

		request = this.auth.obtenerLineasVencidas(1, 10, '1', filtros.oficial, filtros.lider)
		request.then((res: any) => {   this.lineasVencidas = res})
	}

	updateVencimiento() {
		let filtros = this.validationOficialAndLider();
		this.lineasVencidas = null

		this.auth.obtenerLineasVencidas(1, 10, '1', filtros.oficial, filtros.lider)
			.then((res: any) => this.lineasVencidas = res)
	}

	updateProductividad(date = '') {
		let filtros = this.validationOficialAndLider();
		this.productividad = null
		const dnow = new Date();
		const d = `${dnow.getFullYear()}-${dnow.getMonth() + 1}-01`;

		this.auth.obtenerProductividad(1, 1000, (date != '') ? date : d, filtros.oficial, filtros.lider)
			.then((res: any) => this.productividad = res)
	}

	updateAdelantos({ page, page_size, filtros} ) {
		var estado_operacion = filtros.estado;
		let fechaPagoAdelantoIni = filtros['fecha_pago_adelanto__gte']
		let fechaPagoAdelantoFin = filtros['fecha_pago_adelanto__lte']
		if (fechaPagoAdelantoIni && fechaPagoAdelantoFin) {
			fechaPagoAdelantoIni = fechaPagoAdelantoIni.split(' ')[0]
			fechaPagoAdelantoFin = fechaPagoAdelantoFin.split(' ')[0]
		} else {
			fechaPagoAdelantoIni = ''
			fechaPagoAdelantoFin = ''
		}
		filtros = this.validationOficialAndLider();
		this.adelantos = null
		
		if (estado_operacion == 4){
			this.auth.obtenerAdelantosPendientesDashboard(page, page_size, filtros.oficial, filtros.estado, filtros.lider, fechaPagoAdelantoIni, fechaPagoAdelantoFin)
			.then((res: any) => this.adelantos = res)

		}else if (estado_operacion == 3){
			this.auth.obtenerAdelantosCanceladosDashboard(page, page_size, filtros.oficial, filtros.estado, filtros.lider)
			.then((res: any) => this.adelantos = res)

		}else if (estado_operacion == 2){
			this.auth.obtenerAdelantosAbonadosDashboard(page, page_size, filtros.oficial, filtros.estado, filtros.lider)
			.then((res: any) => this.adelantos = res)

		}else{
			this.auth.obtenerAdelantosDashboard(page, page_size, filtros.oficial, filtros.estado, filtros.lider)
			.then((res: any) => this.adelantos = res)

		}
	}

	vencimientoLineasTipo(tipo) {
		let filtros = this.validationOficialAndLider();
		
		this.auth.obtenerLineasVencidas(tipo.page, tipo.per_page, tipo.filtros.tipo_vencimiento, filtros.oficial, filtros.lider)
			.then((res: any) => this.lineasVencidas = res)
	}

	updateVolumen() {
		let filtros = this.validationOficialAndLider();
		this.indicadores = null;
		this.liderOficial = filtros;
		
		let request = this.auth.obtenerEstacionesNumerosAlt(filtros.oficial, filtros.lider, this.rangoFechasVolumes[0]?.filterValue, this.rangoFechasVolumes[1]?.filterValue);
		request.then((res: any) => {
			this.indicadores = res.map(this.acortarNombres)
		})
	}

	updateMonto() {
		let filtros = this.validationOficialAndLider();
		this.indicadores2 = null;
		
		let request = this.auth.obtenerEstacionesNumerosAlt(filtros.oficial, filtros.lider, this.rangoFechasMontos[0]?.filterValue, this.rangoFechasMontos[1]?.filterValue);
		request.then((res: any) => {
			this.indicadores2 = res.map(this.acortarNombres)
		})
	}

	acortarNombres(item) {
		switch (item.estacion) {
			case "Oficial de Negocio":
				item.estacion = "Oficial"
				break;
			case "Análisis de Riesgos":
				item.estacion = "Riesgos"
				break;
			case "Comité de Créditos":
				item.estacion = "Comité"
				break;
			case "Oficial de Negocio - Post Comite":
				item.estacion = "Post Comité"
				break;
			case "Oficial de Negocio - Liquidación":
				item.estacion = "Liquidación"
				break;
			case "Jefe de Operaciones":
				item.estacion = "Operaciones"
				break;
		}
		return item
	}

	obtenerFechas() {
		let date = new Date()
	}

	getNow(date = new Date()) {
		let anio = date.getFullYear()
		let mes = (date.getMonth() + 1) < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
		let dia = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
		return `${anio}-${mes}-${dia}`
	}

	restarDiasFecha(dias) {
		let hoy = new Date();
		hoy.setHours(0, 0, 0, 0)
		let diaEnMilisegundos = 1000 * 60 * 60 * 24;

		let resta = hoy.getTime() - (diaEnMilisegundos * dias);
		let nuevaFecha = new Date(resta)
		return this.getNow(nuevaFecha)
	}

	validacionUserMask(){
		if( this.userMasked != 'default message' ){

			return this.userMasked.id == undefined ? '' : this.userMasked.id;
		
		}else{
			return '';
		}


	}
}
