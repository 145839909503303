import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

import { AbonosService } from 'app/core/services/abonos/abonos.service';

@Component({
  selector: 'app-anular',
  templateUrl: './anular.component.html',
  styleUrls: ['./anular.component.css']
})
export class AnularComponent implements OnInit {
  @Input() abono: any = null

  forma: FormGroup
  maxFechaContable = new Date()
  motivos = []

  constructor(
    private abonosService: AbonosService,
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private spinner: NgxSpinnerService,
  ) {
    this.forma = this.formBuilder.group({
      'fecha_anulacion_contable': [new Date(), [Validators.required]],
      'motivo_anulacion': ['', [Validators.required]],
      'sustento_anulacion': [{ value: '', disabled: true }, [Validators.required]],
    })
    this.forma.get('motivo_anulacion').valueChanges.subscribe(value => {
      if (value) {
        let motivo: any = this.motivos.find((x: any) => x.id == value)
        if (motivo) {
          if (motivo.sustento) {
            this.forma.get('sustento_anulacion').enable()
          } else {
            this.forma.get('sustento_anulacion').disable()
          }
        }
      }
    })
  }

  ngOnInit(): void {
    if (!this.abono) this.activeModal.close(false)
    this.spinner.show()
    forkJoin({
      motivos: this.abonosService.getMotivosAnulacion(),
    }).subscribe(({ motivos }) => {
      this.motivos = motivos
    }).add(() => this.spinner.hide())
  }

  onSubmit() {
    if (!this.forma.valid) return
    this.spinner.show()
    let abono = Object.assign({}, this.forma.value, { id: this.abono.id })
    this.abonosService.guardarAbono(abono).subscribe(
      (data: any) => {
        this.activeModal.close(true)
      },
      res => console.log(res)
    ).add(() => this.spinner.hide())
  }

}
