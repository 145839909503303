import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { RecaudacionRoutingModule } from "./recaudacion-routing.module";
import { RecaudacionComponent } from "./pages/recaudacion/recaudacion.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { ArchwizardModule } from "angular-archwizard";
import { SharedModule } from "app/shared/shared.module";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { UiSwitchModule } from "ngx-ui-switch";
import { AddRecaudacionModalComponent } from './modals/add-recaudacion-modal/add-recaudacion-modal.component';
import { CuentasXPagarComponent } from './modals/cuentas-x-pagar/cuentas-x-pagar.component';
import { DevolverExcedentesModalComponent } from "./modals/devolver-excedentes-modal/devolver-excedentes-modal.component";
import { ImprimirRecaudacionComponent } from "./pages/recaudacion/imprimir-recaudacion/imprimir-recaudacion.component";
import { NotasCreditoRecaudacionComponent } from './modals/notas-credito-recaudacion/notas-credito-recaudacion.component';
import { NotaCreditoTableComponent } from './partials/nota-credito-table/nota-credito-table.component';
import { EditarDetalleComponent } from './modals/editar-detalle/editar-detalle.component';
import { DescartarAplicacionComponent } from './modals/descartar-aplicacion/descartar-aplicacion.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [ImprimirRecaudacionComponent,RecaudacionComponent, AddRecaudacionModalComponent, CuentasXPagarComponent, DevolverExcedentesModalComponent, NotasCreditoRecaudacionComponent, NotaCreditoTableComponent, EditarDetalleComponent, DescartarAplicacionComponent],
  entryComponents: [],
  imports: [
    CommonModule,
    RecaudacionRoutingModule,
    FontAwesomeModule,
    SharedModule,
    JwBootstrapSwitchNg2Module,
    NgbModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    ArchwizardModule,
  ],
})
export class RecaudacionModule {}
