import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { DocschecklistService } from "app/core/services/config/docschecklist.service";
import { FactoringService } from "app/core/services/factoring/factoring.service";
import { VerificationsService } from "app/core/services/factoring/verifications.service";
import { ToastService } from "app/core/services/toast/toast.service";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { ConfirmModalComponent } from "app/shared/utils/confirm-modal/confirm-modal.component";
import { BehaviorSubject, concat, Observable, of, Subject } from "rxjs";
import Swal from 'sweetalert2';
import {
  filter,
  distinctUntilChanged,
  debounceTime,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { AddCommentsModalComponent } from "../add-comments-modal/add-comments-modal.component";
import {
  faFileDownload,
  faCloudUploadAlt,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { ClientesService } from "app/core/services/clientes/clientes.service";
import { Operacion } from "app/core/services/auth/Operacion";

declare var $: any;

@Component({
  selector: "app-docs-modal",
  templateUrl: "./docs-modal.component.html",
  styleUrls: ["./docs-modal.component.css"],
})
export class DocsModalComponent implements OnInit {
  faFileDownload = faFileDownload;
  faCloudUploadAlt = faCloudUploadAlt;
  faTimes = faTimes;
  public loadingRegistroCavali : boolean = false;
  public loadingRetiroCavali : boolean = false;
  public minDate: Date;
  public f_det: FormGroup;
  public tipodoc_list: any[] = [];
  public monedas_list: any[] = [];
  public estados_list: any[] = [];
  public currency: any;
  public monedaBanco: any;
  
  @Input() legal:boolean = false;
  @Input() clienteFactoring: any;
  @Input() operacion: Operacion;
  @Input() row: any;
  @Input() readonly: boolean = false;
  @Input() set monedaDelBanco(value){
    if(!!value){
      console.log(value)
      this.monedaBanco = value
    }
  };

  get moneda(){
    return this.monedaBanco
  }
  comments: any;
  public user: any;

  public columnDefs       : columnHeader[];
  public pageTableActual  : number;
  public pageTableTotal   : number;
  public pageTablePerPages: number  = 10;
  public pageTablePages   : number[];
  public loadingTable     : boolean = false;

  @Output() docsEmit: EventEmitter<any> = new EventEmitter<any>();

  _step2: boolean = false;
  @Input() set step2(val) {
    this._step2 = val;
  }

  get step2() {
    return this._step2;
  }

  readOnlyEstado           = false;
  readOnlyBotonInteraccion = true;
  editAction               = false;
  deleteAction             = false;

  xmlReanOnly = false;
  @Output() xmlEmit: EventEmitter<any> = new EventEmitter<any>();
  @Output() pdfEmit: EventEmitter<any> = new EventEmitter<any>();

  allowed_profiles = [3, 9, 13, 14, 4, 16, 18, 19, 8, 7, 20];
  provincias       : any []  = []
  departamentos    : any []  = []
  distritos        : any []  = []
  deudorSelect     : any;
  searchTextRuc    = "";
  rucResults$      : Observable<any[]>;
  searchText$      = new Subject<string>();
  loadingSearch    : boolean = false;
  caracteresMinimos: number = 4;

  deudorSelectD   : any;
  searchTextRucD  = "";
  loadingSearch2  : boolean = false;
  rucResultsD$    : Observable<any[]>;
  searchTextD$    = new Subject<string>();

  contactosFactoring = [];

  contactListSize = 0;
  contactSize     = 10;
  loading = false;
  constructor(
    private authService        : AuthService,
    private verificacionService: VerificationsService,
    private factoringService: FactoringService,
    public formbuild           : FormBuilder,
    public activeModal         : NgbActiveModal,
    public modalService        : NgbModal,
    public configs             : DocschecklistService,
    public toast               : ToastService,
    private clientesService: ClientesService,
    private factoring          : FactoringService,
  ) {
  }

  ngOnInit(): void {
    this.getData();

    this.user = this.authService.user

    if (this.step2) {
      this.initFormClient();
    } else {
      this.initFormInterno();
    }

    if (this.row) {
      this.obtenerInteractions();
      this.deudorSelect = { id: this.row.deudor, nombre: "", ruc: "" };
      this.searchTextD$ = new BehaviorSubject<string>(this.row.contacto_deudor);
    };

    if (this.clienteFactoring) {
      this.f_det.controls.ruc_search.setValue(this.clienteFactoring.id) 
      
      this.clientesService.obtenerContactos(this.clienteFactoring.id).then(res=>{
        this.contactosFactoring = res['results']
        if (this.row) {
          this.f_det.controls.contacto_nombre_search.setValue({
            nombre: this.row.contacto_deudor,
            telefono: this.row.telefono_deudor,
          })
        }
      })
       
    } else {
      this.loadSeach();
      this.loadContactos();
    }

    if (!this.row || !this.row['vencimiento_cavali_doc'] || !Number(this.row['neto_pagar_doc'])) {
      if (this.f_det.get('vencimiento_cavali_rep')) this.f_det.get('vencimiento_cavali_rep').disable()
      if (this.f_det.get('neto_pagar_rep')) this.f_det.get('neto_pagar_rep').disable()
    }
  }

  getData(){
    this.obtenerEstados();
    let departamentos = this.clientesService.obtenerDepartamentos()
    let monedas = this.configs.obtenerMonedas()
    let tipodoc_list = this.configs.obtenerTipoDetalles()
    
    Promise.all([departamentos,monedas,tipodoc_list]).then((res: any)=>{
      console.log( res )
      this.departamentos = res[0]['results']
      this.monedas_list  = res[1]["results"];
      this.tipodoc_list  = res[2]["results"].sort(function (a, b) {
        if (a.descripcion > b.descripcion) {
            return 1;
        }
        if (a.descripcion < b.descripcion) {
            return -1;
        }
        return 0;
        });;
      this.subscribeChanges()
    })
  }

  getProvincias(departamento){
    this.clientesService.obtenerProvincias(departamento).then((res:any)=>{
      this.provincias = res['results']
      if (!this.readonly) this.f_det.get('deudor_provincia').enable()
    })
  }

  getDistrito(provincia){
    this.clientesService.obtenerDistritos(provincia).then((res:any)=>{
      this.distritos = res['results']
      if (!this.readonly) this.f_det.get('deudor_distrito').enable()
    })
  }

  /**
   * Este evento se suscribe a los cambios en el objeto que recibe la data desde el servidor
   */
  loadSeach() {
    /**
     * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
     * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
     * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value.
     * 		So that API will not hit if the current and previous value is the same
     * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
     * 		string typed by user
     */
    this.rucResults$ = concat(
      of([]), // Items predeterminados
      this.searchText$.pipe(
        filter((res) => {
          return res !== null && res.length >= this.caracteresMinimos;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.loadingSearch = true)),
        switchMap((term) => {
          return this.verificacionService.buscarDeudoresObserver(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.loadingSearch = false))
          );
        })
      )
    );
  }

  loadContactos() {
    this.rucResultsD$ = concat(
      of([]), // Items predeterminados
      this.searchTextD$.pipe(
        filter((res) => {
          return this.deudorSelect['id'];
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.loadingSearch2 = true)),
        switchMap((term) => {
          return this.verificacionService.buscarContactosDeudorObserve(term, this.deudorSelect['id'],1,this.contactSize).pipe(
            catchError(() => of([])), // empty list on error
            tap((response) => {
              this.loadingSearch2 = false;
              let newResponse: any[] = response.slice(this.contactListSize, this.contactSize + this.contactListSize);
              this.contactListSize = this.contactListSize + newResponse.length;
            })
          );
        })
      )
    );
  }

  addTagPromise(ruc) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ id: 0, nombre: "", ruc: ruc });
      }, 500);
    });
  }

  addTagPromiseD(name) {
    return new Promise((resolve) => {
      setTimeout(() => {

        let departamento = this.f_det.get("deudor_departamento")   
        let provincia    = this.f_det.get("deudor_departamento")   
        let distrito     = this.f_det.get("distrito")

        if(departamento && provincia && distrito){
          this.f_det.get("deudor_departamento").setValue(!!this.row.deudor_departamento ? this.row.deudor_departamento : null);
          this.f_det.get("deudor_provincia").setValue(!!this.row.deudor_provincia ? this.row.deudor_provincia : null);
          this.f_det.get("distrito").setValue(!!this.row.distrito ? this.row.distrito : null);
        }

        resolve({ deudor: 0, nombre: name, telefono: "" });
      }, 500);
    });
  }

  changeRucSearch(e) {
    this.searchTextRuc = e.term;
  }

  changeRucSearchD(e) {
    this.searchTextRucD = e.term;
  }
  onScrollToEnd() {
    if(this.contactListSize < this.contactSize){
      return;
    }
    this.contactSize += this.contactSize;
    this.recargarContactos();
  }
  recargarContactos(){
    this.searchTextD$ = new BehaviorSubject<string>('');
    this.loadContactos();
  }
  async obtenerDeudor(deudorId) {
    try {
      if (!deudorId) {
        this.deudorSelect = null;
        this.f_det.controls["ruc"].setValue(this.searchTextRuc);
      } else {
  
        // Si es Factoring busca Beneficiario
        let req
        if (!this.clienteFactoring) {
          this.deudorSelect = await this.verificacionService.obtenerDeudor(deudorId)
        } else {
          this.deudorSelect = await this.factoringService.obtenerBeneficiario(deudorId)

          // Tome los datos del 'beneficiario' pero tengo que poner el id del 'deudor'
          let deudor = await this.verificacionService.buscarDeudores(this.clienteFactoring.ruc)
          this.deudorSelect.id = deudor['id']
        }
  
        this.f_det.controls["ruc"].setValue(this.deudorSelect["ruc"]);
        this.f_det.controls["aceptante"].setValue(this.deudorSelect["nombre"]);
  
  
  
        if (this.clienteFactoring) {
  
          // Este codigo es para pasar de campos de texto a id (Beneficiario tiene ubigeos en texto)
          const departamento = await this.clientesService.obtenerDepartamentoPorNombre(this.deudorSelect['departamento'])
          this.deudorSelect["departamento"] = departamento['results'].find(el=>el.nombre == this.deudorSelect['departamento'])['id']
  
          const provincia = await this.clientesService.obtenerProvinciaPorNombre(this.deudorSelect['provincia'], this.deudorSelect["departamento"])
          this.deudorSelect["provincia"] = provincia['results'].find(el=>el.nombre == this.deudorSelect['provincia'])['id']
  
          const distrito = await this.clientesService.obtenerDistritoPorNombre(this.deudorSelect['distrito'], this.deudorSelect["provincia"])
          this.deudorSelect["distrito"] = distrito['results'].find(el=>el.nombre == this.deudorSelect['distrito'])['id']
                
          if (this.deudorSelect['departamento']) {
            this.getProvincias(this.deudorSelect['departamento']);
          }

          if (this.deudorSelect['provincia']) {
            this.getDistrito(this.deudorSelect['provincia']);
          }

          this.f_det.controls["deudor_departamento"].setValue(this.deudorSelect["departamento"]);
          this.f_det.controls["deudor_provincia"].setValue(this.deudorSelect["provincia"]);
          this.f_det.controls["deudor_distrito"].setValue(this.deudorSelect["distrito"]);
  
        } else {
          if (this.deudorSelect['departamento']) {
            this.getProvincias(this.deudorSelect['departamento']);
          }
  
          if (this.deudorSelect['provincia']) {
            this.getDistrito(this.deudorSelect['provincia']);
          }
  
          this.f_det.controls["deudor_departamento"].setValue(this.deudorSelect["departamento"]);
          this.f_det.controls["deudor_provincia"].setValue(this.deudorSelect["provincia"]);
          this.f_det.controls["deudor_distrito"].setValue(this.deudorSelect["distrito"]);
        }
  
        this.recargarContactos();
      }
    } catch (err) {
      console.log(err)
    }
    
  }

  obtenerContatos(contactoId) {
    if (!contactoId) {
      this.deudorSelectD = null;
      this.f_det.controls["contacto_deudor"].setValue(this.searchTextRucD);
    } else {
      this.verificacionService
        .obtenerContacto(contactoId)
        .then((res) => {
          this.deudorSelectD = res;
          this.f_det.controls["contacto_deudor"].setValue(res["nombre"]);
          this.f_det.controls["telefono_deudor"].setValue(res["telefono"]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  initForm() {
    this.configs.obtenerMonedas().then((res) => {
      this.monedas_list = res["results"];
    });

    this.configs.obtenerTipoDetalles().then((res) => {
      this.tipodoc_list = res["results"];
    });

    this.f_det = this.formbuild.group({
      aceptante       : [this.row.aceptante, []],
      ruc             : [this.row.ruc, []],
      det             : [this.row.det, []],
      ret             : [this.row.ret, []],
      aval            : [this.row.aval, []],
      fisico          : [this.row.aval, []],
      moneda          : [this.row.moneda, []],
      tipo_detalle    : [this.row.tipo_detalle, []],
      numero_documento: [this.row.numero_documento, []],
      contacto_deudor : [this.row.contacto_deudor, []],
      telefono_deudor : [this.row.telefono_deudor, []],
      vencimiento     : [new Date(this.row.vencimiento), []],
      monto_total     : [this.row.monto_total, []],
      estado          : [this.row.estado, []],
      deudor_departamento          : [this.row.deudor_departamento ? this.row.deudor_departamento : null, []],
      deudor_provincia          : [{ value : this.row.deudor_provincia ? this.row.deudor_provincia : null , disabled: true}, []],
      deudor_distrito          : [{ value : this.row.deudor_distrito ? this.row.deudor_distrito : null, disabled: true}, []],
    });

    this.f_det.valueChanges.subscribe((value) => {
      if (value.moneda) {
        this.currency = {
          prefix: value.moneda === 1 ? "S/ " : "$ ",
          thousands: ".",
          decimal: ",",
        };
      }
    });

    this.minDate = new Date();
  }

  initFormClient() {
    if (this.row) {
      this.f_det = this.formbuild.group({
        aceptante       : [this.row.aceptante, [Validators.required]],
        ruc             : [this.row.ruc, [Validators.required]],
        tipo_detalle    : [this.row.tipo_detalle, [Validators.required]],
        numero_documento: [this.row.numero_documento, [Validators.required]],
        vencimiento     : [
          this.parseDate(this.row.vencimiento),
          [Validators.required],
        ],
        monto_total     : [this.row.monto_total, [Validators.nullValidator]],
        estado          : [this.row.estado, [Validators.required]],
        moneda          : [{value : this.row.moneda, disabled: true}, [Validators.required]],
        ruc_search      : [""],

        deudor_departamento: [!!this.row.deudor_departamento ? this.row.deudor_departamento: null, [Validators.required]],
        deudor_provincia   : [!!this.row.deudor_provincia ? this.row.deudor_provincia      : null, [Validators.required]],
        deudor_distrito    : [!!this.row.deudor_distrito ? this.row.deudor_distrito        : null, [Validators.required]],
      });
      this.readOnlyEstado = true;

      if (this.row.xml) {
        this.xmlReanOnly = true;
      }
    } else {
      this.f_det = this.formbuild.group({
        aceptante          : [null, [Validators.required]],
        ruc                : [null, [Validators.required]],
        tipo_detalle       : [null, [Validators.required]],
        numero_documento   : [null, [Validators.required]],
        vencimiento        : [null, [Validators.required]],
        deudor_provincia   : [{ value: null, disabled            : true}, [Validators.required]],
        deudor_distrito    : [{ value: null, disabled            : true}, [Validators.required]],
        deudor_departamento: [null, [Validators.required]],
        monto_total        : [null, [Validators.nullValidator]],
        estado             : [1, [Validators.required]],
        moneda             : [{value : this.monedaBanco, disabled: true}, [Validators.required]],
        ruc_search         : [""],
      });

      this.readOnlyEstado = true;

      this.f_det.controls.ruc_search.valueChanges.subscribe((value) => {
        if (this.operacion['tipo_linea_solicitada'] == 1) {
          this.obtenerDeudor(value);
        } else if (this.clienteFactoring) {
          this.obtenerDeudor(this.clienteFactoring.id);
        }
      });
      
    }

    this.f_det.valueChanges.subscribe((value) => {
      if (value.moneda) {
        this.currency = {
          prefix: value.moneda === 1 ? "S/ " : "$ ",
          thousands: ".",
          decimal: ",",
        };
      }
    });

    this.minDate = new Date();
  }

  initFormInterno() {
    if (this.row) {
      this.f_det = this.formbuild.group({
        ruc: [
          { value: this.row.ruc, disabled: this.readonly },
          [Validators.required],
        ],
        aceptante: [
          { value: this.row.aceptante, disabled: this.readonly },
          [Validators.required],
        ],
        det: [
          { value: this.row.det, disabled: this.readonly },
          [Validators.required],
        ],
        ret: [
          { value: this.row.ret, disabled: this.readonly },
          [Validators.required],
        ],
        aval: [
          { value: this.row.aval, disabled: this.readonly },
          [Validators.required],
        ],
        fisico: [
          { value: this.row.fisico, disabled: this.readonly },
          [Validators.required],
        ],
        moneda: [
          { value: this.row.moneda, disabled: true },
          [Validators.required],
        ],
        tipo_detalle: [
          { value: this.row.tipo_detalle, disabled: this.readonly },
          [Validators.required],
        ],
        numero_documento: [
          { value: this.row.numero_documento, disabled: this.readonly },
          [Validators.required],
        ],
        contacto_deudor: [
          { value: this.row.contacto_deudor, disabled: this.readonly },
          [],
        ],
        telefono_deudor: [
          { value: this.row.telefono_deudor, disabled: this.readonly },
          [],
        ],
        vencimiento: [
          {
            value: this.parseDate(this.row.vencimiento),
            disabled: this.readonly,
          },
          [Validators.required],
        ],
        monto_total: [
          { value: this.row.monto_total, disabled: this.readonly },
          [Validators.nullValidator],
        ],
        estado: [
          { value: this.row.estado, disabled: this.readonly },
          [Validators.required],
        ],
        vencimiento_cavali_doc: [
          {
            value: this.parseDate(this.row.vencimiento_cavali_doc),
            disabled: this.readonly,
          },
          [],
        ],
        vencimiento_cavali_rep: [
          {
            value: this.parseDate(this.row.vencimiento_cavali_rep),
            disabled: this.readonly,
          },
          [],
        ],
        neto_pagar_doc: [
          { 
            value: this.row.neto_pagar_doc, 
            disabled: this.readonly 
          },
          [],
        ],
        neto_pagar_rep: [
          { 
            value: this.row.neto_pagar_rep, 
            disabled: this.readonly 
          },
          [],
        ],

        deudor_departamento: [
          { value: !!this.row.deudor_departamento ? this.row.deudor_departamento : null, disabled: this.readonly },
          [Validators.nullValidator],
        ],
        deudor_provincia: [
          { value: !!this.row.deudor_provincia ? this.row.deudor_provincia : null, disabled: this.readonly },
          [Validators.nullValidator],
        ],
        deudor_distrito: [
          { value: !!this.row.deudor_distrito ? this.row.deudor_distrito : null, disabled: this.readonly },
          [Validators.nullValidator],
        ],

        ruc_search: [""],
        contacto_nombre_search        : [this.row.deudor_contacto],
      });

      if (this.row.xml) {
        this.xmlReanOnly = true;
      }
      if (this.user.isOficialDeNegocio) {
        this.readOnlyEstado = true;
      }
      this.f_det.controls.contacto_nombre_search.valueChanges.subscribe((value) => {
        if (this.clienteFactoring && typeof(value) == 'object') { 
          // value es un objeto
          this.f_det.controls.contacto_deudor.setValue(value.nombre)
          this.f_det.controls.telefono_deudor.setValue(value.telefono)
        } else { 
          // value es un numero
          this.obtenerContatos(value);
        }
      });

    } else {
      this.f_det = this.formbuild.group({
        aceptante         : [null, [Validators.required]],
        ruc               : [null, [Validators.required]],
        det               : [false, [Validators.required]],
        ret               : [false, [Validators.required]],
        aval              : [false, [Validators.required]],
        fisico              : [false, [Validators.required]],
        tipo_detalle      : [null, [Validators.required]],
        numero_documento  : [null, [Validators.required]],
        contacto_deudor   : ['', []],
        telefono_deudor   : ['', []],
        vencimiento       : [null, [Validators.required]],
        monto_total       : [null, [Validators.nullValidator]],
        estado            : [1, [Validators.required]],
        moneda            : [{value : this.monedaBanco, disabled : true}, [Validators.required]],
        vencimiento_cavali_doc: [null, []],
        vencimiento_cavali_rep: [null, []],
        neto_pagar_doc    : [0, []],
        neto_pagar_rep    : [null, []],
        ruc_search        : [""],
        contacto_nombre_search        : [""],
        deudor_provincia     : [{value: null, disabled: true}, [Validators.required]],
        deudor_distrito     : [{value: null, disabled: true}, [Validators.required]],
        deudor_departamento     : [null, [Validators.required]],
      });

      this.readOnlyEstado = true;

      this.f_det.controls.ruc_search.valueChanges.subscribe((value) => {
        this.obtenerDeudor(value);
      });

      this.f_det.controls.contacto_nombre_search.valueChanges.subscribe((value) => {
        if (this.clienteFactoring && typeof(value) == 'object') { 
          // value es un objeto
          this.f_det.controls.contacto_deudor.setValue(value.nombre)
          this.f_det.controls.telefono_deudor.setValue(value.telefono)
        } else { 
          // value es un numero
          this.obtenerContatos(value);
        }
      });
    }

    this.f_det.valueChanges.subscribe((value) => {
      if (value.moneda) {
        this.currency = {
          prefix: value.moneda === 1 ? "S/ " : "$ ",
          thousands: ".",
          decimal: ",",
        };
      }
    });

    this.minDate = new Date();

    if (!this.allowed_profiles.includes(this.user.perfil)) {
      this.f_det.controls["vencimiento_cavali_doc"].disable();
      this.f_det.controls["vencimiento_cavali_rep"].disable();
      this.f_det.controls["deudor_departamento"].disable();
      this.f_det.controls["neto_pagar_doc"].disable();
      this.f_det.controls["neto_pagar_rep"].disable();
    }
  }

  setFormInterno() {
    this.f_det.controls["aceptante"].setValue(this.row.aceptante);
    this.f_det.controls["ruc"].setValue(this.row.ruc);
    this.f_det.controls["det"].setValue(this.row.det);
    this.f_det.controls["ret"].setValue(this.row.ret);
    this.f_det.controls["aval"].setValue(this.row.aval);
    this.f_det.controls["fisico"].setValue(this.row.fisico);
    this.f_det.controls["moneda"].setValue(this.row.moneda);
    this.f_det.controls["tipo_detalle"].setValue(this.row.tipo_detalle);
    this.f_det.controls["numero_documento"].setValue(this.row.numero_documento);
    this.f_det.controls["contacto_deudor"].setValue(this.row.contacto_deudor);
    this.f_det.controls["telefono_deudor"].setValue(this.row.telefono_deudor);
    this.f_det.controls["vencimiento"].setValue(this.row.vencimiento);
    this.f_det.controls["monto_total"].setValue(this.row.monto_total);
    this.f_det.controls["estado"].setValue(this.row.estado);
    this.f_det.controls["deudor_departamento"].setValue(!!this.row.deudor_departamento ? this.row.deudor_departamento : null);
    this.f_det.controls["deudor_provincia"].setValue(!!this.row.deudor_provincia ? this.row.deudor_provincia : null);
    this.f_det.controls["distrito"].setValue(!!this.row.distrito ? this.row.distrito : null);
    this.f_det.controls["vencimiento_cavali_doc"].setValue(this.row.vencimiento_cavali_doc);
    this.f_det.controls["vencimiento_cavali_rep"].setValue(this.row.vencimiento_cavali_rep);
    this.f_det.controls["neto_pagar_doc"].setValue(this.row.neto_pagar_doc);
    this.f_det.controls["neto_pagar_rep"].setValue(this.row.neto_pagar_rep);
  }

  obtenerEstados() {
    this.verificacionService
      .obtenerEstados()
      .then((result) => {
        this.estados_list = result["results"];
      })
      .catch((error) => {
        console.log(error);
      });
  }

  obtenerInteractions(page = 1, page_size = 10) {
    const document = this.row ? this.row.id : 0;
    this.verificacionService
      .obtenerComments(page, page_size, document)
      .then((result) => {
        console.log( 'DATOS', result )
        this.comments = result["results"];
        this.loadTable(result);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadTable(data) {
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];

    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }

    this.columnDefs = [
      {
        headerName: "Fecha",
        field: "fecha",
        sortable: true,
        class: "text-center",
        pipe: "date-time",
      },
      {
        headerName: "Responsable",
        field: "responsable_nombre",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Comentario",
        field: "comentario",
        sortable: true,
        class: "text-center th-width",
      },
    ];
  }

  goToPage({ page, per_page, filtros }) {
    console.log(page, per_page);
    this.obtenerInteractions(page, per_page);
  }

  editComment(comment) {
    const modalRef = this.modalService.open(AddCommentsModalComponent, {});

    modalRef.componentInstance.row = this.row;
    modalRef.componentInstance.comment = comment;

    modalRef.result.then(
      (result) => {
        console.log(result);
        if (result) {
          this.obtenerInteractions();
          this.toast.success("Comentario editado correctamente.!");
        }
      },
      (reason) => {
        console.log(reason);
        this.toast.warning("Ha ocurrido un error!");
      }
    );
  }

  deleteComment(comment) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {});

    modalRef.componentInstance.title = "Remover Comentario";
    modalRef.componentInstance.message =
      "¿Seguro que desea remover el comentario?";

    modalRef.result.then(
      (result) => {
        if (result) {
          this.verificacionService
            .eliminarComentario(comment.id)
            .then((result) => {
              this.toast.success("Comentario eliminado correctamente.!");
              this.obtenerInteractions();
            })
            .catch((error) => {
              console.error(error);
              this.toast.warning("Ha ocurrido un error!");
            });
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  async onSubmit() {
    if (this.f_det.valid) {
      var values = this.f_det.value;

      if (!this.isValidVencimiento('vencimiento', 'Vencimiento')) return
      if (!this.isValidVencimiento('vencimiento_cavali_doc', 'Vencimiento cavali')) return
      if (!this.isValidVencimiento('vencimiento_cavali_rep', 'Vencimiento cavali reprogramado')) return
      if (values['vencimiento_cavali_rep']) {
        if (values['vencimiento_cavali_rep'] <= values['vencimiento_cavali_doc']) {
          this.toast.warning(`La fecha de "reprogramación" debe ser posterior a la fecha "vencimiento cavali"`)
          return
        }
      }
      if (values['neto_pagar_rep']) {
        if (Number(values['neto_pagar_rep']) >= Number(values['neto_pagar_doc'])) {
          this.toast.warning(`El monto neto de "reprogramación" debe ser menor al neto "inicial"`)
          return
        }
        if (Number(values['neto_pagar_rep']) <= 0) {
          this.toast.warning(`El monto neto de "reprogramación" debe ser mayor a cero`)
          return
        }
      }
      let cambioDatosIniciales = false
      if (values['vencimiento_cavali_doc']) {
        let vencimientoNew = values['vencimiento_cavali_doc'].getTime()
        let vencimientoOld:any = this.row ? this.parseDate(this.row['vencimiento_cavali_doc']) : null
        vencimientoOld = vencimientoOld ? vencimientoOld.getTime() : null
        if (vencimientoOld && vencimientoOld != vencimientoNew) {
          cambioDatosIniciales = true
        }
      }
      if (values['neto_pagar_doc']) {
        if (this.row && Number(this.row['neto_pagar_doc']) && Number(values['neto_pagar_doc']) != Number(this.row['neto_pagar_doc'])) {
          cambioDatosIniciales = true
        }
      }
      if (cambioDatosIniciales) {
        const confirmCambioDatosIniciales = await Swal.fire({
          title: '¿Está seguro?',
          html: `
            Estas modificando vencimiento cavali y/o neto del documento, estos valores no se envían como reprogramación a cavali.
            <br />¿Desea continuar?<br />
            <br /><small>Si quiere hacer reprogramación en cavali, cancele y cambie los valores en los campos de reprogramación. Si esta cambiando los datos iniciales del documento, continue.</small>
            `,
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085D6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, continuar',
          cancelButtonText: 'Cancelar',
        })
        if (!confirmCambioDatosIniciales.isConfirmed) return
      }
      
      values['moneda'] = this.f_det.get('moneda').value
      // values["vencimiento"] = this.formatDate(this.f_det.value["vencimiento"]);
      values["vencimientox"] = this.formatDate(this.f_det.value["vencimiento"]);

      values.vencimiento_cavali
        ? (values["vencimiento_cavalix"] = this.formatDate(
            this.f_det.value["vencimiento_cavali"]
          ))
        : console.log("no hay cavali");

      if (values.vencimiento_cavali_doc) values["vencimiento_cavali_doc"] = this.formatDate(this.f_det.value["vencimiento_cavali_doc"]);
      if (values.vencimiento_cavali_rep) values["vencimiento_cavali_rep"] = this.formatDate(this.f_det.value["vencimiento_cavali_rep"]);
      if (values.neto_pagar_rep === '') values["neto_pagar_rep"] = null;

      this.deudorSelect
        ? (this.f_det.value["deudor"] = this.deudorSelect.id)
        : "";
      
      this.deudorSelectD
        ? (this.f_det.value["deudor_contacto"] = this.deudorSelectD.id)
        : "";

      if (this.clienteFactoring) {
        if (this.deudorSelect) {
          console.log("A", this.f_det.value.deudor)
          console.log("DEUDOR:", this.f_det.value)
          this.factoringService.buscarDeudorPorRuc(this.deudorSelect.ruc || this.f_det.value.ruc).then(res=>{
            if (res['results'].length > 0) {
              this.f_det.value["deudor"] = res['results'][0]['id']
            } else {
              delete this.f_det.value["deudor"]
            }

            console.log(res)
            console.log("B", this.f_det.value.deudor)
            
            this.docsEmit.emit(this.f_det.value);
            this.activeModal.close(false);
          })
        } else {
          this.docsEmit.emit(this.f_det.value);
          this.activeModal.close(false);
        }
      } else {
        this.docsEmit.emit(this.f_det.value);
        this.activeModal.close(false);
      }


    }
  }

  private isValidVencimiento(field, label, required=false) {
    let values = this.f_det.value
    if (values[field]) {
      let hoy = new Date(new Date().setHours(0,0,0)).getTime()
      let vencimientoNew = values[field].getTime()
      let vencimientoOld:any = this.row ? this.parseDate(this.row[field]) : null
      vencimientoOld = vencimientoOld ? vencimientoOld.getTime() : null
      if (vencimientoOld != vencimientoNew && vencimientoNew < hoy) {
        this.toast.warning(`La fecha de "${label}" no debe ser anterior a la fecha de hoy`)
        return false
      }
    } else {
      if (required) {
        this.toast.warning(`La fecha de "${label}" no debe ser vacío`)
        return false
      }
    }
    return true
  }

  openModalComment(comment = null) {
    const modalRef = this.modalService.open(AddCommentsModalComponent, {});

    modalRef.componentInstance.row = this.row;
    modalRef.componentInstance.comment = comment;
    modalRef.componentInstance.user = this.user;

    modalRef.result.then(
      (result) => {
        if (result) {
          this.obtenerInteractions();
          this.toast.success("Comentario agregado correctamente.!");
        }
      },
      (reason) => {
        console.log(reason);
        this.toast.warning("Ha ocurrido un error!");
      }
    );
  }

  parseDate(date) {
    if (!date) return null;

    let dateNow = new Date();

    let yy = date.split("-")[0];
    let mm = date.split("-")[1] - 1;
    let dd = date.split("-")[2];

    let d = new Date(yy, mm, dd, dateNow.getHours());
    return d;
  }

  importXML() {
    document.getElementsByName("sendXml")[0].click();
  }

  importPdf() {
    document.getElementsByName("sendPdf")[0].click();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const type = event.target.files[0].type.split("/")[1];
      if (type != "xml") {
        this.toast.warning("El formato del documento no es XML");
        return;
      }

      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("adjunto", file);
      formData.append("operacion_detalle", this.row.id);

      this.xmlEmit.emit(formData);
      this.activeModal.close(false);
    }
  }

  onFileSelect2(event) {
    if (event.target.files.length > 0) {
      // Validar sea PDF
      const type = event.target.files[0].type.split("/")[1];
      if (type != "pdf") {
        this.toast.warning("El formato del documento no es PDF");
        return;
      }

      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("adjunto_pdf", file);
      formData.append("peso", file.size);

      this.pdfEmit.emit(formData);
      this.activeModal.close(false);
    }
  }

  editaComentario(comment) {
    const values = {
      operacion_detalle: this.row.id,
      // responsable: this.row.analista_operacion,
      comentario: comment.comentario,
    };

    this.verificacionService
      .editarComentario(comment.id, values)
      .then((result) => {
        this.obtenerInteractions();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  formatDate(date) {
    if (!(date instanceof Date)) return;
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  nuevoComentario(event){
    let data = {
      operacion_detalle: this.row.id,
      responsable: this.authService.user.id,
      comentario: event,
    }
    this.verificacionService.crearComentario(data)
    .then(result => {
      console.log(result);
      this.obtenerInteractions();
    })
    .catch(error => {
      console.error(error);
      this.activeModal.close(false);
    });
  }

  registroCavali(){
    this.loadingRegistroCavali = true
    console.log('this.loadingRegistroCavali', this.loadingRegistroCavali )
    this.configs.registrarCavali(this.row.id).then((res : any)=>{
      console.log(res)
    }).catch((err)=>{
      if(err.status && err.status == 400){
        if(err.error && err.error.facturas){
          this.toast.warning(err.error.facturas[0])
        }
        
        if(err.error && err.error.cliente_ubigeo){
          this.toast.warning(err.error.cliente_ubigeo[0])
        }
      }
    }).finally(()=>{
      this.loadingRegistroCavali = false
    })
  }
  
  retiroCavali(){
    this.loadingRetiroCavali = true
    this.configs.retirarCavali(this.row.id).then((res : any)=>{
      console.log(res)
      this.row.proceso_cavali = res.id
    }).catch((err)=>{
      if(err.status && err.status == 400){
        if(err.error && err.error.facturas){
          this.toast.warning(err.error.facturas[0])
        }

        if(err.error && err.error.cliente_ubigeo){
          this.toast.warning(err.error.cliente_ubigeo[0])
        }
      }
    }).finally(()=>{
      this.loadingRetiroCavali = false
    })
  }

  resetContact() {
    this.f_det.controls['contacto_nombre_search'].setValue('');
    this.f_det.controls['telefono_deudor'].setValue('');
    if(this.row && (this.contactSize != this.contactListSize)){
      this.recargarContactos();
    }
  }

  subscribeChanges(){
    this.f_det.get('deudor_departamento').valueChanges.subscribe((value)=>{
      if(value){
        this.getProvincias(value);
      }
    });

    this.f_det.get('deudor_provincia').valueChanges.subscribe((value)=>{
      if(value){
        this.getDistrito(value);
      }
    });

    if (this.row) {
      
      if (this.row['deudor_departamento']) {
        this.getProvincias(this.row['deudor_departamento']);
      }
  
      if (this.row['deudor_provincia']) {
        this.getDistrito(this.row['deudor_provincia']);
      }

    }

  }

}
