import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { ToastService } from 'app/core/services/toast/toast.service';

@Component({
  selector: 'app-formato-laft',
  templateUrl: './formato-laft.component.html',
  styleUrls: ['./formato-laft.component.css']
})
export class FormatoLAFTComponent implements OnInit {
  
  myForm: FormGroup;
  estaciones: any[];


  @Input() beneficiario: number;
  @Input() operacion;
  @Input() beneficiario_tipo;
  @Input() formato_plantilla_url;


  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private formatoService: FormatosService,
    private clientesService : ClientesService,
    private factoringService: FactoringService,
    public toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  async createForm() {
    const clienteId = (this.operacion.operacion.tipo_linea_solicitada == 1) ? this.operacion.beneficiario : this.operacion.operacion.beneficiario_factoring;

    this.myForm = this.formBuilder.group({
      responsable: [{ value: '', disabled: true }, Validators.required],
      nombre: [{ value: '', disabled: true }],
      dni: [{ value: '', disabled: true }],
      ruc: [{ value: '', disabled: true }],
      tipo_persona: [{ value: this.beneficiario_tipo == '' ? 'J' : this.beneficiario_tipo, disabled: true }, Validators.required]
    });

    const cliente = await this.clientesService.obtenerCliente(clienteId)
    const operacionBandeja = await this.factoringService.obtenerOperacionBandeja(this.operacion.id)
    
    if (this.operacion.operacion.tipo_linea_solicitada == 2) {
      this.beneficiario_tipo = cliente['tipo_persona'] == 1 ? 'N' : 'J'
    }
    this.myForm.controls.tipo_persona.setValue(this.beneficiario_tipo)


    this.myForm.controls.nombre.setValue(cliente['nombre'])
    this.myForm.controls.dni.setValue(cliente['dni'])
    this.myForm.controls.ruc.setValue(cliente['ruc'])
    this.myForm.controls.responsable.setValue(operacionBandeja['analista_riesgo_nombre'])
    if (this.beneficiario_tipo != 'N') { // J
      this.myForm.controls.ruc.setValidators(Validators.required) 
      this.myForm.controls.dni.clearValidators()
    } else {
      this.myForm.controls.ruc.clearValidators()
      this.myForm.controls.dni.setValidators(Validators.required) 
    }
    this.myForm.controls.ruc.updateValueAndValidity({onlySelf: true})
    this.myForm.controls.dni.updateValueAndValidity({onlySelf: true})
    
    console.log("ACA")
  }

  onSubmit() {
    console.log(this.myForm)

    if (!this.myForm.invalid) {
      var model = {
        "archivo_plantilla": this.formato_plantilla_url,
        "nombre": this.myForm.get("nombre").value,
        "dni": this.myForm.get("dni").value,
        "ruc": this.myForm.get("ruc").value,
        "responsable": this.myForm.get("responsable").value,
      
        "tipo_persona": this.myForm.get("tipo_persona").value, // 'N' o 'J' }
      }

      this.formatoService.generarFormatoLAFT(model).then(
        (x: any) => {
          var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "LAFT.xlsx";
          link.click();
          this.activeModal.close(model);
        }, (reason) => {
          this.activeModal.close(model);
        });
    }
    else {
      this.toast.warning("El formulario no está completo.");
    }
  }

}
