import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { AddContactComponent } from 'app/modules/clientes/modals/add-contact/add-contact.component';
import { ShowOperacionesComponent } from 'app/modules/clientes/modals/show-operaciones/show-operaciones.component';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-contact-cliente',
  templateUrl: './contact-cliente.component.html',
  styleUrls: ['./contact-cliente.component.css']
})
export class ContactClienteComponent implements OnInit {

	public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public searchstring     : string = '';
  
  	@Input() clienteId = 0;
	@Input() readonly = false;

	constructor(
		private clientesService	: ClientesService,
		public modalService     : NgbModal,
	) { }

	ngOnInit(): void {
		this.obtenerContactos();
	}

	obtenerContactos(page = 1, page_size = 10) {
		this.clientesService.obtenerContactos(this.clienteId.toString(), page, page_size)
		.then(res => {
			this.initVars();
			this.loadTable(res);
		})
		.catch(error => {
			console.error(error);
		})
	}

	loadTable(data) {

		this.rowData           = data.results;
		this.pageTableActual   = data.page_number;
		this.pageTableTotal    = data.count;
		this.pageTablePerPages = data.per_page;
		this.pageTablePages = [];
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index);
		}

	}

	initVars() {
		let columns = [
			{
				headerName: '', 
				field     : '' ,
				sortable  : true,
			},
			{
				headerName: 'Contacto', 
				field     : 'nombre' ,
				sortable  : true,
			},
			{
				headerName: 'Teléfono', 
				field     : 'telefono' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Email', 
				field     : 'email' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Cargo', 
				field     : 'cargo' ,
				sortable  : true,
			},
			{
				headerName: 'Rep. Legal', 
				field     : 'representante_legal' ,
				sortable  : true,
				pipe      : 'checkbox',
				checkDisabled: true
			},
			{
				headerName: 'Nacionalidad', 
				field     : 'nacionalidad_nombre',
				class     : 'text-center',
			},
		];

		this.columnDefs = columns;
	}

	tableEditEvent(cantact) {
		const modalRef = this.modalService.open(AddContactComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;
		modalRef.componentInstance.contactId = cantact.id;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerContactos();
		},
		error => {

		});
	}

	goToPage({page,per_page,filtros}) {
		this.obtenerContactos(page, per_page);
	}

	openModal() {

		const modalRef = this.modalService.open(AddContactComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerContactos();
		},
		error => {

		});

	}
	
	destroyContact(cantact) {
		const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Contacto';
		modalRef.componentInstance.message = '¿Seguro que desea remover el contacto?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.clientesService.elimiarContacto(cantact.id)
			.then(result => {
			this.obtenerContactos();
			})
			.catch(error => {
			console.error(error);
			if (error.status == 403) {
				this.clientesService.obtenerFirmante(cantact.id, 1000).then((result) => {
					console.log(result)
					
					this.clientesService.spinnerOn()

					const operaciones = Promise.all(result["results"].map(firmante=>this.clientesService.obtenerOperaciones(firmante.contrato, 1000)))
					
					// TODO: Mostrar modal con operaciones relacionadas
					operaciones.then(res => {
						this.clientesService.spinnerOff()
						const operaciones = []
						res.map(firmante=> {
							firmante["results"].map(operacion=>{
								operaciones.push(operacion.id)
							})
						})
						const modalRef = this.modalService.open(ShowOperacionesComponent, {
							size: "lg",
						});
						modalRef.componentInstance.operaciones = operaciones
						modalRef.componentInstance.errorMessage = "No es posible eliminar el contacto"
						modalRef.componentInstance.infoMessage = "Es firmante en contratos de las siguientes operaciones:"
					})
					
				}).catch((error) => {
					console.log(error)
				})
			}
			});
		}
		}, (reason) => {
			console.log(reason);
		});
	}

}
