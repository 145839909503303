import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AceptantesRoutingModule } from "./aceptantes-routing.module";
import { AceptantesComponent } from "./pages/aceptantes/aceptantes.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgSelectModule } from "@ng-select/ng-select";
import { ArchwizardModule } from "angular-archwizard";
import { SharedModule } from "app/shared/shared.module";
import { JwBootstrapSwitchNg2Module } from "jw-bootstrap-switch-ng2";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { UiSwitchModule } from "ngx-ui-switch";
import { AddAceptanteComponent } from "./pages/add-aceptante/add-aceptante.component";
import { DataComponent } from "./partials/data/data.component";
import { ProvidersComponent } from "./partials/providers/providers.component";
import { ArchivesComponent } from "./partials/archives/archives.component";
import { ContactsComponent } from "./partials/contacts/contacts.component";
import { LinksCompaniesComponent } from "./partials/links-companies/links-companies.component";
import { AddContactModalComponent } from "./modals/add-contact-modal/add-contact-modal.component";
import { AddEntidadVinculoModalComponent } from "./modals/add-entidad-vinculo-modal/add-entidad-vinculo-modal.component";
import { AddArchiveModalComponent } from "./modals/add-archive-modal/add-archive-modal.component";
import { AddProveedoresComponent } from './modals/add-proveedores/add-proveedores.component';
import { EmpresasAceptantesComponent } from './partials/empresas-aceptantes/empresas-aceptantes.component';
import { AceptantesSinRiesgosComponent } from './pages/aceptantes-sin-riesgos/aceptantes-sin-riesgos.component';
import { CriteriosRiesgosComponent } from './modals/criterios-riesgos/criterios-riesgos.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AceptantesComponent,
    AddAceptanteComponent,
    AddAceptanteComponent,
    AddContactModalComponent,
    AddEntidadVinculoModalComponent,
    AddArchiveModalComponent,
    DataComponent,
    ProvidersComponent,
    ArchivesComponent,
    ContactsComponent,
    LinksCompaniesComponent,
    AddProveedoresComponent,
    EmpresasAceptantesComponent,
    AceptantesSinRiesgosComponent,
    CriteriosRiesgosComponent,
  ],
  entryComponents: [],
  imports: [
    CommonModule,
    AceptantesRoutingModule,
    FontAwesomeModule,
    SharedModule,
    JwBootstrapSwitchNg2Module,
    NgbModule,
    NgSelectModule,
    BsDatepickerModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    ArchwizardModule,
  ],
})
export class AceptantesModule {}
