import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { AceptantesService } from 'app/core/services/factoring/aceptantes.service';
import { BloquearModalComponent } from 'app/shared/bloquear-modal/bloquear-modal.component';
import {
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { AuthService } from 'app/core/services/auth/auth.service';
@Component({
  selector: 'app-add-cliente',
  templateUrl: './add-cliente.component.html',
  styleUrls: ['./add-cliente.component.css']
})
export class AddClienteComponent implements OnInit {
  faExclamationTriangle = faExclamationTriangle;
  active = 1;
  clienteID = 0;
  bloqueado: boolean = false;
  entidadData: any;

  constructor(
    private aceptantesService	: AceptantesService,
    private clientesService	: ClientesService,
    private modalService: NgbModal,
    private _activatedRoute  : ActivatedRoute,
    private authService : AuthService
  ) {

    let obtenerIdOperacion = new Promise((resolve,reject)=>{
			this._activatedRoute.params.subscribe(({id}) => {
				if(id){
          this.clienteID = id;
          this.loadData(id)
				}
			})
		 });

   }

  ngOnInit(): void {
  }

  loadData(id){
    this.aceptantesService.obtenerClienteBiId(id).then(( res:any )=>{
      this.entidadData = res
      this.bloqueado   = res.bloqueado
    })
  }

  bloquearCliente(){

    if(this.bloqueado){
      let bodyUnblock = {
        bloqueado      : false,
        motivo_bloqueo : ' ',
        fecha_bloqueo  : null,
        usuario_bloqueo: null,        
      }
      this.updateBlockState(bodyUnblock)
      return
    }

    const modalRef = this.modalService.open(BloquearModalComponent, {
      size: "lg",
    });

    modalRef.componentInstance.title       = 'Bloqueo de Cliente'
    modalRef.componentInstance.entidad     = 'cliente'
    modalRef.componentInstance.entidadData = this.entidadData
  
      modalRef.result.then((bloqueoData) => {
        if (bloqueoData) {
          bloqueoData.bloqueado = true
          this.updateBlockState(bloqueoData)
        } else {
          this.bloqueado = false
        }
      });

  }

  updateBlockState(bloqueoData){
    this.clientesService.updateBloqueoUsuario(bloqueoData, this.clienteID).then((res: any)=>{
      this.loadData(this.clienteID)
    })
  }

  get canEdit() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilOficialDeNegocioID,
      this.authService.perfil.perfilLegalID,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaRiesgosID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilAsistenteCobranzasID,
      this.authService.perfil.perfilAsesorLegalID,
      this.authService.perfil.perfilJefeDeAdmisionID,
      this.authService.perfil.perfilLiderDeEquipo,
      this.authService.perfil.perfilAsistenteComercialID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  get canBlock() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilJefeDeAdmisionID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

}
