import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';

@Component({
  selector: 'app-manage-bancos',
  templateUrl: './manage-bancos.component.html',
  styleUrls: ['./manage-bancos.component.css']
})
export class ManageBancosComponent implements OnInit {

  myForm: FormGroup;
  @Input() bancoId = 0;
  banco: any;
  faTimes = faTimes;
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild              : FormBuilder,
    public activeModal            : NgbActiveModal,
    private configurationService	: ConfigurationService,
  ) {
    this.initForm();
   }

  ngOnInit(): void {
    if (this.bancoId) {
      this.obtenerBanco();
    }
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre: [null, [Validators.required]],
      codigo: [null, []],
    });
  }
  
  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  obtenerBanco() {
    this.configurationService.obtenerBanco(this.bancoId)
    .then(res => {
      this.banco = res;
      this.setForm(res);
    })
    .catch(error => {
      console.error(error);
    });
  }

  onSubmit() {
    if(this.myForm.valid) {
      const data = this.myForm.value;

      this.configurationService.guardarBanco(this.myForm.value, this.bancoId)
      .then(res => {
        this.successEmit.emit();
        this.activeModal.close(true);
      }).
      catch(error => {
        this.activeModal.close(false);
      });
    }
  }

}
