import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LocalServiceService } from '../services/local-service.service';

@Injectable({
  providedIn: 'root'
})
export class ChangePasswordGuard implements CanActivate {

   constructor(
    private router: Router,
    public localService: LocalServiceService
   ){
   }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let user = this.localService.getJsonValue('user');
    
    // Verificar si restablecer pass esta en true
    if( user?.restablecer_password ){
        return true;
    }
    
    this.router.navigate(['/dashboard'])
    return false;
    

  }
  
}
