import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';
import { FacturacionService } from 'app/core/services/facturacion/facturacion.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
  selector: 'app-notas-credito-recaudacion',
  templateUrl: './notas-credito-recaudacion.component.html',
  styleUrls: ['./notas-credito-recaudacion.component.css']
})
export class NotasCreditoRecaudacionComponent implements OnInit {

  public headerTable: columnHeader[];
  public list: any[] = [];
  public selectionList: any[] = [];
  faTimes = faTimes;
  @Input() beneficiarioId: any = null;
  @Input() tipoPagadorId: any = null;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    private recaudacionService: RecaudacionService,
    private facturacionService: FacturacionService,
  ) { }

  ngOnInit(): void {
    this.initTables();

    this.loadData();
  }

  loadData() {

    this.facturacionService.obtenerFacturas('', '', '2', '2', '', 1, 1000,
      (this.tipoPagadorId == 2) ? this.beneficiarioId : '', (this.tipoPagadorId == 1) ? this.beneficiarioId : ''
    )
      .then(res => {
        console.log(res);
        this.list = res['results'];
      })
      .catch(error => {
        console.error(error);
      });
  }

  initTables() {
    let headerDocumentsCarteraTable = [
      {
        headerName: "N°",
        field: "id",
        sortable: true,
        pipe: 'indexcol'
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "cliente_nombre",
      },
      {
        headerName: "Tipo",
        field: "tipo_descripcion",
        sortable: true,
      },
      {
        headerName: "Nro Documento",
        field: "numeracion",
        class: "text-center",
        sortable: true,
      },
      // {
      //   class: "text-center",
      //   headerName: "monto_documento",
      //   field: "monto",
      //   pipe: "currency",
      //   moneda: "moneda",
      //   sortable: true,
      // },
      // {
      //   class: "text-center",
      //   headerName: "Tipo de Cambio",
      //   field: "tipo_cambio_monto",
      //   pipe: "currency",
      //   moneda: "moneda",
      //   sortable: true,
      // },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Total",
        field: "total",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      // {
      //   class: "text-center",
      //   headerName: "Saldo",
      //   field: "saldo",
      //   pipe: "currency",
      //   moneda: "moneda",
      //   sortable: true,
      // },
      {
        class: "text-center",
        headerName: "Fecha",
        field: "fecha_facturado",
        pipe: "date",
        sortable: true,
      },
    ];

    this.headerTable = headerDocumentsCarteraTable;
  }

  goToPageDocumentsCartera(e) {

  }

  selectedItem(row) {
    const id = row.id;

    const findRow = this.selectionList.find(item => item.id == id);

    console.log(row, findRow);

    if (findRow) {
      this.selectionList = this.selectionList.filter(
        (item) => item.id !== id
      );
    } else {
      this.selectionList.push(row);
    }
  }

  sendCuentasList() {
    this.successEmit.emit(this.selectionList);
    this.activeModal.close();
  }

}
