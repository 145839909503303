import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import {NgbConfig} from '@ng-bootstrap/ng-bootstrap';
import { AppService } from './app.service';
import { AuthService } from './core/services/auth/auth.service';
import { SessionNotificationComponent } from './shared/session-notification/session-notification.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit{
	public idleTime;
	public seconds                = 0;
	public interval;
	public alertaDesplegada       : boolean = false;
	public secondsBeforeInactivity: number  = 60;
	public secondsBeforeLogout    : number  = 20;
	public timer                  : boolean = true;

	get inactivityTime(){
		return this.secondsBeforeInactivity * 1000
	}

	@ViewChild(SessionNotificationComponent) alertModal: SessionNotificationComponent;
	
	constructor(
		public appService: AppService,
		public ngbConfig: NgbConfig, 
		public authService: AuthService,
		public router : Router,
		public route:ActivatedRoute){

		this.secondsBeforeInactivity = appService.settings.secondsBeforeInactivity
		this.secondsBeforeLogout = appService.settings.secondsBeforeLogout


		router.events.subscribe((route) => {
			if(route instanceof NavigationEnd){
				if(route.url === '/login'){
					this.stopTimer()
				} else {
					this.iniciaSuscriptoresDeActividad()
					this.resetTimer()
				}
			}
		});
	}
	
	ngOnInit(): void {
		// this.notifyMe('Mensajeee')
		this.authService.alertSuscriber$.subscribe(() => {
			this.alertModal.alertaTokenVencido();
		});   
		
		this.authService.routesAlert$.subscribe((event) => {
			console.log('Alerta de ruta')
			console.log(event)
		});   		
	}

	iniciaSuscriptoresDeActividad(){
		window.addEventListener('mousemove', e => {
			if(this.timer){
				this.resetTimer()
			}
		});
		
		window.addEventListener('keypress', e => {
			if(this.timer){
				this.resetTimer()
			}
		});   
	}

    resetTimer() {
        clearTimeout(this.idleTime);
		this.idleTime = this.timeout
		this.timer=true
	}

	stopTimer(){
        clearTimeout(this.idleTime);
		this.idleTime = null
		this.timer=false
	}

	get timeout(){
		if(!this.alertaDesplegada){
			return setTimeout(()=>{
					this.stopTimer()
					this.seconds = this.secondsBeforeLogout
					this.alertModal.alertaInactividad()
					this.alertaDesplegada = true
					this.interval = this.count
				}, this.inactivityTime)
		} 
		
		return null
	}

	get count(){
		return setInterval(()=>{
			if(this.seconds === 1){
				this.authService.logout()
				this.alertModal.cerrarTodos()
			} else {
				this.seconds--
			}
		},1000)
	}
	
	receive(event){
		switch(event.type){
			case 'token_vencido':
				if(event.response){
					this.authService.refreshToken()
					this.resetTimer()
				} else {
					this.stopTimer()
					this.authService.logout()
				}
			break;
			case 'alerta_inactividad':
				if(event.response){
					this.idleTime = this.timeout
					this.resetTimer()
				} else {
					this.stopTimer()
					this.authService.logout()
				}
			break;
		}

		this.alertaDesplegada = false
		clearInterval(this.interval);
	}


	notifyMe(msg = 'Prueba'){
		this.appService.notifyMe(msg,true)
	}	
}
