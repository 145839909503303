import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from '../../layouts/admin/admin-layout.component';
import { DashboardComponent } from './dashboard.component';
import { AuthGuard } from 'app/core/guards/auth.guard';

const routes: Routes = [
  {
      path: '',
      component: AdminLayoutComponent,
      canActivate: [AuthGuard], 
      data: {
          breadcrumb: 'dashboard'
      },
      children: [
          {
              path: 'dashboard',
              component: DashboardComponent,
              data: {
                  breadcrumb: 'Dashboard'
              }
          },
      ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
