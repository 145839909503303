import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-remove-members-modal',
  templateUrl: './remove-members-modal.component.html',
  styleUrls: ['./remove-members-modal.component.css']
})
export class RemoveMembersModalComponent implements OnInit {
  faTimes = faTimes;
  
  @Input() member: any;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

}
