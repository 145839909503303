import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
  selector: 'app-lineas-factoring',
  templateUrl: './lineas-factoring.component.html',
  styleUrls: ['./lineas-factoring.component.css']
})
export class LineasFactoringComponent implements OnInit {

  @Input() clienteID;
  _empresaID: any;
  lineas: any;

  @Input() set empresaID(value){
    this._empresaID = value;
    this.realoadLinea(value)
    this.filterEmpresaHistorico()

  };
  get empresaID(){
    return this._empresaID
  }
  linea: any;
  myForm: FormGroup;
  monedas: any[] = [];
  public _rowData          : any[]   = [];
  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
  public searchstring     : string = '';

  constructor(
    public formbuild          : FormBuilder,
    private clientesService	  : ClientesService,
    private factoringService  : FactoringService
  ) { }

  ngOnInit() {
    this.initVars();
    this.initForm();

	  let monedas = this.factoringService.obtenerMonedas();

    Promise.all([monedas]).then(res => {
      this.monedas = res[0]['results'];
    }).catch(error => {
      console.error(error);
      
    })

    this.obtenerLineaCredito();
    this.obtenerHistorico();

    this.myForm.disable();
	
  }

  initVars() {
		let columns = [
			{
				headerName: 'N°.', 
				field     : 'id' ,
				sortable  : true,
				pipe      : 'indexcol'
			},
      {
				headerName: 'Fecha Alta', 
				field     : 'fecha_alta' ,
				sortable  : true,
        pipe      : 'date',
        class     : 'text-center',
			},
			{
				headerName: 'Tipo', 
				field     : 'tipo_historico_descripcion',
				sortable  : true,
        class     : 'text-center',
			},
			{
				headerName: 'Moneda', 
				field     : 'moneda_descripcion' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Monto', 
				field     : 'linea_actual' ,
				sortable  : true,
				class     : 'text-center',
				pipe      : "currency",
				moneda    : "moneda",
			},
			{
				headerName: 'TEM Soles', 
				field     : 'tem_soles' ,
				sortable  : true,
        pipe      : 'percent-icon',
        class     : 'text-center',
			},
      {
				headerName: 'TEM Dólares', 
				field     : 'tem_dolares' ,
				sortable  : true,
        pipe      : 'percent-icon',
        class     : 'text-center',
			},
      {
				headerName: 'Anticipo', 
				field     : 'anticipo' ,
				sortable  : true,
        pipe      : 'percent-icon',
        class     : 'text-center',
			},
      {
				headerName: 'Tasa Mora', 
				field     : 'mora' ,
				sortable  : true,
        pipe      : 'percent-icon',
        class     : 'text-center',
			},
      {
				headerName: 'Contrato', 
				field     : 'numero_contrato',
				sortable  : true,
        class     : 'text-center',
			},
      {
				headerName: 'Fecha Contrato', 
				field     : 'fecha_contrato' ,
				sortable  : true,
        pipe      : 'date',
        class     : 'text-center',
			},
      {
				headerName: 'Fecha Vencimiento', 
				field     : 'fecha_vencimiento_contrato' ,
				sortable  : true,
        pipe      : 'date',
        class     : 'text-center',
			},
      {
				headerName: 'Observaciones', 
				field     : 'observaciones_contrato',
				sortable  : true,
			},
		];

		this.columnDefs = columns;
	}

  loadTable(data) {
    this._rowData           = data.results
    this.filterEmpresaHistorico()
		this.pageTableActual   = data.page_number;
		this.pageTableTotal    = data.count;
		this.pageTablePerPages = data.per_page;
		this.pageTablePages = [];
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index);
		}

	}

  filterEmpresaHistorico(empresaID = this.empresaID) {
		this.rowData           = this._rowData.filter(item => (item.tipo == 2) && (item.empresa == empresaID));
  }

  initForm() {
    this.myForm = this.formbuild.group({
      linea_actual      : [null, []],
      linea_consumida   : [null, []],
      linea_disponible  : [null, []],
      moneda            : [null, []],

      fecha_solicitud   : [null, []],
      fecha_resolucion  : [null, []],
      fecha_contrato    : [null, []],
      fecha_vencimiento_contrato : [null, []],

      tem_soles         : [null, []],
      tem_dolares       : [null, []],
      anticipo          : [null, []],

      tea_soles         : [null, []],
      tea_dolares       : [null, []],
      mora              : [null, []],

      observaciones     : [null, []],
    })
  }

  setForm(values) {
    
    if(!values && (!!this.myForm)){
      this.resetFormControls()
      return
    }

    for (const key in values) {
      if (this.myForm.controls[key]) {

        if (key == 'tem_soles') {
          this.myForm.controls[key].setValue(values[key]);
          this.myForm.controls['tea_soles'].setValue(this.teas(values[key]));
          continue;
        }

        if (key == 'tem_dolares') {
          this.myForm.controls[key].setValue(values[key]);
          this.myForm.controls['tea_dolares'].setValue(this.teas(values[key]));
          continue;
        }

        if (key ==  'fecha_alta') {
          this.myForm.controls[key].setValue(this.formatDate(values[key]));
          continue;
        }

        if (key ==  'fecha_vencimiento_contrato') {
          this.myForm.controls[key].setValue(this.formatDate(values[key]));
          continue;
        }

        if (key ==  'fecha_contrato') {
          this.myForm.controls[key].setValue(this.formatDate(values[key]));
          continue;
        }

        if (key ==  'fecha_solicitud') {
          this.myForm.controls[key].setValue(this.formatDate(values[key]));
          continue;
        }

        if (key ==  'fecha_resolucion') {
          this.myForm.controls[key].setValue(this.formatDate(values[key]));
          continue;
        }

        this.myForm.controls[key].setValue(values[key]);
      }
      
    }
  }

  resetFormControls() {
    for (const key in this.myForm.controls){
      this.myForm.controls[key].setValue(null)
    }
  }

  formatDate(date) {
    if (!date) return ''
    const arrayDate = date.split('-');

    return `${arrayDate[2].substring(0,2)}/${arrayDate[1]}/${arrayDate[0]}`;
  }

  teas(value) {
		let amount = 0;
		let val = 1 + (value / 100);
		amount = Math.pow(val, 12) - 1;
		amount *= 100;
		return amount.toFixed(2)
  }

  obtenerLineaCredito() {
    this.factoringService.obtenerLineas(this.clienteID, '2')
    .then(res => {
      this.lineas = res['results']
      this.realoadLinea(this.empresaID)
    }).catch(error => {
      console.error(error);
      
    });
  }

  obtenerHistorico() {
		this.clientesService.obtenerLineasHstorico(this.clienteID)
		.then(res => {
      this.loadTable(res);
		}).catch(error => {
		  console.error(error);
		
		});
  }

  realoadLinea(empresaID) {
    this.linea = this.lineas?.find(linea => (linea.empresa == empresaID) && (linea.tipo == 2));
    this.setForm(this.linea);
  }

}
