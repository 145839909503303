import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { ManageParametrosContrasenaModalComponent } from '../modals/manage-parametros-contraseña-modal/manage-parametros-contraseña-modal.component';

@Component({
  selector: 'app-parametros-contraseña',
  templateUrl: './parametros-contraseña.component.html',
  styleUrls: ['./parametros-contraseña.component.css']
})
export class ParametrosContrasenaComponent implements OnInit {

  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public user             : any;
	public identidad        : any     = {};
	public searchstring     : string = '';
	public canCreate: boolean = false;

  constructor(
    private configurationService  : ConfigurationService,
    public modalService           : NgbModal,
  ) { }

  ngOnInit(): void {
    // this.obtenerParametrosContrasena();
  }

  loadTable(data) {

    this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }
    
  initVars() {
    let columns = [
      {
        headerName: 'Actualización', 
        field     : 'fecha_actualizacion' ,
				pipe      : 'date',
        class     : 'text-center',
        sortable  : true,
      },
      {
        headerName: 'Caractéres Mínimos', 
        field     : 'min_length',
        class     : 'text-center',
        sortable  : true,
      },
      {
        headerName: 'Mayúsculas Mínimas', 
        field     : 'min_uppercase',
        class     : 'text-center',
        sortable  : true,
      },
      {
        headerName: 'Minúsculas Mínimas', 
        field     : 'min_lowercase',
        class     : 'text-center',
        sortable  : true,
      },
      {
        headerName: 'Símbolos Mínimos', 
        field     : 'min_symbol',
        class     : 'text-center',
        sortable  : true,
      },
      {
        headerName: 'Números Mínimos', 
        field     : 'min_number',
        class     : 'text-center',
        sortable  : true,
      },
      {
        headerName: 'Ultimas contraseñas', 
        field     : 'last_password_count',
        class     : 'text-center',
        sortable  : true,
      },
      {
        headerName: 'Días sin actualizar contraseña', 
        field     : 'last_password_change',
        class     : 'text-center',
        sortable  : true,
      },
    ];
  
    this.columnDefs = columns;
  }

  // obtenerParametrosContrasena(page = 1, page_size = 10) {
  //   let ParametrosContrasena = this.configurationService.obtenerParametrosContrasena(page, page_size);

  //   Promise.all([ParametrosContrasena]).then((res :any[])=>{
  //     console.log("RES", res)
  //     let ParametrosContrasena  = res[0];
  //     this.initVars();
  //     this.loadTable(ParametrosContrasena);
  //   });
  // }

  /*   
  crear() {
    const modalRef = this.modalService.open(ManageParametrosContrasenaModalComponent, {
    });

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerParametrosContrasena();
		},
		error => {
      console.error(error);
      
		});
  } */

  // tableEditEvent(row) {
  //   const modalRef = this.modalService.open(ManageParametrosContrasenaModalComponent, {
  //   });

  //   modalRef.componentInstance.parametroId = row.id;

  //   modalRef.componentInstance

  //   modalRef.result.then((result) => {
  //         console.log(result);
  //     }, (reason) => {
  //         console.log(reason);
  //   });

  //   modalRef.componentInstance.successEmit.subscribe(result => {
	// 		console.log(result);
	// 		this.obtenerParametrosContrasena();
	// 	},
	// 	error => {

	// 	});
  // }
  
  /* 
  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Parámetro';
		modalRef.componentInstance.message = '¿Seguro que desea remover el Parámetro?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.configurationService.eliminarParametro(row.id)
			.then(result => {
			this.obtenerParametrosContrasena();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }
 */


  // goToPage({page,per_page,filtros}) {
  //   this.obtenerParametrosContrasena(page, per_page);
  // }
 
}
