import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { OrdenesPagoService } from 'app/core/services/ordenes-pago.service';
import { ConfirmModalComponent } from '../utils/confirm-modal/confirm-modal.component';

@Component({
    selector: 'app-form-resumen-factoring',
    templateUrl: './form-resumen-factoring.component.html',
    styleUrls: ['./form-resumen-factoring.component.css']
})
export class FormResumenFactoringComponent implements OnInit {
    public f_res: FormGroup;
    @Input() set proceso(value) {
        if (value) {
            this._proceso = value
            this._proceso_backup = value
            this.getData()

        }
    }
    @Input() operacion: any;
    get proceso() {
        return this._proceso
    }
    _proceso: any;
    _proceso_backup: any;
    public currency: any = {};
    public currency_linea_solicitada: any = {};
    public currency_total_operacion: any = {};
    public currency_moneda_en_cuenta: any = {};
    public currency_linea_grupo: any = {};
    public currency_linea: any = {};
    public currency_cliente: any = {};
    @Output() values: EventEmitter<any> = new EventEmitter<any>();
    public monedas_list: any[] = [];
    public lineas: any[] = [];
    public tiempos: any[];
    public bancosAbono: any[];
    public empresas: any[];

    @Input() cabecera: any;

    constructor(
        public formbuild: FormBuilder,
        public configs: DocschecklistService,
        public ordenesPago: OrdenesPagoService,
        public authService: AuthService,
        public factoringService: FactoringService,
        private modalService: NgbModal,
        private router: Router,
    ) {
        this.initform();

    }

    ngOnInit(): void {
    }

    getData() {
        let monedas = this.configs.obtenerMonedas()
        let lineas = this.configs.obtenerLineas()
        let bancos = this.factoringService.obtenerCuentas(this.proceso.beneficiario.id)
        Promise.all([monedas, lineas, bancos]).then((res: any[]) => {
            // console.log(res);
            this.monedas_list = res[0]['results']
            this.lineas = res[1]['results'];
            this.bancosAbono = res[2]['results'];
            this.factoringService.obtenerEmpresas().then((res) => {
                this.empresas = res['results'];
            });
            let banco_seleccionado = this.bancosAbono.find((banco) => this.proceso.banco_abono === banco.entidad_financiera && this.proceso.moneda_banco === banco.moneda && this.proceso.nro_cuenta === banco.numero_cuenta)
            if (banco_seleccionado) {
                this.f_res.get('moneda_banco').setValue(banco_seleccionado.moneda)
                this.f_res.get('nro_cuenta').setValue(banco_seleccionado.numero_cuenta)
                this.f_res.get('banco_abono').setValue(banco_seleccionado.entidad_financiera)
                this.f_res.get('cci').setValue(banco_seleccionado.numero_cuenta_cci)
                this.f_res.get('_banco_abono').setValue(banco_seleccionado.id)
                this.f_res.get('cuenta_abono').setValue(banco_seleccionado.id)
            }

            this.f_res.get('_banco_abono').valueChanges.subscribe((value) => {
                let banco_seleccionado = this.bancosAbono.find((banco) => banco.id === value)
                this.f_res.get('cuenta_abono').setValue(banco_seleccionado.id)
                this.f_res.get('moneda_banco').setValue(banco_seleccionado.moneda)
                this.f_res.get('nro_cuenta').setValue(banco_seleccionado.numero_cuenta)
                this.f_res.get('cci').setValue(banco_seleccionado.numero_cuenta_cci)
                this.f_res.get('banco_abono').setValue(banco_seleccionado.entidad_financiera)
            })
        })
    }

    ngOnChanges(changes) {
        if (changes.proceso && this.proceso) {
            this.setForm(this.proceso)
        }
    }

    initform() {

        this.tiempos = [
            { id: 1, descripcion: 'Meses' },
            { id: 2, descripcion: 'Días' },
            { id: 3, descripcion: 'Años' }
        ]

        this.f_res = this.formbuild.group({
            _banco_abono: [null],


            linea_solicitada: [null],
            total_operacion: [null],
            banco_abono: [null],
            moneda: [null],
            nro_cuenta: [null],
            cci: [null],
            documentos_excluidos: [null],
            documentos_incluidos: [null],
            documentos_observados: [null],
            documentos_verificados: [null],
            oficial_negocio: [null],
            moneda_banco: [null],


            tipo_linea: [null],
            linea_actual: [null],
            consumido: [null],
            disponible: [null],
            cliente_nombre: [null],
            contacto_nombre: [null],
            contacto_telefono: [null],
            contacto_email: [null],
            grupo_economico: [null],
            linea_grupo: [null],
            moneda_linea_grupo: [null],
            exposicion_grupo: [null],
            // clave_sol: [null],


            pro_tipo_linea: [null],
            pro_linea_propuesta: [null],
            pro_meses: [null],
            pro_tasa_tea_soles: [null],
            pro_tasa_tem_soles: [null],
            pro_tasa_tea_dolares: [null],
            pro_tasa_tem_dolares: [null],
            pro_tasa_mora: [null],
            pro_anticipo: [null],
            pro_plazo: [null],
            pro_periodo: [null],
            pro_descripcion: [null],
            pro_vigencia: [null],

            moneda_linea_credito: [null],
            moneda_linea: [null],
            moneda_total_operacion: [null],

            cuenta_abono: [null],
            empresa: [ null ],
        })


        this.f_res.valueChanges.subscribe((value) => {
            this.values.emit(value)
        })
    }

    setForm(p) {
        this.f_res.reset({
            moneda_linea_credito: { value: p.moneda_linea_credito, disabled: true },
            linea_solicitada: { value: p.linea_solicitada, disabled: true },
            total_operacion: { value: p.monto, disabled: true },
            // banco_abono           : { value: p.banco_abono, disabled:false },
            cuenta_abono: { value: p.cuenta_abono, disabled: true },
            moneda_banco: { value: p.moneda_banco, disabled: true },
            nro_cuenta: { value: p.nro_cuenta, disabled: true },
            cci: { value: p.cci, disabled: true },
            documentos_excluidos: { value: p.cantidad_documentos_rechazados, disabled: true },
            documentos_incluidos: { value: p.cantidad_documentos, disabled: true },
            documentos_observados: { value: p.documentos_observados, disabled: true },
            documentos_verificados: { value: p.cantidad_documentos_verificados, disabled: true },
            oficial_negocio: { value: p.oficial_negocio_nombre, disabled: true },
            moneda_linea_grupo: { value: p.beneficiario.moneda_linea_grupo, disabled: true },

            tipo_linea: { value: 0, disabled: true },
            linea_actual: { value: '', disabled: true },
            moneda_linea: { value: 0, disabled: true },
            consumido: { value: '', disabled: true },
            disponible: { value: '', disabled: true },
            cliente_nombre: { value: p.beneficiario.nombre, disabled: true },
            contacto_nombre: { value: p.contacto_nombre, disabled: true },
            contacto_email: { value: p.contacto_email, disabled: true },
            contacto_telefono: { value: p.contacto_telefono, disabled: true },
            grupo_economico: { value: p.beneficiario.grupo_economico, disabled: true },
            linea_grupo: { value: p.beneficiario.linea_grupo, disabled: true },
            exposicion_grupo: { value: p.beneficiario.exposicion_grupo, disabled: true },
            // clave_sol: { value: p.beneficiario.clave_sol, disabled: true },
            moneda_total_operacion: { value: p.moneda, disabled: true },
            empresa: { value: p.empresa, disabled: true },

            pro_tipo_linea: { value: "", disabled: true },
            pro_linea_propuesta: { value: "", disabled: true },
            pro_meses: { value: "", disabled: true },
            pro_tasa_tea_soles: { value: "", disabled: true },
            pro_tasa_tem_soles: { value: "", disabled: true },
            pro_tasa_tea_dolares: { value: "", disabled: true },
            pro_tasa_tem_dolares: { value: "", disabled: true },
            pro_tasa_mora: { value: "", disabled: true },
            pro_anticipo: { value: "", disabled: true },
            pro_plazo: { value: "", disabled: true },
            pro_periodo: { value: 2, disabled: true },
            pro_descripcion: { value: "", disabled: true },
            pro_vigencia: { value: 1, disabled: true },

        })

        if (p.linea_credito) {
            this.f_res.controls['tipo_linea'].setValue(p.linea_credito.tipo);
            this.f_res.controls['linea_actual'].setValue(p.linea_credito.linea_actual);
            this.f_res.controls['moneda_linea'].setValue(p.linea_credito.moneda);
            this.f_res.controls['consumido'].setValue(p.linea_credito.linea_consumida);
            this.f_res.controls['disponible'].setValue(p.linea_credito.linea_disponible);
        }

        let config = {
            thousands: '.',
            decimal: ','
        }
        this.currency_moneda_en_cuenta = config
        this.currency_total_operacion = config
        this.currency_linea_solicitada = config
        this.currency_linea = config
        this.currency_linea_grupo = config
    }

    async guardarCambios() {
        let data = {
            moneda_banco: this.f_res.get('moneda_banco').value,
            nro_cuenta: this.f_res.get('nro_cuenta').value,
            banco_abono: this.f_res.get('banco_abono').value,
            cuenta_abono: this.f_res.get('cuenta_abono').value,
            cci: this.f_res.get('cci').value,
            empresa: this.f_res.get('empresa').value
        }

        const modalRef = this.modalService.open(ConfirmModalComponent, {});

        modalRef.componentInstance.title = "Enviar Correo a Tesorería";
        modalRef.componentInstance.message =
            "¿Desea avisar por correo a Tesorería?";

        await modalRef.result.then(async (result) => {
            if (result) {
                await this.ordenesPago.notificarCambiodeCuenta(this.proceso.id).then(resp => {

                }, err => {
                    console.error(err);
                });
            }
        }, (reason) => {
            console.log(reason);
        });

        await this.factoringService.updateResumenProps(this.proceso.id, data).then((res: any) => {
            this.proceso.banco_abono = res.banco_abono
            this.proceso.moneda_banco = res.moneda_banco
            this.proceso.nro_cuenta = res.nro_cuenta
            this.proceso.empresa = res.empresa
        });

        this.router.navigate(['/operaciones']);
    }

    get readonlyActualizarBanco() {
        if (!this.operacion) return true;
        const isResponsable = this.operacion.isResponsable(this.authService.user);
        const isResponsableMiembroEquipo = this.operacion.isResponsableMiembroEquipo(this.authService.miembrosDeleQuipo);
        const isOficial = this.operacion.isOficial(this.authService.user);
        if (isOficial && this.cabecera.solicitante_editar_cuenta_abono) {
            return false;
        }
        const actualTesoreria = this.operacion.actualTesoreria;
        return actualTesoreria || !(isResponsable || isResponsableMiembroEquipo);
    }

    get readonlyActualizarEmpresa() {
        if (!this.operacion) return true
        const { isGerenteComercial, isGerenteGeneral, isAdmin, isOficialDeNegocio, isLiderDeEquipo } = this.authService.user
        const isResponsable = this.operacion.isResponsable(this.authService.user)
        return !(isGerenteComercial || isGerenteGeneral || isAdmin || (isResponsable && (isOficialDeNegocio || isLiderDeEquipo)) || this.operacion.isResponsableMiembroEquipo(this.authService.user.equipo))
    }

}
