import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';

@Component({
  selector: 'app-manage-codigo-ciiu-modal',
  templateUrl: './manage-codigo-ciiu-modal.component.html',
  styleUrls: ['./manage-codigo-ciiu-modal.component.css']
})
export class ManageCodigoCIIUModalComponent implements OnInit {

  myForm: FormGroup;
  @Input() codigoCIIUId = 0;
  codigosCIIU: any;
  faTimes = faTimes;
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild          : FormBuilder,
    public activeModal        : NgbActiveModal,
    private configurationService	: ConfigurationService,
  ) { 
    this.initForm();
  }

  ngOnInit(): void {
    if (this.codigoCIIUId) {
      this.obtenerCodigoCIIU();
    }
  }

  initForm() {
    this.myForm = this.formbuild.group({
      codigo: [null, [Validators.required, Validators.maxLength(10)]],
      nombre: [null, [Validators.required]],
    });
  }
  
  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  obtenerCodigoCIIU() {
    this.configurationService.obtenerCodigoCIIU(this.codigoCIIUId)
    .then(res => {
      this.codigosCIIU = res;
      this.setForm(res);
    })
    .catch(error => {
      console.error(error);
    });
  }

  onSubmit() {
    if(this.myForm.valid) {
      const data = this.myForm.value;

      this.configurationService.guardarCodigoCIIU(this.myForm.value, this.codigoCIIUId)
      .then(res => {
        this.successEmit.emit();
        this.activeModal.close(true);
      }).
      catch(error => {
        this.activeModal.close(false);
      });
    }
  }

}
