import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { FacturacionService } from 'app/core/services/facturacion/facturacion.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { FacturadoModalComponent } from '../../modals/facturado-modal/facturado-modal.component';
import { NotaCreditoModalComponent } from '../../modals/nota-credito-modal/nota-credito-modal.component';
import { ParametrosService } from 'app/core/services/configuration/parametros.service';

@Component({
	selector: 'app-facturas-facturado',
	templateUrl: './facturas-facturado.component.html',
	styleUrls: ['./facturas-facturado.component.css']
})
export class FacturasFacturadoComponent implements OnInit {

	public rowData: any[] = [];
	public loadingTable: boolean = false;
	public columnDefs: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
	public user: any;
	public identidad: any = {};
	public searchstring: string = '';
	public canCreate: boolean = false;
	public ruc_empresa: any;
	
	@Input() set refresh(value) {
		this.obtenerFacturas();
	}
	estados: any[] = [];
	tipos: any[] = [];

	@Output() ocultarBtn = new EventEmitter();

	constructor(
		private facturacionService: FacturacionService,
		private modalService: NgbModal,
		private authService: AuthService,
		public parametros: ParametrosService
	) {
		this.user = this.authService.user;
	}

	ngOnInit(): void {
		
		this.ocultarBtn.emit(false)
		this.obtenerRuc();
		let estados = this.facturacionService.obenerEstados();
		let tipos = this.facturacionService.obtenerTipos();
		let concepto = this.facturacionService.obtenerConceptos();
		Promise.all([estados, tipos, concepto]).then(resp => {
			this.estados = resp[0]['results'];
			this.tipos = resp[1]['results']
			let concepto1 = resp[2]['results']
			this.initVars(this.estados, this.tipos, concepto1);
		}, err => {
			console.error(err);
		});
		this.obtenerFacturas();
	}
	async obtenerRuc(){
		const parametro = await this.parametros.obtenerParametro('FACTURACION_RUC').toPromise();
		console.log("EMPRESA RUC: ", parametro.valor)
		this.ruc_empresa = parametro.valor
	}

	obtenerFacturas(page = 1, page_size = 10, referencia = '', tipo_pagador = '', tipo = '', estado = '2,3,5', pagador_nombre = '', concepto = '', fecha_pago__gte = '' , fecha_pago__lte = '', fecha_facturado__gte = '' , fecha_facturado__lte = '', operacion = '', usuario_creador = '', cuenta_cobrar='', numeracion='' ) {
		// this.user.isAsistenteCobranzas ? this.user.id : ''
		this.facturacionService.obtenerFacturas(
			referencia, tipo_pagador, tipo, estado,
			'',
			page, page_size, '', '', pagador_nombre, concepto , fecha_pago__gte, fecha_pago__lte, fecha_facturado__gte , fecha_facturado__lte, operacion, usuario_creador, '', '', this.user.isOficialDeNegocio || this.user.isLiderDeEquipo ? this.user.id : '', cuenta_cobrar, numeracion
		).then(resp => {
			// this.initVars(this.estados, this.tipos);
			this.loadTable(resp);
		}, err => {
			console.error(err);

		})
	}

	loadTable(data) {
		this.rowData = data.results
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

	initVars(estados = [], tipos = [], concepto = []) {
		let columns = [
			{
				headerName: '',
				field: '',
				sortable: true,
			},
			{
				headerName: 'Creado Por',
				field: 'usuario_creador',
				class: 'text-center',
				sortable: true,
				filterable: true,
				filterProp: 'usuario_creador',
				filterInput: true,
			},
			{
				headerName: 'Nro CxC',
				field: 'cuenta_cobrar',
				class: 'text-center',
				sortable: true,
				filterable: true,
				filterProp: 'cuenta_cobrar',
				filterInput: true,
			},
			{
				headerName: 'Tipo Doc.',
				field: 'tipo_descripcion',
				class: 'text-center',
				sortable: true,
				filterable: true,
				filterProp: 'tipo',
				filterSelectItems: tipos,
				filterItemsProps: {
					value: 'id',
					label: 'descripcion'
				},
			},
			{
				headerName: 'Número',
				field: 'numeracion',
				class: 'text-center',
				sortable: true,
				filterable: true,
				filterProp: 'numeracion',
				filterInput: true,
			},
			{
				headerName: 'Cobrar a',
				field: 'pagador_nombre',
				class: 'text-center',
				sortable: true,
				filterable: true,
				filterProp: 'pagador_nombre',
				filterInput: true,
			},
			{
				headerName: 'Fecha de Pago',
				field: 'fecha_pago',
				class: 'text-center',
				pipe: "date",
				sortable: true,
				filterable: true,
				filterDate: true,
			},
			{
				headerName: 'Fecha facturado',
				field: 'fecha_facturado',
				class: 'text-center',
				pipe: "date",
				sortable: true,
				filterable: true,
				filterDate: true,
			},
			{
				headerName: 'Concepto',
				field: 'concepto',
				class: 'text-center',
				sortable: true,
				filterable: true,
				filterProp: 'concepto',
				filterSelectItems: concepto,
				filterItemsProps: {
					value: 'id',
					label: 'descripcion'
				},
			},
			{
				headerName: 'Monto',
				field: 'monto',
				class: 'text-center',
				pipe: 'currency',
				moneda: 'moneda',
				sortable: true,
			},
			{
				headerName: 'IGV',
				field: 'igv',
				class: 'text-center',
				pipe: 'currency',
				moneda: 'moneda',
				sortable: true,
			},
			{
				headerName: 'Total',
				field: 'total',
				class: 'text-center',
				pipe: 'currency',
				moneda: 'moneda',
				sortable: true,
			},
			{
				headerName: 'Estado',
				field: 'estado_descripcion',
				filterProp: 'estado',
				sortable: true,
				class: 'text-center',
				filterable: true,
				filterSelectItems: estados,
				filterItemsProps: {
					value: 'id',
					label: 'descripcion'
				},
			},
			{
				headerName: 'Operación',
				field: 'operacion',
				class: 'text-center',
				sortable: true,
				filterable: true,
				filterProp: 'operacion',
				filterInput: true,
			},
			{
				headerName: 'Glosa',
				field: 'glosa',
				class: 'text-center',
				sortable: true,
			},
		];

		this.columnDefs = columns;
	}

	goToPage({ page, per_page, filtros, type }) {
		let fecha_pago__gte = '';
		let fecha_pago__lte = '';
		let fecha_facturado__gte = '';
		let fecha_facturado__lte = '';

		if( type == 'fecha_pago' ){

			fecha_pago__gte = filtros.fecha__gte;
			fecha_pago__lte = filtros.fecha__lte;

		}else if( type == 'fecha_facturado' ){

			fecha_facturado__gte = filtros.fecha__gte;
			fecha_facturado__lte = filtros.fecha__lte;
		}

		let listEstados = (filtros.estado) ? `${filtros.estado}` : '2,3,5';
		this.obtenerFacturas(
			page, per_page,
			'',
			'',
			filtros.tipo, 
			listEstados, 
			filtros.pagador_nombre, 
			filtros.concepto,
			fecha_pago__gte,
			fecha_pago__lte,
			fecha_facturado__gte,
			fecha_facturado__lte,
			filtros.operacion,
			filtros.usuario_creador,
			filtros.cuenta_cobrar,
			filtros.numeracion

		);
	}

	tableEditEvent(row) {

		console.log(row);

		const modalRef = this.modalService.open(FacturadoModalComponent, {
			size: 'xl'
		});

		modalRef.componentInstance.factura = row;

		modalRef.result.then((result) => {
			console.log(result);
		}, (reason) => {
			console.log(reason);
		});
	}

	destroy(row) {

	}

	donwloadPdf(data){
		
		this.facturacionService.downloadPdfXml( data.id, 'pdf' )
		.then( (x:any)=>{
			var blob = new Blob([x], { type: x.type });
			var downloadURL = window.URL.createObjectURL(blob);
			var link = document.createElement('a');
			link.href = downloadURL;
			link.download = `${this.ruc_empresa}-${data.tipo_comprobante}-${data.numeracion}.pdf`;
			link.click();
		})
	}

	downloadXml(data){
		this.facturacionService.downloadPdfXml( data.id, 'xml' )
		.then( (x:any)=>{
			var blob = new Blob([x], { type: 'text/xml' } );
			var downloadURL = window.URL.createObjectURL(blob);
			var link = document.createElement('a');
			link.href = downloadURL;
			link.download = `${this.ruc_empresa}-${data.tipo_comprobante}-${data.numeracion}.XML`;
			link.click();
		}, async (reason) => {
			console.log('reason',reason);
		}), err => {
			console.error('por aca el error', err);
		  };
	}

	get canAdd() {
		const perfilesPermitidos = [
		  this.authService.perfil.perfilAdmin,
		  this.authService.perfil.perfilJefeDeOperacionesID,
		//   this.authService.perfil.perfilAnalistaOperacionesID,
		  this.authService.perfil.perfilAsistenteCobranzasID,
		//   this.authService.perfil.perfilAnalistaOperacionesID,
		  this.authService.perfil.perfilGerenciaComercialID,
		  this.authService.perfil.perfilGerenciaGeneralID,
		]
	
		return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
	}

}
