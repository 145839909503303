import { Component, OnInit } from '@angular/core';
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { AuthService } from "app/core/services/auth/auth.service";
import { CuentasCobrarService } from "app/core/services/factoring/cuentas-cobrar.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from '@angular/router';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-cuentas-cobrar',
  templateUrl: './cuentas-cobrar.component.html',
  styleUrls: ['./cuentas-cobrar.component.css']
})
export class CuentasCobrarComponent implements OnInit {
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = "";
  public canCreate: boolean = false;
   faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  constructor(
    public modalService: NgbModal,
    private router: Router,
    private authService: AuthService,
    private cuentasCobrarService: CuentasCobrarService
  ) {
    this.user = this.authService.user;
  }
  ngOnInit(): void {
    this.obtenerCuentasCobrar(1, 10, "", "", "", "", "");
  }
  /**
   *
   * @param page
   * @param page_size
   */
  obtenerCuentasCobrar(
    page: number = 1,
    page_size: number = 10,
    liquidacion = "",
    beneficiario = "",
    deudor = "",
    estado__in = "",
    recaudacion = ""
  ) {
    let documents = this.cuentasCobrarService.obtenerCuentasCobrar(
      page,
      page_size,
      liquidacion,
      beneficiario,
      deudor,
      estado__in,
      recaudacion
    );

    Promise.all([documents]).then(
      (res: any[]) => {
        let documents = res[0];
        this.initVars();
        this.loadTable(documents);
      }
    );
  }
  loadTable(data) {
    console.log("data",data);
    this.rowData = data.results.map((element,index)=> ({
      ...element,
      index : index +1,
      cliente_cobrar_nombre : element.beneficiario != null ? element.beneficiario_nombre : element.deudor_nombre,
      cliente_cobrar_ruc : element.beneficiario != null ? element.beneficiario_ruc : element.deudor_ruc,
    }));

    console.log("ROW DATA",this.rowData);
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }
  initVars() {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cobrar a",
        field: "cliente_cobrar_nombre",
        sortable: true,
        // filterable: true,
        // filterProp: "beneficiario",
        // filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "cliente_cobrar_ruc",
        sortable: true,
      },
      {
        headerName: 'Operación ', 
        field     : 'liquidacion',
        pipe      : 'actionable',
        textField : 'liquidacion',
        class     : 'text-center',
        sortable  : true,
        // filterable: true,
        // filterProp: 'operacion',
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Fch. Liquidación",
        field: "liquidacion_fecha",
        pipe: "date",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fch. Registro",
        field: "fecha_creacion",
        pipe: "date",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Concepto",
        field: "concepto_descripcion",
        sortable: true,
        // filterable: true,
        // filterProp: "aceptante",
        // filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "IGV",
        field: "igv",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },      
      {
        class: "text-center",
        headerName: "Total X Cobrar",
        field: "total",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Saldo X Cobrar",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Estado",
        field: "estado_descripcion",
        sortable: true,
      }
    ];

    this.columnDefs = columns;
  }
  tableEditEvent(row) {}
	workflowEvent(row) {
		console.log(row);
		this.router.navigate(['/operaciones',row.operacion]);
	}
  goToPage({ page, per_page, filtros }) {
    this.cuentasCobrarService
      .obtenerCuentasCobrar(
        page,
        per_page,
        filtros.liquidacion ? filtros.liquidacion : "",
        filtros.beneficiario ? filtros.beneficiario : "",
        filtros.deudor ? filtros.deudor : "",
        filtros.estado__in ? filtros.estado__in : "",
        filtros.recaudacion ? filtros.recaudacion : ""
      )
      .then((documents: any) => {
        this.loadTable(documents);
      });
  }
}
