import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { utils, writeFile } from 'xlsx';
import Swal from 'sweetalert2';
import { AuthService } from 'app/core/services/auth/auth.service';
import { functions } from '../../../../core/helpers/functions';

@Component({
  selector: 'app-reporte-sabana',
  templateUrl: './reporte-sabana.component.html',
  styleUrls: ['./reporte-sabana.component.css']
})
export class ReporteSabanaComponent implements OnInit {

  public reporteForm : FormGroup;
	public headerTable: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 50;
	public pageTablePages: number[];
  public searchstring: string = '';

  public rowData = [];

  public estados = []
  public oficiales_negocio = []
  public empresas = []

  public tipo_pagadores = [
    {
      'id': 1,
      'descripcion': 'Aceptante'
    },
    {
      'id': 2,
      'descripcion': 'Cliente'
    }
  ]

  public generando: boolean = false;
  
  public filtros: any = {
    cliente_razon_social: '',
    cliente_ruc: '',
    aceptante_razon_social: '',
    aceptante_ruc: '',
    nro_operacion: '',
    fecha__gte: '',
    fecha__lte: '',
    tipo_pagador: '',
    oficial_negocio: '',
    estado: '',
  };

  constructor( 
    public documentosCarteraService: DocumentosCarteraService,
    private factoringService: FactoringService,
    public formbuild: FormBuilder,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getReporteSabana({page:1, per_page:this.pageTablePerPages, filtros:{}})
    const estados = this.documentosCarteraService.obtenerEstadosCarteras()
    const oficiales_negocio = this.factoringService.obtenerResponsables(3)
    const empresas = this.factoringService.obtenerEmpresas()

    Promise.all([estados, oficiales_negocio, empresas]).then(res=>{
      this.estados = res[0]['results']
      if (!this.authService.user.isOficialDeNegocio) {
        this.oficiales_negocio = res[1]['results']
      }
      this.empresas = res[2]['results']

      this.loadTableColumns(this.estados, this.tipo_pagadores, this.oficiales_negocio, this.empresas)
    })
  }

  loadTableColumns(estados, tipo_pagadores, oficiales_negocio, empresas) {
		this.headerTable = [
			{
				headerName: 'N°',
        field: 'index',
				// pipe: 'indexcol',
				sortable: true,
			},
      {
        headerName: 'Cliente',
        class: 'text-center',
        field: 'razon_social_cliente',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'cliente_razon_social'
      },
      {
        headerName: 'RUC C.',
        class: 'text-center',
        field: 'cliente',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'cliente_ruc'
      },
      {
        headerName: 'Aceptante',
        class: 'text-center',
        field: 'razon_social_aceptante',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'aceptante_razon_social'
      },
      {
        headerName: 'RUC. A.',
        class: 'text-center',
        field: 'aceptante',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'aceptante_ruc'
      },
      {
        headerName: 'Nro. Operación',
        class: 'text-center',
        field: 'nro_operacion',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'nro_operacion'
      },
      {
        headerName: 'Tipo Operacion',
        class: 'text-center',
        field: 'tipo_operacion',
        filterable: true,
        filterProp: 'tipo_operacion',
        filterSelectItems: [
          {id: 1, descripcion: 'Simple'},
          {id: 2, descripcion: 'Curso Normal'},
          {id: 3, descripcion: 'Curso Normal-Riesgos'},
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Nro. de Adelanto',
        class: 'text-center',
        field: 'adelanto',
        filterInput: true,
        filterProp: 'adelanto'
      },
      {
        headerName: 'Nro. Operación Cliente',
        class: 'text-center',
        field: 'nro_operacion_cliente',
        sortable: true,
      },
      {
        headerName: 'Fecha Cesión',
        class: 'text-center',
        field: 'fecha_cesion',
        sortable: true,
        pipe: "date",
        filterable: true,
        filterDate: true,
        filterProp: 'fecha_cesion',
      },
      {
        headerName: 'Fecha Desembolso',
        class: 'text-center',
        field: 'fecha_desembolso',
        sortable: true,
        pipe: "date",
        filterable: true,
        filterDate: true,
        filterProp: 'fecha_desembolso'
      },
      {
        headerName: 'N° documento',
        class: 'text-center',
        field: 'nro_documento',
        sortable: true,
      },
      {
        headerName: 'Documento',
        class: 'text-center',
        field: 'documento',
        sortable: true,
      },
      {
        headerName: 'Vencimiento Nominal',
        class: 'text-center',
        field: 'fch_vencimiento_nominal',
        sortable: true,
        pipe: "date",
        filterable: true,
        filterDate: true,
        filterProp: 'fecha_vencimiento_nominal'
      },
      {
        headerName: 'Monto',
        class: 'text-center',
        field: 'monto_doc',
        sortable: true,
      },
      {
        headerName: 'Monto no ant.',
        class: 'text-center',
        field: 'monto_no_anticipado',
        sortable: true,
      },
      {
        headerName: 'Monto ant.',
        class: 'text-center',
        field: 'monto_anticipado',
        sortable: true,
      },
      {
        headerName: 'Saldo Financiado',
        class: 'text-center',
        field: 'saldo_financiado',
        sortable: false,
        filterProp: 'saldo_financiado'
      },
      {
        headerName: 'Diferencia Precio',
        class: 'text-center',
        field: 'diferencia_precio',
        sortable: true,
      },
      {
        headerName: 'Tasa Negociada',
        class: 'text-center',
        field: 'tasa_negociada',
        sortable: true,
      },
      {
        headerName: 'Comisiones',
        class: 'text-center',
        field: 'comsiones',
        sortable: true,
      },
      {
        headerName: 'Gastos',
        class: 'text-center',
        field: 'gastos',
        sortable: true,
      },
      {
        headerName: 'IGV',
        class: 'text-center',
        field: 'igv',
        sortable: true,
      },
      {
        headerName: 'CXC Diferencia Precio',
        class: 'text-center',
        field: 'cxc_diferencia_precio',
        sortable: true,
      },
      {
        headerName: 'CXC Comisiones',
        class: 'text-center',
        field: 'cxc_comisiones',
        sortable: true,
      },
      {
        headerName: 'CXC Gastos',
        class: 'text-center',
        field: 'cxc_gastos',
        sortable: true,
      },
      {
        headerName: 'CXC IGV',
        class: 'text-center',
        field: 'cxc_igv',
        sortable: true,
      },
      {
        headerName: 'Anticipo Comité',
        class: 'text-center',
        field: 'anticipo_comite',
        sortable: true,
      },
      {
        headerName: 'Días',
        class: 'text-center',
        field: 'dias',
        sortable: true,
      },
      {
        headerName: 'Pago',
        class: 'text-center',
        field: 'pago',
        sortable: true,
      },
      {
        headerName: 'Tipo Pagador',
        field     : 'tipo_pagador',
        filterProp: 'tipo_pagador',
        sortable  : true,
        class     : 'text-center',
        filterable: true,
        filterSelectItems: tipo_pagadores,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Descripción',
        class: 'text-center',
        field: 'descripcion',
        sortable: true,
      },
      {
        headerName: 'Fecha Pago 1',
        class: 'text-center',
        field: 'fecha_pago1',
        sortable: true,
        pipe: "date"
      },
      {
        headerName: 'Fecha Registro Recaudacion Pago 1',
        class: 'text-center',
        field: 'fecha_registro_recaudacion_pago1', 
        sortable: true,
        pipe: "date"
      },
      {
        headerName: 'Fecha Pago 2',
        class: 'text-center',
        field: 'fecha_pago2',
        sortable: true,
        pipe: "date"
      },
      {
        headerName: 'Fecha Registro Recaudacion Pago 2',
        class: 'text-center',
        field: 'fecha_registro_recaudacion_pago2',
        sortable: true,
        pipe: "date"
      },
      {
        headerName: 'Banco',
        class: 'text-center',
        field: 'banco',
        sortable: true,
      },
      {
        headerName: 'Intereses Devolver',
        class: 'text-center',
        field: 'intereses_devolver',
        sortable: true,
      },
      {
        headerName: 'Excedente Monto',
        class: 'text-center',
        field: 'excedente_monto',
        sortable: true,
      },
      {
        headerName: 'Excedente Apl. CXC',
        class: 'text-center',
        field: 'excedente_aplicacion_cxc',
        sortable: true,
      },
      {
        headerName: 'Excedente Devuelto',
        class: 'text-center',
        field: 'excedente_devuelto',
        sortable: true,
      },
      {
        headerName: 'Fecha Devolución Excedente',
        class: 'text-center',
        field: 'fecha_devolucion_excedente',
        sortable: true,
        pipe: "date"
      },
      {
        headerName: 'Oficial de Negocio',
        field     : 'oficial_negocio',
        filterProp: 'oficial_negocio',
        sortable  : true,
        class     : 'text-center',
        filterable: true,
        filterSelectItems: oficiales_negocio,
        filterItemsProps: {
          value: 'id',
          label: 'nombre'
        },
      },
      {
        headerName: 'Estado',
        field     : 'estado',
        filterProp: 'estados',
        sortable  : true,
        class     : 'text-center',
        filterable: true,
        filterSelectItems: estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
        multipleSelectedItems: true
      },
      {
        headerName: 'Fecha Vencimiento Prórroga',
        class: 'text-center',
        field: 'fecha_venc_prorroga',
        sortable: true,
        pipe: "date",
        filterable: true,
        filterDate: true,
        filterProp: 'fecha_venc_prorroga',
      },
      {
        headerName: 'Banco Cargo',
        class: 'text-center',
        field: 'banco_cargo',
        sortable: true,
      },
      {
        headerName: 'Nro cuenta',
        class: 'text-center',
        field: 'cuenta_cargo',
        sortable: true,
      },
      {
        headerName: 'Empresa Banco',
        class: 'text-center',
        field: 'empresa_banco',
        sortable: true,
      },
		];
  }

  loadTable(data) {

		this.rowData = functions.indextable( data )

		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  getReporteSabana({page, per_page, filtros}) {
    // console.log(filtros)
    this.factoringService.getReporteSabana(
      page, 
      per_page,
      filtros.cliente_razon_social,
      filtros.cliente_ruc,
      filtros.aceptante_razon_social,
      filtros.aceptante_ruc,
      filtros.nro_operacion,
      filtros.fecha_desembolso__gte,
      filtros.fecha_desembolso__lte,
      filtros.fecha_vencimiento_nominal__gte,
      filtros.fecha_vencimiento_nominal__lte,
      filtros.tipo_pagador,
      this.authService.user.isOficialDeNegocio ? this.authService.user.id : filtros.oficial_negocio,
      filtros.estados,
      filtros.empresa,
      filtros.tipo_operacion
      ).then(res=>{
        this.loadTable(res)
    })
  }

  goToPage({page, per_page, filtros}){
    this.filtros = filtros
    this.getReporteSabana({page, per_page, filtros})
	}

  async openExcelModal() {
    const result = await Swal.fire({
      title: 'Generar Excel',
      text: "Elige si deseas generar el excel de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    console.log(result)
    if (result.isDenied) {
      this.generarExcel(this.rowData)
    } else if(result.isConfirmed) {
      this.generarExcelCompleto()
    }
  }

  async generarExcelCompleto() {
    const page_size = 5000
    let page = 1
    let data = []

    this.generando = true
    while (this.generando) {
      try {
        let res = await this.factoringService.getReporteSabana(
          page, 
          page_size,
          this.filtros.cliente_razon_social ? this.filtros.cliente_razon_social : undefined,
          this.filtros.cliente_ruc ? this.filtros.cliente_ruc : undefined,
          this.filtros.aceptante_razon_social ? this.filtros.aceptante_razon_social : undefined,
          this.filtros.aceptante_ruc ? this.filtros.aceptante_ruc : undefined,
          this.filtros.nro_operacion ? this.filtros.nro_operacion : undefined,
          this.filtros.fecha_desembolso__gte ? this.filtros.fecha_desembolso__gte : undefined,
          this.filtros.fecha_desembolso__lte ? this.filtros.fecha_desembolso__lte : undefined,
          this.filtros.fecha_vencimiento_nominal__gte ? this.filtros.fecha_vencimiento_nominal__gte : undefined,
          this.filtros.fecha_vencimiento_nominal__lte ? this.filtros.fecha_vencimiento_nominal__lte : undefined,
          this.filtros.tipo_pagador ? this.filtros.tipo_pagador : undefined,
          this.authService.user.isOficialDeNegocio ? this.authService.user.id : (this.filtros.oficial_negocio ? this.filtros.oficial_negocio : undefined),
          this.filtros.estados ? this.filtros.estados : undefined,
          this.filtros.empresa ? this.filtros.empresa : undefined,
          this.filtros.tipo_operacion ? this.filtros.tipo_operacion : undefined,
          false // showMask
          )

        data = data.concat (res['results'] )
        page++

        if (res['page_number'] == res['num_pages']) {
          this.generando = false
          this.generarExcel(data)
        }
      } catch (ex) {
        console.log(ex)
        this.generando = false
      }
    }
  }

  generarExcel(data) {

    /* generate a worksheet */
    var ws = utils.json_to_sheet(this.newData( data ));

    /* add to workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hoja 1");

    /* write workbook and force a download */
    writeFile(wb, `Reporte - ${new Date().toLocaleString()}.xlsx`);
  }

  newData(data){

    let newDatos = [];

    newDatos = data.map( item=>({

      cliente: item.cliente,
      razon_social_cliente: item.razon_social_cliente,
    	ciiu: item.ciiu,
      aceptante: item.aceptante,
      razon_social_aceptante: item.razon_social_aceptante,
      nro_operacion: item.nro_operacion,
      tipo_operacion: item.tipo_operacion,
      adelanto: item.adelanto,
    	nro_operacion_cliente: item.nro_operacion_cliente,
      fecha_cesion: item.fecha_cesion,
      fecha_desembolso: item.fecha_desembolso,
      nro_documento: item.nro_documento,
      documento: item.documento,
      fch_vencimiento_nominal: item.fch_vencimiento_nominal,
      monto_doc: item.monto_doc,
      monto_no_anticipado: item.monto_no_anticipado,
      monto_anticipado: item.monto_anticipado,
      saldo_financiado: item.saldo_financiado,
      diferencia_precio: item.diferencia_precio,
      tasa_negociada: item.tasa_negociada,
      comsiones: item.comsiones,
      gastos: item.gastos,
      igv: item.igv,
      cxc_diferencia_precio: item.cxc_diferencia_precio,
      cxc_comisiones: item.cxc_comisiones,
      cxc_gastos: item.cxc_gastos,
      cxc_igv: item.cxc_igv,
    	anticipo_comite: item.anticipo_comite,
      dias: item.dias,
      pago: item.pago,
      tipo_pagador: item.tipo_pagador,
      descripcion: item.descripcion,
      fecha_pago1: item.fecha_pago1,
      fecha_registro_recaudacion_pago1: item.fecha_registro_recaudacion_pago1,
      fecha_pago2: item.fecha_pago2,
      fecha_registro_recaudacion_pago2: item.fecha_registro_recaudacion_pago2,
      banco: item.banco,
      intereses_devolver: item.intereses_devolver,
      excedente_monto: item.excedente_monto,
      excedente_aplicacion_cxc: item.excedente_aplicacion_cxc,
      excedente_devuelto: item.excedente_devuelto,
      fecha_devolucion_excedente: item.fecha_devolucion_excedente,
      oficial_negocio: item.oficial_negocio,
      estado: item.estado,
      fecha_venc_prorroga: item.fecha_venc_prorroga,
      empresa: item.empresa,
      banco_cargo: item.banco_cargo,
      cuenta_cargo: item.cuenta_cargo,
      empresa_banco: item.empresa_banco
    }))

    return newDatos;

  }

}
