import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { ToastService } from 'app/core/services/toast/toast.service';

@Component({
  selector: 'app-formato-evaluacion-riesgo',
  templateUrl: './formato-evaluacion-riesgo.component.html',
  styleUrls: ['./formato-evaluacion-riesgo.component.css']
})
export class FormatoEvaluacionRiesgoComponent implements OnInit {

  myForm: FormGroup;
  estaciones: any[];
  public firmantes: any[] = [];
  firmanteValue;
  razon_social;

  @Input() title;
  @Input() beneficiario: number;
  @Input() beneficiario_tipo;
  @Input() fecha_vencimiento;
  @Input() fecha_contrato;
  @Input() formato_codigo;
  @Input() formato_plantilla_url;
  @Input() operacion;


  constructor(
    private modalService   : NgbModal,
    public activeModal     : NgbActiveModal,
    private formBuilder    : FormBuilder,
    private formatoService : FormatosService,
    public toast           : ToastService,
    private clientesService: ClientesService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.formatoService.obtenerFirmantes(this.beneficiario).then((response: any) => {
      this.firmantes = response['results'];
    });

    this.clientesService.obtenerCliente(this.beneficiario).then((cliente: any) => {
      this.razon_social = cliente.nombre == undefined ? '' : cliente.nombre;

    });

  }

  async createForm() {
    const clienteId = (this.operacion.operacion.tipo_linea_solicitada == 1) ? this.operacion.beneficiario : this.operacion.operacion.beneficiario_factoring;

    this.myForm = this.formBuilder.group({
      firmante: [null, Validators.required],
      emision: [{ value: new Date(), disabled: true }, Validators.required],
      contrato: [{ value: new Date(this.fecha_contrato), disabled: true }, Validators.required],
      vcto_contrato: [{ value: new Date(this.fecha_vencimiento), disabled: true }, Validators.required],
      glosa: [{ value: '', disabled: true }],
      nro_documento: [{ value: '', disabled: true }, Validators.required],
      vcto_firma: [{ value: null, disabled: true }, Validators.required],
      lugar_firma: [{ value: '', disabled: true }, Validators.required],
      beneficiario_tipo: [{ value: this.beneficiario_tipo == '' ? 'J' : this.beneficiario_tipo, disabled: true }, Validators.required],
      tipo_persona: [this.beneficiario_tipo == '' ? 'J' : this.beneficiario_tipo, Validators.required]
    });

    const cliente = await this.clientesService.obtenerCliente(clienteId)
    
    if (this.operacion.operacion.tipo_linea_solicitada == 2) {
      this.beneficiario_tipo = cliente['tipo_persona'] == 1 ? 'N' : 'J'
    }
    this.myForm.controls.tipo_persona.setValue(this.beneficiario_tipo)

    this.myForm.get("firmante").valueChanges.subscribe(selectedValue => {
      this.firmantes.filter(x => x.id == selectedValue)
        .forEach(x => {
          this.myForm.get("nro_documento").setValue(x.nro_documento);
          this.myForm.get("lugar_firma").setValue(x.lugar_firma);
          this.myForm.get("vcto_firma").setValue(new Date(x.vcto_firma));
          this.myForm.get("glosa").setValue(x.glosa);
        });

    });

  }

  onSubmit() {

    if (this.myForm.valid) {

      var fecha = new Date(this.myForm.get("vcto_firma").value);
      var hoy = new Date();

      if (hoy > fecha) {
        this.toast.warning("Firma expirada");
        return;
      }

      var model = {
        "cliente": this.razon_social,
        "fecha_actual": this.myForm.get("emision").value,
        "vcto_contrato": this.myForm.get("vcto_contrato").value,
        "nro_documento": this.myForm.get("nro_documento").value,
        "lugar_firma": this.myForm.get("lugar_firma").value,
        "beneficiario_tipo": this.myForm.get("beneficiario_tipo").value,
        "archivo_plantilla": this.formato_plantilla_url,
      }

      this.formatoService.generarFormatoEvaluacionRiesgo(model).then(
        (x: any) => {
          var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "Evaluacion Riesgo.xlsx";
          link.click();
          this.activeModal.close(model);
        }, (reason) => {
          this.activeModal.close(model);
        });
    }
    else {
      this.toast.warning("El formulario no está completo.");
    }
  }

}

export class FirmanteModel {
  public id: number;
  public nombre_firmante: string;
  public nro_documento: string;
  public glosa: string;
  public vcto_firma: Date;
  public lugar_firma: string;

  constructor(id, nombre_firmante, nro_documento, glosa, vcto_firma, lugar_firma) {
    this.id = id;
    this.nombre_firmante = nombre_firmante;
    this.nro_documento = nro_documento;
    this.glosa = glosa;
    this.vcto_firma = vcto_firma;
    this.lugar_firma = lugar_firma;
  }

}
