import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { AuthService } from "app/core/services/auth/auth.service";
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
  selector: 'app-lineas-credito',
  templateUrl: './lineas-credito.component.html',
  styleUrls: ['./lineas-credito.component.css']
})
export class LineasCreditoComponent implements OnInit {

  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public searchstring: string = '';

  @Input() clienteId = 0;
  @Input() readonly = false;

  active = 1;
  myForm: FormGroup;
  formCondiciones: FormGroup;
  beneficiario: any;
  oficiales: any[] = [];
  gerencia: any[] = [];
  oficls: any[] = [];
  cobranzas: any[] = []
  lideresDeEquipo: any = []

  linea: any;
  empresas: any[];

  constructor(
    public formbuild: FormBuilder,
    private clientesService: ClientesService,
    private factoringService: FactoringService,
    private authService: AuthService,
  ) {
    this.obtenerEmpresas();
  }

  async ngOnInit() {
    this.initForm();
    this.initVars();

    this.obtenerBeneficiario();
    this.obtenerHistorico();

    await this.obtenerOficiales();


    this.obtenerAsistentes()
    this.oficls = [
      ...this.oficiales,
      ...this.gerencia,
      ...this.lideresDeEquipo
    ];

    if (this.readonly) {
      this.myForm.disable()
    }

  }
  obtenerEmpresas() {
    this.factoringService.obtenerEmpresas().then((res) => {
      this.empresas = res['results'];
    });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      oficial_negocio: [null, [Validators.required]],
      asistente_cobranzas: [null, [Validators.required]],
      empresa: [1,]
    })
    this.formCondiciones = this.formbuild.group({
      'condiciones_especiales': [null]
    })
  }

  setForm(values) {
    for (const key in values) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(values[key]);
      }
    }

    if (values && values['condiciones_especiales'] !== undefined) {
      this.formCondiciones.controls['condiciones_especiales'].setValue(values['condiciones_especiales'])
    }
  }

  obtenerBeneficiario() {
    this.factoringService.obtenerBeneficiario(this.clienteId)
      .then(res => {
        this.beneficiario = res;
        this.setForm(res);
        this.obtenerLineaCredito();
      }).catch(error => {
        console.error(error);

      })
  }

  async obtenerOficiales() {
    this.oficiales = [];
    this.gerencia = [];
    await this.clientesService.obtenerOficiales('3', 'true')
      .then(res => {
        this.oficiales = res['results'];
      }).catch(error => {
        console.error(error);

      });

    // Traer gerencia comercial
    await this.clientesService.obtenerOficiales('4', 'true')
      .then(res => {
        this.gerencia = res['results'];
      }).catch(error => {
        console.error(error);
      });

    await this.clientesService.obtenerOficiales('18', 'true')
      .then(res => {
        this.lideresDeEquipo = res['results'];
      }).catch(error => {
        console.error(error);
      });


    await this.clientesService.obtenerOficiales('16', 'true')
      .then(res => {
        this.gerencia = this.gerencia.concat(res['results'])
      }).catch(error => {
        console.error(error);
      });
  }

  obtenerAsistentes() {
    this.cobranzas = [];
    this.clientesService.obtenerOficiales('13', 'true')
      .then(res => {
        this.cobranzas = res['results'];
      }).catch(error => {
        console.error(error);
      });
  }


  obtenerHistorico() {
    this.clientesService.obtenerLineasHstorico(this.clienteId.toString())
      .then(res => {
        this.loadTable(res);
      }).catch(error => {
        console.error(error);

      });
  }

  obtenerLineaCredito() {
    let { myForm } = this
    this.linea = this.beneficiario.lineas_de_credito.find((linea) => linea.tipo == 1 && linea.empresa == myForm.get('empresa').value && (!linea.puntual))
    this.setForm(this.linea);
    // this.factoringService.obtenerLineas(this.clienteId)
  }

  initVars() {
    let columns = [
      {
        headerName: 'N°.',
        field: 'id',
        sortable: true,
        pipe: 'indexcol'
      },
      {
        headerName: 'Tipo',
        field: 'tipo_descripcion',
        sortable: true,
        class: 'text-center',
      },
      {
        headerName: 'Moneda',
        field: 'moneda_descripcion',
        sortable: true,
        class: 'text-center',
      },
      {
        headerName: 'Monto',
        field: 'linea_actual',
        sortable: true,
        class: 'text-center',
        pipe: "currency",
        moneda: "moneda",
      },
      {
        headerName: 'TEM Soles',
        field: 'tem_soles',
        sortable: true,
        pipe: 'percent-icon',
        class: 'text-center',
      },
      {
        headerName: 'TEM Dólares',
        field: 'tem_dolares',
        sortable: true,
        pipe: 'percent-icon',
        class: 'text-center',
      },
      {
        headerName: 'Anticipo',
        field: 'anticipo',
        sortable: true,
        pipe: 'percent-icon',
        class: 'text-center',
      },
      {
        headerName: 'Tasa Mora',
        field: 'mora',
        sortable: true,
        pipe: 'percent-icon',
        class: 'text-center',
      },
      {
        headerName: 'Contrato',
        field: 'numero_contrato',
        sortable: true,
        class: 'text-center',
      },
      {
        headerName: 'Fecha Contrato',
        field: 'fecha_contrato',
        sortable: true,
        pipe: 'date',
        class: 'text-center',
      },
      {
        headerName: 'Fecha Vencimiento',
        field: 'fecha_vencimiento_contrato',
        sortable: true,
        pipe: 'date',
        class: 'text-center',
      },
      {
        headerName: 'Observaciones',
        field: 'observaciones',
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }

  loadTable(data) {

    this.rowData = data.results;
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }

  }

  async onSubmit() {
    if (this.myForm.invalid) return;

    await this.clientesService.updateParcialCliente(this.clienteId, {
      'oficial_negocio': this.myForm.controls['oficial_negocio'].value,
      'asistente_cobranzas': this.myForm.controls['asistente_cobranzas'].value
    })

    this.obtenerBeneficiario();
    this.obtenerOficiales();
    this.obtenerAsistentes()
    this.obtenerLineaCredito();
  }

  guardarCondicionesEspeciales() {
    if (this.formCondiciones.invalid) return

    this.clientesService.updateParcialBeneficiario(this.clienteId, this.formCondiciones.value)
      .subscribe(data => {
        this.clientesService.toast.success('Observaciones actualizadas')
      })
  }

  get canUpdateCondiciones() {
    const permitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilAnalistaOperacionesID,
    ]
    return permitidos.indexOf(this.authService.user.perfil) != -1
  }

}
