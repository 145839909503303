import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/core/services/auth/auth.service';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';

import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

import Swal from 'sweetalert2';
import { utils, writeFile } from 'xlsx';
import { functions } from '../../../../core/helpers/functions';

@Component({
  selector: 'app-reporte-cambio-linea-xcliente',
  templateUrl: './reporte-cambio-linea-xcliente.component.html',
  styleUrls: ['./reporte-cambio-linea-xcliente.component.css']
})
export class ReporteCambioLineaXclienteComponent implements OnInit {

	public headerTable: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
  
  public rowData = [];
  public generando: boolean = false;

  public lineas = []

  public filtros: any = {
    beneficiario : undefined,
    tipo : undefined,
    oficial : undefined,
    fecha_alta__lte : undefined,
    fecha_alta__gte : undefined,
  };

  constructor(
    private factoringService: FactoringService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.getReporteCambioDeLineaXCliente({page:1, per_page:this.pageTablePerPages, filtros:{}})

    const lineas = this.factoringService.obtenerTiposDeLineas()

    Promise.all([lineas]).then(res=>{
      this.lineas = res[0]['results']
      this.loadTableColumns(this.lineas)
    })
    this.loadTableColumns(lineas)
  }

  loadTableColumns (lineas) {
    this.headerTable = [
      {
				headerName: 'N°',
        field: 'index',
				// pipe: 'indexcol',
				sortable: true,
			},
      {
        headerName: 'RUC',
        class: 'text-center',
        field: 'beneficiario_ruc',
        sortable: true,
      }, 
      {
        headerName: 'Cliente',
        class: 'text-center',
        field: 'beneficiario_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'beneficiario_nombre'
      },
      {
        headerName: 'Tipo de Producto',
        field     : 'tipo_descripcion',
        filterProp: 'tipo',
        sortable  : true,
        class     : 'text-center',
        filterable: true,
        filterSelectItems: lineas,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Moneda',
        field     : 'moneda',
        sortable  : true,
        class: 'text-center',
        pipe      : 'function',
        function : function(row, header){
          if (row.moneda == 1) {
            return 'Soles'
          }else{
            return 'Dólares'
          }
        }
      },
      {
         headerName: 'Fecha Alta',
         class: 'text-center',
         field: 'fecha_alta',
         sortable: true,
         pipe: "date",
         filterable: true,
         filterDate: true,
         filterProp: 'fecha_alta'
       },
      {
        headerName: 'Monto Línea',
        class: 'text-center',
        field: 'linea_actual',
        sortable: true,
        pipe      : 'function',
				function : function(row, header){
          if (row.moneda == 1) {
            let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(row.linea_actual))
            return `S/ ${number}`
          }
          else {
            return `$ ${Number(row.linea_actual).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
          }
				},
      },
      {
        headerName: 'Ejecutivo',
        class: 'text-center',
        field: 'oficial_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'oficial'
      }, 
      {
        headerName: 'Contrato',
        class: 'text-center',
        field: 'numero_contrato',
        sortable: true,
      }, 
      {
        headerName: 'Fecha Contrato',
        class: 'text-center',
        field: 'fecha_contrato',
        sortable: true,
        pipe: "date",
      },
      {
        headerName: 'Fecha Fin Contrato',
        class: 'text-center',
        field: 'fecha_vencimiento_contrato',
        sortable: true,
        pipe: "date",
      },
    ]
  }

  loadTable(data) {
    this.rowData = functions.indextable( data )

		this.pageTableActual   = data.page_number
		this.pageTableTotal    = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages    = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  getReporteCambioDeLineaXCliente({page, per_page, filtros}) {
    
    this.factoringService.getReporteCambioLineaxCliente(
      filtros.beneficiario_nombre,
      filtros.tipo,
      filtros.oficial,
      filtros.fecha_alta__lte,
      filtros.fecha_alta__gte,
      page, 
      per_page,
      ).then(res=>{
        this.loadTable(res)
    })
  }

  goToPage({page, per_page, filtros}){
    this.filtros = filtros
    this.getReporteCambioDeLineaXCliente({page, per_page, filtros})
	}

// Descargar el exel
  async openExcelModal() {
    const result = await Swal.fire({
      title: 'Generar Excel',
      text: "Elige si deseas generar el excel de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    console.log(result)
    if (result.isDenied) {
      this.generarExcel(this.rowData)
    } else if(result.isConfirmed) {
      this.generarExcelCompleto()
    }
  }

  async generarExcelCompleto() {
    const page_size = 5000
    let page = 1
    let data = []
  
    
    this.generando = true
    while (this.generando) {
      try {
        let res = await this.factoringService.getReporteCambioLineaxCliente(
          this.filtros.beneficiario_nombre ? this.filtros.beneficiario_nombre : undefined,
          this.filtros.tipo ? this.filtros.tipo : undefined,
          this.filtros.oficial ? this.filtros.oficial : undefined,
          this.filtros.fecha_alta__lte ? this.filtros.fecha_alta__lte : undefined,
          this.filtros.fecha_alta__gte ? this.filtros.fecha_alta__gte : undefined,
          page, 
          page_size,
          )

        data = data.concat(res['results'])
        page++
        if (res['page_number'] == res['num_pages']) {
          this.generando = false
          this.generarExcel(data)
        }
      } catch (ex) {
        console.log(ex)
        this.generando = false
      }
    }
  }

  generarExcel(data) {

    // Se deben definir las columnas que se van a mostrar
    var header = ['beneficiario_ruc', 'beneficiario_nombre','tipo_descripcion','moneda_descripcion','fecha_alta','linea_actual','tipo_historico_descripcion','oficial_nombre','numero_contrato','fecha_contrato','fecha_vencimiento_contrato']
    var columns = header.length - 1
 
    const wb = utils.book_new();
    var Heading = [
      ["RUC", "Cliente","Tipo de Producto","Moneda","Fecha Alta","Monto Línea",'Tipo Histórico',"Ejecutivo","Contrato","Fecha Contrato","Fecha Fin Contrato"],
    ];
    
    // const ws = utils.json_to_sheet(data, { header,skipHeader: true });
    
    const ws = utils.json_to_sheet(data, { header,skipHeader: true });
    ws['!ref'] = utils.encode_range({s: {c:0,r:0},e: {c:columns, r:data.length}})
    utils.sheet_add_aoa(ws, Heading);
    utils.book_append_sheet(wb, ws, 'Hoja 1');

    writeFile(wb,`Reporte - ${new Date().toLocaleString()}.xlsx`);

   
  }
}

