import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ExcedentesService } from 'app/core/services/excedentes/excedentes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { DevolucionModalComponent } from '../../../../componentes/devolucion-modal/devolucion-modal.component';
import { functions } from '../../../../../../core/helpers/functions';
import { toArray } from 'rxjs/operators';
import * as XLSX from 'xlsx'
import { concat, Observable, of, Subject, throwError } from 'rxjs';

@Component({
  selector: 'app-devoluciones-observadas',
  templateUrl: './devoluciones-observadas.component.html',
  styleUrls: ['./devoluciones-observadas.component.css']
})
export class DevolucionesObservadasComponent implements OnInit {
  faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = "";
  public canCreate: boolean = false;
  public tmpData: any;
  exportando = false;
	tblFiltros = {}

  constructor(
    public modalService: NgbModal,
    public factoring: FactoringService,
    public router: Router,
    public authService: AuthService,
    public devolucionesService: ExcedentesService
  ) { }

  @Input() tableSelectable: boolean = false;
  @Input() set refresh(value) {
    this.tableSelectable = false;
    this.obtenerDevoluciones();
  }

  @Output() ocultarBtn = new EventEmitter();


  ngOnInit(): void {
    this.obtenerDevoluciones();

    this.ocultarBtn.emit(false)
  }

  obtenerDevoluciones() {
    let devoluciones = this.devolucionesService.obtenerDevolucionesObservadas();
    let estados = this.devolucionesService.obtenerEstados();
    let empresas = this.factoring.obtenerEmpresas();
    let observaciones = this.devolucionesService.obtenerObservaciones();

    var estado = '6'
    var filtros ={
      estado
    }

    this.tblFiltros = filtros

    Promise.all([devoluciones, estados, empresas, observaciones]).then(
      (res: any[]) => {
        let devoluciones = res[0];
        let estados = res[1]['results'];
        let empresas = res[2]['results'];
        let observaciones = res[3]['results']
        this.initVars(estados, empresas, observaciones);
        this.loadTable(devoluciones);
      }
    );
  }

  loadTable(data) {
    this.rowData = functions.indextable( data )
    // this.rowData = data.results.map((element, index) => ({
    //   ...element, index: index + 1
    // }));
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars(estados, 
    empresas,
    observacionList = []) {
    let columns = [
      {
        headerName: "ID",
        field: 'id',
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "cliente_nombre",
        sortable: true,
        filterable: true,
        filterProp: "cliente_nombre",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC Cliente",
        field: "cliente_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
        sortable: true,
        filterable: true,
        filterProp: "aceptante_nombre",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC Aceptante",
        field: "aceptante_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cant. Docs",
        field: "cantidad_docs",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        filterable: true,
        filterRange: true
      },
      {
        class: "text-center",
        headerName: "Fch. Solicitud",
        field: "fecha_solicitud",
        pipe: "date",
        sortable: true,
        filterable: true,
        filterDate: true,
      },
      {
        class: "text-center",
        headerName: "Fch. Aprobación",
        field: "fecha_aprobacion",
        pipe: "date",
        sortable: true,
      },
      {
        headerName: 'Compensación',
        field: 'compensacion',
        class: 'text-center',
        filterable: true,
        filterProp: 'compensacion',
        filterSelectItems: [
          {id: true, descripcion: 'Si'},
          {id: false, descripcion: 'No'}
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
        pipe: 'function',
        function: row => row['compensacion'] ? 'Si' : 'No',
      },
      {
        headerName: 'Estado',
        field: 'estado_descripcion',
        filterProp: 'estado',
        sortable: true,
        class: 'text-center',
        filterSelectItems: estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        }
      },
      {
        headerName: "Observacion",
        field: "solicitud_observaciones",
        sortable: true,
        class: "text-center",
        pipe: 'function',
        function: row => row['solicitud_observaciones'].length > 1 ? 'Varios' : row['solicitud_observaciones'][0]['descripcion'],
        filterable: true,
        filterProp: "solicitud_observaciones",
        filterSelectItems: observacionList.concat(),
        filterItemsProps: {
          value: "id",
          label: "descripcion",
        },
      }
    ];

    this.columnDefs = columns;
  }

  tableEditEvent(row) {

    const modalRef = this.modalService.open(DevolucionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });

    modalRef.componentInstance.devolucionId = row.id;
    modalRef.componentInstance.mostrarDeuda = true;

    modalRef.result.then((result) => {
      console.log(result);
      if (result) {
        this.obtenerDevoluciones();
      }
    }, (reason) => {
      console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
      console.log(result);
      this.obtenerDevoluciones();
      // this.tableEditEvent(result);
    },
      error => {

      });

  }
  async exportarDataTablaSolicitudes() {
		const lote = 100
		console.log('Filtros Tabla', this.tblFiltros)

		this.exportando = true
    //obtenerDevolucionesVigentes
		this.devolucionesService.getDevolucionesObservadas(Object.assign({ 'page_size': 1 }, this.tblFiltros)).subscribe(
			data => {
			let pages = Math.ceil(data['count'] / lote)
			let pages$ = Array.from({ length: pages }).map((_, i) => i + 1).map(
				page => this.devolucionesService.getDevolucionesObservadas(Object.assign({ page, 'page_size': lote }, this.tblFiltros))
			)
			concat(...pages$).pipe(toArray()).subscribe((data: any) => {
				data = data.map((x: any) => x.results).flat(1)
				data = data.map(x => {
				let row = {}
				for (let header of this.columnDefs) {
          if(header['headerName'] == 'Observacion'){
            console.log(header['field'])
            if (x[header['field']].length > 1){
              row[header['headerName']] = 'Varios'
            }else{
              row[header['headerName']] = x[header['field']][0]['descripcion']
            }
          }else if(header['field'] == 'compensacion'){
            if(x[header['field']] == true){
              row[header['headerName']] = 'SI'
            }else{
              row[header['headerName']] = 'NO'
            }

          }else if(header['field'] == 'monto'){
            if(x['moneda'] == 1){
              row['Moneda'] = 'SOLES'
              row[header['headerName']] = parseFloat(x[header['field']])
            }else{
              row['Moneda'] = 'DOLARES'
              row[header['headerName']] =  parseFloat(x[header['field']])
            }
          }else{
            row[header['headerName']] = x[header['field']]
          }
				}
				return row
				})
				let ws = XLSX.utils.json_to_sheet(data)
				let wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, ws, 'Solicitudes')
				XLSX.writeFile(wb, 'Reporte Solicitudes Excedente Observado.xlsx')
			}).add(() => this.exportando = false)
			},
			res => {
			console.log(res)
			this.exportando = false
			}
		)
	}

  goToPage({ page, per_page, filtros }) {

    let data_gte = (filtros.fecha__gte) ? filtros.fecha__gte : '';
    let data_lte = (filtros.fecha__lte) ? filtros.fecha__lte : '';
    data_gte = data_gte.replace(' 00:00', '');
    data_lte = data_lte.replace(' 00:00', '');

    // Export
    var cliente__iconstains = filtros.cliente_nombre ? filtros.cliente_nombre : ''
    var monto__gte = (filtros.valueFrom) ? filtros.valueFrom : ''
    var monto__lte =  (filtros.valueTo) ? filtros.valueTo : ''
    var fecha_solicitud__gte = data_gte
    var fecha_solicitud__lte = data_lte
    var aprobacion_gte = ''
    var aprobacion_lte = ''
    var estado = '6'
    var empresa = filtros.empresa ? filtros.empresa: ''
    var aceptante__iconstains = filtros.aceptante_nombre ? filtros.aceptante_nombre: ''
    var compensacion = filtros.compensacion ? filtros.compensacion: ''
    var filtros_export = {
      page,
      per_page,
      cliente__iconstains,
      monto__gte,
      monto__lte,
      fecha_solicitud__gte,
      fecha_solicitud__lte,
      aprobacion_gte,
      aprobacion_lte,
      estado,
      empresa,
      aceptante__iconstains,
      compensacion
    }
    this.tblFiltros = filtros_export

    let devoluciones = this.devolucionesService.obtenerDevolucionesObservadas(
      page,
      per_page,
      filtros.cliente_nombre,
      (filtros.valueFrom) ? filtros.valueFrom : '',
      (filtros.valueTo) ? filtros.valueTo : '',
      data_gte,
      data_lte,
      '',
      '',
      filtros.estado,
      filtros.empresa,
      filtros.aceptante_nombre,
      filtros.compensacion,
      filtros.solicitud_observaciones
    );

    devoluciones.then(res => {
      this.loadTable(res);
    }).catch(error => {

    });

  }

  workflowEvent(row) {

  }

}
