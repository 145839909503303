import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ExcedentesService } from 'app/modules/excedentes/excedentes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { AddCuentasAbonoComponent } from 'app/modules/clientes/modals/add-cuentas-abono/add-cuentas-abono.component';
import { ExcepcionDeudaLeasingComponent } from 'app/shared/modals/operaciones/excepcion-deuda-leasing/excepcion-deuda-leasing.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-devolucion-modal-confirmar',
  templateUrl: './devolucion-modal-confirmar.component.html',
  styleUrls: ['./devolucion-modal-confirmar.component.css']
})
export class DevolucionModalConfirmarComponent implements OnInit {
  @ViewChild('modalDevolucionesConfirmar', { static: false }) public modalDevolucionesConfirmar;
  @Input() clientesDevoluciones: any = [];
  @Input() propietario: number = null;
  @Input() currentPagadorType : number = 0;
  @Output() reload = new EventEmitter<any>();

  MONTO_MINIMO_DEVOLUCION = 20

  faTimes = faTimes
  public _devolucionesModalConfirmar: any;
  public resumenClientes: any[] = [];
  public monedas: any[] = [
    {id : 1, nombre: 'Soles'},
    {id : 2, nombre: 'Dólares'},
  ]

  constructor(
    public modalService      : NgbModal,
    public excedentesServicio: ExcedentesService,
    public formbuild         : FormBuilder,
    private factoringService: FactoringService,
    public authService       : AuthService,
    public functions         : SharedFunctions,
  ) { }

  ngOnInit(): void {
  }

  async displayModal(clientes) {
    this.obtenerResumen(clientes)
    if (!await this.validarOmitirMontoMinimo()) return
    this.removerMenoresMontoMinimo()

    this.modalService.open(this.modalDevolucionesConfirmar, {
      size: 'xl',
      windowClass: 'modalSizeXXL',
    }).result.then((response: any) => {
    }).catch(res => {})
  }

  obtenerResumen(clientesDevolver){
    let monedas = [1, 2]
    this.resumenClientes = []
    
    clientesDevolver.forEach((cliente) => {

      let total_excedentes = cliente.excedentes_devolver.map((item,index)=>{
        let excedente = cliente.excedentes.find((exc) => exc.id === item)
        return excedente
      })

      for (let moneda of monedas) {
        let excedentesMoneda = total_excedentes.filter(e => e.moneda == moneda)
        if (excedentesMoneda.length == 0) continue

        let total_soles = excedentesMoneda.reduce( (acc, item) =>{
          let total = 0;
          let monto = parseFloat(item.saldo_soles)
          total     = acc + monto ;
          acc       = total
          return acc
        },0)

        let total_dolares = excedentesMoneda.reduce( (acc, item) =>{
          let total = 0;
          let monto = parseFloat(item.saldo_dolares)
          total     = acc + monto ;
          acc       = total
          return acc
        },0)

        this.resumenClientes.push({
          cliente            : cliente.cliente,
          cliente_nombre     : cliente.cliente_nombre,
          cliente_ruc        : cliente.cliente_ruc,
          excedentes_devolver: excedentesMoneda.map(({id, saldo}) => ({id, 'monto': saldo})),
          responsable        : this.authService.user.id,
          moneda             : moneda,
          total_soles        : new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(total_soles)),
          total_dolares      : Number(total_dolares).toLocaleString('en-US', { minimumFractionDigits: 2 }),
        })
      }
    })
  }


  async obtenerSeleccion(){
    let request = { data : this.resumenClientes, propietario: this.propietario }
    if (request.data.map(cliente => cliente['tiene_deuda_vencida']).some(x => x === undefined)) {
      this.factoringService.toast.warning('Aún se está consultando la deuda vencida')
      return
    }
    let tieneDeudaVencida = request.data.map(cliente => cliente['tiene_deuda_vencida']).some(x => x)
    
    if (tieneDeudaVencida) {
      const res = await this.openModalExcepcionDeudaLeasing()
      if (!res) return
      request.data.filter(cliente => cliente['tiene_deuda_vencida']).forEach(cliente => Object.assign(cliente, res))
    }

    this.excedentesServicio.generarDevoluciones(request).then((res:any)=>{
      setTimeout(()=>{
        this.modalService.dismissAll()
      },1000)
    }).catch((response)=>{
      let error = 'Ocurrió un error inesperado'
      if (response.error && typeof (response.error) == 'object') {
        error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
      }
      this.excedentesServicio.toast.warning(error)
    })
  }

  removerCliente(index) {
    this.resumenClientes.splice(index, 1)
  }

  openModalCuentaAbono(cliente) {
    const modalRef = this.modalService.open(AddCuentasAbonoComponent, {
			size: 'lg'
		})
		
		modalRef.componentInstance.clienteId = this.propietario == 1 ? null : cliente
    modalRef.componentInstance.aceptanteId = this.propietario == 1 ? cliente : null
    modalRef.componentInstance.cuentaAbonoId = 0
		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(
      result => {
        // Guardado correctamente
      },
      error => console.log(error)
    )
  }

  openModalExcepcionDeudaLeasing() {
		const modal = this.modalService.open(ExcepcionDeudaLeasingComponent, {
		  size: "md",
		})
		modal.componentInstance.value = {}
    modal.componentInstance.mensaje = 'Existe clientes que registran deuda pendiente'
		return modal.result.then(
		  (result) => result,
		  (reason) => null,
		)
	}

  receiveDeudaVencida(cliente, deuda) {
    cliente['tiene_deuda_vencida'] = !!deuda['deuda_vencida']
  }

  async validarOmitirMontoMinimo() {
    const existeMinimo = this.resumenClientes.some(e => Number(e['total_dolares'].replace(',', '')) < this.MONTO_MINIMO_DEVOLUCION)

    if (existeMinimo) {
      const modalRef = this.modalService.open(ConfirmModalComponent, {});
      let mensaje = `
        <br>
        <span class='message'>
          El monto de alguno de los excedentes es menor a <strong>${this.MONTO_MINIMO_DEVOLUCION} Dólares </strong>
          <br>
          ¿Está seguro de que desea aprobar la operación?
        </span>
      `
      modalRef.componentInstance.title = "El monto es menor al mínimo";
      modalRef.componentInstance.message = mensaje;
      modalRef.componentInstance.messageHTML = true;
      return await modalRef.result
    }
    return true
  }

  removerMenoresMontoMinimo() {
    this.resumenClientes = this.resumenClientes.filter(e => Number(e['total_dolares'].replace(',', '')) >= this.MONTO_MINIMO_DEVOLUCION)
  }

  get canAddCuentasAbono(){
    const perfilesPermitidos = [
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilTesoreriaID,
      this.authService.perfil.perfilLiderDeTesoreriaID,
    ]
    return this.currentPagadorType == 1 || perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }
}
