import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';

@Component({
  selector: 'app-manage-parametros-contraseña-modal',
  templateUrl: './manage-parametros-contraseña-modal.component.html',
  styleUrls: ['./manage-parametros-contraseña-modal.component.css']
})
export class ManageParametrosContrasenaModalComponent implements OnInit {
  faTimes = faTimes
  myForm: FormGroup;
  @Input() parametroId = 0;
  parametros: any;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild          : FormBuilder,
    public activeModal        : NgbActiveModal,
    private configurationService	: ConfigurationService,
  ) { 
    this.initForm();
  }

  ngOnInit(): void {
    if (this.parametroId) {
      // this.obtenerParametroContraseña();
    }
  }

  initForm() {
    this.myForm = this.formbuild.group({
      min_length: [null, [Validators.required]],
      min_uppercase: [null, [Validators.required]],
      min_lowercase: [null, [Validators.required]],
      min_symbol: [null, [Validators.required]],
      min_number: [null, [Validators.required]],
      last_password_count: [null, [Validators.required]],
      last_password_change: [null, [Validators.required]],
    });
  }
  
  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  // obtenerParametroContraseña() {
  //   this.configurationService.obtenerParametroContraseña(this.parametroId)
  //   .then(res => {
  //     this.parametros = res;
  //     this.setForm(res);
  //   })
  //   .catch(error => {
  //     console.error(error);
  //   });
  // }

  // onSubmit() {
  //   if(this.myForm.valid) {
  //     const data = this.myForm.value;

  //     this.configurationService.guardarParametroContraseña(this.myForm.value, this.parametroId)
  //     .then(res => {
  //       this.successEmit.emit();
  //       this.activeModal.close(true);
  //     }).
  //     catch(error => {
  //       this.activeModal.close(false);
  //     });
  //   }
  // }

}
