import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { LegalService } from 'app/core/services/legal.service';
import { ToastService } from 'app/core/services/toast/toast.service';

@Component({
  selector: 'app-formato-unico',
  templateUrl: './formato-unico.component.html',
  styleUrls: ['./formato-unico.component.css']
})
export class FormatoUnicoComponent implements OnInit {
  
  myForm: FormGroup; 
  estaciones: any[];
  public firmantes: any[] = [];
  firmanteValue;
  cliente_nombre= '';
  cliente_documento= '';
  cliente_ocupacion= '';
  cliente_conyuge= '';
  cliente_conyuge_ocupacion= '';
  cliente_conyuge_documento= '';
  cliente_domicilio= '';
  cliente_giro= '';
  cliente_representante_legal1_nombre= '';
  cliente_representante_legal1_nro_doc= '';
  cliente_representante_legal1_cargo= '';
  cliente_representante_legal2_nombre= '';
  cliente_representante_legal2_nro_doc= '';
  cliente_representante_legal2_cargo= '';
  formato_articulo= '';
  formato_descripcion= '';
  formato_descripcion2= '';

  @Input() operacion;
  @Input() title;
  @Input() beneficiario: number;
  @Input() beneficiario_tipo;
  @Input() fecha_vencimiento;
  @Input() fecha_contrato;
  @Input() formato_codigo;
  @Input() numero_contrato;
  @Input() formato_plantilla_url;
  @Input() contrato;

  
  constructor(
    private modalService   : NgbModal,
    public activeModal     : NgbActiveModal,
    private formBuilder    : FormBuilder,
    private formatoService : FormatosService,
    private clientesService: ClientesService,
    public toast           : ToastService,
    private legalService	: LegalService,
    ) { }

  ngOnInit(): void {
    this.createForm();
    this.legalService.obtenerFirmantes(this.contrato.id).then((response: any) => {
      this.firmantes = response['results'];
    });
    let operacion = this.operacion.operacion
    let idCliente = operacion.tipo_linea_solicitada == 1 ? this.beneficiario : operacion.beneficiario_factoring
    this.clientesService.obtenerCliente(idCliente).then((cliente: any) => {
      if (this.beneficiario_tipo === 'N') {

        var nombre         = cliente.primer_nombre == undefined ? '' : cliente.primer_nombre;
        var segundo_nombre = cliente.segundo_nombre == undefined ? '' : cliente.segundo_nombre;
        var paterno        = cliente.apellido_paterno == undefined ? '' : cliente.apellido_paterno;
        var materno        = cliente.apellido_materno == undefined ? '' : cliente.apellido_materno;

        this.cliente_nombre            = `${nombre} ${segundo_nombre} ${paterno} ${materno}`;
        this.cliente_documento         = cliente.dni == undefined ? '' : cliente.dni;
        this.cliente_ocupacion         = cliente.ocupacion == undefined ? '' : cliente.ocupacion;
        this.cliente_conyuge           = cliente.nombre_conyuge == undefined ? '' : cliente.nombre_conyuge;
        this.cliente_conyuge_ocupacion = cliente.ocupacion_conyuge == undefined ? '' : cliente.ocupacion_conyuge;
        this.cliente_conyuge_documento = cliente.dni_conyuge == undefined ? '' : cliente.dni_conyuge;
        this.cliente_domicilio         = cliente.domicilio_legal == undefined ? '' : cliente.domicilio_legal;
        
        this.myForm.get("telefono").setValue(cliente.telefono_contacto == undefined ? '' : cliente.telefono_contacto);
        this.myForm.get("email").setValue(cliente.email == undefined ? '' : cliente.email);

        if(this.cliente_conyuge == undefined || this.cliente_conyuge == ''){
          this.formato_articulo     = "El";
          this.formato_descripcion  = "suscrito";
          this.formato_descripcion2 = "manifiesta";
        }
        else{
          this.formato_articulo     = "Los";
          this.formato_descripcion  = "suscritos";
          this.formato_descripcion2 = "manifiestan";
        }


      } else {
        this.cliente_nombre    = `${cliente.nombre}`;
        this.cliente_documento = cliente.ruc == undefined ? '' : cliente.ruc;
        this.cliente_domicilio = cliente.domicilio_legal == undefined ? '' : cliente.domicilio_legal;
        this.cliente_giro      = cliente.giro == undefined ? '' : cliente.giro;
        this.myForm.get("telefono").setValue(cliente.telefono_contacto == undefined ? '' : cliente.telefono_contacto);
        this.myForm.get("email").setValue(cliente.email == undefined ? '' : cliente.email);

        this.clientesService.obtenerContactos(this.beneficiario.toString(), 1, 2).then(
          (response: any) => {
            
            var representante_legales = response.results;

            if(response.count == 0){
              this.formato_articulo = "El";
              this.formato_descripcion = "suscrito";
              this.formato_descripcion2 = "manifiesta";
            }
            else{
              this.formato_articulo = "Los";
              this.formato_descripcion = "suscritos";
              this.formato_descripcion2 = "manifiestan";
            }

            if(response.count > 0){
              this.cliente_representante_legal1_nombre = representante_legales[0].nombre == undefined ? '' : representante_legales[0].nombre;
              this.cliente_representante_legal1_nro_doc = representante_legales[0].dni == undefined ? '' : representante_legales[0].dni;
              this.cliente_representante_legal1_cargo = representante_legales[0].cargo == undefined ? '' : representante_legales[0].cargo;
            }

            if(response.count>1){
              this.cliente_representante_legal2_nombre = representante_legales[1].nombre == undefined ? '' : representante_legales[1].nombre;
              this.cliente_representante_legal2_nro_doc = representante_legales[1].dni == undefined ? '' : representante_legales[1].dni;
              this.cliente_representante_legal2_cargo = representante_legales[1].cargo == undefined ? '' : representante_legales[1].cargo;
            }

          }
        );
      }

    });
  }

  async createForm() {
    const clienteId = (this.operacion.operacion.tipo_linea_solicitada == 1) ? this.operacion.beneficiario : this.operacion.operacion.beneficiario_factoring;

    this.myForm = this.formBuilder.group({
      firmante         : [null, Validators.required],
      emision          : [{ value: new Date(), disabled: true }, Validators.required],
      contrato         : [{ value: this.numero_contrato, disabled: true }, Validators.required],
      vcto_contrato    : [{ value: new Date(this.fecha_vencimiento), disabled: true }, Validators.required],
      glosa            : [{ value: '', disabled: true }],
      nro_documento    : [{ value: '', disabled: true }, Validators.required],
      tipo_persona     : [{ value: '', disabled: true }],
      vcto_firma       : [{ value: null, disabled: true }, Validators.required],
      lugar_firma      : [{ value: '', disabled: true }, Validators.required],
      beneficiario_tipo: [{ value: this.beneficiario_tipo == '' ? 'J' : this.beneficiario_tipo, disabled: true }, Validators.required],
      email            : ['', Validators.required],
      telefono         : ['', Validators.required],
      nombre           : [null, Validators.required],
      dni              : [null, Validators.required],
      domicilio        : [null, Validators.required],
      cargo            : [null, Validators.required],
    });

    const cliente = await this.clientesService.obtenerCliente(clienteId)

    if (this.operacion.operacion.tipo_linea_solicitada == 2) {
      this.beneficiario_tipo = cliente['tipo_persona'] == 1 ? 'N' : 'J'
    }
    this.myForm.controls.tipo_persona.setValue(this.beneficiario_tipo)

    this.myForm.get("firmante").valueChanges.subscribe(selectedValue => {
      this.firmantes.filter(x => x.id == selectedValue)
        .forEach(x => {
          this.myForm.get("nro_documento").setValue(x.nro_documento);
          this.myForm.get("lugar_firma").setValue(x.lugar_firma);
          this.myForm.get("vcto_firma").setValue(new Date(x.vcto_firma));
          this.myForm.get("glosa").setValue(x.glosa);
        });

      let firmante = this.firmantes.filter(x => x.id == selectedValue)[0]
      console.log(firmante)
      console.log(selectedValue)
      this.clientesService.obtenerContacto(firmante.firmante).then((contacto: any)=>{
        this.myForm.controls.nombre.setValue(contacto['nombre'])
        this.myForm.controls.dni.setValue(contacto['dni'])
        this.myForm.controls.domicilio.setValue(contacto['direccion'])
        this.myForm.controls.cargo.setValue(contacto['cargo'])
        })

    });

  }

  onSubmit() {

    if (this.myForm.valid) {
      var fecha = new Date(this.myForm.get("vcto_firma").value);
      var hoy = new Date();

      if (hoy > fecha) {
        this.toast.warning("Firma expirada");
        return;
      }
      var model = {
        "fecha_actual"            : this.myForm.get("emision").value,
        "nro_documento"           : this.myForm.get("nro_documento").value,
        "lugar_firma"             : this.myForm.get("lugar_firma").value,
        "beneficiario_tipo"       : this.myForm.get("beneficiario_tipo").value,
        "archivo_plantilla"       : this.formato_plantilla_url,
        "conyuge_nombre"          : this.cliente_conyuge,
        "conyuge_documento"       : this.cliente_conyuge_documento,
        "conyuge_ocupacion"       : this.cliente_conyuge_ocupacion,
        "cliente_documento"       : this.cliente_documento,
        "cliente_domicilio"       : this.cliente_domicilio,
        "cliente_email"           : this.myForm.get("email").value,
        "cliente_giro"            : this.cliente_giro,
        "cliente_nombre"          : this.cliente_nombre,
        "cliente_ocupacion"       : this.cliente_ocupacion,
        "representante1_cargo"    : this.cliente_representante_legal1_cargo,
        "representante1_nombre"   : this.cliente_representante_legal1_nombre,
        "representante1_documento": this.cliente_representante_legal1_nro_doc,
        "representante2_cargo"    : this.cliente_representante_legal2_cargo,
        "representante2_nombre"   : this.cliente_representante_legal2_nombre,
        "representante2_documento": this.cliente_representante_legal2_nro_doc,
        "cliente_telefono"        : this.myForm.get("telefono").value,
        "formato_articulo"        : this.formato_articulo,
        "formato_codigo"          : this.formato_codigo,
        "formato_descripcion"     : this.formato_descripcion,
        "formato_descripcion2"    : this.formato_descripcion2,
        "firmante_documento"      : '',
        "firmante_domicilio"      : this.myForm.get("domicilio").value,
        "firmante_email"          : this.myForm.get("email").value,
        "firmante_nombre"         : this.myForm.get("nombre").value,
        "firmante_ocupacion"      : this.myForm.get("cargo").value,
        "firmante_telefono"       : this.myForm.get("telefono").value,
        "operacion"       : this.operacion.operacion.id,
      }

      
      this.formatoService.generarFormatoUnico(model).then(
        (x: any) => {
          var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "Formato_Unico.docx";
          link.click();
          this.activeModal.close(model);
        }, (reason) => {
          this.activeModal.close(model);
      });

    }
    else {
      this.toast.warning("El formulario no está completo.");
    }
  }

}
