import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalServiceService } from '../../services/local-service.service';
import { AppService } from "app/app.service";

@Injectable({
  providedIn: 'root'
})
export class DispositivosService {
  private apiUrl: string = "";

  constructor(
    public appService: AppService,
    public localService: LocalServiceService,
    private http: HttpClient
  ) {
    this.apiUrl = `${this.appService.settings.API_base_url}seguridad/proxy/dispositivos/`
  }

  enviarCodigoCorreo() {
    return this.http.post(`${this.apiUrl}enviar-codigo/`, null, {
      headers: new HttpHeaders().set('SAFE_METHOD', '1')
    })
  }

  verificarCodigo(codigo: string) {
    const data = { codigo }
    return this.http.post(`${this.apiUrl}verificar-codigo/`, data, {
      headers: new HttpHeaders().set('SAFE_METHOD', '1')
    })
  }

  esDispositivoConfiable() {
    const session = this.localService.getJsonValue('session')
    return session?.dispositivo?.confiable || !session?.usuario_configuracion?.segundo_factor
  }

}
