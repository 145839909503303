import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from './auth/auth.service';
import { ToastService } from './toast/toast.service';

@Injectable({
	providedIn: 'root'
})
export class ExpedientesService {
	private apiUrl: string = '';
	public ordenes: string = 'factoring/operaciones/ordenes/';
	public ordenesEstados: string = 'factoring/operaciones/ordenes/estados';
	public ordenesTipo: string = 'factoring/operaciones/ordenes/tipos';

	constructor(
		public appService: AppService,
		public toast: ToastService,
		public spinner: NgxSpinnerService,
		public http: HttpClient,
		public auth: AuthService
	) {
		this.apiUrl = this.appService.settings.API_base_url
	}



	/**
	* Habilita el loader para request a la API
	*/
	spinnerOn() {
		this.spinner.show()
	}

	/**
	 * Mostros errores recibidos del servidor
	 * @param error Error enviado del servidor
	 */
	showErrors(error) {
		const err = error.error;

		for (const key in err) {
			if (Object.prototype.hasOwnProperty.call(err, key)) {
				const element = err[key];
				console.log(element);

				if (Array.isArray(element) && element.length) {
					element.forEach(item => {
						this.spinnerOff(item, false);
					});
				} else {
					this.spinnerOff(element, false);
				}

			}
		}
	}

	/**
	* Desabilita el loader
	* @param mensaje Mensaje del toast
	* @param ok Tipo de mensaje, TRUE para success, FALSE para errores
	*/
	spinnerOff(mensaje: string = null, ok: boolean = true) {
		this.spinner.hide()
		this.appService.notifyMe(mensaje, ok)

		if (mensaje && ok)
			this.toast.success(mensaje)
		if (mensaje && !ok)
			this.toast.warning(mensaje)
	}


	obtenerOrdenesDePago(
		page = 1,
		page_size = 10,
		operacion__beneficiario = '',
		tipo = '',
		fecha__gte = '',
		fecha__lte = '',
		estado = '',
		operacion__oficial_negocio = '',
		monto__range = '',
		operacion = '',
		empresa = '',
		cliente__iconstains = '',
		aceptante__iconstains = '',
	) {
		this.spinnerOn()
		const URL = `${this.apiUrl}${this.ordenes}` +
			`?page=${page}` +
			`&page_size=${page_size}` +
			`&operacion__beneficiario=${operacion__beneficiario}` +
			`&tipo=${tipo}` +
			`&estado=${estado}` +
			`&fecha__gte=${fecha__gte}` +
      		`&fecha__lte=${fecha__lte}` +
			`&operacion__monto__range=${monto__range}` +
			`&operacion=${operacion}` +
			`&empresa=${empresa}` +
			`&cliente__iconstains=${cliente__iconstains}` +
			`&aceptante__iconstains=${aceptante__iconstains}` +
			`&operacion__oficial_negocio=${operacion__oficial_negocio}`;


		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				// reject(err);
				this.showErrors(err);
			});
		});
	}

	obtenerTiposDeOrdenDePago(page = 1, page_size = 10) {
		const URL = `${this.apiUrl}${this.ordenesTipo}` +
			`?page=${page}` +
			`&page_size=${page_size}`;

		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}


	modificarOrdenDePago(idOrden, body) {
		this.spinnerOn()
		const URL = `${this.apiUrl}${this.ordenes}${idOrden}/`;

		return new Promise((resolve, reject) => {
			const httpRequest = this.http.put(URL, body);
			httpRequest.subscribe(data => {
				this.spinnerOff('La orden de pago fue cancelada.')
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}


	obtenerEstadoOrden() {
		this.spinnerOn()
		const URL = `${this.apiUrl}${this.ordenesEstados}`;

		return new Promise((resolve, reject) => {
			const httpRequest = this.http.get(URL);
			httpRequest.subscribe(data => {
				this.spinnerOff();
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}
}
