import { Estacion } from './Estacion'

export class Operacion extends Estacion implements IOperacion {

    public id;
    public beneficiario;
    public beneficiario_tipo;
    public monto;
    public fecha;
    public estado;
    public oficial_negocio;
    public contacto;
    public moneda;
    public moneda_descripcion;
    public estacion;
    public responsable;
    public linea_solicitada;
    public banco_abono;
    public banco_abono_nombre;
    public moneda_banco;
    public banco_moneda_descripcion;
    public nro_cuenta;
    public cci;
    public neto_pagar;
    public consideraciones;
    public estacion_devolucion;
    public tipo_cambio_monto;
    public moneda_linea_credito;
    public moneda_linea_credito_descripcion;
    public detalle;
    public documentos;
    public linea_credito;
    public contrato;
    public liquidado;
    public usuario_creacion;
    public puntual;


    public linea_credito_moneda_descripcion;
    public cantidad_documentos;
    public cantidad_documentos_verificados;
    public cantidad_documentos_rechazados;
    public oficial_negocio_nombre;
    public tipo_evaluacion;
    public tipo_evaluacion_descripcion;
    public contacto_nombre;
    public contacto_telefono;
    public contacto_email;
    public comite;
    public comite_nombre;
    public nuevo_contrato;
    public analista_operacion;
    public analista_operacion_nombre;
    public revisado;
    public vuelta_adelanto;
    public beneficiario_;
    public empresa;
    public sin_riesgo;

    constructor(operacion: Operacion = null) {
        super(operacion)
        this.setOperacion(operacion)
        this.empresa = operacion.empresa ? operacion.empresa : null
    }

    setResumen(resumen) {
        this.linea_credito_moneda_descripcion = resumen.linea_credito_moneda_descripcion
        this.cantidad_documentos = resumen.cantidad_documentos
        this.cantidad_documentos_verificados = resumen.cantidad_documentos_verificados
        this.cantidad_documentos_rechazados = resumen.cantidad_documentos_rechazados
        this.oficial_negocio_nombre = resumen.oficial_negocio_nombre
        this.tipo_evaluacion = resumen.tipo_evaluacion
        this.tipo_evaluacion_descripcion = resumen.tipo_evaluacion_descripcion
        this.contacto_nombre = resumen.contacto_nombre
        this.contacto_telefono = resumen.contacto_telefono
        this.contacto_email = resumen.contacto_email
        this.comite = resumen.comite
        this.comite_nombre = resumen.comite_nombre
        this.beneficiario_ = resumen.beneficiario
    }

    setOperacion(operacion: Operacion) {
        this.id = operacion.id;
        this.beneficiario = operacion.beneficiario;
        this.beneficiario_tipo = operacion.beneficiario_tipo;
        this.monto = operacion.monto;
        this.fecha = operacion.fecha;
        this.estado = operacion.estado;
        this.oficial_negocio = operacion.oficial_negocio;
        this.contacto = operacion.contacto;
        this.moneda = operacion.moneda;
        this.moneda_descripcion = operacion.moneda_descripcion;
        this.estacion = operacion.estacion;
        this.responsable = operacion.responsable;
        this.linea_solicitada = operacion.linea_solicitada;
        this.banco_abono = operacion.banco_abono;
        this.banco_abono_nombre = operacion.banco_abono_nombre;
        this.moneda_banco = operacion.moneda_banco;
        this.banco_moneda_descripcion = operacion.banco_moneda_descripcion;
        this.nro_cuenta = operacion.nro_cuenta;
        this.cci = operacion.cci;
        this.neto_pagar = operacion.neto_pagar;
        this.consideraciones = operacion.consideraciones;
        this.estacion_devolucion = operacion.estacion_devolucion;
        this.tipo_cambio_monto = operacion.tipo_cambio_monto;
        this.moneda_linea_credito = operacion.moneda_linea_credito;
        this.moneda_linea_credito_descripcion = operacion.moneda_linea_credito_descripcion;
        this.detalle = operacion.detalle;
        this.documentos = operacion.documentos;
        this.linea_credito = operacion.linea_credito;
        this.nuevo_contrato = operacion.nuevo_contrato;
        this.contrato = operacion.contrato;
        this.analista_operacion = operacion.analista_operacion;
        this.analista_operacion_nombre = operacion.analista_operacion_nombre;
        this.liquidado = operacion.liquidado;
        this.puntual = operacion.puntual;
        this.sin_riesgo = operacion.sin_riesgo;
    }

    get isDenegado() {
        return this.estado === 9
    }

    get isLiquidado(){
        return this.estado === 4
    }

    get isCancelado() {
        return this.estado === 11
    }

    isResponsable(user) {
        if (this.operacion.responsable === user.id) {
            return true
        } else {
            return false
        }
    }

    isResponsableMiembroEquipo(equipo){
        let pertenece = equipo.find(({ id })=> id == this.operacion.responsable)
        return !!pertenece
    }

    isOficial(user) {
        if (this.operacion.oficial_negocio === user.id) {
            return true
        } else {
            return false
        }
    }

    isAnalistaOperacion(user) {
        if (this.operacion.analista_operacion === user.id) {
            return true
        } else {
            return false
        }
    }

    isAsistenteCobranzas(user) {
        if (this.operacion.asistente_cobranzas === user.id || this.operacion.responsable == user.id) {
            return true
        } else {
            return false
        }
    }
}

export interface IOperacion {
    id?: number;
    beneficiario?: number;
    monto?: number;
    fecha?: string;
    estado?: number;
    oficial_negocio?: number;
    contacto?: number;
    moneda?: number;
    moneda_descripcion?: number;
    estacion?: number;
    responsable?: number;
    linea_solicitada?: number;
    banco_abono?: number;
    banco_abono_nombre?: string;
    moneda_banco?: number;
    banco_moneda_descripcion?: string;
    nro_cuenta?: number;
    cci?: number;
    neto_pagar?: string;
    consideraciones?: string;
    estacion_devolucion?: number;
    tipo_cambio_monto?: string;
    moneda_linea_credito?: number;
    moneda_linea_credito_descripcion?: string;
    detalle?: any[];
    documentos?: any[];
    liquidado?: boolean[];
    usuario_creacion: string;
}