import { Component, OnInit } from '@angular/core';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { ManageComiteModalComponent } from '../modals/manage-comite-modal/manage-comite-modal.component';
import { functions } from '../../../core/helpers/functions';

const fakeData = [
  {
    id: 1,
    minimo_tasa: 20000,
    moneda: 1,
    aceptante_recurrente: 1,
    aceptante_recurrente_nombre: 'Maria Zelada',
    modificacion_concepto       : 'Fomula'
  },
  {
    id: 2,
    minimo_tasa: 20000,
    moneda: 1,
    aceptante_recurrente: 1,
    aceptante_recurrente_nombre: 'Maria Zelada',
    modificacion_concepto       : 'Fomula'
  },
  {
    id: 3,
    minimo_tasa: 20000,
    moneda: 1,
    aceptante_recurrente: 1,
    aceptante_recurrente_nombre: 'Maria Zelada',
    modificacion_concepto       : 'Fomula'
  },
  {
    id: 4,
    minimo_tasa: 20000,
    moneda: 1,
    aceptante_recurrente: 1,
    aceptante_recurrente_nombre: 'Maria Zelada',
    modificacion_concepto       : 'Fomula'
  }
];

@Component({
  selector: 'app-config-comite',
  templateUrl: './config-comite.component.html',
  styleUrls: ['./config-comite.component.css']
})
export class ConfigComiteComponent implements OnInit {
  faSyncAlt  = faSyncAlt;
  faChartPie = faChartPie;
  faPlus     = faPlus;
  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public user             : any;
	public identidad        : any     = {};
	public searchstring     : string = '';
	public canCreate: boolean = false;

  constructor(
    private configurationService  : ConfigurationService,
    public modalService           : NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerComites();
  }

  loadTable(data) {
    console.log(data);
    this.rowData = functions.indextable( data )
    // this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }
    
  initVars() {
    let columns = [
      {
        headerName: 'N°', 
        field     : 'index' ,
        sortable  : true,
      },
      {
        headerName: 'Nombre', 
        field     : 'nombre',
        class     : 'text-center',
        sortable  : true,
      },
      {
        headerName: 'Monto Inicial', 
        field     : 'monto_inicial',
        class     : 'text-center',
        sortable  : true,
        pipe      : 'currency',
        moneda    : 'moneda'
      },
      {
        headerName: 'Prórroga Inicial', 
        field     : 'prorroga_inicial',
        class     : 'text-center',
        sortable  : true,
        pipe      : 'currency',
        moneda    : 'moneda'
      },
    ];
  
    this.columnDefs = columns;
  }

  obtenerComites(page = 1, page_size= 10) {
    
    let comites = this.configurationService.obtenerComites(page, page_size);

    Promise.all([comites]).then(res => {
      let comites = res[0];
      this.initVars();
      this.loadTable(comites);
    }).catch(err => {
      console.error(err);
      
    })

  }

  crear() {
    const modalRef = this.modalService.open(ManageComiteModalComponent, {
      size: 'lg'
    });

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerComites();
		},
		error => {

		});
  }

  tableEditEvent(row) {
    const modalRef = this.modalService.open(ManageComiteModalComponent, {
      size: 'lg'
    });

    modalRef.componentInstance.comiteId = row.id;

    modalRef.componentInstance

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			this.obtenerComites();
		},
		error => {

		});
  }

  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Comité';
		modalRef.componentInstance.message = '¿Seguro que desea remover este Comité?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.configurationService.elimiarComite(row.id)
			.then(result => {
			this.obtenerComites();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  goToPage({page,per_page,filtros}) {
    this.obtenerComites(page, per_page);
  }

}
