import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';

@Component({
  selector: 'app-riesgos-modal',
  templateUrl: './riesgos-modal.component.html',
  styleUrls: ['./riesgos-modal.component.css']
})
export class RiesgosModalComponent implements OnInit {
  public formRiesgos     : FormGroup;
  public empRelacionadas     : any[] = [];
	public columnDefs         : any[];
  public dummySelect         : any[];
  faTimes = faTimes;
  constructor(
    private formbuild       : FormBuilder,
    private authService     : AuthService,
    public modalService             : NgbModal,
  ) {
    this.initForm()
   }

   checkboxChange(event){

   }

  ngOnInit(): void {
    console.log('Iinicio componente')
  }

  initForm(){
    this.formRiesgos = this.formbuild.group({
      ruc        : ["", []],
      cliente    : ["", []],
      comentarios: ["", []],
      check      : [true, []],
      select     : [true, []],
    });

    this.dummySelect = [
      {
        descripcion : 'Empresa 1',
        id : 1,
      },
      {
        descripcion : 'Empresa 2',
        id : 2,
      },
      {
        descripcion : 'Empresa 3',
        id : 3,
      },      
      {
        descripcion : 'Empresa 4',
        id : 4,
      },      
      {
        descripcion : 'Empresa 5',
        id : 5,
      },      
    ]
    this.empRelacionadas = [
      {
        razon_social : 'Empresa 1',
        ruc : '123456789',
        comportamiento : 'Bueno',
        exposicion : '0.00123',
      },
      {
        razon_social : 'Empresa 3',
        ruc : '123456789',
        comportamiento : 'Bueno',
        exposicion : '0.00123',
      },
      {
        razon_social : 'Empresa 2',
        ruc : '123456789',
        comportamiento : 'Bueno',
        exposicion : '0.00123',
      }      
    ]

		this.columnDefs = [
			{
				headerName: 'N°', 
				pipe:'indexcol',
				sortable: true
			},
			{
				headerName: 'Razón Social', 
				field: 'razon_social',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName: 'RUC', 
				field: 'ruc',
				sortable: true,
				defaultInfo: 'Aún no aprobado',
				class: 'text-center'
      },
			{
				headerName: 'Comportamiento', 
				field: 'comportamiento',
				sortable: true,
				class: 'text-center'
      },      
			{
				headerName: 'Exposición', 
				field: 'exposicion',
				sortable: true,
				class: 'text-center'
			},            
		];    
  }
}
