import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../../../../core/services/auth/auth.service';
import { FactoringService } from '../../../../core/services/factoring/factoring.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { concat, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map, filter } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { rejects } from 'assert';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { WizardComponent } from 'angular-archwizard';
import { ToastService } from 'app/core/services/toast/toast.service';
import { FormDocSolicitudFactoringComponent } from 'app/shared/form-doc-solicitud-factoring/form-doc-solicitud-factoring.component';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';

declare var $: any;

interface FileReaderEventTarget extends EventTarget {
	result: string
}
interface FileReaderEvent extends Event {
	target: FileReaderEventTarget;
	getMessage(): string;
}

@Component({
	selector: 'app-registrar-solicitud-factoring',
	templateUrl: './registrar-solicitud-factoring.component.html',
	styleUrls: ['./registrar-solicitud-factoring.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class RegistrarSolicitudFactoringComponent {
	faTimes=faTimes;
	@ViewChild(FormDocSolicitudFactoringComponent) formDocs: FormDocSolicitudFactoringComponent;
	faExclamationTriangle = faExclamationTriangle;

	user: any;
	listaContactos: any[] = [];
	listaBancos: any[] = [];
	listaMonedas: any[] = [];
	operacionId: any = 0;
	BUformularioSolicitantes: string = '';
	BUformularioDetalle: string = '';
	readOnly: boolean = false;
	readOnlyLineaCredito: boolean = false;
	readOnlyFactoring: boolean = false;
	formularioSolicitantes: FormGroup;
	formularioDetalle     : FormGroup;
	formularioChecklist   : FormGroup;
	idOperacion           : number = null;
	operacionDetalles     : any[] = [];
	operacion		      : any;
	beneficiario          : any = {
		"id"             : null,
		"ruc"            : "",
		"nombre"         : "",
		"domicilio"      : "",
		"distrito"       : "",
		"provincia"      : "",
		"departamento"   : "",
		"cliente_leasing": false
	};
	lineas: any[];
	lineasBeneficiario: any[];
	cuentasAbonos: any[];

	rucResults$: Observable<any[]>;
	searchText$ = new Subject<string>();
	ruc: any;
	loadingSearch: boolean = false;
	caracteresMinimos: number = 4;
	isClient: boolean = false;
	botonReanOnlyDerivar = true;
	botonReanOnlyReenviar = false;
	operationData: any = {
		detalle: []
	};
	tipoLineaSolicitada: number;
	clienteFactoring: any;
	empresas: any;
	opSinLineaCampoToggle: boolean = false;

	@ViewChild('alertDetalleVacio', { static: false }) public alertDetalleVacio;
	@ViewChild('wizardNavbar') public wizardNavbar;

	constructor(
		private _activatedRoute : ActivatedRoute,
		private _formBuilder    : FormBuilder,
		private authService     : AuthService,
		private factoringService: FactoringService,
		private configs         : DocschecklistService,
		public modalService     : NgbModal,
		public spinner          : NgxSpinnerService,
		private router          : Router,
		private factoring       : FactoringService,
		public toast            : ToastService,
	) {
		this.user = this.authService.user;
		this.initForms()

		if (this.user.perfil === 2) {
			this.isClient = true
		}

	}

	cargarValidacionDeRutaId(){
		let obtenerIdOperacion = new Promise((resolve, reject) => {
			this._activatedRoute.params.subscribe((param) => {
				if (param.id) {
					this.isClient = true
					this.operationData.id = param.id
					resolve(param.id)
				} else {
					if (this.isClient) {
						return this.cargarDatosDeFormularios(this.user.cliente)
					}
					reject()
				}
			})
		});

		obtenerIdOperacion.then((res: any) => {
			return this.cargarDatosDeProceso(res);
		}).then((response) => {
			return this.cargarDatosDeFormularios(response.beneficiario);
		}).catch((error) => {
		});

		this.factoring.obtenerEmpresas().then((res) => {

			this.empresas = res['results'];
			
		});

	}

	ngOnInit() {
		this.loadSeach();
		this.obtenerTiposDeLinea();
		this.cargarValidacionDeRutaId();
	}

	trackByFn(item: any) {
		return item.id;
	}

	/**
	 * Este evento se suscribe a los cambios en el objeto que rechttp://13.58.248.156/ibe la data desde el servidor
	 */
	loadSeach() {
		/**
		 * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
		 * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
		 * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value.
		 * 		So that API will not hit if the current and previous value is the same
		 * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
		 * 		string typed by user
		 */
		this.rucResults$ = concat(
			of([]), // Items predeterminados
			this.searchText$.pipe(
				filter(res => {
					return res !== null && res.length >= this.caracteresMinimos
				}),
				distinctUntilChanged(),
				debounceTime(800),
				tap(() => this.loadingSearch = true),
				switchMap(term => {
					return this.factoringService.buscarBeneficiariosObserver(term).pipe(
						catchError(() => of([])), // empty list on error
						tap(() => this.loadingSearch = false)
					)
				})
			)
		);
	}


	initForms() {
		this.formularioSolicitantes = this._formBuilder.group({
			id             : ['',],
			ruc            : ['',],
			nombre         : ['',],
			domicilio      : ['',],
			distrito       : ['',],
			provincia      : ['',],
			departamento   : ['',],
			contactos      : ['', Validators.required],
			cliente_leasing: ['',],
			puntual        : [false,],
			telefono       : ['',],
			email          : ['',],
			ruc_search     : ['',],
			empresa        : [ { value:2, disabled:true } ,]
		});

		this.formularioSolicitantes.controls.ruc_search.valueChanges.subscribe((value) => {
			if (!this.isClient && value) {
				this.cargarDatosDeFormularios(value)
			}
		})

		this.formularioSolicitantes.controls.empresa.valueChanges.subscribe((value) => {

			if (this.beneficiario.lineas_de_credito) {
				this.validarContratos()
				this.cambiarLineaBeneficiario()
			}
		})

		this.formularioDetalle = this._formBuilder.group({
			beneficiario         : [null,],
			monto                : [null,],
			fecha                : [null,],
			estado               : [null,],
			oficial_negocio      : [null,],
			moneda               : [null, Validators.required],
			estacion             : [null,],
			responsable          : [null,],
			linea_solicitada     : [null, Validators.required],
			monedaLinea          : [null,],
			banco_abono          : [null, Validators.required],
			nro_cuenta           : [null,],
			cci                  : [null,],
			total_operacion      : [null,],
			consideraciones      : [null,],
			linea_credito        : [null,],
			limitedecredito      : [null,],
			tipo_linea_solicitada: [null, Validators.required],
			consumido            : [null,],
			disponible           : [null,],
			fecha_vencimiento    : [null,],
			cuenta_abono         : [null,],
			ruc_search           : ['',],
		});

		this.formularioChecklist = this._formBuilder.group({
			id: ['',],
		});

		this.formularioDetalle.controls.tipo_linea_solicitada.valueChanges.subscribe((value)=>{
			if(value){
				this.operationData.tipo_linea_solicitada = value
			}
		})

		this.formularioDetalle.controls.ruc_search.valueChanges.subscribe((value) => {
			// Search del cliente en factoring
			if (value) {
				this.clienteFactoring = value
				this.cargarLineaFactoring(value.id)
			}
		})
	}

	cargarDatosDeFormularios(beneficiarioId){
		let monedas       = this.configs.obtenerMonedas()
		let bancos        = this.configs.obtenerBancos()
		let contactos     = this.factoringService.obtenerContactos(beneficiarioId)
		let beneficiarios = this.factoringService.getBeneficiarios(beneficiarioId)
		this.spinner.show()
		return new Promise((resolve, reject) => {
			Promise.all([
				monedas,
				bancos,
				contactos,
				beneficiarios
			]).then((res: any[]) => {
				this.listaMonedas = res[0]['results'];
				this.listaBancos = res[1]['results'];
				this.listaContactos = res[2]['results'];
				this.beneficiario = res[3];

				this.mostrarVencimientosDeLineas(this.beneficiario.lineas_de_credito.filter( linea => !linea.puntual))


				this.datosBeneficiario(res[3]);
				this.spinner.hide();
				this.datosContacto();

				this.obtenerCuentas(this.beneficiario.id);

				// Carga datos de linea en solicitudes creadas (descuento)
				if (this.tipoLineaSolicitada == 1) {
					this.cambiarLineaBeneficiario()
				}else if (this.tipoLineaSolicitada == 2){			
					
					console.log("ESTO ES  UN FACTORING", this.tipoLineaSolicitada)
					console.log("Cliente Beneficiario", this.beneficiario)	
					console.log("Cliente factoring id", this.operacion.beneficiario_factoring)		

					// Asignar Cliente Factoring desde el CRM que creo con Beneficiario
					if (this.beneficiario && this.clienteFactoring == null) {

						// Buscar una solución para traer a todo el objeto de 'beneficiario' pero con los datos del cliente factoring
						// y así no asignar al beneficiario que crea desde un inicio
						this.clienteFactoring = this.beneficiario 
						this.cargarLineaFactoring(this.operacion.beneficiario_factoring)
						
						console.log("Cliente Factoring asignado", this.clienteFactoring)
						
						// Inicializar datos de beneficiario para completarlo con el proveedor en factoring
						this.listaContactos = [];
						this.ruc = false;
						this.loadingSearch = false;
						this.caracteresMinimos = 4;
						this.isClient = false;

						this.formularioSolicitantes = this._formBuilder.group({
							id             : ['',],
							ruc            : ['',],
							nombre         : ['',],
							domicilio      : ['',],
							distrito       : ['',],
							provincia      : ['',],
							departamento   : ['',],
							contactos      : ['', Validators.required],
							cliente_leasing: ['',],
							puntual        : [false,],
							telefono       : ['',],
							email          : ['',],
							ruc_search     : ['',],
							empresa        : [ { value:2, disabled:true } ,]
						});
				
						this.formularioSolicitantes.controls.ruc_search.valueChanges.subscribe((value) => {
							if (!this.isClient && value) {
								this.cargarDatosDeFormularios(value)
							}
						})
						this.beneficiario = {
							"id"             : null,
							"ruc"            : "",
							"nombre"         : "",
							"domicilio"      : "",
							"distrito"       : "",
							"provincia"      : "",
							"departamento"   : "",
							"cliente_leasing": false
						};
					}


					
				}

				resolve(res[3]);
			}).catch((err) => {
				this.listaContactos = [];
				console.error('Algo esta fallando')
				console.error(err)
				// reject()
			})
		})
	}

	cargarDatosDeProceso(id){
		let Operacion = this.factoringService.obtenerOperacion(id)
		let OperacionBandeja = this.factoringService.obtenerOperacionBandejaUnico(id)
		return Promise.all([Operacion, OperacionBandeja]).then((res: any)=>{
			const response: any = res[0];
			if (this.user.perfil == this.authService.perfil.perfilClienteID && response.estacion != 1) {
				this.readOnly = true
			}
			if (this.user.perfil == this.authService.perfil.perfilConsultaID) this.readOnly = true

			if (res[0].estacion_devolucion) {
				this.botonReanOnlyDerivar = false;
				this.botonReanOnlyReenviar = true;
			}
			if (res[0].contacto == null && this.operationData.contacto != null) {
				res[0].contacto = this.operationData.contacto
				res[1].contacto = this.operationData.contacto
				this.operacion.contacto = this.operationData.contacto
			}


			this.operationData.detalle = res[0].detalle;
			this.operacion = res[0];
			this.setFormResponse(response)

			this.tipoLineaSolicitada = this.operacion.tipo_linea_solicitada
			if (this.tipoLineaSolicitada) this.readOnlyLineaCredito = true; // Si ya tiene este campo no se puede cambiar de tipo de producto (Ya tomo los documentos del checklist)

			// Si tiene detalles no permitir cambiar de tipo de linea
			if (this.operationData.detalle.length > 0) {
				// Si tiene uno o mas detalles y es de factoring, carga el cliente de la primera factura
				if (this.tipoLineaSolicitada == 2) {
					this.readOnlyFactoring
					if (!this.clienteFactoring) {
						this.factoringService.getDeudores(this.operationData.detalle[0].deudor).then(res => {
							this.factoringService.buscarBeneficiarios(res['ruc']).then(res2 => {
								this.clienteFactoring = res2['results'][0]
								this.cargarLineaFactoring(this.clienteFactoring.id)
							})
						})
					}
				}
			} else {
				// Ya no deberia pasar por validacion en backend
				if ((this.operacion.tipo_linea_solicitada == 2 || this.formularioDetalle.controls['tipo_linea_solicitada'].value == 2)) {
					this.resetearDetalle()
				}
			}


			this.datosDetalle(response);
			return res[0]
		})
	}

	setFormResponse({ contacto }){
		console.log( contacto )
		let { operacion } = this
		this.formularioSolicitantes.get('contactos').setValue(contacto);
		this.formularioSolicitantes.get('empresa').setValue(operacion?.empresa)
		this.formularioSolicitantes.get('puntual').setValue(operacion?.puntual)
	}

	setFormularioDetallesPuntual(){
		let { operacion } = this
		this.formularioDetalle.get('monedaLinea').setValue(operacion?.moneda_linea_credito)
		this.formularioDetalle.get('moneda').setValue(operacion?.moneda)
		this.formularioDetalle.get('banco_abono').setValue(operacion?.banco_abono)
		this.formularioDetalle.get('nro_cuenta').setValue(operacion?.nro_cuenta)
		this.formularioDetalle.get('cci').setValue(operacion?.cci)
		this.formularioDetalle.get('cuenta_abono').setValue(operacion?.cuenta_abono)
		this.formularioDetalle.get('linea_credito').setValue(operacion?.linea_credito)
		this.formularioDetalle.get('tipo_linea_solicitada').setValue(operacion?.tipo_linea_solicitada)
	}

	datosBeneficiario(data: any) {
		this.formularioSolicitantes.get('id').setValue(data.id)
		this.formularioSolicitantes.get('ruc').setValue(data.ruc)
		this.formularioSolicitantes.get('nombre').setValue(data.nombre)
		this.formularioSolicitantes.get('domicilio').setValue(data.domicilio)
		this.formularioSolicitantes.get('distrito').setValue(data.distrito)
		this.formularioSolicitantes.get('provincia').setValue(data.provincia)
		this.formularioSolicitantes.get('departamento').setValue(data.departamento)
		this.formularioSolicitantes.get('cliente_leasing').setValue(data.cliente_leasing)
	}

	datosContacto() {
		const index = this.formularioSolicitantes.get('contactos').value;
		if (!index) {
			return
		}
		let contacto = this.listaContactos.find(x => x.id == index);
		this.formularioSolicitantes.get('telefono').setValue(contacto.telefono);
		this.formularioSolicitantes.get('email').setValue(contacto.email);
	}

	guardarSolicitante() {
		this.operationData.beneficiario = this.formularioSolicitantes.get('id').value
		this.operationData.contacto     = this.formularioSolicitantes.get('contactos').value
		this.operationData.empresa      = this.formularioSolicitantes.get('empresa').value
		this.operationData.puntual      = this.formularioSolicitantes.get('puntual').value

		if(this.formularioSolicitantes.controls['puntual'].value){
			this.formularioDetalle.get('tipo_linea_solicitada').setValue(1)
			this.formularioDetalle.controls['tipo_linea_solicitada'].disable()

			// this.formularioDetalle.controls['monedaLinea'].setValue(1)
			// this.formularioDetalle.controls['monedaLinea'].disable()

			this.formularioDetalle.controls['linea_solicitada'].setValue(0)
			this.formularioDetalle.controls['linea_solicitada'].disable()
		} else {
			this.formularioDetalle.controls['tipo_linea_solicitada'].enable()
			this.formularioDetalle.controls['monedaLinea'].enable()
			this.formularioDetalle.controls['linea_solicitada'].enable()
		}
	}

	datosDetalle(data: any) {
		this.idOperacion = data.id;

		this.formularioDetalle.controls['cuenta_abono'].setValue(data.cuenta_abono);
		this.formularioDetalle.controls['monto'].setValue(data.monto);
		this.formularioDetalle.controls['moneda'].setValue(data.moneda);
		this.formularioDetalle.controls['monedaLinea'].setValue(data.moneda_linea_credito);
		this.formularioDetalle.controls['linea_solicitada'].setValue(data.linea_solicitada);
		this.formularioDetalle.controls['banco_abono'].setValue(data.banco_abono);
		this.formularioDetalle.controls['nro_cuenta'].setValue(data.nro_cuenta);
		this.formularioDetalle.controls['cci'].setValue(data.cci);
		this.formularioDetalle.controls['total_operacion'].setValue(data.total_operacion);
		this.formularioDetalle.controls['consideraciones'].setValue(data.consideraciones);
		this.formularioDetalle.controls['tipo_linea_solicitada'].setValue(data.tipo_linea_solicitada);
	}

	mapperData() {
		const formData: any = this.formularioDetalle.value;
		this.operationData.linea_solicitada 		=  formData.linea_solicitada;
		this.operationData.moneda_linea_credito 	=  formData.monedaLinea;
		this.operationData.moneda           		=  formData.moneda;
		this.operationData.moneda_banco         	=  formData.moneda;
		this.operationData.banco_abono      		=  formData.banco_abono;
		this.operationData.nro_cuenta       		=  formData.nro_cuenta;
		this.operationData.cci              		=  formData.cci;
		this.operationData.cuenta_abono         	=  formData.cuenta_abono;
		this.operationData.linea_credito         	=  formData.linea_credito;
		this.operationData.tipo_linea_solicitada 	=  formData.tipo_linea_solicitada;
		this.operationData.contacto 				=  this.formularioSolicitantes.get('contactos').value;
	}

	guardarDetalle() {
		const formData                           : any = this.formularioDetalle.value;
		this.operationData.linea_solicitada      = this.formularioDetalle.get('linea_solicitada').value;
		this.operationData.moneda_linea_credito  = this.formularioDetalle.get('monedaLinea').value;
		this.operationData.moneda                = this.formularioDetalle.get('moneda').value;
		this.operationData.moneda_banco          = this.formularioDetalle.get('moneda').value;
		this.operationData.banco_abono           = this.formularioDetalle.get('banco_abono').value;
		this.operationData.nro_cuenta            = this.formularioDetalle.get('nro_cuenta').value;
		this.operationData.cci                   = this.formularioDetalle.get('cci').value;
		this.operationData.cuenta_abono          = this.formularioDetalle.get('cuenta_abono').value;
		this.operationData.linea_credito         = this.formularioDetalle.get('linea_credito').value;
		this.operationData.tipo_linea_solicitada = this.formularioDetalle.get('tipo_linea_solicitada').value;
		this.operationData.contacto              = this.formularioSolicitantes.get('contactos').value;
		this.operationData.empresa               = this.formularioSolicitantes.get('empresa').value;
		this.operationData.puntual               = this.formularioSolicitantes.get('puntual').value;

		const data = {
			beneficiario         : this.operationData.beneficiario,
			linea_solicitada     : formData.linea_solicitada,
			moneda_linea_credito : formData.monedaLinea,
			moneda               : formData.moneda,
			moneda_banco         : formData.moneda,
			banco_abono          : formData.banco_abono,
			nro_cuenta           : formData.nro_cuenta,
			cci                  : formData.cci,
			cuenta_abono         : formData.cuenta_abono,
			linea_credito        : formData.linea_credito,
			tipo_linea_solicitada: formData.tipo_linea_solicitada,
			contacto             : this.operationData.contacto,
			empresa              : this.operationData.empresa,
			puntual              : this.operationData.puntual ? this.operationData.puntual : false
		};

		let request;
		console.log(data)
		if (this.operationData.id) {
			request = this.factoringService.guardarOperacion(data, this.operationData.id);
		} else {
			request = this.factoringService.guardarOperacion(data);
		}

		request.then(data => {
			this.operationData.id = data.id
			const response: any = data;
			console.log(this.operationData);
			this.datosDetalle(response);

			this.factoringService.validarCuentaAbono(this.operationData.cuenta_abono).subscribe(
				() => {},
				res => console.log(res)
			)
			this.factoringService.validarCuentaAbono(this.operationData.cuenta_abono, true).subscribe(
				() => {},
				res => console.log(res)
			)
		},
			error => {
				this.displayErrors(error)
			});
	}

	displayErrors(err) {
		if (err && err.error) {
			for (let prop in err.error) {
				this.toast.warning(err.error[prop])
			}
		}
	}

	async detallesEvent(detalles: any) {
		// if(!this.operationData.detalle){
		// 	this.operationData.detalle = []
		// }

		// Si el parametro {del} viene en TRUE es un evento de eliminacion
		if (detalles.del) {
			// let index = this.operationData.detalle.findIndex((det)=>{
			// 	detalles.id ==det.id
			// })
			// console.log(detalles)
			// this.operationData.detalle.splice(index,1);

			this.factoringService.eliminarDetalle(detalles.id)
				.then(res => this.cargarDatosDeProceso(this.operationData.id))
				.catch(err => this.displayErrors(err));
			return
		}

		// Si es una operacion de CREACIÓN O EDICION se valida en la siguiente sentencia
		// En cualquier caso se agrega el ID del proceso al objeto
		// detalles.operacion = this.operacionId
		if (detalles.id) {
			detalles['operacion'] = this.operationData.id;
			this.factoringService.guardarDetalle(detalles, detalles.id).then(res => {
				this.cargarDatosDeProceso(this.operationData.id);
			})
				.catch(err => this.displayErrors(err));
		}
		// Si es un evento de EDITAR, envía el ID del detalle al servicio de factoring
		// request = this.factoringService.guardarDetalle(detalles,detalles.id)
		else {
			// Si es un evento de CREACIÓN no envia el ID para ser enviado vía POST
			// detalles.id = new Date().valueOf()
			// this.operationData.detalle.push(detalles)

			if (this.formularioDetalle.valid) {

				this.mapperData();
				const dataForm = {
					beneficiario			: this.operationData.beneficiario,
					linea_solicitada 		:  this.operationData.linea_solicitada,
					moneda_linea_credito 	:	this.formularioDetalle.get('monedaLinea').value,
					moneda           		:  this.operationData.moneda,
					moneda_banco         	:  this.operationData.moneda,
					banco_abono      		:  this.operationData.banco_abono,
					nro_cuenta       		:  this.operationData.nro_cuenta,
					cci              		:  this.operationData.cci,
					cuenta_abono         	:  this.operationData.cuenta_abono,
					linea_credito			:  this.operationData.linea_credito,
					tipo_linea_solicitada 	:  this.formularioDetalle.get('tipo_linea_solicitada').value,
					empresa					:  this.operationData.empresa,
					puntual					:  this.operationData.puntual ? this.operationData.puntual : false,
				};

				if (!this.operationData.id) {
					dataForm['oficial_negocio'] = this.user.isOficialDeNegocio || this.user.isGerenteGeneral || this.user.isGerenteComercial ? this.user.id : null

					if (this.operationData.tipo_linea_solicitada == 2) {
						dataForm['beneficiario_factoring'] = this.clienteFactoring.id
						dataForm['contacto'] = this.operationData.contacto // En realidad no deberia tomarlo
					} else {
						dataForm['contacto'] = this.operationData.contacto
					}
				}


				this.factoringService.guardarOperacion(dataForm, this.operationData.id)
					.then(res => {
						this.operacion        = res
						this.operationData.id = res['id']
						detalles['operacion'] = this.operationData.id;
						this.factoringService.guardarDetalle(detalles).then(res => {
							this.cargarDatosDeProceso(this.operationData.id)
						});
						// return;
					})
					.catch(err => this.displayErrors(err));


			} else {
				this.toast.warning('Por favor llene los detalles solicitados!');
				return;
			}
		}

	}

	derivar() {
		this.factoringService.derivarOperacion(this.idOperacion).then((res) => {
			this.router.navigate(['/operaciones'])
		}).catch((err) => {
			console.log(err)
		});
	}

	reenviar() {
		this.factoringService.reenviarOperacion(this.operacion.id, { estacion: this.operacion.estacion_devolucion })
			.then(result => {
				this.router.navigate(['/operaciones'])
			})
			.catch(error => {
				console.error(error);
			});
	}

	validacionMoneda() {
		this.factoringService.validaUbigeoDetalle(this.operationData.id).then((res: any) => {
			if (!res.estado) {
				this.toast.warning(res.mensaje)
				return
			}

			let detalles = this.operationData.detalle.find(item => item.moneda != this.formularioDetalle.get('moneda').value);
			if (!detalles) {
				document.getElementById('button-siguiente-step2').click()
			} else {
				this.toast.warning('La moneda de banco de abono debe ser la misma que la moneda de los documentos.')
			}
		})
	}

	async importXML(event: File[]) {

		let data = event;

		if (this.formularioDetalle.valid) {
			this.mapperData();

			const dataForm = {
				beneficiario         : this.operationData.beneficiario,
				linea_solicitada     : this.operationData.linea_solicitada,
				moneda_linea_credito : this.formularioDetalle.get('monedaLinea').value,
				moneda               : this.operationData.moneda,
				moneda_banco         : this.operationData.moneda,
				banco_abono          : this.operationData.banco_abono,
				nro_cuenta           : this.operationData.nro_cuenta,
				cci                  : this.operationData.cci,
				cuenta_abono         : this.operationData.cuenta_abono,
				linea_credito        : this.operationData.linea_credito,
				tipo_linea_solicitada: this.operationData.tipo_linea_solicitada,
				puntual: this.operationData.puntual ? this.operationData.puntual : false
			};

			if (!this.operationData.id) {
				dataForm['oficial_negocio'] = this.user.isOficialDeNegocio || this.user.isGerenteGeneral || this.user.isGerenteComercial ? this.user.id : null

				if (this.operationData.tipo_linea_solicitada == 2) {
					dataForm['beneficiario_factoring'] = this.clienteFactoring.id
					dataForm['contacto'] = this.operationData.contacto // En realidad no deberia tomarlo
				} else {
					dataForm['contacto'] = this.operationData.contacto
				}
			}

			this.factoringService.guardarOperacion(dataForm, this.operationData.id)
				.then(async res => {
					this.operacion = res
					this.operationData.id = res['id']
					// data.append('operacion', this.operationData.id);
					// this.subirXml(data);
					let data = [];
					for (let index = 0; index < event.length; index++) {
						let form = new FormData();
						form.append('operacion', this.operationData.id);
						form.append("adjunto", event[index]);
						data.push(form);
					}
					await this.subirXml(data);
					// this.refreshTable();
				});
		} else {
			this.toast.warning('Por favor llene los detalles solicitados!');
			return;
		}
	}

	crearOperacionXML(file: FormData) {
		const formData                           : any = this.formularioDetalle.value;
		this.operationData.linea_solicitada      = formData.linea_solicitada;
		this.operationData.moneda_linea_credito  = formData.monedaLinea;
		this.operationData.moneda                = formData.moneda;
		this.operationData.moneda_banco          = formData.moneda;
		this.operationData.banco_abono           = formData.banco_abono;
		this.operationData.nro_cuenta            = formData.nro_cuenta;
		this.operationData.cci                   = formData.cci;
		this.operationData.cuenta_abono          = formData.cuenta_abono;
		this.operationData.linea_credito         = formData.linea_credito;
		this.operationData.tipo_linea_solicitada = formData.tipo_linea_solicitada;
		this.operationData.puntual               = formData.puntual;

		// this.operationData['detalle'] = this.mapperDetalleOperacion(this.operationData['detalle']);

		const dataForm = {
			beneficiario: this.operationData.beneficiario,
			linea_solicitada: this.operationData.linea_solicitada,
			moneda_linea_credito: this.operationData.moneda_linea_credito,
			moneda: this.operationData.moneda,
			moneda_banco: this.operationData.moneda,
			banco_abono: this.operationData.banco_abono,
			nro_cuenta: this.operationData.nro_cuenta,
			cci: this.operationData.cci,
			cuenta_abono: this.operationData.cuenta_abono,
			linea_credito: this.operationData.linea_credito,
			tipo_linea_solicitada: this.operationData.tipo_linea_solicitada,
			puntual: this.operationData.puntual ? this.operationData.puntual : false
		};

		let request;
		if (this.operationData.id) {
			request = this.factoringService.guardarOperacion(dataForm, this.operationData.id)
		} else {
			request = this.factoringService.guardarOperacion(dataForm)
		}

		request.then(data => {
			this.operationData.id = data.id
			const response: any = data;
			this.datosDetalle(response);
			file.append('operacion', this.operationData.id);
			this.subirXml(file);
		},
			error => {
				if (error.error['tipo cambio']) {
				}
				console.log(error)
				console.log('Guardar detalle error');
			});
	}

	async subirXml(data) {
		await this.factoringService.importarXmlDetalles(data)
			.then(result => {
				console.log(result);
				this.cargarDatosDeProceso(this.operationData.id);
				return result;
			})
			.catch(error => {
				console.error(error);
			});
	}

	obtenerTiposDeLinea() {
		this.factoringService.obtenerTiposDeLineas()
			.then(res => {
				this.lineas = res['results'];
			})
			.catch(error => {
				console.error(error);
			});
	}

	async obtenerLineas(beneficiarioId) {
		const operacion = this.operacion;

		const res = await this.factoringService.obtenerLineas(beneficiarioId)
		if (res['results'].length) {
			this.lineasBeneficiario = res['results'];
		}
		return res
	}

	async cambiarLineaBeneficiario() {
		const tipoLinea = this.formularioDetalle.controls['tipo_linea_solicitada'].value;
		this.tipoLineaSolicitada = tipoLinea

		if (!this.lineasBeneficiario) {
			if (this.clienteFactoring) {
				await this.obtenerLineas(this.clienteFactoring?.id)
			} else {
				await this.obtenerLineas(this.beneficiario?.id)
			}
		}

		let lineaBeneficiario
		if (this.lineasBeneficiario?.length) {
			// Elige la linea correcta
			lineaBeneficiario = this.lineasBeneficiario.find((item) => {

				let condicion1 = this.operacion?.puntual && ( this.operacion.estacion == 1 ) && item.puntual
				let condicion4 = !this.operacion?.puntual && !item.puntual
				let condicion2 = item.tipo == tipoLinea
				let condicion3 = item.empresa == this.formularioSolicitantes.get('empresa').value

				return (condicion1 || condicion4) && condicion2 && condicion3;
			});
		}

		if (tipoLinea == 2) {
			this.readOnlyFactoring = true
		} else {
			// Obtiene las cuentas del beneficiario (si se cambia de factoring a descuento)
			this.readOnlyFactoring = false
			this.clienteFactoring = null
			this.formularioDetalle.controls['ruc_search'].setValue('');
		}

		if (lineaBeneficiario && tipoLinea == 1) { // La linea de factoring se carga en "CargarLineaFactoring"
			this.formularioDetalle.controls['linea_credito'].setValue(lineaBeneficiario.id);
			this.formularioDetalle.controls['limitedecredito'].setValue(lineaBeneficiario.linea_actual);
			this.formularioDetalle.controls['consumido'].setValue(lineaBeneficiario.linea_consumida);
			this.formularioDetalle.controls['disponible'].setValue(lineaBeneficiario.linea_disponible);

			this.formularioDetalle.controls['linea_solicitada'].setValue(lineaBeneficiario.linea_actual);

			this.formularioDetalle.controls['monedaLinea'].setValue(this.operacion?.moneda_linea_credito || this.operationData?.moneda_linea_credito || lineaBeneficiario.moneda);
			this.formularioDetalle.controls['cuenta_abono'].setValue(this.operacion?.cuenta_abono || this.operationData?.cuenta_abono);
			this.formularioDetalle.controls['monto'].setValue(this.operacion?.monto || this.operationData?.monto);
			this.formularioDetalle.controls['moneda'].setValue(this.operacion?.moneda || this.operationData?.moneda);
			this.formularioDetalle.controls['banco_abono'].setValue(this.operacion?.banco_abono || this.operationData?.banco_abono);
			this.formularioDetalle.controls['nro_cuenta'].setValue(this.operacion?.nro_cuenta || this.operationData?.nro_cuenta);
			this.formularioDetalle.controls['cci'].setValue(this.operacion?.cci || this.operationData?.cci);

			if (this.operacion?.linea_solicitada || this.operationData?.linea_solicitada) {
				this.formularioDetalle.controls['linea_solicitada'].setValue(this.operacion?.linea_solicitada || this.operationData?.linea_solicitada);
			}

			this.formularioDetalle.controls['fecha_vencimiento'].setValue(this.parseDate(lineaBeneficiario.fecha_vencimiento_contrato));
		} 
		
		// console.log(this.formularioSolicitantes.controls['puntual'].value)
		if(!lineaBeneficiario && (this.formularioSolicitantes.controls['puntual'].value)){
			this.setFormularioDetallesPuntual()
		}
		else {
			// this.resetearDetalle()
			this.readOnlyFactoring = false
		}
	}

	resetearDetalle() {
		this.formularioDetalle.controls['linea_credito'].setValue(null);
		this.formularioDetalle.controls['limitedecredito'].setValue(null);
		this.formularioDetalle.controls['consumido'].setValue(null);
		this.formularioDetalle.controls['disponible'].setValue(null);
		this.formularioDetalle.controls['monedaLinea'].setValue(null);
		this.formularioDetalle.controls['linea_solicitada'].setValue(null);
		this.formularioDetalle.controls['cci'].setValue(null);
		this.formularioDetalle.controls['nro_cuenta'].setValue(null);
		this.formularioDetalle.controls['moneda'].setValue(null);
		this.formularioDetalle.controls['cuenta_abono'].setValue(null);



		this.formularioDetalle.controls['fecha_vencimiento'].setValue(null);
	}

	cargarLineaFactoring(beneficiarioId) {
		this.readOnlyFactoring = false
		this.resetearDetalle()

		this.formularioDetalle.controls['cuenta_abono'].setValue(this.operacion?.cuenta_abono || this.operationData?.cuenta_abono);
		this.formularioDetalle.controls['monto'].setValue(this.operacion?.monto || this.operationData?.monto);
		this.formularioDetalle.controls['moneda'].setValue(this.operacion?.moneda || this.operationData?.moneda);
		this.formularioDetalle.controls['monedaLinea'].setValue(this.operacion?.moneda_linea_credito || this.operationData?.moneda_linea_credito);
		this.formularioDetalle.controls['banco_abono'].setValue(this.operacion?.banco_abono || this.operationData?.banco_abono);
		this.formularioDetalle.controls['nro_cuenta'].setValue(this.operacion?.nro_cuenta || this.operationData?.nro_cuenta);
		this.formularioDetalle.controls['cci'].setValue(this.operacion?.cci || this.operationData?.cci);
		this.factoringService.obtenerLineas(beneficiarioId)
			.then(res => {
				if (res['results'].length) {
					let tipoLineaSolicitada = this.operacion?.tipo_linea_solicitada || this.operationData?.tipo_linea_solicitada || this.tipoLineaSolicitada
					const beneficiarioLinea = res['results'].find(item => (item.tipo == tipoLineaSolicitada) && (item.empresa == this.formularioSolicitantes.get('empresa').value));
					if (beneficiarioLinea) {
						this.formularioDetalle.controls['linea_credito'].setValue(beneficiarioLinea.id);
						this.formularioDetalle.controls['limitedecredito'].setValue(beneficiarioLinea.linea_actual);
						this.formularioDetalle.controls['consumido'].setValue(beneficiarioLinea.linea_consumida);
						this.formularioDetalle.controls['disponible'].setValue(beneficiarioLinea.linea_disponible);
						this.formularioDetalle.controls['monedaLinea'].setValue(beneficiarioLinea.moneda);
						this.formularioDetalle.controls['linea_solicitada'].setValue(beneficiarioLinea.linea_actual);
						this.formularioDetalle.controls['fecha_vencimiento'].setValue(this.parseDate(beneficiarioLinea.fecha_vencimiento));
					}
				}
				if (this.operacion?.linea_solicitada || this.operationData?.linea_solicitada) {
					this.formularioDetalle.controls['linea_solicitada'].setValue(this.operacion?.linea_solicitada || this.operationData?.linea_solicitada);
				}
			})
			.catch(error => {
				console.error(error);
			});

	}

	parseDate(date) {
		if (!date)
			return;

		const arrayDate = date.split('-');
		return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;

	}

	obtenerCuentas(beneficiarioId) {
		console.log("obtenerCuentas")
		this.factoringService.obtenerCuentas(beneficiarioId)
			.then(res => {
				console.log(res);
				this.cuentasAbonos = res['results'];
			})
			.catch(error => {
				console.error(error);
			})
	}

	llenarDatosDeCuentas() {
		const cuentaId = this.formularioDetalle.controls['cuenta_abono'].value;
		if (!cuentaId) {
			return;
		}


		const cuenta = this.cuentasAbonos.find(item => item.id == cuentaId);
		this.formularioDetalle.controls['banco_abono'].setValue(cuenta.entidad_financiera);
		this.formularioDetalle.controls['moneda'].setValue(cuenta.moneda);
		this.formularioDetalle.controls['nro_cuenta'].setValue(cuenta.numero_cuenta);
		this.formularioDetalle.controls['cci'].setValue(cuenta.numero_cuenta_cci);

	}

	mapperDetalleOperacion(arrayDetalles) {
		return arrayDetalles.map(item => {
			let data = item;
			delete item.adjunto_pdf;
			return data;
		});
	}

	refreshTable() {
		this.cargarDatosDeProceso(this.operationData.id);
	}

	dateString(fecha) {
		if (!fecha) return ''

		let fechaArr = fecha.split('-');

		let diasVencimiento = this.restaFechas(fechaArr)

		if (diasVencimiento < 30) {
			// color rojo
		} else {
			// color azul
		}

		return `${fechaArr[2]}/${fechaArr[1]}/${fechaArr[0]}`
	}

	restaFechas(fechaArr) {
		let fechaVencimiento = new Date(fechaArr[0], (fechaArr[1] - 1), fechaArr[2])
		let justoHoy = new Date()
		justoHoy.setHours(0, 0, 0, 0)
		let diferenciaDias = fechaVencimiento.getTime() - justoHoy.getTime()
		return Math.floor(diferenciaDias / (1000 * 60 * 60 * 24))
	}

	lanzarModalConfirmacion(documentos: any[]) {
		const modalRef = this.modalService.open(ConfirmModalComponent, {});
		let mensaje = `
            <span><strong>Existen documentos vencidos en la operación actual:</strong></span>
            <ul>
        `

		documentos.forEach((item) => {
			mensaje += `<li>${item.documento_descripcion}</li>`
		})
		mensaje += `</ul><br><span class='message'><strong>¿Está seguro de que desea derivar la operación?</strong></span>`
		modalRef.componentInstance.title = "Documentos Vencidos";
		modalRef.componentInstance.message = mensaje;
		modalRef.componentInstance.messageHTML = true;

		modalRef.result.then((result) => {
			if (result) {
				this.derivar()
			}
		});
	}

	async validacionesDerivacion() {

		this.factoring.validardocumentosVencidos(this.operacion.id).then((res: any) => {
			if (res.documentos && res.documentos.length > 0) {
				this.lanzarModalConfirmacion(res.documentos)
			} else {
				this.derivar()
			}
		}).catch((err) => {
			console.log(err)
		})
	}

	mostrarVencimientosDeLineas(lineas_de_credito: any) {
		if (this.beneficiario.lineas_de_credito.length > 0) {
			this.beneficiario.lineas_de_credito = this.beneficiario.lineas_de_credito.map((item, arr, element) => {
				if (item.fecha_vencimiento_contrato) {
					item.dias_de_vencimiento = this.restaFechas(item.fecha_vencimiento_contrato.split('-'))
				} else {
					item.dias_de_vencimiento = 1000
				}

				item.estado = item.dias_de_vencimiento > 30
				return item
			})
		}

		this.validarContratos()

	}

	mostrarBotonOperacionSinLinea(value) {
		this.opSinLineaCampoToggle = value
	}

	validarContratos() {
		let { formularioSolicitantes, beneficiario } = this
		let empresaFormulario = formularioSolicitantes.get('empresa').value

		let getLineasDescuento                  = ({tipo, empresa }) => (tipo == 1) && (empresa == empresaFormulario)
		let gesLineasDescuentoPuntualesVigentes = ({ puntual, empresa, estado }) => ( puntual ) && ( empresa == empresaFormulario ) && ( estado )
		
		let { lineas_de_credito }                    = beneficiario
		let lineasDescuento                          = lineas_de_credito.filter(getLineasDescuento)
		let algunContratoDesgloseVencido             = lineasDescuento.some(linea => !linea.estado)
		let algunContratoPuntual                     = lineasDescuento.some(gesLineasDescuentoPuntualesVigentes)

		let condicion1                               = (lineasDescuento.length == 0) || algunContratoDesgloseVencido
		let condicion2                               = !algunContratoPuntual


		if ( condicion1 && condicion2) {
			this.mostrarBotonOperacionSinLinea(true)
		} else {
			this.mostrarBotonOperacionSinLinea(false)
		}


	}
}



