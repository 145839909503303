import { Component, Input, OnInit } from '@angular/core';
import { faCloudUploadAlt, faFileDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { AdjuntarFileComponent } from 'app/shared/adjuntar-file/adjuntar-file.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { AddArchivoComponent } from '../partials/add-archivo/add-archivo.component';

@Component({
  selector: 'app-archivos',
  templateUrl: './archivos.component.html',
  styleUrls: ['./archivos.component.css']
})
export class ArchivosComponent implements OnInit {
  @Input() clienteId    = 0;
  @Input() expedienteId = 0;
  @Input() readonly     = false;
  isExpediente = false;
  archivos: any[] = [];
  faFileDownload = faFileDownload;
  faCloudUploadAlt = faCloudUploadAlt;
  faTrash = faTrash;

  constructor(
    public modalService: NgbModal,
    public clientes: ClientesService,
    public functions: SharedFunctions,
    public adjuntar: AdjuntarFileComponent,
  ) { }

  ngOnInit(): void {
    this.buscarDocumentos()
  }

  public buscarDocumentos() {
    let archivos;
    if(this.expedienteId != 0){
      archivos = this.clientes.buscarDocumentosExpediente(this.expedienteId)
      this.isExpediente = true
    } else {
      archivos = this.clientes.buscarDocumentosEntidad('cliente', this.clienteId)
    }

    Promise.all([
      archivos
    ]).then((res: any[]) => {
      this.archivos = res[0].results.map((item) => {
        item.fecha_creacion = item.fecha_creacion ? this.functions.formaStringDate(item.fecha_creacion) : '';
        item.fecha_actualizacion = item.fecha_actualizacion ? this.functions.formaStringDate(item.fecha_actualizacion) : '';
        return item
      })
    })
  }

  public agregarDocumento() {
    const modalRef = this.modalService.open(AddArchivoComponent, {});
    let { isExpediente } = this
    modalRef.componentInstance.entidad = isExpediente ? "expediente" : "cliente";
    modalRef.componentInstance.entidadId = isExpediente ? this.expedienteId : this.clienteId;

    modalRef.result.then((result) => {
      if (result) {
        this.guardarDocumento(result);
      }
    });
  }

  public guardarDocumento(documento) {
    const formData = new FormData();
    for (const props in documento) {
      if(props == 'archivo'){
        formData.append('peso',documento[props].size)  
      }
      formData.append(props, documento[props]);
    }
    if(this.isExpediente){
      this.clientes.adjuntarDocumentosExpediente(formData).then((res: any) => {
        this.ngOnInit()
        console.log(res);
      })
    } else {
      this.clientes.adjuntarDocumentosEntidad('cliente', formData).then((res: any) => {
        this.ngOnInit()
        console.log(res);
      })
    }
  }

  public descargaDocumento(archivo) {
    const ultimoSlash = new URL(archivo.archivo).pathname;
    const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1]

    this.downloadFile(archivo.archivo, adjuntoName);
  }

  destroyArchive(archive) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {});

    modalRef.componentInstance.title = "Remover Archivo";
    modalRef.componentInstance.message =
      "¿Seguro que desea remover el archivo?";

    modalRef.result.then((result) => {
      if (result) {
        if(this.isExpediente){
          this.clientes.eliminarAdjuntoExpediente(archive.id).then((res: any) => {
            this.ngOnInit()
          })
        } else {
          this.clientes.eliminarAdjuntoEntidad('cliente', archive.id).then((res: any) => {
            this.ngOnInit()
          })
        }
      }
    });
  }

  downloadFile(url, name) {
    this.clientes.downloadFile(url).then(
      (x: any) => {
        console.log(x);
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = name;
        link.click();
      }, async (reason) => {
        console.log(reason);
      }), err => {
        console.error(err);
      };
  }

}
