import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-adjuntar-file',
  templateUrl: './adjuntar-file.component.html',
  styleUrls: ['./adjuntar-file.component.css']
})
export class AdjuntarFileComponent implements OnInit {
  @Output() fileAdjunto = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }


  crearAdjuntar(){
    var fileSelect = document.createElement("input"); 
    fileSelect.setAttribute("id", "campoAdjuntar"); 
    fileSelect.setAttribute("type", "file"); 
    fileSelect.setAttribute("style", "display: none;"); 
    let formularioDetalles = document.getElementsByTagName("div")[0]
    formularioDetalles.appendChild(fileSelect)
    document.getElementById("campoAdjuntar").click()
    document.getElementById("campoAdjuntar").addEventListener('change', (event) => {
      this.onFileSelect(event)
        formularioDetalles.removeChild(fileSelect)
    });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];         
      // const formData = new FormData();
      // formData.append('adjunto', file);
      this.fileAdjunto.emit(file)
    }
  }

  obtenerBlob(res,nombre,formato = ''){
    var blob = new Blob([res]);
    var downloadURL = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${nombre}.${formato}`;
    link.click();
  }

  _obtenerBlob(res,nombre){
    var blob = new Blob([res]);
    var downloadURL = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = downloadURL;
    link.download = `${nombre}`;
    link.click();
  }

}
