import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FactoringService, IFiltros } from '../../../../core/services/factoring/factoring.service';
import { AuthService } from '../../../../core/services/auth/auth.service';
import { columnHeader } from '../../../../shared/factoring-datatable/factoring-datatable.component';
import { ActivatedRoute, Router } from '@angular/router';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { ReassignAnalystModalComponent } from '../../modals/reassign-analyst-modal/reassign-analyst-modal.component';
import { concat } from 'rxjs';
import { EstacionesOperacionModalComponent } from '../../modals/estaciones-operacion-modal/estaciones-operacion-modal.component';
import { EliminarOperacionModalComponent } from '../../modals/eliminar-operacion-modal/eliminar-operacion-modal.component';
import { ToastService } from 'app/core/services/toast/toast.service';
import { VerificationsService } from 'app/core/services/factoring/verifications.service';
import { Operacion } from 'app/core/services/auth/Operacion';
import { Estacion } from 'app/core/services/auth/Estacion';
import { DesembolsoModalComponent } from '../../modals/desembolso-modal/desembolso-modal.component';
import { faChartPie, faPlus, faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toArray } from 'rxjs/operators';
import * as XLSX from 'xlsx'

export interface ITotalizaciones {
	soles: number, 
	dolares: number, 
}

@Component({
	selector: 'app-factoring',
	templateUrl: './factoring.component.html',
	styleUrls: ['./factoring.component.css']
})
export class FactoringComponent implements OnInit {
	faTimes = faTimes;
	public rowData: any[] = [];
	public totalizacionData : ITotalizaciones = { soles: 0, dolares: 0 };
	public loadingTable: boolean = false;
	public columnDefs: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
	public user: any;
	public identidad: any = {};
	public searchstring: string = '';
	public haveTipoCambio: boolean = true;
	public estacionSearchId: any;
	public actBotonEdit:boolean = true;
	reassing = false;
	faPlus= faPlus;
	faSyncAlt = faSyncAlt;
	faChartPie = faChartPie;
	lider:any;
	oficial:any;
	exportando = false;
	tblFiltros = { // Debería incluirse lider y ofical
		moneda: "",
	};
	order_fecha = '';

	@ViewChild('e_tipocambio', { static: false }) public e_tipocambio;
	// observable$      : Observable<any[]>;
	// subjectText$      = new Subject<string>();
	// fieldData              : any;
	// loadingSearch              : boolean = false;

	estaciones: any[] = [];
	public empresas: any[];
	monedas: any[] = [];
	bancos: any[] = [];
 
 	totalDesembolsos: ITotalizaciones = { soles: 0, dolares: 0 }

	get lider1(){
		return this.lider == undefined || this.lider == 'l' ? '' : this.lider;
	}

	get oficial1(){
		return this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial;
	}

	constructor(
		private authService: AuthService,
		private activatedRoute: ActivatedRoute,
		private router: Router,
		private factoringService: FactoringService,
		private docService: DocschecklistService,
		private modalService: NgbModal,
		public toast: ToastService,
		private verificacionService: VerificationsService,
		private sanitizer : DomSanitizer
	) {
		this.user = this.authService.user;
		this.activatedRoute.params.subscribe(param => {
			let { oficial, lider, estacionId, moneda } = param
			this.oficial = oficial;
			this.lider = lider;
			this.tblFiltros = {...this.tblFiltros,  moneda: moneda};
			if (!!estacionId) {
				this.estacionSearchId = estacionId
			}
		})
	}

	ngOnInit(): void {
		let page = 1, per_page = 10;
		let operaciones;
		let totalizaciones;
		let { estacionSearchId } = this;
		let filtros : IFiltros = {
			page: page,
			per_page: per_page,
			beneficiario: '',
			oficial_negocio: '',
			responsable: '',
			estado: '',
			estacion: '',
			fecha__gte: '',
			fecha__lte: '',
			analista_riesgo: '',
			comite: '',
			monto__range: '',
			oficial_negocio__nombre: '',
			analista_operacion: '',
			tipo_linea_solicitada: '',
			empresas: '',
			lider: '',
			moneda: this.tblFiltros.moneda ? this.tblFiltros.moneda : "",
			ordering: '',
			responsable_nombre: '',
			fecha_estacion__gte: '',
			fecha_estacion__lte: '',
			banco_abono: '',
			cuenta_abono: '',
		};
		
		switch (this.user.perfil) {
			case this.authService.perfil.perfilAsistenteCobranzasID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : this.authService.perfil.perfilAsistenteCobranzasID.toString(),
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilJefeDeOperacionesID:
				filtros = {
					...filtros, 
					estacion: estacionSearchId ? estacionSearchId : this.authService.perfil.perfilJefeDeOperacionesID.toString(),
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilAnalistaOperacionesID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId :'9',
					analista_operacion: `${this.authService.user.id}`,
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilLiderDeEquipo:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '',
					lider: this.lider == undefined || this.lider == 'l' ? `${this.authService.user.id}` : this.lider,
				}
				break;

			case this.authService.perfil.perfilLegalID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '6',
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilAsesorLegalID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '6',
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilJefeDeAdmisionID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '3',
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilLiderDeTesoreriaID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '11',
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilAnalistaRiesgosID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					responsable: this.user.id,
					estacion: estacionSearchId ? estacionSearchId : '',
					analista_riesgo: this.user.id,
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			default:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '',
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;
		}

		operaciones = this.obtenerOperacionesFiltradasAlt(filtros, false);
		totalizaciones = this.obtenerOperacionesFiltradasAlt(filtros, true);

		let estados = this.docService.obtenerEstados();
		let estaciones = this.docService.obtenerEstaciones();
		let analistasRiesgos = this.factoringService.obtenerResponsables(6);
		let analistasOperaciones = this.factoringService.obtenerResponsables(14);
		let oficialesNegocio = this.factoringService.obtenerResponsables(3);
		let tiposDeCambio = this.docService.obtenerTiposDeCambio(this.formatDate(new Date()));
		let tipoProductos = this.factoringService.obtenerTiposDeLineas();
		let empresas = this.factoringService.obtenerEmpresas();
		let monedas = this.factoringService.obtenerMonedas();
		let bancos = this.factoringService.obtenerBancos();
		// let liderDeEquipo = this.factoringService.obtenerLiderDeEquipo();

		Promise.all([
			operaciones,
			estados,
			estaciones,
			analistasRiesgos,
			oficialesNegocio,
			tiposDeCambio,
			analistasOperaciones,
			tipoProductos,
			empresas,
			totalizaciones,
			monedas,
			bancos,
			// liderDeEquipo,
		]).then((res: any[]) => {
			let operaciones = res[0];
			let estados = res[1]['results'];
			let estaciones = res[2]['results'];
			let analistasRiesgos = res[3]['results']
			let oficialesNegocios = res[4]['results']
			let tiposDeCambio = res[5]['results'][0]
			let analistasOperaciones = res[6]['results']
			let tipoProductos = res[7]['results']
			let empresas = res[8]['results']
			let totalizaciones = res[9];
			this.monedas = res[10]['results']
			this.bancos = res[11]['results']
			// let liderDeEquipo = res[9]['results'];
			
			if (!tiposDeCambio) {
				this.haveTipoCambio = false
			}
			this.initVars(tipoProductos, estados, estaciones, analistasRiesgos, oficialesNegocios, analistasOperaciones, empresas,);

			if (!tiposDeCambio) {
				this.haveTipoCambio = false
			}
			this.initVars(tipoProductos, estados, estaciones, analistasRiesgos, oficialesNegocios, analistasOperaciones, empresas,);
			this.loadTable(operaciones);
			this.loadTotalizaciones(totalizaciones);

			this.estaciones = estaciones;

		});

		if (this.user.isJefeDeAdmision || this.user.isAsesorLegal || this.user.isGerenteGeneral || this.user.isGerenteComercial || this.user.isJefeDeOperaciones || this.user.isLiderDeTesoreria || this.user.isLiderDeEquipo) {
			// if (this.user.isJefeDeAdmision || this.user.isAsesorLegal || this.user.isJefeDeOperaciones) {
			this.reassing = true;
		}
	}

	refresh(): void {
		let page = 1, per_page = 10;
		let operaciones;
		let totalizaciones;
		let { estacionSearchId, authService } = this;
		let filtros : IFiltros = {
			page: page,
			per_page: per_page,
			beneficiario: '',
			oficial_negocio: '',
			responsable: '',
			estado: '',
			estacion: '',
			fecha__gte: '',
			fecha__lte: '',
			analista_riesgo: '',
			comite: '',
			monto__range: '',
			oficial_negocio__nombre: '',
			analista_operacion: '',
			tipo_linea_solicitada: '',
			empresas: '',
			lider: '',
			moneda: this.tblFiltros.moneda ? this.tblFiltros.moneda : "",
			ordering: '',
			responsable_nombre: '',
			fecha_estacion__gte: '',
			fecha_estacion__lte: '',
			banco_abono: '',
			cuenta_abono: '',
		};

		switch (this.user.perfil) {
			case this.authService.perfil.perfilAsistenteCobranzasID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : this.authService.perfil.perfilAsistenteCobranzasID.toString(),
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilJefeDeOperacionesID:
				filtros = {
					...filtros, 
					estacion: estacionSearchId ? estacionSearchId : this.authService.perfil.perfilJefeDeOperacionesID.toString(),
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilAnalistaOperacionesID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId :'9',
					analista_operacion: `${this.authService.user.id}`,
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilLiderDeEquipo:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '',
					lider: this.lider == undefined || this.lider == 'l' ? `${this.authService.user.id}` : this.lider,
				}
				break;

			case this.authService.perfil.perfilLegalID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '6',
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilAsesorLegalID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '6',
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilJefeDeAdmisionID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '3',
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			case this.authService.perfil.perfilLiderDeTesoreriaID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '11',
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break; 

			case this.authService.perfil.perfilAnalistaRiesgosID:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					responsable: this.user.id,
					estacion: estacionSearchId ? estacionSearchId : '',
					analista_riesgo: this.user.id,
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;

			default:
				filtros = {
					...filtros,
					oficial_negocio: this.oficial == undefined || this.oficial == 'o' ? '' : this.oficial,
					estacion: estacionSearchId ? estacionSearchId : '',
					lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				}
				break;
		}

		operaciones = this.obtenerOperacionesFiltradasAlt(filtros, false);
		totalizaciones = this.obtenerOperacionesFiltradasAlt(filtros, true);

		Promise.all([
			operaciones,
			totalizaciones,
		]).then((res: any[]) => {
			let operaciones = res[0];
			let totalizaciones = res[1];
			this.loadTable(operaciones);
			this.loadTotalizaciones(totalizaciones);
		});

		if (this.user.isJefeDeAdmision || this.user.isAsesorLegal || this.user.isGerenteGeneral || this.user.isGerenteComercial || this.user.isJefeDeOperaciones || this.user.isLiderDeTesoreria || this.user.isLiderDeEquipo) {
			this.reassing = true;
		}
	}

	isPriority(row: any) {
		return (row?.adelanto_comite > 0) ? '#85C1E9' : (row?.electo_sin_riesgo ? '#0a05' : '');
	}

	/* Inicializa la tabla con datos y paginacion */
	loadTable(data) {
		this.rowData = data.results
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

	loadTotalizaciones(data){
		this.totalizacionData = {
			soles: data['monto']['S/'] || 0,
			dolares: data['monto']['US$'] || 0
		}
		this.totalDesembolsos = {
			soles: data['desembolso']['S/'] || 0,
			dolares: data['desembolso']['US$'] || 0
		}
	}

	initVars(
		tipoProductos = [],
		estados = [],
		estaciones = [],
		analistaRiesgos = [],
		oficialesNegocio = [],
		analistasOperaciones = [],
		empresas = [],
	) {
		let itemsOrdenados = oficialesNegocio.sort((itemA, itemB) => {
			if (itemA > itemB) {
				return 1
			}
			if (itemA < itemB) {
				return -1
			}

			return 0
		})

		let idOficiales = this.user.equipo.map((item)=> item.id)
		let filtrados = oficialesNegocio.filter((item)=>  { return idOficiales.includes(item.id) || item.id == this.user.id })

		let columnas = [
			{
				headerName: 'N° Ope.',
				field: 'id',
				sortable: true,
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 19, 18],
				filterable: true,
				filterProp: "operacion",
				filterInput: true,
				sumCol: [11, 17].includes(this.user.perfil),
 				sumColFn: () => 'TOTAL'
			},
			// {
			// 	headerName: 'Empresa',
			// 	field: 'empresa_nombre',
			// 	sortable: true,
			// 	filterable: true,
			// 	filterProp: 'empresa',
			// 	filterSelectItems: empresas,
			// 	filterItemsProps: {
			// 		value: 'id',
			// 		label: 'nombre'
			// 	},
			// 	perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 19, 18],
			// },
			{
				headerName: 'Analista de Operaciones',
				field: 'analista_operacion_nombre',
				filterProp: 'analista_operacion_nombre',
				filterable: true,
				filterSelectItems: analistasOperaciones,
				filterItemsProps: {
					value: 'id',
					label: 'nombre'
				},
				pipe: 'function',
				function: function (row, header) {
					return !!row.analista_operacion ? row.analista_operacion_nombre : 'No asignado'
				},
				perfiles: [9, 14]
			},
			{
				headerName: 'Tipo de Producto',
				field: 'tipo_linea_solicitada_descripcion',
				filterProp: 'tipo_linea_solicitada',
				sortable: true,
				class: 'text-center',
				filterable: true,
				filterSelectItems: tipoProductos,
				filterItemsProps: {
					value: 'id',
					label: 'descripcion'
				},
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 19, 18],
			},
			{
				headerName: 'RUC',
				field: 'beneficiario_ruc',
				sortable: true,
				class: 'text-center',
				perfiles: [1, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 19, 18]
			},
			{
				headerName: 'Cliente',
				field: 'beneficiario_nombre',
				sortable: true,
				filterable: true,
				filterProp: 'beneficiario',
				filterType: 'buscarBeneficiario',
				perfiles: [1, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 19, 18]
			},
			{
				headerName: 'Oficial de Negocios',
				field: 'oficial_negocio_nombre',
				filterProp:  this.user.isLiderDeEquipo ? 'oficial_negocio' : 'oficial_negocio__nombre',
				sortable: true,
				filterable: true,
				filterInput: !this.user.isLiderDeEquipo,
				filterSelectItems: this.user.isLiderDeEquipo ? filtrados : null,
				filterItemsProps: {
					value: 'id',
					label: 'nombre'
				},
				perfiles: [1, 2, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14,19, 18]
			},
			{
				headerName: 'Analista de Riesgos',
				field: 'analista_riesgo_nombre',
				filterProp: 'analista_riesgo',
				filterable: true,
				filterSelectItems: analistaRiesgos,
				filterItemsProps: {
					value: 'id',
					label: 'nombre'
				},
				sortable: true,
				perfiles: [5, 6, 8, 7]
			},
			{
				headerName: 'Analista de Operaciones',
				field: 'analista_operacion_nombre',
				filterProp: 'analista_operacion_nombre',
				filterable: true,
				filterSelectItems: analistasOperaciones,
				filterItemsProps: {
					value: 'id',
					label: 'nombre'
				},
				pipe: 'function',
				function: function (row, header) {
					return !!row.analista_operacion ? row.analista_operacion_nombre : 'No asignado'
				},
				perfiles: [4]
			},
			{
				headerName: 'Fecha asignado',
				field: 'fecha_analista_asignado',
				pipe: 'date',
				class: 'text-center',
				perfiles: []
			},{
				headerName: "Moneda",
				field: "moneda_descripcion",
				filterProp: "moneda",
				sortable: true, 
				class: "text-center",
				filterable: true,
				filterSelectItems: this.monedas,
				filterItemsProps: { value: 'id', label: 'descripcion' },
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14, 19, 18],
			},{
				headerName: 'Monto',
				field: 'monto',
				pipe: 'currency',
				moneda: 'moneda',
				class: 'text-center',
				sortable: true,
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14,19, 18],
				filterable: true,
				filterRange: true,
				sumCol: true,
				sumColFn: () =>  {
					const htmlString = `
						<div class="d-flex flex-column px-5">
							<div class="d-flex align-items-center justify-content-between mx-2 mt-1">
								<h6 class="m-0 p-0">S/. </h6>
								<p class="m-0 p-0 ml-2">${Number(this.totalizacionData.soles).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
							</div>
							<div class="d-flex align-items-center justify-content-between mx-2 mb-1">
								<h6 class="m-0 p-0">$ </h6>
								<p class="m-0 p-0 ml-2">${Number(this.totalizacionData.dolares).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
							</div>
						</div>
					`
					return this.sanitizer.bypassSecurityTrustHtml(htmlString)
				},
			},
			{
				headerName: 'Anticipo',
				field: 'anticipo_comite',
				pipe: 'percent-icon',
				sortable: true,
				class: 'text-center',
				perfiles: [9, 4, 16, 10, 11, 17, 12, 14]
			},
			{
				headerName: 'Desembolso',
				field: 'monto_desembolso',
				pipe: 'currency',
				moneda: 'moneda',
				class: 'text-center',
 				sumCol: true,
 				sumColFn: () => {
					 const htmlString = `
					 <div class="d-flex flex-column px-5">
						 <div class="d-flex align-items-center justify-content-between mx-2 mt-1">
							 <h6 class="m-0 p-0">S/. </h6>
							 <p class="m-0 p-0 ml-2">${Number(this.totalDesembolsos.soles).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
						 </div>
						 <div class="d-flex align-items-center justify-content-between mx-2 mb-1">
							 <h6 class="m-0 p-0">$ </h6>
							 <p class="m-0 p-0 ml-2">${Number(this.totalDesembolsos.dolares).toLocaleString('en-US', { minimumFractionDigits: 2 })}</p>
						 </div>
					 </div>
				 `
				 return this.sanitizer.bypassSecurityTrustHtml(htmlString)
 				},
				perfiles: [9, 4, 16, 10, 11, 17, 12, 14]
			},
			{
				headerName: 'Banco',
				field: 'banco_abono_nombre',
				class: 'text-center',
				filterable: true,
				filterProp: "banco_abono",
				filterSelectItems: this.bancos,
				filterItemsProps: {
					value: 'id',
					label: 'nombre'
				},
				perfiles: [11, 17]
			},
			{
				headerName: 'Cuenta',
				field: 'nro_cuenta',
				class: 'text-center',
				filterable: true,
				filterProp: "cuenta_abono",
				filterInput: true,
				perfiles: [11, 17]
			},
			{
				headerName: 'Fecha',
				field: 'fecha',
				pipe: 'date',
				sortable: true,
				filterable: true,
				filterDate: true,
				class: 'text-center',
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14,19, 18]
			},
			{
				headerName: 'Estado',
				field: 'estado_descripcion',
				filterProp: 'estado',
				sortable: true,
				class: 'text-center',
				filterable: true,
				filterSelectItems: estados.filter((estado) => estado.id != 9),
				filterItemsProps: {
					value: 'id',
					label: 'descripcion'
				},
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14,19, 18]
			},
			{
				headerName: 'Workflow',
				field: 'perfil_descripcion',
				pipe: 'actionable',
				text: 'Ver',
				sortable: true,
				perfiles: [1, 2, 3, 4, 16, 5, 6, 8, 7, 9, 10, 11, 17, 12, 13, 14,19, 18]
			},
			{
				headerName: 'Estación',
				field: 'estacion_nombre',
				sortable: true,
				filterProp: 'estacion',
				class: 'text-center',
				filterable: true,
				filterSelectItems: estaciones,
				filterItemsProps: {
					value: 'id',
					label: 'nombre'
				},
				perfiles: [1, 3, 4, 16, 5, 6, 7, 8, 9, 13, 14, 17,19, 18]
			},
			{
				headerName: 'Estación',
				field: 'estacion_nombre',
				sortable: true,
				filterProp: 'estacion',
				class: 'text-center',
				perfiles: [10, 11, 12]
			},
			{
				headerName: 'Estación',
				field: 'estacion_descripcion_cliente',
				sortable: true,
				filterProp: 'estacion',
				class: 'text-center',
				perfiles: [2]
			},
			{
				headerName: 'Responsable',
				field: 'responsable_nombre',
				sortable: true,
				filterable: true,
				filterProp: "responsable_nombre",
				filterInput: true,
				perfiles: [3, 4, 16, 5, 6, 8, 7, 17, 19, 18]
			},
			{
				headerName: 'Fecha estación',
				field: 'fecha_estacion',
				filterProp: 'fecha_estacion',
				pipe: 'date',
				sortable: true,
				filterable: true,
				filterDate: true,
				class: 'text-center',
				perfiles: [1, 3, 4, 16, 6, 8, 7, 10, 11, 17, 12, 13, 19, 18]
			},
			{
				headerName: 'Fecha ingreso operaciones',
				field: 'fecha_jefe_operaciones_asignado',
				pipe: 'date',
				sortable: true,
				class: 'text-center',
				perfiles: [9, 14]
			},
		];

		this.columnDefs = columnas.reduce((acc, item) => {
			if (item.perfiles.includes(this.user.perfil)) {
				acc.push(item)
			}
			return acc
		}, [])

		//quitamos la validación que no permitia al perfil Consultas editar las operaciones en curso
		// this.actBotonEdit = this.user.perfil == 18 ? false : true;
	}

	crear() {
		if (this.haveTipoCambio) {
			this.router.navigate(['/registrar-solicitud-factoring'])
		} else {
			this.errorNoHayTipoCambio()
		}
	}

	tableEditEvent(row: any) {
		if (row.estacion == 1) {
			this.router.navigate(['/registrar-solicitud-factoring', row.id])
		} else {
			this.router.navigate(['/operaciones', row.id])
		}

	}

	async exportarDataTablaAbonos() {
		console.log('Filtros Tabla', this.tblFiltros)
		const lote = 100

		this.exportando = true
		this.factoringService.getOperaciones(Object.assign({ 'page_size': 1 }, this.tblFiltros)).subscribe(
			data => {
			let pages = Math.ceil(data['count'] / lote)
			let pages$ = Array.from({ length: pages }).map((_, i) => i + 1).map(
				page => this.factoringService.getOperaciones(Object.assign({ page, 'page_size': lote }, this.tblFiltros))
			)
			concat(...pages$).pipe(toArray()).subscribe((data: any) => {
				data = data.map((x: any) => x.results).flat(1)
				data = data.map(x => {
				let row = {}
				for (let header of this.columnDefs) {
					if(header['field'] == 'monto'){
						if(x['moneda'] == 1){
							row['Moneda'] = 'SOLES'
							row[header['headerName']] =  parseFloat(x[header['field']])
						}else{
							row['Moneda'] = 'DOLARES'
							row[header['headerName']] =  parseFloat(x[header['field']])
						}
					}else{
						row[header['headerName']] = x[header['field']]
					}
				}
				return row
				})
				let ws = XLSX.utils.json_to_sheet(data)
				let wb = XLSX.utils.book_new()
				XLSX.utils.book_append_sheet(wb, ws, 'Operaciones')
				XLSX.writeFile(wb, 'Reporte Operaciones en Curso.xlsx')
			}).add(() => this.exportando = false)
			},
			res => {
			console.log(res)
			this.exportando = false
			}
		)
	}

	goToPage({ page, per_page, filtros }) {
		console.log("filtros en filtrable", filtros)
		this.tblFiltros = filtros

		let operaciones;
		let totalizaciones;

		if( filtros.beneficiario != ''){
			this.order_fecha = '-fecha'
			filtros.lider = ''
			if (filtros.estacion != undefined){
				filtros.estacion = filtros.estacion
			}else{
				filtros.estacion = ''
			}
		}

		const range = (filtros.valueFrom && filtros.valueTo) ? `${filtros.valueFrom},${filtros.valueTo}` : '';
		
		if (filtros.operacion > 0) {
			let dataResult = {
				count: 0,
				next: null,
				num_pages: 1,
				page_number: 1,
				per_page: 10,
				previous: null,
				results: []
			};
				this.factoringService.obtenerOperacionBandeja(filtros.operacion)
					.then((res : any) => {
						dataResult.results.push(res);
						this.loadTable(dataResult);
						this.loadTotalizaciones({ 
							monto: {
								"S/": res.moneda == 1 ? parseFloat(res.monto) : .0,
								"US$": res.moneda == 2 ? parseFloat(res.monto) : .0,
							},
							desembolso: {
								"S/": res.moneda == 1 ? parseFloat(res.monto_desembolso) : .0,
								"US$": res.moneda == 2 ? parseFloat(res.monto_desembolso) : .0,
							}
						})
						return;
					}).catch(error => {
						console.error(error);
						this.loadTable(dataResult);
						return;
					});
				return;
		}else{
			 filtros.estado = filtros.estado === 0 ? 0 : filtros.estado === undefined ? '' : filtros.estado;
			let tFiltros : IFiltros = {
				page: page,
				per_page: per_page,
				beneficiario: filtros.beneficiario ? filtros.beneficiario : '',
				oficial_negocio: filtros.oficial_negocio ? filtros.oficial_negocio : this.oficial1,
				responsable: filtros.responsable ? filtros.responsable : '',
				estado: filtros.estado,
				estacion: filtros.estacion ? filtros.estacion : '',
				fecha__gte: filtros.fecha__gte ? filtros.fecha__gte : '',
				fecha__lte: filtros.fecha__lte ? filtros.fecha__lte : '',
				analista_riesgo: filtros.analista_riesgo ? filtros.analista_riesgo : '',
				comite: '',
				monto__range: range,
				oficial_negocio__nombre: filtros.oficial_negocio__nombre ? filtros.oficial_negocio__nombre : '',
				analista_operacion: filtros.analista_operacion_nombre ? filtros.analista_operacion_nombre : '',
				tipo_linea_solicitada: filtros.tipo_linea_solicitada ? filtros.tipo_linea_solicitada : '', 
				empresas: filtros.empresa ? filtros.empresa : '',
				lider: this.lider == undefined || this.lider == 'l' ? '' : this.lider,
				moneda: filtros.moneda ? filtros.moneda : '',
				ordering: this.order_fecha,
				responsable_nombre: filtros.responsable_nombre ? filtros.responsable_nombre : '',
				fecha_estacion__gte: filtros.fecha_estacion__gte ? filtros.fecha_estacion__gte : '',
				fecha_estacion__lte: filtros.fecha_estacion__lte ? filtros.fecha_estacion__lte : '',
				banco_abono: filtros.banco_abono ? filtros.banco_abono : '',
				cuenta_abono: filtros.cuenta_abono ? filtros.cuenta_abono : ''
			};

			switch (this.user.perfil) {
				case this.authService.perfil.perfilAsistenteCobranzasID:
					tFiltros = { ...tFiltros }
					break;

				case this.authService.perfil.perfilJefeDeOperacionesID:
					tFiltros = { ...tFiltros }
					break;

				case this.authService.perfil.perfilAnalistaOperacionesID:
					filtros.analista_operacion_nombre = filtros.estacion !== null ? '' : `${this.authService.user.id}`;
					filtros.estacion = filtros.estacion === '' ? '' : filtros.estacion === undefined ? '9' : filtros.estacion;
					tFiltros = {
						...tFiltros,
						estacion: filtros.estacion ? filtros.estacion : '', 
						analista_operacion: filtros.analista_operacion_nombre ? filtros.analista_operacion_nombre : '',
					}
					break;

				case this.authService.perfil.perfilLiderDeEquipo:
					tFiltros = {
						...tFiltros,
						lider: this.lider == undefined || this.lider == 'l' ? `${this.authService.user.id}` : this.lider,
					}
					break;

				case this.authService.perfil.perfilLegalID:
					filtros.estacion = filtros.estacion === '' ? '' : filtros.estacion === undefined ? '6' : filtros.estacion;
					tFiltros = {
						...tFiltros,
						estacion: filtros.estacion ? filtros.estacion : '', 
					}
					break; 

				case this.authService.perfil.perfilAsesorLegalID:
					filtros.estacion = filtros.estacion === '' ? '' : filtros.estacion === undefined ? '6' : filtros.estacion;
					tFiltros = {
						...tFiltros,
						estacion: filtros.estacion ? filtros.estacion : '',
					}
					break; 

				case this.authService.perfil.perfilJefeDeAdmisionID:
					filtros.estacion = filtros.estacion === '' ? '' : filtros.estacion === undefined ? '3' : filtros.estacion;
					tFiltros = {
						...tFiltros,
						estacion: filtros.estacion ? filtros.estacion : '', 
					}
					break;
					
				case this.authService.perfil.perfilLiderDeTesoreriaID:
					filtros.estacion = filtros.estacion === '' ? '' : filtros.estacion === undefined ? '11' : filtros.estacion;
					tFiltros = {
						...tFiltros,
						estacion: filtros.estacion ? filtros.estacion : '', 
					}
					break;
					
				case this.authService.perfil.perfilAnalistaRiesgosID:
					tFiltros = { ...tFiltros }
					break;

				default:
					tFiltros = { ...tFiltros }
					break;
			}

			operaciones = this.obtenerOperacionesFiltradasAlt(tFiltros, false);
			totalizaciones = this.obtenerOperacionesFiltradasAlt(tFiltros, true);
			
			Promise.all([
				operaciones,
				totalizaciones,
			]).then((res: any[]) => {
				let operaciones = res[0];
				let totalizaciones = res[1];
				this.loadTable(operaciones);
				this.loadTotalizaciones(totalizaciones);
			});

			if (this.user.isJefeDeAdmision || this.user.isAsesorLegal || this.user.isGerenteGeneral || this.user.isGerenteComercial || this.user.isJefeDeOperaciones || this.user.isLiderDeTesoreria || this.user.isLiderDeEquipo) {
				this.reassing = true;
			}
		}
	}

	obtenerOperacionesFiltradasAlt(filtros : IFiltros, totalized : boolean = false){
		switch (this.user.perfil) {
			case this.authService.perfil.perfilClienteID:
				filtros.beneficiario = this.user.cliente;
				break;
			case this.authService.perfil.perfilOficialDeNegocioID:
			case this.authService.perfil.perfilAsistenteComercialID:
				filtros.oficial_negocio = this.user.id;
				break;			
			case this.authService.perfil.perfilLiderDeEquipo:
				filtros.lider = this.user.id;
				break;
			case this.authService.perfil.perfilRecepcionID:
				filtros.responsable = this.user.id;
				filtros.estacion = '12';
				break;
			case this.authService.perfil.perfilTesoreriaID:
				filtros.responsable = this.user.id;
				filtros.estacion = '11';
				break;
			case this.authService.perfil.perfilGerenciaGeneralID:
			case this.authService.perfil.perfilGerenciaComercialID:
			case this.authService.perfil.perfilJefeDeAdmisionID:
			case this.authService.perfil.perfilAnalistaRiesgosID:
			case this.authService.perfil.perfilAsesorLegalID:
			case this.authService.perfil.perfilLegalID:
			case this.authService.perfil.perfilJefeDeOperacionesID:
			case this.authService.perfil.perfilAnalistaOperacionesID:
			case this.authService.perfil.perfilAsistenteCobranzasID:
			case this.authService.perfil.perfilLiderDeTesoreriaID:
				break;
			default:
				break;
		}
		this.tblFiltros = filtros;
		return this.factoringService.obtenerBandejaAlt(filtros, totalized);
	}

	reassignAnalyst(e) {
		const modalRef = this.modalService.open(ReassignAnalystModalComponent, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'lg'
		});
		modalRef.componentInstance.operacion = e;
		modalRef.componentInstance.user = this.authService.user;
		modalRef.result.then(res => {
			if (res) {
				this.ngOnInit()
			}
		});
	}

	errorNoHayTipoCambio() {
		this.modalService.open(this.e_tipocambio, {
			size: 'md'
		}).result.then((response: any) => {
			return this.docService.obtenerTiposDeCambio(this.formatDate(new Date()))
		}).then((res) => {
			let tiposDeCambio = res['results'][0]
			if (!tiposDeCambio) {
				this.haveTipoCambio = false
			} else {
				this.haveTipoCambio = true
			}
		})
	}

	formatDate(date) {
		return date.getFullYear() +
			"-" + (date.getMonth() + 1) +
			"-" + date.getDate();
	}

	refrescar() {
		this.refresh()
	}

	openOpercion(row) {
		const modalRef = this.modalService.open(EstacionesOperacionModalComponent, {
			ariaLabelledBy: 'modal-basic-title',
			size: 'xl'
		});
		modalRef.componentInstance.operacion = row;
		modalRef.componentInstance.estaciones = this.estaciones;
		modalRef.result.then(res => {

		});
	}

	destroy(row: Operacion) {
		let op = new Operacion(row)
		let negarCancelar = op.actualJefeOperaciones || op.actualTesoreria || op.actualAsistenteCobranzas

		if (negarCancelar) {
			this.toast.warning("La operación ya ha sido liquidada");
			return;
		}

		if (!this.authService.perfil.isResponsableDeOperacion(row)) {
			this.toast.warning("No es responsable de esta operación");
			return;
		}

		if (row.estado == 11) {
			this.verificacionService.extornarOperacion(row.id).then((res) => {
				this.refrescar()
			})
			return
		}


		const modalRef = this.modalService.open(EliminarOperacionModalComponent, {

		});

		modalRef.componentInstance.idOperacion = row.id

		modalRef.result.then((result) => {
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe((data) => {
			modalRef.dismiss()
			this.refrescar()
		})

	}

	canReassign() {
		if (this.user.isGerenteGeneral || this.user.isGerenteComercial || this.user.isLiderDeEquipo) {
			// Al poder este perfil ver operaciones de varias estaciones, quiero que solo muestre reasignar a las que estan en oficial de negocio y Lider de Equipo
			return (row) => {
				const estacion = new Estacion(row)
				if (
					estacion.actualOficialNegocio ||
					estacion.actualOficialNegociosPostComite ||
					estacion.actualOficialNegocioLiquidacion ||
					estacion.actualJefeOperaciones ||
					estacion.actualArchivo ||
					estacion.actualAsistenteCobranzas 
				) {
					return true
				}
				return false
			}
		} else if (this.user.isLiderDeTesoreria) {
			// Al poder este perfil ver operaciones de varias estaciones, quiero que solo muestre reasignar a las que estan en tesoreria
			return (row) => {
				const estacion = new Estacion(row)
				return estacion.actualTesoreria
			}
		}else if( this.user.perfil_descripcion === 'Asesor legal' ){
			return (row) => {
				const estacion = new Estacion(row)
				return estacion.actualLegal
			}
			
		}else if( this.user.perfil_descripcion === 'Jefe de Operaciones' ){
			return (row) => {
				const estacion = new Estacion(row)
				return estacion.actualJefeOperaciones
			}
		}
		
		return (row) => true
	}

	showDesembolso(row) {
		return (row.estacion < 10);
	}

	desembolsoEvent(row) {
		console.log(row);
		const modalRef = this.modalService.open(DesembolsoModalComponent, {

		});

		modalRef.result.then(res => {

		});
	}

	get canDelete() {
		const perfilesPermitidos = [
			this.authService.perfil.perfilAdmin,
			this.authService.perfil.perfilOficialDeNegocioID,
			this.authService.perfil.perfilLiderDeEquipo,	
			this.authService.perfil.perfilGerenciaComercialID,
			this.authService.perfil.perfilGerenciaGeneralID,
			this.authService.perfil.perfilAnalistaRiesgosID,
			this.authService.perfil.perfilJefeDeAdmisionID,
			this.authService.perfil.perfilAsesorLegalID,
			this.authService.perfil.perfilLegalID,
			this.authService.perfil.perfilJefeDeOperacionesID,
			this.authService.perfil.perfilAnalistaOperacionesID,
			this.authService.perfil.perfilSubGerenciaComercialID,
			this.authService.perfil.perfilTesoreriaID,
			this.authService.perfil.perfilLiderDeTesoreriaID,
			this.authService.perfil.perfilRecepcionID,
			this.authService.perfil.perfilClienteID,
			this.authService.perfil.perfilAnalistaOperacionesID,
			this.authService.perfil.perfilAsistenteCobranzasID,
			this.authService.perfil.perfilAsistenteComercialID,
		]

		return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
	}
}
