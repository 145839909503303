import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../toast/toast.service';
import { FactoringService } from "app/core/services/factoring/factoring.service";
import { catchError } from 'rxjs/operators';
import { forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExcedentesService {

  private apiUrl: string = '';
  public devoluciones: string = 'factoring/solicitudesdevolucion/';
  public devolucionesHistoricoURL: string = 'factoring/solicitudesdevolucion/historico/';
  public estados: string = 'factoring/solicitudesdevolucion/estados/';
  public excedentes: string = 'factoring/excedentes/';
  public comentarios: string = 'factoring/solicitudesdevolucion/comentarios/';
  public detalles: string = 'factoring/solicitudesdevolucion/detalles/';
  public aprobaciones: string = "factoring/solicitudesdevolucion/aprobaciones/";
  public observacionSolicitud: string = "factoring/solicitudesdevolucion/observaciones/";
  public deuda: string = "factoring/beneficiarios/deuda/resumen/";
  public ordenes: string = "factoring/operaciones/ordenes/devolucionexcedentes/";
  public observaciones: string = "factoring/observacionestipo/";

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient,
    public factoring: FactoringService,
  ) {
    this.apiUrl = this.appService.settings.API_base_url
  }

  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show()
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();

    if (mensaje && ok)
      this.toast.success(mensaje);
    if (mensaje && !ok)
      this.toast.warning(mensaje);
  }

  /**
   * Mostros errores recibidos del servidor
   * @param error Error enviado del servidor
   */
  showErrors(error) {
    const err = error.error;

    for (const key in err) {
      if (Object.prototype.hasOwnProperty.call(err, key)) {
        const element = err[key];
        console.log(element);

        if (Array.isArray(element) && element.length) {
          element.forEach(item => {
            this.spinnerOff(item, false);
          });
        } else {
          this.spinnerOff(element, false);
        }

      }
    }
  }
  getDevoluciones(queryParams: object = {}) {
    const url = `${this.apiUrl}factoring/solicitudesdevolucion/?${this.encodeQueryParams(queryParams)}`
    return this.http.get(url).pipe(
    catchError(res => this.handleError(res))
    )
  }
  private handleError(response) {
      let error = 'Ocurrió un error inesperado'
      if (response.error && typeof (response.error) == 'object') {
          error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
      }
      this.toast.warning(error)
      return throwError(response)
  }
  private encodeQueryParams(queryParams) {
    return Object.entries(queryParams).map((e: any) => `${encodeURIComponent(e[0])}=${encodeURIComponent(e[1])}`).join('&')
}


  /**
   * 
   * @param page 
   * @param page_size 
   */
  obtenerEstados(
    page: number = 1,
    page_size: number = 1000,
  ) {

    const url = this.apiUrl + this.estados +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }

  obtenerObservaciones(
    page: number = 1,
    page_size: number = 1000,
  ) {

    const url = this.apiUrl + this.observaciones +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }

  /**
   * 
   * @param page 
   * @param page_size 
   * @param cliente__iconstains 
   * @param monto__gte 
   * @param monto__lte 
   * @param fecha_solicitud__gte 
   * @param fecha_solicitud__lte 
   * @param fecha_aprobacion__gte 
   * @param fecha_aprobacion__lte 
   * @param estado 
   */
  obtenerDevoluciones(
    page: number = 1,
    page_size: number = 10,
    cliente__iconstains: string = '',
    monto__gte: string = '',
    monto__lte: string = '',
    fecha_solicitud__gte: string = '',
    fecha_solicitud__lte: string = '',
    fecha_aprobacion__gte: string = '',
    fecha_aprobacion__lte: string = '',
    estado: string = '',
    empresa: string = '',
    aceptante__iconstains: string = '',
    compensacion: string = '',
  ) {

    const url = this.apiUrl + this.devoluciones +
      `?cliente__iconstains=${cliente__iconstains}` +
      `&monto__gte=${monto__gte}` +
      `&monto__lte=${monto__lte}` +
      `&fecha_solicitud__gte=${fecha_solicitud__gte}` +
      `&fecha_solicitud__lte=${fecha_solicitud__lte}` +
      `&fecha_aprobacion__gte=${fecha_aprobacion__gte}` +
      `&fecha_aprobacion__lte=${fecha_aprobacion__lte}` +
      `&estado=${estado}` +
      `&page_size=${page_size}` +
      `&empresa=${empresa}` +
      `&aceptante__iconstains=${aceptante__iconstains}` +
      `&compensacion=${compensacion}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }
  obtenerDevolucionesEspecifica(
    id: number = 1
  ) {

    const url = this.apiUrl + this.devoluciones + id

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }
  obtenerDevolucionesHistorico(
    page: number = 1,
    page_size: number = 10,
    cliente__iconstains: string = '',
    monto__gte: string = '',
    monto__lte: string = '',
    fecha_solicitud__gte: string = '',
    fecha_solicitud__lte: string = '',
    fecha_aprobacion__gte: string = '',
    fecha_aprobacion__lte: string = '',
    estado: string = '',
    empresa: string = '',
    aceptante__iconstains: string = '',
    compensacion: string = '',
    moneda:string = '',
    banco_abono:string = '',
    cuenta_abono:string = ''
  ) {

    const url = this.apiUrl + this.devolucionesHistoricoURL +
      `?cliente__iconstains=${cliente__iconstains}` +
      `&monto__gte=${monto__gte}` +
      `&monto__lte=${monto__lte}` +
      `&fecha_solicitud__gte=${fecha_solicitud__gte}` +
      `&fecha_solicitud__lte=${fecha_solicitud__lte}` +
      `&fecha_aprobacion__gte=${fecha_aprobacion__gte}` +
      `&fecha_aprobacion__lte=${fecha_aprobacion__lte}` +
      `&estado=${estado}` +
      `&page_size=${page_size}` +
      `&empresa=${empresa}` +
      `&aceptante__iconstains=${aceptante__iconstains}` +
      `&compensacion=${compensacion}` +
      `&moneda=${moneda}` +
      `&banco_abono=${banco_abono}`+
      `&cuenta_abono=${cuenta_abono}`+
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }

  getDevolucionesVigentes(queryParams: object = {}) {
    const url = `${this.apiUrl}factoring/solicitudesdevolucion/?${this.encodeQueryParams(queryParams)}`
    return this.http.get(url).pipe(
    catchError(res => this.handleError(res))
    )
  }

  getDevolucionesObservadas(queryParams: object = {}) {
    const url = `${this.apiUrl}factoring/solicitudesdevolucion/?${this.encodeQueryParams(queryParams)}`
    return this.http.get(url).pipe(
    catchError(res => this.handleError(res))
    )
  }

  obtenerDevolucionesVigentes(
    page: number = 1,
    page_size: number = 10,
    cliente__iconstains: string = '',
    monto__gte: string = '',
    monto__lte: string = '',
    fecha_solicitud__gte: string = '',
    fecha_solicitud__lte: string = '',
    fecha_aprobacion__gte: string = '',
    fecha_aprobacion__lte: string = '',
    estado: string = '1',
    empresa: string = '',
    aceptante__iconstains: string = '',
    compensacion: string = '',
  ) {
    const url = this.apiUrl + this.devoluciones +
      `?cliente__iconstains=${cliente__iconstains}` +
      `&monto__gte=${monto__gte}` +
      `&monto__lte=${monto__lte}` +
      `&fecha_solicitud__gte=${fecha_solicitud__gte}` +
      `&fecha_solicitud__lte=${fecha_solicitud__lte}` +
      `&fecha_aprobacion__gte=${fecha_aprobacion__gte}` +
      `&fecha_aprobacion__lte=${fecha_aprobacion__lte}` +
      `&estado=${estado}` +
      `&page_size=${page_size}` +
      `&empresa=${empresa}` +
      `&aceptante__iconstains=${aceptante__iconstains}` +
      `&compensacion=${compensacion}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }

  obtenerDevolucionesObservadas(
    page: number = 1,
    page_size: number = 10,
    cliente__iconstains: string = '',
    monto__gte: string = '',
    monto__lte: string = '',
    fecha_solicitud__gte: string = '',
    fecha_solicitud__lte: string = '',
    fecha_aprobacion__gte: string = '',
    fecha_aprobacion__lte: string = '',
    estado: string = '6',
    empresa: string = '',
    aceptante__iconstains: string = '',
    compensacion: string = '',
    observaciones: string = ''
  ) {

    const url = this.apiUrl + this.devoluciones +
      `?cliente__iconstains=${cliente__iconstains}` +
      `&monto__gte=${monto__gte}` +
      `&monto__lte=${monto__lte}` +
      `&fecha_solicitud__gte=${fecha_solicitud__gte}` +
      `&fecha_solicitud__lte=${fecha_solicitud__lte}` +
      `&fecha_aprobacion__gte=${fecha_aprobacion__gte}` +
      `&fecha_aprobacion__lte=${fecha_aprobacion__lte}` +
      `&estado=${estado}` +
      `&page_size=${page_size}` +
      `&empresa=${empresa}` +
      `&aceptante__iconstains=${aceptante__iconstains}` +
      `&compensacion=${compensacion}` +
      `&page=${page}`+
      `&observaciones=${observaciones}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }

  /**
   * Obtener una solicitud de devolucion dado el ID
   * @param devolucionId ID de la solicitud
   */
  obtenerDevolucion(devolucionId) {

    const url = this.apiUrl + this.devoluciones + `${devolucionId}/`

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }

  /**
   * Crea o edita una solicitud dependiente si se pasa o no el id
   * @param data 
   * @param devolucionId 
   */
  crearDevolucion(data, devolucionId = 0) {
    // console.log(data, devolucionId)
    const url = devolucionId
      ? this.apiUrl + this.devoluciones + `${devolucionId}/`
      : this.apiUrl + this.devoluciones;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (devolucionId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff('Se ha editado la devolución exitosamente');
            res(response);
          },
          (err) => {
            if(err.error.fecha_contable){
              this.spinnerOff(err.error.fecha_contable, false);
            }else{

              this.spinnerOff("La operación falló", false);
            }
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff('Se ha solicitado la devolución exitosamente');
            res(response);
          },
          (err) => {
            if(err.error.fecha_contable){

              this.spinnerOff(err.error.fecha_contable, false);

            }else{

              this.spinnerOff("La operación falló", false);
            }
            ref(err);
          }
        );
      }
    });

  }

  /**
   * 
   * @param cliente 
   * @param page 
   * @param page_size 
   */
  obtenerExcedentes(
    cliente: string = '',
    estado: string = '',
    page: number = 1,
    page_size: number = 1000,
    excluir_devoluciones: boolean = false,
    saldo__gt: number = undefined,
    empresa: string = '',
    aceptante: string = '',
    tipoAceptante: boolean = false,
  ) {

    const url = this.apiUrl + this.excedentes +
      `?cliente=${cliente || ''}` +
      `&aceptante=${aceptante || ''}` +
      (aceptante && tipoAceptante ? `&cliente__isnull=true` : '') +
      `&estado=${estado}` +
      `&excluir_devoluciones=${excluir_devoluciones}` +
      `&saldo__gt=${saldo__gt != undefined ? saldo__gt : ''}` +
      `&empresa=${empresa}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }

  obtenerDetalles(
    solicitud_devolucion: string = '',
    excedente: string = '',
    page: number = 1,
    page_size: number = 1000,
  ) {

    const url = this.apiUrl + this.detalles +
      `?solicitud_devolucion=${solicitud_devolucion}` +
      `&excedente=${excedente}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }

  /**
   * 
   * @param solicitud_devolucion 
   * @param responsable 
   * @param page 
   * @param page_size 
   */
  obtenerComentarios(
    solicitud_devolucion: string = '',
    responsable: string = '',
    page: number = 1,
    page_size: number = 1000,
  ) {

    const url = this.apiUrl + this.comentarios +
      `?solicitud_devolucion=${solicitud_devolucion}` +
      `&responsable=${responsable}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }

  /**
   * Crea o edita un comentario condicionando la accion por el id
   * @param data 
   * @param commentId 
   */
  crearComentario(data, commentId = 0) {
    const url = commentId
      ? this.apiUrl + this.comentarios + `${commentId}/`
      : this.apiUrl + this.comentarios;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (commentId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });

  }

  eliminarComentario(commentId) {
    const url = this.apiUrl + this.comentarios + `/${commentId}/`

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  obtenerAprobaciones(
    solicitud_devolucion: string = '',
    responsable: string = '',
    aprobador: string = '',
    page: number = 1,
    page_size: number = 1000,
  ) {

    const url = this.apiUrl + this.aprobaciones +
      `?solicitud_devolucion=${solicitud_devolucion}` +
      `&responsable=${responsable}` +
      `&aprobador=${aprobador}` +
      `&page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });

  }

  aprobarSolicitudDevolucion(data) {
    const url = this.apiUrl + this.aprobaciones;
    // console.log(data)
    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.post(url, data)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  crearSolicitudObservaciones(data) {
    const url = this.apiUrl + this.observacionSolicitud;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.showErrors(err);
          ref(err);
        });
    });
  }

  obtenerDeudaBeneficiario(beneficiarioId) {
    const url = this.apiUrl + this.deuda + `${beneficiarioId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  obtenerDeudaDeudor(deudorId) {
    const url = this.apiUrl + 'factoring/deudores/deuda/resumen/' + `${deudorId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  obtenerOrdenDePago(solicitud_devolucion) {
    const url = this.apiUrl + this.ordenes
      + `?solicitud_devolucion=${solicitud_devolucion}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  async crearOrdenPago(data, ordenId) {
    const apiBase = await this.factoring.validarPesoAdjunto(data.get('peso'), this.apiUrl);
    const url = apiBase + this.ordenes + `${ordenId}/`;
    data.delete('peso');
    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.put(url, data)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }


  modificarPropiedadesDeExcedente(body, id) {
    const url = this.apiUrl +
      this.excedentes + `${id}/`;

    this.spinnerOn()
    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.patch(url, body)
        .subscribe((response) => {
          this.spinnerOff('La operación fue realizada con éxito');
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }


  obtenerCompensaciones(
    page: number = 1,
    page_size: number = 10,
    empresa = 1,
    propietario: number = 1,
    filtroRucNombre: string = ''
  ) {

    const url = this.apiUrl + this.excedentes + `aplicaciones/pendientes/` +
      `?page=${page}` +
      `&page_size=${page_size}` +
      `&empresa=${empresa}` +
      `&propietario=${propietario}` +
      `&ruc_nombre__icontains=${filtroRucNombre}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          ref(err);
        });
    });
  }

  enviarAplicaciones(data, sunat) {
    // enviar_facturar: (0)No envía a sunat, (1) envía a sunat
    const url = this.apiUrl + this.excedentes + `aplicaciones/pendientes/` +
      `?enviar_facturar=${sunat}`

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.post(url, data)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  saveExcepcionDeudaLeasing(devolucion_id, data) {
    const url = `${this.apiUrl}factoring/solicitudesdevolucion/${devolucion_id}/`
    return this.http.patch(url, data).pipe(
        catchError(response => {
            let error = 'Ocurrió un error inesperado'
            if (response.error && typeof (response.error) == 'object') {
                error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
            }
            this.toast.warning(error)
            return of(false)
        })
      )
  }

  getTotalDesembolsos(queryParams) {
    const url = `${this.apiUrl}factoring/solicitudesdevolucion/total-desembolsos/?${this.encodeQueryParams(queryParams)}`
    return this.http.get(url).pipe(
        catchError(response => {
            let error = 'Ocurrió un error inesperado'
            if (response.error && typeof (response.error) == 'object') {
                error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
            }
            this.toast.warning(error)
            return throwError(response)
        })
      )
  }
}
