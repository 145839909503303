import { invalid } from '@angular/compiler/src/render3/view/util';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { ContactoPoderesService } from 'app/core/services/poderes/contacto-poderes.service';
import { SharedFunctions } from 'app/core/shared/functions';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent implements OnInit {
  faTimes=faTimes;
  active             = 1;
  myForm             : FormGroup;
  @Input() clienteId = 0;
  paises             : any[];
  @Input() contactId = 0;
  contacto           : any;
  poderesContacto    = [];
  newPoderesEdit     = 0;
  departamentos      : any[] = [];
  provincias         : any[] = [];
  distritos          : any[] = [];
  tiposDocumentos    : any[] = [];
  tiposEndeudamientos: any[] = [];
  tiposInscripcion:  any[] = [];
  tiposConservador:  any[] = [];
  showButtom          = true;
  emailValido = true;
  estadosCiviles: any[] = [];
  public generos = [
    { key: "F", nombre: "Femenino" },
    { key: "M", nombre: "Masculino" },
  ];

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild              : FormBuilder,
    public activeModal            : NgbActiveModal,
    private authService           : AuthService,
    private clientesService       : ClientesService,
    private contactoPoderesService: ContactoPoderesService,
    public sharedFunctions        : SharedFunctions
  ) {
    this.initForm();
  }

  ngOnInit(): void {

    this.loadData();

    if (this.contactId) {
      this.obtenerContacto();
    }
    
  }

  swtichRepLegal(event){
    if(event && this.myForm.get('firmante').value){
      this.myForm.get('firmante').setValue(false)
    }
  }

  switchFirmante(event){
    if(event && this.myForm.get('representante_legal').value){
      this.myForm.get('representante_legal').setValue(false)
    }
  }

  loadData(){
    let paises = this.clientesService.obtenerPaises();
    let departamentos = this.clientesService.obtenerDepartamentos();
    let tiposDocumentos = this.clientesService.obtenerTiposDocumentos();
    let tiposEndeudamientos = this.clientesService.obtenerTiposEndeudamientos();
    let tiposInscripcion = this.clientesService.obtenerTipoInscripcion();
    let tipoConservador = this.clientesService.obtenerTipoConservador();
    let estadosCiviles = this.clientesService.estadosCiviles();

    Promise.all([
      paises,
      departamentos,
      tiposDocumentos,
      tiposEndeudamientos,
      tiposInscripcion,
      tipoConservador,
      estadosCiviles
    ]).then((res:any[])=>{
      this.paises          = res[0]['results']
      this.departamentos   = res[1]['results']
      this.tiposInscripcion   = res[4]['results']
      this.tiposConservador   = res[5]['results']
      this.estadosCiviles = res[6]['results'];

      this.tiposDocumentos = res[2]['results'].sort(function (a, b) {
        if (a.nombre > b.nombre) {
          return 1;
        }
        if (a.nombre < b.nombre) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
      
      this.tiposEndeudamientos = res[3]['results']    
      
    }).catch((e)=>{
      console.log('Hubo un error en el modal de contactos')
    })
  }

  obtenerContacto() {
    let contactos = this.clientesService.obtenerContacto(this.contactId);
    contactos.then(res => {
      this.contacto = res;
      this.setForm(this.contacto);

    }).catch(error => {

    });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre                      : [null, [Validators.required]],
      email                       : [null, [Validators.required, Validators.email]],
      telefono                    : [null, []],
      cargo                       : [null, []],
      area                        : [null, []],
      dni                         : [null, [Validators.required]],
      lugar_firma                 : [null, []],
      vcto_firma                  : [null, []],
      observaciones               : [null, []],
      poderes                     : [null, []],
      representante_legal         : [false,[]],
      firmante                    : [false,[]],
      nacionalidad                : [null, []],
      estado_civil                : [null, [Validators.required]],
      sexo                        : [null, [Validators.required]],
      gentilicio                  : [null, [Validators.required]],

      ruc                         : [null, []],
      apellido_paterno            : [null, []],
      apellido_materno            : [null, []],
      primer_nombre               : [null, []],
      segundo_nombre              : [null, []],
      direccion                   : [null, []],
      ciuu                        : [null, []],
      
      tipo_condicion_endeudamiento: [null, []],
      departamento                : [null, []],
      provincia                   : [null, []],
      distrito                    : [null, []],
      tipo_documento              : [null, [Validators.required]],
      cliente                     : [null, []],
      ocupacion_codigo            : [null, []],
      grupo_economico             : [null, []],

      fecha_escritura             : [null, []],
      inicio_firma                : [null, []],

      tipo_inscripcion            : [null, []],
      tipo_conservador            : [null, []],
      observaciones_legal            : [null, []],
    });


    // Se elimina la validacion de representante legal por configuraciones
    // en la tarjeta PRO-261

    this.myForm.controls['representante_legal'].valueChanges.subscribe(value => {
      this.showButtom = false;
      if (value) {
        this.myForm.controls['nombre'].setValidators(Validators.required);
      } else {
        this.myForm.controls['nombre'].clearValidators();
        this.myForm.controls['cargo'].clearValidators();
      }

      this.myForm.controls['nombre'].updateValueAndValidity()
      this.myForm.controls['cargo'].updateValueAndValidity()
      
      this.showButtom = true;
    });

    this.myForm.get('departamento').valueChanges.subscribe((value)=>{
      if(value){
        console.log('Valor cambio')
        this.getProvincias(value)
      }
    });

    this.myForm.get('provincia').valueChanges.subscribe((value)=>{
      if(value){
        this.getDistrito(value)
      }
    });

    // this.myForm.get('provincia').disable()
    // this.myForm.get('distrito').disable()
  }

  getProvincias(departamento){
    this.clientesService.obtenerProvincias(departamento).then((res:any)=>{
      this.provincias = res['results']
      this.myForm.get('provincia').enable()
    })
  }

  getDistrito(provincia){
    this.clientesService.obtenerDistritos(provincia).then((res:any)=>{
      this.distritos = res['results']
      this.myForm.get('distrito').enable()
    })
  }

  setForm(contact) {
    for (const key in contact) {
      if (this.myForm.controls[key]) {

        switch(key){
          case 'fecha_escritura':
            this.myForm.controls[key].setValue(this.parseDate(contact[key]));
            continue;
          break;
          case 'inicio_firma':
            this.myForm.controls[key].setValue(this.parseDate(contact[key]));
            continue;
          break;
          case 'vcto_firma':
            this.myForm.controls[key].setValue(this.parseDate(contact[key]));
            continue;
          break;
          case 'provincia':
            if(contact[key]){
              this.myForm.get('provincia').enable()
              this.getProvincias(contact.departamento)
              this.myForm.controls[key].setValue(contact[key]);
            }
            continue;
          break;
          case 'distrito':
            if(contact[key]){
              this.myForm.get('distrito').enable()
              this.getDistrito(contact.provincia)
              this.myForm.controls[key].setValue(contact[key]);
            }
            continue;
          break;          
        }

        this.myForm.controls[key].setValue(contact[key]);
      }
        
    }
  }

  parseDate(date) {
    if (!date)
      return null;
    
    let dateNow = new Date();
    let yy = date.split('-')[0];
    let mm = (date.split('-')[1] - 1);
    // let dd = date.split('-')[2];
    let dd = (date.split('-')[2]).substring(0,2);

    let d = new Date(yy, mm, dd, dateNow.getHours());
    
    return d;
  }

  onSavePoder(event) {
    this.poderesContacto = event;
  }

  onNewPoderes(event) {
    this.newPoderesEdit = event;
  }

  changeValidators(e) {
    if (e) {
      this.myForm.controls['fecha_escritura'].setValidators(Validators.required);
      this.myForm.controls['tipo_inscripcion'].setValidators(Validators.required);
      this.myForm.controls['tipo_conservador'].setValidators(Validators.required);
      this.myForm.controls['inicio_firma'].setValidators(Validators.required);
    } else {
      this.myForm.controls['fecha_escritura'].clearValidators();
      this.myForm.controls['tipo_inscripcion'].clearValidators();
      this.myForm.controls['tipo_conservador'].clearValidators();
      this.myForm.controls['inicio_firma'].clearValidators();
    }
    
  }
  validarEmail(e){
    let email = e.target.value;
    this.emailValido = email ? this.sharedFunctions.validateEmail(email) : true;
  }

  onSubmit() {
    if (this.myForm.valid) {
      console.log(this.myForm.value)
      var data = this.myForm.value;
      // data.fecha_escritura = this.parseDate(data.fecha_escritura)
      // data.fecha_inicio = this.parseDate(data.fecha_inicio)

      data['cliente'] = this.clienteId;

      this.clientesService.guardarContacto(this.myForm.value, this.contactId)
        .then((res: any) => {

          this.poderesContacto.forEach(x => {
            x.contacto = res.id
          });

          if (this.newPoderesEdit == 1) {
            this.contactoPoderesService.guardar(this.poderesContacto).then(x => {
            });
          }
          else {
            this.contactoPoderesService.guardar(this.poderesContacto, res.id).then(x => {
            });
          }


          this.successEmit.emit();
          this.activeModal.close(false);
        }).
        catch(error => {
          this.activeModal.close(false);
        });
    }
  }

  get readonlyOperaciones() {
    const u = this.authService.user
    return !(u.isJefeDeOperaciones || u.isAnalistaDeOperaciones || u.isOficialDeNegocio || u.isGerenteComercial || u.isGerenteGeneral || u.isAsesorLegal)
  }

}
