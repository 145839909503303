import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { AuthService } from "app/core/services/auth/auth.service";
import { AceptantesService } from "app/core/services/factoring/aceptantes.service";
import { ConfirmModalComponent } from "app/shared/utils/confirm-modal/confirm-modal.component";
import { AddEntidadVinculoComponent } from "app/modules/clientes/modals/add-entidad-vinculo/add-entidad-vinculo.component";

@Component({
  selector: "app-add-aceptante-links-companies",
  templateUrl: "./links-companies.component.html",
  styleUrls: ["./links-companies.component.css"],
})
export class LinksCompaniesComponent implements OnInit {
  public user: any;
  public rowDataTop: any[] = [];
  public loadingTableTop: boolean = false;
  public columnDefsTop: columnHeader[];
  public pageTableActualTop: number;
  public pageTableTotalTop: number;
  public pageTablePerPagesTop: number = 10;
  public pageTablePagesTop: number[];
  public searchstringTop: string = "";

  public rowDataBottom: any[] = [];
  public loadingTableBottom: boolean = false;
  public columnDefsBottom: columnHeader[];
  public pageTableActualBottom: number;
  public pageTableTotalBottom: number;
  public pageTablePerPagesBottom: number = 10;
  public pageTablePagesBottom: number[];
  public searchstringBottom: string = "";

  @Input() aceptanteId = 0;

  constructor(
    private modalService: NgbModal,
    public localeService: BsLocaleService,
    private aceptantesService: AceptantesService,
    private authService: AuthService
  ) {
    defineLocale("es", esLocale);
    this.localeService.use("es");
    this.user = this.authService.user;
  }

  ngOnInit(): void {
    this.obtenerVinculos();
  }

  obtenerVinculos() {
    // this.aceptantesService
    //   .obtenerVinculos(this.aceptanteId.toString())
    //   .then((res) => {
    //     this.initVars();
    //     this.loadTableTop(res);
    //     this.loadTableBottom(res);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  }

  loadTableTop(data) {
    this.rowDataTop = data.results.filter((item) => item.directo);
    this.pageTableActualTop = data.page_number;
    this.pageTableTotalTop = data.count;
    this.pageTablePerPagesTop = data.per_page;
    this.pageTablePagesTop = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePagesTop.push(index);
    }
  }

  loadTableBottom(data) {
    this.rowDataBottom = data.results.filter((item) => !item.directo);
    this.pageTableActualBottom = data.page_number;
    this.pageTableTotalBottom = data.count;
    this.pageTablePerPagesBottom = data.per_page;
    this.pageTablePagesBottom = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePagesBottom.push(index);
    }
  }

  initVars() {
    let headerColumTop = [
      {
        headerName: "N° Doc.",
        field: "id",
        sortable: true,
      },
      {
        headerName: "Razón Social",
        field: "nombre",
        sortable: true,
      },
      {
        headerName: "Tipo de Persona",
        field: "tipo_persona_nombre",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Doc. Ident-RUC",
        field: "dni_ruc",
        sortable: true,
      },
      {
        headerName: "Aportes(Soles)",
        field: "aporte",
        class: "text-center",
      },
      {
        headerName: "Participacion(Part.%)",
        field: "participacion",
        class: "text-center",
      },
      {
        headerName: "Gestión(Si-No)",
        field: "gestion",
        sortable: true,
        pipe: "checkbox",
        checkDisabled: true,
      },
      {
        headerName: "Cód. SBS",
        field: "codigo_sbs",
        sortable: true,
        class: "text-center",
      },
    ];

    let headerColumnBottom = [
      {
        headerName: "N° Doc.",
        field: "id",
        sortable: true,
      },
      {
        headerName: "Razón Social",
        field: "nombre",
        sortable: true,
      },
      {
        headerName: "Tipo de Persona",
        field: "tipo_persona_nombre",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Doc. Ident-RUC",
        field: "dni_ruc",
        sortable: true,
      },
      {
        headerName: "Aportes(Soles)",
        field: "aporte",
        class: "text-center",
      },
      {
        headerName: "Participacion(Part.%)",
        field: "participacion",
        class: "text-center",
      },
      {
        headerName: "Gestión(Si-No)",
        field: "gestion",
        sortable: true,
        pipe: "checkbox",
        checkDisabled: true,
      },
      {
        headerName: "Cód. SBS",
        field: "codigo_sbs",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Cargo",
        field: "cargo",
        sortable: true,
        class: "text-center",
      },
    ];

    this.columnDefsTop = headerColumTop;
    this.columnDefsBottom = headerColumnBottom;
  }

  goToPage({ page, per_page, filtros }) {
    this.obtenerVinculos();
  }

  tableEditEvent(vinculo) {
    const modalRef = this.modalService.open(AddEntidadVinculoComponent, {
      size: "lg",
    });

    modalRef.componentInstance.aceptanteId = this.aceptanteId;
    modalRef.componentInstance.vinculoId = vinculo.id;
    modalRef.componentInstance.directo = vinculo.directo;

    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {
        console.log(reason);
      }
    );

    modalRef.componentInstance.successEmit.subscribe(
      (result) => {
        console.log(result);
        if (result.directo) {
          this.obtenerVinculos();
        } else {
        }
      },
      (error) => {}
    );
  }

  destroyContact(director) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {});

    modalRef.componentInstance.title = "Remover Director";
    modalRef.componentInstance.message =
      "¿Seguro que desea remover el Director?";

    modalRef.result.then(
      (result) => {
        if (result) {
          // this.aceptantesService
          //   .eliminarVinculo(director.id)
          //   .then((result: any) => {
          //     this.obtenerVinculos();
          //   })
          //   .catch((error) => {
          //     console.error(error);
          //   });
        }
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  openModal(directo = false) {
    const modalRef = this.modalService.open(AddEntidadVinculoComponent, {
      size: "lg",
    });

    modalRef.componentInstance.aceptanteId = this.aceptanteId;
    modalRef.componentInstance.directo = directo;

    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {
        console.log(reason);
      }
    );

    modalRef.componentInstance.successEmit.subscribe(
      (result) => {
        console.log(result);
        if (result.directo) {
          this.obtenerVinculos();
        } else {
        }
      },
      (error) => {}
    );
  }
}
