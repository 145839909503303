import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { ManageComiteIntegrantesModalComponent } from '../manage-comite-integrantes-modal/manage-comite-integrantes-modal.component';
import { ManageComiteIntegrantesOrdenModalComponent } from '../manage-comite-integrantes-orden-modal/manage-comite-integrantes-orden-modal.component';

@Component({
  selector: 'app-manage-comite-modal',
  templateUrl: './manage-comite-modal.component.html',
  styleUrls: ['./manage-comite-modal.component.css']
})
export class ManageComiteModalComponent implements OnInit {
  faPlus=faPlus;
  myForm: FormGroup;
  @Input() comiteId = 0;
  comite: any;
  faTimes = faTimes;
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public user             : any;
	public identidad        : any     = {};
	public searchstring     : string = '';
	public canCreate: boolean = false;

  constructor(
    public formbuild              : FormBuilder,
    public activeModal            : NgbActiveModal,
    private configurationService	: ConfigurationService,
    public modalService           : NgbModal,
  ) { 
    this.initForm();
  }

  ngOnInit(): void {
    if (this.comiteId) {
      this.obtenerComite();
    }
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre: [null, [Validators.required]],
      monto_inicial: [null, [Validators.required]],
      prorroga_inicial: [null, [Validators.required]],
    });
  }
  
  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  obtenerComite() {
    this.configurationService.obtenerComite(this.comiteId)
    .then(res => {
      this.comite = res;
      this.setForm(res);
      this.obtenerIntegrantes();
    })
    .catch(error => {
      console.error(error);
    });
  }

  obtenerIntegrantes() {
    this.configurationService.comitesIntegrantes(this.comiteId)
      .then(res => {
        console.log(res);
        this.initVars();
        this.loadTable(res);
      }).catch(err => {
        console.error(err);
        
      })
  }

  onSubmit() {
    if(this.myForm.valid) {
      const data = {
        ...this.myForm.value,
        moneda: 2
      };

      this.configurationService.guardarComite(data, this.comiteId)
      .then(res => {
        this.successEmit.emit();
        this.activeModal.close(true);
      }).
      catch(error => {
        this.activeModal.close(false);
      });
    }
  }

  loadTable(data) {
    this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }
    
  initVars() {
    let columns = [
      {
        headerName: 'N°', 
        field     : 'id' ,
        sortable  : true,
        pipe      : 'indexcol'
      },
      {
        headerName: 'Orden', 
        field     : 'orden' ,
        sortable  : true,
      },
      {
        headerName: 'Integrante', 
        field     : 'integrante_nombre',
        class     : 'text-center',
        sortable  : true,
      },
    ];
  
    this.columnDefs = columns;
  }

  addIntegrante() {
    const modalRef = this.modalService.open(ManageComiteIntegrantesModalComponent, {
    });

    modalRef.componentInstance.comiteId = this.comiteId;
    modalRef.componentInstance.integrantes = this.rowData;

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			
      this.configurationService.addIntegrantesComite(result)
      .then(res => {

        this.obtenerIntegrantes();

      }).catch(err => {
        console.error(err);
        
      })

		},
		error => {

		});
  }

  tableEditEvent(row) {

  }

  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Integrante';
		modalRef.componentInstance.message = '¿Seguro que desea remover este Integrante?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.configurationService.elimiarIntegrante(row.id)
			.then(result => {
			this.obtenerIntegrantes();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  
  editIntegrante(row) {
    const modalRef = this.modalService.open(ManageComiteIntegrantesOrdenModalComponent, {
    });
    console.log("ROW", row)
    modalRef.componentInstance.comiteId = this.comiteId;
    modalRef.componentInstance.integrante = row.integrante_nombre;
    modalRef.componentInstance.orden = row.orden;
    
    modalRef.componentInstance.successEmit.subscribe(result => {    
      this.configurationService.editIntegrantesComite(row.id, result)
        .then(res => {
          this.obtenerIntegrantes();
        }).catch(err => {
        })
    }, error => {

    });
  }
}
