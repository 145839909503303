import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { AddPagareComponent } from '../../modals/add-pagare/add-pagare.component';

@Component({
  selector: 'app-documentosderespaldo',
  templateUrl: './documentosderespaldo.component.html',
  styleUrls: ['./documentosderespaldo.component.css']
})
export class DocumentosderespaldoComponent implements OnInit {
  @Input() clienteId = 0;
  @Input() readonly = false;

  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public searchstring     : string = '';
  
  constructor(
    public formbuild        : FormBuilder,
    private clientesService	: ClientesService,
    private router          : Router,
    public toast            : ToastService,
    public modalService     : NgbModal, 
  ) { }

  ngOnInit(): void {
    this.obtenerPagares();
  }

  obtenerPagares(page = 1, page_size = 10) {
    let clientes = this.clientesService.obtenerPagares(this.clienteId.toString(),page, page_size);

    Promise.all([clientes]).then(res => {
      console.log(res);

      let clietes = res[0];

      this.initVars();
      this.loadTable(clietes)
    }).catch(err => {
      console.error(err);
      
    });
  }

  loadTable(data) {

		this.rowData           = data.results;
		this.pageTableActual   = data.page_number;
		this.pageTableTotal    = data.count;
		this.pageTablePerPages = data.per_page;
		this.pageTablePages = [];
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index);
		}

	}

	initVars() {
		let columns = [
			{
				headerName: '', 
				field     : '' ,
				sortable  : true,
			},
			// {
			// 	headerName: 'RUC', 
			// 	field     : 'cliente_ruc' ,
			// 	sortable  : true,
			// },
			{
				headerName: 'Número de documento', 
				field     : 'numero_documento' ,
				sortable  : true,
			},
			{
				headerName: 'Monto', 
				field     : 'monto' ,
				sortable  : true,
				class     : 'text-center',
				pipe      : 'currency',
				moneda    : 'moneda'
			},
			{
				headerName: 'Fecha de Prospecto', 
				field     : 'fecha_protesto' ,
				sortable  : true,
				class     : 'text-center',
        		pipe      : 'date'
			},
      		{
				headerName: 'Fecha Operativa', 
				field     : 'fecha_operativa',
				sortable  : true,
				class     : 'text-center',
       			pipe      : 'date'
			},
    		{
				headerName: 'Fecha de Vcto', 
				field     : 'fecha_vencimiento',
				sortable  : true,
				class     : 'text-center',
        		pipe      : 'date'
			},
      		{
				headerName: 'Fecha de Toma', 
				field     : 'fecha_toma',
				sortable  : true,
				class     : 'text-center',
        		pipe      : 'date'
			},
      		{
				headerName: 'Tipo de Documento',
				field     : 'tipo_documento_descripcion',
				class     : 'text-center',
				sortable  : true,
			},
      		{
				headerName: 'Tipo Pagare',
				field     : 'tipo_pagare_descripcion' ,
				sortable  : true,
			},
      		{
				headerName: 'Custodia',
				field     : 'custodia_descripcion',
				class     : 'text-center',
				sortable  : true,
			},
			{
				headerName: 'Tipo Emisión',
				field     : 'tipo_emision_descripcion' ,
				sortable  : true,
			},
      		{
				headerName: 'Tipo Emisor',
				field     : 'tipo_emisor_descripcion' ,
				sortable  : true,
			},
			{
				headerName: 'Marca',
				field     : 'marca' ,
				sortable  : true,
			},
			{
				headerName: 'Modelo',
				field     : 'modelo' ,
				sortable  : true,
			},
      		{
				headerName: 'Categoría',
				field     : 'categoria' ,
				sortable  : true,
			},
			{
				headerName: 'Color',
				field     : 'color' ,
				sortable  : true,
			},
			{
				headerName: 'Año',
				field     : 'anio' ,
				sortable  : true,
			},
      		{
				headerName: 'Placa',
				field     : 'placa' ,
				sortable  : true,
			},
		];

		this.columnDefs = columns;
	}

	openModal() {
		const modalRef = this.modalService.open(AddPagareComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerPagares();
		},
		error => {

		});
	}

	tableEditEvent(row) {
		const modalRef = this.modalService.open(AddPagareComponent, {
			size: 'lg'
		});

		modalRef.componentInstance.clienteId = this.clienteId;
		modalRef.componentInstance.pagareId = row.id;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerPagares();
		},
		error => {

		});
	}

	destroy(row) {
		const modalRef = this.modalService.open(ConfirmModalComponent, {
			
		});

		modalRef.componentInstance.title = 'Remover Pagaré';
		modalRef.componentInstance.message = '¿Seguro que desea remover el Pagaré?';

		modalRef.result.then((result) => {
		if (result) {
			this.clientesService.eliminarPagare(row.id)
			.then(result => {
			this.obtenerPagares();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
	}

  	goToPage({page,per_page,filtros}) {
		this.obtenerPagares(page, per_page);
	}

}
