import { Component, OnInit } from '@angular/core';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { ManageTipodetallesModalComponent } from 'app/modules/configuration/modals/manage-tipodetalles-modal/manage-tipodetalles-modal.component';
import { columnHeader } from '../factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from '../utils/confirm-modal/confirm-modal.component';
import { functions } from '../../core/helpers/functions';

@Component({
  selector: 'app-config-tipos-docs',
  templateUrl: './config-tipos-docs.component.html',
  styleUrls: ['./config-tipos-docs.component.css']
})
export class ConfigTiposDocsComponent implements OnInit {
  faPlus=faPlus;
  faChartPie=faChartPie;
  faSyncAlt=faSyncAlt;
  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public user             : any;
	public identidad        : any     = {};
	public searchstring     : string = '';
	public canCreate: boolean = false;

  constructor(
    private configurationService  : ConfigurationService,
    public modalService           : NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerTiposEvaluacion();
  }

  obtenerTiposEvaluacion(page = 1, page_size = 10) {
    let evaluaciones = this.configurationService.obtenerTiposDetalles(page, page_size);

    Promise.all([evaluaciones]).then((res :any[])=>{
      let evaluaciones  = res[0]
      this.initVars();
      this.loadTable(evaluaciones);
    });
  }

  loadTable(data) {

    this.rowData = functions.indextable( data )
    // this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }
    
  initVars() {
    let columns = [
      {
        headerName: 'N°', 
        field     : 'index' ,
        sortable  : true,
      },
      {
        headerName: 'Descripción', 
        field     : 'descripcion',
        class     : 'text-center',
        sortable  : true,
        // filterable: true,
        // filterProp: 'descripcion',
        // filterInput: true,
      },
    ];
  
    this.columnDefs = columns;
  }

  crear() {
    const modalRef = this.modalService.open(ManageTipodetallesModalComponent, {
    });

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerTiposEvaluacion();
		},
		error => {

		});
  }

  tableEditEvent(row) {
    const modalRef = this.modalService.open(ManageTipodetallesModalComponent, {
    });

    modalRef.componentInstance.evaluacionId = row.id;

    modalRef.componentInstance

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			this.obtenerTiposEvaluacion();
		},
		error => {

		});
  }

  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Tipo de Detalle';
		modalRef.componentInstance.message = '¿Seguro que desea remover el Tipo de Detalle?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.configurationService.eliminarDetalle(row.id)
			.then(result => {
			this.obtenerTiposEvaluacion();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  goToPage({page,per_page,filtros}) {
    this.obtenerTiposEvaluacion(page, per_page);
  }

}
