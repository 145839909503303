import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DocumentosChecklistComponent } from './pages/documentos-checklist/documentos-checklist.component';
import { RegistrarSolicitudFactoringComponent } from './pages/registrar-solicitud-factoring/registrar-solicitud-factoring.component';
import { BandejaDenegadosComponent } from '../aplicacion-evaluacion-riesgos/pages/bandeja-denegados/bandeja-denegados.component';
import { AdminLayoutComponent } from '../../layouts/admin/admin-layout.component';
import { CompletarSolicitudComponent } from './pages/completar-solicitud/completar-solicitud.component';
import { FactoringComponent } from '../aplicacion-evaluacion-riesgos/pages/factoring/factoring.component';
import { FormatosComponent } from 'app/shared/formatos/formatos.component';
import { AuthGuard } from 'app/core/guards/auth.guard';




const routes: Routes = [ 
    {
        path: '',
        component: AdminLayoutComponent,
        data: {
            breadcrumb: 'Autenticacion'
        },
        children: [
            {
                path: 'operaciones',
                canActivate: [AuthGuard],
                component: FactoringComponent,
                data: {
                    breadcrumb: 'Operaciones de Factoring'
                }
            },
            {
                path: 'doc-checklist',
                canActivate: [AuthGuard],
                component: DocumentosChecklistComponent,
                data: {
                    breadcrumb: 'Checklist de documentos'
                }
            },
            {
                path: 'formatos',
                canActivate: [AuthGuard],
                component: FormatosComponent,
                data: {
                    breadcrumb: 'Formatos'
                }
            },         
            {
                path: 'registrar-solicitud-factoring',
                canActivate: [AuthGuard],
                component: RegistrarSolicitudFactoringComponent,
                data: {
                    breadcrumb: 'Registrar Solicitud de Factoring'
                }
            },   
            {
                path: 'registrar-solicitud-factoring/:id',
                canActivate: [AuthGuard],
                component: RegistrarSolicitudFactoringComponent,
                data: {
                    breadcrumb: 'Registrar Solicitud de Factoring'
                }
            },
            {
                path: 'denegados',
                canActivate: [AuthGuard],
                component: BandejaDenegadosComponent,
                data: {
                    breadcrumb: 'Bandeja Denegados'
                }
            },   
            {
                path: 'denegados/:id',
                canActivate: [AuthGuard],
                component: CompletarSolicitudComponent,
                data: {
                    breadcrumb: 'Bandeja Denegados'
                }
            },   
        ]
    },    
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConfigRoutingModule { }
