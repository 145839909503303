import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { AddEjecutivoComponent } from 'app/modules/clientes/modals/add-ejecutivo/add-ejecutivo.component';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-administrations',
  templateUrl: './administrations.component.html',
  styleUrls: ['./administrations.component.css']
})
export class AdministrationsComponent implements OnInit {

  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
  public searchstring     : string = '';

  @Input() clienteId = 0;
  @Input() readonly = false;

  constructor(
    private clientesService	: ClientesService,
    public modalService     : NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerEjecutivos();
  }

  async obtenerEjecutivos(page = 1, page_size = 10) {
	//this.loadingTable = true
    await this.clientesService.obtenerEjecutivos(this.clienteId.toString(), page, page_size)
    .then(res => {
      this.initVars();
			this.loadTable(res);
			//this.loadingTable = false;
    })
    .catch(error => {
      console.error(error);
    })
  }

  loadTable(data) {

		this.rowData           = data.results;
		this.pageTableActual   = data.page_number;
		this.pageTableTotal    = data.count;
		this.pageTablePerPages = data.per_page;
		this.pageTablePages = [];
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index);
		}

	}

  initVars() {
		let columns = [
			{
				headerName: 'N° Doc.', 
				field     : 'id' ,
				sortable  : true,
			},
			{
				headerName: 'Principales Ejecutivos', 
				field     : 'nombre' ,
				sortable  : true,
			},
			{
				headerName: 'Documento de Identidad', 
				field     : 'dni' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Cargo Funcion', 
				field     : 'cargo' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Nacionalidad', 
				field     : 'nacionalidad_nombre' ,
				sortable  : true,
      },
      {
				headerName: 'E-Mail', 
				field     : 'email' ,
				sortable  : true,
			},
		];

		this.columnDefs = columns;
	}

  goToPage({page,per_page,filtros}) {
    this.obtenerEjecutivos(page, per_page);
  }

  tableEditEvent(ejecutivo) {
    const modalRef = this.modalService.open(AddEjecutivoComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;
		modalRef.componentInstance.ejecutivoId = ejecutivo.id;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerEjecutivos();
		},
		error => {

		});
  }

  destroyContact(accionista) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Ejecutivo';
		modalRef.componentInstance.message = '¿Seguro que desea remover este Ejecutivo?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.clientesService.elimiarEjecutivo(accionista.id)
			.then(result => {
			this.obtenerEjecutivos();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  openModal() {
    const modalRef = this.modalService.open(AddEjecutivoComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;

		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerEjecutivos();
		},
		error => {

		});
  }

}
