import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-show-operaciones',
  templateUrl: './show-operaciones.component.html',
  styleUrls: ['./show-operaciones.component.css']
})
export class ShowOperacionesComponent implements OnInit {

  faTimes=faTimes;
  @Input() operaciones : any[]
  @Input() errorMessage : any[]
  @Input() infoMessage : any[]
  

  constructor(
    public activeModal: NgbActiveModal,
    private router: Router,
  ) {
  }

  ngOnInit() {
  }

  navToOperacion(id : number) {
    const route = '/operaciones/' + id 
    this.router.navigate([route])
    this.activeModal.close(false)
  }
}
