import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AceptantesService } from "app/core/services/factoring/aceptantes.service";
import { ContactoPoderesService } from "app/core/services/poderes/contacto-poderes.service";
import { SharedFunctions } from 'app/core/shared/functions';

@Component({
  selector: "app-add-aceptante-add-contact-modal",
  templateUrl: "./add-contact-modal.component.html",
  styleUrls: ["./add-contact-modal.component.css"],
})
export class AddContactModalComponent implements OnInit {
  public myForm: FormGroup;
  public paises: any[];
  public contacto: any;
  public poderesContacto = [];
  public newPoderesEdit = 0;
  emailValido = true;
  faTimes = faTimes

  @Input() aceptanteId = 0;
  @Input() contactId = 0;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild: FormBuilder,
    public activeModal: NgbActiveModal,
    private aceptantesService: AceptantesService,
    private contactoPoderesService: ContactoPoderesService,
    public sharedFunctions  : SharedFunctions,
  ) {
    this.initForm();
    this.obtenerPaises();
  }

  ngOnInit(): void {
    if (this.contactId) {
      this.obtenerContact();
    }
  }

  obtenerPaises() {
    this.aceptantesService
      .obtenerPaises()
      .then((res) => {
        this.paises = res["results"];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  obtenerContact() {
    this.aceptantesService
      .obtenerContacto(this.contactId)
      .then((res) => {
        this.contacto = res;
        this.setForm(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      telefono: [null, [Validators.maxLength(50)]],
      cargo: [null, []],
      observaciones: [null, []],
      poderes: [null, []],
      representante_legal: [false, []],
      nacionalidad: [null, []],
    });
  }

  setForm(contact) {
    for (const key in contact) {
      if (this.myForm.controls[key])
        this.myForm.controls[key].setValue(contact[key]);
    }
  }

  onSavePoder(event) {
    this.poderesContacto = event;
  }

  onNewPoderes(event) {
    this.newPoderesEdit = event;
  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = this.myForm.value;
      data["aceptante"] = this.aceptanteId;

      this.aceptantesService
        .guardarContacto(this.myForm.value, this.contactId)
        .then((res: any) => {
          this.poderesContacto.forEach((x) => {
            x.contacto = res.id;
          });

          if (this.newPoderesEdit == 1) {
            this.contactoPoderesService
              .guardar(this.poderesContacto)
              .then((x) => {});
          } else {
            this.contactoPoderesService
              .guardar(this.poderesContacto, res.id)
              .then((x) => {});
          }

          this.successEmit.emit();
          this.activeModal.close(false);
        })
        .catch((error) => {
          this.activeModal.close(false);
        });
    }
  }
  validarEmail(e){
    let email = e.target.value;
    this.emailValido = email ? this.sharedFunctions.validateEmail(email) : true;
  }
}
