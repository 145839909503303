import { Component, Input, OnInit } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ClientesService } from "app/core/services/clientes/clientes.service";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { ConfirmModalComponent } from "app/shared/utils/confirm-modal/confirm-modal.component";
import { AddRelationCompetitorComponent } from "app/modules/clientes/modals/add-relation-competitor/add-relation-competitor.component";

@Component({
  selector: " app-business-information-relations-competitors",
  templateUrl: "./relations-competitors.component.html",
  styleUrls: ["./relations-competitors.component.css"],
})
export class RelationsCompetitorsComponent implements OnInit {
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public searchstring: string = "";

  @Input() clienteId = 0;
  @Input() readonly = false;

  constructor(
    private clientesService: ClientesService,
    public modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.obtenerRelacionesCompetidores();
  }

  obtenerRelacionesCompetidores(page = 1, page_size = 10) {
    this.clientesService
      .obtenerRelacionesCompetidores(this.clienteId.toString(), page, page_size)
      .then((res) => {
        this.initVars();
        this.loadTable(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  loadTable(data) {
    this.rowData = data.results.map((el: any, i: number) => ({
      ...el,
      index: i + 1,
    }));
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars() {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Razón Social",
        field: "nombre",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "% Participación de Mercado",
        field: "participacion_mercado",
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {
    this.obtenerRelacionesCompetidores(page, per_page);
  }

  tableEditEvent(client) {
    const modalRef = this.modalService.open(AddRelationCompetitorComponent, {
      size: "lg",
    });

    modalRef.componentInstance.clienteId = this.clienteId;
    modalRef.componentInstance.relacionId = client.id;

    modalRef.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {
        console.log(reason);
      }
    );

    modalRef.componentInstance.successEmit.subscribe(
      (result) => {
        console.log(result);
        this.obtenerRelacionesCompetidores();
      },
      (error) => {}
    );
  }

  destroyContact(client) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {});

    modalRef.componentInstance.title = "Remover competidor";
    modalRef.componentInstance.message =
      "¿Seguro que desea remover el competidor?";

    modalRef.result.then((result) => {
      if (result) {
        this.clientesService
          .eliminarRelacionCompetidor(client.id)
          .then((result) => {
            this.obtenerRelacionesCompetidores();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }

  openModal() {
    const modalRef = this.modalService.open(AddRelationCompetitorComponent, {
      size: "lg",
    });

    modalRef.componentInstance.clienteId = this.clienteId;

    modalRef.componentInstance.successEmit.subscribe(
      (result) => {
        this.obtenerRelacionesCompetidores();
      },
      (error) => {}
    );
  }
}
