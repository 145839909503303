import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../toast/toast.service';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  private apiUrl                  : string = "";
  public evaluaciones             : string = 'factoring/operaciones/tipoevaluacion/';
  public formulasMaestras             : string = 'factoring/liquidaciones/configuraciones/conceptos/';
  public formulasGrupos             : string = 'factoring/liquidaciones/configuraciones/grupos/';
  public tiposFirmasContratos     : string = 'factoring/contratos/tiposfirmacontrato/';
  public codigoCIIU               : string = 'clientes/clientes/ciiu/';
  public parametros               : string = 'factoring/beneficiarios/parametros/';
  // public parametrosContraseña     : string = 'seguridad/password-config/';
  public condiciones              : string = 'factoring/operaciones/condiciones/';
  public bancos                   : string = 'factoring/bancos/';
  public cuentas                  : string = 'factoring/recaudaciones/cuentas/';
  public comites                  : string = 'factoring/comites/';
  public integrantes              : string = 'factoring/comites/integrantes/';
  public cierres                  : string = 'factoring/cierres/';

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient
  ) { 
    this.apiUrl = this.appService.settings.API_base_url;
  }

  /**
   * Habilita el loader para request a la API
   */
   spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();

    this.appService.notifyMe(mensaje,ok)
    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);

  }

  /**
   * 
   * @param page 
   * @param page_size
   */
   obtenerEvaluaciones(
    page: number = 1,
    page_size: number = 10,
  ) {
      const url = this.apiUrl + this.evaluaciones +
                  `?page_size=${page_size}` + `&page=${page}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

    /**
   * 
   * @param page 
   * @param page_size
   */
     obtenerTiposDetalles(
      page: number = 1,
      page_size: number = 10,
    ) {
        const url = this.apiUrl + `factoring/operaciones/tipodetalle/?page=${page}&page_size=${page_size}`;
  
        return new Promise((res, ref) => {
          this.spinnerOn();
    
          this.http.get(url).subscribe(
            (response) => {
              this.spinnerOff();
              res(response);
            },
            (err) => {
              this.spinnerOff("La petición falló", false);
              ref(err);
            }
          );
        });
    }
  

  obtenerFormulasMaestras(
    page: number = 1,
    page_size: number = 10,
  ) {
      const url = this.apiUrl + this.formulasMaestras +
                  `?page_size=${page_size}` + `&page=${page}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  crearFormulaMaestra(
    body
  ) {
      const url = this.apiUrl + this.formulasMaestras;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.post(url,body).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  actualizarFormulaMaestra(
    body,
    id
  ) {
      const url = this.apiUrl + this.formulasMaestras+`${id}/`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.patch(url,body).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  eliminarFormulaMaestra(
    id
  ) {
      const url = this.apiUrl + this.formulasMaestras+`${id}/`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.delete(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  obtenerFormulasGrupos(
    page: number = 1,
    page_size: number = 10,
  ) {
      const url = this.apiUrl + this.formulasGrupos +
                  `?page_size=${page_size}` + `&page=${page}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }  

  obtenerEvaluacion(id) {
    const url = this.apiUrl + this.evaluaciones + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerTipoDetalle(id) {
    const url = this.apiUrl + `factoring/operaciones/tipodetalle/` + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarEvaluacion(data, evaluacionId = 0) {
    const url = evaluacionId
      ? this.apiUrl + this.evaluaciones + `${evaluacionId}/`
      : this.apiUrl + this.evaluaciones;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (evaluacionId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue registrada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }


  guardarTipoDetalle(data, detalleId = 0) {
    const url = detalleId
      ? this.apiUrl + `factoring/operaciones/tipodetalle/` + `${detalleId}/`
      : this.apiUrl + `factoring/operaciones/tipodetalle/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (detalleId) {
        //patch: ES ACTUALIZAR
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        //post: ES CREAR
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue registrada exitosamente.");
            res(response);
          },
          (err) => {
            if(err && err.error && err.error.descripcion[0]){
              this.spinnerOff(err.error.descripcion[0], false);
            } else {
              this.spinnerOff("La operación falló", false);
            }
            ref(err);
          }
        );
      }
    });
  }

  elimiarEvaluacion(evaluacionId) {
    const url = this.apiUrl + this.evaluaciones + `${evaluacionId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Tipo de Evaluación Eliminada");
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  eliminarDetalle(detalleId) {
    const url = this.apiUrl + `factoring/operaciones/tipodetalle/` + `${detalleId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Tipo de Detalle Eliminado");
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Tipos de Firmastes Contratos
   */

  /**
   * 
   * @param page 
   * @param page_size
   */
   obtenerTiposFirmantes(
    page: number = 1,
    page_size: number = 10,
  ) {
      const url = this.apiUrl + this.tiposFirmasContratos +
                  `?page_size=${page_size}` + `&page=${page}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  obtenerTipoFirma(id) {
    const url = this.apiUrl + this.tiposFirmasContratos + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarTipoFirma(data, tiposFirmantesId = 0) {
    const url = tiposFirmantesId
      ? this.apiUrl + this.tiposFirmasContratos + `${tiposFirmantesId}/`
      : this.apiUrl + this.tiposFirmasContratos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (tiposFirmantesId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue registrada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarTiposFirmas(tiposFirmantesId) {
    const url = this.apiUrl + this.tiposFirmasContratos + `${tiposFirmantesId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Tipo de Firma Eliminada");
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Tipos de Firmastes Contratos
   */

  
  /**
   * Tipos de Parametros
   */

   obtenerParametros(
    page: number = 1,
    page_size: number = 10,
  ) {
      const url = this.apiUrl + this.parametros +
                  `?page_size=${page_size}` + `&page=${page}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  obtenerParametro(id) {
    const url = this.apiUrl + this.parametros + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarParametro(data, parametroId = 0) {
    const url = parametroId
      ? this.apiUrl + this.parametros + `${parametroId}/`
      : this.apiUrl + this.parametros;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (parametroId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue registrada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarParametro(parametroId) {
    const url = this.apiUrl + this.parametros + `${parametroId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Parametro Eliminado");
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Tipos de Parametros
   */

  //  obtenerParametrosContrasena(
  //   page: number = 1,
  //   page_size: number = 10,
  // ) {
  //     const url = this.apiUrl + this.parametrosContraseña +
  //                 `?page_size=${page_size}` + `&page=${page}`;

  //     return new Promise((res, ref) => {
  //       this.spinnerOn();
  
  //       this.http.get(url).subscribe(
  //         (response) => {
  //           this.spinnerOff();
  //           res(response);
  //         },
  //         (err) => {
  //           this.spinnerOff("La petición falló", false);
  //           ref(err);
  //         }
  //       );
  //     });
  // }

  // obtenerParametroContraseña(id) {
  //   const url = this.apiUrl + this.parametrosContraseña + `/${id}/`;

  //   return new Promise((res, ref) => {
  //     this.spinnerOn();

  //     this.http.get(url).subscribe(
  //       (response) => {
  //         this.spinnerOff();
  //         res(response);
  //       },
  //       (err) => {
  //         this.spinnerOff("La operación falló", false);
  //         ref(err);
  //       }
  //     );
  //   });
  // }

  // guardarParametroContraseña(data, parametroId = 0) {
  //   const url = parametroId
  //     ? this.apiUrl + this.parametrosContraseña + `${parametroId}/`
  //     : this.apiUrl + this.parametrosContraseña;

  //   return new Promise((res, ref) => {
  //     this.spinnerOn();

  //     if (parametroId) {
  //       this.http.patch(url, data).subscribe(
  //         (response) => {
  //           this.spinnerOff("La información fue guardada exitosamente.");
  //           res(response);
  //         },
  //         (err) => {
  //           this.spinnerOff("La operación falló", false);
  //           ref(err);
  //         }
  //       );
  //     } else {
  //       this.http.post(url, data).subscribe(
  //         (response) => {
  //           this.spinnerOff("La información fue registrada exitosamente.");
  //           res(response);
  //         },
  //         (err) => {
  //           this.spinnerOff("La operación falló", false);
  //           ref(err);
  //         }
  //       );
  //     }
  //   });
  // }

  /**
   * Tipos de Parametros
   */
  
  /**
   * Tipos de Codigo CIIU
   */

   obtenerCodigosCIIU(
    search: string = "",
    page: number = 1,
    page_size: number = 10,
  ) {
      const url = this.apiUrl + this.codigoCIIU +
                  `?page_size=${page_size}` + `&page=${page}` + `&search=${search}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  obtenerCodigoCIIU(id) {
    const url = this.apiUrl + this.codigoCIIU + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarCodigoCIIU(data, ciiuId = 0) {
    const url = ciiuId
      ? this.apiUrl + this.codigoCIIU + `${ciiuId}/`
      : this.apiUrl + this.codigoCIIU;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (ciiuId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue registrada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarCodigoCIIU(ciiuId) {
    const url = this.apiUrl + this.codigoCIIU + `${ciiuId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Codigo CIIU Eliminada");
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Tipos de Codigo CIIU
   */
  


  /**
   * Condiones
   */

  /**
   * 
   * @param page 
   * @param page_size
   */
   obtenerCondiciones(
    page: number = 1,
    page_size: number = 10,
  ) {
      const url = this.apiUrl + this.condiciones +
                  `?page_size=${page_size}` + `&page=${page}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  obtenerCondicion(id) {
    const url = this.apiUrl + this.condiciones + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarCondicion(data, condicionId = 0) {
    const url = condicionId
      ? this.apiUrl + this.condiciones + `${condicionId}/`
      : this.apiUrl + this.condiciones;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (condicionId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue registrada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarCondicion(condicionId) {
    const url = this.apiUrl + this.condiciones + `${condicionId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Condición Eliminada");
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin Condiciones
   */

  /**
  * Bancos 
  */

  /**
   * 
   * @param page 
   * @param page_size
   */
   obtenerBancos(
    nombre:string = '',
    page: number = 1,
    page_size: number = 10,
  ) {
      const url = this.apiUrl + this.bancos +
                  `?page_size=${page_size}` + `&page=${page}` + `&nombre__icontains=${nombre}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  obtenerBanco(id) {
    const url = this.apiUrl + this.bancos + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarBanco(data, bancoId = 0) {
    const url = bancoId
      ? this.apiUrl + this.bancos + `${bancoId}/`
      : this.apiUrl + this.bancos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (bancoId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue registrada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarBanco(bancoId) {
    const url = this.apiUrl + this.bancos + `${bancoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Banco Eliminado");
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin bancos
   */

  /**
   * Cuentas
   */

  /**
   * 
   * @param page 
   * @param page_size
   */
   obtenerCuentas(
    page: number = 1,
    page_size: number = 10,
  ) {
      const url = this.apiUrl + this.cuentas +
                  `?page_size=${page_size}` + `&page=${page}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  obtenerCuenta(id) {
    const url = this.apiUrl + this.cuentas + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarCuenta(data, cuentaId = 0) {
    const url = cuentaId
      ? this.apiUrl + this.cuentas + `${cuentaId}/`
      : this.apiUrl + this.cuentas;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (cuentaId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue registrada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarCuenta(cuentaId) {
    const url = this.apiUrl + this.cuentas + `${cuentaId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Cuenta Eliminada");
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin cuentas
   */

  /** 
   * Comite
   */

  /**
   * 
   * @param page 
   * @param page_size
   */
   obtenerComites(
    page: number = 1,
    page_size: number = 10,
  ) {
      const url = this.apiUrl + this.comites +
                  `?page_size=${page_size}` + `&page=${page}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  obtenerComite(id) {
    const url = this.apiUrl + this.comites + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarComite(data, comiteId = 0) {
    const url = comiteId
      ? this.apiUrl + this.comites + `${comiteId}/`
      : this.apiUrl + this.comites;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (comiteId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue registrada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarComite(comiteId) {
    const url = this.apiUrl + this.comites + `${comiteId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Comité Eliminado");
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  comitesIntegrantes(comiteId) {
    const url = this.apiUrl + this.comites + `integrantes/` +
                  `?page_size=${1000}` + `&page=${1}`+
                  `&comite=${comiteId}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  addIntegrantesComite(data) {

    const url = this.apiUrl + this.integrantes;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.post(url, data).subscribe(
        (response) => {
          this.spinnerOff("La información fue registrada exitosamente.");
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );

    });

  }

  editIntegrantesComite(integranteId, data) {

    const url = this.apiUrl + this.integrantes + `${integranteId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.patch(url, data).subscribe(
        (response) => {
          console.log("RES", response)
          this.spinnerOff("La información fue registrada exitosamente.");
          res(response);
        },
        (err) => {
          console.log("ERR", err)
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );

    });

  }

  elimiarIntegrante(integranteId) {
    const url = this.apiUrl + this.integrantes + `${integranteId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Integrante Eliminado");
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

  /**
   * Fin comite
   */

  getCierrePeriodoContable(
    page = 1,
    page_size = 10,
    year = '',
    month__gte = '',
    month__lte = '',
    tipo_cierre = '',
    year__lte = '',
    year__gte = '',
    periodo_inicio__gte = '',
    periodo_fin__lte = ''
  ){

    const url = `${this.apiUrl}${this.cierres}?page=${page}&page_size=${page_size}&year=${year}&month__gte=${month__gte}&month__lte=${month__lte}&tipo_cierre=${tipo_cierre}&year__lte=${year__lte}&year__gte=${year__gte}&periodo_inicio__gte=${periodo_inicio__gte}&periodo_fin__lte=${periodo_fin__lte}`;
    this.spinnerOn();
    return this.http.get( url )
              .pipe( 
                map( (val)=>{
                  this.spinnerOff();
                  return val

                }),
                catchError( err =>{
                    this.spinnerOff("La petición falló", false);
                  return of( err ) 
                  })
               )

  }

  cerrarPeriodoContableoOperativo(body: any, tipo_cierre: string){
    const url = `${this.apiUrl}${this.cierres}${tipo_cierre}/`;
    body.cerrado = 1;
    this.spinnerOn();

    return this.http.put(url, body)
      .pipe(
        map((val)=>{
          this.spinnerOff();
          return val;
        }),
        catchError((err) => {
          if (err.error.periodo_fin) {
            this.spinnerOff(err.error.periodo_fin, false);
          } else {
            this.spinnerOff("La petición falló", false);
          }
          return of(err)
        })
      )
  }

  abrirPeriodoContableoOperativo(body: any, tipo_cierre: string) {
    const url = `${this.apiUrl}${this.cierres}${tipo_cierre}/`;
    body.cerrado = 0;
    this.spinnerOn();

    return this.http.put(url, body)
      .pipe(
        map((val) => {
          this.spinnerOff();
          return val;
        }),
        catchError((err) => {
          if (err.error.periodo_fin) {
            this.spinnerOff(err.error.periodo_fin, false);
          } else {
            this.spinnerOff("La petición falló", false);
          }
          return of(err);
        }),
      );
  }

}
