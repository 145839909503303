import { Component, OnInit } from '@angular/core';
import * as moment from "moment";
import * as ExcelJS from "exceljs";
import * as XLSX from "xlsx"

import { AuthService } from 'app/core/services/auth/auth.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { ToastService } from 'app/core/services/toast/toast.service'; 
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { functions } from '../../../../core/helpers/functions';
import { PreviewFileService } from 'app/core/services/file/preview-file.service';


@Component({
    selector: 'app-reporte-creditos-factoring',
    templateUrl: './reporte-creditos-factoring.component.html',
    styleUrls: ['./reporte-creditos-factoring.component.css']
})

export class ReporteCreditosFactoringComponent implements OnInit {
    public isGeneratingExcel : boolean = false;
    public isFirstExcelUploaded : boolean = false;

    public headerTable : columnHeader[];
    public tblAcciones = [];
    public pageTableActual : number;
    public pageTableTotal : number;
    public pageTablePerPages : number = 10;
    public pageTablePages : number[];
    public searchString : string = '';
    public rowData = []

    public fechas = {
        fecha_ultima_transaccion__gte: undefined,
        fecha_ultima_transaccion__lte: undefined,
    }


    public filtros : any = {
        nro_operacion : undefined,
        documentos : undefined,
        cliente_ruc : undefined,
        cliente_razon_social : undefined,
        deudor_ruc : undefined,
        deudor_razon_social : undefined
    }
    //#region Constructor
    constructor(
        private factoringService : FactoringService,
        public authService : AuthService,
        public toast: ToastService,
        public previewFileService: PreviewFileService
    ){}
    //#endregion

    //#region Functions
    ngOnInit() : void {
        this.loadTableColumns();
    }

    getReportesCreditosFactoring({page, per_page, filtros, type = ""}) {
        // Type === "fecha_emision_factura" se emite desde "goToPage"os);
        if(type === "fecha_ultima_transaccion"){
            this.fechas.fecha_ultima_transaccion__gte = filtros.fecha_ultima_transaccion__gte
                ? moment(filtros.fecha_ultima_transaccion__gte, ["YYYY-M-D", "YYYY-MM-DD"]).format("YYYY-MM-DD") 
                : undefined
            this.fechas.fecha_ultima_transaccion__lte = filtros.fecha_ultima_transaccion__lte 
                ? moment(filtros.fecha_ultima_transaccion__lte, ["YYYY-M-D", "YYYY-MM-DD"]).format("YYYY-MM-DD") 
                : undefined
        }else{
            this.filtros = {...this.filtros, ...filtros}
        }
        
        this.factoringService.getReporteCreditoFactoring(
            this.filtros.nro_operacion,
            this.filtros.documentos,
            this.fechas.fecha_ultima_transaccion__gte,
            this.fechas.fecha_ultima_transaccion__lte,
            this.filtros.cliente_ruc,
            this.filtros.cliente_razon_social,
            this.filtros.deudor_ruc,
            this.filtros.deudor_razon_social,
            page,
            per_page,
            true
        ).then(res => {
            this.loadTable(res);
        })
    }
    //#endregion

    //#region  Table Functions
    loadTable(data){
        this.rowData = functions.indextable(data);
        this.pageTableActual = data.page_number;
        this.pageTableTotal = data.count;
        this.pageTablePerPages = data.per_page;
        this.pageTablePages = [];

        for(let index = 0; index < data.num_pages; index++){
            this.pageTablePages.push(index)
        }
        
    }

    loadTableColumns(){
        this.headerTable = [
            {
                headerName: "N°",
                class: "text-center",
                field: "nro_operacion",
                //filterProp: "nro_operacion",
                sortable: true,
                filterable: false, // true
                //filterInput: true,
            }, 
            {
                headerName: "Fecha inicio",
                class: "text-center",
                field: "fecha_ini_factoring",
                sortable: false,
                filterable: false,
                pipe: "date",
            },
            {
                headerName: "Cliente",
                class: "text-center",
                field: "cliente_razon_social",
                //filterProp: "cliente_razon_social",
                sortable: false,
                filterable: false, // true,
                // filterInput: true,
            },
            {
                headerName: "Ruc",
                class: "text-center",
                field: "cliente_ruc",
                //filterProp: "cliente_ruc",
                sortable: false,
                filterable: false, //true,
                //filterInput: true,
            },
            {
                headerName: "Deudor",
                class: "text-center",
                field: "deudor_razon_social",
                //filterProp: "deudor_razon_social",
                sortable: false,
                filterable: false, // true,
                //filterInput: true,
            },
            {
                headerName: "Ruc",
                class: "text-center",
                field: "deudor_ruc",
                //filterProp: "deudor_ruc",
                sortable: false,
                filterable: false, // true,
                //filterInput: true,
            },
            {
                headerName: "Nro. Documento",
                class: "text-center",
                field: "nro_documento",
                // filterProp: "documentos",
                sortable: false,
                filterable: false, //true,
                // filterInput: true,
            },
            { 
                headerName: "Fecha Emisión",
                class: "text-center",
                field: "fecha_emision_factura",
                sortable: false,
                pipe: "date",
                filterable: false,
            },
            { 
                headerName: "Monto",
                class: "text-center",
                field: "monto_documento",
                sortable: false,
                filterable: false,
            },
            { 
                headerName: "Monto Financiado",
                class: "text-center",
                field: "monto_financiado",
                sortable: false,
                filterable: false,
            },
            { 
                headerName: "Cobranza del monto financiado",
                class: "text-center",
                field: "cobranza_monto_financiado",
                sortable: false,
                filterable: false,
            },
            {
                headerName: "Ultima transaccion",
                class: "text-center",
                field: "fecha_ultima_transaccion",
                sortable: false,
                filterable: true,
                filterDate: true,
                filterProp: "fecha_ultima_transaccion"
            }
        ];
        this.tblAcciones = [
            {
              tooltip: 'Ver pdf', icon: 'fa fa-file-pdf-o', class: 'btn-outline-danger',
              click: (row) => {
                    console.log("ROW: ", row);
                    this.previewFile(row.adjunto_pdf)
                }
            },
          ]
    }

    goToPage({page, per_page, filtros, type}){
        // this.filtros = filtros
        this.getReportesCreditosFactoring({page, per_page, filtros, type})
    }

    //#endregion

    //#region  Excel 
    importarExcel(event){
        console.log("ImportarExcelEvent: ", event);
        const target : DataTransfer = <DataTransfer>(event.target);
        if(target.files.length !== 1){
            this.toast.warning("Solo se permite de un documento a la vez.")
            return;
        }
        
        const reader: FileReader = new FileReader();
        reader.onload = (e) => {
            // Excel content to JSON
            const content = e.target.result;
            const workbook = XLSX.read(content, {type: "binary"});
            const worksheetName = workbook.SheetNames[0]; // Nombre de la primera hoja del excel.
            const worksheet = workbook.Sheets[worksheetName]; // Recogiendo el contenido basado en el nombre de la página.

            const data = XLSX.utils.sheet_to_json(worksheet)
            data.forEach((row : any) => {
                for(let key in row){
                    row[key.toLowerCase()] = row[key];
                    delete row[key];
                }
            })
            if(data.length == 0){
                this.toast.warning("El archivo no contiene datos relevantes.");
                return;
            }
            
            // Procesando operaciones y num_docs
            const operacionesSet = new Set([]);
            const numDocSet = new Set([]);
            data.forEach((row : any) => {
                if(!row.operacion && !row.documento){
                    this.toast.warning("El formato del archivo no es el adecuado.");
                    return;
                }else{
                    let tOperacion = row.operacion?.toString().replaceAll(" ", "") || "";
                    let tNumDoc = row.documento?.toString().replaceAll(" ", "") || "";
                    if(tOperacion !== ""){ operacionesSet.add(tOperacion) }
                    if(tNumDoc !== ""){ numDocSet.add(tNumDoc) }
                }
            })

            const operaciones = Array.from(operacionesSet).join(",");
            const numDocs = Array.from(numDocSet).join(",");
            
            if(operaciones.length == 0 && numDocs.length == 0){
                this.toast.warning("El archivo no contiente datos relevantes.");
                return;
            }
            
            this.isFirstExcelUploaded = true;

            // Levantando consulta
            this.filtros = {
                ...this.filtros,
                nro_operacion: operaciones,
                documentos: numDocs,
            }
            this.getReportesCreditosFactoring({page: 1, per_page: this.pageTablePerPages, filtros: this.filtros, type: "" })
        }
        reader.readAsBinaryString(target.files[0]);
    }

    generarFormato(){
        console.log("Generando Formato:");
        const workBook = new ExcelJS.Workbook();
        const workSheet = workBook.addWorksheet("formato");

        let dataDeploy = [
            { 
				area: `A1:A1`,
				value: "Operacion"
			},{ 
				area: `B1:B1`,
				value: "Documento"
			}
        ]
        
        dataDeploy.forEach(x => {
			workSheet.mergeCells(x.area);
			let tCell = workSheet.getCell(x.area);
            tCell.value = x.value;
        })

		for(let i = 1; i <= 58; i++){
			workSheet.getColumn(i).width = 13;
		}

        // Generando buffer y disparando evento para descargar el archivo
		workBook.xlsx.writeBuffer().then(buffer => {
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download =  `FormatoCarga.xlsx`;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		})
    
    }

    generarExcel(){
        console.log("Generando Excel:");
        let data = this.rowData;
        
        const workBook = new ExcelJS.Workbook();
        const workSheet = workBook.addWorksheet("ReporteCreditoFactoring");

        // Titulo
		workSheet.addRow(["Créditos Factoring"]);
		workSheet.mergeCells("A1:U1");
        let titleCell = workSheet.getCell("U1");
		titleCell.alignment = {vertical: "middle", horizontal: "left"}
		titleCell.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 14, bold: false}
		titleCell.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "7b9fd1"} }

        // Subtitle
        workSheet.addRow([''])
        workSheet.addRow([`Operaciones: ${this.filtros.nro_operacion}`]);
		workSheet.mergeCells("A3:U3");
        let filter1 = workSheet.getCell("U3");
		filter1.alignment = {vertical: "middle", horizontal: "left"}
		filter1.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: false}
		filter1.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "FFFFFF"} }

        workSheet.addRow([`Documentos: ${this.filtros.documentos}`]);
		workSheet.mergeCells("A4:U4");
        let filter2 = workSheet.getCell("U4");
		filter2.alignment = {vertical: "middle", horizontal: "left"}
		filter2.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: false}
		filter2.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "FFFFFF"} }


        //CabeceraData Styles
		let headerTableStyle = {
			alignement: {vertical: "middle", horizontal: "center"},
			font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: true},
			fill: { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} },
			numFmt: ""
		}
		let dataTableStyle = {
			alignement: {vertical: "middle", horizontal: "center"},
			font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: false},
			fill: { type: "pattern", pattern: "solid", fgColor: {argb: "FFFFFF"} },
			numFmt: ""
		}
        let startingNewRowsAt = 6;

        let dataDeploy = [
            { 
				area: `A${startingNewRowsAt}:A${startingNewRowsAt}`,
				value: "Nro.",
				style: headerTableStyle,
			},{ 
				area: `B${startingNewRowsAt}:B${startingNewRowsAt}`,
				value: "Fecha de inicio",
				style: headerTableStyle,
			},{ 
				area: `C${startingNewRowsAt}:C${startingNewRowsAt}`,
				value: "Cliente - Ruc",
				style: headerTableStyle,
			},{ 
				area: `D${startingNewRowsAt}:E${startingNewRowsAt}`,
				value: "Cliente - Razón Social",
				style: headerTableStyle,
			},{ 
				area: `F${startingNewRowsAt}:F${startingNewRowsAt}`,
				value: "Cliente - Distrito",
				style: headerTableStyle,
			},{ 
				area: `G${startingNewRowsAt}:I${startingNewRowsAt}`,
				value: "Cliente - Dirección",
				style: headerTableStyle,
			},{ 
				area: `J${startingNewRowsAt}:J${startingNewRowsAt}`,
				value: "Cliente - Email",
				style: headerTableStyle,
			},{ 
				area: `K${startingNewRowsAt}:K${startingNewRowsAt}`,
				value: "Cliente - Teléfono",
				style: headerTableStyle,
			},{ 
				area: `L${startingNewRowsAt}:L${startingNewRowsAt}`,
				value: "Cliente - Giro negocio",
				style: headerTableStyle,
			},{ 
				area: `M${startingNewRowsAt}:M${startingNewRowsAt}`,
				value: "Cliente - Línea",
				style: headerTableStyle,
			},{ 
				area: `N${startingNewRowsAt}:N${startingNewRowsAt}`,
				value: "Deudor - Ruc",
				style: headerTableStyle,
			},{ 
				area: `O${startingNewRowsAt}:P${startingNewRowsAt}`,
				value: "Deudor - Razón Social",
				style: headerTableStyle,
			},{ 
				area: `Q${startingNewRowsAt}:Q${startingNewRowsAt}`,
				value: "Deudor - Distrito",
				style: headerTableStyle,
			},{ 
				area: `R${startingNewRowsAt}:T${startingNewRowsAt}`,
				value: "Deudor - Dirección",
				style: headerTableStyle,
			},{ 
				area: `U${startingNewRowsAt}:U${startingNewRowsAt}`,
				value: "Deudor - Email",
				style: headerTableStyle,
			},{ 
				area: `V${startingNewRowsAt}:V${startingNewRowsAt}`,
				value: "Deudor - Teléfono",
				style: headerTableStyle,
			},{ 
				area: `W${startingNewRowsAt}:W${startingNewRowsAt}`,
				value: "Moneda - Operación",
				style: headerTableStyle,
			},{ 
				area: `X${startingNewRowsAt}:X${startingNewRowsAt}`,
				value: "Fecha emisión de factura",
				style: headerTableStyle,
			},{ 
				area: `Y${startingNewRowsAt}:Y${startingNewRowsAt}`,
				value: "Fecha confirmación Cavali",
				style: headerTableStyle,
			},{ 
				area: `Z${startingNewRowsAt}:Z${startingNewRowsAt}`,
				value: "Moneda - Doc. en Cartera",
				style: headerTableStyle,
			},{ 
				area: `AA${startingNewRowsAt}:AA${startingNewRowsAt}`,
				value: "Tipo documento",
				style: headerTableStyle,
			},{ 
				area: `AB${startingNewRowsAt}:AB${startingNewRowsAt}`,
				value: "Nro. Documento",
				style: headerTableStyle,
			},{ 
				area: `AC${startingNewRowsAt}:AC${startingNewRowsAt}`,
				value: "Fecha de vencimiento",
				style: headerTableStyle,
			},{ 
				area: `AD${startingNewRowsAt}:AD${startingNewRowsAt}`,
				value: "Estado documento",
				style: headerTableStyle,
			},{ 
				area: `AE${startingNewRowsAt}:AE${startingNewRowsAt}`,
				value: "Monto documento",
				style: headerTableStyle,
			},{ 
				area: `AF${startingNewRowsAt}:AF${startingNewRowsAt}`,
				value: "Monto financiado",
				style: headerTableStyle,
			},{ 
				area: `AG${startingNewRowsAt}:AG${startingNewRowsAt}`,
				value: "% Financiamiento",
				style: headerTableStyle,
			},{ 
				area: `AH${startingNewRowsAt}:AH${startingNewRowsAt}`,
				value: "Cobranza monto financiado",
				style: headerTableStyle,
			},{ 
				area: `AI${startingNewRowsAt}:AI${startingNewRowsAt}`,
				value: "Saldo monto financiado",
				style: headerTableStyle,
			},{ 
				area: `AJ${startingNewRowsAt}:AJ${startingNewRowsAt}`,
				value: "Fecha último pago",
				style: headerTableStyle,
			},{ 
				area: `AK${startingNewRowsAt}:AK${startingNewRowsAt}`,
				value: "Prórroga",
				style: headerTableStyle,
			},{ 
				area: `AL${startingNewRowsAt}:AL${startingNewRowsAt}`,
				value: "Vencimiento de prórroga",
				style: headerTableStyle,
			}
        ]

        data.forEach(x => {
            startingNewRowsAt++;
            let tRow = [
                { 
                    area: `A${startingNewRowsAt}:A${startingNewRowsAt}`,
                    value: x.nro_operacion,
                    style: dataTableStyle,
                },{ 
                    area: `B${startingNewRowsAt}:B${startingNewRowsAt}`,
                    value: moment(x.fecha_ini_factoring).isValid() ? moment(x.fecha_ini_factoring).format("YYYYMMDD") : "",
                    style: dataTableStyle,
                },{ 
                    area: `C${startingNewRowsAt}:C${startingNewRowsAt}`,
                    value: x.cliente_ruc,
                    style: dataTableStyle,
                },{ 
                    area: `D${startingNewRowsAt}:E${startingNewRowsAt}`,
                    value: x.cliente_razon_social,
                    style: dataTableStyle,
                },{ 
    				area: `F${startingNewRowsAt}:F${startingNewRowsAt}`,
                    value: x.cliente_distrito,
                    style: dataTableStyle,
                },{ 
    				area: `G${startingNewRowsAt}:I${startingNewRowsAt}`,
                    value: x.cliente_direccion,
                    style: dataTableStyle,
                },{ 
    				area: `J${startingNewRowsAt}:J${startingNewRowsAt}`,
                    value: x.cliente_email,
                    style: dataTableStyle,
                },{ 
    				area: `K${startingNewRowsAt}:K${startingNewRowsAt}`,
                    value: x.cliente_telefono,
                    style: dataTableStyle,
                },{ 
				    area: `L${startingNewRowsAt}:L${startingNewRowsAt}`,
                    value: x.cliente_giro_negocio,
                    style: dataTableStyle,
                },{ 
    				area: `M${startingNewRowsAt}:M${startingNewRowsAt}`,
                    value: x.cliente_linea,
                    style: dataTableStyle,
                },{ 
    				area: `N${startingNewRowsAt}:N${startingNewRowsAt}`,
                    value: x.deudor_ruc,
                    style: dataTableStyle,
                },{ 
				    area: `O${startingNewRowsAt}:P${startingNewRowsAt}`,
                    value: x.deudor_razon_social,
                    style: dataTableStyle,
                },{ 
				    area: `Q${startingNewRowsAt}:Q${startingNewRowsAt}`,
                    value: x.deudor_distrito,
                    style: dataTableStyle,
                },{ 
    				area: `R${startingNewRowsAt}:T${startingNewRowsAt}`,
                    value: x.deudor_direccion,
                    style: dataTableStyle,
                },{ 
                    area: `U${startingNewRowsAt}:U${startingNewRowsAt}`,
                    value: x.deudor_email,
                    style: dataTableStyle,
                },{ 
                    area: `V${startingNewRowsAt}:V${startingNewRowsAt}`,
                    value: x.deudor_telefono,
                    style: dataTableStyle,
                },{ 
                    area: `W${startingNewRowsAt}:W${startingNewRowsAt}`,
                    value: x.moneda_operacion,
                    style: dataTableStyle,
                },{ 
                    area: `X${startingNewRowsAt}:X${startingNewRowsAt}`,
                    value: moment(x.fecha_emision_factura).isValid() ? moment(x.fecha_emision_factura).format("YYYYMMDD") : "",
                    style: dataTableStyle,
                },{ 
                    area: `Y${startingNewRowsAt}:Y${startingNewRowsAt}`,
                    value: moment(x.fecha_confirmacion_cavali).isValid() ? moment(x.fecha_confirmacion_cavali).format("YYYYMMDD") : "",
                    style: dataTableStyle,
                },{ 
                    area: `Z${startingNewRowsAt}:Z${startingNewRowsAt}`,
                    value: x.moneda_documento,
                    style: dataTableStyle,
                },{ 
                    area: `AA${startingNewRowsAt}:AA${startingNewRowsAt}`,
                    value: x.tipo_documento,
                    style: dataTableStyle,
                },{ 
                    area: `AB${startingNewRowsAt}:AB${startingNewRowsAt}`,
                    value: x.nro_documento,
                    style: dataTableStyle,
                },{ 
                    area: `AC${startingNewRowsAt}:AC${startingNewRowsAt}`,
                    value: moment(x.fecha_vencimiento).isValid() ? moment(x.fecha_vencimiento).format("YYYYMMDD") : "",
                    style: dataTableStyle,
                },{ 
                    area: `AD${startingNewRowsAt}:AD${startingNewRowsAt}`,
                    value: x.estado_documento,
                    style: dataTableStyle,
                },{ 
                    area: `AE${startingNewRowsAt}:AE${startingNewRowsAt}`,
                    style: dataTableStyle,
                    value: x.monto_documento,
                },{ 
                    area: `AF${startingNewRowsAt}:AF${startingNewRowsAt}`,
                    value: x.monto_financiado,
                    style: dataTableStyle,
                },{ 
                    area: `AG${startingNewRowsAt}:AG${startingNewRowsAt}`,
                    value: x.percent_financimiento,
                    style: dataTableStyle,
                },{ 
                    area: `AH${startingNewRowsAt}:AH${startingNewRowsAt}`,
                    value: x.cobranza_monto_financiado,
                    style: dataTableStyle,
                },{ 
                    area: `AI${startingNewRowsAt}:AI${startingNewRowsAt}`,
                    value: x.saldo_monto_financiado,
                    style: dataTableStyle,
                },{ 
                    area: `AJ${startingNewRowsAt}:AJ${startingNewRowsAt}`,
                    value: moment(x.fecha_ultima_transaccion).isValid() ? moment(x.fecha_ultima_transaccion).format("YYYYMMDD") : "",
                    style: dataTableStyle,
                },{ 
                    area: `AK${startingNewRowsAt}:AK${startingNewRowsAt}`,
                    value: x.prorroga_id,
                    style: dataTableStyle,
                },{ 
                    area: `AL${startingNewRowsAt}:AL${startingNewRowsAt}`,
                    value: moment(x.prorroga_vencimiento).isValid() ? moment(x.prorroga_vencimiento).format("YYYYMMDD") : "",
                    style: dataTableStyle,
                }
            ]
            dataDeploy = [...dataDeploy, ...tRow];
        })

        dataDeploy.forEach(x => {
			workSheet.mergeCells(x.area);
			let tCell = workSheet.getCell(x.area)
            tCell.value = x.value;
			tCell.alignment = x.style.alignement as Partial<ExcelJS.Alignment>;
			tCell.font = x.style.font;
			tCell.fill = x.style.fill as ExcelJS.Fill;
			if(x.style.numFmt != "" && tCell.value !== "") { tCell.numFmt = x.style.numFmt}
        })

        
		// Extra styles: 
		workSheet.getRow(1).height = 25;
		for(let i = 1; i <= 58; i++){
			workSheet.getColumn(i).width = 30;
		}

        // Generando buffer y disparando evento para descargar el archivo
		workBook.xlsx.writeBuffer().then(buffer => {
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download =  `ReporteCreditoFactoring-${moment().format("YYYY-MM-DD")}.xlsx`;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		})
    }
    //#endregion
    previewFile(url) {
        // https://digital-back-dev.totalsf.com.pe/factoring/media/operacion_pdf/90770_1722315269.pdf
        if(url !== ""){
            url = `https://digital-back-dev.totalsf.com.pe/factoring/media/${url}`
            this.factoringService.downloadFile(url).then(
              (data: any) => {
                this.previewFileService.open(data)
              }, (reason) => {
                console.log(reason);
              })
        }else{
            this.toast.warning("No se pudo ubicar el documento asociado.")
        }
    }
}