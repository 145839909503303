import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';

@Component({
  selector: 'app-add-ejecutivo',
  templateUrl: './add-ejecutivo.component.html',
  styleUrls: ['./add-ejecutivo.component.css']
})
export class AddEjecutivoComponent implements OnInit {
  faTimes=faTimes;
  public minDate: Date;
  myForm: FormGroup;
  @Input() clienteId = 0;
  paises : any[];
  @Input() ejecutivoId = 0;
  ejecutivo : any;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  personas: any[];

  constructor(
    public formbuild        : FormBuilder,
    public activeModal      : NgbActiveModal,
    private clientesService	: ClientesService,
  ) {
    this.initForm();
   }

  ngOnInit(): void {
    this.obtenerPaises();

    if (this.ejecutivoId) {
      this.obtenerEjecutivo();
    }
  }

  obtenerPaises() {
    this.clientesService.obtenerPaises()
    .then(res => {
      this.paises = res['results'];
    })
    .catch(error => {
      console.error(error);
    });
  }

  obtenerEjecutivo() {
    this.clientesService.obtenerEjecutivo(this.ejecutivoId)
    .then(res => {
      this.ejecutivo = res;
      this.setForm(res);
    })
    .catch(error => {
      console.error(error);
    });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre          : [null, [Validators.required]],
      dni             : [null, [Validators.required]],
      cargo           : [null, []],
      email           : [null, [Validators.required, Validators.email]],
      nacionalidad    : [null, []],
    });
  }

  get f() {
    return this.myForm.controls;
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  onSubmit() {
    if(this.myForm.valid) {
      const data = this.myForm.value;
      data['cliente'] = this.clienteId;

      this.clientesService.guardarEjecutivo(this.myForm.value, this.ejecutivoId)
      .then(res => {
        this.successEmit.emit();
        this.activeModal.close(false);
      }).
      catch(error => {
        this.activeModal.close(false);
      });
    }
  }

}
