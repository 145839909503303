import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConfigurationRoutingModule } from './configuration-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'app/shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UiSwitchModule } from 'ngx-ui-switch';
import { TipoEvaluacionComponent } from './tipo-evaluacion/tipo-evaluacion.component';
import { ManageEvaluacionModalComponent } from './modals/manage-evaluacion-modal/manage-evaluacion-modal.component';
import { TiposFirmaContratosComponent } from './tipos-firma-contratos/tipos-firma-contratos.component';
import { ManageTiposFirmasContratoModalComponent } from './modals/manage-tipos-firmas-contrato-modal/manage-tipos-firmas-contrato-modal.component';
import { CondicionesComponent } from './condiciones/condiciones.component';
import { ManageCondicionesModalComponent } from './modals/manage-condiciones-modal/manage-condiciones-modal.component';
import { GestionBancosComponent } from './gestion-bancos/gestion-bancos.component';
import { ManageBancosComponent } from './modals/manage-bancos/manage-bancos.component';
import { RecaudacionCuentasComponent } from './recaudacion-cuentas/recaudacion-cuentas.component';
import { ManageRecaudacionCuentasComponent } from './modals/manage-recaudacion-cuentas/manage-recaudacion-cuentas.component';
import { ConfigComiteComponent } from './config-comite/config-comite.component';
import { ManageComiteModalComponent } from './modals/manage-comite-modal/manage-comite-modal.component';
import { ManageComiteIntegrantesModalComponent } from './modals/manage-comite-integrantes-modal/manage-comite-integrantes-modal.component';
import { ManageComiteIntegrantesOrdenModalComponent } from './modals/manage-comite-integrantes-orden-modal/manage-comite-integrantes-orden-modal.component';
import { FormulasComponent } from './formulas/formulas.component';
import { AddFormulaComponent } from './formulas/partials/add-formula/add-formula.component';
import { CodigoCIIUComponent } from './codigo-ciiu/codigo-ciiu.component';
import { ManageCodigoCIIUModalComponent } from './modals/manage-codigo-ciiu-modal/manage-codigo-ciiu-modal.component';
import { ParametrosComponent } from './parametros/parametros.component';
import { ManageParametrosModalComponent } from './modals/manage-parametros-modal/manage-parametros-modal.component';
import { ParametrosContrasenaComponent } from './parametros-contraseña/parametros-contraseña.component';
import { ManageParametrosContrasenaModalComponent } from './modals/manage-parametros-contraseña-modal/manage-parametros-contraseña-modal.component';
import { ManageTipodetallesModalComponent } from './modals/manage-tipodetalles-modal/manage-tipodetalles-modal.component';
import { FacturacionSeriesComponent } from './facturacion-series/facturacion-series.component';
import { ManageFacturacionSeriesComponent } from './modals/facturacion/manage-facturacion-series/manage-facturacion-series.component';
import { LideresEquipoComponent } from './lideres-equipo/lideres-equipo.component';
import { LideresModalComponent } from './lideres-modal/lideres-modal.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LideresAsignarComponent } from './lideres-asignar/lideres-asignar.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TipoNcComponent } from './tipo-nc/tipo-nc.component';
import { ManageReporte } from './modals/manage-reporte-modal/manage-reporte-modal.component';
import { AsignacionCursoNormalComponent } from './asignacion-curso-normal/asignacion-curso-normal.component';
import { FeriadosComponent } from './feriados/feriados.component';
import { FeriadoPickerComponent } from './feriados/feriado-picker/feriado-picker.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    TipoEvaluacionComponent,
    ManageEvaluacionModalComponent,
    TiposFirmaContratosComponent,
    ManageTiposFirmasContratoModalComponent,
    CondicionesComponent,
    ManageCondicionesModalComponent,
    ParametrosComponent,
    ManageParametrosModalComponent,
    ParametrosContrasenaComponent,
    ManageParametrosContrasenaModalComponent,
    CodigoCIIUComponent,
    ManageCodigoCIIUModalComponent,
    GestionBancosComponent,
    ManageBancosComponent,
    RecaudacionCuentasComponent,
    ManageRecaudacionCuentasComponent,
    ConfigComiteComponent,
    ManageComiteModalComponent,
    ManageComiteIntegrantesModalComponent,
    ManageComiteIntegrantesOrdenModalComponent,
    FormulasComponent,
    AddFormulaComponent,
    ManageTipodetallesModalComponent,
    FacturacionSeriesComponent,
    ManageFacturacionSeriesComponent,
    LideresEquipoComponent,
    LideresModalComponent,
    LideresAsignarComponent,
    TipoNcComponent,
    ManageReporte,
    AsignacionCursoNormalComponent,
    FeriadosComponent,
    FeriadoPickerComponent
  ],
  imports: [
    CommonModule,
    ConfigurationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    NgxDatatableModule,
    NgSelectModule,
    NgbModule,
    BsDropdownModule.forRoot(),
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    NgxMaskModule.forRoot(maskConfig),
    BsDatepickerModule.forRoot(),
    FontAwesomeModule
  ]
})

export class ConfigurationModule { }
