import { Component, ViewChild } from '@angular/core';
import { AuthService } from "app/core/services/auth/auth.service";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { faChartPie, faCloudDownloadAlt, faTimes, faFileExcel, faPlus } from "@fortawesome/free-solid-svg-icons";
import { functions } from '../../core/helpers/functions';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
@Component({
    template: ''
})
export class BaseLavadoActivosComponent {
    public modalRef: any;
    public modalConfig: any = {
        ariaLabelledBy: "modal-basic-title",
        size: "xl",
        windowClass: "modalSizeXXL"
    }
    faTimes = faTimes;
    faFileExcel = faFileExcel;
    faPlus = faPlus;
    faCloudDownloadAlt = faCloudDownloadAlt;
    faChartPie = faChartPie;
    public requestService: (filters: Record<string, string>) => any
    public extraFunctionService: (data) => any;
    public rowData: any[] = [];
    public loadingTable: boolean = false;
    public columnDefs: columnHeader[];
    public pageTableActual: number;
    public pageTableTotal: number;
    public pageTablePerPages: number = 10;
    public pageTablePages: number[];
    public searchstring: string = "";
    public toDelete: number;
    public user: any;
    public filters: Record<string, string> = {}
    public editModal

    constructor(
        public authService: AuthService,
        public modalService: NgbModal,
    ) {
        this.user = this.authService.user;
    }
    ngOnInit(): void {
        this.executeService();
    }
    initVars() { }

    loadTable(data: { page_number: number; count: number; per_page: number; num_pages: number; }) {

        this.rowData = functions.indextable(data)
        this.pageTableActual = data.page_number;
        this.pageTableTotal = data.count;
        this.pageTablePerPages = data.per_page;
        this.pageTablePages = [];
        for (let index = 0; index < data.num_pages; index++) {
            this.pageTablePages.push(index);
        }
    }

    executeService() {
        let data: any
        try{
            data = this.requestService(this.filters)
        }
        catch(e){
            if ( e instanceof TypeError){
                throw new Error("Quizas falta definir requestService en tu componente.");
            }
        }
        if (data){
            Promise.all([data]).then((res: any[]) => {
                data = res[0]
                if (this.extraFunctionService){
                    data['results'] = this.extraFunctionService(res[0])
                }
                this.initVars();
                console.log(data)
                this.loadTable(data);
            });
        }
       
    }

    openModal(modal, properties) {
        this.modalRef = this.modalService.open(
            modal,
            this.modalConfig
        );
        for (const key in properties) {
            this.modalRef.componentInstance[key] = properties[key]
        }
        this.modalRef.result.then((result: any) => {
            this.executeService();
        }, (reason: any) => {
            this.executeService();
        });
    }

    tableEditEvent(row: any) {
        this.openModal(this.editModal, {'instance_row': row})
    }

    get canEdit() {
        const perfilesPermitidos = [
            
        ];
        return perfilesPermitidos.indexOf(this.authService.user.perfil) !== -1;
    }
}