import {
  Component,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import Swal from 'sweetalert2';
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { AuthService } from "app/core/services/auth/auth.service";
import { RecaudacionService } from "app/core/services/factoring/recaudacion.service";
import { ParametrosService } from 'app/core/services/configuration/parametros.service';
import { ConfirmModalComponent } from "app/shared/utils/confirm-modal/confirm-modal.component";
import { CuentasXPagarComponent } from "../cuentas-x-pagar/cuentas-x-pagar.component";
import { Perfil } from "app/core/services/auth/Perfil";
import { DevolverExcedentesModalComponent } from "../devolver-excedentes-modal/devolver-excedentes-modal.component";
import { ToastService } from "app/core/services/toast/toast.service";
import { BehaviorSubject, concat, Observable, of, Subject } from 'rxjs';
import {
  filter,
  distinctUntilChanged,
  debounceTime,
  tap,
  switchMap,
  catchError,
} from "rxjs/operators";
import { FactoringService } from "app/core/services/factoring/factoring.service";
import { ResultadoCavaliComponent } from "app/shared/resultado-cavali/resultado-cavali.component";
import { NotasCreditoRecaudacionComponent } from "../notas-credito-recaudacion/notas-credito-recaudacion.component";
import { faTimes, faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";
import { Router } from '@angular/router';
import { DocumentosCarteraService } from '../../../../core/services/factoring/documentos-cartera.service';
import { CuentasService } from '../../../../core/services/cuentas-x-cobrar/cuentas.service';
import { EditarDetalleComponent } from "../editar-detalle/editar-detalle.component";

interface ExcedenteDevolver {
  id_devolver: number;
  monto_exceso: string;
  tipo_pagar: number;
}

@Component({
  selector: "app-add-recaudacion-modal",
  templateUrl: "./add-recaudacion-modal.component.html",
  styleUrls: ["./add-recaudacion-modal.component.css"],
})
export class AddRecaudacionModalComponent implements OnInit {
  faTimes = faTimes
  faCloudUploadAlt = faCloudUploadAlt
  private modalDocumentsCarteraReference     : any;
  public facturar_data: number;
  public noBank                              : boolean           = true;
  public estadoFacturacion                   : boolean           = false;
  public tipos                               : any[]             = [];
  public beneficiariosList                   : any[]             = [];
  public deudoresList                        : any[]             = [];
  public pagadoresList                       : any[]             = [];
  public medios                              : any[]             = [];
  public cuentasRecaudadoras                 : any[]             = [];
  public cuentasRecaudadorasFilter           : any[]             = [];
  public bancos                              : any[]             = [];
  public monedas                             : any[]             = [];
  public documentsCarteraList                : any[]             = [];
  public documentsCarteraSelectedList        : any[]             = [];
  public documentsCarteraSelectionList       : any[]             = [];
  public excedentesList                      : any[]             = [];
  public headerDocumentsTable                : columnHeader[];
  public headerExcedentesTable               : columnHeader[];
  public headerDocumentsCarteraTable         : columnHeader[];
  public pageDocumentsCarteraTableActual     : number;
  public pageDocumentsCarteraTableTotal      : number;
  public pageDocumentsCarteraTablePerPages   : number            = 10;
  public pageDocumentsCarteraTablePages      : number[];
  public minDate                             : Date;
  public cabeceraSolicitudForm               : FormGroup;
  public bodySolicitudForm                   : FormGroup;
  public user                                : any;
  public canAprobe                           : any               = null;
  public excedente                           : any               = null;
  public sumaDocumentos                      = 0;
  estadosRecaudacion                         : any[];
  public cabeceraMoneda                      : any;
  public monedaPrincipal                     : any;
  public checksDisabled = false;

  @ViewChild("modal_periodo_cierre", { static: false }) public modal_periodo_cierre;
  @ViewChild("e_documentscartera", { static  : false }) public e_documentscartera;
  @Input() recaudacion                       : any               = [];
  @Input() recaudacionId                     = 0;
  @Input() totalDocumentos                   : any;
  @Input() totalExcedentes                   : any;
  @Input() openFromExcedentes                = false;
  @Input() liquidacion: any;
  @Input() abono: any;

  @Output() reloadList                       : EventEmitter<any> = new EventEmitter<any>();
  @Output() successEmit                      : EventEmitter<any> = new EventEmitter<any>();

  readOnlyForm = false;
  readOnlyButtonActualizar = false;
  readOnlyButtonAprobar    = false;
  readOnlyButtonDocs       = false;

  perfil                   = new Perfil();

  beneficiarioSelect       : any;
  searchTextBene           = "";
  beneResults$             : Observable<any[]>;
  searchText$              = new Subject<string>();
  loadingSearch            : boolean = false;
  caracteresMinimos        : number  = 4;

  deudorSelect             : any;
  searchTextDeudor         = "";
  deudorResults$           : Observable<any[]>;
  searchTextDeudor$        = new Subject<string>();

  empresas                 : any[]   = [];

  refreshNotaCreditoTable  = false;
  listNotasCredito         : any[]   = [];

  actInputExc: boolean;
  
  tipoEstado: number;

  tipoCambio: any = null
  tiposCambio: any[] = []
  maxFechaPago = new Date()
  tiposBasePorcentaje = [{id: 1, descripcion: 'Total financiado'}, {id: 2, descripcion: 'Total neto'}]

  pagadores$: Observable<any>
  pagadoresInput$ = new Subject<string>()
  pagadoresLoading = false
  otroPropietario = undefined

  actualizandoDatos = false

  constructor(
    private modalService      : NgbModal,
    private formBuilder       : FormBuilder,
    public localeService      : BsLocaleService,
    private recaudacionService: RecaudacionService,
    private authService       : AuthService,
    public activeModal        : NgbActiveModal,
    public toast              : ToastService,
    private factoringService  : FactoringService,
    private parametrosService : ParametrosService,
    private documentosCarteraService : DocumentosCarteraService,
    private cuentasService : CuentasService,
    private router: Router
  ) {
    defineLocale("es", esLocale);
    this.localeService.use("es");
    this.user = this.authService.user;
  }

  async ngOnInit() {
    this.actInputExc = this.router.url.split('/')[1] == 'excedentes' ? true : false;
    this.crearCabecera();

    this.tipoCambio = await this.factoringService.obtenerTipoDeCambio()
    
    await this.obtenerData();
    if (this.recaudacionId) {
      this.recaudacionId = this.recaudacionId;
      await this.obtenerRecaudacion();
      if (this.recaudacion && this.recaudacion.estado != 1) {
        this.checksDisabled = true
        this.obtenerExcedentes();
      }
      const idSelect =
        this.recaudacion.tipo_pagador == 1
          ? this.recaudacion.aceptante
          : this.recaudacion.cliente;
      this.cabeceraSolicitudForm.controls["tipo"].setValue(
        this.recaudacion.tipo_pagador
      );
      this.cabeceraSolicitudForm.controls["pagador"].setValue(idSelect);
      this.cabeceraSolicitudForm.controls["pagador_name"].setValue(
        this.recaudacion["pagador_nombre"]
      );
      if (this.recaudacion.tipo_pagador == 1) {
        this.searchTextDeudor$ = new BehaviorSubject<string>(
          this.recaudacion.pagador_nombre
        );
      } else {
        this.searchText$ = new BehaviorSubject<string>(
          this.recaudacion.pagador_nombre
        );
      }
    }

    // Restricción de fechas, en aplicaciones reales, no en simulaciones
    if (this.recaudacionId && this.recaudacion) {
      if (!(this.recaudacion.abono || this.recaudacion.excedente || this.recaudacion.liquidacion)) {
        this.maxFechaPago = null
      }
    } else {
      if (!(this.abono || this.openFromExcedentes || this.liquidacion)) {
        this.maxFechaPago = null
      }
    }

    this.medios = this.filterUserTeroreria(this.medios);

    if (this.liquidacion) this.initModalLiquidacion()
    if (this.abono) this.initModalAbono()

    this.initTables();

    this.loadSeachBeneficiarios();
    this.loadSeachDeudores();
  }

  loadSeachBeneficiarios() {
    this.beneResults$ = concat(
      of([]), // Items predeterminados
      this.searchText$.pipe(
        filter((res) => {
          return res !== null && res.length >= this.caracteresMinimos;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.loadingSearch = true)),
        switchMap((term) => {
          return this.recaudacionService
            .obtenerBeneficiariosObserver(term)
            .pipe(
              catchError(() => of([])), // empty list on error
              tap(() => (this.loadingSearch = false))
            );
        })
      )
    );
  }

  loadSeachDeudores() {
    this.deudorResults$ = concat(
      of([]), // Items predeterminados
      this.searchTextDeudor$.pipe(
        filter((res) => {
          return res !== null && res.length >= this.caracteresMinimos;
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => (this.loadingSearch = true)),
        switchMap((term) => {
          return this.recaudacionService.obtenerDeudoresObserver(term).pipe(
            catchError(() => of([])), // empty list on error
            tap(() => (this.loadingSearch = false))
          );
        })
      )
    );
  }

  async loadTiposCambio() {
    let tipoCambioActual = this.cabeceraSolicitudForm.get('tipo_cambio_monto').value
    let fechaPago = this.formatDate(this.cabeceraSolicitudForm.get('fecha_pago').value)
    if (!fechaPago) return
    this.tipoCambio = await this.factoringService.obtenerTipoDeCambio(fechaPago)
    this.tiposCambio = Object.entries(this.tipoCambio)
      .filter(e => ['compra', 'venta', 'contable'].includes(e[0]))
      .map(e => ({descripcion: e[0], valor: e[1]}))
    if (tipoCambioActual) {
      if (!this.tiposCambio.map(e => Number(e['valor'])).includes(Number(tipoCambioActual))) {
        this.tiposCambio.push(this.addTipoCambio(tipoCambioActual))
      }
    } else {
      this.selectTipoCambioPorMoneda(this.cabeceraSolicitudForm.get('moneda').value)
    }
  }

  disableFields() {
    // solo es llamado para aplicacion de excedentes
    setTimeout(() => {
      this.cabeceraSolicitudForm.get('periodo_cerrado').disable()
      this.cabeceraSolicitudForm.get('cuenta_numero').setValidators([])
      this.cabeceraSolicitudForm.get('cuenta').setValidators([])
      this.cabeceraSolicitudForm.get('nro_pago').setValidators([])
      this.cabeceraSolicitudForm.get('nro_pago').disable()
      this.cabeceraSolicitudForm.get('medio').disable()
      this.cabeceraSolicitudForm.get('pagador').disable()
      this.cabeceraSolicitudForm.get('moneda').disable()
      this.cabeceraSolicitudForm.get('monto').enable()
      // this.cabeceraSolicitudForm.get('fecha_pago').disable()
      this.cabeceraSolicitudForm.get('tipo').disable()
      this.cabeceraSolicitudForm.get('cuenta_numero').disable()
      this.cabeceraSolicitudForm.get('cuenta').disable()

      if (this.abono) {
        this.cabeceraSolicitudForm.get('fecha_solicitud').disable()
        this.cabeceraSolicitudForm.get('fecha_pago').disable()
      }
    }, 500)
  }

  initModal(excedente) {
    this.excedente = excedente;
    let tiposPagador = this.recaudacionService.obtenerTiposPagador();
    //let deudores = this.recaudacionService.obtenerDeudores(1, 100);
    //let beneficiarios = this.recaudacionService.obtenerBeneficiarios(1, 100);
    let tipoPago = !!this.excedente.cliente ? 2 : 1;

    // this.noBank = false;
    this.disableFields();
    Promise.all([tiposPagador])
      .then((res: any[]) => {
        this.tipos = res[0].results;
        //this.beneficiariosList = res[1].results;
        //this.deudoresList = res[2].results;
      })
      .finally(() => {
        this.ngOnInit();
        this.disableFields();
        this.selectedTipoEvent(tipoPago);
        this.cabeceraSolicitudForm.get("moneda").setValue(excedente.moneda);
        this.cabeceraSolicitudForm.get("tipo").setValue(tipoPago);
        this.cabeceraSolicitudForm.get("fecha_pago").setValue(new Date());
        this.cabeceraSolicitudForm.get("fecha_solicitud").setValue(new Date());
        this.cabeceraSolicitudForm.get('empresa').setValue(excedente.empresa);

        this.cabeceraSolicitudForm
          .get("monto")
          .setValue(
            excedente.moneda == 1
              ? excedente.saldo_soles
              : excedente.saldo_dolares
          );
        this.cabeceraSolicitudForm.get("nro_pago").setValue("Aplicación");
        this.cabeceraSolicitudForm.get("medio").setValue(-2);
        this.cabeceraSolicitudForm
          .get("pagador")
          .setValue(tipoPago == 1 ? excedente.aceptante : excedente.cliente);
        this.cabeceraSolicitudForm
          .get("pagador_name")
          .setValue(
            tipoPago == 1
              ? excedente.aceptante_nombre
              : excedente.cliente_nombre
          );
      });
  }

  private initModalLiquidacion() {
    if (!(this.recaudacion && this.recaudacion.id)) {
      this.selectedTipoEvent(2) // CLIENTE
      this.cabeceraSolicitudForm.get("moneda").setValue(this.liquidacion.moneda);
      // this.cabeceraSolicitudForm.get("tipo_cambio_monto").setValue(this.liquidacion.tipo_cambio_monto);
      this.cabeceraSolicitudForm.get("tipo").setValue(2); // CLIENTE
      this.cabeceraSolicitudForm.get("fecha_pago").setValue(this.parseDate(this.liquidacion.fecha_desembolso_programado));
      this.cabeceraSolicitudForm.get("fecha_contable").setValue(this.parseDate(this.liquidacion.fecha_desembolso_programado));
      this.cabeceraSolicitudForm.get("fecha_solicitud").setValue(new Date());
      // this.cabeceraSolicitudForm.get('empresa').setValue(excedente.empresa);
      this.cabeceraSolicitudForm.get("monto").setValue(this.liquidacion.monto_desembolso);
      this.cabeceraSolicitudForm.get("nro_pago").setValue(this.liquidacion.operacion.id);
      this.cabeceraSolicitudForm.get("medio").setValue(-1); // LIQUIDACION
      this.cabeceraSolicitudForm.get("pagador").setValue(this.liquidacion.operacion.beneficiario);
      this.cabeceraSolicitudForm.get("pagador_name").setValue(this.liquidacion.operacion.beneficiario_nombre);
    }
    this.disableFields()
  }

  private initModalAbono() {
    if (!(this.recaudacion && this.recaudacion.id)) {
      this.selectedTipoEvent(this.abono.pagador.tipo)
      this.cabeceraSolicitudForm.get("moneda").setValue(this.abono.moneda);
      this.cabeceraSolicitudForm.get("tipo").setValue(this.abono.pagador.tipo);
      this.cabeceraSolicitudForm.get("fecha_pago").setValue(new Date(`${this.abono.fecha}T00:00`));
      this.cabeceraSolicitudForm.get("fecha_contable").setValue(new Date());
      this.cabeceraSolicitudForm.get("fecha_solicitud").setValue(new Date());
      this.cabeceraSolicitudForm.get("monto").setValue(this.abono.saldo);
      this.cabeceraSolicitudForm.get("nro_pago").setValue(this.abono.id);
      this.cabeceraSolicitudForm.get("medio").setValue(-3); // APLICACIÓN DE ABONOS
      this.cabeceraSolicitudForm.get("pagador").setValue(this.abono.pagador.id);
      this.cabeceraSolicitudForm.get("pagador_name").setValue(this.abono.pagador_nombre);

      this.selectedMonedaEvent(this.abono, true);

      this.cabeceraSolicitudForm.get('cuenta').setValue(this.abono.cuenta)
      this.cabeceraSolicitudForm.get('cuenta_numero').setValue(this.abono.cuenta_numero)
    }
    this.disableFields()
  }

  async obtenerRecaudacion() {
    await this.recaudacionService
      .obtenerRecaudacion(this.recaudacionId)
      .then((res) => {
        this.recaudacion = res;
        this.selectedTipoEvent(res["tipo_pagador"]);
        this.selectedMonedaEvent(res, true);
        this.obtenerDocumentosDetalle();
        this.obtenerNotasCreditos();

        this.excedentesList = this.mapperExcedentes(
          res["excedentes_simulados"]
        );

        this.setForm(res);

        if (this.recaudacion.estado == 2 || this.recaudacion.estado == 3) {
          this.readOnlyForm = true;
          this.readOnlyButtonDocs = true;
          this.readOnlyButtonActualizar = true;
          this.readOnlyButtonAprobar = true;
        }

        if ((this.user.isOficialDeNegocio && !this.liquidacion) || this.user.isConsultas) {
          this.readOnlyForm = true;
          this.readOnlyButtonDocs = true;
          this.readOnlyButtonActualizar = true;
          this.readOnlyButtonAprobar = true;
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  initTables() {
    let tipoCambio:any = Number(this.cabeceraSolicitudForm.get('tipo_cambio_monto').value || this.tipoCambio?.contable)
    console.log("TC", tipoCambio)
    let headerDocumentsTable = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
        pipe: "indexcol",
      },
      {
        class: "text-center",
        headerName:
          this.recaudacion && this.recaudacion["tipo_pagador"] == 1
            ? "Cliente"
            : "Aceptante",
        field: "nombre",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "tipo_documento_descripcion",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Nro Documento",
        field: "numero_documento",
      },
      {
        class: "text-center",
        headerName: "Producto",
        field: "tipo_producto_descripcion",
      },
      {
        class: "text-center",
        headerName: "Saldo Neto",
        field: "saldo_documento",
        pipe: "currency",
        moneda: "moneda_documento",
        sortable: true,
        sumCol: true,
        tipoCambio: tipoCambio,
        moneda_detail: this.monedaCabecera,
      },
      {
        class: "text-center",
        headerName: "Saldo Financiado",
        field: "saldo_financiado_documento",
        pipe: "currency",
        moneda: "moneda_documento",
        sortable: true,
        sumCol: true,
        tipoCambio: tipoCambio,
        moneda_detail: this.monedaCabecera,
      },
      // {
      //   class: "text-center",
      //   headerName: "Tipo cambio Liq.",
      //   field: "tipo_cambio_monto",
      //   // pipe:  this.router.url.split('/')[1] == 'excedentes' ? "input_camb": '',
      //   sortable: true,
      // },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency_detail",
        moneda_detail: this.monedaCabecera,
        sortable: true,
        sumCol: true,
        tipoCambio: tipoCambio,
      },
      {
        class: "text-center",
        headerName: "Pago",
        field: "pago",
        pipe: "currency_detail",
        moneda_detail: this.monedaCabecera,
        sortable: true,
        sumCol: true,
        tipoCambio: tipoCambio,
        // inputExcedente: this.actInputExc
      },
      // {
      //   class: "text-center",
      //   headerName: "Monto especifico",
      //   field: "monto_esp",
      //   pipe: "input",
      //   sortable: true,
      //   tipoCambioField: 'tipo_cambio_monto',
      //   inputExcedente: this.actInputExc
      // },
      {
        class: "text-center",
        headerName: "Nuevo Saldo",
        field: "saldo",
        pipe: "currency_detail",
        moneda_detail: this.monedaCabecera,
        sortable: true,
        sumCol: true,
        tipoCambio: tipoCambio,
      },
      {
        class: "text-center",
        headerName: "Pago del neto",
        field: "pago_neto",
        pipe: "currency",
        moneda: "moneda_documento",
        sortable: true,
        sumCol: true,
        tipoCambio: tipoCambio,
        moneda_detail: this.monedaCabecera,
      },
      {
        class: "text-center",
        headerName: "Pago del financiado",
        field: "pago_financiado",
        pipe: "currency",
        moneda: "moneda_documento",
        sortable: true,
        sumCol: true,
        tipoCambio: tipoCambio,
        moneda_detail: this.monedaCabecera,
      },
      {
        headerName: "Fecha Vencimiento",
        field: "fecha_vencimiento",
        pipe: "date",
        class: "text-center",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Prorrogar",
        field: "prorrogar",
        pipe: "checkbox",
        function: function (row) {
          // si regresa true el checkbox se inactiva, si regresa false el checkbox queda habilitado
          return !(!!row['documento_cartera'] && Number(row['pago_financiado']) < Number(row['saldo_financiado_documento']))
        },
      },
      {
        headerName: "Estado CAVALI",
        field: "estado_cavali",
        sortable: true,
        class: "text-center",
        pipe: "buttonElement",
        function: function (row): any {
          if (row.estado_cavali) {
            return `${row.estado_cavali_descripcion}`;
          }
        },
      },
      {
        class: "text-center",
        headerName: "Monto mora",
        field: "monto_mora",
      },
      {
        class: "text-center",
        headerName: "Motivo monto mora",
        field: "motivo_monto_mora_descripcion",
      },
      {
        class: "text-center",
        headerName: "Tasa mora",
        field: "tasa_mora",
      },
      {
        class: "text-center",
        headerName: "Motivo tasa mora",
        field: "motivo_tasa_mora_descripcion",
      },
      {
        class: "text-center",
        headerName: "Monto Mora Generado",
        field: "monto_mora_generado",
      },
      {
        class: "text-center",
        headerName: "Monto a pagar",
        field: "monto_pagar",
      },
    ];
    let headerExcedentesTable = [
      {
        headerName: "N°",
        field: "index",
        pipe: "indexcol",
      },
      {
        class: "text-center",
        headerName:
          this.recaudacion && this.recaudacion["tipo_pagador"] == 1
            ? "Cliente"
            : "Aceptante",
        field: "nombre",
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "tipo",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Nro Documento",
        field: "nro_documento",
      },
      {
        class: "text-center",
        headerName: "Monto Neto",
        field: "monto_documento",
        pipe: "currency",
        moneda: "moneda",
        sumCol: true,
        tipoCambio: tipoCambio,
        tipoCambioField: 'tipo_cambio_monto',
        moneda_detail: this.monedaCabecera,
      },
      {
        class: "text-center",
        headerName: "Financiado",
        field: "financiado",
        pipe: "currency",
        moneda: "moneda",
        sumCol: true,
        tipoCambio: tipoCambio,
        tipoCambioField: 'tipo_cambio_monto',
        moneda_detail: this.monedaCabecera,
      },
      {
        class: "text-center",
        headerName: "Interés Devuelto",
        field: "intereses_devolver",
        pipe: "currency",
        moneda: "moneda",
        sumCol: true,
        tipoCambio: tipoCambio,
        tipoCambioField: 'tipo_cambio_monto',
        moneda_detail: this.monedaCabecera,
      },
      {
        class: "text-center",
        headerName: "Excedente",
        field: "excedente",
        pipe: "currency",
        moneda: "moneda",
        sumCol: true,
        tipoCambio: tipoCambio,
        tipoCambioField: 'tipo_cambio_monto',
        moneda_detail: this.monedaCabecera,
      },
      {
        class: "text-center",
        headerName: "IGV",
        field: "igv_intereses",
        pipe: "currency",
        moneda: "moneda",
        sumCol: true,
        tipoCambio: tipoCambio,
        tipoCambioField: 'tipo_cambio_monto',
        moneda_detail: this.monedaCabecera,
      },
      {
        class: "text-center",
        headerName: "Interés Mora",
        field: "intereses_mora",
        pipe: "currency",
        moneda: "moneda",
        sumCol: true,
        tipoCambio: tipoCambio,
        tipoCambioField: 'tipo_cambio_monto',
        moneda_detail: this.monedaCabecera,
      },
      {
        class: "text-center",
        headerName: "Devolver",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
        sumCol: true,
        tipoCambio: tipoCambio,
        tipoCambioField: 'tipo_cambio_monto',
        moneda_detail: this.monedaCabecera,
      },
    ];
    let headerDocumentsCarteraTable:any[] = [
      {
        headerName: "N°",
        field: "id",
        sortable: true,
        pipe: "indexcol",
      },
      {
        class: "text-center",
        headerName: "Fecha Vencimiento",
        field: "fecha_vencimiento",
        pipe: "date",
        sortable: true,
        filterable: true,
				filterDate: true,
      },
      {
        headerName: 'Moneda',
        field: 'moneda_descripcion',
        class: 'text-center',
        pipe: 'function',
        function: row => row.moneda == 1 ? 'S/' : '$',
        filterable: true,
        filterProp: 'moneda',
        filterSelectItems: this.monedas,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Monto a pagar (saldo)",
        field: "saldo",
        pipe: "currency",
        sortable: true,
        filterable: true,
        filterProp: 'saldo',
        filterRange: true,
      },
      {
        class: "text-center",
        headerName: "Prrg",
        field: "prorrogado_confirmacion",
        filterable: true,
        filterProp: 'prorrogado',
        filterSelectItems: [
          {id: 1, descripcion: 'Si'},
          {id: 0, descripcion: 'No'},
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: this.recaudacion && this.recaudacion["tipo_pagador"] == 1
          ? "Cliente"
          : "Aceptante",
        field: this.recaudacion && this.recaudacion["tipo_pagador"] == 1
          ? "beneficiario_nombre"
          : "aceptante_nombre",
        sortable: true,
        filterable: true,
        filterProp: this.recaudacion && this.recaudacion["tipo_pagador"] == 1
          ? "beneficiario_ruc_nombre__icontains"
          : "deudor_ruc_nombre__icontains",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Nro documento",
        field: "operacion_numero_documento",
        filterable: true,
        filterProp: "operacion_detalle__numero_documento__icontains",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Tipo documento",
        field: "operacion_tipo_detalle_descripcion",
        sortable: true,
        filterable: true,
        filterProp: "operacion_detalle__tipo_detalle",
        filterSelectItems: [
          {id: 1, descripcion: 'Factura'},
          {id: 2, descripcion: 'Letra'},
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Producto",
        field: "tipo_producto_descripcion",
        sortable: true,
        filterable: true,
        filterProp: "tipo_linea",
        filterSelectItems: [
          // Obtener desde backend, por servicio de lineas, no existe servicio
          // Crear método para obtener, pero lleva a BOILERPLATE CODE, se deja en hardcode
          {id: 1, descripcion: 'Descuento'},
          {id: 2, descripcion: 'Factoring'},
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Operación",
        field: "operacion",
        sortable: true,
        filterable: true,
        filterProp: "operacion",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Monto documento",
        field: "monto_neto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        filterable: true,
        filterProp: 'monto_neto',
        filterRange: true
      },
      {
        class: "text-center",
        headerName: "Saldo Financiado",
        field: "saldo_financiado",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        filterable: true,
        filterProp: 'saldo_financiado',
        filterRange: true
      },
    ];

    // if( this.router.url.split('/')[1] == 'excedentes' ){
    //   headerDocumentsCarteraTable.push({
    //     class: "text-center",
    //     headerName: "Tipo cambio.",
    //     field: "tipo_cambio_monto",
    //     pipe:  "input_camb",
    //     sortable: true,
    //   })
    // }

    // se agrega el checkbox para elegir si el documento se va a pagar hacia el financiado
    if (this.recaudacion && this.recaudacion.tipo_pagador === 1) { // tipo_pagador ACEPTANTE
      const pago_financiado = {
        class: "text-center",
        headerName: "Pago al financiado",
        field: "pagar_financiado",
        pipe: "checkbox",
        function: function (row) {
          // si regresa true el checkbox se inactiva, si regresa false el checkbox queda habilitado
          return row.documento_cartera ? false : true
        },
        sortable: true,
      };
      headerDocumentsTable.splice(12, 0, pago_financiado)
    }

    this.headerDocumentsTable = headerDocumentsTable;
    this.headerExcedentesTable = headerExcedentesTable;
    this.headerDocumentsCarteraTable = headerDocumentsCarteraTable;
  }

  crearCabecera() {
    this.minDate = new Date();

    let validatorsMonto = [Validators.required, Validators.min(0.01)]
    if (this.abono) {
      validatorsMonto.push(Validators.max(this.abono.saldo))
    }

    this.cabeceraSolicitudForm = this.formBuilder.group({
      empresa        : [{ value: 2, disabled: true }, [Validators.required]],
      tipo           : [ 1, [Validators.required]],
      pagador        : [null, [Validators.required]],
      fecha_solicitud: [new Date()],
      fecha_pago     : [null, [Validators.required]],
      fecha_contable : [new Date(), [Validators.required]],
      nro_pago       : [null, []],
      moneda         : [null, [Validators.required]],
      monto          : [null, validatorsMonto],
      estado         : [1, []],
      pagador_name   : ['', []],
      medio          : [null, [Validators.required]],
      cuenta         : [null, [Validators.required]],
      cuenta_numero  : [null, [Validators.required]],
      periodo_cerrado: [false, []],
      tipo_cambio_monto: [null, [Validators.required]],
      porcentaje_pago: [{value: null, disabled: true}, [Validators.required, Validators.min(0.01)]],
      base_porcentaje: [{value: 1, disabled: true}, [Validators.required]],
      es_pago_porcentaje: [false, []],
    }, { validators: (group: AbstractControl):  ValidationErrors | null => { 
      let fechaPago = group.get('fecha_pago').value
      let fechaContable = group.get('fecha_contable').value
      if (!fechaContable || ! fechaPago) return null
      fechaPago = new Date(fechaPago.setHours(0, 0, 0, 0))
      fechaContable = new Date(fechaContable.setHours(0, 0, 0, 0))
      return fechaContable && fechaPago && fechaContable.getTime() >= fechaPago.getTime() ? null : { fechaContableAnterior: true }
    }});

    this.cabeceraSolicitudForm.get('fecha_pago').valueChanges.subscribe(
      value => this.loadTiposCambio(),
      res => console.log(res),
    )
    this.cabeceraSolicitudForm.get('es_pago_porcentaje').valueChanges.subscribe(
      value => {
        if (value) {
          this.cabeceraSolicitudForm.get('porcentaje_pago').enable()
          this.cabeceraSolicitudForm.get('base_porcentaje').enable()
        } else {
          this.cabeceraSolicitudForm.get('porcentaje_pago').disable()
          this.cabeceraSolicitudForm.get('base_porcentaje').disable()
          this.cabeceraSolicitudForm.get('porcentaje_pago').setValue(null)
        }
      },
      res => console.log(res),
    )
  }

  setForm(data) {
    const mapperData = {
      tipo_cambio_monto: data.tipo_cambio_monto, // Antes de cambiar fecha_pago
      tipo           : data.tipo_pagador,
      pagador        : (data.tipo_pagador == 1) ? data.aceptante: data.cliente,
      fecha_solicitud: data.fecha_registro,
      fecha_pago     : data.fecha_pago,
      nro_pago       : data.numero_pago,
      moneda         : data.moneda,
      monto          : data.monto,
      estado         : data.estado,
      medio          : data.forma_pago,
      cuenta         : data.cuenta,
      cuenta_numero  : data.cuenta_numero,
      periodo_cerrado: data.periodo_cerrado,
      empresa        : data.empresa,
      fecha_contable : data.fecha_contable,
      porcentaje_pago: data.porcentaje_pago,
      base_porcentaje: data.base_porcentaje || 1,
      es_pago_porcentaje: !!data.porcentaje_pago,
    };
    
    for (const key in mapperData) {
      if (this.cabeceraSolicitudForm.controls[key]) {
        if (key == "fecha_pago" || key == "fecha_contable") {
          this.cabeceraSolicitudForm.controls[key].setValue(
            this.parseDate(mapperData[key])
          );
          continue;
        }

        if (key == "fecha_solicitud") {
          let yy = mapperData[key].split("-")[0];
          let mm = mapperData[key].split("-")[1];
          let dd = mapperData[key].split("-")[2];
          let date = `${yy}-${mm}-${dd.substring(0, 2)}`;

          this.cabeceraSolicitudForm.controls[key].setValue(
            this.parseDate(date)
          );
          continue;
        }
        
        this.cabeceraSolicitudForm.controls[key].setValue(mapperData[key]);
      }
      

    }
  }

  compararTipos(tipo1: any, tipo2: any) {
    return tipo1 && tipo2 ? tipo1.id === tipo2 : tipo1 === tipo2;
  }

  compararPagadores(pagador1: any, pagador2: any) {
    return pagador1 && pagador2
      ? pagador1.id === pagador2
      : pagador1 === pagador2;
  }

  async obtenerData() {
    let tiposPagador = await this.recaudacionService.obtenerTiposPagador();
    // let beneficiarios = await this.recaudacionService.obtenerBeneficiarios();
    // let deudores = await this.recaudacionService.obtenerDeudores();
    let medios = await this.recaudacionService.obtenerFormasPago({ordering: '-descripcion'});
    let cuentasRecaudadoras = await this.recaudacionService.obtenerCuentasRecaudacion();
    let bancos = await this.recaudacionService.obtenerBancosRecaudacion();
    let monedas = await this.recaudacionService.obtenerMonedas();
    let estados = await this.recaudacionService.obtenerEstadosRecaudacion();
    let empresas = this.factoringService.obtenerEmpresas();

    await Promise.all([
      tiposPagador,
      medios,
      cuentasRecaudadoras,
      bancos,
      monedas,
      estados,
      empresas,
    ]).then((res: any[]) => {
      this.tipos = res[0].results;
      // this.beneficiariosList = res[1].results;
      // this.deudoresList = res[2].results;
      this.medios = res[1].results;
      this.cuentasRecaudadoras = res[2].results;
      this.bancos = res[3].results;
      this.monedas = res[4].results;
      this.estadosRecaudacion = res[5].results;

      this.empresas = res[6]['results'];

    });
  }

  selectedTipoEvent(tipo) {
    this.cabeceraSolicitudForm.controls["pagador"].setValue(null);
    const ID = tipo.id ? tipo.id : tipo;
    switch (ID) {
      case 1:
        this.pagadoresList = this.deudoresList;
        break;
      case 2:
        this.pagadoresList = this.beneficiariosList;
        break;
      default:
        this.pagadoresList = [];
        break;
    }

    this.pagadoresList.sort(function (a, b) {
      if (a.nombre > b.nombre) {
        return 1;
      }
      if (a.nombre < b.nombre) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
  }

  selectedMonedaEvent(moneda, init = false) {
    this.cabeceraSolicitudForm.controls["cuenta"].setValue(null);
    this.cabeceraSolicitudForm.controls["cuenta_numero"].setValue(null);
    let empresa = this.cabeceraSolicitudForm.controls['empresa'].value;

    const monedaID = init ? moneda.moneda : moneda.id;
    empresa = empresa ? empresa : moneda.empresa;

    this.cuentasRecaudadorasFilter = this.cuentasRecaudadoras.filter(
      (item) => (item.moneda == monedaID && item.empresa == empresa)
    );

    if (!init) {
      this.selectTipoCambioPorMoneda(moneda.id)
    }
  }

  changeEmpresa(empresa) {
    this.cabeceraSolicitudForm.controls["cuenta"].setValue(null);
    this.cabeceraSolicitudForm.controls["cuenta_numero"].setValue(null);
    this.cabeceraSolicitudForm.controls["moneda"].setValue(null);
  }

  selectedBancoEvent(cuenta) {
    const { moneda } = this.cabeceraSolicitudForm.value;

    this.cabeceraSolicitudForm.controls["cuenta_numero"].setValue(cuenta.numero);
  }

  //TIPO DE CAMBIO MODIFICADO
  async modificarTipoCambio( data:any ){

    await this.documentosCarteraService.modificarTipoCambio( data.id, { tipo_cambio_monto: data.tipo_cambio_monto } )
    
  }

  getCabeceraData() {
    this.cabeceraSolicitudForm.enable();

    // Desconstruimos los datos de la cabecera de la recaudacion
    const {
      tipo,
      pagador,
      fecha_pago,
      fecha_contable,
      nro_pago,
      empresa,
      monto,
      estado,
      medio,
      cuenta,
      periodo_cerrado,
      moneda,
      tipo_cambio_monto,
      porcentaje_pago,
      base_porcentaje,
    } = this.cabeceraSolicitudForm.value;

    // solo es llamado para aplicacion de excedentes
    // validar que el monto de la aplicacion sea menor al monto del excedente
    if (this.excedente) {
      const montoExcedente = this.excedente.moneda === 1
        ? this.excedente.saldo_soles
        : this.excedente.saldo_dolares

      if (monto == 0) {
        this.toast.warning(
          `El monto a aplicar debe ser mayor a 0`
        )
        return null
      }
      if (parseFloat(monto) > parseFloat(montoExcedente)) {
        this.toast.warning(
          `El monto a aplicar debe ser menor al excedente total: ${montoExcedente}`
        )
        return null
      }
    }

    this.monedaPrincipal = moneda;

    // Buscamos dentro de la lista de cuentas, la cuenta seleccionada en la cabecera de la recaudacion
    let cuentaRecaudo = this.cuentasRecaudadoras.find((cuentaR) => cuentaR.id == cuenta);
    // los medios de pago especiales no tienen cuentaRecaudo
    const mediosPagoEspeciales = [
      -2, // Excedentes
      -1, // Liquidacion
    ]

    // Mapeamos todos los datos para enviarlo al endpoint
    let data = {
      tipo_pagador: tipo,
      fecha_pago: this.formatDate(fecha_pago),
      fecha_contable: this.formatDate( fecha_contable ),
      numero_pago: nro_pago,
      moneda: moneda,
      monto,
      empresa,
      forma_pago: medio,
      cuenta: mediosPagoEspeciales.includes(medio) ? null : cuentaRecaudo.id,
      cuenta_numero: mediosPagoEspeciales.includes(medio) ? null : cuentaRecaudo.numero,
      periodo_cerrado,
      banco : mediosPagoEspeciales.includes(medio) ? null : cuentaRecaudo.banco,
      tipo_cambio_monto,
      porcentaje_pago: porcentaje_pago || null,
      base_porcentaje: base_porcentaje || null,
    };

    if (!!estado) data["estado"] = estado;
    if (tipo === 2) data["beneficiario"] = pagador;
    else data["deudor"] = pagador;

    tipo == 2 ? (data["cliente"] = pagador) : (data["aceptante"] = pagador);

    if (this.liquidacion) {
      data['operacion'] = this.liquidacion.operacion.id
    }

    if (estado > 1 || medio < 0) this.disableFields(); // Recs registradas que no sean aplicacion/liquidacion permitir modificar

    return data;
  }


  async enviarCabecera(precaucion_periodo_cierre = false) {  
    if (this.cabeceraSolicitudForm.invalid) {
      Object.values(this.cabeceraSolicitudForm.controls).forEach(control => control.markAsTouched())
      this.toast.warning('Complete correctamente el formulario')
      return
    }
    if (this.recaudacion && this.recaudacion.id) {
      await this.actualizarCabecera();

    } else {
      // if (this.openModalPeriodoCerrado(precaucion_periodo_cierre))return

      await this.registrarRecaudacion();
      this.obtenerData();
    }
  }

  async registrarRecaudacion() {
    let data = this.getCabeceraData();
    if (!data) {
      return
    }
    if (!!this.excedente) {
      data["excedente"] = this.excedente.id;
    }
    if (!!this.abono) {
      data["abono"] = this.abono.id;
    }
    let request = this.recaudacionService
      .registrarRecaudacion(data)
      .then(async (recaudacion: any) => {
        this.recaudacion = recaudacion;
        this.recaudacionId = recaudacion.id;
        this.excedentesList = this.mapperExcedentes(
          recaudacion["excedentes_simulados"]
        );

        this.initTables();
        this.setForm(recaudacion);
      });
  }

  // NUEVA LOGICA 
  modifPago( row ){

    let documentos = this.documentsCarteraSelectedList.reduce(
      (acc, item) => {
        // delete item. row.montoAnt;
        // delete item.montoAntDolar;
        // delete item.pago_financiado_ant;
        acc += Number.parseFloat(item.pago);
        return acc;
      },
      0
    );

    /**
     * Si es de tipo aplicacion se va a setear el valor de todos los documentos
     * seleccionados como el monto de la recaudacion
     */
    if (this.cabeceraSolicitudForm.get("medio").value == -2) {
      this.cabeceraSolicitudForm
        .get("monto")
        .setValue(documentos.toFixed(2));
    }
    
    // this.actualizarCabecera();

  }

  async facturasCount(id) {

    await this.recaudacionService
      .obtenerFacturasCount(id)
      .then(async (data: any) => {

        this.facturar_data = data.count

      });

  }

  async actualizarCabecera(estado = null, excedentes_especificos: ExcedenteDevolver[] = null) {
    this.tipoEstado = estado;

    let data = this.getCabeceraData();
    if (!data) {
      return
    }
    if (estado) {
      data["estado"] = estado;
    }

    data["excedentes_especificos"] = excedentes_especificos

    let queryParams = {'enviar_facturar': 0} // Enviar cero, el envío será luego de guardada la recaudación

    this.actualizandoDatos = true
    await this.recaudacionService
      .actualizarEstadoRecaudacion(this.recaudacionId, data, queryParams)
      .then(async (recaudacion: any) => {
        this.recaudacion = recaudacion;
        await this.facturasCount(this.recaudacion.id);
        console.log("data count fact: ", this.facturar_data)
        if (estado == 2 && this.facturar_data > 0) { // 2: COBRADO, APROBADO
          const paramModalEnvioAutomatico = await this.parametrosService.obtenerParametro('FACTURACION_MODAL_ENVIO_AUTOMATICO').toPromise()
          if (paramModalEnvioAutomatico.valor == '1') {
            const enviarFacturasSunatResult = await Swal.fire({
              title: 'Enviar facturas a SUNAT',
              text: "Las facturas generadas se enviarán a SUNAT de manera automática",
              icon: 'info',
              showCancelButton: true,
              confirmButtonColor: '#3085D6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Si, enviar',
              cancelButtonText: 'No enviar',
              allowOutsideClick: false,
            })
            if (enviarFacturasSunatResult.isConfirmed) {
              // Enviar a facturar en segundo plano
              this.recaudacionService.enviarFacturar({'recaudacion': this.recaudacion.id}).subscribe(
                data => {},
                res => {}
              )
            }
          }
        }

        this.excedentesList = this.mapperExcedentes(
          recaudacion["excedentes_simulados"]
        );

        this.initTables();
        this.setForm(recaudacion);

        if (this.recaudacion.estado == 2 || this.recaudacion.estado == 3) {
          this.readOnlyForm = true;
          this.readOnlyButtonDocs = true;
          this.readOnlyButtonActualizar = true;
          this.readOnlyButtonAprobar = true;
        }

        if (this.user.isOficialDeNegocio && !this.liquidacion) {
          this.readOnlyForm = true;
          this.readOnlyButtonDocs = true;
          this.readOnlyButtonActualizar = true;
          this.readOnlyButtonAprobar = true;
        }

        this.obtenerDocumentosDetalle();

        if (
          this.cabeceraSolicitudForm.get("medio").value == -2 &&
          this.documentsCarteraSelectedList.length > 0
        ) {
          let data = {
            monto: this.cabeceraSolicitudForm.get("monto").value,
            moneda: this.cabeceraSolicitudForm.get("moneda").value,
          };
          // this.reloadList.emit(data)
        }

        if (this.tipoEstado == 2) {
          await this.obtenerRecaudacion();
          await this.obtenerExcedentes();
        }
        
      }).catch(
        error => console.log(error)
      ).then(() => {
        this.actualizandoDatos = false
      });

    }

  async obtenerDocumentosCartera(
    page = 1,
    page_size: 1000,
    beneficiario = "",
    deudor = "",
    estado__in = "",
    empresa = '',
    fecha__gte = '',
    fecha__lte = '',
    queryParams = {}
  ) {
    this.documentsCarteraList = []
    // Quitar de query params, porque ya los envían como parámetros específicos
    delete queryParams['fecha__gte']
    delete queryParams['fecha__lte']
    // Agregar orden
    queryParams['ordering'] = `${this.recaudacion.moneda == 1 ? '' : '-'}moneda,fecha_vencimiento,-saldo_financiado,-saldo_neto`
    return await this.recaudacionService
      .obtenerDocumentosCartera(
        beneficiario,
        deudor,
        estado__in,
        empresa,
        '',
        page,
        page_size,
        fecha__gte,
        fecha__lte,
        queryParams
      )
      .then((documents: any) => {
        const { results, count, num_pages } = documents;
  
        let tablePages = [];
        let filteredList = results;

        this.documentsCarteraSelectedList.filter(e => e.documento_cartera !== null).forEach((doc) => {
          const documento = doc.documento_cartera;
          filteredList = filteredList.filter((doc: any) => {
            return doc.id !== documento;
          });
        });
        this.documentsCarteraList = filteredList;
        this.pageDocumentsCarteraTableTotal = filteredList.length;

        for (let index = 0; index < num_pages; index++) {
          tablePages.push(index);
        }
        this.pageDocumentsCarteraTableActual = page;
        this.pageDocumentsCarteraTablePerPages = page_size;
        this.pageDocumentsCarteraTablePages = tablePages;
      });
  }

  async showDocumentsCartera() {
    this.goToPageDocumentsCartera({ page: 1, per_page: 1000, filtros: {} })
    this.loadPagadores() // Buscador depagadores al seleccionar documentos cartera para aplicar

    this.modalDocumentsCarteraReference = this.modalService.open(
      this.e_documentscartera,
      {
        size: "xl",
        windowClass: "modalSizeXXL",
      }
    );
  }

  showCuentasxPagar() {
    const modalRef = this.modalService.open(CuentasXPagarComponent, {
      size: "xl",
      windowClass: "modalSizeXXL",
    });

    /**
     * Cliente
     */
    modalRef.componentInstance.beneficiarioId =
      this.recaudacion.tipo_pagador == 2 ? this.recaudacion.cliente : null;
    /**
     * Aceptante
     */
    modalRef.componentInstance.deudorId =
      this.recaudacion.tipo_pagador == 1 ? this.recaudacion.aceptante : null;

    // Empresa
    modalRef.componentInstance.empresa = this.cabeceraSolicitudForm.controls['empresa'].value;

    modalRef.componentInstance.recaudacion = this.recaudacion
    modalRef.componentInstance.documentosPagar = this.documentsCarteraSelectedList;

    modalRef.result.then(
      (result) => { },
      (reason) => { }
    );

    modalRef.componentInstance.successEmit.subscribe(
      (result) => {
        const formData = result.map((item) => {
          return {
            recaudacion: this.recaudacionId,
            cuenta_cobrar: item.id,
          };
          
        });

        const observables = formData.map((entry) => {
          return this.registrarDocumentoCartera(entry);
        });

        concat(...observables).subscribe(
          (singleDocument) => {
            this.recaudacionService.spinnerOff();
            this.obtenerDocumentosDetalle();
          },
          (error) => { }
        );
      },
      (error) => { }
    );
  }

  goToPageDocumentsCartera({ page, per_page, filtros }) {
    const tipo = this.otroPropietario ? this.otroPropietario.tipo : this.cabeceraSolicitudForm.get("tipo").value;
    const pagador = this.otroPropietario ? this.otroPropietario.id : this.cabeceraSolicitudForm.get("pagador").value;
    const deudor = tipo === 1 ? pagador : "";
    const beneficiario = tipo === 2 ? pagador : "";
    const empresa = this.cabeceraSolicitudForm.controls['empresa'].value;
    this.documentsCarteraSelectionList = []
    filtros['pagador_cliente'] = beneficiario
    filtros['pagador_aceptante'] = deudor
    this.obtenerDocumentosCartera(1, 1000, '', '', "1,3", empresa, filtros.fecha__gte, filtros.fecha__lte, filtros);
  }

  selectedItem(row: any) {
    if ( !this.documentsCarteraSelectionList.includes(row) )
      this.documentsCarteraSelectionList.push(row);
    else
      this.documentsCarteraSelectionList =
        this.documentsCarteraSelectionList.filter((item) => item.id !== row.id);
  }

  async registrarDetalles() {
    /**Nueva Logica */
    const formData = this.documentsCarteraSelectionList.map((item) => {
      return {
        recaudacion: this.recaudacionId,
        documento_cartera: item.id,
      };
    });

    let vencimientoMayor = this.documentsCarteraSelectionList
      .map(e => e['fecha_vencimiento'])
      .reduce((m, e) => new Date(`${e}T00:00:00`) > m ? new Date(`${e}T00:00:00`) : m, new Date(0))
    let vencidosOmitidos = this.documentsCarteraList.filter(e => !e.selected)
      .filter(e => new Date(`${e['fecha_vencimiento']}T00:00:00`) < vencimientoMayor).length
    if (vencidosOmitidos > 0) {
      const documentosVencidosModalResult = await Swal.fire({
        title: '¿Está seguro?',
        text: `Existen ${vencidosOmitidos} documentos con fecha de vencimiento anterior a la fecha de vencimiento de algún seleccionado ¿Desea continuar?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085D6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, continuar'
      })
      if (!documentosVencidosModalResult.isConfirmed) return
    }

    const observables = formData.map((entry) => {
      return this.registrarDocumentoCartera(entry);
    });

    concat(...observables).subscribe(
      (singleDocument) => {
        this.obtenerDocumentosDetalle();
      },
      (error) => { }
    );

    // this.documentsCarteraSelectionList = [];
    this.modalDocumentsCarteraReference.close();
  }

  registrarDocumentoCartera(entry): Observable<any> {
    return this.recaudacionService.registrarDocumentoCartera(entry);
  }

  registrarNotasCredito(entry): Observable<any> {
    return this.recaudacionService.registrarNotasCredito(entry);
  }

  obtenerDocumentosDetalle() {
    this.estadoFacturacion = false;
    this.recaudacionService
      .obtenerDocumentosDetalle(this.recaudacionId.toString())
      .then((res) => {
        const result = res["results"].reverse();

        this.documentsCarteraSelectedList = result.map((doc, i) => {
          
          if(doc.monto == doc.pago){
            this.estadoFacturacion = true;
          }

          const data = {
            id: doc.id,
            nombre:
              this.recaudacion.tipo_pagador == 1
                ? doc.cliente_nombre
                : doc.aceptante_nombre,
            tipo_documento_descripcion: doc.tipo_documento_descripcion,
            tipo_producto_descripcion: doc.tipo_producto_descripcion,
            numero_documento: doc.numero_documento,
            moneda_documento: doc.moneda_documento,
            moneda_documento_descripcion: doc.moneda_documento_descripcion,
            saldo_documento: doc.saldo_documento,
            saldo_financiado_documento: doc?.saldo_financiado_documento,
            tipo_cambio_monto: doc.tipo_cambio_monto,
            fecha_vencimiento: doc.fecha_vencimiento,
            index: i + 1,
            monto: doc.monto,
            pago: doc.pago,
            saldo: doc.saldo,
            operacion_detalle: doc?.operacion_detalle,
            documento_cartera: doc?.documento_cartera,
            pago_neto: doc?.pago_neto,
            pago_financiado: doc?.pago_financiado,
            pagar_financiado: doc?.pagar_financiado,
            selected: doc?.pagar_financiado,
            estado_cavali: doc?.estado_cavali,
            estado_cavali_descripcion: doc?.estado_cavali_descripcion,
            cuenta_cobrar: doc?.cuenta_cobrar,
            monto_mora: doc?.monto_mora,
            tasa_mora: doc?.tasa_mora,
            motivo_monto_mora: doc?.motivo_monto_mora,
            motivo_monto_mora_descripcion: doc?.motivo_monto_mora_descripcion,
            motivo_tasa_mora: doc?.motivo_tasa_mora,
            motivo_tasa_mora_descripcion: doc?.motivo_tasa_mora_descripcion,
            monto_mora_original: doc?.monto_mora_original,
            tasa_mora_original: doc?.tasa_mora_original,
            tem: doc?.tem,
            tea: doc?.tea,
            cliente_aceptante: this.recaudacion.tipo_pagador == 1 ? doc.cliente : doc.aceptante, // El no pagador
            monto_pagar: doc?.monto_pagar,
            monto_mora_generado: doc?.monto_mora_generado,
            prorrogar: doc?.prorrogar,
          };
          return data;

        });

        let documentos = this.documentsCarteraSelectedList.reduce(
          (acc, item) => {
            acc += Number.parseFloat(item.pago);
            return acc;
          },
          0
        );

        /**
         * Si es de tipo aplicacion se va a setear el valor de todos los documentos
         * seleccionados como el monto de la recaudacion
         */
        if (this.cabeceraSolicitudForm.get("medio").value == -2) {
          this.cabeceraSolicitudForm
            .get("monto")
            .setValue(documentos.toFixed(2));
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async eliminarDetalle(row: any) {
    const { index } = row;
    this.documentsCarteraSelectedList =
      this.documentsCarteraSelectedList.filter(
        (doc: any) => doc.index !== index
      );
  }

  async registrarExcedentes() {
    if (this.excedentesList.length > 0) {
      this.excedentesList.forEach((doc) => { });
      // const { id } = this.comisionsList[0];
      // await this.prorrogasService
      //   .eliminarComision(id)
      //   .then(() => (this.comisionsList = []));
    }

    // await this.recaudacionService
    //   .registrarExcedentes({ prorroga: this.prorroga.id })
    //   .then((comision: any) => {
    //     this.comisionsList = [{ ...comision, index: 1 }];
    //   });
  }

  obtenerExcedentes() {
    this.recaudacionService
      .obtenerExcedentes(this.recaudacion.id)
      .then((res) => {
        this.excedentesList = this.mapperExcedentes(res["results"]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  formatDateInput(date) {
    return date.toLocaleString("es-ES").split(",")[0].split(" ")[0];
  }

  formatDate(date) {
    if (!(date instanceof Date)) return;

    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  parseDate(date) {
    if (!date) return null;

    let dateNow = new Date();

    let yy = date.split("-")[0];
    let mm = date.split("-")[1] - 1;
    let dd = date.split("-")[2];

    let d = new Date(yy, mm, dd, dateNow.getHours());
    return d;
  }

  destroy(item) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {});

    modalRef.componentInstance.title = "Remover Detalle";
    modalRef.componentInstance.message =
      "¿Seguro que desea remover este detalle?";

    modalRef.result.then(
      (result) => {
        if (result) {
          this.recaudacionService
            .eliminarDetalleDocumento(item.id)
            .then((result) => {
              this.obtenerDocumentosDetalle();
            })
            .catch((error) => {
              console.error(error);
            });
        }
      },
      (reason) => { }
    );
  }

  pagoFinanciadoChecked(data) {
    const { id, pagar_financiado, prorrogar } = data.row
    if (data.field == 'prorrogar') {
      this.recaudacionService.actualizarRecaudacionDetalle(id, { prorrogar: !prorrogar }).then(res => {
        this.obtenerRecaudacion()
      })
    } else {
      this.recaudacionService.actualizarRecaudacionDetalle(id, { pagar_financiado: !pagar_financiado }).then(res => {
        this.obtenerDocumentosDetalle()
      })
    }
  }

  mapperExcedentes(items) {
    const newArray = items.map((item, i) => {
      const data = {
        index: i + 1,
        nombre:
          this.recaudacion.tipo_pagador == 1
            ? item.cliente_nombre
            : item.aceptante_nombre,
        tipo: item.tipo_documento_descripcion,
        nro_documento: item.numero_documento,
        monto: item.monto,
        monto_documento: item.monto_documento,
        financiado: item.financiado,
        intereses_devolver: item.intereses_devolver,
        excedente: item.excedente,
        moneda: item.moneda,
        intereses_mora: item.intereses_mora,
        igv_intereses: item.igv_intereses,
      };

      return data;
    });

    return newArray;
  }

  get validarAprobar() {
    if (this.cabeceraSolicitudForm.get("medio").value == -2) {
      return false;
    }

    if (!this.recaudacion || this.recaudacion.estado == 2) return true;

    // const validForm = this.cabeceraSolicitudForm.valid;
    let constrls = this.cabeceraSolicitudForm.controls;

    for (const key in constrls) {
      if (Object.prototype.hasOwnProperty.call(constrls, key)) {
        if (key == "fecha_solicitud" || key == "fecha_pago") {
          continue;
        }

        if (constrls[key].errors) {
          return true;
        }
      }
    }

    const montoAPagar = this.cabeceraSolicitudForm.controls["monto"].value;
    let documentos = 0;

    this.documentsCarteraSelectedList.forEach((item) => {
      documentos += parseFloat(item.pago);
    });

    if (
      parseFloat(montoAPagar) >= parseFloat(documentos.toFixed(2))
    ) {
      return false;
    } else {
      return true;
    }
  }

  get validForm() {
    let constrls = this.cabeceraSolicitudForm.controls;

    let result = true;

    for (const key in constrls) {
      if (Object.prototype.hasOwnProperty.call(constrls, key)) {
        if (key == "fecha_solicitud" || key == "fecha_pago") {
          continue;
        }

        if (constrls[key].errors) {
          result = true;
        } else {
          result = false;
        }
      }
    }

    return result;
  }

  openModalPeriodoCerrado(precaucion_periodo_cierre) {
    if (precaucion_periodo_cierre && !this.cabeceraSolicitudForm.get('periodo_cerrado').value) {
      const fecha_pago = this.cabeceraSolicitudForm.get('fecha_pago').value
      const fecha_actual = new Date()
      if (fecha_actual.getMonth() > fecha_pago.getMonth()) {
        this.modalService.open(
          this.modal_periodo_cierre,
          {
            size: "lg",
          }
        );
        return true
      }
    }
    return false
  }

  async aprobarRecaudacion(precaucion_periodo_cierre = false) {
    // JSAKUDA PRO-598

    if (this.recaudacion?.aplicacion_terceros && !this.user.isJefeDeOperaciones) {
      this.toast.warning('No tiene permiso para aprobar aplicaciones a deuda de terceros')
      return
    }

    this.cabeceraMoneda = (this.cabeceraSolicitudForm.controls['moneda'].value) === 1 ? "Soles" : "Dólares";

    let monedas: string;
    const distintaMoneda: string[] = [""];
    this.documentsCarteraSelectedList.forEach((item) => {
      monedas = item.moneda_documento_descripcion;
      distintaMoneda.push(monedas)
    })
    distintaMoneda.push(this.cabeceraMoneda);
    let limpiaMoneda = distintaMoneda.filter(element => element != "")

    if (limpiaMoneda.includes('Dólares') && limpiaMoneda.includes('Soles')) {
      const diferenteMonedaModalResult = await Swal.fire({
        title: '¿Está seguro?',
        text: "Existen documentos de diferente moneda a la de la aplicación ¿Desea continuar con la aprobación?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085D6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Aprobar'
      })

      if (!diferenteMonedaModalResult.isConfirmed) return
    }

    // En pago porcentaje, verificar si hay documentos pagados
    let documentosPagados = this.documentsCarteraSelectedList.filter(e => !!e['documento_cartera'] && Number(e['pago_financiado']) >= Number(e['saldo_financiado_documento']))
    if (documentosPagados.length > 0) {
      const docsPagadosResult = await Swal.fire({
        title: '¿Está seguro?',
        text: `Existen ${documentosPagados.length} documentos pagados totalmente ¿Desea continuar con la aprobación?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, aprobar',
        cancelButtonText: 'Cancelar',
      })

      if (!docsPagadosResult.isConfirmed) return
    }

    // if (this.openModalPeriodoCerrado(precaucion_periodo_cierre)) {
    //   return
    // }
    const montoAPagar = this.cabeceraSolicitudForm.controls['monto'].value;
    let documentos = 0;
    this.documentsCarteraSelectedList.forEach((item) => {
      documentos += parseFloat(item.pago);
    });

    //  * Bloque de codigo para aplicacion de excedentes

    if (this.cabeceraSolicitudForm.get("medio").value == -2) {
      let menorOIgual;
      if (!this.excedente)
        this.toast.warning(
          "La aplicacion de excedentes se realiza desde la pestaña de excedentes!"
        );

      // Si el monto total de los documentos es menor o igual al excedente, puede proceder


      if (this.cabeceraSolicitudForm.get("moneda").value == 1) {
        menorOIgual =
          documentos <= Number.parseFloat(this.excedente.monto_soles);
      } else {
        menorOIgual =
          documentos <= Number.parseFloat(this.excedente.monto_dolares);
      }
      if (!menorOIgual) {
        this.toast.warning(
          "La suma de los documentos sobrepasa el monto del excedente"
        );
        return;
      }
      
      // await this.enviarCabecera();
    }
    
    if (parseFloat(montoAPagar) > parseFloat(documentos.toFixed(2))) {
  
      const modalRef = this.modalService.open(
        DevolverExcedentesModalComponent,
        {size: 'lg'}
      );
      const monto_excedentes = parseFloat(this.excedentesList[this.excedentesList.length - 1].excedente)
      modalRef.componentInstance.monto_excedentes = monto_excedentes;
      modalRef.componentInstance.excedentes = this.excedentesList;
      modalRef.componentInstance.tiposPagador = this.tipos;
      modalRef.componentInstance.recaudacionId = this.recaudacionId;
      modalRef.componentInstance.pagadorRecaudacionTipo = this.recaudacion.tipo_pagador;
      modalRef.componentInstance.pagadorRecaudacionId = this.cabeceraSolicitudForm.controls["pagador"].value;
      modalRef.componentInstance.esAplicacionDevolver = this.documentsCarteraSelectedList.length == 0
      modalRef.componentInstance.recaudacion = this.recaudacion
      modalRef.result.then(
        (result) => { },
        (reason) => { }
      );
      modalRef.componentInstance.successEmit.subscribe(
        (result) => {
          this.actualizarCabecera(2, result);
        },
        (error) => { }
      );
    } else {
  
      this.actualizarCabecera(2);
    }
  }


  anularRecaudacion() {
    this.recaudacionService
      .actualizarEstadoRecaudacion(this.recaudacionId, { estado: 3 })
      .then((res) => {
        this.obtenerRecaudacion();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  get monedaCabecera() {
    return this.recaudacion ? this.recaudacion["moneda"] : 0;
  }

  get validarFormCabecera() {
    return false;
  }

  get showDocsButton() {
    if (this.recaudacion && this.recaudacion.estado == 1) {
      return false;
    } else {
      return true;
    }
  }

  get canDelete() {
    return this.recaudacion && this.recaudacion.estado == 1 ? true : false;
  }

  get nameHeader() {
    if (this.recaudacion) {
      this.recaudacion["tipo_pagador"] == 1 ? "Cliente" : "Aceptante";
    } else {
      return "";
    }
  }

  changePagador(e) {
    this.cabeceraSolicitudForm.controls["pagador_name"].setValue(e ? e['nombre'] : '');
  }

  redencionProcesar() {
    const facturasIds = this.documentsCarteraSelectedList
      .filter((f) => parseInt(f.saldo) == 0 && f.documento_cartera > 0 && f.estado_cavali != 4 /*4: REDIMIDO*/)
      .map((f) => f.operacion_detalle);

    let request = this.recaudacionService.redencion({
      facturas: [...facturasIds],
      fecha_pago: this.recaudacion.fecha_pago,
    });

    request.then(
      (resp) => {
        this.actualizarCabecera();
      },
      (err) => {
        console.error(err);
      }
    );
  }

  get showRedencion(){
     // E001-1062
    const cavali:any = this.documentsCarteraSelectedList
            .filter( (f)=> f.estado_cavali && f.estado_cavali === 4 );

    if( cavali.length === this.documentsCarteraSelectedList.length  )
      return true;

    return false;
  }

  get showCavali() {
    const profiles = [
      this.authService.perfil.perfilAsistenteCobranzasID,
      this.authService.perfil.perfilJefeDeOperacionesID,
    ];
   
    const facturasIds = this.documentsCarteraSelectedList
      .filter((f) => parseInt(f.saldo) == 0 && f.documento_cartera > 0)
      .map((f) => f.operacion_detalle)

    if (
      this.recaudacion &&
      facturasIds.length &&
      profiles.includes(this.user.perfil)
    ) {
      // return this.recaudacion.usuario_creacion == 'CAVALI';
      return true;
    }

    return false;
  }
  goToImprimirRecaudacion() {
    let id = this.recaudacion.id;
    window.open("#/recaudacion/imprimir/" + id, "_blank");
    // window.open(url, "_blank");
    // const url = this.router.serializeUrl(
    // 	this.router.createUrlTree(['/reporte','deuda-pagos',moneda,ruc,tipo,patron,tipoCambio])
    //   );

    // window.open(url, '_blank');
  }

  actionClick(event) {
    if (event.field == 'estado_cavali') {
      const cavaliModal = this.modalService.open(ResultadoCavaliComponent, { size: "xl", })
      cavaliModal.componentInstance.operacionDetalleId = event.operacion_detalle
    }
  }

  showNotasCredito() {
    const modalRef = this.modalService.open(NotasCreditoRecaudacionComponent, {
      size: "lg",
    });
    /**
     * Cliente
     */
    modalRef.componentInstance.beneficiarioId = this.recaudacion.tipo_pagador == 2 ? this.recaudacion.cliente : this.recaudacion.aceptante;
    /**
     * Tipo pagador
     */
    modalRef.componentInstance.tipoPagadorId = this.recaudacion.tipo_pagador;

    modalRef.result.then(
      (result) => {

      },
      (reason) => {

      }
    );

    modalRef.componentInstance.successEmit.subscribe(
      async (result) => {
        const formData = result.map((item) => {
          return {
            recaudacion: this.recaudacionId,
            nota_credito: item.id,
          };
        });

        const observables = formData.map((entry) => {
          return this.registrarNotasCredito(entry);
        });

        await concat(...observables).subscribe(
          async (singleDocument) => {
            this.recaudacionService.spinnerOff();
            // await this.actualizarCabecera();
            // await this.obtenerNotasCreditos();
            await this.ngOnInit();
          },
          (error) => { }
        );
      },
      (error) => { }
    );
  }

  obtenerNotasCreditos() {
    this.recaudacionService.obtenerNotasCreditos(this.recaudacionId.toString(), '', 1, 1000).then(resp => {
      this.listNotasCredito = resp['results'];
      this.refreshNotaCreditoTable = !this.refreshNotaCreditoTable;
    }, err => {
      console.error(err);

    });
  }

  liquidarRecaudacion() {
    this.recaudacionService.generarLiquidacion(this.recaudacionId).then(
      (x: any) => {
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = `Liquidación-${this.recaudacionId}.xlsx`;
        link.click();
      }, (reason) => {
      });
  }

  filterUserTeroreria(data){

    if(this.recaudacionId && this.recaudacion?.forma_pago < 0){

      return data;

    }else{

      if( this.canAdd ){

        return data.filter( item => item.id > 0 )
  
      }else{
  
        return data;
      }

    }
  }

  showEditarDetalle(item) {
    const modalRef = this.modalService.open(EditarDetalleComponent, {
      size: "lg",
    });
    modalRef.componentInstance.itemInstance = item;
    modalRef.componentInstance.recaudacion = this.recaudacion;

    modalRef.result.then(
      (result) => {console.log('res', result)},
      (reason) => {console.log('rea', reason)},
    );

    modalRef.componentInstance.successEmit.subscribe(
      async (result) => {
        let data = []
        let dataItem = {}

        // Campos que solo debe actualizar al detalle seleccionado y no a todos
        Object.entries(result).filter(e => ['monto_pagar'].includes(e[0])).forEach(([k, v]) => dataItem[k] = v)
        delete result['monto_pagar']

        if (result.todos) {
          data = this.documentsCarteraSelectedList.filter(
            (e: any) => e['documento_cartera'] ? true : false
          )
        } else if (result['todos_cliente']) {
          data = this.documentsCarteraSelectedList.filter(
            (e: any) => (e['documento_cartera'] ? true : false) && e['cliente_aceptante'] == item['cliente_aceptante']
          )
        } else {
          data = [ item ]
        }
        
        data = data.map((e: any) => Object.assign({'id': e.id}, result))
        Object.assign(data.find(e => e.id == item.id), dataItem)

        const observables = data.map((e) => {
          return this.recaudacionService.actualizarDetalle(e.id, e).pipe(
            catchError((res) => {
              let error = 'Ocurrió un error inesperado'
              if (res.error && typeof(res.error) == 'object') {
                error = Object.values(res.error).join('\n');
              }
              // this.recaudacionService.spinnerOff(error, false)
              this.recaudacionService.toast.warning(error)
              return of(false)
            })
          )
        });

        this.recaudacionService.spinnerOn();

        let actualizados = 0, pendientes = observables.length
        concat(...observables).subscribe(
          (data) => {
            pendientes--
            actualizados++
            if (data === false) actualizados--
            if (pendientes <= 0) this.recaudacionService.spinnerOff()
            if (pendientes <= 0 && actualizados > 0) {
              this.obtenerRecaudacion()
            }
          },
          (res) => {
            this.recaudacionService.spinnerOff('Ocurrió un error inesperado', false)
            if (actualizados > 0) {
              this.obtenerRecaudacion()
            }
          }
        );
      },
      (error) => { console.log(error) }
    );
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('sustento_ajuste_mora', file);
      this.recaudacionService.spinnerOn();
      this.recaudacionService.modificarRecaudacion(this.recaudacion.id, formData).subscribe(
        data => {
          this.recaudacion['sustento_ajuste_mora'] = data['sustento_ajuste_mora']
          this.recaudacionService.spinnerOff();
        },
        res => {
          let error = 'Ocurrió un error inesperado'
            if (res.error && typeof(res.error) == 'object') {
              error = Object.values(res.error).join('\n');
            }
            this.recaudacionService.spinnerOff(error, false);
        },
      )
    }
  }

  descargarArchivo(url) {
    const ultimoSlash = new URL(url).pathname;
    const name = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1]

    this.factoringService.downloadFile(url).then(
      (x: any) => {
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = name;
        link.click();
      }, async (reason) => {
        console.log(reason);
      }), err => {
        console.error(err);
      };
  }

  selectTipoCambioPorMoneda(monedaId) {
    if (monedaId) {
      let cambio = (monedaId == 1) ? 'venta' : 'compra'
      let tipoCambio = this.tiposCambio.filter(e => e['descripcion'] == cambio)[0]
      if (tipoCambio) {
        this.cabeceraSolicitudForm.controls["tipo_cambio_monto"].setValue(tipoCambio.valor)
      }
    }
  }

  addTipoCambio(value) {
    return {descripcion: 'otro', valor: value}
  }

  get totalDocsSeleccionados() {
    return this.documentsCarteraSelectionList.reduce((total, e) => {
      let monto = Number(e.saldo)
      if (e.moneda != this.recaudacion.moneda) {
        if (this.recaudacion.moneda == 1) {
          monto *= this.recaudacion.tipo_cambio_monto
        } else {
          monto /= this.recaudacion.tipo_cambio_monto
        }
        monto = Math.round(monto * 100) / 100
      }
      return total + monto
    }, 0)
  }

  get canEdit() {
    return row => true
    // Posible editar docs y cxc
    // return row => row.documento_cartera ? true : false
  }

  get canAdd() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilAsistenteCobranzasID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  onChangeCheckBuscarOtros(checked) {
    if (!checked && this.otroPropietario) this.onChangePropietario(undefined)
  }

  onChangePropietario(propietario) {
    this.otroPropietario = propietario
    this.goToPageDocumentsCartera({ page: 1, per_page: 9999, filtros: {}})
  }

  async closeModal() {
    let cxcPendientes = []
    let excedentesPendientes = []
    if (this.recaudacion?.estado == 1) {
      cxcPendientes = this.excedentesList
        .filter(e => Number(e.excedente) < Number(e.igv_intereses) + Number(e.intereses_mora))
      excedentesPendientes = this.excedentesList.filter(e => Number(e.monto) > 0)
    }
    if (cxcPendientes.length > 0) {
      const isConfirmed = await this.confirmarCloseModal(
        'CxC con saldo pendiente',
        `
          CxC(mora e igv interés) están quedando con saldo pendiente en los documentos:
          <ul>
            ${cxcPendientes.map(e => `<li>${e.nro_documento}</li>`)}
          </ul>
          ¿Continuar y dejar las CxC con saldo, o regresar y modificar el pago?
        `,
      )
      if (!isConfirmed) return
    }
    if (this.liquidacion && excedentesPendientes.length > 0) {
      const isConfirmed = await this.confirmarCloseModal(
        'Excedentes a devolver',
        `
          Se están generando excedentes a devolver <br />
          ¿Continuar y dejar los excedentes, o regresar y modificar el pago?
        `,
      )
      if (!isConfirmed) return
    }
    if (this.recaudacion?.estado == 1 && this.liquidacion && this.liquidacion.fecha_desembolso_programado != this.recaudacion.fecha_pago) {
      const isConfirmed = await this.confirmarCloseModal(
        'Fecha desembolso',
        `
          La fecha de pago de la aplicación está diferente a la fecha de desembolso de la operación <br />
          ¿Continuar y dejar las fechas diferentes, o regresar y modificar la fecha?
        `,
      )
      if (!isConfirmed) return
    }
    this.activeModal.close(false)
  }

  private async confirmarCloseModal(titulo, contenido) {
    const confirmDialog = await Swal.fire({
      title: titulo,
      html: contenido,
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, continuar',
      cancelButtonText: 'No, regresar y editar',
      allowOutsideClick: false,
    })
    return confirmDialog.isConfirmed
  }

  private loadPagadores() {
    this.pagadores$ = concat(
      of([]), // default items
      this.pagadoresInput$.pipe(
        filter((res) => {
          return res !== null
        }),
        distinctUntilChanged(),
        debounceTime(800),
        tap(() => this.pagadoresLoading = true),
        switchMap(term => this.recaudacionService.getPagadores({ 'ruc_nombre': term, 'tipo': this.recaudacion.tipo_pagador }, false).pipe(
          catchError(() => of([])), // empty list on error
          tap(() => this.pagadoresLoading = false)
        ))
      )
    )
  }
}
