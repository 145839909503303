import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';

@Component({
  selector: 'app-add-cuentas-abono',
  templateUrl: './add-cuentas-abono.component.html',
  styleUrls: ['./add-cuentas-abono.component.css']
})
export class AddCuentasAbonoComponent implements OnInit {
  faTimes=faTimes;
  public minDate: Date;
  myForm: FormGroup;
  @Input() clienteId = 0;
  @Input() aceptanteId = 0;
  paises : any[];
  @Input() cuentaAbonoId = 0;
  cuentaAbono : any;
  monedas : any[];
  bancos : any[];

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild        : FormBuilder,
    public activeModal      : NgbActiveModal,
    private clientesService	: ClientesService,
    private factoringService : FactoringService,
    private configs          : DocschecklistService,
  ) {
    this.cargarDatosForm();
    this.initForm();
   }

  ngOnInit(): void {
    if (this.cuentaAbonoId) {
      this.obtenerCuentaAbono();
    }
  }

  obtenerCuentaAbono() {
    this.clientesService.obtenerCuentasAbono(this.cuentaAbonoId, !!this.aceptanteId)
    .then(res => {
      this.cuentaAbono = res;
      this.setForm(res);
    })
    .catch(error => {
      console.error(error);
    });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      entidad_financiera    : [null, [Validators.required]],
      moneda                : [null, [Validators.required]],
      numero_cuenta         : [null, [Validators.required]],
      numero_cuenta_cci     : [null, []],
    });
  }

  cargarDatosForm() {
    let monedas       = this.configs.obtenerMonedas();
    let bancos        = this.configs.obtenerBancos();
    
    Promise.all([monedas, bancos])
    .then(res => {
      this.monedas = res[0]['results'];
      this.bancos = res[1]['results'];
    })
    .catch(error => {
      console.error(error);
    });
  }

  get f() {
    return this.myForm.controls;
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  onSubmit() {
    if(this.myForm.valid) {
      const data = this.myForm.value;
      if (this.clienteId) {
        data['beneficiario'] = this.clienteId;
      }
      if (this.aceptanteId) {
        data['deudor'] = this.aceptanteId;
      }

      this.clientesService.guardarCuentaAbono(this.myForm.value, this.cuentaAbonoId)
      .then(async (res: any) => {
        this.factoringService.spinnerOn()
        if (!res['fecha_numero_validado']) {
          try {
            await this.factoringService.validarCuentaAbono(res.id).toPromise()
          } catch (ex) { console.log(ex) }
        }
        if (!res['fecha_cci_validado']) {
          try {
            await this.factoringService.validarCuentaAbono(res.id, true).toPromise()
          } catch (ex) { console.log(ex) }
        }
        this.factoringService.spinnerOff()
        this.successEmit.emit();
        this.activeModal.close(false);
      }).
      catch(error => {
        this.activeModal.close(false);
      });
    }
  }

}
