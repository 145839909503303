import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "app/core/guards/auth.guard";
import { AdminLayoutComponent } from "../../layouts/admin/admin-layout.component";
import { ImprimirRecaudacionComponent } from "./pages/recaudacion/imprimir-recaudacion/imprimir-recaudacion.component";
import { RecaudacionComponent } from "./pages/recaudacion/recaudacion.component";

const routes: Routes = [
  {
    path: "",
    component: AdminLayoutComponent,
    data: {
      breadcrumb: "Aplicación",
    },
    children: [
      {
        path: "recaudacion",
        component: RecaudacionComponent,
        data: {
          breadcrumb: "Aplicación",
        },
      },
    ],
  },
  {
    path: 'recaudacion/imprimir/:id',
    component: ImprimirRecaudacionComponent,
    canActivate: [AuthGuard],
    data: {
      breadcrumb: 'Imprimir Aplicación'
    }
  }, 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RecaudacionRoutingModule {}
