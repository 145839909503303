import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ClientesService } from "app/core/services/clientes/clientes.service";

@Component({
  selector: "app-add-asset",
  templateUrl: "./add-asset.component.html",
  styleUrls: ["./add-asset.component.css"],
})
export class AddAssetComponent implements OnInit {
  public minDate: Date = new Date("1900-01-01");
  public myForm: FormGroup;
  public activo: any;
  public tipos: any[];
  faTimes = faTimes;
  @Input() clienteId = 0;
  @Input() activoId = 0;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild: FormBuilder,
    public activeModal: NgbActiveModal,
    private clientesService: ClientesService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    if (this.activoId) {
      this.obtenerActivo();
    }

    this.clientesService
      .obtenerActivosTipos()
      .then((res) => {
        this.tipos = res["results"];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  obtenerActivo() {
    this.clientesService
      .obtenerActivo(this.activoId)
      .then((res) => {
        this.activo = res;
        this.setForm(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      tipo_bien: [null, []],
      gravado_beneficiario: [null, []],
      ubicacion: [null, []],
      ficha_rrpp: [null, []],
      marca: [null, []],
      modelo: [null, []],
      serie: [null, []],
      anio: [null, []],
      placa: [null, []],
      fecha_compra: [null, []],
      valor_compra: [null, []],
    });
  }

  get f() {
    return this.myForm.controls;
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        if (key === "fecha_compra") {
          this.myForm.controls[key].setValue(this.parseDate(item[key]));
        } else this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = this.myForm.value;
      data["cliente"] = this.clienteId;
      data["fecha_compra"] = this.formatDate(data?.fecha_compra);

      this.clientesService
        .guardarActivo(this.myForm.value, this.activoId)
        .then((res) => {
          this.successEmit.emit();
          this.activeModal.close(false);
        })
        .catch((error) => {
          this.activeModal.close(false);
        });
    }
  }

  compararTipos(tipo1: any, tipo2: any) {
    return tipo1 && tipo2 ? tipo1.id === tipo2 : tipo1 === tipo2;
  }

  parseDate(date) {
    if (!date) return null;

    let dateArray = date.split("-");

    let year = dateArray[0];
    let month = parseInt(dateArray[1], 10) - 1;
    let stringDate = dateArray[2];
    let _entryDate = new Date(year, month, stringDate);

    return _entryDate.toLocaleString("es-ES").split(",")[0].split(" ")[0];
  }

  formatDate(date) {
    if (!(date instanceof Date)) return;

    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }
}
