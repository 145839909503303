import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddMembersModalComponent } from '../../modals/add-members-modal/add-members-modal.component';
import { RemoveMembersModalComponent } from '../../modals/remove-members-modal/remove-members-modal.component';

@Component({
  selector: 'app-committee',
  templateUrl: './committee.component.html',
  styleUrls: ['./committee.component.css']
})
export class CommitteeComponent implements OnInit {
  faPlus=faPlus;
  public activeNav          : number  = 1;
  public tabsList = [
    {
      id: 1,
      name: 'REGULAR'
    },
    {
      id: 2,
      name: 'MAYORES'
    },
    {
      id: 3,
      name: 'PREMIUM'
    },
  ];

  currency = [
    {
      id: 1,
      name: 'Dolares'
    },
    {
      id: 2,
      name: 'Soles'
    }
  ];

  fakerMembers = [
    {
      id: 1,
      name: 'Jorge'
    },
    {
      id: 1,
      name: 'Isabel'
    },
    {
      id: 1,
      name: 'Eduardo'
    },
    {
      id: 1,
      name: 'Eleazar'
    }
  ];


  myForm: FormGroup;

  public columnDefs         : any[];
	public loadingTable       : boolean = false;
  // public tabsList           : any[]   = [];
  // public activeNav          : number  = 1;
  public estaciones         : any[] = [];
  public members            : any[] = [];
  public checkchecked       : any = {};
  public documentoDesacoplar: any = {};
  public documentoSelect    : any[] = [];
  public documentoSelectList: any[] = [];

  constructor(
    public formbuild   : FormBuilder,
    public modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.loadTable();
  }

  createForm() {
    this.myForm = this.formbuild.group({
      name: [''],
      select_large: [1],
      large_line: [],
      select_minor: [1],
      minor_line: ['']
    });
  }

  loadTable() {
    this.columnDefs = [
      {
        headerName: 'N°', 
				pipe:'indexcol',
				sortable: true
      },
      {
				headerName: 'Miembro', 
				field: 'name',
				sortable: true,
        class: 'text-center'
			},
    ];
    this.loadData();
  }

  loadData() {
    this.members = this.fakerMembers;
  }

  addMembers() {
    this.modalService.open(AddMembersModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md'
    }).result.then(res => {
      console.log(res);
    });
  }

  removeMember(member) {
    console.log(member);
    const modalRef = this.modalService.open(RemoveMembersModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'md'
    })
    modalRef.componentInstance.member = member;
    modalRef.result.then(res => {
      console.log(res)
    });
  }

  checkboxChange() {

  }

  desacoplarDocumento() {

  }

}
