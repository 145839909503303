import { Component, Input, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { ClientesService } from "app/core/services/clientes/clientes.service";
import { pairwise, startWith } from "rxjs/operators";

@Component({
  selector: "app-business-information",
  templateUrl: "./business-information.component.html",
  styleUrls: ["./business-information.component.css"],
})
export class BusinessInformationComponent implements OnInit {
  active = 1;
  dataForm: FormGroup;
  cliente: any;
  sectores: any[];
  actividades: any[];
  actividadesSelected: any[];
  @Input() clienteId = 0;
  @Input() readonly = false;

  constructor(
    public formbuild: FormBuilder,
    private clientesService: ClientesService,
    private router: Router
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.obtenerSectores();

    if (this.clienteId) {
      this.obtenerCliente();
    }

    this.onSelectChange("sector", "participacion_mercado");
    this.onSelectChange(
      "actividad_principal_texto",
      "actividad_principal_porcentaje"
    );
    this.onSelectChange(
      "actividad_complementaria_1_texto",
      "actividad_complementaria_1_porcentaje"
    );
    this.onSelectChange(
      "actividad_complementaria_2_texto",
      "actividad_complementaria_2_porcentaje"
    );

    if (this.readonly) {
      this.dataForm.disable()
    }
  }

  obtenerCliente() {
    this.clientesService
      .obtenerCliente(this.clienteId)
      .then((res: any) => {
        this.cliente = res;
        this.initActividades();
        this.setForm(res);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  obtenerSectores() {
    this.clientesService
      .obtenerSector()
      .then((res) => {
        this.sectores = res["results"];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  obtenerActividades() {
    const controls = this.dataForm.controls;
    const sector = controls["sector"].value;

    if (!sector) return;

    controls["actividad_principal_texto"].setValue(null);
    controls["actividad_complementaria_1_texto"].setValue(null);
    controls["actividad_complementaria_2_texto"].setValue(null);
    controls["actividad_principal_porcentaje"].setValue(null);
    controls["actividad_complementaria_1_porcentaje"].setValue(null);
    controls["actividad_complementaria_2_porcentaje"].setValue(null);
    controls["actividad_principal_porcentaje"].disable();
    controls["actividad_complementaria_1_porcentaje"].disable();
    controls["actividad_complementaria_2_porcentaje"].disable();

    this.clientesService
      .obtenerActividades(sector)
      .then((res) => {
        this.actividades = res["results"];
        this.actividadesSelected = res["results"];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  initActividades() {
    const sector = this.cliente.sector;

    if (sector) {
      this.clientesService
        .obtenerActividades(sector)
        .then((res) => {
          this.actividades = res["results"];
          this.actividadesSelected = res["results"];
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  initForm() {
    this.dataForm = this.formbuild.group({
      sector: [null, []],
      participacion_mercado: [
        {
          value: null,
          disabled: !this.dataForm?.controls["sector"]?.value,
        },
        [Validators.required],
      ],
      actividad_principal_porcentaje: [
        {
          value: null,
          disabled: !this.dataForm?.controls["actividad_principal_texto"]?.value,
        },
        [Validators.required],
      ],
      actividad_complementaria_1_porcentaje: [
        {
          value: null,
          disabled: !this.dataForm?.controls["actividad_complementaria_1_texto"]
            ?.value,
        },
        [],
      ],
      actividad_complementaria_2_porcentaje: [
        {
          value: null,
          disabled: !this.dataForm?.controls["actividad_complementaria_2_texto"]
            ?.value,
        },
        [],
      ],
      inicio_operaciones: [null, [Validators.required]],
      cantidad_empleados: [null, []],
      cantidad_obreros: [null, []],
      sector_industrial_porcentaje: [null, []],
      sector_comercial_porcentaje: [null, []],
      sector_servicios_porcentaje: [null, []],
      sector_otros_porcentaje: [null, []],
      sector_texto: [null, []],
      actividad_principal_texto: [null, [Validators.required]],
      actividad_complementaria_1_texto: [null, []],
      actividad_complementaria_2_texto: [null, []],
    });
  }

  onSelectChange(item, porcentaje) {
    this.dataForm
      .get(item)
      .valueChanges.pipe(startWith(null), pairwise())
      .subscribe(([prev, next]) => {
        if (!prev && !!next) this.dataForm.controls[porcentaje].enable();
        if (item !== "sector") {
          this.actividadesSelected = this.actividadesSelected?.filter(
            (el) => el.id !== next
          );
          if (!!prev) {
            let prevItem = this.actividades.findIndex((el) => el.id === prev);
            this.actividadesSelected.push(this.actividades[prevItem]);
          }
        }
      });
  }

  setForm(cliente) {
    for (const key in cliente) {
      if (this.dataForm.controls[key]) {
        if (key == "inicio_operaciones") {
          this.dataForm.controls[key].setValue(this.parseDate(cliente[key]));
          continue;
        }

        this.dataForm.controls[key].setValue(cliente[key]);
      }
    }
  }

  onSubmit() {
    if (this.dataForm.valid) {
      const value = this.dataForm.value;
      value["inicio_operaciones"] = this.formatDate(
        value["inicio_operaciones"]
      );
      this.clientesService
        .guardarCliente(value, this.clienteId)
        .then((res: any) => {
          this.router.navigate(["/clientes", res.id]);
        });
    }
  }

  formatDate(date) {
    if (!(date instanceof Date)) return;
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate() +
      ` ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
    );
  }

  parseDate(date) {
    if (!date) return null;

    let dateNow = new Date();

    let yy = date.split("-")[0];
    let mm = date.split("-")[1] - 1;
    let dd = date.split("-")[2].substring(0, 2);

    let d = new Date(yy, mm, dd, dateNow.getHours());
    return d;
  }
}
