import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class PoderesService {

	private apiUrl         : string = '';
	public poderes         : string = 'clientes/poderes/';
	public resultados: string = 'clientes/poderesresultados';
	
	constructor(
		public appService: AppService,
		public toast     : ToastService,
		public spinner   : NgxSpinnerService,
		public http      : HttpClient,
		public auth      : AuthService
	) {
        this.apiUrl = this.appService.settings.API_base_url
   }
   
   	/**
	* Habilita el loader para request a la API
	*/
	spinnerOn(){
		this.spinner.show()
	}
	
	/**
	* Desabilita el loader
	* @param mensaje Mensaje del toast
	* @param ok Tipo de mensaje, TRUE para success, FALSE para errores
	*/
	spinnerOff(mensaje: string = null, ok : boolean = true){
		this.spinner.hide()
		this.appService.notifyMe(mensaje,ok)
		
		if(mensaje && ok)
		this.toast.success(mensaje)
		if(mensaje && !ok)
		this.toast.warning(mensaje)
	}

  	/**
	 * Listar los poderes del modal de creación de contacto
	 */
  obtenerPoderes(){
    const URL = this.apiUrl+
            this.poderes+'?page_size=20';

    return new Promise((resolve, reject) => {
        const httpRequest = this.http.get(URL);
        httpRequest.subscribe(data => {
            resolve(data);
        }, (err) => {
            reject(err);
        });
    });
}	

  	/**
	 * Listar los resultados para llenar en el <select> del modal de creación de contacto
	 */
  obtenerResultados(){
    const URL = this.apiUrl+
            this.resultados;

    return new Promise((resolve, reject) => {
        const httpRequest = this.http.get(URL);
        httpRequest.subscribe(data => {
            resolve(data);
        }, (err) => {
            reject(err);
        });
    });
}

}
