import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { parseDate } from 'ngx-bootstrap/chronos';

@Component({
  selector: 'app-add-shareholders',
  templateUrl: './add-shareholders.component.html',
  styleUrls: ['./add-shareholders.component.css']
})
export class AddShareholdersComponent implements OnInit {
  faTimes=faTimes;
  public minDate: Date;
  myForm: FormGroup;
  @Input() clienteId = 0;
  paises : any[];
  @Input() accionistaId = 0;
  accionista : any;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  personas: any[];

  constructor(
    public formbuild        : FormBuilder,
    public activeModal      : NgbActiveModal,
    private clientesService	: ClientesService,
  ) {
    this.initForm();
   }

  ngOnInit(): void {
    this.obtenerPaises();
    this.getTiposPersonas();

    if (this.accionistaId) {
      this.obtenerAccionista();
    }
  }

  getTiposPersonas() {
    this.clientesService.tiposPersonas()
    .then(res => {
      this.personas = res['results'];
    })
    .catch(error => {
      console.error(error);
    })
  }

  obtenerPaises() {
    this.clientesService.obtenerPaises()
    .then(res => {
      this.paises = res['results'];
    })
    .catch(error => {
      console.error(error);
    });
  }

  obtenerAccionista() {
    this.clientesService.obtenerAccionista(this.accionistaId)
    .then(res => {
      this.accionista = res;
      this.setForm(res);
    })
    .catch(error => {
      console.error(error);
    });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre              : [null, [Validators.required]],
      tipo_persona        : [null, [Validators.required]],
      dni_ruc             : [null, [Validators.required, Validators.maxLength(50)]],
      fecha_nacimiento    : [null, []],
      nacionalidad        : [null, []],
      aporte              : [null, []],
      participacion       : [null, []]
    });
  }

  get f() {
    return this.myForm.controls;
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        if (key == 'fecha_nacimiento') {
          this.myForm.controls[key].setValue(this.parseDate(item[key]));
          continue;
        }

        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  onSubmit() {
    if(this.myForm.valid) {
      const data = this.myForm.value;
      data['cliente'] = this.clienteId;
      data['fecha_nacimiento'] = this.formatDate(data['fecha_nacimiento']);

      this.clientesService.guardarAccionista(this.myForm.value, this.accionistaId)
      .then(res => {
        this.successEmit.emit();
        this.activeModal.close(false);
      }).
      catch(error => {
        this.activeModal.close(false);
      });
    }
  }

  formatDate(date){
    if (!(date instanceof Date))
        return;
    return date.getFullYear() + 
                "-" + (date.getMonth() + 1) + 
                "-" + date.getDate();
  }

  parseDate(date) {
    if (!date)
      return null;
    
    let dateNow = new Date();
    
    let yy = date.split('-')[0];
    let mm = (date.split('-')[1] - 1);
    let dd = date.split('-')[2];

    let d = new Date(yy, mm, dd, dateNow.getHours());
    console.log(d);
    return d;
  }

}
