import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ClientesService } from "app/core/services/clientes/clientes.service";

@Component({
  selector: "app-antecedentes-credito",
  templateUrl: "./antecedentes-credito.component.html",
  styleUrls: ["./antecedentes-credito.component.css"],
})
export class AntecedentesCreditoComponent implements OnInit {
  active = 1;
  @Input() clienteId = 0;
  @Input() readonly = false;

  constructor(
    private clientesService: ClientesService,
    private router: Router
  ) {}

  ngOnInit(): void {}
}
