import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';

@Component({
  selector: 'app-manage-condiciones-modal',
  templateUrl: './manage-condiciones-modal.component.html',
  styleUrls: ['./manage-condiciones-modal.component.css']
})
export class ManageCondicionesModalComponent implements OnInit {
  faTimes = faTimes;
  myForm: FormGroup;
  @Input() condicionId = 0;
  condicion: any;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild              : FormBuilder,
    public activeModal            : NgbActiveModal,
    private configurationService	: ConfigurationService,
  ) { 
    this.initForm();
  }

  ngOnInit(): void {
    if (this.condicionId) {
      this.obtenerCondicion();
    }
  }

  initForm() {
    this.myForm = this.formbuild.group({
      descripcion: [null, [Validators.required]],
    });
  }
  
  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  obtenerCondicion() {
    this.configurationService.obtenerCondicion(this.condicionId)
    .then(res => {
      this.condicion = res;
      this.setForm(res);
    })
    .catch(error => {
      console.error(error);
    });
  }

  onSubmit() {
    if(this.myForm.valid) {
      const data = this.myForm.value;

      this.configurationService.guardarCondicion(this.myForm.value, this.condicionId)
      .then(res => {
        this.successEmit.emit();
        this.activeModal.close(true);
      }).
      catch(error => {
        this.activeModal.close(false);
      });
    }
  }

}
