import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'app/app.service';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class ExcedentesService {
  private apiUrl: string = "";
  private excedentesUrl: string = "/factoring/excedentes/";
  private excedentesEstadsUrl: string = "/factoring/excedentes/estados/";
  private excedentesComentarios: string = "/factoring/excedentes/comentarios/";
  private excedentesAplicaciones: string = "/factoring/excedentes/aplicaciones/";
  private aprobaciones: string = "factoring/solicitudesdevolucion/aprobadores/";
  private operacionTipoDetalle: string =  "/factoring/operaciones/tipodetalle/";
  public devoluciones: string = 'factoring/solicitudesdevolucion/';
  public observacionSolicitud: string = "factoring/solicitudesdevolucion/observaciones/";
  public deuda: string = "factoring/beneficiarios/deuda/resumen/";

  constructor(
    public appService: AppService,
    public spinner: NgxSpinnerService,
    public toast: ToastService,
    public router: Router,
    private http: HttpClient,
    public localService: LocalServiceService
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
  }

  /**
 * Habilita el loader para request a la API
 */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Mostros errores recibidos del servidor
   * @param error Error enviado del servidor
   */
  showErrors(error) {
    const err = error.error;

    for (const key in err) {
      if (Object.prototype.hasOwnProperty.call(err, key)) {
        const element = err[key];
        console.log(element);

        if (Array.isArray(element) && element.length) {
          element.forEach(item => {
            this.spinnerOff(item, false);
          });
        } else {
          this.spinnerOff(element, false);
        }

      }
    }
  }
  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();
    if(mensaje){
      if(ok){
        this.toast.success(mensaje)
      } else {
        this.toast.warning(mensaje)
      }
    }
    this.appService.notifyMe(mensaje, ok)
  }

  crearSolicitudObservaciones(data) {
    const url = this.apiUrl + this.observacionSolicitud;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.post(url, data)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          ref(err);
        });
    });
  }

  obtenerDeudaDeudor(deudorId) {
    const url = this.apiUrl + 'factoring/deudores/deuda/resumen/' + `${deudorId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  obtenerDeudaBeneficiario(beneficiarioId) {
    const url = this.apiUrl + this.deuda + `${beneficiarioId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          this.showErrors(err);
          ref(err);
        });
    });
  }

  crearDevolucion(data, devolucionId = 0) {
    // console.log(data, devolucionId)
    const url = devolucionId
      ? this.apiUrl + this.devoluciones + `${devolucionId}/`
      : this.apiUrl + this.devoluciones;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (devolucionId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff('Se ha editado la devolución exitosamente');
            res(response);
          },
          (err) => {
            if(err.error.fecha_contable){
              this.spinnerOff(err.error.fecha_contable, false);
            }else{

              this.spinnerOff("La operación falló", false);
            }
            ref(err);
          }
        );
      } 
    });
  }

  resolverSolicitudObservaciones(data) {
    const url = this.apiUrl + this.observacionSolicitud;
    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.patch(url, data)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          ref(err);
        });
    });
  }

  obtenerDevolucionesObservadas(
    page: number = 1,
    page_size: number = 100,
    cliente__iconstains: string = '',
    monto__gte: string = '',
    monto__lte: string = '',
    fecha_solicitud__gte: string = '',
    fecha_solicitud__lte: string = '',
    fecha_aprobacion__gte: string = '',
    fecha_aprobacion__lte: string = '',
    estado: string = '6',
    empresa: string = '',
    aceptante__iconstains: string = '',
  ) {

    const url = this.apiUrl + this.devoluciones +
      `?cliente__iconstains=${cliente__iconstains}` +
      `&monto__gte=${monto__gte}` +
      `&monto__lte=${monto__lte}` +
      `&fecha_solicitud__gte=${fecha_solicitud__gte}` +
      `&fecha_solicitud__lte=${fecha_solicitud__lte}` +
      `&fecha_aprobacion__gte=${fecha_aprobacion__gte}` +
      `&fecha_aprobacion__lte=${fecha_aprobacion__lte}` +
      `&estado=${estado}` +
      `&page_size=${page_size}` +
      `&empresa=${empresa}` +
      `&aceptante__iconstains=${aceptante__iconstains}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          ref(err);
        });
    });

  }

  obtenerAprobadores() {
    const url = this.apiUrl + this.aprobaciones;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  obtenerExcedentesLibres(
    page                  : number = 1,
    page_size             : number = 10,
    tipo_documento        = '',
    numero_documento      = '',
    fecha__gte            = '',
    fecha__lte            = '',
    monto__gte            = '',
    monto__lte            = '',
    estado                = '',
    operacion             = '',
    recaudacion           = '',
    cliente__iconstains   = '',
    aceptante__iconstains = '',
    empresa               = '',
    fecha_creacion__gte   = '',
    fecha_creacion__lte   =  '',
    propietario: number = null,
  ) {

    const url = this.apiUrl + `factoring/excedentes/libres/` +
      `?page=${page}` +
      `&page_size=${page_size}` +
      `&tipo_documento=${tipo_documento}` +
      `&numero_documento=${numero_documento}` +
      `&fecha__gte=${fecha__gte}` +
      `&fecha__lte=${fecha__lte}` +
      `&monto__gte=${monto__gte}` +
      `&monto__lte=${monto__lte}` +
      `&estado=${estado}` +
      `&operacion=${operacion}` +
      `&recaudacion=${recaudacion}` +
      `&cliente__iconstains=${cliente__iconstains}` +
      `&empresa=${empresa}` +
      `&aceptante__iconstains=${aceptante__iconstains}`+
      `&fecha_creacion__gte=${fecha_creacion__gte}`+
      `&fecha_creacion__lte=${fecha_creacion__lte}`+
      `&propietario=${propietario || ''}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (response) => {
          let error = 'Ocurrió un error inesperado'
          if (response.error && typeof (response.error) == 'object') {
            error = Object.values(response.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
          }
          this.spinnerOff(error,false);
          ref(response);
        });
    });
  }

  obtenerExcedentes(
    page: number = 1,
    page_size: number = 10,
    tipo_documento = '',
    numero_documento = '',
    fecha__gte = '',
    fecha__lte = '',
    monto__gte = '',
    monto__lte = '',
    estado = '',
    operacion = '',
    recaudacion = '',
    cliente__iconstains = '',
    aceptante__iconstains = '',
    empresa = '',
  ) {

    const url = this.apiUrl + this.excedentesUrl +
      `?page=${page}` +
      `&page_size=${page_size}` +
      `&tipo_documento=${tipo_documento}` +
      `&numero_documento=${numero_documento}` +
      `&fecha__gte=${fecha__gte}` +
      `&fecha__lte=${fecha__lte}` +
      `&monto__gte=${monto__gte}` +
      `&monto__lte=${monto__lte}` +
      `&estado=${estado}` +
      `&operacion=${operacion}` +
      `&recaudacion=${recaudacion}` +
      `&cliente__iconstains=${cliente__iconstains}` +
      `&empresa=${empresa}` +
      `&aceptante__iconstains=${aceptante__iconstains}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          ref(err);
        });
    });
  }

  obtenerExcedentesTipos(
    page: number = 1,
    page_size: number = 1000) {
    const url = this.apiUrl + this.operacionTipoDetalle +
      `?page=${page}` +
      `&page_size=${page_size}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          ref(err);
        });
    });
  }

  obtenerExcedentesEstados(
    page: number = 1,
    page_size: number = 1000) {
    const url = this.apiUrl + this.excedentesEstadsUrl +
      `?page=${page}` +
      `&page_size=${page_size}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          // this.spinnerOff('La operación falló',false);
          ref(err);
        });
    });
  }

  obtenerExcedentesComentarios(
    excedente,
    responsable = '') {
    const url = this.apiUrl + this.excedentesComentarios +
      `?excedente=${excedente}` +
      `&responsable=${responsable}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff('La operación falló', false);
          ref(err);
        });
    });
  }

  obtenerExcedentesAplicaciones(
    excedente,
    recaudacion = '') {
    const url = this.apiUrl + this.excedentesAplicaciones +
      `?excedente=${excedente}` +
      `&recaudacion=${recaudacion}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff('La operación falló', false);
          ref(err);
        });
    });
  }

  obtenerHistorialExcedente(excedenteId) {
    const url = this.apiUrl + `factoring/excedentes/${excedenteId}/historial`

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response);
        }, (err) => {
          this.spinnerOff('La operación falló', false);
          ref(err);
        });
    });
  }

  /**
   * 
   * @param body 
   */
  crearComentarioCuentaCobrar(body) {
    const url = this.apiUrl +
      this.excedentesComentarios;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.post(url, body)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }


  // generarDevoluciones(body) {
  //     const url = this.apiUrl +
  //       `factoring/excedentes/generar_devoluciones/`;
  
  //     return new Promise((res, ref) => {
  //       this.spinnerOn()
  //       this.http.post(url, body)
  //         .subscribe((response) => {
  //           this.spinnerOff('Las devoluciones fueron generadas con éxito',true);
  //           res(response)
  //         }, (err) => {
  //           console.log(err)
  //           this.spinnerOff(err.error.error,false);
  //           ref(err);
  //         });
  //     });
  //   }

  /**
   * 
   * @param body 
   * @param id 
   */
  editarComentarioCuentaCobrar(body, id) {
    const url = this.apiUrl +
      this.excedentesComentarios + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.put(url, body)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

  /**
   * 
   * @param body 
   * @param id 
   */
  eliminarcomentarioCuentaCobrar(body, id) {
    const url = this.apiUrl +
      this.excedentesComentarios + `${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.delete(url, body)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }


  generarDevoluciones(body) {
    const url = this.apiUrl +
      `factoring/excedentes/generar_devoluciones/`;

    return new Promise((res, ref) => {
      this.spinnerOn()
      this.http.post(url, body)
        .subscribe((response) => {
          let mensaje = 'Las Devoluciones fueron generadas con éxito'
          try {
            if (response && typeof (response) == 'object') {
              mensaje = Object.values(response).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
            }
          } catch {}
          this.spinnerOff(mensaje, true);
          res(response)
        }, (err) => {
          this.spinnerOff();
          ref(err);
        });
    });
  }

}
