import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FacturacionService } from 'app/core/services/facturacion/facturacion.service';
import { AuthService } from '../../../../../core/services/auth/auth.service';

@Component({
  selector: 'app-factura-detalle-modal',
  templateUrl: './factura-detalle-modal.component.html',
  styleUrls: ['./factura-detalle-modal.component.css']
})
export class FacturaDetalleModalComponent implements OnInit {
  faTimes = faTimes
  myForm: FormGroup;
  @Input() glosa;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private modalService  : NgbModal,
    public activeModal    : NgbActiveModal,
    public formbuild      : FormBuilder,
    private facturacionService    : FacturacionService,
    private authService :AuthService
  ) { 
    
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.myForm = this.formbuild.group({
      glosa: [this.glosa.glosa, [Validators.required]]
    });
  }

  onSubmit() {
    console.log('Submir');
    // return;
    this.facturacionService.editarFacturaDetalles(this.myForm.value, this.glosa.id).then(resp => {
      console.log(resp);
      this.successEmit.emit();
      this.activeModal.close(true);
    }, err => {
      console.error(err);
      
    })
  }

  get canAdd() {
		const perfilesPermitidos = [
		  this.authService.perfil.perfilAdmin,
		  this.authService.perfil.perfilJefeDeOperacionesID,
		//   this.authService.perfil.perfilAnalistaOperacionesID,
		  this.authService.perfil.perfilAsistenteCobranzasID,
		//   this.authService.perfil.perfilAnalistaOperacionesID,
		  this.authService.perfil.perfilGerenciaComercialID,
		  this.authService.perfil.perfilGerenciaGeneralID,
		]
	
		return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
	}

}
