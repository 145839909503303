import { animation } from '@angular/animations';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { faMoneyCheck, faMoneyCheckAlt, faWallet } from '@fortawesome/free-solid-svg-icons';
import { MonedaPipe } from 'app/core/pipes/moneda.pipe';
import { SharedFunctions } from 'app/core/shared/functions';
import Chart from 'chart.js';

@Component({
	selector: 'app-indicador',
	templateUrl: './indicador.component.html',
	styleUrls: ['./indicador.component.css'],
	providers: [MonedaPipe]
})
export class IndicadorComponent implements OnInit, OnChanges {
	faMoneyCheckAlt = faMoneyCheckAlt;
	faWallet = faWallet;
	public chartColor;
	public canvas: any;
	public ctx;
	public gradientStroke;
	public gradientFill;
	moneda: boolean = false;
	_indicador;
	_indicadorNombre;
	_rangoFechas: Date[];
	
	@Input() liderOficial:any;
	@Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();
	@Input() title;
	@Input() indicadorTitle;
	@Input() indicadorId;
	@Input() set indicador(value) {
		this._indicador = value
		setTimeout(() => {
			if (this._indicador) {
				this.evalCharts();
			}
		}, 100)
		}
	@Input() indicadorFechas : boolean;
	@Input() rangoFechas : { filterValue: Date | null  }[] = [{ filterValue: null}, {filterValue : null}];
	@Output() onRangoFechasChange = new EventEmitter<{startDate: Date, endDate: Date}>();
	@Output() onRangoFechasSincro = new EventEmitter();

	get evaluarType(){
		return this.moneda ? 2 : 1 
	}

	constructor(
		public monedapipe: MonedaPipe,
		public sharedFunctions: SharedFunctions,
		private router: Router,
	) { }

	ngOnInit(): void {
		this._rangoFechas = [ this.rangoFechas[0].filterValue, this.rangoFechas[1].filterValue ]
	}

	ngOnChanges(changes: SimpleChanges): void {
		if(changes.rangoFechas){
			this._rangoFechas = [ this.rangoFechas[0].filterValue, this.rangoFechas[1].filterValue ]
		}
	}

	_onRangoFechasChange(value: Date[]){
		if(value && value.length == 2){
			this.onRangoFechasChange.emit({startDate: value[0], endDate: value[1]})
		}
	}

	_onRangoFechasSincro(){
		this.onRangoFechasSincro.emit();
	}

	update() {
		// this.actualizarDom(this.moneda);
		this.updateEvent.emit(false)
	}

	evalCharts() {
		switch (this.indicadorId) {
			case 1: // Indicador de volumen de operaciones -> this._indicadorNombre = "volumen"
				setTimeout(() => {
					this.cargarIndicadorVolumen()
				}, 1000)
				break;
			case 2: //Indicador de volumen de operaciones -> this._indicadorNombre = 'cantidad'
				setTimeout(() => {
					let dataIndicador = this._indicador.map(item => item.monto_soles)
					this.cargarIndicadorDolares(dataIndicador)
				}, 1000)
				break;
		}
	}

	cargarIndicadorVolumen() {
		this.chartColor = "#FFFFFF";
		this.canvas = document.getElementById(this.indicadorTitle);
		this.ctx = this.canvas.getContext("2d");

		this.gradientStroke = this.ctx.createLinearGradient(500, 0, 100, 0);
		this.gradientStroke.addColorStop(0, '#80b6f4');
		this.gradientStroke.addColorStop(1, this.chartColor);

		this.gradientFill = this.ctx.createLinearGradient(0, 170, 0, 50);
		this.gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
		this.gradientFill.addColorStop(1, "rgba(249, 99, 59, 0.40)");
	
		let tIndicador = this._indicador;
		let tMonedaPipe = this.monedapipe;

		const data = {
			labels: this._indicador.map(item => item.estacion),
			datasets: [{
				label: "Operaciones",
				borderColor: "#6bd098",
				pointRadius: 0,
				pointHoverRadius: 0,
				fill: false,
				borderWidth: 2,
				data: this._indicador.map(item => item.cantidad)
			}]
		};

		const maxValue = this._indicador.length > 0 ? this._indicador.reduce((max, obj) => {
			return (obj.cantidad > max.cantidad) ? obj : max;
		}, this._indicador[0]) : 100;
		
		const options = {
			indexAxis: 'y',
			elements: {
				bar: {
					borderWidth: 2,
				},
			},
			responsive: true,
			onClick: event => {
				let dat = myChart.getElementAtEvent(event)[0]
				if (dat) {
					const datasetIndex = dat._datasetIndex;
					const model = myChart.getElementsAtEvent(event)[datasetIndex]._model;
					this.onBarClicked(model.label, 'sinmoneda');
				}
			},
			scales: {
				yAxes: [{
				  ticks: {
					beginAtZero: true,
					suggestedMax: Math.ceil( maxValue.cantidad + (maxValue.cantidad / 6)),
					suggestedMin: 0,
				  }
				}]
			  },
			tooltips: {
				enabled: true,
				mode: 'label',
				callbacks: {
					title: (tooltipItems, data) => {
						const tooltipItem = tooltipItems[0];
						return `${data.labels[tooltipItem.index]}`;
					},
					label: function(tooltipItems, data) { 
						let montosAsociados = tIndicador[tooltipItems.index] || null;
						let labels = []
						
						if(!montosAsociados){
							labels = ["Cargando..."]
						}else{
							labels = [
								`Cantidad: ${montosAsociados.cantidad}`,
								`Montos: ${tMonedaPipe.transform(montosAsociados.monto_dolares, 2)}`,
							];
							if(montosAsociados.id >= 8) { // Desde "Liquidación" en adelante se agregan los detalles de Adelantos y Desembolsos
								labels  = [ ...labels,
									`Montos adelantos: ${tMonedaPipe.transform(montosAsociados.monto_adelanto_dolares, 2)}`,
									`Montos desembolsos: ${tMonedaPipe.transform(montosAsociados.monto_desembolso_dolares, 2)}`,
								]
							}
						}
						return labels;
					},
				}
			}
		}

		const config = {
			type: 'bar',
			data: data,
			options: options,
		};
		var myChart = new Chart(this.ctx, config);
	}

	onBarClicked(category, type) {
		let estacionId;
		switch (category) {
			case 'Cliente':
				estacionId = 1
				break;
			case 'Oficial':
				estacionId = 2
				break;
			case 'Riesgos':
				estacionId = 3
				break;
			case 'Comité':
				estacionId = 4
				break;
			case 'Post Comité':
				estacionId = 5
				break;
			case 'Legal':
				estacionId = 6
				break;
			case 'Liquidación':
				estacionId = 8
				break;
			case 'Operaciones':
				estacionId = 9
				break;
			case 'Tesorería':
				estacionId = 11
				break;
		}
		this.router.navigate(['/operaciones/estacion', estacionId,  this.liderOficial.oficial == '' ? 'o' :this.liderOficial.oficial, this.liderOficial.lider == '' ? 'l' :this.liderOficial.lider, type === 'moneda' ? this.evaluarType : '' ])
	}

	cargarIndicadorDolares(dataContent = [], labels = this._indicador.map(item => item.estacion), moneda = this.moneda) {
		console.log("DataContent: ", dataContent);
		
		const footer = (tooltipItems) => {
			console.log(tooltipItems)
			return 'exito'
		},
		{ monedapipe } = this

		this.chartColor = "#FFFFFF";
		this.canvas = document.getElementById(this.indicadorTitle);
		this.ctx = this.canvas.getContext("2d");

		this.gradientStroke = this.ctx.createLinearGradient(500, 0, 100, 0);
		this.gradientStroke.addColorStop(0, '#80b6f4');
		this.gradientStroke.addColorStop(1, this.chartColor);

		this.gradientFill = this.ctx.createLinearGradient(0, 170, 0, 50);
		this.gradientFill.addColorStop(0, "rgba(128, 182, 244, 0)");
		this.gradientFill.addColorStop(1, "rgba(249, 99, 59, 0.40)");
		const data = {
			labels: labels,
			datasets: [{
				label: moneda ? 'Dólares' : 'Soles',
				borderColor: "#6bd098",
				pointRadius: 0,
				pointHoverRadius: 0,
				fill: false,
				borderWidth: 3,
				data: dataContent
			}]
		};

		const maxValue = dataContent.length > 0 ? dataContent.reduce((max, obj) => {
			return (obj > max) ? obj : max;
		}, dataContent[0]) : 100;

		const plugins = {
			legend: {
				position: 'bottom',
			},
			title: {
				display: true,
				text: 'Chart.js Horizontal Bar Chart'
			},
			tooltip: {
				callbacks: {
					footer: footer,
					beforeLabel: footer
				}
			}
		}

		const options = {
			indexAxis: 'y',
			elements: {
				bar: {
					borderWidth: 2,
				}
			},
			responsive: true,
			onClick: event => {
				let dat = myChart.getElementAtEvent(event)[0]
				if (dat) {
					const datasetIndex = dat._datasetIndex;
					const model = myChart.getElementsAtEvent(event)[datasetIndex]._model;
					this.onBarClicked(model.label, 'moneda');
				}
				// console.log(model);
			},
			scales: {
				yAxes: [{
					ticks: {
						beginAtZero: true,
						suggestedMax: Math.ceil( maxValue + (maxValue / 6)),
						suggestedMin: 0,
						callback(value, index, values) {
							return monedapipe.transform(value, moneda ? 2 : 1);
						},
					}
				}]
			},
			tooltips: {
				enabled: true,
				mode: 'label',
				callbacks: {
					label: function (tooltipItems, data) {
						let valor = data.datasets[0].data[tooltipItems.index]
						return monedapipe.transform(valor, moneda ? 2 : 1);
					},
				}
			},
		};

		const config = {
			type: 'bar',
			data: data,
			options: options
		};

		var myChart = new Chart(this.ctx, config);
	}

	actualizarDom(event) {
		console.log(event);
		let canva = document.getElementById(this.indicadorTitle)
		let idCanva = `canvaContainer${this.indicadorTitle}`

		var element = document.getElementById(idCanva);

		// if (element) {
		// 	let parent = element.parentNode
		// 	parent.removeChild(element);
		// 	console.log('Entro en ciclo')
		// 	// canvaContainer.removeChild(canvaContainer.childNodes[1])

		// 	var newCanva = document.createElement("canvas");
		// 	newCanva.setAttribute("id", this.indicadorTitle);
		// 	newCanva.setAttribute("width", "900");
		// 	newCanva.setAttribute("height", "320");

		// 	// let canvaContainer = document.getElementById(idCanva)
		// 	parent.appendChild(newCanva);
		// }

		let { moneda } = this
		let labels = this._indicador.map(item => item.estacion)
		if (event) {
			
			let dataIndicador = this._indicador.map(item => item.monto_dolares)
			this.cargarIndicadorDolares(dataIndicador, labels, true)
		} else {
			let dataIndicador = this._indicador.map(item => item.monto_soles)
			this.cargarIndicadorDolares(dataIndicador, labels, false)
		}
	}

	public hexToRGB(hex, alpha) {
		var r = parseInt(hex.slice(1, 3), 16),
			g = parseInt(hex.slice(3, 5), 16),
			b = parseInt(hex.slice(5, 7), 16);

		if (alpha) {
			return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
		} else {
			return "rgb(" + r + ", " + g + ", " + b + ")";
		}
	}
}
