import { Component, OnInit } from '@angular/core';

import { forkJoin } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

import { NotificacionesService } from 'app/core/services/notificaciones/notificaciones.service';
import { ReenviarComponent } from '../../modals/reenviar/reenviar.component';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.css']
})
export class NotificacionesComponent implements OnInit {
  tblRows: any[] = []
  tblHeaders: columnHeader[]
  tblTotalRows: number
  tblPages: number[]
  tblActualPage: number = 1
  tblPerPage: number = 10
  tblFiltros = {}
  tblAcciones = []

  tipos = []
  estados = []
  tiposEventos = []

  constructor(
    private notificacionesService: NotificacionesService,
    private spinner: NgxSpinnerService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    forkJoin({
      tipos: this.notificacionesService.getTiposNotificacion(),
      estados: this.notificacionesService.getEstadosNotificacion(),
      tiposEventos: this.notificacionesService.getTiposEvento(),
    }).subscribe(({ tipos, estados, tiposEventos }) => {
      this.tipos = tipos
      this.estados = estados
      this.tiposEventos = tiposEventos
      this.initTablaNotificaciones()
    })
  }

  loadDataTablaNotificaciones({ page = this.tblActualPage, per_page = this.tblPerPage, filtros = this.tblFiltros }) {
    this.spinner.show()
    Object.keys(filtros).filter(field => field.includes('fecha')).forEach(field => {
      if (field.includes('__lt')) {
        filtros[field] = filtros[field].replace('00:00', '23:59:59')
      }
    })
    this.tblFiltros = filtros
    this.notificacionesService.getNotificaciones(Object.assign({ page, page_size: per_page }, filtros)).subscribe(
      (data: any) => {
        this.tblRows = data.results
        this.tblTotalRows = data.count
        this.tblPages = Array.from(Array(data.num_pages).keys())
        this.tblPerPage = data.per_page
        this.tblActualPage = data.page_number
      },
      (res: any) => console.log(res)
    ).add(() => this.spinner.hide())
  }

  reenviar(notificacion) {
    this.spinner.show()
    this.notificacionesService.reenviar(notificacion).subscribe(
      (data: any) => {
        console.log(data)
        Object.assign(notificacion, data)
        if (notificacion['error']) {
          let error = notificacion['error']
          try {
            error = JSON.parse(error)
            error = error['message']
          } catch (ex) {}
          this.notificacionesService.toast.warning(error)
        } else {
          this.notificacionesService.toast.success('Notificación enviada.')
        }
      },
      (res: any) => console.log(res)
    ).add(() => this.spinner.hide())
  }

  openModalReenviar(notificacion) {
    const modal = this.modalService.open(ReenviarComponent, {
      size: "md",
    })
    modal.componentInstance.notificacion = notificacion
    modal.result.then(
      (result) => {
        if (result) this.reenviar(notificacion)
      },
      (reason) => { console.log(reason) },
    )
  }

  private initTablaNotificaciones() {
    this.tblAcciones = [
      {
        tooltip: 'Reenviar', icon: 'fa fa-send', class: 'btn-info',
        click: row => this.openModalReenviar(row),
        visible: row => true
      },
    ]
    this.tblHeaders = [
      {
        headerName: 'ID',
        field: 'id',
        class: 'text-center',
        filterable: true,
        filterProp: 'id',
        filterInput: true,
      },
      {
        headerName: 'Fecha registro',
        field: 'fecha_creacion',
        pipe: 'date',
        class: 'text-center',
        filterable: true,
        filterProp: 'fecha_creacion',
        filterDate: true,
      },
      {
        headerName: 'Tipo',
        field: 'tipo_descripcion',
        filterable: true,
        filterProp: 'tipo',
        filterSelectItems: this.tipos,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Evento',
        field: 'evento_descripcion',
        filterable: true,
        filterProp: 'evento',
        filterSelectItems: this.tiposEventos,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Asunto',
        field: 'asunto',
        filterable: true,
        filterProp: 'asunto__icontains',
        filterInput: true,				
        pipe: 'function',
				function: function (row) {
          if(row.tipo == 3){
            return " "
          }else{
            return row.asunto
          }
				},
      },
      // {
      //   headerName: 'Mensaje',
      //   field: 'mensaje',
      //   filterable: true,
      //   filterProp: 'mensaje__icontains',
      //   filterInput: true,
      // },
      {
        headerName: 'Destinatarios',
        field: 'destinatarios',
        filterable: true,
        filterProp: 'destinatarios_raw__icontains',
        filterInput: true,
      },
      {
        headerName: 'Adjuntos',
        field: 'adjuntos',
        filterable: true,
        filterProp: 'adjuntos_raw__icontains',
        filterInput: true,
        pipe: 'tags',
        tagElementKey: 0,
      },
      {
        headerName: 'Comentario',
        field: 'comentario',
        filterable: true,
        filterProp: 'comentario__icontains',
        filterInput: true,
      },
      {
        headerName: 'Fecha envío',
        field: 'fecha_envio',
        pipe: 'date-time',
        class: 'text-center',
        filterable: true,
        filterProp: 'fecha_envio',
        filterDate: true,
      },
      {
        headerName: 'Estado',
        field: 'estado_descripcion',
        filterable: true,
        filterProp: 'estado',
        filterSelectItems: this.estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      // {
      //   headerName: 'Error',
      //   field: 'error',
      //   filterable: true,
      //   filterProp: 'error__icontains',
      //   filterInput: true,
      //   pipe: 'function',
      //   function: row => row.error ? JSON.parse(row.error).message : ''
      // },
    ]
    this.loadDataTablaNotificaciones({})
  }

}
