import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FactoringService } from 'app/core/services/factoring/factoring.service';

@Component({
  selector: 'app-formato-modal',
  templateUrl: './formato-modal.component.html',
  styleUrls: ['./formato-modal.component.css']
})
export class FormatoModalComponent implements OnInit {
  faTimes = faTimes;
  codes = ['01', '02', '03', '04', '05'];

  @Input() tipo_formato;
  @Input() beneficiario: number;
  @Input() beneficiario_tipo;
  @Input() fecha_vencimiento;
  @Input() fecha_contrato;
  @Input() formato_codigo;
  @Input() formato_plantilla_url;
  @Input() descripcion;
  @Input() numero_contrato;
  @Input() montoOperacion;
  @Input() monedaOperacion;
  @Input() monedaSimbolo;
  @Input() operacion;
  @Input() operacionModel;
  @Input() ruta;
  @Input() contrato;

  constructor(
    public factoring: FactoringService
  ) { }

  ngOnInit() {
    console.log(this.tipo_formato);
    console.log(this.contrato);
  }

  isCode(code) {
    return this.codes.includes(code);
  }

  openDocument(adjunto) {
    console.log(adjunto);
    let urlDoc = adjunto;
    const ultimoSlash = new URL(urlDoc).pathname;
    const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1]
    console.log(adjuntoName);
    console.log(adjunto.adjunto);
    this.downloadFile(urlDoc, adjuntoName);
    // var win = window.open(urlDoc, '_blank');
    // win.focus();
  }

  downloadFile(url, name) {
    this.factoring.downloadFile(url).then(
      (x: any) => {
        console.log(x);
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = name;
        link.click();
      }, async (reason) => {
        console.log(reason);
      }), err => {
        console.error(err);
      };
  }

}
