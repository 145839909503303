import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LineaCredito } from 'app/core/services/auth/LineaCredito';
import { LocalServiceService } from 'app/core/services/local-service.service';

@Component({
  selector: 'app-reportelineacredito',
  templateUrl: './reportelineacredito.component.html',
  styleUrls: ['./reportelineacredito.component.css']
})
export class ReportelineacreditoComponent implements OnInit {
	public linea_formulario   : FormGroup;
	public linea_credito: LineaCredito;


  constructor(
		public localService: LocalServiceService,
		public formbuild        : FormBuilder,

  ) {

   }

  ngOnInit(): void {
	let data = this.localService.getJsonValue('LCredito')
	this.linea_credito = new LineaCredito(data)
    this.initFormulario(this.linea_credito,data.operacion)
  }

  initFormulario(linea: LineaCredito, op){
    this.linea_formulario = this.formbuild.group({
			beneficiario_nombre       : [''],
			tasa_linea                : [''],
			monto_solicitado          : [''],
			monto_aprobado            : [''],
			fecha_alta                : [''],
			fecha_plazo               : [''],
			tem_soles                 : [''],
			tem_dolares               : [''],
			tea_soles                 : [''],
			tea_dolares               : [''],
			porcentaje_anticipo       : [''],
			porcentaje_mora           : [''],
			observaciones_lineacredito: [''],
		});

    this.linea_formulario.reset({
			beneficiario_nombre                : op.operacion.beneficiario_linea_nombre,
			tasa_linea                : linea.tipo_descripcion,
			monto_solicitado          : op.linea_solicitada,
			monto_aprobado            : linea.linea_actual,
			fecha_alta                : linea.fecha_altaDate,
			fecha_plazo               : linea.fecha_vencimientoDate,
			tem_soles                 : linea.tem_soles,
			tem_dolares               : linea.tem_dolares,
			tea_soles                 : this.teas(linea.tem_soles),
			tea_dolares               : this.teas(linea.tem_dolares),
			porcentaje_anticipo       : linea.anticipo,
			porcentaje_mora           : linea.mora,
			observaciones_lineacredito: linea.observaciones,			
		})
	
	this.linea_formulario.disable()
  }

  teas(value) {
		let amount = 0;
		let val = 1 + (value / 100);
		amount = Math.pow(val, 12) - 1;
		amount *= 100;
		return amount.toFixed(2)
	}

}
