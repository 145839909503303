import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { JudicialService } from 'app/core/services/judicial/judicial.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { DocsCarterasModalComponent } from '../components/docs-carteras-modal/docs-carteras-modal.component';
import { ExpedientejudicialComponent } from '../components/expedientejudicial/expedientejudicial.component';

@Component({
  selector: 'app-judicial',
  templateUrl: './judicial.component.html',
  styleUrls: ['./judicial.component.css']
})
export class JudicialComponent implements OnInit {

  @ViewChild(DocsCarterasModalComponent) documentoCarteraModal: DocsCarterasModalComponent;
  faSyncAlt=faSyncAlt;
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = "";
  public canCreate: boolean = false;
  public tmpData: any;

  constructor(
    public modalService: NgbModal,
    public router: Router,
    private factoringService: FactoringService,
    public authService: AuthService,
    public judialService: JudicialService,
    public documentosCarteraService: DocumentosCarteraService
  ) { }

  ngOnInit(): void {
    this.obtenerJudial();
  }

  obtenerJudial() {

    let judicial = this.judialService.obtenerJudial();
    let estados = this.documentosCarteraService.obtenerEstadosCarteras();
    let empresas = this.factoringService.obtenerEmpresas();

    Promise.all([judicial, estados, empresas]).then(
      (res: any[]) => {
        let documents = res[0];
        let estados = res[1]['results'];
        let empresas = res[2]['results'];
        this.initVars(estados, empresas);
        this.loadTable(documents);
      }
    );

  }

  loadTable(data) {
    this.rowData = data.results.map((element, index) => ({
      ...element, index: index + 1
    }));
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars(estados, empresas) {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "beneficiario_nombre",
        sortable: true,
        filterable: true,
        filterProp: "beneficiario",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "beneficiario_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
        sortable: true,
        filterable: true,
        filterProp: "aceptante",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "aceptante_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "operacion_tipo_detalle",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Nro Documento",
        field: "operacion_numero_documento",
        sortable: true,
        filterable: true,
        filterProp: "operacion_numero_documento",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Fch. Vcto",
        field: "fecha_vencimiento",
        pipe: "date",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto_neto",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        filterable: true,
        filterRange: true
      },
      {
        class: "text-center",
        headerName: "Saldo X Pagar",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
      {
        headerName: 'Estado',
        field: 'estado_descripcion',
        filterProp: 'estado',
        sortable: true,
        class: 'text-center',
        filterable: true,
        filterSelectItems: estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Operación ',
        field: 'operacion',
        pipe: 'actionable',
        textField: 'operacion',
        class: 'text-center',
        sortable: true,
        filterable: true,
        filterProp: 'operacion',
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Prórroga",
        field: "dias_prorroga",
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {

    const range = (filtros.valueFrom && filtros.valueTo) ? `${filtros.valueFrom},${filtros.valueTo}` : '';

    this.judialService.obtenerJudial(
      page,
      per_page,
      filtros.beneficiario,
      filtros.aceptante,
      filtros.operacion_numero_documento,
      range,
      filtros.estado,
      filtros.operacion,
      filtros.empresa
    )
      .then(res => {
        this.loadTable(res);
      })
      .catch(error => {
        const data = {
          count: 0,
          next: null,
          num_pages: 1,
          page_number: 1,
          per_page: 10,
          previous: null,
          results: []
        }

        this.loadTable(data);
      });

  }

  tableEditEvent(row) {
    let comentarios = this.documentosCarteraService.obtenerComentariosDocumento(row.id)
    let bandejaRecaudo = this.documentosCarteraService.obtenerBandejaRecaudo(row.id)
    let prorrogas = this.documentosCarteraService.obtenerBandejaProrroga(row.id)
    let cabecera = this.documentosCarteraService.obtenerCabeceraCartera(row.id)

    this.tmpData = row
    Promise.all([comentarios, bandejaRecaudo, prorrogas, cabecera]).then((response: any[]) => {
      let comentarios = response[0].results.map((comentario) => {
        comentario.fecha = this.parseDate(comentario.fecha)
        return comentario
      })
      let bandejaRecaudos = response[1].results
      let prorrogas = response[2].results
      let cabecera = response[3]
      this.documentoCarteraModal.initModal(
        cabecera,
        comentarios,
        bandejaRecaudos,
        prorrogas)
    })
  }

  workflowEvent(row) {
    this.router.navigate(['/operaciones', row.operacion]);
  }

  parseDate(fecha) {

    let yy = fecha.split('-')[0];
    let mm = fecha.split('-')[1];
    let dd = fecha.split('-')[2];
    dd = dd.split('T')[0]

    let hora = Number(fecha.split('T')[1].split(':')[0])
    let momento = (hora >= 12) ? "PM" : "AM";
    hora = (hora >= 12) ? hora - 12 : hora
    let minuto = fecha.split('T')[1].split(':')[1]
    let hour = `  ${hora}:${minuto} ${momento}`

    let date = `${dd}/${mm}/${yy} ${hour}`;
    return date
  }

  guardarCambios(body) {
    console.log(body)
    this.documentosCarteraService.modificarCabecera(body, this.tmpData.id).then((res) => {
      console.log(body)
    })
  }

  editaComentario(event) {
    let hoy = new Date();

    event.fecha = hoy.toISOString()
    this.documentosCarteraService.editarComentarioDocumento(event, event.id).then((result) => {
      return this.documentosCarteraService.obtenerComentariosDocumento(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.parseDate(comentario.fecha)
        return comentario
      })
      this.documentoCarteraModal.refrescaComentarios(comentarios)
    })
  }

  eliminaComentario(event) {
    this.documentosCarteraService.eliminarcomentarioDocumento(event, event.id).then((result) => {
      return this.documentosCarteraService.obtenerComentariosDocumento(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.parseDate(comentario.fecha)
        return comentario
      })
      this.documentoCarteraModal.refrescaComentarios(comentarios)
    })
  }

  nuevoComentario(event: string) {
    let user = this.authService.user
    let commentBody = {
      documento_cartera: this.tmpData.id,
      comentario: event,
      responsable: user.id,
      responsable_nombre: user.nombreCompleto,
      fecha: new Date().toISOString()
    }
    this.documentosCarteraService.crearComentarioDocumento(commentBody).then((result) => {
      return this.documentosCarteraService.obtenerComentariosDocumento(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.parseDate(comentario.fecha)
        return comentario
      })
      this.documentoCarteraModal.refrescaComentarios(comentarios)
    })
  }

}
