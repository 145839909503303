import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { AdminLayoutComponent } from 'app/layouts/admin/admin-layout.component';

import { NotificacionesComponent } from './pages/notificaciones/notificaciones.component';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Notificaciones'
    },
    children: [
      {
        path: 'notificaciones',
        canActivate: [AuthGuard],
        component: NotificacionesComponent,
        data: {
          breadcrumb: 'Notificaciones'
        }
      },
    ]
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NotificacionesRoutingModule { }
