import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, RequiredValidator, Validators } from '@angular/forms';
import { Operacion } from 'app/core/services/auth/Operacion';
import { LegalService } from 'app/core/services/legal.service';
import { LineaCredito } from 'app/core/services/auth/LineaCredito';
import { Contrato } from 'app/core/services/auth/Contrato';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { UploadFilesComponent } from '../upload-files/upload-files.component';
import { AuthService } from 'app/core/services/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateContrato, DateContratoPuntual } from '../utils/date-contrato.validators';
import { ToastService } from 'app/core/services/toast/toast.service';
import { Router } from '@angular/router';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-contrato',
	templateUrl: './contrato.component.html',
	styleUrls: ['./contrato.component.css']
})
export class ContratoComponent implements OnInit {
	faTimes = faTimes;
	test = false;
	public checkchecked       : any = {};
	public linea_formulario   : FormGroup;
	public contrato_formulario: FormGroup;
	public _readonly          : boolean = false;
	@Input() operacion        : Operacion;
	public columnDefs:any[] = [];	
	public columnDefsCliente:any[] = [];
	public loadingTable     : boolean = false;
	
	@Input() set readonly(value){
		if(value && this.contrato_formulario && this.contrato_formulario.get('observaciones_contrato')){
			// this.contrato_formulario.disable()	
			this.linea_formulario.disable()
		}
		this._readonly = value
	};
	
	public linea_credito        : LineaCredito;
	public contrato             : Contrato;
	public documentos           : any[];
	public firmantes            : any[]   = [];
	public tiposFirmantes       : any[]   = [];
	public firmantesDisponibles : any[]   = [];
	public loadingButtonLinea   : boolean = false;
	public loadingButtonContrato: boolean = false;
	public canUploadFiles       : boolean;
	enableSelect: boolean = false;
	firmantePack : any = {
		contrato     : '', 
		tipo_firmante: '', 
		firmante     : '', 
		conyugue     : ''
	}
	
	tipoFirmante : any;
	selectItems: any[] = [];
	_selectFirmantes: any[] = [];
	firmantesBackUp: any = '';
	set selectFirmantes(value){
		this._selectFirmantes = value
	}

	get selectFirmantes(){
		return this._selectFirmantes
	}
	@ViewChild(UploadFilesComponent) uploadComponent: UploadFilesComponent;
	@ViewChild('noLineaCredito', { static: false }) public noLineaCredito;
	@ViewChild("add_firmante", { static: false }) public add_firmante;

	perfilesAutorizados = [this.authService.perfil.perfilLegalID, this.authService.perfil.perfilAsesorLegalID];
	fechaContratoIndefinido = new Date(2100, 0, 1)
	
	constructor(
		public formbuild        : FormBuilder,
		public factoring        : FactoringService,
		public authService      : AuthService,
		private modalService    : NgbModal,
        private legalService	: LegalService,
		private router            : Router,
		public localService: LocalServiceService,
		private toast			: ToastService
	) { 
		
	}
	
	ngOnInit(): void {
		this.initFormulario();
		this.getInfoComponent();
		console.log(this.operacion)
		this.legalService.obtenerOperacionContrato(this.operacion.id).then(res=>{
			if (!!res['contrato']) {
				this.setContrato(new Contrato(res['contrato']))
			}
		})
		

		// Perfiles autorizados para contrato
		const perfiles = [
			this.authService.perfil.perfilLegalID,
			this.authService.perfil.perfilAsesorLegalID,
			this.authService.perfil.perfilGerenciaComercialID,
			this.authService.perfil.perfilGerenciaGeneralID,
		];

		if (perfiles.includes(this.authService.user.perfil)) {
			this.contrato_formulario.enable();
		} else {
			this.contrato_formulario.disable();
		}
		this.canUploadFiles = this.permisoUploadFiles()

		//Deshabilitar input `numero_contrato`
		//this.contrato_formulario.get('numero_contrato').disable()
	}
	
	imprimirLneaCredito(){
		this.linea_credito['operacion'] = this.operacion
		this.localService.setJsonValue("LCredito", this.linea_credito);
		window.open('#/reporte/linea_credito');
//		this.router.navigate(["/reporte/linea_credito"]);
	}

	initFormulario(){
		this.linea_formulario = this.formbuild.group({
			tasa_linea                : [''],
			monto_solicitado          : [''],
			monto_aprobado            : [''],
			fecha_alta                : [''],
			fecha_plazo               : [''],
			tem_soles                 : [''],
			tem_dolares               : [''],
			tea_soles                 : [''],
			tea_dolares               : [''],
			porcentaje_anticipo       : [''],
			porcentaje_mora           : [''],
			observaciones_lineacredito: [''],
			beneficiario_nombre: [{value: '', disabled: true},]
		});

		this.columnDefs = [
			{
				headerName: 'N°', 
				pipe:'indexcol',
				sortable: true
			},
			{
				headerName: 'Representante Legal', 
				field: 'nombre',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName: 'Firma', 
				field: 'esFirmante',
				sortable: true,
				checkDisabled: false,
				pipe: "checkbox",
				class: 'text-center'
			},	
		  ];

		  this.columnDefsCliente = [
			{
				headerName: 'N°', 
				pipe      : 'indexcol',
				sortable  : true
			},
			{
				headerName: 'Representante Legal', 
				field     : 'firmante_nombre',
				sortable  : true,
				class     : 'text-center'
			},
			{
				headerName: 'Tipo Firma', 
				field     : 'tipo_firmante_descripcion',
				sortable  : true,
				class     : 'text-center'
			},
			{
				headerName   : 'Verificado', 
				field        : 'verificado',
				sortable     : true,
				checkDisabled: !(this.authService.user.isJefeDeOperaciones || this.authService.user.isGerenteComercial || this.authService.user.isGerenteGeneral || this.operacion.isAnalistaOperacion(this.authService.user)),
				pipe         : "checkbox",
				class        : 'text-center',
				function     : function (row) {
					return false;
				}
			},					
		  ];	
		  
		let puntual  = this.operacion.puntual
		//let num_contrato_default = this.linea_credito.nro_contrato_default
		
		this.contrato_formulario = this.formbuild.group({
			numero_contrato       : ['',[Validators.required]],
			fecha_contrato        : ['',[Validators.required]],
			fecha_vencimiento     : ['',[Validators.required]],
			vencimiento_indefinido: [false,[]],
			observaciones_contrato: ['']
		}, {
			validators: puntual ? DateContratoPuntual : DateContrato
		});
	}

	checkboxTableChange(event){
		let body = {
			contrato     : event.row.contrato,
			tipo_firmante: event.row.tipo_firmante,
			firmante     : event.row.firmante,
			verificado   : event.newvalue
		}
		this.legalService.editarFirmante(event.row.id,body).then((res:any)=>{
			this.checkchecked = event.checkEvent
		})
	}

	getInfoComponent(){
		if(!this.operacion.linea_credito){
			return
		}
		let lineaCredito    = this.legalService.buscarLineaCredito(this.operacion.linea_credito)
		let tiposDocumentos = this.legalService.obtenerTiposDocumentosContrato()
		let tiposFirmantes  = this.legalService.obtenerTiposFirmantes()

		Promise.all([lineaCredito,tiposDocumentos,tiposFirmantes])
			.then((res: any[])=>{
				this.setLinea(new LineaCredito(res[0]))
				//this.initNroContrato(new LineaCredito(res[0]))
				if(this.operacion.puntual){
					this.documentos = res[1].results.filter((item)=> item.id != 3)
				} else {
					this.documentos = res[1].results.filter((item)=> item.id != 8)
				}
				// console.log(res[1].results, this.operacion)
				this.tiposFirmantes = res[2].results
				if(!this.operacion.nuevo_contrato){
					this.cargarFirmantes();
				}
			})
	}

	cargarFirmantes(idOperacion = this.operacion.id,idContrato = this.operacion.contrato){
		let contrato              = this.legalService.obtenerOperacionContrato(idOperacion)
		let firmantes             = this.legalService.obtenerFirmantes(idContrato)
		let firmantesBeneficiario = this.legalService.obtenerFirmantesBeneficiario(this.linea_credito.beneficiario.toString())
		Promise.all([contrato,firmantes,firmantesBeneficiario])
			.then((res : any[])=>{
				this.setContrato(new Contrato(res[0].contrato))
				this.firmantesDisponibles = res[2].results
				this.setFirmantes(res[1].results)
			})
	}

	initNroContrato(linea : LineaCredito){
		this.linea_credito = linea
		this.contrato_formulario.reset({
			numero_contrato: linea.nro_contrato_default,			
		})
	}


	setLinea(linea : LineaCredito){
		this.linea_credito = linea
		this.linea_formulario.reset({
			tasa_linea                : linea.tipo_descripcion,
			monto_solicitado          : this.operacion.linea_solicitada,
			monto_aprobado            : linea.linea_actual,
			fecha_alta                : linea.fecha_altaDate,
			fecha_plazo               : linea.fecha_vencimientoDate,
			tem_soles                 : linea.tem_soles,
			tem_dolares               : linea.tem_dolares,
			tea_soles                 : this.teas(linea.tem_soles),
			tea_dolares               : this.teas(linea.tem_dolares),
			porcentaje_anticipo       : linea.anticipo,
			porcentaje_mora           : linea.mora,
			observaciones_lineacredito: linea.observaciones,			
			beneficiario_nombre: linea.beneficiario_nombre,			
			nro_contrato_default: linea.nro_contrato_default,			
		})

		this.linea_formulario.disable()
		if(!this._readonly){
			// TODO revisar que perfiles tienen observaciones_lineacredito habilitado
			this.linea_formulario.get('observaciones_lineacredito').enable()
		}
	}



	setContrato(contrato: Contrato){
		this.contrato = new Contrato(contrato)
		
		this.contrato_formulario.reset({
			numero_contrato       : this.contrato.numero_contrato,
			fecha_contrato        : this.contrato.fecha_contratoDate,
			fecha_vencimiento     : this.contrato.fecha_vencimientoDate,
			vencimiento_indefinido: this.contrato.fecha_vencimientoDate.getTime() == this.fechaContratoIndefinido.getTime() ? true : false,
			observaciones_contrato: this.contrato.observaciones			
		})

		// Deshabilitar input 'numero_contrato'
		//this.contrato_formulario.get('numero_contrato').disable() 

		this.buscarDocumentosContrato(this.contrato.id)
	}

	checkUpdate(event){
		this.grabarContrato(event)
	}
	
	formatDate(date){
		if (!(date instanceof Date))
			return;
		return date.getFullYear() + 
					"-" + (date.getMonth() + 1) + 
					"-" + date.getDate();
	}

	grabarContrato(verificacion = false){
		this.contrato_formulario.enable()
		let formulario = this.contrato_formulario.value 

		let hoy = new Date(new Date().setHours(0,0,0,0)).getTime()
		let vencimientoNew = formulario.fecha_vencimiento.getTime()
		let vencimientoOld:any = this.contrato?.fecha_vencimientoDate
		vencimientoOld = vencimientoOld ? vencimientoOld.getTime() : null
		if (vencimientoOld != vencimientoNew && vencimientoNew < hoy) {
			this.toast.warning(`La fecha de vencimiento no debe ser anterior a la fecha de hoy`)
			return false
		}

		let contrato = {
			linea_credito    : this.linea_credito.id,
			numero_contrato  : formulario.numero_contrato,
			fecha_contrato   : this.formatDate(formulario.fecha_contrato),
			fecha_vencimiento: this.formatDate(formulario.fecha_vencimiento),
			observaciones    : formulario.observaciones_contrato,
			vigente          : true,		
			verificado          : verificacion,		
		}

		this.loadingButtonContrato = true
		if(this.operacion.nuevo_contrato){
			this.legalService.actualizarcontrato(contrato,this.operacion.id).then((contrato:any)=>{
				this.setContrato(contrato.contrato)
				// this.operacion.contrato = contrato.contrato.numero_contrato
				this.operacion.contrato = contrato.contrato.id
				this.cargarFirmantes();
				return this.factoring.actualizarOperacion({nuevo_contrato : false},this.operacion.id)
			}).then((operacion)=>{
				this.loadingButtonContrato = false
				this.operacion.nuevo_contrato = false
			})
		} else {
			this.legalService.actualizarBodyContrato(contrato,this.contrato.id).then((contrato : any)=>{
				this.loadingButtonContrato = false
				this.setContrato(contrato)
				this.cargarFirmantes();
			})
		}
	}

	uploadFile(file: FormData){
		let arch = file.get('adjunto');
		if (arch['name'].length > 100) {
			this.toast.warning('Seleccione una archivo con un nombre inferior a 100 caracteres.');
			return;
		}
		file.append('contrato',this.contrato.id.toString());
		this.legalService.agregarDocumentoContrato(file).then((documento)=>{
			this.buscarDocumentosContrato(this.contrato.id);
		});
	}

	buscarDocumentosContrato(idContrato){
		this.legalService.obtenerDocumentosContrato(idContrato).then((documentos : any)=>{
			this.documentos?.forEach((doc)=>{
				let adjunto = documentos.results.find(((adj:any) => adj.documento === doc.id))
				if(adjunto){
					doc.adjunto = adjunto.adjunto
					doc.verificado = adjunto.verificado
					doc.documentoId = adjunto.id
				}
			})
		})
	}

	grabaLineaComentario(){
		this.loadingButtonLinea = true
		this.linea_credito.observaciones = this.linea_formulario.get('observaciones_lineacredito').value
		this.legalService.actualizarLineaCredito(this.linea_credito).then((res)=>{
			this.loadingButtonLinea = false
			this.setLinea(this.linea_credito)
		})
	}

	verificarDocumento(event){
		this.legalService.actualizarDocumentoContrato({
			contrato  : this.contrato.id,
			documento : event.doc.id,
			verificado: event.value
		},event.doc.documentoId).then((response)=>{
			this.uploadComponent.asyncEnd(event)
		})
	}

	errorNoIdLinea(){
		this.modalService.open(this.noLineaCredito, {
			size: 'md'
		}).result.then((response: any)=>{
			console.log(response)
		})
	}

	teas(value) {
		let amount = 0;
		let val = 1 + (value / 100);
		amount = Math.pow(val, 12) - 1;
		amount *= 100;
		return amount.toFixed(2)
	}

	agregaFirmante(){
		this.firmantesBackUp = JSON.stringify(this.firmantes)
		this.modalService.open(this.add_firmante, {
			size: 'lg'
		}).result.then((response: any)=>{
			if(response){
				console.log(this.firmantes)
			}
		})
	}

	checkboxChange(event,alt = null){
		if(!alt.esFirmante){
			this.firmantePack.contrato = this.operacion.contrato
			this.firmantePack.conyugue = false
			this.firmantePack.firmante = alt.id
			this.firmantePack.tipo_firmante = 1
			this.checkchecked = event.checkEvent
			this.asignarFirmante(this.firmantePack)
		} else {
			let firmante = this.firmantes.find((firmante)=> firmante.firmante === alt.id)
			this.eliminarFirmante(firmante.id).then((res:any)=>{
				this.checkchecked = event.checkEvent
				this.cargarFirmantes()
			})
			this.enableSelect = false
		}
	}

	checkIndefinidoUpdate(event,alt = null) {
		if (event) this.contrato_formulario.controls['fecha_vencimiento'].setValue(this.fechaContratoIndefinido)
		else {
			if (this.contrato.fecha_vencimientoDate.getTime() == this.fechaContratoIndefinido.getTime()) { // Si desde BD esta indefinido lo pone undefined
				this.contrato_formulario.controls['fecha_vencimiento'].reset()
			} else { // Sino lo pone como en la BD
				this.contrato_formulario.controls['fecha_vencimiento'].setValue(this.contrato.fecha_vencimientoDate)
			}
		}
	}

	checkConyugue(event,alt = null){
		let firma = this.firmantes.find((firmante)=> firmante.firmante == alt.id)

		if(!alt.conyuge){
			this.legalService.editarFirmante(firma.id,{
				contrato     : this.operacion.contrato,
				tipo_firmante: firma.tipo_firmante,
				firmante     : firma.firmante,
				conyuge     : true,
			}).then((res)=>{
				this.cargarFirmantes()
			})		
		} else {
			this.legalService.editarFirmante(firma.id,{
				contrato     : this.operacion.contrato,
				tipo_firmante: firma.tipo_firmante,
				firmante     : firma.firmante,
				conyuge     : false,
			}).then((res)=>{
				this.cargarFirmantes()
			})	
		}
	}
	
	changeEvent(event,alternative = null){
		let firma = this.firmantes.find((firmante)=> firmante.firmante == alternative.id)
		this.legalService.editarFirmante(firma.id,{
			contrato     : this.operacion.contrato,
			tipo_firmante: event.id,
			firmante     : alternative.id,
		}).then((res)=>{
			this.cargarFirmantes()
		})
		
	}

	asignarFirmante({contrato,tipo_firmante,firmante,conyugue = false}){
		this.legalService.agregarFirmante({
			contrato: contrato, 
			tipo_firmante: tipo_firmante, 
			firmante: firmante, 
			conyuge: conyugue
		}).then((res: any)=>{
			this.enableSelect = false
			this.cargarFirmantes()
		})
	}

	eliminarFirmante(id){
		return this.legalService.eliminarFirmante(id)
	}

	checkPasswordMath(): boolean {
		return  this.contrato_formulario.hasError('dateContrato')  &&
			(this.contrato_formulario.get('fecha_contrato').dirty || this.contrato_formulario.get('fecha_contrato').value) &&
			this.contrato_formulario.get('fecha_vencimiento').dirty;
	}

	setFirmantes(firmantes){
		let resultado = this.firmantesDisponibles.map((acc)=>{
			let indexFirmante = firmantes.find((firmante)=>firmante.firmante == acc.id)
			console.log(this.tiposFirmantes)
			if(indexFirmante){
				acc.esFirmante = true
				acc.conyuge = indexFirmante.conyuge
				acc.tipo_firmante = indexFirmante.tipo_firmante
			} else {
				acc.conyuge = false
				acc.esFirmante = false
				acc.tipo_firmante = null
			}

			return acc
		},[])

		this.firmantesDisponibles = resultado
		this.firmantes = firmantes
		this.filtrarTiposFirmas()
	}

	filtrarTiposFirmas(){
		this.firmantesDisponibles.forEach((firmante)=>{
			if(firmante.esFirmante && firmante.representante_legal){
				firmante.tiposFirmantes = this.tiposFirmantes
			} 
			
			if(firmante.esFirmante && (!firmante.representante_legal)) {
				firmante.tiposFirmantes = [this.tiposFirmantes[0]]
			}
		})
	}

	permisoUploadFiles() {
		let canLiderEquipoUploadFiles = false
		if (this.authService.user.isLiderDeEquipo) {
			let miembrosEquipo = this.authService.miembrosDeleQuipo.map(e => e.id)
      miembrosEquipo.push(this.authService.user.id)
      // agregar al usuario lider a los miembros de equipo
			canLiderEquipoUploadFiles = miembrosEquipo.indexOf(this.operacion.oficial_negocio) !== -1
		}

		return this.operacion.nuevo_contrato
			|| (
				this._readonly
				&& !this.authService.user.isOnlyOficialDeNegocio
				&& !this.authService.user.isJefeDeOperaciones
				&& !canLiderEquipoUploadFiles
			)
	}

	get isAsesorLegal() {

		if (this.authService.user.perfil == this.authService.perfil.perfilLegalID) {
			return this.operacion.actualLegal;
		}

		return (this.authService.user.isAsesorLegal && 
			(this.operacion.actualAsesorLegal || this.operacion.actualLegal || this.operacion.actualOficialNegocioLiquidacion || this.operacion.actualJefeOperaciones));
	}

	get showInputDate() {
		
		if (this.contrato_formulario.controls['fecha_vencimiento'].value instanceof Date) {
			return this.contrato_formulario.controls['fecha_vencimiento'].value?.getTime() != this.fechaContratoIndefinido.getTime();
		} else {
			return true;
		}
		
	}

}
	
