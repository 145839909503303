import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { faChartPie, faCloudDownloadAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { AceptantesService } from "app/core/services/factoring/aceptantes.service";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { functions } from '../../../../core/helpers/functions';

@Component({
  selector: "app-aceptantes",
  templateUrl: "./aceptantes.component.html",
  styleUrls: ["./aceptantes.component.css"],
})
export class AceptantesComponent implements OnInit {
  faTimes = faTimes;
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public searchstring: string = "";
  public toDelete: number;
  faCloudDownloadAlt = faCloudDownloadAlt;
  faChartPie = faChartPie;
  @ViewChild("e_confirmdelete", { static: false }) public e_confirmdelete;

  constructor(
    public modalService: NgbModal,
    private authService: AuthService,
    private router: Router,
    private aceptantesService: AceptantesService
  ) {
    this.user = this.authService.user;
  }

  ngOnInit(): void {
    this.obtenerAceptantes();
  }

  /**
   *
   * @param page
   * @param page_size
   */
  obtenerAceptantes(page = 1, page_size = 10, nombre = "") {
    let aceptantes = this.aceptantesService.obtenerAceptantes(
      page,
      page_size,
      nombre
    );

    Promise.all([aceptantes]).then((res: any[]) => {
      let aceptantes = res[0];
      this.initVars();
      this.loadTable(aceptantes);
    });
  }

  loadTable(data) {

    this.rowData = functions.indextable( data )

    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars() {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Nombre",
        field: "nombre",
        sortable: true,
        filterable: true,
        filterProp: "nombre",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Sector",
        field: "sector_nombre",
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {
    this.obtenerAceptantes(page, per_page, filtros.nombre);
  }

  tableEditEvent(row: any) {
    this.router.navigate(["/aceptante", row.id]);
  }

  get canEdit() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilOficialDeNegocioID,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
      this.authService.perfil.perfilJefeDeAdmisionID,
      this.authService.perfil.perfilAnalistaRiesgosID,
      this.authService.perfil.perfilLegalID,
      this.authService.perfil.perfilAsesorLegalID,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilSubGerenciaComercialID,
      this.authService.perfil.perfilTesoreriaID,
      this.authService.perfil.perfilLiderDeTesoreriaID,
      this.authService.perfil.perfilRecepcionID,
      this.authService.perfil.perfilAsistenteCobranzasID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilLiderDeEquipo,
      this.authService.perfil.perfilAsistenteComercialID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }
}
