import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder } from "@angular/forms";
import { faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AceptantesService } from "app/core/services/factoring/aceptantes.service";
import { MiniAlertComponent } from "app/shared/mini-alert/mini-alert.component";

@Component({
  selector: "app-add-aceptante-add-archive-modal",
  templateUrl: "./add-archive-modal.component.html",
  styleUrls: ["./add-archive-modal.component.css"],
})
export class AddArchiveModalComponent implements OnInit {
  public myForm: FormGroup;
  public archivo: any;
  faTimes = faTimes
  @Input() aceptanteId = 0;
  @Input() archiveId = 0;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild(MiniAlertComponent) alert: MiniAlertComponent;

  constructor(
    public formbuild: FormBuilder,
    public activeModal: NgbActiveModal,
    private aceptantesService: AceptantesService
  ) {}

  ngOnInit(): void {
    if (this.archiveId) {
      this.obtenerArchivo();
    }
  }

  selectFile() {
    document.getElementById("archivo_input").click();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      const formData = new FormData();

      formData.append("archivo", file);
      formData.append("nombre", file?.name);
      formData.append("aceptante", String(this.aceptanteId));
      formData.append("peso", file.size);

      this.aceptantesService
        .guardarArchivo(formData, this.archiveId)
        .then((archivo) => {
          this.archivo = archivo;
          this.successEmit.emit();
          this.activeModal.close(false);
        });
    }
  }

  obtenerArchivo() {
    this.aceptantesService
      .obtenerArchivo(this.archiveId)
      .then((res) => {
        this.archivo = res;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  downloadDoc({ archivo }) {
    if (typeof archivo === "object") {
      this.alert
        .displayAlert({
          title: "Archivo",
          body: "Debe guardar el formulario para poder descargar el archivo",
          truefalse: true,
          accept: false,
        })
        .then((response) => {});
      return;
    }
    let urlDoc = archivo;
    var win = window.open(urlDoc, "_blank");
    win.focus();
  }

  download(adjunto) {
    console.log(adjunto);
    // return;
    let urlDoc = adjunto.archivo;
    const ultimoSlash = new URL(urlDoc).pathname;
    const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1];
    this.downloadFile(urlDoc, adjuntoName);
  }

  downloadFile(url, name) {
    this.aceptantesService.downloadFile(url).then(
        (x: any) => {
        console.log(x);
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = name;
        link.click();
        }, async (reason) => {
        console.log(reason);
        }), err => {
        console.error(err);
    };
  }
}
