import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { ToastService } from 'app/core/services/toast/toast.service';

@Component({
  selector: 'app-formato-mineria-ilegal',
  templateUrl: './formato-mineria-ilegal.component.html',
  styleUrls: ['./formato-mineria-ilegal.component.css']
})
export class FormatoMineriaIlegalComponent implements OnInit {

  myForm: FormGroup;
  estaciones: any[];
  public firmantes: any[] = [];
  firmanteValue;
  cliente_nombre;
  nacionalidad;
  razon_social;

  @Input() title;
  @Input() beneficiario: number;
  @Input() beneficiario_tipo;
  @Input() formato_codigo;
  @Input() formato_plantilla_url;
  cliente_documento: any;
  cliente_doc: any;
  direccion: any;
  ruc: any;
  direccion_empresa: any;
  lugar_firma: any;
  nro_doc: any;


  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private formatoService: FormatosService,
    private clientesService: ClientesService,
    public toast: ToastService,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.formatoService.obtenerFirmantes(this.beneficiario).then((response: any) => {
      this.firmantes = response['results'];
    });

    this.clientesService.obtenerCliente(this.beneficiario).then((cliente: any) => {
      this.razon_social = cliente.nombre == undefined ? '' : cliente.nombre;
      this.ruc = cliente.ruc == undefined ? '' : cliente.ruc;
      this.direccion_empresa = cliente.domicilio_legal == undefined ? '' : cliente.domicilio_legal;

    });

  }

  createForm() {

    this.myForm = this.formBuilder.group({
      firmante: [null, Validators.required],
      emision: [{ value: new Date(), disabled: true }, Validators.required],
      glosa: [{ value: '', disabled: true }],
      nro_documento: [{ value: '', disabled: true }, Validators.required],
      vcto_firma: [{ value: null, disabled: true }, Validators.required],
      lugar_firma: [{ value: '', disabled: true }, Validators.required],
      beneficiario_tipo: [{ value: this.beneficiario_tipo == undefined ? 'J' : this.beneficiario_tipo, disabled: true }, Validators.required]
    });

    this.myForm.get("firmante").valueChanges.subscribe(selectedValue => {

      this.firmantes.filter(x => x.id == selectedValue)
        .forEach(x => {
          this.clientesService.obtenerContacto(x.id).then((firmate: any) => {
            this.cliente_nombre = x.nombre_firmante == undefined ? '' : x.nombre_firmante;
            this.cliente_doc = x.nro_documento == undefined ? '' : x.nro_documento;
            this.nacionalidad = firmate.nacionalidad_nombre == undefined ? '' : firmate.nacionalidad_nombre;
            this.cliente_documento = firmate.tipo_documento_nombre == undefined ? '' : firmate.tipo_documento_nombre;
            this.direccion = firmate.direccion == undefined ? '' : firmate.direccion;
            
            this.myForm.get("nro_documento").setValue(x.nro_documento);
            this.myForm.get("lugar_firma").setValue(x.lugar_firma);
            this.myForm.get("vcto_firma").setValue(new Date(x.vcto_firma));
            this.myForm.get("glosa").setValue(x.glosa);
          });
        });

    });

  }

  onSubmit() {

    if (this.myForm.valid) {

      var fecha = new Date(this.myForm.get("vcto_firma").value);
      var hoy = new Date();

      if (hoy > fecha) {
        this.toast.warning("Firma expirada");
        return;
      }

      var model = {
        "beneficiario_tipo": this.myForm.get("beneficiario_tipo").value,
        "cliente_nombre": this.cliente_nombre,
        "razon_social": this.razon_social,
        "nacionalidad": this.nacionalidad,
        "cliente_doc": this.cliente_doc,
        "documento": this.cliente_documento,
        "direccion": this.direccion,
        "ruc": this.ruc,
        "direccion_empresa": this.direccion_empresa,
        "lugar_firma": this.myForm.get("lugar_firma").value,
        "nro_doc": this.myForm.get("nro_documento").value,
        "fecha_actual": this.myForm.get("emision").value,
        "archivo_plantilla": this.formato_plantilla_url,
      }

      this.formatoService.generarFormatoMineriaIlegal(model).then(
        (x: any) => {
          var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "Declaración Jurada de Minería Ilegal.docx";
          link.click();
          this.activeModal.close(model);
        }, (reason) => {
          this.activeModal.close(model);
        });
    }
    else {
      this.toast.warning("El formulario no está completo.");
    }
  }

}
