import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-procesos',
  templateUrl: './procesos.component.html',
  styleUrls: ['./procesos.component.css']
})
export class ProcesosComponent implements OnInit {

  active = 1
  procesosCavali = [
    {id: '04006', descripcion: 'Anotación en Cuenta'},
    {id: '04005', descripcion: 'Transferencia Contable'},
    {id: '04007', descripcion: 'Redención'},
    {id: '04008', descripcion: 'Retiro'},
    {id: '04009', descripcion: 'Re-programación de Pagos'},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
