import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { AceptantesService } from 'app/core/services/factoring/aceptantes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { PdfMakerService } from 'app/core/services/pdf-maker.service';
import { ReportesService } from 'app/core/services/reportes.service';
import { IObtenerCabecera, IObtenerCuentaPorCobrar, IObtenerDocumentosCartera, IObtenerExcedentes, IObtenerLiquidaciones } from 'app/core/services/reportes.service.interface';
import { RiesgosService } from 'app/core/services/riesgos.service';
import { concat, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map, filter, buffer } from 'rxjs/operators';
import * as ExcelJS from "exceljs";
import * as moment from "moment";

interface ICelda {
	area : string, 
	value : any,
	style : any
}

interface ICabeceraExcel {
	tipo : string;
	ruc : string;
	nombre : string;
	tipo_credito : string;
	sbs : string;
	aceptantes__on_tipo : string;
	aceptantes : string;
	empresa : string;

	fecha : string;
	n_aceptantes__on_tipo : string;
	n_aceptantes : number;
	n_docs_cartera : number;
	n_max_mora : number;
	n_cuentas_cobrar : number;
	n_excedentes : number;
	tipo_producto : string;

	moneda__codigo : number;
	moneda : string;
	fecha_vencimiento__on_tipo : string;
	fecha_vencimiento : string;
	linea__on_tipo : string
	linea : number;
	ocupado : number;
	vigente : number;
	mora : number;
	disponible__on_tipo : string;
	disponible : number | string;
	cuentas_cobrar : number;
	excedentes : number;

	mora_dias : any;
}

interface IDocCarteraExcel {
	numero_documento : string;
	nombre : string;
	fecha_vencimiento : string;
	estado : string; 
	monto__codigo : number;
	monto : number | string;
	saldo__codigo : number;
	saldo : number;
}

interface ICuentasCobrarExcel {
	numero_documento : string;
	concepto : string;
	fecha_emision : string;
	estado : string;
	monto__codigo : number;
	monto : number | string; 
	saldo__codigo : number;
	saldo : number;
}

interface IExcedenteExcel {
	numero_documento : string;
	concepto : string;
	fecha_emision : string;
	estado : string;
	monto__codigo : number;
	monto : number | string; 
	saldo__codigo : number;
	saldo : number;
}

interface ILiquidacionesExcel {
	operacion_detalle_id : number,
	numero_documento : string,
	operacion_id : number, 
	nombre : string,
	aceptante : string,
	cliente : string,
	fecha_desembolso : string,
	fecha_vencimiento : string,
	moneda : number,
	moneda_reporte : number,
	monto_neto : number,
	monto_adelanto : number,
	pendiente_desembolso : number, 
	monto_total_soles : number,
	monto_total_dolares : number,
	monto_total : number, // Se ajusta en función a la moneda definida para el reporte
	estado : string,
}

interface IAnticuamientoExcel {
	numero_documento : string;
	nombre : string;
	estado : string;
	estado__codigo : number;
	prorroga : number;
	moneda__codigo : number;
	_1_8_dias? : number;
	_9_15_dias? : number;
	_16_30_dias? : number; 
	_31_60_dias? : number;
	_mas_60_dias? : number;
}

@Component({
	selector: 'app-reportes',
	templateUrl: './reportes.component.html',
	styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {
	public formularioDeudas: FormGroup
	public formularioPgagos: FormGroup
	active = 1;
	public cabecera: any;
	public tiposBusqueda: any[];
	public monedas: any[];
	public tiposBusquedaPagos: any[];
	public monedasPagos: any[];
	public tiposCambio: any[] = [];
	public tiposProductos: any[] = [];

	rucResults$: Observable<any[]>;
	rucResultsPagos$: Observable<any[]>;
	secondaryResults$: Observable<any[]>;
	secondaryResultsPagos$: Observable<any[]>;
	buscaRucPagos$ = new Subject<string>();
	buscaRuc$ = new Subject<string>();
	buscaSecundario$ = new Subject<string>();
	buscaSecundarioPagos$ = new Subject<string>();
	ruc: any;
	rucPagos: any;
	loadingSearch: boolean = false;
	loadingSearchPagos: boolean = false;
	caracteresMinimos: number = 4;
	empresas: any[] = [];
	rucFilterSearch: any;
	tiposLineas: { id: number; descripcion: string; }[];
	clienteDefault: number;

	constructor(
		public reporteService: ReportesService,
		private configs: DocschecklistService,
		public formbuild: FormBuilder,
		public authService: AuthService,
		public riesgosService: RiesgosService,
		public docService: DocschecklistService,
		public modalService: NgbModal,
		public router: Router,
		public pdf: PdfMakerService,
		public localService       : LocalServiceService,
		public aceptantesService: AceptantesService,
		public clientesService: ClientesService,
		private factoringService: FactoringService,

	) { }

	ngOnInit(): void {
		this.getData()
		this.initForms()
		this.llenarDatosClienteDeDevoluciones()
		// this.empresas = this.factoringService.obtenerEmpresas();
	}

	getData() {
		let cabecera = this.reporteService.obtenerCabecera()
		let tiposDeCambio = this.configs.obtenerTiposDeCambio(this.formatDate(new Date()));

		Promise.all([cabecera, tiposDeCambio])
			.then((res: any[]) => {
				this.cabecera = res[0]
				this.cargarTiposCambio(res[1].results[0])
			}).catch((err) => {
				console.log(err)
			}).finally(() => {
			})
	}

	llenarDatosClienteDeDevoluciones() {
		const cliente = this.localService.getJsonValue('cliente_reporte')
		if (cliente) {
			this.clienteDefault = cliente.cliente_id
		  this.formularioDeudas.get('ruc').setValue(this.clienteDefault)
			const clienteFromDevoluciones = {
				id: cliente.cliente_id,
				nombre: cliente.cliente_nombre,
			}

			this.rucResults$ = of([clienteFromDevoluciones])
			this.formularioDeudas.get('tipo_linea').setValue(this.tiposLineas[0].id)
			this.localService.deleteJsonValue('cliente_reporte')
		}
	}

	cargarTiposCambio(cambios) {
		if (cambios) {
			this.tiposCambio.push({
				descripcion: 'Contable',
				valor: cambios.contable
			})

			this.tiposCambio.push({
				descripcion: 'Compra',
				valor: cambios.compra
			})

			this.tiposCambio.push({
				descripcion: 'Venta',
				valor: cambios.venta
			})
		} else {
			this.tiposCambio.push({
				descripcion: 'Contable',
				valor: 1
			})

			this.tiposCambio.push({
				descripcion: 'Compra',
				valor: 1.1
			})

			this.tiposCambio.push({
				descripcion: 'Venta',
				valor: 0.9
			})
		}

		this.formularioDeudas.get('tipo_cambio').setValue(this.tiposCambio[0].valor)
	}

	initForms() {
		this.loadSearchTipoPagos();
		this.loadSearchTipo();
		this.loadSearchSecundario();
		this.loadSearchSecundarioPagos()
		this.cargarFormularioDeudas()
		this.cargarFormularioPagos()

	}

	cargarFormularioPagos() {
		this.tiposBusquedaPagos = [
			{
				id: 1,
				descripcion: 'Cliente'
			},
			{
				id: 2,
				descripcion: 'Aceptante'
			},
		]

		this.monedasPagos = [
			{
				id: 1,
				descripcion: 'Soles'
			},
			{
				id: 2,
				descripcion: 'Dólares'
			},
		]

		this.formularioPgagos = this.formbuild.group({
			tipo       : [1, [Validators.required]],
			ruc        : [null, [Validators.required]],
			fecha      : [null, []],
			fecha_     : [null, []],
			moneda     : [null, [Validators.required]],
			secundario : [null, []],
			tipo_cambio: [null, [Validators.required]],
			tipo_linea: [1, [Validators.required]],
		})

		this.formularioPgagos.get('tipo').setValue(1)
		this.formularioPgagos.get('tipo').valueChanges.subscribe((value) => {
			let rucValue = !!this.formularioPgagos.get('ruc').value
			if (rucValue) {
				this.resetForm()
				this.empresas = []
			}
		})
		this.formularioPgagos.get('moneda').setValue(1)
		this.formularioPgagos.get('fecha').valueChanges.subscribe((value: Date) => {
			this.formularioPgagos.get('fecha_').setValue(value.getTime())
		})
		this.formularioPgagos.get('fecha').setValue(new Date())
	}

	cargarFormularioDeudas() {
		this.tiposBusqueda = [
			{
				id: 1,
				descripcion: 'Cliente'
			},
			{
				id: 2,
				descripcion: 'Aceptante'
			},
		]

		this.monedas = [
			{
				id: 1,
				descripcion: 'Soles'
			},
			{
				id: 2,
				descripcion: 'Dólares'
			},
		]


		this.tiposLineas = [
			{
				id: 1,
				descripcion: 'Descuento'
			},
			{
				id: 2,
				descripcion: 'Factoring'
			},
		]

		this.formularioDeudas = this.formbuild.group({
			tipo       : [1, [Validators.required]],
			ruc        : [null, [Validators.required]],
			fecha      : [null, []],
			fecha_     : [null, []],
			moneda     : [null, [Validators.required]],
			secundario : [null, []],
			empresa    : [{value : 1, disabled : true}, [Validators.required]],
			tipo_cambio: [null, [Validators.required]],
			tipo_linea : [{value : null, disabled : false}, [Validators.required]],
		})

		this.formularioDeudas.get('tipo').setValue(1)
		this.formularioDeudas.get('tipo').valueChanges.subscribe((value) => {
			if(value && value == 1){
				this.tiposLineas = [
					{
						id: 1,
						descripcion: 'Descuento'
					},
					{
						id: 2,
						descripcion: 'Factoring'
					},
				]
				this.formularioDeudas.get('tipo_linea').setValue(null)
				this.formularioDeudas.get('tipo_linea').enable()
			} else if(value && value == 2) {
				this.tiposLineas = [
					{
						id: 1,
						descripcion: 'Descuento'
					},
				]
				this.formularioDeudas.get('tipo_linea').setValue(1)
				this.formularioDeudas.get('tipo_linea').disable()
			}
			let rucValue = !!this.formularioDeudas.get('ruc').value
			if (rucValue) {
				this.resetForm()
			}
			this.formularioDeudas.get('empresa').setValue(null)
			this.empresas = []
		})

		this.formularioDeudas.get('ruc').valueChanges.subscribe((rucBusqueda)=>{
			if(!rucBusqueda){
				this.formularioDeudas.get('empresa').disable()
				return
			}

			let request;
			if(this.formularioDeudas.get('tipo').value == 1){
				request = this.clientesService.obtenerEmpresas(rucBusqueda)
			} else {
				request = this.clientesService.obtenerEmpresasAceptante(rucBusqueda)
			}

			request.then((res)=>{
				this.empresas = res[0].empresas
				this.formularioDeudas.get('empresa').enable()
				this.formularioDeudas.get('empresa').setValue(1)
			})
		})

		this.formularioDeudas.get('moneda').setValue(1)
		this.formularioDeudas.get('fecha').valueChanges.subscribe((value: Date) => {
			this.formularioDeudas.get('fecha_').setValue(value.getTime())
		})
		this.formularioDeudas.get('fecha').setValue(new Date())
	}

	goToReportePagos() {
		let moneda     = this.formularioDeudas.get('moneda').value
		let tipo       = this.formularioDeudas.get('tipo').value
		let ruc        = this.formularioDeudas.get('ruc').value
		let tipoCambio = this.formularioDeudas.get('tipo_cambio').value
		let empresa    = this.formularioDeudas.get('empresa').value
		let tipo_linea = this.formularioDeudas.get('tipo_linea').value
		let patron     = '';
		let filtro;
		if (!!this.formularioDeudas.get('secundario').value) {
			filtro = this.formularioDeudas.get('secundario').value;
			patron = filtro.reduce((acc, item, arr) => {
				if (acc === '') {
					acc += `${item}`
				} else {
					acc += `,${item}`
				}
				return acc
			}, '')
		} else {
			filtro = 0;
		}

		this.localService.setJsonValue('reportePagosFiltros',{
			moneda,
			tipo,
			ruc,
			tipocambio: tipoCambio,
			empresa,
			filtro: patron,
			tipo_linea
		})
		window.open('#/reporte/pagos/' + moneda + '/' + ruc + '/' + tipo + '/' + tipoCambio, '_blank');
	}

	generarExcelPagos(){
		console.log("Generando Excel");
		let entidad = this.formularioDeudas.get("tipo").value == 1 ? "cliente" : "aceptante";
		let filtroSecundario = "";
		if (!!this.formularioDeudas.get('secundario').value) {
			let tFiltro = this.formularioDeudas.get('secundario').value;
			filtroSecundario = tFiltro.reduce((acc, item, arr) => {
				if (acc === '') {
					acc += `${item}`
				} else {
					acc += `,${item}`
				}
				return acc
			}, '')
		}

		let cabeceraRequest : IObtenerCabecera = {
			entidad,
			moneda: this.formularioDeudas.get("moneda").value,
			tipo_cambio: this.formularioDeudas.get('tipo_cambio').value,
			codigo_entidad: this.formularioDeudas.get('ruc').value,
			aceptante: entidad == "cliente" ? filtroSecundario : '',
			cliente: entidad == "cliente" ? '' : filtroSecundario,
			empresa: this.formularioDeudas.get('empresa').value,
			tipo_linea: this.formularioDeudas.get('tipo_linea').value,
		}
		
		let documentosCarteraRequest : IObtenerDocumentosCartera = {
			beneficiaro: entidad == "cliente" ? this.formularioDeudas.get('ruc').value : filtroSecundario,
			deudor: entidad == "cliente" ? filtroSecundario : this.formularioDeudas.get('ruc').value,
			estado: "2",
			empresa: this.formularioDeudas.get('empresa').value,
			tipo_linea: this.formularioDeudas.get('tipo_linea').value,
		}
		// Sin uso
		let excedentesRequest : IObtenerExcedentes = {
			cliente: entidad == "cliente" ? this.formularioDeudas.get('ruc').value : filtroSecundario,
			aceptante:  entidad == "cliente" ? filtroSecundario : this.formularioDeudas.get('ruc').value,
			empresa: this.formularioDeudas.get('empresa').value,
			tipo_linea: this.formularioDeudas.get('tipo_linea').value,
		}

		let cuentaPorCobrarRequest : IObtenerCuentaPorCobrar = {
			beneficiario: entidad == "cliente" ? this.formularioDeudas.get('ruc').value : filtroSecundario,
			deudor: entidad == "cliente" ? filtroSecundario : this.formularioDeudas.get('ruc').value,
			empresa: this.formularioDeudas.get('empresa').value,
			tipo_linea: this.formularioDeudas.get('tipo_linea').value,
			estado: "2",
		}

		let liquidacionesRequest : IObtenerLiquidaciones = {
			cliente_codigo: entidad == "cliente" ? this.formularioDeudas.get('ruc').value : undefined,
			aceptante_codigo: entidad == "aceptante" ? this.formularioDeudas.get('ruc').value : undefined,
		}

		let cabecera = this.reporteService.obtenerCabeceraAlt(cabeceraRequest);
		let documentosCartera = this.reporteService.obtenerDocumentosEnCarteraReporteAlt(documentosCarteraRequest);
		let cuentasCobrar = this.reporteService.obtenerCuentasPorCobrarReporteAlt(cuentaPorCobrarRequest);
		let liquidaciones = this.reporteService.obtenerLiquidacionesReporte(liquidacionesRequest);

		Promise.all([cabecera, documentosCartera, cuentasCobrar, liquidaciones])
			.then((resp : any[]) => {
				let cabeceraResults = resp[0];
				let documentosCarteraResp = resp[1].results;
				let cuentasCobrar = resp[2].results;
				let liquidaciones = resp[3];

				let cabMoneda = parseInt(cabeceraRequest.moneda);
				let cabeceraExcel : ICabeceraExcel = {
					tipo: cabeceraRequest.entidad == "cliente" ? "Cliente" : "Aceptante",
					ruc: cabeceraResults.ruc,
					nombre: cabeceraResults.nombre,
					tipo_credito: cabeceraResults.tipo_credito,
					sbs: cabeceraResults.sbs,
					aceptantes__on_tipo: cabeceraRequest.entidad == "cliente" ? "Aceptantes" : "Clientes",
					aceptantes: cabeceraResults.filtro_entidad.cantidad == "Todos" ? cabeceraResults.filtro_entidad.cantidad : cabeceraResults?.filtro_entidad?.lista?.join(", ") || "",
					empresa: cabeceraRequest.empresa == "1" ? "Factoring Total" : "Total Servicios", //
					fecha: this.formatMomentDate(cabeceraResults.fecha),
					n_aceptantes__on_tipo: cabeceraRequest.entidad == "cliente" ? "Aceptantes" :  "Clientes",
					n_aceptantes: cabeceraResults?.filtro_entidad?.lista?.length || 0,
					n_docs_cartera: cabeceraResults.docs_en_cartera,
					n_max_mora: cabeceraResults.max_mora,
					n_cuentas_cobrar: cabeceraResults.cuentas_cobrar,
					n_excedentes: cabeceraResults.n_excedentes,
					tipo_producto: cabeceraRequest.tipo_linea == "1" ? "Descuento" : cabeceraRequest.tipo_linea == "2" ? "Factoring" : "",
					moneda__codigo: cabMoneda,
					moneda: cabMoneda == 1 ? "Soles" : cabMoneda == 2 ? "Dólares" : "",
					fecha_vencimiento__on_tipo: cabeceraRequest.entidad == "cliente" ? "Fecha de Vencimiento" : "Fecha Max Exposición",
					fecha_vencimiento: cabeceraRequest.entidad == "cliente" ?  this.formatMomentDate(cabeceraResults.fecha_vencimiento) : this.formatMomentDate(cabeceraResults.fecha_max_expo),
					linea__on_tipo: cabeceraRequest.entidad == "cliente" ? "Línea" : "Max Exposición",
					linea: parseFloat(cabeceraResults.linea),
					ocupado: parseFloat(cabeceraResults.ocupado),
					vigente: parseFloat(cabeceraResults.vigente),
					mora: parseFloat(cabeceraResults.mora),
					disponible__on_tipo: cabeceraRequest.entidad == "cliente" ? "Disponible" : "",
					disponible: cabeceraRequest.entidad == "cliente" ? parseFloat(cabeceraResults.disponible) : "",
					cuentas_cobrar: parseFloat(cabeceraResults.total_cuentas_cobrar),
					excedentes: parseFloat(cabeceraResults.total_excedentes),
					mora_dias: cabeceraResults.mora_dias
				}

				let docCarteraExcel :  IDocCarteraExcel[] = documentosCarteraResp?.map((dc) => {
					let dcMoneda = parseInt(dc.moneda);
					let reporteMoneda = parseInt(cabeceraRequest.moneda);
					let tDc : IDocCarteraExcel = {
						numero_documento: dc.operacion_numero_documento,
						nombre: cabeceraRequest.tipo_linea == "2" ? dc.beneficiario_nombre : dc.aceptante_nombre,
						fecha_vencimiento: this.formatMomentDate(dc.fecha_vencimiento),
						estado: dc.estado_descripcion,
						monto__codigo: dcMoneda,
						monto: (dcMoneda == 1 ? dc.monto_neto_soles ? parseFloat(dc.monto_neto_soles) : "" : dc.monto_neto_dolares ? parseFloat(dc.monto_neto_dolares) : ""),
						saldo__codigo:  reporteMoneda,
						saldo: (reporteMoneda == 1 ? parseFloat(dc.saldo_soles) : parseFloat(dc.saldo_dolares)) || 0,
					}
					return  tDc;
				}) || [];

				let cuentasCobrarExcel : ICuentasCobrarExcel[] = cuentasCobrar?.map((cc) => {
					let ccMoneda = parseInt(cc.moneda);
					let reporteMoneda = parseInt(cabeceraRequest.moneda);
					let tCc : ICuentasCobrarExcel = {
						numero_documento: cc.id,
						concepto: `${!!cc.concepto_descripcion ? cc.concepto_descripcion : ""}: ${!!cc.tipo_proceso_descripcion ? cc.tipo_proceso_descripcion : ""} ${!!cc.numero_proceso ? cc.numero_proceso : ""}`,
						fecha_emision: this.formatMomentDate(cc.fecha_creacion),
						estado: cc.estado_descripcion,
						monto__codigo: ccMoneda,
						//monto: (ccMoneda == 1 ? parseFloat(cc.monto_soles) : parseFloat(cc.monto_dolares)) || 0,
						monto: (ccMoneda == 1 ? cc.monto_soles ? parseFloat(cc.total) : "" : cc.monto_dolares ? parseFloat(cc.total) : ""), // WARNING - No coincide con la info que ofrece back 
						saldo__codigo: reporteMoneda,
						saldo: (reporteMoneda == 1 ? parseFloat(cc.saldo_soles) : parseFloat(cc.saldo_dolares)),
					}
					return tCc;
				}) || [];

				let liquidacionesExcel : ILiquidacionesExcel[] = liquidaciones?.map((liq) => {
					let reporteMoneda = parseInt(cabeceraRequest.moneda);
					let tLiq : ILiquidacionesExcel = {
						operacion_detalle_id: liq.operacion_detalle_id,
						numero_documento: liq.numero_documento,
						operacion_id: liq.operacion_id,
						nombre: cabeceraRequest.entidad == "cliente" ? liq.aceptante : liq.cliente,
						aceptante: liq.aceptante,
						cliente: liq.cliente,
						fecha_desembolso: this.formatMomentDate(liq.fecha_desembolso),
						fecha_vencimiento: this.formatMomentDate(liq.fecha_vencimiento),
						moneda: liq.moneda,
						moneda_reporte: reporteMoneda,
						monto_neto: liq.monto_neto,
						monto_adelanto: liq.monto_adelanto,
						pendiente_desembolso: liq.pendiente_desembolso,
						monto_total_soles: liq.monto_total_soles,
						monto_total_dolares: liq.monto_total_dolares,
						monto_total: (reporteMoneda == 1 ? liq.monto_total_soles ? parseFloat(liq.monto_total_soles) : 0 : liq.monto_total_dolares ? parseFloat(liq.monto_total_dolares) : 0),
						estado: liq.estado,
					}
					return tLiq;
				}) || [];

				// Aplicando adelantos a totales de la cabecera:
				let tAdelantos = {
					ocupado: 0,
					vigente: 0,
					mora: 0,
				}
						 
				let ttotales = liquidaciones.reduce((acumulador, item) => {
					let t_monto_total = cabMoneda == 1 ? parseFloat(item.monto_total_soles) : parseFloat(item.monto_total_dolares);
					acumulador.ocupado += t_monto_total;
					if(item.estado == "Vigente"){
						acumulador.vigente += t_monto_total;
					}else{
						acumulador.mora += t_monto_total;
					}
					return acumulador
				}, tAdelantos)

				cabeceraExcel = {
					...cabeceraExcel, 
					ocupado: cabeceraExcel.ocupado + ttotales.ocupado,
					disponible: cabeceraExcel.disponible as number - ttotales.ocupado,
					vigente: cabeceraExcel.vigente + ttotales.vigente,
					mora: cabeceraExcel.mora + ttotales.mora,
				}

				// Ajuste de empresas filtradas
				let empresasFiltradas = documentosCarteraResp.reduce((acc, item) => {
					let existe;
					if(cabeceraRequest.entidad == "cliente"){
						existe = acc.filter((empresa) => empresa.aceptante_ruc == item.aceptante_ruc);
					}else{
						existe = acc.filter((empresa) => empresa.beneficiario_ruc == item.beneficiario_ruc);
					}
					if( !(existe.length > 0) ){ acc.push(item) }
					return acc
				}, [])

				if(cabeceraExcel.n_aceptantes == 0){
					cabeceraExcel.n_aceptantes = empresasFiltradas.length || 0;
				}
				
				this.crearExcelPagos(cabeceraExcel, docCarteraExcel, cuentasCobrarExcel);
			})
	}

	crearExcelPagos(
		cabecera : ICabeceraExcel,
		docCarteraData : IDocCarteraExcel[] = [],
		cuentasCobrarData : ICuentasCobrarExcel[] = [],
	){
		const workBook = new ExcelJS.Workbook();
		const workSheet = workBook.addWorksheet("ReportePagos");
		// Titulo Principal
		workSheet.addRow(["Reporte de Pagos"]);
		workSheet.mergeCells("A1:O1");
		let titleCell = workSheet.getCell("O1");

		titleCell.alignment = {vertical: "middle", horizontal: "center"}
		titleCell.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 14, bold: false}
		titleCell.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "F9AE00"} }
		//Cabecera --------------------------------------------------------------------------------------------------------------------------------------
		let cabeceraSubTitleStyle = {
			alignement: {vertical: "middle", horizontal: "left"},
			font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: true},
			fill: { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} }			
		}
		let cabeceraDataStyle = {
			alignement: {vertical: "middle", horizontal: "center"},
			font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: false},
			fill: { type: "pattern", pattern: "solid", fgColor: {argb: "FFFFFF"} }			
		}
		let cabeceraDeploy : ICelda[] = [
			// New Row
			{ 
				area: "A2:B2",
				value: "Tipo",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C2:E2",
				value: cabecera.tipo,
				style: cabeceraDataStyle,
			},{ 
				area: "F2:G2",
				value: "Fecha",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H2:J2",
				value: cabecera.fecha,
				style: cabeceraDataStyle,
			},{ 
				area: "K2:L2",
				value: "Moneda",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M2:O2",
				value: cabecera.moneda,
				style: cabeceraDataStyle,
			}, 
			// New Row
			{ 
				area: "A3:B3",
				value: "RUC",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C3:E3",
				value: cabecera.ruc,
				style: cabeceraDataStyle,
			},{ 
				area: "F3:G3",
				value: cabecera.n_aceptantes__on_tipo,
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H3:J3",
				value: cabecera.n_aceptantes,
				style: cabeceraDataStyle,
			},{ 
				area: "K3:L3",
				value: cabecera.fecha_vencimiento__on_tipo,
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M3:O3",
				value: cabecera.fecha_vencimiento,
				style: cabeceraDataStyle,
			},			
			// New Row
			{ 
				area: "A4:B4",
				value: "Nombre",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C4:E4",
				value: cabecera.nombre,
				style: cabeceraDataStyle,
			},{ 
				area: "F4:G4",
				value: "Docs en Cartera",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H4:J4",
				value: cabecera.n_docs_cartera,
				style: cabeceraDataStyle,
			},{ 
				area: "K4:L4",
				value: cabecera.linea__on_tipo,
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M4:O4",
				value: cabecera.linea,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},			
			// New Row
			{ 
				area: "A5:B5",
				value: "Tipo de Crédito",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C5:E5",
				value: cabecera.tipo_credito,
				style: cabeceraDataStyle,
			},{ 
				area: "F5:G5",
				value: "Max Mora",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H5:J5",
				value: cabecera.n_max_mora,
				style: cabeceraDataStyle,
			},{ 
				area: "K5:L5",
				value: "Ocupado",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M5:O5",
				value: cabecera.ocupado,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
			// New Row
			{ 
				area: "A6:B6",
				value: "SBS",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C6:E6",
				value: cabecera.sbs,
				style: cabeceraDataStyle,
			},{ 
				area: "F6:G6",
				value: "Ctas por cobrar",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H6:J6",
				value: cabecera.n_cuentas_cobrar,
				style: cabeceraDataStyle,
			},{ 
				area: "K6:L6",
				value: "    Vigente",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M6:O6",
				value: cabecera.vigente,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
			// New Row
			{ 
				area: "A7:B7",
				value: cabecera.aceptantes__on_tipo,
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C7:E7",
				value: cabecera.aceptantes,
				style: cabeceraDataStyle,
			},{ 
				area: "F7:G7",
				value: "Excedentes",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H7:J7",
				value: cabecera.n_excedentes,
				style: cabeceraDataStyle,
			},{ 
				area: "K7:L7",
				value: "    Mora",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M7:O7",
				value: cabecera.mora,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
			// New Row
			{ 
				area: "A8:B8",
				value: "Empresa",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C8:E8",
				value: cabecera.empresa,
				style: cabeceraDataStyle,
			},{ 
				area: "F8:G8",
				value: "Tipo de Producto: ",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H8:J8",
				value: cabecera.tipo_producto,
				style: cabeceraDataStyle,
			},{ 
				area: "K8:L8",
				value: cabecera.disponible__on_tipo,
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M8:O8",
				value: cabecera.disponible,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
			// New Row
			{ 
				area: "K9:L9",
				value: "Ctas x Cobrar",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M9:O9",
				value: cabecera.cuentas_cobrar,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
			// New Row
			{ 
				area: "K10:L10",
				value: "Excedentes",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M10:O10",
				value: cabecera.excedentes,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
		]

		cabeceraDeploy.forEach(x => {
			workSheet.mergeCells(x.area);
			let tCell = workSheet.getCell(x.area)
			
			tCell.value = x.value;
			tCell.alignment = x.style.alignement as Partial<ExcelJS.Alignment>;
			tCell.font = x.style.font;
			tCell.fill = x.style.fill as ExcelJS.Fill;
			if(x.style.numFmt != "") { tCell.numFmt = x.style.numFmt}
		})

		let startingNewRowsAt = 12
		// Documentos en Cartera -----------------------------------------------------------------------------------------------------------------------------------------
		// Title
		workSheet.mergeCells(`A${startingNewRowsAt}:O${startingNewRowsAt}`);
		let docCarteraTitleCell = workSheet.getCell(`O${startingNewRowsAt}`);
		docCarteraTitleCell.value = "Documentos en Cartera"
		docCarteraTitleCell.alignment = {vertical: "middle", horizontal: "left"}
		docCarteraTitleCell.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 13, bold: false}
		docCarteraTitleCell.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} }
		// Table Header (Starts a row 11)
		let headerTableStyle = {
			alignement: {vertical: "middle", horizontal: "center"},
			font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: true},
			fill: { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} },
			numFmt: ""
		}
		let dataTableStyle = {
			alignement: {vertical: "middle", horizontal: "center"},
			font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: false},
			fill: { type: "pattern", pattern: "solid", fgColor: {argb: "FFFFFF"} },
			numFmt: ""
		}
		// Cabecera de tabla
		startingNewRowsAt++
		let docCarteraDeploy : ICelda[] = [
			{ 
				area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
				value: "Número Doc",
				style: headerTableStyle,
			},{ 
				area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
				value: cabecera.aceptantes__on_tipo,
				style: headerTableStyle,
			},{ 
				area: `G${startingNewRowsAt}:I${startingNewRowsAt}`,
				value: "Estado",
				style: headerTableStyle,
			},{ 
				area: `J${startingNewRowsAt}:K${startingNewRowsAt}`,
				value: "Fecha de Vencimiento",
				style: headerTableStyle,
			},{ 
				area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
				value: "Monto",
				style: headerTableStyle,
			},{ 
				area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
				value: "Saldo",
				style: headerTableStyle,
			},
		]
		// Incrusting data
		docCarteraData.forEach(x => {
			startingNewRowsAt++;
			let tRow = [
				{
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: x.numero_documento,
					style: dataTableStyle,
				}, { 
					area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
					value: x.nombre,
					style: dataTableStyle,
				},{ 
					area: `G${startingNewRowsAt}:I${startingNewRowsAt}`,
					value: x.estado,
					style: dataTableStyle,
				},{ 
					area: `J${startingNewRowsAt}:K${startingNewRowsAt}`,
					value: x.fecha_vencimiento,
					style: dataTableStyle,
				},{ 
					area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
					value: x.monto,
					style:  {...dataTableStyle, numFmt: `"${x.monto__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: x.saldo,
					style: {...dataTableStyle, numFmt: `"${x.saldo__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},
			];
			docCarteraDeploy = [...docCarteraDeploy, ...tRow];
		})
		// Incrusting Totales
		if(docCarteraData.length > 0){
			startingNewRowsAt++;
			let docCarteraTotales = [
				{
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: "Totales",
					style: headerTableStyle,
				},{ 
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: docCarteraData.reduce((acc, num) => {return acc + num.saldo}, 0),
					style: {...headerTableStyle, numFmt: `"${docCarteraData[0].saldo__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},
			]
			docCarteraDeploy = [...docCarteraDeploy, ...docCarteraTotales];
		}

		docCarteraDeploy.forEach(x => {
			workSheet.mergeCells(x.area);
			let tCell = workSheet.getCell(x.area)
			
			tCell.value = x.value;
			tCell.alignment = x.style.alignement as Partial<ExcelJS.Alignment>;
			tCell.font = x.style.font;
			tCell.fill = x.style.fill as ExcelJS.Fill;
			if(x.style.numFmt != "" && tCell.value !== "") { tCell.numFmt = x.style.numFmt}
		})
		//  Cuentas por Cobrar ----------------------------------------------------------------------------------------------------------------------------------------------
		startingNewRowsAt+=2;
		// Title
		workSheet.mergeCells(`A${startingNewRowsAt}:O${startingNewRowsAt}`);
		let cuentasCobrarTitleCell = workSheet.getCell(`O${startingNewRowsAt}`);
		cuentasCobrarTitleCell.value = "Cuentas por Cobrar"
		cuentasCobrarTitleCell.alignment = {vertical: "middle", horizontal: "left"}
		cuentasCobrarTitleCell.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 13, bold: false}
		cuentasCobrarTitleCell.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} }
		startingNewRowsAt++;
		// Table Header
		let cuentaCobrarDeploy : ICelda[] = [
			{ 
				area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
				value: "Número Doc",
				style: headerTableStyle,
			},{ 
				area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
				value: "Concepto",
				style: headerTableStyle,
			},{ 
				area: `G${startingNewRowsAt}:H${startingNewRowsAt}`,
				value: "Fecha de Emisión",
				style: headerTableStyle,
			},{ 
				area: `I${startingNewRowsAt}:K${startingNewRowsAt}`,
				value: "Estado",
				style: headerTableStyle,
			},{ 
				area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
				value: "Monto",
				style: headerTableStyle,
			},{ 
				area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
				value: "Saldo",
				style: headerTableStyle,
			}, 
		]
		// Incrusting data
		cuentasCobrarData.forEach(x => {
			startingNewRowsAt++;
			let tRow = [
				{ 
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: x.numero_documento,
					style: dataTableStyle,
				},{ 
					area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
					value: x.concepto,
					style: dataTableStyle,
				},{ 
					area: `G${startingNewRowsAt}:H${startingNewRowsAt}`,
					value: x.fecha_emision,
					style: dataTableStyle,
				},{ 
					area: `I${startingNewRowsAt}:K${startingNewRowsAt}`,
					value: x.estado,
					style: dataTableStyle,
				},{ 
					area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
					value: x.monto,
					style:  {...dataTableStyle, numFmt: `"${x.monto__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: x.saldo,
					style:  {...dataTableStyle, numFmt: `"${x.saldo__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				}, 
			];
			cuentaCobrarDeploy = [...cuentaCobrarDeploy, ...tRow];
		})
		// Incrusting Totales
		if(cuentasCobrarData.length > 0){
			startingNewRowsAt++;
			let cuentaCobrarTotales : ICelda[] = [
				{
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: "Totales",
					style: headerTableStyle,
				},{ 
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: cuentasCobrarData.reduce((acc, num) => {return acc + ( num.saldo ) }, 0),
					style: {...headerTableStyle, numFmt: `"${cuentasCobrarData[0].saldo__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},
			]
			cuentaCobrarDeploy = [...cuentaCobrarDeploy, ...cuentaCobrarTotales];
		}

		cuentaCobrarDeploy.forEach(x => {
			workSheet.mergeCells(x.area);
			let tCell = workSheet.getCell(x.area)
			
			tCell.value = x.value;
			tCell.alignment = x.style.alignement as Partial<ExcelJS.Alignment>;
			tCell.font = x.style.font;
			tCell.fill = x.style.fill as ExcelJS.Fill;
			if(x.style.numFmt != "" && tCell.value !== "") { tCell.numFmt = x.style.numFmt }
		})

		// Extra styles: 
		workSheet.getRow(1).height = 25;
		for(let i = 1; i <= 15; i++){
			workSheet.getColumn(i).width = 13;
		}
		// Generando buffer y disparando evento para descargar el archivo
		workBook.xlsx.writeBuffer().then(buffer => {
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download =  `Pagos ${moment().format("DDMMYYYYHHMM")}.xlsx`;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		})
	}

	goToReporteDeudas() {
		let moneda     = this.formularioDeudas.get('moneda').value
		let tipo       = this.formularioDeudas.get('tipo').value
		let ruc        = this.formularioDeudas.get('ruc').value
		let tipoCambio = this.formularioDeudas.get('tipo_cambio').value
		let empresa    = this.formularioDeudas.get('empresa').value;
		let tipo_linea = this.formularioDeudas.get('tipo_linea').value;
		let patron = '';
		let filtro;
		if (!!this.formularioDeudas.get('secundario').value) {
			filtro = this.formularioDeudas.get('secundario').value;
			patron = filtro.reduce((acc, item, arr) => {
				if (acc === '') {
					acc += `${item}`
				} else {
					acc += `,${item}`
				}
				return acc
			}, '')
		} else {
			filtro = 0;
		}

		this.localService.setJsonValue('reporteDeudaFiltros',{
			moneda,
			tipo,
			ruc,
			tipocambio: tipoCambio,
			empresa,
			filtro: patron,
			tipo_linea
		})

		window.open('#/reporte/deuda/' + moneda + '/' + ruc + '/' + tipo + '/' + tipoCambio, '_blank');
	}

	generarExcelCuentas(){
		console.log("Generando Excel: ");
		let entidad = this.formularioDeudas.get("tipo").value == 1 ? "cliente" : "aceptante";
		let filtroSecundario = "";
		if (!!this.formularioDeudas.get('secundario').value) {
			let tFiltro = this.formularioDeudas.get('secundario').value;
			filtroSecundario = tFiltro.reduce((acc, item, arr) => {
				if (acc === '') {
					acc += `${item}`
				} else {
					acc += `,${item}`
				}
				return acc
			}, '')
		}

		let cabeceraRequest : IObtenerCabecera = {
			entidad,
			moneda: this.formularioDeudas.get("moneda").value,
			tipo_cambio: this.formularioDeudas.get('tipo_cambio').value,
			codigo_entidad: this.formularioDeudas.get('ruc').value,
			aceptante: entidad == "cliente" ? filtroSecundario : '',
			cliente: entidad == "cliente" ? '' : filtroSecundario,
			empresa: this.formularioDeudas.get('empresa').value,
			tipo_linea: this.formularioDeudas.get('tipo_linea').value,
		}

		let documentosCarteraRequest : IObtenerDocumentosCartera = {
			cliente_linea: entidad == "cliente" ? this.formularioDeudas.get('ruc').value : filtroSecundario,
			deudor: entidad == "cliente" ? filtroSecundario : this.formularioDeudas.get('ruc').value,
			empresa: this.formularioDeudas.get('empresa').value,
			tipo_linea: this.formularioDeudas.get('tipo_linea').value,
			estado: "1,3",
		}

		let excedentesRequest : IObtenerExcedentes = {
			cliente_linea: entidad == "cliente" ? this.formularioDeudas.get('ruc').value : filtroSecundario,
			aceptante:  entidad == "cliente" ? filtroSecundario : this.formularioDeudas.get('ruc').value,
			empresa: this.formularioDeudas.get('empresa').value,
			tipo_linea: this.formularioDeudas.get('tipo_linea').value,
		}

		let cuentaPorCobrarRequest : IObtenerCuentaPorCobrar = {
			cliente_linea: entidad == "cliente" ? this.formularioDeudas.get('ruc').value : filtroSecundario,
			deudor: entidad == "cliente" ? filtroSecundario : this.formularioDeudas.get('ruc').value,
			empresa: this.formularioDeudas.get('empresa').value,
			tipo_linea: this.formularioDeudas.get('tipo_linea').value,
			estado: "1",
		}

		let liquidacionesRequest : IObtenerLiquidaciones = {
			cliente_codigo: entidad == "cliente" ? this.formularioDeudas.get('ruc').value : undefined,
			aceptante_codigo: entidad == "aceptante" ? this.formularioDeudas.get('ruc').value : undefined,
		}

		let cabecera = this.reporteService.obtenerCabeceraAlt(cabeceraRequest);
		let documentosCartera = this.reporteService.obtenerDocumentosEnCarteraReporteAlt(documentosCarteraRequest);
		let excedentes = this.reporteService.obtenerExcedentesReporteAlt(excedentesRequest);
		let cuentasCobrar = this.reporteService.obtenerCuentasPorCobrarReporteAlt(cuentaPorCobrarRequest);
		let liquidaciones = this.reporteService.obtenerLiquidacionesReporte(liquidacionesRequest);
		
		Promise.all([cabecera, documentosCartera, excedentes, cuentasCobrar, liquidaciones])
			.then((resp : any[]) => {
				let cabeceraResults = resp[0];
				let documentosCarteraResp = resp[1].results;
				let excedentesResp = resp[2].results;
				let cuentasCobrar = resp[3].results;
				let liquidaciones = resp[4];

				let cabMoneda = parseInt(cabeceraRequest.moneda);
				let cabeceraExcel : ICabeceraExcel = {
					tipo: cabeceraRequest.entidad == "cliente" ? "Cliente" : "Aceptante",
					ruc: cabeceraResults.ruc,
					nombre: cabeceraResults.nombre,
					tipo_credito: cabeceraResults.tipo_credito,
					sbs: cabeceraResults.sbs,
					aceptantes__on_tipo: cabeceraRequest.entidad == "cliente" ? "Aceptantes" : "Clientes",
					aceptantes: cabeceraResults.filtro_entidad.cantidad == "Todos" ? cabeceraResults.filtro_entidad.cantidad : cabeceraResults?.filtro_entidad?.lista?.join(", ") || "",
					empresa: cabeceraRequest.empresa == "1" ? "Factoring Total" : "Total Servicios", //
					fecha: this.formatMomentDate(cabeceraResults.fecha),
					n_aceptantes__on_tipo: cabeceraRequest.entidad == "cliente" ? "Aceptantes" :  "Clientes",
					n_aceptantes: cabeceraResults?.filtro_entidad?.lista?.length || 0,
					n_docs_cartera: cabeceraResults.docs_en_cartera,
					n_max_mora: cabeceraResults.max_mora,
					n_cuentas_cobrar: cabeceraResults.cuentas_cobrar,
					n_excedentes: cabeceraResults.n_excedentes,
					tipo_producto: cabeceraRequest.tipo_linea == "1" ? "Descuento" : cabeceraRequest.tipo_linea == "2" ? "Factoring" : "",
					moneda__codigo: cabMoneda,
					moneda: cabMoneda == 1 ? "Soles" : cabMoneda == 2 ? "Dólares" : "",
					fecha_vencimiento__on_tipo: cabeceraRequest.entidad == "cliente" ? "Fecha de Vencimiento" : "Fecha Max Exposición",
					fecha_vencimiento: cabeceraRequest.entidad == "cliente" ?  this.formatMomentDate(cabeceraResults.fecha_vencimiento) : this.formatMomentDate(cabeceraResults.fecha_max_expo),
					linea__on_tipo: cabeceraRequest.entidad == "cliente" ? "Línea" : "Max Exposición",
					linea: parseFloat(cabeceraResults.linea),
					ocupado: parseFloat(cabeceraResults.ocupado),
					vigente: parseFloat(cabeceraResults.vigente),
					mora: parseFloat(cabeceraResults.mora),
					disponible__on_tipo: cabeceraRequest.entidad == "cliente" ? "Disponible" : "",
					disponible: cabeceraRequest.entidad == "cliente" ? parseFloat(cabeceraResults.disponible) : "",
					cuentas_cobrar: parseFloat(cabeceraResults.total_cuentas_cobrar),
					excedentes: parseFloat(cabeceraResults.total_excedentes),
					mora_dias: cabeceraResults.mora_dias
				}

				let docCarteraExcel :  IDocCarteraExcel[] = documentosCarteraResp?.map((dc) => {
					let dcMoneda = parseInt(dc.moneda);
					let reporteMoneda = parseInt(cabeceraRequest.moneda);
					let tDc : IDocCarteraExcel = {
						numero_documento: dc.operacion_numero_documento,
						nombre: cabeceraRequest.tipo_linea == "2" ? dc.beneficiario_nombre : dc.aceptante_nombre,
						fecha_vencimiento: this.formatMomentDate(dc.fecha_vencimiento),
						estado: dc.estado_descripcion,
						monto__codigo: dcMoneda,
						monto: (dcMoneda == 1 ? dc.monto_neto_soles ? parseFloat(dc.monto_neto_soles) : "" : dc.monto_neto_dolares ? parseFloat(dc.monto_neto_dolares) : ""),
						saldo__codigo:  reporteMoneda,
						saldo: (reporteMoneda == 1 ? parseFloat(dc.saldo_financiado_soles) : parseFloat(dc.saldo_financiado_dolares)) || 0,
					}
					return  tDc;
				}) || [];

				let cuentasCobrarExcel : ICuentasCobrarExcel[] = cuentasCobrar?.map((cc) => {
					let ccMoneda = parseInt(cc.moneda);
					let reporteMoneda = parseInt(cabeceraRequest.moneda);
					let tCc : ICuentasCobrarExcel = {
						numero_documento: cc.id,
						concepto: `${!!cc.concepto_descripcion ? cc.concepto_descripcion : ""}: ${!!cc.tipo_proceso_descripcion ? cc.tipo_proceso_descripcion : ""} ${!!cc.numero_proceso ? cc.numero_proceso : ""}`,
						fecha_emision: this.formatMomentDate(cc.fecha_creacion),
						estado: cc.estado_descripcion,
						monto__codigo: ccMoneda,
						// monto: (ccMoneda == 1 ? parseFloat(cc.monto_soles) : parseFloat(cc.monto_dolares)) || 0,
						monto: (ccMoneda == 1 ? cc.monto_soles ? parseFloat(cc.total) : "" : cc.monto_dolares ? parseFloat(cc.total) : ""), // WARNING - No coincide con la info que ofrece back 
						saldo__codigo: reporteMoneda,
						saldo: (reporteMoneda == 1 ? parseFloat(cc.saldo_soles) : parseFloat(cc.saldo_dolares)) || 0,
					}
					return tCc;
				}) || [];

				let liquidacionesExcel : ILiquidacionesExcel[] = liquidaciones?.map((liq) => {
					let reporteMoneda = parseInt(cabeceraRequest.moneda);
					let tLiq : ILiquidacionesExcel = {
						operacion_detalle_id: liq.operacion_detalle_id,
						numero_documento: liq.numero_documento,
						operacion_id: liq.operacion_id,
						nombre: cabeceraRequest.entidad == "cliente" ? liq.aceptante : liq.cliente,
						aceptante: liq.aceptante,
						cliente: liq.cliente,
						fecha_desembolso: this.formatMomentDate(liq.fecha_desembolso),
						fecha_vencimiento: this.formatMomentDate(liq.fecha_vencimiento),
						moneda: liq.moneda,
						moneda_reporte: reporteMoneda,
						monto_neto: liq.monto_neto,
						monto_adelanto: liq.monto_adelanto,
						pendiente_desembolso: liq.pendiente_desembolso,
						monto_total_soles: liq.monto_total_soles,
						monto_total_dolares: liq.monto_total_dolares,
						monto_total: (reporteMoneda == 1 ? liq.monto_total_soles ? parseFloat(liq.monto_total_soles) : 0 : liq.monto_total_dolares ? parseFloat(liq.monto_total_dolares) : 0),
						estado: liq.estado,
					}
					return tLiq;
				}) || [];

				let excedentesExcel : IExcedenteExcel[] = excedentesResp?.map((e) => {
					let exMoneda = parseInt(e.moneda);
					let tEx : IExcedenteExcel = {
						numero_documento: e.id,
						concepto: e.recaudacion === "Totales" ? "" : `${!!e.tipo_documento_descripcion ? e.tipo_documento_descripcion : ""}: ${!!e.numero_documento ? e.numero_documento : ""} - Aplicación ${e.recaudacion}`,
						fecha_emision: this.formatMomentDate(e.fecha),
						estado: e.estado_descripcion,
						monto__codigo: exMoneda,
						// monto: (exMoneda == 1 ? parseFloat(e.monto_soles) : parseFloat(e.monto_dolares)) || 0,
						monto: (exMoneda == 1 ? e.monto_soles ? parseFloat(e.excedente) : "" : e.monto_dolares ? parseFloat(e.excedente) : ""), // WARNING - No coincide con la info que ofrece back 
						saldo__codigo: exMoneda,
						saldo: (exMoneda == 1 ? parseFloat(e.saldo_soles) : parseFloat(e.saldo_dolares)) || 0,
					}
					return tEx;
				}) || [];

				// Aplicando adelantos a totales de la cabecera:
				let tAdelantos = {
					ocupado: 0,
					vigente: 0,
					mora: 0,
				}
						 
				let ttotales = liquidaciones.reduce((acumulador, item) => {
					let t_monto_total = cabMoneda == 1 ? parseFloat(item.monto_total_soles) : parseFloat(item.monto_total_dolares);
					acumulador.ocupado += t_monto_total;
					if(item.estado == "Vigente"){
						acumulador.vigente += t_monto_total;
					}else{
						acumulador.mora += t_monto_total;
					}
					return acumulador
				}, tAdelantos)

				cabeceraExcel = {
					...cabeceraExcel, 
					ocupado: cabeceraExcel.ocupado + ttotales.ocupado,
					disponible: cabeceraExcel.disponible as number - ttotales.ocupado,
					vigente: cabeceraExcel.vigente + ttotales.vigente,
					mora: cabeceraExcel.mora + ttotales.mora,
				}

				//  Anticuamiento procesamiento: 
				let moraRanges = [];
				for (const key in cabeceraExcel.mora_dias){
					moraRanges.push(key); // Se creará un array con (sujeto a cambios) ["1_8_dias", "9_15_dias", "16_30_dias", "31_60_dias", "mas_60_dias"]
				}
				let anticuamientoExcel : IAnticuamientoExcel[] = documentosCarteraResp?.map((dc) => {
					let reporteMoneda = parseInt(cabeceraRequest.moneda);
					let tDc : IAnticuamientoExcel = {
							numero_documento: dc.operacion_numero_documento,
							nombre: cabeceraRequest.tipo_linea == "2" ? dc.beneficiario_nombre : dc.aceptante_nombre,
							estado__codigo:  parseInt(dc.estado),
							estado: dc.estado_descripcion,
							prorroga: dc.dias_prorroga,
							moneda__codigo: reporteMoneda,
					}

					let diferenciaDias = moment().diff(moment(dc.fecha_vencimiento), "days"); 
					moraRanges.forEach((diasMora) => {
						let intervalo = diasMora.split("_"); // "1_8_dias" -> ["1", "8", "dias"]
						let inicio = parseInt(intervalo[0]); // 1
						let fin = parseInt(intervalo[1]);
						// En el caso "mas_60_dias", inicio no parseará bien ("mas"), por ende será inexistente
						if(inicio){
							if((diferenciaDias >= inicio) && (diferenciaDias <= fin)){
								tDc[`_${diasMora}`] = reporteMoneda == 1 ? parseFloat(dc.saldo_soles) : parseFloat(dc.saldo_dolares);
							}else{
								tDc[`_${diasMora}`] = .0;
							}
						}else{
							if(diferenciaDias >= fin){
								tDc[`_${diasMora}`] = reporteMoneda == 1 ? parseFloat(dc.saldo_soles) : parseFloat(dc.saldo_dolares);
							}else{
								tDc[`_${diasMora}`] = .0;
							}
						}
					})
					return tDc;
				}) || [];

				// Ajuste de empresas filtradas
				let empresasFiltradas = documentosCarteraResp.reduce((acc, item) => {
					let existe;
					if(cabeceraRequest.entidad == "cliente"){
						existe = acc.filter((empresa) => empresa.aceptante_ruc == item.aceptante_ruc);
					}else{
						existe = acc.filter((empresa) => empresa.beneficiario_ruc == item.beneficiario_ruc);
					}
					if( !(existe.length > 0) ){ acc.push(item) }
					return acc
				}, [])

				if(cabeceraExcel.n_aceptantes == 0){
					cabeceraExcel.n_aceptantes = empresasFiltradas.length || 0;
				}
				// Filtrando anticuamiento por estado:
				anticuamientoExcel = anticuamientoExcel.filter(x => x.estado__codigo === 3);

				this.crearExcelCuentas(cabeceraExcel, docCarteraExcel, cuentasCobrarExcel, excedentesExcel, anticuamientoExcel, liquidacionesExcel);
			})

	}

	crearExcelCuentas(
		cabecera : ICabeceraExcel, 
		docCarteraData : IDocCarteraExcel[] = [], 
		cuentasCobrarData : ICuentasCobrarExcel[] = [],
		excedenteData : IExcedenteExcel[] = [],
		anticuamientoData : IAnticuamientoExcel[] = [],
		liquidacionesData : ILiquidacionesExcel[] = [],
	){
		const workBook = new ExcelJS.Workbook();
		const workSheet = workBook.addWorksheet("EstadoCuenta");

		// Titulo
		workSheet.addRow(["Estado de Cuenta"]);
		workSheet.mergeCells("A1:O1");
		let titleCell = workSheet.getCell("O1");

		titleCell.alignment = {vertical: "middle", horizontal: "center"}
		titleCell.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 14, bold: false}
		titleCell.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "F9AE00"} }

		//Cabecera
		let cabeceraSubTitleStyle = {
			alignement: {vertical: "middle", horizontal: "left"},
			font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: true},
			fill: { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} }			
		}
		let cabeceraDataStyle = {
			alignement: {vertical: "middle", horizontal: "center"},
			font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: false},
			fill: { type: "pattern", pattern: "solid", fgColor: {argb: "FFFFFF"} }			
		}
		let cabeceraDeploy : ICelda[] = [
			// New Row
			{ 
				area: "A2:B2",
				value: "Tipo",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C2:E2",
				value: cabecera.tipo,
				style: cabeceraDataStyle,
			},{ 
				area: "F2:G2",
				value: "Fecha",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H2:J2",
				value: cabecera.fecha,
				style: cabeceraDataStyle,
			},{ 
				area: "K2:L2",
				value: "Moneda",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M2:O2",
				value: cabecera.moneda,
				style: cabeceraDataStyle,
			}, 
			// New Row
			{ 
				area: "A3:B3",
				value: "RUC",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C3:E3",
				value: cabecera.ruc,
				style: cabeceraDataStyle,
			},{ 
				area: "F3:G3",
				value: cabecera.n_aceptantes__on_tipo,
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H3:J3",
				value: cabecera.n_aceptantes,
				style: cabeceraDataStyle,
			},{ 
				area: "K3:L3",
				value: cabecera.fecha_vencimiento__on_tipo,
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M3:O3",
				value: cabecera.fecha_vencimiento,
				style: cabeceraDataStyle,
			},			
			// New Row
			{ 
				area: "A4:B4",
				value: "Nombre",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C4:E4",
				value: cabecera.nombre,
				style: cabeceraDataStyle,
			},{ 
				area: "F4:G4",
				value: "Docs en Cartera",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H4:J4",
				value: cabecera.n_docs_cartera,
				style: cabeceraDataStyle,
			},{ 
				area: "K4:L4",
				value: cabecera.linea__on_tipo,
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M4:O4",
				value: cabecera.linea,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},			
			// New Row
			{ 
				area: "A5:B5",
				value: "Tipo de Crédito",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C5:E5",
				value: cabecera.tipo_credito,
				style: cabeceraDataStyle,
			},{ 
				area: "F5:G5",
				value: "Max Mora",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H5:J5",
				value: cabecera.n_max_mora,
				style: cabeceraDataStyle,
			},{ 
				area: "K5:L5",
				value: "Ocupado",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M5:O5",
				value: cabecera.ocupado,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
			// New Row
			{ 
				area: "A6:B6",
				value: "SBS",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C6:E6",
				value: cabecera.sbs,
				style: cabeceraDataStyle,
			},{ 
				area: "F6:G6",
				value: "Ctas por cobrar",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H6:J6",
				value: cabecera.n_cuentas_cobrar,
				style: cabeceraDataStyle,
			},{ 
				area: "K6:L6",
				value: "    Vigente",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M6:O6",
				value: cabecera.vigente,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
			// New Row
			{ 
				area: "A7:B7",
				value: cabecera.aceptantes__on_tipo,
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C7:E7",
				value: cabecera.aceptantes,
				style: cabeceraDataStyle,
			},{ 
				area: "F7:G7",
				value: "Excedentes",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H7:J7",
				value: cabecera.n_excedentes,
				style: cabeceraDataStyle,
			},{ 
				area: "K7:L7",
				value: "    Mora",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M7:O7",
				value: cabecera.mora,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
			// New Row
			{ 
				area: "A8:B8",
				value: "Empresa",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "C8:E8",
				value: cabecera.empresa,
				style: cabeceraDataStyle,
			},{ 
				area: "F8:G8",
				value: "Tipo de Producto: ",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "H8:J8",
				value: cabecera.tipo_producto,
				style: cabeceraDataStyle,
			},{ 
				area: "K8:L8",
				value: cabecera.disponible__on_tipo,
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M8:O8",
				value: cabecera.disponible,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
			// New Row
			{ 
				area: "K9:L9",
				value: "Ctas x Cobrar",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M9:O9",
				value: cabecera.cuentas_cobrar,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
			// New Row
			{ 
				area: "K10:L10",
				value: "Excedentes",
				style: cabeceraSubTitleStyle,
			},{ 
				area: "M10:O10",
				value: cabecera.excedentes,
				style: {...cabeceraDataStyle, numFmt: `"${cabecera.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00`},
			},
		]

		cabeceraDeploy.forEach(x => {
			workSheet.mergeCells(x.area);
			let tCell = workSheet.getCell(x.area)
			
			tCell.value = x.value;
			tCell.alignment = x.style.alignement as Partial<ExcelJS.Alignment>;
			tCell.font = x.style.font;
			tCell.fill = x.style.fill as ExcelJS.Fill;
			if(x.style.numFmt != "") { tCell.numFmt = x.style.numFmt}
		})

		let startingNewRowsAt = 12
		// Table Header (Starts a row 11)
		let headerTableStyle = {
			alignement: {vertical: "middle", horizontal: "center"},
			font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: true},
			fill: { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} },
			numFmt: ""
		}
		let dataTableStyle = {
			alignement: {vertical: "middle", horizontal: "center"},
			font: {name: "Calibri", color: {argb: "000000"}, family: 3, size: 11, bold: false},
			fill: { type: "pattern", pattern: "solid", fgColor: {argb: "FFFFFF"} },
			numFmt: ""
		}

		//  Adelantos ----------------------------------------------------------------------------------------------------------------------------------------------
		// Title
		workSheet.mergeCells(`A${startingNewRowsAt}:O${startingNewRowsAt}`);
		let liquidacionesTitleCell = workSheet.getCell(`O${startingNewRowsAt}`);
		liquidacionesTitleCell.value = "Adelantos"
		liquidacionesTitleCell.alignment = {vertical: "middle", horizontal: "left"}
		liquidacionesTitleCell.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 13, bold: false}
		liquidacionesTitleCell.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} }
		startingNewRowsAt++;
		// Table Header
		let liquidacionDeploy : ICelda[] = [
			{ 
				area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
				value: "Número Doc.",
				style: headerTableStyle,
			},{ 
				area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
				value: cabecera.aceptantes__on_tipo,
				style: headerTableStyle,
			},{ 
				area: `G${startingNewRowsAt}:G${startingNewRowsAt}`,
				value: "Fecha Desem.",
				style: headerTableStyle,
			},{ 
				area: `H${startingNewRowsAt}:H${startingNewRowsAt}`,
				value: "Fecha Vcto",
				style: headerTableStyle,
			},{ 
				area: `I${startingNewRowsAt}:I${startingNewRowsAt}`,
				value: "Estado",
				style: headerTableStyle,
			},{ 
				area: `J${startingNewRowsAt}:K${startingNewRowsAt}`,
				value: "Monto Adelanto",
				style: headerTableStyle,
			},{ 
				area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
				value: "Pendiente Desembolso",
				style: headerTableStyle,
			},{ 
				area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
				value: "Total",
				style: headerTableStyle,
			}
		]
		// Incrusting data
		liquidacionesData.forEach(x => {
			startingNewRowsAt++;
			let tRow = [
				{ 
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: x.numero_documento,
					style: dataTableStyle,
				},{ 
					area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
					value: x.nombre,
					style: dataTableStyle,
				},{ 
					area: `G${startingNewRowsAt}:G${startingNewRowsAt}`,
					value: x.fecha_desembolso,
					style: dataTableStyle,
				},{ 
					area: `H${startingNewRowsAt}:H${startingNewRowsAt}`,
					value: x.fecha_vencimiento,
					style: dataTableStyle,
				},{ 
					area: `I${startingNewRowsAt}:I${startingNewRowsAt}`,
					value: x.estado,
					style: dataTableStyle,
				},{ 
					area: `J${startingNewRowsAt}:K${startingNewRowsAt}`,
					value: x.monto_adelanto,
					style:  {...dataTableStyle, numFmt: `"${x.moneda == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
					value: x.pendiente_desembolso,
					style:  {...dataTableStyle, numFmt: `"${x.moneda == 1 ? "S/" : "$"}" #,##0.00` },
				},{
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: x.monto_total,
					style:  {...dataTableStyle, numFmt: `"${x.moneda_reporte == 1 ? "S/" : "$"}" #,##0.00` },
				}
			];
			liquidacionDeploy = [...liquidacionDeploy, ...tRow];
		})
		// Incrusting Totales
		if(liquidacionesData.length > 0){
			startingNewRowsAt++;
			let liquidacionesTotales = [
				{
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: "Totales",
					style: headerTableStyle,
				},{ 
					area: `J${startingNewRowsAt}:K${startingNewRowsAt}`,
					value: liquidacionesData.reduce((acc, num) => {return acc + ( num.monto_adelanto ) }, 0),
					style: {...headerTableStyle, numFmt: `"${liquidacionesData[0].moneda == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: liquidacionesData.reduce((acc, num) => {return acc + ( num.monto_total ) }, 0),
					style: {...headerTableStyle, numFmt: `"${liquidacionesData[0].moneda_reporte == 1 ? "S/" : "$"}" #,##0.00` },
				},
			]
			liquidacionDeploy = [...liquidacionDeploy, ...liquidacionesTotales];
		}

		liquidacionDeploy.forEach(x => {
			workSheet.mergeCells(x.area);
			let tCell = workSheet.getCell(x.area)
			
			tCell.value = x.value;
			tCell.alignment = x.style.alignement as Partial<ExcelJS.Alignment>;
			tCell.font = x.style.font;
			tCell.fill = x.style.fill as ExcelJS.Fill;
			if(x.style.numFmt != "" && tCell.value !== "") { tCell.numFmt = x.style.numFmt}
		})

		// Documentos en Cartera -----------------------------------------------------------------------------------------------------------------------------------------
		startingNewRowsAt+=2;
		// Title
		workSheet.mergeCells(`A${startingNewRowsAt}:O${startingNewRowsAt}`);
		let docCarteraTitleCell = workSheet.getCell(`O${startingNewRowsAt}`);
		docCarteraTitleCell.value = "Documentos en Cartera"
		docCarteraTitleCell.alignment = {vertical: "middle", horizontal: "left"}
		docCarteraTitleCell.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 13, bold: false}
		docCarteraTitleCell.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} }
		// Cabecera de tabla
		startingNewRowsAt++
		let docCarteraDeploy : ICelda[] = [
			{ 
				area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
				value: "Número Doc",
				style: headerTableStyle,
			},{ 
				area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
				value: cabecera.aceptantes__on_tipo,
				style: headerTableStyle,
			},{ 
				area: `G${startingNewRowsAt}:H${startingNewRowsAt}`,
				value: "Fecha de Vencimiento",
				style: headerTableStyle,
			},{ 
				area: `I${startingNewRowsAt}:K${startingNewRowsAt}`,
				value: "Estado",
				style: headerTableStyle,
			},{ 
				area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
				value: "Monto",
				style: headerTableStyle,
			},{ 
				area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
				value: "Saldo",
				style: headerTableStyle,
			},
		]
		// Incrusting data
		docCarteraData.forEach(x => {
			startingNewRowsAt++;
			let tRow = [
				{
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: x.numero_documento,
					style: dataTableStyle,
				}, { 
					area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
					value: x.nombre,
					style: dataTableStyle,
				},{ 
					area: `G${startingNewRowsAt}:H${startingNewRowsAt}`,
					value: x.fecha_vencimiento,
					style: dataTableStyle,
				},{ 
					area: `I${startingNewRowsAt}:K${startingNewRowsAt}`,
					value: x.estado,
					style: dataTableStyle,
				},{ 
					area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
					value: x.monto,
					style:  {...dataTableStyle, numFmt: `"${x.monto__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: x.saldo,
					style: {...dataTableStyle, numFmt: `"${x.saldo__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},
			];
			docCarteraDeploy = [...docCarteraDeploy, ...tRow];
		})
		// Incrusting Totales
		if(docCarteraData.length > 0){
			startingNewRowsAt++;
			let docCarteraTotales = [
				{
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: "Totales",
					style: headerTableStyle,
				},{ 
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: docCarteraData.reduce((acc, num) => {return acc + num.saldo}, 0),
					style: {...headerTableStyle, numFmt: `"${docCarteraData[0].saldo__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},
			]
			docCarteraDeploy = [...docCarteraDeploy, ...docCarteraTotales];
		}

		docCarteraDeploy.forEach(x => {
			workSheet.mergeCells(x.area);
			let tCell = workSheet.getCell(x.area)
			
			tCell.value = x.value;
			tCell.alignment = x.style.alignement as Partial<ExcelJS.Alignment>;
			tCell.font = x.style.font;
			tCell.fill = x.style.fill as ExcelJS.Fill;
			if(x.style.numFmt != "" && tCell.value !== "") { tCell.numFmt = x.style.numFmt}
		})

		//  Cuentas por Cobrar ----------------------------------------------------------------------------------------------------------------------------------------------
		startingNewRowsAt+=2;
		// Title
		workSheet.mergeCells(`A${startingNewRowsAt}:O${startingNewRowsAt}`);
		let cuentasCobrarTitleCell = workSheet.getCell(`O${startingNewRowsAt}`);
		cuentasCobrarTitleCell.value = "Cuentas por Cobrar"
		cuentasCobrarTitleCell.alignment = {vertical: "middle", horizontal: "left"}
		cuentasCobrarTitleCell.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 13, bold: false}
		cuentasCobrarTitleCell.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} }
		startingNewRowsAt++;
		// Table Header
		let cuentaCobrarDeploy : ICelda[] = [
			{ 
				area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
				value: "Número Doc",
				style: headerTableStyle,
			},{ 
				area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
				value: "Concepto",
				style: headerTableStyle,
			},{ 
				area: `G${startingNewRowsAt}:H${startingNewRowsAt}`,
				value: "Fecha de Emisión",
				style: headerTableStyle,
			},{ 
				area: `I${startingNewRowsAt}:K${startingNewRowsAt}`,
				value: "Estado",
				style: headerTableStyle,
			},{ 
				area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
				value: "Monto",
				style: headerTableStyle,
			},{ 
				area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
				value: "Saldo",
				style: headerTableStyle,
			}, 
		]
		// Incrusting data
		cuentasCobrarData.forEach(x => {
			startingNewRowsAt++;
			let tRow = [
				{ 
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: x.numero_documento,
					style: dataTableStyle,
				},{ 
					area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
					value: x.concepto,
					style: dataTableStyle,
				},{ 
					area: `G${startingNewRowsAt}:H${startingNewRowsAt}`,
					value: x.fecha_emision,
					style: dataTableStyle,
				},{ 
					area: `I${startingNewRowsAt}:K${startingNewRowsAt}`,
					value: x.estado,
					style: dataTableStyle,
				},{ 
					area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
					value: x.monto,
					style:  {...dataTableStyle, numFmt: `"${x.monto__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: x.saldo,
					style:  {...dataTableStyle, numFmt: `"${x.saldo__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				}, 
			];
			cuentaCobrarDeploy = [...cuentaCobrarDeploy, ...tRow];
		})
		// Incrusting Totales
		if(cuentasCobrarData.length > 0){
			startingNewRowsAt++;
			let cuentaCobrarTotales = [
				{
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: "Totales",
					style: headerTableStyle,
				},{ 
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: cuentasCobrarData.reduce((acc, num) => {return acc + ( num.saldo ) }, 0),
					style: {...headerTableStyle, numFmt: `"${cuentasCobrarData[0].saldo__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},
			]
			cuentaCobrarDeploy = [...cuentaCobrarDeploy, ...cuentaCobrarTotales];
		}

		cuentaCobrarDeploy.forEach(x => {
			workSheet.mergeCells(x.area);
			let tCell = workSheet.getCell(x.area)
			
			tCell.value = x.value;
			tCell.alignment = x.style.alignement as Partial<ExcelJS.Alignment>;
			tCell.font = x.style.font;
			tCell.fill = x.style.fill as ExcelJS.Fill;
			if(x.style.numFmt != "" && tCell.value !== "") { tCell.numFmt = x.style.numFmt}
		})

		//  Excedentes -------------------------------------------------------------------------------------------------------------------------------------------------
		startingNewRowsAt+=2;
		// Title
		workSheet.mergeCells(`A${startingNewRowsAt}:O${startingNewRowsAt}`);
		let excedentes = workSheet.getCell(`O${startingNewRowsAt}`);
		excedentes.value = "Excedentes por Devolver"
		excedentes.alignment = {vertical: "middle", horizontal: "left"}
		excedentes.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 13, bold: false}
		excedentes.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} }
		startingNewRowsAt++;
		// Table Header
		let excedentesDeploy : ICelda[] = [
			{ 
				area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
				value: "Número Doc",
				style: headerTableStyle,
			},{ 
				area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
				value: "Concepto",
				style: headerTableStyle,
			},{ 
				area: `G${startingNewRowsAt}:H${startingNewRowsAt}`,
				value: "Fecha de Emisión",
				style: headerTableStyle,
			},{ 
				area: `I${startingNewRowsAt}:K${startingNewRowsAt}`,
				value: "Estado",
				style: headerTableStyle,
			},{ 
				area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
				value: "Monto",
				style: headerTableStyle,
			},{ 
				area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
				value: "Saldo",
				style: headerTableStyle,
			}, 
		]
		// Incrusting data
		excedenteData.forEach(x => {
			startingNewRowsAt++;
			let tRow = [
				{ 
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: x.numero_documento,
					style: dataTableStyle,
				},{ 
					area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
					value: x.concepto,
					style: dataTableStyle,
				},{ 
					area: `G${startingNewRowsAt}:H${startingNewRowsAt}`,
					value: x.fecha_emision,
					style: dataTableStyle,
				},{ 
					area: `I${startingNewRowsAt}:K${startingNewRowsAt}`,
					value: x.estado,
					style: dataTableStyle,
				},{ 
					area: `L${startingNewRowsAt}:M${startingNewRowsAt}`,
					value: x.monto,
					style:  {...dataTableStyle, numFmt: `"${x.monto__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: x.saldo,
					style:  {...dataTableStyle, numFmt: `"${x.saldo__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				}, 
			];
			excedentesDeploy = [...excedentesDeploy, ...tRow];
		});
		// Incrusting Totales
		if(excedenteData.length > 0){
			startingNewRowsAt++;
			let excedenteTotales = [
				{
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: "Totales",
					style: headerTableStyle,
				},{ 
					area: `N${startingNewRowsAt}:O${startingNewRowsAt}`,
					value: excedenteData.reduce((acc, num) => {return acc + num.saldo}, 0),
					style: {...headerTableStyle, numFmt: `"${excedenteData[0].saldo__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},
			]
			excedentesDeploy = [...excedentesDeploy, ...excedenteTotales];
		}

		excedentesDeploy.forEach(x => {
			workSheet.mergeCells(x.area);
			let tCell = workSheet.getCell(x.area)
			
			tCell.value = x.value;
			tCell.alignment = x.style.alignement as Partial<ExcelJS.Alignment>;
			tCell.font = x.style.font;
			tCell.fill = x.style.fill as ExcelJS.Fill;
			if(x.style.numFmt != "" && tCell.value !== "") { tCell.numFmt = x.style.numFmt}
		})

		//  Deuda ---------------------------------------------------------------------------------------------------------------------------------------------------------------
		startingNewRowsAt+=2;
		// Title
		workSheet.mergeCells(`A${startingNewRowsAt}:O${startingNewRowsAt}`);
		let deuda = workSheet.getCell(`O${startingNewRowsAt}`);
		deuda.value = "Anticuamiento de la Deuda"
		deuda.alignment = {vertical: "middle", horizontal: "left"}
		deuda.font = {name: "Calibri", color: {argb: "000000"}, family: 3, size: 13, bold: false}
		deuda.fill = { type: "pattern", pattern: "solid", fgColor: {argb: "F4F3EF"} }
		startingNewRowsAt++;
		// Table Header
		let deudaDeploy : ICelda []= [
			{ 
				area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
				value: "Número Doc",
				style: headerTableStyle,
			},{ 
				area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
				value: cabecera.aceptantes__on_tipo,
				style: headerTableStyle,
			},{ 
				area: `G${startingNewRowsAt}:H${startingNewRowsAt}`,
				value: "Estado",
				style: headerTableStyle,
			},{ 
				area: `I${startingNewRowsAt}:J${startingNewRowsAt}`,
				value: "Prórroga",
				style: headerTableStyle,
			},{ 
				area: `K${startingNewRowsAt}`,
				value: "1-8 Días",
				style: headerTableStyle,
			},{ 
				area: `L${startingNewRowsAt}`,
				value: "9-15 Días",
				style: headerTableStyle,
			},{ 
				area: `M${startingNewRowsAt}`,
				value: "16-30 Días",
				style: headerTableStyle,
			},{ 
				area: `N${startingNewRowsAt}`,
				value: "31-60 Días",
				style: headerTableStyle,
			},{ 
				area: `O${startingNewRowsAt}`,
				value: "Más 60 Días",
				style: headerTableStyle,
			},
		]
		// Incrusting data
		anticuamientoData.forEach(x => {
			startingNewRowsAt++;
			let tRow = [
				{ 
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: x.numero_documento,
					style: dataTableStyle,
				},{ 
					area: `C${startingNewRowsAt}:F${startingNewRowsAt}`,
					value: x.nombre,
					style: dataTableStyle,
				},{ 
					area: `G${startingNewRowsAt}:H${startingNewRowsAt}`,
					value: x.estado,
					style: dataTableStyle,
				},{ 
					area: `I${startingNewRowsAt}:J${startingNewRowsAt}`,
					value: x.prorroga,
					style: dataTableStyle,
				},{ 
					area: `K${startingNewRowsAt}`,
					value: x._1_8_dias,
					style:  {...dataTableStyle, numFmt: `"${x.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `L${startingNewRowsAt}`,
					value: x._9_15_dias,
					style:  {...dataTableStyle, numFmt: `"${x.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `M${startingNewRowsAt}`,
					value: x._16_30_dias,
					style:  {...dataTableStyle, numFmt: `"${x.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `N${startingNewRowsAt}`,
					value: x._31_60_dias,
					style:  {...dataTableStyle, numFmt: `"${x.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `O${startingNewRowsAt}`,
					value: x._mas_60_dias,
					style:  {...dataTableStyle, numFmt: `"${x.moneda__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},
			];
			deudaDeploy = [...deudaDeploy, ...tRow];
		})
		// Incrusting Totales
		if(anticuamientoData.length > 0){
			startingNewRowsAt++;
			let anticuamientoTotales = [
				{
					area: `A${startingNewRowsAt}:B${startingNewRowsAt}`,
					value: "Totales",
					style: headerTableStyle,
				},{ 
					area: `K${startingNewRowsAt}`,
					value: anticuamientoData.reduce((acc, num) => {return acc + num._1_8_dias}, 0),
					style: {...headerTableStyle, numFmt: `"${anticuamientoData[0].moneda__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `L${startingNewRowsAt}`,
					value: anticuamientoData.reduce((acc, num) => {return acc + num._9_15_dias}, 0),
					style: {...headerTableStyle, numFmt: `"${anticuamientoData[0].moneda__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `M${startingNewRowsAt}`,
					value: anticuamientoData.reduce((acc, num) => {return acc + num._16_30_dias}, 0),
					style: {...headerTableStyle, numFmt: `"${anticuamientoData[0].moneda__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `N${startingNewRowsAt}`,
					value: anticuamientoData.reduce((acc, num) => {return acc + num._31_60_dias}, 0),
					style: {...headerTableStyle, numFmt: `"${anticuamientoData[0].moneda__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},{ 
					area: `O${startingNewRowsAt}`,
					value: anticuamientoData.reduce((acc, num) => {return acc + num._mas_60_dias}, 0),
					style: {...headerTableStyle, numFmt: `"${anticuamientoData[0].moneda__codigo == 1 ? "S/" : "$"}" #,##0.00` },
				},
			]
			deudaDeploy = [...deudaDeploy, ...anticuamientoTotales];
		}

		deudaDeploy.forEach(x => {
			workSheet.mergeCells(x.area);
			let tCell = workSheet.getCell(x.area)
			
			tCell.value = x.value;
			tCell.alignment = x.style.alignement as Partial<ExcelJS.Alignment>;
			tCell.font = x.style.font;
			tCell.fill = x.style.fill as ExcelJS.Fill;
			if(x.style.numFmt != "") { tCell.numFmt = x.style.numFmt}
		})


		// Extra styles: 
		workSheet.getRow(1).height = 25;
		for(let i = 1; i <= 15; i++){
			workSheet.getColumn(i).width = 13;
		}
		
		// Generando buffer y disparando evento para descargar el archivo
		workBook.xlsx.writeBuffer().then(buffer => {
			const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download =  `EstadoCuenta ${moment().format("DDMMYYYYHHMM")}.xlsx`;

			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		})

	}

	loadSearchTipoPagos() {
		/**
		 * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
		 * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
		 * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value. 
		 * 		So that API will not hit if the current and previous value is the same
		 * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
		 * 		string typed by user
		 */

		this.rucResultsPagos$ = concat(
			of([]), // Items predeterminados
			this.buscaRucPagos$.pipe(
				filter(res => {
					return res !== null && res.length >= this.caracteresMinimos
				}),
				distinctUntilChanged(),
				debounceTime(800),
				tap(() => this.loadingSearchPagos = true),
				switchMap(term => {
					let request: Observable<any>;
					if (this.formularioDeudas.get('tipo').value === 1) {
						request = this.reporteService.obtenerClientesObservable(1, 1000, '', term);
					} else {
						request = this.reporteService.obtenerAceptantesObservable(1, 1000, term);
					}

					return request.pipe(
						catchError(() => of([])), // empty list on error
						tap(() => this.loadingSearchPagos = false)
					)
				})
			)
		);

	}

	loadSearchTipo() {
		/**
		 * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
		 * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
		 * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value. 
		 * 		So that API will not hit if the current and previous value is the same
		 * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
		 * 		string typed by user
		 */

		this.rucResults$ = concat(
			of([]), // Items predeterminados
			this.buscaRuc$.pipe(
				filter(res => {
					return res !== null && res.length >= this.caracteresMinimos
				}),
				distinctUntilChanged(),
				debounceTime(800),
				tap(() => this.loadingSearch = true),
				switchMap(term => {
					let request: Observable<any>;

					if (this.formularioDeudas.get('tipo').value === 1) {
						request = this.reporteService.obtenerClientesObservable(1, 1000, '', term);
					} else {
						request = this.reporteService.obtenerAceptantesObservable(1, 1000, term);
					}

					return request.pipe(
						catchError(() => of([])), // empty list on error
						tap((tapItem) => {
							this.rucFilterSearch = tapItem
							this.loadingSearch = false
						})
					)
				})
			)
		);
	}

	loadSearchSecundario() {
		this.secondaryResults$ = concat(
			of([]), // Items predeterminados
			this.buscaSecundario$.pipe(
				filter(res => {
					return res !== null && res.length >= this.caracteresMinimos
				}),
				distinctUntilChanged(),
				debounceTime(800),
				tap(() => this.loadingSearch = true),
				switchMap(term => {
					let request: Observable<any>;

					if (this.formularioDeudas.get('tipo').value === 2) {
						request = this.reporteService.obtenerClientesObservable(1, 1000, '', term);
					} else {
						request = this.reporteService.obtenerAceptantesObservable(1, 1000, term);
					}

					return request.pipe(
						catchError(() => of([])), // empty list on error
						tap((tapItem) => {
							console.log(tapItem)
							this.loadingSearch = false
						})
					)
				})
			)
		);
	}

	loadSearchSecundarioPagos() {
		this.secondaryResultsPagos$ = concat(
			of([]), // Items predeterminados
			this.buscaSecundarioPagos$.pipe(
				filter(res => {
					return res !== null && res.length >= this.caracteresMinimos
				}),
				distinctUntilChanged(),
				debounceTime(800),
				tap(() => this.loadingSearchPagos = true),
				switchMap(term => {
					let request: Observable<any>;

					if (this.formularioPgagos.get('tipo').value === 2) {
						request = this.reporteService.obtenerClientesObservable(1, 1000, '', term);
					} else {
						request = this.reporteService.obtenerAceptantesObservable(1, 1000, term);
					}

					return request.pipe(
						catchError(() => of([])), // empty list on error
						tap(() => this.loadingSearchPagos = false)
					)
				})
			)
		);
	}

	resetForm() {
		this.formularioDeudas.get('ruc').setValue(null)
		this.formularioDeudas.get('empresa').setValue(1)
		this.formularioDeudas.get('empresa').disable()
		this.formularioDeudas.get('secundario').setValue(null)
		this.formularioDeudas.get('fecha').setValue(new Date())
		this.formularioDeudas.get('tipo_cambio').setValue(this.tiposCambio[0].valor)
		this.empresas = []

		this.buscaRuc$ = new Subject<string>();
		this.buscaSecundario$ = new Subject<string>();

		this.formularioPgagos.get('ruc').setValue(null)
		this.formularioPgagos.get('secundario').setValue(null)
		this.formularioPgagos.get('fecha').setValue(new Date())
		this.formularioPgagos.get('tipo_cambio').setValue(this.tiposCambio[0].valor)

		this.buscaRuc$ = new Subject<string>();
		this.buscaSecundario$ = new Subject<string>();
		this.buscaRucPagos$ = new Subject<string>();
		this.buscaSecundarioPagos$ = new Subject<string>();

		this.loadSearchTipo()
		this.loadSearchTipoPagos()
		this.loadSearchSecundario()
		this.loadSearchSecundarioPagos()
	}

	formatDate(date) {
		if (!(date instanceof Date))
			return date;

		return date.getFullYear() +
			"-" + (date.getMonth() + 1) +
			"-" + date.getDate();
	}

	formatMomentDate(fecha : string, format : string = "DD/MM/YYYY"){
		let tMoment = moment(fecha);
		return tMoment.isValid() ? tMoment.format(format) : fecha;
	}
}
