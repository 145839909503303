import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { AddEntidadVinculoComponent } from 'app/modules/clientes/modals/add-entidad-vinculo/add-entidad-vinculo.component';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-entidades-vinculos',
  templateUrl: './entidades-vinculos.component.html',
  styleUrls: ['./entidades-vinculos.component.css']
})
export class EntidadesVinculosComponent implements OnInit {

  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
  public searchstring     : string = '';

  public rowData2          : any[]   = [];
	public loadingTable2     : boolean = false;
	public columnDefs2       : columnHeader[];
	public pageTableActual2  : number;
	public pageTableTotal2   : number;
	public pageTablePerPages2 : number  = 10;
	public pageTablePages2   : number[];
  public searchstring2     : string = '';

  @Input() clienteId = 0;
  @Input() readonly = false;

  constructor(
    private clientesService	: ClientesService,
    public modalService     : NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerVinculos();
    this.obtenerVinculosDirectos();
  }

  obtenerVinculos() {
    console.log('Llamando vinculos otros');
    this.clientesService.obtenerVinculos(this.clienteId.toString())
    .then(res => {
      this.initVars();
			this.loadTable(res);
    })
    .catch(error => {
      console.error(error);
    })
  }
  obtenerVinculosDirectos() {
    console.log('Llamando vinculos directos');
    this.clientesService.obtenerVinculos(this.clienteId.toString())
    .then(res => {
      this.initVars();
			this.loadTable2(res);
    })
    .catch(error => {
      console.error(error);
    })
  }

  loadTable(data) {

		this.rowData           = data.results.filter( item => item.directo);
		// this.pageTableActual   = data.page_number;
		// this.pageTableTotal    = data.count;
		// this.pageTablePerPages = data.per_page;
		// this.pageTablePages = [];
		// for (let index = 0; index < data.num_pages; index++) {
		// 	this.pageTablePages.push(index);
		// }

  }
  
  loadTable2(data) {

		this.rowData2           = data.results.filter( item => !item.directo);
		// this.pageTableActual2   = data.page_number;
		// this.pageTableTotal2    = data.count;
		// this.pageTablePerPages2 = data.per_page;
		// this.pageTablePages2 = [];
		// for (let index = 0; index < data.num_pages; index++) {
		// 	this.pageTablePages2.push(index);
		// }

	}

  initVars() {
		let columns = [
			{
				headerName: 'N° Doc.', 
				field     : 'id' ,
				sortable  : true,
			},
			{
				headerName: 'Razón Social', 
				field     : 'nombre' ,
				sortable  : true,
			},
			{
				headerName: 'Tipo de Persona', 
				field     : 'tipo_persona_nombre' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Doc. Ident-RUC', 
				field     : 'dni_ruc' ,
				sortable  : true,
			},
      {
				headerName: 'Aportes(Soles)', 
				field     : 'aporte',
        class     : 'text-center',
      },
      {
				headerName: 'Participacion(Part.%)', 
				field     : 'participacion',
        class     : 'text-center',
      },
      {
				headerName: 'Gestión(Si-No)', 
				field     : 'gestion' ,
				sortable  : true,
				pipe      : 'checkbox',
				checkDisabled: true
      },
      {
				headerName: 'Cód. SBS',
				field     : 'codigo_sbs' ,
				sortable  : true,
				class     : 'text-center',
			},
    ];
    
    let columns2 = [
			{
				headerName: 'N° Doc.', 
				field     : 'id' ,
				sortable  : true,
			},
			{
				headerName: 'Razón Social', 
				field     : 'nombre' ,
				sortable  : true,
			},
			{
				headerName: 'Tipo de Persona', 
				field     : 'tipo_persona_nombre' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Doc. Ident-RUC', 
				field     : 'dni_ruc' ,
				sortable  : true,
			},
      {
				headerName: 'Aportes(Soles)', 
				field     : 'aporte',
        class     : 'text-center',
      },
       {
				headerName: 'Participacion(Part.%)', 
				field     : 'participacion',
        class     : 'text-center',
      },
      {
				headerName: 'Gestión(Si-No)', 
				field     : 'gestion' ,
				sortable  : true,
				pipe      : 'checkbox',
				checkDisabled: true
      },
      {
				headerName: 'Cód. SBS',
				field     : 'codigo_sbs' ,
				sortable  : true,
				class     : 'text-center',
      },
      {
				headerName: 'Cargo', 
				field     : 'cargo' ,
				sortable  : true,
				class     : 'text-center',
			}
		];

    this.columnDefs = columns;
    this.columnDefs2 = columns2;
	}

  goToPage({page,per_page,filtros}) {
    this.obtenerVinculos();
  }

  tableEditEvent(vinculo) {
    const modalRef = this.modalService.open(AddEntidadVinculoComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;
    modalRef.componentInstance.vinculoId = vinculo.id;
    modalRef.componentInstance.directo = vinculo.directo;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			if (result.directo) {
        this.obtenerVinculos();
      } else {
        this.obtenerVinculosDirectos();
      }
		},
		error => {

		});
  }

  destroyContact(director) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Director';
		modalRef.componentInstance.message = '¿Seguro que desea remover el Director?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.clientesService.elimiarVinculo(director.id)
			.then((result : any) => {
        this.obtenerVinculos();
        this.obtenerVinculosDirectos();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  openModal(directo = false) {
    const modalRef = this.modalService.open(AddEntidadVinculoComponent, {
			size: 'lg'
		});
		
    modalRef.componentInstance.clienteId = this.clienteId;
    modalRef.componentInstance.directo = directo;

		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
      console.log(result);
      if (result.directo) {
        this.obtenerVinculos();
      } else {
        this.obtenerVinculosDirectos();
      }
		},
		error => {

		});
  }

}
