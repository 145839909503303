import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LegalService } from 'app/core/services/legal.service';
import { AdjuntarFileComponent } from 'app/shared/adjuntar-file/adjuntar-file.component';

@Component({
  selector: 'app-formato-metodizado',
  templateUrl: './formato-metodizado.component.html',
  styleUrls: ['./formato-metodizado.component.css']
})
export class FormatoMetodizadoComponent implements OnInit {
  
  constructor(
    public adjuntaComponent : AdjuntarFileComponent,
    public legal: LegalService,
    public modalService   : NgbModal,
    ) { }

  ngOnInit(): void {
    this.adjuntaComponent.crearAdjuntar();
    this.adjuntaComponent.fileAdjunto.subscribe((file:any)=>{
      const formData = new FormData();
      // formData.append('adjunto', file);
      this.obtenerArchivo(formData)
    })
    this.modalService.dismissAll('error')
  }
  
  obtenerArchivo(body){
    this.legal.obtenerMetodizado(body).then((res)=>{
      this.adjuntaComponent.obtenerBlob(res,'FormatoMetodizado','xlsx')
      this.adjuntaComponent.fileAdjunto.unsubscribe()
      setTimeout(() => {
        this.modalService.dismissAll()
      },1500)
    })
  }



}
