import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { AdminLayoutComponent } from 'app/layouts/admin/admin-layout.component';
import { ProcesosComponent } from './pages/procesos/procesos.component';
import { ResultadosComponent } from './pages/resultados/resultados.component';


const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Cavali'
    },
    children: [
      {
        path: 'cavali-procesos',
        canActivate: [AuthGuard],
        component: ProcesosComponent,
        data: {
          breadcrumb: 'Procesos de CAVALI'
        }
      },
      {
        path: 'cavali-resultados',
        canActivate: [AuthGuard],
        component: ResultadosComponent,
        data: {
          breadcrumb: 'Resultados de CAVALI'
        }
      },
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CavaliRoutingModule { }
