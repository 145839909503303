import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../auth/auth.service';
import { ToastService } from '../toast/toast.service';

@Injectable({
    providedIn: 'root'
})
export class ContactoPoderesService {

    private apiUrl: string = '';
    public poderes: string = 'clientes/poderescontactos';

    constructor(
        public appService: AppService,
        public toast: ToastService,
        public spinner: NgxSpinnerService,
        public http: HttpClient,
        public auth: AuthService
    ) {
        this.apiUrl = this.appService.settings.API_base_url
    }

    /**
* Habilita el loader para request a la API
*/
    spinnerOn() {
        this.spinner.show()
    }

    /**
    * Desabilita el loader
    * @param mensaje Mensaje del toast
    * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
    */
    spinnerOff(mensaje: string = null, ok: boolean = true) {
        this.spinner.hide()
        this.appService.notifyMe(mensaje,ok)

        if (mensaje && ok)
            this.toast.success(mensaje)
        if (mensaje && !ok)
            this.toast.warning(mensaje)
    }

    /**
 * Listar los poderes del modal de creación de contacto
 */
    obtenerPoderesContactos(id = 0) {
        const URL = `${this.apiUrl}${this.poderes}?contacto=${id}`;
        return new Promise((resolve, reject) => {
            const httpRequest = this.http.get(URL);
            httpRequest.subscribe(data => {
                resolve(data);
            }, (err) => {
                reject(err);
            });
        });
    }

    /**
     * Guarda o actualiza un detalle poderes usuarios
     * @param data Objeto JSON del objeto a actualizar
     * @param id ID de la operacion a actualizar
     */
    guardar(data: any, id: any = null) {
        console.log(id);
        return new Promise((resolve, reject) => {
            let httpReques;
            let URL = this.apiUrl + this.poderes + '/';
            console.log(URL);
            if (!id) {
                httpReques = this.http.post(URL, data);
            } else {
                URL += `${id}/`
                httpReques = this.http.put(URL, data);
            }

            this.spinnerOn()
            httpReques.subscribe(data => {
                this.spinnerOff();
                resolve(data);
            }, (err) => {
                this.spinnerOff('Ocurrió un error durante el proceso : ' + err);
                reject(err);
            });
        });
    }

}
