import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-manage-comite-integrantes-orden-modal',
  templateUrl: './manage-comite-integrantes-orden-modal.component.html',
  styleUrls: ['./manage-comite-integrantes-orden-modal.component.css']
})
export class ManageComiteIntegrantesOrdenModalComponent implements OnInit {
  faTimes = faTimes
  myForm: FormGroup;
  oficiales: any[] = [];
  @Input() comiteId = 0;
  @Input() integrante: any;
  @Input() orden: number = 0;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild        : FormBuilder,
    public activeModal      : NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.myForm = this.formbuild.group({
      orden: [this.orden, [Validators.required]]
    });
  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = {
        orden: this.myForm.value.orden,
        comite: this.comiteId
      }
      this.successEmit.emit(data);
      this.activeModal.close(true);
    }
  }

}
