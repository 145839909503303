import { Injectable } from "@angular/core";
import { ToastService } from "../services/toast/toast.service";

@Injectable({
    providedIn: "root",
})
export class SharedFunctions {
    public diasSemana: any[] = [
      {
        id: 1,
        descriocion: "Lunes",
        abrv: "LUN",
      },
      {
        id: 2,
        descriocion: "Martes",
        abrv: "MAR",
      },
      {
        id: 3,
        descriocion: "Miércoles",
        abrv: "MIE",
      },
      {
        id: 4,
        descriocion: "Jueves",
        abrv: "JUE",
      },
      {
        id: 5,
        descriocion: "Viernes",
        abrv: "VIE",
      },
      {
        id: 6,
        descriocion: "Sábado",
        abrv: "SAB",
      },
      {
        id: 7,
        descriocion: "Domingo",
        abrv: "DOM",
      },
    ];

    public mesesAnio: any[] = [
      {
        id: 1,
        descriocion: "Enero",
        abrv: "ENE",
      },
      {
        id: 2,
        descriocion: "Febrero",
        abrv: "FEB",
      },
      {
        id: 3,
        descriocion: "Marzo",
        abrv: "MAR",
      },
      {
        id: 4,
        descriocion: "Abril",
        abrv: "ABR",
      },
      {
        id: 5,
        descriocion: "Mayo",
        abrv: "MAY",
      },
      {
        id: 6,
        descriocion: "Junio",
        abrv: "JUN",
      },
      {
        id: 7,
        descriocion: "Julio",
        abrv: "JUL",
      },
      {
        id: 8,
        descriocion: "Agosto",
        abrv: "AGO",
      },
      {
        id: 9,
        descriocion: "Septiembre",
        abrv: "SEP",
      },
      {
        id: 10,
        descriocion: "Octubre",
        abrv: "OCT",
      },
      {
        id: 11,
        descriocion: "Noviembre",
        abrv: "NOV",
      },
      {
        id: 12,
        descriocion: "Diciembre",
        abrv: "DIC",
      },
    ];

    constructor(
      public toast             : ToastService
    ){

    }

    validateEmail (email: string) : boolean{
        var mailformat = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return mailformat.test(email);
    }

    parseDate(date) {
      if (!date)
        return null;
      
      let dateNow = new Date();
      let yy = date.split('-')[0];
      let mm = (date.split('-')[1] - 1);
      let dd = (date.split('-')[2]).substring(0,2);
  
      let d = new Date(yy, mm, dd, dateNow.getHours());
      
      return d;
    }

    parseDateReverse(date) {
      if (!date)
        return null;
      
      let dateNow = new Date();
      let yy = date.split('/')[2];
      let mm = (date.split('/')[1] - 1);
      let dd = (date.split('/')[0]).substring(0,2);
  
      let d = new Date(yy, mm, (dd), dateNow.getHours());
      
      return d;
    }

    formatDate(date) {
      if (!(date instanceof Date)) return;
      return (
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
      );
    }
    
    getDateFromString(stringDate){
      let string = this.formaStringDate(stringDate)

      return this.parseDateReverse(string)
    }

    /**
     * 
     * @param date 	2021-05-24T15:11:45.148776
     * @returns 24/05/2021
     */
    formaStringDate(date: string): string {
      let fechaArr = date.split('-')
      let anio = fechaArr[0]
      let mes = fechaArr[1]
      let dia = fechaArr[2].split('T')[0]
      return `${dia}/${mes}/${anio}`
    }

    /**
     * 
     * @param date 	2021-05-24 00:00
     * @returns 2021-05-24
     */
    cleanDate(date: string): string {
      let fechaDirty = date.split(' ')[0]
      let fechaArr = fechaDirty.split('-')
      let anio = fechaArr[0]
      let mes = (parseInt(fechaArr[1])) < 10 ? `0${fechaArr[1]}`: fechaArr[1]
      let dia = (parseInt(fechaArr[2])) < 10 ? `0${fechaArr[2]}`: fechaArr[2]
      return `${anio}-${mes}-${dia}`
    }

    restaFechas(fechaEntrada: string){
      let fechaArr: any = fechaEntrada.split('-')

      let anio = parseInt(fechaArr[0])
      let mes = parseInt(fechaArr[1]) - 1
      let dia = parseInt(fechaArr[2])

      let fechaVencimiento = new Date(anio,mes,dia)
      let justoHoy = new Date()
      justoHoy.setHours(0,0,0,0)
      fechaVencimiento.setHours(0,0,0,0)
      let diferenciaDias =  justoHoy.getTime() - fechaVencimiento.getTime()

      let resultadoDias = Math.floor(diferenciaDias/(1000*60*60*24))

      return resultadoDias
    }

    /**
     * IN 2021-10-01
     * Out OCT01
     */
    abrvStringDate(date){
      let dateObj = this.parseDate(date)

      let mesNumero = dateObj.getMonth()+1
      let mes = this.mesesAnio.find(mes => mes.id == mesNumero)
      console.log(mes.abrv)

      let textString = `${mes.abrv}${dateObj.getDate()}`
      return textString
    }

    displayErrors(err){
      if(err && err.error){
          for(let prop in err.error){
              this.toast.warning(err.error[prop])
          }
      }
    }

    addDaysToDate(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    restDaysToDate(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() - days);
      return result;
    }

    getFunctionalDate(date: Date){
      return date.toJSON().slice(0,10)
    }
}