import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'moneda'
})
export class MonedaPipe implements PipeTransform {
	
	public monedas : any[] = [
		{
			id: 1,
			descripcion: "Soles"
		},
		{
			id: 2,
			descripcion: "Dólares"
		}
	]
	
	transform(value: unknown, moneda): string {
		let retorno;
		moneda = Number.parseInt(moneda)
		if(moneda === 1 ){
			let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(value))
			retorno = `S/ ${number}`
		} else if (moneda === 2) {
			retorno = `$ ${Number(value).toLocaleString('en-US', {minimumFractionDigits: 2})}`
		} else {
			retorno = Number(value)
			retorno = isNaN(retorno) ? '' : `${retorno.toLocaleString('en-US', {minimumFractionDigits: 2})}`
		}
		return retorno;
	}
	
}
