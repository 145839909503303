import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommitteesRoutingModule } from './committees-routing.module';
import { CommitteeComponent } from './pages/committee/committee.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ArchwizardModule } from 'angular-archwizard';
import { SharedModule } from 'app/shared/shared.module';
import { JwBootstrapSwitchNg2Module } from 'jw-bootstrap-switch-ng2';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { UiSwitchModule } from 'ngx-ui-switch';
import { AddMembersModalComponent } from './modals/add-members-modal/add-members-modal.component';
import { RemoveMembersModalComponent } from './modals/remove-members-modal/remove-members-modal.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [CommitteeComponent, AddMembersModalComponent, RemoveMembersModalComponent],
  entryComponents: [
    AddMembersModalComponent,
    RemoveMembersModalComponent
  ],
  imports: [
    CommonModule,
    CommitteesRoutingModule,
    FontAwesomeModule,
    SharedModule,
    JwBootstrapSwitchNg2Module,
    NgbModule,
    NgSelectModule,
    NgxMaskModule.forRoot(maskConfig),
    FormsModule,
    ReactiveFormsModule,
    UiSwitchModule.forRoot({
        size              : 'medium',
        color             : '#3e50b4',
        checkedTextColor  : "#ffffff",
        uncheckedTextColor: "#ffffff",
        defaultBgColor    : '#6c757d',
        defaultBoColor    : '#3e50b4',
        checkedLabel      : 'Si ',
        uncheckedLabel    : 'No'
    }),
    ArchwizardModule
  ]
})
export class CommitteesModule { }
