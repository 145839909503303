import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { SharedFunctions } from 'app/core/shared/functions';

@Component({
  selector: 'app-add-formula',
  templateUrl: './add-formula.component.html',
  styleUrls: ['./add-formula.component.css']
})
export class AddFormulaComponent implements OnInit {
  faTimes = faTimes;
  myForm             : FormGroup;
  @Input() clienteId = 0;
  @Input() formulaItem: any = null;
  @Input() editable: any = null;
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();
  itemsGrupos: any[] = []
  itemsMonedas: any [] = [
    {
      id: 1,
      descripcion: 'Soles'
    },
    {
      id: 2,
      descripcion: 'Dólares'
    }
  ]
  constructor(
    public formbuild       : FormBuilder,
    public activeModal     : NgbActiveModal,
    private authService    : AuthService,
    private clientesService: ClientesService,
    public sharedFunctions  : SharedFunctions,
  	public configurationService  : ConfigurationService,
  ) {
   }

  ngOnInit(): void {
    this.initForm()
    this.getData()
  }

  getData(){
    let formulasGrupos = this.configurationService.obtenerFormulasGrupos()

    Promise.all([formulasGrupos]).then((res: any[])=>{
      this.itemsGrupos = res[0]['results']

      if(this.editable){
        this.setForm(this.formulaItem)
      }
    })

  }

  initForm() {
    this.myForm = this.formbuild.group({
      descripcion: ['',[Validators.required]],
      grupo      : [null,[Validators.required]],
      formula    : ['',[Validators.required]],
      moneda     : [null,[]],
      afecto_igv : [false,[]],
      estado     : [false,[]],
      codigo_sf     : [null,[Validators.required]],
    });

  }

  setForm(valores) {
    console.log(valores)
    for (const key in valores) {
      if (this.myForm.controls[key]) {
        this.myForm.get(key).setValue(valores[key]);
      }
    }
  }


  onSubmit(){

    let valores: any = this.mapDataToSave()
    if(!valores.afecto_igv){
      valores['afecto_igv'] = this.myForm.get('afecto_igv').value
    }

    if(!valores.estado){
      valores['estado'] = this.myForm.get('estado').value
    }

    if(this.editable){
      this.configurationService.actualizarFormulaMaestra(valores,this.formulaItem.id ).then((res : any)=>{
        this.successEmit.emit(true)
      })
    } else {
      this.configurationService.crearFormulaMaestra(valores).then((res: any)=>{
        this.successEmit.emit(true)
      })
    }

  } 

  mapDataToSave(){
    let valores = {}
    let controles = this.myForm.controls

    for (const key in controles) {
      if(this.myForm.get(key) && this.myForm.get(key).value){
        switch(key){
          default:
            valores[key] = this.myForm.get(key).value
          break
        }
      }
    }

    return valores
  }


}
