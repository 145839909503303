import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { ReportesService } from 'app/core/services/reportes.service';

@Component({
  selector: 'app-reportes-pagos',
  templateUrl: './reportes-pagos.component.html',
  styleUrls: ['./reportes-pagos.component.css']
})
export class ReportesPagosComponent implements OnInit {
	public fecha : any;
	public fecha_ : any;
	public moneda : string;
	public entidad : string;
	public ruc : string;
	public tipo : string;
	public tipocambio : string;
	public filtro : string;
	public cabecera : any;
	public excedentes : any[] = [];
	public documentosEnCartera : any[] = [];
	public documentosEnCarteraMora : any[] = [];
	public liquidaciones : any[] = [];
	public cuentasPorCobrar : any[] = [];
	public columnasDocumentosCartera : any[] = [];
	public columnasLiquidaciones : any[] = [];
	public columnasDocumentosCarteraMora : any[] = [];
	public columnasCuentasPorCobrar : any[] = [];
	public columnasExcedentes : any[] = [];
	public empresasFiltradas : any [] = [];
	public moraTablaFormato : any [] = [];
	public empresa: any;
	tipo_linea: string;


	constructor(
		public modalService   : NgbModal,
		public router         : Router,
		public localService   : LocalServiceService,
		private activatedRoute: ActivatedRoute,
		public authService    : AuthService,
		public reporteService : ReportesService,
	) {
		this.activatedRoute.params.subscribe( param => {
			param = this.localService.getJsonValue('reporteDeudaFiltros')
			
			this.moneda = param.moneda
			this.ruc = param.ruc
			this.tipo = param.tipo
			this.tipocambio = param.tipocambio
			this.empresa = param.empresa
			this.filtro = param.filtro == '' ? '' : param.filtro;
			this.tipo_linea = param.tipo_linea;
			if (param.tipo == 1){
				this.entidad = 'cliente'
			} else {
				this.entidad = 'aceptante'
			}
			this.initForms(param.moneda);
			this.getData();
		})
	}

	ngOnInit(): void {
	}

	initForms(moneda_reporte_external){
		this.columnasDocumentosCartera = [
			{
				headerName: 'Número Doc',
				field: 'operacion_numero_documento',
			},
			{
				headerName:  this.tipo == '2' ? 'Cliente' :'Aceptante' ,
				field: this.tipo == '2' ? 'beneficiario_nombre' :'aceptante_nombre',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName: 'Fecha Vcto',
				field: 'fecha_vencimiento',
				sortable: true,
				defaultInfo: 'Aún no aprobado',
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					if(!row.fecha_vencimiento){
						return ''
					}
					let hora_dia = 'AM';
					let fecha = row.fecha_vencimiento.split('-')
					let ano = fecha[0]
					let mes = fecha[1]
					let dia = fecha[2].split('T')[0]
					return `${dia}/${mes}/${ano}`
				},
			},
			{
				headerName:  'Estado' ,
				field: 'estado_descripcion',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName: 'Monto',
				field: 'comportamiento_la_ft_descripcion',
				sortable: true,
				pipe      : 'function',
				class: 'text-center',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.monto_neto_soles) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.monto_neto_soles))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.monto_neto_dolares) return ''
						retorno = `$ ${Number(row.monto_neto_dolares).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName: 'Saldo Financiado',
				field: 'exposicion',
				sortable: true,
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					let moneda = row.moneda_reporte
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.saldo_financiado_soles))
						retorno = `S/ ${number}`
					}
					else
						retorno = `$ ${Number(row.saldo_financiado_dolares).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno;
				},
			},
		];

		this.columnasLiquidaciones = [
			{
				headerName: "Número Doc.",
				field: "numero_documento"
			},
			{
				headerName: this.tipo == "2" ? "Clientes" : "Aceptantes",
				field: this.tipo == "2" ? "cliente" : "aceptante",
				sortable: true,
				class: "text-center",
			},
			{
				headerName: 'Fecha Desembolso',
				field: 'fecha_desembolso',
				sortable: true,
				defaultInfo: 'Aún no aprobado',
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					if(!row.fecha_desembolso){
						return ''
					}
					let fecha = row.fecha_desembolso.split('-')
					let ano = fecha[0]
					let mes = fecha[1]
					let dia = fecha[2]
					return `${dia}/${mes}/${ano}`
				},
			},
			{
				headerName: 'Fecha Vcto',
				field: 'fecha_vencimiento',
				sortable: true,
				defaultInfo: 'Aún no aprobado',
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					if(!row.fecha_vencimiento){
						return ''
					}
					let fecha = row.fecha_vencimiento.split('-')
					let ano = fecha[0]
					let mes = fecha[1]
					let dia = fecha[2]
					return `${dia}/${mes}/${ano}`
				},
			},
			{
				headerName:  'Estado' ,
				field: 'estado',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName: "Monto Adelanto",
				sortable: true,
				pipe: "function",
				class: "text-center",
				function: function(row){
					let moneda = row.moneda;
					let retorno;
					moneda = Number.parseFloat(moneda);
					if(!row.monto_adelanto) return ""
					if(moneda == 1){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row?.monto_adelanto || 0))
						retorno = `S/  ${number}`
					}else{
						retorno = `$ ${Number(row?.monto_adelanto).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
					return retorno;
				}
			},
			{
				headerName: "Desembolso Pen.",
				sortable: true,
				pipe: "function",
				class: "text-center",
				function: function(row){
					let moneda = row.moneda;
					let retorno;
					moneda = Number.parseFloat(moneda);
					if(!row.pendiente_desembolso) return ""
					if(moneda == 1){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row?.pendiente_desembolso || 0))
						retorno = `S/  ${number}`
					}else{
						retorno = `$ ${Number(row?.pendiente_desembolso).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
					return retorno;
				}
			},
			{
				headerName: "Total",
				sortable: true,
				pipe: "function",
				class: "text-center",
				function: function(row){
					let moneda = moneda_reporte_external; // row.moneda_reporte
					let retorno = "";
					moneda = Number.parseInt(moneda)
					if(moneda === 1){
						if(!row.monto_total_soles) return ""
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row?.monto_total_soles))
						retorno = `S/  ${number}`
					}else{
						if(!row.monto_total_dolares) return ""
						retorno = `$ ${Number(row?.monto_total_dolares).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
					return retorno;
				}
			},
		]

		this.columnasDocumentosCarteraMora = [
			{
				headerName: 'Número Doc',
				field: 'operacion_numero_documento',
			},
			{
				headerName:  this.tipo == '2' ? 'Cliente' :'Aceptante' ,
				field: this.tipo == '2' ? 'beneficiario_nombre' :'aceptante_nombre',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName:  'Estado' ,
				field: 'estado_descripcion',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName: 'Prórroga',
				field: 'dias_prorroga',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName: '1 a 8 Días',
				field: '1_8_dias',
				sortable: true,
				pipe      : 'function',
				class: 'text-center',
				function : function(row){
					let moneda = row.moneda_reporte
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row['1_8_dias']))
						retorno = `S/ ${number}`
					}
					else
						retorno = `$ ${Number(row['1_8_dias']).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno;
				},
			},
			{
				headerName: '9 a 15 Días',
				field: '9_15_dias',
				sortable: true,
				pipe      : 'function',
				class: 'text-center',
				function : function(row){
					let moneda = row.moneda_reporte
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row['9_15_dias']))
						retorno = `S/ ${number}`
					}
					else
						retorno = `$ ${Number(row['9_15_dias']).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno;
				},
			},
			{
				headerName: '16 a 30 Días',
				field: '16_30_dias',
				sortable: true,
				pipe      : 'function',
				class: 'text-center',
				function : function(row){

					let moneda = row.moneda_reporte
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row['16_30_dias']))
						retorno = `S/ ${number}`
					}
					else
						retorno = `$ ${Number(row['16_30_dias']).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno;
				},
			},
			{
				headerName: '31 a 60 Días',
				field: '31_60_dias',
				sortable: true,
				pipe      : 'function',
				class: 'text-center',
				function : function(row){
					let moneda = row.moneda_reporte
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row['31_60_dias']))
						retorno = `S/ ${number}`
					}
					else
						retorno = `$ ${Number(row['31_60_dias']).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno;
				},
			},
			{
				headerName: 'más de 60 Días',
				field: 'mas_60_dias',
				sortable: true,
				pipe      : 'function',
				class: 'text-center',
				function : function(row){
					let moneda = row.moneda_reporte
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row['mas_60_dias']))
						retorno = `S/ ${number}`
					}
					else
						retorno = `$ ${Number(row['mas_60_dias']).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno;
				},
			}
		];

		this.columnasCuentasPorCobrar = [
			{
				headerName: 'Número Doc',
				field: 'id',
			},
			{
				headerName: 'Concepto',
				field: 'concepto_descripcion',
				sortable: true,
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					if(row.id ==='Totales'){
						return ''
					}
					return `${!!row.concepto_descripcion?row.concepto_descripcion:''}: ${!!row.tipo_proceso_descripcion ? row.tipo_proceso_descripcion : ''} ${row.numero_proceso ? row.numero_proceso : ''}`
				},
			},
			{
				headerName: 'Fech. Emisión',
				field: 'fecha_creacion',
				sortable: true,
				defaultInfo: 'Aún no aprobado',
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					if(!row.fecha_creacion){
						return ''
					}
					let hora_dia = 'AM';
					let fecha = row.fecha_creacion.split('-')
					let ano = fecha[0]
					let mes = fecha[1]
					let dia = fecha[2].split('T')[0]

					let _hora = fecha[2].split('T')
					let hora = _hora[1].split(':')[0]

					if(hora>12){
						hora-= 12
						hora_dia = 'PM';
					} else {
						hora_dia = 'AM';
					}

					let minutos = _hora[1].split(':')[1]
					return `${dia}/${mes}/${ano}`
				},
			},
			{
				headerName:  'Estado' ,
				field: 'estado_descripcion',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName: 'Monto',
				field: 'comportamiento_la_ft_descripcion',
				sortable: true,
				pipe      : 'function',
				class: 'text-center',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.monto_soles) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.total))
						retorno = `S/ ${number}`
					} else{
						if(!row.monto_dolares) return ''
						retorno = `$ ${Number(row.total).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
					return retorno;
				},
			},
			{
				headerName: 'Saldo',
				field: 'exposicion',
				sortable: true,
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					let moneda = row.moneda_reporte
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.saldo_soles))
						retorno = `S/ ${number}`
					}
					else
						retorno = `$ ${Number(row.saldo_dolares).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno;
				},
			},
		];

		this.columnasExcedentes = [
			{
				headerName: 'Número Doc',
				field: 'id',
			},
			{
				headerName: 'Concepto',
				field: 'tipo_documento_descripcion',
				sortable: true,
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					if(row.recaudacion === 'Totales'){
						return ''
					}
					return `${!!row.tipo_documento_descripcion ? row.tipo_documento_descripcion : ''}: ${!!row.numero_documento ? row.numero_documento : ''} - Aplicación ${row.recaudacion}`
				},
			},
			{
				headerName: 'Fech. Emisión',
				field: 'fecha',
				sortable: true,
				defaultInfo: 'Aún no aprobado',
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					if(row.fecha){
						let hora_dia = 'AM';
						let fecha = row.fecha.split('-')
						let ano = fecha[0]
						let mes = fecha[1]
						let dia = fecha[2].split('T')[0]
						return `${dia}/${mes}/${ano}`
					} else {
						return ''
					}
				},
			},
			{
				headerName:  'Estado' ,
				field: 'estado_descripcion',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName: 'Monto',
				field: 'comportamiento_la_ft_descripcion',
				sortable: true,
				pipe      : 'function',
				class: 'text-center',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.monto_soles) return ''

						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.excedente))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.monto_dolares) return ''
						retorno = `$ ${Number(row.excedente).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
					return retorno;

				},
			},
			{
				headerName: 'Saldo',
				field: 'exposicion',
				sortable: true,
				class: 'text-center',
				pipe      : 'function',
				function : function(row){

					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.saldo_soles))
						retorno = `S/ ${number}`
					}
					else
						retorno = `$ ${Number(row.saldo_dolares).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno;
				},
			},
		];
	}

	diferenciaDias(fecha1: Date, fecha2 :Date){
		let tiempo = fecha2.getTime() - fecha1.getTime()
		return Math.floor(tiempo / (1000 * 3600 * 24));
	}

	totalesAdelantos(){
		this.liquidaciones;
		let adelantosTotales = {
			numero_documento: "Totales",
			moneda: this.moneda,
			monto_adelanto: 0,
			pendiente_desembolso: null,
			monto_total_soles: 0,
			monto_total_dolares: 0,
		}

		let totales = this.liquidaciones.reduce((acumulador, item) => {
			acumulador.moneda = item.moneda,
			acumulador.monto_adelanto += Number.parseFloat(item.monto_adelanto)
			acumulador.monto_total_soles += Number.parseFloat(item.monto_total_soles)
			acumulador.monto_total_dolares += Number.parseFloat(item.monto_total_dolares)
			return acumulador;
		}, adelantosTotales)

		this.liquidaciones.push(totales);
	}

	totalesDocsCartera(){
		let totalesDocumentosCartera = {
			'operacion_numero_documento': 'Totales',
			'aceptante_nombre' : '',
			'fecha_vencimiento' : null,
			'estado_descripcion' : '',
			'dias_prorroga' : '',
			'monto_neto_soles' : null,
			'moneda' : this.moneda,
			'moneda_reporte' : 0,
			'monto_neto_dolares' : null,
			'saldo_soles' : 0,
			'saldo_dolares' : 0,
			'saldo_financiado_soles': 0,
			'saldo_financiado_dolares' : 0,
			'1_8_dias' : 0,
			'9_15_dias' : 0,
			'16_30_dias' : 0,
			'31_60_dias' : 0,
			'mas_60_dias' : 0,
		}

		let totales = this.documentosEnCartera.reduce((acumulador,item)=>{

			acumulador['moneda']         = item['moneda']
			acumulador['moneda_reporte'] = item['moneda_reporte']
			acumulador['saldo_soles']    += Number.parseFloat(item['saldo_soles'])
			acumulador['saldo_dolares']  += Number.parseFloat(item['saldo_dolares'])
			acumulador['saldo_financiado_soles']    += Number.parseFloat(item['saldo_financiado_soles'])
			acumulador['saldo_financiado_dolares']  += Number.parseFloat(item['saldo_financiado_dolares'])
			acumulador['1_8_dias']       += Number.parseFloat(item['1_8_dias'])
			acumulador['9_15_dias']      += Number.parseFloat(item['9_15_dias'])
			acumulador['16_30_dias']     += Number.parseFloat(item['16_30_dias'])
			acumulador['31_60_dias']     += Number.parseFloat(item['31_60_dias'])
			acumulador['mas_60_dias']    += Number.parseFloat(item['mas_60_dias'])

			return acumulador
		},totalesDocumentosCartera);

		this.documentosEnCartera = this.documentosEnCartera.map((item)=>{
			item.moneda_reporte = this.moneda
			return item
		})
		this.documentosEnCarteraMora = this.documentosEnCarteraMora.map((item)=>{
			item.moneda_reporte = this.moneda
			return item
		})
		this.documentosEnCartera.push(totales)
		this.documentosEnCarteraMora.push(totales)
	}

	totalesExcedentes(){
		this.excedentes;
		let totalesExcedentes =		{
			recaudacion               : 'Totales',
			tipo_documento_descripcion: '',
			fecha                     : null,
			moneda                    : this.moneda,
			moneda_reporte            : 0,
			monto_soles               : null,
			monto_dolares             : null,
			saldo_soles               : 0,
			saldo_dolares             : 0,
		}

		let totales = this.excedentes.reduce((acumulador,item) =>{
			acumulador.moneda         = item.moneda
			acumulador.moneda_reporte = item.moneda_reporte
			acumulador.saldo_soles    += Number.parseFloat(item.saldo_soles)
			acumulador.saldo_dolares  += Number.parseFloat(item.saldo_dolares)
			return acumulador
		}, totalesExcedentes )

		this.excedentes.push(totales)
	}

	totalesCxC(){
		let totalesCuentasCobrar =		{
			id                  : 'Totales',
			concepto_descripcion: '',
			fecha_creacion      : null,
			monto_soles         : null,
			moneda              : this.moneda,
			moneda_reporte      : 0,
			monto_dolares       : null,
			saldo_soles         : 0,
			saldo_dolares       : 0,
		}

		let totales = this.cuentasPorCobrar.reduce((acumulador,item)=>{
			acumulador.moneda         = item.moneda_reporte
			acumulador.moneda_reporte = item.moneda_reporte
			acumulador.saldo_soles    += Number.parseFloat(item.saldo_soles)
			acumulador.saldo_dolares  += Number.parseFloat(item.saldo_dolares)
			return acumulador
		},totalesCuentasCobrar)

		this.cuentasPorCobrar = this.cuentasPorCobrar.map((item)=>{
			item.moneda_reporte = this.moneda
			return item
		})
		this.cuentasPorCobrar.push(totales);
	}

	aplicandoAdelantosALinea(){
		let tAdelantos = {
			ocupado: 0,
			vigente: 0,
			mora: 0,
		}
		 
		let ttotales = this.liquidaciones.reduce((acumulador, item) => {
			let t_monto_total = parseInt(this.moneda) == 1 ? parseFloat(item.monto_total_soles) : parseFloat(item.monto_total_dolares);
			acumulador.ocupado += t_monto_total;
			if(item.estado == "Vigente"){
				acumulador.vigente += t_monto_total;
			}else{
				acumulador.mora += t_monto_total;
			}
			return acumulador
		}, tAdelantos)
		
		this.cabecera = {
			...this.cabecera,
			ocupado: this.cabecera.ocupado + ttotales.ocupado,
			disponible: this.cabecera.disponible - ttotales.ocupado,
			vigente: this.cabecera.vigente + ttotales.vigente,
			mora: this.cabecera.mora + ttotales.mora,
		}
	}

	getData(){
		let cabecera;
		let documentosCartera;
		let liquidaciones;
		let excedentes;
		let cuentasPorCobrar;
		if(this.entidad == 'cliente'){
			cabecera = this.reporteService.obtenerCabecera(
					this.entidad,
					this.moneda,
					this.tipocambio,
					this.ruc,
					this.filtro,
					'',
					this.empresa,
					this.tipo_linea
				);
			documentosCartera = this.reporteService.obtenerDocumentosEnCarteraReporte(
					this.ruc, //Cliente_linea
					this.filtro, //Deudor/Aceptante Filtro
					'1,3', //Estado
					this.empresa, //Empresa
					this.tipo_linea
				);
			liquidaciones = this.reporteService.obtenerLiquidacionesReporte({
					cliente_codigo: this.ruc
				});
			excedentes = this.reporteService.obtenerExcedentesReporte(
					this.ruc,
					this.filtro,
					this.empresa,
					this.tipo_linea
				);
			cuentasPorCobrar  = this.reporteService.obtenerCuentasPorCobrarReporte(
					this.ruc,
					this.filtro,
					this.empresa,
					this.tipo_linea,
					'1'
				);
		} else {
			cabecera = this.reporteService.obtenerCabecera(
					this.entidad,
					this.moneda,
					this.tipocambio,
					this.ruc,
					'',
					this.filtro,
					this.empresa,
					this.tipo_linea,
				);
			documentosCartera = this.reporteService.obtenerDocumentosEnCarteraReporte(
					this.filtro,
					this.ruc,
					'1,3',
					this.empresa,
					this.tipo_linea
				);
			liquidaciones = this.reporteService.obtenerLiquidacionesReporte({
					aceptante_codigo: this.ruc
				});
			excedentes        = this.reporteService.obtenerExcedentesReporte(
					this.filtro,
					this.ruc,
					this.empresa,
					this.tipo_linea
				);
			cuentasPorCobrar  = this.reporteService.obtenerCuentasPorCobrarReporte(
					this.filtro,
					this.ruc,
					this.empresa,
					this.tipo_linea,
					'1'
				);
		}

		Promise.all([
			cabecera,
			documentosCartera,
			excedentes,
			cuentasPorCobrar,
			liquidaciones
		]).then(( res : any[] ) => {
			this.cabecera = res[0]
			for (const key in this.cabecera.mora_dias) {
				this.moraTablaFormato.push(key)
			}
			this.cabecera.fecha = `${this.cabecera.fecha.split('-')[2]}/${this.cabecera.fecha.split('-')[1]}/${this.cabecera.fecha.split('-')[0]}`
			this.cabecera.fecha_vencimiento = this.cabecera.fecha_vencimiento.split('-').length != 3 ? this.cabecera.fecha_vencimiento : `${this.cabecera.fecha_vencimiento.split('-')[2]}/${this.cabecera.fecha_vencimiento.split('-')[1]}/${this.cabecera.fecha_vencimiento.split('-')[0]}`
			this.cabecera.fecha_max_expo = this.cabecera.fecha_max_expo === "" ? "" : `${this.cabecera.fecha_max_expo.split('-')[2]}/${this.cabecera.fecha_max_expo.split('-')[1]}/${this.cabecera.fecha_max_expo.split('-')[0]}`

			this.cargaDocumentosCartera(res[1].results)

			if(this.cabecera.filtro_entidad.lista.length === 0){
				this.cabecera.filtro_entidad.lista = this.empresasFiltradas
			}

			this.excedentes = res[2].results.map( item =>{
				item.moneda_reporte = this.moneda
				return item
			})

			this.cuentasPorCobrar = res[3].results.map( item =>{
				item.moneda_reporte = this.moneda
				return item
			})

			this.liquidaciones = res[4];
			
			this.aplicandoAdelantosALinea();

			this.totalesAdelantos();
			this.totalesExcedentes();
			this.totalesCxC();
			this.totalesDocsCartera();
		})
		.catch((err)=>{
			if(!!err && !!err.error && !!err.error.detail){
				console.warn(err.error.detail)
			} else {
				console.log(`Error no captado en reporte de pagos`)
				console.log(err)
			}
		})
		.finally(()=>{
			setTimeout(()=>{
				window.print()
			}, 3000)
		})
	}

	cargaDocumentosCartera(docs: any[]){
		this.documentosEnCartera = docs.map(item =>{
			item.moneda_reporte    = this.moneda
			let fecha      = item.fecha_vencimiento.split('-')
			let ahora      = new Date()
			let fecha_date = new Date(fecha[0],(fecha[1]-1),fecha[2])
			let mora       = this.diferenciaDias(fecha_date,ahora)

			this.moraTablaFormato.forEach((diasmora)=>{
				let intervalo = diasmora.split('_')
				let inicio    = parseInt(intervalo[0])
				let fin       = parseInt(intervalo[1])

				if(inicio){
					if((mora >= inicio) && (mora<= fin)){
						item[diasmora] = item.moneda_reporte == 1 ? item.saldo_soles : item.saldo_dolares
					} else {
						item[diasmora] = 0
					}
				} else {
					if(mora>= fin){
						item[diasmora] = item.moneda_reporte == 1 ? item.saldo_soles : item.saldo_dolares
					} else {
						item[diasmora] = 0
					}
				}
			})
			return item
		})

		this.empresasFiltradas = this.documentosEnCartera.reduce((acc,item)=>{
			let existe;
			if(this.tipo == '1'){
				existe = acc.filter((empresa) => empresa.aceptante_ruc == item.aceptante_ruc)
			} else {
				existe = acc.filter((empresa) => empresa.beneficiario_ruc == item.beneficiario_ruc)
			}

			if(!(existe.length > 0)){
				acc.push(item)
			}
			return acc
		},[])

		this.documentosEnCarteraMora = this.documentosEnCartera.filter( item => item.estado == 3)
	}
}
