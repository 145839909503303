import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from 'app/core/services/toast/toast.service';

@Component({
  selector: 'app-formato-planilla-descuento',
  templateUrl: './formato-planilla-descuento.component.html',
  styleUrls: ['./formato-planilla-descuento.component.css']
})
export class FormatoPlanillaDescuentoComponent implements OnInit {
  
  myForm: FormGroup;
  estaciones: any[];


  @Input() beneficiario: number;
  @Input() operacion;
  @Input() formato_plantilla_url;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private formatoService: FormatosService,
    private clientesService : ClientesService,
    private factoringService: FactoringService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  async createForm() {
    const clienteId = (this.operacion.operacion.tipo_linea_solicitada == 1) ? this.operacion.beneficiario : this.operacion.operacion.beneficiario_factoring;

    this.myForm = this.formBuilder.group({
      fecha_actual: [{ value: new Date(), disabled: true }, Validators.required],

      nombre: [null, Validators.required],
      domicilio: [null, Validators.required],
      oficial_negocio: [null, Validators.required],
      ruc: [null, Validators.required],
      telefono: [null, Validators.required],
      email: [null, Validators.required],
      contacto: [null, Validators.required],
      banco: [null, Validators.required],
      numero_cuenta: [null, Validators.required],
      facturas: [null, Validators.required],
      letras: [null, Validators.required],
      soles: [null, Validators.required],
      dolares: [null, Validators.required],
      descuento_letras: [null, Validators.required],
      descuento_facturas: [null, Validators.required],
      deudores: [null, Validators.required],
    });

    const cliente = await this.clientesService.obtenerCliente(clienteId)
    const operacionBandeja = await this.factoringService.obtenerOperacionBandeja(this.operacion.id)

    const deudores_id = []
    let facturas = false
    let letras = false
    let soles = false
    let dolares = false
    let descuento_letras = false
    let descuento_facturas = false

    this.operacion.detalle.forEach(detalle=>{
      if (!deudores_id.includes(detalle.deudor)) {
        deudores_id.push(detalle.deudor)
      }
      if (detalle.tipo_detalle == 1) {
        facturas = true
        descuento_facturas = true
      }
      if (detalle.tipo_detalle == 2) {
        letras = true
        descuento_letras = true
      }
      if (detalle.moneda == 1) {
        soles = true
      } else if (detalle.moneda == 2) {
        dolares = true
      }
    })
    console.log(cliente)
    this.myForm.controls.nombre.setValue(cliente['nombre'])
    this.myForm.controls.domicilio.setValue(cliente['domicilio_legal'])
    this.myForm.controls.oficial_negocio.setValue(operacionBandeja['oficial_negocio_nombre'])
    this.myForm.controls.ruc.setValue(cliente['ruc'])
    this.myForm.controls.telefono.setValue(cliente['telefono_contacto'])
    this.myForm.controls.email.setValue(cliente['email'])
    this.myForm.controls.contacto.setValue(this.operacion['contacto_nombre'])
    this.myForm.controls.banco.setValue(this.operacion['banco_abono_nombre'])
    this.myForm.controls.numero_cuenta.setValue(this.operacion['nro_cuenta'])
    
    this.myForm.controls.facturas.setValue(facturas)
    this.myForm.controls.letras.setValue(letras)
    this.myForm.controls.soles.setValue(soles)
    this.myForm.controls.dolares.setValue(dolares)
    this.myForm.controls.descuento_letras.setValue(descuento_letras)
    this.myForm.controls.descuento_facturas.setValue(descuento_facturas)
    
    this.myForm.controls.deudores.setValue(this.operacion.detalle)
  }

  onSubmit() {
    console.log(this.myForm);

    if (!this.myForm.invalid) {
      var model = {
        "archivo_plantilla": this.formato_plantilla_url,
        "fecha_actual": this.myForm.get("fecha_actual").value,
        "nombre": this.myForm.get("nombre").value,
        "domicilio": this.myForm.get("domicilio").value,
        "oficial_negocio": this.myForm.get("oficial_negocio").value,
        "ruc": this.myForm.get("ruc").value,
        "telefono": this.myForm.get("telefono").value,
        "email": this.myForm.get("email").value,
        "contacto": this.myForm.get("contacto").value,
        "banco": this.myForm.get("banco").value,
        "numero_cuenta": this.myForm.get("numero_cuenta").value,
        "facturas": this.myForm.get("facturas").value,
        "letras": this.myForm.get("letras").value,
        "soles": this.myForm.get("soles").value,
        "dolares": this.myForm.get("dolares").value,
        "descuento_letras": this.myForm.get("descuento_letras").value,
        "descuento_facturas": this.myForm.get("descuento_facturas").value,
        "deudores": null,
      };
      let deudores = this.myForm.get("deudores").value;

      const generarFormatoPlanillaDescuentoAux = async () => {
        this.spinner.show();
        const deudoresGroupBy12 = () => {
          let deudoresBy12 = [];
          while (deudores.length > 12) {
            let aux = deudores.slice(0, 12);
            deudores = deudores.slice(12);
            deudoresBy12.push(aux);
          }

          if (deudores.length < 12 && deudores.length > 0) {
            deudoresBy12.push(deudores);
          }
          return deudoresBy12;
        };
        const deudoresBy12 = deudoresGroupBy12();

        deudoresBy12.map(async (deudores12, idx) => {
          model.deudores = deudores12;
          let req = this.formatoService.generarFormatoPlanillaDescuento(model).then((x: any) => {
            var blob = new Blob([x], { type: `application/pdf` });
            // var blob = new Blob([x], { type: `application/${this.myForm.get("deudores").value.length<=12?'pdf':'zip'}` });
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement("a");
            link.href = downloadURL;
            link.download = `PlanillaInstruccion${idx}.` + `pdf`;
            link.click();
            this.activeModal.close(model);
          }, (reason) => {
            this.activeModal.close(model);
          });
          await req;
          this.spinner.hide();
        });
      };

      generarFormatoPlanillaDescuentoAux();

      // this.formatoService.generarFormatoPlanillaDescuento(model).then(
      //   (x: any) => {
      //     var blob = new Blob([x], { type: `application/${model.deudores.length<=12?'pdf':'zip'}` });
      //     // var blob = new Blob([x], { type: `application/${this.myForm.get("deudores").value.length<=12?'pdf':'zip'}` });
      //     var downloadURL = window.URL.createObjectURL(blob);
      //     var link = document.createElement('a');
      //     link.href = downloadURL;
      //     link.download = "PlanillaDescuento." + `${this.myForm.get("deudores").value.length<=12?'pdf':'zip'}`;
      //     link.click();
      //     this.activeModal.close(model);
      //   }, (reason) => {
      //     this.activeModal.close(model);
      //   });
    } else {
      this.toast.warning("El formulario no está completo.");
    }
  }

}
