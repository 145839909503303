import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { FacturacionService } from 'app/core/services/facturacion/facturacion.service';
import { AddRecaudacionModalComponent } from 'app/modules/recaudacion/modals/add-recaudacion-modal/add-recaudacion-modal.component';
import { concat, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap, map, filter, startWith, pairwise } from 'rxjs/operators';

@Component({
	selector: 'app-modal-cuentas-cobrar',
	templateUrl: './modal-cuentas-cobrar.component.html',
	styleUrls: ['./modal-cuentas-cobrar.component.css']
})
export class ModalCuentasCobrarComponent implements OnInit {
	faTimes = faTimes;
	@ViewChild('modalCuentaCobrar', { static: false }) public modalCuentaCobrar;
	active                       = 1;
	public columnasRecaudacion   : any[];
	public formularioCuentaCobrar: FormGroup;
	public cuenta                : any;
	public bandejaRecaudos       : any[];
	filasComentarios             : any[] = [];
	@Input() readOnly  		        = false;
	@Output() nuevoComent        = new EventEmitter<any>();
	@Output() editaComent        = new EventEmitter<any>();
	@Output() eliminaComent      = new EventEmitter<any>();
	@Output() cambios            = new EventEmitter<any>();
	@Output() guardar            = new EventEmitter<any>();
	beneficiarios$        : Observable<any[]>;
	deudores$             : Observable<any[]>;
	searchBeneficiario$   = new Subject<string>();
	searchDeudor$         = new Subject<string>();
	loadingSearch         : boolean = false;
	public conceptos      : any[];
	public estados        : any[];
	public nuevo          : boolean = false;
	public isBeneficiario = false;
	public tiposPagador   : any[];
	public monedas        : any[];
	public tiposCambio    : any[]   = [];
	public detalles		  : any[] = [];
	public lineas         : any[];


	constructor(
		public formbuild    : FormBuilder,
		public factoring    : FactoringService,
		public cuentasCobrarService : CuentasService,
		public modalService: NgbModal,
		private authService: AuthService,
		private documentosCarteraService: DocumentosCarteraService,
		private facturacionService: FacturacionService,
		private configs         		: DocschecklistService,

	) { }
	
	ngOnInit(): void {

	}
	
	cargarTiposCambio(cambios){
		if(cambios){
			this.tiposCambio.push({
				descripcion: 'Contable',
				valor: cambios.contable
			})

			this.tiposCambio.push({
				descripcion: 'Compra',
				valor: cambios.compra
			})

			this.tiposCambio.push({
				descripcion: 'Venta',
				valor: cambios.venta
			})
		} else {
			this.tiposCambio.push({
				descripcion: 'Contable',
				valor: 1
			})

			this.tiposCambio.push({
				descripcion: 'Compra',
				valor: 1.1
			})

			this.tiposCambio.push({
				descripcion: 'Venta',
				valor: 0.9
			})
		}	

		this.formularioCuentaCobrar.get('tipo_cambio_monto').setValue(this.tiposCambio[0].valor)
	}

	initForm({
		deudor_nombre         = null,
		deudor_ruc            = null,
		estado_descripcion    = null,
		concepto_descripcion  = null,
		fecha_creacion        = null,
		id                    = null,
		monto                 = null,
		igv                   = 0,
		totalxcobrar          = null,
		saldo                 = null,
		liquidacion_fecha	    = null,
		liquidacion	          = null,
		liquidacion_operacion = null,
		concepto              = null,
		tipo_producto_descripcion                 = '',
		estado                = null,
		deudor                = null,
		tipo_pagador          = 1,
		moneda                = 1,
		cobrar_a              = null,
		beneficiario              = null,
		cobrar_a_ruc          = null,
		tipo_proceso = null,
		tipo_proceso_descripcion = '',
		numero_proceso = '',
		operacion = '',
		operacion_detalle = null,
		glosa = null,
	}){
		let tmp_cobrar_a = deudor_nombre;
		let tmp_cobrar_ruc = deudor_ruc;

		if(tipo_proceso === 3 || tipo_proceso === 4 || tipo_proceso === 2){
			tmp_cobrar_a = cobrar_a
			tmp_cobrar_ruc = cobrar_a_ruc
		} else {
			tmp_cobrar_a = cobrar_a
			tmp_cobrar_ruc = cobrar_a_ruc
		}

		this.formularioCuentaCobrar = this.formbuild.group({
			deudor_nombre        : [tmp_cobrar_a],
			deudor_ruc           : [tmp_cobrar_ruc],
			estado_descripcion   : [estado_descripcion ? estado_descripcion                 : null],
			concepto_descripcion : [concepto_descripcion ? concepto_descripcion             : null],
			tipo_producto_descripcion : [tipo_proceso_descripcion ? tipo_producto_descripcion : null],
			fecha_creacion       : [(fecha_creacion) ? this.dateFormater(fecha_creacion)    : new Date()],
			monto                : [monto ? monto                                           : null],
			igv                  : [igv ? igv                                               : null],
			totalxcobrar         : [totalxcobrar ? totalxcobrar                             : null],
			saldo                : [saldo ? saldo                                           : null],
			liquidacion_fecha    : [liquidacion_fecha ? this.dateFormater(liquidacion_fecha): null],
			estado               : [estado ? estado                                         : null],
			concepto             : [concepto ? concepto                                     : null],
			deudor               : [deudor ? deudor                                         : null],
			beneficiario         : [beneficiario ? beneficiario                             : null],
			tipo_pagador         : [tipo_pagador],
			liquidacion_operacion: [liquidacion_operacion ? liquidacion_operacion           : null],
			moneda               : [moneda ? moneda                                         : 1],
			tipo_proceso_descripcion: [tipo_proceso_descripcion ? tipo_proceso_descripcion: null],
			tipo_proceso: [tipo_proceso ? tipo_proceso: null],
			numero_proceso: [numero_proceso ? numero_proceso: '', [Validators.required]],
			tipo_cambio_monto: [],
			operacion_detalle:[operacion_detalle?operacion_detalle:null],
			glosa: [glosa?glosa:null, [Validators.maxLength(500)]],
		});

		let tiposDeCambio = this.configs.obtenerTiposDeCambio(this.formatDate(new Date()));
		Promise.all([tiposDeCambio]).then((res: any)=>{
			this.cargarTiposCambio(res[0].results[0])
		})

		this.formularioCuentaCobrar.get('tipo_pagador').valueChanges.subscribe((value)=>{
			if(!(tipo_proceso === 3 || tipo_proceso === 4)){
				if(value === 1){
					this.isBeneficiario = false;
					this.formularioCuentaCobrar.get('deudor_ruc').setValue('')
				} else {
					this.isBeneficiario = true;
					this.formularioCuentaCobrar.get('deudor_ruc').setValue('')
				}
			}
		})

		this.formularioCuentaCobrar.get('numero_proceso')
		.valueChanges
		.pipe(startWith(null), pairwise())
		.pipe(debounceTime(800),distinctUntilChanged())
		.subscribe(([ovalue, value])=>{
			
			if (value == '' || value == null || value == undefined) {
				this.detalles = []
				this.formularioCuentaCobrar.controls['operacion_detalle'].patchValue(null)
				return
			}
			if (value !== ovalue) {
				this.factoring.obtenerDetalles(value, 100).then(res => {
					this.formularioCuentaCobrar.controls['operacion_detalle'].patchValue(null)
					this.detalles = res['results']
				}).catch(() => console.log(''))
			}
		})

		this.columnasRecaudacion = [
			{
				headerName: 'N° aplicación', 
				field     : 'recaudacion' ,
				class     : 'text-center',
				sortable  : true,
				textField: 'recaudacion',
				pipe: 'actionable',
				actionableType: 'recaudacion',
			}, 
			{
				headerName: 'Fecha Pago', 
				field     : 'fecha_pago' ,
				sortable  : true,
				class     : 'text-center',
				pipe: 'date'
			},  	
			{
				headerName: 'Monto Pago', 
				field     : 'monto',
				sortable  : true,
				class     : 'text-center',
				pipe      : 'function',
				function : function(row,header){
					if(row.moneda_documento == 1){
						let number = new Intl.NumberFormat("en-IN",{minimumFractionDigits: 2}).format(Number(row.monto))
						return `S/ ${number}`
					}
					else{
						return `$ ${Number(row.monto).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
				}				
			}, 				
			{
				headerName: 'Pago', 
				field     : 'pago' ,
				sortable  : true,
				class     : 'text-center',
				pipe      : 'function',
				function : function(row,header){
					if(row.moneda_documento == 1){
						let number = new Intl.NumberFormat("en-IN",{minimumFractionDigits: 2}).format(Number(row.pago))
						return `S/ ${number}`
					}
					else{
						return `$ ${Number(row.pago).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
				}					
			}, 
			{
				headerName: 'Estado aplicación',
				field: 'recaudacion_estado_descripcion',
				sortable: true,
				class: 'text-center',
			},
			{
				headerName: 'Tipo Pagador', 
				field     : 'tipo_pagador_descripcion' ,
				sortable  : true,
				class     : 'text-center',
			}, 		
			{
				headerName: 'Medio Pago', 
				field     : 'medio_pago_descripcion' ,
				sortable  : true,
				class     : 'text-center',
			}, 		
			{
				headerName: 'Nro Pago', 
				field     : 'numero_pago' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Fecha Pago Anticipado de Documento',
				field: 'fecha_pago_anticipado',
				sortable: true,
				class: 'text-center',
				pipe: 'date'
			},
			{
				headerName: 'Monto original cxc',
				field: 'monto_original_cxc',
				class: 'text-center',
				pipe: 'function',
				function : function(row){
					if (row.moneda_cxc == 1 && row.monto_original_cxc){
						let number = new Intl.NumberFormat("en-IN",{minimumFractionDigits: 2}).format(Number(row.monto_original_cxc))
						return `S/ ${number}`
					}
					else if (row.monto_original_cxc){
						return `$ ${Number(row.monto_original_cxc).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
				}
			},
			{
				headerName: 'Nota de crédito',
				field: 'nota_credito',
				class: 'text-center',
			},
			{
				headerName: 'Total NC',
				field: 'total_nc',
				class: 'text-center',
				pipe: 'function',
				function : function(row){
					if (row.moneda_nc == 1 && row.total_nc) {
						let number = new Intl.NumberFormat("en-IN",{minimumFractionDigits: 2}).format(Number(row.total_nc))
						return `S/ ${number}`
					}
					else if (row.total_nc) {
						return `$ ${Number(row.total_nc).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
				}

			}
		]
		
		this.toggleForm(liquidacion)
		this.formularioCuentaCobrar.get('deudor_ruc').disable()

		switch(tipo_proceso){
			case 4:
				this.formularioCuentaCobrar.disable()
			break;
			case 3:
				this.formularioCuentaCobrar.disable()
			break;			
			case 1:
				this.formularioCuentaCobrar.get('estado').disable()
			break;
		}

		this.displayModal()
		this.loadSearchBeneficiarios()
		this.loadSearchDeudores()
	}

	toggleForm(liquidacion = null){
		this.formularioCuentaCobrar.get('tipo_proceso_descripcion').disable()
		// this.formularioCuentaCobrar.get('numero_proceso').disable()
		if(liquidacion && (!this.nuevo)){
			this.formularioCuentaCobrar.get('deudor_nombre').disable()
			this.formularioCuentaCobrar.get('deudor_ruc').disable()
			this.formularioCuentaCobrar.get('estado_descripcion').disable()
			this.formularioCuentaCobrar.get('concepto_descripcion').disable()
			this.formularioCuentaCobrar.get('fecha_creacion').disable()
			this.formularioCuentaCobrar.get('monto').disable()
			this.formularioCuentaCobrar.get('igv').disable()
			this.formularioCuentaCobrar.get('totalxcobrar').disable()
			this.formularioCuentaCobrar.get('saldo').disable()
			this.formularioCuentaCobrar.get('liquidacion_fecha').disable()		
			this.formularioCuentaCobrar.get('liquidacion_operacion').disable()		
			this.formularioCuentaCobrar.get('tipo_pagador').disable()		
		}

		if(this.nuevo){
			this.formularioCuentaCobrar.get('deudor_ruc').setValidators([Validators.required])
			this.formularioCuentaCobrar.get('concepto').setValidators([Validators.required])
			this.formularioCuentaCobrar.get('estado').setValidators([Validators.required])
			this.formularioCuentaCobrar.get('monto').setValidators([Validators.required])
			this.formularioCuentaCobrar.get('numero_proceso').setValidators([Validators.required])
			this.formularioCuentaCobrar.get('fecha_creacion').disable()
			this.formularioCuentaCobrar.get('liquidacion_fecha').disable()	
			this.formularioCuentaCobrar.get('estado').setValue(1)	
			this.formularioCuentaCobrar.get('estado').disable()	

			this.formularioCuentaCobrar.get('deudor').valueChanges.subscribe((value)=>{
				if(value){
					this.factoring.getDeudores(value).then((res: any)=>{
						this.formularioCuentaCobrar.get('deudor_ruc').setValue(res.ruc)
					})					
				}
			})

			this.formularioCuentaCobrar.get('beneficiario').valueChanges.subscribe((value)=>{
				if(value){
					this.factoring.getBeneficiarios(value).then((res: any)=>{
						this.formularioCuentaCobrar.get('deudor_ruc').setValue(res.ruc)
					})
				}
			})

			this.formularioCuentaCobrar.get('liquidacion_operacion').disable()
			this.formularioCuentaCobrar.get('igv').disable()
			this.formularioCuentaCobrar.get('totalxcobrar').disable()
			this.formularioCuentaCobrar.get('saldo').disable()			
		}
	}

	async initComponent(
		cuenta       : any = null,
		comentarios  : any = null,
		recaudaciones: any = null,
		conceptos    : any[],
		estados      : any[],
		tiposPagador: any[],
		monedas: any[]){
		if(!cuenta){
			this.nuevo = true
			this.initForm({tipo_proceso:1})
			this.cuenta = { moneda : 1 }
		} else {
			this.nuevo = false
			this.cuenta = cuenta
			this.filasComentarios = comentarios.results.map((comentario)=>{
				comentario.fecha = this.dateComentarios(comentario.fecha)
				return comentario
			})

			let recaudacionesConPagosProrrogaAdelantados = null
			let NCRecaudacionesIds = new Map();
			if (cuenta.historial_documento_pago_anticipado) {
				const fechasRecaudacionesDocumento = cuenta.historial_fecha_pago_anticipado.split(';')
				const responseRecaudacionesDocumento: any = await this.documentosCarteraService.obtenerBandejaRecaudoPorDocumentos(cuenta.historial_documento_pago_anticipado)
				recaudacionesConPagosProrrogaAdelantados = responseRecaudacionesDocumento.results.map((rec, key) => {
					rec.pago = null
					rec.monto = null
					rec.moneda_cxc = cuenta.moneda
					rec.fecha_pago_anticipado = fechasRecaudacionesDocumento[key]
					rec.monto_original_cxc = cuenta.historial_monto_pago_anticipado
					return rec
				});

				const recaudacionesConsulta = recaudaciones.results.map(rec => rec.recaudacion).join(',')
				const responseNCRecaudaciones = await this.facturacionService.obtenerFacturasPorRecaudacion(
					recaudacionesConsulta,
					'2', // tipo Nota credito
					'1,2', // estado pendiente o facturado
				)
				responseNCRecaudaciones['results'].map(NCRec => {
					const data = {
						id: NCRec['id'],
						nota_credito: NCRec['serie'] ? `${NCRec['serie']}-${NCRec['numero']}` : "No se emitió",
						total_nc: NCRec['total'],
						moneda_nc: NCRec['moneda'],
						estado: NCRec['estado'],
					}
					const idPrev = NCRecaudacionesIds.get(NCRec['recaudacion']) ?? {id: -1000000};

					if (idPrev.id < data.id || data.estado === 2) {
						NCRecaudacionesIds.set(NCRec['recaudacion'], data);
					}
				})
				recaudacionesConPagosProrrogaAdelantados.forEach(rec => {
					rec.nota_credito = null;
					rec.total_nc = null;
					rec.moneda_nc = null;
				});
			}

			let recaudos = recaudaciones.results.map(recaudacion => {
				// si la recaudacion esta dentro de recaudacionesConPagosProrrogaAdelantados
				recaudacion.fecha_pago_anticipado = null
				recaudacion.monto_original_cxc = null
				recaudacion.moneda_cxc = null
				if (NCRecaudacionesIds.has(recaudacion.recaudacion)) {
					recaudacion.nota_credito = NCRecaudacionesIds.get(recaudacion.recaudacion)['nota_credito'];
					recaudacion.total_nc = NCRecaudacionesIds.get(recaudacion.recaudacion)['total_nc'];
					recaudacion.moneda_nc = NCRecaudacionesIds.get(recaudacion.recaudacion)['moneda_nc'];
				} else {
					recaudacion.nota_credito = null;
					recaudacion.total_nc = null;
					recaudacion.moneda_nc = null;
				}
				return recaudacion
			});

			if (recaudacionesConPagosProrrogaAdelantados) {
				recaudos = recaudos.concat(recaudacionesConPagosProrrogaAdelantados)
			}

			this.bandejaRecaudos = recaudos
			const tieneRecaudacion = !!cuenta.recaudacion

			this.initForm(cuenta)
			if (!(cuenta.concepto === -3) && !(tieneRecaudacion && (cuenta.recaudacion === cuenta.numero_proceso))) {
				this.factoring.obtenerDetalles(this.formularioCuentaCobrar.get('numero_proceso').value, 100).then(res=>{
					this.detalles = res['results']
				})
			}
		}

		this.conceptos = conceptos
		this.estados   = estados
		this.tiposPagador = tiposPagador
		this.monedas = monedas
	}
	
	displayModal(){
		this.modalService.open(this.modalCuentaCobrar, {
			size: 'xl'
		}).result.then((response: any)=>{
		})
	}

	splitDateString(text){
		let split      = text.split('-');
		let year       = split[0]
		let month      = split[1]
		let day        = split[2].split('T')[0]
		
		let textReturn = `${day}/${month}/${year}`
		return textReturn
	}

	nuevoComentario(coment){
		this.nuevoComent.emit(coment)
	}

	editarComentario(coment){
		this.editaComent.emit(coment)
	}
	
	eliminarComentario(coment){
		this.eliminaComent.emit(coment)
	}	

	parseDate(fecha){
		let yy = fecha.split('-')[0];
		let mm = fecha.split('-')[1];
		let dd = fecha.split('-')[2];
		dd = dd.split('T')[0]
	
		let date = `${yy}/${mm}/${dd}`;
		return date
	
	}

	refrescaComentarios(comentarios){
		this.filasComentarios = []
		this.filasComentarios = comentarios
	}

	/**
	 * Este evento se suscribe a los cambios en el objeto que recibe la data desde el servidor
	 */
	loadSearchBeneficiarios() {
		/**
		 * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
		 * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
		 * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value. 
		 * 		So that API will not hit if the current and previous value is the same
		 * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
		 * 		string typed by user
		 */
		this.beneficiarios$ = concat(
			of([]), // Items predeterminados
			this.searchBeneficiario$.pipe(
			filter(res => {
				// return true
				return res !== null && res.length >= 4
			}),
			distinctUntilChanged(),
			debounceTime(800),
			tap(() => this.loadingSearch = true),
			switchMap(term => {
				return this.factoring.buscarBeneficiariosObserver(term).pipe(
				catchError(() => of([])), // empty list on error
				tap(() => this.loadingSearch = false)
				)
			})
			)
		);
	}	

	loadSearchDeudores() {
		/**
		 * filter(): The event will be triggered only when the length of the input value is more than 2 or whatever you like
		 * debounceTime(): This operator takes time in milliseconds. This is the time between key events before a user stops typing.
		 * distinctUntilChanged(): This operator checks whether the current input is sitting from a previously entered value. 
		 * 		So that API will not hit if the current and previous value is the same
		 * switchMap => fetches the server result by calling the "buscarBeneficiariosObserver()" method passing the
		 * 		string typed by user
		 */
		this.deudores$ = concat(
			of([]), // Items predeterminados
			this.searchDeudor$.pipe(
			filter(res => {
				// return true
				return res !== null && res.length >= 4
			}),
			distinctUntilChanged(),
			debounceTime(800),
			tap(() => this.loadingSearch = true),
			switchMap(term => {
				return this.factoring.buscarDeudorObserver(term).pipe(
				catchError(() => of([])), // empty list on error
				tap(() => this.loadingSearch = false)
				)
			})
			)
		);
	}		

	guardarCuentaCobrar(){
		this.formularioCuentaCobrar.enable()
		let valor = this.formularioCuentaCobrar.value
		let object : any= {}
		this.toggleForm()
		object.numero_proceso = this.formularioCuentaCobrar.get('numero_proceso').value

		if (valor.tipo_proceso == 1) {
			object.operacion = this.formularioCuentaCobrar.get('numero_proceso').value
		}
		for( let prop in valor){
			if(valor[prop] != '' && valor[prop] != null && (!(prop == 'numero_proceso' || prop == 'tipo_proceso_descripcion'))){
				object[prop] = valor[prop]
			}
		}
		if (valor.tipo_proceso == 1) {
			object.operacion_detalle = this.formularioCuentaCobrar.get('operacion_detalle').value
		}		
		if(this.nuevo){
			this.guardar.emit(object)
		} else {
			object['id'] = this.cuenta.id
			this.cambios.emit(object)
		}
	}

	dateFormater(date : string){
		date = this.parseDate(date)
		let dateSplit = date.split('/')
		let hoy = new Date()
		let retorno = new Date(Number(dateSplit[0]),(Number(dateSplit[1])-1),Number(dateSplit[2]),hoy.getHours())

		return retorno
	}

	cerrarModal(){
		this.nuevo = false;
		this.modalService.dismissAll()
	}

	dateComentarios(fecha){
		let yy = fecha.split('-')[0];
		let mm = fecha.split('-')[1];
		let dd = fecha.split('-')[2];
		dd = dd.split('T')[0]
		
		let hora = fecha.split('T')[1].split(':')

		let H = Number(hora[0])
		let M = hora[1]
		let HoraDia = 'AM'
		if(H > 12){
			H = H - 12
			HoraDia = 'PM'
		}

		let HoraCompleta = `${H}:${M} ${HoraDia}`
		let date = `${yy}/${mm}/${dd} ${HoraCompleta}`;
		return date
	}

	formatDate(date){
		if(!(date instanceof Date))
			return date;

		return date.getFullYear() + 
			"-" + (date.getMonth() + 1) + 
			"-" + date.getDate();
	}

	openRecaudacion(event) {

		const modalRef = this.modalService.open(AddRecaudacionModalComponent, {
		  ariaLabelledBy: "modal-basic-title",
		  size: "xl",
		});
	
		modalRef.componentInstance.recaudacionId = event.actionableType;
	
		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
		  console.log(reason);
		});
	  }
}
