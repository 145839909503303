import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { Operacion } from 'app/core/services/auth/Operacion';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { LegalService } from 'app/core/services/legal.service';
import { OrdenesPagoService } from 'app/core/services/ordenes-pago.service';
import { OrdenDePago } from 'app/core/services/auth/OrdenDePago';
import { MiniAlertComponent } from 'app/shared/mini-alert/mini-alert.component';
import { ConfirmModalComponent } from '../utils/confirm-modal/confirm-modal.component';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NumeroCuentaBancoComponent } from '../numero-cuenta-banco/numero-cuenta-banco.component';
@Component({
	selector: 'app-orden-pago',
	templateUrl: './orden-pago.component.html',
	styleUrls: ['./orden-pago.component.css']
})
export class OrdenPagoComponent implements OnInit {
	public _operacion                   : Operacion;
	public _readonly                    : boolean = true;
	public formularioAdelanto           : FormGroup;
	public formularioDesembolso         : FormGroup;
	public monedas                      : any[];
	public formasDePago                 : any[];
	public cuentasAdelanto               : any[];
	public cuentasDesembolso             : any[];
	public adelanto                     : OrdenDePago;
	public desembolso                   : OrdenDePago;
	public minDate                      : Date    = new Date();
	documentoContext                    : any     = {};
	faTimes = faTimes
	@ViewChild('noAdjunto', { static    : false }) public noAdjunto;
	@ViewChild(MiniAlertComponent) alert: MiniAlertComponent;

	@ViewChildren(NumeroCuentaBancoComponent) inputsCuentasAbono: QueryList<NumeroCuentaBancoComponent>


	@Input() set readonly(value) {
		if (value) {
			this.formularioAdelanto.disable()
			this.formularioDesembolso.disable()
		}
		this._readonly = value
	}
	@Input() set operacion(value: Operacion) {
		if (value) {
			this.loadData(value)
			this._operacion = value
		}
	};

	@Input() readonlyAccount: boolean;

	constructor(public formbuild: FormBuilder,
		public factoring: FactoringService,
		private authService: AuthService,
		private ordenesPago: OrdenesPagoService,
		private modalService: NgbModal,
		private configs: DocschecklistService,
		private legalService: LegalService) {
		this.initFormulario()
	}

	ngOnInit(): void {
		console.log(this.readonlyAccount, this._operacion, 'SAber si activar modificiar cuenta');
		console.log(this.formularioDesembolso.disabled);
	}

	initFormulario() {

		this.formularioAdelanto = this.formbuild.group({
			monto: ['', [Validators.required]],
			moneda: ['', [Validators.required]],
			forma_pago: ['', [Validators.required]],
			fecha_desembolso_programado: ['', [Validators.required]],
			banco_cargo: ['', [Validators.required]],
			cuenta: ['', [Validators.required]],
			moneda_cargo: ['', [Validators.required]],
			cuenta_cargo: ['', [Validators.required]],
			nro_pago: ['', [Validators.required]],
			banco_abono: ['', [Validators.required]],
			_banco_abono: ['', [Validators.required]],
			moneda_abono: ['', [Validators.required]],
			cuenta_abono: ['', [Validators.required]],
			comentarios: [''],
			tesoreria_responsable: ['', [Validators.required]],
		});

		this.formularioAdelanto.get('cuenta').valueChanges.subscribe((change) => {
			if (this.cuentasAdelanto) {
				let cuenta = this.cuentasAdelanto.find((cuenta) => cuenta.id === change)
				this.formularioAdelanto.get('moneda_cargo').setValue(cuenta.moneda)
				this.formularioAdelanto.get('cuenta_cargo').setValue(cuenta.numero)
				this.formularioAdelanto.get('banco_cargo').setValue(cuenta.banco)
			}
		})


		this.formularioDesembolso = this.formbuild.group({
			monto: ['', [Validators.required]],
			moneda: ['', [Validators.required]],
			forma_pago: ['', [Validators.required]],
			fecha_desembolso_programado: ['', [Validators.required]],
			banco_cargo: ['', [Validators.required]],
			cuenta: ['', [Validators.required]],
			moneda_cargo: ['', [Validators.required]],
			cuenta_cargo: ['', [Validators.required]],
			nro_pago: ['', [Validators.required]],
			banco_abono: ['', [Validators.required]],
			_banco_abono: ['', [Validators.required]],
			moneda_abono: ['', [Validators.required]],
			cuenta_abono: ['', [Validators.required]],
			comentarios: [''],
			cuenta_abono_verificado: ['', [Validators.requiredTrue]],
			tesoreria_responsable: ['', [Validators.required]],
		});

		this.formularioDesembolso.get('cuenta').valueChanges.subscribe((change) => {
			console.log("fuera del if:", this.formularioDesembolso)
			if (this.cuentasDesembolso) {

				let cuenta = this.cuentasDesembolso.find((cuenta) => cuenta.id === change)
				console.log("dentro del if:", this.formularioDesembolso)
				console.log("cuenta:",cuenta )
				this.formularioDesembolso.get('moneda_cargo').setValue(cuenta.moneda)
				this.formularioDesembolso.get('cuenta_cargo').setValue(cuenta.numero)
				this.formularioDesembolso.get('banco_cargo').setValue(cuenta.banco)
			}
		})
		this.formularioDesembolso.get('moneda_cargo').disable()
		this.formularioDesembolso.get('cuenta_cargo').disable()
		this.formularioAdelanto.get('moneda_cargo').disable()
		this.formularioAdelanto.get('cuenta_cargo').disable()

		let monedas = this.configs.obtenerMonedas();

		Promise.all([monedas]).then((response: any[]) => {
			this.monedas = response[0].results
		})
	}

	loadData(operacion) {
		let adelanto = this.ordenesPago.obtenerAdelanto(operacion.id)
		let liquidacion = this.ordenesPago.obtenerOrden(operacion.id)
		let formasDePago = this.ordenesPago.obtenerFormasDePago();

		Promise.all([adelanto, formasDePago, liquidacion]).then((response: any[]) => {
			let adelanto = response[0].results.find((e) => e.tipo === 2)
			this.formasDePago = this.filterUserTeroreria(response[1].results)
			let desembolso = response[2].results.find((e) => e.tipo === 1)

			if (adelanto) {
				this.adelanto = new OrdenDePago(adelanto)
				this.setAdelanto(adelanto)
				this.cuentasAdelanto = adelanto.cuenta_cargo_operacion
			} else {
				this.formularioAdelanto.disable()
			}
			if (desembolso) {
				console.log("desembolso: ", desembolso)
				this.desembolso = new OrdenDePago(desembolso)
				this.setDesembolso(desembolso)
				this.cuentasDesembolso = desembolso.cuenta_cargo_operacion
			} else {
				console.log(response[2].results)
				this.formularioDesembolso.disable()
			}
		})
	}

	setAdelanto(adelanto: OrdenDePago) {
		this.formularioAdelanto.reset({
			monto: adelanto.monto,
			moneda: adelanto.moneda,
			forma_pago: adelanto.forma_pago,
			fecha_desembolso_programado: adelanto.fecha_pago ? OrdenDePago.parseDate(adelanto.fecha_pago) : null,
			banco_cargo: adelanto.banco_cargo,
			cuenta: adelanto.cuenta,
			moneda_cargo: adelanto.moneda_cargo,
			cuenta_cargo: adelanto.cuenta_cargo,
			nro_pago: adelanto.nro_pago,
			banco_abono: adelanto.cuenta_abono_operacion.entidad_financiera,
			_banco_abono: adelanto.cuenta_abono_operacion.entidad_financiera_nombre,
			moneda_abono: adelanto.cuenta_abono_operacion.moneda,
			cuenta_abono: adelanto.cuenta_abono_operacion.numero_cuenta,
			comentarios: adelanto.comentarios,
			adjunto: adelanto.adjunto,
			tesoreria_responsable: adelanto.tesoreria_responsable,
		})

		this.formularioAdelanto.get('_banco_abono').disable()
		this.formularioAdelanto.get('moneda_abono').disable()
		this.formularioAdelanto.get('cuenta_abono').disable()
		this.formularioAdelanto.get('monto').disable()
		this.formularioAdelanto.get('moneda').disable()
		this.formularioAdelanto.get('tesoreria_responsable').disable()
	}

	setDesembolso(desembolso) {

		this.formularioDesembolso.reset({
			monto: desembolso.monto,
			moneda: desembolso.moneda,
			forma_pago: desembolso.forma_pago,
			fecha_desembolso_programado: desembolso.fecha_desembolso_programado ? OrdenDePago.parseDate(desembolso.fecha_desembolso_programado) : null,
			banco_cargo: desembolso.banco_cargo,
			cuenta: desembolso.cuenta,
			moneda_cargo: desembolso.moneda_cargo,
			cuenta_cargo: desembolso.cuenta_cargo,
			nro_pago: desembolso.nro_pago,
			banco_abono: desembolso.cuenta_abono_operacion.entidad_financiera,
			_banco_abono: desembolso.cuenta_abono_operacion.entidad_financiera_nombre,
			moneda_abono: desembolso.cuenta_abono_operacion.moneda,
			cuenta_abono: desembolso.cuenta_abono_operacion.numero_cuenta,
			comentarios: desembolso.comentarios,
			adjunto: desembolso.adjunto,
			tesoreria_responsable: desembolso.tesoreria_responsable,
			cuenta_abono_verificado: desembolso.cuenta_abono_operacion.verificado,
		})
		console.log("formulario desembolso: ", this.formularioDesembolso)
		this.formularioDesembolso.get('_banco_abono').disable()
		this.formularioDesembolso.get('moneda_abono').disable()
		this.formularioDesembolso.get('cuenta_abono').disable()
		this.formularioDesembolso.get('monto').disable()
		this.formularioDesembolso.get('moneda').disable()
		this.formularioDesembolso.get('tesoreria_responsable').disable()
		this.formularioDesembolso.get('fecha_desembolso_programado').disable()
	}

	adjuntarArchivo(orden) {
		var fileSelect = document.createElement("input");
		fileSelect.setAttribute("id", "fielSelect");
		fileSelect.setAttribute("type", "file");
		fileSelect.setAttribute("name", "profile");
		fileSelect.setAttribute("style", "display: none;");
		let formularioDetalles = document.getElementById("form_upload")
		formularioDetalles.appendChild(fileSelect)
		document.getElementById("fielSelect").click()
		document.getElementById("fielSelect").addEventListener('change', (event) => {
			this.onFileSelect(event, orden)
			formularioDetalles.removeChild(fileSelect)
		});
	}

	onFileSelect(event, orden, flag = false) {
		console.log(orden)
		if (event.target.files.length > 0) {
			const file = event.target.files[0];
			orden.updateOrden = { "adjunto": file }
			if (flag) {

			}
			// this.guardarDesembolso()		
		}
	}

	async guardarAdelanto() {
		this.adelanto.updateOrden = this.formularioAdelanto.value
		this.adelanto.updateOrden = { "responsable_orden": this.authService.user.id }

		if (!this.adelanto.adjunto) {
			this.modalService.open(this.noAdjunto, {
				size: 'md'
			}).result.then((response: any) => {
				console.log(response)
			})
		} else {
			this.formularioAdelanto.get('moneda_cargo').enable()
			this.formularioAdelanto.get('cuenta_cargo').enable()
			this.formularioAdelanto.get('moneda_abono').enable()
			this.formularioAdelanto.get('cuenta_abono').enable()
			this.formularioAdelanto.get('monto').enable()
			this.formularioAdelanto.get('moneda').enable()
			this.formularioAdelanto.get('tesoreria_responsable').enable()

			if (this.formularioAdelanto.get('nro_pago').value === this.formularioDesembolso.get('nro_pago').value) {
				this.alert.displayAlert({
					title: 'Número de documentos',
					body: 'El número de pago de Adelanto y Desembolso deben ser diferentes',
					truefalse: true,
					accept: false
				}).then((response) => {
					this.formularioAdelanto.get('nro_pago').setValue('')
				})
				return
			} else {
				this.adelanto.updateOrden = this.formularioAdelanto.value
				this.adelanto.updateOrden = { "responsable_orden": this.authService.user.id }
			}

			this.formularioAdelanto.get('moneda_cargo').disable()
			this.formularioAdelanto.get('cuenta_cargo').disable()
			this.formularioAdelanto.get('moneda_abono').disable()
			this.formularioAdelanto.get('cuenta_abono').disable()
			this.formularioAdelanto.get('monto').disable()
			this.formularioAdelanto.get('moneda').disable()
			this.formularioAdelanto.get('tesoreria_responsable').disable()

			let adelanto = this.adelanto.valores

			const cuentaValida = await this.validarCuentaAbono(this.adelanto)
			if (!cuentaValida) return

			this.ordenesPago.actualizaAdelanto(this.adelanto.id, adelanto).then((response: any) => {
				this.loadData(this._operacion)
			})
		}
	}

	async guardarDesembolso() {

		if (!this.desembolso.adjunto) {
			this.modalService.open(this.noAdjunto, {
				size: 'md'
			}).result.then((response: any) => {
				console.log(response)
			})
		} else {
			this.formularioDesembolso.get('moneda_cargo').enable()
			this.formularioDesembolso.get('cuenta_cargo').enable()
			this.formularioDesembolso.get('moneda_abono').enable()
			this.formularioDesembolso.get('cuenta_abono').enable()
			this.formularioDesembolso.get('monto').enable()
			this.formularioDesembolso.get('moneda').enable()
			this.formularioDesembolso.get('fecha_desembolso_programado').enable()
			this.formularioDesembolso.get('tesoreria_responsable').enable()

			if (this.formularioAdelanto.get('nro_pago').value === this.formularioDesembolso.get('nro_pago').value) {
				this.alert.displayAlert({
					title: 'Número de documentos',
					body: 'El número de pago de Adelanto y Desembolso deben ser diferentes',
					truefalse: true,
					accept: false
				}).then((response) => {
					this.formularioDesembolso.get('nro_pago').setValue('')
				})
				return
			} else {
				this.desembolso.updateOrden = this.formularioDesembolso.value
				this.desembolso.updateOrden = { "responsable_orden": this.authService.user.id }
			}


			this.formularioDesembolso.get('moneda_cargo').disable()
			this.formularioDesembolso.get('cuenta_cargo').disable()
			this.formularioDesembolso.get('moneda_abono').disable()
			this.formularioDesembolso.get('cuenta_abono').disable()
			this.formularioDesembolso.get('monto').disable()
			this.formularioDesembolso.get('moneda').disable()
			this.formularioDesembolso.get('tesoreria_responsable').disable()
			this.formularioDesembolso.get('fecha_desembolso_programado').disable()

			let desembolso = this.desembolso.valores

			const cuentaValida = await this.validarCuentaAbono(this.desembolso)
			if (!cuentaValida) return

			this.ordenesPago.actualizaDesembolso(this.desembolso.id, desembolso).then((response: any) => {
				console.log(response)
				this.loadData(this._operacion)
			})
		}
	}

	async validarCuentaAbono(orden) {
		let valido = false
		this.factoring.spinner.show()
		try {
			const cuentaAbono = await this.factoring.validarCuentaAbono(orden.cuenta_abono_operacion.id).toPromise()
			// Es valido si la validación retorna valido o si no tiene fecha de validacion
			// No tiene la fecha porque no se valida si es de un banco no disponible para validar
			valido = !cuentaAbono['fecha_numero_validado'] || !!cuentaAbono['numero_valido']
		} catch {
			this.factoring.toast.warning("Ocurrio un error validando la cuenta de abono")
		}
		// Comentado porque en orden no se muestra el cci, descomentar para validar cci
		// if (valido) {
		// 	try {
		// 		const cuentaAbono = await this.factoring.validarCuentaAbono(orden.cuenta_abono_operacion.id, true).toPromise()
		// 		// Es valido si la validación retorna valido o si no tiene fecha de validacion
		// 		// No tiene la fecha porque no se valida si es de un banco no disponible para validar
		// 		valido = !cuentaAbono['fecha_cci_validado'] || !!cuentaAbono['cci_valido']
		// 	} catch {
		// 		this.factoring.toast.warning("Ocurrio un error validando la cuenta de abono")
		// 	}
		// }
		this.factoring.spinner.hide()
		this.inputsCuentasAbono.forEach(e => e.loadCuentaAbono())
		if (!valido) {
			this.factoring.toast.warning("La cuenta de abono no se encuentra válida")
		}
		return valido
	}

	downloadDoc({ adjunto }) {
		// if(typeof adjunto == 'object'){
		// 	this.alert.displayAlert({
		// 		title: 'Documento adjunto',
		// 		body: 'Debe guardar el formulario para poder descargar el archivo adjunto',
		// 		truefalse: true,
		// 		accept: false
		// 	}).then((response)=>{
		// 	})			
		// 	return
		// }
		let urlDoc = adjunto
		const ultimoSlash = new URL(urlDoc).pathname;
		const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1];
		this.downloadFile(urlDoc, adjuntoName);
		// var win = window.open(urlDoc, '_blank');
		// win.focus();
	}

	download(adjunto) {
		console.log(adjunto);
		// return;
		let urlDoc = adjunto.adjunto;
		const ultimoSlash = new URL(urlDoc).pathname;
		const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1];
		this.downloadFile(urlDoc, adjuntoName);
	}

	downloadFile(url, name) {
		this.factoring.downloadFile(url).then(
			(x: any) => {
				console.log(x);
				var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
				var downloadURL = window.URL.createObjectURL(blob);
				var link = document.createElement('a');
				link.href = downloadURL;
				link.download = name;
				link.click();
			}, async (reason) => {
				console.log(reason);
			}), err => {
				console.error(err);
			};
	}

	solicitarCambioCuenta() {
		const modalRef = this.modalService.open(ConfirmModalComponent, {});

		modalRef.componentInstance.title = "Solicitar Cambio Cuenta";
		modalRef.componentInstance.message =
			"¿Seguro que desea cambiar la cuenta?";

		modalRef.result.then((result) => {
			if (result) {

				// Solictar Editar cuenta
				this.ordenesPago.solicitudCambiarCuenta(this._operacion.id).then(resp => {
					console.log(resp);
				}, err => {
					console.error(err);

				});
			}
		}, (reason) => {
			console.log(reason);
		});
	}

	cambiarVerificadoCuentaAbono(e) {
		let desembolso = this.desembolso
		let body = {
			verificado: e,
			beneficiario: desembolso.cuenta_abono_operacion.beneficiario_id,
			entidad_financiera: desembolso.cuenta_abono_operacion.entidad_financiera,
			moneda: desembolso.cuenta_abono_operacion.moneda,
			numero_cuenta: desembolso.cuenta_abono_operacion.numero_cuenta
		}
		this.ordenesPago.actualizaEstadoVerificado(desembolso.cuenta_abono_operacion.id, body).then((response: any) => {
			console.log(response)
		})
	}

	filterUserTeroreria(data){

		if( this.authService.user.perfilLiderDeTesoreriaID === this.authService.user.perfil ||  this.authService.user.perfilTesoreriaID === this.authService.user.perfil ){

			return data.filter( item => item.id > 0 && item.id != 8 )

		}else{

			return data;
		}

	}

}
