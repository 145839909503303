import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-members-modal',
  templateUrl: './add-members-modal.component.html',
  styleUrls: ['./add-members-modal.component.css']
})
export class AddMembersModalComponent implements OnInit {
  faTimes = faTimes;
  members = [
    {
      id: 1,
      name: 'Jorge'
    },
    {
      id: 1,
      name: 'Isabel'
    },
    {
      id: 1,
      name: 'Eduardo'
    },
    {
      id: 1,
      name: 'Eleazar'
    }
  ];

  myForm: FormGroup;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm() {
    this.myForm = this.formBuilder.group({
      member: []
    });
  }

  onSubmit() {

  }

}
