import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { MonedaPipe } from "app/core/pipes/moneda.pipe";

@Component({
	selector: "app-imprimir-carta-modificacion",
	templateUrl: "./imprimir-carta-modificacion.component.html",
	styleUrls: ["./imprimir-carta-modificacion.component.css"],
	providers: [MonedaPipe]
})
export class ImprimirCartaModificacionComponent implements OnInit {
	public meses = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
	public fecha_actual : Date
	public fecha_actual_formateada : string

	public correlativo: string = '' 
	public nombre : string = '' 
	public domicilio : string = '' 
	public departamento : string = '' 
	public provincia : string = '' 
	public distrito : string = '' 
	public tipo_linea : string = '' 
	public id_linea : string = '' 

	public monto_linea : string = '' 
	public moneda_linea : string = '' 
	public tipo_equivalente_moneda: string = ''
	public nombre_modena: string = '' // hack para que qa pase pipeline // eliminar cuando se pueda

	constructor(
		private activatedRoute: ActivatedRoute,
		public monedapipe: MonedaPipe,
	) {
		this.fecha_actual = new Date()
		this.fecha_actual_formateada = `${this.fecha_actual.getDate()} de ${this.meses[this.fecha_actual.getMonth()]} del ${this.fecha_actual.getFullYear()}`
		this.activatedRoute.queryParamMap.subscribe((qp) => {
			this.correlativo = qp['params']?.correlativo
			this.nombre = qp['params']?.nombre
			this.domicilio = qp['params']?.domicilio
			this.departamento = qp['params']?.departamento
			this.provincia = qp['params']?.provincia
			this.distrito = qp['params']?.distrito
			this.tipo_linea = qp['params']?.tipo_linea
			this.id_linea = qp['params']?.id_linea
			this.monto_linea = qp['params']?.monto_linea
			this.moneda_linea = qp['params']?.moneda_linea
		});
	}

	ngOnInit(): void {
		let moneda_linea_plural: string
		if (this.moneda_linea == 'S/.') {
			moneda_linea_plural = 'Soles'
			this.tipo_equivalente_moneda = 'extranjera'
		} else {
			moneda_linea_plural = 'Dolares Americanos'
			this.tipo_equivalente_moneda = 'nacional'
		}
		
		let monto_formateado = this.monedapipe.transform(
			Number(this.monto_linea), this.moneda_linea == 'S/.' ? 1 : 2)

		this.monto_linea = `${monto_formateado} ${new this.FormatearMonto(this.moneda_linea, moneda_linea_plural).numero_a_letras(this.monto_linea)}`.toUpperCase()

		setTimeout(()=>window.print(), 2000)
	}

	FormatearMonto = class {
		constructor(moneda_singular : string, moneda_plural : string) {
			this.MONEDA_SINGULAR = moneda_singular
			this.MONEDA_PLURAL = moneda_plural
		}
		// US$ 90,000.00 (NOVENTA MIL Y 00/100 DOLARES AMERICANOS)
		MONEDA_SINGULAR = ''
		MONEDA_PLURAL = ''
		CENTIMOS_SINGULAR = 'centavo'
		CENTIMOS_PLURAL = 'centavos'
		MAX_NUMERO = 999999999999
		UNIDADES = [
			'cero',
			'uno',
			'dos',
			'tres',
			'cuatro',
			'cinco',
			'seis',
			'siete',
			'ocho',
			'nueve'
		]
		DECENAS = [
			'diez',
			'once',
			'doce',
			'trece',
			'catorce',
			'quince',
			'dieciseis',
			'diecisiete',
			'dieciocho',
			'diecinueve'
		]
		DIEZ_DIEZ = [
			'cero',
			'diez',
			'veinte',
			'treinta',
			'cuarenta',
			'cincuenta',
			'sesenta',
			'setenta',
			'ochenta',
			'noventa'
		]
		CIENTOS = [
			'_',
			'ciento',
			'doscientos',
			'trescientos',
			'cuatroscientos',
			'quinientos',
			'seiscientos',
			'setecientos',
			'ochocientos',
			'novecientos'
		]
	
		numero_a_letras(numero) {
			console.log(numero)
			let resultado = ''
			let numero_entero = Math.round(numero)
			let letras_decimal = ''
			let parte_decimal = Math.round(Math.round((Math.abs(numero) - Math.abs(numero_entero)) * 100))

			if (numero_entero > this.MAX_NUMERO){
				console.error('Número demasiado alto')
			}

			if (numero_entero < 0) {
				return `menos ${this.numero_a_letras(Math.abs(numero))}`
			}

			if (parte_decimal > 9) {
				letras_decimal = `punto ${this.numero_a_letras(parte_decimal)}`
			}
			else if (parte_decimal > 0) {
				letras_decimal = `punto cero ${this.numero_a_letras(parte_decimal)}`
			}
			
			if (numero_entero <= 99) {
				resultado = this.leer_decenas(numero_entero)
			}
			else if (numero_entero <= 999) {
				resultado = this.leer_centenas(numero_entero)
			}
			else if (numero_entero <= 999999) {
				resultado = this.leer_miles(numero_entero)
			}
			else if (numero_entero <= 999999999) {
				resultado = this.leer_millones(numero_entero)
			}
			else {
				resultado = this.leer_millardos(numero_entero)
				resultado = resultado.replace('uno mil', 'un mil')
				resultado = resultado.trim()
				resultado = resultado.replace(' _ ', ' ')
				resultado = resultado.replace('  ', ' ')
				if (parte_decimal > 0) {
					resultado = `${resultado} ${letras_decimal}`
				}
			}

			// agregar SOLES o DOLARES AMERICANOS al final
			let moneda = ''
			if (numero_entero == 1) {
				moneda = this.MONEDA_SINGULAR
			}	else {
				moneda = this.MONEDA_PLURAL
			}
			return `${resultado} ${moneda}`
		}
	
		numero_a_moneda(numero) {
			let numero_entero = Math.round(numero)
			let parte_decimal = Math.round(Math.round((Math.abs(numero) - Math.abs(numero_entero)) * 100))
			let centimos = ''
			let moneda = ''
			
			if (parte_decimal == 1) {
				centimos = parte_decimal.toPrecision(2) + "/100"
			}
			else {
				centimos = parte_decimal.toPrecision(2) + "/100"
			}

			if (numero_entero == 1) {
				moneda = this.MONEDA_SINGULAR
			}
			else {
				moneda = this.MONEDA_PLURAL
			}

			let letras = this.numero_a_letras(numero_entero)
			letras = letras.replace('uno', 'un')
			
			let letras_decimal = centimos
			letras = `${letras} y ${letras_decimal} ${moneda}`
			return letras
		}
	
		leer_decenas(numero) {
			if (numero < 10) {
				return this.UNIDADES[numero]
			}

			let decena = Math.floor(numero/10); 
			let unidad = numero % 10
			let resultado

			if (numero <= 19) {
				resultado = this.DECENAS[unidad]
			}
			else if (numero > 20 && numero <= 29) {
				resultado = `veinti${this.UNIDADES[unidad]}`
			}
			else {
				resultado = this.DIEZ_DIEZ[decena]
				if (unidad > 0) {
					resultado = `${resultado} y ${this.UNIDADES[unidad]}`
				}
			}

			return resultado
		}
	
		leer_centenas(numero) {
			let centena = Math.floor(numero/100); 
			let decena = numero % 100
			let resultado

			if (numero == 0) {
				resultado = 'cien'
			}
			else {
				resultado = this.CIENTOS[centena]
				if (decena > 0) {
					resultado = `${resultado} ${this.leer_decenas(decena)}`
				}
			}
			return resultado
		}
	
		leer_miles(numero) {
			let millar = Math.floor(numero/1000); 
			let centena = numero % 1000
			let resultado

			if (millar == 1) {
				resultado = ''
			}
			if ((millar >= 2) && (millar <= 9)) {
				resultado = this.UNIDADES[millar]
			} else if ((millar >= 10) && (millar <= 99)) {
				resultado = this.leer_decenas(millar)
			} else if ((millar >= 100) && (millar <= 999)) {
				resultado = this.leer_centenas(millar)
			}
			resultado = `${resultado} mil`
			if (centena > 0) {
				resultado = `${resultado} ${this.leer_centenas(centena)}`
			}
			return resultado
		}
	
		leer_millones(numero) {
			let millon = Math.floor(numero/1000000); 
			let millar = numero % 1000000
			let resultado

			resultado = ''
			if (millon == 1) {
				resultado = ' un millon '
			}
			if ((millon >= 2) && (millon <= 9)) {
				resultado = this.UNIDADES[millon]
			}
			else if ((millon >= 10) && (millon <= 99)) {
				resultado = this.leer_decenas(millon)
			}
			else if ((millon >= 100) && (millon <= 999)) {
				resultado = this.leer_centenas(millon)
			}
			if (millon > 1) {
				resultado = `${resultado} millones`
			}
			if ((millar > 0) && (millar <= 999)) {
				resultado = `${resultado} ${this.leer_centenas(millar)}`
			}
			else if ((millar >= 1000) && (millar <= 999999)) {
				resultado = `${resultado} ${this.leer_miles(millar)}`
			}
			return resultado
		}
	
		leer_millardos(numero) {
			let millardo = Math.floor(numero/1000000); 
			let millon = numero % 1000000
			return `${this.leer_miles(millardo)} millones ${this.leer_millones(millon)}`
		}
	
	}

}
