import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';

@Component({
  selector: 'app-manage-comite-integrantes-modal',
  templateUrl: './manage-comite-integrantes-modal.component.html',
  styleUrls: ['./manage-comite-integrantes-modal.component.css']
})
export class ManageComiteIntegrantesModalComponent implements OnInit {
  faTimes = faTimes;
  myForm: FormGroup;
  oficiales: any[] = [];
  @Input() comiteId = 0;
  @Input() integrantes: any[] = [];

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild        : FormBuilder,
    private clientesService	: ClientesService,
    public activeModal      : NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.obtenerOficiales();
    this.initForm();
  }

  initForm() {
    this.myForm = this.formbuild.group({
      integrante: [null, [Validators.required]],
    });
  }

  obtenerOficiales() {
    this.clientesService.obtenerOficiales('', 'true')
    .then(res => {
      let oficiales = res['results'].filter(i => (this.isExistIntegrant(i) && i.email != 'Administrador'));
      oficiales.sort(function (a, b) {
        if (a.nombre > b.nombre) {
          return 1;
        }
        if (a.nombre < b.nombre) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      this.oficiales = oficiales;
    }).catch(error => {
      console.error(error);
      
    });
  }

  isExistIntegrant(integrante) {
    let isExit = true;
    this.integrantes.forEach(element => {
      if ( element.integrante === integrante.id ) isExit = false;
    });

    return isExit;
  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = {
        ...this.myForm.value,
        comite: this.comiteId
      }

      this.successEmit.emit(data);
      this.activeModal.close(true);
    }
  }

}
