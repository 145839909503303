import { Component, OnInit } from '@angular/core';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentsService } from 'app/core/services/documents/documents.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { ManageDocumentModalComponent } from '../modals/manage-document-modal/manage-document-modal.component';
import { functions } from '../../../core/helpers/functions';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
	public user             : any;
	public identidad        : any     = {};
	public searchstring     : string = '';
	public canCreate: boolean = false;

  constructor(
    private documentsService	: DocumentsService,
    public modalService     : NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerDocumentos();
  }

  obtenerDocumentos(
    page              = 1,
    page_size            = 10,
    descripcion__icontains       = '',
    grupo = '',
  ) {
      let documents  = this.documentsService.obtenerDocuments(page, page_size, descripcion__icontains, grupo);
      let grupos = this.documentsService.obtenerGrupos();

      Promise.all([documents, grupos]).then((res :any[])=>{
        let documents  = res[0];
        let grupos  = res[1]['results'];
        this.initVars(grupos);
        this.loadTable(documents);
      });
  }

  loadTable(data) {
    this.rowData = functions.indextable( data )
    // this.rowData           = data.results
    this.pageTableActual   = data.page_number
    this.pageTableTotal    = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }
    
  initVars(grupos) {
    let columns = [
      {
        headerName: 'N°', 
        field     : 'index' ,
        sortable  : true,
      },
      {
        headerName: 'Descripción', 
        field     : 'descripcion',
        class     : 'text-center',
        sortable  : true,
        filterable: true,
        filterProp: 'descripcion',
        filterInput: true,
      },
      {
        headerName: 'Grupo', 
        field     : 'grupo_descripcion',
        filterProp: 'grupo',
        sortable  : true,
        class     : 'text-center',
        filterable: true,
        filterSelectItems: grupos,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Tipo de persona', 
        field     : 'tipo_persona_nombre',
        class     : 'text-center',
      },
      {
        headerName: 'Días Vigencia', 
        field     : 'vigencia_dias',
        class     : 'text-center',
      },
    ];
  
    this.columnDefs = columns;
  }

  crear() {
    const modalRef = this.modalService.open(ManageDocumentModalComponent, {
    });

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerDocumentos();
		},
		error => {

		});
  }

  tableEditEvent(row) {
    const modalRef = this.modalService.open(ManageDocumentModalComponent, {
    });

    modalRef.componentInstance.documentId = row.id;

    modalRef.componentInstance

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });

    modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerDocumentos();
		},
		error => {

		});
  }

  goToPage({page,per_page,filtros}) {
    console.log(page, per_page);
    this.obtenerDocumentos(page, per_page, filtros.descripcion, filtros.grupo);
  }

  destroy(row) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Documento';
		modalRef.componentInstance.message = '¿Seguro que desea remover el Documento?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.documentsService.elimiarDocument(row.id)
			.then(result => {
			this.obtenerDocumentos();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

}
