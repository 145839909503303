import { Component, Input, OnInit } from '@angular/core';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';

@Component({
  selector: 'app-lineas-desglose',
  templateUrl: './lineas-desglose.component.html',
  styleUrls: ['./lineas-desglose.component.css']
})
export class LineasDesgloseComponent implements OnInit {
  listaDesgloses: any[];
  @Input() clienteID = 0;

  constructor(
    public propuestaService: PropuestaComercialService,

  ) { }

  ngOnInit(): void {
    var desglosesResponse;

    this.propuestaService.buscaBeneficiarioDesgloseLineas(this.clienteID)
    .then((res: any)=>{
      desglosesResponse = res
      let idsPromesa = [];
      let promesas = res.reduce((acc,item)=>{
        if(!idsPromesa.includes(item.aceptante)){
          idsPromesa.push(item.aceptante)
          acc.push(this.propuestaService.buscaAceptantesById(item.aceptante))
        }
        return acc
      },[])

      return Promise.all(promesas)
    })
    .then((aceptantes: any[])=>{
      this.listaDesgloses = 
      // Se asigna aceptantes a los desgloses
      desglosesResponse.map((desglose: any)=>{
        desglose.aceptante = aceptantes.find((acp)=> acp.id == desglose.aceptante)
        return desglose
      })
      //Se filtran los desgloses por la propuesta en la variable global propuesta
      // .filter((desglose)=> desglose.propuesta == 3)

      console.log(this.listaDesgloses)
      // if(this.desgloses.length == 0){
      //   this.displayMessage('Este aceptante aun no tiene desgloses')
      // }
    })
  }

  
}
