import { Component, Input, OnInit } from '@angular/core';
import { ClientesService } from 'app/core/services/clientes/clientes.service';

@Component({
  selector: 'app-sentinel',
  templateUrl: './sentinel.component.html',
  styleUrls: ['./sentinel.component.css']
})
export class SentinelComponent implements OnInit {

  @Input() ruc = '0'

  public dataSentinel = {}
  public dataSunat = {}
  public dataRcc = []
  public consultandoSunat = false
  public consultandoSentinel = false
  public consultandoRcc = false

  constructor(
    public clientesService: ClientesService,
  ) { }

  ngOnInit(): void {
    this.consultaSentinel()
    this.consultaSunat()
    this.consultaRcc()
  }

  consultaSentinel() {
    this.consultandoSentinel = true
    this.clientesService.getDataSentinel({ruc: this.ruc, page_size: 1}).subscribe(
      (data: any) => this.dataSentinel = data.results.length > 0 ? data.results[0] : {},
      res => console.log(res)
    ).add(() => this.consultandoSentinel = false)
  }

  consultaSunat() {
    this.consultandoSunat = true
    this.clientesService.getDataSunat({ruc: this.ruc, page_size: 1}).subscribe(
      (data: any) => this.dataSunat = data.results.length > 0 ? data.results[0] : {},
      res => console.log(res)
    ).add(() => this.consultandoSunat = false)
  }

  consultaRcc() {
    this.consultandoRcc = true
    this.clientesService.getDataRcc({ruc: this.ruc}).subscribe(
      (data: any) => {
        this.dataRcc = data.sort((a, b) => Number(b['periodo']) - Number(a['periodo']))
      },
      res => console.log(res)
    ).add(() => this.consultandoRcc = false)
  }

}
