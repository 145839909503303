import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
import { ModalZonaRiesgoComponent } from "./modals/modal-zona-riesgo/modal-zona-riesgo.component";
import { BaseLavadoActivosComponent } from '../../base-lavado-activos-component';
import { dateInput } from "app/shared/form-date/form-date.component";

@Component({
  selector: 'app-zonas-riesgo',
  templateUrl: './zonas-riesgo.component.html',
  styleUrls: ['./zonas-riesgo.component.css']
})
export class ZonasRiesgoComponent extends BaseLavadoActivosComponent implements OnInit {
  public requestService = (filters: Record<string, string>) => this.lavadoActivosService.obtenerZonaRiesgo(filters);
  public extraFunctionService = (data) => this.calcularPromedio(data)
  public editModal: any = ModalZonaRiesgoComponent;
  public dateInput     : dateInput;
  public isPeriodoButton: boolean = false;
  public isPeriodoButtonColor: any = 'btn-info'
  public periodos: any[] = [];
  @Input() selectedPeriodo: any;
  bsValue = new Date();

  constructor(
    public modalService: NgbModal,
    public authService: AuthService,
    private lavadoActivosService: LavadoActivosService
  ) { 
    super(authService, modalService)
  }
  ngOnInit(): void {
    this.setPeriodoFecha(this.getDate())
    this.buildInputDate();
  }
  buildInputDate(){
    this.dateInput = {
      bsConfig : {
        defaultDay: '01',
        minMode: 'month',
        adaptivePosition: true,
        dateInputFormat: 'MM-YYYY'
      }, 
      bsplaceholder : '00/0000',
      classes: ''
    }
  }
  getDate(){
    const year = this.bsValue.getFullYear();
    const month = this.bsValue.getMonth() + 1;
    const currentDate = `${year}-${month}-01`;
    return currentDate;
  }
  isPeriodo(periodo: any): void{
    this.isPeriodoButton = periodo.length === 0;
    this.isPeriodoButtonColor = this.isPeriodoButton ? 'btn-danger' : 'btn-info';
  }
  setPeriodo(periodo) {
    // Asignamos la respuesta
    this.selectedPeriodo = periodo;
    this.filters['periodo'] = periodo
  }
  async setPeriodoFecha(date: any): Promise<void> {
    // Seteamos periodo_fecha por la fecha obtenida
    this.filters['periodo__fecha'] = date
    try {
      const periodos = await this.lavadoActivosService.obtenerPeriodos(
        {page_size: '1', page: '1', fecha: date }
      );
      this.isPeriodo(periodos['results']);
      
      const resultado = await Promise.all([periodos]);
      const periodo = resultado[0]['results'][0];
      this.setPeriodo(periodo);
      this.filters['periodo'] = periodo['id']
    } catch (error) {
      // Manejar el error aquí
      console.error('Periodo no encontrado', );
    }
  }
  calcularNivelRiesgo(nivelRiesgo: any){
    if (nivelRiesgo <= 3) {
      return "Riesgo Bajo"
    }else if (nivelRiesgo <= 6){
      return "Riesgo Medio"
    }else if (nivelRiesgo <= 12){
      return "Riesgo Alto"
    }else if (nivelRiesgo >= 12){
      return "Riesgo Extremo"
    }
  }

  calcularPromedio(data: any[]){
    let list = data["results"].map((element)=>{
      
      var suma_riesgo = element.lugar_residencia + element.lugar_sucursal + element.lugar_operacion
      var promedio_riesgo = Math.round(suma_riesgo / 3)
      
      return {
        ...element,
        "lugar_residencia": this.calcularNivelRiesgo(element.lugar_residencia),
        "lugar_sucursal": this.calcularNivelRiesgo(element.lugar_sucursal),
        "lugar_operacion": this.calcularNivelRiesgo(element.lugar_operacion),
        "promedio_riesgo": promedio_riesgo,
      }
    })
    return list
  }

  initVars() {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        headerName: "Zona",
        field: "descripcion",
        sortable: true,
      },
      {
        headerName: "Lugar de operación",
        field: "lugar_operacion",
        sortable: true,
      },
      {
        headerName: "Lugar de sucursal",
        field: "lugar_sucursal",
        sortable: true,
      },
      {
        headerName: "Lugar de residencia",
        field: "lugar_residencia",
        sortable: true,
      },
      {
        headerName: "Promedio",
        field: "promedio_riesgo",
        sortable: true,
      },
      {
        headerName: "Actualizado por",
        field: "usuario_actualizacion",
        sortable: true,
      },
      {
        headerName: "Fecha de actualización",
        field: "fecha_actualizacion",
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }

  rowColor(row: any) {
    if (row.promedio_riesgo <= 3) {
      return '#92d0506b';
    }else if (row.promedio_riesgo <= 6){
      return '#ffc0006e';
    }else if (row.promedio_riesgo <= 12){
      return '#ff00007a';
    }else if (row.promedio_riesgo >= 12){
      return '#c0000080';
    }
  }
}
