import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { AuthService } from "app/core/services/auth/auth.service";
import { AceptantesService } from "app/core/services/factoring/aceptantes.service";
import { ConfirmModalComponent } from "app/shared/utils/confirm-modal/confirm-modal.component";
import { AddContactModalComponent } from "../../modals/add-contact-modal/add-contact-modal.component";

@Component({
  selector: "app-add-aceptante-contacts",
  templateUrl: "./contacts.component.html",
  styleUrls: ["./contacts.component.css"],
})
export class ContactsComponent implements OnInit {
  public user: any;
  public headerTable: columnHeader[];
  public rowData: any[] = [];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public searchstring: string = "";

  @Input() aceptanteId = 0;
  @Input() readonly = false;

  constructor(
    private modalService: NgbModal,
    public localeService: BsLocaleService,
    private aceptantesService: AceptantesService,
    private authService: AuthService
  ) {
    defineLocale("es", esLocale);
    this.localeService.use("es");
    this.user = this.authService.user;
  }

  ngOnInit(): void {
    this.initTables();
    this.obtenerContactos(1, 10);
  }

  async obtenerContactos(page = 1, page_size: 10) {
    return await this.aceptantesService
      .obtenerContactos(this.aceptanteId, page, page_size)
      .then((documents: any) => {
        this.loadTable(documents);
      });
  }

  initTables() {
    let headerTable = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        headerName: "Contacto",
        field: "nombre",
        sortable: true,
      },
      {
        headerName: "Teléfono",
        field: "telefono",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Email",
        field: "email",
        sortable: true,
        class: "text-center",
      },
      {
        headerName: "Cargo",
        field: "cargo",
        sortable: true,
      },
      {
        headerName: "Rep. Legal",
        field: "representante_legal",
        sortable: true,
        pipe: "checkbox",
        checkDisabled: true,
      },
      {
        headerName: "Nacionalidad",
        field: "nacionalidad_nombre",
        class: "text-center",
      },
    ];

    this.headerTable = headerTable;
  }

  loadTable(data) {
    this.rowData = data.results?.map((el: any, i: number) => ({
      ...el,
      index: i + 1,
    }));

    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  goToPage({ page, per_page }) {
    this.obtenerContactos(page, per_page);
  }

  tableEditEvent(contact) {
    const modalRef = this.modalService.open(AddContactModalComponent, {
      size: "lg",
    });

    modalRef.componentInstance.aceptanteId = this.aceptanteId;
    modalRef.componentInstance.contactId = contact.id;

    modalRef.result.then(() => {});

    modalRef.componentInstance.successEmit.subscribe(() => {
      this.obtenerContactos(1, 10);
    });
  }

  openModal() {
    const modalRef = this.modalService.open(AddContactModalComponent, {
      size: "lg",
    });

    modalRef.componentInstance.aceptanteId = this.aceptanteId;

    modalRef.result.then(() => {});

    modalRef.componentInstance.successEmit.subscribe(() => {
      this.obtenerContactos(1, 10);
    });
  }

  destroyContact(contact) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {});

    modalRef.componentInstance.title = "Remover Contacto";
    modalRef.componentInstance.message =
      "¿Seguro que desea remover el contacto?";

    modalRef.result.then((result) => {
      if (result) {
        this.aceptantesService
          .eliminarContacto(contact.id)
          .then(() => {
            this.obtenerContactos(1, 10);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  }
}
