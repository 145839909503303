import { FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms"
export const DateContrato: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  const startDate = control.get("fecha_contrato")
  const endDate = control.get("fecha_vencimiento")

  return endDate.value > startDate.value
    ? null
    : { dateContrato: true }
}


export const DateContratoPuntual: ValidatorFn = (
  control: FormGroup
): ValidationErrors | null => {
  const startDate = control.get("fecha_contrato")
  const endDate = control.get("fecha_vencimiento")
  let inicio = new Date(startDate.value)
  let fin = new Date(endDate.value)

  inicio.setHours(0,0,0,0)
  fin.setHours(0,0,0,0)



  let  valor = inicio.getDate() == fin.getDate() || endDate.value > startDate.value
  return valor
    ? null
    : { dateContrato: true }
}
