import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { AceptantesService } from "app/core/services/factoring/aceptantes.service";

@Component({
  selector: "app-add-aceptante-add-entidad-vinculo-modal",
  templateUrl: "./add-entidad-vinculo-modal.component.html",
  styleUrls: ["./add-entidad-vinculo-modal.component.css"],
})
export class AddEntidadVinculoModalComponent implements OnInit {
  public myForm: FormGroup;
  public paises: any[];
  public vinculo: any;
  public personas: any[];
  faTimes = faTimes

  @Input() aceptanteId = 0;
  @Input() vinculoId = 0;
  @Input() directo = false;
  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild: FormBuilder,
    public activeModal: NgbActiveModal,
    private aceptantesService: AceptantesService
  ) {
    this.initForm();
  }

  ngOnInit(): void {
    this.getTiposPersonas();

    if (this.vinculoId) {
      this.obtenerVinculo();
    }
  }

  getTiposPersonas() {
    this.aceptantesService
      .tiposPersonas()
      .then((res) => {
        this.personas = res["results"];
      })
      .catch((error) => {
        console.error(error);
      });
  }

  obtenerVinculo() {
    // this.aceptantesService
    //   .obtenerVinculo(this.vinculoId)
    //   .then((res) => {
    //     this.vinculo = res;
    //     this.setForm(res);
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //   });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      nombre: [null, [Validators.required]],
      tipo_persona: [null, [Validators.required, Validators.maxLength(50)]],
      dni_ruc: [null, []],
      aporte: [null, []],
      participacion: [null, []],
      gestion: [false, []],
      codigo_sbs: [null, []],
      cargo: [null, []],
    });
  }

  get f() {
    return this.myForm.controls;
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = this.myForm.value;
      data["aceptante"] = this.aceptanteId;
      data["directo"] = this.directo ? 1 : 0;

      // this.aceptantesService
      //   .guardarVinculo(data, this.vinculoId)
      //   .then((res) => {
      //     this.successEmit.emit(res);
      //     this.activeModal.close(false);
      //   })
      //   .catch((error) => {
      //     this.activeModal.close(false);
      //   });
    }
  }
}
