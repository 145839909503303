import { Component, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
import { ModalVariableRiesgoComponent } from "./modals/modal_variable_riesgo.component";
import { BaseLavadoActivosComponent } from "../../base-lavado-activos-component";

@Component({
    selector: 'app-variables_riesgo',
    templateUrl: './variables_riesgo.component.html',
    styleUrls: ['./variables_riesgo.component.css']
})
export class VariablesRiesgoComponent extends BaseLavadoActivosComponent{
  public requestService = (filters) => this.lavadoActivosService.obtenerVariablesRiesgo(filters);
  public editModal: any = ModalVariableRiesgoComponent;
  public variableRiesgo: any;
  private modalConfirmDeleteReference: any;
  @ViewChild("e_unabledelete", { static: false }) public e_unabledelete;
  @ViewChild("e_confirmdelete", { static: false }) public e_confirmdelete;

  constructor(
    public modalService: NgbModal,
    public authService: AuthService,
    private lavadoActivosService: LavadoActivosService,
  ) {
    super(authService, modalService);
  }


  addVariableRiesgo(){
    this.openModal(ModalVariableRiesgoComponent, {'variableRiesgo': null})
  }

  initVars() {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        headerName: "Descripción",
        field: "descripcion",
        sortable: true,
        filterable: true,
        filterProp: "descripcion", // Nombre de la propiedad a filtrar
        filterInput: true,
      }
      
    ];

    this.columnDefs = columns;
  }

  deleteActionTable(row: any){
    this.variableRiesgo = row;
    this.modalConfirmDeleteReference = this.modalService.open(
      this.e_confirmdelete,
      {
        size: "md",
      }
    );
    
  }
  confirmDelete() {
    this.lavadoActivosService
      .deleteVariableRiesgo(this.variableRiesgo.id)
      .then(() => {
        this.modalConfirmDeleteReference.close();
        this.variableRiesgo = null;
        this.executeService();
      });
  }
  isDeleteAction(row: any){
    return row.es_dinamico ? false: true
  }

  cancelDelete() {
    this.variableRiesgo = null;
    this.modalConfirmDeleteReference.close();
  }

  goToPage({ page, per_page, filtros }) {
    this.filters = {
      page: page,
      page_size: per_page,
    }
    if (filtros.tipo){
      this.filters['tipos'] = filtros.tipo
    }
    if (filtros.descripcion){
      this.filters['descripcion'] = filtros.descripcion
    }
    this.executeService()
  }
}