import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ResultadoFacturasModalComponent } from '../../modals/resultado-facturas-modal/resultado-facturas-modal.component';
import { CavaliService } from 'app/core/services/cavali/cavali.service';

@Component({
  selector: 'app-procesos-correctos',
  templateUrl: './procesos-correctos.component.html',
  styleUrls: ['./procesos-correctos.component.css']
})
export class ProcesosCorrectosComponent implements OnInit {

  @Input() procesosCavali = []
  
  tblRows: any[] = []
  tblHeaders: columnHeader[]
  tblTotalRows: number
  tblPages: number[]
  tblActualPage: number = 1
  tblPerPage: number = 10
  tblFiltros = {}
  tblAcciones = []
  
  constructor( 
    public modalService: NgbModal,
    private spinner: NgxSpinnerService,
    public cavaliService: CavaliService,
  ) {}


  ngOnInit(): void {
    this.loadTableColumns()
    this.loadDataTable({})
  }

  loadTableColumns() {
    this.tblAcciones = [
      {
        tooltip: 'Detalle', icon: 'fa fa-eye', class: 'btn-info',
        click: row => this.openModalDetalle(row),
      }
    ]
    this.tblHeaders = [
      {
        headerName: 'ID',
        class: 'text-center',
        field: 'id',
        sortable: true
      },
      {
        headerName: 'Código',
        class: 'text-center',
        field: 'codigo',
        sortable: true,
        filterable: true,
        filterProp: 'codigo__icontains',
        filterInput: true,
      },
      {
        headerName: 'Proceso',
        class: 'text-center',
        field: 'codigo_descripcion',
        sortable: true,
        filterable: true,
        filterProp: 'codigo',
        filterSelectItems: this.procesosCavali,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Fecha',
        class: 'text-center',
        field: 'fecha',
        sortable: true,
        pipe: 'function',
        function: function (row) {
					return `${row.fecha.split('-').reverse().join('/')} ${row.hora.slice(0,8)}`
				},
        filterable: true,
        filterProp: 'fecha',
        filterDate: true,
      },
      {
        headerName: 'Identificador',
        class: 'text-center',
        field: 'identificador',
        sortable: true,
        filterable: true,
        filterProp: 'identificador__icontains',
        filterInput: true,
      },
      {
        headerName: 'Respuesta',
        class: 'text-center',
        field: 'mensaje_respuesta',
        sortable: true
      },
      {
        headerName: 'R. Servicio',
        class: 'text-center',
        field: 'resultado__action_code_label',
        sortable: true
      },
      {
        headerName: 'R. Fecha Acción',
        class: 'text-center',
        field: 'resultado__action_date',
        sortable: true,
        pipe: 'function',
        function: function (row) {
					if (row.resultado__action_date) return `${row.resultado__action_date.split('-').reverse().join('/')} ${row.resultado__action_time}`
          else return ''
				},
        filterable: true,
        filterProp: 'resultado__action_date',
        filterDate: true,
      },
      {
        headerName: 'Operación',
        class: 'text-center',
        field: 'operacion',
        sortable: true,
        filterable: true,
        filterProp: 'operacion',
        filterInput: true,
      },
      {
        headerName: 'Documentos',
        class: 'text-center',
        field: 'numeros_documentos',
        sortable: true,
        filterable: true,
        filterProp: 'numero_documento',
        filterInput: true,
      },
    ]
  }

  parseData(data) {
    return data.map(e => {
      return {...e, ...{
        resultado__application_code: e?.resultado?.application_code,
        resultado__application_user: e?.resultado?.application_user,
        resultado__action_code: e?.resultado?.action_code,
        resultado__action_code_label: e?.resultado?.action_code_label,
        resultado__action_date: e?.resultado?.action_date,
        resultado__action_time: e?.resultado?.action_time,
      }}
    })
  }

  loadDataTable({ page = this.tblActualPage, per_page = this.tblPerPage, filtros = this.tblFiltros }) {
    this.spinner.show()
    Object.keys(filtros).filter(field => field.includes('fecha')).forEach(field => filtros[field] = filtros[field].split(' ')[0])
    filtros['tiene_error'] = false
    filtros['tiene_resultado'] = true
    this.tblFiltros = filtros
    this.cavaliService.getProcesos(Object.assign({ page, page_size: per_page }, filtros)).subscribe(
      (data: any) => {
        this.tblRows = this.parseData(data.results)
        this.tblTotalRows = data.count
        this.tblPages = Array.from(Array(data.num_pages).keys())
        this.tblPerPage = data.per_page
        this.tblActualPage = data.page_number
      },
      (res: any) => console.log(res)
    ).add(() => this.spinner.hide())
  }

  openModalDetalle(proceso) {
    const cavaliModal = this.modalService.open(ResultadoFacturasModalComponent, {size: "xl",})
    cavaliModal.componentInstance.proceso = proceso.id
  }

}
