import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MonedaPipe } from 'app/core/pipes/moneda.pipe';
import { AuthService } from 'app/core/services/auth/auth.service';
import { RestriccionesService } from 'app/core/services/auth/restricciones.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

@Component({
	selector: 'app-reporteliquidacion',
	templateUrl: './reporteliquidacion.component.html',
	styleUrls: ['./reporteliquidacion.component.css'],
	providers: [MonedaPipe, NgbActiveModal]
})
export class ReporteliquidacionComponent implements OnInit {
	@ViewChild('modificarLiquidacion', { static: false }) public modificarLiquidacion;
	@ViewChild('modificarDatoLiquidacion', { static: false }) public modificarDatoLiquidacion;
	@ViewChild('e_tipocambio', { static: false }) public e_tipocambio;
	@ViewChild('e_documentscartera', { static: false }) public e_documentscartera;

	faPencilAlt = faPencilAlt;
	faTrash = faTrash;
	public formularioLiquidacion: FormGroup;
	public formularioLiquidaciones: FormGroup[];
	public tiposCambio: any[] = [];
	public monedas: any[];
	public comisiones: any[];
	public gastos: any[];
	public minDate: Date;
	public liquidacionDate: Date;
	public documentos: any[];
	public columns: any[];
	public columnasConcepto: any[];
	public _liquidacion: any;
	public set liquidacion(value) {
		if (value.liquidacion && value.liquidacion.comite_aprobaciones) {
			value.liquidacion.comite_aprobaciones.forEach((miembro: any) => {
				miembro.fecha = this.commentDate(miembro.fecha)
				console.log(miembro)
			})
			console.log(value.liquidacion.comite_aprobaciones)
		}
		this._liquidacion = value
	}

	public get liquidacion() {
		return this._liquidacion
	}
	public liquidacionSaved: boolean = false;
	public liquidacionEditable: any;
	public rowEditable: any;
	public monedaOperacion: any;
	public updatePrefix: boolean = true;
	public motivoliquidacion: string;
	public value = 0;
	public montoDiferencia = 0;
	public diferenciaToggle: boolean = true;
	public temporalEditable: any;
	_idOperacion: number;
	prefix: string = 'S/ '
	totalColor: boolean = false;
	public existeTipoCambio: boolean = true;
	@Input() set idOperacion(value) {
		this._idOperacion = value
	}


	@Input() readOnlyLiquidacion;
	@Input() operacion: any;

	private modalDocumentsCarteraReference: any;
	public documentsCarteraList: any[] = [];
	public documentsCarteraSelectedList: any[] = [];
	public documentsCarteraSelectionList: any[] = [];
	public headerDocumentsCarteraTable: columnHeader[];
	public pageDocumentsCarteraTableActual: number;
	public pageDocumentsCarteraTableTotal: number;
	public pageDocumentsCarteraTablePerPages: number = 10;
	public pageDocumentsCarteraTablePages: number[];

	public headerCuentasCobrarTable: columnHeader[];
	montoAdelanto = 0;

	docsCarteraSelect: any = [];
	docsCuentasCobrar: any = [];

	cxcList: any = [];
	tiposFirmas: any[] = [];
	propuestaComite: any;

	constructor(
		public route: ActivatedRoute,
		private configs: DocschecklistService,
		private propuestaService: PropuestaComercialService,
		public modalService: NgbModal,
		private router: Router,
		private formbuild: FormBuilder,
		public toast: ToastService,
		private factoring: FactoringService,
		public authService: AuthService,
		public localService: LocalServiceService,
		public monedapipe: MonedaPipe,
		public restricciones: RestriccionesService,
		private recaudacionService: RecaudacionService,
		public activeModal: NgbActiveModal,
		public sharedFunctions: SharedFunctions
	) {

		let data = this.localService.getJsonValue("RLiquidacion")
		this.initComponenteData(data)
	}

	initComponenteData(data = null) {
		this.initform();

		if (!!data) {
			this.operacion = data.operacion
			this.idOperacion = data.operacion.id
		} else {
			return
		}

		let monedas = this.configs.obtenerMonedas();
		let tiposDeCambio = this.configs.obtenerTiposDeCambio(this.formatDate(new Date()));
		let tiposFirmas = this.propuestaService.obtenerTiposFirmas();
		Promise.all([monedas, tiposDeCambio, tiposFirmas]).then((res: any[]) => {
			this.monedas = res[0]['results']
			this.tiposFirmas = res[2]['results']
			let cambios = res[1]['results'][0]
			if (cambios) {
				this.tiposCambio.push({
					descripcion: 'Contable',
					valor: cambios.contable
				})

				this.tiposCambio.push({
					descripcion: 'Compra',
					valor: cambios.compra
				})

				this.tiposCambio.push({
					descripcion: 'Venta',
					valor: cambios.venta
				})
			} else {
				this.existeTipoCambio = false
			}

			return this.propuestaService.ObtenerSimulacionDeLiquidacion(this._idOperacion)
		}).then((liquidacion: any) => {
			this.liquidacion = liquidacion
			// this.liquidacion.liquidacion = liquidacion.liquidaciones[0]
			this.liquidacionDate = this.parseDate(liquidacion.fecha_desembolso_programado);
			this.liquidacionSaved = true
			if (this.liquidacion.liquidaciones.length > 1) {
				this.montoAdelanto = this.liquidacion.liquidaciones[1].monto_desembolso;
			} else if (this.liquidacion.liquidaciones.length === 1) {
				this.montoAdelanto = this.liquidacion.adelanto;
			}

			this.cargarLiquidacion();
			this.initTables()
			this.crearSimulacion();
		}).catch((error) => {
			console.log(error)
		}).finally(() => {
			setTimeout(() => {
				window.print()
			}, 5000)
		});
		this.initform();
	}

	ngOnInit(): void {
	}

	showAdelantos(index): boolean {

		if (this.liquidacion.liquidaciones.length === 1) {
			return true;
		}

		if (this.liquidacion.liquidaciones.length > 1 && index === 2) {
			return true;
		} else {
			return false;
		}
	}

	commentDate(fecha) {

		if (!fecha) {
			return
		}

		let yy = fecha.split('-')[0];
		let mm = fecha.split('-')[1];
		let dd = fecha.split('-')[2];
		dd = dd.split('T')[0]

		let hora = Number(fecha.split('T')[1].split(':')[0])
		let momento = (hora >= 12) ? "PM" : "AM";
		hora = (hora >= 12) ? hora - 12 : hora
		let minuto = fecha.split('T')[1].split(':')[1]
		let hour = `  ${hora}:${minuto} ${momento}`

		let date = `${dd}/${mm}/${yy} ${hour}`;
		return date
	}



	parseDate(date) {
		if (!date)
			return null;

		let dateNow = new Date();

		let yy = date.split('-')[0];
		let mm = (date.split('-')[1] - 1);
		let dd = date.split('-')[2];

		let d = new Date(yy, mm, dd, dateNow.getHours());
		return d;
	}

	initform() {
		this.minDate = new Date();

		this.formularioLiquidacion = this.formbuild.group({
			tipo_cambio: ['', [Validators.required]],
			fecha_desembolso: [new Date(), [Validators.required]],
			moneda: ['', [Validators.required]],
			tem_soles: [{ value: '', disabled: true }, []],
			tea_soles: [{ value: '', disabled: true }, []],
			anticipo: [{ value: '', disabled: true }, []],
			tem_dolares: [{ value: '', disabled: true }, []],
			tea_dolares: [{ value: '', disabled: true }, []],
			tipo_firma_contrato: [1, []],
		});
	}

	crearSimulacion() {

		if (!this.existeTipoCambio) {
			alert('No hay tipo de cambio')
			return
		}

		if (this.formularioLiquidacion.invalid)
			return

		if (this.liquidacion && this.liquidacion.liquidacion.activo) {
			return;
		}

		let tipo_cambio = this.formularioLiquidacion.get('tipo_cambio').value;
		let moneda = this.formularioLiquidacion.get('moneda').value;
		let fecha_desembolso = this.formularioLiquidacion.get('fecha_desembolso').value;
		let tipo_firma_contrato = this.formularioLiquidacion.get('tipo_firma_contrato').value;

		const data = {
			operacion: this._idOperacion,
			tipo_cambio: tipo_cambio,
			fecha_desembolso: this.formatDate(fecha_desembolso),
			moneda: moneda,
			tipo_firma_contrato: tipo_firma_contrato,
		};

		this.propuestaService.crearSimulacionDeLiquidacionMethod2(
			data
		).then((res: any) => {
			this.liquidacion = res
			this.liquidacion.liquidacion = res.liquidaciones[0]
			this.monedaOperacion = this.liquidacion.liquidacion.moneda
			this.setPrefix(this.liquidacion.liquidacion.moneda)
			this.formularioLiquidacion.get('anticipo').setValue(res.anticipo_comite)
			this.formularioLiquidacion.get('tem_soles').setValue(res.tem_soles_comite)
			this.formularioLiquidacion.get('tea_soles').setValue(res.tea_soles_comite)
			this.formularioLiquidacion.get('tem_dolares').setValue(res.tem_dolares_comite)
			this.formularioLiquidacion.get('tea_dolares').setValue(res.tea_dolares_comite)
			this.loadTableColumns()
		}).catch(error => {
			if (error.error.fecha_desembolso)
				this.toast.warning(error.error.fecha_desembolso);
			// this.formularioLiquidacion.get('fecha_desembolso').setValue(new Date());
		});

	}


	formatDate(date) {
		if (!(date instanceof Date))
			return date;

		return date.getFullYear() +
			"-" + (date.getMonth() + 1) +
			"-" + date.getDate();
	}

	setPrefix(moneda) {
		this.updatePrefix = false
		setTimeout(() => {
			if (moneda === 1) {
				this.prefix = 'S/ '
			} else {
				this.prefix = '$ '
			}
			this.updatePrefix = true
		}, 500);
	}

	loadTableColumns() {
		this.columns = [
			{
				headerName: 'N°',
				pipe: 'indexcol',
				sortable: true
			},
			{
				headerName: 'Aceptante',
				class: 'text-center liquidacion-aceptante',
				field: 'aceptante',
				sortable: true
			},
			{
				headerName: 'TEM',
				field: 'tem',
				sortable: true,
				class: 'text-center',
				pipe: 'function',
				function: function (row) {
					return row.tem
				},
				postfix: 'percent'
			},
			{
				headerName: 'Documento',
				field: 'numero_documento',
				class: 'text-center',
				sortable: true
			},
			{
				headerName: 'Vcto CAVALI',
				field: 'vencimiento_cavali',
				sortable: true,
				class: 'text-center',
				pipe: 'date'
			},
			{
				headerName: 'Dias',
				field: 'dias_financiados',
				class: 'text-center',
				sortable: true,
			},
			{
				headerName: 'Monto',
				field: 'monto_total',
				sortable: true,
				class: 'text-center',
				pipe: 'currency',
				moneda: 'moneda',
			},
			{
				headerName: 'Neto pagar',
				pipe: 'currency',
				class: 'text-center',
				field: 'neto_pagar',
				sortable: true,
				moneda: 'moneda',
			},
			{
				headerName: 'No Financiado',
				class: 'text-center',
				field: 'monto_no_financiado',
				pipe: 'currency_detail',
				sortable: true,
				moneda_detail: this.liquidacion.moneda,
			},
			{
				headerName: 'Financiado',
				field: 'monto_financiado',
				class: 'text-center',
				pipe: 'currency_detail',
				sortable: true,
				moneda_detail: this.liquidacion.moneda,
			},
		];
	}

	cargarLiquidacion() {
		// console.log(this.liquidacion)
		// this.monedaOperacion = this.liquidacion.liquidacion.moneda;
		// this.formularioLiquidacion.get('tipo_cambio').setValue(this.liquidacion.liquidacion.tipo_cambio_monto);
		// this.formularioLiquidacion.get('moneda').setValue(this.liquidacion.liquidacion.moneda);
		// this.formularioLiquidacion.get('moneda').disable()
		// this.formularioLiquidacion.get('fecha_desembolso').setValue(this.liquidacion.fecha_desembolso_programado)
		// this.formularioLiquidacion.get('anticipo').setValue(this.liquidacion.anticipo_comite);
		// this.formularioLiquidacion.get('tem_soles').setValue(this.liquidacion.tem_soles_comite);
		// this.formularioLiquidacion.get('tea_soles').setValue(this.liquidacion.tea_soles_comite);
		// this.formularioLiquidacion.get('tem_dolares').setValue(this.liquidacion.tem_dolares_comite);
		// this.formularioLiquidacion.get('tea_dolares').setValue(this.liquidacion.tea_dolares_comite);
		// this.formularioLiquidacion.get('tipo_firma_contrato').setValue(this.liquidacion.liquidacion.tipo_firma_contrato);
		this.setPrefix(this.liquidacion.moneda);
		this.loadTableColumns();

		this.formularioLiquidaciones = this.liquidacion.liquidaciones.map((item, index) => {
			return new FormGroup({
				tipo_cambio: new FormControl({ value: item.tipo_cambio_monto, disabled: true }, Validators.required),
				fecha_desembolso: new FormControl({ value: this.parseDate(item.fecha_desembolso_programado), disabled: true }, Validators.required),
				moneda: new FormControl({ value: item.moneda_descripcion, disabled: true }, Validators.required),
				tem_soles: new FormControl({ value: this.liquidacion.tem_soles_comite, disabled: true }, Validators.required),
				tea_soles: new FormControl({ value: this.liquidacion.tea_soles_comite, disabled: true }, Validators.required),
				anticipo: new FormControl({ value: item.anticipo, disabled: true }, Validators.required),
				tem_dolares: new FormControl({ value: this.liquidacion.tem_dolares_comite, disabled: true }, Validators.required),
				tea_dolares: new FormControl({ value: this.liquidacion.tea_dolares_comite, disabled: true }, Validators.required),
				tipo_firma_contrato: new FormControl({ value: item.tipo_firma_contrato, disabled: true }, Validators.required)
			})
		})

		// let form = this.formbuild.group({
		// 	tipo_cambio     : ['',[Validators.required]],
		// 	fecha_desembolso: [new Date(),[Validators.required]],
		// 	moneda          : ['',[Validators.required]],
		// 	tem_soles       : [{value: '',disabled: true},[]],
		// 	tea_soles       : [{value: '',disabled: true},[]],
		// 	anticipo        : [{value: '',disabled: true},[]],
		// 	tem_dolares     : [{value: '',disabled: true},[]],
		// 	tea_dolares     : [{value: '',disabled: true},[]],
		// 	tipo_firma_contrato: [1 ,[]],
		// });

	}

	// get noFinanciado() {
	// 	if (!this.liquidacion)
	// 		return 0;

	// 	let financiado = this.liquidacion.liquidaciones.reduce((acc, act) => {
	// 		return parseFloat(acc) + parseFloat(act.monto_financiado);
	// 	}, 0);

	// 	let amount = this.liquidacion.neto_pagar - financiado;
	// 	return amount.toFixed(2);
	// }

	initTables() {
		let headerDocumentsCarteraTable = [
			{
				class: "text-center",
				headerName: "Tipo",
				field: "operacion_tipo_detalle_descripcion",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Documento",
				field: "operacion_numero_documento",
			},
			{
				class: "text-center",
				headerName: "Monto",
				field: "monto_neto",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Vencimiento",
				field: "fecha_vencimiento",
				pipe: "date",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Saldo",
				field: "saldo",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "Mora",
				field: "intereses_mora",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "IGV int.",
				field: "igv_intereses",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "Int. dev.",
				field: "intereses_devolver",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "T.C.",
				field: "tipo_cambio_monto",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Deuda",
				field: "deuda",
				pipe: "currency",
				moneda: "moneda_pago",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "Abono",
				field: "pago",
				pipe: "currency",
				moneda: "moneda_pago",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
		];

		let headerCuentasCobrarTable = [
			{
				class: "text-center",
				headerName: "Concepto",
				field: "concepto_descripcion",
			},
			{
				headerName: "Nro°",
				field: "id",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Monto",
				field: "monto",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "IGV",
				field: "igv",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Total",
				field: "total",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Saldo",
				field: "saldo",
				pipe: "currency",
				moneda: "moneda",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "T.C.",
				field: "tipo_cambio_monto",
				sortable: true,
			},
			{
				class: "text-center",
				headerName: "Deuda",
				field: "deuda",
				pipe: "currency",
				moneda: "moneda_pago",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
			{
				class: "text-center",
				headerName: "Abono",
				field: "pago",
				pipe: "currency",
				moneda: "moneda_pago",
				sortable: true,
				sumCol: true,
				tipoCambioField: 'tipo_cambio_monto',
				moneda_detail: this.operacion.moneda,
			},
		];

		this.headerCuentasCobrarTable = headerCuentasCobrarTable;

		this.headerDocumentsCarteraTable = headerDocumentsCarteraTable;
	}
}
