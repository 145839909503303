import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { LegalService } from 'app/core/services/legal.service';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-formato-acuerdo-llenado',
  templateUrl: './formato-acuerdo-llenado.component.html',
  styleUrls: ['./formato-acuerdo-llenado.component.css']
})
export class FormatoAcuerdoLlenadoComponent implements OnInit {

  myForm: FormGroup;
  
  @Input() beneficiario: number;
  @Input() beneficiario_tipo;
  @Input() formato_codigo;
  @Input() formato_plantilla_url;
  @Input() numero_contrato;
  @Input() fecha_vencimiento;
  @Input() operacion;
  @Input() contrato;

  cliente: any;
  representantes: any[] = [];
  linea: any;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private formatoService: FormatosService,
    private clientesService: ClientesService,
    public toast: ToastService,
    private propuestaService  : PropuestaComercialService,
    private legalService	: LegalService,
  ) { }

  async ngOnInit() {

    this.createForm();

    await this.clientesService.obtenerLinea(this.operacion.linea_credito).then(resp => {
      console.log(resp);
      this.linea = resp;
    }, err => {
      console.error(err);
    });

    this.legalService.obtenerFirmantes(this.contrato.id).then(resp => {
      console.log(resp);
      this.representantes = resp['results'];
    }, err => {
      console.error(err);
      
    });

    this.clientesService.obtenerCliente(this.linea.beneficiario).then((cliente: any) => {
      this.cliente = cliente;
    });

  }

  createForm() {
    this.myForm = this.formBuilder.group({
      
    });
  }

  onSubmit() {
    const data = {
      tipo_producto       : this.operacion.operacion.tipo_linea_solicitada.toString(),
      contrato            : this.numero_contrato,
      cliente_nombre      : this.cliente.nombre,
      cliente_inscripcion : this.cliente.numero_partida,
      domicilio           : this.cliente.domicilio_legal,
      cliente_ruc         : this.cliente.ruc,
      representantes      : this.representantes.map(data => {
        return {
          nombre: data.firmante_nombre,
          dni: data.nro_documento
        }
      }),
      distrito            : this.cliente.distrito_nombre,
      provincia           : this.cliente.provincia_nombre,
      departamento        : this.cliente.departamento_nombre,
      oficina_registral   : this.cliente.oficina_registral_nombre ? this.cliente.oficina_registral_nombre : '',
      zona_registral      : this.cliente.zona_registral_nombre ? this.cliente.zona_registral_nombre : ''
    };

    console.log(data);

    this.formatoService.generarFormatoAcuerdoLlenado(data).then(
      (x: any) => {
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "Acuerdo de Llenado.docx";
        link.click();
        this.activeModal.close(data);
      }, async (reason) => {
        this.activeModal.close(data);
        console.log(reason);
        const message = JSON.parse(await reason.error.text());
        console.log(message);
        this.showModalErrorContrato(message['detail']);
      });

  }

  showModalErrorContrato(message) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {

    });

    modalRef.componentInstance.showBottomLeft = false;
    modalRef.componentInstance.title = 'Información requerida en el contrato marco';
    modalRef.componentInstance.titleRightBottom = 'Aceptar';
    modalRef.componentInstance.messageHTML = true;
    modalRef.componentInstance.message = `<span class="">${message}</span>`;
  }

}
