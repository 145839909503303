import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { LocalServiceService } from 'app/core/services/local-service.service';

@Component({
  selector: 'app-reporteverificacionpagina',
  templateUrl: './reporteverificacionpagina.component.html',
  styleUrls: ['./reporteverificacionpagina.component.css']
})
export class ReporteverificacionpaginaComponent implements OnInit {
  public fecha_pago__gte: any;
  public fecha_pago__lte: any;
  public data: any;
  colReporte: any;
  constructor(
		private activatedRoute: ActivatedRoute,
    private sanitized: DomSanitizer,
		public localService   : LocalServiceService,

  ) { 
    let param            = this.localService.getJsonValue('reporteverificaciontabla')
    this.fecha_pago__gte = param.fecha_pago__gte
    this.fecha_pago__lte = param.fecha_pago__lte
    this.data            = param.res
  }

  ngOnInit(): void {
    this.colReporte = [
			{
				headerName: 'Número Pago',
				field: 'numero_pago',
			},
			{
				headerName: 'Moneda',
				field: 'moneda',
				sortable: true,
				// defaultInfo: 'Aún no aprobado',
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					if(row.moneda == 1){
						return 'Soles'
					} else {
						return 'Dólares'
					}
				},
			},
			{
				headerName: 'Fecha Pago',
				field: 'fecha_pago',
				sortable: true,
				// defaultInfo: 'Aún no aprobado',
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					if(!row.fecha_pago){
						return ''
					}
					let hora_dia = 'AM';
					let fecha = row.fecha_pago.split('-')
					let ano = fecha[0]
					let mes = fecha[1]
					let dia = fecha[2].split('T')[0]
					return `${dia}/${mes}/${ano}`
				},
			},
			{
				headerName: 'Abonado Anterior',
				field: 'abonado_anterior',
				sortable: true,
				pipe      : 'function',
				class: 'text-right',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.deuda_saldo) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.deuda_saldo))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.deuda_saldo) return ''
						retorno = `$ ${Number(row.deuda_saldo).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName: 'Abonado',
				field: 'monto_abonado',
				sortable: true,
				pipe      : 'function',
				class: 'text-left',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.monto_abonado) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.monto_abonado))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.monto_abonado) return ''
						retorno = `$ ${Number(row.monto_abonado).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName: 'Neto Saldo',
				field: 'neto_saldo',
				sortable: true,
				pipe      : 'function',
				class: 'text-right',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.neto_saldo) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.neto_saldo))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.neto_saldo) return ''
						retorno = `$ ${Number(row.neto_saldo).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName: 'Neto Saldo Calculado',
				field: 'neto_saldo_calc',
				sortable: true,
				pipe      : 'function',
				class: 'text-left',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.neto_saldo_calc) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.neto_saldo_calc))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.neto_saldo_calc) return ''
						retorno = `$ ${Number(row.neto_saldo_calc).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName:  'Documentos' ,
				field: 'estado_descripcion',
				sortable: true,
				class: 'text-center',
				pipe: "functionInnertHTML",
				function: (row) => {      
					if (row.docs && row.docs.length > 0) {
					let textoHTML = ''
					row.docs.forEach(element => {
						textoHTML+= `<button class="btn btn-danger btn-round btn-sm" style="font-size: 0.7em; padding: .2em .8em;">${element}</button><br>`
					})
					return this.sanitized.bypassSecurityTrustHtml(textoHTML)
					} else {
					return '';
					}
				},
			},
			{
				headerName: 'Saldo',
				field: 'neto_saldo',
				sortable: true,
				pipe      : 'function',
				class: 'text-right',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.neto_saldo) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.neto_saldo))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.neto_saldo) return ''
						retorno = `$ ${Number(row.neto_saldo).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
					return retorno;
				},
			},
			{
				headerName: 'Saldo Calculado',
				field     : 'neto_saldo_calc',
				sortable  : true,
				class     : 'text-left',
				pipe      : 'function',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.neto_saldo_calc))
						retorno = `S/ ${number}`
					}
					else
						retorno = `$ ${Number(row.neto_saldo_calc).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno;
				},
			},
			{
				headerName: 'Deuda Saldo',
				field: 'deuda_saldo',
				sortable: true,
				pipe      : 'function',
				class: 'text-right',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.deuda_saldo) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.deuda_saldo))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.deuda_saldo) return ''
						retorno = `$ ${Number(row.deuda_saldo).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName: 'Deuda Saldo Calculado',
				field: 'deuda_saldo_calc',
				sortable: true,
				pipe      : 'function',
				class: 'text-left',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.deuda_saldo_calc) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.deuda_saldo_calc))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.deuda_saldo_calc) return ''
						retorno = `$ ${Number(row.deuda_saldo_calc).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName: 'Excedente',
				field: 'excedente',
				sortable: true,
				class: 'text-left',
			},
			{
				headerName: 'Excedente Calculado',
				field: 'excedente_calc',
				sortable: true,
				class: 'text-right',
			},
			{
				headerName: 'Intereses',
				field: 'intereses_devolver',
				sortable: true,
				pipe      : 'function',
				class: 'text-right',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.intereses_devolver) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.intereses_devolver))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.intereses_devolver) return ''
						retorno = `$ ${Number(row.intereses_devolver).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName: 'Intereses Calc',
				field: 'intereses_devolver_calc',
				sortable: true,
				pipe      : 'function',
				class: 'text-left',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.intereses_calc) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.intereses_calc))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.intereses_calc) return ''
						retorno = `$ ${Number(row.intereses_calc).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName: 'Mora',
				field: 'mora',
				sortable: true,
				pipe      : 'function',
				class: 'text-right',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.mora) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.mora))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.mora) return ''
						retorno = `$ ${Number(row.mora).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName: 'Mora Calc',
				field: 'mora_calc',
				sortable: true,
				pipe      : 'function',
				class: 'text-left',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.mora_calc) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.mora_calc))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.mora_calc) return ''
						retorno = `$ ${Number(row.mora_calc).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName: 'IGV Intereses',
				field: 'igv_intereses',
				sortable: true,
				pipe      : 'function',
				class: 'text-right',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.mora) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.mora))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.mora) return ''
						retorno = `$ ${Number(row.mora).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName: 'IGV Intereses Calc',
				field: 'igv_intereses_calc',
				sortable: true,
				pipe      : 'function',
				class: 'text-left',
				function : function(row){
					let moneda = row.moneda
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						if(!row.mora_calc) return ''
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.mora_calc))
						retorno = `S/ ${number}`
					}
					else{
						if(!row.mora_calc) return ''
						retorno = `$ ${Number(row.mora_calc).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
		];
  }

  getData(){
    
  }
}
