import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from "@angular/forms";

import { SharedModule } from "app/shared/shared.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";

import { AbonosRoutingModule } from './abonos-routing.module'
import { AbonosComponent } from './pages/abonos/abonos.component';
import { AbonoComponent } from './pages/abono/abono.component';
import { ImportarComponent } from './modals/importar/importar.component';
import { AnularComponent } from './modals/anular/anular.component';
import { IdentificarComponent } from './modals/identificar/identificar.component'
import { ReasignarComponent } from './modals/reasignar/reasignar.component'


@NgModule({
  declarations: [
    AbonosComponent,
    AbonoComponent,
    ImportarComponent,
    AnularComponent,
    IdentificarComponent,
    ReasignarComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    BsDatepickerModule,
    AbonosRoutingModule,
  ]
})
export class AbonosModule { }
