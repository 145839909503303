import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { faReply, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { JudicialService } from 'app/core/services/judicial/judicial.service';
import { AddRecaudacionModalComponent } from 'app/modules/recaudacion/modals/add-recaudacion-modal/add-recaudacion-modal.component';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { CompensacionesModalComponent } from '../../componentes/compensaciones-modal/compensaciones-modal.component';
import { DevolucionesModalComponent } from '../../componentes/devoluciones-modal/devoluciones-modal.component';
import { ExcedentesModalComponent } from '../../componentes/excedentes-modal/excedentes-modal.component';
import { ExcedentesService } from '../../excedentes.service';
import { functions } from '../../../../core/helpers/functions';

@Component({
  selector: 'app-excedentes',
  templateUrl: './excedentes.component.html',
  styleUrls: ['./excedentes.component.css']
})
export class ExcedentesComponent implements OnInit {
  faSyncAlt=faSyncAlt;
  faReply = faReply;
  @ViewChild(CompensacionesModalComponent) modalCompensaciones: CompensacionesModalComponent;
  @ViewChild(ExcedentesModalComponent) _excedentesModal: ExcedentesModalComponent;
  @ViewChild(DevolucionesModalComponent) _devolucionesModal: DevolucionesModalComponent;
  empresas: any;
  set excedentesModal(value) {
    this._excedentesModal = value;
  }

  get excedentesModal() {
    return this._excedentesModal
  }
  set devolucionesModal(value) {
    this._devolucionesModal = value;
  }

  get devolucionesModal() {
    return this._devolucionesModal
  }
  private modalRef: any;
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = "";
  public canCreate: boolean = false;
  public tmpData: any;

  constructor(
    public modalService: NgbModal,
    public router: Router,
    public authService: AuthService,
    public judialService: JudicialService,
    public documentosCarteraService: DocumentosCarteraService,
    public excedentesService: ExcedentesService,
    private factoringService: FactoringService,
  ) { }

  ngOnInit(): void {
    this.obtenerExcedentes();
  }

  obtenerExcedentes() {

    let excedentes = this.excedentesService.obtenerExcedentes();
    let estados    = this.excedentesService.obtenerExcedentesEstados();
    let tipos      = this.excedentesService.obtenerExcedentesTipos();
    let empresas   = this.factoringService.obtenerEmpresas()

    Promise.all([excedentes, estados, tipos, empresas]).then(
      (res: any[]) => {
        let excedentes = res[0];
        let estados = res[1].results;
        let tipos = res[2].results;
        let empresas = res[3]['results'];
        this.initVars(estados, tipos, empresas);
        this.loadTable(excedentes);
        this.excedentesModal.reloadTable.subscribe((res) => {
          // console.log(res)
          this.obtenerExcedentes()
          this.excedentesService.obtenerHistorialExcedente(res).then((response: any) => {
            this.excedentesModal.recargarHistorial(response)
          })
        }, (err) => {
          console.log(err)
        })
      }
    );
  }

  loadTable(data) {
    this.rowData = functions.indextable( data )
    // this.rowData = data.results.map((element, index) => ({
    //   ...element, index: index + 1
    // }));
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars(estados, tipos, empresas) {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cliente",
        field: "cliente_nombre",
        sortable: true,
        filterable: true,
        filterProp: "cliente__iconstains",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "cliente_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
        sortable: true,
        filterable: true,
        filterProp: "aceptante__iconstains",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "aceptante_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "tipo_documento_descripcion",
        sortable: true,
        filterProp: 'tipo_documento',
        filterable: true,
        filterSelectItems: tipos,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Nro Documento",
        field: "numero_documento",
        sortable: true,
        filterable: true,
        filterProp: "numero_documento",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Fch. Recaudo",
        pipe: 'date',
        field: "fecha",
        sortable: true,
        filterable: true,
        filterDate: true,
      },
      {
        class: "text-center",
        headerName: "Excedente",
        field: "excedente",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        filterable: true,
        filterRange: true
      },
      {
        class: "text-center",
        headerName: "Saldo",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        // filterable: true,
        // filterRange: true
      },
      {
        headerName: 'Estado',
        field: 'estado_descripcion',
        filterProp: 'estado',
        sortable: true,
        class: 'text-center',
        filterable: true,
        filterSelectItems: estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Operación ',
        field: 'operacion',
        pipe: 'actionable',
        textField: 'operacion',
        class: 'text-center',
        actionableType: 'operacion',
        sortable: true,
        filterable: true,
        filterProp: 'operacion',
        filterInput: true,
      },
      {
        // headerName: "Recaudación",
        // class: "text-center",
        // field: "recaudacion",
        // sortable: true,

        headerName: 'Aplicación ',
        field: 'recaudacion',
        pipe: 'actionable',
        textField: 'recaudacion',
        class: 'text-center',
        sortable: true,
        actionableType: 'recaudacion',
        // filterable: true,
        filterProp: 'recaudacion',
        filterInput: true,
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {
    // const range = (filtros.valueFrom && filtros.valueTo) ? `${filtros.valueFrom},${filtros.valueTo}` : '';
    filtros.valueFrom ? filtros.monto__gte = filtros.valueFrom : filtros.monto__gte = ''
    filtros.valueTo ? filtros.valueTo = filtros.monto__lte : filtros.valueTo = ''

    this.excedentesService.obtenerExcedentes(
      page,
      per_page,
      filtros.tipo_documento,
      filtros.numero_documento,
      filtros.fecha__gte ? filtros.fecha__gte.split(' ')[0] : '',
      filtros.fecha__lte ? filtros.fecha__lte.split(' ')[0] : '',
      filtros.monto__gte,
      filtros.monto__lte,
      filtros.estado,
      filtros.operacion,
      filtros.recaudacion,
      filtros.cliente__iconstains,
      filtros.aceptante__iconstains,
      filtros.empresa,
    ).then(res => {
      this.loadTable(res);
    })
      .catch(error => {

      });

  }

  tableEditEvent(row) {
    let comentarios  = this.excedentesService.obtenerExcedentesComentarios(row.id)
    let aplicaciones = this.excedentesService.obtenerExcedentesAplicaciones(row.id)
    let historial    = this.excedentesService.obtenerHistorialExcedente(row.id)

    this.tmpData = row
    Promise.all([
      comentarios,
      aplicaciones,
      historial])
      .then((response: any[]) => {
        let comentarios = response[0].results.map((comentario) => {
          comentario.fecha = this.parseDate(comentario.fecha)
          return comentario
        })
        let aplicaciones = response[1].results
        let historial = response[2]
        this.modalService.dismissAll()
        this.excedentesModal.initModal(row, comentarios, aplicaciones, historial)
        this.excedentesModal.reloadList.subscribe((res) => {
          if (res.moneda == 1) {
            this.tmpData.saldo = Number.parseFloat(this.tmpData.monto_soles) - Number.parseFloat(res.monto)
          } else {
            this.tmpData.saldo = Number.parseFloat(this.tmpData.monto_dolares) - Number.parseFloat(res.monto)
          }
          this.tableEditEvent(this.tmpData)
        })
      })
  }

  crearDevoluciones(){
    this.devolucionesModal.initModal()
  }

  workflowEvent(row) {
    if (row.actionableName === 'operacion') {
      this.router.navigate(['/operaciones', row.operacion]);
    } else {
      this.verRecaudacion(row)
    }
  }

  verRecaudacion(row: any) {
    console.log(row)
    this.modalRef = this.modalService.open(AddRecaudacionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });

    this.modalRef.componentInstance.recaudacionId = row.recaudacion;

    this.modalRef.result.then((result) => {
      console.log(result);
      // this.obtenerRecaudaciones();
    }, (reason) => {
      console.log(reason);
      // this.obtenerRecaudaciones();
    });

  }

  parseDate(fecha) {

    let yy = fecha.split('-')[0];
    let mm = fecha.split('-')[1];
    let dd = fecha.split('-')[2];
    dd = dd.split('T')[0]

    let hora = Number(fecha.split('T')[1].split(':')[0])
    let momento = (hora >= 12) ? "PM" : "AM";
    hora = (hora >= 12) ? hora - 12 : hora
    let minuto = fecha.split('T')[1].split(':')[1]
    let hour = `  ${hora}:${minuto} ${momento}`

    let date = `${dd}/${mm}/${yy} ${hour}`;
    return date
  }

  guardarCambios(body) {
    // console.log(body)
    // this.documentosCarteraService.modificarCabecera(body,this.tmpData.id).then((res)=>{
    //   console.log(body)
    // })
  }

  editaComentario(event) {
    let hoy = new Date();

    event.fecha = hoy.toISOString()
    this.excedentesService.editarComentarioCuentaCobrar(event, event.id).then((result) => {
      return this.excedentesService.obtenerExcedentesComentarios(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.parseDate(comentario.fecha)
        return comentario
      })
      this.excedentesModal.refrescaComentarios(comentarios)
    })
  }

  eliminaComentario(event) {
    this.excedentesService.editarComentarioCuentaCobrar(event, event.id).then((result) => {
      return this.excedentesService.obtenerExcedentesComentarios(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.parseDate(comentario.fecha)
        return comentario
      })
      this.excedentesModal.refrescaComentarios(comentarios)
    })
  }

  nuevoComentario(event: string) {
    let user = this.authService.user
    let commentBody = {
      excedente: this.tmpData.id,
      comentario: event,
      responsable: user.id,
      responsable_nombre: user.nombreCompleto,
      fecha: new Date().toISOString()
    }
    this.excedentesService.crearComentarioCuentaCobrar(commentBody).then((result) => {
      return this.excedentesService.obtenerExcedentesComentarios(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.parseDate(comentario.fecha)
        return comentario
      })
      this.excedentesModal.refrescaComentarios(comentarios)
    })
  }

  openModalCompensaciones () {
   return this.modalCompensaciones.openModal()
  }

}
