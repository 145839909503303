import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from 'app/app.service';
import { ToastService } from '../toast/toast.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
	providedIn: 'root'
})
export class PropuestaComercialService {

	public apiUrl: string = '';
	public propuestaComercial: string = 'factoring/operaciones/propuesta/comercial/';
	public riskAnalysis: string = 'factoring/operaciones/propuesta/riesgos/';
	public propuestaComite: string = 'factoring/operaciones/propuesta/comite/';
	public operaciones: string = 'factoring/operaciones/';
	public operacionesSugerencias: string = 'factoring/sugerencias/';
	public simularLiquidacion: string = 'factoring/liquidaciones/simulacion/';
	public liquidacion: string = 'factoring/liquidaciones/';
	public modificarLiquidacion: string = 'factoring/liquidaciones/modificarmontos/';
	public modificarLiquidacionaprobar: string = 'factoring/liquidaciones/modificarmontos/aprobar/';
	public contrato: string = 'factoring/operaciones/contrato/';
	public tiposDeFirma: string = 'factoring/contratos/tiposfirmacontrato/';
	public tiposFianza: string = 'factoring/contratos/tiposfianza/';
	public tiposFirma: string = 'factoring/contratos/tiposfirmarepresentantelegal/';
	public condiciones: string = 'factoring/operaciones/condiciones/';



	constructor(
		public appService: AppService,
		public toast: ToastService,
		public spinner: NgxSpinnerService,
		public http: HttpClient
	) {
		this.apiUrl = this.appService.settings.API_base_url
	}

	/**
	 * Habilita el loader para request a la API
	 */
	spinnerOn() {
		this.spinner.show()
	}

	/**
	 * Desabilita el loader
	 * @param mensaje Mensaje del toast
	 * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
	 */
	spinnerOff(mensaje: string = null, ok: boolean = true) {
		this.spinner.hide()
		this.appService.notifyMe(mensaje, ok)

		if (mensaje && ok)
			this.toast.success(mensaje)
		if (mensaje && !ok)
			this.toast.warning(mensaje)
	}

	/**
   * Mostros errores recibidos del servidor
   * @param error Error enviado del servidor
   */
	showErrors(error) {
		const err = error.error;

		for (const key in err) {
			if (Object.prototype.hasOwnProperty.call(err, key)) {
				const element = err[key];

				if (Array.isArray(element) && element.length) {
					element.forEach(item => {
						this.spinnerOff(item, false);
					});
				} else {
					this.spinnerOff(element, false);
				}

			}
		}
	}

	/**
	 * Obtiene la operacion pasado un ID
	 * @param operacion ID de la operacion
	 */
	obtenerOperacion(operacion: number) {
		const URL = this.apiUrl + this.operaciones + operacion + '/';

		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}

	/**
	 * Resuelve una propuesta comercial de determinada operacion
	 * @param operacion ID de la operacion de la propuesta comercial
	 */
	obtenerPropuestaComercialPorOperacion(operacion: number) {
		const URL = this.apiUrl + this.propuestaComercial + operacion + '/';

		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}

	/**
	 * Actualiza una propuesta comercial de determinada operacion
	 * @param operacion ID de la operacion de la propuesta comercial
	 * @param body Objeto JSON con los cambios que se guardaran en dicha propuesta
	 */
	actulizarPropuestaComercial(operacion: any, body: any) {

		const URL = this.apiUrl + this.propuestaComercial + operacion + '/';
		this.spinnerOn()
		return new Promise((resolve, reject) => {
			const httpRequest = this.http.patch(URL, body);
			httpRequest.subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				console.log(err)
				this.spinnerOff('La operación falló', false)
				reject(err);
			});

		});
	}

	// Risk Analysis

	/**
	 * Resuelve un analisis de riesgo para una determinada operacion
	 * @param operacion ID de la operacion de la propuesta comercial
	 */
	obtenerAnalisisRiesgos(operacion: number) {
		const URL = this.apiUrl + this.riskAnalysis + operacion + '/';

		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}

	/**
	 * Retorna un objeto con la data de propuesta de un comite
	 * @param operacion Id de la operacion a obtener
	 */
	obtenerPropuestaComite(operacion: number) {
		const URL = this.apiUrl + this.propuestaComite + operacion + '/';
		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}

	/**
	* Actualiza un analisis de riesgo de una determinada operacion
	* @param operacion ID de la operacion del analisis de riesgo
	* @param body Objeto JSON con los cambios que se guardaran en dicho analisis
	*/
	actualizarAnalisisRiesgo(operacion: any, body: any) {
		const URL = this.apiUrl + this.riskAnalysis + operacion + '/';
		return new Promise((resolve, reject) => {
			const httpRequest = this.http.patch(URL, body);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}


	/**
* Actualiza una propuesta de comite de una determinada operacion
* @param operacion ID de la operacion del analisis de riesgo
* @param body Objeto JSON con los cambios que se guardaran en dicho analisis
*/
	actualizarPropuestaComite(operacion: any, body: any) {
		const URL = this.apiUrl + this.propuestaComite + operacion + '/';
		return new Promise((resolve, reject) => {
			const httpRequest = this.http.put(URL, body);
			httpRequest.subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});

		});
	}


	/**
	 * 
	 * Resuelve als sugerencias solicitadas por cierta operacion
	 * @param operacion ID de la operacion de la operacion
	 */
	obtenerSugerenciasDeOperacion(operacion: number, page_size = 1000) {
		let URL = this.apiUrl + this.operacionesSugerencias;
		URL = `${URL}?operacion=${operacion}&page_size=${page_size}`;

		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				this.spinnerOff()
				reject(err);
			});
		});
	}


	actualizarComentarioDeSugerencia(sugerencia: number, body) {
		let URL = this.apiUrl + this.operacionesSugerencias + sugerencia + '/';
		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.put(URL, body).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				this.spinnerOff()
				reject(err);
			});
		});
	}


	crearSimulacionDeLiquidacion(operacion, tipo_cambio, fecha_desembolso, moneda) {
		let URL = this.apiUrl + this.simularLiquidacion + operacion + '/' +
			`?tipo_cambio=${tipo_cambio}` +
			`&fecha_desembolso=${fecha_desembolso}` +
			`&moneda=${moneda}`;

		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				// this.spinnerOff()
				this.showErrors(err)
				reject(err);
			});
		});
	}

	crearSimulacionDeLiquidacionMethod2(data) {
		let URL = this.apiUrl + this.simularLiquidacion;

		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.post(URL, data).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				// this.spinnerOff()
				this.showErrors(err)
				reject(err);
			});
		});
	}

	guardarSimulacionDeLiquidacion(operacion, body) {
		// let URL = this.apiUrl + this.liquidacion +operacion+'/';
		let URL = this.apiUrl + this.liquidacion;

		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.post(URL, body).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				// this.spinnerOff()
				this.showErrors(err);
				reject(err);
			});
		});
	}

	crearSimulacionDeCierre(liq, data) {
		let URL = this.apiUrl + this.liquidacion + `${liq}/cerrar-adelanto/simulacion/`;

		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.post(URL, data).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				// this.spinnerOff()
				this.showErrors(err)
				reject(err);
			});
		});
	}

	cerrarLiquidacion(liq, body) {
		// let URL = this.apiUrl + this.liquidacion +operacion+'/';
		let URL = this.apiUrl + this.liquidacion + `${liq}/cerrar-adelanto/`;

		this.spinnerOn();
		return new Promise((resolve, reject) => {
			this.http.post(URL, body).subscribe(data => {
				this.spinnerOff();
				resolve(data);
			}, (err) => {
				// this.spinnerOff()
				this.showErrors(err);
				reject(err);
			});
		});
	}

	ObtenerSimulacionDeLiquidacion(operacion) {
		let URL = this.apiUrl + this.liquidacion + operacion + '/';

		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				this.spinnerOff()
				reject(err);
			});
		});
	}


	modificarSimulacionDeLiquidacion(operacion) {
		let URL = this.apiUrl + this.modificarLiquidacion + operacion + '/';

		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				this.spinnerOff()
				reject(err);
			});
		});
	}

	actualizarSimulacionDeLiquidacion(operacion, body) {
		let URL = this.apiUrl + this.modificarLiquidacion + operacion + '/';

		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.put(URL, body).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				this.spinnerOff()
				reject(err);
			});
		});
	}

	aprobarModificacionDeSimulacion(body) {
		let URL = this.apiUrl + this.modificarLiquidacionaprobar;

		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.post(URL, body).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				this.spinnerOff()
				reject(err);
			});
		});
	}

	eliminarLiquidacion(liquidacionId) {
		let URL = this.apiUrl + this.liquidacion + liquidacionId + '/';

		this.spinnerOn();
		return new Promise((resolve, reject) => {
			this.http.delete(URL).subscribe(data => {
				this.spinnerOff();
				resolve(data);
			}, (err) => {
				this.spinnerOff();
				reject(err);
			});
		});
	}

	obtenerContrato(operacionId) {
		let URL = this.apiUrl + this.contrato + `${operacionId}/`;

		this.spinnerOn();
		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				this.spinnerOff();
				resolve(data);
			}, (err) => {
				this.spinnerOff();
				reject(err);
			});
		});
	}

	obtenerTiposFirmas(page_size = 1000) {
		let URL = this.apiUrl + this.tiposDeFirma;
		// URL = `${URL}?page_size=${page_size}`;

		this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				this.spinnerOff()
				reject(err);
			});
		});
	}

	/**
	 * Obtiene la operacion pasado un ID
	 * @param operacion ID de la operacion
	 */
	obtenerTiposFirma(page = 1, page_size = 1000) {
		const URL = this.apiUrl + this.tiposFirma;

		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}

	/**
	 * Obtiene la operacion pasado un ID
	 * @param operacion ID de la operacion
	 */
	obtenerTiposFianza(page = 1, page_size = 1000) {
		const URL = this.apiUrl + this.tiposFianza;

		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}

	obtenerCondiciones(page = 1, page_size = 1000) {
		const URL = this.apiUrl + this.condiciones;

		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}

	buscaAceptantes(ruc) {
		const URL = this.apiUrl + `clientes/aceptantes/?ruc__icontains=${ruc}`;
		// this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				// this.spinnerOff()
				resolve(data['results'][0]);
			}, (err) => {
				reject(err);
			});
		});
	}

	buscaAceptantesById(idAceptante) {
		const URL = this.apiUrl + `clientes/aceptantes/${idAceptante}/`;
		// this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				// this.spinnerOff()
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}

	buscaAceptantesDesgloseLineas(aceptanteId, operacionId) {
		const URL = this.apiUrl + `factoring/operaciones/lineas/?operacion=${operacionId}&aceptante=${aceptanteId}&page_size=1000`;

		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				resolve(data['results']);
			}, (err) => {
				reject(err);
			});
		});
	}

	buscaBeneficiarioDesgloseLineas(beneficiarioId) {
		const URL = this.apiUrl + `factoring/beneficiarios/lineas/?beneficiario=${beneficiarioId}`;

		return new Promise((resolve, reject) => {
			this.http.get(URL).subscribe(data => {
				resolve(data['results'][0]['lineas_aceptantes']);
			}, (err) => {
				reject(err);
			});
		});
	}

	actualizarDesglose(desgloseId, body) {
		const URL = this.apiUrl + `factoring/operaciones/lineas/${desgloseId}/`;
		// this.spinnerOn()

		return new Promise((resolve, reject) => {
			this.http.patch(URL, body).subscribe(data => {
				// this.spinnerOff()
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}

	eliminarDesglose(desgloseId) {
		const URL = this.apiUrl + `factoring/operaciones/lineas/${desgloseId}/`;
		// this.spinnerOn()

		return new Promise((resolve, reject) => {
			this.http.delete(URL).subscribe(data => {
				// this.spinnerOff()
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}

	crearDesglose(body) {
		const URL = this.apiUrl + `factoring/operaciones/lineas/`;
		// this.spinnerOn()
		return new Promise((resolve, reject) => {
			this.http.post(URL, body).subscribe(data => {
				// this.spinnerOff()
				resolve(data);
			}, (err) => {
				reject(err);
			});
		});
	}

	cambiaEstadoRevisado(liquidacionId, data) {
		const url = this.apiUrl + this.liquidacion + `/datos-editables//${liquidacionId}/`;

		return new Promise((resolve, reject) => {
			this.spinnerOn()
			const httpRequest = this.http.patch(url, { revisado: data.revisado });
			httpRequest.subscribe(data => {
				this.spinnerOff()
				resolve(data);
			}, (err) => {
				this.spinnerOff(err.error.detail, false);
				reject(err);
			});

		});
	}

}
