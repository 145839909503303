import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ExcedentesService } from 'app/modules/excedentes/excedentes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ClientesComponent } from 'app/modules/clientes/pages/clientes/clientes.component';
import { DevolucionModalConfirmarComponent } from '../devolucion-modal-confirmar/devolucion-modal-confirmar.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DevolucionEditarDetalleComponent } from '../devolucion-editar-detalle/devolucion-editar-detalle.component';

@Component({
  selector: 'app-devoluciones-modal',
  templateUrl: './devoluciones-modal.component.html',
  styleUrls: ['./devoluciones-modal.component.css']
})
export class DevolucionesModalComponent implements OnInit {
  faTimes = faTimes
  @ViewChild(DevolucionModalConfirmarComponent) _devolucionesModalConfirmar: DevolucionModalConfirmarComponent;
  backUpData: any;
  set devolucionesModalConfirmar(value) {
    this._devolucionesModalConfirmar = value;
  }

  get devolucionesModalConfirmar() {
    return this._devolucionesModalConfirmar
  }

  @ViewChild('modalDevoluciones', { static: false }) public modalDevoluciones;
  @Input() beneficiarioId: any = [];
  @Output() reload = new EventEmitter<any>();
  public modalRef: any;
  public bottonAgregar: boolean = false;
  public reasignarAceptante: boolean = false
  public reasignarCliente: boolean = false
  public pageTableActual: number;
  public columnDefs: columnHeader[];
  public pageTableTotal: number;
  public rowData: any[] = [];
  public pageTablePerPages: number = 10;
  public todoSelect: boolean = false;
  public pageTablePages: number[];
  public gruposClientes: any[] = [];
  public clientesDevolver: any[] = [];
  public loader: boolean = false;
  public dataCero: boolean = false;
  public dateValue: any;
  tableSelectable: any;
	public componentForm    : FormGroup
  listSelect: any;
  columnDefsBox:any[];
  public activeNavItem : number = 2

  constructor(
    public modalService      : NgbModal,
    public excedentesServicio: ExcedentesService,
    public formbuild         : FormBuilder,
    private factoringService: FactoringService,
    public spinner: NgxSpinnerService,
    public authService       : AuthService,
    public functions         : SharedFunctions,
  ) { }

  ngOnInit(): void {

  }
  
  refreshDataTable(values=null) {
    this.gruposClientes = []
    if (!values) values = this.componentForm.get('fechas').value
    if (!values || values.length == 0) return;

    let fecha_creacion__gte = this.formatDate(
      values[0]
    );

    let fecha_creacion__lte = this.formatDate(
      values[1]
    );

    this.excedentesServicio.obtenerExcedentesLibres(
      1,
      500,
      ``,
      ``,
      ``,
      ``,
      ``,
      ``,
      `1`,
      ``,
      ``,
      ``,
      ``,
      ``,
      `${fecha_creacion__gte} 00:00:00.000`,
      `${fecha_creacion__lte} 23:59:59.999`,
      this.activeNavItem
    ).then((res: any) => {
      if (this.activeNavItem == 1) { // Aceptante, Deudor
        res.results.forEach(e => Object.assign(e, {
          cliente: e.aceptante,
          cliente_ruc: e.aceptante_ruc,
          cliente_nombre: e.aceptante_nombre,
        }))
      }
      this.loadTable(res);
    })
  }

  formatDate(date) {
    if (!(date instanceof Date)) return;
    return (
      date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate()
    );
  }

  initModal() {
    let dateAyer = new Date(new Date().getTime() - 24*60*60*1000);
    let inicio = new Date()
    
    inicio.setFullYear(dateAyer.getFullYear() - 2)

    this.componentForm = this.formbuild.group({
      fechas: [[inicio,dateAyer] , [Validators.required]],
	  })

    this.gruposClientes = []
    this.initVars()    
    this.displayModal()
  }

  enableSelectable() {
    this.tableSelectable = !this.tableSelectable
  }

  get countCheck(){
    let selecciones = this.gruposClientes.reduce((acc,cliente)=>{
      if(cliente.preselected.length > 0){
        let clientePost = {
          ...cliente,
          responsable: this.authService.user.id,
          excedentes_devolver : cliente.preselected
        }
        acc.push(clientePost)
      }
      return acc
    },[])

    if(selecciones.length > 0){
      return true
    } else {
      return false
    }
  }

  toggleCheckAll(){
    if(this.todoSelect){
      this.gruposClientes.forEach((item)=>{
        item.preselected = item.excedentes.map((item) => item.id)
        item.checked = true
      })
    } else {
      this.gruposClientes.forEach((item)=>{
        item.preselected = []
        item.checked = false
      })
    }
  }

  toggleText(){
    this.todoSelect = !this.todoSelect
    this.toggleCheckAll()
  }

  validarTotales(){
    let totales = this.gruposClientes.reduce((acc,clie)=>{
      acc[0].push(...clie.excedentes)
      acc[1].push(...clie.preselected)
      return acc
    },[[],[]])

    if(totales[0].length == totales[1].length){
      this.todoSelect = true
    } else {
      this.todoSelect = false
    }
  }

  checkEvent(rows, cliente) {
    let ids = rows.map( item => item.id )
    cliente.preselected = ids

    if(cliente.excedentes.length == cliente.preselected.length){
      cliente.checked = true
    } else {
      cliente.checked = false
    }

    this.validarTotales()
  }

  toggleCheck(grupoCliente){
    if(grupoCliente.checked){
      let ids = grupoCliente.excedentes.map( item => item.id )
      grupoCliente.preselected = ids
    } else {
      let clienteIndex = this.gruposClientes.findIndex((item)=> item.cliente == grupoCliente.cliente)
      this.gruposClientes[clienteIndex]['preselected'] =[] 
    }

    this.validarTotales()
  }

  // TODO: No se usa ni en el html
  goToPage({ page, per_page, filtros }) {
    filtros.valueFrom ? filtros.monto__gte = filtros.valueFrom : filtros.monto__gte = ''
    filtros.valueTo ? filtros.valueTo = filtros.monto__lte : filtros.valueTo = ''

    this.excedentesServicio.obtenerExcedentesLibres(
      page,
      per_page,
      filtros.tipo_documento,
      filtros.numero_documento,
      filtros.fecha__gte,
      filtros.fecha__lte,
      filtros.monto__gte,
      filtros.monto__lte,
      filtros.estado,
      filtros.operacion,
      filtros.recaudacion,
      filtros.cliente__iconstains,
      filtros.aceptante__iconstains,
      filtros.empresa,
    ).then(res => {
      this.loadTable(res);
    })
      .catch(error => {

      });

  }

  loadTable(data: any) {
    this.backUpData = data
    if(data.results.length == 0){
      this.dataCero = true
    } else {
      this.dataCero = false
    }

    this.rowData = data.results.map((element, index) => ({
      ...element, index: index + 1
    }));
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }

    this.filtrarGruposClientes(this.rowData)
    this.loader = false
  }

  filtrarGruposClientes(data){
    let gruposAceptantes = data.reduce((acc,item)=>{
      let ids = acc.map( item => item.cliente )
      
      if(!ids.includes(item.cliente)){

        let excedentes = data.filter( filterItem => filterItem.cliente == item.cliente )
        let dolares    = excedentes.filter( item => item.moneda == 2 )
        let soles      = excedentes.filter( item => item.moneda == 1 )

        let total_soles = soles.reduce( (acc, item) =>{
          let total = 0;
          let monto = parseFloat(item.saldo)
          total     = acc + monto ;
          acc       = total
          return acc
        },0)

        let total_dolares = dolares.reduce( (acc, item) =>{
          let total = 0;
          let monto = parseFloat(item.saldo)
          total     = acc + monto ;
          acc       = total
          return acc
        },Number(0))

        let cliente = {
          cliente       : item.cliente,
          cliente_nombre: item.cliente_nombre,
          cliente_ruc   : item.cliente_ruc,
          total_soles   : new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(total_soles)),
          total_dolares : Number(total_dolares).toLocaleString('en-US', { minimumFractionDigits: 2 }),
          excedentes,
          preselected: [],
          checked: false,
        }

        acc.push(cliente)
      }

      return acc
    },[])

    let excedentesPorGruposClientes = gruposAceptantes.map(element => {
      element.excedentes = this.rowData.filter( item => item.cliente == element.cliente )
      return element
    });
    this.gruposClientes = excedentesPorGruposClientes
  }

  displayModal() {
    this.modalService.open(this.modalDevoluciones, {
      size: 'xl'
    }).result.then((response: any) => {
    }).catch(res => {})
  }


  initVars() {
    let columns = [
      {
        headerName: "Nro Excedente",
        field: "id",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Aceptante",
        field: "aceptante_nombre",
        sortable: true,
        filterProp: "aceptante__iconstains",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "aceptante_ruc",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Tipo",
        field: "tipo_documento_descripcion",
        sortable: true,
        filterProp: 'tipo_documento',
        // filterSelectItems: tipos,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Nro Documento",
        field: "numero_documento",
        sortable: true,
        filterProp: "numero_documento",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Excedente",
        field: "excedente",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
        filterRange: true
      },
      {
        class: "text-center",
        headerName: "Saldo",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
        sortable: true,
      },
    ];

    if (this.activeNavItem == 1) { // Aceptante
      columns.splice(1, 4)
    }

    this.columnDefs = columns;
  }

  obtenerSeleccion(){
    let resultadoGrupos = this.gruposClientes.reduce((acc,cliente)=>{
      if(cliente.preselected.length > 0){
        let clientePost = {
          ...cliente,
          responsable: this.authService.user.id,
          excedentes_devolver : cliente.preselected
        }
        acc.push(clientePost)
      }
      return acc
    },[])

    this.clientesDevolver = resultadoGrupos
    this.lanzarModalConfirmacion(resultadoGrupos)
  }


  lanzarModalConfirmacion(resultadoGrupos){
    this.devolucionesModalConfirmar.propietario = this.activeNavItem
    this.devolucionesModalConfirmar.displayModal(resultadoGrupos)
    // this.todoSelect = false
    // this.loadTable(this.backUpData)
  }

  pageChange(page) {
    this.initVars()
    this.refreshDataTable()
  }

  showEditarDetalle(item) {
    const modalRef = this.modalService.open(DevolucionEditarDetalleComponent, {
      size: "md",
    });
    modalRef.componentInstance.excedente = item

    modalRef.result.then(
      (result) => {
        if (result) {
        }
      },
      (reason) => {console.log('rea', reason)},
    )
  }

}
