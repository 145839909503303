import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'app/app.service';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../toast/toast.service';
import { FactoringService } from "app/core/services/factoring/factoring.service";

@Injectable({
  providedIn: 'root'
})
export class FormatosService {

  private apiUrl: string;

  private documentos: string = 'factoring/formatos/';
  private contactos: string = 'factoring/contactos/';
  private formato_documentos: string = 'factoring/formato_documentos/';
  private operacion_formatos: string = 'factoring/operaciones/formatos/';

  constructor(public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    private http: HttpClient,
    private modalService: NgbModal,
    public factoring: FactoringService,
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
  }

  spinnerOn() {
    this.spinner.show();
  }

  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();

    if (mensaje && ok)
      this.toast.success(mensaje);
    if (mensaje && !ok)
      this.toast.warning(mensaje);
  }

   /**
   * Mostros errores recibidos del servidor
   * @param error Error enviado del servidor
   */
    showErrors(error) {
      const err = error.error;
      
      for (const key in err) {
          if (Object.prototype.hasOwnProperty.call(err, key)) {
              const element = err[key];
              console.log(element);

              if (Array.isArray(element) && element.length) {
                  element.forEach(item => {
                      this.spinnerOff(item, false);
                  });
              } else {
                  this.spinnerOff(element, false);
              }

          }
      }
  }

  /**
   * Retorna todos los formatos posibles que podria requerir un proceso
   * @param page Pagina solicitada
   * @param page_size Cantidad de resultados por query
   * @param string Parametro de busqueda, retorna coincidencias con el nombre del documento
   */
  obtenerFormatos(
    page: number = 1,
    page_size: number = 1000,
    string: string = ''
  ) {

    const url = this.apiUrl +
      this.documentos +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&descripcion__icontains=${string}`;

    this.spinnerOn();
    return new Promise((res, ref) => {
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          ref(err)
        })
    })
  }

  /**
 * Retorna todos los firmantes que posean una firma y sean representantes legales  posibles que podria requerir un proceso
 * @param page Pagina solicitada
 * @param page_size Cantidad de resultados por query
 * @param string Parametro de busqueda, retorna coincidencias con el nombre del documento
 */
  obtenerFirmantes(
    beneficiario: number
  ) {

    const url = this.apiUrl +
      this.contactos +
      // `?beneficiario=${beneficiario}`;
      `?beneficiario=${beneficiario}&representante_legal=true&poder=true`;

    this.spinnerOn();
    return new Promise((res, ref) => {
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          ref(err)
        })
    })
  }

  /**
 * Retorna un contrato para poder validar la fecha del contrato
 * @param page Pagina solicitada
 * @param page_size Cantidad de resultados por query
 * @param string Parametro de busqueda, retorna coincidencias con el nombre del documento
 */
  obtenerContrato(
    page: number = 1,
    page_size: number = 1000,
    string: string = ''
  ) {

    const url = this.apiUrl +
      this.documentos +
      `?page_size=${page_size}` +
      `&page=${page}` +
      `&descripcion__icontains=${string}`;

    this.spinnerOn();
    return new Promise((res, ref) => {
      this.http.get(url)
        .subscribe((response) => {
          this.spinnerOff();
          res(response)
        }, (err) => {
          ref(err)
        })
    })
  }

  generarFormatoCartaAutorizacion(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "carta_autorizacion/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, (err) => {
        this.spinnerOff()
        var reader = new FileReader();
        reader.onloadend = (e) => {
          let error = JSON.parse(<any>e.target.result)
          console.log(e, e.target.result, error)
          for (let key in error) {
            this.toast.warning(`El campo ${key} es requerido`);
          }
        }
        reader.readAsText(err.error);
        reject(err);
      });

    });
  }

  generarFormatoEvaluacionRiesgo(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "evaluacion_riesgo/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, (err) => {
        this.spinnerOff()
        var reader = new FileReader();
        reader.onloadend = (e) => {
          let error = JSON.parse(<any>e.target.result)
          console.log(e, e.target.result, error)
          for (let key in error) {
            this.toast.warning(`El campo ${key} es requerido`);
          }
        }
        reader.readAsText(err.error);
        reject(err);
      });

    });
  }

  generarFormatoOrigenFondos(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "origen_fondos/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, (err) => {
        this.spinnerOff()
        var reader = new FileReader();
        reader.onloadend = (e) => {
          let error = JSON.parse(<any>e.target.result)
          console.log(e, e.target.result, error)
          for (let key in error) {
            this.toast.warning(`El campo ${key} es requerido`);
          }
        }
        reader.readAsText(err.error);
        reject(err);
      });

    });
  }

  generarFormatoUnico(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "unico/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, (err) => {
        this.spinnerOff()
        var reader = new FileReader();
        reader.onloadend = (e) => {
          let error = JSON.parse(<any>e.target.result)
          console.log(e, e.target.result, error)
          for (let key in error) {
            this.toast.warning(`El campo ${key} es requerido`);
          }
        }
        reader.readAsText(err.error);
        reject(err);
      });

    });
  }

  generarFormatoMineriaIlegal(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "mineria_ilegal/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, (err) => {
        this.spinnerOff()
        var reader = new FileReader();
        reader.onloadend = (e) => {
          let error = JSON.parse(<any>e.target.result)
          console.log(e, e.target.result, error)
          for (let key in error) {
            this.toast.warning(`El campo ${key} es requerido`);
          }
        }
        reader.readAsText(err.error);
        reject(err);
      });

    });
  }

  generarFormatoContratoMarco(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "contrato_marco/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, async (err) => {
        this.spinnerOff();
        try {
          const message = JSON.parse(await err.error.text());
          let error = {};
          error['error'] = message;
          console.log(message);
          console.log('Error al  generar contrato', false); 
        } catch (error) {
          console.error(error);
          this.spinnerOff('Error al  generar contrato', false);
        }
        // this.showErrors(error);
        reject(err);
      });

    });
  }

  generarFormatoPagare(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "pagare_fianzas/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, async (err) => {
        this.spinnerOff();
        try {
          const message = JSON.parse(await err.error.text());
          let error = {};
          error['error'] = message;
          console.log(message);
          console.log('Error al  generar contrato'); 
        } catch (error) {
          console.error(error);
          this.spinnerOff('Error al  generar contrato', false);
        }
        // this.showErrors(error);
        reject(err);
      });

    });
  }

  generarAdendaContrato(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "adenda_contrato/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, async (err) => {
        this.spinnerOff();
        // const message = JSON.parse(await err.error.text());
        // let error = {};
        // error['error'] = message;
        // console.log(message);
        // this.showErrors(error);
        console.log('Error al  generar contrato');
        reject(err);
      });

    });
  }

  generarFormatoSolicitudFinal(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "solicitud_final/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, async (err) => {
        this.spinnerOff();
        // const message = JSON.parse(await err.error.text());
        // let error = {};
        // error['error'] = message;
        // console.log(message);
        // this.showErrors(error);
        console.log('Error al  generar contrato');
        reject(err);
      });

    });
  }

  generarFormatoDJPEP(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "declaracion_jurada_informacion/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, async (err) => {
        const message = JSON.parse(await err.error.text());
        let error = {};
        error['error'] = message;
        console.log(message);
        this.spinnerOff();
        console.log('Error al  generar contrato');
        // this.showErrors(error);
        reject(err);
      });

    });
  }

  generarFormatoLAFT(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "calificacion_riesgo/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, async (err) => {
        const message = JSON.parse(await err.error.text());
        let error = {};
        error['error'] = message;
        console.log(message);
        this.spinnerOff();
        console.log('Error al  generar contrato');
        this.showErrors(error);
        reject(err);
      });

    });
  }

  generarFormatoAcuerdoLlenado(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "acuerdo_pagare/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, async (err) => {
        this.spinnerOff();
        // const message = JSON.parse(await err.error.text());
        // let error = {};
        // error['error'] = message;
        // console.log(message);
        // console.log('Error al  generar contrato');
        // this.showErrors(error);
        reject(err);
      });

    });
  }

  generarFormatoCartaModificacion(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "carta_modificacion_linea/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, async (err) => {
        this.spinnerOff();
        const message = JSON.parse(await err.error.text());
        let error = {};
        error['error'] = message;
        console.log(message);
        console.log('Error al  generar contrato');
        this.showErrors(error);
        reject(err);
      });

    });
  }

  generarFormatoCartaNotarialCobranzas(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "carta_notarial_cobranzas/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      this.spinnerOn()
      httpReques.subscribe(data => {
        this.spinnerOff()
        resolve(data);
      }, async (err) => {
        this.spinnerOff();
        const message = JSON.parse(await err.error.text());
        let error = {};
        error['error'] = message;
        console.log(message);
        console.log('Error al  generar contrato');
        this.showErrors(error);
        reject(err);
      });

    });
  }


  generarFormatoPlanillaDescuento(data) {
    return new Promise((resolve, reject) => {
      let httpReques;
      let URL = this.apiUrl + this.formato_documentos + "planilla_descuento/";
      const httpOptions = {
        responseType: 'blob' as 'json'
      };
      httpReques = this.http.post(URL, data, httpOptions);
      httpReques.subscribe(data => {
        resolve(data);
      }, async (err) => {
        const message = JSON.parse(await err.error.text());
        let error = {};
        error['error'] = message;
        console.log(message);
        console.log('Error al  generar contrato');
        this.showErrors(error);
        reject(err);
      });

    });
  }

  /**
   * Guarda un formato adjunto para determinado proceso de factoring
   * @param data Objeto JSON con la informacion del formato a guardar
   */
  async guardarDocumentoOperacion(data:any){
    console.log(data);
    this.spinnerOn();
    const apiBase = await this.factoring.validarPesoAdjunto(data.get('peso'), this.apiUrl);
    data.delete('peso');
    return new Promise((res,ref)=>{
        this.http.post(`${apiBase}${this.operacion_formatos}`,data)
        .subscribe((response)=>{
            this.spinnerOff('El archivo fue adjuntado de manera exitosa');
            res(response)
        }, (err)=>{
            let msg = 'Ocurrió un error durante la carga del archivo'
            if (err.error && typeof (err.error) == 'object') {
                msg = Object.values(err.error).map(e => typeof (e) == 'object' ? JSON.stringify(e) : e).join('\n')
            }
            this.spinnerOff(msg, false)
            ref(err)
        })
    })
  }

      /**
     * Actualiza el objeto de un documento en particular
     * @param id ID del documento a actualizar 
     * @param data Objeto del documento a actualizar
     */
    actualizarFormatoOperacion(id: number, data:any, state: boolean){
      this.spinnerOn();
      return new Promise((res,ref)=>{
          this.http.put(`${this.apiUrl}${this.operacion_formatos}/${id}/`,data)
          .subscribe((response)=>{
              this.spinnerOff('El archivo fue verificado de manera exitosa');
              res(response)
          }, (err)=>{
              this.spinnerOff('Ocurrió un error durante la verificación del archivo',false);
              ref(err)
          })
      }) 
  }

  /**
 * Retorna una operacion formato para poder actualizar la verificacion del mismo
 * @param string Parametro de busqueda, retorna coincidencias con el nombre del documento
 */
obtenerOperacionFormato(
  formato: string = '',
  operacion: string = ''
) {
  const url = this.apiUrl +
    this.operacion_formatos +
    `?operacion=${operacion}&formato=${formato}`;
  
  this.spinnerOn();
  return new Promise((res, ref) => {
    this.http.get(url)
      .subscribe((response) => {
        this.spinnerOff();
        res(response)
      }, (err) => {
        ref(err)
        this.spinnerOff();
      })
  })
}

downloadFile(url) {
  return new Promise((resolve, reject) => {
    let httpReques;
    // let URL = this.apiUrl + this.formato_documentos + "solicitud_final/";
    const httpOptions = {
      responseType: 'blob' as 'json'
    };
    httpReques = this.http.get(url, httpOptions);
    this.spinnerOn();
    httpReques.subscribe(data => {
      this.spinnerOff();
      resolve(data);
    }, async (err) => {
      this.spinnerOff();
      console.log('Error al  generar contrato');
      reject(err);
    });

  });
}

generarCorrelativo() {
  return new Promise((resolve, reject) => {
    let httpReques;
    let URL = this.apiUrl + this.formato_documentos + "correlativo/";
    httpReques = this.http.post(URL, '');
    this.spinnerOn()
    httpReques.subscribe(data => {
      this.spinnerOff()
      resolve(data);
    }, (err) => {
      this.spinnerOff("Ocurrio un error", false)
      reject(err);
    });
  });
}

}
