import { Component, Input, OnInit } from '@angular/core';
import { AbonosService } from 'app/core/services/abonos/abonos.service';

import { Router } from '@angular/router';

import { NgxSpinnerService } from "ngx-spinner";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
    selector: 'app-my-component',
    templateUrl: './observaciones.component.html',
    styleUrls: ['./observaciones.component.css']
})
export class ObservacionesComponent implements OnInit {
    
    @Input() abono: any = null
    
    constructor(
        public router: Router,
        public activeModal: NgbActiveModal,
    ){ 
    }

    ngOnInit(): void {
    }
}