import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-excepcion-deuda-leasing',
  templateUrl: './excepcion-deuda-leasing.component.html',
  styleUrls: ['./excepcion-deuda-leasing.component.css']
})
export class ExcepcionDeudaLeasingComponent implements OnInit {

  @Input() value: any
  @Input() soloLectura: boolean = false
  @Input() soloAdvertencia: boolean = false
  @Input() mensaje = 'El cliente registra deuda pendiente'

  forma: FormGroup
  tipos = []

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private factoringService: FactoringService,
  ) {
    this.forma = this.formBuilder.group({
      'excepcion_tipo': [null, [Validators.required]],
      'excepcion_sustento': [null, [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.spinner.show()
    forkJoin({
      tipos: this.factoringService.getTiposExcepcion(),
    }).subscribe(({ tipos }) => {
      this.tipos = tipos
    }).add(() => this.spinner.hide())

    this.forma.patchValue(this.value)
    
    if (this.soloLectura || this.soloAdvertencia) {
      this.forma.disable()
    }
  }

  onSubmit() {
    if (!this.forma.valid && !this.forma.disabled) return
    this.value = this.forma.value
    this.activeModal.close(this.value)
  }
}
