import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { AddShareholdersComponent } from 'app/modules/clientes/modals/add-shareholders/add-shareholders.component';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';

@Component({
  selector: 'app-shareholders',
  templateUrl: './shareholders.component.html',
  styleUrls: ['./shareholders.component.css']
})
export class ShareholdersComponent implements OnInit {

  public rowData          : any[]   = [];
	public loadingTable     : boolean = false;
	public columnDefs       : columnHeader[];
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number  = 10;
	public pageTablePages   : number[];
  public searchstring     : string = '';

  @Input() clienteId = 0;
  @Input() readonly = false;

  constructor(
    private clientesService	: ClientesService,
    public modalService     : NgbModal,
  ) { }

  ngOnInit(): void {
    this.obtenerAccionistas();
  }

  obtenerAccionistas(page = 1, page_size = 10) {
    this.clientesService.obtenerAccionistas(this.clienteId.toString(), page, page_size)
    .then(res => {
      this.initVars();
			this.loadTable(res);
    })
    .catch(error => {
      console.error(error);
    })
  }

  loadTable(data) {

		this.rowData           = data.results;
		this.pageTableActual   = data.page_number;
		this.pageTableTotal    = data.count;
		this.pageTablePerPages = data.per_page;
		this.pageTablePages = [];
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index);
		}

	}

  initVars() {
		let columns = [
			{
				headerName: 'N° Doc.', 
				field     : 'id' ,
				sortable  : true,
			},
			{
				headerName: 'Accionista', 
				field     : 'nombre' ,
				sortable  : true,
			},
			{
				headerName: 'Tipo de Persona', 
				field     : 'tipo_persona_nombre' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Doc. Ident. RUC', 
				field     : 'dni_ruc' ,
				sortable  : true,
				class     : 'text-center',
			},
			{
				headerName: 'Edad', 
				field     : 'area' ,
				sortable  : true,
			},
			{
				headerName: 'Nacionalidad', 
				field     : 'nacionalidad_nombre' ,
				sortable  : true,
			},
			{
				headerName: 'Aporte S.', 
				field     : 'aporte',
				class     : 'text-center',
      },
      {
				headerName: 'Participación', 
				field     : 'participacion',
				class     : 'text-center',
			},
		];

		this.columnDefs = columns;
	}

  goToPage({page,per_page,filtros}) {
    this.obtenerAccionistas(page, per_page);
  }

  tableEditEvent(shareholder) {
    const modalRef = this.modalService.open(AddShareholdersComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;
		modalRef.componentInstance.accionistaId = shareholder.id;

		modalRef.result.then((result) => {
		console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerAccionistas();
		},
		error => {

		});
  }

  destroyContact(accionista) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {
		
		});

		modalRef.componentInstance.title = 'Remover Accionista';
		modalRef.componentInstance.message = '¿Seguro que desea remover el accionista?';
		
		modalRef.result.then((result) => {
		if (result) {
			this.clientesService.elimiarAccionista(accionista.id)
			.then(result => {
			this.obtenerAccionistas();
			})
			.catch(error => {
			console.error(error);
			});
		}
		}, (reason) => {
			console.log(reason);
		});
  }

  openModal() {
    const modalRef = this.modalService.open(AddShareholdersComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.clienteId = this.clienteId;

		modalRef.result.then((result) => {
		  console.log(result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.successEmit.subscribe(result => {
			console.log(result);
			this.obtenerAccionistas();
		},
		error => {

		});
  }

}
