import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-devolucion-editar-detalle',
  templateUrl: './devolucion-editar-detalle.component.html',
  styleUrls: ['./devolucion-editar-detalle.component.css']
})
export class DevolucionEditarDetalleComponent implements OnInit {

  @Input() excedente: any = null;

  faTimes = faTimes
  forma: FormGroup

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
  ) {
    this.forma = this.formBuilder.group({
      'saldo': [null, [Validators.required]],
      'monto': [null, [Validators.required]],
    })
  }

  ngOnInit(): void {
    if (this.excedente.saldo_original == undefined) {
      this.excedente.saldo_original = this.excedente.saldo
    }
    this.forma.patchValue({ 'monto': this.excedente.saldo, 'saldo': this.excedente.saldo_original })
    this.forma.get('monto').setValidators([Validators.required, Validators.min(0.01), Validators.max(this.excedente.saldo_original)])
  }

  onSubmit() {
    if (this.forma.invalid) return
    this.excedente.saldo = this.forma.value.monto
    this.excedente.saldo_soles = this.excedente.saldo
    this.excedente.saldo_dolares = this.excedente.saldo
    if (this.excedente.moneda == 1) {
      this.excedente.saldo_dolares /= this.excedente.tipo_cambio_monto
      this.excedente.saldo_dolares = Math.round(this.excedente.saldo_dolares * 100) / 100
    }
    if (this.excedente.moneda == 2) {
      this.excedente.saldo_soles *= this.excedente.tipo_cambio_monto
      this.excedente.saldo_soles = Math.round(this.excedente.saldo_soles * 100) / 100
    }
    this.activeModal.close(true)
  }

}
