import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup } from '@angular/forms';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { AuthService } from 'app/core/services/auth/auth.service';
import { RestriccionesService } from 'app/core/services/auth/restricciones.service';
import {
    faFileDownload, 
    faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { SharedFunctions } from 'app/core/shared/functions';
@Component({
    selector: 'app-form-doc-checklist',
    templateUrl: './form-doc-checklist.component.html',
    styleUrls: ['./form-doc-checklist.component.css']
})
export class FormDocChecklistComponent implements OnInit {
    faFileDownload = faFileDownload;
    faCloudUploadAlt = faCloudUploadAlt;
    
    @Input() operacion      : any;
    @Input() beneficiarioData   : any;
    @Input() beneficiario   : any;
    @Input() step3          : boolean = false;
    @Input() readonly       : boolean = false;
    @Input() estacion       : number = 1;
    uploadForm              : FormGroup; 
    documentoContext        : any     = {};
    readOnly                : boolean = false;
    user                    : any     = {};
    _grupos                 : GruposModel[];
    
    set grupos(val){
        this._grupos = val.map((g)=>{
            return g
        })
    }

    get grupos(){
        return this._grupos
    }

    constructor(
        public formBuilder: FormBuilder,
        public docs       : DocschecklistService,
        public authService: AuthService,
        public restriccion: RestriccionesService,
        public funciones: SharedFunctions,
        public factoring  : FactoringService)
    {
		this.user = this.authService.user;
    }
    
    ngOnInit(): void {
        if (!!this.operacion.operacion){
            this.operacion.tipo_linea_solicitada = this.operacion.operacion.tipo_linea_solicitada
        }

        this.initComponent(this.operacion.id);
        console.log(this.operacion)

        // this.initDocsBeneficiarios(this.beneficiario);

        this.uploadForm = this.formBuilder.group({
            profile: ['']
          });

    }

    get esBeneficiarioNatural(){
        if(this.step3){
            return this.beneficiarioData.tipo_persona == 1
        } else {
            return this.operacion.beneficiario_tipo == 'N'
        }
    }

    get esBeneficiarioJuridico(){
        if(this.step3){
            return this.beneficiarioData.tipo_persona == 2
        } else {
            return this.operacion.beneficiario_tipo == 'J'
        }
    }

    evaluarObligatorio(doc){
        if(doc.obligatorio){
            // console.log(doc)
            // console.log(`Persona actual: ${} ${doc.tipo_persona}, ${doc.documento_descripcion}`)
            if(!doc.tipo_persona){
                return true
            }
            
            if(doc.tipo_persona == 2 && this.esBeneficiarioJuridico){
                return true
            }
            
            if(doc.tipo_persona == 1 && this.esBeneficiarioNatural){
                return true
            }
        } else {
            return false
        }

    }


    /**
     * Retorna los grupos existentes, con sus documentos
     * pertenecientes a esta categoria, y a su vez cada documento PODRIA tener adjuntos
     * o no
     * @param idProceso Id del proceso a buscar
     */
    initComponent(idProceso){
        var adjuntos: any[];
        var documentos : any[];
        let req
        
        this.factoring.obtenerOperacionDocumentos(idProceso)
            .then((res:any)=>{
                adjuntos = res.results
                return this.factoring.obtenerDocumentosEstacion(this.estacion)
            }).then((res: any)=>{
            documentos       = res.results
            documentos.forEach((d)=> {
                d.id = d.documento
                d.descripcion = d.documento_descripcion

                if(d.id == 33 && this.estacion == 8){
                    d.estacionRiesgos = true
                }
            })
            let documentosMapped = documentos.map((d)=>{
                d.adjunto = null
                d.checked = false
                let index = adjuntos.findIndex((a)=> a.documento === d.id)
                if(index >= 0){
                    d.adjunto = adjuntos[index]
                    d.adjunto.diasTranscurridos = this.funciones.restaFechas(d.adjunto.fecha)
                    d.adjunto.diasVigencia = d.vigencia_dias - d.adjunto.diasTranscurridos
                    d.checked = true
                    d.loading = false

                    if(d.id == 33 && this.estacion == 8){
                        d.estacionRiesgos = true
                    }
                }
                return d
            })
            let grupos = documentosMapped.reduce((acc: any[],item: any)=>{
                let index = acc.findIndex( i => i.grupo === item.grupo)
                if(index < 0){
                    acc.push({
                        grupo            : item.grupo,
                        grupo_descripcion: item.grupo_descripcion,
                        documentos       : [item]
                    })
                } else {
                    acc[index].documentos.push(item)
                }
                return acc
            },[])

            function compare( a, b ) {
                if ( a.grupo < b.grupo ){
                    return -1;
                }
                if ( a.grupo > b.grupo ){
                    return 1;
                }
                return 0;
            }

            grupos.sort(compare)
            this.grupos=grupos                
            console.log(this.grupos)
        })
    }

    initDocsBeneficiarios(idProceso) {
        this.factoring.obtenerDocumentosBeneficiarios(idProceso)
        .then(res => {
            console.log(res);
        })
        .catch(error => {
            console.error(error);
        });
    }

    onFileSelect(event) {
        if (event.target.files.length > 0) {
          const file = event.target.files[0];         
          this.uploadForm.get('profile').setValue(file);
          const formData = new FormData();
          formData.append('adjunto', this.uploadForm.get('profile').value);
          formData.append('operacion', this.operacion.id.toString());
          formData.append('documento', this.documentoContext.id);
          formData.append('peso', file.size);
          this.factoring.guardarDocumentoOperacion(formData)
          .then(res=>{
            this.initComponent(this.operacion.id)
          })
          .catch(res=>{
            })

        }
      }

    evalDocumento(item){
        this.documentoContext = item;
        var fileSelect = document.createElement("input"); 
        fileSelect.setAttribute("id", "fielSelect"); 
        fileSelect.setAttribute("type", "file"); 
        fileSelect.setAttribute("name", "profile"); 
        fileSelect.setAttribute("style", "display: none;"); 
        let formularioDetalles = document.getElementById("check-list-detalles")
        formularioDetalles.appendChild(fileSelect)
        document.getElementById("fielSelect").click()
        document.getElementById("fielSelect").addEventListener('change', (event) => {
            this.onFileSelect(event)
            formularioDetalles.removeChild(fileSelect)
        });
        // document.getElementsByName('profile')[0].click();
       
    }

    verifyChange(event,doc){
        let indexGrupo = this.grupos.findIndex( g => g.grupo === doc.grupo)
        let indexDocumento = this.grupos[indexGrupo].documentos.findIndex( d => d.id === doc.id)
        let documentoAdjunto = this.grupos[indexGrupo].documentos[indexDocumento].adjunto

        this.factoring.actualizarDocumentoOperacion(documentoAdjunto.id, documentoAdjunto,event).then((response)=>{
            this._grupos[indexGrupo].documentos[indexDocumento].adjunto.verificado = event
            this.grupos[indexGrupo].documentos[indexDocumento].loading = false
        }).catch((err)=>{
            this.grupos[indexGrupo].documentos[indexDocumento].loading = false
        })

        setTimeout(()=>{
            this.grupos[indexGrupo].documentos[indexDocumento].adjunto.verificado = !event
        },5)
    }

    openDocument({adjunto}){
        // console.log(adjunto);
        let urlDoc = adjunto.adjunto;
        const ultimoSlash = new URL(urlDoc).pathname;
        const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1]
        console.log(adjuntoName);
        console.log(adjunto.adjunto);
        this.downloadFile(urlDoc, adjuntoName);
        // var win = window.open(urlDoc, '_blank');
        // win.focus();
    }

    downloadFile(url, name) {
        this.factoring.downloadFile(url).then(
            (x: any) => {
            console.log(x);
            var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = name;
            link.click();
            }, async (reason) => {
            console.log(reason);
            }), err => {
            console.error(err);
        };
    }
}



export class GruposModel{
    public grupo            : number;
    public grupo_descripcion: string;
    public documentos       : DocumentoModel[];

}

export class DocumentoModel{
    public id               : number;
    public descripcion      : string;
    public grupo            : number;
    public grupo_descripcion: string;
    public adjunto?         : DocumentoAdjuntoModel;
    public checked?         : boolean;
    public loading?         : boolean;
    public estacionRiesgos?         : boolean;
}

export class DocumentoAdjuntoModel{
    public id        : number;
    public operacion : number;
    public documento : number;
    public adjunto   : string;
    public verificado: boolean;
}