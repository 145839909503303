import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ExcedentesService } from 'app/core/services/excedentes/excedentes.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { SharedFunctions } from 'app/core/shared/functions';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-compensaciones-modal-confirmar',
  templateUrl: './compensaciones-modal-confirmar.component.html',
  styleUrls: ['./compensaciones-modal-confirmar.component.css']
})
export class CompensacionesModalConfirmarComponent implements OnInit {

  @ViewChild('modalCompensacionesConfirmar', { static: false }) public modalCompensacionesConfirmar;
  @Input() clientesCompensaciones: any = [];
  @Input() tipoCambio = null;
  @Output() reload = new EventEmitter<any>();
  faTimes = faTimes;
  constructor(
    public modalService: NgbModal,
    public excedentesServicio: ExcedentesService,
    public formbuild: FormBuilder,
    private factoringService: FactoringService,
    public authService: AuthService,
    public functions: SharedFunctions,
  ) { }

  public resumenClientes: any[] = [];
  public monedas: any[] = [
    { id: 1, nombre: 'Soles' },
    { id: 2, nombre: 'Dólares' },
  ]
  ngOnInit(): void {
  }


  displayModal(cliente) {
    this.obtenerResumen(cliente)

    this.modalService.open(this.modalCompensacionesConfirmar, {
      size: 'xl'
    }).result.then((response: any) => {
    })
  }

  obtenerResumen(clientesDevolver) {
    console.log(clientesDevolver)
    // console.log(clientesDevolver, 'enmodal')
    this.resumenClientes = clientesDevolver.map((cliente) => {

      let total_soles_Cartera  : number = cliente.totalDocs.soles
      let total_dolares_Cartera: number = cliente.totalDocs.dolares
      let total_soles_Cuenta   : number = cliente.totalCXC.soles
      let total_dolares_Cuenta : number = cliente.totalCXC.dolares


      let total_soles = total_soles_Cuenta + total_soles_Cartera
      let total_dolares = total_dolares_Cartera + total_dolares_Cuenta

      return {
        "nombre"            : cliente.nombre,
        "ruc"               : cliente.ruc,
        "empresa"           : cliente.empresa,
        "cliente"           : cliente.cliente,
        "aceptante"         : cliente.aceptante,
        "excedente"         : cliente.excedente,
        "total_soles"       : new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(total_soles)),
        "total_dolares"     : Number(total_dolares).toLocaleString('en-US', { minimumFractionDigits: 2 }),
        "moneda"            : 1,
        // "cuentas_cobrar"    : cliente.cuentas_cobrar,
        // "documentos_cartera": cliente.documentos_cartera,
        "excedentes"          : cliente.preselected,
        "cuentas_cobrar": cliente.preselectedcxc,
        "documentos_cartera"      : cliente.preselecteddocs,
        "totalCXC": {
          soles: this.formatoEN(cliente.totalCXC.soles, true), 
          dolares:this.formatoEN(cliente.totalCXC.dolares, false)
        },
        "totalDocs": {
          soles: this.formatoEN(cliente.totalDocs.soles, true), 
          dolares:this.formatoEN(cliente.totalDocs.dolares, false)
        },
        "totalExc": {
          soles: this.formatoEN(cliente.totalExc.soles, true), 
          dolares:this.formatoEN(cliente.totalExc.dolares, false)
        }  ,
      }
    })
  }

  formatoEN(val,item){
    if(item){
      return new Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(Number(val))
    } else {
      return Number(val).toLocaleString('en-US', { minimumFractionDigits: 2 })
    }
  }



  async obtenerSeleccion() {
    console.log(this.resumenClientes)

    const enviarFacturasSunatResult = await Swal.fire({
      title: 'Enviar facturas a SUNAT',
      text: "Las facturas generadas se enviarán a SUNAT de manera automática",
      icon: 'info',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, enviar',
      cancelButtonText: 'No enviar',
      allowOutsideClick: false,
    })

    let sunat = enviarFacturasSunatResult.isConfirmed ? 1 : 0;

    this.resumenClientes.forEach(req => {
      console.log(req)
      let data = {
        "empresa"           : req.empresa,
        "cliente"           : req.cliente,
        "aceptante"         : req.aceptante,
        "excedentes"        : req.excedentes,
        "cuentas_cobrar"    : req.cuentas_cobrar,
        "documentos_cartera": req.documentos_cartera,
        "tipo_cambio_monto": this.tipoCambio,
      }
      console.log(data)
      this.excedentesServicio.enviarAplicaciones(data, sunat).then((res: any) => {
        return Swal.fire(
          'Aprobado',
          'Compensaciones aplicadas con éxito !',
          'success'
        )
      }).catch((e) => {
        console.error(e)
      })
    })


    setTimeout(() => {
      this.modalService.dismissAll()
    }, 1000)
  }
}
