import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { faSyncAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { toJSDate } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-calendar';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { RiesgosService } from 'app/core/services/riesgos.service';

@Component({
	selector: 'app-riesgo-tabs',
	templateUrl: './riesgo-tabs.component.html',
	styleUrls: ['./riesgo-tabs.component.css']
})
export class RiesgoTabsComponent implements OnInit {
	faTimes = faTimes;
	faSyncAlt = faSyncAlt;
	public formRiesgos: FormGroup;
	public formDeudores: FormGroup;
	public empRelacionadas: any[] = [];
	public deudores: any[] = [];
	public comportamientos: any[] = [];
	public columnDefs: any[];
	public columnDeudores: any[];
	public dummySelect: any[];
	public exposicion: any[];
	public evaluacion: any[];
	public tiposCredito: any[] = [];
	public deudaVigente: any = 0;
	public operacionesCurso: any = 0;
	public fechaRiesgo: any;
	public total_operaciones_riesgo: any = 0;
	public operacionRiesgos: any;
	public haveTipoCambio: boolean = true;
	editormode: boolean = false;
	tipodoc_list: any;
	deudaMora: any;

	set editorMode(value) {
		this.editormode = value
	}

	get editorMode() {
		return this.editormode
	}

	public accordionActive: any = 0;
	_readonly = false;
	@Input() set readonly(value) {
		if (value && this.formRiesgos) {
			this.formRiesgos.disable()
		} else if (this.formRiesgos) {
			this.formRiesgos.enable()
		}
		this._readonly = value
	}

	get readonly() {
		return this._readonly
	}

	public monedas: any[] = [
		{
			"id": 1,
			"descripcion": "Soles"
		},
		{
			"id": 2,
			"descripcion": "Dólares"
		}
	];
	active = 1;
	@Input() operacion: any = null;
	cliente: any = null;
	@ViewChild('e_tipocambio', { static: false }) public e_tipocambio;

	deudas: any;

	constructor(
		private formbuild: FormBuilder,
		private authService: AuthService,
		private riesgosService: RiesgosService,
		public configs: DocschecklistService,
		private docService: DocschecklistService,
		private modalService: NgbModal,
		private router: Router,
	) {
		this.initForm()
		this.formDeudores = this.formbuild.group([]);
	}

	ngOnInit(): void {
		this.getData()
		//this.readonly = false
		console.log(this._readonly, 'Activar form');

	}

	initForm() {
		// this.docService.obtenerTiposDeCambio(this.formatDate(new Date()))
		this.formRiesgos = this.formbuild.group({
			ruc: ["", []],
			nombre: ["", []],
			exposicion: [1, []],
			evaluacion: [1, []],
			comentario_evaluacion: ['', []],
			comportamiento_la_ft: ['', []],
			resumen_riesgos: ["", []],
			comportamiento_interno: ["", []],
			comportamiento_central_riesgos: ["", []],
			facturas: [false, []],
			letras: [false, []],
			instrumentos_negociar: [false, []],
			ratio_rentabilidad_monto: [null, []],
			ratio_rentabilidad_comentario: [null, []],
			ratio_endeudamiento_monto: [null, []],
			ratio_endeudamiento_comentario: [null, []],
			ratio_liquidez_monto: [null, []],
			ratio_liquidez_comentario: [null, []],
			capacidad_pago: [null, []],
			aspectos_llamativos_la_ft: [null, []],
			analista_riesgo_nombre: [null, []],
			comportamiento_la_ft_descripcion: [null, []],
			tipos_detalles_: [[], []],
			tipos_detalles: [[], []],
		});

		this.dummySelect = [
			{
				descripcion: 'Empresa 1',
				id: 1,
			},
			{
				descripcion: 'Empresa 2',
				id: 2,
			},
			{
				descripcion: 'Empresa 3',
				id: 3,
			},
			{
				descripcion: 'Empresa 4',
				id: 4,
			},
			{
				descripcion: 'Empresa 5',
				id: 5,
			},
		]

		this.columnDefs = [
			{
				headerName: 'N°',
				pipe: 'indexcol',
				sortable: true
			},
			{
				headerName: 'Razón Social',
				field: 'nombre',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName: 'RUC',
				field: 'ruc',
				sortable: true,
				defaultInfo: 'Aún no aprobado',
				class: 'text-center'
			},
			{
				headerName: 'Comportamiento',
				field: 'comportamiento_la_ft_descripcion',
				sortable: true,
				class: 'text-center'
			},
			{
				headerName: 'Exposición',
				field: 'exposicion',
				sortable: true,
				class: 'text-center',
				pipe: 'function',
				function: function (row) {
					if (row.moneda_exposicion == 1) {
						let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(row.exposicion))
						return `S/ ${number}`
					}
					else {
						return `$ ${Number(row.exposicion).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
					}
				},
			},
		];
	}

	gotoDocumentos(cliente, aceptante) {
		this.router.navigate(['/documentos_cartera', cliente, aceptante])
	}

	gotoCuentasCobrar(cliente, aceptante) {
		this.router.navigate(['/cuentas-x-cobrar', aceptante])
	}

	getData(operacion = this.operacion) {
		this.riesgosService.spinnerOn();
		let { beneficiario } = operacion
		let cliente          = this.riesgosService.obtenerClientes(beneficiario)
		// let deudas           = this.riesgosService.obtenerBeneficiarioDeudadocumentos(beneficiario)
		let exposicion       = this.riesgosService.obtenerDocumentosAprobados(beneficiario)
		let evaluacion       = this.riesgosService.obtenerEvaluaciones()
		let riesgos          = this.riesgosService.obtenerOperacionesRiesgos(this.operacion.id)
		let comportamientos  = this.riesgosService.obtenerComportamientoEmpresas(this.operacion.id)
		let tiposDeCambio    = this.docService.obtenerTiposDeCambio(this.formatDate(new Date()));
		let tiposCredito     = this.riesgosService.obtenerTiposCredito();
		let deudores         = this.riesgosService.obtenerDeudoresRiesgo(beneficiario) 
		let tiposDetalles    = this.configs.obtenerTipoDetalles() 

		this.configs.obtenerTipoDetalles().then((res: any)=>{

		  })
		
		Promise.all([
			cliente,
			exposicion,
			evaluacion,
			riesgos,
			comportamientos,
			tiposDeCambio,
			tiposCredito,
			deudores,
			tiposDetalles])
		.then((res: any[])=>{


			this.exposicion      = res[1].results.length > 0 ? res[1].results: [{moneda: this.operacion.moneda}]
			this.evaluacion      = res[2].results
			this.loadData(res[0],res[3])

			if(!res[3].fecha_riesgos){
				this.guardarDatosNumericos()
			} else {
				this.riesgosService.obtenerOperacionesRiesgos(this.operacion.id).then((res: any)=>{
					let mora              = res[4]
					let riesgo            = res
					this.fechaRiesgo      = this.dateComentarios(riesgo.fecha_riesgos)
					this.operacionesCurso = riesgo.monto_operaciones_curso
					this.deudaVigente     = riesgo.deuda_vigente
					this.deudaMora        = riesgo.deuda_mora
					this.sumar()
				})
			}


			this.comportamientos = res[4].results
			this.tiposCredito = res[6].results
			this.deudores     = res[7].results
			this.tipodoc_list = res[8].results.sort(function (a, b) {
				if (a.descripcion > b.descripcion) {
					return 1;
				}
				if (a.descripcion < b.descripcion) {
					return -1;
				}
				return 0;
				});


				if (!res[6]['results'][0]) {
					this.haveTipoCambio = false
				}

				if (res[0].grupo_economico) {
					let empresasRelacionadas = this.riesgosService.obtenerEmpresasRelacionadas(res[0].grupo_economico)
					// let deudores = this.riesgosService.obtenerDeudoresRiesgo() 
					return Promise.all([empresasRelacionadas, deudores])
				} else {
					this.riesgosService.spinnerOff();
				}
			}).then((res: any[]) => {
				if (res) {
					this.empRelacionadas = res[0].results.filter((item) => item.id != this.operacionRiesgos.beneficiario.id)
				}
			}).catch(error => {
				console.error(error);
			}).finally(() => {
				this.riesgosService.spinnerOff();
				this.sumar()
			})
	}
	
	loadData(cliente,riesgo){
		this.cliente = cliente
		this.operacionRiesgos = riesgo
		// console.log(riesgo)
		// if(!riesgo.fecha_riesgos){
		// 	this.guardarDatosNumericos()
		// } else {
		// 	this.fechaRiesgo = this.dateComentarios(riesgo.fecha_riesgos)
		// 	this.operacionesCurso = riesgo.monto_operaciones_curso
		// 	this.deudaVigente = riesgo.deuda_vigente
		// 	this.deudaMora = mora.total_mora ? mora.total_mora : 0.00 
		// 	this.sumar()
		// }

		this.formRiesgos.get('ruc').setValue(cliente.ruc)
		this.formRiesgos.get('nombre').setValue(cliente.nombre)
		this.formRiesgos.get('comentario_evaluacion').setValue(riesgo.comentario_evaluacion)
		this.formRiesgos.get('resumen_riesgos').setValue(riesgo.beneficiario.resumen_riesgos)
		this.formRiesgos.get('comportamiento_interno').setValue(riesgo.beneficiario.comportamiento_interno)
		this.formRiesgos.get('comportamiento_central_riesgos').setValue(riesgo.beneficiario.comportamiento_central_riesgos)
		this.formRiesgos.get('facturas').setValue(riesgo.beneficiario.facturas)
		this.formRiesgos.get('letras').setValue(riesgo.beneficiario.letras)
		this.formRiesgos.get('instrumentos_negociar').setValue(riesgo.beneficiario.instrumentos_negociar)
		this.formRiesgos.get('instrumentos_negociar').setValue(riesgo.beneficiario.instrumentos_negociar)
		this.formRiesgos.get('ratio_rentabilidad_monto').setValue(riesgo.beneficiario.ratio_rentabilidad_monto)
		this.formRiesgos.get('ratio_rentabilidad_comentario').setValue(riesgo.beneficiario.ratio_rentabilidad_comentario)
		this.formRiesgos.get('ratio_endeudamiento_monto').setValue(riesgo.beneficiario.ratio_endeudamiento_monto)
		this.formRiesgos.get('ratio_endeudamiento_comentario').setValue(riesgo.beneficiario.ratio_endeudamiento_comentario)
		this.formRiesgos.get('ratio_liquidez_monto').setValue(riesgo.beneficiario.ratio_liquidez_monto)
		this.formRiesgos.get('ratio_liquidez_comentario').setValue(riesgo.beneficiario.ratio_liquidez_comentario)
		this.formRiesgos.get('capacidad_pago').setValue(riesgo.beneficiario.capacidad_pago)
		this.formRiesgos.get('aspectos_llamativos_la_ft').setValue(riesgo.beneficiario.aspectos_llamativos_la_ft)
		this.formRiesgos.get('analista_riesgo_nombre').setValue(riesgo.analista_riesgo_nombre)
		this.formRiesgos.get('comportamiento_la_ft_descripcion').setValue(riesgo.beneficiario.comportamiento_la_ft_descripcion)
		this.formRiesgos.get('comportamiento_la_ft').setValue(riesgo.beneficiario.comportamiento_la_ft)
		this.formRiesgos.get('exposicion').setValue(this.operacionRiesgos.moneda_exposicion)

		let filtered = riesgo.beneficiario.tipos_detalles.map((item) => item.id)
		this.formRiesgos.controls['tipos_detalles_'].setValue(filtered)
		this.formRiesgos.controls['tipos_detalles'].setValue(riesgo.tipos_detalles)

		this.formRiesgos.get('ruc').disable()
		this.formRiesgos.get('nombre').disable()
		this.formRiesgos.get('exposicion').disable()
		this.formRiesgos.get('analista_riesgo_nombre').disable()
	}

	guardaRiesgo() {
		let body = this.operacionRiesgos

		for (const key in body) {
			if (this.formRiesgos.get(key)) {
				body[key] = this.formRiesgos.get(key).value
			}
		}

		for (const key in body.beneficiario) {
			if (this.formRiesgos.get(key)) {
				body.beneficiario[key] = this.formRiesgos.get(key).value
			}
		}


		body.beneficiario.tipos_detalles = this.formRiesgos.get('tipos_detalles_').value.map((id) => {
			return this.tipodoc_list.find(item => item.id == id)
		})


		this.riesgosService.spinnerOn();
		this.riesgosService.actualizarOperacionesRiesgos(body.id, body).then((res) => {
			this.getData()
		})
	}

	errorNoHayTipoCambio() {
		this.modalService.open(this.e_tipocambio, {
			size: 'md'
		}).result.then((response: any) => {
			return this.docService.obtenerTiposDeCambio(this.formatDate(new Date()))
		}).then((res) => {
			let tiposDeCambio = res['results'][0]
			if (!tiposDeCambio) {
				this.haveTipoCambio = false
			} else {
				this.haveTipoCambio = true
			}
		})
	}

	formatDate(date) {
		return date.getFullYear() +
			"-" + (date.getMonth() + 1) +
			"-" + date.getDate();
	}

	guardarDatosNumericos() {
		this.obtenerStatusActual()
		let moneda_deuda = (this.exposicion[0] && this.exposicion[0].moneda) ? this.exposicion[0].moneda : 1
		
		this.riesgosService.obtenerBeneficiarioDeudadocumentos(this.operacion.beneficiario,moneda_deuda).then((res: any)=>{
			let deudaRiesgo = res.results[0]
			let body = {
				moneda_exposicion       : moneda_deuda,
				monto_operacion_actual  : this.operacion.monto,
				monto_operacion_presente: this.operacionRiesgos.monto_operacion_presente,
				monto_operaciones_curso : this.operacionesCurso,
				deuda_vigente           : deudaRiesgo.total_vigente,
				deuda_mora              : deudaRiesgo.total_mora,
			}

			return this.riesgosService.actualizarOperacionesRiesgosPatch(this.operacionRiesgos.id, body)
		}).then((res) => {
			this.getData()
		})
	}

	obtenerStatusActual() {
		let deuda = this.exposicion.reduce((acc, item) => {
			acc.doc_cartera = +parseFloat(item.monto_documentos_cartera)
			acc.operaciones_aprobadas = +parseFloat(item.monto_operaciones_aprobadas)
			acc.operaciones_desembolsar = +parseFloat(item.monto_operaciones_desembolsar)
			return acc
		}, {
			doc_cartera: 0,
			operaciones_aprobadas: 0,
			operaciones_desembolsar: 0,
		})
		this.deudaVigente = parseFloat(deuda.doc_cartera)
		this.operacionesCurso = parseFloat(deuda.operaciones_aprobadas)
		this.sumar()
	}

	dateComentarios(fecha) {
		let yy = fecha.split('-')[0];
		let mm = fecha.split('-')[1];
		let dd = fecha.split('-')[2];
		dd = dd.split('T')[0]
		let hora = fecha.split('T')[1].split(':')

		let H = Number(hora[0])
		let M = hora[1]
		let HoraDia = 'AM'
		if (H > 12) {
			H = H - 12
			HoraDia = 'PM'
		}

		let HoraCompleta = `${H}:${M} ${HoraDia}`
		let date = `${dd}/${mm}/${yy} ${HoraCompleta}`;
		return date
	}

	sumar() {
		this.total_operaciones_riesgo = 0
		// this.operacionRiesgos.monto_operacion_presente ? null : this.operacionRiesgos.monto_operacion_presente = parseFloat(!!this.operacion.monto ? 0 : this.operacion.monto)
		!!this.operacionesCurso ? null : this.operacionesCurso = 0
		!!this.deudaVigente ? null : this.deudaVigente = 0

		this.total_operaciones_riesgo += parseFloat(this.operacionesCurso);
		this.total_operaciones_riesgo += parseFloat(!!this.deudaVigente ? this.deudaVigente : 0);
		this.total_operaciones_riesgo += parseFloat(!!this.deudaMora ? this.deudaMora: 0);
		this.total_operaciones_riesgo += parseFloat(!!this.operacion ? this.operacion.monto: 0);
		// if (this.operacionRiesgos.monto_operacion_presente != 0) {
		// 	this.total_operaciones_riesgo += parseFloat(this.operacionRiesgos.monto_operacion_presente).toFixed(2);
		// } else {
		// 	this.total_operaciones_riesgo += parseFloat(this.operacion.monto).toFixed(2);
		// }
		// this.total_operaciones_riesgo = Number(this.total_operaciones_riesgo).toFixed(2)

	}

	editableDeudor({
		tipo_credito,
		resumen_riesgos,
		comportamiento_interno,
		comportamiento_central_riesgos,
		instrumentos_negociar,
		facturas,
		letras,
		tipos_detalles
	}) {


		this.formDeudores = this.formbuild.group({
			tipo_credito: [tipo_credito, []],
			resumen_riesgos: [resumen_riesgos, []],
			comportamiento_interno: [comportamiento_interno, []],
			comportamiento_central_riesgos: [comportamiento_central_riesgos, []],
			instrumentos_negociar: [instrumentos_negociar, []],
			tipos_detalles_: [[], []],
			facturas: [facturas, []],
			letras: [letras, []],
		})

		if (tipos_detalles.length > 0) {
			let filtered = tipos_detalles.map((item) => item.id)
			this.formDeudores.get('tipos_detalles_').setValue(filtered)
		}
	}

	habilitaEditor(deudor) {
		this.editableDeudor(deudor)
		this.editorMode = true
	}

	guardarFormulario(deudor, index) {
		this.editorMode = false
		let valores = this.formDeudores.value
		let req = {}
		for (var prop in valores) {
			if (valores[prop] && (valores[prop] != '')) {
				req[prop] = valores[prop]
			}
		}
		req['facturas'] = valores.facturas
		req['letras'] = valores.letras

		req['tipos_detalles'] = this.formDeudores.get('tipos_detalles_').value.map((id) => {
			return this.tipodoc_list.find(item => item.id == id)
		})



		this.riesgosService.spinnerOn()
		this.riesgosService.actualizarDeudor(deudor.id, req).then((res) => {
			this.deudores[index] = res
			return this.riesgosService.obtenerDeudoresRiesgo(this.cliente.id)
		}).then((res: any) => {
			this.riesgosService.spinnerOff('La información fue guardada con éxito')
			this.deudores = res.results
		})
	}

	get canSave() {
		const perfilesPermitidos = [
			this.authService.perfil.perfilAdmin,
			this.authService.perfil.perfilOficialDeNegocioID,
			this.authService.perfil.perfilGerenciaComercialID,
			this.authService.perfil.perfilGerenciaGeneralID,
			this.authService.perfil.perfilAnalistaRiesgosID,
			this.authService.perfil.perfilJefeDeAdmisionID,
			this.authService.perfil.perfilAsesorLegalID,
			this.authService.perfil.perfilLegalID,
			this.authService.perfil.perfilJefeDeOperacionesID,
			this.authService.perfil.perfilAnalistaOperacionesID,
			this.authService.perfil.perfilSubGerenciaComercialID,
			this.authService.perfil.perfilTesoreriaID,
			this.authService.perfil.perfilLiderDeTesoreriaID,
			this.authService.perfil.perfilRecepcionID,
			this.authService.perfil.perfilClienteID,
			this.authService.perfil.perfilAnalistaOperacionesID,
			this.authService.perfil.perfilAsistenteCobranzasID,
			this.authService.perfil.perfilAsistenteComercialID,
		]

		return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
	}
}
