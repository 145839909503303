import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { ReportesService } from 'app/core/services/reportes.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { NgxSpinnerService } from 'ngx-spinner';
import { utils, writeFile } from 'xlsx';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-reporteverificacion',
  templateUrl: './reporteverificacion.component.html',
  styleUrls: ['./reporteverificacion.component.css']
})
export class ReporteverificacionComponent implements OnInit {
	public componentForm    : FormGroup
	colReporte              : any;
	rowData                 : any[] = [];
	dirtyData               : any = false;
	public pageTableActual  : number;
	public pageTableTotal   : number;
	public pageTablePerPages: number = 50;
	public pageTablePages   : number[];

	constructor(
		public localService  : LocalServiceService,
		public reporteService: ReportesService,
		private sanitized    : DomSanitizer,
		public spinner       : NgxSpinnerService,
		public formbuild     : FormBuilder,
		public functions     : SharedFunctions
	) { }

  ngOnInit(): void {

    this.componentForm = this.formbuild.group({
			fechas         : [ , [Validators.required]],
		fecha_pago__gte: [ , [Validators.required]],
		fecha_pago__lte: [ , [Validators.required]],
	})

    // this.componentForm.get('fecha_pago__gte').valueChanges.subscribe((value)=>{
    //   if(value && this.componentForm.get('fecha_pago__lte').value){
    //     this.loadData()
    //   }
    // })

    this.componentForm.get('fecha_pago__lte').valueChanges.subscribe((value)=>{
      if(value && this.componentForm.get('fecha_pago__gte').value){
        this.loadData()
      }
    })
	

    this.colReporte = [
			{
				headerName: 'Número Pago',
				field: 'numero_pago',
			},
			{
				headerName: 'Fecha Pago',
				field: 'fecha_pago',
				sortable: true,
				// defaultInfo: 'Aún no aprobado',
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					if(!row.fecha_pago){
						return 0
					}
					let hora_dia = 'AM';
					let fecha = row.fecha_pago.split('-')
					let ano = fecha[0]
					let mes = fecha[1]
					let dia = fecha[2].split('T')[0]
					return `${dia}/${mes}/${ano}`
				},
			},
			{
				headerName: 'Moneda',
				field: 'moneda',
				sortable: true,
				// defaultInfo: 'Aún no aprobado',
				class: 'text-center',
				pipe      : 'function',
				function : function(row){
					if(row.moneda_id == 1){
						return 'Soles'
					} else {
						return 'Dólares'
					}
				},
			},
			{
				headerName: 'Abono Anterior',
				field: 'abonado_anterior',
				sortable: true,
				class: 'text-center',
				pipe: "functionInnertHTML",
				function: (row) => {
					let moneda = row.moneda_id
					let retorno;
					if(!row.abonado_anterior){
						row.abonado_anterior = 0
					}

					if(!row.monto_abonado){
						row.monto_abonado = 0
					}
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.abono_anterior))
						retorno = `S/ ${number}`
					}
					else{
						retorno = `$ ${Number(row.abono_anterior).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}

					// if(row.abono_anterior != row.monto_abonado){
					// 	let valor = ''
					// 	valor+=`<span style="color: red; font-weight:bold;">${retorno}</span>`
					// 	return this.sanitized.bypassSecurityTrustHtml(valor)
					// }
					return retorno;
				},
			},
			{
				headerName: 'Abonado',
				field     : 'monto_abonado',
				sortable  : true,
				pipe      : 'functionInnertHTML',
				class     : 'text-center',
				function  : (row)=>{
					let moneda = row.moneda_id
					let retorno;
					if(!row.monto_abonado){
						row.monto_abonado = 0
					}

					if(!row.abonado_anterior){
						row.abonado_anterior = 0
					}
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.monto_abonado))
						retorno = `S/ ${number}`
					}
					else{
						retorno = `$ ${Number(row.monto_abonado).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					return retorno;
				},
			},
			{
				headerName:  'Documentos' ,
				field: 'estado_descripcion',
				sortable: true,
				class: 'text-center',
				pipe: "functionInnertHTML",
				function: (row) => {      
					if (row.docs && row.docs.length > 0) {
					let textoHTML = ''
					row.docs.forEach(element => {
						textoHTML+= `<button class="btn btn-danger btn-round btn-sm" style="font-size: 0.7em; padding: .2em .8em;">${element}</button><br>`
					})
					return this.sanitized.bypassSecurityTrustHtml(textoHTML)
					} else {
					return 0;
					}
				},
			},
			{
				headerName: 'Deuda Saldo',
				field: 'deuda_saldo',
				sortable: true,
				pipe      : 'functionInnertHTML',
				class: 'text-center',
				function : (row)=>{
					if(!row.deuda_saldo){
						row.deuda_saldo = 0
					}

					if(!row.deuda_saldo_calc){
						row.deuda_saldo_calc = 0
					}
					let moneda = row.moneda_id
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.deuda_saldo))
						retorno = `S/ ${number}`
					}
					else{
						retorno = `$ ${Number(row.deuda_saldo).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}

					if(row.deuda_saldo != row.deuda_saldo_calc){
						let valor = ''
						valor+=`<span style="color: red; font-weight:bold;">${retorno}</span>`
						return this.sanitized.bypassSecurityTrustHtml(valor)
					}
					return retorno;
				},
			},
			{
				headerName: 'Deuda Saldo Calculado',
				field: 'deuda_saldo_calc',
				sortable: true,
				pipe      : 'functionInnertHTML',
				class: 'text-center',
				function : (row)=>{
					if(!row.deuda_saldo){
						row.deuda_saldo = 0
					}

					if(!row.deuda_saldo_calc){
						row.deuda_saldo_calc = 0
					}
					let moneda = row.moneda_id
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.deuda_saldo_calc))
						retorno = `S/ ${number}`
					}
					else{
						retorno = `$ ${Number(row.deuda_saldo_calc).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}

					if(row.deuda_saldo != row.deuda_saldo_calc){
						let valor = ''
						valor+=`<span style="color: red; font-weight:bold;">${retorno}</span>`
						return this.sanitized.bypassSecurityTrustHtml(valor)
					}
					return retorno;
				},
			},
			{
				headerName: 'Excedente',
				field: 'excedente',
				sortable: true,
				pipe      : 'functionInnertHTML',
				class: 'text-center',
				function : (row)=>{
					if(!row.excedente){
						row.excedente = 0
					}

					if(!row.excedente_calc){
						row.excedente_calc = 0
					}
					let moneda = row.moneda_id
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.excedente))
						retorno = `S/ ${number}`
					}
					else{
						retorno = `$ ${Number(row.excedente).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
					if(row.excedente != row.excedente_calc){
						let valor = ''
						valor+=`<span style="color: red; font-weight:bold;">${retorno}</span>`
						return this.sanitized.bypassSecurityTrustHtml(valor)
					}
					return retorno;
				},
			},
			{
				headerName: 'Excedente Calculado',
				field: 'excedente_calc',
				sortable: true,
				pipe      : 'functionInnertHTML',
				class: 'text-center',
				function : (row)=>{
					if(!row.excedente){
						row.excedente = 0
					}

					if(!row.excedente_calc){
						row.excedente_calc = 0
					}
					let moneda = row.moneda_id
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.excedente_calc))
						retorno = `S/ ${number}`
					}
					else{
						retorno = `$ ${Number(row.excedente_calc).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}

					if(row.excedente != row.excedente_calc){
						let valor = ''
						valor+=`<span style="color: red; font-weight:bold;">${retorno}</span>`
						return this.sanitized.bypassSecurityTrustHtml(valor)
					}
					return retorno;
				},
			},
			{
				headerName: 'Intereses',
				field: 'intereses_devolver',
				sortable: true,
				pipe      : 'functionInnertHTML',
				class: 'text-center',
				function : (row)=>{
					if(!row.intereses_devolver){
						row.intereses_devolver = 0
					}

					if(!row.intereses_devolver_calc){
						row.intereses_devolver_calc = 0
					}
					let moneda = row.moneda_id
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.intereses_devolver))
						retorno = `S/ ${number}`
					}
					else{
						retorno = `$ ${Number(row.intereses_devolver).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
					if(row.intereses_devolver != row.intereses_devolver_calc){
						let valor = ''
						valor+=`<span style="color: red; font-weight:bold;">${retorno}</span>`
						return this.sanitized.bypassSecurityTrustHtml(valor)
					}
					return retorno;
				},
			},
			{
				headerName: 'Intereses Calculados',
				field: 'intereses_devolver_calc',
				sortable: true,
				pipe      : 'functionInnertHTML',
				class: 'text-center',
				function : (row)=>{
					if(!row.intereses_devolver_calc){
						row.intereses_devolver_calc = 0
					}

					if(!row.intereses_devolver){
						row.intereses_devolver = 0
					}
					let moneda = row.moneda_id
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.intereses_devolver_calc))
						retorno = `S/ ${number}`
					}
					else{
						retorno = `$ ${Number(row.intereses_devolver_calc).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					if(row.intereses_devolver_calc != row.intereses_devolver){
						let valor = ''
						valor+=`<span style="color: red; font-weight:bold;">${retorno}</span>`
						return this.sanitized.bypassSecurityTrustHtml(valor)
					}
					return retorno;
				},
			},
			{
				headerName: 'Mora',
				field: 'mora',
				sortable: true,
				pipe      : 'functionInnertHTML',
				class: 'text-center',
				function : (row)=>{
					if(!row.mora){
						row.mora = 0
					}

					if(!row.mora_calc){
						row.mora_calc = 0
					}
					let moneda = row.moneda_id
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.mora))
						retorno = `S/ ${number}`
					}
					else{
						retorno = `$ ${Number(row.mora).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}
					if(row.mora != row.mora_calc){
						let valor = ''
						valor+=`<span style="color: red; font-weight:bold;">${retorno}</span>`
						return this.sanitized.bypassSecurityTrustHtml(valor)
					}
					return retorno;
				},
			},
			{
				headerName: 'Mora Calculados',
				field: 'mora_calc',
				sortable: true,
				pipe      : 'functionInnertHTML',
				class: 'text-center',
				function : (row)=>{
					if(!row.mora){
						row.mora = 0
					}

					if(!row.mora_calc){
						row.mora_calc = 0
					}
					let moneda = row.moneda_id
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.mora_calc))
						retorno = `S/ ${number}`
					}
					else{
						retorno = `$ ${Number(row.mora_calc).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}
					if(row.mora != row.mora_calc){
						let valor = ''
						valor+=`<span style="color: red; font-weight:bold;">${retorno}</span>`
						return this.sanitized.bypassSecurityTrustHtml(valor)
					}
					return retorno;
				},
			},

			{
				headerName: 'IGV Intereses',
				field: 'igv_intereses',
				sortable: true,
				pipe      : 'functionInnertHTML',
				class: 'text-center',
				function : (row)=>{
					if(!row.igv_intereses){
						row.igv_intereses = 0
					}

					if(!row.igv_intereses_calc){
						row.igv_intereses_calc = 0
					}

					let moneda = row.moneda_id
					let retorno;
					moneda = Number.parseInt(moneda)
					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.igv_intereses))
						retorno = `S/ ${number}`
					}
					else{
						retorno = `$ ${Number(row.igv_intereses).toLocaleString('en-US', {minimumFractionDigits: 2})}`

					}

					if(row.igv_intereses != row.igv_intereses_calc){
						let valor = ''
						valor+=`<span style="color: red; font-weight:bold;">${retorno}</span>`
						return this.sanitized.bypassSecurityTrustHtml(valor)
					}
					return retorno;
				},
			},

			{
				headerName: 'IGV Intereses Calculados',
				field: 'igv_intereses_calc',
				sortable: true,
				pipe      : 'functionInnertHTML',
				class: 'text-center',
				function : (row)=>{
					if(!row.igv_intereses){
						row.igv_intereses = 0
					}
					if(!row.igv_intereses_calc){
						row.igv_intereses_calc = 0
					}

					let moneda = row.moneda_id
					let retorno;
					moneda = Number.parseInt(moneda)

					if(moneda === 1 ){
						let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.igv_intereses_calc))
						retorno = `S/ ${number}`
					} else{
						retorno = `$ ${Number(row.igv_intereses_calc).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					}

					if(row.igv_intereses != row.igv_intereses_calc){
						let valor = ''
						valor+=`<span style="color: red; font-weight:bold;">${retorno}</span>`
						return this.sanitized.bypassSecurityTrustHtml(valor)
					}

					return retorno;
				},
			},
		];
  }


  loadTable(data) {
		this.rowData = [];
		this.rowData           = data.results
		this.pageTableActual   = data.page_number
		this.pageTableTotal    = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages    = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

	goToPage({ page, per_page, filtros }) {
		let { componentForm }= this
		let fecha_pago__gte     = componentForm.get('fecha_pago__gte').value
		let fecha_pago__lte     = componentForm.get('fecha_pago__lte').value
		this.reporteService.obtenerReporteValidacion(
			fecha_pago__gte,
			fecha_pago__lte,
			page,
			per_page
		).then((res : any) => {
			this.dirtyData = true
			this.loadTable(res)
			this.localService.setJsonValue('reporteverificaciontabla',{
				fecha_pago__gte,
				fecha_pago__lte,
				res
			})
		  this.spinner.hide();
		})
	}

  loadData(){
    let { componentForm }= this
    this.rowData = []
    this.spinner.show();

	let fecha_pago__gte     = componentForm.get('fecha_pago__gte').value
	let fecha_pago__lte     = componentForm.get('fecha_pago__lte').value
    this.reporteService.obtenerReporteValidacion(
		fecha_pago__gte,
		fecha_pago__lte,
		1,
		10
    ).then((res : any) => {
		this.dirtyData = true
		this.loadTable(res)
		this.localService.setJsonValue('reporteverificaciontabla',{
			fecha_pago__gte,
			fecha_pago__lte,
			res
		})
      this.spinner.hide();
    })
  }

  calendarOption(values) {
    let { componentForm , functions}= this

    if(!!values){
      componentForm.get('fecha_pago__gte').setValue(functions.formatDate(values[0]))
      componentForm.get('fecha_pago__lte').setValue(functions.formatDate(values[1]))
    }
  }

  goToReporteValidacion(){
		window.open('#/reporte/verificacion/tabla', '_blank');
  }


  async generarExcelCompleto() {

    let { componentForm }= this

	let fecha_pago__gte     = componentForm.get('fecha_pago__gte').value
	let fecha_pago__lte     = componentForm.get('fecha_pago__lte').value
	this.reporteService.obtenerReporteValidacion(
	fecha_pago__gte,
	fecha_pago__lte,
	1,
	1000
	).then((res : any) => {
		// this.loadTable(res)
		this.spinner.hide();
		this.generarExcel(res.results)
	})
  }

  generarExcel(data) {
	let reporte = this.cleanData(data)
    /* generate a worksheet */
    var ws = utils.json_to_sheet(reporte);

    /* add to workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hoja 1");

    /* write workbook and force a download */
    writeFile(wb, `Reporte - ${new Date().toLocaleString()}.xlsx`);
  }

  cleanData(data){
	let columnas = [
		'id',
		'tipo_pagador_id',
		'numero_pago',
		'moneda_id',
		'fecha_pago',
		'abono_anterior',
		'monto_abonado',
		'deuda_saldo',
		'deuda_saldo_calc',
		'excedente',
		'excedente_calc',
		'intereses_devolver',
		'intereses_devolver_calc',
		'mora',
		'mora_calc',
		'igv_intereses',
		'igv_intereses_calc',
		'docs',
	]
	let reporte = data.map((item)=>{
		let retorno = {}
		columnas.forEach((col)=>{
			retorno[col] = item[col]
		})
		return retorno
	})

	return reporte.map((item)=>{
		for(let i in item){
			if(!item[i]){
				item[i] = 0
			}

			if(i == 'moneda_id'){
				if(item[i] == 1){
					item[i] = 'Soles'
				} else {
					item[i] = 'Dólares'
				}
			}

			if(i == 'tipo_pagador_id'){
				if(item[i] == 1){
					item[i] = 'Cliente'
				} else {
					item[i] = 'Aceptante'
				}
			}

			if(i == 'docs'){
				if(item[i].length > 0){
					let items = item[i]
					item[i] = '';
					items.forEach(element => {
						item[i]+=`${element} `
					});
				} else {
					item[i] = '';
				}
			}
		}

		return item
	})
  }

  async openExcelModal() {
    const result = await Swal.fire({
      title: 'Generar Excel',
      text: "Elige si deseas generar el excel de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    console.log(result)
    if (result.isDenied) {
      this.generarExcel(this.rowData)
    } else if(result.isConfirmed) {
      this.generarExcelCompleto()
    }
  }

}
