import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FacturacionRoutingModule } from './facturacion-routing.module';
import { FacturacionComponent } from './facturacion/facturacion.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'app/shared/shared.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UiSwitchModule } from 'ngx-ui-switch';
import { FacturasPendientesComponent } from './partials/facturas-pendientes/facturas-pendientes.component';
import { FacturasFacturadoComponent } from './partials/facturas-facturado/facturas-facturado.component';
import { FacturaModalComponent } from './modals/factura-modal/factura-modal.component';
import { FacturaDetalleModalComponent } from './partials/modals/factura-detalle-modal/factura-detalle-modal.component';
import { FacturadoModalComponent } from './modals/facturado-modal/facturado-modal.component';
import { NotaCreditoModalComponent } from './modals/nota-credito-modal/nota-credito-modal.component';
import { FacturasComprobantesComponent } from './partials/facturas-comprobantes/facturas-comprobantes.component';
import { CierrePeriodoComponent } from './cierre-periodo/cierre-periodo.component';
import { ContableComponent } from './cierre-periodo/contable/contable.component';
import { OperativoComponent } from './cierre-periodo/operativo/operativo.component';
import { ModalsComponent } from './cierre-periodo/modals/modals.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [FacturacionComponent, FacturasPendientesComponent, FacturasFacturadoComponent, FacturaModalComponent, FacturaDetalleModalComponent, FacturadoModalComponent, NotaCreditoModalComponent, FacturasComprobantesComponent, CierrePeriodoComponent, ContableComponent, OperativoComponent, ModalsComponent],
  exports: [ CierrePeriodoComponent ],
  imports: [
    CommonModule,
    FacturacionRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    NgbModule,
    NgxDatatableModule,
    NgSelectModule,
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    NgxMaskModule.forRoot(maskConfig),
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    FontAwesomeModule,
    TooltipModule
  ]
})
export class FacturacionModule { }
