import { Component, OnInit } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
import { BaseLavadoActivosComponent } from '../../base-lavado-activos-component';

@Component({
  selector: 'app-periodos',
  templateUrl: './periodos.component.html',
  styleUrls: ['./periodos.component.css']
})
export class PeriodosComponent extends BaseLavadoActivosComponent implements OnInit {

  public requestService = (filters: Record<string, string>) => this.lavadoActivosService.obtenerPeriodos(filters);
  
  constructor(
    public modalService: NgbModal,
    public authService: AuthService,
    private lavadoActivosService: LavadoActivosService,
  ) {
    super(authService, modalService)
  }
  initVars() {
    let columns = [  
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fecha del Periodo",
        field: "fecha",
        sortable: true,
        filterable: true,
        filterProp: "fecha",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Estado",
        field: "estado",
      },
      {
        class: "text-center",
        headerName: "Cantidad de evaluaciones",
        field: "cantidad_plantillas",
      },
      {
        class: "text-center",
        headerName: "Cantidad de evaluaciones por Evaluar",
        field: "cantidad_plantillas_por_evaluar",
      },
      {
        class: "text-center",
        headerName: "Cantidad de evaluaciones con Riesgo Alto",
        field: "evaluaciones_con_riesgo_alto",
      },
      {
        class: "text-center",
        headerName: "Cantidad de evaluaciones con Riesgo Medio",
        field: "evaluaciones_con_riesgo_medio",
      },
      {
        class: "text-center",
        headerName: "Cantidad de evaluaciones con Riesgo Bajo",
        field: "evaluaciones_con_riesgo_bajo",
      },
      {
        class: "text-center",
        headerName: "Nivel de Riesgo",
        field: "nivel_riesgo",
      },
      {
        class: "text-center",
        headerName: "Promedio de Riesgo",
        field: "promedio_riesgo",
      },
      
      {
        class: "text-center",
        headerName: "Fecha de creación",
        field: "fecha_creacion",
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {
    this.filters = filtros
    this.filters['page'] = page
    this.filters['page_size'] = per_page
  
    this.executeService();
    
  }
}
