import {
  faMoneyBillWave,
  faFileInvoice,
  faUserEdit,
  faCalendarAlt,
  faCog,
  faHandHoldingUsd,
  faClock,
  faTools,
  faCashRegister,
  faUsers,
  faFileMedical,
  faUsersCog,
  faClipboardCheck,
  faUserTie,
  faUserTag,
  faFunnelDollar,
  faFile,
  faChartLine,
  faFileInvoiceDollar,
  faCreditCard,
  faBullhorn,
  faFolder,
  faGavel,
  faReceipt,
  faUserFriends,
  faFileExcel,
  faCogs,
  faCoins,
  faFolderOpen,
  faMedal,
  faSignature,
  faClipboardList,
  faUniversity,
  faChartBar,
  faSubscript,
  faAddressCard,
  faPen,
  faUserLock,
  faBuilding,
  faFileArchive,
  faFistRaised,
  faStopwatch,
  faMask,
  faLock,
  faPaperPlane,
  faCalendar,
  faCalendarDay,
  faExclamationCircle,
  faBell,
  faShapes,
  faWrench,
  faMapMarked,
  faTable,
  faExclamationTriangle,
  faCalendarCheck
} from '@fortawesome/free-solid-svg-icons';
import { Perfil } from "./Perfil";
const perfil = new Perfil();
let lavado_activos_permisos = [
  perfil.perfilAdmin,
  perfil.perfilLiderDeTesoreriaID,
  perfil.perfilGerenciaGeneralID,
  perfil.perfilGerenciaComercialID,
]

export const _ROUTES = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    collapse: "Dashboard",
    icontype: "nc-icon nc-money-coins",
    fontAwesomeIcon: faChartLine,
    accessAllowed: [
      perfil.perfilAdmin,
      perfil.perfilOficialDeNegocioID,
      perfil.perfilLiderDeEquipo,
      perfil.perfilGerenciaComercialID,
      perfil.perfilGerenciaGeneralID,
      perfil.perfilAnalistaRiesgosID,
      perfil.perfilJefeDeAdmisionID,
      perfil.perfilAsesorLegalID,
      perfil.perfilLegalID,
      perfil.perfilJefeDeOperacionesID,
      perfil.perfilAnalistaOperacionesID,
      perfil.perfilSubGerenciaComercialID,
      perfil.perfilTesoreriaID,
      perfil.perfilLiderDeTesoreriaID,
      perfil.perfilRecepcionID,
      perfil.perfilClienteID,
      perfil.perfilAnalistaOperacionesID,
      perfil.perfilAsistenteCobranzasID,
      perfil.perfilConsultaID,
      perfil.perfilAsistenteComercialID
    ],
  },
  {
    path: "",
    title: "Operaciones",
    type: "sub",
    collapse: "operaciones",
    icontype: "nc-icon nc-money-coins",
    fontAwesomeIcon: faFileInvoiceDollar,
    accessAllowed: [
      perfil.perfilAdmin,
      perfil.perfilOficialDeNegocioID,
      perfil.perfilLiderDeEquipo,
      perfil.perfilGerenciaComercialID,
      perfil.perfilGerenciaGeneralID,
      perfil.perfilAnalistaRiesgosID,
      perfil.perfilJefeDeAdmisionID,
      perfil.perfilAsesorLegalID,
      perfil.perfilLegalID,
      perfil.perfilJefeDeOperacionesID,
      perfil.perfilAnalistaOperacionesID,
      perfil.perfilSubGerenciaComercialID,
      perfil.perfilTesoreriaID,
      perfil.perfilLiderDeTesoreriaID,
      perfil.perfilRecepcionID,
      perfil.perfilClienteID,
      perfil.perfilAnalistaOperacionesID,
      perfil.perfilAsistenteCobranzasID,
      perfil.perfilConsultaID,
      perfil.perfilAsistenteComercialID
    ],
    children: [
      {
        path: "/operaciones",
        title: "En curso",
        type: "link",
        isComite: false,
        fontAwesomeIcon: faCashRegister,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilClienteID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/operaciones-comite",
        title: "Comité",
        type: "link",
        isComite: true,
        fontAwesomeIcon: faUsers,
        accessAllowed: ["comite"],
      },
      {
        path: "/verifications",
        title: "Verificación",
        isComite: false,
        type: "link",
        fontAwesomeIcon: faClipboardCheck,
        accessAllowed: [
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/denegados",
        title: "Denegadas",
        type: "link",
        isComite: false,
        collapse: "components",
        icontype: "nc-icon nc-money-coins",
        fontAwesomeIcon: faFileExcel,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
    ]
  },
  {
    path: "",
    title: "CAVALI",
    type: "sub",
    collapse: "cavali",
    icontype: "nc-icon nc-money-coins",
    fontAwesomeIcon: faReceipt,
    accessAllowed: [
      perfil.perfilJefeDeOperacionesID,
      perfil.perfilAnalistaOperacionesID,
      perfil.perfilAsistenteCobranzasID,
      perfil.perfilGerenciaComercialID,
      perfil.perfilGerenciaGeneralID,
      perfil.perfilOficialDeNegocioID,
      perfil.perfilLiderDeEquipo,
      perfil.perfilAdmin,
      perfil.perfilAnalistaRiesgosID,
      perfil.perfilJefeDeAdmisionID,
      perfil.perfilAsesorLegalID,
      perfil.perfilLegalID,
      perfil.perfilSubGerenciaComercialID,
      perfil.perfilTesoreriaID,
      perfil.perfilRecepcionID,
      perfil.perfilConsultaID,
      perfil.perfilAsistenteComercialID
    ],
    children: [
      {
        path: "/cavali-procesos",
        title: "Procesos",
        type: "link",
        isComite: true,
        fontAwesomeIcon: faFileInvoice,
        accessAllowed: [
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilAdmin,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/cavali-resultados",
        title: "Resultados",
        type: "link",
        isComite: true,
        fontAwesomeIcon: faFileInvoice,
        accessAllowed: [
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilAdmin,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
    ]
  },
  {
    path: "",
    title: "Cobranzas",
    type: "sub",
    collapse: "cobranzas",
    icontype: "nc-icon nc-money-coins",
    fontAwesomeIcon: faCoins,
    accessAllowed: [
      perfil.perfilJefeDeOperacionesID,
      perfil.perfilAnalistaOperacionesID,
      perfil.perfilAsistenteCobranzasID,
      perfil.perfilGerenciaComercialID,
      perfil.perfilGerenciaGeneralID,
      perfil.perfilOficialDeNegocioID,
      perfil.perfilLiderDeEquipo,
      perfil.perfilAdmin,
      perfil.perfilAnalistaRiesgosID,
      perfil.perfilJefeDeAdmisionID,
      perfil.perfilAsesorLegalID,
      perfil.perfilLegalID,
      perfil.perfilSubGerenciaComercialID,
      perfil.perfilTesoreriaID,
      perfil.perfilLiderDeTesoreriaID,
      perfil.perfilRecepcionID,
      perfil.perfilConsultaID,
      perfil.perfilAsistenteComercialID
    ],
    children: [
      {
        path: "/abonos",
        title: "Recaudaciones",
        type: "link",
        isComite: false,
        fontAwesomeIcon: faCreditCard,
        icontype: "nc-icon nc-credit-card",
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID,
        ],
      },
      {
        path: "/recaudacion",
        title: "Aplicaciones",
        isComite: false,
        type: "link",
        fontAwesomeIcon: faFunnelDollar,
        icontype: "nc-icon nc-bank",
        accessAllowed: [
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID,
        ],
      },
      {
        path: "/cuentas-x-cobrar",
        title: "Cuentas por cobrar",
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-paper",
        fontAwesomeIcon: faFileInvoiceDollar,
        accessAllowed: [
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/excedentes",
        title: "Excedentes",
        isComite: true,
        type: "link",
        icontype: "nc-icon nc-chart-pie-36",
        fontAwesomeIcon: faHandHoldingUsd,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/documentos_cartera",
        title: "Documentos Cartera",
        isComite: true,
        fontAwesomeIcon: faFolder,
        type: "link",
        icontype: "nc-icon nc-briefcase-24",
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/judicial",
        title: "Judicial",
        isComite: true,
        type: "link",
        icontype: "nc-icon nc-single-copy-04",
        fontAwesomeIcon: faGavel,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID,
        ],
      },
      {
        path: "/expedientes-judiciales",
        title: "Expedientes Judiciales",
        isComite: true,
        type: "link",
        icontype: "nc-icon nc-single-copy-04",
        fontAwesomeIcon: faGavel,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/prorrogas",
        title: "Prórrogas",
        isComite: true,
        type: "link",
        fontAwesomeIcon: faBullhorn,
        icontype: "nc-icon nc-calendar-60",
        accessAllowed: [
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/devoluciones",
        title: "Solicitudes de devolución",
        isComite: true,
        type: "link",
        icontype: "nc-icon nc-credit-card",
        fontAwesomeIcon: faReceipt,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/facturacion",
        title: "Facturación",
        isComite: true,
        type: "link",
        icontype: "nc-icon nc-credit-card",
        fontAwesomeIcon: faFileArchive,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/gestion_cobranzas",
        title: "Gestión de Cobranzas",
        isComite: true,
        type: "link",
        icontype: "nc-icon nc-credit-card",
        fontAwesomeIcon: faStopwatch,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilJefeDeOperacionesID,
        ],
      },
    ]
  },
  {
    path: "",
    title: "Tesorería",
    type: "sub",
    collapse: "tesoreria",
    icontype: "nc-icon nc-money-coins",
    fontAwesomeIcon: faFileInvoiceDollar,
    accessAllowed: [
      perfil.perfilAdmin,
      perfil.perfilTesoreriaID,
      perfil.perfilLiderDeTesoreriaID,
      perfil.perfilJefeDeOperacionesID,
      perfil.perfilConsultaID,
    ],
    children: [
      {
        path: "/ordenes-pagos",
        title: "Órdenes de Pagos",
        fontAwesomeIcon: faCreditCard,
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-credit-card",
        accessAllowed: [
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilConsultaID,
        ],
      },
    ]
  },
  {
    path: "",
    title: "Configuración",
    type: "sub",
    collapse: "configuracion",
    icontype: "nc-icon nc-money-coins",
    fontAwesomeIcon: faCogs,
    accessAllowed: [
      perfil.perfilGerenciaComercialID,
      perfil.perfilGerenciaGeneralID,
      perfil.perfilJefeDeAdmisionID,
      perfil.perfilJefeDeOperacionesID,
      perfil.perfilAnalistaOperacionesID,
      perfil.perfilAnalistaRiesgosID,
      perfil.perfilAsesorLegalID,
      perfil.perfilTesoreriaID,
      perfil.perfilLiderDeTesoreriaID,
      perfil.perfilAsistenteCobranzasID
    ],
    children: [
      {
        path: "/configuracion/tipos-documentos",
        title: "Tipos Documentos",
        type: "link",
        ab: '',
        fontAwesomeIcon: faFolderOpen,
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      {
        path: "/configuracion/documents",
        title: "Documentos",
        type: "link",
        ab: '',
        fontAwesomeIcon: faFolderOpen,
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      {
        path: "/configuracion/cierre-periodo",
        title: "Cierre de periodo",
        type: "link",
        ab: '',
        fontAwesomeIcon: faLock,
        accessAllowed: [
          perfil.perfilGerenciaGeneralID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLiderDeTesoreriaID,
        ]
      },
      {
        path: "/configuracion/tipo-evaluacion",
        title: "Tipo de Evaluaci├│n",
        type: "link",
        ab: '',
        fontAwesomeIcon: faMedal,
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      {
        path: "/configuracion/tipos-firmas",
        title: "Tipos de Firma contratos",
        type: "link",
        ab: '',
        fontAwesomeIcon: faSignature,
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      {
        path: "/configuracion/condiciones",
        title: "Condiciones",
        type: "link",
        fontAwesomeIcon: faClipboardList,
        ab: '',
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      {
        path: "/configuracion/formulas",
        title: "Fórmulas",
        type: "link",
        fontAwesomeIcon: faSubscript,
        ab: '',
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      {
        path: "/configuracion/bancos",
        title: "Bancos",
        type: "link",
        fontAwesomeIcon: faUniversity,
        ab: '',
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      {
        path: "/configuracion/cuentas",
        title: "Cuentas",
        type: "link",
        ab: '',
        fontAwesomeIcon: faChartBar,
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      {
        path: "/doc-checklist",
        title: "Checklist de Documentos",
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-single-02",
        fontAwesomeIcon: faFileMedical,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/grupos-economicos",
        title: "Grupos Económicos",
        isComite: true,
        type: "link",
        icontype: "nc-icon nc-chart-pie-36",
        fontAwesomeIcon: faUserFriends,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilAsistenteComercialID
        ],
      },
      
      {
        path: "/configuracion/cierre-periodo",
        title: "Cierre de periodo",
        type: "link",
        ab: '',
        fontAwesomeIcon: faLock,
        accessAllowed: [
          perfil.perfilGerenciaGeneralID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLiderDeTesoreriaID,
        ]
      },
      {
        path: "/configuracion/config-comite",
        title: "Comités",
        type: "link",
        ab: '',
        fontAwesomeIcon: faUsers,
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAdmin,

        ]
      },
      {
        path: "/configuracion/ciiu",
        title: "Código CIIU",
        type: "link",
        ab: '',
        fontAwesomeIcon: faAddressCard,
        accessAllowed: [
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      {
        path: "/configuracion/parametros",
        title: "Parámetros",
        type: "link",
        ab: '',
        fontAwesomeIcon: faPen,
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAdmin,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      // {
      //   path: "/configuracion/parametros-contrasena",
      //   title: "Parámetros Contraseña",
      //   type: "link",
      //   ab: '',
      //   fontAwesomeIcon: faUserLock,
      //   accessAllowed: [
      //     perfil.perfilGerenciaComercialID,
      //     perfil.perfilGerenciaGeneralID,
      //     perfil.perfilAdmin
      //   ]
      // },
      {
        path: "/configuracion/facturacion-series",
        title: "Facturación Series",
        type: "link",
        ab: '',
        fontAwesomeIcon: faBuilding,
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAdmin
        ]
      },
      {
        path: "/configuracion/lideres",
        title: "Líderes de Equipo",
        type: "link",
        ab: '',
        fontAwesomeIcon: faFistRaised,
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAdmin
        ]
      },
      {
        path: "/configuracion/tipos-nc",
        title: "Tipos de NC",
        type: "link",
        ab: '',
        fontAwesomeIcon: faFileArchive,
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAdmin,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      {
        path: "/configuracion/asignacion_curso_normal",
        title: "Asignacion Curso Normal",
        type: "link",
        ab: '',
        fontAwesomeIcon: faFolderOpen,
        accessAllowed: [
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilAsistenteCobranzasID
        ]
      },
      {
        path: "/configuracion/feriados",
        title: "Feriados",
        type: "link",
        ab: '',
        fontAwesomeIcon: faCalendar,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilJefeDeOperacionesID,
        ]
      },
    ]
  },
  // {
  //   path: "/users",
  //   title: "Gestión de usuarios",
  //   type: "link",
  //   icontype: "nc-icon nc-circle-10",
  //   fontAwesomeIcon: faUserEdit,
  //   isComite: false,
  //   accessAllowed: [perfil.perfilAdmin],
  // },
  {
    path: "/clientes",
    title: "Clientes",
    isComite: false,
    type: "link",
    icontype: "nc-icon nc-single-02",
    fontAwesomeIcon: faUserTie,
    accessAllowed: [
      perfil.perfilGerenciaComercialID,
      perfil.perfilGerenciaGeneralID,
      perfil.perfilOficialDeNegocioID,
      perfil.perfilLiderDeEquipo,
      perfil.perfilLegalID,
      perfil.perfilJefeDeOperacionesID,
      perfil.perfilAnalistaRiesgosID,
      perfil.perfilAnalistaOperacionesID,
      perfil.perfilAsistenteCobranzasID,
      perfil.perfilAsesorLegalID,
      perfil.perfilJefeDeAdmisionID,
      perfil.perfilConsultaID,
      perfil.perfilAsistenteComercialID
    ],
  },
  {
    path: "/clientes-asignacion-cartera",
    title: "Asignación de cartera",
    isComite: false,
    type: "link",
    icontype: "nc-icon nc-single-02",
    fontAwesomeIcon: faUserTie,
    accessAllowed: [
      perfil.perfilAdmin,
      perfil.perfilGerenciaComercialID,
    ],
  },
  {
    path: "/aceptantes",
    title: "Aceptantes",
    isComite: false,
    fontAwesomeIcon: faUserTag,
    type: "link",
    icontype: "nc-icon nc-bag-16",
    accessAllowed: [
      perfil.perfilAdmin,
      perfil.perfilOficialDeNegocioID,
      perfil.perfilLiderDeEquipo,
      perfil.perfilGerenciaComercialID,
      perfil.perfilGerenciaGeneralID,
      perfil.perfilJefeDeAdmisionID,
      perfil.perfilAnalistaRiesgosID,
      perfil.perfilLegalID,
      perfil.perfilAsesorLegalID,
      perfil.perfilJefeDeOperacionesID,
      perfil.perfilAnalistaOperacionesID,
      perfil.perfilSubGerenciaComercialID,
      perfil.perfilTesoreriaID,
      perfil.perfilLiderDeTesoreriaID,
      perfil.perfilRecepcionID,
      perfil.perfilAsistenteCobranzasID,
      perfil.perfilConsultaID,
      perfil.perfilAsistenteComercialID
    ],
  },
  {
    path: "/aceptantes-sin-riesgos",
    title: "Aceptantes curso normal",
    isComite: false,
    fontAwesomeIcon: faUserTag,
    type: "link",
    icontype: "nc-icon nc-bag-16",
    accessAllowed: [
      perfil.perfilAdmin,
      perfil.perfilJefeDeAdmisionID,
    ],
  },
  {
    path: "/notificaciones",
    title: "Notificaciones",
    isComite: false,
    fontAwesomeIcon: faPaperPlane,
    type: "link",
    icontype: "nc-icon nc-paper",
    accessAllowed: [
      perfil.perfilAdmin,
      perfil.perfilJefeDeOperacionesID,
      perfil.perfilAnalistaOperacionesID,
      perfil.perfilGerenciaComercialID,
      perfil.perfilLiderDeEquipo,
    ],
  },
  {
    path: "",
    title: "Reportes",
    isComite: false,
    type: "sub",
    collapse: "reportes",
    icontype: "nc-icon nc-paper",
    fontAwesomeIcon: faFile,
    accessAllowed: [
      perfil.perfilAdmin,
      perfil.perfilOficialDeNegocioID,
      perfil.perfilLiderDeEquipo,
      perfil.perfilGerenciaComercialID,
      perfil.perfilGerenciaGeneralID,
      perfil.perfilAnalistaRiesgosID,
      perfil.perfilJefeDeAdmisionID,
      perfil.perfilAsesorLegalID,
      perfil.perfilLegalID,
      perfil.perfilJefeDeOperacionesID,
      perfil.perfilAnalistaOperacionesID,
      perfil.perfilSubGerenciaComercialID,
      perfil.perfilTesoreriaID,
      perfil.perfilRecepcionID,
      perfil.perfilAsistenteCobranzasID,
      perfil.perfilConsultaID,
      perfil.perfilAsistenteComercialID,
      perfil.perfilLiderDeTesoreriaID
    ],
    children: [
      {
        path: "/reportes/total-report",
        title: "Total Report",
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-paper",
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/reportes/cartera",
        title: "Reporte Cartera",
        type: "link",
        ab: '',
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/reportes/cliente",
        title: "Reportes personas",
        type: "link",
        ab: '',
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/reportes/sabana",
        title: "Reporte documentos",
        type: "link",
        ab: '',
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID,
          perfil.perfilLiderDeTesoreriaID
        ],
      },
      {
        path: "/reporte/cambioLineaXCliente",
        title: "Reporte Cambios de Línea",
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-paper",
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ]
      },
      {
        path: "/reportes/cxc",
        title: "Reporte CXC",
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-paper",
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/reportes/verificacion",
        title: "Reporte de Verificación",
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-paper",
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilLiderDeEquipo,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/reportes/apl-excedentes",
        title: "Reporte Aplicaciones Exc.",
        title1: "Reporte Aplicaciones de Excedentes",
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-paper",
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/reporte/excedentes",
        title: "Reporte Exc. por Devolver",
        title1: "Reporte Excedentes por Devolver",
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-paper",
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },   
      {
        path:"/reporte/excedentes-devueltos",
        title: "Reporte Excedentes devueltos",
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-paper",
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/reporte/cuadroConsolidado",
        title: "Reporte Consolidado Exc",
        title1: "Reporte Consolidado Excedentes",
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-paper",
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      },
      {
        path: "/reporte/creditosFactoring",
        title: "Reporte Créditos Factoring",
        title1: "Reporte Créditos Factoring",
        isComite: false,
        type: "link",
        icontype: "nc-icon nc-paper",
        fontAwesomeIcon: faFile,
        accessAllowed: [
          perfil.perfilAdmin,
          perfil.perfilOficialDeNegocioID,
          perfil.perfilGerenciaComercialID,
          perfil.perfilGerenciaGeneralID,
          perfil.perfilAnalistaRiesgosID,
          perfil.perfilJefeDeAdmisionID,
          perfil.perfilAsesorLegalID,
          perfil.perfilLegalID,
          perfil.perfilJefeDeOperacionesID,
          perfil.perfilAnalistaOperacionesID,
          perfil.perfilSubGerenciaComercialID,
          perfil.perfilTesoreriaID,
          perfil.perfilLiderDeTesoreriaID,
          perfil.perfilRecepcionID,
          perfil.perfilAsistenteCobranzasID,
          perfil.perfilConsultaID,
          perfil.perfilAsistenteComercialID
        ],
      }, 
    ]
  },
  {
    path: "",
    title: "Lavado de Activos",
    isComite: false,
    type: "sub",
    collapse: "lavado_de_activos",
    icontype: "nc-icon nc-paper",
    fontAwesomeIcon: faMoneyBillWave,
    accessAllowed: lavado_activos_permisos,
    children: [
      {
        path: "/lavado_activos/plantillas",
        title: "Configuración de Plantillas",
        type: "link",
        ab: '',
        fontAwesomeIcon: faWrench,
        accessAllowed: lavado_activos_permisos,
      }, 
      {
        path: "/lavado_activos/variables_riesgo",
        title: "Variables de Riesgo",
        type: "link",
        ab: '',
        fontAwesomeIcon: faShapes,
        accessAllowed: lavado_activos_permisos,
      },
      {
        path: "/lavado_activos/zonas_riesgo",
        title: "Zonas de Riesgo",
        type: "link",
        ab: '',
        fontAwesomeIcon: faMapMarked,
        accessAllowed: lavado_activos_permisos,
      },
      {
        path: "/lavado_activos/matrices_riesgo",
        title: "Matrices de Riesgo",
        type: "link",
        ab: '',
        fontAwesomeIcon: faTable,
        accessAllowed: lavado_activos_permisos,
      },
      {
        path: "/lavado_activos/reportes",
        title: "Señales de Alerta",
        type: "link",
        ab: '',
        fontAwesomeIcon: faExclamationTriangle,
        accessAllowed: lavado_activos_permisos,
      }, 
      {
        path: "/lavado_activos/periodos",
        title: "Periodos",
        type: "link",
        ab: '',
        fontAwesomeIcon: faCalendarCheck,
        accessAllowed: lavado_activos_permisos,
      }, 
      {
        path: "/lavado_activos/calificaciones",
        title: "Calificación",
        type: "link",
        ab: '',
        fontAwesomeIcon: faBell,
        accessAllowed: lavado_activos_permisos,
      },   
    ]
  },
];
