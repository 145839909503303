import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { AdminLayoutComponent } from 'app/layouts/admin/admin-layout.component';
import { ImprimirCartaModificacionComponent } from './pages/imprimir-carta-modificacion/imprimir-carta-modificacion.component';


const routes: Routes = [
  {
    path: 'formatos/imprimir/carta-modificacion',
    canActivate: [AuthGuard],
    component: ImprimirCartaModificacionComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FormatosRoutingModule { }
