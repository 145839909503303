import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faCreditCard } from '@fortawesome/free-solid-svg-icons';
import { MonedaPipe } from 'app/core/pipes/moneda.pipe';
import { SharedFunctions } from 'app/core/shared/functions';

@Component({
	selector: 'app-vencimiento-lineas',
	templateUrl: './vencimiento-lineas.component.html',
	styleUrls: ['./vencimiento-lineas.component.css'],
	providers: [MonedaPipe]
})
export class VencimientoLineasComponent implements OnInit {
	faCreditCard = faCreditCard;
	@Output() pageUpdate: EventEmitter<any> = new EventEmitter<any>();

	_lineasVencidas: any[];
	tipo: number = 1;
	_tipoSwitch = true;
	itemsData: any[];
	columnDefs: any[];
	rowData: any[] = [];
	pageTableActual: any;
	pageTableTotal: any;
	pageTablePerPages: any = 10;
	pageTablePages: any[];
	@Output() emiter: EventEmitter<any> = new EventEmitter<any>();
	@Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();

	@Input() set tipoSwitch(value) {
		this._tipoSwitch = value
		if (this._tipoSwitch) {
			this.tipo = 1
		} else {
			this.tipo = 2
		}
	}

	get tipoSwitch() {
		return this._tipoSwitch
	}

	@Input() set lineasVencidas(value: any) {
		if (value) {
			let { results } = value

			value.results = results.map((item) => {
				item.fecha_vencimiento = this.sharedFunctions.formaStringDate(item.fecha_vencimiento)
				return item
			})
			this.loadTable(value)
		}
	}
	get lineasVencidas() {
		return this._lineasVencidas
	}

	@Input() title: string;

	constructor(
		public sharedFunctions: SharedFunctions,
		public monedapipe: MonedaPipe,
	) { }

	ngOnInit(): void {

		this.columnDefs = [
			{
				headerName: 'Cliente',
				field: 'beneficiario_nombre',
				sortable: true,
			},
			{
				headerName: 'Vencimiento',
				field: 'fecha_vencimiento',
				class: 'text-center',
				filterProp: 'fecha_vencimiento',
				filterInput: true,
			},
			{
				headerName: 'Tipo Linea ',
				class: 'text-center',
				filterProp: 'tipo_linea',
				filterInput: true,
				pipe: 'function',
				function: function ({ moneda, tipo_linea }, header) {
					return tipo_linea
				},
			},
			{
				headerName: 'Linea Actual ',
				class: 'text-center',
				filterProp: 'tipo_linea',
				filterInput: true,
				pipe: 'function',
				function: function ({ moneda, linea_actual }, header) {
					return linea_actual
				},
			}
		];
	}

	rowClick(row) {
		console.log(row)
	}

	loadTable(data) {
		this.rowData = data.results.map((item) => {
			item.linea_actual = this.monedapipe.transform(item.linea_actual, item.moneda)
			return item
		});
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

	goToPage({ page, per_page, filtros }) {
		filtros.tipo_vencimiento = this.tipo
		this.pageUpdate.emit({ page, per_page, filtros })
	}

	switchChange(value: number) {
		let tipo;
		if (value) {
			tipo = 1
		} else {
			tipo = 2
		}
		this.pageTableActual = 1
		this.pageUpdate.emit({
			page: this.pageTableActual,
			per_page: this.pageTablePerPages,
			filtros: { tipo_vencimiento: tipo }
		})
		// this.emiter.emit(value ? 1 : 2)
	}

	update() {
		this.pageUpdate.emit({
			page: this.pageTableActual,
			per_page: this.pageTablePerPages,
			filtros: { tipo_vencimiento: this.tipoSwitch }
		})
	}

}
