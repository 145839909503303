import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { FacturacionService } from 'app/core/services/facturacion/facturacion.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { FacturaDetalleModalComponent } from '../../partials/modals/factura-detalle-modal/factura-detalle-modal.component';

@Component({
  selector: 'app-factura-modal',
  templateUrl: './factura-modal.component.html',
  styleUrls: ['./factura-modal.component.css']
})
export class FacturaModalComponent implements OnInit {
  faTimes = faTimes
  myForm: FormGroup;
  estados: any[] = [];
  tiposPagador: any[] = [];
  tipos: any[] = [];
  monedas: any[] = [];
  @Input() facturaIn: any;
  public user: any;
  facturas: any[] = [];
  readonly = true;

  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public identidad: any = {};
  public searchstring: string = '';
  public canCreate: boolean = false;

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    public formbuild: FormBuilder,
    private cuentaService: CuentasService,
    public configs: DocschecklistService,
    private facturacionService: FacturacionService,
    private authService: AuthService,
  ) {
    this.initForm();
    this.user = this.authService.user;
  }

  ngOnInit(): void {

    this.initData();

  }

  initData() {
    let estados = this.cuentaService.obtenerEstados();
    let tiposPagador = this.cuentaService.obtenerTiposPagador();
    let monedas = this.configs.obtenerMonedas();
    let tipos = this.facturacionService.obtenerTipos();
    let facturas = this.facturacionService.obtenerFacturasCompacto('', '', '1', '2', '', 1, 1000,
      this.facturaIn.tipo_pagador == 2 ? this.facturaIn.pagador : '',
      this.facturaIn.tipo_pagador == 1 ? this.facturaIn.pagador : ''
    );

    Promise.all([estados, tiposPagador, monedas, tipos, facturas]).then((res) => {
      this.tiposPagador = res[0]['results'];
      this.estados = res[1]['results'];
      this.monedas = res[2]['results'];
      this.tipos = res[3]['results'];
      this.facturas = res[4]['results'];
      this.setForm(this.facturaIn);
      if (this.facturaIn.estado != 1) this.myForm.disable();
      this.obtenerDetalles();
    }, err => {
      console.error(err);
    });
  }

  initForm() {
    this.myForm = this.formbuild.group({
      tipo_pagador_descripcion: [null, [Validators.required]],
      pagador_nombre: [null, [Validators.required]],
      pagador_ruc: [null, [Validators.required]],
      concepto: [null, [Validators.required]],
      fecha_facturado: [null, [Validators.required]],
      fecha_pago: [null, [Validators.required]],
      moneda: [null, [Validators.required]],
      monto: [null, [Validators.required]],
      igv: [null, [Validators.required]],
      total: [null, [Validators.required]],
      tipo_descripcion: [null, [Validators.required]],
      tipo: [null, [Validators.required]],
      referencia: [null, []]
    });
  }

  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        switch (key) {
          case 'fecha_facturado':
            this.myForm.controls[key].setValue(this.parseDate(item[key]));
            continue;
            break;
          case 'fecha_pago':
            this.myForm.controls[key].setValue(this.parseDate(item[key]));
            continue;
            break;
        }
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  parseDate(date) {
    if (!date)
      return null;

    let dateNow = new Date();
    let yy = date.split('-')[0];
    let mm = (date.split('-')[1] - 1);
    // let dd = date.split('-')[2];
    let dd = (date.split('-')[2]).substring(0, 2);

    let d = new Date(yy, mm, dd, dateNow.getHours());

    return d;
  }

  obtenerDetalles() {
    this.facturacionService.obtenerFacturasDetalles(this.facturaIn.id, '', 1, 1000).then(resp => {
      console.log(resp);
      this.initVars();
      this.loadTable(resp);
    }, err => {
      console.error(err);

    });
  }

  loadTable(data) {

    this.rowData = data.results
    this.pageTableActual = data.page_number
    this.pageTableTotal = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }

  initVars() {
    let columns = [
      // {
      //   headerName: 'N°', 
      //   field     : 'id' ,
      //   sortable  : true,
      // },
      {
        headerName: 'Nro CxC',
        field: 'cuenta_cobrar',
        class: 'text-center',
        sortable: true,
      },
      {
        headerName: 'Monto',
        field: 'monto',
        class: 'text-center',
        pipe: 'currency_detail',
        moneda: 'moneda',
        sortable: true,
        moneda_detail: this.myForm.controls.moneda.value,
      },
      {
        headerName: 'IGV',
        field: 'igv',
        class: 'text-center',
        pipe: 'currency_detail',
        moneda: 'moneda',
        sortable: true,
        moneda_detail: this.myForm.controls.moneda.value,
      },
      {
        headerName: 'Total',
        field: 'total',
        class: 'text-center',
        pipe: 'currency_detail',
        moneda: 'moneda',
        sortable: true,
        moneda_detail: this.myForm.controls.moneda.value,
      },
      {
        headerName: 'Glosa',
        field: 'glosa',
        class: 'text-center',
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }

  editDetalle(row) {
    const modalRef = this.modalService.open(FacturaDetalleModalComponent, {

    });

    modalRef.componentInstance.glosa = row;

    modalRef.componentInstance.successEmit.subscribe(result => {
      console.log(result);
      this.obtenerDetalles();
    },
      error => {

      });

    modalRef.result.then((result) => {
      console.log(result);
    }, (reason) => {
      console.log(reason);
    });
  }

  get showReferencia() {
    return this.myForm.controls['tipo'].value == 2 || this.myForm.controls['tipo'].value == 3;
  }

  onSubmit() {
    if (this.showReferencia && !this.myForm.controls['referencia'].value) {
      return;
    }
    let fecha_pago: Date | string = new Date(this.myForm.controls['fecha_pago'].value)
    fecha_pago = fecha_pago.toISOString().slice(0, 10)
    let data = {
      tipo: this.myForm.controls['tipo'].value,
      observacion: '',
      referencia: this.myForm.controls['referencia'].value,
      fecha_pago: fecha_pago,
    }
    this.facturacionService.mofificarFactura(this.facturaIn.id, data).then(res => {
      this.activeModal.close(true);
    }, err => {
      console.error(err);

    });
  }

  get canAdd() {
		const perfilesPermitidos = [
		  this.authService.perfil.perfilAdmin,
		  this.authService.perfil.perfilJefeDeOperacionesID,
		//   this.authService.perfil.perfilAnalistaOperacionesID,
		  this.authService.perfil.perfilAsistenteCobranzasID,
		//   this.authService.perfil.perfilAnalistaOperacionesID,
		  this.authService.perfil.perfilGerenciaComercialID,
		  this.authService.perfil.perfilGerenciaGeneralID,
		]
	
		return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
	}

}
