import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from 'app/layouts/admin/admin-layout.component';
import { ExcedentesComponent } from './page/excedentes/excedentes.component';
import { DevolucionesComponent } from './pages/devoluciones/devoluciones.component';


const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Solicitudes de devolución'
    },
    children: [
      {
        path: 'devoluciones',
        component: DevolucionesComponent,
        data: {
          breadcrumb: 'Solicitudes de devolución'
        }
      },
      {
        path: 'excedentes',
        component: ExcedentesComponent,
        data: {
          breadcrumb: 'Excedentes'
        }
      }
    ]
  }
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExcedentesRoutingModule { }
