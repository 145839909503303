import {
  Component,
  OnInit,
  ViewChild,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { AuthService } from "app/core/services/auth/auth.service";
import { RecaudacionService } from "app/core/services/factoring/recaudacion.service";
import { ConfirmModalComponent } from "app/shared/utils/confirm-modal/confirm-modal.component";
import { AceptantesService } from "app/core/services/factoring/aceptantes.service";
import { AddProveedoresComponent } from "../../modals/add-proveedores/add-proveedores.component";

@Component({
  selector: "app-add-aceptante-providers",
  templateUrl: "./providers.component.html",
  styleUrls: ["./providers.component.css"],
})
export class ProvidersComponent implements OnInit {

  public user: any;

  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public searchstring: string = "";
  public toDelete: number;

  @Input() aceptanteId = 0;
  @Input() readonly = false;
  aceptante : any;
  clientes : any[] = [];
  deudas : any[] = [];

  myform: FormGroup;

  constructor(
    private modalService        : NgbModal,
    private formBuilder         : FormBuilder,
    public localeService        : BsLocaleService,
    private recaudacionService  : RecaudacionService,
    private authService         : AuthService,
    private aceptantesService   : AceptantesService
  ) {
    defineLocale("es", esLocale);
    this.localeService.use("es");
    this.user = this.authService.user;
  }

  ngOnInit(): void {
    this.initForm();
    this.obtenerAceptante();

    if (this.readonly) this.myform.disable()
  }

  initForm() {
    this.myform = this.formBuilder.group({
      maxima_exposicion: [null, [Validators.required]],
    });
  }

  obtenerAceptante() {
    this.aceptantesService.obtenerAceptante(this.aceptanteId)
    .then(res => {
      this.aceptante = res;
      this.myform.controls['maxima_exposicion'].setValue(res['maxima_exposicion']);
      this.obtenerClientes();
    }).catch(error => {
      console.error(error);
    });
  }

  obtenerClientes() {
    let clientes = this.aceptantesService.obtenerClientes(this.aceptante.ruc);

    Promise.all([clientes]).then((res: any[]) => {
      let clientes = res[0];
      this.clientes = clientes['results'];
      this.obtenerDeudas(clientes);
      this.initVars();
      this.loadTable(clientes);
    });

  }

  obtenerDeudas(clientes) {
    let { results } = clientes;
    console.log(clientes)
    let ids = results.map(item => item.cliente);
    this.aceptantesService.obtenerDeudas(ids.toString(),this.aceptante.id)
    .then(res => {
      this.deudas = res['results'];
      this.mapperCliente();
    }).catch(error => {
      console.error(error);
      
    })
  }

  mapperCliente() {
    let data = this.clientes.map(item => {
      let client = item;
      let deuda = this.deudas.find(item => item.id == client.cliente);

      let data = {
        ...client,
        ...deuda,
        aceptante: client.id,
        total_deuda: (parseFloat(deuda.total_mora) + parseFloat(deuda.total_vigente)),
      }
      return data;
    });
    console.log(data);
    this.rowData = data;
  }

  loadTable(data) {
    // this.rowData = data.results?.map((el, index: number) => ({
    //   ...el,
    //   index: index + 1,
    // }));
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars() {
    let columns = [
      {
        headerName: "N°",
        field: "cliente",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Cliente Factoring",
        field: "cliente_nombre",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "% Ingresos",
        field: "porcentaje_ingresos",
        pipe: 'percent-icon',
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Monto Facturación",
        field: "facturacion_anual",
        pipe: "currency_detail",
        moneda_detail: 1,
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Deuda",
        field: "total_deuda",
        pipe: "currency_detail",
        moneda_detail: 1,
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Vigente",
        field: "total_vigente",
        pipe: "currency_detail",
        moneda_detail: 1,
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Mora",
        field: "total_mora",
        pipe: "currency_detail",
        moneda_detail: 1,
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "% Mora",
        field: "porcentaje_mora",
        pipe: 'percent-icon',
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Max Días",
        field: "maximo_dias",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Instrumentos",
        field: "instrumentos",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Reseñas",
        field: "resenia",
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {
    
  }

  tableEditEvent(row) {
    const modalRef = this.modalService.open(AddProveedoresComponent, {
      
    });

    modalRef.componentInstance.proveedor = row;

    modalRef.result.then(() => {});

    modalRef.componentInstance.successEmit.subscribe(() => {
      this.obtenerClientes();
    });
  }

  onSubmit() {
    if(this.myform.invalid)
      return;

    let data = this.aceptante;
    data['maxima_exposicion'] = this.myform.controls['maxima_exposicion'].value;
    data['moneda_maxima_exposicion'] = 1;
    
    this.aceptantesService.guardarAceptante(data, this.aceptante.id)
    .then(res => {
      this.obtenerAceptante();
    }).catch(error => {
      console.error(error);
      
    })
  }

  get deudaTotal() {
    let amount = this.rowData.reduce((acc, act) => {
      return parseFloat(acc) + parseFloat(act.total_deuda);
    }, 0);

    return amount.toFixed(2);
  }

  get disponiple() {
    if (this.aceptante && this.aceptante.maxima_exposicion) {
      return parseFloat(this.aceptante.maxima_exposicion) - parseFloat(this.deudaTotal);
    } else {
      return null;
    }
  }

}
