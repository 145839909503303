import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  private apiUrl                  : string = "";
  public documents                : string = 'factoring/documentos/';
  public documentsGrupos          : string = 'factoring/documentos/grupos/';

  constructor(
    public appService: AppService,
    public toast: ToastService,
    public spinner: NgxSpinnerService,
    public http: HttpClient
  ) {
    this.apiUrl = this.appService.settings.API_base_url;
   }

   /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();

    this.appService.notifyMe(mensaje,ok)
    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);

  }

  obtenerGrupos(page: number = 1, page_size: number = 1000) {
    const url = this.apiUrl + this.documentsGrupos + `?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }
  

  obtenerTiposPersonas(page: number = 1, page_size: number = 1000) {
    const url = this.apiUrl + `clientes/personas/tipos/?page_size=${page_size}` + `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  obtenerTiposProductos() {
    const url = this.apiUrl + `factoring/lineas`;

    return new Promise((res, ref) => {
      this.spinnerOn();
      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff();
          ref(err);
        }
      );
    });
  }

  /**
   * 
   * @param page 
   * @param page_size 
   * @param descripcion__icontains 
   * @param grupo 
   */
  obtenerDocuments(
    page: number = 1,
    page_size: number = 10,
    descripcion__icontains: string = "",
    grupo: string = "",
  ) {
      const url = this.apiUrl + this.documents +
                  `?page_size=${page_size}` + `&page=${page}` +
                  `&descripcion__icontains=${descripcion__icontains}` +
                  `&grupo=${grupo}`;

      return new Promise((res, ref) => {
        this.spinnerOn();
  
        this.http.get(url).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La petición falló", false);
            ref(err);
          }
        );
      });
  }

  obtenerDocument(id) {
    const url = this.apiUrl + this.documents + `/${id}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarDocument(data, documentId = 0) {
    const url = documentId
      ? this.apiUrl + this.documents + `${documentId}/`
      : this.apiUrl + this.documents;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (documentId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fue guardada exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff("La información fueregistrado exitosamente.");
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  elimiarDocument(documentId) {
    const url = this.apiUrl + this.documents + `${documentId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff("Documento Eliminado!");
          res(response);
        },
        (err) => {
          this.spinnerOff("La petición falló", false);
          ref(err);
        }
      );
    });
  }

}
