export class OrdenDePago{
    public id                         : any = '';
    public monto                      : any = '';	
    public forma_pago                 : any = '';
    public moneda                     : any = '';
    public moneda_descripcion         : any = '';
    public banco_cargo                : any = '';
    public banco_cargo_nombre         : any = '';
    public moneda_cargo               : any = '';
    public moneda_cargo_descripcion   : any = '';
    public cuenta_cargo               : any = '';
    public fecha_pago                 : any = '';	
    public nro_pago                   : any = '';
    public comentarios                : any = '';
    public adjunto                    : any = '';	
    public tipo                       : any = '';
    public tipo_descripcion           : any = '';
    public banco_abono                : any = '';
    public banco_abono_nombre         : any = '';
    public moneda_abono               : any = '';
    public moneda_abono_descripcion   : any = '';
    public cuenta_abono               : any = '';
    public responsable_orden          : any = '';
    public tesoreria_responsable      : any = '';
    public fecha_desembolso_programado: any = '';
    public cuenta_cargo_operacion     : any = '';
    public cuenta_abono_operacion     : any = '';
    public cuenta                     : any = '';


    constructor(orden = null){
        this.setOrden(orden);
    }

    set updateOrden(orden){
        this.setOrden(orden);
    }

    static parseDate(fecha){
        
        let yy = fecha.split('-')[0];
        let mm = Number(fecha.split('-')[1]) - 1;
        let dd = fecha.split('-')[2];

        let date = new Date(yy,mm,dd)
        return date

    }

    setOrden(orden){
        if(!orden){
            return
        }

        let thisPropiedades = Object.getOwnPropertyNames(new OrdenDePago())
        for (const property in orden) {
            let temporal = thisPropiedades.find(props => props === property)

            if(temporal && (this[temporal] == '' || this[temporal] == ""))
                this[temporal] = null

            if(temporal && orden[property]){
                this[temporal] = orden[property]
            }
        }
    }


    formatDate(date){
        
        if(!date){
            date = new Date()
        }

        if (!(date instanceof Date))
            return date;
        return date.getFullYear() + 
                    "-" + (date.getMonth() + 1) + 
                    "-" + date.getDate();
    }  


    get valores(){
        let thisPropiedades = Object.getOwnPropertyNames(new OrdenDePago())

        let retorno : FormData = thisPropiedades.reduce((formData,item)=>{
            if(this[item]){
                
                if(!((item == 'adjunto') && (typeof this[item] == 'string'))){
                    formData.append(item, this[item]);
                } 

            }
            
            return formData
        },new FormData())

        let fecha = this.formatDate(this.fecha_pago)
        retorno.append('fecha_pago',fecha)

        return retorno
    }

}