import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from 'app/app.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastService } from '../toast/toast.service';

@Injectable({
  providedIn: 'root'
})
export class GrupoEconomicoService {

  private apiUrl                  : string = "";
  private gruposEconomicos        : string = "clientes/gruposeconomicos/";
  public gruposClientes           : string = 'factoring/beneficiarios/';

  constructor(
    public appService: AppService,
      public toast: ToastService,
      public spinner: NgxSpinnerService,
      public http: HttpClient
  ) { 
    this.apiUrl = this.appService.settings.API_base_url;
  }

  /**
   * Habilita el loader para request a la API
   */
  spinnerOn() {
    this.spinner.show();
  }

  /**
   * Desabilita el loader
   * @param mensaje Mensaje del toast
   * @param ok Tipo de mensaje, TRUE para success, FALSE para errores
   */
  spinnerOff(mensaje: string = null, ok: boolean = true) {
    this.spinner.hide();

    this.appService.notifyMe(mensaje,ok)
    if (mensaje && ok) this.toast.success(mensaje);
    if (mensaje && !ok) this.toast.warning(mensaje);

  }

  obtenerGruposEconomicos(
    page: number = 1,
    page_size: number = 10,
  ) {

    const url = this.apiUrl + this.gruposEconomicos +
      `?page_size=${page_size}` +
      `&page=${page}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });

  }

  obtenerGrupoEconomico(grupoId) {
    const url = this.apiUrl + this.gruposEconomicos + `${grupoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  guardarGrupoEconomico(data, grupoId = 0) {
    const url = grupoId
      ? this.apiUrl + this.gruposEconomicos + `${grupoId}/`
      : this.apiUrl + this.gruposEconomicos;

    return new Promise((res, ref) => {
      this.spinnerOn();

      if (grupoId) {
        this.http.patch(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      } else {
        this.http.post(url, data).subscribe(
          (response) => {
            this.spinnerOff();
            res(response);
          },
          (err) => {
            this.spinnerOff("La operación falló", false);
            ref(err);
          }
        );
      }
    });
  }

  eliminarGrupoEconomico(grupoId) {
    const url = this.apiUrl + this.gruposEconomicos + `${grupoId}/`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.delete(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });
  }

  grupoEcomonicoClientes(grupoId) {

    const url = this.apiUrl + this.gruposClientes + `?grupo_economico=${grupoId}`;

    return new Promise((res, ref) => {
      this.spinnerOn();

      this.http.get(url).subscribe(
        (response) => {
          this.spinnerOff();
          res(response);
        },
        (err) => {
          this.spinnerOff("La operación falló", false);
          ref(err);
        }
      );
    });

  }

}
