import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { LavadoActivosService } from 'app/core/services/lavado_activos/lavado_activos.service'
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { AuthService } from "app/core/services/auth/auth.service";
import { faFistRaised, faLongArrowAltLeft, faLongArrowAltRight, faTimes } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-manage-configuracion-modal',
  templateUrl: './manage-configuracion-modal.component.html',
  styleUrls: ['./manage-configuracion-modal.component.css']
})
export class ManageConfiguracionModalComponent implements OnInit {
  faTimes = faTimes;
  faFistRaised = faFistRaised;
  faLongArrowAltRight = faLongArrowAltRight;
  faLongArrowAltLeft = faLongArrowAltLeft;
  public disponibles = [];
  public asignados = [];
  public variablesRiesgo: any[] = [];
  public filtroDisponibles = '';
  public filtroAsignados = '';
  public instance_row: any;
  public user: any;

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public activeModal: NgbActiveModal,
    public lavadoActivosService: LavadoActivosService,
    public localeService: BsLocaleService,
    private authService: AuthService,
  ) {
    defineLocale("es", esLocale);
    this.localeService.use("es");
  }


  ngOnInit(): void {
    this.user = this.authService.user;
    this.obtenerVariablesRiesgo();
  }

  obtenerVariablesRiesgo(page = 1, page_size = 99) {
    let variables_riesgo_disponibles = this.lavadoActivosService.obtenerVariablesRiesgo({page, page_size});
    let variables_riesgo_asignados = this.lavadoActivosService.obtenerVariablesRiesgo({page, page_size, tipos:[this.instance_row.id]})
    Promise.all([variables_riesgo_asignados, variables_riesgo_disponibles]).then((res: any[]) => {
      this.asignados = res[0].results

      this.disponibles = this.validarDisponibles(res[1].results)
    });

  }

  eliminarTildes(texto: string): string {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }

  filtrarDisponibles(): any[] {
    let filtro = this.eliminarTildes(this.filtroDisponibles.toLowerCase());
    return this.disponibles.filter(e => this.eliminarTildes(e.descripcion.toLowerCase()).includes(filtro));
  }
  
  filtrarAsignados(): any[] {
    let filtro = this.eliminarTildes(this.filtroAsignados.toLowerCase());
    return this.asignados.filter(e => this.eliminarTildes(e.descripcion.toLowerCase()).includes(filtro));
  }

  moverADisponibles(index: number): void {
    const elemento = this.asignados.splice(index, 1)[0];
    this.disponibles.unshift(elemento);
  }

  moverAAsignados(index: number): void {
    const elemento = this.disponibles.splice(index, 1)[0];
    this.asignados.unshift(elemento);
  }
  validarDisponibles(variables: any[]) {
    const asignados = this.asignados.map(value => value.id);
    return variables.filter(value => !asignados.includes(value.id));
  }
  actualizarTipoPlantilla() {
    const variables = this.asignados.map(value => value.id);
    const row = {
      id: this.instance_row.id,
      variables
    };
  
    this.lavadoActivosService
      .pathTipoPlantillasRiesgo(row)
      .then(() => {
        this.obtenerVariablesRiesgo();
      });
  }
}
