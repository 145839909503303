import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { RestriccionesService } from "app/core/services/auth/restricciones.service";
import { CommonServicesService } from "app/core/services/common-services.service";
import { DocschecklistService } from "app/core/services/config/docschecklist.service";
import { PropuestaComercialService } from "app/core/services/propuesta-comercial/propuesta-comercial.service";
import { ToastService } from "app/core/services/toast/toast.service";
import { DesglosesComponent } from "app/shared/desgloses/desgloses.component";
import { OpenTextModalComponent } from "app/shared/modals/open-text-modal/open-text-modal.component";

@Component({
  selector: "app-analysis-form",
  templateUrl: "./analysis-form.component.html",
  styleUrls: ["./analysis-form.component.css"],
})
export class AnalysisFormComponent implements OnInit {
  faCopy = faCopy
  public analysisForm                 : FormGroup;
  public tiposLinea                   : any[]   = [];
  public tiposMoneda                  : any[]   = [];
  public tiempos                      : any[];
  public propuestaContext             : any     = {};
  public desglosesLineaPropuestaRiesgo: any[];
  public tiposEvaluacion : any[] = [];
  contrato                            : any;
  @Input() tiposFirma                 : any[]   = []
  @Input() condiciones                : any[]   = []
  @Input() tiposFianza                : any[]   = []
  @Input() hasContrato                : boolean = false
  @Input() operacionId                : any     = 0;
  @Input() operacion                  : any     = 0;
  @Input() colorSet                   : any;
  @Input() _checkToggle               : boolean = false;
  desglosesDisabled: boolean = false;
  @Input() set checkToggle(val) {
    this.checkCopy = !val;
  }
  get checkToggle() {
    return this.checkCopy;
  }
  @Output() formSend = new EventEmitter<boolean>();

  checkCopy = false;
  @Output() changeCopy = new EventEmitter<any>();

  @Input() frmPropuesta: any;
  @Output() changeForm = new EventEmitter<any>();
  @Output() clickForm = new EventEmitter<any>();

  @Input() frmCommitee: any;

  @Output() sendValuesCommitee = new EventEmitter<any>();
  _readOnly: boolean = false;
  @Input() set readOnly(val) {
    this._readOnly = val;
    if(!this.analysisForm)
      return
      
  }

  temMinimoSoles = 0
  temMinimoDolares = 0

  constructor(
    public toast            : ToastService,
    private propuestaService: PropuestaComercialService,
    private configs         : DocschecklistService,
    private authService     : AuthService,
    private formbuild       : FormBuilder,
    private restricciones   : RestriccionesService,
    public serviciosComunes: CommonServicesService,
    public modalService     : NgbModal,
  ) {
    
  }

  ngOnInit(): void {
    this.initForm();
    console.log(this.hasContrato)
    if(!this.hasContrato){
      this.analysisForm.get('contrato_nuevo_riesgos').setValue(true);
      this.analysisForm.get('contrato_nuevo_riesgos').disable();
      this.analysisForm.get('tipo_fianza_riesgos').setValidators([Validators.required]);
      this.analysisForm.get('tipo_firma_riesgos').setValidators([Validators.required]);
    }

    this.checkCopy = false;

    this.tiempos = [
      { id: 1, descripcion: "Días" },
      { id: 2, descripcion: "Meses" },
      { id: 3, descripcion: "Años" },
    ];

    let lineas = this.configs.obtenerLineas();
    let monedas = this.configs.obtenerMonedas();
    let propuesta = this.propuestaService.obtenerAnalisisRiesgos(
      this.operacionId
    );
    let tiposEvaluacion = this.configs.obtenerTiposDeEvaluacion();
    let desgloses = this.serviciosComunes.getDesglose(this.operacionId,2)

    Promise.all([propuesta, lineas, monedas, desgloses, tiposEvaluacion])
      .then((res) => {
        let dataPropuesta = res[0];
        this.propuestaContext = res[0];
        this.analysisForm.reset({
          tipo_linea_comercial           : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
          moneda_linea                   : dataPropuesta["moneda_riesgos"],
          moneda_riesgos                 : dataPropuesta["moneda"],
          monto_linea                    : dataPropuesta["linea_propuesta_riesgos"],
          vigencia                       : dataPropuesta["vigencia_riesgos"],
          vigencia_tipo                  : dataPropuesta["tipo_vigencia_riesgos"],
          tea_soles                      : dataPropuesta["tea_soles_riesgos"],
          tem_soles                      : dataPropuesta["tem_soles_riesgos"],
          tea_dolares                    : dataPropuesta["tea_dolares_riesgos"],
          tem_dolares                    : dataPropuesta["tem_dolares_riesgos"],
          tasa_mora                      : dataPropuesta["tasa_mora_riesgos"],
          anticipo                       : dataPropuesta["anticipo_riesgos"],
          plazo                          : dataPropuesta["plazo_riesgos"],
          plazo_tipos                    : dataPropuesta["tipo_plazo_riesgos"],
          adelanto_                      : dataPropuesta["adelanto_riesgos"],
          moneda_abono_leasing           : dataPropuesta['moneda'],
          abono_leasing                  : dataPropuesta['abono_leasing_riesgos'],
          comentarios                    : dataPropuesta["comentarios_riesgos"],
          contrato_nuevo_riesgos         : dataPropuesta["contrato_nuevo_riesgos"],
          ofical_negocio                 : dataPropuesta["analista_riesgo_nombre"],
          tipo_fianza_riesgos            : dataPropuesta["tipo_fianza_riesgos"],
          tipo_firma_riesgos             : dataPropuesta["tipo_firma_riesgos"],
          comision_estructuracion_riesgos: dataPropuesta["comision_estructuracion_riesgos"],
          condiciones_riesgos            : dataPropuesta["condiciones_riesgos"],
          tipo_evaluacion                : dataPropuesta["tipo_evaluacion"],
        });
        this.changeForm.emit(this.analysisForm.value);
        this.tiposLinea                    = res[1]["results"];
        this.tiposMoneda                   = res[2]["results"];
        this.desglosesLineaPropuestaRiesgo = res[3]["desgloses"];
        this.tiposEvaluacion = res[4]["results"];
        this.obtenerContrato(this.propuestaContext["id"]);

        if(this.operacion.puntual){
          this.analysisForm.get('contrato_nuevo_riesgos').setValue(true)
          this.analysisForm.get('contrato_nuevo_riesgos').disable()
          this.desglosesDisabled = true
        }
      })
      .catch((error) => {
        console.error(error);
      });
      if (this._readOnly) {
        this.analysisForm.disable();
      } else {
        this.analysisForm.enable();
      }

  }

  emitEvent(){
    this.propuestaContext._desgloses = this.desglosesLineaPropuestaRiesgo
    this.changeCopy.emit(this.propuestaContext)
  }

  getPropuestaRiesgo() {
    let lineas = this.configs.obtenerLineas();
    let monedas = this.configs.obtenerMonedas();
    let propuesta = this.propuestaService.obtenerAnalisisRiesgos(
      this.operacionId
    );

    Promise.all([propuesta, lineas, monedas])
      .then((res) => {
        let dataPropuesta = res[0];
        this.propuestaContext = res[0];
        this.analysisForm.reset({
          tipo_linea_comercial           : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
          moneda_linea                   : dataPropuesta["moneda_riesgos"],
          moneda_riesgos                   : dataPropuesta["moneda"],
          monto_linea                    : dataPropuesta["linea_propuesta_riesgos"],
          vigencia                       : dataPropuesta["vigencia_riesgos"],
          vigencia_tipo                  : dataPropuesta["tipo_vigencia_riesgos"],
          tea_soles                      : dataPropuesta["tea_soles_riesgos"],
          tem_soles                      : dataPropuesta["tem_soles_riesgos"],
          tea_dolares                    : dataPropuesta["tea_dolares_riesgos"],
          tem_dolares                    : dataPropuesta["tem_dolares_riesgos"],
          tasa_mora                      : dataPropuesta["tasa_mora_riesgos"],
          anticipo                       : dataPropuesta["anticipo_riesgos"],
          plazo                          : dataPropuesta["plazo_riesgos"],
          plazo_tipos                    : dataPropuesta["tipo_plazo_riesgos"],
          adelanto_                      : dataPropuesta["adelanto_riesgos"],
          moneda_abono_leasing           : dataPropuesta['moneda'],
          abono_leasing                  : dataPropuesta['abono_leasing_riesgos'],
          comentarios                    : dataPropuesta["comentarios_riesgos"],
          contrato_nuevo_riesgos         : dataPropuesta["contrato_nuevo_riesgos"],
          ofical_negocio                 : dataPropuesta["analista_riesgo_nombre"],
          tipo_fianza_riesgos            : dataPropuesta["tipo_fianza_riesgos"],
          tipo_firma_riesgos             : dataPropuesta["tipo_firma_riesgos"],
          comision_estructuracion_riesgos: dataPropuesta["comision_estructuracion_riesgos"],
          condiciones_riesgos            : dataPropuesta["condiciones_riesgos"],
          tipo_evaluacion                : dataPropuesta["tipo_evaluacion"],
        });

        this.changeForm.emit(this.analysisForm.value);
        this.tiposLinea = res[1]["results"];
        this.tiposMoneda = res[2]["results"];

        this.obtenerContrato(this.propuestaContext["id"]);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  initForm() {
    let producto = this.operacion?.tipo_linea_solicitada || this.operacion?.operacion?.tipo_linea_solicitada
    let fecha = new Date(this.operacion?.fecha || this.operacion?.operacion?.fecha || new Date())
    let fechaInicioValidacion = new Date(2022, 8, 14) // Validar operaciones creadas desde esta fecha
    this.temMinimoSoles = (producto == 2 || fecha.getTime() < fechaInicioValidacion.getTime()) ? 0 : 1//(this.operacion?.linea_credito ? 1.5 : 1.6)
    this.temMinimoDolares = (producto == 2 || fecha.getTime() < fechaInicioValidacion.getTime()) ? 0 : 1//(this.operacion?.linea_credito ? 1.47 : 1.57)

    this.analysisForm       = this.formbuild.group({
      tipo_linea_comercial           : ["", [Validators.required]],
      moneda_linea                   : ["", [Validators.required]],
      moneda_riesgos                   : ["", [Validators.required]],
      monto_linea                    : ["", [Validators.required]],
      vigencia                       : ["", [Validators.required]],
      vigencia_tipo                  : ["", [Validators.required]],
      tea_soles                      : ["", [Validators.required]],
      tem_soles                      : ["", [Validators.required, Validators.min(this.temMinimoSoles)]],
      tea_dolares                    : ["", [Validators.required]],
      tem_dolares                    : ["", [Validators.required, Validators.min(this.temMinimoDolares)]],
      tasa_mora                      : ["", [Validators.required]],
      anticipo                       : ["", [Validators.required]],
      plazo                          : ["", [Validators.required]],
      plazo_tipos                    : ["", [Validators.required]],
      comentarios                    : ["", [Validators.required]],
      moneda_abono_leasing           : ['', [Validators.required]],
      abono_leasing                  : [0],
      adelanto_                      : ["", [Validators.required, Validators.max(this.operacion.monto - 1)]],
      contrato_nuevo_riesgos         : [false, [Validators.required]],
      ofical_negocio                 : ["", []],
      tipo_firma_riesgos             : ["", []],
      tipo_fianza_riesgos            : ["", []],
      comision_estructuracion_riesgos: ["", []],
			condiciones_riesgos: [[],[]],
      tipo_evaluacion: ["", [Validators.required]],
    });

    this.analysisForm.valueChanges.subscribe((value) => {
      if (this.diffEmpty(value)) {
        this.changeForm.emit(this.analysisForm.value);
      }
    });
  }

  setForm(values) {

    this.analysisForm.reset({
      tipo_linea_comercial           : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
      moneda_linea                   : values["moneda_linea"],
      monto_linea                    : values["monto_linea"],
      moneda_riesgos                    : values["moneda_comercial"],
      vigencia                       : values["vigencia"],
      vigencia_tipo                  : values["vigencia_tipo"],
      tea_soles                      : values["tea_soles"],
      tem_soles                      : values["tem_soles"],
      tea_dolares                    : values["tea_dolares"],
      tem_dolares                    : values["tem_dolares"],
      tasa_mora                      : values["tasa_mora"],
      anticipo                       : values["anticipo"],
      plazo                          : values["plazo"],
      plazo_tipos                    : values["plazo_tipos"],
      comentarios                    : values["comentarios"],
      contrato_nuevo_riesgos         : values["contrato_nuevo_comercial"],
      ofical_negocio                 : this.propuestaContext['analista_riesgo_nombre'],
      adelanto_                      : values["adelanto_"],
      tipo_fianza_riesgos            : values["tipo_fianza_comercial"],
      tipo_firma_riesgos             : values["tipo_firma_comercial"],
      comision_estructuracion_riesgos: values["comision_estructuracion_comercial"],
      condiciones_riesgos            : values["condiciones_comercial"],
      moneda_abono_leasing           : values["moneda_abono_leasing"],
      abono_leasing                  : values["abono_leasing"],
      tipo_evaluacion                : values["tipo_evaluacion"],
    });
  }

  /**
   * Comparar el valor del input para dar color
   */
  colorInput(formControl) {
    if (this.frmCommitee && this.frmCommitee[formControl]) {
      return "";
    }

    if (this.operacion.estacion != 4) {
      return "";
    }

    let control = parseFloat(this.analysisForm.controls[formControl].value);
    let auxControl = parseFloat(this.frmPropuesta[formControl]);
    return control > auxControl ? "input-red" : "input-blue";
  }

  sendValueCommite(formControl) {
    let value = this.analysisForm.controls[formControl].value;
    this.sendValuesCommitee.emit({ formControl, value });
  }

  saveForm() {
    if (!this.analysisForm.valid) return;
    this.analysisForm.get('contrato_nuevo_riesgos').enable()
    const req = {
      tipo_linea_riesgos             : this.operacion.tipo_linea_solicitada || this.operacion.operacion.tipo_linea_solicitada,
      linea_propuesta_riesgos        : this.analysisForm.controls["monto_linea"].value,
      vigencia_riesgos               : this.analysisForm.controls["vigencia"].value,
      tipo_vigencia_riesgos          : this.analysisForm.controls["vigencia_tipo"].value,
      moneda_riesgos                 : this.analysisForm.controls["moneda_linea"].value,
      tea_soles_riesgos              : this.analysisForm.controls["tea_soles"].value,
      tem_soles_riesgos              : this.analysisForm.controls["tem_soles"].value,
      tea_dolares_riesgos            : this.analysisForm.controls["tea_dolares"].value,
      tem_dolares_riesgos            : this.analysisForm.controls["tem_dolares"].value,
      tasa_mora_riesgos              : this.analysisForm.controls["tasa_mora"].value,
      anticipo_riesgos               : this.analysisForm.controls["anticipo"].value,
      plazo_riesgos                  : this.analysisForm.controls["plazo"].value,
      tipo_plazo_riesgos             : this.analysisForm.controls["plazo_tipos"].value,
      comentarios_riesgos            : this.analysisForm.controls["comentarios"].value,
      adelanto_riesgos               : this.analysisForm.controls["adelanto_"].value,
      analista_riesgo_nombre         : this.propuestaContext["analista_riesgo_nombre"],
      contrato_nuevo_riesgos         : this.analysisForm.controls["contrato_nuevo_riesgos"].value,
      tipo_fianza_riesgos            : this.analysisForm.controls["tipo_fianza_riesgos"].value,
      tipo_firma_riesgos             : this.analysisForm.controls["tipo_firma_riesgos"].value,
      comision_estructuracion_riesgos: this.analysisForm.get("comision_estructuracion_riesgos").value,
      condiciones_riesgos            : this.analysisForm.get("condiciones_riesgos").value,
      abono_leasing_riesgos          : this.analysisForm.controls['abono_leasing'].value,
      tipo_evaluacion                : this.analysisForm.controls["tipo_evaluacion"].value,
      //falta moneda_leasing - monto_leasing
    };
    // this.analysisForm.get('contrato_nuevo_riesgos').disable()

    if (!this.propuestaContext["analista_riesgo_nombre"]) return;

    this.propuestaService
      .actualizarAnalisisRiesgo(this.operacionId, req)
      .then((r) => {
        this.toast.success("La operación fue completada con éxito!");
        this.getPropuestaRiesgo();
        this.formSend.emit(true);
      })
      .catch((error) => {
        this.formSend.emit(false);
        this.toast.warning("Ha ocurrido un error al enviar la data");
      });
  }

  diffEmpty(obj) {
    for (const item in obj) {
      if (obj[item] != "") {
        return true;
      }
    }
    return false;
  }

  obtenerContrato(operacionId) {
    this.propuestaService
      .obtenerContrato(operacionId)
      .then((res) => {
        this.contrato = res["contrato"] ? res["contrato"] : null;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  parseDate(date) {
    if (!date) return;

    const arrayDate = date.split("-");
    return `${arrayDate[2]}/${arrayDate[1]}/${arrayDate[0]}`;
  }

  setInviable(){
    this.analysisForm.reset({
      moneda_linea                   :  1,
      monto_linea                    :  0,
      vigencia                       :  0,
      vigencia_tipo                  :  1,
      tea_soles                      :  0,
      tem_soles                      :  0,
      tea_dolares                    :  0,
      tem_dolares                    :  0,
      tasa_mora                      :  0,
      anticipo                       :  0,
      plazo                          :  0,
      plazo_tipos                    :  1,
      comentarios                    :  'INVIABLE',
      contrato_nuevo_riesgos         :  false,
      ofical_negocio                 :  null,
      adelanto_                      :  0,
      tipo_fianza_riesgos            :  1,
      tipo_firma_riesgos             :  1,
      comision_estructuracion_riesgos:  0,
      condiciones_riesgos            :  [],
    });
  }

  changeMonedaAdelanto(moneda) {
		this.analysisForm.controls['moneda_linea'].setValue(moneda.id);
	}

  get showOpenText() {
    return (this.analysisForm.controls['comentarios'].value && this.analysisForm.controls['comentarios'].value.length > 100)
  }

  openTextModal() {

    if(!this.analysisForm.disabled) return;
    
    const modalRef = this.modalService.open(OpenTextModalComponent, {
    });

    modalRef.componentInstance.title = 'Comentarios Propuesta de Riesgos';
    modalRef.componentInstance.message = this.analysisForm.get('comentarios').value;

    modalRef.result.then((result) => {
          console.log(result);
      }, (reason) => {
          console.log(reason);
    });
  }

  mostrarDesglose(){
    let { detalle } = this.operacion
    const modalRef = this.modalService.open(DesglosesComponent, {
      size: "lg",
    });
    console.log(this.desglosesLineaPropuestaRiesgo)
    modalRef.componentInstance.desgloses     = this.desglosesLineaPropuestaRiesgo
    modalRef.componentInstance.operacion     = this.operacion.id;
    modalRef.componentInstance.operacionBody = this.operacion;
    modalRef.componentInstance.propuesta     = 2;
    modalRef.componentInstance.readonly      = this._readOnly;
    // modalRef.componentInstance.readonly     = false;
    modalRef.componentInstance.deleteEvent.subscribe((val)=>{
      this.obtenerDesgloses()
      this.modalService.dismissAll()
    })

    modalRef.result.then((result) => {
      if (result) {

      }
    });

  }

  obtenerDesgloses(){
    this.modalService.dismissAll()
    let desgloses = this.serviciosComunes.getDesglose(this.operacionId,2)
    desgloses.then((res: any)=>{
      this.desglosesLineaPropuestaRiesgo = res['desgloses']
      this.mostrarDesglose()
    })
  }

  setDesglose(_desgloses){
    let desgloses = JSON.parse(_desgloses)
    let desgloseTemp = this.desglosesLineaPropuestaRiesgo

    let addDesg: any[] = []
    let filtrados = desgloseTemp.map((desg,index,arr) => {
      let find = desgloses.find((item) => item.aceptante.id == desg.aceptante.id)
      if(find){
        for (let prop in find){
          if(!(prop == 'id' || prop =='propuesta')){
            desg[prop] = find[prop]
          }
        }
      }
      
      return desg
    })

    let noIncluidos = desgloses.filter((desg)=>{
      let find = desgloseTemp.find((item) => item.aceptante.id == desg.aceptante.id)
      if(!find){
        return true
      } else {
        return false
      }
    })

    let concatenar = noIncluidos.map((item)=>{
      item.propuesta = 2
      delete item.id
      return item
    })
    this.desglosesLineaPropuestaRiesgo = desgloseTemp.concat(concatenar)
  }

}
