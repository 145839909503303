import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { AdminLayoutComponent } from 'app/layouts/admin/admin-layout.component';
import { DocumentsComponent } from './documents/documents.component';


const routes: Routes = [{
  path: 'configuracion',
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Documentos'
    },
    children: [
      {
        path: 'documents',
        canActivate: [AuthGuard],
        component: DocumentsComponent,
        data: {
          breadcrumb: 'Documentos'
        }
      },
    ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DocumentsRoutingModule { }
