import {
  Component,
  OnInit
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {  NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { esLocale } from "ngx-bootstrap/locale";
import { defineLocale } from "ngx-bootstrap/chronos";
import { AuthService } from "app/core/services/auth/auth.service";
import { Perfil } from "app/core/services/auth/Perfil";
import { ToastService } from "app/core/services/toast/toast.service";
import { Subject } from 'rxjs';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";


@Component({
  selector: 'app-modal-configuracion',
  templateUrl: './modal-configuracion.component.html',
  styleUrls: ['./modal-configuracion.component.css']
})
export class ModalConfiguracionComponent implements OnInit {
  faTimes                     = faTimes
  public codigoImportacion: string = 'EXCEL_VARIABLES_NAME';
  public mostrarVariables: boolean = false;
  public variables: any = null;
  public minDate              : Date;
  public cabeceraSolicitudForm: FormGroup;
  public user                 : any;
  public canAprobe            : any               = null;
  row_instance     : any               ;
  readOnlyForm                = false;
  readOnlyButtonActualizar    = false;
  perfil                      = new Perfil();
  searchTextBene              = "";
  searchText$                 = new Subject<string>();
  loadingSearch               : boolean           = false;
  caracteresMinimos           : number            = 4;
  actInputExc                 : boolean;
  constructor(
    private formBuilder       : FormBuilder,
    public localeService      : BsLocaleService,
    private authService       : AuthService,
    public activeModal        : NgbActiveModal,
    public toast              : ToastService,
    private lavadoActivosService : LavadoActivosService,
  ) { }

  ngOnInit(): void {
    defineLocale("es", esLocale);
    this.localeService.use("es");
    this.user = this.authService.user;
    this.crearCabecera();
    this.obtenerConfiguracion();
    if(this.row_instance.codigo == this.codigoImportacion){
      this.obtenerVariablesRiesgo()
    }
  }
  crearFormularioVariables(){
    
  }
  async obtenerVariablesRiesgo(page = 1, page_size = 99) {
    let variables_riesgo = this.lavadoActivosService.obtenerVariablesRiesgoOnlyDescripcion(page, page_size);
     Promise.all([await variables_riesgo]).then((res: any[]) => { 
      this.variables =  res[0]['results'];
      this.crearCabeceraA1(this.variables);
    });
  }
  getCabeceraData() {
    this.cabeceraSolicitudForm.enable();

    // Desconstruimos los datos de la cabecera de la recaudacion
   
    const {
      descripcion,
      valor
    } = this.cabeceraSolicitudForm.value;

    // Mapeamos todos los datos para enviarlo al endpoint
    let data = {
          
      descripcion: descripcion,
      valor: valor
    };

    if (this.row_instance.codigo == this.codigoImportacion) {
    let dataVariables = [];
    this.variables.forEach(element => {
      dataVariables.push({
        id : element.id,
        excel_reference_name : this.cabeceraSolicitudForm.value[`variable-${element.id}`]
      });
      
    });
    data['valor'] = JSON.stringify(dataVariables);
   }
    
    return data;
  }
  async enviarCabecera() {
    let data = this.getCabeceraData();
    if (!data) {
      return
    }

    if(this.row_instance && this.row_instance.id){
     
      data['id'] = this.row_instance.id;
      await this.lavadoActivosService
      .editarConfiguracion(data)
      ;
    } 
    this.obtenerConfiguracion();
  }
  refreshConfiguracion(configuracion: any){
    this.row_instance = configuracion;
    if (configuracion.codigo == this.codigoImportacion) {
    var string_json = configuracion.valor;
    var json_verse = JSON.parse(string_json);
    this.row_instance['variables'] = json_verse;
    }
    this.setForm(this.row_instance);


  }
  async obtenerConfiguracion() {
    await this.lavadoActivosService
      .obtenerConfiguracion(this.row_instance.id)
      .then((res) => {
        this.refreshConfiguracion(res)
        
      })
      .catch((error) => {
        console.error(error);
      });
    
  }
  crearCabecera() {
    this.cabeceraSolicitudForm = this.formBuilder.group({
      descripcion: ['', [Validators.required]],
      valor    : ['', [Validators.required]]
    })
  }
  crearCabeceraA1(variables: any[]) {
  
    variables.forEach(element => {
      this.cabeceraSolicitudForm.addControl(
        `variable-${element.id}`, 
        new FormControl('', [Validators.required])
      )
    });

    this.mostrarVariables = true;
  }

  setForm(data) {
    const mapperData = {
      descripcion: data.descripcion,
    };
    if (this.row_instance.codigo == this.codigoImportacion) {
        data.variables.forEach(element => {
        mapperData[`variable-${element.id}`] = element.excel_reference_name;
      });
    }else{
      mapperData['valor'] = data.valor;
    }
    for (const key in mapperData) {
      if (this.cabeceraSolicitudForm.controls[key]) {
        this.cabeceraSolicitudForm.controls[key].setValue(mapperData[key]);
      }
    }
  }

  get validForm() {
    let constrls = this.cabeceraSolicitudForm.controls;

    let result = true;

    for (const key in constrls) {
      if (Object.prototype.hasOwnProperty.call(constrls, key)) {
        if (constrls[key].errors) {
          result = true;
        } else {
          result = false;
        }
      }
    }
    return result;
  }
}
