import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { columnHeader } from '../../../../shared/factoring-datatable/factoring-datatable.component';
import { ConfigurationService } from '../../../../core/services/configuration/configuration.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalsComponent } from '../modals/modals.component';
import { toIsoString } from '../../../../shared/date/date';

@Component({
  selector: 'app-contable',
  templateUrl: './contable.component.html',
  styleUrls: ['./contable.component.css']
})
export class ContableComponent implements OnInit {

  @ViewChild("cierre_periodo", { static: false }) public cierre_periodo;

  @Input('refresh') set refresh(value){
    this.getOperativo();
  }

  public columnContable: any[] = [];
  public rowData: any[] = [];

  // public columnas = [
  //   'periodo',
  //   'periodo_inicio',
  //   'periodo_fin',
  //   'cerrado',
  //   'tipo_cierre_descripcion'
  // ]

  public loadingTable: boolean = false;
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];

  constructor( private configurationService  : ConfigurationService,
              private modalService: NgbModal ) { }

  ngOnInit(): void {

    this.loadColums();

    this.getOperativo();

  }

  getOperativo( page = 1, page_size = 10, year = '', month__gte = '', month__lte = '',  tipo_cierre = '', year__lte = '', year__gte = '', periodo_inicio__gte = '',  periodo_fin__lte = '' ){

    this.configurationService.getCierrePeriodoContable(page , page_size, '', '', '','2', year__lte, year__gte, periodo_inicio__gte,  periodo_fin__lte)
    .subscribe( ( resp )=>{

      console.log( resp )

      this.loadTable( resp )

    })

  }

  loadColums(){

    let setColmun = (obj)=>{

      this.columnContable.push( obj ) 

    }

    let config = {}

    config = {
      // filterProp : 'periodo',
      headerName : 'Periodo',
      field: "periodo",
      class: 'text-center',
      filterable : false,
      filterInput: false,
      filterDate: false
    }
    setColmun(config)

    config = {
      filterProp : 'periodo_inicio',
      headerName : 'Periodo Inicio',
      class: 'text-center',
      pipe: 'date',
      field: "periodo_inicio",
      filterable : true,
      filterInput: false,
      filterDateUnic: true
    }
    setColmun(config)

    config = {
      filterProp : 'periodo_fin',
      headerName : 'Periodo Fin',
      class: 'text-center',
      pipe: 'date',
      field: "periodo_fin",
      filterable : true,
      filterInput: false,
      filterDateUnic: true
    }
    setColmun(config)

    config = {
      filterProp : 'year',
      headerName : 'Año',
      class: 'text-center',
      field: "year",
      filterable : true,
      filterInput: false,
      filterDate: true,
      filterYear : { minMode:'year', showClearButton: true, clearPosition: 'right' }
    }
    setColmun(config)

    config = {
      filterProp : 'cerrado',
      headerName : 'Estado',
      class: 'text-center',
      field: "cerrado",
      pipe: "buttonElement",
      sortable: true,
      filterable : false,
      filterInput: false,
      filterDate: false,
      function: function (row) {
        return row.cerrado ? 'Cerrado': 'Abierto';
      },
    }
    setColmun(config)


    config = {
      filterProp : 'tipo_cierre_descripcion',
      headerName : 'Tipo cierre',
      class: 'text-center',
      field: "tipo_cierre_descripcion",
      filterable : false,
      filterInput: false,
      filterDate: false
    }

    setColmun(config)
  }

  loadTable(data) {
    this.rowData = data.results
    this.pageTableActual = data.page_number
    this.pageTableTotal = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }

  goToPage( { page, per_page, filtros } ){

    console.log( 'Filtros', filtros );
    this.getOperativo( page, per_page, '', '', '', '2' , filtros?.year__lte?.split('-')[0] , filtros?.year__gte?.split('-')[0], filtros?.periodo_inicio?.split(' ')[0], filtros?.periodo_fin?.split(' ')[0] )

  }

  // formatDate(date) {
  //   return (
  //     date.getFullYear() +
  //     "-" +
  //     ( ( date.getMonth() +1).toString().length == 1 ? '0'+(date.getMonth()+1) :  (date.getMonth()+ 1) ) +
  //     "-" +
  //     date.getDate()
  //   );
  // }

  closeOpenPeriodo(row){

    const modal = this.modalService.open( ModalsComponent, { size: 'md' } );

    modal.componentInstance.row = row;
    
    modal.componentInstance.successEmit.subscribe( 
      (val)=>{
        this.modalService.dismissAll();
        this.getOperativo();
      }
    )

  }

}
