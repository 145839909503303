import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MonedaPipe } from 'app/core/pipes/moneda.pipe';

@Component({
	selector: 'app-concentracion',
	templateUrl: './concentracion.component.html',
	styleUrls: ['./concentracion.component.css'],
	providers: [MonedaPipe]

})
export class ConcentracionComponent implements OnInit {
	@Output() pageUpdate: EventEmitter<any> = new EventEmitter<any>();
	rowData: any[] = [];
	pageTableActual: any;
	pageTableTotal: any;
	pageTablePerPages: any = 10;
	pageTablePages: any[];
	@Input() title;
	@Input() indicadorTitle;
	@Input() indicadorId;
	_indicador: any;
	columnDefs: any[];
	@Input() tableId = 0;
	@Input() set indicador(value) {
		if (value) {
			
			this.loadTable(value)
			this._indicador = value.results;

		}
	}
	constructor(
		public monedapipe: MonedaPipe,
	) { }

	ngOnInit(): void {
		this.initComponent()
	}

	initComponent() {
		this.columnDefs = [
			{
				headerName: 'Nombre',
				field: 'nombre',
				sortable: true,
			},
			{
				headerName: 'Monto soles',
				field: 'nombre',
				class: 'text-center',
				pipe: 'function',
				function: function ({ saldo_soles }, header) {
					return `${saldo_soles}`;
				},
			},
			{
				headerName: 'Monto Dólares',
				class: 'text-center',
				pipe: 'function',
				function: function ({ saldo_dolares }, header) {
					return `${saldo_dolares}`;
				},
			},
			{
				headerName: 'Porcentaje ',
				class: 'text-center',
				filterProp: 'tipo_linea',
				filterInput: true,
				pipe: 'function',
				function: function ({ porcentaje }, header) {
					return `${porcentaje} %`
				},
			}
		];
	}


	loadTable(data) {
		this.rowData = data.results.map((item) => {
			item.saldo_dolares = this.monedapipe.transform(item.saldo_dolares, 2)
			item.saldo_soles = this.monedapipe.transform(item.saldo_soles, 1)
			return item
		});
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

	goToPage({ page, per_page, filtros }) {
		// filtros.tipo_vencimiento = this.tipo
		this.pageUpdate.emit({ page, per_page, filtros })
	}

	update() {
		this.pageUpdate.emit({
			page: this.pageTableActual,
			per_page: this.pageTablePerPages,
			filtros: {}
		})
	}
}
