import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'app/shared/shared.module';

import { NotificacionesRoutingModule } from './notificaciones-routing.module';
import { NotificacionesComponent } from './pages/notificaciones/notificaciones.component';
import { ReenviarComponent } from './modals/reenviar/reenviar.component';

@NgModule({
  declarations: [
    NotificacionesComponent,
    ReenviarComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    NotificacionesRoutingModule,
  ]
})
export class NotificacionesModule { }
