import { Component, OnInit } from '@angular/core';
import { AuthService } from 'app/core/services/auth/auth.service';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';

import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';

import Swal from 'sweetalert2';
import { utils, writeFile } from 'xlsx';
import { functions } from '../../../../core/helpers/functions';

@Component({
  selector: 'app-reporte-exedentes-devueltos',
  templateUrl: './reporte-exedentes-devueltos.component.html',
  styleUrls: ['./reporte-exedentes-devueltos.component.css']
})
export class ReporteExedentesDevueltosComponent implements OnInit {

	public headerTable: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
  
  public rowData = [];
  public generando: boolean = false;

  public filtros: any = {
    nro_operacion: undefined,
    cliente_nombre: undefined,
    cliente_ruc: undefined,
    aceptante_nombre: undefined,
    aceptante_ruc: undefined,
    tipo_pagador: undefined,
    linea: undefined,
  };

  constructor(
    private factoringService: FactoringService,
    public authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.getReporteExedentesDevueltos({page:1, per_page:this.pageTablePerPages, filtros:{}})

    this.loadTableColumns()
  }

  loadTableColumns () {
    this.headerTable = [
      {
				headerName: 'N°',
        field: 'index',
				// pipe: 'indexcol',
				sortable: true,
			},
      {
        headerName: 'Ejecutivo',
        class: 'text-center',
        field: 'ejecutivo_nombre',
        sortable: true,
      }, 
      {
        headerName: 'Fecha',
        class: 'text-center',
        field: 'fecha',
        sortable: true,
        pipe: "date",
        filterable: true,
        filterDate: true,
        filterProp: 'fecha'
      },
      {
        headerName: 'Nro. aplicación',
        class: 'text-center',
        field: 'recaudacion',
        sortable: true,
      },
      {
        headerName: 'Nro Operación',
        field     : 'operacion',
        filterProp: 'operacion',
        sortable  : true,
        class: 'text-center',
        filterable: true,
        filterInput: true,
      },
      {
        headerName: 'RUC Cliente',
        class: 'text-center',
        field: 'cliente_ruc',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'cliente_ruc'
      },
      {
        headerName: 'Cliente',
        class: 'text-center',
        field: 'cliente_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'cliente_nombre'
      },
      {
        headerName: 'RUC Aceptante',
        class: 'text-center',
        field: 'aceptante_ruc',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'aceptante_ruc'
      },
      {
        headerName: 'Aceptante',
        class: 'text-center',
        field: 'aceptante_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'aceptante_nombre'
      },
      {
        headerName: 'Concepto de la CXC',
        class: 'text-center',
        field: 'concepto_descripcion',
        sortable: true,
      },
      {
        headerName: 'Documento de Cartera',
        class: 'text-center',
        field: 'numero_documento',
        sortable: true,
        // filterable: true,
        // filterInput: true,
        // filterProp: 'id'
        //         pipe      : 'function',
				// function : function(row, header){
        //   if (!row.documento_cartera) {
        //     return `$ 0`
        //   }

        //   if (row.monto_soles) {
        //     let number = new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(Number(row.documento_cartera))
        //     return `S/ ${number}`
        //   }
        //   else {
        //     return `$ ${Number(row.documento_cartera).toLocaleString('en-US', { minimumFractionDigits: 2 })}`
        //   }
				// },
      },
      {
        headerName: 'Monto Soles',
        class: 'text-center',
        field: 'monto_soles_doc',
        // sortable: true,
        // filterable: true,
        // filterInput: true,
        // filterProp: 'id'
                pipe      : 'function',
				function : function(row){
					let retorno;
          if(!row.monto_soles) return `S/ 0`
          let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.monto_soles))
          retorno = `S/ ${number}`
					return retorno;
				},
      },
      {
        headerName: 'Monto Dólares',
        class: 'text-center',
        field: 'monto_dolares_doc',
        // sortable: true,
        // filterable: true,
        // filterInput: true,
        // filterProp: 'id',
        pipe      : 'function',
				function : function(row){
					let retorno;
          if(!row.monto_dolares) return `$ 0`
          retorno = `$ ${Number(row.monto_dolares).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno;
				},
      },
      {
        headerName: 'Monto devuelto',
        class: 'text-center',
        field: '',
        sortable: true,
        pipe      : 'function',
        function : function(row){
					let retorno;
          if(row.monto_soles){
          let number = new Intl.NumberFormat("en-US",{minimumFractionDigits: 2}).format(Number(row.monto_soles))
          retorno = `S/ ${number}`
					return retorno;
        }else{
          retorno = `$ ${Number(row.monto_dolares).toLocaleString('en-US', {minimumFractionDigits: 2})}`
					return retorno; 
        }
				},
      },
      {
        headerName: 'Fecha de Devolución',
        class: 'text-center',
        field: 'fecha_devolucion',
        sortable: true,
        pipe: "date",
      },
    ]
  }

  loadTable(data) {

    this.rowData = functions.indextable( data )

		this.pageTableActual   = data.page_number
		this.pageTableTotal    = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages    = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  //MODIFICAR
  getReporteExedentesDevueltos({page, per_page, filtros}) {
    this.factoringService.getReporteExcedentesDevueltos(
      filtros.operacion,
      filtros.fecha__gte,
      filtros.fecha__lte,
      filtros.cliente_nombre,
      filtros.cliente_ruc,
      filtros.aceptante_nombre,
      filtros.aceptante_ruc,
      page, 
      per_page,
      ).then(res=>{
        this.loadTable(res)
    })
  }

  goToPage({page, per_page, filtros}){
    this.filtros = filtros
    this.getReporteExedentesDevueltos({page, per_page, filtros})
	}

// Descargar el exel
  async openExcelModal() {
    const result = await Swal.fire({
      title: 'Generar Excel',
      text: "Elige si deseas generar el excel de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    console.log(result)
    if (result.isDenied) {
      this.generarExcel(this.rowData)
    } else if(result.isConfirmed) {
      this.generarExcelCompleto()
    }
  }

  async generarExcelCompleto() {
    const page_size = 5000
    let page = 1
    let data = []
  
    
    this.generando = true
    while (this.generando) {
      try {
        let res = await this.factoringService.getReporteExcedentesDevueltos(
          
          this.filtros.nro_operacion ? this.filtros.nro_operacion : undefined,
          this.filtros.fecha__gte ? this.filtros.fecha__gte : undefined,
          this.filtros.fecha__lte ? this.filtros.fecha__lte : undefined,
          this.filtros.cliente_nombre ? this.filtros.cliente_nombre : undefined,
          this.filtros.cliente_ruc ? this.filtros.cliente_ruc : undefined,
          this.filtros.aceptante_nombre ? this.filtros.aceptante_nombre : undefined,
          this.filtros.aceptante_ruc ? this.filtros.aceptante_ruc : undefined,
          page, 
          page_size,
          )

        data = data.concat(res['results'])
        page++
        if (res['page_number'] == res['num_pages']) {
          this.generando = false
          this.generarExcel(data)
        }
      } catch (ex) {
        console.log(ex)
        this.generando = false
      }
    }
  }

  generarExcel(data) {

    // Se deben definir las columnas que se van a mostrar
    var header = ['ejecutivo_nombre', 'fecha', 'recaudacion','operacion','cliente_ruc','cliente_nombre','aceptante_ruc','aceptante_nombre','concepto_descripcion','numero_documento','monto_soles','monto_dolares','monto','fecha_devolucion']
    var columns = header.length - 1
 
    const wb = utils.book_new();
    var Heading = [
      ["Ejecutivo", "Fecha", "Nro. aplicación","Nro Operación","RUC Cliente","Cliente","RUC Aceptante","Aceptante","Concepto de la CXC","Documento de Cartera","Monto Soles","Monto Dólares","Monto devuelto","Fecha de Devolución"],
    ];
    
    // const ws = utils.json_to_sheet(data, { header,skipHeader: true });
    
    const ws = utils.json_to_sheet(data, { header,skipHeader: true });
    ws['!ref'] = utils.encode_range({s: {c:0,r:0},e: {c:columns, r:data.length}})
    utils.sheet_add_aoa(ws, Heading);
    utils.book_append_sheet(wb, ws, 'Hoja 1');

    writeFile(wb,`Reporte - ${new Date().toLocaleString()}.xlsx`);

   
  }
}
