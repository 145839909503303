import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgSelectModule } from '@ng-select/ng-select';

import { AplicacionEvaluacionRiesgosRoutingModule } from './aplicacion-evaluacion-riesgos-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { FactoringComponent } from './pages/factoring/factoring.component';
import { ReassignAnalystModalComponent } from './modals/reassign-analyst-modal/reassign-analyst-modal.component';
import { FactoringComiteComponent } from './pages/factoring-comite/factoring-comite.component';
import { VerificationComponent } from './pages/verification/verification.component';
import { DocsModalComponent } from './modals/docs-modal/docs-modal.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AddCommentsModalComponent } from './modals/add-comments-modal/add-comments-modal.component';
import { BandejaPagosComponent } from './pages/bandeja-pagos/bandeja-pagos.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { EstacionesOperacionModalComponent } from './modals/estaciones-operacion-modal/estaciones-operacion-modal.component';
import { EliminarOperacionModalComponent } from './modals/eliminar-operacion-modal/eliminar-operacion-modal.component';
import { BandejaDenegadosComponent } from './pages/bandeja-denegados/bandeja-denegados.component';
import { DevolverOperacionModalComponent } from './modals/devolver-operacion-modal/devolver-operacion-modal.component';
import { DesembolsoModalComponent } from './modals/desembolso-modal/desembolso-modal.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    FactoringComponent,
    ReassignAnalystModalComponent,
    FactoringComiteComponent,
    VerificationComponent,
    DocsModalComponent,
    AddCommentsModalComponent,
    BandejaPagosComponent,
    EstacionesOperacionModalComponent,
    EliminarOperacionModalComponent,
    BandejaDenegadosComponent,
    DevolverOperacionModalComponent,
    DesembolsoModalComponent
  ],
  entryComponents: [
    ReassignAnalystModalComponent,
    DocsModalComponent,
    AddCommentsModalComponent,
    DesembolsoModalComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AplicacionEvaluacionRiesgosRoutingModule,
    NgxSkeletonLoaderModule,
    NgxDatatableModule,
    NgSelectModule,
    SharedModule,
    UiSwitchModule.forRoot({
      size: 'medium',
      color: '#3e50b4',
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: '#6c757d',
      defaultBoColor: '#3e50b4',
      checkedLabel: 'Si ',
      uncheckedLabel: 'No'
    }),
    NgxMaskModule.forRoot(maskConfig),
    BsDatepickerModule.forRoot(),
    FontAwesomeModule
  ]
})
export class AplicacionEvaluacionRiesgosModule { }
