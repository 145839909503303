import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { faTimes, faUserTie } from '@fortawesome/free-solid-svg-icons';
@Component({
  selector: 'app-modal-confir-correos',
  templateUrl: './modal-confir-correos.component.html',
  styleUrls: ['./modal-confir-correos.component.css']
})
export class ModalConfirCorreosComponent implements OnInit {
  faUserTie = faUserTie;
  faTimes            = faTimes;

  @Input() seleccionRecordatorios;
  correos: any[] = [];
  @Input() seleccionAgrupada: any[];
  selections: any[];
  textoasunto: any = 'DOCUMENTOS CON VENCIMIENTO “Fecha de vencimiento”: “Cliente” – “Aceptante”';
  constructor(
    private modalService: NgbModal,
    public documentosCarteraService: DocumentosCarteraService,
    public activeModal: NgbActiveModal,
    public factoring: FactoringService
  ) { }

  ngOnInit(): void {
    this.factoring.obtenerResponsables('','true',1,1000).then((res: any)=>{
      this.correos = res.results
      console.log(this.seleccionRecordatorios)
    })
  }

  cerrar(){
    this.activeModal.dismiss()
  }

  customSearchFn(term: string, item) {
    term = term.toLowerCase();
    let searchEmail = item.email.toString().toLowerCase().includes(term)
    let searchNombre = item.nombre.toString().toLowerCase().includes(term);
    return searchEmail || searchNombre
  }

  addTagFn(name) {
    return { correo: name, nombre: name, tag: true };
  }

  compareWith(item, selected) {
    return item.id === selected.id
  }

  onSubmitButton(){

  }

  generarRecordatorios(){
    this.documentosCarteraService.enviarRecordatorios({ documentos_cartera: this.seleccionRecordatorios.map( item => item.id ) }).then((res: any) => {
      // this.selectionRows = []
      // this.enableSelectable()
    })

  }

}
