import { Component, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { LavadoActivosService } from 'app/core/services/lavado_activos/lavado_activos.service';
import { ManageConfiguracionModalComponent } from './modals/manage/manage-configuracion-modal.component'
import { BaseLavadoActivosComponent } from '../../base-lavado-activos-component'
import { AuthService } from "app/core/services/auth/auth.service";

@Component({
    selector: 'app-configuracion_variables_riesgo',
    templateUrl: './configuracion_variables_riesgo.component.html',
    //styleUrls: ['./alertas.component.css']
})
export class ConfiguracionVariablesRiesgoComponent extends BaseLavadoActivosComponent {
  public editModal = ManageConfiguracionModalComponent
  public requestService = (filters) => this.lavadoActivosService.obtenerTipoPlantillasRiesgo(filters)
  constructor(
    public modalService: NgbModal,
    private lavadoActivosService: LavadoActivosService,
    public authService: AuthService
  ) {super(authService, modalService)}

  initVars() {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        headerName: "Descripción",
        field: "descripcion",
        sortable: true,
      },
      
    ];

    this.columnDefs = columns;
  }
}
