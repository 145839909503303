import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { AdminLayoutComponent } from 'app/layouts/admin/admin-layout.component';
import { BandejaPagosComponent } from '../aplicacion-evaluacion-riesgos/pages/bandeja-pagos/bandeja-pagos.component';
import { AddClienteComponent } from './pages/add-cliente/add-cliente.component';
import { AsignacionCarteraComponent } from './pages/asignacion-cartera/asignacion-cartera.component';
import { ClientesComponent } from './pages/clientes/clientes.component';
import { GruposEconomicosComponent } from './pages/grupos-economicos/grupos-economicos.component';


const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    data: {
      breadcrumb: 'Clientes'
    },
    children: [
      {
        path: 'clientes',
        canActivate: [AuthGuard],
        component: ClientesComponent,
        data: {
          breadcrumb: 'Clientes'
        }
      },
      {
        path: 'add-clientes',
        canActivate: [AuthGuard],
        component: AddClienteComponent,
        data: {
          breadcrumb: 'Registrar Cliente'
        }
      },
      {
        path: 'clientes/:id',
        canActivate: [AuthGuard],
        component: AddClienteComponent,
        data: {
          breadcrumb: 'Editar Cliente'
        }
      },
      {
        path: 'ordenes-pago',
        canActivate: [AuthGuard],
        component: BandejaPagosComponent,
        data: {
            breadcrumb: 'Ordenes de Pago'
        }
      },
      {
        path: 'grupos-economicos',
        canActivate: [AuthGuard],
        component: GruposEconomicosComponent,
        data: {
            breadcrumb: 'Grupos Económicos'
        }
      },
      {
        path: 'clientes-asignacion-cartera',
        canActivate: [AuthGuard],
        component: AsignacionCarteraComponent,
        data: {
            breadcrumb: 'Asignación de cartera'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ClientesRoutingModule { }
