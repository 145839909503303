import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { VerificationsService } from 'app/core/services/factoring/verifications.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { DocsModalComponent } from '../../modals/docs-modal/docs-modal.component';
import { functions } from '../../../../core/helpers/functions';
import { forkJoin, from } from 'rxjs';

@Component({
	selector: 'app-verification',
	templateUrl: './verification.component.html',
	styleUrls: ['./verification.component.css']
})
export class VerificationComponent implements OnInit {

	public rowData: any[] = [];
	public loadingTable: boolean = false;
	public columnDefs: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 10;
	public pageTablePages: number[];
	public user: any;
	public identidad: any = {};
	public searchstring: string = '';
	public canCreate: boolean = false;
	faPlus= faPlus;
	faSyncAlt = faSyncAlt;
	faChartPie = faChartPie;
	constructor(
		private authService: AuthService,
		private router: Router,
		private verificacionService: VerificationsService,
		private docService: DocschecklistService,
		public modalService: NgbModal,
		private factoringService: FactoringService,
		public toast: ToastService,
	) { }

	ngOnInit(): void {
		
		//Nueva logica
		let documents = this.verificacionService.obtenerDocumentos(1,  10, '2',  '', '',  '',  '',  '', '',  '', '');
		let estaciones = this.docService.obtenerEstaciones();
		let estados = this.verificacionService.obtenerEstados();
		let empresas = this.factoringService.obtenerEmpresas()

		forkJoin({ documents, estaciones, estados, empresas
		}).subscribe( ({ documents, estaciones, estados, empresas })=> {
			this.initVars(estados['results'], estaciones['results'], empresas['results']);
			this.loadTable(documents);
		}  )

		// Promise.all([documents, estaciones, estados, empresas]).then((res: any[]) => {
		// 	console.log(res);
		// 	let documents = res[0];
		// 	let estaciones = res[1]['results'];
		// 	let estados = res[2]['results'];
		// 	let empresas = res[3]['results'];
		// 	this.initVars(estados, estaciones, empresas);
		// 	this.loadTable(documents);
		// });

	}

	/**
	 * 
	 * @param page 
	 * @param page_size 
	 * @param estado 
	 * @param analista_operacion 
	 * @param operacion 
	 */
	//estado 2
	obtenerDocuments(page = 1, page_size = 10, estado = '' , analista_operacion = '', operacion = '', empresa = '', fecha__gte = '', fecha__lte = '', beneficiario_nombre = '', deudor_nombre__icontains = '', numero_documento__icontains = '') {

		this.verificacionService.obtenerDocumentos(page, page_size, estado ? estado : '2', analista_operacion, operacion, empresa,fecha__gte, fecha__lte, beneficiario_nombre, deudor_nombre__icontains, numero_documento__icontains)
		.then( res=>{
			this.loadTable(res);
		} )
	
	}

	loadTable(data) {

		this.rowData = functions.indextable(data)
		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

	initVars(estados = [], estaciones = [], empresas = []) {
		let columns = [
			{
				headerName: 'N°',
				field: 'index',
				sortable: true,
			},
			{
				headerName: 'Oficial de Negocios',
				field: 'oficial_negocio_nombre',
				sortable: true,
			},
			{
				headerName: 'Cliente',
				field: 'beneficiario_nombre',
				sortable: true,
				class: 'text-center',
				textField: 'beneficiario_nombre',
				filterable: true,
				filterProp: 'beneficiario_nombre',
				filterInput: true,
			},
			{
				headerName: 'RUC',
				field: 'beneficiario_ruc',
				sortable: true,
				class: 'text-center',
			},
			{
				headerName: 'Aceptante',
				field: 'aceptante',
				sortable: true,
				class: 'text-center',
				textField: 'aceptante',
				filterable: true,
				filterProp: 'aceptante',
				filterInput: true,
			},
			{
				headerName: 'RUC',
				field: 'ruc',
				sortable: true,
				class: 'text-center',
			},
			{
				headerName: 'Tipo',
				field: 'tipo_detalle_descripcion',
				sortable: true,
			},
			{
				headerName: 'Documento',
				field: 'numero_documento',
				sortable: true,
				textField: 'numero_documento',
				filterable: true,
				filterProp: 'numero_documento',
				filterInput: true,
			},
			// {
			// 	headerName: 'Fecha Documento', 
			// 	field     : 'fecha_verificacion',
			// 	pipe      : 'date',
			// 	class     : 'text-center',
			// },
			{
				headerName: 'Fecha Vencimiento',
				field: 'vencimiento',
				class: 'text-center',
				pipe: 'date',
				sortable: true,
				filterable: true,
				filterDate: true,
			},
			{
				headerName: 'Monto',
				field: 'monto_total',
				pipe: 'currency',
				moneda: 'moneda',
				sortable: true,
				class: 'text-center',
			},
			{
				headerName: 'Estado',
				field: 'estado_descripcion',
				filterProp: 'estado',
				sortable: true,
				class: 'text-center',
				filterable: true,
				filterSelectItems: estados,
				filterItemsProps: {
					value: 'id',
					label: 'descripcion'
				},
			},
			{
				headerName: 'Fecha Verificación',
				field: 'fecha_verificacion',
				pipe: 'date',
				class: 'text-center',
			},
			{
				headerName: 'Operación ',
				field: 'operacion',
				pipe: 'actionable',
				textField: 'operacion',
				class: 'text-center',
				sortable: true,
				filterable: true,
				filterProp: 'operacion',
				filterInput: true,
			},
			// {
			// 	headerName: 'Estación', 
			// 	field     : 'estacion_nombre',
			// 	sortable  : true,
			// 	filterProp: 'estacion',
			// 	class     : 'text-center',
			// 	filterable: true,
			// 	filterSelectItems: estaciones,
			// 	filterItemsProps: {
			// 		value: 'id',
			// 		label: 'nombre'
			// 	},
			// },
			{
				headerName: 'Estación',
				field: 'estacion_nombre',
				sortable: true,
				filterProp: 'estacion',
				class: 'text-center',
			},
		];

		this.columnDefs = columns;
	}

	goToPage({ page, per_page, filtros }) {

		console.log( 'FILTROS', filtros )
		this.obtenerDocuments(page, per_page, filtros.estado ? filtros.estado : '2', '', filtros.operacion, filtros.empresa, filtros.fecha__gte, filtros.fecha__lte, filtros.beneficiario_nombre, filtros.aceptante, filtros.numero_documento);

	}

	async tableEditEvent(row) {

		const operacion = await this.factoringService.obtenerOperacion(row.operacion)
		let clienteFactoring
		if (operacion['tipo_linea_solicitada'] == 2) {
			clienteFactoring = await this.factoringService.obtenerBeneficiario(operacion['beneficiario_factoring'])
		}

		console.log( 'Operacion', operacion )

		const modalRef = this.modalService.open(DocsModalComponent, {
			size: 'lg'
		});
		
		modalRef.componentInstance.row = row;
		modalRef.componentInstance.operacion = row;
		modalRef.componentInstance.legal = this.authService.perfil.isAsesorLegal || this.authService.perfil.isGerenteComercial || this.authService.perfil.isGerenteGeneral ? true : false;
		modalRef.componentInstance.clienteFactoring = clienteFactoring

		modalRef.result.then((result) => {
			console.log('Resultado del modal',result);
		}, (reason) => {
			console.log(reason);
		});

		modalRef.componentInstance.docsEmit
			.subscribe(result => {
				if (result) {
					result['operacion'] = row.operacion;
					// result['vencimiento'] = this.formatDate(result['vencimiento']);
					result['vencimiento'] = result['vencimientox'];
					// result['vencimiento_cavali'] = this.formatDate(result['vencimiento_cavali']);
					result['vencimiento_cavali'] = result['vencimiento_cavalix'];
					const request = this.factoringService.guardarDetalle(result, row.id);
					request.then(result => {
						this.toast.success('Documentos procesados correctamente');
						this.obtenerDocuments();
					})
						.catch(error => {
							console.error(error);
							this.toast.warning('Ha ocurrido un error!');
						});
				}
			},
				error => {
					console.error(error);
				});

	}

	workflowEvent(row) {
		console.log(row);
		this.router.navigate(['/operaciones', row.operacion]);
	}

	formatDate(date) {
		return date.getFullYear() +
			"-" + (date.getMonth() + 1) +
			"-" + date.getDate();
	}

}
