import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { FormatosService } from 'app/core/services/config/formatos.service';
import { LegalService } from 'app/core/services/legal.service';
import { PropuestaComercialService } from 'app/core/services/propuesta-comercial/propuesta-comercial.service';
import { ToastService } from 'app/core/services/toast/toast.service';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { FactoringService } from '../../../../../core/services/factoring/factoring.service';

@Component({
  selector: 'app-formato-contrato-marco',
  templateUrl: './formato-contrato-marco.component.html',
  styleUrls: ['./formato-contrato-marco.component.css']
})
export class FormatoContratoMarcoComponent implements OnInit {

  myForm: FormGroup;
  estaciones: any[];
  public firmantes: any[] = [];
  firmantesSelect: any[] = [];

  @Input() title;
  @Input() beneficiario: number;
  @Input() beneficiario_tipo;
  @Input() formato_codigo;
  @Input() formato_plantilla_url;
  @Input() numero_contrato;
  @Input() montoOperacion;
  @Input() monedaOperacion;
  @Input() monedaSimbolo;

  @Input() operacion;
  @Input() contrato;

  firmanteValue;
  cliente_nombre;
  nacionalidad;
  razon_social;
  cliente_documento: any;
  cliente_doc: any;
  direccion: any;
  ruc: any;
  direccion_empresa: any;
  lugar_firma: any;
  nro_doc: any;
  estadocivil: any;
  nro_partida: any;
  oficina_registral: any;
  cliente: any;
  liquidacion: any;
  linea: any;

  contactoList:any[] = [];

  constructor(
    private modalService: NgbModal,
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private formatoService: FormatosService,
    private clientesService: ClientesService,
    public toast: ToastService,
    private propuestaService  : PropuestaComercialService,
    private legalService	: LegalService,
    private factoringService : FactoringService
  ) { }

  ngOnInit(): void {
    console.log('Beneficiario', this.beneficiario);
    if (!this.contrato?.id) {
      this.toast.warning('No posee contrato');
        this.activeModal.close(false);
        return;
    }
    this.createForm();

    const clienteId = this.operacion.operacion.beneficiario_linea_id;

    // Listado de contactos de los clientes
    this.factoringService.obtenerContactos(this.beneficiario)
      .then( ({ results })=>{
        console.log( results )
        this.contactoList = results;

      }, err =>{
        console.log( err )
      })

    this.legalService.obtenerFirmantes(this.contrato.id).then(resp => {
      console.log(resp);
      this.firmantes = resp['results'];
    }, err => {
      console.error(err);
      
    });

    this.clientesService.obtenerCliente(clienteId).then((cliente: any) => {
      
      this.cliente = cliente;
      this.razon_social = cliente.nombre == undefined ? '' : cliente.nombre;
      this.ruc = cliente.ruc == undefined ? '' : cliente.ruc;
      this.direccion_empresa = cliente.domicilio_legal == undefined ? '' : cliente.domicilio_legal;
      this.oficina_registral = cliente.oficina_registral_nombre == undefined ? '' : cliente.oficina_registral_nombre;
      this.nro_partida = cliente.numero_partida == undefined ? '' : cliente.numero_partida;

    });

    this.propuestaService.ObtenerSimulacionDeLiquidacion(this.operacion.id).then(res => {
      this.liquidacion = res;
    }, err => {
      console.error(err);
      
    });

    this.clientesService.obtenerLinea(this.operacion.linea_credito).then(resp => {
      console.log(resp);
      this.linea = resp;
    }, err => {
      console.error(err);
    });

  }

  createForm() {

    this.myForm = this.formBuilder.group({
      firmante: [null, []],
      contacto: [null, [ Validators.required ]],
      emision: [{ value: new Date(), disabled: true }, Validators.required],
      // glosa: [{ value: '', disabled: true }],
      // nro_documento: [{ value: '', disabled: true }, Validators.required],
      // vcto_firma: [{ value: null, disabled: true }, Validators.required],
      // lugar_firma: [{ value: '', disabled: true }, Validators.required],
      beneficiario_tipo: [{ value: this.beneficiario_tipo == undefined ? 'J' : this.beneficiario_tipo, disabled: true }, Validators.required]
    });

  }

  onSubmit() {
 
    if (this.myForm.valid) {

      // var fecha = new Date(this.myForm.get("vcto_firma").value);
      // var hoy = new Date();

      // if (hoy > fecha) {
      //   this.toast.warning("Firma expirada");
      //   return;
      // }

      if (!this.firmantes.length) {
        this.toast.warning('No posee firmantes');
        this.activeModal.close(false);
        return;
      }

      let firmantes = this.firmantes.map(f => {
        return {
          id              : f.id,
          dni             : f.nro_documento,
          estado_civil    : f.estado_civil,
          nombre_completo : f.firmante_nombre,
          tipo_documento  : f.tipo_documento,
          sexo            : f.sexo,
          gentilicio      : f.gentilicio,
          lugar_firma     : f.lugar_firma
        }
      });

      const { nombre, email, telefono, tipo_documento, nro_documento } = this.myForm.get('contacto').value;

      // New Data Format
      let data = {
        "tipo_producto"     : this.operacion.operacion.tipo_linea_solicitada.toString(),
        "razon_social"      : this.razon_social,
        "beneficiario_tipo" : this.myForm.get("beneficiario_tipo").value,
        "ruc"               : this.ruc,
        "direccion_empresa" : this.direccion_empresa,
        "nro_partida"       : this.nro_partida,
        "oficina_registral" : this.oficina_registral,
        "monto"             : this.montoOperacion,
        "contrato"          : this.numero_contrato,
        "moneda"            : this.linea.moneda.toString(),
        "tipo_cambio"       : this.operacion.tipo_cambio_monto,
        "simbolo"           : this.monedaSimbolo,
        "fecha_actual"      : this.myForm.get("emision").value,
        "domicilio_legal"   : this.direccion_empresa,
        "distrito"          : this.cliente.distrito_nombre,
        "provincia"         : this.cliente.provincia_nombre,
        "departamento"      : this.cliente.departamento_nombre,
        "email"             : this.cliente.email,
        "telefono"          : this.cliente.telefono_contacto,
        "firmantes"         : firmantes,
        "contacto_nombre"   : nombre,
        "contacto_telefono" : telefono,
        "contacto_email"    : email,
        "contacto_tipo_documento": tipo_documento,
        "contacto_nro_documento" : nro_documento,
        "zona_registral"    : this.cliente.zona_registral_nombre ? this.cliente.zona_registral_nombre : ''
      };
      
      console.log( 'Enviar data', data);

      this.formatoService.generarFormatoContratoMarco(data).then(
        (x: any) => {
          console.log(x);
          var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          var downloadURL = window.URL.createObjectURL(blob);
          var link = document.createElement('a');
          link.href = downloadURL;
          link.download = "Contrato Marco.docx";
          link.click();
          this.activeModal.close(data);
        }, async (reason) => {
          this.activeModal.close(data);
          console.log(reason);
          const message = JSON.parse(await reason.error.text());
          console.log(message);
          this.showModalErrorContrato(message['detail']);
        }), err => {
          console.error(err);
          
        };
    }
    else {
      this.toast.warning("El formulario no está completo.");
    }
  }

  showModalErrorContrato(message) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {

    });

    modalRef.componentInstance.showBottomLeft = false;
    modalRef.componentInstance.title = 'Información requerida en el contrato marco';
    modalRef.componentInstance.titleRightBottom = 'Aceptar';
    modalRef.componentInstance.messageHTML = true;
    modalRef.componentInstance.message = `<span class="">${message}</span>`;
  }

  changeFirmantes(e) {
    console.log(e);
    this.firmantesSelect = e;
  }

}
