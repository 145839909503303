import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FacturacionService } from 'app/core/services/facturacion/facturacion.service';

@Component({
  selector: 'app-manage-facturacion-series',
  templateUrl: './manage-facturacion-series.component.html',
  styleUrls: ['./manage-facturacion-series.component.css']
})
export class ManageFacturacionSeriesComponent implements OnInit {

  myForm: FormGroup;
  @Input() serieId = 0;
  serie: any;
  faTimes = faTimes;
  tipos: any[] = [];
  origenes:any[] = [];

  @Output() successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public formbuild            : FormBuilder,
    public activeModal          : NgbActiveModal,
    private facturacionService  : FacturacionService,
  ) { 
    this.initForm();
  }

  ngOnInit(): void {
    this.obtenerTipos();
    this.obtenerOrigenProceso();
    if (this.serieId) {
      this.obtenerSerie();
    }
  }

  initForm() {
    this.myForm = this.formbuild.group({
      tipo  : [null, [Validators.required]],
      serie : [null, [Validators.required]],
      numero: [null, [Validators.required]],
      proceso: [null, [Validators.required]]
    });
  }
  
  setForm(item) {
    for (const key in item) {
      if (this.myForm.controls[key]) {
        this.myForm.controls[key].setValue(item[key]);
      }
    }
  }

  obtenerTipos() {
    this.facturacionService.obtenerTipos().then((resp) => {

      this.tipos = resp['results'];
    }, err => {
      console.error(err);
      
    });
  }

  obtenerOrigenProceso(){

    this.facturacionService.obtenerOrigen().then( (resp)=>{
      
      this.origenes = resp['results'];

    }, err => {
      console.error(err);
      
    });

  }

  obtenerSerie() {
    this.facturacionService.obtenerSerie(this.serieId).then((resp) => {
      this.serie = resp;
      this.setForm(resp);
    }, err => {
      console.error(err);
      
    });
  }

  onSubmit() {
    if (this.myForm.invalid) {
      return;
    }
    this.facturacionService.guardarSerie(this.myForm.value, this.serieId)
      .then((res: any) => {
        this.successEmit.emit();
        this.activeModal.close(false);
      }).
      catch(error => {
        this.activeModal.close(false);
      });
  }

}
