import { Component, OnInit, ViewChild } from "@angular/core";
import { faChartPie, faPlus, faSyncAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { FactoringService } from "app/core/services/factoring/factoring.service";
import { RecaudacionService } from "app/core/services/factoring/recaudacion.service";
import { columnHeader } from "app/shared/factoring-datatable/factoring-datatable.component";
import { AddRecaudacionModalComponent } from "../../modals/add-recaudacion-modal/add-recaudacion-modal.component";
import { functions } from '../../../../core/helpers/functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { concat, from } from 'rxjs';
import * as XLSX from 'xlsx'
import { DescartarAplicacionComponent } from "../../modals/descartar-aplicacion/descartar-aplicacion.component";

@Component({
  selector: "app-recaudacion",
  templateUrl: "./recaudacion.component.html",
  styleUrls: ["./recaudacion.component.css"],
})
export class RecaudacionComponent implements OnInit {
  private modalConfirmDeleteReference: any;
  private modalRef: any;
  public rowData: any[] = [];
  public estados: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public searchstring: string = "";
  public canDelete: boolean = true;
  public toDelete: number;
   faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  faTimes = faTimes;
  exportando = false
  tblFiltros = {}

  form_fecha_contable_anulacion: FormGroup;
  maxFechaContable = new Date()
  aplicacionesEnlazadas = []
  mostrarAplicacionesEnlazadas = false

  @ViewChild("e_unabledelete", { static: false }) public e_unabledelete;
  @ViewChild("e_confirmdelete", { static: false }) public e_confirmdelete;

  constructor(
    private authService: AuthService,
    public modalService: NgbModal,
    private recaudacionService: RecaudacionService,
    private factoringService: FactoringService,
    private form: FormBuilder
  ) {
    this.user = this.authService.user;
  }

  ngOnInit(): void {
    if (!this.canAdd) {
      this.canDelete = false
    }
    this.initform();
    this.obtenerRecaudaciones();
  }

  initform(){

    this.form_fecha_contable_anulacion = this.form.group({
      fecha_contable_anulacion: [ new Date(), [ Validators.required ] ]
    })

  }

  /**
   *
   * @param page
   * @param page_size
   * @param pagador_nombre
   * @param tipo_pagador
   * @param forma_pago
   * @param numero_pago
   * @param estado
   * @param banco
   */
  obtenerRecaudaciones(
    page = 1,
    page_size = 10,
    pagador_nombre = "",
    tipo_pagador = "",
    forma_pago = "",
    numero_pago = "",
    estado = "2",
    banco = "",
    monto__range = ""
  ) {
    let documents = this.recaudacionService.obtenerDocumentos(
      page,
      page_size,
      pagador_nombre,
      tipo_pagador,
      forma_pago,
      numero_pago,
      estado,
      banco,
      monto__range
    );
    let tiposPagador = this.recaudacionService.obtenerTiposPagador();
    let formasPago = this.recaudacionService.obtenerFormasPago({ordering: 'descripcion'});
    let estadosRecaudacion = this.recaudacionService.obtenerEstadosRecaudacion();
    let bancosRecaudacion = this.recaudacionService.obtenerBancosRecaudacion();
    let empresas = this.factoringService.obtenerEmpresas();

    Promise.all([
      documents,
      tiposPagador,
      formasPago,
      estadosRecaudacion,
      bancosRecaudacion,
      empresas,
    ]).then((res: any[]) => {
      let documents = res[0];
      let tiposPagador = res[1]["results"];
      let formasPago = res[2]["results"];
      let estadosRecaudacion = res[3]["results"];
      let bancosRecaudacion = res[4]["results"];
      let empresas = res[5]["results"];

      this.estados = estadosRecaudacion;
      this.initVars(
        tiposPagador,
        formasPago,
        estadosRecaudacion,
        bancosRecaudacion,
        empresas
      );
      this.loadTable(documents);
    });
  }

  loadTable(data) {
    this.rowData = data.results;
    this.pageTableActual = data.page_number;
    this.pageTableTotal = data.count;
    this.pageTablePerPages = data.per_page;
    this.pageTablePages = [];
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index);
    }
  }

  initVars(
    tiposPagador = [],
    formasPago = [],
    estadosRecaudacion = [],
    bancosRecaudacion = [],
    empresas = [],
  ) {
    let columns = [
      {
        headerName: "N°",
        field: "id",
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'id',
      },
      {
        class: "text-center",
        headerName: "Pagador",
        field: "pagador_nombre",
        sortable: true,
        filterable: true,
        filterProp: "pagador_nombre",
        filterInput: true,
      },
      {
        class: "text-center",
        headerName: "Tipo Pagador",
        field: "tipo_pagador_nombre",
        sortable: true,
        filterable: true,
        filterProp: "tipo_pagador",
        filterSelectItems: tiposPagador,
        filterItemsProps: {
          value: "id",
          label: "descripcion",
        },
      },
      {
        class: "text-center",
        headerName: "Fecha Pago",
        field: "fecha_pago",
        pipe: "date",
        sortable: true,
        filterable: true,
        filterProp: 'fecha_pago',
        filterDate: true,
      },
      {
        class: "text-center",
        headerName: "Fecha Registro",
        field: "fecha_registro",
        pipe: "date",
        sortable: true,
        filterable: true,
        filterProp: 'fecha_registro',
        filterDate: true,
      },
      {
        headerName: 'Moneda',
        field: 'moneda_descripcion',
        class: 'text-center',
        pipe: 'function',
        function: row => row.moneda == 1 ? 'S/' : '$',
        filterable: true,
        filterProp: 'moneda',
        filterSelectItems: [
          {id: 1, descripcion: 'Soles'},
          {id: 2, descripcion: 'Dólares'},
        ],
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        sortable: true,
        filterable: true,
        filterRange: true
      },
      {
        class: "text-center",
        headerName: "Banco",
        field: "banco_nombre",
        sortable: true,
        pipe: 'function',
        function: row => `${row['cuenta_codigo']} - ${row['banco_nombre']}`,
        filterable: true,
        filterProp: "banco",
        filterSelectItems: bancosRecaudacion,
        filterItemsProps: {
          value: "id",
          label: "nombre",
        },
      },
      {
        headerName: "Nro operación",
        field: "operaciones",
        sortable: true,
        class: "text-center",
        pipe: 'function',
        function: row => row['operaciones'].length > 1 ? 'Varios' : row['operaciones'][0],
        filterable: true,
        filterProp: "operaciones",
        filterInput: true,
      },
      {
        headerName: "Medio de Pago",
        field: "forma_pago_descripcion",
        sortable: true,
        class: "text-center",
        filterable: true,
        filterProp: "forma_pago",
        filterSelectItems: formasPago,
        filterItemsProps: {
          value: "id",
          label: "descripcion",
        },
      },
      {
        headerName: "Estado",
        field: "estado_descripcion",
        sortable: true,
        class: "text-center",
        filterable: true,
        filterProp: "estado",
        filterSelectItems: estadosRecaudacion.concat([{id: -1, descripcion: 'Pendiente aprobar'}]),
        filterItemsProps: {
          value: "id",
          label: "descripcion",
        },
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {
    const range = (filtros.valueFrom && filtros.valueTo) ? `${filtros.valueFrom},${filtros.valueTo}` : '';
    console.log(page, per_page, filtros);
    // this.obtenerRecaudaciones(
    //   page,
    //   per_page,
    //   filtros.pagador_nombre,
    //   filtros.tipo_pagador,
    //   filtros.forma_pago,
    //   filtros.numero_pago,
    //   filtros.estado,
    //   filtros.banco,
    //   range
    // );

    // Filtro por estado "Pendiente de aprobar" que no es un estado
    if (filtros?.estado == -1) {
      delete filtros.estado
      filtros['aplicacion_terceros'] = 1
      filtros['estado'] = 1
    }

    this.tblFiltros = {
      page,
      per_page,
      pagador_nombre: filtros.pagador_nombre,
      tipo_pagador: filtros.tipo_pagador,
      forma_pago: filtros.forma_pago,
      numero_pago: filtros.numero_pago,
      estado: filtros.estado,
      banco: filtros.banco,
      monto__range: range,
      empresa: filtros.empresa,
      id: filtros.id,
      queryParams: {
        'operaciones': filtros.operaciones || '',
        'fecha_pago__gte': (filtros.fecha_pago__gte || '').split(' ')[0],
        'fecha_pago__lte': (filtros.fecha_pago__lte || '').split(' ')[0],
        'fecha_registro__gte': filtros.fecha_registro__gte || '',
        'fecha_registro__lte': filtros.fecha_registro__lte || '',
        'moneda': filtros.moneda || '',
        'aplicacion_terceros': filtros.aplicacion_terceros || '',
      }
    }
    let documents = this.recaudacionService.obtenerDocumentos(
      this.tblFiltros['page'],
      this.tblFiltros['per_page'],
      this.tblFiltros['pagador_nombre'],
      this.tblFiltros['tipo_pagador'],
      this.tblFiltros['forma_pago'],
      this.tblFiltros['numero_pago'],
      this.tblFiltros['estado'],
      this.tblFiltros['banco'],
      this.tblFiltros['monto__range'],
      this.tblFiltros['empresa'],
      this.tblFiltros['id'],
      this.tblFiltros['queryParams'],
    );
    documents.then(res => {
      this.loadTable(res);
    }, err => {
      console.error(err);

    })
  }

  addRecaudacion() {
    this.modalRef = this.modalService.open(AddRecaudacionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
      windowClass: "modalSizeXXL"
    });

    this.modalRef.componentInstance.recaudacion = null;

    this.modalRef.result.then((result) => {}).catch((reason) => {}).then(async () => {
      let recaudacion = this.modalRef.componentInstance.recaudacion
			if (recaudacion && recaudacion.id) {
        if (recaudacion.estado == 1) { // REGISTRADO
          let modalDescartarAplicacion = this.modalService.open(DescartarAplicacionComponent, {
            size: 'md',
            beforeDismiss() {
              return false
            },
          })
          modalDescartarAplicacion.componentInstance.recaudacion = recaudacion
          await modalDescartarAplicacion.result
        }
				this.obtenerRecaudaciones();
			}
    });

  }

  tableEditEvent(row: any) {
    this.modalRef = this.modalService.open(AddRecaudacionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
      windowClass: "modalSizeXXL"
    });

    this.modalRef.componentInstance.recaudacionId = row.id;

    this.modalRef.result.then((result) => {}).catch((reason) => {}).then(async () => {
      let recaudacion = this.modalRef.componentInstance.recaudacion
			if (recaudacion && recaudacion.id) {
        if (recaudacion.estado == 1) { // REGISTRADO
          let modalDescartarAplicacion = this.modalService.open(DescartarAplicacionComponent, {
            size: 'md',
            beforeDismiss() {
              return false
            },
          })
          modalDescartarAplicacion.componentInstance.recaudacion = recaudacion
          await modalDescartarAplicacion.result
        }
				this.obtenerRecaudaciones();
			}
    });

  }

  tableDeleteEvent(row: any) {
    const estadoRecaudacion = row.estado;
    if (estadoRecaudacion == 3) {
      return
    }
    if (this.user.perfil === 13 && estadoRecaudacion !== 1) {
      this.modalService.open(this.e_unabledelete, {
        size: "md",
      });
    } else {
      this.toDelete = row.id;
      this.form_fecha_contable_anulacion.controls.fecha_contable_anulacion.setValue(new Date())
      this.recaudacionService.spinner.show()
      this.recaudacionService.obtenerAplicacionesEnlazadas(row.id).subscribe(
        (data: any) => {
          this.aplicacionesEnlazadas = data
          this.mostrarAplicacionesEnlazadas = this.aplicacionesEnlazadas.filter(e => e.recaudacion != this.toDelete).length > 0
          this.modalConfirmDeleteReference = this.modalService.open(
            this.e_confirmdelete,
            {
              size: "md",
            }
          );
        },
        res => console.log(res)
      ).add(() => this.recaudacionService.spinner.hide())
    }
  }

  formatDate(date) {
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1) +
      "-" +
      date.getDate()
    );
  }

  confirmDelete() {

    if( this.form_fecha_contable_anulacion.invalid ){
      return;
    }
    

    const recaudacionId = this.toDelete;
    const { id, description } = this.estados.find((el) => el.id === 3);
    const data = { estado: id, estado_descripcion: description, fecha_contable_cancelacion: this.formatDate(this.form_fecha_contable_anulacion.controls.fecha_contable_anulacion.value) };

    this.recaudacionService
      .actualizarEstadoRecaudacion(recaudacionId, data)
      .then(() => {
        this.modalConfirmDeleteReference.close();
        this.toDelete = null;
        this.obtenerRecaudaciones();
      });
  }

  cancelDelete() {
    this.toDelete = null;
    this.modalConfirmDeleteReference.close();
  }

  get canAdd() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilAsistenteCobranzasID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  colorRow(row) {
    return row['estado'] == 1 && row['aplicacion_terceros'] ? '#0cc4' : ''
  }

  async exportarDataTabla() {
    const lote = 100
    let dataTabla = []

    this.exportando = true
    try {
      let data: any = await this.recaudacionService.obtenerDocumentos(
        1,
        1,
        this.tblFiltros['pagador_nombre'],
        this.tblFiltros['tipo_pagador'],
        this.tblFiltros['forma_pago'],
        this.tblFiltros['numero_pago'],
        this.tblFiltros['estado'],
        this.tblFiltros['banco'],
        this.tblFiltros['monto__range'],
        this.tblFiltros['empresa'],
        this.tblFiltros['id'],
        this.tblFiltros['queryParams'],
      )
      
      let pages = Math.ceil(data['count'] / lote)

      for (let i = 0; i < pages; i++) {
        data = await this.recaudacionService.obtenerDocumentos(
          i+1,
          lote,
          this.tblFiltros['pagador_nombre'],
          this.tblFiltros['tipo_pagador'],
          this.tblFiltros['forma_pago'],
          this.tblFiltros['numero_pago'],
          this.tblFiltros['estado'],
          this.tblFiltros['banco'],
          this.tblFiltros['monto__range'],
          this.tblFiltros['empresa'],
          this.tblFiltros['id'],
          this.tblFiltros['queryParams'],
        )
        data = data.results.map(x => {
          let row = {}
          for (let header of this.columnDefs) {
            row[header['headerName']] = x[header['field']]
            if (header['field'] == 'operaciones') {
              row[header['headerName']] = x[header['field']].join(', ')
            }
          }
          return row
        })
        dataTabla = dataTabla.concat(data)
      }
      let ws = XLSX.utils.json_to_sheet(dataTabla)
      let wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Aplicaciones')
      XLSX.writeFile(wb, 'Reporte aplicaciones.xlsx')
    } catch (ex) {
      console.log(ex)
    } finally {
      this.exportando = false
    }
  }
}
