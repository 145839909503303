import { Component, OnInit } from '@angular/core';
import { faFistRaised, faLongArrowAltLeft, faLongArrowAltRight, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators} from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";

@Component({
  selector: 'app-modal-zona-riesgo',
  templateUrl: './modal-zona-riesgo.component.html',
  styleUrls: ['./modal-zona-riesgo.component.css']
})
export class ModalZonaRiesgoComponent implements OnInit {
  faTimes                          = faTimes;
  faFistRaised                     = faFistRaised;
  faLongArrowAltRight              = faLongArrowAltRight;
  faLongArrowAltLeft               = faLongArrowAltLeft;
  public instance_row: any;
  public zona_riesgo_id       : any;
  public departamentos        : any[] = [];
  public variablesRiesgo           : any[] = [];
  public disponibles = [];
  public asignados = [];
  public filtroDisponibles = '';
  public filtroAsignados = '';

  constructor(
    public activeModal          : NgbActiveModal,
    private lavadoActivosService: LavadoActivosService,
    private clientesService       : ClientesService,
  ) { }

  ngOnInit(): void {
    this.zona_riesgo_id = this.instance_row.id
    this.obtenerDepartamentos()
  }

  obtenerDepartamentos(page = 1, page_size = 99) {
    let zonas_riesgo_disponibles = this.clientesService.obtenerDepartamentosFactoring();
    let zonas_riesgo_asignados = this.clientesService.obtenerDepartamentosFactoring(this.zona_riesgo_id)
    Promise.all([zonas_riesgo_asignados, zonas_riesgo_disponibles]).then((res: any[]) => {
      this.asignados = res[0].results
      this.disponibles = this.validarDisponibles(res[1].results)
    });

  }

  eliminarTildes(texto: string): string {
    return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  }

  filtrarDisponibles(): any[] {
    let filtro = this.eliminarTildes(this.filtroDisponibles.toLowerCase());
    return this.disponibles.filter(e => this.eliminarTildes(e.nombre.toLowerCase()).includes(filtro));
  }
  
  filtrarAsignados(): any[] {
    let filtro = this.eliminarTildes(this.filtroAsignados.toLowerCase());
    return this.asignados.filter(e => this.eliminarTildes(e.nombre.toLowerCase()).includes(filtro));
  }

  moverADisponibles(index: number): void {
    const elemento = this.asignados.splice(index, 1)[0];
    this.disponibles.unshift(elemento);
  }

  moverAAsignados(index: number): void {
    const elemento = this.disponibles.splice(index, 1)[0];
    this.asignados.unshift(elemento);
  }
  validarDisponibles(variables: any[]) {
    const asignados = this.asignados.map(value => value.id);
    return variables.filter(value => !asignados.includes(value.id));
  }

 
  async actualizarTipoPlantilla(){
    let data = {
      id: this.zona_riesgo_id,
      departamentos: []
    };
    this.asignados.forEach(element => {
      var id = element.id;
      data['departamentos'].push(id)
    });
    await this.lavadoActivosService
    .pathZonasRiesgo(data);
  }
}
