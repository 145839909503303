import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "app/core/guards/auth.guard";
import { AdminLayoutComponent } from "../../layouts/admin/admin-layout.component";
import { AceptantesComponent } from "./pages/aceptantes/aceptantes.component";
import { AddAceptanteComponent } from "./pages/add-aceptante/add-aceptante.component";
import { AceptantesSinRiesgosComponent } from "./pages/aceptantes-sin-riesgos/aceptantes-sin-riesgos.component";

const routes: Routes = [
  {
    path: "",
    component: AdminLayoutComponent,
    data: {
      breadcrumb: "Aceptantes",
    },
    children: [
      {
        path: "aceptantes",
        canActivate: [AuthGuard],
        component: AceptantesComponent,
        data: {
          breadcrumb: "Aceptantes",
        },
      },
      {
        path: "aceptantes-sin-riesgos",
        canActivate: [AuthGuard],
        component: AceptantesSinRiesgosComponent,
        data: {
          breadcrumb: "Aceptantes curso normal",
        },
      },
      {
        path: "add-aceptante",
        canActivate: [AuthGuard],
        component: AddAceptanteComponent,
        data: {
          breadcrumb: "Registrar Aceptante",
        },
      },
      {
        path: "aceptante/:id",
        canActivate: [AuthGuard],
        component: AddAceptanteComponent,
        data: {
          breadcrumb: "Editar Aceptante",
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AceptantesRoutingModule {}
