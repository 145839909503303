import { Component, Input, OnInit } from '@angular/core';
import { faCloudUploadAlt, faFileDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientesService } from 'app/core/services/clientes/clientes.service';
import { DocumentsService } from 'app/core/services/documents/documents.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { AdjuntarFileComponent } from 'app/shared/adjuntar-file/adjuntar-file.component';
import { ConfirmModalComponent } from 'app/shared/utils/confirm-modal/confirm-modal.component';
import { AddArchivoComponent } from '../archivos/partials/add-archivo/add-archivo.component';

@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {
  @Input() clienteId = 0;
  @Input() readonly = false;
  archivos           : any [] = [];
  faFileDownload     = faFileDownload;
  faCloudUploadAlt   = faCloudUploadAlt;
	faTrash             = faTrash;

  constructor(
    public modalService: NgbModal,
    public clientes    : ClientesService,
    public functions   : SharedFunctions,
    private documentsService	: DocumentsService,
    public adjuntar    : AdjuntarFileComponent,
  ) { }

  ngOnInit(): void {
    this.buscarDocumentos()
  }

  public buscarDocumentos(){
    let archivos = this.clientes.buscarDocumentosClientes(this.clienteId)
    let documents  = this.documentsService.obtenerDocuments(1, 1000);

    Promise.all([
      archivos,
      documents
    ]).then((res: any[])=>{
      let tipoDocumentos = res[1].results
      this.archivos = res[0].results.map((item)=>{
        item.documento = tipoDocumentos.find((doc)=> doc.id == item.documento)
        return item
      })

      console.log(this.archivos)
    })
  }

  public agregarDocumento(){
    // const modalRef = this.modalService.open(AddArchivoComponent, {});

    // modalRef.componentInstance.entidad = "cliente";
    // modalRef.componentInstance.entidadId = this.clienteId;
    
    // modalRef.result.then((result) => {
    //   if (result) {
    //     this.guardarDocumento(result);
    //   }
    // });
  }

  public guardarDocumento(documento){
    const formData = new FormData();
    for(const props in documento){
      formData.append(props, documento[props]);
    }
    formData.append("peso", documento.size)
    this.clientes.adjuntarDocumentosEntidad('cliente',formData).then((res: any)=>{
      this.ngOnInit()
      console.log(res);
    })
  }

  public descargaDocumento(archivo){
    // let extension = archivo.adjunto.split('/')
    // extension.reverse()
    // let nombre = extension[0]
    // this.adjuntar._obtenerBlob(archivo.adjunto,nombre)
    // var link = document.createElement('a');
    // link.href = archivo.archivo;
    // link.download = `${nombre}.${extension[0]}`;
    // link.click();
    console.log(archivo)

    var win = window.open(archivo.adjunto, '_blank');
    win.focus();
  }

  destroyArchive(archive) {
    const modalRef = this.modalService.open(ConfirmModalComponent, {});

    modalRef.componentInstance.title = "Remover Archivo";
    modalRef.componentInstance.message =
      "¿Seguro que desea remover el archivo?";

    modalRef.result.then((result) => {
      if (result) {
        this.clientes.eliminarAdjuntoEntidad('cliente',archive.id).then((res: any)=>{
          this.ngOnInit()
        })
      }
    });
  }

  download(adjunto) {
    console.log(adjunto);
    // return;
    let urlDoc = adjunto.adjunto;
    const ultimoSlash = new URL(urlDoc).pathname;
    const adjuntoName = ultimoSlash.split('/')[ultimoSlash.split('/').length - 1];
    this.downloadFile(urlDoc, adjuntoName);
  }

  downloadFile(url, name) {
    this.clientes.downloadFile(url).then(
        (x: any) => {
        console.log(x);
        var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
        var downloadURL = window.URL.createObjectURL(blob);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = name;
        link.click();
        }, async (reason) => {
        console.log(reason);
        }), err => {
        console.error(err);
    };
  }
}
