import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreviewFileService {

  constructor() { }

  open(data, title='Archivo') {
    let blob = new Blob([data], { type: 'application/pdf' })
    let reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      let base64data = reader.result
      let win = window.open('')
      win.document.write(`
        <html>
        <header>
          <title>${title}</title>
        </header>
        <body style="margin: 0px">
          <object data="${base64data}" type="application/pdf" width="100%" height="100%">
            <p>Tu navegador no puede mostrar este archivo. Puedes descargar el archivo <a href="${base64data}">aquí</a>.</p>
          </object>
        </body>
        </html>
      `)
    }
  }
}
