import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import { utils, writeFile } from 'xlsx';
import Swal from 'sweetalert2';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { functions } from '../../../../core/helpers/functions';

@Component({
  selector: 'app-reporte-cxc',
  templateUrl: './reporte-cxc.component.html',
  styleUrls: ['./reporte-cxc.component.css']
})
export class ReporteCxcComponent implements OnInit {

  public reporteForm : FormGroup;
	public headerTable: columnHeader[];
	public pageTableActual: number;
	public pageTableTotal: number;
	public pageTablePerPages: number = 50;
	public pageTablePages: number[];
  public searchstring: string = '';

  public rowData = [];

  public estados = []
  public oficiales_negocio = []
  public empresas = []

  public tipo_pagadores = [
    {
      'id': 1,
      'descripcion': 'Aceptante'
    },
    {
      'id': 2,
      'descripcion': 'Cliente'
    }
  ]

  public lineas = []

  public generando: boolean = false;
  
  public filtros: any = {
    nro_operacion: undefined,
    cliente_nombre: undefined,
    cliente_ruc: undefined,
    deudor_nombre: undefined,
    deudor_ruc: undefined,
    tipo_pagador: undefined,
    linea: undefined,
  };

  constructor( 
    public cxcService : CuentasService,
    private factoringService: FactoringService,
    public formbuild: FormBuilder,
    public authService: AuthService
  ) {}

  ngOnInit(): void {
    this.getReporteCXC({page:1, per_page:this.pageTablePerPages, filtros:{}})
    const estados = this.cxcService.obtenerEstados()
    const tipo_pagadores = this.cxcService.obtenerTiposPagador()
    const lineas = this.factoringService.obtenerTiposDeLineas()

    Promise.all([estados, tipo_pagadores, lineas]).then(res=>{
      this.estados = res[0]['results']
      this.tipo_pagadores = res[1]['results']
      this.lineas = res[2]['results']
      this.loadTableColumns(this.estados, this.tipo_pagadores, this.lineas)
    })
  }

  loadTableColumns(estados, tipo_pagadores, lineas) {
		this.headerTable = [
			{
				headerName: 'N°',
        field: 'index',
				// pipe: 'indexcol',
				sortable: true,
			},
      {
        headerName: 'Ejecutivo',
        class: 'text-center',
        field: 'ejecutivo',
        sortable: true,
      },
      {
        headerName: 'Tipo de Producto',
        field     : 'linea',
        filterProp: 'linea',
        sortable  : true,
        class     : 'text-center',
        filterable: true,
        filterSelectItems: lineas,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Ruc',
        class: 'text-center',
        field: 'cliente_ruc',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'cliente_ruc'
      },
      {
        headerName: 'Razón Social',
        class: 'text-center',
        field: 'cliente_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'cliente_nombre'
      },
      {
        headerName: 'Ruc Deudor',
        class: 'text-center',
        field: 'deudor_ruc',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'deudor_ruc'
      },
      {
        headerName: 'Deudor Razón Social',
        class: 'text-center',
        field: 'deudor_nombre',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'deudor_nombre'
      },
      {
        headerName: 'Numero Cxc',
        class: 'text-center',
        field: 'id',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'id'
      },
      {
        headerName: 'Nro. Ope.',
        class: 'text-center',
        field: 'nro_operacion',
        sortable: true,
        filterable: true,
        filterInput: true,
        filterProp: 'nro_operacion'
      },
      {
        headerName: 'Nro. Doc.',
        class: 'text-center',
        field: 'nro_documento',
        sortable: true,
      },
      {
        headerName: 'Concepto',
        class: 'text-center',
        field: 'concepto',
        sortable: true,
      },
      {
        headerName: 'Fecha Cxc',
        class: 'text-center',
        field: 'fecha_creacion',
        sortable: true,
        pipe: "date"
      },
      {
        headerName: 'Glosa',
        class: 'text-center',
        field: 'glosa',
        sortable: true,
      },
      {
        headerName: 'Moneda',
        class: 'text-center',
        field: 'moneda',
        sortable: true,
      },
      {
        headerName: 'Monto',
        class: 'text-center',
        field: 'monto_total',
        sortable: true,
      },
      {
        headerName: 'Saldo',
        class: 'text-center',
        field: 'saldo',
        sortable: true,
      },
      {
        headerName: 'Estado',
        field     : 'estado',
        filterProp: 'estado',
        sortable  : true,
        class     : 'text-center',
        filterable: true,
        filterSelectItems: estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Fecha de pago',
        class: 'text-center',
        field: 'fecha_pago',
        sortable: true,
        pipe: "date"
      },
      {
        headerName: 'Tipo de pago',
        class: 'text-center',
        field: 'tipo_pago',
        sortable: true,
      },
      {
        headerName: 'Tipo de pagador',
        field     : 'tipo_pagador',
        filterProp: 'tipo_pagador',
        sortable  : true,
        class     : 'text-center',
        filterable: true,
        filterSelectItems: tipo_pagadores,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Exc. Soles',
        class: 'text-center',
        field: 'excedente_soles',
        sortable: true,
      },
      {
        headerName: 'Exc. Dolares ',
        class: 'text-center',
        field: 'excedente_dolares',
        sortable: true,
      },
      {
        headerName: 'Gestor de cobranzas',
        class: 'text-center',
        field: 'gestor_conbranzas',
        sortable: true,
      },
      {
        headerName: 'Teléfono Contacto',
        class: 'text-center',
        field: 'contacto_numero',
        sortable: true,
      },
      {
        headerName: 'Email Contacto',
        class: 'text-center',
        field: 'contacto_email',
        sortable: true,
      },
      {
        headerName: 'Nombre del Contacto',
        class: 'text-center',
        field: 'contacto_nombre',
        sortable: true,
      },
      {
        headerName: 'Gestión',
        class: 'text-center',
        field: 'gestion',
        sortable: true,
      },

		];
  }

  loadTable(data) {

    this.rowData = functions.indextable( data )

		this.pageTableActual = data.page_number
		this.pageTableTotal = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  getReporteCXC({page, per_page, filtros}) {
    console.log(filtros)
    this.factoringService.getReporteCXC(
      filtros.nro_operacion,
      filtros.cliente_nombre,
      filtros.cliente_ruc,
      filtros.deudor_nombre,
      filtros.deudor_ruc,
      filtros.tipo_pagador,
      filtros.estado,
      filtros.id,
      filtros.linea,
      page, 
      per_page,
      ).then(res=>{
        this.loadTable(res)
    })
  }


  goToPage({page, per_page, filtros}){
    this.filtros = filtros
    this.getReporteCXC({page, per_page, filtros})
	}

  async openExcelModal() {
    const result = await Swal.fire({
      title: 'Generar Excel',
      text: "Elige si deseas generar el excel de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    console.log(result)
    if (result.isDenied) {
      this.generarExcel(this.rowData)
    } else if(result.isConfirmed) {
      this.generarExcelCompleto2()
    }
  }

  async generarExcelCompleto2() {
    const page_size = 10000
    let page = 1
    let data = []

    this.generando = true
    while (this.generando) {
      try {
        let res = await this.factoringService.getReporteCXC(
          this.filtros.nro_operacion ? this.filtros.nro_operacion : undefined,
          this.filtros.cliente_nombre ? this.filtros.cliente_nombre : undefined,
          this.filtros.cliente_ruc ? this.filtros.cliente_ruc : undefined,
          this.filtros.deudor_nombre ? this.filtros.deudor_nombre : undefined,
          this.filtros.deudor_ruc ? this.filtros.deudor_ruc : undefined,
          this.filtros.tipo_pagador ? this.filtros.tipo_pagador : undefined,
          this.filtros.estado ? this.filtros.estado : undefined,
          this.filtros.cxc_id ? this.filtros.cxc_id : undefined,
          this.filtros.tipo_producto ? this.filtros.tipo_producto : undefined,
          page, 
          page_size,
          false // showMask
          )

        data = data.concat (res['results'] )
        page++
        if (res['page_number'] == res['num_pages']) {
          this.generando = false
          this.generarExcel2(data)
        }
      } catch (ex) {
        console.log('ocurrio un error: ')
        console.log(ex)
        this.generando = false
      }
    }
  }

  generarExcel2(data) {

    /* generate a worksheet */
    var ws = utils.json_to_sheet(this.newData( data ));

    /* add to workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hoja 1");

    /* write workbook and force a download */
    writeFile(wb, `Reporte - ${new Date().toLocaleString()}.xlsx`);
  }

  async generarExcelCompleto() {
    await this.factoringService.generarExcelReporteCXC()
    this.generando = true;

    (async () => {
        while (this.generando){
            console.log("Start")
            try {
              await this.descargarExcelCompleto()
              this.generando = false
            } catch (error) {
            }
        }
    
    })();
  }

  descargarExcelCompleto(){
    return new Promise<boolean>((resolve, rejects) => setTimeout(() => {
        this.factoringService.downloadExcelReporteCXC().then(
          (x: any) => {
            console.log(x);
            var blob = new Blob([x], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            var downloadURL = window.URL.createObjectURL(blob);
            var link = document.createElement('a');
            link.href = downloadURL;
            link.download = `Reporte CXC.xlsx`;
            link.click();
            resolve(true)
          }).catch(err => {
            rejects(false)
          })
    }, 3000))
  }

  generarExcel(data) {
    /* generate a worksheet */
    var ws = utils.json_to_sheet(this.newData(data));

    /* add to workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hoja 1");

    /* write workbook and force a download */
    writeFile(wb, `Reporte - ${new Date().toLocaleString()}.xlsx`);
  }

  newData(data){

    let newDatos = [];

    newDatos = data.map( item=>({

      cliente_ruc : item.cliente_ruc, 
      ejecutivo : item.ejecutivo, 
      cliente_nombre : item.cliente_nombre,
      deudor_ruc : item.deudor_ruc, 
      deudor_nombre : item.deudor_nombre,
      id : item.id,
      nro_operacion	 : item.nro_operacion	, 
      linea	 : item.linea	, 
      nro_documento	 : item.nro_documento	, 
      concepto	 : item.concepto	, 
      fecha_creacion	 : item.fecha_creacion	, 	
      glosa	 : item.glosa	, 
      moneda : item.moneda, 
      monto_total	 : item.monto_total	,
      saldo	 : item.saldo	, 
      estado	 : item.estado	, 
      fecha_pago	 : item.fecha_pago	, 
      tipo_pago	 : item.tipo_pago	, 
      tipo_pagador	 : item.tipo_pagador	,
      excedente_soles	 : item.excedente_soles	, 
      excedente_dolares	 : item.excedente_dolares	, 
      gestor_cobranzas	 : item.gestor_cobranzas	, 
      contacto_numero : item.contacto_numero, 
      contacto_email	 : item.contacto_email	,
      contacto_nombre	 : item.contacto_nombre	, 
      gestion : item.gestion, 
      
    }))

    return newDatos;

  }

}
