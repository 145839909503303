import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faChartPie, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/services/auth/auth.service';
import { DocschecklistService } from 'app/core/services/config/docschecklist.service';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { CuentasCobrarService } from 'app/core/services/factoring/cuentas-cobrar.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { ProrrogasService } from 'app/core/services/factoring/prorrogas.service';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { AddProrrogaModalComponent } from 'app/modules/prorrogas/modals/add-prorroga-modal/add-prorroga-modal.component';
import { AddRecaudacionModalComponent } from 'app/modules/recaudacion/modals/add-recaudacion-modal/add-recaudacion-modal.component';
import { columnHeader } from 'app/shared/factoring-datatable/factoring-datatable.component';
import Swal from 'sweetalert2';
import { utils, writeFile } from 'xlsx';
import { ModalCuentasCobrarComponent } from '../../components/modal-cuentas-cobrar/modal-cuentas-cobrar.component';

@Component({
  selector: 'app-cuentas-x-cobrar',
  templateUrl: './cuentas-x-cobrar.component.html',
  styleUrls: ['./cuentas-x-cobrar.component.css']
})
export class CuentasXCobrarComponent implements OnInit {
   faPlus = faPlus;
  faChartPie = faChartPie;
  faSyncAlt = faSyncAlt;
  public rowData: any[] = [];
  public loadingTable: boolean = false;
  public columnDefs: columnHeader[];
  public pageTableActual: number;
  public pageTableTotal: number;
  public pageTablePerPages: number = 10;
  public pageTablePages: number[];
  public user: any;
  public identidad: any = {};
  public searchstring: string = '';
  public canCreate: boolean = false;
  public tmpData: any;
  public conceptos: any[];
  public estados: any[];
  public lineas: any[];
  public tiposPagador: any[];
  public monedas: any[];
  public cliente: any = '';
  public aceptante: any = '';
  public filtros: any = {};
  @ViewChild(ModalCuentasCobrarComponent) modalCuentaCobrar: ModalCuentasCobrarComponent;

  profilesExcluded = [
    this.authService.perfil.perfilGerenciaComercialID, 
    this.authService.perfil.perfilGerenciaGeneralID, 
    this.authService.perfil.perfilOficialDeNegocioID, 
    this.authService.perfil.perfilConsultaID,
    this.authService.perfil.perfilAsistenteComercialID,
  ];

  constructor(
    private cuentaService: CuentasService,
    private cuentasCobrar: CuentasCobrarService,
    public modalService: NgbModal,
    private router: Router,
    public localService: LocalServiceService,
    public authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public configs: DocschecklistService,
    private prorrogasService: ProrrogasService,
    public sharedFuncstions: SharedFunctions,
    private factoringService: FactoringService,

  ) {
    this.loadRedirection()


    this.user = authService.user;
    this.activatedRoute.params.subscribe(param => {
      this.aceptante = param.aceptante ? param.aceptante : '';
    })
  }

  loadRedirection() {
    let data = this.localService.getJsonValue("RedirectFromJudicial")
    if (data) {
      let obj = {
        page: 1,
        per_page: 10,
        filtros: {
          cobrar_a: data.cliente_nombre ? data.cliente_nombre : '',
        }
      }

      setTimeout(() => {
        this.goToPage(obj)
        this.localService.setJsonValue("RedirectFromJudicial", null)
      }, 3000)
    }
  }

  ngOnInit(): void {
    this.obtenerCuentas();
  }



  /**
   * 
   * @param page 
   * @param page_size 
   * @param beneficiario 
   * @param deudor 
   * @param estado 
   * @param concepto 
   */
  obtenerCuentas(
    page = 1,
    page_size = 10,
    beneficiario = '',
    deudor = '',
    estado = '',
    pagador = this.aceptante ? this.aceptante : '',
    concepto = '',
    tipo_linea = '',
    fecha_creacion__range = '',
    liquidacion__operacion = '',
    tipo_proceso = ''
  ) {
    let cuentas = this.cuentaService.obtenerCuentas(
      page,
      page_size,
      beneficiario,
      deudor,
      estado,
      pagador,
      concepto,
      tipo_linea,
      fecha_creacion__range,
      liquidacion__operacion,
      tipo_proceso,
      '',
      '',
      ''
    );

    // page
    // page_size
    // beneficiario_deudor_ruc_nombre__icontains
    // deudor
    // estado
    // concepto
    // fecha_creacion__range
    // liquidacion__operacion
    // tipo_proceso
    // pagador__iconstains
    // numero_proceso
    // fecha_creacion__gte
    // fecha_creacion__lte
    // monto__range 

    let conceptos = this.cuentasCobrar.obtenerConceptos();
    let estados = this.cuentaService.obtenerEstados();
    let tiposPagador = this.cuentaService.obtenerTiposPagador()
    let monedas = this.configs.obtenerMonedas()
    let tiposProcesos = this.cuentaService.obtenerTiposProcesos();
    let empresas = this.factoringService.obtenerEmpresas();
    let lineas = this.factoringService.obtenerTiposDeLineas();

    Promise.all([conceptos, estados, tiposPagador, monedas, tiposProcesos, empresas, lineas]).then((res: any[]) => {
      let conceptos = res[0]['results'];
      conceptos.sort(function (a, b) {
        if (a.descripcion > b.descripcion) {
          return 1;
        }
        if (a.descripcion < b.descripcion) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      let estados = res[1]['results'];
      let tiposPagador = res[2]['results'];
      let monedas = res[3]['results'];
      let tiposProcesos = res[4]['results'];
      let empresas = res[5]['results'];
      let lineas = res[6]['results'];

      this.initVars(conceptos, estados, tiposPagador, monedas, tiposProcesos, empresas, lineas);

    });
  }

  loadTable(data: any) {
    console.log(data)
    this.rowData = data.results
    this.pageTableActual = data.page_number
    this.pageTableTotal = data.count
    this.pageTablePerPages = data.per_page
    this.pageTablePages = []
    for (let index = 0; index < data.num_pages; index++) {
      this.pageTablePages.push(index)
    }
  }

  initVars(conceptos, estados, tiposPagador, monedas, tiposProcesos, empresas, lineas) {
    this.conceptos = conceptos
    this.estados = estados
    this.tiposPagador = tiposPagador
    this.monedas = monedas
    this.lineas = lineas

    let columns = [
      {
        headerName: 'Nro CxC',
        field: 'id',
        sortable: true,
      },
      {
        headerName: 'Tipo de Producto',
        field: 'tipo_producto_descripcion',
        sortable: true,
        filterable: true,
        class: 'text-center',
        filterProp: 'tipo_linea',
        filterSelectItems: lineas,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Tipo ',
        field: 'tipo_pagador_descripcion',
        class: 'text-center',
        sortable: true,
      },
      {
        headerName: 'Cobrar a',
        field: 'cobrar_a',
        class: 'text-center',
        sortable: true,
        filterable: this.cliente != '' ? false : true,
        filterProp: "cobrar_a",
        filterInput: true,
      },
      {
        headerName: 'RUC ',
        field: 'cobrar_a_ruc',
        class: 'text-center',
        sortable: true,
      },
      {
        headerName: 'Proceso',
        field: 'tipo_proceso_descripcion',
        filterProp: 'proceso',
        sortable: true,
        class: 'text-center',
        filterable: true,
        filterSelectItems: tiposProcesos,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        headerName: 'Número',
        field: 'numero_proceso',
        class: 'text-center',
        sortable: true,
        textField: 'numero_proceso',
        pipe: 'actionable',
        actionableType: 'tipo_proceso',
        filterable: true,
        filterProp: 'numero_proceso',
        filterInput: true,
      },
      {
        headerName: 'Fecha Registro',
        field: 'fecha_creacion',
        pipe: 'date',
        class: 'text-center',
        sortable: true,
        filterable: true,
        filterDate: true,
      },
      {
        headerName: 'Última fecha pago',
        field: 'ultimo_pago_fecha_pago',
        pipe: 'date',
        class: 'text-center',
      },
      {
        headerName: 'Concepto',
        field: 'concepto_descripcion',
        filterProp: 'concepto',
        sortable: true,
        class: 'text-center',
        filterable: true,
        filterSelectItems: conceptos,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
      },
      {
        class: "text-center",
        headerName: "Monto",
        field: "monto",
        pipe: "currency",
        moneda: "moneda",
        filterable: true,
        filterRange: true
      },
      {
        class: "text-center",
        headerName: "IGV",
        field: "igv",
        pipe: "currency",
        moneda: "moneda",
      },
      {
        class: "text-center",
        headerName: "Total x Cobrar",
        field: "totalxcobrar",
        pipe: "currency",
        moneda: "moneda",
      },
      {
        class: "text-center",
        headerName: "Saldo x Cobrar",
        field: "saldo",
        pipe: "currency",
        moneda: "moneda",
      },
      {
        headerName: 'Estado',
        field: 'estado_descripcion',
        filterProp: 'estado',
        sortable: true,
        class: 'text-center',
        filterable: true,
        filterSelectItems: estados,
        filterItemsProps: {
          value: 'id',
          label: 'descripcion'
        },
        defaultValue: {
          id: 1,
          descripcion: 'Vigente'
        }
      },
    ];

    this.columnDefs = columns;
  }

  goToPage({ page, per_page, filtros }) {

    let fecha_creacion__gte = '';
    let fecha_creacion__lte = '';

    if (filtros.fecha__gte && filtros.fecha__lte) {
      fecha_creacion__gte = filtros.fecha__gte;
      fecha_creacion__lte = filtros.fecha__lte;
      fecha_creacion__lte = fecha_creacion__lte.replace('00:00', '23:59');
    }

    const range = (filtros.valueFrom && filtros.valueTo) ? `${filtros.valueFrom},${filtros.valueTo}` : '';

    let cuentas = this.cuentaService.obtenerCuentas(
      page,
      per_page,
      '',
      '',
      filtros.estado,
      filtros.concepto,
      filtros.tipo_linea,
      '',
      '',
      filtros.proceso,
      filtros.cobrar_a,
      filtros.numero_proceso,
      fecha_creacion__gte,
      fecha_creacion__lte,
      range,
      filtros.empresa
    );

    this.filtros = {
      estado: filtros.estado,
      concepto: filtros.concepto,
      tipo_linea: filtros.tipo_linea,
      proceso: filtros.proceso,
      cobrar_a: filtros.cobrar_a,
      numero_proceso: filtros.numero_proceso,
      fecha_creacion__gte,
      fecha_creacion__lte,
      range
    }

    cuentas.then((res: any) => {
      this.loadTable(res)
    }).catch(error => {
      console.error(error);
    });

  }

  crear() {
    this.modalCuentaCobrar.initComponent(
      null,
      null,
      null,
      this.conceptos,
      this.estados,
      this.tiposPagador,
      this.monedas)
  }

  tableEditEvent(cuenta: any = this.tmpData) {
    let { tipo_proceso, recaudacion } = cuenta
    let cuentaData = this.cuentaService.obtenerCuenta(cuenta.id)
    let comentarios = this.cuentaService.obtenerComentariosCuentaCobrar(cuenta.id)
    let autodebitos
    let recaudaciones
    if (tipo_proceso == 4) {
      autodebitos = this.cuentaService.obtenerBandejaRecaudoRecaudos(recaudacion)
    }
    
    recaudaciones = this.cuentaService.obtenerBandejaRecaudo(cuenta.id)

    console.log(recaudaciones)

    this.tmpData = cuenta

    Promise.all([
      cuentaData,
      comentarios,
      recaudaciones,
      autodebitos,
    ]).then((res: any[]) => {

      let cuentaData = res[0];
      let comentarios = res[1];
      let recaudaciones = res[2];
      let autodebitos = res[3];

      if (autodebitos) {
        recaudaciones.results = recaudaciones?.results.concat(autodebitos?.results.find(el=>el.operacion_detalle == cuentaData.operacion_detalle))
      }
      this.modalCuentaCobrar.initComponent(
        cuentaData,
        comentarios,
        recaudaciones,
        this.conceptos,
        this.estados,
        this.tiposPagador,
        this.monedas)
    })
  }

  tableDeleteEvent(cxc) {
    this.cuentaService.anularCuentaCobrar(cxc.id)
    this.obtenerCuentas()
  }

  nuevoComentario(event: string) {
    let user = this.authService.user
    let hoy = new Date();
    let commentBody = {
      cuenta_cobrar: this.tmpData.id,
      comentario: event,
      responsable: user.id,
      fecha: hoy.toISOString()
    }

    this.cuentaService.crearComentarioCuentaCobrar(commentBody).then((result) => {
      return this.cuentaService.obtenerComentariosCuentaCobrar(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.dateComentarios(comentario.fecha)
        return comentario
      })
      this.modalCuentaCobrar.refrescaComentarios(comentarios)
    })
  }

  workflowEvent(row) {
    console.log(row);

    if (row.numero_proceso < 1)
      return;

    switch (row.actionableType) {
      case 1:
        // Manual

        break;
      case 2:
        // Prorroga
        this.obtenerProrroga(row);
        break;
      case 3:
        // Liquidacion
        this.router.navigate(['/operaciones', row.numero_proceso]);
        break;
      case 4:
        // Recaudación
        this.openRecaudacion(row);
        break;

      default:
        break;
    }

  }

  openRecaudacion(row) {

    if (row.recaudacion < 1) {
      return;
    }

    const modalRef = this.modalService.open(AddRecaudacionModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });

    modalRef.componentInstance.recaudacionId = row.numero_proceso;

    modalRef.result.then((result) => {
      console.log(result);

    }, (reason) => {
      console.log(reason);

    });

  }

  obtenerProrroga(row) {
    this.prorrogasService.obtenerProrroga(row.numero_proceso)
      .then(res => {
        this.openProrrogas(res);
      })
      .catch(error => {
        console.error(error);

      });
  }

  openProrrogas(row) {
    let modalEditReference = this.modalService.open(AddProrrogaModalComponent, {
      ariaLabelledBy: "modal-basic-title",
      size: "xl",
    });

    modalEditReference.componentInstance.prorroga = row;
    modalEditReference.result.then(() => {

    });
  }

  editaComentario(event) {
    let hoy = new Date();

    event.fecha = hoy.toISOString()
    this.cuentaService.editarComentarioCuentaCobrar(event, event.id).then((result) => {
      return this.cuentaService.obtenerComentariosCuentaCobrar(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.dateComentarios(comentario.fecha)
        return comentario
      })
      this.modalCuentaCobrar.refrescaComentarios(comentarios)
    })
  }

  eliminaComentario(event) {
    this.cuentaService.eliminarcomentarioCuentaCobrar(event, event.id).then((result) => {
      return this.cuentaService.obtenerComentariosCuentaCobrar(this.tmpData.id)
    }).then((response: any) => {
      let comentarios = response.results.map((comentario) => {
        comentario.fecha = this.dateComentarios(comentario.fecha)
        return comentario
      })
      this.modalCuentaCobrar.refrescaComentarios(comentarios)
    })
  }

  parseDate(fecha) {

    let yy = fecha.split('-')[0];
    let mm = fecha.split('-')[1];
    let dd = fecha.split('-')[2];
    dd = dd.split('T')[0]


    let hour = `  ${fecha.split('T')[1].split(':')[0]}:${fecha.split('T')[1].split(':')[0]}`

    let date = `${yy}-${mm}-${dd}`;
    return date

  }

  dateComentarios(fecha) {
    let yy = fecha.split('-')[0];
    let mm = fecha.split('-')[1];
    let dd = fecha.split('-')[2];
    dd = dd.split('T')[0]

    let hora = fecha.split('T')[1].split(':')

    let H = Number(hora[0])
    let M = hora[1]
    let HoraDia = 'AM'
    if (H > 12) {
      H = H - 12
      HoraDia = 'PM'
    }

    let HoraCompleta = `${H}:${M} ${HoraDia}`
    let date = `${yy}/${mm}/${dd} ${HoraCompleta}`;
    return date
  }

  guardaCuentaPagar(event) {
    console.log(event)
    event.operacion = event.numero_proceso
    this.cuentaService.guardarCuenta(event).then((res: any) => {
      this.modalCuentaCobrar.cerrarModal()
    }).catch((err) => this.sharedFuncstions.displayErrors(err))
  }

  editarCuentaPagar(event) {
    this.cuentaService.modificarCuenta(event, event.id).then((res) => {
      this.modalCuentaCobrar.cerrarModal()
      this.tableEditEvent(res)
      this.obtenerCuentas();
    }).catch((err) => {
      console.log(err)
    })
  }

  canDelete(cxc) {
    const MANUAL = 1
    const PRORROGA = 2
    const RECAUDACION = 4

    if (!(parseFloat((Number(cxc['monto']) + Number(cxc['igv'])).toFixed(2)) == Number(cxc['saldo']))) return false
    if ([MANUAL, PRORROGA, RECAUDACION].indexOf(cxc['tipo_proceso']) != -1) return true

    return false
  }

  async openExcelModal() {
    const result = await Swal.fire({
      title: 'Generar Excel',
      text: "Elige si deseas generar el excel de la tabla actual o con todas las filas.",
      icon: 'info',
      showDenyButton: true,
      denyButtonColor: 'gray',
      denyButtonText: 'Simple',
      confirmButtonColor: '#3085D6',
      confirmButtonText: 'Completo'
    })
    console.log(result)
    if (result.isDenied) {
      this.generarExcel(this.rowData)
    } else if (result.isConfirmed) {
      this.generarExcelCompleto()
    }
  }

  parseDataExcel(data) {
    return data.map(el => {
      return {
        'Nro CxC': el?.id,
        'Tipo de Pagador': el?.tipo_pagador_descripcion,
        'Nombre': el?.cobrar_a,
        'RUC': el?.cobrar_a_ruc,
        'Proceso': el?.tipo_proceso_descripcion,
        'Número': el?.numero_proceso,
        'Fecha Registro': el?.fecha_creacion.split("T")[0],
        'Concepto': el?.concepto_descripcion,
        'Monto': el?.monto,
        'IGV': el?.igv,
        'Total x Cobrar': el?.totalxcobrar,
        'Saldo x Cobrar': el?.saldo,
        'Estado': el?.estado_descripcion,
        'Empresa': el?.empresa_nombre
      }
    })
  }

  async generarExcelCompleto() {
    const page_size = 5000
    let page = 1
    let data = []

    let generando = true
    while (generando) {
      try {
        let res = await this.cuentaService.obtenerCuentas(
          page,
          page_size,
          '',
          '',
          this.filtros.estado,
          this.filtros.concepto,
          this.filtros.tipo_linea,
          '',
          '',
          this.filtros.proceso,
          this.filtros.cobrar_a,
          this.filtros.numero_proceso,
          this.filtros.fecha_creacion__gte,
          this.filtros.fecha_creacion__lte,
          this.filtros.range
        );

        data = data.concat(res['results']);
        page++;

        if (res['page_number'] == res['num_pages']) {
          generando = false
          this.generarExcel(data)
        }
      } catch (ex) {
        console.log(ex)
        generando = false
      }
    }
  }

  generarExcel(data) {
    const parsedData = this.parseDataExcel(data)

    /* generate a worksheet */
    var ws = utils.json_to_sheet(parsedData);

    /* add to workbook */
    var wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Hoja 1");

    /* write workbook and force a download */
    writeFile(wb, `Reporte - ${new Date().toLocaleString()}.xlsx`);
  }

  get canDeleteOrEdit() {
    const perfilesPermitidos = [
      this.authService.perfil.perfilAdmin,
      this.authService.perfil.perfilJefeDeOperacionesID,
      this.authService.perfil.perfilAnalistaOperacionesID,
      this.authService.perfil.perfilAsistenteCobranzasID,
      this.authService.perfil.perfilGerenciaComercialID,
      this.authService.perfil.perfilGerenciaGeneralID,
    ]

    return perfilesPermitidos.indexOf(this.authService.user.perfil) != -1
  }

  canReassign(){
    return (row)=> {
      if ((row.tipo_proceso == 4 || row.tipo_proceso == 6) && row.concepto == -2) return true // Recaudacion/migracion - mora
      if (row.tipo_proceso == 2 && (row.concepto == -3 || row.concepto == 8)) return true // Prorroga - intereses prorroga || comisión prorroga
      return false
    }
  }

  async reassignEvent(row) {
    let cambiar = await Swal.fire({
      title: '¿Deseas cambiar de Tipo de pagador?',
      text: `El pagador actual de la CXC ${row.id} es ${this.tiposPagador.find(el=>el.id==row.tipo_pagador)?.descripcion}. ¿Deseas cambiarlo?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085D6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, cambiar',
      cancelButtonText: 'No'
    })
    if (cambiar.isConfirmed) {
      console.log(cambiar)
      if (row.tipo_pagador == 1) {
        row.tipo_pagador = 2
      } else {
        row.tipo_pagador = 1
      }
      this.cuentaService.modificarCuenta(row, row.id).then(res=>{
        this.obtenerCuentas();
      })
    }
  }
}
