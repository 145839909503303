import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { UsersCrudComponent } from './pages/users-crud/users-crud.component';
import { AdminLayoutComponent } from '../../layouts/admin/admin-layout.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { AuthGuard } from 'app/core/guards/auth.guard';
import { LoginPageGuard } from 'app/core/guards/login-page.guard';
import { ChangePasswordGuard } from '../../core/guards/change-password.guard';



const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        canActivate: [LoginPageGuard],
        component: LoginComponent,
        data: {
            breadcrumb: 'Login'
        }
    },
    {
        path: 'change-password',
        canActivate: [ChangePasswordGuard],
        component: ChangePasswordComponent,
        data: {
            breadcrumb: 'Cambiar contraseña'
        }
    },
    {
        path:'forget-password',
        canActivate: [LoginPageGuard],
        component: ForgetPasswordComponent,
        data: {
            breadcrumb: 'Olvidaste tu contraseña'
        }
    },
    // {
    //     path: '',
    //     component: AdminLayoutComponent,
    //     data: {
    //         breadcrumb: 'Autenticacion'
    //     },
      
    //     children: [
    //         {
    //             path: 'users',
    //             component: UsersCrudComponent,
    //             canActivate: [AuthGuard], 
    //             data: {
    //                 breadcrumb: 'Gestión de usuarios'
    //             }
    //         },
    //     ]
    // },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
