import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DocumentosCarteraService } from 'app/core/services/factoring/documentos-cartera.service';
import { FactoringService } from 'app/core/services/factoring/factoring.service';
import { faTimes, faUserTie } from '@fortawesome/free-solid-svg-icons';
import { CuentasService } from 'app/core/services/cuentas-x-cobrar/cuentas.service';
import { FormBuilder } from '@angular/forms';
import { AuthService } from 'app/core/services/auth/auth.service';
import { ConfigurationService } from 'app/core/services/configuration/configuration.service';
import { OrdenesPagoService } from 'app/core/services/ordenes-pago.service';
import { RecaudacionService } from 'app/core/services/factoring/recaudacion.service';

interface Filtro {
  page?: number,
  page_size?: number,
  beneficiario_nombre?: string,
  deudor_nombre?: string,
  operacion_numero_documento?: string,
  estado: string,
  operacion_detalle?: string,
  fecha_vencimiento__gte?: string,
  fecha_vencimiento__lte?: string,
  asistente_cobranzas?: string,
  empresa?: string,
  tipo_linea?: string,
}

@Component({
  selector: 'app-tabla-docs',
  templateUrl: './tabla-docs.component.html',
  styleUrls: ['./tabla-docs.component.css']
})
export class TablaDocsComponent implements OnInit {
  faUserTie = faUserTie;
  faTimes            = faTimes;
  @Input() seleccionPrevia = [];
  @Input() selectable = false;
  @Input() filtros: Filtro = {estado: '1,3'};
  @Output() tabladocs: EventEmitter<any> = new EventEmitter<any>();

  public rowData = [];
	public headerTable: any[];
	public pageTableActual: number;
	public pageTableTotal: number;
	@Input() pageTablePerPages: number = 10;
	public pageTablePages: number[];
  public tipo_pagadores = [
    {
      'id': 1,
      'descripcion': 'Aceptante'
    },
    {
      'id': 2,
      'descripcion': 'Cliente'
    }
  ]
  public columnas = [
    ['operacion_numero_documento', 'Número documento'],
    ['beneficiario_nombre', 'Cliente'],
    ['deudor_nombre', 'Aceptante'],
    ['tipo_producto_descripcion', 'Tipo producto'],
    ['operacion_tipo_detalle_descripcion', 'Tipo documento'],
    ['monto_neto_soles', 'Monto soles'],
    ['monto_neto_dolares', 'Monto dólares'],
    ['estado_descripcion', 'Estado'],
    ['fecha_vencimiento', 'Fecha vencimiento'],
  ]
  constructor(
    public cxcService              : CuentasService,
    private factoringService       : FactoringService,
    public formbuild               : FormBuilder,
    public documentosCarteraService: DocumentosCarteraService,
    public authService             : AuthService,
    public config                  : ConfigurationService,
    public activeModal             : NgbActiveModal,
    public ordenes                 : OrdenesPagoService,
    public recaudacion             : RecaudacionService
  ) { }

  ngOnInit(): void {
    this.getDocumentosCartera({page:1, per_page:this.pageTablePerPages, filtros:this.filtros })
    this.obtenerVariablesFiltros()
  }

  goToPage({page, per_page, filtros}){
    filtros.estado = filtros.estado ? filtros.estado : '1,3';
    this.filtros = filtros
    this.getDocumentosCartera({page, per_page, filtros})
	}

  getDocumentosCartera({page, per_page, filtros}) {
      this.documentosCarteraService
        .obtenerDocumentosCartera(
          page,
          per_page,
          filtros.beneficiario_nombre,
          filtros.deudor_nombre,
          filtros.operacion_numero_documento,
          '',
          filtros.estado,
          filtros.operacion,
          filtros.fecha_vencimiento__gte != undefined ? (filtros.fecha_vencimiento__gte).slice(0, -6):(filtros.fecha_vencimiento__gte),
          filtros.fecha_vencimiento__lte != undefined ? (filtros.fecha_vencimiento__lte).slice(0, -6):(filtros.fecha_vencimiento__lte),
          filtros.asistente_cobranzas,
          '',
          filtros.empresa,
          filtros.tipo_linea,
          '',
      ).then(res=>{
        this.loadTable(res)
    })
  }

  obtenerVariablesFiltros() {
    let estados  = this.documentosCarteraService.obtenerEstadosCarteras();
    let empresas = this.factoringService.obtenerEmpresasCodigo();
    let lineas   = this.factoringService.obtenerTiposDeLineas();
		let oficialesNegocio = this.factoringService.obtenerResponsables(3);
    let tipoDocs = this.config.obtenerTiposDetalles()
    let tiposPagos = this.ordenes.obtenerFormasDePago()
    let tiposPagadores = this.recaudacion.obtenerTiposPagador()
    // No es necesario obtenerlos ya que al haber un defaultValue simula un click en un filtrar y los obtiene. Si lo agrego tambien aca hace dos llamadas
    Promise.all([
      estados, 
      empresas, 
      lineas,
      oficialesNegocio,
      tipoDocs,
      tiposPagos,
      tiposPagadores
    ]).then(
      (res: any[]) => {
        let estados = res[0]['results']
        let empresas = res[1]['results'];
        let lineas = res[2]['results']
        this.loadTableColumns(
          estados, 
          empresas, 
          lineas,
          res[3]['results'],
          res[4]['results'],
          res[5]['results'],
          res[6]['results'],
          );
      }
    );
  }

  loadTableColumns(
    estados, 
    empresas, 
    lineas,
    oficiales,
    tiposDocs, 
    tiposPagos,
    tiposPagadores
    ) {
    let idOficiales = this.authService.user.equipo.map((item)=> item.id)
    let filtrados = oficiales.filter((item)=>  { return idOficiales.includes(item.id) || item.id == this.authService.user.id })
    let cols = this.columnas.map((item)=> {
      return {
        headerName: item[1],
        class: 'text-center',
        field: item[0]
      }
    })
    this.headerTable = cols


    let setHeader = (prop, obj) => {
      let Index = this.headerTable.findIndex((el)=> el.field == prop)
      if(Index >= 0){
        let oldHeader = this.headerTable[Index]
        this.headerTable[Index] = {
          ...oldHeader , 
          ...obj
        }
      }
    }

    let configs = {}

    configs = {
      filterProp : 'oficial_negocio',
      headerName : 'Ejecutivo',
      filterable : true,
      class: 'text-center ejecutivo-class',
      filterInput: true,
      filterDate: false
    }
    setHeader('ejecutivo',configs)


    configs = {
      filterProp : 'asistente_cobranza',
      headerName : 'Gestor',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('gestor',configs)

    configs = {
      filterProp : 'cliente_ruc',
      headerName : 'Ruc',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('cliente_ruc',configs)

    configs = {
      filterProp : 'beneficiario_nombre',
      headerName : 'Cliente',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('beneficiario_nombre',configs)

    configs = {
      filterProp : 'deudor_ruc',
      headerName : 'Ruc',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('deudor_ruc',configs)

    configs = {
      filterProp : 'deudor_nombre',
      headerName : 'Deudor',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('deudor_nombre',configs)

    configs = {
      filterProp : 'operacion',
      headerName : 'Nro Operación',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('nro_ope',configs)

    configs = {
      filterProp : 'tipo_linea',
      headerName : 'Producto',
      filterable : true,
      filterInput: false,
      filterSelectItems: lineas,
      filterItemsProps: {
        value: 'id',
        label: 'descripcion'
      },
    }
    setHeader('tipo_producto_descripcion',configs)

    configs = {
      filterProp : 'operacion_numero_documento',
      headerName : 'Nro Doc',
      filterable : true,
      filterInput: true,
      filterDate: false,
    }
    setHeader('operacion_numero_documento',configs)

    configs = {
      filterProp : 'fecha_desembolso',
      headerName : 'Fecha Cesión',
      pipe: 'date',
      filterable : true,
      filterInput: false,
      filterDate: true,
    }
    setHeader('fecha_cesion',configs)

    configs = {
      filterProp : 'monto_doc_deudor',
      headerName : 'Monto Deudor',
      filterable : false,
      filterInput: true,
      filterDate: false,
    }
    setHeader('monto_doc_deudor',configs)

    configs = {
      filterProp : 'monto_no_financiado',
      headerName : 'Monto No Financiado',
      filterable : false,
      filterInput: true,
      filterDate: false,
    }
    setHeader('monto_no_financiado',configs)

    configs = {
      filterProp : 'monto_ant_cliente_monto_financiado',
      headerName : 'Monto Ant Cliente',
      filterable : false,
      filterInput: true,
      filterDate: false,
    }
    setHeader('monto_ant_cliente_monto_financiado',configs)

    configs = {
      filterProp : 'estado',
      headerName : 'Estado',
      filterable : true,
      filterInput: false,
      filterDate: false,
      filterSelectItems: estados.filter( (value: any) => value.id != 2 ),
      filterItemsProps: {
        value: 'id',
        label: 'descripcion'
      },
    }
    setHeader('estado_descripcion',configs)

    configs = {
      filterProp : 'fecha_vencimiento',
      headerName : 'Fecha Vencimiento',
      pipe: 'date',
      filterable : true,
      filterInput: false,
      filterDate: true,
    }
    setHeader('fecha_vencimiento',configs)

    configs = {
      filterProp : 'monto_soles',
      headerName : 'Monto Soles',
      filterable : false,
      filterInput: true,
      filterDate: false,
    }
    setHeader('monto_soles',configs)

    configs = {
      filterProp : 'monto_dolares',
      headerName : 'Monto Dólares',
      filterable : false,
      filterInput: true,
      filterDate: false,
    }
    setHeader('monto_dolares',configs)

    configs = {
      filterProp : 'fecha_pago',
      headerName : 'Fecha Pago',
      filterable : true,
      filterInput: false,
      pipe: 'date',
      filterDate: true,
    }
    setHeader('fecha_pago',configs)

    configs = {
      filterProp : 'forma_pago',
      headerName : 'Forma Pago',
      filterable : true,
      filterInput: false,
      filterDate: false,
      filterSelectItems: tiposPagos,
      filterItemsProps: {
        value: 'id',
        label: 'descripcion'
      },
    }
    setHeader('forma_pago',configs)

    configs = {
      filterProp : 'tipo_pagador',
      headerName : 'Tipo Pagador',
      filterable : true,
      filterInput: false,
      filterDate: false,
      filterSelectItems: tiposPagadores,
      filterItemsProps: {
        value: 'id',
        label: 'descripcion'
      },
    }
    setHeader('tipo_pagador',configs)

    configs = {
      filterProp : 'dias_mora',
      headerName : 'Días Mora',
      filterable : false,
      filterInput: false,
      filterDate: false,
    }
    setHeader('dias_mora',configs)

    configs = {
      filterProp : 'monto_mora',
      headerName : 'Monto Mora',
      filterable : false,
      filterInput: false,
      filterDate: false,
    }
    setHeader('monto_mora',configs)

    configs = {
      filterProp : 'fecha_compromiso_pago',
      headerName : 'Fecha Compromiso Pago',
      filterable : false,
      pipe: 'date',
      filterInput: true,
      filterDate: false,
    }
    setHeader('fecha_compromiso_pago',configs)

    configs = {
      filterProp : 'asistente_cobranza',
      headerName : 'Gestión',
      filterable : false,
      filterInput: false,
      filterDate: false,
    }
    setHeader('gestion',configs)

    configs = {
      filterProp       : 'empresa',
      headerName       : 'Empresa',
      filterable       : true,
      filterInput: false,
      filterSelectItems: [
        {
          id: 1,
          nombre: 'Total Servicios'
        },
        {
          id: 2,
          nombre: 'Factoring'
        },
      ],
      filterItemsProps: {
        value: 'id',
        label: 'nombre'
      }
    }
    setHeader('empresa',configs)
  }

  loadTable(data) {
    this.rowData = data.results
		this.pageTableActual   = data.page_number
		this.pageTableTotal    = data.count
		this.pageTablePerPages = data.per_page
		this.pageTablePages    = []
		for (let index = 0; index < data.num_pages; index++) {
			this.pageTablePages.push(index)
		}
	}

  resultadosSelection(seleccion){
    this.tabladocs.emit(seleccion)
  }

}
