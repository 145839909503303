import { Component, ElementRef, OnInit, ViewChild, Renderer2, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { faKey, faUser, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from 'app/core/services/auth/auth.service';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { UsersModel } from 'app/shared/models/users.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { PasswordMath } from '../../Utils/password-math.validator';

declare var $:any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, AfterViewInit {

  @ViewChild('pass1') pass1 : ElementRef;
  @ViewChild('pass2') pass2 : ElementRef;

  public myForm: FormGroup;
  focus;
  focus1;
  focus2;
  test : Date = new Date();
  private toggleButton;
  private sidebarVisible: boolean;
  private nativeElement: Node;
  loginResult = true;
  errorsForm : any = {};
  loginWithErrors = false;
  user: any;
  faUser = faUser;
  faKey = faKey;
  Eye1 = faEyeSlash;
  Eye2 = faEyeSlash;
  constructor(
    private router   : Router,
    private element  : ElementRef,
    private spinner  : NgxSpinnerService,
    private formbuild: FormBuilder,
    private auth     : AuthService,
    public localService: LocalServiceService,
    public render: Renderer2,
    private cdRef:ChangeDetectorRef
  ) {
      this.nativeElement = element.nativeElement;
      this.sidebarVisible = false;
      this.user = new UsersModel();
   }

  ngOnInit(): void {

    this.checkFullPageBackgroundImage();
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    var navbar : HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];
    
    setTimeout(function(){
        // after 1000 ms we add the class animated to the login/register card
        $('.card').removeClass('card-hidden');
    }, 700);

    this.user = this.localService.getJsonValue('user');
    this.initForm();

  }

  ngAfterViewInit(){

    this.cdRef.detectChanges();

  }

  ngOnDestroy(){
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
  }

  checkFullPageBackgroundImage(){
    var $page = $('.full-page');
    var image_src = $page.data('image');
    
    if(image_src !== undefined){
        var image_container = '<div class="full-page-background" style="background-image: url(' + image_src + ') "/>'
        $page.append(image_container);
    }
  };

  initForm() {
    this.myForm = this.formbuild.group({
      // email : [ '', [Validators.required,Validators.pattern(/^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/), Validators.minLength(5)]],
      newPassword: ['', [Validators.required, Validators.minLength(8) ] ],
      confirmPassword: ['', [Validators.required, Validators.minLength(8) ] ]
    },
    {
      validators: this.passordIguales()
    });
  }

  // restablecer_password

  camposRequiredPattern(pattern){

    return( control: AbstractControl ) =>{

      return pattern.test(control.value) ? null : { noPaso: true };

    }

  }

  passordIguales(){

    ( formGroup: FormGroup )=>{

      const pass1Control = formGroup.get('newPassword');
      const pass2Control = formGroup.get('confirmPassword');

      return pass1Control!.value !== pass2Control!.value ? pass2Control.setErrors( { noesIgual:true } ) : null

    }

  }

  contrasenasNoValidas(){

    const pass1 = this.myForm.get('newPassword')?.value
    const pass2 = this.myForm.get('confirmPassword')?.value

    return pass1 !== pass2 ? true : false;

    
  }

  mostrarPass(valor:any){

    const passElement = valor == 'pass1' ? this.pass1.nativeElement : this.pass2.nativeElement;

    if( passElement.getAttribute('type') === 'password' ){

      this.render.setAttribute( passElement, 'type', 'text' );

      this.Eye1 = valor == 'pass1' ? faEye : this.Eye1;
      this.Eye2 = valor == 'pass2' ? faEye : this.Eye2;

    }else{

      this.render.setAttribute( passElement, 'type', 'password' );

      this.Eye1 = valor == 'pass1' ? faEyeSlash : this.Eye1;
      this.Eye2 = valor == 'pass2' ? faEyeSlash : this.Eye2;

    }

  }

  onSubmit() {
    if (this.myForm.valid) {
      const data = {
        password: this.myForm.value['newPassword'],
      };
      
      this.auth.changePassword(this.user.id, data).then((res)=>{
        this.spinner.hide();
        // this.router.navigate(['/dashboard']);
      }).catch((err)=>{
          this.spinner.hide();
          this.loginResult = false;
          console.log('Ocurrio un error durante el registro!')
      })
    }
  }

}
