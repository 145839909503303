import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { LocalServiceService } from '../services/local-service.service';

@Injectable({
	providedIn: 'root'
})
export class LoginPageGuard implements CanActivate {
	
	constructor(
		public router : Router,
		public localService: LocalServiceService,
		public authService      : AuthService){
		
	} 

	canActivate(
	next: ActivatedRouteSnapshot,
	state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		
		let proximaRutaLogin = state.url === '/login'
		let userExist        = !!(this.localService.getJsonValue('user'))

		if(state.url === '/forget-password' && userExist){
			this.router.navigate(['/dashboard']);
			return false;
		}

		if(proximaRutaLogin && userExist){
			this.router.navigate(['/dashboard'])
		}
		
		return true;
	}
	
}
	