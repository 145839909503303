import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { faCheck, faPencilAlt, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
	selector: 'app-caja-comentarios',
	templateUrl: './caja-comentarios.component.html',
	styleUrls: ['./caja-comentarios.component.css']
})
export class CajaComentariosComponent implements OnInit {
	/**
	 * @param readOnly Indica que no se puede agregar ni modificar comentarios
	 * @param canAdd Indica que se pueden agregar items
	 * @param canRolEdit Indica que el rol pueden editar items
	 * @param canRolDelete Indica que el rol pueden elimiar items
	 * @param body Propiedad que renderiza el body del arreglo Filas
	 * @param titleAdd Titulo que se muestra cuando se va a agregar un nuevo item
	 * @param subtitleAdd Subtitulo que se muestra cuando se va a agregar un nuevo item
	 * @param idAutor Indica la propiedad que puede editar o eliminar un item
	 * @param actualID Indica el ID del usuario o elemento que puede agregar editar o modificar un tiem
	 * @param header Propiedad que indica el titulo que se va a renderizar el arreglo Filas
	 * @param subHeader Propiedad que indica el subtitulo que se va a renderizar del arreglo Filas
	 * @param filas Arreglo de elementos que se renderizaran
	 */
	faPlus=faPlus;
	faTimes = faTimes;
	faPencilAlt=faPencilAlt;
	faCheck=faCheck;
	@Input() readOnly  		= false;
	@Input() canAdd    		= false;
	@Input() canDelete 		= false;
	@Input() canRolEdit 	= false;
	@Input() canRolDelete 	= false;
	@Input() body       	: string = 'comentario';
	@Input() titleAdd   	: string;
	@Input() subtitleAdd	: string;
	@Input() idAutor    	: string;
	@Input() actualID    	: string;
	@Input() header     	: string = 'responsable_nombre';
	@Input() subHeader  	: string = 'responsable_rol';
	_filas      : any[];
	@Input() set filas(value){
		
		if(!!value){
			value.forEach((i,index)=>{
				if((this.actualID === i[this.idAutor]) && !i[this.body]){
					i.editable = true
				} else {
					i.editable = false
				}
				i.index = index
			})
	
			this._filas = value
		} else {
			this._filas = []
		}
	};

	get filas(){
		return this._filas
	}

	/**
	 * Pipes
	 */
	@Input() pipeSubHeader = '';

	@Output() eventoEditar = new EventEmitter<boolean>();
	@Output() eventoAgregar = new EventEmitter<boolean>();
	@Output() eventoEliminar = new EventEmitter<any>();
	texto : string;
	newItem: boolean = false;
	editarItem: boolean = false;
	public itemForm              : FormGroup;

	
	puedeEditarEliminar(idAutor){
		if(this.actualID==idAutor || this.canRolDelete){
			return true
		} else {
			return false
		}
	}
	
	
	constructor(
		private formbuild : FormBuilder) { }
	
	ngOnInit(): void {
		this.initComponent()
	}
	
	initComponent(){
		this.itemForm = this.formbuild.group({
			newItem: ['', [Validators.required]],
			editarItem: [''],
		});
	}

	newItemClick(){
		this.newItem = true
	}

	submitItem(){
		this.eventoAgregar.emit(this.itemForm.get('newItem').value)
		this.newItem = false
	}

	accionEditarItem(item){
		this._filas[item.index].editable = true
		this.itemForm.get('editarItem').setValue(item[this.body])
	}

	accionEliminarItem(item){
		this.eventoEliminar.emit(this._filas[item.index]);
	}

	saveEditable(item){
		this._filas[item.index].editable = false
		this._filas[item.index][this.body] = this.itemForm.get('editarItem').value
		this.eventoEditar.emit(this._filas[item.index])
	}

	muestraComentario(item){
		let diferenteDeNulo = (item[this.body] != '') && (item[this.body] != null)
		let esAutor         = item[this.idAutor] === this.actualID

		if(diferenteDeNulo || esAutor){
			return true
		} else {
			return false
		}
		// return true
	}
	
}
