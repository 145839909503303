import { Component } from '@angular/core';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
import { ModalMatricesRiesgoComponent } from "./modals/modal-matrices-riesgo/modal-matrices-riesgo.component";
import { BaseLavadoActivosComponent } from '../../base-lavado-activos-component';

@Component({
  selector: 'app-matrices-riesgo',
  templateUrl: './matrices-riesgo.component.html',
  styleUrls: ['./matrices-riesgo.component.css']
})
export class MatricesRiesgoComponent extends BaseLavadoActivosComponent {
  public requestService = () => this.lavadoActivosService.obtenerMatrices();
  public editModal: any = ModalMatricesRiesgoComponent;
  constructor(
    public modalService: NgbModal,
    public authService: AuthService,
    private lavadoActivosService: LavadoActivosService
  ) { 
    super(authService, modalService)
  }

  initVars() {
    let columns = [
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },
      {
        headerName: "Tipo",
        field: "tipo_descripcion",
        sortable: true,
      },
      {
        headerName: "Descripción",
        field: "descripcion",
        sortable: true,
      },
    ];

    this.columnDefs = columns;
  }
}
