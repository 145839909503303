import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-open-text-modal',
  templateUrl: './open-text-modal.component.html',
  styleUrls: ['./open-text-modal.component.css']
})
export class OpenTextModalComponent implements OnInit {
  faTimes = faTimes;
  @Input() title = '';
  @Input() message = '';

  constructor(
    public activeModal          : NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

}
