import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faWallet } from '@fortawesome/free-solid-svg-icons';
import { MonedaPipe } from 'app/core/pipes/moneda.pipe';
import { LocalServiceService } from 'app/core/services/local-service.service';
import { SharedFunctions } from 'app/core/shared/functions';
import { Router } from '@angular/router';

@Component({
	selector: 'app-table-stats',
	templateUrl: './table-stats.component.html',
	styleUrls: ['./table-stats.component.css'],
	providers:    [ MonedaPipe ]
})
export class TableStatsComponent implements OnInit {
	faWallet=faWallet;
	@Output() rowClickEvent: EventEmitter<any> = new EventEmitter<any>();

	_carterasVencidas : any[];
	@Output () updateEvent: EventEmitter<any> = new EventEmitter<any>();
	@Input() set carterasVencidas(value){
		if(value){
			// this._carterasVencidas = value;
			this._carterasVencidas = value.filter((item)=> item.codigo != '00')
		}
	}
	get carterasVencidas(){
		return this._carterasVencidas
	}
	@Input() title:string;
	@Input() liderOficial:any;

	constructor(
		public sharedFunctions: SharedFunctions,
		public monedapipe     : MonedaPipe,
		public localService: LocalServiceService,
		private router : Router

	) { }
	
	ngOnInit(): void {
		// this.carterasVencidas = this.carterasVencidas.splice(0,1)
	}

	update(){
		this.updateEvent.emit(true)
	}

	rowClick(row){
		console.log( row )
		let hoy = new Date()
		let newDate: any;
		hoy.setHours(0,0,0,0)
		let { getFunctionalDate, addDaysToDate,restDaysToDate } = this.sharedFunctions
		let data = {
			fecha_vencimiento__gte: '',
			fecha_vencimiento__lte: ''
		}
		console.log(row.concepto)
		switch(row.codigo){
			case '01':
				newDate = restDaysToDate(hoy,30)

				data.fecha_vencimiento__gte = getFunctionalDate(restDaysToDate(hoy,365))
				data.fecha_vencimiento__lte = getFunctionalDate(newDate)
				//Vence en mas de 30 dias
			break;
			case '02':
				newDate = restDaysToDate(hoy,15)
				data.fecha_vencimiento__gte = getFunctionalDate(restDaysToDate(newDate,15)) 
				data.fecha_vencimiento__lte = getFunctionalDate(newDate)
				//Vence en mas de 15 dias
			break;
			case '03':
				data.fecha_vencimiento__gte = getFunctionalDate(restDaysToDate(hoy,15)) 
				data.fecha_vencimiento__lte = getFunctionalDate(restDaysToDate(hoy,7))
				//Vence en mas de 7 dias
			break;
			case '04':
				data.fecha_vencimiento__gte = getFunctionalDate(restDaysToDate(hoy,7)) 
				data.fecha_vencimiento__lte = getFunctionalDate(restDaysToDate(hoy,1))
				//Vencidos semana pasada
			break;
			case '05':
				data.fecha_vencimiento__gte = getFunctionalDate(hoy) 
				data.fecha_vencimiento__lte = getFunctionalDate(hoy)
				//Vencen hoy
			break;
			case '06':
				data.fecha_vencimiento__gte = getFunctionalDate(hoy) 
				data.fecha_vencimiento__lte = getFunctionalDate(addDaysToDate(hoy,7))
				//Por vencer proxima semana

			break;
			case '07':
				newDate = addDaysToDate(hoy,7)
				data.fecha_vencimiento__gte = getFunctionalDate(newDate) 
				data.fecha_vencimiento__lte = getFunctionalDate(addDaysToDate(newDate,8))
				//Por vencer 7 dias
			break;
			case '08':
				newDate = addDaysToDate(hoy,15)
				data.fecha_vencimiento__gte = getFunctionalDate(newDate) 
				data.fecha_vencimiento__lte = getFunctionalDate(addDaysToDate(newDate,15))
				//Por vencer 15 dias
			break;
			case '09':
				newDate = addDaysToDate(hoy,30)
				data.fecha_vencimiento__gte = getFunctionalDate(newDate) 
				data.fecha_vencimiento__lte = getFunctionalDate(addDaysToDate(newDate,15))
				//Por vencer 30 dias
			break;
			case '10':
				newDate = addDaysToDate(hoy,45)
				data.fecha_vencimiento__gte = getFunctionalDate(newDate) 
				data.fecha_vencimiento__lte = getFunctionalDate(addDaysToDate(newDate,15))
				//Por vencer 45 dias
			break;
			case '11':
				newDate = addDaysToDate(hoy,60)
				data.fecha_vencimiento__gte = getFunctionalDate(newDate) 
				data.fecha_vencimiento__lte = getFunctionalDate(addDaysToDate(newDate,365))
				//Por vencer 60 dias
			break;
		}

		this.localService.setJsonValue("DashCarterasVencidas", data);
		//  this.router.navigate(["/reporte/liquidacion", { 'data': JSON.stringify(data) }]);
		// window.open(`#/documentos_cartera/ol/${ this.liderOficial.oficial == '' ? 'o' :this.liderOficial.oficial  }/${ this.liderOficial.lider == '' ? 'l' :this.liderOficial.lider  }`);	
		this.router.navigate(['/documentos_cartera/ol/', this.liderOficial.oficial == '' ? 'o' :this.liderOficial.oficial, this.liderOficial.lider == '' ? 'l' :this.liderOficial.lider ])
		// window.open("#/documentos_cartera", "_blank");		
	}
	
}
