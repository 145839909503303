import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ConfigurationService } from "../../../../core/services/configuration/configuration.service";

@Component({
  selector: "app-modals",
  templateUrl: "./modals.component.html",
  styleUrls: ["./modals.component.css"],
})
export class ModalsComponent implements OnInit {
  dateForm: FormGroup;

  @Input()
  row = null;

  @Output()
  successEmit: EventEmitter<any> = new EventEmitter<any>();

  constructor(private form: FormBuilder, private configurationService: ConfigurationService) {}

  ngOnInit(): void {
  }

  sendCloseOpen() {
    const year = this.row.year;
    const month = this.row.month;
    // si el periodo esta cerrado entonces abrirlo
    if (this.row.cerrado) {
      console.log("cerrado");
      const tipo = this.row.tipo_cierre == 1 ? "periodo_operativo" : "periodo_contable";

      this.configurationService.abrirPeriodoContableoOperativo({ year, month }, tipo)
        .subscribe(resp => this.successEmit.emit(resp));
      // si el periodo esta abierta y puede abrirse entonces cerrarlo
    } else if (!this.row.cerrado) {
      let tipo = this.row.tipo_cierre == 1 ? "periodo_operativo" : "periodo_contable";

      this.configurationService.cerrarPeriodoContableoOperativo({ year, month }, tipo)
        .subscribe(resp => this.successEmit.emit(resp));
      // el resto de casos (periodos abiertos)
    }
  }

  formatDate(date) {
    return (
      date.getFullYear()
      + "-"
      + ((date.getMonth() + 1).toString().length == 1
        ? "0" + (date.getMonth() + 1)
        : (date.getMonth() + 1))
      + "-"
      + date.getDate()
    );
  }
}
