import { Component, OnInit, ViewChild } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AuthService } from "app/core/services/auth/auth.service";
import { LavadoActivosService } from "app/core/services/lavado_activos/lavado_activos.service";
import { ModalsReporteComponent } from "./modals-reporte/modals-reporte.component";
import { BaseLavadoActivosComponent } from '../../base-lavado-activos-component';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent extends BaseLavadoActivosComponent implements OnInit {
  public requestService = (filters) =>  this.lavadoActivosService.obtenerReportes(filters)
  public reporte: any;
  public editModal: any = ModalsReporteComponent;
  private modalConfirmDeleteReference: any;
  
  @ViewChild("e_unabledelete", { static: false }) public e_unabledelete;
  @ViewChild("e_confirmdelete", { static: false }) public e_confirmdelete;

  constructor(
    public modalService: NgbModal,
    public authService: AuthService,
    private lavadoActivosService: LavadoActivosService,
  ) {
    super(authService, modalService)
  }
  

  initVars() {
    let columns = [  
      {
        headerName: "N°",
        field: "index",
        sortable: true,
      },  
      {
        class: "text-center",
        headerName: "Creado por",
        field: "usuario_creacion",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Actualizado por",
        field: "usuario_actualizacion",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fecha de creación",
        field: "fecha_creacion",
        sortable: true,
      },
      {
        class: "text-center",
        headerName: "Fecha de actualización",
        field: "fecha_actualizacion",
        sortable: true,
      }, 
      {
        class: "text-center",
        headerName: "Cliente",
        field: "cliente_nombre",
        sortable: true,
        filterable: true,
        filterProp: "cliente_nombre", // Nombre de la propiedad a filtrar
        filterInput: true, // Se puede filtrar?
      },
      {
        class: "text-center",
        headerName: "RUC",
        field: "cliente_ruc",
        sortable: true,
        filterable: true,
        filterProp: "cliente_ruc", // Nombre de la propiedad a filtrar
        filterInput: true, // Se puede filtrar?
      },
    ];

    this.columnDefs = columns;
  }
  addReporte(){
    this.openModal(ModalsReporteComponent, {})
  }
  
  deleteActionTable(row: any){
    this.reporte = row;
    this.modalConfirmDeleteReference = this.modalService.open(
      this.e_confirmdelete,
      {
        size: "md",
      }
    );
    
  }
  confirmDelete() {
    this.lavadoActivosService
      .deleteReporte(this.reporte.id) 
      .then(() => {
        this.modalConfirmDeleteReference.close();
        this.reporte = null;
        this.executeService();
      });
  }
  isDeleteAction(row: any){
    return row.es_dinamico ? false: true
  }

  cancelDelete() {
    this.reporte = null;
    this.modalConfirmDeleteReference.close();
  }

  goToPage({ page, per_page, filtros }) {
    this.filters = filtros
    this.filters['page'] = page
    this.filters['page_size'] = per_page
  
    this.executeService();
    
  }

}
