import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PropuestaComercialService } from './propuesta-comercial/propuesta-comercial.service';

@Injectable({
  providedIn: 'root'
})
export class CommonServicesService {
  invocarDesgloses: Subject<any> = new Subject(); 
  public desglosesdescripcion: any[] = [
    {
      id: 1,
      descripcion: 'comercial'
    },
    {
      id: 2,
      descripcion: 'riesgos'
    },
    {
      id: 3,
      descripcion: 'comite'
    },
  ]; 

  constructor(
    public propuestaService: PropuestaComercialService,
  ) { }

  obtenerDesglosesDesdeComponente() { 
    this.invocarDesgloses.next(true)      
  }

  getDesglose(idOperacion,propuestaId){
    var desglosesResponse;
    let desgloses: any[];

    return new Promise((resolve,reject)=>{
      this.propuestaService.buscaAceptantesDesgloseLineas('',idOperacion)
      .then((res: any)=>{
        desglosesResponse = res
        let idsPromesa = [];
        let promesas = res.reduce((acc,item)=>{
          if(!idsPromesa.includes(item.aceptante)){
            idsPromesa.push(item.aceptante)
            console.log(item.aceptante)
            acc.push(this.propuestaService.buscaAceptantesById(item.aceptante))
          }
  
          return acc
        },[])
  
        return Promise.all(promesas)
      })
      .then((aceptantes: any[])=>{
        desgloses = 
        // Se asigna aceptantes a los desgloses
        desglosesResponse.map((desglose: any)=>{
          desglose.aceptante = aceptantes.find((acp)=> acp.id == desglose.aceptante)
          return desglose
        })
        //Se filtran los desgloses por la propuesta en la variable global propuesta
        .filter((desglose)=> desglose.propuesta == propuestaId)
        resolve({
          desgloses
        })
      })    
    });
  }
}
