import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { PoderesComponent } from "app/shared/poderes/poderes.component";
import { ClientesRoutingModule } from "./clientes-routing.module";
import { ClientesComponent } from "./pages/clientes/clientes.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SharedModule } from "app/shared/shared.module";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { UiSwitchModule } from "ngx-ui-switch";
import { AddClienteComponent } from "./pages/add-cliente/add-cliente.component";
import { GeneralDataComponent } from "./partials/general-data/general-data.component";
import { ContactClienteComponent } from "./partials/general/contact-cliente/contact-cliente.component";
import { AddContactComponent } from "./modals/add-contact/add-contact.component";
import { ShareholdersComponent } from "./partials/general/shareholders/shareholders.component";
import { AddShareholdersComponent } from "./modals/add-shareholders/add-shareholders.component";
import { DirectoriesComponent } from "./partials/general/directories/directories.component";
import { AddDirectorieComponent } from "./modals/add-directorie/add-directorie.component";
import { EntidadesVinculosComponent } from "./partials/general/entidades-vinculos/entidades-vinculos.component";
import { AddEntidadVinculoComponent } from "./modals/add-entidad-vinculo/add-entidad-vinculo.component";
import { AdministrationsComponent } from "./partials/general/administrations/administrations.component";
import { AddEjecutivoComponent } from "./modals/add-ejecutivo/add-ejecutivo.component";
import { BusinessInformationComponent } from "./partials/business-information/business-information.component";
import { LocalesSucursalesComponent } from "./partials/bussins/locales-sucursales/locales-sucursales.component";
import { IngresosComponent } from "./partials/bussins/ingresos/ingresos.component";
import { RelationsClientComponent } from "./partials/bussins/relations-client/relations-client.component";
import { RelationsProvidersComponent } from "./partials/bussins/relations-providers/relations-providers.component";
import { RelationsCompetitorsComponent } from "./partials/bussins/relations-competitors/relations-competitors.component";
import { RelationsAssetsComponent } from "./partials/bussins/relations-assets/relations-assets.component";
import { AddLocalComponent } from "./modals/add-local/add-local.component";
import { AddRelationClientComponent } from "./modals/add-relation-client/add-relation-client.component";
import { AddRelationProviderComponent } from "./modals/add-relation-provider/add-relation-provider.component";
import { AddRelationCompetitorComponent } from "./modals/add-relation-competitor/add-relation-competitor.component";
import { AddAssetComponent } from "./modals/add-asset/add-asset.component";
import { CuentasAbonoComponent } from "./partials/general/cuentas-abono/cuentas-abono.component";
import { AddCuentasAbonoComponent } from "./modals/add-cuentas-abono/add-cuentas-abono.component";
import { AntecedentesCreditoComponent } from "./partials/antecedentes-credito/antecedentes-credito.component";
import { CreditosCapitalTrabajoComponent } from "./partials/antecedentes-credito/creditos-capital-trabajo/creditos-capital-trabajo.component";
import { OtrosCreditosComponent } from "./partials/antecedentes-credito/otros-creditos/otros-creditos.component";
import { RelacionesFinancierasComponent } from "./partials/antecedentes-credito/relaciones-financieras/relaciones-financieras.component";
import { AddComercialCreditComponent } from "./modals/add-comercial-credit/add-comercial-credit.component";
import { AddOperationalCreditComponent } from "./modals/add-operational-credit/add-operational-credit.component";
import { AddOtherCreditComponent } from "./modals/add-other-credit/add-other-credit.component";
import { AddFinancialRelationshipComponent } from "./modals/add-financial-relationship/add-financial-relationship.component";
import { LineasCreditoComponent } from './partials/lineas-credito/lineas-credito.component';
import { LineasDescuentoComponent } from "./partials/lineas-credito/lineas-descuento/lineas-descuento.component";
import { GruposEconomicosComponent } from "./pages/grupos-economicos/grupos-economicos.component";
import { AddGrupoEconomicoModalComponent } from "./modals/add-grupo-economico-modal/add-grupo-economico-modal.component";
import { LineasFactoringComponent } from "./partials/lineas-credito/lineas-factoring/lineas-factoring.component";
import { AvalesfiadoresComponent } from './partials/avalesfiadores/avalesfiadores.component';
import { DocumentosderespaldoComponent } from './partials/documentosderespaldo/documentosderespaldo.component';
import { AddPagareComponent } from './modals/add-pagare/add-pagare.component';
import { AddAvalComponent } from './modals/add-aval/add-aval.component';
import { ShowOperacionesComponent } from "./modals/show-operaciones/show-operaciones.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LineasDesgloseComponent } from './partials/lineas-credito/lineas-desglose/lineas-desglose.component';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ArchivosComponent } from './partials/archivos/archivos/archivos.component';
import { AddArchivoComponent } from './partials/archivos/partials/add-archivo/add-archivo.component';
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { TasasHistoricasComponent } from "app/shared/partials/propuestas/modals/tasas-historicas/tasas-historicas.component";
import { DocumentosComponent } from './partials/documentos/documentos.component';
import { AsignacionCarteraComponent } from './pages/asignacion-cartera/asignacion-cartera.component';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    PoderesComponent,
    ClientesComponent,
    AddClienteComponent,
    GeneralDataComponent,
    ContactClienteComponent,
    AddContactComponent,
    ShareholdersComponent,
    AddShareholdersComponent,
    DirectoriesComponent,
    AddDirectorieComponent,
    EntidadesVinculosComponent,
    AddEntidadVinculoComponent,
    AdministrationsComponent,
    AddEjecutivoComponent,
    BusinessInformationComponent,
    LocalesSucursalesComponent,
    IngresosComponent,
    RelationsClientComponent,
    RelationsProvidersComponent,
    RelationsCompetitorsComponent,
    RelationsAssetsComponent,
    AddLocalComponent,
    AddRelationClientComponent,
    AddRelationProviderComponent,
    AddRelationCompetitorComponent,
    AddAssetComponent,
    CuentasAbonoComponent,
    AddCuentasAbonoComponent,
    AntecedentesCreditoComponent,
    CreditosCapitalTrabajoComponent,
    OtrosCreditosComponent,
    RelacionesFinancierasComponent,
    AddComercialCreditComponent,
    AddOperationalCreditComponent,
    AddOtherCreditComponent,
    AddFinancialRelationshipComponent,
    LineasCreditoComponent,
    LineasDescuentoComponent,
    GruposEconomicosComponent,
    AddGrupoEconomicoModalComponent,
    LineasFactoringComponent,
    AvalesfiadoresComponent,
    DocumentosderespaldoComponent,
    AddPagareComponent,
    AddAvalComponent,
    ShowOperacionesComponent,
    LineasDesgloseComponent,
    ArchivosComponent,
    AddArchivoComponent,
    TasasHistoricasComponent,
    DocumentosComponent,
    AsignacionCarteraComponent,
  ],
  entryComponents: [AddContactComponent, ShareholdersComponent],
  exports: [
    ArchivosComponent,
    AddArchivoComponent
  ],
  imports: [
    CommonModule,
    ClientesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    NgbModule,
    NgxDatatableModule,
    NgSelectModule,
    UiSwitchModule.forRoot({
      size: "medium",
      color: "#3e50b4",
      checkedTextColor: "#ffffff",
      uncheckedTextColor: "#ffffff",
      defaultBgColor: "#6c757d",
      defaultBoColor: "#3e50b4",
      checkedLabel: "Si ",
      uncheckedLabel: "No",
    }),
    NgxMaskModule.forRoot(maskConfig),
    BsDatepickerModule.forRoot(),
    FontAwesomeModule,
    TooltipModule
    
  ],
})
export class ClientesModule {}
